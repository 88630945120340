import { useState } from "react";
import { MaybePromise } from "axios-cache-interceptor";
import { Backend } from "@screencloud/billing.types";

import { getCustomer, invalidateGetCustomer } from "src/billinglatest/clients/service.client";
import { useAppContext } from "src/hooks/useAppContext";

import { HookProps } from "src/billinglatest/types";

export interface UseCustomer {
  hasBillingAddress: () => boolean;
  isChargebee: () => boolean;
  isSignage: () => boolean;
  providerName: () => string;
  providerLink: () => string;
  set: (input: Backend.Organizations.Customer.Get.Response.Body) => void;
  get: () => Backend.Organizations.Customer.Get.Response.Body;
  fetch: () => Promise<void>;
  refetch: () => Promise<void>;
  invalidate: () => MaybePromise<void>;
}

export function useCustomer(props?: HookProps): UseCustomer {
  const context = useAppContext();
  const cache = props?.cache || false;
  const [_get, _set] = useState({} as Backend.Organizations.Customer.Get.Response.Body);

  /**
   * Determines if the customer has a billing address on record.
   *
   * @remarks
   * This function checks if the customer has provided their billing address. Having a valid billing address is crucial
   * for further processing, and certain actions should be restricted until this information is available. The presence
   * of a billing address is determined primarily by the country field.
   */
  const hasBillingAddress = (): boolean => {
    const billingAddress = get().billingAddress;
    return !!(billingAddress && billingAddress.country);
  };

  /**
   * Checks if the customer is using Chargebee as their provider.
   *
   * @remarks
   * Only those on Chargebee get the full billing section. The rest, well, they're considered partners and get a
   * severely limited view, if any.
   */
  const isChargebee = (): boolean => {
    return get().provider === "chargebee";
  };

  /**
   * Checks if the user is associated with Signage.
   *
   * @remarks
   * Signage customers should handle their subscriptions on the Signage platform. Access to Studio is only permitted
   * after official migration. If a Signage user attempts to access Studio, they'll receive a message directing them
   * to Signage.
   */
  const isSignage = (): boolean => {
    return get().cfPrimary !== "studio";
  };

  /**
   * Fetch the user-friendly name of the provider.
   *
   * @remarks
   * While typically reserved for non-Chargebee customers, we default to Chargebee if there's no specific match.
   */
  const providerName = (): string => {
    switch (get().provider) {
      case "azure":
        return "Microsoft Azure Marketplace";
      default:
        return "Chargebee";
    }
  };

  /**
   * Retrieve the link to manage subscriptions for the provider.
   *
   * @remarks
   * Chargebee will give you an empty string since the management hub is right here in Studio. We're just making sure
   * it's falsy to dodge those undefined errors.
   */
  const providerLink = (): string => {
    switch (get().provider) {
      case "azure":
        return "https://portal.azure.com/#view/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources";
      default:
        return "";
    }
  };

  /**
   * Essential Methods.
   *
   * This section includes essential methods that form the core of the hook's functionality. These methods are crucial
   * and are unlikely to require any updates in the future.
   */

  /**
   * Set the customer.
   */
  const set = (input: Backend.Organizations.Customer.Get.Response.Body): void => {
    return _set(input || {});
  };

  /**
   * Return the customer.
   */
  const get = (): Backend.Organizations.Customer.Get.Response.Body => {
    return _get;
  };

  /**
   * Fetches the customer from the billing service.
   *
   * @remarks
   * This function retrieves the customer from the billing service and stores it within the hook.
   */
  const fetch = async (): Promise<void> => {
    set(await getCustomer(context.currentSpace?.id, cache));
  };

  /**
   * A shortcut for the `fetch` function.
   *
   * @remarks
   * This function acts as a convenient alternative to the fetch function. Calling refetch() produces the same outcome
   * as calling fetch(), with the added benefit of ensuring the invalidation of any cached data.
   */
  const refetch = async (): Promise<void> => {
    await invalidate();
    return fetch();
  };

  /**
   * Clear the cache.
   *
   * @remarks
   * This method is of type `MaybePromise<>`, indicating it can be invoked in a promise-like or synchronous manner,
   * depending on the calling context (e.g., it's preferable to call it synchronously in useEffect).
   */
  const invalidate = () => {
    return invalidateGetCustomer();
  };

  /**
   * Return the hook
   */
  return {
    hasBillingAddress,
    isChargebee,
    isSignage,
    providerName,
    providerLink,
    set,
    get,
    fetch,
    refetch,
    invalidate,
  };
}
