import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** An IPv4 or IPv6 host address, and optionally its subnet. */
  InternetAddress: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A set of key/value pairs, keys are strings, values may be a string or null. Exposed as a JSON object. */
  KeyValueHash: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

export type ActivateSubscriptionInput = {
  spaceId?: InputMaybe<Scalars["String"]>;
};

export type ActivateSubscriptionPayload = {
  __typename: "ActivateSubscriptionPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

export type ActiveUser = {
  __typename: "ActiveUser";
  isOwner: Maybe<Scalars["Boolean"]>;
  orgId: Maybe<Scalars["UUID"]>;
  spaceId: Maybe<Scalars["UUID"]>;
  userId: Maybe<Scalars["UUID"]>;
};

/** All input for the `addAppInstanceToChannel` mutation. */
export type AddAppInstanceToChannelInput = {
  appInstanceId?: InputMaybe<Scalars["UUID"]>;
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  channelId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentPosition?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  rules?: InputMaybe<Scalars["JSON"]>;
  zoneKey?: InputMaybe<Scalars["String"]>;
};

/** The output of our `addAppInstanceToChannel` mutation. */
export type AddAppInstanceToChannelPayload = {
  __typename: "AddAppInstanceToChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `addAppInstanceToChannel` mutation. */
export type AddAppInstanceToChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `addAppInstanceToPlaylist` mutation. */
export type AddAppInstanceToPlaylistInput = {
  appInstanceId?: InputMaybe<Scalars["UUID"]>;
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentPosition?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  playlistId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `addAppInstanceToPlaylist` mutation. */
export type AddAppInstanceToPlaylistPayload = {
  __typename: "AddAppInstanceToPlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `addAppInstanceToPlaylist` mutation. */
export type AddAppInstanceToPlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `addFavoriteFile` mutation. */
export type AddFavoriteFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The output of our `addFavoriteFile` mutation. */
export type AddFavoriteFilePayload = {
  __typename: "AddFavoriteFilePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `addFavoriteFile` mutation. */
export type AddFavoriteFilePayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `addFavoriteFolder` mutation. */
export type AddFavoriteFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The output of our `addFavoriteFolder` mutation. */
export type AddFavoriteFolderPayload = {
  __typename: "AddFavoriteFolderPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `addFavoriteFolder` mutation. */
export type AddFavoriteFolderPayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `addFavoriteLink` mutation. */
export type AddFavoriteLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  linkId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The output of our `addFavoriteLink` mutation. */
export type AddFavoriteLinkPayload = {
  __typename: "AddFavoriteLinkPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `addFavoriteLink` mutation. */
export type AddFavoriteLinkPayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `addFavoritePlaylist` mutation. */
export type AddFavoritePlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  playlistId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The output of our `addFavoritePlaylist` mutation. */
export type AddFavoritePlaylistPayload = {
  __typename: "AddFavoritePlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `addFavoritePlaylist` mutation. */
export type AddFavoritePlaylistPayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `addFavoriteSite` mutation. */
export type AddFavoriteSiteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  siteId?: InputMaybe<Scalars["UUID"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `addFavoriteSite` mutation. */
export type AddFavoriteSitePayload = {
  __typename: "AddFavoriteSitePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `addFavoriteSite` mutation. */
export type AddFavoriteSitePayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `addFileToChannel` mutation. */
export type AddFileToChannelInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  channelId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentPosition?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
  rules?: InputMaybe<Scalars["JSON"]>;
  zoneKey?: InputMaybe<Scalars["String"]>;
};

/** The output of our `addFileToChannel` mutation. */
export type AddFileToChannelPayload = {
  __typename: "AddFileToChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `addFileToChannel` mutation. */
export type AddFileToChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `addFileToPlaylist` mutation. */
export type AddFileToPlaylistInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentPosition?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
  playlistId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `addFileToPlaylist` mutation. */
export type AddFileToPlaylistPayload = {
  __typename: "AddFileToPlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `addFileToPlaylist` mutation. */
export type AddFileToPlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `addGroupPermissionSets` mutation. */
export type AddGroupPermissionSetsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `addGroupPermissionSets` mutation. */
export type AddGroupPermissionSetsPayload = {
  __typename: "AddGroupPermissionSetsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  group: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge: Maybe<GroupsEdge>;
  /** Reads a single `Org` that is related to this `Group`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Group`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Group`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `addGroupPermissionSets` mutation. */
export type AddGroupPermissionSetsPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `addGroupSpacePermissionSets` mutation. */
export type AddGroupSpacePermissionSetsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `addGroupSpacePermissionSets` mutation. */
export type AddGroupSpacePermissionSetsPayload = {
  __typename: "AddGroupSpacePermissionSetsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupId: Maybe<Group>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupIdAndOrgId: Maybe<Group>;
  groupSpace: Maybe<GroupSpace>;
  /** An edge for our `GroupSpace`. May be used by Relay 1. */
  groupSpaceEdge: Maybe<GroupSpacesEdge>;
  /** Reads a single `Org` that is related to this `GroupSpace`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `GroupSpace`. */
  spaceBySpaceId: Maybe<Space>;
};

/** The output of our `addGroupSpacePermissionSets` mutation. */
export type AddGroupSpacePermissionSetsPayloadGroupSpaceEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

/** All input for the `addLinkToChannel` mutation. */
export type AddLinkToChannelInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  channelId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentPosition?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  linkId?: InputMaybe<Scalars["UUID"]>;
  rules?: InputMaybe<Scalars["JSON"]>;
  zoneKey?: InputMaybe<Scalars["String"]>;
};

/** The output of our `addLinkToChannel` mutation. */
export type AddLinkToChannelPayload = {
  __typename: "AddLinkToChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `addLinkToChannel` mutation. */
export type AddLinkToChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `addLinkToPlaylist` mutation. */
export type AddLinkToPlaylistInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentPosition?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  linkId?: InputMaybe<Scalars["UUID"]>;
  playlistId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `addLinkToPlaylist` mutation. */
export type AddLinkToPlaylistPayload = {
  __typename: "AddLinkToPlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `addLinkToPlaylist` mutation. */
export type AddLinkToPlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `addPlaylistToChannel` mutation. */
export type AddPlaylistToChannelInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  channelId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentPosition?: InputMaybe<Scalars["Int"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  playlistId?: InputMaybe<Scalars["UUID"]>;
  rules?: InputMaybe<Scalars["JSON"]>;
  zoneKey?: InputMaybe<Scalars["String"]>;
};

/** The output of our `addPlaylistToChannel` mutation. */
export type AddPlaylistToChannelPayload = {
  __typename: "AddPlaylistToChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `addPlaylistToChannel` mutation. */
export type AddPlaylistToChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `addUserToGroups` mutation. */
export type AddUserToGroupsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `addUserToGroups` mutation. */
export type AddUserToGroupsPayload = {
  __typename: "AddUserToGroupsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  userGroups: Maybe<Array<UserGroup>>;
};

/** The created app to show in the App Store. */
export type App = Node & {
  __typename: "App";
  /** Reads and enables pagination through a set of `AppInstall`. */
  appInstallsByAppId: AppInstallsConnection;
  /** Reads and enables pagination through a set of `AppInstance`. */
  appInstancesByAppId: AppInstancesConnection;
  appVersionByAppId: Maybe<AppVersion>;
  /** Reads and enables pagination through a set of `AppVersion`. */
  appVersionsByAppId: AppVersionsConnection;
  /** The app's categories. */
  categories: Array<Maybe<Scalars["String"]>>;
  /** Reads and enables pagination through a set of `CommonAppInstanceTemplate`. */
  commonAppInstanceTemplatesByAppId: CommonAppInstanceTemplatesConnection;
  createdAt: Maybe<Scalars["Datetime"]>;
  defaultInstall: AppDefaultInstallType;
  description: Maybe<Scalars["String"]>;
  developer: Maybe<Scalars["String"]>;
  iconUrl: Maybe<Scalars["String"]>;
  /** The uuid of the app. */
  id: Scalars["UUID"];
  isInstalled: Maybe<Scalars["Boolean"]>;
  latestAppVersionByApId: Maybe<AppVersion>;
  latestAppVersionByAppId: Maybe<AppVersion>;
  /** The name of the app, each app has unique name. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** A list of feature flags required for the installation of this app. */
  requirements: Array<Maybe<Scalars["String"]>>;
  /** The the app id on app register, once created not allow to change. */
  scrn: Scalars["String"];
  slug: Maybe<Scalars["String"]>;
  stableAppVersionByAppId: Maybe<AppVersion>;
  tags: Array<Maybe<Scalars["String"]>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  visibility: AppVisibility;
};

/** The created app to show in the App Store. */
export type AppAppInstallsByAppIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstallCondition>;
  filter: InputMaybe<AppInstallFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** The created app to show in the App Store. */
export type AppAppInstancesByAppIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** The created app to show in the App Store. */
export type AppAppVersionsByAppIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppVersionCondition>;
  filter: InputMaybe<AppVersionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppVersionsOrderBy>>;
};

/** The created app to show in the App Store. */
export type AppCommonAppInstanceTemplatesByAppIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonAppInstanceTemplateCondition>;
  filter: InputMaybe<CommonAppInstanceTemplateFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonAppInstanceTemplatesOrderBy>>;
};

/** A condition to be used against `App` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AppCondition = {
  /** Checks for equality with the object’s `categories` field. */
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `defaultInstall` field. */
  defaultInstall?: InputMaybe<AppDefaultInstallType>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `developer` field. */
  developer?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `iconUrl` field. */
  iconUrl?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `requirements` field. */
  requirements?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `scrn` field. */
  scrn?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `visibility` field. */
  visibility?: InputMaybe<AppVisibility>;
};

export enum AppConfiguration {
  Editor = "EDITOR",
  Form = "FORM",
}

/** A filter to be used against AppConfiguration fields. All fields are combined with a logical ‘and.’ */
export type AppConfigurationFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AppConfiguration>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AppConfiguration>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AppConfiguration>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AppConfiguration>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AppConfiguration>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AppConfiguration>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AppConfiguration>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AppConfiguration>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AppConfiguration>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AppConfiguration>>;
};

export enum AppDefaultInstallType {
  Always = "ALWAYS",
  Never = "NEVER",
  Once = "ONCE",
}

/** A filter to be used against AppDefaultInstallType fields. All fields are combined with a logical ‘and.’ */
export type AppDefaultInstallTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AppDefaultInstallType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AppDefaultInstallType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AppDefaultInstallType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AppDefaultInstallType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AppDefaultInstallType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AppDefaultInstallType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AppDefaultInstallType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AppDefaultInstallType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AppDefaultInstallType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AppDefaultInstallType>>;
};

/** A filter to be used against `App` object types. All fields are combined with a logical ‘and.’ */
export type AppFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AppFilter>>;
  /** Filter by the object’s `categories` field. */
  categories?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `defaultInstall` field. */
  defaultInstall?: InputMaybe<AppDefaultInstallTypeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `developer` field. */
  developer?: InputMaybe<StringFilter>;
  /** Filter by the object’s `iconUrl` field. */
  iconUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AppFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AppFilter>>;
  /** Filter by the object’s `requirements` field. */
  requirements?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `scrn` field. */
  scrn?: InputMaybe<StringFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `visibility` field. */
  visibility?: InputMaybe<AppVisibilityFilter>;
};

/** The installed app for each organization and space. */
export type AppInstall = Node & {
  __typename: "AppInstall";
  /** Get all app instances by app install ID and space ID. */
  allAppInstancesByAppInstallIdAndSpaceId: AppInstancesConnection;
  /** Reads a single `App` that is related to this `AppInstall`. */
  appByAppId: Maybe<App>;
  appId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `AppInstance`. */
  appInstancesByAppInstallId: AppInstancesConnection;
  /** Get available app instances for the current space and app install ID including global app instances and shared app instances. */
  availableAppInstancesByAppInstallIdAndSpace: AppInstancesConnection;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  latestAppVersionByAppInstallId: Maybe<AppVersion>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `AppInstall`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Space` that is related to this `AppInstall`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Maybe<Scalars["UUID"]>;
  stableAppVersionByAppInstallId: Maybe<AppVersion>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByUpdatedBy: Maybe<User>;
};

/** The installed app for each organization and space. */
export type AppInstallAllAppInstancesByAppInstallIdAndSpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The installed app for each organization and space. */
export type AppInstallAppInstancesByAppInstallIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** The installed app for each organization and space. */
export type AppInstallAvailableAppInstancesByAppInstallIdAndSpaceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<AppInstancesOrderBy>>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/**
 * A condition to be used against `AppInstall` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AppInstallCondition = {
  /** Checks for equality with the object’s `appId` field. */
  appId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `AppInstall` object types. All fields are combined with a logical ‘and.’ */
export type AppInstallFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AppInstallFilter>>;
  /** Filter by the object’s `appId` field. */
  appId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AppInstallFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AppInstallFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `AppInstall` values. */
export type AppInstallsConnection = {
  __typename: "AppInstallsConnection";
  /** A list of edges which contains the `AppInstall` and cursor to aid in pagination. */
  edges: Array<AppInstallsEdge>;
  /** A list of `AppInstall` objects. */
  nodes: Array<AppInstall>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppInstall` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `AppInstall` edge in the connection. */
export type AppInstallsEdge = {
  __typename: "AppInstallsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `AppInstall` at the end of the edge. */
  node: AppInstall;
};

/** Methods to use when ordering `AppInstall`. */
export enum AppInstallsOrderBy {
  AppIdAsc = "APP_ID_ASC",
  AppIdDesc = "APP_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

/** The app instance which created from app-install for each space in the organization. */
export type AppInstance = Node & {
  __typename: "AppInstance";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  appId: Scalars["UUID"];
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstallId: Scalars["UUID"];
  appVersionByAppInstanceId: Maybe<AppVersion>;
  /** Return associations to app instance by app_instance and space id. */
  associationsByAppInstanceIdAndSpaceId: AssociationsConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByFromAppInstanceAndOrgId: AssociationsConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByToAppInstanceAndOrgId: AssociationsConnection;
  /** The available timestamp of this app instance */
  availableAt: Maybe<Scalars["Datetime"]>;
  /** Get casted screen(s) by appInstance's id. */
  castedScreenByAppInstanceId: ScreensConnection;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** The common app instance template this app was created from. This link is severed after create. */
  commonAppInstanceTemplateId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `CommonAppInstanceTemplate`. */
  commonAppInstanceTemplatesBySourceAppInstanceId: CommonAppInstanceTemplatesConnection;
  config: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** The expire timestamp of this app instance */
  expireAt: Maybe<Scalars["Datetime"]>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Get files that contained in an app instance. */
  filesByAppInstanceId: FilesConnection;
  id: Scalars["UUID"];
  isShared: Maybe<Scalars["Boolean"]>;
  isSharedAll: Maybe<Scalars["Boolean"]>;
  isSharedExplicit: Maybe<Scalars["Boolean"]>;
  /** If this was created from a template this org created. (Note: nothing to do with common app instance templates). */
  isTemplate: Scalars["Boolean"];
  name: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByAppInstanceIdAndOrgId: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareAppInstance: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareAppInstanceAndOrgId: ShareAssociationsConnection;
  /** Spaces that currently got shared access to this app instance. */
  sharedSpacesByAppInstanceId: SpacesConnection;
  spaceId: Scalars["UUID"];
  state: Maybe<Scalars["JSON"]>;
  status: Maybe<AppInstanceStatus>;
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByToAppInstanceAndOrgId: TagAssociationsConnection;
  tags: Array<Maybe<Scalars["String"]>>;
  /** The common app instance template created from this app instance */
  targetCommonAppInstanceTemplateId: Maybe<Scalars["UUID"]>;
  thumbnailFileId: Maybe<Scalars["UUID"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
  version: Maybe<Scalars["String"]>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceAssociationsByAppInstanceIdAndSpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceAssociationsByFromAppInstanceAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceAssociationsByToAppInstanceAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceCastedScreenByAppInstanceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceCommonAppInstanceTemplatesBySourceAppInstanceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonAppInstanceTemplateCondition>;
  filter: InputMaybe<CommonAppInstanceTemplateFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonAppInstanceTemplatesOrderBy>>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceFilesByAppInstanceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceScreenContentHistoriesByAppInstanceIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceShareAssociationsByShareAppInstanceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceShareAssociationsByShareAppInstanceAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceSharedSpacesByAppInstanceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The app instance which created from app-install for each space in the organization. */
export type AppInstanceTagAssociationsByToAppInstanceAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/**
 * A condition to be used against `AppInstance` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AppInstanceCondition = {
  /** Checks for equality with the object’s `appId` field. */
  appId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `appInstallId` field. */
  appInstallId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `availableAt` field. */
  availableAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `commonAppInstanceTemplateId` field. */
  commonAppInstanceTemplateId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `expireAt` field. */
  expireAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isShared` field. */
  isShared?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isTemplate` field. */
  isTemplate?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<AppInstanceStatus>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `targetCommonAppInstanceTemplateId` field. */
  targetCommonAppInstanceTemplateId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `thumbnailFileId` field. */
  thumbnailFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `AppInstance` object types. All fields are combined with a logical ‘and.’ */
export type AppInstanceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AppInstanceFilter>>;
  /** Filter by the object’s `appId` field. */
  appId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `appInstallId` field. */
  appInstallId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `availableAt` field. */
  availableAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `commonAppInstanceTemplateId` field. */
  commonAppInstanceTemplateId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `expireAt` field. */
  expireAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTemplate` field. */
  isTemplate?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AppInstanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AppInstanceFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<AppInstanceStatusFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `targetCommonAppInstanceTemplateId` field. */
  targetCommonAppInstanceTemplateId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `thumbnailFileId` field. */
  thumbnailFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

export enum AppInstanceStatus {
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

/** A filter to be used against AppInstanceStatus fields. All fields are combined with a logical ‘and.’ */
export type AppInstanceStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AppInstanceStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AppInstanceStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AppInstanceStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AppInstanceStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AppInstanceStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AppInstanceStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AppInstanceStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AppInstanceStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AppInstanceStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AppInstanceStatus>>;
};

/** A connection to a list of `AppInstance` values. */
export type AppInstancesConnection = {
  __typename: "AppInstancesConnection";
  /** A list of edges which contains the `AppInstance` and cursor to aid in pagination. */
  edges: Array<AppInstancesEdge>;
  /** A list of `AppInstance` objects. */
  nodes: Array<AppInstance>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppInstance` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `AppInstance` edge in the connection. */
export type AppInstancesEdge = {
  __typename: "AppInstancesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `AppInstance` at the end of the edge. */
  node: AppInstance;
};

/** Methods to use when ordering `AppInstance`. */
export enum AppInstancesOrderBy {
  AppIdAsc = "APP_ID_ASC",
  AppIdDesc = "APP_ID_DESC",
  AppInstallIdAsc = "APP_INSTALL_ID_ASC",
  AppInstallIdDesc = "APP_INSTALL_ID_DESC",
  AvailableAtAsc = "AVAILABLE_AT_ASC",
  AvailableAtDesc = "AVAILABLE_AT_DESC",
  CommonAppInstanceTemplateIdAsc = "COMMON_APP_INSTANCE_TEMPLATE_ID_ASC",
  CommonAppInstanceTemplateIdDesc = "COMMON_APP_INSTANCE_TEMPLATE_ID_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  ExpireAtAsc = "EXPIRE_AT_ASC",
  ExpireAtDesc = "EXPIRE_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsSharedAllAsc = "IS_SHARED_ALL_ASC",
  IsSharedAllDesc = "IS_SHARED_ALL_DESC",
  IsSharedAsc = "IS_SHARED_ASC",
  IsSharedDesc = "IS_SHARED_DESC",
  IsSharedExplicitAsc = "IS_SHARED_EXPLICIT_ASC",
  IsSharedExplicitDesc = "IS_SHARED_EXPLICIT_DESC",
  IsTemplateAsc = "IS_TEMPLATE_ASC",
  IsTemplateDesc = "IS_TEMPLATE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  StateAsc = "STATE_ASC",
  StateDesc = "STATE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  TargetCommonAppInstanceTemplateIdAsc = "TARGET_COMMON_APP_INSTANCE_TEMPLATE_ID_ASC",
  TargetCommonAppInstanceTemplateIdDesc = "TARGET_COMMON_APP_INSTANCE_TEMPLATE_ID_DESC",
  ThumbnailFileIdAsc = "THUMBNAIL_FILE_ID_ASC",
  ThumbnailFileIdDesc = "THUMBNAIL_FILE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
}

export type AppManagementJwtPayload = {
  __typename: "AppManagementJwtPayload";
  expiresAt: Maybe<Scalars["Int"]>;
  signedAppManagementToken: Maybe<Scalars["String"]>;
  tokenType: Maybe<Scalars["String"]>;
};

export type AppManagementJwtRequestInput = {
  spaceId: Scalars["String"];
};

/** For storing and telling the information what app needed for each of its version. */
export type AppVersion = Node & {
  __typename: "AppVersion";
  /** Reads a single `App` that is related to this `AppVersion`. */
  appByAppId: Maybe<App>;
  appId: Scalars["UUID"];
  betaFlag: Maybe<Scalars["Boolean"]>;
  /** The type of configuration that the app required. */
  configurationType: Maybe<AppConfiguration>;
  createdAt: Maybe<Scalars["Datetime"]>;
  editorUrl: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Indicates the latest version of an app */
  isLatest: Scalars["Boolean"];
  /** Indicates the latest stable version of an app */
  isLatestStable: Scalars["Boolean"];
  manifestJson: Maybe<Scalars["JSON"]>;
  /** To tell the Studio that the app required to access the sandbox storage or not. */
  mediaSandboxAccess: Maybe<Scalars["Boolean"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  stableFlag: Maybe<Scalars["Boolean"]>;
  storeData: Maybe<Scalars["JSON"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  version: Maybe<Scalars["String"]>;
  viewerUrl: Maybe<Scalars["String"]>;
};

/**
 * A condition to be used against `AppVersion` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AppVersionCondition = {
  /** Checks for equality with the object’s `appId` field. */
  appId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `betaFlag` field. */
  betaFlag?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `configurationType` field. */
  configurationType?: InputMaybe<AppConfiguration>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `editorUrl` field. */
  editorUrl?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isLatest` field. */
  isLatest?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isLatestStable` field. */
  isLatestStable?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `manifestJson` field. */
  manifestJson?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `mediaSandboxAccess` field. */
  mediaSandboxAccess?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `stableFlag` field. */
  stableFlag?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `storeData` field. */
  storeData?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `viewerUrl` field. */
  viewerUrl?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `AppVersion` object types. All fields are combined with a logical ‘and.’ */
export type AppVersionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AppVersionFilter>>;
  /** Filter by the object’s `appId` field. */
  appId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `betaFlag` field. */
  betaFlag?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `configurationType` field. */
  configurationType?: InputMaybe<AppConfigurationFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `editorUrl` field. */
  editorUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isLatest` field. */
  isLatest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isLatestStable` field. */
  isLatestStable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `manifestJson` field. */
  manifestJson?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mediaSandboxAccess` field. */
  mediaSandboxAccess?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AppVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AppVersionFilter>>;
  /** Filter by the object’s `stableFlag` field. */
  stableFlag?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `storeData` field. */
  storeData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
  /** Filter by the object’s `viewerUrl` field. */
  viewerUrl?: InputMaybe<StringFilter>;
};

/** A connection to a list of `AppVersion` values. */
export type AppVersionsConnection = {
  __typename: "AppVersionsConnection";
  /** A list of edges which contains the `AppVersion` and cursor to aid in pagination. */
  edges: Array<AppVersionsEdge>;
  /** A list of `AppVersion` objects. */
  nodes: Array<AppVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppVersion` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `AppVersion` edge in the connection. */
export type AppVersionsEdge = {
  __typename: "AppVersionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `AppVersion` at the end of the edge. */
  node: AppVersion;
};

/** Methods to use when ordering `AppVersion`. */
export enum AppVersionsOrderBy {
  AppIdAsc = "APP_ID_ASC",
  AppIdDesc = "APP_ID_DESC",
  BetaFlagAsc = "BETA_FLAG_ASC",
  BetaFlagDesc = "BETA_FLAG_DESC",
  ConfigurationTypeAsc = "CONFIGURATION_TYPE_ASC",
  ConfigurationTypeDesc = "CONFIGURATION_TYPE_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  EditorUrlAsc = "EDITOR_URL_ASC",
  EditorUrlDesc = "EDITOR_URL_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsLatestAsc = "IS_LATEST_ASC",
  IsLatestDesc = "IS_LATEST_DESC",
  IsLatestStableAsc = "IS_LATEST_STABLE_ASC",
  IsLatestStableDesc = "IS_LATEST_STABLE_DESC",
  ManifestJsonAsc = "MANIFEST_JSON_ASC",
  ManifestJsonDesc = "MANIFEST_JSON_DESC",
  MediaSandboxAccessAsc = "MEDIA_SANDBOX_ACCESS_ASC",
  MediaSandboxAccessDesc = "MEDIA_SANDBOX_ACCESS_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StableFlagAsc = "STABLE_FLAG_ASC",
  StableFlagDesc = "STABLE_FLAG_DESC",
  StoreDataAsc = "STORE_DATA_ASC",
  StoreDataDesc = "STORE_DATA_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
  ViewerUrlAsc = "VIEWER_URL_ASC",
  ViewerUrlDesc = "VIEWER_URL_DESC",
}

export type AppViewerJwtPayload = {
  __typename: "AppViewerJwtPayload";
  expiresAt: Maybe<Scalars["Int"]>;
  signedAppViewerToken: Maybe<Scalars["String"]>;
  tokenType: Maybe<Scalars["String"]>;
};

export type AppViewerJwtRequestInput = {
  screenId?: InputMaybe<Scalars["String"]>;
  spaceId: Scalars["String"];
};

export enum AppVisibility {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

/** A filter to be used against AppVisibility fields. All fields are combined with a logical ‘and.’ */
export type AppVisibilityFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AppVisibility>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AppVisibility>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AppVisibility>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AppVisibility>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AppVisibility>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AppVisibility>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AppVisibility>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AppVisibility>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AppVisibility>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AppVisibility>>;
};

/** A connection to a list of `App` values. */
export type AppsConnection = {
  __typename: "AppsConnection";
  /** A list of edges which contains the `App` and cursor to aid in pagination. */
  edges: Array<AppsEdge>;
  /** A list of `App` objects. */
  nodes: Array<App>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `App` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `App` edge in the connection. */
export type AppsEdge = {
  __typename: "AppsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `App` at the end of the edge. */
  node: App;
};

/** Methods to use when ordering `App`. */
export enum AppsOrderBy {
  CategoriesAsc = "CATEGORIES_ASC",
  CategoriesDesc = "CATEGORIES_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DefaultInstallAsc = "DEFAULT_INSTALL_ASC",
  DefaultInstallDesc = "DEFAULT_INSTALL_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  DeveloperAsc = "DEVELOPER_ASC",
  DeveloperDesc = "DEVELOPER_DESC",
  IconUrlAsc = "ICON_URL_ASC",
  IconUrlDesc = "ICON_URL_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RequirementsAsc = "REQUIREMENTS_ASC",
  RequirementsDesc = "REQUIREMENTS_DESC",
  ScrnAsc = "SCRN_ASC",
  ScrnDesc = "SCRN_DESC",
  SlugAsc = "SLUG_ASC",
  SlugDesc = "SLUG_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VisibilityAsc = "VISIBILITY_ASC",
  VisibilityDesc = "VISIBILITY_DESC",
}

/** The association details for each relation. */
export type Association = {
  __typename: "Association";
  /** Reads a single `AppInstance` that is related to this `Association`. */
  appInstanceByFromAppInstanceAndOrgId: Maybe<AppInstance>;
  /** Reads a single `AppInstance` that is related to this `Association`. */
  appInstanceByToAppInstanceAndOrgId: Maybe<AppInstance>;
  /** Reads a single `Cast` that is related to this `Association`. */
  castByFromCastAndOrgId: Maybe<Cast>;
  /** Reads a single `Cast` that is related to this `Association`. */
  castByToCastAndOrgId: Maybe<Cast>;
  /** Reads a single `Channel` that is related to this `Association`. */
  channelByFromChannelAndOrgId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Association`. */
  channelByToChannelAndOrgId: Maybe<Channel>;
  /** Reads a single `Credential` that is related to this `Association`. */
  credentialByToCredentialAndOrgId: Maybe<Credential>;
  /** Reads a single `File` that is related to this `Association`. */
  fileByToFileAndOrgId: Maybe<File>;
  fromAppInstance: Maybe<Scalars["UUID"]>;
  fromCast: Maybe<Scalars["UUID"]>;
  /** The uuid of the channel whom reference to the item. */
  fromChannel: Maybe<Scalars["UUID"]>;
  /** The uuid of the playlist whom reference to the item. */
  fromPlaylist: Maybe<Scalars["UUID"]>;
  fromScreen: Maybe<Scalars["UUID"]>;
  /** Reads a single `Link` that is related to this `Association`. */
  linkByToLinkAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Association`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization that player belong to. */
  orgId: Scalars["UUID"];
  /** Reads a single `Playlist` that is related to this `Association`. */
  playlistByFromPlaylistAndOrgId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Association`. */
  playlistByToPlaylistAndOrgId: Maybe<Playlist>;
  /** Reads a single `Screen` that is related to this `Association`. */
  screenByFromScreenAndOrgId: Maybe<Screen>;
  /** Reads a single `Site` that is related to this `Association`. */
  siteByToSiteAndOrgId: Maybe<Site>;
  /** The uuid of app that been referenced. */
  toAppInstance: Maybe<Scalars["UUID"]>;
  toCast: Maybe<Scalars["UUID"]>;
  /** The uuid of channel that been referenced. */
  toChannel: Maybe<Scalars["UUID"]>;
  toCredential: Maybe<Scalars["UUID"]>;
  /** The uuid of file that been referenced. */
  toFile: Maybe<Scalars["UUID"]>;
  /** The uuid of link that is referenced. */
  toLink: Maybe<Scalars["UUID"]>;
  /** The uuid of playlist that been referenced. */
  toPlaylist: Maybe<Scalars["UUID"]>;
  toSite: Maybe<Scalars["UUID"]>;
};

/**
 * A condition to be used against `Association` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AssociationCondition = {
  /** Checks for equality with the object’s `fromAppInstance` field. */
  fromAppInstance?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `fromCast` field. */
  fromCast?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `fromChannel` field. */
  fromChannel?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `fromPlaylist` field. */
  fromPlaylist?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `fromScreen` field. */
  fromScreen?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toAppInstance` field. */
  toAppInstance?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toCast` field. */
  toCast?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toChannel` field. */
  toChannel?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toCredential` field. */
  toCredential?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toFile` field. */
  toFile?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toLink` field. */
  toLink?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toPlaylist` field. */
  toPlaylist?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toSite` field. */
  toSite?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Association` object types. All fields are combined with a logical ‘and.’ */
export type AssociationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AssociationFilter>>;
  /** Filter by the object’s `fromAppInstance` field. */
  fromAppInstance?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fromCast` field. */
  fromCast?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fromChannel` field. */
  fromChannel?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fromPlaylist` field. */
  fromPlaylist?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fromScreen` field. */
  fromScreen?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AssociationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AssociationFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toAppInstance` field. */
  toAppInstance?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toCast` field. */
  toCast?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toChannel` field. */
  toChannel?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toCredential` field. */
  toCredential?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toFile` field. */
  toFile?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toLink` field. */
  toLink?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toPlaylist` field. */
  toPlaylist?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toSite` field. */
  toSite?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Association` values. */
export type AssociationsConnection = {
  __typename: "AssociationsConnection";
  /** A list of edges which contains the `Association` and cursor to aid in pagination. */
  edges: Array<AssociationsEdge>;
  /** A list of `Association` objects. */
  nodes: Array<Association>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Association` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Association` edge in the connection. */
export type AssociationsEdge = {
  __typename: "AssociationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Association` at the end of the edge. */
  node: Association;
};

/** Methods to use when ordering `Association`. */
export enum AssociationsOrderBy {
  FromAppInstanceAsc = "FROM_APP_INSTANCE_ASC",
  FromAppInstanceDesc = "FROM_APP_INSTANCE_DESC",
  FromCastAsc = "FROM_CAST_ASC",
  FromCastDesc = "FROM_CAST_DESC",
  FromChannelAsc = "FROM_CHANNEL_ASC",
  FromChannelDesc = "FROM_CHANNEL_DESC",
  FromPlaylistAsc = "FROM_PLAYLIST_ASC",
  FromPlaylistDesc = "FROM_PLAYLIST_DESC",
  FromScreenAsc = "FROM_SCREEN_ASC",
  FromScreenDesc = "FROM_SCREEN_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  ToAppInstanceAsc = "TO_APP_INSTANCE_ASC",
  ToAppInstanceDesc = "TO_APP_INSTANCE_DESC",
  ToCastAsc = "TO_CAST_ASC",
  ToCastDesc = "TO_CAST_DESC",
  ToChannelAsc = "TO_CHANNEL_ASC",
  ToChannelDesc = "TO_CHANNEL_DESC",
  ToCredentialAsc = "TO_CREDENTIAL_ASC",
  ToCredentialDesc = "TO_CREDENTIAL_DESC",
  ToFileAsc = "TO_FILE_ASC",
  ToFileDesc = "TO_FILE_DESC",
  ToLinkAsc = "TO_LINK_ASC",
  ToLinkDesc = "TO_LINK_DESC",
  ToPlaylistAsc = "TO_PLAYLIST_ASC",
  ToPlaylistDesc = "TO_PLAYLIST_DESC",
  ToSiteAsc = "TO_SITE_ASC",
  ToSiteDesc = "TO_SITE_DESC",
}

/** All input for the `authAcceptPendingUserInvite` mutation. */
export type AuthAcceptPendingUserInviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  connection?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  orgId?: InputMaybe<Scalars["UUID"]>;
  picture?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  strategy?: InputMaybe<Scalars["String"]>;
};

/** The output of our `authAcceptPendingUserInvite` mutation. */
export type AuthAcceptPendingUserInvitePayload = {
  __typename: "AuthAcceptPendingUserInvitePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  publicOrgByPendingUserType: Maybe<PublicOrgByPendingUserType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigFloat"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigFloat"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigFloat"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigFloat"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigFloat"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigFloat"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigFloat"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigFloat"]>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigInt"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigInt"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigInt"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigInt"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigInt"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigInt"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigInt"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigInt"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigInt"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export type BillingAddon = Node & {
  __typename: "BillingAddon";
  /** Reads and enables pagination through a set of `BillingAddonPlan`. */
  billingAddonPlansByBillingAddonId: BillingAddonPlansConnection;
  /** Reads and enables pagination through a set of `BillingAddonSubscriptionFuture`. */
  billingAddonSubscriptionFuturesByBillingAddonId: BillingAddonSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingAddonSubscription`. */
  billingAddonSubscriptionsByBillingAddonId: BillingAddonSubscriptionsConnection;
  chargeType: Maybe<Scalars["String"]>;
  currencyCode: Maybe<CurrencyCode>;
  id: Scalars["String"];
  metaData: Maybe<Scalars["JSON"]>;
  name: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  period: Maybe<Scalars["Int"]>;
  periodUnit: Maybe<Scalars["String"]>;
  price: Maybe<Scalars["Int"]>;
  raw: Maybe<Scalars["JSON"]>;
  status: Maybe<Scalars["String"]>;
};

export type BillingAddonBillingAddonPlansByBillingAddonIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonPlanCondition>;
  filter: InputMaybe<BillingAddonPlanFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonPlansOrderBy>>;
};

export type BillingAddonBillingAddonSubscriptionFuturesByBillingAddonIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionFutureCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionFuturesOrderBy>>;
};

export type BillingAddonBillingAddonSubscriptionsByBillingAddonIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionsOrderBy>>;
};

/**
 * A condition to be used against `BillingAddon` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingAddonCondition = {
  /** Checks for equality with the object’s `chargeType` field. */
  chargeType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `metaData` field. */
  metaData?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `period` field. */
  period?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `periodUnit` field. */
  periodUnit?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `BillingAddon` object types. All fields are combined with a logical ‘and.’ */
export type BillingAddonFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingAddonFilter>>;
  /** Filter by the object’s `chargeType` field. */
  chargeType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metaData` field. */
  metaData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingAddonFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingAddonFilter>>;
  /** Filter by the object’s `period` field. */
  period?: InputMaybe<IntFilter>;
  /** Filter by the object’s `periodUnit` field. */
  periodUnit?: InputMaybe<StringFilter>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<IntFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
};

export type BillingAddonPlan = Node & {
  __typename: "BillingAddonPlan";
  /** Reads a single `BillingAddon` that is related to this `BillingAddonPlan`. */
  billingAddonByBillingAddonId: Maybe<BillingAddon>;
  billingAddonId: Scalars["String"];
  /** Reads a single `BillingPlan` that is related to this `BillingAddonPlan`. */
  billingPlanByBillingPlanId: Maybe<BillingPlan>;
  billingPlanId: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  raw: Maybe<Scalars["JSON"]>;
};

/**
 * A condition to be used against `BillingAddonPlan` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingAddonPlanCondition = {
  /** Checks for equality with the object’s `billingAddonId` field. */
  billingAddonId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingPlanId` field. */
  billingPlanId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
};

/** A filter to be used against `BillingAddonPlan` object types. All fields are combined with a logical ‘and.’ */
export type BillingAddonPlanFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingAddonPlanFilter>>;
  /** Filter by the object’s `billingAddonId` field. */
  billingAddonId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingPlanId` field. */
  billingPlanId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingAddonPlanFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingAddonPlanFilter>>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
};

/** A connection to a list of `BillingAddonPlan` values. */
export type BillingAddonPlansConnection = {
  __typename: "BillingAddonPlansConnection";
  /** A list of edges which contains the `BillingAddonPlan` and cursor to aid in pagination. */
  edges: Array<BillingAddonPlansEdge>;
  /** A list of `BillingAddonPlan` objects. */
  nodes: Array<BillingAddonPlan>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingAddonPlan` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingAddonPlan` edge in the connection. */
export type BillingAddonPlansEdge = {
  __typename: "BillingAddonPlansEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingAddonPlan` at the end of the edge. */
  node: BillingAddonPlan;
};

/** Methods to use when ordering `BillingAddonPlan`. */
export enum BillingAddonPlansOrderBy {
  BillingAddonIdAsc = "BILLING_ADDON_ID_ASC",
  BillingAddonIdDesc = "BILLING_ADDON_ID_DESC",
  BillingPlanIdAsc = "BILLING_PLAN_ID_ASC",
  BillingPlanIdDesc = "BILLING_PLAN_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
}

export type BillingAddonSubscription = Node & {
  __typename: "BillingAddonSubscription";
  /** Reads a single `BillingAddon` that is related to this `BillingAddonSubscription`. */
  billingAddonByBillingAddonId: Maybe<BillingAddon>;
  billingAddonId: Scalars["String"];
  /** Reads a single `BillingCustomer` that is related to this `BillingAddonSubscription`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads a single `BillingSubscription` that is related to this `BillingAddonSubscription`. */
  billingSubscriptionByBillingSubscriptionId: Maybe<BillingSubscription>;
  billingSubscriptionId: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingAddonSubscription`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  raw: Maybe<Scalars["JSON"]>;
  unitPrice: Maybe<Scalars["Int"]>;
};

/**
 * A condition to be used against `BillingAddonSubscription` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BillingAddonSubscriptionCondition = {
  /** Checks for equality with the object’s `billingAddonId` field. */
  billingAddonId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `unitPrice` field. */
  unitPrice?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `BillingAddonSubscription` object types. All fields are combined with a logical ‘and.’ */
export type BillingAddonSubscriptionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingAddonSubscriptionFilter>>;
  /** Filter by the object’s `billingAddonId` field. */
  billingAddonId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingAddonSubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingAddonSubscriptionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `unitPrice` field. */
  unitPrice?: InputMaybe<IntFilter>;
};

export type BillingAddonSubscriptionFuture = Node & {
  __typename: "BillingAddonSubscriptionFuture";
  /** Reads a single `BillingAddon` that is related to this `BillingAddonSubscriptionFuture`. */
  billingAddonByBillingAddonId: Maybe<BillingAddon>;
  billingAddonId: Scalars["String"];
  /** Reads a single `BillingCustomer` that is related to this `BillingAddonSubscriptionFuture`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads a single `BillingSubscriptionFuture` that is related to this `BillingAddonSubscriptionFuture`. */
  billingSubscriptionFutureByBillingSubscriptionId: Maybe<BillingSubscriptionFuture>;
  billingSubscriptionId: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingAddonSubscriptionFuture`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  raw: Maybe<Scalars["JSON"]>;
  unitPrice: Maybe<Scalars["Int"]>;
};

/**
 * A condition to be used against `BillingAddonSubscriptionFuture` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type BillingAddonSubscriptionFutureCondition = {
  /** Checks for equality with the object’s `billingAddonId` field. */
  billingAddonId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `unitPrice` field. */
  unitPrice?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `BillingAddonSubscriptionFuture` object types. All fields are combined with a logical ‘and.’ */
export type BillingAddonSubscriptionFutureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingAddonSubscriptionFutureFilter>>;
  /** Filter by the object’s `billingAddonId` field. */
  billingAddonId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingAddonSubscriptionFutureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingAddonSubscriptionFutureFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `unitPrice` field. */
  unitPrice?: InputMaybe<IntFilter>;
};

/** A connection to a list of `BillingAddonSubscriptionFuture` values. */
export type BillingAddonSubscriptionFuturesConnection = {
  __typename: "BillingAddonSubscriptionFuturesConnection";
  /** A list of edges which contains the `BillingAddonSubscriptionFuture` and cursor to aid in pagination. */
  edges: Array<BillingAddonSubscriptionFuturesEdge>;
  /** A list of `BillingAddonSubscriptionFuture` objects. */
  nodes: Array<BillingAddonSubscriptionFuture>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingAddonSubscriptionFuture` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingAddonSubscriptionFuture` edge in the connection. */
export type BillingAddonSubscriptionFuturesEdge = {
  __typename: "BillingAddonSubscriptionFuturesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingAddonSubscriptionFuture` at the end of the edge. */
  node: BillingAddonSubscriptionFuture;
};

/** Methods to use when ordering `BillingAddonSubscriptionFuture`. */
export enum BillingAddonSubscriptionFuturesOrderBy {
  BillingAddonIdAsc = "BILLING_ADDON_ID_ASC",
  BillingAddonIdDesc = "BILLING_ADDON_ID_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingSubscriptionIdAsc = "BILLING_SUBSCRIPTION_ID_ASC",
  BillingSubscriptionIdDesc = "BILLING_SUBSCRIPTION_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  UnitPriceAsc = "UNIT_PRICE_ASC",
  UnitPriceDesc = "UNIT_PRICE_DESC",
}

/** A connection to a list of `BillingAddonSubscription` values. */
export type BillingAddonSubscriptionsConnection = {
  __typename: "BillingAddonSubscriptionsConnection";
  /** A list of edges which contains the `BillingAddonSubscription` and cursor to aid in pagination. */
  edges: Array<BillingAddonSubscriptionsEdge>;
  /** A list of `BillingAddonSubscription` objects. */
  nodes: Array<BillingAddonSubscription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingAddonSubscription` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingAddonSubscription` edge in the connection. */
export type BillingAddonSubscriptionsEdge = {
  __typename: "BillingAddonSubscriptionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingAddonSubscription` at the end of the edge. */
  node: BillingAddonSubscription;
};

/** Methods to use when ordering `BillingAddonSubscription`. */
export enum BillingAddonSubscriptionsOrderBy {
  BillingAddonIdAsc = "BILLING_ADDON_ID_ASC",
  BillingAddonIdDesc = "BILLING_ADDON_ID_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingSubscriptionIdAsc = "BILLING_SUBSCRIPTION_ID_ASC",
  BillingSubscriptionIdDesc = "BILLING_SUBSCRIPTION_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  UnitPriceAsc = "UNIT_PRICE_ASC",
  UnitPriceDesc = "UNIT_PRICE_DESC",
}

/** A connection to a list of `BillingAddon` values. */
export type BillingAddonsConnection = {
  __typename: "BillingAddonsConnection";
  /** A list of edges which contains the `BillingAddon` and cursor to aid in pagination. */
  edges: Array<BillingAddonsEdge>;
  /** A list of `BillingAddon` objects. */
  nodes: Array<BillingAddon>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingAddon` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingAddon` edge in the connection. */
export type BillingAddonsEdge = {
  __typename: "BillingAddonsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingAddon` at the end of the edge. */
  node: BillingAddon;
};

/** Methods to use when ordering `BillingAddon`. */
export enum BillingAddonsOrderBy {
  ChargeTypeAsc = "CHARGE_TYPE_ASC",
  ChargeTypeDesc = "CHARGE_TYPE_DESC",
  CurrencyCodeAsc = "CURRENCY_CODE_ASC",
  CurrencyCodeDesc = "CURRENCY_CODE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetaDataAsc = "META_DATA_ASC",
  MetaDataDesc = "META_DATA_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PeriodAsc = "PERIOD_ASC",
  PeriodDesc = "PERIOD_DESC",
  PeriodUnitAsc = "PERIOD_UNIT_ASC",
  PeriodUnitDesc = "PERIOD_UNIT_DESC",
  PriceAsc = "PRICE_ASC",
  PriceDesc = "PRICE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
}

export type BillingAddress = Node & {
  __typename: "BillingAddress";
  /** Reads a single `BillingCustomer` that is related to this `BillingAddress`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  city: Maybe<Scalars["String"]>;
  company: Maybe<Scalars["String"]>;
  country: Maybe<CountryCode>;
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  line1: Maybe<Scalars["String"]>;
  line2: Maybe<Scalars["String"]>;
  line3: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingAddress`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  phone: Maybe<Scalars["String"]>;
  raw: Maybe<Scalars["JSON"]>;
  state: Maybe<Scalars["String"]>;
  stateCode: Maybe<Scalars["String"]>;
  validationStatus: Maybe<Scalars["String"]>;
  zip: Maybe<Scalars["String"]>;
};

/**
 * A condition to be used against `BillingAddress` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingAddressCondition = {
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `company` field. */
  company?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<CountryCode>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line1` field. */
  line1?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line2` field. */
  line2?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line3` field. */
  line3?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `stateCode` field. */
  stateCode?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `validationStatus` field. */
  validationStatus?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `zip` field. */
  zip?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `BillingAddress` object types. All fields are combined with a logical ‘and.’ */
export type BillingAddressFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingAddressFilter>>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `company` field. */
  company?: InputMaybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<CountryCodeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line1` field. */
  line1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line2` field. */
  line2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line3` field. */
  line3?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingAddressFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingAddressFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `phone` field. */
  phone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stateCode` field. */
  stateCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validationStatus` field. */
  validationStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `zip` field. */
  zip?: InputMaybe<StringFilter>;
};

/** A connection to a list of `BillingAddress` values. */
export type BillingAddressesConnection = {
  __typename: "BillingAddressesConnection";
  /** A list of edges which contains the `BillingAddress` and cursor to aid in pagination. */
  edges: Array<BillingAddressesEdge>;
  /** A list of `BillingAddress` objects. */
  nodes: Array<BillingAddress>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingAddress` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingAddress` edge in the connection. */
export type BillingAddressesEdge = {
  __typename: "BillingAddressesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingAddress` at the end of the edge. */
  node: BillingAddress;
};

/** Methods to use when ordering `BillingAddress`. */
export enum BillingAddressesOrderBy {
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  CityAsc = "CITY_ASC",
  CityDesc = "CITY_DESC",
  CompanyAsc = "COMPANY_ASC",
  CompanyDesc = "COMPANY_DESC",
  CountryAsc = "COUNTRY_ASC",
  CountryDesc = "COUNTRY_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FirstNameAsc = "FIRST_NAME_ASC",
  FirstNameDesc = "FIRST_NAME_DESC",
  LastNameAsc = "LAST_NAME_ASC",
  LastNameDesc = "LAST_NAME_DESC",
  Line1Asc = "LINE1_ASC",
  Line1Desc = "LINE1_DESC",
  Line2Asc = "LINE2_ASC",
  Line2Desc = "LINE2_DESC",
  Line3Asc = "LINE3_ASC",
  Line3Desc = "LINE3_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PhoneAsc = "PHONE_ASC",
  PhoneDesc = "PHONE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  StateAsc = "STATE_ASC",
  StateCodeAsc = "STATE_CODE_ASC",
  StateCodeDesc = "STATE_CODE_DESC",
  StateDesc = "STATE_DESC",
  ValidationStatusAsc = "VALIDATION_STATUS_ASC",
  ValidationStatusDesc = "VALIDATION_STATUS_DESC",
  ZipAsc = "ZIP_ASC",
  ZipDesc = "ZIP_DESC",
}

export type BillingCoupon = Node & {
  __typename: "BillingCoupon";
  /** Reads and enables pagination through a set of `BillingCouponSubscriptionFuture`. */
  billingCouponSubscriptionFuturesByBillingCouponId: BillingCouponSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingCouponSubscription`. */
  billingCouponSubscriptionsByBillingCouponId: BillingCouponSubscriptionsConnection;
  currencyCode: Maybe<CurrencyCode>;
  discountAmount: Maybe<Scalars["Int"]>;
  discountPercentage: Maybe<Scalars["BigFloat"]>;
  discountType: Maybe<Scalars["String"]>;
  durationMonth: Maybe<Scalars["Int"]>;
  durationType: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  raw: Maybe<Scalars["JSON"]>;
  status: Maybe<Scalars["String"]>;
  validTill: Maybe<Scalars["Int"]>;
};

export type BillingCouponBillingCouponSubscriptionFuturesByBillingCouponIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionFutureCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionFuturesOrderBy>>;
};

export type BillingCouponBillingCouponSubscriptionsByBillingCouponIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionsOrderBy>>;
};

/**
 * A condition to be used against `BillingCoupon` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingCouponCondition = {
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `discountAmount` field. */
  discountAmount?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `discountPercentage` field. */
  discountPercentage?: InputMaybe<Scalars["BigFloat"]>;
  /** Checks for equality with the object’s `discountType` field. */
  discountType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `durationMonth` field. */
  durationMonth?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `durationType` field. */
  durationType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `validTill` field. */
  validTill?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `BillingCoupon` object types. All fields are combined with a logical ‘and.’ */
export type BillingCouponFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingCouponFilter>>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `discountAmount` field. */
  discountAmount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `discountPercentage` field. */
  discountPercentage?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `discountType` field. */
  discountType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `durationMonth` field. */
  durationMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `durationType` field. */
  durationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingCouponFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingCouponFilter>>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validTill` field. */
  validTill?: InputMaybe<IntFilter>;
};

export type BillingCouponSubscription = Node & {
  __typename: "BillingCouponSubscription";
  /** Reads a single `BillingCoupon` that is related to this `BillingCouponSubscription`. */
  billingCouponByBillingCouponId: Maybe<BillingCoupon>;
  billingCouponId: Scalars["String"];
  /** Reads a single `BillingCustomer` that is related to this `BillingCouponSubscription`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads a single `BillingSubscription` that is related to this `BillingCouponSubscription`. */
  billingSubscriptionByBillingSubscriptionId: Maybe<BillingSubscription>;
  billingSubscriptionId: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingCouponSubscription`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  raw: Maybe<Scalars["JSON"]>;
};

/**
 * A condition to be used against `BillingCouponSubscription` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BillingCouponSubscriptionCondition = {
  /** Checks for equality with the object’s `billingCouponId` field. */
  billingCouponId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
};

/** A filter to be used against `BillingCouponSubscription` object types. All fields are combined with a logical ‘and.’ */
export type BillingCouponSubscriptionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingCouponSubscriptionFilter>>;
  /** Filter by the object’s `billingCouponId` field. */
  billingCouponId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingCouponSubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingCouponSubscriptionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
};

export type BillingCouponSubscriptionFuture = Node & {
  __typename: "BillingCouponSubscriptionFuture";
  /** Reads a single `BillingCoupon` that is related to this `BillingCouponSubscriptionFuture`. */
  billingCouponByBillingCouponId: Maybe<BillingCoupon>;
  billingCouponId: Scalars["String"];
  /** Reads a single `BillingCustomer` that is related to this `BillingCouponSubscriptionFuture`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads a single `BillingSubscriptionFuture` that is related to this `BillingCouponSubscriptionFuture`. */
  billingSubscriptionFutureByBillingSubscriptionId: Maybe<BillingSubscriptionFuture>;
  billingSubscriptionId: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingCouponSubscriptionFuture`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  raw: Maybe<Scalars["JSON"]>;
};

/**
 * A condition to be used against `BillingCouponSubscriptionFuture` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type BillingCouponSubscriptionFutureCondition = {
  /** Checks for equality with the object’s `billingCouponId` field. */
  billingCouponId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
};

/** A filter to be used against `BillingCouponSubscriptionFuture` object types. All fields are combined with a logical ‘and.’ */
export type BillingCouponSubscriptionFutureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingCouponSubscriptionFutureFilter>>;
  /** Filter by the object’s `billingCouponId` field. */
  billingCouponId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingCouponSubscriptionFutureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingCouponSubscriptionFutureFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
};

/** A connection to a list of `BillingCouponSubscriptionFuture` values. */
export type BillingCouponSubscriptionFuturesConnection = {
  __typename: "BillingCouponSubscriptionFuturesConnection";
  /** A list of edges which contains the `BillingCouponSubscriptionFuture` and cursor to aid in pagination. */
  edges: Array<BillingCouponSubscriptionFuturesEdge>;
  /** A list of `BillingCouponSubscriptionFuture` objects. */
  nodes: Array<BillingCouponSubscriptionFuture>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingCouponSubscriptionFuture` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingCouponSubscriptionFuture` edge in the connection. */
export type BillingCouponSubscriptionFuturesEdge = {
  __typename: "BillingCouponSubscriptionFuturesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingCouponSubscriptionFuture` at the end of the edge. */
  node: BillingCouponSubscriptionFuture;
};

/** Methods to use when ordering `BillingCouponSubscriptionFuture`. */
export enum BillingCouponSubscriptionFuturesOrderBy {
  BillingCouponIdAsc = "BILLING_COUPON_ID_ASC",
  BillingCouponIdDesc = "BILLING_COUPON_ID_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingSubscriptionIdAsc = "BILLING_SUBSCRIPTION_ID_ASC",
  BillingSubscriptionIdDesc = "BILLING_SUBSCRIPTION_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
}

/** A connection to a list of `BillingCouponSubscription` values. */
export type BillingCouponSubscriptionsConnection = {
  __typename: "BillingCouponSubscriptionsConnection";
  /** A list of edges which contains the `BillingCouponSubscription` and cursor to aid in pagination. */
  edges: Array<BillingCouponSubscriptionsEdge>;
  /** A list of `BillingCouponSubscription` objects. */
  nodes: Array<BillingCouponSubscription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingCouponSubscription` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingCouponSubscription` edge in the connection. */
export type BillingCouponSubscriptionsEdge = {
  __typename: "BillingCouponSubscriptionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingCouponSubscription` at the end of the edge. */
  node: BillingCouponSubscription;
};

/** Methods to use when ordering `BillingCouponSubscription`. */
export enum BillingCouponSubscriptionsOrderBy {
  BillingCouponIdAsc = "BILLING_COUPON_ID_ASC",
  BillingCouponIdDesc = "BILLING_COUPON_ID_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingSubscriptionIdAsc = "BILLING_SUBSCRIPTION_ID_ASC",
  BillingSubscriptionIdDesc = "BILLING_SUBSCRIPTION_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
}

/** A connection to a list of `BillingCoupon` values. */
export type BillingCouponsConnection = {
  __typename: "BillingCouponsConnection";
  /** A list of edges which contains the `BillingCoupon` and cursor to aid in pagination. */
  edges: Array<BillingCouponsEdge>;
  /** A list of `BillingCoupon` objects. */
  nodes: Array<BillingCoupon>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingCoupon` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingCoupon` edge in the connection. */
export type BillingCouponsEdge = {
  __typename: "BillingCouponsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingCoupon` at the end of the edge. */
  node: BillingCoupon;
};

/** Methods to use when ordering `BillingCoupon`. */
export enum BillingCouponsOrderBy {
  CurrencyCodeAsc = "CURRENCY_CODE_ASC",
  CurrencyCodeDesc = "CURRENCY_CODE_DESC",
  DiscountAmountAsc = "DISCOUNT_AMOUNT_ASC",
  DiscountAmountDesc = "DISCOUNT_AMOUNT_DESC",
  DiscountPercentageAsc = "DISCOUNT_PERCENTAGE_ASC",
  DiscountPercentageDesc = "DISCOUNT_PERCENTAGE_DESC",
  DiscountTypeAsc = "DISCOUNT_TYPE_ASC",
  DiscountTypeDesc = "DISCOUNT_TYPE_DESC",
  DurationMonthAsc = "DURATION_MONTH_ASC",
  DurationMonthDesc = "DURATION_MONTH_DESC",
  DurationTypeAsc = "DURATION_TYPE_ASC",
  DurationTypeDesc = "DURATION_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  ValidTillAsc = "VALID_TILL_ASC",
  ValidTillDesc = "VALID_TILL_DESC",
}

export type BillingCustomer = Node & {
  __typename: "BillingCustomer";
  allowDirectDebit: Maybe<Scalars["Boolean"]>;
  autoCollection: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `BillingAddonSubscriptionFuture`. */
  billingAddonSubscriptionFuturesByBillingCustomerId: BillingAddonSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingAddonSubscription`. */
  billingAddonSubscriptionsByBillingCustomerId: BillingAddonSubscriptionsConnection;
  /** Reads a single `BillingAddress` that is related to this `BillingCustomer`. */
  billingAddressByBillingCustomerId: Maybe<BillingAddress>;
  /** Reads and enables pagination through a set of `BillingCouponSubscriptionFuture`. */
  billingCouponSubscriptionFuturesByBillingCustomerId: BillingCouponSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingCouponSubscription`. */
  billingCouponSubscriptionsByBillingCustomerId: BillingCouponSubscriptionsConnection;
  billingDate: Maybe<Scalars["Int"]>;
  billingDateMode: Maybe<Scalars["String"]>;
  billingDayOfWeek: Maybe<Scalars["String"]>;
  billingDayOfWeekMode: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `BillingHierarchy`. */
  billingHierarchiesByChildId: BillingHierarchiesConnection;
  /** Reads and enables pagination through a set of `BillingHierarchy`. */
  billingHierarchiesByInvoiceeId: BillingHierarchiesConnection;
  /** Reads and enables pagination through a set of `BillingHierarchy`. */
  billingHierarchiesByParentId: BillingHierarchiesConnection;
  /** Reads and enables pagination through a set of `BillingHierarchy`. */
  billingHierarchiesByPayeeId: BillingHierarchiesConnection;
  /** Reads and enables pagination through a set of `BillingInvoice`. */
  billingInvoicesByBillingCustomerId: BillingInvoicesConnection;
  /** Reads and enables pagination through a set of `BillingPaymentSource`. */
  billingPaymentSourcesByBillingCustomerId: BillingPaymentSourcesConnection;
  /** Reads and enables pagination through a set of `BillingRenewalFuture`. */
  billingRenewalFuturesByBillingCustomerId: BillingRenewalFuturesConnection;
  /** Reads and enables pagination through a set of `BillingRenewal`. */
  billingRenewalsByBillingCustomerId: BillingRenewalsConnection;
  /** Reads a single `BillingReseller` that is related to this `BillingCustomer`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  billingResellerId: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `BillingSubscriptionFuture`. */
  billingSubscriptionFuturesByBillingCustomerId: BillingSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingSubscription`. */
  billingSubscriptionsByBillingCustomerId: BillingSubscriptionsConnection;
  cfAccountManager: Maybe<Scalars["String"]>;
  cfAllowLicence: Maybe<Scalars["Boolean"]>;
  cfCustomerType: Maybe<Scalars["String"]>;
  cfInvoicedPayment: Maybe<Scalars["Boolean"]>;
  cfPartner: Maybe<Scalars["String"]>;
  cfPrimary: Maybe<Scalars["String"]>;
  company: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  deleted: Maybe<Scalars["Boolean"]>;
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  invoiceOwnerId: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  metaData: Maybe<Scalars["JSON"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingCustomer`. */
  orgByBillingCustomerId: Maybe<Org>;
  /** Reads a single `Org` that is related to this `BillingCustomer`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  parentId: Maybe<Scalars["String"]>;
  paymentOwnerId: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  preferredCurrencyCode: Maybe<CurrencyCode>;
  provider: Maybe<Scalars["String"]>;
  raw: Maybe<Scalars["JSON"]>;
  /** Reads and enables pagination through a set of `Space`. */
  spacesByBillingCustomerId: SpacesConnection;
  taxability: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  vatNumber: Maybe<Scalars["String"]>;
  vatNumberStatus: Maybe<Scalars["String"]>;
};

export type BillingCustomerBillingAddonSubscriptionFuturesByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionFutureCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionFuturesOrderBy>>;
};

export type BillingCustomerBillingAddonSubscriptionsByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionsOrderBy>>;
};

export type BillingCustomerBillingCouponSubscriptionFuturesByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionFutureCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionFuturesOrderBy>>;
};

export type BillingCustomerBillingCouponSubscriptionsByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionsOrderBy>>;
};

export type BillingCustomerBillingHierarchiesByChildIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingHierarchyCondition>;
  filter: InputMaybe<BillingHierarchyFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingHierarchiesOrderBy>>;
};

export type BillingCustomerBillingHierarchiesByInvoiceeIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingHierarchyCondition>;
  filter: InputMaybe<BillingHierarchyFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingHierarchiesOrderBy>>;
};

export type BillingCustomerBillingHierarchiesByParentIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingHierarchyCondition>;
  filter: InputMaybe<BillingHierarchyFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingHierarchiesOrderBy>>;
};

export type BillingCustomerBillingHierarchiesByPayeeIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingHierarchyCondition>;
  filter: InputMaybe<BillingHierarchyFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingHierarchiesOrderBy>>;
};

export type BillingCustomerBillingInvoicesByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingInvoiceCondition>;
  filter: InputMaybe<BillingInvoiceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingInvoicesOrderBy>>;
};

export type BillingCustomerBillingPaymentSourcesByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingPaymentSourceCondition>;
  filter: InputMaybe<BillingPaymentSourceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingPaymentSourcesOrderBy>>;
};

export type BillingCustomerBillingRenewalFuturesByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingRenewalFutureCondition>;
  filter: InputMaybe<BillingRenewalFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingRenewalFuturesOrderBy>>;
};

export type BillingCustomerBillingRenewalsByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingRenewalCondition>;
  filter: InputMaybe<BillingRenewalFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingRenewalsOrderBy>>;
};

export type BillingCustomerBillingSubscriptionFuturesByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingSubscriptionFutureCondition>;
  filter: InputMaybe<BillingSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingSubscriptionFuturesOrderBy>>;
};

export type BillingCustomerBillingSubscriptionsByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingSubscriptionCondition>;
  filter: InputMaybe<BillingSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingSubscriptionsOrderBy>>;
};

export type BillingCustomerSpacesByBillingCustomerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SpaceCondition>;
  filter: InputMaybe<SpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

/**
 * A condition to be used against `BillingCustomer` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingCustomerCondition = {
  /** Checks for equality with the object’s `allowDirectDebit` field. */
  allowDirectDebit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `autoCollection` field. */
  autoCollection?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingDate` field. */
  billingDate?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `billingDateMode` field. */
  billingDateMode?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingDayOfWeek` field. */
  billingDayOfWeek?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingDayOfWeekMode` field. */
  billingDayOfWeekMode?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingResellerId` field. */
  billingResellerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfAccountManager` field. */
  cfAccountManager?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfAllowLicence` field. */
  cfAllowLicence?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `cfCustomerType` field. */
  cfCustomerType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfInvoicedPayment` field. */
  cfInvoicedPayment?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `cfPartner` field. */
  cfPartner?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `company` field. */
  company?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `invoiceOwnerId` field. */
  invoiceOwnerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `metaData` field. */
  metaData?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `paymentOwnerId` field. */
  paymentOwnerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `preferredCurrencyCode` field. */
  preferredCurrencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `taxability` field. */
  taxability?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `vatNumber` field. */
  vatNumber?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `vatNumberStatus` field. */
  vatNumberStatus?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `BillingCustomer` object types. All fields are combined with a logical ‘and.’ */
export type BillingCustomerFilter = {
  /** Filter by the object’s `allowDirectDebit` field. */
  allowDirectDebit?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingCustomerFilter>>;
  /** Filter by the object’s `autoCollection` field. */
  autoCollection?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingDate` field. */
  billingDate?: InputMaybe<IntFilter>;
  /** Filter by the object’s `billingDateMode` field. */
  billingDateMode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingDayOfWeek` field. */
  billingDayOfWeek?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingDayOfWeekMode` field. */
  billingDayOfWeekMode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingResellerId` field. */
  billingResellerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfAccountManager` field. */
  cfAccountManager?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfAllowLicence` field. */
  cfAllowLicence?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `cfCustomerType` field. */
  cfCustomerType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfInvoicedPayment` field. */
  cfInvoicedPayment?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `cfPartner` field. */
  cfPartner?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `company` field. */
  company?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `invoiceOwnerId` field. */
  invoiceOwnerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metaData` field. */
  metaData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingCustomerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingCustomerFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `paymentOwnerId` field. */
  paymentOwnerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  phone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `preferredCurrencyCode` field. */
  preferredCurrencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `taxability` field. */
  taxability?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `vatNumber` field. */
  vatNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `vatNumberStatus` field. */
  vatNumberStatus?: InputMaybe<StringFilter>;
};

/** A connection to a list of `BillingCustomer` values. */
export type BillingCustomersConnection = {
  __typename: "BillingCustomersConnection";
  /** A list of edges which contains the `BillingCustomer` and cursor to aid in pagination. */
  edges: Array<BillingCustomersEdge>;
  /** A list of `BillingCustomer` objects. */
  nodes: Array<BillingCustomer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingCustomer` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingCustomer` edge in the connection. */
export type BillingCustomersEdge = {
  __typename: "BillingCustomersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingCustomer` at the end of the edge. */
  node: BillingCustomer;
};

/** Methods to use when ordering `BillingCustomer`. */
export enum BillingCustomersOrderBy {
  AllowDirectDebitAsc = "ALLOW_DIRECT_DEBIT_ASC",
  AllowDirectDebitDesc = "ALLOW_DIRECT_DEBIT_DESC",
  AutoCollectionAsc = "AUTO_COLLECTION_ASC",
  AutoCollectionDesc = "AUTO_COLLECTION_DESC",
  BillingDateAsc = "BILLING_DATE_ASC",
  BillingDateDesc = "BILLING_DATE_DESC",
  BillingDateModeAsc = "BILLING_DATE_MODE_ASC",
  BillingDateModeDesc = "BILLING_DATE_MODE_DESC",
  BillingDayOfWeekAsc = "BILLING_DAY_OF_WEEK_ASC",
  BillingDayOfWeekDesc = "BILLING_DAY_OF_WEEK_DESC",
  BillingDayOfWeekModeAsc = "BILLING_DAY_OF_WEEK_MODE_ASC",
  BillingDayOfWeekModeDesc = "BILLING_DAY_OF_WEEK_MODE_DESC",
  BillingResellerIdAsc = "BILLING_RESELLER_ID_ASC",
  BillingResellerIdDesc = "BILLING_RESELLER_ID_DESC",
  CfAccountManagerAsc = "CF_ACCOUNT_MANAGER_ASC",
  CfAccountManagerDesc = "CF_ACCOUNT_MANAGER_DESC",
  CfAllowLicenceAsc = "CF_ALLOW_LICENCE_ASC",
  CfAllowLicenceDesc = "CF_ALLOW_LICENCE_DESC",
  CfCustomerTypeAsc = "CF_CUSTOMER_TYPE_ASC",
  CfCustomerTypeDesc = "CF_CUSTOMER_TYPE_DESC",
  CfInvoicedPaymentAsc = "CF_INVOICED_PAYMENT_ASC",
  CfInvoicedPaymentDesc = "CF_INVOICED_PAYMENT_DESC",
  CfPartnerAsc = "CF_PARTNER_ASC",
  CfPartnerDesc = "CF_PARTNER_DESC",
  CfPrimaryAsc = "CF_PRIMARY_ASC",
  CfPrimaryDesc = "CF_PRIMARY_DESC",
  CompanyAsc = "COMPANY_ASC",
  CompanyDesc = "COMPANY_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FirstNameAsc = "FIRST_NAME_ASC",
  FirstNameDesc = "FIRST_NAME_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InvoiceOwnerIdAsc = "INVOICE_OWNER_ID_ASC",
  InvoiceOwnerIdDesc = "INVOICE_OWNER_ID_DESC",
  LastNameAsc = "LAST_NAME_ASC",
  LastNameDesc = "LAST_NAME_DESC",
  MetaDataAsc = "META_DATA_ASC",
  MetaDataDesc = "META_DATA_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  ParentIdAsc = "PARENT_ID_ASC",
  ParentIdDesc = "PARENT_ID_DESC",
  PaymentOwnerIdAsc = "PAYMENT_OWNER_ID_ASC",
  PaymentOwnerIdDesc = "PAYMENT_OWNER_ID_DESC",
  PhoneAsc = "PHONE_ASC",
  PhoneDesc = "PHONE_DESC",
  PreferredCurrencyCodeAsc = "PREFERRED_CURRENCY_CODE_ASC",
  PreferredCurrencyCodeDesc = "PREFERRED_CURRENCY_CODE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProviderAsc = "PROVIDER_ASC",
  ProviderDesc = "PROVIDER_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  TaxabilityAsc = "TAXABILITY_ASC",
  TaxabilityDesc = "TAXABILITY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VatNumberAsc = "VAT_NUMBER_ASC",
  VatNumberDesc = "VAT_NUMBER_DESC",
  VatNumberStatusAsc = "VAT_NUMBER_STATUS_ASC",
  VatNumberStatusDesc = "VAT_NUMBER_STATUS_DESC",
}

export type BillingDistributor = Node & {
  __typename: "BillingDistributor";
  /** Reads and enables pagination through a set of `BillingReseller`. */
  billingResellersByBillingDistributorId: BillingResellersConnection;
  cfAllowLicence: Maybe<Scalars["Boolean"]>;
  cfPartner: Maybe<Scalars["String"]>;
  cfPrimary: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  company: Maybe<Scalars["String"]>;
  country: Maybe<CountryCode>;
  createdAt: Maybe<Scalars["Datetime"]>;
  deleted: Maybe<Scalars["Boolean"]>;
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  invoiceOwnerId: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  line1: Maybe<Scalars["String"]>;
  line2: Maybe<Scalars["String"]>;
  line3: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads and enables pagination through a set of `Org`. */
  orgsByBillingDistributorId: OrgsConnection;
  parentId: Maybe<Scalars["String"]>;
  paymentOwnerId: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  raw: Maybe<Scalars["JSON"]>;
  state: Maybe<Scalars["String"]>;
  stateCode: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  zip: Maybe<Scalars["String"]>;
};

export type BillingDistributorBillingResellersByBillingDistributorIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingResellerCondition>;
  filter: InputMaybe<BillingResellerFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingResellersOrderBy>>;
};

export type BillingDistributorOrgsByBillingDistributorIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/**
 * A condition to be used against `BillingDistributor` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingDistributorCondition = {
  /** Checks for equality with the object’s `cfAllowLicence` field. */
  cfAllowLicence?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `cfPartner` field. */
  cfPartner?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `company` field. */
  company?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<CountryCode>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `invoiceOwnerId` field. */
  invoiceOwnerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line1` field. */
  line1?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line2` field. */
  line2?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line3` field. */
  line3?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `paymentOwnerId` field. */
  paymentOwnerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `stateCode` field. */
  stateCode?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `zip` field. */
  zip?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `BillingDistributor` object types. All fields are combined with a logical ‘and.’ */
export type BillingDistributorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingDistributorFilter>>;
  /** Filter by the object’s `cfAllowLicence` field. */
  cfAllowLicence?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `cfPartner` field. */
  cfPartner?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `company` field. */
  company?: InputMaybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<CountryCodeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `invoiceOwnerId` field. */
  invoiceOwnerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line1` field. */
  line1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line2` field. */
  line2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line3` field. */
  line3?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingDistributorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingDistributorFilter>>;
  /** Filter by the object’s `parentId` field. */
  parentId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `paymentOwnerId` field. */
  paymentOwnerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  phone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stateCode` field. */
  stateCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `zip` field. */
  zip?: InputMaybe<StringFilter>;
};

/** A connection to a list of `BillingDistributor` values. */
export type BillingDistributorsConnection = {
  __typename: "BillingDistributorsConnection";
  /** A list of edges which contains the `BillingDistributor` and cursor to aid in pagination. */
  edges: Array<BillingDistributorsEdge>;
  /** A list of `BillingDistributor` objects. */
  nodes: Array<BillingDistributor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingDistributor` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingDistributor` edge in the connection. */
export type BillingDistributorsEdge = {
  __typename: "BillingDistributorsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingDistributor` at the end of the edge. */
  node: BillingDistributor;
};

/** Methods to use when ordering `BillingDistributor`. */
export enum BillingDistributorsOrderBy {
  CfAllowLicenceAsc = "CF_ALLOW_LICENCE_ASC",
  CfAllowLicenceDesc = "CF_ALLOW_LICENCE_DESC",
  CfPartnerAsc = "CF_PARTNER_ASC",
  CfPartnerDesc = "CF_PARTNER_DESC",
  CfPrimaryAsc = "CF_PRIMARY_ASC",
  CfPrimaryDesc = "CF_PRIMARY_DESC",
  CityAsc = "CITY_ASC",
  CityDesc = "CITY_DESC",
  CompanyAsc = "COMPANY_ASC",
  CompanyDesc = "COMPANY_DESC",
  CountryAsc = "COUNTRY_ASC",
  CountryDesc = "COUNTRY_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FirstNameAsc = "FIRST_NAME_ASC",
  FirstNameDesc = "FIRST_NAME_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InvoiceOwnerIdAsc = "INVOICE_OWNER_ID_ASC",
  InvoiceOwnerIdDesc = "INVOICE_OWNER_ID_DESC",
  LastNameAsc = "LAST_NAME_ASC",
  LastNameDesc = "LAST_NAME_DESC",
  Line1Asc = "LINE1_ASC",
  Line1Desc = "LINE1_DESC",
  Line2Asc = "LINE2_ASC",
  Line2Desc = "LINE2_DESC",
  Line3Asc = "LINE3_ASC",
  Line3Desc = "LINE3_DESC",
  Natural = "NATURAL",
  ParentIdAsc = "PARENT_ID_ASC",
  ParentIdDesc = "PARENT_ID_DESC",
  PaymentOwnerIdAsc = "PAYMENT_OWNER_ID_ASC",
  PaymentOwnerIdDesc = "PAYMENT_OWNER_ID_DESC",
  PhoneAsc = "PHONE_ASC",
  PhoneDesc = "PHONE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  StateAsc = "STATE_ASC",
  StateCodeAsc = "STATE_CODE_ASC",
  StateCodeDesc = "STATE_CODE_DESC",
  StateDesc = "STATE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  ZipAsc = "ZIP_ASC",
  ZipDesc = "ZIP_DESC",
}

/** A connection to a list of `BillingHierarchy` values. */
export type BillingHierarchiesConnection = {
  __typename: "BillingHierarchiesConnection";
  /** A list of edges which contains the `BillingHierarchy` and cursor to aid in pagination. */
  edges: Array<BillingHierarchiesEdge>;
  /** A list of `BillingHierarchy` objects. */
  nodes: Array<BillingHierarchy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingHierarchy` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingHierarchy` edge in the connection. */
export type BillingHierarchiesEdge = {
  __typename: "BillingHierarchiesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingHierarchy` at the end of the edge. */
  node: BillingHierarchy;
};

/** Methods to use when ordering `BillingHierarchy`. */
export enum BillingHierarchiesOrderBy {
  ChildIdAsc = "CHILD_ID_ASC",
  ChildIdDesc = "CHILD_ID_DESC",
  InvoiceeIdAsc = "INVOICEE_ID_ASC",
  InvoiceeIdDesc = "INVOICEE_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  ParentIdAsc = "PARENT_ID_ASC",
  ParentIdDesc = "PARENT_ID_DESC",
  PayeeIdAsc = "PAYEE_ID_ASC",
  PayeeIdDesc = "PAYEE_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type BillingHierarchy = Node & {
  __typename: "BillingHierarchy";
  /** Reads a single `BillingCustomer` that is related to this `BillingHierarchy`. */
  billingCustomerByChildId: Maybe<BillingCustomer>;
  /** Reads a single `BillingCustomer` that is related to this `BillingHierarchy`. */
  billingCustomerByInvoiceeId: Maybe<BillingCustomer>;
  /** Reads a single `BillingCustomer` that is related to this `BillingHierarchy`. */
  billingCustomerByParentId: Maybe<BillingCustomer>;
  /** Reads a single `BillingCustomer` that is related to this `BillingHierarchy`. */
  billingCustomerByPayeeId: Maybe<BillingCustomer>;
  /** Child billing_customer_id/chargebee customer_id */
  childId: Scalars["String"];
  /** billing_customer_id/chargebee customer_id who gets invoiced */
  invoiceeId: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingHierarchy`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Parent billing_customer_id/chargebee customer_id */
  parentId: Scalars["String"];
  /** billing_customer_id/chargebee customer_id who pays */
  payeeId: Maybe<Scalars["String"]>;
};

/**
 * A condition to be used against `BillingHierarchy` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingHierarchyCondition = {
  /** Checks for equality with the object’s `childId` field. */
  childId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `invoiceeId` field. */
  invoiceeId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `payeeId` field. */
  payeeId?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `BillingHierarchy` object types. All fields are combined with a logical ‘and.’ */
export type BillingHierarchyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingHierarchyFilter>>;
  /** Filter by the object’s `childId` field. */
  childId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `invoiceeId` field. */
  invoiceeId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingHierarchyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingHierarchyFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `payeeId` field. */
  payeeId?: InputMaybe<StringFilter>;
};

export type BillingInvoice = Node & {
  __typename: "BillingInvoice";
  amountDue: Maybe<Scalars["Int"]>;
  amountPaid: Maybe<Scalars["Int"]>;
  amountToCollect: Maybe<Scalars["Int"]>;
  baseCurrencyCode: Maybe<CurrencyCode>;
  /** Reads a single `BillingCustomer` that is related to this `BillingInvoice`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads a single `BillingSubscription` that is related to this `BillingInvoice`. */
  billingSubscriptionByBillingSubscriptionId: Maybe<BillingSubscription>;
  billingSubscriptionId: Scalars["String"];
  date: Maybe<Scalars["Datetime"]>;
  dueDate: Maybe<Scalars["Datetime"]>;
  id: Scalars["String"];
  lastSyncAt: Maybe<Scalars["Datetime"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  paidAt: Maybe<Scalars["Datetime"]>;
  raw: Maybe<Scalars["JSON"]>;
  status: Maybe<Scalars["String"]>;
  tax: Maybe<Scalars["Int"]>;
  total: Maybe<Scalars["Int"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
};

/**
 * A condition to be used against `BillingInvoice` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingInvoiceCondition = {
  /** Checks for equality with the object’s `amountDue` field. */
  amountDue?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `amountPaid` field. */
  amountPaid?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `amountToCollect` field. */
  amountToCollect?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `baseCurrencyCode` field. */
  baseCurrencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `dueDate` field. */
  dueDate?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `lastSyncAt` field. */
  lastSyncAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `paidAt` field. */
  paidAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `tax` field. */
  tax?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
};

/** A filter to be used against `BillingInvoice` object types. All fields are combined with a logical ‘and.’ */
export type BillingInvoiceFilter = {
  /** Filter by the object’s `amountDue` field. */
  amountDue?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountPaid` field. */
  amountPaid?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountToCollect` field. */
  amountToCollect?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingInvoiceFilter>>;
  /** Filter by the object’s `baseCurrencyCode` field. */
  baseCurrencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dueDate` field. */
  dueDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastSyncAt` field. */
  lastSyncAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingInvoiceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingInvoiceFilter>>;
  /** Filter by the object’s `paidAt` field. */
  paidAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tax` field. */
  tax?: InputMaybe<IntFilter>;
  /** Filter by the object’s `total` field. */
  total?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `BillingInvoice` values. */
export type BillingInvoicesConnection = {
  __typename: "BillingInvoicesConnection";
  /** A list of edges which contains the `BillingInvoice` and cursor to aid in pagination. */
  edges: Array<BillingInvoicesEdge>;
  /** A list of `BillingInvoice` objects. */
  nodes: Array<BillingInvoice>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingInvoice` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingInvoice` edge in the connection. */
export type BillingInvoicesEdge = {
  __typename: "BillingInvoicesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingInvoice` at the end of the edge. */
  node: BillingInvoice;
};

/** Methods to use when ordering `BillingInvoice`. */
export enum BillingInvoicesOrderBy {
  AmountDueAsc = "AMOUNT_DUE_ASC",
  AmountDueDesc = "AMOUNT_DUE_DESC",
  AmountPaidAsc = "AMOUNT_PAID_ASC",
  AmountPaidDesc = "AMOUNT_PAID_DESC",
  AmountToCollectAsc = "AMOUNT_TO_COLLECT_ASC",
  AmountToCollectDesc = "AMOUNT_TO_COLLECT_DESC",
  BaseCurrencyCodeAsc = "BASE_CURRENCY_CODE_ASC",
  BaseCurrencyCodeDesc = "BASE_CURRENCY_CODE_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingSubscriptionIdAsc = "BILLING_SUBSCRIPTION_ID_ASC",
  BillingSubscriptionIdDesc = "BILLING_SUBSCRIPTION_ID_DESC",
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  DueDateAsc = "DUE_DATE_ASC",
  DueDateDesc = "DUE_DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastSyncAtAsc = "LAST_SYNC_AT_ASC",
  LastSyncAtDesc = "LAST_SYNC_AT_DESC",
  Natural = "NATURAL",
  PaidAtAsc = "PAID_AT_ASC",
  PaidAtDesc = "PAID_AT_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TaxAsc = "TAX_ASC",
  TaxDesc = "TAX_DESC",
  TotalAsc = "TOTAL_ASC",
  TotalDesc = "TOTAL_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type BillingPaymentSource = Node & {
  __typename: "BillingPaymentSource";
  amazonPayment: Maybe<Scalars["JSON"]>;
  bankAccount: Maybe<Scalars["JSON"]>;
  /** Reads a single `BillingCustomer` that is related to this `BillingPaymentSource`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  card: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  gateway: Maybe<Scalars["String"]>;
  gatewayAccountId: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  issuingCountry: Maybe<CountryCode>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingPaymentSource`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  paypal: Maybe<Scalars["JSON"]>;
  raw: Maybe<Scalars["JSON"]>;
  referenceId: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
};

/**
 * A condition to be used against `BillingPaymentSource` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BillingPaymentSourceCondition = {
  /** Checks for equality with the object’s `amazonPayment` field. */
  amazonPayment?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `bankAccount` field. */
  bankAccount?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `card` field. */
  card?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `gateway` field. */
  gateway?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `gatewayAccountId` field. */
  gatewayAccountId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `issuingCountry` field. */
  issuingCountry?: InputMaybe<CountryCode>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `paypal` field. */
  paypal?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `referenceId` field. */
  referenceId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
};

/** A filter to be used against `BillingPaymentSource` object types. All fields are combined with a logical ‘and.’ */
export type BillingPaymentSourceFilter = {
  /** Filter by the object’s `amazonPayment` field. */
  amazonPayment?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingPaymentSourceFilter>>;
  /** Filter by the object’s `bankAccount` field. */
  bankAccount?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `card` field. */
  card?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `gateway` field. */
  gateway?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gatewayAccountId` field. */
  gatewayAccountId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `issuingCountry` field. */
  issuingCountry?: InputMaybe<CountryCodeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingPaymentSourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingPaymentSourceFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `paypal` field. */
  paypal?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `referenceId` field. */
  referenceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `BillingPaymentSource` values. */
export type BillingPaymentSourcesConnection = {
  __typename: "BillingPaymentSourcesConnection";
  /** A list of edges which contains the `BillingPaymentSource` and cursor to aid in pagination. */
  edges: Array<BillingPaymentSourcesEdge>;
  /** A list of `BillingPaymentSource` objects. */
  nodes: Array<BillingPaymentSource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingPaymentSource` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingPaymentSource` edge in the connection. */
export type BillingPaymentSourcesEdge = {
  __typename: "BillingPaymentSourcesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingPaymentSource` at the end of the edge. */
  node: BillingPaymentSource;
};

/** Methods to use when ordering `BillingPaymentSource`. */
export enum BillingPaymentSourcesOrderBy {
  AmazonPaymentAsc = "AMAZON_PAYMENT_ASC",
  AmazonPaymentDesc = "AMAZON_PAYMENT_DESC",
  BankAccountAsc = "BANK_ACCOUNT_ASC",
  BankAccountDesc = "BANK_ACCOUNT_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  CardAsc = "CARD_ASC",
  CardDesc = "CARD_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  GatewayAccountIdAsc = "GATEWAY_ACCOUNT_ID_ASC",
  GatewayAccountIdDesc = "GATEWAY_ACCOUNT_ID_DESC",
  GatewayAsc = "GATEWAY_ASC",
  GatewayDesc = "GATEWAY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IssuingCountryAsc = "ISSUING_COUNTRY_ASC",
  IssuingCountryDesc = "ISSUING_COUNTRY_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PaypalAsc = "PAYPAL_ASC",
  PaypalDesc = "PAYPAL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  ReferenceIdAsc = "REFERENCE_ID_ASC",
  ReferenceIdDesc = "REFERENCE_ID_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type BillingPlan = Node & {
  __typename: "BillingPlan";
  /** Reads and enables pagination through a set of `BillingAddonPlan`. */
  billingAddonPlansByBillingPlanId: BillingAddonPlansConnection;
  billingCycles: Maybe<Scalars["Int"]>;
  /** Reads and enables pagination through a set of `BillingSubscriptionFuture`. */
  billingSubscriptionFuturesByPlanId: BillingSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingSubscription`. */
  billingSubscriptionsByPlanId: BillingSubscriptionsConnection;
  cfEnterprise: Maybe<Scalars["Boolean"]>;
  cfIncludedPhysicalScreenLicenses: Maybe<Scalars["Int"]>;
  cfIncludedVirtualScreenLicenses: Maybe<Scalars["Int"]>;
  cfIsLegacy: Maybe<Scalars["Boolean"]>;
  cfName: Maybe<Scalars["String"]>;
  cfSpecial: Maybe<Scalars["String"]>;
  cfSubscriptionType: Maybe<Scalars["String"]>;
  cfVersion: Maybe<Scalars["String"]>;
  currencyCode: Maybe<CurrencyCode>;
  description: Maybe<Scalars["String"]>;
  freeQuantity: Maybe<Scalars["Int"]>;
  id: Scalars["String"];
  metaData: Maybe<Scalars["JSON"]>;
  name: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  period: Maybe<Scalars["Int"]>;
  periodUnit: Maybe<Scalars["String"]>;
  price: Maybe<Scalars["Int"]>;
  pricingModel: Maybe<Scalars["String"]>;
  raw: Maybe<Scalars["JSON"]>;
  status: Maybe<Scalars["String"]>;
  trialPeriod: Maybe<Scalars["Int"]>;
  trialPeriodUnit: Maybe<Scalars["String"]>;
};

export type BillingPlanBillingAddonPlansByBillingPlanIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonPlanCondition>;
  filter: InputMaybe<BillingAddonPlanFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonPlansOrderBy>>;
};

export type BillingPlanBillingSubscriptionFuturesByPlanIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingSubscriptionFutureCondition>;
  filter: InputMaybe<BillingSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingSubscriptionFuturesOrderBy>>;
};

export type BillingPlanBillingSubscriptionsByPlanIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingSubscriptionCondition>;
  filter: InputMaybe<BillingSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingSubscriptionsOrderBy>>;
};

/**
 * A condition to be used against `BillingPlan` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BillingPlanCondition = {
  /** Checks for equality with the object’s `billingCycles` field. */
  billingCycles?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `cfEnterprise` field. */
  cfEnterprise?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `cfIncludedPhysicalScreenLicenses` field. */
  cfIncludedPhysicalScreenLicenses?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `cfIncludedVirtualScreenLicenses` field. */
  cfIncludedVirtualScreenLicenses?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `cfIsLegacy` field. */
  cfIsLegacy?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `cfName` field. */
  cfName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfSpecial` field. */
  cfSpecial?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfSubscriptionType` field. */
  cfSubscriptionType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfVersion` field. */
  cfVersion?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `freeQuantity` field. */
  freeQuantity?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `metaData` field. */
  metaData?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `period` field. */
  period?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `periodUnit` field. */
  periodUnit?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `pricingModel` field. */
  pricingModel?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `trialPeriod` field. */
  trialPeriod?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `trialPeriodUnit` field. */
  trialPeriodUnit?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `BillingPlan` object types. All fields are combined with a logical ‘and.’ */
export type BillingPlanFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingPlanFilter>>;
  /** Filter by the object’s `billingCycles` field. */
  billingCycles?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cfEnterprise` field. */
  cfEnterprise?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `cfIncludedPhysicalScreenLicenses` field. */
  cfIncludedPhysicalScreenLicenses?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cfIncludedVirtualScreenLicenses` field. */
  cfIncludedVirtualScreenLicenses?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cfIsLegacy` field. */
  cfIsLegacy?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `cfName` field. */
  cfName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfSpecial` field. */
  cfSpecial?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfSubscriptionType` field. */
  cfSubscriptionType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfVersion` field. */
  cfVersion?: InputMaybe<StringFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `freeQuantity` field. */
  freeQuantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metaData` field. */
  metaData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingPlanFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingPlanFilter>>;
  /** Filter by the object’s `period` field. */
  period?: InputMaybe<IntFilter>;
  /** Filter by the object’s `periodUnit` field. */
  periodUnit?: InputMaybe<StringFilter>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<IntFilter>;
  /** Filter by the object’s `pricingModel` field. */
  pricingModel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trialPeriod` field. */
  trialPeriod?: InputMaybe<IntFilter>;
  /** Filter by the object’s `trialPeriodUnit` field. */
  trialPeriodUnit?: InputMaybe<StringFilter>;
};

/** A connection to a list of `BillingPlan` values. */
export type BillingPlansConnection = {
  __typename: "BillingPlansConnection";
  /** A list of edges which contains the `BillingPlan` and cursor to aid in pagination. */
  edges: Array<BillingPlansEdge>;
  /** A list of `BillingPlan` objects. */
  nodes: Array<BillingPlan>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingPlan` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingPlan` edge in the connection. */
export type BillingPlansEdge = {
  __typename: "BillingPlansEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingPlan` at the end of the edge. */
  node: BillingPlan;
};

/** Methods to use when ordering `BillingPlan`. */
export enum BillingPlansOrderBy {
  BillingCyclesAsc = "BILLING_CYCLES_ASC",
  BillingCyclesDesc = "BILLING_CYCLES_DESC",
  CfEnterpriseAsc = "CF_ENTERPRISE_ASC",
  CfEnterpriseDesc = "CF_ENTERPRISE_DESC",
  CfIncludedPhysicalScreenLicensesAsc = "CF_INCLUDED_PHYSICAL_SCREEN_LICENSES_ASC",
  CfIncludedPhysicalScreenLicensesDesc = "CF_INCLUDED_PHYSICAL_SCREEN_LICENSES_DESC",
  CfIncludedVirtualScreenLicensesAsc = "CF_INCLUDED_VIRTUAL_SCREEN_LICENSES_ASC",
  CfIncludedVirtualScreenLicensesDesc = "CF_INCLUDED_VIRTUAL_SCREEN_LICENSES_DESC",
  CfIsLegacyAsc = "CF_IS_LEGACY_ASC",
  CfIsLegacyDesc = "CF_IS_LEGACY_DESC",
  CfNameAsc = "CF_NAME_ASC",
  CfNameDesc = "CF_NAME_DESC",
  CfSpecialAsc = "CF_SPECIAL_ASC",
  CfSpecialDesc = "CF_SPECIAL_DESC",
  CfSubscriptionTypeAsc = "CF_SUBSCRIPTION_TYPE_ASC",
  CfSubscriptionTypeDesc = "CF_SUBSCRIPTION_TYPE_DESC",
  CfVersionAsc = "CF_VERSION_ASC",
  CfVersionDesc = "CF_VERSION_DESC",
  CurrencyCodeAsc = "CURRENCY_CODE_ASC",
  CurrencyCodeDesc = "CURRENCY_CODE_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  FreeQuantityAsc = "FREE_QUANTITY_ASC",
  FreeQuantityDesc = "FREE_QUANTITY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetaDataAsc = "META_DATA_ASC",
  MetaDataDesc = "META_DATA_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PeriodAsc = "PERIOD_ASC",
  PeriodDesc = "PERIOD_DESC",
  PeriodUnitAsc = "PERIOD_UNIT_ASC",
  PeriodUnitDesc = "PERIOD_UNIT_DESC",
  PriceAsc = "PRICE_ASC",
  PriceDesc = "PRICE_DESC",
  PricingModelAsc = "PRICING_MODEL_ASC",
  PricingModelDesc = "PRICING_MODEL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TrialPeriodAsc = "TRIAL_PERIOD_ASC",
  TrialPeriodDesc = "TRIAL_PERIOD_DESC",
  TrialPeriodUnitAsc = "TRIAL_PERIOD_UNIT_ASC",
  TrialPeriodUnitDesc = "TRIAL_PERIOD_UNIT_DESC",
}

export type BillingRenewal = Node & {
  __typename: "BillingRenewal";
  amountDue: Maybe<Scalars["Int"]>;
  amountPaid: Maybe<Scalars["Int"]>;
  /** Reads a single `BillingCustomer` that is related to this `BillingRenewal`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads a single `BillingSubscription` that is related to this `BillingRenewal`. */
  billingSubscriptionByBillingSubscriptionId: Maybe<BillingSubscription>;
  billingSubscriptionId: Scalars["String"];
  createdAt: Maybe<Scalars["Datetime"]>;
  creditsApplied: Maybe<Scalars["Int"]>;
  discounts: Maybe<Scalars["JSON"]>;
  lineItemDiscounts: Maybe<Scalars["JSON"]>;
  lineItemTaxes: Maybe<Scalars["JSON"]>;
  lineItems: Maybe<Scalars["JSON"]>;
  nextBillingAt: Maybe<Scalars["Datetime"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingRenewal`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  raw: Maybe<Scalars["JSON"]>;
  subTotal: Maybe<Scalars["Int"]>;
  taxAmount: Maybe<Scalars["Int"]>;
  taxDescription: Maybe<Scalars["String"]>;
  taxRate: Maybe<Scalars["BigFloat"]>;
  taxes: Maybe<Scalars["JSON"]>;
  total: Maybe<Scalars["Int"]>;
};

/**
 * A condition to be used against `BillingRenewal` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingRenewalCondition = {
  /** Checks for equality with the object’s `amountDue` field. */
  amountDue?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `amountPaid` field. */
  amountPaid?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `creditsApplied` field. */
  creditsApplied?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `discounts` field. */
  discounts?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `lineItemDiscounts` field. */
  lineItemDiscounts?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `lineItemTaxes` field. */
  lineItemTaxes?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `lineItems` field. */
  lineItems?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `nextBillingAt` field. */
  nextBillingAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `subTotal` field. */
  subTotal?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `taxAmount` field. */
  taxAmount?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `taxDescription` field. */
  taxDescription?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `taxRate` field. */
  taxRate?: InputMaybe<Scalars["BigFloat"]>;
  /** Checks for equality with the object’s `taxes` field. */
  taxes?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `BillingRenewal` object types. All fields are combined with a logical ‘and.’ */
export type BillingRenewalFilter = {
  /** Filter by the object’s `amountDue` field. */
  amountDue?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountPaid` field. */
  amountPaid?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingRenewalFilter>>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `creditsApplied` field. */
  creditsApplied?: InputMaybe<IntFilter>;
  /** Filter by the object’s `discounts` field. */
  discounts?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lineItemDiscounts` field. */
  lineItemDiscounts?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lineItemTaxes` field. */
  lineItemTaxes?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lineItems` field. */
  lineItems?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `nextBillingAt` field. */
  nextBillingAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingRenewalFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingRenewalFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `subTotal` field. */
  subTotal?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taxAmount` field. */
  taxAmount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taxDescription` field. */
  taxDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `taxRate` field. */
  taxRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `taxes` field. */
  taxes?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `total` field. */
  total?: InputMaybe<IntFilter>;
};

export type BillingRenewalFuture = Node & {
  __typename: "BillingRenewalFuture";
  amountDue: Maybe<Scalars["Int"]>;
  amountPaid: Maybe<Scalars["Int"]>;
  /** Reads a single `BillingCustomer` that is related to this `BillingRenewalFuture`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads a single `BillingSubscriptionFuture` that is related to this `BillingRenewalFuture`. */
  billingSubscriptionFutureByBillingSubscriptionId: Maybe<BillingSubscriptionFuture>;
  billingSubscriptionId: Scalars["String"];
  createdAt: Maybe<Scalars["Datetime"]>;
  creditsApplied: Maybe<Scalars["Int"]>;
  discounts: Maybe<Scalars["JSON"]>;
  lineItemDiscounts: Maybe<Scalars["JSON"]>;
  lineItemTaxes: Maybe<Scalars["JSON"]>;
  lineItems: Maybe<Scalars["JSON"]>;
  nextBillingAt: Maybe<Scalars["Datetime"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingRenewalFuture`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  raw: Maybe<Scalars["JSON"]>;
  subTotal: Maybe<Scalars["Int"]>;
  taxAmount: Maybe<Scalars["Int"]>;
  taxDescription: Maybe<Scalars["String"]>;
  taxRate: Maybe<Scalars["BigFloat"]>;
  taxes: Maybe<Scalars["JSON"]>;
  total: Maybe<Scalars["Int"]>;
};

/**
 * A condition to be used against `BillingRenewalFuture` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BillingRenewalFutureCondition = {
  /** Checks for equality with the object’s `amountDue` field. */
  amountDue?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `amountPaid` field. */
  amountPaid?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `creditsApplied` field. */
  creditsApplied?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `discounts` field. */
  discounts?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `lineItemDiscounts` field. */
  lineItemDiscounts?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `lineItemTaxes` field. */
  lineItemTaxes?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `lineItems` field. */
  lineItems?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `nextBillingAt` field. */
  nextBillingAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `subTotal` field. */
  subTotal?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `taxAmount` field. */
  taxAmount?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `taxDescription` field. */
  taxDescription?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `taxRate` field. */
  taxRate?: InputMaybe<Scalars["BigFloat"]>;
  /** Checks for equality with the object’s `taxes` field. */
  taxes?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `BillingRenewalFuture` object types. All fields are combined with a logical ‘and.’ */
export type BillingRenewalFutureFilter = {
  /** Filter by the object’s `amountDue` field. */
  amountDue?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountPaid` field. */
  amountPaid?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingRenewalFutureFilter>>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingSubscriptionId` field. */
  billingSubscriptionId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `creditsApplied` field. */
  creditsApplied?: InputMaybe<IntFilter>;
  /** Filter by the object’s `discounts` field. */
  discounts?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lineItemDiscounts` field. */
  lineItemDiscounts?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lineItemTaxes` field. */
  lineItemTaxes?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lineItems` field. */
  lineItems?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `nextBillingAt` field. */
  nextBillingAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingRenewalFutureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingRenewalFutureFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `subTotal` field. */
  subTotal?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taxAmount` field. */
  taxAmount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taxDescription` field. */
  taxDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `taxRate` field. */
  taxRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `taxes` field. */
  taxes?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `total` field. */
  total?: InputMaybe<IntFilter>;
};

/** A connection to a list of `BillingRenewalFuture` values. */
export type BillingRenewalFuturesConnection = {
  __typename: "BillingRenewalFuturesConnection";
  /** A list of edges which contains the `BillingRenewalFuture` and cursor to aid in pagination. */
  edges: Array<BillingRenewalFuturesEdge>;
  /** A list of `BillingRenewalFuture` objects. */
  nodes: Array<BillingRenewalFuture>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingRenewalFuture` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingRenewalFuture` edge in the connection. */
export type BillingRenewalFuturesEdge = {
  __typename: "BillingRenewalFuturesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingRenewalFuture` at the end of the edge. */
  node: BillingRenewalFuture;
};

/** Methods to use when ordering `BillingRenewalFuture`. */
export enum BillingRenewalFuturesOrderBy {
  AmountDueAsc = "AMOUNT_DUE_ASC",
  AmountDueDesc = "AMOUNT_DUE_DESC",
  AmountPaidAsc = "AMOUNT_PAID_ASC",
  AmountPaidDesc = "AMOUNT_PAID_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingSubscriptionIdAsc = "BILLING_SUBSCRIPTION_ID_ASC",
  BillingSubscriptionIdDesc = "BILLING_SUBSCRIPTION_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreditsAppliedAsc = "CREDITS_APPLIED_ASC",
  CreditsAppliedDesc = "CREDITS_APPLIED_DESC",
  DiscountsAsc = "DISCOUNTS_ASC",
  DiscountsDesc = "DISCOUNTS_DESC",
  LineItemsAsc = "LINE_ITEMS_ASC",
  LineItemsDesc = "LINE_ITEMS_DESC",
  LineItemDiscountsAsc = "LINE_ITEM_DISCOUNTS_ASC",
  LineItemDiscountsDesc = "LINE_ITEM_DISCOUNTS_DESC",
  LineItemTaxesAsc = "LINE_ITEM_TAXES_ASC",
  LineItemTaxesDesc = "LINE_ITEM_TAXES_DESC",
  Natural = "NATURAL",
  NextBillingAtAsc = "NEXT_BILLING_AT_ASC",
  NextBillingAtDesc = "NEXT_BILLING_AT_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  SubTotalAsc = "SUB_TOTAL_ASC",
  SubTotalDesc = "SUB_TOTAL_DESC",
  TaxesAsc = "TAXES_ASC",
  TaxesDesc = "TAXES_DESC",
  TaxAmountAsc = "TAX_AMOUNT_ASC",
  TaxAmountDesc = "TAX_AMOUNT_DESC",
  TaxDescriptionAsc = "TAX_DESCRIPTION_ASC",
  TaxDescriptionDesc = "TAX_DESCRIPTION_DESC",
  TaxRateAsc = "TAX_RATE_ASC",
  TaxRateDesc = "TAX_RATE_DESC",
  TotalAsc = "TOTAL_ASC",
  TotalDesc = "TOTAL_DESC",
}

/** A connection to a list of `BillingRenewal` values. */
export type BillingRenewalsConnection = {
  __typename: "BillingRenewalsConnection";
  /** A list of edges which contains the `BillingRenewal` and cursor to aid in pagination. */
  edges: Array<BillingRenewalsEdge>;
  /** A list of `BillingRenewal` objects. */
  nodes: Array<BillingRenewal>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingRenewal` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingRenewal` edge in the connection. */
export type BillingRenewalsEdge = {
  __typename: "BillingRenewalsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingRenewal` at the end of the edge. */
  node: BillingRenewal;
};

/** Methods to use when ordering `BillingRenewal`. */
export enum BillingRenewalsOrderBy {
  AmountDueAsc = "AMOUNT_DUE_ASC",
  AmountDueDesc = "AMOUNT_DUE_DESC",
  AmountPaidAsc = "AMOUNT_PAID_ASC",
  AmountPaidDesc = "AMOUNT_PAID_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingSubscriptionIdAsc = "BILLING_SUBSCRIPTION_ID_ASC",
  BillingSubscriptionIdDesc = "BILLING_SUBSCRIPTION_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreditsAppliedAsc = "CREDITS_APPLIED_ASC",
  CreditsAppliedDesc = "CREDITS_APPLIED_DESC",
  DiscountsAsc = "DISCOUNTS_ASC",
  DiscountsDesc = "DISCOUNTS_DESC",
  LineItemsAsc = "LINE_ITEMS_ASC",
  LineItemsDesc = "LINE_ITEMS_DESC",
  LineItemDiscountsAsc = "LINE_ITEM_DISCOUNTS_ASC",
  LineItemDiscountsDesc = "LINE_ITEM_DISCOUNTS_DESC",
  LineItemTaxesAsc = "LINE_ITEM_TAXES_ASC",
  LineItemTaxesDesc = "LINE_ITEM_TAXES_DESC",
  Natural = "NATURAL",
  NextBillingAtAsc = "NEXT_BILLING_AT_ASC",
  NextBillingAtDesc = "NEXT_BILLING_AT_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  SubTotalAsc = "SUB_TOTAL_ASC",
  SubTotalDesc = "SUB_TOTAL_DESC",
  TaxesAsc = "TAXES_ASC",
  TaxesDesc = "TAXES_DESC",
  TaxAmountAsc = "TAX_AMOUNT_ASC",
  TaxAmountDesc = "TAX_AMOUNT_DESC",
  TaxDescriptionAsc = "TAX_DESCRIPTION_ASC",
  TaxDescriptionDesc = "TAX_DESCRIPTION_DESC",
  TaxRateAsc = "TAX_RATE_ASC",
  TaxRateDesc = "TAX_RATE_DESC",
  TotalAsc = "TOTAL_ASC",
  TotalDesc = "TOTAL_DESC",
}

export type BillingReseller = Node & {
  __typename: "BillingReseller";
  /** Reads and enables pagination through a set of `BillingCustomer`. */
  billingCustomersByBillingResellerId: BillingCustomersConnection;
  /** Reads a single `BillingDistributor` that is related to this `BillingReseller`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  billingDistributorId: Maybe<Scalars["String"]>;
  cfAllowLicence: Maybe<Scalars["Boolean"]>;
  cfPartner: Maybe<Scalars["String"]>;
  cfPrimary: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  company: Maybe<Scalars["String"]>;
  country: Maybe<CountryCode>;
  createdAt: Maybe<Scalars["Datetime"]>;
  deleted: Maybe<Scalars["Boolean"]>;
  email: Maybe<Scalars["String"]>;
  firstName: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  invoiceOwnerId: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  line1: Maybe<Scalars["String"]>;
  line2: Maybe<Scalars["String"]>;
  line3: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads and enables pagination through a set of `Org`. */
  orgsByBillingResellerId: OrgsConnection;
  paymentOwnerId: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  raw: Maybe<Scalars["JSON"]>;
  state: Maybe<Scalars["String"]>;
  stateCode: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  zip: Maybe<Scalars["String"]>;
};

export type BillingResellerBillingCustomersByBillingResellerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCustomerCondition>;
  filter: InputMaybe<BillingCustomerFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCustomersOrderBy>>;
};

export type BillingResellerOrgsByBillingResellerIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/**
 * A condition to be used against `BillingReseller` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BillingResellerCondition = {
  /** Checks for equality with the object’s `billingDistributorId` field. */
  billingDistributorId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfAllowLicence` field. */
  cfAllowLicence?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `cfPartner` field. */
  cfPartner?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `company` field. */
  company?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<CountryCode>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `invoiceOwnerId` field. */
  invoiceOwnerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line1` field. */
  line1?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line2` field. */
  line2?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `line3` field. */
  line3?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `paymentOwnerId` field. */
  paymentOwnerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `stateCode` field. */
  stateCode?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `zip` field. */
  zip?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `BillingReseller` object types. All fields are combined with a logical ‘and.’ */
export type BillingResellerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingResellerFilter>>;
  /** Filter by the object’s `billingDistributorId` field. */
  billingDistributorId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfAllowLicence` field. */
  cfAllowLicence?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `cfPartner` field. */
  cfPartner?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `company` field. */
  company?: InputMaybe<StringFilter>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<CountryCodeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `invoiceOwnerId` field. */
  invoiceOwnerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line1` field. */
  line1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line2` field. */
  line2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `line3` field. */
  line3?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingResellerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingResellerFilter>>;
  /** Filter by the object’s `paymentOwnerId` field. */
  paymentOwnerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  phone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stateCode` field. */
  stateCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `zip` field. */
  zip?: InputMaybe<StringFilter>;
};

/** A connection to a list of `BillingReseller` values. */
export type BillingResellersConnection = {
  __typename: "BillingResellersConnection";
  /** A list of edges which contains the `BillingReseller` and cursor to aid in pagination. */
  edges: Array<BillingResellersEdge>;
  /** A list of `BillingReseller` objects. */
  nodes: Array<BillingReseller>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingReseller` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingReseller` edge in the connection. */
export type BillingResellersEdge = {
  __typename: "BillingResellersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingReseller` at the end of the edge. */
  node: BillingReseller;
};

/** Methods to use when ordering `BillingReseller`. */
export enum BillingResellersOrderBy {
  BillingDistributorIdAsc = "BILLING_DISTRIBUTOR_ID_ASC",
  BillingDistributorIdDesc = "BILLING_DISTRIBUTOR_ID_DESC",
  CfAllowLicenceAsc = "CF_ALLOW_LICENCE_ASC",
  CfAllowLicenceDesc = "CF_ALLOW_LICENCE_DESC",
  CfPartnerAsc = "CF_PARTNER_ASC",
  CfPartnerDesc = "CF_PARTNER_DESC",
  CfPrimaryAsc = "CF_PRIMARY_ASC",
  CfPrimaryDesc = "CF_PRIMARY_DESC",
  CityAsc = "CITY_ASC",
  CityDesc = "CITY_DESC",
  CompanyAsc = "COMPANY_ASC",
  CompanyDesc = "COMPANY_DESC",
  CountryAsc = "COUNTRY_ASC",
  CountryDesc = "COUNTRY_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DeletedAsc = "DELETED_ASC",
  DeletedDesc = "DELETED_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FirstNameAsc = "FIRST_NAME_ASC",
  FirstNameDesc = "FIRST_NAME_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InvoiceOwnerIdAsc = "INVOICE_OWNER_ID_ASC",
  InvoiceOwnerIdDesc = "INVOICE_OWNER_ID_DESC",
  LastNameAsc = "LAST_NAME_ASC",
  LastNameDesc = "LAST_NAME_DESC",
  Line1Asc = "LINE1_ASC",
  Line1Desc = "LINE1_DESC",
  Line2Asc = "LINE2_ASC",
  Line2Desc = "LINE2_DESC",
  Line3Asc = "LINE3_ASC",
  Line3Desc = "LINE3_DESC",
  Natural = "NATURAL",
  PaymentOwnerIdAsc = "PAYMENT_OWNER_ID_ASC",
  PaymentOwnerIdDesc = "PAYMENT_OWNER_ID_DESC",
  PhoneAsc = "PHONE_ASC",
  PhoneDesc = "PHONE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  StateAsc = "STATE_ASC",
  StateCodeAsc = "STATE_CODE_ASC",
  StateCodeDesc = "STATE_CODE_DESC",
  StateDesc = "STATE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  ZipAsc = "ZIP_ASC",
  ZipDesc = "ZIP_DESC",
}

export type BillingSubscription = Node & {
  __typename: "BillingSubscription";
  activatedAt: Maybe<Scalars["Datetime"]>;
  autoCollection: Maybe<Scalars["String"]>;
  baseCurrencyCode: Maybe<CurrencyCode>;
  /** Reads and enables pagination through a set of `BillingAddonSubscription`. */
  billingAddonSubscriptionsByBillingSubscriptionId: BillingAddonSubscriptionsConnection;
  /** Reads and enables pagination through a set of `BillingCouponSubscription`. */
  billingCouponSubscriptionsByBillingSubscriptionId: BillingCouponSubscriptionsConnection;
  /** Reads a single `BillingCustomer` that is related to this `BillingSubscription`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads and enables pagination through a set of `BillingInvoice`. */
  billingInvoicesByBillingSubscriptionId: BillingInvoicesConnection;
  /** Reads a single `BillingPlan` that is related to this `BillingSubscription`. */
  billingPlanByPlanId: Maybe<BillingPlan>;
  /** Reads a single `BillingRenewal` that is related to this `BillingSubscription`. */
  billingRenewalByBillingSubscriptionId: Maybe<BillingRenewal>;
  cancelledAt: Maybe<Scalars["Datetime"]>;
  cfPrimary: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  currencyCode: Maybe<CurrencyCode>;
  currentTermEnd: Maybe<Scalars["Datetime"]>;
  currentTermStart: Maybe<Scalars["Datetime"]>;
  dueInvoicesCount: Maybe<Scalars["Int"]>;
  dueSince: Maybe<Scalars["Datetime"]>;
  hasScheduledChanges: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  metaData: Maybe<Scalars["JSON"]>;
  nextBillingAt: Maybe<Scalars["Datetime"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingSubscription`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  pauseDate: Maybe<Scalars["Datetime"]>;
  paymentSourceId: Maybe<Scalars["String"]>;
  planAmount: Maybe<Scalars["Int"]>;
  planId: Maybe<Scalars["String"]>;
  planQuantity: Maybe<Scalars["Int"]>;
  planUnitPrice: Maybe<Scalars["Int"]>;
  poNumber: Maybe<Scalars["String"]>;
  raw: Maybe<Scalars["JSON"]>;
  remainingBillingCycles: Maybe<Scalars["Int"]>;
  resumeDate: Maybe<Scalars["Datetime"]>;
  startDate: Maybe<Scalars["Datetime"]>;
  startedAt: Maybe<Scalars["Datetime"]>;
  status: Maybe<Scalars["String"]>;
  totalDues: Maybe<Scalars["Int"]>;
  trialEnd: Maybe<Scalars["Datetime"]>;
  trialStart: Maybe<Scalars["Datetime"]>;
};

export type BillingSubscriptionBillingAddonSubscriptionsByBillingSubscriptionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionsOrderBy>>;
};

export type BillingSubscriptionBillingCouponSubscriptionsByBillingSubscriptionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionsOrderBy>>;
};

export type BillingSubscriptionBillingInvoicesByBillingSubscriptionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingInvoiceCondition>;
  filter: InputMaybe<BillingInvoiceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingInvoicesOrderBy>>;
};

/**
 * A condition to be used against `BillingSubscription` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BillingSubscriptionCondition = {
  /** Checks for equality with the object’s `activatedAt` field. */
  activatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `autoCollection` field. */
  autoCollection?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `baseCurrencyCode` field. */
  baseCurrencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cancelledAt` field. */
  cancelledAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `currentTermEnd` field. */
  currentTermEnd?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `currentTermStart` field. */
  currentTermStart?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `dueInvoicesCount` field. */
  dueInvoicesCount?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `dueSince` field. */
  dueSince?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `hasScheduledChanges` field. */
  hasScheduledChanges?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `metaData` field. */
  metaData?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `nextBillingAt` field. */
  nextBillingAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `pauseDate` field. */
  pauseDate?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `paymentSourceId` field. */
  paymentSourceId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `planAmount` field. */
  planAmount?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `planId` field. */
  planId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `planQuantity` field. */
  planQuantity?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `planUnitPrice` field. */
  planUnitPrice?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `poNumber` field. */
  poNumber?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `remainingBillingCycles` field. */
  remainingBillingCycles?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `resumeDate` field. */
  resumeDate?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `totalDues` field. */
  totalDues?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `trialEnd` field. */
  trialEnd?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `trialStart` field. */
  trialStart?: InputMaybe<Scalars["Datetime"]>;
};

/** A filter to be used against `BillingSubscription` object types. All fields are combined with a logical ‘and.’ */
export type BillingSubscriptionFilter = {
  /** Filter by the object’s `activatedAt` field. */
  activatedAt?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingSubscriptionFilter>>;
  /** Filter by the object’s `autoCollection` field. */
  autoCollection?: InputMaybe<StringFilter>;
  /** Filter by the object’s `baseCurrencyCode` field. */
  baseCurrencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cancelledAt` field. */
  cancelledAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `currentTermEnd` field. */
  currentTermEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currentTermStart` field. */
  currentTermStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dueInvoicesCount` field. */
  dueInvoicesCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `dueSince` field. */
  dueSince?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `hasScheduledChanges` field. */
  hasScheduledChanges?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metaData` field. */
  metaData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `nextBillingAt` field. */
  nextBillingAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingSubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingSubscriptionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `pauseDate` field. */
  pauseDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `paymentSourceId` field. */
  paymentSourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `planAmount` field. */
  planAmount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `planId` field. */
  planId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `planQuantity` field. */
  planQuantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `planUnitPrice` field. */
  planUnitPrice?: InputMaybe<IntFilter>;
  /** Filter by the object’s `poNumber` field. */
  poNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `remainingBillingCycles` field. */
  remainingBillingCycles?: InputMaybe<IntFilter>;
  /** Filter by the object’s `resumeDate` field. */
  resumeDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalDues` field. */
  totalDues?: InputMaybe<IntFilter>;
  /** Filter by the object’s `trialEnd` field. */
  trialEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `trialStart` field. */
  trialStart?: InputMaybe<DatetimeFilter>;
};

export type BillingSubscriptionFuture = Node & {
  __typename: "BillingSubscriptionFuture";
  activatedAt: Maybe<Scalars["Datetime"]>;
  autoCollection: Maybe<Scalars["String"]>;
  baseCurrencyCode: Maybe<CurrencyCode>;
  /** Reads and enables pagination through a set of `BillingAddonSubscriptionFuture`. */
  billingAddonSubscriptionFuturesByBillingSubscriptionId: BillingAddonSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingCouponSubscriptionFuture`. */
  billingCouponSubscriptionFuturesByBillingSubscriptionId: BillingCouponSubscriptionFuturesConnection;
  /** Reads a single `BillingCustomer` that is related to this `BillingSubscriptionFuture`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  billingCustomerId: Scalars["String"];
  /** Reads a single `BillingPlan` that is related to this `BillingSubscriptionFuture`. */
  billingPlanByPlanId: Maybe<BillingPlan>;
  /** Reads a single `BillingRenewalFuture` that is related to this `BillingSubscriptionFuture`. */
  billingRenewalFutureByBillingSubscriptionId: Maybe<BillingRenewalFuture>;
  cancelledAt: Maybe<Scalars["Datetime"]>;
  cfPrimary: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  currencyCode: Maybe<CurrencyCode>;
  currentTermEnd: Maybe<Scalars["Datetime"]>;
  currentTermStart: Maybe<Scalars["Datetime"]>;
  dueInvoicesCount: Maybe<Scalars["Int"]>;
  dueSince: Maybe<Scalars["Datetime"]>;
  hasScheduledChanges: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  metaData: Maybe<Scalars["JSON"]>;
  nextBillingAt: Maybe<Scalars["Datetime"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `BillingSubscriptionFuture`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  pauseDate: Maybe<Scalars["Datetime"]>;
  paymentSourceId: Maybe<Scalars["String"]>;
  planAmount: Maybe<Scalars["Int"]>;
  planId: Maybe<Scalars["String"]>;
  planQuantity: Maybe<Scalars["Int"]>;
  planUnitPrice: Maybe<Scalars["Int"]>;
  poNumber: Maybe<Scalars["String"]>;
  raw: Maybe<Scalars["JSON"]>;
  remainingBillingCycles: Maybe<Scalars["Int"]>;
  resumeDate: Maybe<Scalars["Datetime"]>;
  startDate: Maybe<Scalars["Datetime"]>;
  startedAt: Maybe<Scalars["Datetime"]>;
  status: Maybe<Scalars["String"]>;
  totalDues: Maybe<Scalars["Int"]>;
  trialEnd: Maybe<Scalars["Datetime"]>;
  trialStart: Maybe<Scalars["Datetime"]>;
};

export type BillingSubscriptionFutureBillingAddonSubscriptionFuturesByBillingSubscriptionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionFutureCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionFuturesOrderBy>>;
};

export type BillingSubscriptionFutureBillingCouponSubscriptionFuturesByBillingSubscriptionIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionFutureCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionFuturesOrderBy>>;
};

/**
 * A condition to be used against `BillingSubscriptionFuture` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BillingSubscriptionFutureCondition = {
  /** Checks for equality with the object’s `activatedAt` field. */
  activatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `autoCollection` field. */
  autoCollection?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `baseCurrencyCode` field. */
  baseCurrencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `cancelledAt` field. */
  cancelledAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `currentTermEnd` field. */
  currentTermEnd?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `currentTermStart` field. */
  currentTermStart?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `dueInvoicesCount` field. */
  dueInvoicesCount?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `dueSince` field. */
  dueSince?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `hasScheduledChanges` field. */
  hasScheduledChanges?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `metaData` field. */
  metaData?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `nextBillingAt` field. */
  nextBillingAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `pauseDate` field. */
  pauseDate?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `paymentSourceId` field. */
  paymentSourceId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `planAmount` field. */
  planAmount?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `planId` field. */
  planId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `planQuantity` field. */
  planQuantity?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `planUnitPrice` field. */
  planUnitPrice?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `poNumber` field. */
  poNumber?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `remainingBillingCycles` field. */
  remainingBillingCycles?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `resumeDate` field. */
  resumeDate?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `totalDues` field. */
  totalDues?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `trialEnd` field. */
  trialEnd?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `trialStart` field. */
  trialStart?: InputMaybe<Scalars["Datetime"]>;
};

/** A filter to be used against `BillingSubscriptionFuture` object types. All fields are combined with a logical ‘and.’ */
export type BillingSubscriptionFutureFilter = {
  /** Filter by the object’s `activatedAt` field. */
  activatedAt?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BillingSubscriptionFutureFilter>>;
  /** Filter by the object’s `autoCollection` field. */
  autoCollection?: InputMaybe<StringFilter>;
  /** Filter by the object’s `baseCurrencyCode` field. */
  baseCurrencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cancelledAt` field. */
  cancelledAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cfPrimary` field. */
  cfPrimary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `currentTermEnd` field. */
  currentTermEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currentTermStart` field. */
  currentTermStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dueInvoicesCount` field. */
  dueInvoicesCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `dueSince` field. */
  dueSince?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `hasScheduledChanges` field. */
  hasScheduledChanges?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metaData` field. */
  metaData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `nextBillingAt` field. */
  nextBillingAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BillingSubscriptionFutureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BillingSubscriptionFutureFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `pauseDate` field. */
  pauseDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `paymentSourceId` field. */
  paymentSourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `planAmount` field. */
  planAmount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `planId` field. */
  planId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `planQuantity` field. */
  planQuantity?: InputMaybe<IntFilter>;
  /** Filter by the object’s `planUnitPrice` field. */
  planUnitPrice?: InputMaybe<IntFilter>;
  /** Filter by the object’s `poNumber` field. */
  poNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `remainingBillingCycles` field. */
  remainingBillingCycles?: InputMaybe<IntFilter>;
  /** Filter by the object’s `resumeDate` field. */
  resumeDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalDues` field. */
  totalDues?: InputMaybe<IntFilter>;
  /** Filter by the object’s `trialEnd` field. */
  trialEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `trialStart` field. */
  trialStart?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `BillingSubscriptionFuture` values. */
export type BillingSubscriptionFuturesConnection = {
  __typename: "BillingSubscriptionFuturesConnection";
  /** A list of edges which contains the `BillingSubscriptionFuture` and cursor to aid in pagination. */
  edges: Array<BillingSubscriptionFuturesEdge>;
  /** A list of `BillingSubscriptionFuture` objects. */
  nodes: Array<BillingSubscriptionFuture>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingSubscriptionFuture` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingSubscriptionFuture` edge in the connection. */
export type BillingSubscriptionFuturesEdge = {
  __typename: "BillingSubscriptionFuturesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingSubscriptionFuture` at the end of the edge. */
  node: BillingSubscriptionFuture;
};

/** Methods to use when ordering `BillingSubscriptionFuture`. */
export enum BillingSubscriptionFuturesOrderBy {
  ActivatedAtAsc = "ACTIVATED_AT_ASC",
  ActivatedAtDesc = "ACTIVATED_AT_DESC",
  AutoCollectionAsc = "AUTO_COLLECTION_ASC",
  AutoCollectionDesc = "AUTO_COLLECTION_DESC",
  BaseCurrencyCodeAsc = "BASE_CURRENCY_CODE_ASC",
  BaseCurrencyCodeDesc = "BASE_CURRENCY_CODE_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  CancelledAtAsc = "CANCELLED_AT_ASC",
  CancelledAtDesc = "CANCELLED_AT_DESC",
  CfPrimaryAsc = "CF_PRIMARY_ASC",
  CfPrimaryDesc = "CF_PRIMARY_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CurrencyCodeAsc = "CURRENCY_CODE_ASC",
  CurrencyCodeDesc = "CURRENCY_CODE_DESC",
  CurrentTermEndAsc = "CURRENT_TERM_END_ASC",
  CurrentTermEndDesc = "CURRENT_TERM_END_DESC",
  CurrentTermStartAsc = "CURRENT_TERM_START_ASC",
  CurrentTermStartDesc = "CURRENT_TERM_START_DESC",
  DueInvoicesCountAsc = "DUE_INVOICES_COUNT_ASC",
  DueInvoicesCountDesc = "DUE_INVOICES_COUNT_DESC",
  DueSinceAsc = "DUE_SINCE_ASC",
  DueSinceDesc = "DUE_SINCE_DESC",
  HasScheduledChangesAsc = "HAS_SCHEDULED_CHANGES_ASC",
  HasScheduledChangesDesc = "HAS_SCHEDULED_CHANGES_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetaDataAsc = "META_DATA_ASC",
  MetaDataDesc = "META_DATA_DESC",
  Natural = "NATURAL",
  NextBillingAtAsc = "NEXT_BILLING_AT_ASC",
  NextBillingAtDesc = "NEXT_BILLING_AT_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PauseDateAsc = "PAUSE_DATE_ASC",
  PauseDateDesc = "PAUSE_DATE_DESC",
  PaymentSourceIdAsc = "PAYMENT_SOURCE_ID_ASC",
  PaymentSourceIdDesc = "PAYMENT_SOURCE_ID_DESC",
  PlanAmountAsc = "PLAN_AMOUNT_ASC",
  PlanAmountDesc = "PLAN_AMOUNT_DESC",
  PlanIdAsc = "PLAN_ID_ASC",
  PlanIdDesc = "PLAN_ID_DESC",
  PlanQuantityAsc = "PLAN_QUANTITY_ASC",
  PlanQuantityDesc = "PLAN_QUANTITY_DESC",
  PlanUnitPriceAsc = "PLAN_UNIT_PRICE_ASC",
  PlanUnitPriceDesc = "PLAN_UNIT_PRICE_DESC",
  PoNumberAsc = "PO_NUMBER_ASC",
  PoNumberDesc = "PO_NUMBER_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  RemainingBillingCyclesAsc = "REMAINING_BILLING_CYCLES_ASC",
  RemainingBillingCyclesDesc = "REMAINING_BILLING_CYCLES_DESC",
  ResumeDateAsc = "RESUME_DATE_ASC",
  ResumeDateDesc = "RESUME_DATE_DESC",
  StartedAtAsc = "STARTED_AT_ASC",
  StartedAtDesc = "STARTED_AT_DESC",
  StartDateAsc = "START_DATE_ASC",
  StartDateDesc = "START_DATE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TotalDuesAsc = "TOTAL_DUES_ASC",
  TotalDuesDesc = "TOTAL_DUES_DESC",
  TrialEndAsc = "TRIAL_END_ASC",
  TrialEndDesc = "TRIAL_END_DESC",
  TrialStartAsc = "TRIAL_START_ASC",
  TrialStartDesc = "TRIAL_START_DESC",
}

/** A connection to a list of `BillingSubscription` values. */
export type BillingSubscriptionsConnection = {
  __typename: "BillingSubscriptionsConnection";
  /** A list of edges which contains the `BillingSubscription` and cursor to aid in pagination. */
  edges: Array<BillingSubscriptionsEdge>;
  /** A list of `BillingSubscription` objects. */
  nodes: Array<BillingSubscription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BillingSubscription` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `BillingSubscription` edge in the connection. */
export type BillingSubscriptionsEdge = {
  __typename: "BillingSubscriptionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `BillingSubscription` at the end of the edge. */
  node: BillingSubscription;
};

/** Methods to use when ordering `BillingSubscription`. */
export enum BillingSubscriptionsOrderBy {
  ActivatedAtAsc = "ACTIVATED_AT_ASC",
  ActivatedAtDesc = "ACTIVATED_AT_DESC",
  AutoCollectionAsc = "AUTO_COLLECTION_ASC",
  AutoCollectionDesc = "AUTO_COLLECTION_DESC",
  BaseCurrencyCodeAsc = "BASE_CURRENCY_CODE_ASC",
  BaseCurrencyCodeDesc = "BASE_CURRENCY_CODE_DESC",
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  CancelledAtAsc = "CANCELLED_AT_ASC",
  CancelledAtDesc = "CANCELLED_AT_DESC",
  CfPrimaryAsc = "CF_PRIMARY_ASC",
  CfPrimaryDesc = "CF_PRIMARY_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CurrencyCodeAsc = "CURRENCY_CODE_ASC",
  CurrencyCodeDesc = "CURRENCY_CODE_DESC",
  CurrentTermEndAsc = "CURRENT_TERM_END_ASC",
  CurrentTermEndDesc = "CURRENT_TERM_END_DESC",
  CurrentTermStartAsc = "CURRENT_TERM_START_ASC",
  CurrentTermStartDesc = "CURRENT_TERM_START_DESC",
  DueInvoicesCountAsc = "DUE_INVOICES_COUNT_ASC",
  DueInvoicesCountDesc = "DUE_INVOICES_COUNT_DESC",
  DueSinceAsc = "DUE_SINCE_ASC",
  DueSinceDesc = "DUE_SINCE_DESC",
  HasScheduledChangesAsc = "HAS_SCHEDULED_CHANGES_ASC",
  HasScheduledChangesDesc = "HAS_SCHEDULED_CHANGES_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetaDataAsc = "META_DATA_ASC",
  MetaDataDesc = "META_DATA_DESC",
  Natural = "NATURAL",
  NextBillingAtAsc = "NEXT_BILLING_AT_ASC",
  NextBillingAtDesc = "NEXT_BILLING_AT_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PauseDateAsc = "PAUSE_DATE_ASC",
  PauseDateDesc = "PAUSE_DATE_DESC",
  PaymentSourceIdAsc = "PAYMENT_SOURCE_ID_ASC",
  PaymentSourceIdDesc = "PAYMENT_SOURCE_ID_DESC",
  PlanAmountAsc = "PLAN_AMOUNT_ASC",
  PlanAmountDesc = "PLAN_AMOUNT_DESC",
  PlanIdAsc = "PLAN_ID_ASC",
  PlanIdDesc = "PLAN_ID_DESC",
  PlanQuantityAsc = "PLAN_QUANTITY_ASC",
  PlanQuantityDesc = "PLAN_QUANTITY_DESC",
  PlanUnitPriceAsc = "PLAN_UNIT_PRICE_ASC",
  PlanUnitPriceDesc = "PLAN_UNIT_PRICE_DESC",
  PoNumberAsc = "PO_NUMBER_ASC",
  PoNumberDesc = "PO_NUMBER_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RawAsc = "RAW_ASC",
  RawDesc = "RAW_DESC",
  RemainingBillingCyclesAsc = "REMAINING_BILLING_CYCLES_ASC",
  RemainingBillingCyclesDesc = "REMAINING_BILLING_CYCLES_DESC",
  ResumeDateAsc = "RESUME_DATE_ASC",
  ResumeDateDesc = "RESUME_DATE_DESC",
  StartedAtAsc = "STARTED_AT_ASC",
  StartedAtDesc = "STARTED_AT_DESC",
  StartDateAsc = "START_DATE_ASC",
  StartDateDesc = "START_DATE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TotalDuesAsc = "TOTAL_DUES_ASC",
  TotalDuesDesc = "TOTAL_DUES_DESC",
  TrialEndAsc = "TRIAL_END_ASC",
  TrialEndDesc = "TRIAL_END_DESC",
  TrialStartAsc = "TRIAL_START_ASC",
  TrialStartDesc = "TRIAL_START_DESC",
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Boolean"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Boolean"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Boolean"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Boolean"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Boolean"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Boolean"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Boolean"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Boolean"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Boolean"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Boolean"]>>;
};

export type BrandInfoColor = {
  __typename: "BrandInfoColor";
  brightness: Maybe<Scalars["Int"]>;
  hex: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
};

export type BrandInfoContentFormat = {
  __typename: "BrandInfoContentFormat";
  background: Maybe<Scalars["String"]>;
  format: Maybe<Scalars["String"]>;
  height: Maybe<Scalars["Int"]>;
  size: Maybe<Scalars["Int"]>;
  src: Maybe<Scalars["String"]>;
  width: Maybe<Scalars["Int"]>;
};

export type BrandInfoFont = {
  __typename: "BrandInfoFont";
  name: Maybe<Scalars["String"]>;
  origin: Maybe<Scalars["String"]>;
  originId: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
};

export type BrandInfoImage = {
  __typename: "BrandInfoImage";
  formats: Maybe<Array<Maybe<BrandInfoContentFormat>>>;
  type: Maybe<Scalars["String"]>;
};

export type BrandInfoLink = {
  __typename: "BrandInfoLink";
  name: Maybe<Scalars["String"]>;
  url: Maybe<Scalars["String"]>;
};

export type BrandInfoLogo = {
  __typename: "BrandInfoLogo";
  formats: Maybe<Array<Maybe<BrandInfoContentFormat>>>;
  theme: Maybe<Scalars["String"]>;
  type: Maybe<Scalars["String"]>;
};

/** All input for the `bulkCreateCasts` mutation. */
export type BulkCreateCastsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  isUpdateByOrgId?: InputMaybe<Scalars["Boolean"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `bulkCreateCasts` mutation. */
export type BulkCreateCastsPayload = {
  __typename: "BulkCreateCastsPayload";
  casts: Maybe<Array<Cast>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `bulkResetScreenLocation` mutation. */
export type BulkResetScreenLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isResetByOrgId?: InputMaybe<Scalars["Boolean"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `bulkResetScreenLocation` mutation. */
export type BulkResetScreenLocationPayload = {
  __typename: "BulkResetScreenLocationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `bulkUpdateScreenContent` mutation. */
export type BulkUpdateScreenContentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  isUpdateByOrgId?: InputMaybe<Scalars["Boolean"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `bulkUpdateScreenContent` mutation. */
export type BulkUpdateScreenContentPayload = {
  __typename: "BulkUpdateScreenContentPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `bulkUpdateScreenLocation` mutation. */
export type BulkUpdateScreenLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isUpdateByOrgId?: InputMaybe<Scalars["Boolean"]>;
  locationDetail?: InputMaybe<Scalars["JSON"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `bulkUpdateScreenLocation` mutation. */
export type BulkUpdateScreenLocationPayload = {
  __typename: "BulkUpdateScreenLocationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `bulkUpdateScreenTimezone` mutation. */
export type BulkUpdateScreenTimezoneInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isUpdateByOrgId?: InputMaybe<Scalars["Boolean"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  timezoneOverride?: InputMaybe<Scalars["String"]>;
};

/** The output of our `bulkUpdateScreenTimezone` mutation. */
export type BulkUpdateScreenTimezonePayload = {
  __typename: "BulkUpdateScreenTimezonePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

export type CardIntentConfirmInput = {
  paymentIntentId: Scalars["String"];
  spaceId?: InputMaybe<Scalars["String"]>;
};

export type CardIntentConfirmPayload = {
  __typename: "CardIntentConfirmPayload";
  clientSecret: Scalars["String"];
  id: Scalars["String"];
  query: Maybe<Query>;
  status: Scalars["String"];
};

export type CardIntentPaymentInput = {
  licences: Scalars["Int"];
  spaceId?: InputMaybe<Scalars["String"]>;
  term: Scalars["String"];
};

export type CardIntentPaymentPayload = {
  __typename: "CardIntentPaymentPayload";
  clientSecret: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["String"]>;
  nextActionType: Maybe<Scalars["String"]>;
  query: Maybe<Query>;
  requiresAction: Scalars["Boolean"];
  status: Maybe<Scalars["String"]>;
};

export type CardIntentSetupInput = {
  paymentMethodId: Scalars["String"];
  spaceId?: InputMaybe<Scalars["String"]>;
};

export type CardIntentSetupPayload = {
  __typename: "CardIntentSetupPayload";
  clientSecret: Scalars["String"];
  id: Scalars["String"];
  nextActionType: Maybe<Scalars["String"]>;
  query: Maybe<Query>;
  requiresAction: Scalars["Boolean"];
  status: Scalars["String"];
};

export type Cast = Node & {
  __typename: "Cast";
  /** Get casted app. */
  appInstanceByCastId: Maybe<AppInstance>;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByFromCastAndOrgId: AssociationsConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByToCastAndOrgId: AssociationsConnection;
  /** Get casted channel. */
  channelByCastId: Maybe<Channel>;
  content: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  expiresAt: Maybe<Scalars["Datetime"]>;
  /** Get casted file. */
  fileByCastId: Maybe<File>;
  id: Scalars["UUID"];
  /** Get casted link. */
  linkByCastId: Maybe<Link>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Cast`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Get casted playlist. */
  playlistByCastId: Maybe<Playlist>;
  priority: Maybe<Scalars["Int"]>;
  /** Reads and enables pagination through a set of `Screen`. */
  screensByCastId: ScreensConnection;
  /** Get casted Site. */
  siteByCastId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Cast`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Scalars["UUID"];
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `Cast`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Cast`. */
  userByUpdatedBy: Maybe<User>;
};

export type CastAssociationsByFromCastAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

export type CastAssociationsByToCastAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

export type CastScreensByCastIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** A condition to be used against `Cast` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CastCondition = {
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Cast` object types. All fields are combined with a logical ‘and.’ */
export type CastFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CastFilter>>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CastFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CastFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** All input for the `castStart` mutation. */
export type CastStartInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `castStart` mutation. */
export type CastStartPayload = {
  __typename: "CastStartPayload";
  cast: Maybe<Cast>;
  /** An edge for our `Cast`. May be used by Relay 1. */
  castEdge: Maybe<CastsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Cast`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Cast`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Cast`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Cast`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `castStart` mutation. */
export type CastStartPayloadCastEdgeArgs = {
  orderBy?: InputMaybe<Array<CastsOrderBy>>;
};

/** All input for the `castStop` mutation. */
export type CastStopInput = {
  castId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `castStop` mutation. */
export type CastStopPayload = {
  __typename: "CastStopPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `castableScreens` mutation. */
export type CastableScreensInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  priority: Scalars["Int"];
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
  targetSpaceId: Scalars["UUID"];
};

/** The output of our `castableScreens` mutation. */
export type CastableScreensPayload = {
  __typename: "CastableScreensPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** A connection to a list of `Cast` values. */
export type CastsConnection = {
  __typename: "CastsConnection";
  /** A list of edges which contains the `Cast` and cursor to aid in pagination. */
  edges: Array<CastsEdge>;
  /** A list of `Cast` objects. */
  nodes: Array<Cast>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cast` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Cast` edge in the connection. */
export type CastsEdge = {
  __typename: "CastsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Cast` at the end of the edge. */
  node: Cast;
};

/** Methods to use when ordering `Cast`. */
export enum CastsOrderBy {
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  ExpiresAtAsc = "EXPIRES_AT_ASC",
  ExpiresAtDesc = "EXPIRES_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PriorityAsc = "PRIORITY_ASC",
  PriorityDesc = "PRIORITY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

/** The channel information. */
export type Channel = Node & {
  __typename: "Channel";
  /** Get apps that contained in a channel. */
  appInstancesByChannelId: AppInstancesConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByFromChannelAndOrgId: AssociationsConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByToChannelAndOrgId: AssociationsConnection;
  /** Get casted screen(s) by channel's id. */
  castedScreenByChannelId: ScreensConnection;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** Get channel that contained in a channel. */
  channelsByChannelId: ChannelsConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByChildOf: ChannelsConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByDraftOf: ChannelsConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByDuplicateOf: ChannelsConnection;
  childOf: Maybe<Scalars["UUID"]>;
  /** The information of the content structure in the channel. */
  content: Maybe<Scalars["JSON"]>;
  /** The channel cover icon detail include glyph and background color. */
  coverData: Maybe<Scalars["JSON"]>;
  /** The channel cover image file. */
  coverImageId: Maybe<Scalars["UUID"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Helper function for return draft version of a channel. */
  draft: Maybe<Channel>;
  /** The uuid of the published channel of this channel. */
  draftOf: Maybe<Scalars["UUID"]>;
  duplicateOf: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Embed`. */
  embedsByChannelId: EmbedsConnection;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Get files that contained in a channel. */
  filesByChannelId: FilesConnection;
  /** The width dimension of the channel. */
  height: Maybe<Scalars["Int"]>;
  /** Toggle enable hidden zone for channel. */
  hiddenZone: Maybe<Scalars["Boolean"]>;
  /** The uuid of the channel. */
  id: Scalars["UUID"];
  /** Indicate embed is enabled */
  isEmbedEnabled: Maybe<Scalars["Boolean"]>;
  /** Indicate embed is public */
  isEmbedPublic: Maybe<Scalars["Boolean"]>;
  isEnabled: Maybe<Scalars["Boolean"]>;
  /** To identify the channel is the hidden channel from listing in the Channel page. */
  isHidden: Maybe<Scalars["Boolean"]>;
  /** Is this channel a parent channel */
  isParent: Maybe<Scalars["Boolean"]>;
  /** To identify the current channel's draft content has been published. */
  isPublished: Maybe<Scalars["Boolean"]>;
  isShared: Maybe<Scalars["Boolean"]>;
  isSharedAll: Maybe<Scalars["Boolean"]>;
  isSharedExplicit: Maybe<Scalars["Boolean"]>;
  layoutByChannel: Maybe<Layout>;
  /** The uuid reference to layout of the channel. */
  layoutId: Maybe<Scalars["UUID"]>;
  /** Get links that contained in a channel. */
  linksByChannelId: LinksConnection;
  /** The name of the channel. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization that channel belong to. */
  orgId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `Org`. */
  orgsByBlankChannelId: OrgsConnection;
  /** Reads and enables pagination through a set of `Org`. */
  orgsByBrandChannelId: OrgsConnection;
  /** Reads and enables pagination through a set of `Org`. */
  orgsByStartChannelId: OrgsConnection;
  /** Get parent channel by child of */
  parentChannelByChildOf: Maybe<ParentChannelByChildOf>;
  /** Number of screens that currently playing the channel. */
  playingScreenNum: Maybe<Scalars["BigInt"]>;
  /** Get playlist that contained in a channel. */
  playlistsByChannelId: PlaylistsConnection;
  preferences: Maybe<Scalars["JSON"]>;
  /** Helper function for return published version of a channel. */
  published: Maybe<Channel>;
  publishedAt: Maybe<Scalars["Datetime"]>;
  publishedBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByChannelIdAndOrgId: ScreenContentHistoriesConnection;
  /** Screens that currently playing the channel. */
  screensByChannelId: ScreensConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareChannel: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareChannelAndOrgId: ShareAssociationsConnection;
  /** Spaces that currently got shared access to the channel. */
  sharedSpacesByChannelId: SpacesConnection;
  /** Get shared spaces with sharing mode by channel id */
  sharedSpacesWithSharingModeByChannelId: SharedSpaceWithSharingModesConnection;
  /** shortId for embeding */
  shortId: Maybe<Scalars["String"]>;
  /** Get Sites that are contained in a channel. */
  sitesByChannelId: SitesConnection;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** The uuid of the space that channel belong to. */
  spaceId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByToChannelAndOrgId: TagAssociationsConnection;
  tags: Array<Maybe<Scalars["String"]>>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Theme for the channel. */
  themeId: Maybe<Scalars["UUID"]>;
  /** Indicate a type of the channel */
  type: ChannelType;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /**
   * The flag for telling that is the channel using the organization default theme.
   * @deprecated true
   */
  useOrgDefaultTheme: Maybe<Scalars["Boolean"]>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
  /** The height dimension of the channel. */
  width: Maybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelAppInstancesByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelAssociationsByFromChannelAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The channel information. */
export type ChannelAssociationsByToChannelAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The channel information. */
export type ChannelCastedScreenByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelChannelsByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelChannelsByChildOfArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** The channel information. */
export type ChannelChannelsByDraftOfArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** The channel information. */
export type ChannelChannelsByDuplicateOfArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** The channel information. */
export type ChannelEmbedsByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<EmbedCondition>;
  filter: InputMaybe<EmbedFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EmbedsOrderBy>>;
};

/** The channel information. */
export type ChannelFilesByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelLinksByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelOrgsByBlankChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** The channel information. */
export type ChannelOrgsByBrandChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** The channel information. */
export type ChannelOrgsByStartChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** The channel information. */
export type ChannelPlaylistsByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelScreenContentHistoriesByChannelIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** The channel information. */
export type ChannelScreensByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelShareAssociationsByShareChannelArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The channel information. */
export type ChannelShareAssociationsByShareChannelAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The channel information. */
export type ChannelSharedSpacesByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelSharedSpacesWithSharingModeByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  filter: InputMaybe<SharedSpaceWithSharingModeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelSitesByChannelIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The channel information. */
export type ChannelTagAssociationsByToChannelAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** A condition to be used against `Channel` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ChannelCondition = {
  /** Checks for equality with the object’s `childOf` field. */
  childOf?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `coverData` field. */
  coverData?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `coverImageId` field. */
  coverImageId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `draftOf` field. */
  draftOf?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `duplicateOf` field. */
  duplicateOf?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `height` field. */
  height?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `hiddenZone` field. */
  hiddenZone?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isEmbedEnabled` field. */
  isEmbedEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isEmbedPublic` field. */
  isEmbedPublic?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isEnabled` field. */
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isHidden` field. */
  isHidden?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isPublished` field. */
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isShared` field. */
  isShared?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `layoutId` field. */
  layoutId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `preferences` field. */
  preferences?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `publishedBy` field. */
  publishedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `themeId` field. */
  themeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<ChannelType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `useOrgDefaultTheme` field. */
  useOrgDefaultTheme?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `width` field. */
  width?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `Channel` object types. All fields are combined with a logical ‘and.’ */
export type ChannelFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ChannelFilter>>;
  /** Filter by the object’s `childOf` field. */
  childOf?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `coverData` field. */
  coverData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `coverImageId` field. */
  coverImageId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `draftOf` field. */
  draftOf?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `duplicateOf` field. */
  duplicateOf?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<IntFilter>;
  /** Filter by the object’s `hiddenZone` field. */
  hiddenZone?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isEmbedEnabled` field. */
  isEmbedEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isEmbedPublic` field. */
  isEmbedPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isEnabled` field. */
  isEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isHidden` field. */
  isHidden?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPublished` field. */
  isPublished?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `layoutId` field. */
  layoutId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ChannelFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ChannelFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `preferences` field. */
  preferences?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `publishedBy` field. */
  publishedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `themeId` field. */
  themeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<ChannelTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `useOrgDefaultTheme` field. */
  useOrgDefaultTheme?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `width` field. */
  width?: InputMaybe<IntFilter>;
};

export enum ChannelType {
  Blank = "BLANK",
  Default = "DEFAULT",
  Splash = "SPLASH",
}

/** A filter to be used against ChannelType fields. All fields are combined with a logical ‘and.’ */
export type ChannelTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ChannelType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ChannelType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ChannelType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ChannelType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ChannelType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ChannelType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ChannelType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ChannelType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ChannelType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ChannelType>>;
};

/** A connection to a list of `Channel` values. */
export type ChannelsConnection = {
  __typename: "ChannelsConnection";
  /** A list of edges which contains the `Channel` and cursor to aid in pagination. */
  edges: Array<ChannelsEdge>;
  /** A list of `Channel` objects. */
  nodes: Array<Channel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Channel` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Channel` edge in the connection. */
export type ChannelsEdge = {
  __typename: "ChannelsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Channel` at the end of the edge. */
  node: Channel;
};

/** Methods to use when ordering `Channel`. */
export enum ChannelsOrderBy {
  ChildOfAsc = "CHILD_OF_ASC",
  ChildOfDesc = "CHILD_OF_DESC",
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  CoverDataAsc = "COVER_DATA_ASC",
  CoverDataDesc = "COVER_DATA_DESC",
  CoverImageIdAsc = "COVER_IMAGE_ID_ASC",
  CoverImageIdDesc = "COVER_IMAGE_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DraftOfAsc = "DRAFT_OF_ASC",
  DraftOfDesc = "DRAFT_OF_DESC",
  DuplicateOfAsc = "DUPLICATE_OF_ASC",
  DuplicateOfDesc = "DUPLICATE_OF_DESC",
  HeightAsc = "HEIGHT_ASC",
  HeightDesc = "HEIGHT_DESC",
  HiddenZoneAsc = "HIDDEN_ZONE_ASC",
  HiddenZoneDesc = "HIDDEN_ZONE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsEmbedEnabledAsc = "IS_EMBED_ENABLED_ASC",
  IsEmbedEnabledDesc = "IS_EMBED_ENABLED_DESC",
  IsEmbedPublicAsc = "IS_EMBED_PUBLIC_ASC",
  IsEmbedPublicDesc = "IS_EMBED_PUBLIC_DESC",
  IsEnabledAsc = "IS_ENABLED_ASC",
  IsEnabledDesc = "IS_ENABLED_DESC",
  IsHiddenAsc = "IS_HIDDEN_ASC",
  IsHiddenDesc = "IS_HIDDEN_DESC",
  IsPublishedAsc = "IS_PUBLISHED_ASC",
  IsPublishedDesc = "IS_PUBLISHED_DESC",
  IsSharedAllAsc = "IS_SHARED_ALL_ASC",
  IsSharedAllDesc = "IS_SHARED_ALL_DESC",
  IsSharedAsc = "IS_SHARED_ASC",
  IsSharedDesc = "IS_SHARED_DESC",
  IsSharedExplicitAsc = "IS_SHARED_EXPLICIT_ASC",
  IsSharedExplicitDesc = "IS_SHARED_EXPLICIT_DESC",
  LayoutIdAsc = "LAYOUT_ID_ASC",
  LayoutIdDesc = "LAYOUT_ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PreferencesAsc = "PREFERENCES_ASC",
  PreferencesDesc = "PREFERENCES_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PublishedAtAsc = "PUBLISHED_AT_ASC",
  PublishedAtDesc = "PUBLISHED_AT_DESC",
  PublishedByAsc = "PUBLISHED_BY_ASC",
  PublishedByDesc = "PUBLISHED_BY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  ThemeIdAsc = "THEME_ID_ASC",
  ThemeIdDesc = "THEME_ID_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  UseOrgDefaultThemeAsc = "USE_ORG_DEFAULT_THEME_ASC",
  UseOrgDefaultThemeDesc = "USE_ORG_DEFAULT_THEME_DESC",
  WidthAsc = "WIDTH_ASC",
  WidthDesc = "WIDTH_DESC",
}

/** All input for the `clearCacheScreen` mutation. */
export type ClearCacheScreenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  device: Scalars["JSON"];
  screenId: Scalars["UUID"];
};

/** The output of our `clearCacheScreen` mutation. */
export type ClearCacheScreenPayload = {
  __typename: "ClearCacheScreenPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `clearCacheScreen` mutation. */
export type ClearCacheScreenPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

export type ClearCacheScreensByOrgIdPayload = {
  __typename: "ClearCacheScreensByOrgIdPayload";
  clearCacheFailed: Maybe<Scalars["Int"]>;
  clearCacheSucceeded: Maybe<Scalars["Int"]>;
  error: Maybe<Scalars["String"]>;
};

export type ClearCacheScreensByScreenIdsPayload = {
  __typename: "ClearCacheScreensByScreenIdsPayload";
  clearCacheFailed: Maybe<Scalars["Int"]>;
  clearCacheSucceeded: Maybe<Scalars["Int"]>;
  error: Maybe<Scalars["String"]>;
};

export type ClearCacheScreensBySpaceIdPayload = {
  __typename: "ClearCacheScreensBySpaceIdPayload";
  clearCacheFailed: Maybe<Scalars["Int"]>;
  clearCacheSucceeded: Maybe<Scalars["Int"]>;
  error: Maybe<Scalars["String"]>;
};

export enum CommandType {
  ClearCache = "clear_cache",
  ClearMediaCache = "clear_media_cache",
  ForceHardReset = "force_hard_reset",
  Mute = "mute",
  Reboot = "reboot",
  ResetAuth = "reset_auth",
  ResetPairing = "reset_pairing",
  RestartPlayerApp = "restart_player_app",
  UpdateDeviceInfo = "update_device_info",
}

/** App Instance Templates for e.g. Canvas app. */
export type CommonAppInstanceTemplate = Node & {
  __typename: "CommonAppInstanceTemplate";
  /** Reads a single `App` that is related to this `CommonAppInstanceTemplate`. */
  appByAppId: Maybe<App>;
  appId: Scalars["UUID"];
  /** Reads a single `AppInstance` that is related to this `CommonAppInstanceTemplate`. */
  appInstanceBySourceAppInstanceId: Maybe<AppInstance>;
  /** Reads a single `AppInstance` that is related to this `CommonAppInstanceTemplate`. */
  appInstanceByTargetCommonAppInstanceTemplateId: Maybe<AppInstance>;
  /** Reads and enables pagination through a set of `AppInstance`. */
  appInstancesByCommonAppInstanceTemplateId: AppInstancesConnection;
  /** Reads a single `CommonFile` that is related to this `CommonAppInstanceTemplate`. */
  commonFileByThumbnailFileId: Maybe<CommonFile>;
  config: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  id: Scalars["UUID"];
  isDeprecated: Scalars["Boolean"];
  isPublished: Scalars["Boolean"];
  name: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `CommonAppInstanceTemplate`. */
  orgBySourceOrgId: Maybe<Org>;
  sourceAppInstanceId: Maybe<Scalars["UUID"]>;
  sourceOrgId: Maybe<Scalars["UUID"]>;
  state: Maybe<Scalars["JSON"]>;
  status: Maybe<AppInstanceStatus>;
  tags: Array<Maybe<Scalars["String"]>>;
  thumbnailFileId: Maybe<Scalars["UUID"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  version: Maybe<Scalars["String"]>;
};

/** App Instance Templates for e.g. Canvas app. */
export type CommonAppInstanceTemplateAppInstancesByCommonAppInstanceTemplateIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/**
 * A condition to be used against `CommonAppInstanceTemplate` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CommonAppInstanceTemplateCondition = {
  /** Checks for equality with the object’s `appId` field. */
  appId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isDeprecated` field. */
  isDeprecated?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isPublished` field. */
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `sourceAppInstanceId` field. */
  sourceAppInstanceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `sourceOrgId` field. */
  sourceOrgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<AppInstanceStatus>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `thumbnailFileId` field. */
  thumbnailFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `CommonAppInstanceTemplate` object types. All fields are combined with a logical ‘and.’ */
export type CommonAppInstanceTemplateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CommonAppInstanceTemplateFilter>>;
  /** Filter by the object’s `appId` field. */
  appId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDeprecated` field. */
  isDeprecated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPublished` field. */
  isPublished?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CommonAppInstanceTemplateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CommonAppInstanceTemplateFilter>>;
  /** Filter by the object’s `sourceAppInstanceId` field. */
  sourceAppInstanceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sourceOrgId` field. */
  sourceOrgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<AppInstanceStatusFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `thumbnailFileId` field. */
  thumbnailFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** A connection to a list of `CommonAppInstanceTemplate` values. */
export type CommonAppInstanceTemplatesConnection = {
  __typename: "CommonAppInstanceTemplatesConnection";
  /** A list of edges which contains the `CommonAppInstanceTemplate` and cursor to aid in pagination. */
  edges: Array<CommonAppInstanceTemplatesEdge>;
  /** A list of `CommonAppInstanceTemplate` objects. */
  nodes: Array<CommonAppInstanceTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CommonAppInstanceTemplate` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `CommonAppInstanceTemplate` edge in the connection. */
export type CommonAppInstanceTemplatesEdge = {
  __typename: "CommonAppInstanceTemplatesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `CommonAppInstanceTemplate` at the end of the edge. */
  node: CommonAppInstanceTemplate;
};

/** Methods to use when ordering `CommonAppInstanceTemplate`. */
export enum CommonAppInstanceTemplatesOrderBy {
  AppIdAsc = "APP_ID_ASC",
  AppIdDesc = "APP_ID_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsDeprecatedAsc = "IS_DEPRECATED_ASC",
  IsDeprecatedDesc = "IS_DEPRECATED_DESC",
  IsPublishedAsc = "IS_PUBLISHED_ASC",
  IsPublishedDesc = "IS_PUBLISHED_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceAppInstanceIdAsc = "SOURCE_APP_INSTANCE_ID_ASC",
  SourceAppInstanceIdDesc = "SOURCE_APP_INSTANCE_ID_DESC",
  SourceOrgIdAsc = "SOURCE_ORG_ID_ASC",
  SourceOrgIdDesc = "SOURCE_ORG_ID_DESC",
  StateAsc = "STATE_ASC",
  StateDesc = "STATE_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  ThumbnailFileIdAsc = "THUMBNAIL_FILE_ID_ASC",
  ThumbnailFileIdDesc = "THUMBNAIL_FILE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  VersionAsc = "VERSION_ASC",
  VersionDesc = "VERSION_DESC",
}

export type CommonFile = {
  __typename: "CommonFile";
  availableAt: Maybe<Scalars["Datetime"]>;
  /** Reads and enables pagination through a set of `CommonAppInstanceTemplate`. */
  commonAppInstanceTemplatesByThumbnailFileId: CommonAppInstanceTemplatesConnection;
  /** Reads and enables pagination through a set of `CommonFileOutput`. */
  commonFileOutputsByCommonFileId: CommonFileOutputsConnection;
  createdAt: Maybe<Scalars["Datetime"]>;
  expireAt: Maybe<Scalars["Datetime"]>;
  fileProcessingStatus: Maybe<JobStatus>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCommonFileId: FilesConnection;
  id: Maybe<Scalars["UUID"]>;
  metadata: Scalars["JSON"];
  mimetype: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  size: Maybe<Scalars["BigInt"]>;
  source: Maybe<Scalars["String"]>;
  tags: Array<Maybe<Scalars["String"]>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
};

export type CommonFileCommonAppInstanceTemplatesByThumbnailFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonAppInstanceTemplateCondition>;
  filter: InputMaybe<CommonAppInstanceTemplateFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonAppInstanceTemplatesOrderBy>>;
};

export type CommonFileCommonFileOutputsByCommonFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonFileOutputCondition>;
  filter: InputMaybe<CommonFileOutputFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonFileOutputsOrderBy>>;
};

export type CommonFileFilesByCommonFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type CommonFileOutput = {
  __typename: "CommonFileOutput";
  /** Reads a single `CommonFile` that is related to this `CommonFileOutput`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  commonFileId: Maybe<Scalars["UUID"]>;
  content: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  /** Reads and enables pagination through a set of `FileOutput`. */
  fileOutputsByCommonFileId: FileOutputsConnection;
  id: Maybe<Scalars["UUID"]>;
  metadata: Maybe<Scalars["JSON"]>;
  mimetype: Maybe<Scalars["String"]>;
  size: Maybe<Scalars["BigInt"]>;
  url: Maybe<Scalars["String"]>;
};

export type CommonFileOutputFileOutputsByCommonFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileOutputCondition>;
  filter: InputMaybe<FileOutputFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileOutputsOrderBy>>;
};

/**
 * A condition to be used against `CommonFileOutput` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CommonFileOutputCondition = {
  /** Checks for equality with the object’s `commonFileId` field. */
  commonFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `mimetype` field. */
  mimetype?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars["BigInt"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `CommonFileOutput` object types. All fields are combined with a logical ‘and.’ */
export type CommonFileOutputFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CommonFileOutputFilter>>;
  /** Filter by the object’s `commonFileId` field. */
  commonFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mimetype` field. */
  mimetype?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CommonFileOutputFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CommonFileOutputFilter>>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** A connection to a list of `CommonFileOutput` values. */
export type CommonFileOutputsConnection = {
  __typename: "CommonFileOutputsConnection";
  /** A list of edges which contains the `CommonFileOutput` and cursor to aid in pagination. */
  edges: Array<CommonFileOutputsEdge>;
  /** A list of `CommonFileOutput` objects. */
  nodes: Array<CommonFileOutput>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CommonFileOutput` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `CommonFileOutput` edge in the connection. */
export type CommonFileOutputsEdge = {
  __typename: "CommonFileOutputsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `CommonFileOutput` at the end of the edge. */
  node: CommonFileOutput;
};

/** Methods to use when ordering `CommonFileOutput`. */
export enum CommonFileOutputsOrderBy {
  CommonFileIdAsc = "COMMON_FILE_ID_ASC",
  CommonFileIdDesc = "COMMON_FILE_ID_DESC",
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  MimetypeAsc = "MIMETYPE_ASC",
  MimetypeDesc = "MIMETYPE_DESC",
  Natural = "NATURAL",
  SizeAsc = "SIZE_ASC",
  SizeDesc = "SIZE_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
}

export type CommonFileToDelete = {
  __typename: "CommonFileToDelete";
  createdAt: Maybe<Scalars["Datetime"]>;
  deletedAt: Maybe<Scalars["Datetime"]>;
  error: Maybe<Scalars["String"]>;
  fileId: Maybe<Scalars["UUID"]>;
  id: Maybe<Scalars["UUID"]>;
  metadata: Maybe<Scalars["JSON"]>;
  mimetype: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  size: Maybe<Scalars["BigInt"]>;
  source: Maybe<Scalars["String"]>;
};

export type CommonFont = Node & {
  __typename: "CommonFont";
  boldFileId: Maybe<Scalars["UUID"]>;
  boldItalicFileId: Maybe<Scalars["UUID"]>;
  cssUrl: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `CommonFont`. */
  fileByBoldFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `CommonFont`. */
  fileByBoldItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `CommonFont`. */
  fileByItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `CommonFont`. */
  fileByRegularFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `CommonFont`. */
  fileByThumbnailFileId: Maybe<File>;
  fontFamily: Scalars["String"];
  fontType: Maybe<FontType>;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByCommonFontId: FontsConnection;
  id: Scalars["UUID"];
  isDefaultProvided: Maybe<Scalars["Boolean"]>;
  italicFileId: Maybe<Scalars["UUID"]>;
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  regularFileId: Maybe<Scalars["UUID"]>;
  thumbnailFileId: Maybe<Scalars["UUID"]>;
};

export type CommonFontFontsByCommonFontIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/**
 * A condition to be used against `CommonFont` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CommonFontCondition = {
  /** Checks for equality with the object’s `boldFileId` field. */
  boldFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `boldItalicFileId` field. */
  boldItalicFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `cssUrl` field. */
  cssUrl?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `fontFamily` field. */
  fontFamily?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `fontType` field. */
  fontType?: InputMaybe<FontType>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isDefaultProvided` field. */
  isDefaultProvided?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `italicFileId` field. */
  italicFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `regularFileId` field. */
  regularFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `thumbnailFileId` field. */
  thumbnailFileId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `CommonFont` object types. All fields are combined with a logical ‘and.’ */
export type CommonFontFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CommonFontFilter>>;
  /** Filter by the object’s `boldFileId` field. */
  boldFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `boldItalicFileId` field. */
  boldItalicFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `cssUrl` field. */
  cssUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fontFamily` field. */
  fontFamily?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fontType` field. */
  fontType?: InputMaybe<FontTypeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDefaultProvided` field. */
  isDefaultProvided?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `italicFileId` field. */
  italicFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CommonFontFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CommonFontFilter>>;
  /** Filter by the object’s `regularFileId` field. */
  regularFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `thumbnailFileId` field. */
  thumbnailFileId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `CommonFont` values. */
export type CommonFontsConnection = {
  __typename: "CommonFontsConnection";
  /** A list of edges which contains the `CommonFont` and cursor to aid in pagination. */
  edges: Array<CommonFontsEdge>;
  /** A list of `CommonFont` objects. */
  nodes: Array<CommonFont>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CommonFont` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `CommonFont` edge in the connection. */
export type CommonFontsEdge = {
  __typename: "CommonFontsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `CommonFont` at the end of the edge. */
  node: CommonFont;
};

/** Methods to use when ordering `CommonFont`. */
export enum CommonFontsOrderBy {
  BoldFileIdAsc = "BOLD_FILE_ID_ASC",
  BoldFileIdDesc = "BOLD_FILE_ID_DESC",
  BoldItalicFileIdAsc = "BOLD_ITALIC_FILE_ID_ASC",
  BoldItalicFileIdDesc = "BOLD_ITALIC_FILE_ID_DESC",
  CssUrlAsc = "CSS_URL_ASC",
  CssUrlDesc = "CSS_URL_DESC",
  FontFamilyAsc = "FONT_FAMILY_ASC",
  FontFamilyDesc = "FONT_FAMILY_DESC",
  FontTypeAsc = "FONT_TYPE_ASC",
  FontTypeDesc = "FONT_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsDefaultProvidedAsc = "IS_DEFAULT_PROVIDED_ASC",
  IsDefaultProvidedDesc = "IS_DEFAULT_PROVIDED_DESC",
  ItalicFileIdAsc = "ITALIC_FILE_ID_ASC",
  ItalicFileIdDesc = "ITALIC_FILE_ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RegularFileIdAsc = "REGULAR_FILE_ID_ASC",
  RegularFileIdDesc = "REGULAR_FILE_ID_DESC",
  ThumbnailFileIdAsc = "THUMBNAIL_FILE_ID_ASC",
  ThumbnailFileIdDesc = "THUMBNAIL_FILE_ID_DESC",
}

export type CommonLayout = Node & {
  __typename: "CommonLayout";
  config: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  fullScreenZoneId: Maybe<Scalars["String"]>;
  height: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  isFlexible: Maybe<Scalars["Boolean"]>;
  isScalable: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  updatedAt: Maybe<Scalars["Datetime"]>;
  width: Maybe<Scalars["Int"]>;
  zones: Scalars["Int"];
};

/**
 * A condition to be used against `CommonLayout` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CommonLayoutCondition = {
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `fullScreenZoneId` field. */
  fullScreenZoneId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `height` field. */
  height?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isFlexible` field. */
  isFlexible?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isScalable` field. */
  isScalable?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `width` field. */
  width?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `zones` field. */
  zones?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `CommonLayout` object types. All fields are combined with a logical ‘and.’ */
export type CommonLayoutFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CommonLayoutFilter>>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fullScreenZoneId` field. */
  fullScreenZoneId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isFlexible` field. */
  isFlexible?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isScalable` field. */
  isScalable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CommonLayoutFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CommonLayoutFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `width` field. */
  width?: InputMaybe<IntFilter>;
  /** Filter by the object’s `zones` field. */
  zones?: InputMaybe<IntFilter>;
};

/** A connection to a list of `CommonLayout` values. */
export type CommonLayoutsConnection = {
  __typename: "CommonLayoutsConnection";
  /** A list of edges which contains the `CommonLayout` and cursor to aid in pagination. */
  edges: Array<CommonLayoutsEdge>;
  /** A list of `CommonLayout` objects. */
  nodes: Array<CommonLayout>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CommonLayout` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `CommonLayout` edge in the connection. */
export type CommonLayoutsEdge = {
  __typename: "CommonLayoutsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `CommonLayout` at the end of the edge. */
  node: CommonLayout;
};

/** Methods to use when ordering `CommonLayout`. */
export enum CommonLayoutsOrderBy {
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  FullScreenZoneIdAsc = "FULL_SCREEN_ZONE_ID_ASC",
  FullScreenZoneIdDesc = "FULL_SCREEN_ZONE_ID_DESC",
  HeightAsc = "HEIGHT_ASC",
  HeightDesc = "HEIGHT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsFlexibleAsc = "IS_FLEXIBLE_ASC",
  IsFlexibleDesc = "IS_FLEXIBLE_DESC",
  IsScalableAsc = "IS_SCALABLE_ASC",
  IsScalableDesc = "IS_SCALABLE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  WidthAsc = "WIDTH_ASC",
  WidthDesc = "WIDTH_DESC",
  ZonesAsc = "ZONES_ASC",
  ZonesDesc = "ZONES_DESC",
}

export type CommonPermissionSet = Node & {
  __typename: "CommonPermissionSet";
  color: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["UUID"];
  isDefaultProvided: Scalars["Boolean"];
  isDefaultProvidedAsGroup: Scalars["Boolean"];
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads and enables pagination through a set of `PermissionSet`. */
  permissionSetsByCommonPermissionSetId: PermissionSetsConnection;
  permissions: Array<Maybe<Scalars["String"]>>;
};

export type CommonPermissionSetPermissionSetsByCommonPermissionSetIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PermissionSetCondition>;
  filter: InputMaybe<PermissionSetFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionSetsOrderBy>>;
};

/**
 * A condition to be used against `CommonPermissionSet` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CommonPermissionSetCondition = {
  /** Checks for equality with the object’s `color` field. */
  color?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isDefaultProvided` field. */
  isDefaultProvided?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isDefaultProvidedAsGroup` field. */
  isDefaultProvidedAsGroup?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `permissions` field. */
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** A filter to be used against `CommonPermissionSet` object types. All fields are combined with a logical ‘and.’ */
export type CommonPermissionSetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CommonPermissionSetFilter>>;
  /** Filter by the object’s `color` field. */
  color?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDefaultProvided` field. */
  isDefaultProvided?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDefaultProvidedAsGroup` field. */
  isDefaultProvidedAsGroup?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CommonPermissionSetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CommonPermissionSetFilter>>;
  /** Filter by the object’s `permissions` field. */
  permissions?: InputMaybe<StringListFilter>;
};

/** A connection to a list of `CommonPermissionSet` values. */
export type CommonPermissionSetsConnection = {
  __typename: "CommonPermissionSetsConnection";
  /** A list of edges which contains the `CommonPermissionSet` and cursor to aid in pagination. */
  edges: Array<CommonPermissionSetsEdge>;
  /** A list of `CommonPermissionSet` objects. */
  nodes: Array<CommonPermissionSet>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CommonPermissionSet` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `CommonPermissionSet` edge in the connection. */
export type CommonPermissionSetsEdge = {
  __typename: "CommonPermissionSetsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `CommonPermissionSet` at the end of the edge. */
  node: CommonPermissionSet;
};

/** Methods to use when ordering `CommonPermissionSet`. */
export enum CommonPermissionSetsOrderBy {
  ColorAsc = "COLOR_ASC",
  ColorDesc = "COLOR_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsDefaultProvidedAsc = "IS_DEFAULT_PROVIDED_ASC",
  IsDefaultProvidedAsGroupAsc = "IS_DEFAULT_PROVIDED_AS_GROUP_ASC",
  IsDefaultProvidedAsGroupDesc = "IS_DEFAULT_PROVIDED_AS_GROUP_DESC",
  IsDefaultProvidedDesc = "IS_DEFAULT_PROVIDED_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PermissionsAsc = "PERMISSIONS_ASC",
  PermissionsDesc = "PERMISSIONS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** Job to replace content inside entites. */
export type ContentReplacementJob = Node & {
  __typename: "ContentReplacementJob";
  createdAt: Maybe<Scalars["Datetime"]>;
  /** The UUID of user/system whose create the job. */
  createdBy: Scalars["UUID"];
  error: Maybe<Scalars["String"]>;
  finishedAt: Maybe<Scalars["Datetime"]>;
  /** The uuid of the job. */
  id: Scalars["UUID"];
  lastRetryAt: Maybe<Scalars["Datetime"]>;
  /** The uuid of the new content. */
  newContentId: Scalars["UUID"];
  /** Content type of the new content. */
  newContentType: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `ContentReplacementJob`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization who holds both contents and target entites. */
  orgId: Scalars["UUID"];
  /** The uuid of the origital content. */
  originContentId: Scalars["UUID"];
  /** Content type of the origital content. */
  originContentType: Scalars["String"];
  originalAppInstance: Maybe<AppInstance>;
  originalFile: Maybe<File>;
  retries: Scalars["Int"];
  /** Reads a single `Space` that is related to this `ContentReplacementJob`. */
  spaceBySpaceId: Maybe<Space>;
  /** The uuid of the space who holds both contents and target entites. */
  spaceId: Scalars["UUID"];
  startedAt: Maybe<Scalars["Datetime"]>;
  /** The status of the job. */
  status: Scalars["String"];
  /** The list of target entities whose content will be replaced. */
  targetEntities: Maybe<Array<Maybe<Scalars["JSON"]>>>;
};

/**
 * A condition to be used against `ContentReplacementJob` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ContentReplacementJobCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `finishedAt` field. */
  finishedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `lastRetryAt` field. */
  lastRetryAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `newContentId` field. */
  newContentId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `newContentType` field. */
  newContentType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `originContentId` field. */
  originContentId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `originContentType` field. */
  originContentType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `retries` field. */
  retries?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `targetEntities` field. */
  targetEntities?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
};

/** A filter to be used against `ContentReplacementJob` object types. All fields are combined with a logical ‘and.’ */
export type ContentReplacementJobFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ContentReplacementJobFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `error` field. */
  error?: InputMaybe<StringFilter>;
  /** Filter by the object’s `finishedAt` field. */
  finishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastRetryAt` field. */
  lastRetryAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `newContentId` field. */
  newContentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `newContentType` field. */
  newContentType?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ContentReplacementJobFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ContentReplacementJobFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `originContentId` field. */
  originContentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `originContentType` field. */
  originContentType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `retries` field. */
  retries?: InputMaybe<IntFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetEntities` field. */
  targetEntities?: InputMaybe<JsonListFilter>;
};

/** A connection to a list of `ContentReplacementJob` values. */
export type ContentReplacementJobsConnection = {
  __typename: "ContentReplacementJobsConnection";
  /** A list of edges which contains the `ContentReplacementJob` and cursor to aid in pagination. */
  edges: Array<ContentReplacementJobsEdge>;
  /** A list of `ContentReplacementJob` objects. */
  nodes: Array<ContentReplacementJob>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ContentReplacementJob` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `ContentReplacementJob` edge in the connection. */
export type ContentReplacementJobsEdge = {
  __typename: "ContentReplacementJobsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `ContentReplacementJob` at the end of the edge. */
  node: ContentReplacementJob;
};

/** Methods to use when ordering `ContentReplacementJob`. */
export enum ContentReplacementJobsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  ErrorAsc = "ERROR_ASC",
  ErrorDesc = "ERROR_DESC",
  FinishedAtAsc = "FINISHED_AT_ASC",
  FinishedAtDesc = "FINISHED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LastRetryAtAsc = "LAST_RETRY_AT_ASC",
  LastRetryAtDesc = "LAST_RETRY_AT_DESC",
  Natural = "NATURAL",
  NewContentIdAsc = "NEW_CONTENT_ID_ASC",
  NewContentIdDesc = "NEW_CONTENT_ID_DESC",
  NewContentTypeAsc = "NEW_CONTENT_TYPE_ASC",
  NewContentTypeDesc = "NEW_CONTENT_TYPE_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  OriginContentIdAsc = "ORIGIN_CONTENT_ID_ASC",
  OriginContentIdDesc = "ORIGIN_CONTENT_ID_DESC",
  OriginContentTypeAsc = "ORIGIN_CONTENT_TYPE_ASC",
  OriginContentTypeDesc = "ORIGIN_CONTENT_TYPE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RetriesAsc = "RETRIES_ASC",
  RetriesDesc = "RETRIES_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  StartedAtAsc = "STARTED_AT_ASC",
  StartedAtDesc = "STARTED_AT_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TargetEntitiesAsc = "TARGET_ENTITIES_ASC",
  TargetEntitiesDesc = "TARGET_ENTITIES_DESC",
}

export enum CountryCode {
  Ad = "AD",
  Ae = "AE",
  Af = "AF",
  Ag = "AG",
  Ai = "AI",
  Al = "AL",
  Am = "AM",
  Ao = "AO",
  Aq = "AQ",
  Ar = "AR",
  As = "AS",
  At = "AT",
  Au = "AU",
  Aw = "AW",
  Ax = "AX",
  Az = "AZ",
  Ba = "BA",
  Bb = "BB",
  Bd = "BD",
  Be = "BE",
  Bf = "BF",
  Bg = "BG",
  Bh = "BH",
  Bi = "BI",
  Bj = "BJ",
  Bl = "BL",
  Bm = "BM",
  Bn = "BN",
  Bo = "BO",
  Bq = "BQ",
  Br = "BR",
  Bs = "BS",
  Bt = "BT",
  Bv = "BV",
  Bw = "BW",
  By = "BY",
  Bz = "BZ",
  Ca = "CA",
  Cc = "CC",
  Cd = "CD",
  Cf = "CF",
  Cg = "CG",
  Ch = "CH",
  Ci = "CI",
  Ck = "CK",
  Cl = "CL",
  Cm = "CM",
  Cn = "CN",
  Co = "CO",
  Cr = "CR",
  Cu = "CU",
  Cv = "CV",
  Cw = "CW",
  Cx = "CX",
  Cy = "CY",
  Cz = "CZ",
  De = "DE",
  Dj = "DJ",
  Dk = "DK",
  Dm = "DM",
  Do = "DO",
  Dz = "DZ",
  Ec = "EC",
  Ee = "EE",
  Eg = "EG",
  Eh = "EH",
  Er = "ER",
  Es = "ES",
  Et = "ET",
  Fi = "FI",
  Fj = "FJ",
  Fk = "FK",
  Fm = "FM",
  Fo = "FO",
  Fr = "FR",
  Ga = "GA",
  Gb = "GB",
  Gd = "GD",
  Ge = "GE",
  Gf = "GF",
  Gg = "GG",
  Gh = "GH",
  Gi = "GI",
  Gl = "GL",
  Gm = "GM",
  Gn = "GN",
  Gp = "GP",
  Gq = "GQ",
  Gr = "GR",
  Gs = "GS",
  Gt = "GT",
  Gu = "GU",
  Gw = "GW",
  Gy = "GY",
  Hk = "HK",
  Hm = "HM",
  Hn = "HN",
  Hr = "HR",
  Ht = "HT",
  Hu = "HU",
  Id = "ID",
  Ie = "IE",
  Il = "IL",
  Im = "IM",
  In = "IN",
  Io = "IO",
  Iq = "IQ",
  Ir = "IR",
  Is = "IS",
  It = "IT",
  Je = "JE",
  Jm = "JM",
  Jo = "JO",
  Jp = "JP",
  Ke = "KE",
  Kg = "KG",
  Kh = "KH",
  Ki = "KI",
  Km = "KM",
  Kn = "KN",
  Kp = "KP",
  Kr = "KR",
  Kw = "KW",
  Ky = "KY",
  Kz = "KZ",
  La = "LA",
  Lb = "LB",
  Lc = "LC",
  Li = "LI",
  Lk = "LK",
  Lr = "LR",
  Ls = "LS",
  Lt = "LT",
  Lu = "LU",
  Lv = "LV",
  Ly = "LY",
  Ma = "MA",
  Mc = "MC",
  Md = "MD",
  Me = "ME",
  Mf = "MF",
  Mg = "MG",
  Mh = "MH",
  Mk = "MK",
  Ml = "ML",
  Mm = "MM",
  Mn = "MN",
  Mo = "MO",
  Mp = "MP",
  Mq = "MQ",
  Mr = "MR",
  Ms = "MS",
  Mt = "MT",
  Mu = "MU",
  Mv = "MV",
  Mw = "MW",
  Mx = "MX",
  My = "MY",
  Mz = "MZ",
  Na = "NA",
  Nc = "NC",
  Ne = "NE",
  Nf = "NF",
  Ng = "NG",
  Ni = "NI",
  Nl = "NL",
  No = "NO",
  Np = "NP",
  Nr = "NR",
  Nu = "NU",
  Nz = "NZ",
  Om = "OM",
  Pa = "PA",
  Pe = "PE",
  Pf = "PF",
  Pg = "PG",
  Ph = "PH",
  Pk = "PK",
  Pl = "PL",
  Pm = "PM",
  Pn = "PN",
  Pr = "PR",
  Ps = "PS",
  Pt = "PT",
  Pw = "PW",
  Py = "PY",
  Qa = "QA",
  Re = "RE",
  Ro = "RO",
  Rs = "RS",
  Ru = "RU",
  Rw = "RW",
  Sa = "SA",
  Sb = "SB",
  Sc = "SC",
  Sd = "SD",
  Se = "SE",
  Sg = "SG",
  Sh = "SH",
  Si = "SI",
  Sj = "SJ",
  Sk = "SK",
  Sl = "SL",
  Sm = "SM",
  Sn = "SN",
  So = "SO",
  Sr = "SR",
  Ss = "SS",
  St = "ST",
  Sv = "SV",
  Sx = "SX",
  Sy = "SY",
  Sz = "SZ",
  Tc = "TC",
  Td = "TD",
  Tf = "TF",
  Tg = "TG",
  Th = "TH",
  Tj = "TJ",
  Tk = "TK",
  Tl = "TL",
  Tm = "TM",
  Tn = "TN",
  To = "TO",
  Tr = "TR",
  Tt = "TT",
  Tv = "TV",
  Tw = "TW",
  Tz = "TZ",
  Ua = "UA",
  Ug = "UG",
  Um = "UM",
  Us = "US",
  Uy = "UY",
  Uz = "UZ",
  Va = "VA",
  Vc = "VC",
  Ve = "VE",
  Vg = "VG",
  Vi = "VI",
  Vn = "VN",
  Vu = "VU",
  Wf = "WF",
  Ws = "WS",
  Ye = "YE",
  Yt = "YT",
  Za = "ZA",
  Zm = "ZM",
  Zw = "ZW",
}

/** A filter to be used against CountryCode fields. All fields are combined with a logical ‘and.’ */
export type CountryCodeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CountryCode>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CountryCode>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CountryCode>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CountryCode>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CountryCode>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CountryCode>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CountryCode>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CountryCode>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CountryCode>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CountryCode>>;
};

export type CreateAdditionalQrContextInput = {
  clientId?: InputMaybe<Scalars["String"]>;
  context?: InputMaybe<Scalars["JSON"]>;
};

export type CreateAdditionalQrContextPayload = {
  __typename: "CreateAdditionalQrContextPayload";
  key: Maybe<Scalars["String"]>;
  message: Maybe<Scalars["String"]>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `createAppInstanceByCommonAppInstanceTemplateId` mutation. */
export type CreateAppInstanceByCommonAppInstanceTemplateIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  commonAppInstanceTemplateId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createAppInstanceByCommonAppInstanceTemplateId` mutation. */
export type CreateAppInstanceByCommonAppInstanceTemplateIdPayload = {
  __typename: "CreateAppInstanceByCommonAppInstanceTemplateIdPayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createAppInstanceByCommonAppInstanceTemplateId` mutation. */
export type CreateAppInstanceByCommonAppInstanceTemplateIdPayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `createAppInstance` mutation. */
export type CreateAppInstanceInput = {
  appInstallId?: InputMaybe<Scalars["UUID"]>;
  appInstanceId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  isTemplate?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  status?: InputMaybe<AppInstanceStatus>;
  thumbnailFileId?: InputMaybe<Scalars["UUID"]>;
  version?: InputMaybe<Scalars["String"]>;
};

/** The output of our `createAppInstance` mutation. */
export type CreateAppInstancePayload = {
  __typename: "CreateAppInstancePayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createAppInstance` mutation. */
export type CreateAppInstancePayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `createBot` mutation. */
export type CreateBotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `createBot` mutation. */
export type CreateBotPayload = {
  __typename: "CreateBotPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `createBot` mutation. */
export type CreateBotPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `createCastsByAppInstanceId` mutation. */
export type CreateCastsByAppInstanceIdInput = {
  appInstanceId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `createCastsByAppInstanceId` mutation. */
export type CreateCastsByAppInstanceIdPayload = {
  __typename: "CreateCastsByAppInstanceIdPayload";
  casts: Maybe<Array<Cast>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `createCastsByChannelId` mutation. */
export type CreateCastsByChannelIdInput = {
  channelId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `createCastsByChannelId` mutation. */
export type CreateCastsByChannelIdPayload = {
  __typename: "CreateCastsByChannelIdPayload";
  casts: Maybe<Array<Cast>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `createCastsByFileId` mutation. */
export type CreateCastsByFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `createCastsByFileId` mutation. */
export type CreateCastsByFileIdPayload = {
  __typename: "CreateCastsByFileIdPayload";
  casts: Maybe<Array<Cast>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `createCastsByLinkId` mutation. */
export type CreateCastsByLinkIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  linkId?: InputMaybe<Scalars["UUID"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `createCastsByLinkId` mutation. */
export type CreateCastsByLinkIdPayload = {
  __typename: "CreateCastsByLinkIdPayload";
  casts: Maybe<Array<Cast>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `createCastsByPlaylistId` mutation. */
export type CreateCastsByPlaylistIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  playlistId?: InputMaybe<Scalars["UUID"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `createCastsByPlaylistId` mutation. */
export type CreateCastsByPlaylistIdPayload = {
  __typename: "CreateCastsByPlaylistIdPayload";
  casts: Maybe<Array<Cast>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `createCastsBySiteId` mutation. */
export type CreateCastsBySiteIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  siteId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createCastsBySiteId` mutation. */
export type CreateCastsBySiteIdPayload = {
  __typename: "CreateCastsBySiteIdPayload";
  casts: Maybe<Array<Cast>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `createCasts` mutation. */
export type CreateCastsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  expiresAt?: InputMaybe<Scalars["Datetime"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `createCasts` mutation. */
export type CreateCastsPayload = {
  __typename: "CreateCastsPayload";
  casts: Maybe<Array<Cast>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `createChannel` mutation. */
export type CreateChannelInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  childOf?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  coverData?: InputMaybe<Scalars["JSON"]>;
  coverImageId?: InputMaybe<Scalars["UUID"]>;
  height?: InputMaybe<Scalars["Int"]>;
  hiddenZone?: InputMaybe<Scalars["Boolean"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  layoutId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

/** The output of our `createChannel` mutation. */
export type CreateChannelPayload = {
  __typename: "CreateChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createChannel` mutation. */
export type CreateChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `createCommonAppInstanceTemplateBySourceAppInstanceId` mutation. */
export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isDeprecated: Scalars["Boolean"];
  isPublished: Scalars["Boolean"];
  sourceAppInstanceId: Scalars["UUID"];
};

/** The output of our `createCommonAppInstanceTemplateBySourceAppInstanceId` mutation. */
export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdPayload = {
  __typename: "CreateCommonAppInstanceTemplateBySourceAppInstanceIdPayload";
  /** Reads a single `App` that is related to this `CommonAppInstanceTemplate`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstance` that is related to this `CommonAppInstanceTemplate`. */
  appInstanceBySourceAppInstanceId: Maybe<AppInstance>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  commonAppInstanceTemplate: Maybe<CommonAppInstanceTemplate>;
  /** An edge for our `CommonAppInstanceTemplate`. May be used by Relay 1. */
  commonAppInstanceTemplateEdge: Maybe<CommonAppInstanceTemplatesEdge>;
  /** Reads a single `CommonFile` that is related to this `CommonAppInstanceTemplate`. */
  commonFileByThumbnailFileId: Maybe<CommonFile>;
  /** Reads a single `Org` that is related to this `CommonAppInstanceTemplate`. */
  orgBySourceOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `createCommonAppInstanceTemplateBySourceAppInstanceId` mutation. */
export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdPayloadCommonAppInstanceTemplateEdgeArgs = {
  orderBy?: InputMaybe<Array<CommonAppInstanceTemplatesOrderBy>>;
};

/** All input for the `createContentReplacementJob` mutation. */
export type CreateContentReplacementJobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  newContentId?: InputMaybe<Scalars["UUID"]>;
  newContentType?: InputMaybe<Scalars["String"]>;
  originContentId?: InputMaybe<Scalars["UUID"]>;
  originContentType?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  targetEntities?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
};

/** The output of our `createContentReplacementJob` mutation. */
export type CreateContentReplacementJobPayload = {
  __typename: "CreateContentReplacementJobPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  contentReplacementJob: Maybe<ContentReplacementJob>;
  /** An edge for our `ContentReplacementJob`. May be used by Relay 1. */
  contentReplacementJobEdge: Maybe<ContentReplacementJobsEdge>;
  /** Reads a single `Org` that is related to this `ContentReplacementJob`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `ContentReplacementJob`. */
  spaceBySpaceId: Maybe<Space>;
};

/** The output of our `createContentReplacementJob` mutation. */
export type CreateContentReplacementJobPayloadContentReplacementJobEdgeArgs = {
  orderBy?: InputMaybe<Array<ContentReplacementJobsOrderBy>>;
};

/** All input for the `createCredential` mutation. */
export type CreateCredentialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  credentialKey?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  username?: InputMaybe<Scalars["String"]>;
};

/** The output of our `createCredential` mutation. */
export type CreateCredentialPayload = {
  __typename: "CreateCredentialPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  credential: Maybe<Credential>;
  /** An edge for our `Credential`. May be used by Relay 1. */
  credentialEdge: Maybe<CredentialsEdge>;
  /** Reads a single `Org` that is related to this `Credential`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Credential`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `createCredential` mutation. */
export type CreateCredentialPayloadCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

export type CreateCustomerInput = {
  company: Scalars["String"];
  email: Scalars["String"];
};

export type CreateCustomerPayload = {
  __typename: "CreateCustomerPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `createDuplicateFiles` mutation. */
export type CreateDuplicateFilesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  files?: InputMaybe<Array<InputMaybe<DuplicateFileInput>>>;
};

/** The output of our `createDuplicateFiles` mutation. */
export type CreateDuplicateFilesPayload = {
  __typename: "CreateDuplicateFilesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  files: Maybe<Array<Maybe<File>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

export type CreateDuplicateJobInput = {
  isPublicSnapshot?: InputMaybe<Scalars["Boolean"]>;
  jobType?: InputMaybe<DuplicateJobType>;
  snapshotId?: InputMaybe<Scalars["String"]>;
  snapshotType?: InputMaybe<SnapshotType>;
  sourceContentId?: InputMaybe<Scalars["String"]>;
  sourceSpaceId?: InputMaybe<Scalars["String"]>;
  targetNewName?: InputMaybe<Scalars["String"]>;
  targetSpaceId?: InputMaybe<Scalars["String"]>;
};

export type CreateDuplicateJobPayload = {
  __typename: "CreateDuplicateJobPayload";
  id: Maybe<Scalars["String"]>;
  success: Maybe<Scalars["Boolean"]>;
};

export type CreateDuplicateJobsInput = {
  isPublicSnapshot?: InputMaybe<Scalars["Boolean"]>;
  jobType?: InputMaybe<DuplicateJobType>;
  snapshotId?: InputMaybe<Scalars["String"]>;
  snapshotType?: InputMaybe<SnapshotType>;
  sourceContentId?: InputMaybe<Scalars["String"]>;
  sourceSpaceId?: InputMaybe<Scalars["String"]>;
  targetNewName?: InputMaybe<Scalars["String"]>;
  targetSpaceIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CreateEnrollmentTokenInput = {
  contentId?: InputMaybe<Scalars["UUID"]>;
  contentType?: InputMaybe<Scalars["String"]>;
  disabled?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["Date"]>;
  name?: InputMaybe<Scalars["String"]>;
  screenEnv?: InputMaybe<Scalars["JSON"]>;
  screenGroupId?: InputMaybe<Scalars["UUID"]>;
  screenNamePrefix?: InputMaybe<Scalars["String"]>;
  spaceId: Scalars["UUID"];
};

export type CreateEnrollmentTokenPayload = {
  __typename: "CreateEnrollmentTokenPayload";
  payload: Maybe<Scalars["JSON"]>;
  token: Maybe<Scalars["String"]>;
};

/** All input for the `createFileByCommonFileId` mutation. */
export type CreateFileByCommonFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  commonFileId?: InputMaybe<Scalars["UUID"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createFileByCommonFileId` mutation. */
export type CreateFileByCommonFileIdPayload = {
  __typename: "CreateFileByCommonFileIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createFileByCommonFileId` mutation. */
export type CreateFileByCommonFileIdPayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `createFile` mutation. */
export type CreateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderId?: InputMaybe<Scalars["UUID"]>;
  metadata?: InputMaybe<Scalars["JSON"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["BigInt"]>;
  source?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `createFile` mutation. */
export type CreateFilePayload = {
  __typename: "CreateFilePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createFile` mutation. */
export type CreateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `createFolder` mutation. */
export type CreateFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["UUID"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createFolder` mutation. */
export type CreateFolderPayload = {
  __typename: "CreateFolderPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  folder: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Folder`. */
  folderByParentId: Maybe<Folder>;
  /** An edge for our `Folder`. May be used by Relay 1. */
  folderEdge: Maybe<FoldersEdge>;
  /** Reads a single `Org` that is related to this `Folder`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createFolder` mutation. */
export type CreateFolderPayloadFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** All input for the `createFontFromCommon` mutation. */
export type CreateFontFromCommonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  commonFontId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createFontFromCommon` mutation. */
export type CreateFontFromCommonPayload = {
  __typename: "CreateFontFromCommonPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFont` that is related to this `Font`. */
  commonFontByCommonFontId: Maybe<CommonFont>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByRegularFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByThumbnailFileId: Maybe<File>;
  font: Maybe<Font>;
  /** An edge for our `Font`. May be used by Relay 1. */
  fontEdge: Maybe<FontsEdge>;
  /** Reads a single `Org` that is related to this `Font`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Font`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Font`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createFontFromCommon` mutation. */
export type CreateFontFromCommonPayloadFontEdgeArgs = {
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** All input for the `createFont` mutation. */
export type CreateFontInput = {
  boldFileId?: InputMaybe<Scalars["UUID"]>;
  boldItalicFileId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  cssUrl?: InputMaybe<Scalars["String"]>;
  italicFileId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  regularFileId?: InputMaybe<Scalars["UUID"]>;
  thumbnailFileId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createFont` mutation. */
export type CreateFontPayload = {
  __typename: "CreateFontPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFont` that is related to this `Font`. */
  commonFontByCommonFontId: Maybe<CommonFont>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByRegularFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByThumbnailFileId: Maybe<File>;
  font: Maybe<Font>;
  /** An edge for our `Font`. May be used by Relay 1. */
  fontEdge: Maybe<FontsEdge>;
  /** Reads a single `Org` that is related to this `Font`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Font`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Font`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createFont` mutation. */
export type CreateFontPayloadFontEdgeArgs = {
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** All input for the `createGroup` mutation. */
export type CreateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `createGroup` mutation. */
export type CreateGroupPayload = {
  __typename: "CreateGroupPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  group: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge: Maybe<GroupsEdge>;
  /** Reads a single `Org` that is related to this `Group`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Group`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Group`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createGroup` mutation. */
export type CreateGroupPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `createLayout` mutation. */
export type CreateLayoutInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  config: Scalars["JSON"];
  spaceId: Scalars["UUID"];
};

/** The output of our `createLayout` mutation. */
export type CreateLayoutPayload = {
  __typename: "CreateLayoutPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  layout: Maybe<Layout>;
  /** An edge for our `Layout`. May be used by Relay 1. */
  layoutEdge: Maybe<LayoutsEdge>;
  /** Reads a single `Org` that is related to this `Layout`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Layout`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Layout`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Layout`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createLayout` mutation. */
export type CreateLayoutPayloadLayoutEdgeArgs = {
  orderBy?: InputMaybe<Array<LayoutsOrderBy>>;
};

/** All input for the `createLink` mutation. */
export type CreateLinkInput = {
  autoReload?: InputMaybe<Scalars["Boolean"]>;
  autoReloadDurationMs?: InputMaybe<Scalars["Int"]>;
  cacheBusting?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  cloudConfig?: InputMaybe<Scalars["JSON"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
  linkType?: InputMaybe<LinkType>;
  name?: InputMaybe<Scalars["String"]>;
  params?: InputMaybe<Scalars["JSON"]>;
  qrcodeConfig?: InputMaybe<Scalars["JSON"]>;
  qrcodeEnabled?: InputMaybe<Scalars["Boolean"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
};

/** The output of our `createLink` mutation. */
export type CreateLinkPayload = {
  __typename: "CreateLinkPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileIdAndOrgId: Maybe<File>;
  link: Maybe<Link>;
  /** An edge for our `Link`. May be used by Relay 1. */
  linkEdge: Maybe<LinksEdge>;
  /** Reads a single `Org` that is related to this `Link`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Link`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Link`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createLink` mutation. */
export type CreateLinkPayloadLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** All input for the `createNotificationRule` mutation. */
export type CreateNotificationRuleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  event?: InputMaybe<Scalars["String"]>;
  screenId?: InputMaybe<Scalars["UUID"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  waitTimeInSecond?: InputMaybe<Scalars["Int"]>;
};

/** The output of our `createNotificationRule` mutation. */
export type CreateNotificationRulePayload = {
  __typename: "CreateNotificationRulePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRule: Maybe<NotificationRule>;
  /** An edge for our `NotificationRule`. May be used by Relay 1. */
  notificationRuleEdge: Maybe<NotificationRulesEdge>;
  /** Reads a single `Org` that is related to this `NotificationRule`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Screen` that is related to this `NotificationRule`. */
  screenByScreenIdAndOrgId: Maybe<Screen>;
  /** Reads a single `Space` that is related to this `NotificationRule`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `NotificationRule`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `createNotificationRule` mutation. */
export type CreateNotificationRulePayloadNotificationRuleEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** All input for the `createOrUpdateWhiteLabel` mutation. */
export type CreateOrUpdateWhiteLabelInput = {
  activeItemColor?: InputMaybe<Scalars["String"]>;
  activeTextColor?: InputMaybe<Scalars["String"]>;
  bgColor?: InputMaybe<Scalars["String"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  glyphFileId?: InputMaybe<Scalars["UUID"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  textColor?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  wordmark?: InputMaybe<Scalars["String"]>;
};

/** The output of our `createOrUpdateWhiteLabel` mutation. */
export type CreateOrUpdateWhiteLabelPayload = {
  __typename: "CreateOrUpdateWhiteLabelPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `WhiteLabel`. */
  fileByGlyphFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `WhiteLabel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `WhiteLabel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `WhiteLabel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `WhiteLabel`. */
  userByUpdatedBy: Maybe<User>;
  whiteLabel: Maybe<WhiteLabel>;
  /** An edge for our `WhiteLabel`. May be used by Relay 1. */
  whiteLabelEdge: Maybe<WhiteLabelsEdge>;
};

/** The output of our `createOrUpdateWhiteLabel` mutation. */
export type CreateOrUpdateWhiteLabelPayloadWhiteLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

export type CreateOrgConnectionInput = {
  automaticEnroll?: InputMaybe<Scalars["Boolean"]>;
  connection: Scalars["String"];
  provider: Scalars["String"];
};

export type CreateOrgConnectionPayload = {
  __typename: "CreateOrgConnectionPayload";
  orgConnection: OrgConnection;
};

/** All input for the `createOrg` mutation. */
export type CreateOrgInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  inviteKey?: InputMaybe<Scalars["String"]>;
  orgName?: InputMaybe<Scalars["String"]>;
  orgSlug?: InputMaybe<Scalars["String"]>;
};

/** The output of our `createOrg` mutation. */
export type CreateOrgPayload = {
  __typename: "CreateOrgPayload";
  activeUser: Maybe<ActiveUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

export type CreatePaymentSourceCardInput = {
  gwToken: Scalars["String"];
  spaceId?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentSourceCardPayload = {
  __typename: "CreatePaymentSourceCardPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

export type CreatePaymentSourceDirectdebitInput = {
  redirectFlowId: Scalars["String"];
  spaceId?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentSourceDirectdebitPayload = {
  __typename: "CreatePaymentSourceDirectdebitPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

export type CreatePaymentSourcePaypalInput = {
  spaceId?: InputMaybe<Scalars["String"]>;
  token: Scalars["String"];
};

export type CreatePaymentSourcePaypalPayload = {
  __typename: "CreatePaymentSourcePaypalPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `createPermissionSetFromCommon` mutation. */
export type CreatePermissionSetFromCommonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  commonPermissionSetId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createPermissionSetFromCommon` mutation. */
export type CreatePermissionSetFromCommonPayload = {
  __typename: "CreatePermissionSetFromCommonPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonPermissionSet` that is related to this `PermissionSet`. */
  commonPermissionSetByCommonPermissionSetId: Maybe<CommonPermissionSet>;
  /** Reads a single `Org` that is related to this `PermissionSet`. */
  orgByOrgId: Maybe<Org>;
  permissionSet: Maybe<PermissionSet>;
  /** An edge for our `PermissionSet`. May be used by Relay 1. */
  permissionSetEdge: Maybe<PermissionSetsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `createPermissionSetFromCommon` mutation. */
export type CreatePermissionSetFromCommonPayloadPermissionSetEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionSetsOrderBy>>;
};

/** All input for the `createPermissionSet` mutation. */
export type CreatePermissionSetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `createPermissionSet` mutation. */
export type CreatePermissionSetPayload = {
  __typename: "CreatePermissionSetPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonPermissionSet` that is related to this `PermissionSet`. */
  commonPermissionSetByCommonPermissionSetId: Maybe<CommonPermissionSet>;
  /** Reads a single `Org` that is related to this `PermissionSet`. */
  orgByOrgId: Maybe<Org>;
  permissionSet: Maybe<PermissionSet>;
  /** An edge for our `PermissionSet`. May be used by Relay 1. */
  permissionSetEdge: Maybe<PermissionSetsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `createPermissionSet` mutation. */
export type CreatePermissionSetPayloadPermissionSetEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionSetsOrderBy>>;
};

/** All input for the `createPlaylist` mutation. */
export type CreatePlaylistInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createPlaylist` mutation. */
export type CreatePlaylistPayload = {
  __typename: "CreatePlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createPlaylist` mutation. */
export type CreatePlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

export type CreateQrInput = {
  clientId?: InputMaybe<Scalars["String"]>;
  context?: InputMaybe<Scalars["JSON"]>;
  persistent?: InputMaybe<Scalars["Boolean"]>;
  targetUrl?: InputMaybe<Scalars["String"]>;
  wrapperUrl?: InputMaybe<Scalars["String"]>;
};

export type CreateQrPayload = {
  __typename: "CreateQrPayload";
  message: Maybe<Scalars["String"]>;
  qr_url: Maybe<Scalars["String"]>;
  success: Maybe<Scalars["Boolean"]>;
};

export type CreateRelationshipInput = {
  addressCity?: InputMaybe<Scalars["String"]>;
  addressCountry?: InputMaybe<Scalars["String"]>;
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  addressLine3?: InputMaybe<Scalars["String"]>;
  addressState?: InputMaybe<Scalars["String"]>;
  addressStateCode?: InputMaybe<Scalars["String"]>;
  addressZip?: InputMaybe<Scalars["String"]>;
  cardToken?: InputMaybe<Scalars["String"]>;
  currency: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  gocardlessToken?: InputMaybe<Scalars["String"]>;
  invoiced?: InputMaybe<Scalars["Boolean"]>;
  invoicee: Scalars["String"];
  name: Scalars["String"];
  payee: Scalars["String"];
  paypalToken?: InputMaybe<Scalars["String"]>;
  plan: Scalars["String"];
  spaceIds: Array<Scalars["String"]>;
  term: Scalars["String"];
  vatNumber?: InputMaybe<Scalars["String"]>;
};

export type CreateRelationshipPayload = {
  __typename: "CreateRelationshipPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `createScreenGroup` mutation. */
export type CreateScreenGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  env: Scalars["JSON"];
  name: Scalars["String"];
  spaceId: Scalars["UUID"];
};

/** The output of our `createScreenGroup` mutation. */
export type CreateScreenGroupPayload = {
  __typename: "CreateScreenGroupPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `ScreenGroup`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screenGroup: Maybe<ScreenGroup>;
  /** An edge for our `ScreenGroup`. May be used by Relay 1. */
  screenGroupEdge: Maybe<ScreenGroupsEdge>;
  /** Reads a single `Space` that is related to this `ScreenGroup`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `ScreenGroup`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `ScreenGroup`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createScreenGroup` mutation. */
export type CreateScreenGroupPayloadScreenGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenGroupsOrderBy>>;
};

/** All input for the `createScreen` mutation. */
export type CreateScreenInput = {
  name?: InputMaybe<Scalars["String"]>;
  pairingCode: Scalars["String"];
  playbackMode?: InputMaybe<Scalars["String"]>;
  preparedScreenId?: InputMaybe<Scalars["UUID"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  rotation?: InputMaybe<ScreenRotation>;
  screenGroupId?: InputMaybe<Scalars["UUID"]>;
  spaceId: Scalars["UUID"];
};

/** All input for the `createScreenNote` mutation. */
export type CreateScreenNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  note: Scalars["String"];
  screenId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The output of our `createScreenNote` mutation. */
export type CreateScreenNotePayload = {
  __typename: "CreateScreenNotePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `ScreenNote`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Screen` that is related to this `ScreenNote`. */
  screenByScreenId: Maybe<Screen>;
  screenNote: Maybe<ScreenNote>;
  /** An edge for our `ScreenNote`. May be used by Relay 1. */
  screenNoteEdge: Maybe<ScreenNotesEdge>;
  /** Reads a single `Space` that is related to this `ScreenNote`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `ScreenNote`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `ScreenNote`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createScreenNote` mutation. */
export type CreateScreenNotePayloadScreenNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** The output of our `createScreen` mutation. */
export type CreateScreenPayload = {
  __typename: "CreateScreenPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  query: Maybe<Query>;
  screen: Maybe<Screen>;
};

export type CreateSiteInput = {
  actions?: InputMaybe<Array<Scalars["JSON"]>>;
  compute?: InputMaybe<Scalars["JSON"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  name: Scalars["String"];
  spaceId: Scalars["UUID"];
  tags: Array<InputMaybe<Scalars["String"]>>;
  thumbnailId?: InputMaybe<Scalars["UUID"]>;
  type: SiteType;
  url: Scalars["String"];
};

export type CreateSitePayload = {
  __typename: "CreateSitePayload";
  site: Maybe<Site>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `createSpace` mutation. */
export type CreateSpaceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  env?: InputMaybe<Scalars["JSON"]>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  preferences?: InputMaybe<Scalars["JSON"]>;
  skipShareChannelsByDefaultSharingMode?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

/** The output of our `createSpace` mutation. */
export type CreateSpacePayload = {
  __typename: "CreateSpacePayload";
  /** Reads a single `BillingCustomer` that is related to this `Space`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Folder` that is related to this `Space`. */
  folderByRootFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `Space`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  space: Maybe<Space>;
  /** An edge for our `Space`. May be used by Relay 1. */
  spaceEdge: Maybe<SpacesEdge>;
  /** Reads a single `User` that is related to this `Space`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Space`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createSpace` mutation. */
export type CreateSpacePayloadSpaceEdgeArgs = {
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

/** All input for the `createTheme` mutation. */
export type CreateThemeInput = {
  bodyFontId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  logoFileId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  titleFontId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createTheme` mutation. */
export type CreateThemePayload = {
  __typename: "CreateThemePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileIdDraft: Maybe<File>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontIdDraft: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontIdDraft: Maybe<Font>;
  /** Reads a single `Org` that is related to this `Theme`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  theme: Maybe<Theme>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge: Maybe<ThemesEdge>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `createTheme` mutation. */
export type CreateThemePayloadThemeEdgeArgs = {
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** All input for the `createToken` mutation. */
export type CreateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `createToken` mutation. */
export type CreateTokenPayload = {
  __typename: "CreateTokenPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  tokenSecretPayload: Maybe<TokenSecretPayload>;
};

/** 3rd party credential. */
export type Credential = Node & {
  __typename: "Credential";
  /** Reads and enables pagination through a set of `Association`. */
  associationsByToCredentialAndOrgId: AssociationsConnection;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** The key of the credential. This is usually a hash. */
  credentialKey: Scalars["String"];
  /** The description of the credential. */
  description: Maybe<Scalars["String"]>;
  /** The primary unique identifier for the credential. */
  id: Scalars["UUID"];
  isShared: Maybe<Scalars["Boolean"]>;
  isSharedAll: Maybe<Scalars["Boolean"]>;
  isSharedExplicit: Maybe<Scalars["Boolean"]>;
  /** True if credential is used by at least on appInstance throughout the org */
  isUsed: Maybe<Scalars["Boolean"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Credential`. */
  orgByOrgId: Maybe<Org>;
  /** The id of the organization that credential belong to. */
  orgId: Scalars["UUID"];
  /** The provider for credential. */
  provider: Scalars["String"];
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareCredential: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareCredentialAndOrgId: ShareAssociationsConnection;
  /** Spaces that currently got shared access to the credential. */
  sharedSpacesByCredentialId: SpacesConnection;
  /** The id of the space that credential belong to. */
  spaceId: Scalars["UUID"];
  /** Reads a single `User` that is related to this `Credential`. */
  userByCreatedBy: Maybe<User>;
  /** The username of the credential. */
  username: Maybe<Scalars["String"]>;
};

/** 3rd party credential. */
export type CredentialAssociationsByToCredentialAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** 3rd party credential. */
export type CredentialShareAssociationsByShareCredentialArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** 3rd party credential. */
export type CredentialShareAssociationsByShareCredentialAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** 3rd party credential. */
export type CredentialSharedSpacesByCredentialIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/**
 * A condition to be used against `Credential` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CredentialCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `credentialKey` field. */
  credentialKey?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isShared` field. */
  isShared?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `Credential` object types. All fields are combined with a logical ‘and.’ */
export type CredentialFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CredentialFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `credentialKey` field. */
  credentialKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CredentialFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CredentialFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Credential` values. */
export type CredentialsConnection = {
  __typename: "CredentialsConnection";
  /** A list of edges which contains the `Credential` and cursor to aid in pagination. */
  edges: Array<CredentialsEdge>;
  /** A list of `Credential` objects. */
  nodes: Array<Credential>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Credential` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Credential` edge in the connection. */
export type CredentialsEdge = {
  __typename: "CredentialsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Credential` at the end of the edge. */
  node: Credential;
};

/** Methods to use when ordering `Credential`. */
export enum CredentialsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  CredentialKeyAsc = "CREDENTIAL_KEY_ASC",
  CredentialKeyDesc = "CREDENTIAL_KEY_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsSharedAllAsc = "IS_SHARED_ALL_ASC",
  IsSharedAllDesc = "IS_SHARED_ALL_DESC",
  IsSharedAsc = "IS_SHARED_ASC",
  IsSharedDesc = "IS_SHARED_DESC",
  IsSharedExplicitAsc = "IS_SHARED_EXPLICIT_ASC",
  IsSharedExplicitDesc = "IS_SHARED_EXPLICIT_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProviderAsc = "PROVIDER_ASC",
  ProviderDesc = "PROVIDER_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UsernameAsc = "USERNAME_ASC",
  UsernameDesc = "USERNAME_DESC",
}

export enum CurrencyCode {
  Eur = "EUR",
  Gbp = "GBP",
  Usd = "USD",
}

/** A filter to be used against CurrencyCode fields. All fields are combined with a logical ‘and.’ */
export type CurrencyCodeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CurrencyCode>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CurrencyCode>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CurrencyCode>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CurrencyCode>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CurrencyCode>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CurrencyCode>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CurrencyCode>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CurrencyCode>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CurrencyCode>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CurrencyCode>>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Date"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Date"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Date"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Date"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Date"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Date"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Date"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Date"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Date"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Date"]>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Datetime"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Datetime"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Datetime"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Datetime"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Datetime"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Datetime"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Datetime"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Datetime"]>>;
};

/** All input for the `deactivateToken` mutation. */
export type DeactivateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `deactivateToken` mutation. */
export type DeactivateTokenPayload = {
  __typename: "DeactivateTokenPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Token`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  token: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge: Maybe<TokensEdge>;
  /** Reads a single `User` that is related to this `Token`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUserId: Maybe<User>;
};

/** The output of our `deactivateToken` mutation. */
export type DeactivateTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the `defaultLayoutLandscape` mutation. */
export type DefaultLayoutLandscapeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `defaultLayoutLandscape` mutation. */
export type DefaultLayoutLandscapePayload = {
  __typename: "DefaultLayoutLandscapePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  uuid: Maybe<Scalars["UUID"]>;
};

/** All input for the `defaultLayoutPortrait` mutation. */
export type DefaultLayoutPortraitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `defaultLayoutPortrait` mutation. */
export type DefaultLayoutPortraitPayload = {
  __typename: "DefaultLayoutPortraitPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  uuid: Maybe<Scalars["UUID"]>;
};

/** All input for the `deleteAppInstance` mutation. */
export type DeleteAppInstanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The output of our `deleteAppInstance` mutation. */
export type DeleteAppInstancePayload = {
  __typename: "DeleteAppInstancePayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteAppInstance` mutation. */
export type DeleteAppInstancePayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `deleteAppInstances` mutation. */
export type DeleteAppInstancesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `deleteAppInstances` mutation. */
export type DeleteAppInstancesPayload = {
  __typename: "DeleteAppInstancesPayload";
  appInstances: Maybe<Array<AppInstance>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `deleteChannel` mutation. */
export type DeleteChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `deleteChannel` mutation. */
export type DeleteChannelPayload = {
  __typename: "DeleteChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteChannel` mutation. */
export type DeleteChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `deleteChannels` mutation. */
export type DeleteChannelsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `deleteChannels` mutation. */
export type DeleteChannelsPayload = {
  __typename: "DeleteChannelsPayload";
  channels: Maybe<Array<Channel>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `deleteCommonAppInstanceTemplateById` mutation. */
export type DeleteCommonAppInstanceTemplateByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `deleteCommonAppInstanceTemplateById` mutation. */
export type DeleteCommonAppInstanceTemplateByIdPayload = {
  __typename: "DeleteCommonAppInstanceTemplateByIdPayload";
  /** Reads a single `App` that is related to this `CommonAppInstanceTemplate`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstance` that is related to this `CommonAppInstanceTemplate`. */
  appInstanceBySourceAppInstanceId: Maybe<AppInstance>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  commonAppInstanceTemplate: Maybe<CommonAppInstanceTemplate>;
  /** An edge for our `CommonAppInstanceTemplate`. May be used by Relay 1. */
  commonAppInstanceTemplateEdge: Maybe<CommonAppInstanceTemplatesEdge>;
  /** Reads a single `CommonFile` that is related to this `CommonAppInstanceTemplate`. */
  commonFileByThumbnailFileId: Maybe<CommonFile>;
  /** Reads a single `Org` that is related to this `CommonAppInstanceTemplate`. */
  orgBySourceOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `deleteCommonAppInstanceTemplateById` mutation. */
export type DeleteCommonAppInstanceTemplateByIdPayloadCommonAppInstanceTemplateEdgeArgs = {
  orderBy?: InputMaybe<Array<CommonAppInstanceTemplatesOrderBy>>;
};

/** All input for the `deleteCredentialByKey` mutation. */
export type DeleteCredentialByKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  key?: InputMaybe<Scalars["String"]>;
};

/** The output of our `deleteCredentialByKey` mutation. */
export type DeleteCredentialByKeyPayload = {
  __typename: "DeleteCredentialByKeyPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  credential: Maybe<Credential>;
  /** An edge for our `Credential`. May be used by Relay 1. */
  credentialEdge: Maybe<CredentialsEdge>;
  /** Reads a single `Org` that is related to this `Credential`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Credential`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `deleteCredentialByKey` mutation. */
export type DeleteCredentialByKeyPayloadCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

/** All input for the `deleteCredential` mutation. */
export type DeleteCredentialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteCredential` mutation. */
export type DeleteCredentialPayload = {
  __typename: "DeleteCredentialPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  credential: Maybe<Credential>;
  /** An edge for our `Credential`. May be used by Relay 1. */
  credentialEdge: Maybe<CredentialsEdge>;
  /** Reads a single `Org` that is related to this `Credential`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Credential`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `deleteCredential` mutation. */
export type DeleteCredentialPayloadCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

export type DeleteDuplicateJobInput = {
  jobId: Scalars["String"];
};

export type DeleteDuplicateJobPayload = {
  __typename: "DeleteDuplicateJobPayload";
  id: Maybe<Scalars["String"]>;
  success: Maybe<Scalars["Boolean"]>;
};

export type DeleteDuplicateJobsInput = {
  jobIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type DeleteDuplicateJobsPayload = {
  __typename: "DeleteDuplicateJobsPayload";
  id: Maybe<Scalars["String"]>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `deleteFileById` mutation. */
export type DeleteFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteFileById` mutation. */
export type DeleteFileByIdPayload = {
  __typename: "DeleteFileByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteFileById` mutation. */
export type DeleteFileByIdPayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `deleteFileByIds` mutation. */
export type DeleteFileByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `deleteFileByIds` mutation. */
export type DeleteFileByIdsPayload = {
  __typename: "DeleteFileByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  files: Maybe<Array<File>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `deleteFolderById` mutation. */
export type DeleteFolderByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderId: Scalars["UUID"];
};

/** The output of our `deleteFolderById` mutation. */
export type DeleteFolderByIdPayload = {
  __typename: "DeleteFolderByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  folder: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Folder`. */
  folderByParentId: Maybe<Folder>;
  /** An edge for our `Folder`. May be used by Relay 1. */
  folderEdge: Maybe<FoldersEdge>;
  /** Reads a single `Org` that is related to this `Folder`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteFolderById` mutation. */
export type DeleteFolderByIdPayloadFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** All input for the `deleteFolderByIds` mutation. */
export type DeleteFolderByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `deleteFolderByIds` mutation. */
export type DeleteFolderByIdsPayload = {
  __typename: "DeleteFolderByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  folders: Maybe<Array<Folder>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `deleteFont` mutation. */
export type DeleteFontInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteFont` mutation. */
export type DeleteFontPayload = {
  __typename: "DeleteFontPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFont` that is related to this `Font`. */
  commonFontByCommonFontId: Maybe<CommonFont>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByRegularFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByThumbnailFileId: Maybe<File>;
  font: Maybe<Font>;
  /** An edge for our `Font`. May be used by Relay 1. */
  fontEdge: Maybe<FontsEdge>;
  /** Reads a single `Org` that is related to this `Font`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Font`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Font`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteFont` mutation. */
export type DeleteFontPayloadFontEdgeArgs = {
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** All input for the `deleteGroup` mutation. */
export type DeleteGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteGroup` mutation. */
export type DeleteGroupPayload = {
  __typename: "DeleteGroupPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  group: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge: Maybe<GroupsEdge>;
  /** Reads a single `Org` that is related to this `Group`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Group`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Group`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteGroup` mutation. */
export type DeleteGroupPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `deleteGroupPermissionSets` mutation. */
export type DeleteGroupPermissionSetsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `deleteGroupPermissionSets` mutation. */
export type DeleteGroupPermissionSetsPayload = {
  __typename: "DeleteGroupPermissionSetsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  group: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge: Maybe<GroupsEdge>;
  /** Reads a single `Org` that is related to this `Group`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Group`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Group`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteGroupPermissionSets` mutation. */
export type DeleteGroupPermissionSetsPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `deleteGroupSpacePermissionSets` mutation. */
export type DeleteGroupSpacePermissionSetsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteGroupSpacePermissionSets` mutation. */
export type DeleteGroupSpacePermissionSetsPayload = {
  __typename: "DeleteGroupSpacePermissionSetsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupId: Maybe<Group>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupIdAndOrgId: Maybe<Group>;
  groupSpace: Maybe<GroupSpace>;
  /** An edge for our `GroupSpace`. May be used by Relay 1. */
  groupSpaceEdge: Maybe<GroupSpacesEdge>;
  /** Reads a single `Org` that is related to this `GroupSpace`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `GroupSpace`. */
  spaceBySpaceId: Maybe<Space>;
};

/** The output of our `deleteGroupSpacePermissionSets` mutation. */
export type DeleteGroupSpacePermissionSetsPayloadGroupSpaceEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

/** All input for the `deleteLayout` mutation. */
export type DeleteLayoutInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The output of our `deleteLayout` mutation. */
export type DeleteLayoutPayload = {
  __typename: "DeleteLayoutPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  layout: Maybe<Layout>;
  /** An edge for our `Layout`. May be used by Relay 1. */
  layoutEdge: Maybe<LayoutsEdge>;
  /** Reads a single `Org` that is related to this `Layout`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Layout`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Layout`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Layout`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteLayout` mutation. */
export type DeleteLayoutPayloadLayoutEdgeArgs = {
  orderBy?: InputMaybe<Array<LayoutsOrderBy>>;
};

/** All input for the `deleteLinkById` mutation. */
export type DeleteLinkByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `deleteLinkById` mutation. */
export type DeleteLinkByIdPayload = {
  __typename: "DeleteLinkByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileIdAndOrgId: Maybe<File>;
  link: Maybe<Link>;
  /** An edge for our `Link`. May be used by Relay 1. */
  linkEdge: Maybe<LinksEdge>;
  /** Reads a single `Org` that is related to this `Link`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Link`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Link`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteLinkById` mutation. */
export type DeleteLinkByIdPayloadLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** All input for the `deleteLinks` mutation. */
export type DeleteLinksInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `deleteLinks` mutation. */
export type DeleteLinksPayload = {
  __typename: "DeleteLinksPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  links: Maybe<Array<Link>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `deleteNotificationRuleById` mutation. */
export type DeleteNotificationRuleByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  notificationRuleId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteNotificationRuleById` mutation. */
export type DeleteNotificationRuleByIdPayload = {
  __typename: "DeleteNotificationRuleByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRule: Maybe<NotificationRule>;
  /** An edge for our `NotificationRule`. May be used by Relay 1. */
  notificationRuleEdge: Maybe<NotificationRulesEdge>;
  /** Reads a single `Org` that is related to this `NotificationRule`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Screen` that is related to this `NotificationRule`. */
  screenByScreenIdAndOrgId: Maybe<Screen>;
  /** Reads a single `Space` that is related to this `NotificationRule`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `NotificationRule`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `deleteNotificationRuleById` mutation. */
export type DeleteNotificationRuleByIdPayloadNotificationRuleEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** All input for the `deleteNotificationSubscriptionById` mutation. */
export type DeleteNotificationSubscriptionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  notificationSubscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteNotificationSubscriptionById` mutation. */
export type DeleteNotificationSubscriptionByIdPayload = {
  __typename: "DeleteNotificationSubscriptionByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `NotificationRule` that is related to this `NotificationSubscription`. */
  notificationRuleByNotificationRuleId: Maybe<NotificationRule>;
  notificationSubscription: Maybe<NotificationSubscription>;
  /** An edge for our `NotificationSubscription`. May be used by Relay 1. */
  notificationSubscriptionEdge: Maybe<NotificationSubscriptionsEdge>;
  /** Reads a single `Org` that is related to this `NotificationSubscription`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `NotificationSubscription`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `NotificationSubscription`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `deleteNotificationSubscriptionById` mutation. */
export type DeleteNotificationSubscriptionByIdPayloadNotificationSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationSubscriptionsOrderBy>>;
};

/** All input for the `deleteOrgConnection` mutation. */
export type DeleteOrgConnectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteOrgConnection` mutation. */
export type DeleteOrgConnectionPayload = {
  __typename: "DeleteOrgConnectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `OrgConnection`. */
  orgByOrgId: Maybe<Org>;
  orgConnection: Maybe<OrgConnection>;
  /** An edge for our `OrgConnection`. May be used by Relay 1. */
  orgConnectionEdge: Maybe<OrgConnectionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `OrgConnection`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `OrgConnection`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteOrgConnection` mutation. */
export type DeleteOrgConnectionPayloadOrgConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgConnectionsOrderBy>>;
};

/** All input for the `deleteOrg` mutation. */
export type DeleteOrgInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  orgId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteOrg` mutation. */
export type DeleteOrgPayload = {
  __typename: "DeleteOrgPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteOrg` mutation. */
export type DeleteOrgPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `deletePermissionSet` mutation. */
export type DeletePermissionSetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deletePermissionSet` mutation. */
export type DeletePermissionSetPayload = {
  __typename: "DeletePermissionSetPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonPermissionSet` that is related to this `PermissionSet`. */
  commonPermissionSetByCommonPermissionSetId: Maybe<CommonPermissionSet>;
  /** Reads a single `Org` that is related to this `PermissionSet`. */
  orgByOrgId: Maybe<Org>;
  permissionSet: Maybe<PermissionSet>;
  /** An edge for our `PermissionSet`. May be used by Relay 1. */
  permissionSetEdge: Maybe<PermissionSetsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `deletePermissionSet` mutation. */
export type DeletePermissionSetPayloadPermissionSetEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionSetsOrderBy>>;
};

/** All input for the `deletePlaylist` mutation. */
export type DeletePlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `deletePlaylist` mutation. */
export type DeletePlaylistPayload = {
  __typename: "DeletePlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deletePlaylist` mutation. */
export type DeletePlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `deletePlaylists` mutation. */
export type DeletePlaylistsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  idList?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `deletePlaylists` mutation. */
export type DeletePlaylistsPayload = {
  __typename: "DeletePlaylistsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  playlists: Maybe<Array<Playlist>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `deleteScreenGroupById` mutation. */
export type DeleteScreenGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenGroupId?: InputMaybe<Scalars["UUID"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteScreenGroupById` mutation. */
export type DeleteScreenGroupByIdPayload = {
  __typename: "DeleteScreenGroupByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `ScreenGroup`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screenGroup: Maybe<ScreenGroup>;
  /** An edge for our `ScreenGroup`. May be used by Relay 1. */
  screenGroupEdge: Maybe<ScreenGroupsEdge>;
  /** Reads a single `Space` that is related to this `ScreenGroup`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `ScreenGroup`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `ScreenGroup`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteScreenGroupById` mutation. */
export type DeleteScreenGroupByIdPayloadScreenGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenGroupsOrderBy>>;
};

/** All input for the `deleteScreen` mutation. */
export type DeleteScreenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenId?: InputMaybe<Scalars["UUID"]>;
};

/** All input for the `deleteScreenNoteById` mutation. */
export type DeleteScreenNoteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenNoteId: Scalars["UUID"];
};

/** The output of our `deleteScreenNoteById` mutation. */
export type DeleteScreenNoteByIdPayload = {
  __typename: "DeleteScreenNoteByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `ScreenNote`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Screen` that is related to this `ScreenNote`. */
  screenByScreenId: Maybe<Screen>;
  screenNote: Maybe<ScreenNote>;
  /** An edge for our `ScreenNote`. May be used by Relay 1. */
  screenNoteEdge: Maybe<ScreenNotesEdge>;
  /** Reads a single `Space` that is related to this `ScreenNote`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `ScreenNote`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `ScreenNote`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteScreenNoteById` mutation. */
export type DeleteScreenNoteByIdPayloadScreenNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** The output of our `deleteScreen` mutation. */
export type DeleteScreenPayload = {
  __typename: "DeleteScreenPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteScreen` mutation. */
export type DeleteScreenPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

export type DeleteSiteByIdInput = {
  id: Scalars["UUID"];
};

export type DeleteSiteByIdPayload = {
  __typename: "DeleteSiteByIdPayload";
  site: Maybe<Site>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `deleteSpace` mutation. */
export type DeleteSpaceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaceId: Scalars["UUID"];
};

/** The output of our `deleteSpace` mutation. */
export type DeleteSpacePayload = {
  __typename: "DeleteSpacePayload";
  /** Reads a single `BillingCustomer` that is related to this `Space`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Folder` that is related to this `Space`. */
  folderByRootFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `Space`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  space: Maybe<Space>;
  /** An edge for our `Space`. May be used by Relay 1. */
  spaceEdge: Maybe<SpacesEdge>;
  /** Reads a single `User` that is related to this `Space`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Space`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteSpace` mutation. */
export type DeleteSpacePayloadSpaceEdgeArgs = {
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

/** All input for the `deleteTheme` mutation. */
export type DeleteThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteTheme` mutation. */
export type DeleteThemePayload = {
  __typename: "DeleteThemePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileIdDraft: Maybe<File>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontIdDraft: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontIdDraft: Maybe<Font>;
  /** Reads a single `Org` that is related to this `Theme`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  theme: Maybe<Theme>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge: Maybe<ThemesEdge>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `deleteTheme` mutation. */
export type DeleteThemePayloadThemeEdgeArgs = {
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** All input for the `deleteToken` mutation. */
export type DeleteTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `deleteToken` mutation. */
export type DeleteTokenPayload = {
  __typename: "DeleteTokenPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Token`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  token: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge: Maybe<TokensEdge>;
  /** Reads a single `User` that is related to this `Token`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUserId: Maybe<User>;
};

/** The output of our `deleteToken` mutation. */
export type DeleteTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the `deleteUserFromGroups` mutation. */
export type DeleteUserFromGroupsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteUserFromGroups` mutation. */
export type DeleteUserFromGroupsPayload = {
  __typename: "DeleteUserFromGroupsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  userGroups: Maybe<Array<UserGroup>>;
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `deleteUser` mutation. */
export type DeleteUserPayload = {
  __typename: "DeleteUserPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `deleteUser` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `depairScreen` mutation. */
export type DepairScreenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  playerId: Scalars["String"];
  screenId: Scalars["UUID"];
};

/** The output of our `depairScreen` mutation. */
export type DepairScreenPayload = {
  __typename: "DepairScreenPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `depairScreen` mutation. */
export type DepairScreenPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

export type DirectdebitAuthorizeInput = {
  redirectPath: Scalars["String"];
  spaceId?: InputMaybe<Scalars["String"]>;
};

export type DirectdebitAuthorizePayload = {
  __typename: "DirectdebitAuthorizePayload";
  id: Maybe<Scalars["String"]>;
  query: Maybe<Query>;
  redirectUrl: Maybe<Scalars["String"]>;
  successRedirectUrl: Maybe<Scalars["String"]>;
};

export type DownloadMetricsInput = {
  file_name?: InputMaybe<Scalars["String"]>;
};

export type DownloadMetricsPayload = {
  __typename: "DownloadMetricsPayload";
  url: Maybe<Scalars["String"]>;
};

/** All input for the `duplicateAppInstance` mutation. */
export type DuplicateAppInstanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `duplicateAppInstance` mutation. */
export type DuplicateAppInstancePayload = {
  __typename: "DuplicateAppInstancePayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `duplicateAppInstance` mutation. */
export type DuplicateAppInstancePayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `duplicateChannel` mutation. */
export type DuplicateChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
};

/** The output of our `duplicateChannel` mutation. */
export type DuplicateChannelPayload = {
  __typename: "DuplicateChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `duplicateChannel` mutation. */
export type DuplicateChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `duplicateEditableSharedChannel` mutation. */
export type DuplicateEditableSharedChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** The output of our `duplicateEditableSharedChannel` mutation. */
export type DuplicateEditableSharedChannelPayload = {
  __typename: "DuplicateEditableSharedChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `duplicateEditableSharedChannel` mutation. */
export type DuplicateEditableSharedChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** An input for mutations affecting `DuplicateFile` */
export type DuplicateFileInput = {
  commonFileId?: InputMaybe<Scalars["UUID"]>;
  fileOutputs?: InputMaybe<Array<InputMaybe<DuplicateFileOutputInput>>>;
  folderId?: InputMaybe<Scalars["UUID"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  metadata?: InputMaybe<Scalars["JSON"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["BigInt"]>;
  source?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** An input for mutations affecting `DuplicateFileOutput` */
export type DuplicateFileOutputInput = {
  content?: InputMaybe<Scalars["JSON"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  metadata?: InputMaybe<Scalars["JSON"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["BigInt"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type DuplicateJobPayload = {
  __typename: "DuplicateJobPayload";
  id: Maybe<Scalars["String"]>;
  status: Maybe<DuplicateJobStatus>;
};

export enum DuplicateJobStatus {
  Completed = "completed",
  Failed = "failed",
  NotStarted = "not_started",
  Processed = "processed",
  Processing = "processing",
  Queue = "queue",
}

export enum DuplicateJobStatusType {
  Import = "import",
  PublicSnapshot = "public_snapshot",
  Snapshot = "snapshot",
}

export enum DuplicateJobType {
  Import = "import",
  Snapshot = "snapshot",
  SnapshotAndImport = "snapshot_and_import",
}

/** All input for the `duplicatePlaylist` mutation. */
export type DuplicatePlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
};

/** The output of our `duplicatePlaylist` mutation. */
export type DuplicatePlaylistPayload = {
  __typename: "DuplicatePlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `duplicatePlaylist` mutation. */
export type DuplicatePlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** @omit/nLook up table for embed content */
export type Embed = Node & {
  __typename: "Embed";
  /** Reads a single `Channel` that is related to this `Embed`. */
  channelByChannelId: Maybe<Channel>;
  channelId: Maybe<Scalars["UUID"]>;
  cover: Scalars["JSON"];
  id: Scalars["BigInt"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Embed`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Playlist` that is related to this `Embed`. */
  playlistByPlaylistId: Maybe<Playlist>;
  playlistId: Maybe<Scalars["UUID"]>;
  shortId: Scalars["String"];
};

/** A condition to be used against `Embed` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EmbedCondition = {
  /** Checks for equality with the object’s `channelId` field. */
  channelId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `cover` field. */
  cover?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `playlistId` field. */
  playlistId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `shortId` field. */
  shortId?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `Embed` object types. All fields are combined with a logical ‘and.’ */
export type EmbedFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmbedFilter>>;
  /** Filter by the object’s `channelId` field. */
  channelId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `cover` field. */
  cover?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmbedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmbedFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `playlistId` field. */
  playlistId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `shortId` field. */
  shortId?: InputMaybe<StringFilter>;
};

export type EmbedRequest = {
  __typename: "EmbedRequest";
  contentId: Maybe<Scalars["String"]>;
  contentType: Maybe<Scalars["String"]>;
  isPublic: Maybe<Scalars["Boolean"]>;
};

/** Records of user's embed content viewing activities. */
export type EmbedViewLog = Node & {
  __typename: "EmbedViewLog";
  /** The action: authenticate, play */
  action: Scalars["String"];
  /** The client's IP address obtained from load balancer or from server. */
  clientAddress: Scalars["String"];
  /** The client's user agent. */
  clientAgent: Scalars["String"];
  contentId: Scalars["UUID"];
  /** The id of playlist or channel embeded */
  contentType: Scalars["String"];
  /** Random UUID generated, remains same for the browser, stored in localstorage. */
  embedViewerId: Scalars["UUID"];
  id: Scalars["BigInt"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** The id of the organization. */
  orgId: Scalars["UUID"];
  /** The client's IP address explicitly sent by client. */
  playerClientAddress: Scalars["String"];
  /** The id generated by client. */
  sessionId: Scalars["UUID"];
  /** The timestamp of actioned. */
  timestamp: Maybe<Scalars["Datetime"]>;
  /** The id of the user (for studio_embed_user role). */
  userId: Maybe<Scalars["UUID"]>;
};

/** A connection to a list of `EmbedViewLog` values. */
export type EmbedViewLogsConnection = {
  __typename: "EmbedViewLogsConnection";
  /** A list of edges which contains the `EmbedViewLog` and cursor to aid in pagination. */
  edges: Array<EmbedViewLogsEdge>;
  /** A list of `EmbedViewLog` objects. */
  nodes: Array<EmbedViewLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmbedViewLog` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `EmbedViewLog` edge in the connection. */
export type EmbedViewLogsEdge = {
  __typename: "EmbedViewLogsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `EmbedViewLog` at the end of the edge. */
  node: EmbedViewLog;
};

/** A connection to a list of `Embed` values. */
export type EmbedsConnection = {
  __typename: "EmbedsConnection";
  /** A list of edges which contains the `Embed` and cursor to aid in pagination. */
  edges: Array<EmbedsEdge>;
  /** A list of `Embed` objects. */
  nodes: Array<Embed>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Embed` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Embed` edge in the connection. */
export type EmbedsEdge = {
  __typename: "EmbedsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Embed` at the end of the edge. */
  node: Embed;
};

/** Methods to use when ordering `Embed`. */
export enum EmbedsOrderBy {
  ChannelIdAsc = "CHANNEL_ID_ASC",
  ChannelIdDesc = "CHANNEL_ID_DESC",
  CoverAsc = "COVER_ASC",
  CoverDesc = "COVER_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PlaylistIdAsc = "PLAYLIST_ID_ASC",
  PlaylistIdDesc = "PLAYLIST_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ShortIdAsc = "SHORT_ID_ASC",
  ShortIdDesc = "SHORT_ID_DESC",
}

/** All input for the `ensurePolicyReadOnly` mutation. */
export type EnsurePolicyReadOnlyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  tNames?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `ensurePolicyReadOnly` mutation. */
export type EnsurePolicyReadOnlyPayload = {
  __typename: "EnsurePolicyReadOnlyPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  strings: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** All input for the `ensurePolicyStudioJobRunner` mutation. */
export type EnsurePolicyStudioJobRunnerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  tNames?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `ensurePolicyStudioJobRunner` mutation. */
export type EnsurePolicyStudioJobRunnerPayload = {
  __typename: "EnsurePolicyStudioJobRunnerPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  strings: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type EventBuffer = Node & {
  __typename: "EventBuffer";
  id: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  payload: Scalars["String"];
};

/**
 * A condition to be used against `EventBuffer` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EventBufferCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `EventBuffer` object types. All fields are combined with a logical ‘and.’ */
export type EventBufferFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EventBufferFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EventBufferFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EventBufferFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<StringFilter>;
};

/** A connection to a list of `EventBuffer` values. */
export type EventBuffersConnection = {
  __typename: "EventBuffersConnection";
  /** A list of edges which contains the `EventBuffer` and cursor to aid in pagination. */
  edges: Array<EventBuffersEdge>;
  /** A list of `EventBuffer` objects. */
  nodes: Array<EventBuffer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EventBuffer` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `EventBuffer` edge in the connection. */
export type EventBuffersEdge = {
  __typename: "EventBuffersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `EventBuffer` at the end of the edge. */
  node: EventBuffer;
};

/** Methods to use when ordering `EventBuffer`. */
export enum EventBuffersOrderBy {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  PayloadAsc = "PAYLOAD_ASC",
  PayloadDesc = "PAYLOAD_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type ExchangePreviewTokenPayload = {
  __typename: "ExchangePreviewTokenPayload";
  previewToken: Maybe<Scalars["String"]>;
};

export type ExportLogsFilterParams = {
  appViewerUrl?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  appid?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  appname?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  channelId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  channelName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  channelZoneId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  consumer?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  instanceId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  keyword?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  message?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  platform?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playbackType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playerVersion?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playlistId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playlistName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  screenId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  screenName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  source?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  spaceId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  spaceName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  studioPlayerVersion?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  viewUrl?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ExportPlaybackLogsInput = {
  direction?: InputMaybe<Scalars["String"]>;
  endTime?: InputMaybe<Scalars["BigInt"]>;
  filterParams?: InputMaybe<ExportLogsFilterParams>;
  limit?: InputMaybe<Scalars["Int"]>;
  startTime?: InputMaybe<Scalars["BigInt"]>;
};

/** The favorite folders and files by each user. */
export type Favorite = {
  __typename: "Favorite";
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** The uuid of the file that user added favorite. */
  fileId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** The uuid of the folder that user added favorite. */
  folderId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** The uuid of the link that user added favorite. */
  linkId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization that player belong to. */
  orgId: Scalars["UUID"];
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** The uuid of the playlist that user added favorite. */
  playlistId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** The uuid of the site that user added favorite. */
  siteId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Scalars["UUID"];
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
  /** The uuid of the user whom added favorite. */
  userId: Scalars["UUID"];
};

/**
 * A condition to be used against `Favorite` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FavoriteCondition = {
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `folderId` field. */
  folderId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `linkId` field. */
  linkId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `playlistId` field. */
  playlistId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `siteId` field. */
  siteId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Favorite` object types. All fields are combined with a logical ‘and.’ */
export type FavoriteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FavoriteFilter>>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `folderId` field. */
  folderId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `linkId` field. */
  linkId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FavoriteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FavoriteFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `playlistId` field. */
  playlistId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `siteId` field. */
  siteId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Favorite` values. */
export type FavoritesConnection = {
  __typename: "FavoritesConnection";
  /** A list of edges which contains the `Favorite` and cursor to aid in pagination. */
  edges: Array<FavoritesEdge>;
  /** A list of `Favorite` objects. */
  nodes: Array<Favorite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Favorite` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Favorite` edge in the connection. */
export type FavoritesEdge = {
  __typename: "FavoritesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Favorite` at the end of the edge. */
  node: Favorite;
};

/** Methods to use when ordering `Favorite`. */
export enum FavoritesOrderBy {
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  FolderIdAsc = "FOLDER_ID_ASC",
  FolderIdDesc = "FOLDER_ID_DESC",
  LinkIdAsc = "LINK_ID_ASC",
  LinkIdDesc = "LINK_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PlaylistIdAsc = "PLAYLIST_ID_ASC",
  PlaylistIdDesc = "PLAYLIST_ID_DESC",
  SiteIdAsc = "SITE_ID_ASC",
  SiteIdDesc = "SITE_ID_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** Default feature flag settings (imposed by ScreenCloud). */
export type FeatureFlag = Node & {
  __typename: "FeatureFlag";
  createdAt: Maybe<Scalars["Datetime"]>;
  /** Is this flag customizable by orgs? */
  defaultCustomizable: Scalars["Boolean"];
  description: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `FeatureFlagOrg`. */
  featureFlagOrgsByKey: FeatureFlagOrgsConnection;
  /** The unique name of the feature flag. */
  key: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** For enforce value of this feature flag. */
  overwriteValue: Maybe<Scalars["Boolean"]>;
  /** Tags related to the flag (e.g. beta, experimental etc) */
  tags: Array<Maybe<FeatureFlagTags>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  /** Default value for this feature flag. */
  value: Scalars["Boolean"];
  visibility: FeatureFlagVisibility;
};

/** Default feature flag settings (imposed by ScreenCloud). */
export type FeatureFlagFeatureFlagOrgsByKeyArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FeatureFlagOrgCondition>;
  filter: InputMaybe<FeatureFlagOrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FeatureFlagOrgsOrderBy>>;
};

/**
 * A condition to be used against `FeatureFlag` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeatureFlagCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `defaultCustomizable` field. */
  defaultCustomizable?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `overwriteValue` field. */
  overwriteValue?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `visibility` field. */
  visibility?: InputMaybe<FeatureFlagVisibility>;
};

/** A filter to be used against `FeatureFlag` object types. All fields are combined with a logical ‘and.’ */
export type FeatureFlagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeatureFlagFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `defaultCustomizable` field. */
  defaultCustomizable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeatureFlagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeatureFlagFilter>>;
  /** Filter by the object’s `overwriteValue` field. */
  overwriteValue?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<FeatureFlagTagsListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `visibility` field. */
  visibility?: InputMaybe<FeatureFlagVisibilityFilter>;
};

/** Feature flag organization overrides. */
export type FeatureFlagOrg = Node & {
  __typename: "FeatureFlagOrg";
  /** The custom_value of this feature flag for the org. */
  customValue: Maybe<Scalars["Boolean"]>;
  /** Can this org edit the value. */
  customizable: Maybe<Scalars["Boolean"]>;
  /** Reads a single `FeatureFlag` that is related to this `FeatureFlagOrg`. */
  featureFlagByKey: Maybe<FeatureFlag>;
  /** Feature flag this record is related to. */
  key: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `FeatureFlagOrg`. */
  orgByOrgId: Maybe<Org>;
  /** The id of the organization the feature flag belongs to. */
  orgId: Scalars["UUID"];
  /** The default value of this feature flag. */
  value: Maybe<Scalars["Boolean"]>;
};

/**
 * A condition to be used against `FeatureFlagOrg` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FeatureFlagOrgCondition = {
  /** Checks for equality with the object’s `customValue` field. */
  customValue?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `customizable` field. */
  customizable?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars["Boolean"]>;
};

/** A filter to be used against `FeatureFlagOrg` object types. All fields are combined with a logical ‘and.’ */
export type FeatureFlagOrgFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeatureFlagOrgFilter>>;
  /** Filter by the object’s `customValue` field. */
  customValue?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `customizable` field. */
  customizable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeatureFlagOrgFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeatureFlagOrgFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<BooleanFilter>;
};

/** A connection to a list of `FeatureFlagOrg` values. */
export type FeatureFlagOrgsConnection = {
  __typename: "FeatureFlagOrgsConnection";
  /** A list of edges which contains the `FeatureFlagOrg` and cursor to aid in pagination. */
  edges: Array<FeatureFlagOrgsEdge>;
  /** A list of `FeatureFlagOrg` objects. */
  nodes: Array<FeatureFlagOrg>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeatureFlagOrg` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `FeatureFlagOrg` edge in the connection. */
export type FeatureFlagOrgsEdge = {
  __typename: "FeatureFlagOrgsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `FeatureFlagOrg` at the end of the edge. */
  node: FeatureFlagOrg;
};

/** Methods to use when ordering `FeatureFlagOrg`. */
export enum FeatureFlagOrgsOrderBy {
  CustomizableAsc = "CUSTOMIZABLE_ASC",
  CustomizableDesc = "CUSTOMIZABLE_DESC",
  CustomValueAsc = "CUSTOM_VALUE_ASC",
  CustomValueDesc = "CUSTOM_VALUE_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
}

export enum FeatureFlagTags {
  Beta = "BETA",
  Deprecated = "DEPRECATED",
  Experimental = "EXPERIMENTAL",
  Internal = "INTERNAL",
  Maintenance = "MAINTENANCE",
}

/** A filter to be used against FeatureFlagTags List fields. All fields are combined with a logical ‘and.’ */
export type FeatureFlagTagsListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<FeatureFlagTags>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<FeatureFlagTags>>>;
};

export enum FeatureFlagVisibility {
  Internal = "INTERNAL",
  Private = "PRIVATE",
  Public = "PUBLIC",
}

/** A filter to be used against FeatureFlagVisibility fields. All fields are combined with a logical ‘and.’ */
export type FeatureFlagVisibilityFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<FeatureFlagVisibility>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<FeatureFlagVisibility>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<FeatureFlagVisibility>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<FeatureFlagVisibility>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<FeatureFlagVisibility>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<FeatureFlagVisibility>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<FeatureFlagVisibility>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<FeatureFlagVisibility>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<FeatureFlagVisibility>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<FeatureFlagVisibility>>;
};

/** A connection to a list of `FeatureFlag` values. */
export type FeatureFlagsConnection = {
  __typename: "FeatureFlagsConnection";
  /** A list of edges which contains the `FeatureFlag` and cursor to aid in pagination. */
  edges: Array<FeatureFlagsEdge>;
  /** A list of `FeatureFlag` objects. */
  nodes: Array<FeatureFlag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeatureFlag` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `FeatureFlag` edge in the connection. */
export type FeatureFlagsEdge = {
  __typename: "FeatureFlagsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `FeatureFlag` at the end of the edge. */
  node: FeatureFlag;
};

/** Methods to use when ordering `FeatureFlag`. */
export enum FeatureFlagsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DefaultCustomizableAsc = "DEFAULT_CUSTOMIZABLE_ASC",
  DefaultCustomizableDesc = "DEFAULT_CUSTOMIZABLE_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  KeyAsc = "KEY_ASC",
  KeyDesc = "KEY_DESC",
  Natural = "NATURAL",
  OverwriteValueAsc = "OVERWRITE_VALUE_ASC",
  OverwriteValueDesc = "OVERWRITE_VALUE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  ValueAsc = "VALUE_ASC",
  ValueDesc = "VALUE_DESC",
  VisibilityAsc = "VISIBILITY_ASC",
  VisibilityDesc = "VISIBILITY_DESC",
}

/** The file information. */
export type File = Node & {
  __typename: "File";
  /** Reads and enables pagination through a set of `AppInstance`. */
  appInstancesByThumbnailFileId: AppInstancesConnection;
  /** Return associations to file by file and space id. */
  associationsByFileAndSpaceId: AssociationsConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByToFileAndOrgId: AssociationsConnection;
  availableAt: Maybe<Scalars["Datetime"]>;
  /** Get casted screen(s) by file's id. */
  castedScreenByFileId: ScreensConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByCoverImageId: ChannelsConnection;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  commonFileId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `CommonFont`. */
  commonFontsByBoldFileId: CommonFontsConnection;
  /** Reads and enables pagination through a set of `CommonFont`. */
  commonFontsByBoldItalicFileId: CommonFontsConnection;
  /** Reads and enables pagination through a set of `CommonFont`. */
  commonFontsByItalicFileId: CommonFontsConnection;
  /** Reads and enables pagination through a set of `CommonFont`. */
  commonFontsByRegularFileId: CommonFontsConnection;
  /** Reads and enables pagination through a set of `CommonFont`. */
  commonFontsByThumbnailFileId: CommonFontsConnection;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  deletedAt: Maybe<Scalars["Datetime"]>;
  expireAt: Maybe<Scalars["Datetime"]>;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByFileId: FavoritesConnection;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByFileIdAndOrgId: FavoritesConnection;
  /** Reads and enables pagination through a set of `FileOutput`. */
  fileOutputsByFileId: FileOutputsConnection;
  /** Reads and enables pagination through a set of `FileProcessingJob`. */
  fileProcessingJobsByFileId: FileProcessingJobsConnection;
  /** The media processing status of this file. */
  fileProcessingStatus: Maybe<JobStatus>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** The uuid of the folder that file belong to. */
  folderId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByBoldFileId: FontsConnection;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByBoldItalicFileId: FontsConnection;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByItalicFileId: FontsConnection;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByRegularFileId: FontsConnection;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByThumbnailFileId: FontsConnection;
  /** The uuid of the file. */
  id: Scalars["UUID"];
  /** Helper function for return expired status check. */
  isExpired: Maybe<Scalars["Boolean"]>;
  /** Return favorite status of a file. */
  isFavorite: Maybe<Scalars["Boolean"]>;
  isShared: Maybe<Scalars["Boolean"]>;
  isSharedAll: Maybe<Scalars["Boolean"]>;
  isSharedExplicit: Maybe<Scalars["Boolean"]>;
  /** Reads and enables pagination through a set of `Link`. */
  linksByFileId: LinksConnection;
  /** Reads and enables pagination through a set of `Link`. */
  linksByFileIdAndOrgId: LinksConnection;
  metadata: Scalars["JSON"];
  /** The mimetype of the file. */
  mimetype: Maybe<Scalars["String"]>;
  /** The name of the file. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization that file belong to. */
  orgId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `Org`. */
  orgsByBrandBackgroundId: OrgsConnection;
  /** Reads and enables pagination through a set of `Org`. */
  orgsByBrandLogoId: OrgsConnection;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByFileIdAndOrgId: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareFile: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareFileAndOrgId: ShareAssociationsConnection;
  /** Spaces that currently got shared access to the file. */
  sharedSpacesByFileId: SpacesConnection;
  /** Reads and enables pagination through a set of `Site`. */
  sitesByThumbnailId: SitesConnection;
  /** Reads and enables pagination through a set of `Site`. */
  sitesByThumbnailIdAndOrgId: SitesConnection;
  /** The size of the file in bytes. */
  size: Maybe<Scalars["BigInt"]>;
  /** The source of the file. */
  source: Maybe<Scalars["String"]>;
  spaceId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByToFileAndOrgId: TagAssociationsConnection;
  /** The json array of tags for the file. */
  tags: Array<Maybe<Scalars["String"]>>;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByLogoFileId: ThemesConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByLogoFileIdDraft: ThemesConnection;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads and enables pagination through a set of `WhiteLabel`. */
  whiteLabelsByGlyphFileId: WhiteLabelsConnection;
};

/** The file information. */
export type FileAppInstancesByThumbnailFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** The file information. */
export type FileAssociationsByFileAndSpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The file information. */
export type FileAssociationsByToFileAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The file information. */
export type FileCastedScreenByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The file information. */
export type FileChannelsByCoverImageIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** The file information. */
export type FileCommonFontsByBoldFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonFontCondition>;
  filter: InputMaybe<CommonFontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonFontsOrderBy>>;
};

/** The file information. */
export type FileCommonFontsByBoldItalicFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonFontCondition>;
  filter: InputMaybe<CommonFontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonFontsOrderBy>>;
};

/** The file information. */
export type FileCommonFontsByItalicFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonFontCondition>;
  filter: InputMaybe<CommonFontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonFontsOrderBy>>;
};

/** The file information. */
export type FileCommonFontsByRegularFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonFontCondition>;
  filter: InputMaybe<CommonFontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonFontsOrderBy>>;
};

/** The file information. */
export type FileCommonFontsByThumbnailFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonFontCondition>;
  filter: InputMaybe<CommonFontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonFontsOrderBy>>;
};

/** The file information. */
export type FileFavoritesByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The file information. */
export type FileFavoritesByFileIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The file information. */
export type FileFileOutputsByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileOutputCondition>;
  filter: InputMaybe<FileOutputFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileOutputsOrderBy>>;
};

/** The file information. */
export type FileFileProcessingJobsByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileProcessingJobCondition>;
  filter: InputMaybe<FileProcessingJobFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileProcessingJobsOrderBy>>;
};

/** The file information. */
export type FileFontsByBoldFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** The file information. */
export type FileFontsByBoldItalicFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** The file information. */
export type FileFontsByItalicFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** The file information. */
export type FileFontsByRegularFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** The file information. */
export type FileFontsByThumbnailFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** The file information. */
export type FileLinksByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LinkCondition>;
  filter: InputMaybe<LinkFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** The file information. */
export type FileLinksByFileIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LinkCondition>;
  filter: InputMaybe<LinkFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** The file information. */
export type FileOrgsByBrandBackgroundIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** The file information. */
export type FileOrgsByBrandLogoIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** The file information. */
export type FileScreenContentHistoriesByFileIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** The file information. */
export type FileShareAssociationsByShareFileArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The file information. */
export type FileShareAssociationsByShareFileAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The file information. */
export type FileSharedSpacesByFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The file information. */
export type FileSitesByThumbnailIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

/** The file information. */
export type FileSitesByThumbnailIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

/** The file information. */
export type FileTagAssociationsByToFileAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** The file information. */
export type FileThemesByLogoFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** The file information. */
export type FileThemesByLogoFileIdDraftArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** The file information. */
export type FileWhiteLabelsByGlyphFileIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<WhiteLabelCondition>;
  filter: InputMaybe<WhiteLabelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `availableAt` field. */
  availableAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `commonFileId` field. */
  commonFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `expireAt` field. */
  expireAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `fileProcessingStatus` field. */
  fileProcessingStatus?: InputMaybe<JobStatus>;
  /** Checks for equality with the object’s `folderId` field. */
  folderId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isShared` field. */
  isShared?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `mimetype` field. */
  mimetype?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars["BigInt"]>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `availableAt` field. */
  availableAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `commonFileId` field. */
  commonFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `expireAt` field. */
  expireAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileProcessingStatus` field. */
  fileProcessingStatus?: InputMaybe<JobStatusFilter>;
  /** Filter by the object’s `folderId` field. */
  folderId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mimetype` field. */
  mimetype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<StringFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** The processed variant of studio.file */
export type FileOutput = Node & {
  __typename: "FileOutput";
  commonFileId: Maybe<Scalars["UUID"]>;
  /** Reads a single `CommonFileOutput` that is related to this `FileOutput`. */
  commonFileOutputByCommonFileId: Maybe<CommonFileOutput>;
  /** The collection of urls and mimetypes for complex outputs. */
  content: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  /** Reads a single `File` that is related to this `FileOutput`. */
  fileByFileId: Maybe<File>;
  /** The uuid of the original file. */
  fileId: Scalars["UUID"];
  /** Reads a single `FileProcessingJob` that is related to this `FileOutput`. */
  fileProcessingJobByFileProcessingJobId: Maybe<FileProcessingJob>;
  /** The uuid of the file_processing_job. */
  fileProcessingJobId: Maybe<Scalars["UUID"]>;
  /** The uuid of the file_output. */
  id: Scalars["UUID"];
  /** The collection of additional data for the file_output. */
  metadata: Maybe<Scalars["JSON"]>;
  /** The mimetype string of the processed file. */
  mimetype: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `FileOutput`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization. */
  orgId: Scalars["UUID"];
  /** The size (bytes) of the processed file. */
  size: Maybe<Scalars["BigInt"]>;
  /** Reads a single `Space` that is related to this `FileOutput`. */
  spaceBySpaceId: Maybe<Space>;
  /** The id of the space that screen_group belong to. */
  spaceId: Maybe<Scalars["UUID"]>;
  /** The (first) url of the processed file. */
  url: Maybe<Scalars["String"]>;
};

/**
 * A condition to be used against `FileOutput` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FileOutputCondition = {
  /** Checks for equality with the object’s `commonFileId` field. */
  commonFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `fileProcessingJobId` field. */
  fileProcessingJobId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `mimetype` field. */
  mimetype?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars["BigInt"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `FileOutput` object types. All fields are combined with a logical ‘and.’ */
export type FileOutputFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileOutputFilter>>;
  /** Filter by the object’s `commonFileId` field. */
  commonFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fileProcessingJobId` field. */
  fileProcessingJobId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mimetype` field. */
  mimetype?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileOutputFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileOutputFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FileOutput` values. */
export type FileOutputsConnection = {
  __typename: "FileOutputsConnection";
  /** A list of edges which contains the `FileOutput` and cursor to aid in pagination. */
  edges: Array<FileOutputsEdge>;
  /** A list of `FileOutput` objects. */
  nodes: Array<FileOutput>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileOutput` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `FileOutput` edge in the connection. */
export type FileOutputsEdge = {
  __typename: "FileOutputsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `FileOutput` at the end of the edge. */
  node: FileOutput;
};

/** Methods to use when ordering `FileOutput`. */
export enum FileOutputsOrderBy {
  CommonFileIdAsc = "COMMON_FILE_ID_ASC",
  CommonFileIdDesc = "COMMON_FILE_ID_DESC",
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  FileProcessingJobIdAsc = "FILE_PROCESSING_JOB_ID_ASC",
  FileProcessingJobIdDesc = "FILE_PROCESSING_JOB_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  MimetypeAsc = "MIMETYPE_ASC",
  MimetypeDesc = "MIMETYPE_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SizeAsc = "SIZE_ASC",
  SizeDesc = "SIZE_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
}

/** The jobs to be processed by the post-processing service */
export type FileProcessingJob = Node & {
  __typename: "FileProcessingJob";
  createdAt: Maybe<Scalars["Datetime"]>;
  error: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `FileProcessingJob`. */
  fileByFileId: Maybe<File>;
  /** The uuid of the file. */
  fileId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `FileOutput`. */
  fileOutputsByFileProcessingJobId: FileOutputsConnection;
  finishedAt: Maybe<Scalars["Datetime"]>;
  /** The uuid of the job. */
  id: Scalars["UUID"];
  /** Job/file information for file processing */
  info: Maybe<Scalars["JSON"]>;
  /** The target output format of the file */
  jobTargetOutput: Maybe<JobTargetOutput>;
  /** The type of file processing job */
  jobType: Maybe<JobType>;
  lastRetryAt: Maybe<Scalars["Datetime"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `FileProcessingJob`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization who holds the file. */
  orgId: Scalars["UUID"];
  retries: Scalars["Int"];
  /** Reads a single `Space` that is related to this `FileProcessingJob`. */
  spaceBySpaceId: Maybe<Space>;
  /** The id of the space that screen_group belong to. */
  spaceId: Maybe<Scalars["UUID"]>;
  startedAt: Maybe<Scalars["Datetime"]>;
  status: JobStatus;
};

/** The jobs to be processed by the post-processing service */
export type FileProcessingJobFileOutputsByFileProcessingJobIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileOutputCondition>;
  filter: InputMaybe<FileOutputFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileOutputsOrderBy>>;
};

/**
 * A condition to be used against `FileProcessingJob` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FileProcessingJobCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `finishedAt` field. */
  finishedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `info` field. */
  info?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `jobTargetOutput` field. */
  jobTargetOutput?: InputMaybe<JobTargetOutput>;
  /** Checks for equality with the object’s `jobType` field. */
  jobType?: InputMaybe<JobType>;
  /** Checks for equality with the object’s `lastRetryAt` field. */
  lastRetryAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `retries` field. */
  retries?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<JobStatus>;
};

/** A filter to be used against `FileProcessingJob` object types. All fields are combined with a logical ‘and.’ */
export type FileProcessingJobFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileProcessingJobFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `error` field. */
  error?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `finishedAt` field. */
  finishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `info` field. */
  info?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `jobTargetOutput` field. */
  jobTargetOutput?: InputMaybe<JobTargetOutputFilter>;
  /** Filter by the object’s `jobType` field. */
  jobType?: InputMaybe<JobTypeFilter>;
  /** Filter by the object’s `lastRetryAt` field. */
  lastRetryAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileProcessingJobFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileProcessingJobFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `retries` field. */
  retries?: InputMaybe<IntFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<JobStatusFilter>;
};

/** A connection to a list of `FileProcessingJob` values. */
export type FileProcessingJobsConnection = {
  __typename: "FileProcessingJobsConnection";
  /** A list of edges which contains the `FileProcessingJob` and cursor to aid in pagination. */
  edges: Array<FileProcessingJobsEdge>;
  /** A list of `FileProcessingJob` objects. */
  nodes: Array<FileProcessingJob>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileProcessingJob` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `FileProcessingJob` edge in the connection. */
export type FileProcessingJobsEdge = {
  __typename: "FileProcessingJobsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `FileProcessingJob` at the end of the edge. */
  node: FileProcessingJob;
};

/** Methods to use when ordering `FileProcessingJob`. */
export enum FileProcessingJobsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  ErrorAsc = "ERROR_ASC",
  ErrorDesc = "ERROR_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  FinishedAtAsc = "FINISHED_AT_ASC",
  FinishedAtDesc = "FINISHED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  InfoAsc = "INFO_ASC",
  InfoDesc = "INFO_DESC",
  JobTargetOutputAsc = "JOB_TARGET_OUTPUT_ASC",
  JobTargetOutputDesc = "JOB_TARGET_OUTPUT_DESC",
  JobTypeAsc = "JOB_TYPE_ASC",
  JobTypeDesc = "JOB_TYPE_DESC",
  LastRetryAtAsc = "LAST_RETRY_AT_ASC",
  LastRetryAtDesc = "LAST_RETRY_AT_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RetriesAsc = "RETRIES_ASC",
  RetriesDesc = "RETRIES_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  StartedAtAsc = "STARTED_AT_ASC",
  StartedAtDesc = "STARTED_AT_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
}

/** To contain the list of deleted file to be clean up on server. */
export type FileToDelete = Node & {
  __typename: "FileToDelete";
  createdAt: Maybe<Scalars["Datetime"]>;
  deletedAt: Maybe<Scalars["Datetime"]>;
  /** The error of a failed file_to_delete operation. */
  error: Maybe<Scalars["String"]>;
  fileId: Maybe<Scalars["UUID"]>;
  folderId: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  metadata: Maybe<Scalars["JSON"]>;
  mimetype: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  orgId: Scalars["UUID"];
  size: Maybe<Scalars["BigInt"]>;
  source: Maybe<Scalars["String"]>;
};

/**
 * A condition to be used against `FileToDelete` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FileToDeleteCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `error` field. */
  error?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `folderId` field. */
  folderId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `mimetype` field. */
  mimetype?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars["BigInt"]>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `FileToDelete` object types. All fields are combined with a logical ‘and.’ */
export type FileToDeleteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileToDeleteFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `error` field. */
  error?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `folderId` field. */
  folderId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mimetype` field. */
  mimetype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileToDeleteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileToDeleteFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FileToDelete` values. */
export type FileToDeletesConnection = {
  __typename: "FileToDeletesConnection";
  /** A list of edges which contains the `FileToDelete` and cursor to aid in pagination. */
  edges: Array<FileToDeletesEdge>;
  /** A list of `FileToDelete` objects. */
  nodes: Array<FileToDelete>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileToDelete` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `FileToDelete` edge in the connection. */
export type FileToDeletesEdge = {
  __typename: "FileToDeletesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `FileToDelete` at the end of the edge. */
  node: FileToDelete;
};

/** Methods to use when ordering `FileToDelete`. */
export enum FileToDeletesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DeletedAtAsc = "DELETED_AT_ASC",
  DeletedAtDesc = "DELETED_AT_DESC",
  ErrorAsc = "ERROR_ASC",
  ErrorDesc = "ERROR_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  FolderIdAsc = "FOLDER_ID_ASC",
  FolderIdDesc = "FOLDER_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  MimetypeAsc = "MIMETYPE_ASC",
  MimetypeDesc = "MIMETYPE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SizeAsc = "SIZE_ASC",
  SizeDesc = "SIZE_DESC",
  SourceAsc = "SOURCE_ASC",
  SourceDesc = "SOURCE_DESC",
}

/** A connection to a list of `File` values. */
export type FilesConnection = {
  __typename: "FilesConnection";
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  __typename: "FilesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `File` at the end of the edge. */
  node: File;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  AvailableAtAsc = "AVAILABLE_AT_ASC",
  AvailableAtDesc = "AVAILABLE_AT_DESC",
  CommonFileIdAsc = "COMMON_FILE_ID_ASC",
  CommonFileIdDesc = "COMMON_FILE_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DeletedAtAsc = "DELETED_AT_ASC",
  DeletedAtDesc = "DELETED_AT_DESC",
  ExpireAtAsc = "EXPIRE_AT_ASC",
  ExpireAtDesc = "EXPIRE_AT_DESC",
  FileProcessingStatusAsc = "FILE_PROCESSING_STATUS_ASC",
  FileProcessingStatusDesc = "FILE_PROCESSING_STATUS_DESC",
  FolderIdAsc = "FOLDER_ID_ASC",
  FolderIdDesc = "FOLDER_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsFavoriteAsc = "IS_FAVORITE_ASC",
  IsFavoriteDesc = "IS_FAVORITE_DESC",
  IsSharedAllAsc = "IS_SHARED_ALL_ASC",
  IsSharedAllDesc = "IS_SHARED_ALL_DESC",
  IsSharedAsc = "IS_SHARED_ASC",
  IsSharedDesc = "IS_SHARED_DESC",
  IsSharedExplicitAsc = "IS_SHARED_EXPLICIT_ASC",
  IsSharedExplicitDesc = "IS_SHARED_EXPLICIT_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  MimetypeAsc = "MIMETYPE_ASC",
  MimetypeDesc = "MIMETYPE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SizeAsc = "SIZE_ASC",
  SizeDesc = "SIZE_DESC",
  SourceAsc = "SOURCE_ASC",
  SourceDesc = "SOURCE_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

/** The folder information. */
export type Folder = Node & {
  __typename: "Folder";
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByFolderId: FavoritesConnection;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByFolderIdAndOrgId: FavoritesConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFolderId: FilesConnection;
  /** Reads a single `Folder` that is related to this `Folder`. */
  folderByParentId: Maybe<Folder>;
  /** Reads and enables pagination through a set of `Folder`. */
  foldersByParentId: FoldersConnection;
  /** The uuid of the folder. */
  id: Scalars["UUID"];
  /** Return favorite status of a folder. */
  isFavorite: Maybe<Scalars["Boolean"]>;
  isPrimary: Maybe<Scalars["Boolean"]>;
  isShared: Maybe<Scalars["Boolean"]>;
  isSharedAll: Maybe<Scalars["Boolean"]>;
  isSharedExplicit: Maybe<Scalars["Boolean"]>;
  /** The name of the folder. */
  name: Scalars["String"];
  /** Reads and enables pagination through a set of `File`. */
  nestedFilesByFolder: FilesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Folder`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization that folder belong to. */
  orgId: Scalars["UUID"];
  /** The parent folder id. */
  parentId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareFolder: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareFolderAndOrgId: ShareAssociationsConnection;
  /** Spaces that currently got shared access to the folder. */
  sharedSpacesByFolderId: SpacesConnection;
  spaceId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `Space`. */
  spacesByRootFolderId: SpacesConnection;
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByToFolderAndOrgId: TagAssociationsConnection;
  tags: Array<Maybe<Scalars["String"]>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByUpdatedBy: Maybe<User>;
};

/** The folder information. */
export type FolderFavoritesByFolderIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The folder information. */
export type FolderFavoritesByFolderIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The folder information. */
export type FolderFilesByFolderIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** The folder information. */
export type FolderFoldersByParentIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FolderCondition>;
  filter: InputMaybe<FolderFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** The folder information. */
export type FolderNestedFilesByFolderArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The folder information. */
export type FolderShareAssociationsByShareFolderArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The folder information. */
export type FolderShareAssociationsByShareFolderAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The folder information. */
export type FolderSharedSpacesByFolderIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The folder information. */
export type FolderSpacesByRootFolderIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SpaceCondition>;
  filter: InputMaybe<SpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

/** The folder information. */
export type FolderTagAssociationsByToFolderAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** A condition to be used against `Folder` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FolderCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isPrimary` field. */
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isShared` field. */
  isShared?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Folder` object types. All fields are combined with a logical ‘and.’ */
export type FolderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FolderFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isPrimary` field. */
  isPrimary?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FolderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FolderFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `parentId` field. */
  parentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

export type FolderHierarchyDetail = {
  __typename: "FolderHierarchyDetail";
  id: Maybe<Scalars["UUID"]>;
  idPath: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  level: Maybe<Scalars["Int"]>;
  name: Maybe<Scalars["String"]>;
  namePath: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** A connection to a list of `FolderHierarchyDetail` values. */
export type FolderHierarchyDetailsConnection = {
  __typename: "FolderHierarchyDetailsConnection";
  /** A list of edges which contains the `FolderHierarchyDetail` and cursor to aid in pagination. */
  edges: Array<FolderHierarchyDetailsEdge>;
  /** A list of `FolderHierarchyDetail` objects. */
  nodes: Array<FolderHierarchyDetail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FolderHierarchyDetail` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `FolderHierarchyDetail` edge in the connection. */
export type FolderHierarchyDetailsEdge = {
  __typename: "FolderHierarchyDetailsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `FolderHierarchyDetail` at the end of the edge. */
  node: FolderHierarchyDetail;
};

/** A connection to a list of `Folder` values. */
export type FoldersConnection = {
  __typename: "FoldersConnection";
  /** A list of edges which contains the `Folder` and cursor to aid in pagination. */
  edges: Array<FoldersEdge>;
  /** A list of `Folder` objects. */
  nodes: Array<Folder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Folder` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Folder` edge in the connection. */
export type FoldersEdge = {
  __typename: "FoldersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Folder` at the end of the edge. */
  node: Folder;
};

/** Methods to use when ordering `Folder`. */
export enum FoldersOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsFavoriteAsc = "IS_FAVORITE_ASC",
  IsFavoriteDesc = "IS_FAVORITE_DESC",
  IsPrimaryAsc = "IS_PRIMARY_ASC",
  IsPrimaryDesc = "IS_PRIMARY_DESC",
  IsSharedAllAsc = "IS_SHARED_ALL_ASC",
  IsSharedAllDesc = "IS_SHARED_ALL_DESC",
  IsSharedAsc = "IS_SHARED_ASC",
  IsSharedDesc = "IS_SHARED_DESC",
  IsSharedExplicitAsc = "IS_SHARED_EXPLICIT_ASC",
  IsSharedExplicitDesc = "IS_SHARED_EXPLICIT_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  ParentIdAsc = "PARENT_ID_ASC",
  ParentIdDesc = "PARENT_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

export type Font = Node & {
  __typename: "Font";
  boldFileId: Maybe<Scalars["UUID"]>;
  boldItalicFileId: Maybe<Scalars["UUID"]>;
  /** Reads a single `CommonFont` that is related to this `Font`. */
  commonFontByCommonFontId: Maybe<CommonFont>;
  commonFontId: Maybe<Scalars["UUID"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  cssUrl: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByRegularFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByThumbnailFileId: Maybe<File>;
  fontFamily: Scalars["String"];
  fontType: Maybe<FontType>;
  id: Scalars["UUID"];
  italicFileId: Maybe<Scalars["UUID"]>;
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Font`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  regularFileId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByBodyFontId: ThemesConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByBodyFontIdDraft: ThemesConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByTitleFontId: ThemesConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByTitleFontIdDraft: ThemesConnection;
  thumbnailFileId: Maybe<Scalars["UUID"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `Font`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Font`. */
  userByUpdatedBy: Maybe<User>;
};

export type FontThemesByBodyFontIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

export type FontThemesByBodyFontIdDraftArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

export type FontThemesByTitleFontIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

export type FontThemesByTitleFontIdDraftArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** A condition to be used against `Font` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FontCondition = {
  /** Checks for equality with the object’s `boldFileId` field. */
  boldFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `boldItalicFileId` field. */
  boldItalicFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `commonFontId` field. */
  commonFontId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `cssUrl` field. */
  cssUrl?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `fontFamily` field. */
  fontFamily?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `fontType` field. */
  fontType?: InputMaybe<FontType>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `italicFileId` field. */
  italicFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `regularFileId` field. */
  regularFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `thumbnailFileId` field. */
  thumbnailFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Font` object types. All fields are combined with a logical ‘and.’ */
export type FontFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FontFilter>>;
  /** Filter by the object’s `boldFileId` field. */
  boldFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `boldItalicFileId` field. */
  boldItalicFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `commonFontId` field. */
  commonFontId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `cssUrl` field. */
  cssUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fontFamily` field. */
  fontFamily?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fontType` field. */
  fontType?: InputMaybe<FontTypeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `italicFileId` field. */
  italicFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FontFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FontFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `regularFileId` field. */
  regularFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `thumbnailFileId` field. */
  thumbnailFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

export enum FontType {
  File = "FILE",
  Url = "URL",
}

/** A filter to be used against FontType fields. All fields are combined with a logical ‘and.’ */
export type FontTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<FontType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<FontType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<FontType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<FontType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<FontType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<FontType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<FontType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<FontType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<FontType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<FontType>>;
};

/** A connection to a list of `Font` values. */
export type FontsConnection = {
  __typename: "FontsConnection";
  /** A list of edges which contains the `Font` and cursor to aid in pagination. */
  edges: Array<FontsEdge>;
  /** A list of `Font` objects. */
  nodes: Array<Font>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Font` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Font` edge in the connection. */
export type FontsEdge = {
  __typename: "FontsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Font` at the end of the edge. */
  node: Font;
};

/** Methods to use when ordering `Font`. */
export enum FontsOrderBy {
  BoldFileIdAsc = "BOLD_FILE_ID_ASC",
  BoldFileIdDesc = "BOLD_FILE_ID_DESC",
  BoldItalicFileIdAsc = "BOLD_ITALIC_FILE_ID_ASC",
  BoldItalicFileIdDesc = "BOLD_ITALIC_FILE_ID_DESC",
  CommonFontIdAsc = "COMMON_FONT_ID_ASC",
  CommonFontIdDesc = "COMMON_FONT_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  CssUrlAsc = "CSS_URL_ASC",
  CssUrlDesc = "CSS_URL_DESC",
  FontFamilyAsc = "FONT_FAMILY_ASC",
  FontFamilyDesc = "FONT_FAMILY_DESC",
  FontTypeAsc = "FONT_TYPE_ASC",
  FontTypeDesc = "FONT_TYPE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ItalicFileIdAsc = "ITALIC_FILE_ID_ASC",
  ItalicFileIdDesc = "ITALIC_FILE_ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RegularFileIdAsc = "REGULAR_FILE_ID_ASC",
  RegularFileIdDesc = "REGULAR_FILE_ID_DESC",
  ThumbnailFileIdAsc = "THUMBNAIL_FILE_ID_ASC",
  ThumbnailFileIdDesc = "THUMBNAIL_FILE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

/** A `GetBillingAddonsRecord` edge in the connection. */
export type GetBillingAddonEdge = {
  __typename: "GetBillingAddonEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `GetBillingAddonsRecord` at the end of the edge. */
  node: GetBillingAddonsRecord;
};

/** A connection to a list of `GetBillingAddonsRecord` values. */
export type GetBillingAddonsConnection = {
  __typename: "GetBillingAddonsConnection";
  /** A list of edges which contains the `GetBillingAddonsRecord` and cursor to aid in pagination. */
  edges: Array<GetBillingAddonEdge>;
  /** A list of `GetBillingAddonsRecord` objects. */
  nodes: Array<GetBillingAddonsRecord>;
  /** The count of *all* `GetBillingAddonsRecord` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `GetBillingAddonsFutureRecord` values. */
export type GetBillingAddonsFutureConnection = {
  __typename: "GetBillingAddonsFutureConnection";
  /** A list of edges which contains the `GetBillingAddonsFutureRecord` and cursor to aid in pagination. */
  edges: Array<GetBillingAddonsFutureEdge>;
  /** A list of `GetBillingAddonsFutureRecord` objects. */
  nodes: Array<GetBillingAddonsFutureRecord>;
  /** The count of *all* `GetBillingAddonsFutureRecord` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `GetBillingAddonsFutureRecord` edge in the connection. */
export type GetBillingAddonsFutureEdge = {
  __typename: "GetBillingAddonsFutureEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `GetBillingAddonsFutureRecord` at the end of the edge. */
  node: GetBillingAddonsFutureRecord;
};

/** The return type of our `getBillingAddonsFuture` query. */
export type GetBillingAddonsFutureRecord = {
  __typename: "GetBillingAddonsFutureRecord";
  currencyCode: Maybe<CurrencyCode>;
  id: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Int"]>;
};

/** The return type of our `getBillingAddons` query. */
export type GetBillingAddonsRecord = {
  __typename: "GetBillingAddonsRecord";
  currencyCode: Maybe<CurrencyCode>;
  id: Maybe<Scalars["String"]>;
  name: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Int"]>;
};

export type GetBrandInfoPayload = {
  __typename: "GetBrandInfoPayload";
  claimed: Maybe<Scalars["Boolean"]>;
  colors: Maybe<Array<Maybe<BrandInfoColor>>>;
  description: Maybe<Scalars["String"]>;
  domain: Maybe<Scalars["String"]>;
  fonts: Maybe<Array<Maybe<BrandInfoFont>>>;
  images: Maybe<Array<Maybe<BrandInfoImage>>>;
  links: Maybe<Array<Maybe<BrandInfoLink>>>;
  logos: Maybe<Array<Maybe<BrandInfoLogo>>>;
  name: Maybe<Scalars["String"]>;
};

export type GetDuplicateJobInput = {
  jobId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<DuplicateJobStatusType>;
};

export type GetImportJobsInput = {
  snapshotType?: InputMaybe<SnapshotType>;
  sourceSpaceId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<DuplicateJobStatus>>>;
  targetSpaceId?: InputMaybe<Scalars["String"]>;
};

export type GetImportJobsPayload = {
  __typename: "GetImportJobsPayload";
  createdAt: Maybe<Scalars["Datetime"]>;
  isPublicSnapshot: Maybe<Scalars["Boolean"]>;
  jobId: Maybe<Scalars["String"]>;
  newName: Maybe<Scalars["String"]>;
  snapshotId: Maybe<Scalars["String"]>;
  snapshotType: Maybe<SnapshotType>;
  status: Maybe<DuplicateJobStatus>;
  targetContentId: Maybe<Scalars["String"]>;
  targetOrgId: Maybe<Scalars["String"]>;
  targetSpaceId: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  userId: Maybe<Scalars["String"]>;
};

export type GetInvoicePdfPayload = {
  __typename: "GetInvoicePdfPayload";
  downloadUrl: Scalars["String"];
  validTill: Scalars["Datetime"];
};

export type GetInvoicesPayload = {
  __typename: "GetInvoicesPayload";
  invoices: Maybe<Scalars["JSON"]>;
};

export type GetLogsFilterParams = {
  appViewerUrl?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  appid?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  appname?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  channelId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  channelName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  channelZoneId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  consumer?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  instanceId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  keyword?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  message?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  platform?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playbackType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playerVersion?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playlistId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playlistName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  screenId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  screenName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  source?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  spaceId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  spaceName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  studioPlayerVersion?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  viewUrl?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GetMetricsInput = {
  end: Scalars["String"];
  metric: Metric;
  next_token?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<Scalars["Int"]>;
  query_execution_id?: InputMaybe<Scalars["String"]>;
  screen_id?: InputMaybe<Scalars["String"]>;
  secondary_tenant_id?: InputMaybe<Scalars["String"]>;
  start: Scalars["String"];
  target_domain?: InputMaybe<Scalars["String"]>;
  target_url?: InputMaybe<Scalars["String"]>;
};

export type GetMetricsPayload = {
  __typename: "GetMetricsPayload";
  fileName: Maybe<Scalars["String"]>;
  items: Maybe<Array<Maybe<Scalars["JSON"]>>>;
  nextToken: Maybe<Scalars["String"]>;
  queryExecutionId: Maybe<Scalars["String"]>;
};

export type GetPlaybackLogsInput = {
  direction?: InputMaybe<Scalars["String"]>;
  endTime?: InputMaybe<Scalars["BigInt"]>;
  filterParams?: InputMaybe<GetLogsFilterParams>;
  limit?: InputMaybe<Scalars["Int"]>;
  startTime?: InputMaybe<Scalars["BigInt"]>;
};

export type GetPlaybackLogsPayload = {
  __typename: "GetPlaybackLogsPayload";
  data: Maybe<Array<Maybe<Scalars["JSON"]>>>;
  status: Maybe<Scalars["String"]>;
};

export type GetScreenDeviceAllowClonePayload = {
  __typename: "GetScreenDeviceAllowClonePayload";
  allowClone: Maybe<Scalars["Boolean"]>;
  playerId: Maybe<Scalars["UUID"]>;
  screenId: Maybe<Scalars["UUID"]>;
};

export type GetScreenLicencesPayload = {
  __typename: "GetScreenLicencesPayload";
  allowLicence: Scalars["Boolean"];
  futureLicences: Scalars["Int"];
  quotaReached: Scalars["Boolean"];
  remainingLicences: Scalars["Int"];
  totalLicences: Scalars["Int"];
  usedLicences: Scalars["Int"];
};

export type GetSnapshotInput = {
  isPublicSnapshot?: InputMaybe<Scalars["Boolean"]>;
};

export type GetSnapshotsPayload = {
  __typename: "GetSnapshotsPayload";
  contentId: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  isPublicSnapshot: Maybe<Scalars["Boolean"]>;
  jobId: Maybe<Scalars["String"]>;
  snapshotType: Maybe<SnapshotType>;
  sourceOrgId: Maybe<Scalars["String"]>;
  sourceSpaceId: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  userId: Maybe<Scalars["String"]>;
};

export type GraphqlSession = {
  __typename: "GraphqlSession";
  org: Maybe<Org>;
  screen: Maybe<Screen>;
  token: Maybe<Token>;
  user: Maybe<User>;
};

export type Group = Node & {
  __typename: "Group";
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  description: Scalars["String"];
  /** Reads and enables pagination through a set of `GroupSpace`. */
  groupSpacesByGroupId: GroupSpacesConnection;
  /** Reads and enables pagination through a set of `GroupSpace`. */
  groupSpacesByGroupIdAndOrgId: GroupSpacesConnection;
  id: Scalars["UUID"];
  /** Indicate that newly created user will be automatically added to this group */
  isDefault: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Group`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  permissionSets: Array<Maybe<Scalars["UUID"]>>;
  permissions: Array<Maybe<Scalars["String"]>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `Group`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Group`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads and enables pagination through a set of `UserGroup`. */
  userGroupsByGroupId: UserGroupsConnection;
};

export type GroupGroupSpacesByGroupIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupSpaceCondition>;
  filter: InputMaybe<GroupSpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

export type GroupGroupSpacesByGroupIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupSpaceCondition>;
  filter: InputMaybe<GroupSpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

export type GroupUserGroupsByGroupIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserGroupCondition>;
  filter: InputMaybe<UserGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
};

/** This table caches the grants for user that belong to the group_ids */
export type GroupCombination = Node & {
  __typename: "GroupCombination";
  groupIds: Array<Maybe<Scalars["UUID"]>>;
  id: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `GroupCombination`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
};

/**
 * A condition to be used against `GroupCombination` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GroupCombinationCondition = {
  /** Checks for equality with the object’s `groupIds` field. */
  groupIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `GroupCombination` object types. All fields are combined with a logical ‘and.’ */
export type GroupCombinationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GroupCombinationFilter>>;
  /** Filter by the object’s `groupIds` field. */
  groupIds?: InputMaybe<UuidListFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GroupCombinationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GroupCombinationFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `GroupCombination` values. */
export type GroupCombinationsConnection = {
  __typename: "GroupCombinationsConnection";
  /** A list of edges which contains the `GroupCombination` and cursor to aid in pagination. */
  edges: Array<GroupCombinationsEdge>;
  /** A list of `GroupCombination` objects. */
  nodes: Array<GroupCombination>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupCombination` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `GroupCombination` edge in the connection. */
export type GroupCombinationsEdge = {
  __typename: "GroupCombinationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `GroupCombination` at the end of the edge. */
  node: GroupCombination;
};

/** Methods to use when ordering `GroupCombination`. */
export enum GroupCombinationsOrderBy {
  GroupIdsAsc = "GROUP_IDS_ASC",
  GroupIdsDesc = "GROUP_IDS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

/** A condition to be used against `Group` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GroupCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isDefault` field. */
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `permissionSets` field. */
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Checks for equality with the object’s `permissions` field. */
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Group` object types. All fields are combined with a logical ‘and.’ */
export type GroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GroupFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDefault` field. */
  isDefault?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GroupFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `permissionSets` field. */
  permissionSets?: InputMaybe<UuidListFilter>;
  /** Filter by the object’s `permissions` field. */
  permissions?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

export type GroupSpace = Node & {
  __typename: "GroupSpace";
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupId: Maybe<Group>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupIdAndOrgId: Maybe<Group>;
  groupId: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `GroupSpace`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  permissionSets: Array<Maybe<Scalars["UUID"]>>;
  permissions: Array<Maybe<Scalars["String"]>>;
  /** Reads a single `Space` that is related to this `GroupSpace`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Scalars["UUID"];
};

/**
 * A condition to be used against `GroupSpace` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GroupSpaceCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `permissionSets` field. */
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Checks for equality with the object’s `permissions` field. */
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `GroupSpace` object types. All fields are combined with a logical ‘and.’ */
export type GroupSpaceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GroupSpaceFilter>>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GroupSpaceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GroupSpaceFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `permissionSets` field. */
  permissionSets?: InputMaybe<UuidListFilter>;
  /** Filter by the object’s `permissions` field. */
  permissions?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `GroupSpace` values. */
export type GroupSpacesConnection = {
  __typename: "GroupSpacesConnection";
  /** A list of edges which contains the `GroupSpace` and cursor to aid in pagination. */
  edges: Array<GroupSpacesEdge>;
  /** A list of `GroupSpace` objects. */
  nodes: Array<GroupSpace>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupSpace` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `GroupSpace` edge in the connection. */
export type GroupSpacesEdge = {
  __typename: "GroupSpacesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `GroupSpace` at the end of the edge. */
  node: GroupSpace;
};

/** Methods to use when ordering `GroupSpace`. */
export enum GroupSpacesOrderBy {
  GroupIdAsc = "GROUP_ID_ASC",
  GroupIdDesc = "GROUP_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PermissionsAsc = "PERMISSIONS_ASC",
  PermissionsDesc = "PERMISSIONS_DESC",
  PermissionSetsAsc = "PERMISSION_SETS_ASC",
  PermissionSetsDesc = "PERMISSION_SETS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
}

/** A connection to a list of `Group` values. */
export type GroupsConnection = {
  __typename: "GroupsConnection";
  /** A list of edges which contains the `Group` and cursor to aid in pagination. */
  edges: Array<GroupsEdge>;
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Group` edge in the connection. */
export type GroupsEdge = {
  __typename: "GroupsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Group` at the end of the edge. */
  node: Group;
};

/** Methods to use when ordering `Group`. */
export enum GroupsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsDefaultAsc = "IS_DEFAULT_ASC",
  IsDefaultDesc = "IS_DEFAULT_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PermissionsAsc = "PERMISSIONS_ASC",
  PermissionsDesc = "PERMISSIONS_DESC",
  PermissionSetsAsc = "PERMISSION_SETS_ASC",
  PermissionSetsDesc = "PERMISSION_SETS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

export type IdentityProvider = {
  __typename: "IdentityProvider";
  description: Scalars["String"];
  iconUrl: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  type: Scalars["String"];
};

export type IdentityProviderLaunchUrlInput = {
  id?: InputMaybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type InstallAppForSpaceInput = {
  appId: Scalars["String"];
  spaceId: Scalars["String"];
};

export type InstallAppForSpacePayload = {
  __typename: "InstallAppForSpacePayload";
  appInstall: Maybe<AppInstall>;
};

/** All input for the `installOrgApp` mutation. */
export type InstallOrgAppInput = {
  appId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `installOrgApp` mutation. */
export type InstallOrgAppPayload = {
  __typename: "InstallOrgAppPayload";
  /** Reads a single `App` that is related to this `AppInstall`. */
  appByAppId: Maybe<App>;
  appInstall: Maybe<AppInstall>;
  /** An edge for our `AppInstall`. May be used by Relay 1. */
  appInstallEdge: Maybe<AppInstallsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `AppInstall`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `AppInstall`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `installOrgApp` mutation. */
export type InstallOrgAppPayloadAppInstallEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** All input for the `installSpaceApp` mutation. */
export type InstallSpaceAppInput = {
  appId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaceId: Scalars["UUID"];
};

/** The output of our `installSpaceApp` mutation. */
export type InstallSpaceAppPayload = {
  __typename: "InstallSpaceAppPayload";
  /** Reads a single `App` that is related to this `AppInstall`. */
  appByAppId: Maybe<App>;
  appInstall: Maybe<AppInstall>;
  /** An edge for our `AppInstall`. May be used by Relay 1. */
  appInstallEdge: Maybe<AppInstallsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `AppInstall`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `AppInstall`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `installSpaceApp` mutation. */
export type InstallSpaceAppPayloadAppInstallEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Int"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Int"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Int"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Int"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Int"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Int"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Int"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

/** A filter to be used against InternetAddress fields. All fields are combined with a logical ‘and.’ */
export type InternetAddressFilter = {
  /** Contained by the specified internet address. */
  containedBy?: InputMaybe<Scalars["InternetAddress"]>;
  /** Contains the specified internet address. */
  contains?: InputMaybe<Scalars["InternetAddress"]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["InternetAddress"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["InternetAddress"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["InternetAddress"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["InternetAddress"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["InternetAddress"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["InternetAddress"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["InternetAddress"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["InternetAddress"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["InternetAddress"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["InternetAddress"]>>;
};

/** All input for the `inviteUser` mutation. */
export type InviteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  emails?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  isAdminGroup?: InputMaybe<Scalars["Boolean"]>;
  omitInviteEmail?: InputMaybe<Scalars["Boolean"]>;
};

/** The output of our `inviteUser` mutation. */
export type InviteUserPayload = {
  __typename: "InviteUserPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  users: Maybe<Array<Maybe<User>>>;
};

/** All input for the `isSessionValid` mutation. */
export type IsSessionValidInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `isSessionValid` mutation. */
export type IsSessionValidPayload = {
  __typename: "IsSessionValidPayload";
  boolean: Maybe<Scalars["Boolean"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `isSystemUser` mutation. */
export type IsSystemUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `isSystemUser` mutation. */
export type IsSystemUserPayload = {
  __typename: "IsSystemUserPayload";
  boolean: Maybe<Scalars["Boolean"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars["JSON"]>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars["JSON"]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["JSON"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["JSON"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["JSON"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["JSON"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["JSON"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["JSON"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["JSON"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["JSON"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["JSON"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["JSON"]>>;
};

/** A filter to be used against JSON List fields. All fields are combined with a logical ‘and.’ */
export type JsonListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars["JSON"]>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
};

export enum JobRegion {
  Eu = "EU",
  Us = "US",
}

export enum JobStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  NotStarted = "NOT_STARTED",
  Pending = "PENDING",
}

/** A filter to be used against JobStatus fields. All fields are combined with a logical ‘and.’ */
export type JobStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<JobStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<JobStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<JobStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<JobStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<JobStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<JobStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<JobStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<JobStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<JobStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<JobStatus>>;
};

export enum JobTargetOutput {
  Dash = "DASH",
  Hls = "HLS",
  Jpeg = "JPEG",
  Mp4 = "MP4",
  Png = "PNG",
}

/** A filter to be used against JobTargetOutput fields. All fields are combined with a logical ‘and.’ */
export type JobTargetOutputFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<JobTargetOutput>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<JobTargetOutput>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<JobTargetOutput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<JobTargetOutput>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<JobTargetOutput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<JobTargetOutput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<JobTargetOutput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<JobTargetOutput>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<JobTargetOutput>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<JobTargetOutput>>;
};

export enum JobType {
  Audio = "AUDIO",
  Avc = "AVC",
  CommonFileCopy = "COMMON_FILE_COPY",
  ConvertDocument = "CONVERT_DOCUMENT",
  Document = "DOCUMENT",
  FileCopy = "FILE_COPY",
  Hevc = "HEVC",
  Image = "IMAGE",
  Inspection = "INSPECTION",
  PrepareDocument = "PREPARE_DOCUMENT",
  Snapshot = "SNAPSHOT",
  Video = "VIDEO",
}

/** A filter to be used against JobType fields. All fields are combined with a logical ‘and.’ */
export type JobTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<JobType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<JobType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<JobType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<JobType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<JobType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<JobType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<JobType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<JobType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<JobType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<JobType>>;
};

/** A filter to be used against KeyValueHash fields. All fields are combined with a logical ‘and.’ */
export type KeyValueHashFilter = {
  /** Contained by the specified KeyValueHash. */
  containedBy?: InputMaybe<Scalars["KeyValueHash"]>;
  /** Contains the specified KeyValueHash. */
  contains?: InputMaybe<Scalars["KeyValueHash"]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["KeyValueHash"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["KeyValueHash"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["KeyValueHash"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["KeyValueHash"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["KeyValueHash"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["KeyValueHash"]>>;
};

/** The layout information, number of zones, css for zone layout but not content related. */
export type Layout = Node & {
  __typename: "Layout";
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByLayout: ChannelsConnection;
  /** The configuration data of the layout - css. */
  config: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  fullScreenZoneId: Maybe<Scalars["String"]>;
  /** The height dimension of the layout. */
  height: Maybe<Scalars["Int"]>;
  /** The uuid of the layout. */
  id: Scalars["UUID"];
  /** Layout is responsive to any size. */
  isFlexible: Maybe<Scalars["Boolean"]>;
  /** Layout is able to scale to the Channel with the same aspect ratio. */
  isScalable: Maybe<Scalars["Boolean"]>;
  /** The name of the layout. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Layout`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization that layout belong to. */
  orgId: Scalars["UUID"];
  /** Reads a single `Space` that is related to this `Layout`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Scalars["UUID"];
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `Layout`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Layout`. */
  userByUpdatedBy: Maybe<User>;
  /** The width dimension of the layout. */
  width: Maybe<Scalars["Int"]>;
  /** The number of zones of the layout (not include hidden zone). */
  zones: Scalars["Int"];
};

/** The layout information, number of zones, css for zone layout but not content related. */
export type LayoutChannelsByLayoutArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A condition to be used against `Layout` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LayoutCondition = {
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `fullScreenZoneId` field. */
  fullScreenZoneId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `height` field. */
  height?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isFlexible` field. */
  isFlexible?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isScalable` field. */
  isScalable?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `width` field. */
  width?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `zones` field. */
  zones?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `Layout` object types. All fields are combined with a logical ‘and.’ */
export type LayoutFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LayoutFilter>>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fullScreenZoneId` field. */
  fullScreenZoneId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isFlexible` field. */
  isFlexible?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isScalable` field. */
  isScalable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LayoutFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LayoutFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `width` field. */
  width?: InputMaybe<IntFilter>;
  /** Filter by the object’s `zones` field. */
  zones?: InputMaybe<IntFilter>;
};

/** A connection to a list of `Layout` values. */
export type LayoutsConnection = {
  __typename: "LayoutsConnection";
  /** A list of edges which contains the `Layout` and cursor to aid in pagination. */
  edges: Array<LayoutsEdge>;
  /** A list of `Layout` objects. */
  nodes: Array<Layout>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Layout` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Layout` edge in the connection. */
export type LayoutsEdge = {
  __typename: "LayoutsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Layout` at the end of the edge. */
  node: Layout;
};

/** Methods to use when ordering `Layout`. */
export enum LayoutsOrderBy {
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  FullScreenZoneIdAsc = "FULL_SCREEN_ZONE_ID_ASC",
  FullScreenZoneIdDesc = "FULL_SCREEN_ZONE_ID_DESC",
  HeightAsc = "HEIGHT_ASC",
  HeightDesc = "HEIGHT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsFlexibleAsc = "IS_FLEXIBLE_ASC",
  IsFlexibleDesc = "IS_FLEXIBLE_DESC",
  IsScalableAsc = "IS_SCALABLE_ASC",
  IsScalableDesc = "IS_SCALABLE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  WidthAsc = "WIDTH_ASC",
  WidthDesc = "WIDTH_DESC",
  ZonesAsc = "ZONES_ASC",
  ZonesDesc = "ZONES_DESC",
}

/** Legacy format logs for early access usage */
export type LegacyLog = Node & {
  __typename: "LegacyLog";
  action: Maybe<Scalars["String"]>;
  /** The timestamp of actioned. */
  actionedAt: Maybe<Scalars["Datetime"]>;
  /** The id of user who actioned. */
  actionedBy: Maybe<Scalars["UUID"]>;
  clientAddr: Maybe<Scalars["InternetAddress"]>;
  clientPort: Maybe<Scalars["Int"]>;
  /** The additional data that related to the action. */
  extra: Maybe<Scalars["JSON"]>;
  id: Scalars["UUID"];
  msg: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** The id of the object that got actioned. */
  objectId: Maybe<Scalars["UUID"]>;
  /** The type of object that got actioned. */
  objectType: Maybe<Scalars["String"]>;
  /** The id of the organization of affected user. */
  orgId: Scalars["UUID"];
  teamId: Maybe<Scalars["UUID"]>;
};

/**
 * A condition to be used against `LegacyLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LegacyLogCondition = {
  /** Checks for equality with the object’s `action` field. */
  action?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `actionedAt` field. */
  actionedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `actionedBy` field. */
  actionedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `clientAddr` field. */
  clientAddr?: InputMaybe<Scalars["InternetAddress"]>;
  /** Checks for equality with the object’s `clientPort` field. */
  clientPort?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `extra` field. */
  extra?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `msg` field. */
  msg?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `objectType` field. */
  objectType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `LegacyLog` object types. All fields are combined with a logical ‘and.’ */
export type LegacyLogFilter = {
  /** Filter by the object’s `action` field. */
  action?: InputMaybe<StringFilter>;
  /** Filter by the object’s `actionedAt` field. */
  actionedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `actionedBy` field. */
  actionedBy?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LegacyLogFilter>>;
  /** Filter by the object’s `clientAddr` field. */
  clientAddr?: InputMaybe<InternetAddressFilter>;
  /** Filter by the object’s `clientPort` field. */
  clientPort?: InputMaybe<IntFilter>;
  /** Filter by the object’s `extra` field. */
  extra?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `msg` field. */
  msg?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LegacyLogFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `objectType` field. */
  objectType?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LegacyLogFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `LegacyLog` values. */
export type LegacyLogsConnection = {
  __typename: "LegacyLogsConnection";
  /** A list of edges which contains the `LegacyLog` and cursor to aid in pagination. */
  edges: Array<LegacyLogsEdge>;
  /** A list of `LegacyLog` objects. */
  nodes: Array<LegacyLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LegacyLog` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `LegacyLog` edge in the connection. */
export type LegacyLogsEdge = {
  __typename: "LegacyLogsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `LegacyLog` at the end of the edge. */
  node: LegacyLog;
};

/** Methods to use when ordering `LegacyLog`. */
export enum LegacyLogsOrderBy {
  ActionedAtAsc = "ACTIONED_AT_ASC",
  ActionedAtDesc = "ACTIONED_AT_DESC",
  ActionedByAsc = "ACTIONED_BY_ASC",
  ActionedByDesc = "ACTIONED_BY_DESC",
  ActionAsc = "ACTION_ASC",
  ActionDesc = "ACTION_DESC",
  ClientAddrAsc = "CLIENT_ADDR_ASC",
  ClientAddrDesc = "CLIENT_ADDR_DESC",
  ClientPortAsc = "CLIENT_PORT_ASC",
  ClientPortDesc = "CLIENT_PORT_DESC",
  ExtraAsc = "EXTRA_ASC",
  ExtraDesc = "EXTRA_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  MsgAsc = "MSG_ASC",
  MsgDesc = "MSG_DESC",
  Natural = "NATURAL",
  ObjectIdAsc = "OBJECT_ID_ASC",
  ObjectIdDesc = "OBJECT_ID_DESC",
  ObjectTypeAsc = "OBJECT_TYPE_ASC",
  ObjectTypeDesc = "OBJECT_TYPE_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TeamIdAsc = "TEAM_ID_ASC",
  TeamIdDesc = "TEAM_ID_DESC",
}

/** The link table for store urls, website, etc. */
export type Link = Node & {
  __typename: "Link";
  /** Reads and enables pagination through a set of `Association`. */
  associationsByToLinkAndOrgId: AssociationsConnection;
  autoReload: Maybe<Scalars["Boolean"]>;
  autoReloadDurationMs: Maybe<Scalars["Int"]>;
  cacheBusting: Maybe<Scalars["Boolean"]>;
  /** Get casted screen(s) by link's id. */
  castedScreenByLinkId: ScreensConnection;
  cloudConfig: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByLinkId: FavoritesConnection;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByLinkIdAndOrgId: FavoritesConnection;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** The image capture of the website. */
  fileId: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  /** Return favorite status of a link. */
  isFavorite: Maybe<Scalars["Boolean"]>;
  isShared: Maybe<Scalars["Boolean"]>;
  isSharedAll: Maybe<Scalars["Boolean"]>;
  isSharedExplicit: Maybe<Scalars["Boolean"]>;
  /** Enum type of link type. */
  linkType: LinkType;
  /** The friendly name of the link. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Link`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  params: Maybe<Scalars["JSON"]>;
  qrcodeConfig: Maybe<Scalars["JSON"]>;
  qrcodeEnabled: Maybe<Scalars["Boolean"]>;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByLinkIdAndOrgId: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareLink: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareLinkAndOrgId: ShareAssociationsConnection;
  /** Spaces that currently got shared access to the link. */
  sharedSpacesByLinkId: SpacesConnection;
  /** The uuid of the space of creator. */
  spaceId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByToLinkAndOrgId: TagAssociationsConnection;
  /** The json array of tags for the link. */
  tags: Array<Maybe<Scalars["String"]>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** The full url address. */
  url: Scalars["String"];
  /** Reads a single `User` that is related to this `Link`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Link`. */
  userByUpdatedBy: Maybe<User>;
};

/** The link table for store urls, website, etc. */
export type LinkAssociationsByToLinkAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The link table for store urls, website, etc. */
export type LinkCastedScreenByLinkIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The link table for store urls, website, etc. */
export type LinkFavoritesByLinkIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The link table for store urls, website, etc. */
export type LinkFavoritesByLinkIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The link table for store urls, website, etc. */
export type LinkScreenContentHistoriesByLinkIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** The link table for store urls, website, etc. */
export type LinkShareAssociationsByShareLinkArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The link table for store urls, website, etc. */
export type LinkShareAssociationsByShareLinkAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The link table for store urls, website, etc. */
export type LinkSharedSpacesByLinkIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The link table for store urls, website, etc. */
export type LinkTagAssociationsByToLinkAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** A condition to be used against `Link` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LinkCondition = {
  /** Checks for equality with the object’s `autoReload` field. */
  autoReload?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `autoReloadDurationMs` field. */
  autoReloadDurationMs?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `cacheBusting` field. */
  cacheBusting?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `cloudConfig` field. */
  cloudConfig?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isShared` field. */
  isShared?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `linkType` field. */
  linkType?: InputMaybe<LinkType>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `params` field. */
  params?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `qrcodeConfig` field. */
  qrcodeConfig?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `qrcodeEnabled` field. */
  qrcodeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `Link` object types. All fields are combined with a logical ‘and.’ */
export type LinkFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LinkFilter>>;
  /** Filter by the object’s `autoReload` field. */
  autoReload?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `autoReloadDurationMs` field. */
  autoReloadDurationMs?: InputMaybe<IntFilter>;
  /** Filter by the object’s `cacheBusting` field. */
  cacheBusting?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `cloudConfig` field. */
  cloudConfig?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `linkType` field. */
  linkType?: InputMaybe<LinkTypeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LinkFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LinkFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `params` field. */
  params?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `qrcodeConfig` field. */
  qrcodeConfig?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `qrcodeEnabled` field. */
  qrcodeEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

export enum LinkType {
  Cloud = "CLOUD",
  Internal = "INTERNAL",
  Standard = "STANDARD",
}

/** A filter to be used against LinkType fields. All fields are combined with a logical ‘and.’ */
export type LinkTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LinkType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LinkType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LinkType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LinkType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LinkType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LinkType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LinkType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LinkType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LinkType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LinkType>>;
};

/** A connection to a list of `Link` values. */
export type LinksConnection = {
  __typename: "LinksConnection";
  /** A list of edges which contains the `Link` and cursor to aid in pagination. */
  edges: Array<LinksEdge>;
  /** A list of `Link` objects. */
  nodes: Array<Link>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Link` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Link` edge in the connection. */
export type LinksEdge = {
  __typename: "LinksEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Link` at the end of the edge. */
  node: Link;
};

/** Methods to use when ordering `Link`. */
export enum LinksOrderBy {
  AutoReloadAsc = "AUTO_RELOAD_ASC",
  AutoReloadDesc = "AUTO_RELOAD_DESC",
  AutoReloadDurationMsAsc = "AUTO_RELOAD_DURATION_MS_ASC",
  AutoReloadDurationMsDesc = "AUTO_RELOAD_DURATION_MS_DESC",
  CacheBustingAsc = "CACHE_BUSTING_ASC",
  CacheBustingDesc = "CACHE_BUSTING_DESC",
  CloudConfigAsc = "CLOUD_CONFIG_ASC",
  CloudConfigDesc = "CLOUD_CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsSharedAllAsc = "IS_SHARED_ALL_ASC",
  IsSharedAllDesc = "IS_SHARED_ALL_DESC",
  IsSharedAsc = "IS_SHARED_ASC",
  IsSharedDesc = "IS_SHARED_DESC",
  IsSharedExplicitAsc = "IS_SHARED_EXPLICIT_ASC",
  IsSharedExplicitDesc = "IS_SHARED_EXPLICIT_DESC",
  LinkTypeAsc = "LINK_TYPE_ASC",
  LinkTypeDesc = "LINK_TYPE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  ParamsAsc = "PARAMS_ASC",
  ParamsDesc = "PARAMS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  QrcodeConfigAsc = "QRCODE_CONFIG_ASC",
  QrcodeConfigDesc = "QRCODE_CONFIG_DESC",
  QrcodeEnabledAsc = "QRCODE_ENABLED_ASC",
  QrcodeEnabledDesc = "QRCODE_ENABLED_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
}

/** A record of user's activities and actions. */
export type Log = Node & {
  __typename: "Log";
  action: Maybe<Scalars["String"]>;
  /** Application name whom actioned. */
  applicationName: Maybe<Scalars["String"]>;
  /** Display columns that changed after updated. */
  changedFields: Maybe<Scalars["KeyValueHash"]>;
  /** IP address of client that issued query. Null for unix domain socket. */
  clientAddr: Maybe<Scalars["InternetAddress"]>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  clientPort: Maybe<Scalars["Int"]>;
  id: Scalars["BigInt"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** The id of the object that got actioned. */
  objectId: Maybe<Scalars["UUID"]>;
  objectName: Maybe<Scalars["String"]>;
  /** The type or table name of object that got actioned. */
  objectType: Maybe<Scalars["String"]>;
  /** The id of the organization of affected user. */
  orgId: Maybe<Scalars["UUID"]>;
  /** Display columns that changed before updated, or all columns when deleted. */
  previousFields: Maybe<Scalars["KeyValueHash"]>;
  /** The object id of the table that caused the trigger invocation. */
  relid: Maybe<Scalars["BigFloat"]>;
  /** Address of the connected remote client */
  remoteAddress: Maybe<Scalars["String"]>;
  /** Database session user name */
  sessionUserName: Maybe<Scalars["String"]>;
  spaceId: Maybe<Scalars["UUID"]>;
  /** The system access id if user has one. */
  systemAccessId: Maybe<Scalars["UUID"]>;
  systemTokenId: Maybe<Scalars["UUID"]>;
  /** The system user id */
  systemUserId: Maybe<Scalars["UUID"]>;
  /** The timestamp of actioned. */
  timestamp: Maybe<Scalars["Datetime"]>;
  /** The user api token */
  tokenId: Maybe<Scalars["String"]>;
  /** Transaction id */
  transactionId: Maybe<Scalars["BigInt"]>;
  /** The id of user whom actioned. */
  userId: Maybe<Scalars["UUID"]>;
};

/** A condition to be used against `Log` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LogCondition = {
  /** Checks for equality with the object’s `action` field. */
  action?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `applicationName` field. */
  applicationName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `changedFields` field. */
  changedFields?: InputMaybe<Scalars["KeyValueHash"]>;
  /** Checks for equality with the object’s `clientAddr` field. */
  clientAddr?: InputMaybe<Scalars["InternetAddress"]>;
  /** Checks for equality with the object’s `clientPort` field. */
  clientPort?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `objectType` field. */
  objectType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `previousFields` field. */
  previousFields?: InputMaybe<Scalars["KeyValueHash"]>;
  /** Checks for equality with the object’s `relid` field. */
  relid?: InputMaybe<Scalars["BigFloat"]>;
  /** Checks for equality with the object’s `remoteAddress` field. */
  remoteAddress?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `sessionUserName` field. */
  sessionUserName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `systemAccessId` field. */
  systemAccessId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `systemTokenId` field. */
  systemTokenId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `systemUserId` field. */
  systemUserId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `tokenId` field. */
  tokenId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `transactionId` field. */
  transactionId?: InputMaybe<Scalars["BigInt"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Log` object types. All fields are combined with a logical ‘and.’ */
export type LogFilter = {
  /** Filter by the object’s `action` field. */
  action?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LogFilter>>;
  /** Filter by the object’s `applicationName` field. */
  applicationName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `changedFields` field. */
  changedFields?: InputMaybe<KeyValueHashFilter>;
  /** Filter by the object’s `clientAddr` field. */
  clientAddr?: InputMaybe<InternetAddressFilter>;
  /** Filter by the object’s `clientPort` field. */
  clientPort?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LogFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `objectType` field. */
  objectType?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LogFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `previousFields` field. */
  previousFields?: InputMaybe<KeyValueHashFilter>;
  /** Filter by the object’s `relid` field. */
  relid?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `remoteAddress` field. */
  remoteAddress?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sessionUserName` field. */
  sessionUserName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `systemAccessId` field. */
  systemAccessId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `systemTokenId` field. */
  systemTokenId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `systemUserId` field. */
  systemUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tokenId` field. */
  tokenId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionId` field. */
  transactionId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A table to hold translatable information for studio.log */
export type LogObjectFieldValue = Node & {
  __typename: "LogObjectFieldValue";
  field: Maybe<Scalars["String"]>;
  logId: Scalars["BigInt"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  objectId: Maybe<Scalars["UUID"]>;
  objectType: Maybe<Scalars["String"]>;
  orgId: Maybe<Scalars["UUID"]>;
  value: Maybe<Scalars["String"]>;
};

/** A connection to a list of `Log` values. */
export type LogsConnection = {
  __typename: "LogsConnection";
  /** A list of edges which contains the `Log` and cursor to aid in pagination. */
  edges: Array<LogsEdge>;
  /** A list of `Log` objects. */
  nodes: Array<Log>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Log` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Log` edge in the connection. */
export type LogsEdge = {
  __typename: "LogsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Log` at the end of the edge. */
  node: Log;
};

/** Methods to use when ordering `Log`. */
export enum LogsOrderBy {
  ActionAsc = "ACTION_ASC",
  ActionDesc = "ACTION_DESC",
  ApplicationNameAsc = "APPLICATION_NAME_ASC",
  ApplicationNameDesc = "APPLICATION_NAME_DESC",
  ChangedFieldsAsc = "CHANGED_FIELDS_ASC",
  ChangedFieldsDesc = "CHANGED_FIELDS_DESC",
  ClientAddrAsc = "CLIENT_ADDR_ASC",
  ClientAddrDesc = "CLIENT_ADDR_DESC",
  ClientPortAsc = "CLIENT_PORT_ASC",
  ClientPortDesc = "CLIENT_PORT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  ObjectIdAsc = "OBJECT_ID_ASC",
  ObjectIdDesc = "OBJECT_ID_DESC",
  ObjectTypeAsc = "OBJECT_TYPE_ASC",
  ObjectTypeDesc = "OBJECT_TYPE_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PreviousFieldsAsc = "PREVIOUS_FIELDS_ASC",
  PreviousFieldsDesc = "PREVIOUS_FIELDS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RelidAsc = "RELID_ASC",
  RelidDesc = "RELID_DESC",
  RemoteAddressAsc = "REMOTE_ADDRESS_ASC",
  RemoteAddressDesc = "REMOTE_ADDRESS_DESC",
  SessionUserNameAsc = "SESSION_USER_NAME_ASC",
  SessionUserNameDesc = "SESSION_USER_NAME_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  SystemAccessIdAsc = "SYSTEM_ACCESS_ID_ASC",
  SystemAccessIdDesc = "SYSTEM_ACCESS_ID_DESC",
  SystemTokenIdAsc = "SYSTEM_TOKEN_ID_ASC",
  SystemTokenIdDesc = "SYSTEM_TOKEN_ID_DESC",
  SystemUserIdAsc = "SYSTEM_USER_ID_ASC",
  SystemUserIdDesc = "SYSTEM_USER_ID_DESC",
  TimestampAsc = "TIMESTAMP_ASC",
  TimestampDesc = "TIMESTAMP_DESC",
  TokenIdAsc = "TOKEN_ID_ASC",
  TokenIdDesc = "TOKEN_ID_DESC",
  TransactionIdAsc = "TRANSACTION_ID_ASC",
  TransactionIdDesc = "TRANSACTION_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export enum Metric {
  /** All scanned screens for a domain for a tenant */
  AllMostScannedScreensForDomainForTenant = "all_most_scanned_screens_for_domain_for_tenant",
  /** All scanned screens for a target url for a tenant */
  AllMostScannedScreensForTargetUrlForTenant = "all_most_scanned_screens_for_target_url_for_tenant",
  /** All scanned screens for a tenant */
  AllMostScannedScreensForTenant = "all_most_scanned_screens_for_tenant",
  /** All viewed domains for a screen for a tenant */
  AllMostViewedDomainForScreenForTenant = "all_most_viewed_domain_for_screen_for_tenant",
  /** All most viewed urls for a screen for a tenant */
  AllMostViewedTargetUrlsForScreenForTenant = "all_most_viewed_target_urls_for_screen_for_tenant",
  /** All visited domains for a tenant */
  AllMostVisitedDomainForTenant = "all_most_visited_domain_for_tenant",
  /** All visited urls for a tenant */
  AllMostVisitedTargetUrlForTenant = "all_most_visited_target_url_for_tenant",
  /** Top 5 most scanned screens for a domain for a tenant */
  Top_5MostScannedScreensForDomainForTenant = "top_5_most_scanned_screens_for_domain_for_tenant",
  /** Top 5 most scanned screens for a target url for a tenant */
  Top_5MostScannedScreensForTargetUrlForTenant = "top_5_most_scanned_screens_for_target_url_for_tenant",
  /** Top 5 most scanned screens for a tenant */
  Top_5MostScannedScreensForTenant = "top_5_most_scanned_screens_for_tenant",
  /** Top 5 most viewed domains for a screen for a tenant */
  Top_5MostViewedDomainForScreenForTenant = "top_5_most_viewed_domain_for_screen_for_tenant",
  /** Top 5 most viewed urls for a screen for a tenant */
  Top_5MostViewedTargetUrlsForScreenForTenant = "top_5_most_viewed_target_urls_for_screen_for_tenant",
  /** Top 5 most visited domains for a tenant */
  Top_5MostVisitedDomainForTenant = "top_5_most_visited_domain_for_tenant",
  /** Top 5 most visited urls for a tenant */
  Top_5MostVisitedTargetUrlForTenant = "top_5_most_visited_target_url_for_tenant",
  /** Total number of scans for a screen for a tenant */
  TotalScansForScreenForTenant = "total_scans_for_screen_for_tenant",
  /** Total number of visits for a domain for a tenant */
  TotalVisitsForDomainForTenant = "total_visits_for_domain_for_tenant",
  /** Total number of visits for a url for a tenant */
  TotalVisitsForTargetUrlForTenant = "total_visits_for_target_url_for_tenant",
  /** Total number of visits for a tenant */
  TotalVisitsForTenant = "total_visits_for_tenant",
}

/** All input for the `migrationBilling` mutation. */
export type MigrationBillingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  data?: InputMaybe<Scalars["JSON"]>;
};

/** The output of our `migrationBilling` mutation. */
export type MigrationBillingPayload = {
  __typename: "MigrationBillingPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  json: Maybe<Scalars["JSON"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `migrationInit` mutation. */
export type MigrationInitInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  data?: InputMaybe<Scalars["JSON"]>;
  orgId?: InputMaybe<Scalars["UUID"]>;
  region?: InputMaybe<JobRegion>;
  signageMigrationExportJobId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `migrationInit` mutation. */
export type MigrationInitPayload = {
  __typename: "MigrationInitPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  json: Maybe<Scalars["JSON"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `migrationScreen` mutation. */
export type MigrationScreenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  data?: InputMaybe<Scalars["JSON"]>;
};

/** The output of our `migrationScreen` mutation. */
export type MigrationScreenPayload = {
  __typename: "MigrationScreenPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  json: Maybe<Scalars["JSON"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename: "Mutation";
  activateSubscription: Maybe<ActivateSubscriptionPayload>;
  /** Add app instance to channel's content */
  addAppInstanceToChannel: Maybe<AddAppInstanceToChannelPayload>;
  /** Add app instance to playlist's content */
  addAppInstanceToPlaylist: Maybe<AddAppInstanceToPlaylistPayload>;
  /**
   * Use update_file_is_favorite instead
   * @deprecated true
   */
  addFavoriteFile: Maybe<AddFavoriteFilePayload>;
  /**
   * Use update_folder_is_favorite instead
   * @deprecated true
   */
  addFavoriteFolder: Maybe<AddFavoriteFolderPayload>;
  /**
   * Use update_link_is_favorite instead
   * @deprecated true
   */
  addFavoriteLink: Maybe<AddFavoriteLinkPayload>;
  /**
   * Use update_playlist_is_favorite instead
   * @deprecated true
   */
  addFavoritePlaylist: Maybe<AddFavoritePlaylistPayload>;
  /**
   * Use update_site_is_favorite instead
   * @deprecated true
   */
  addFavoriteSite: Maybe<AddFavoriteSitePayload>;
  /** Add file to channel's content */
  addFileToChannel: Maybe<AddFileToChannelPayload>;
  /** Add file to playlist's content */
  addFileToPlaylist: Maybe<AddFileToPlaylistPayload>;
  addGroupPermissionSets: Maybe<AddGroupPermissionSetsPayload>;
  addGroupSpacePermissionSets: Maybe<AddGroupSpacePermissionSetsPayload>;
  /** Add Link to channel's content */
  addLinkToChannel: Maybe<AddLinkToChannelPayload>;
  /** Add Link to playlist's content */
  addLinkToPlaylist: Maybe<AddLinkToPlaylistPayload>;
  /** Add Playlist to channel's content */
  addPlaylistToChannel: Maybe<AddPlaylistToChannelPayload>;
  addUserToGroups: Maybe<AddUserToGroupsPayload>;
  authAcceptPendingUserInvite: Maybe<AuthAcceptPendingUserInvitePayload>;
  /** Bulk create casts by org or space or screens */
  bulkCreateCasts: Maybe<BulkCreateCastsPayload>;
  /** Reset multiple screen location */
  bulkResetScreenLocation: Maybe<BulkResetScreenLocationPayload>;
  /** Bulk update multiple screens content */
  bulkUpdateScreenContent: Maybe<BulkUpdateScreenContentPayload>;
  /** Bulk update multiple screen location */
  bulkUpdateScreenLocation: Maybe<BulkUpdateScreenLocationPayload>;
  /** Bulk update multiple screens timezone */
  bulkUpdateScreenTimezone: Maybe<BulkUpdateScreenTimezonePayload>;
  cardIntentConfirm: Maybe<CardIntentConfirmPayload>;
  cardIntentPayment: Maybe<CardIntentPaymentPayload>;
  cardIntentSetup: Maybe<CardIntentSetupPayload>;
  /** Start cast content to screens. */
  castStart: Maybe<CastStartPayload>;
  /** Stop cast content to screens. */
  castStop: Maybe<CastStopPayload>;
  castableScreens: Maybe<CastableScreensPayload>;
  /** To clear cache on a screen. */
  clearCacheScreen: Maybe<ClearCacheScreenPayload>;
  clearCacheScreensByOrgId: Maybe<ClearCacheScreensByOrgIdPayload>;
  clearCacheScreensByScreenIds: Maybe<ClearCacheScreensByScreenIdsPayload>;
  clearCacheScreensBySpaceId: Maybe<ClearCacheScreensBySpaceIdPayload>;
  createAdditionalQrContext: Maybe<CreateAdditionalQrContextPayload>;
  /** To create a new app instance from the installed app. */
  createAppInstance: Maybe<CreateAppInstancePayload>;
  /** Create app instance given a common_app_instance_template */
  createAppInstanceByCommonAppInstanceTemplateId: Maybe<CreateAppInstanceByCommonAppInstanceTemplateIdPayload>;
  /** Create a bot user. */
  createBot: Maybe<CreateBotPayload>;
  /** Create casts by supplying a content json and a list of screen ids. One cast-object will be created for each distinct space of all the supplied screens. */
  createCasts: Maybe<CreateCastsPayload>;
  /** Create casts by supplying an app instance id and a list of screen ids. One cast-object will be created for each distinct space of all the supplied screens. Screens already receiving a cast with a higher priority will be skipped. */
  createCastsByAppInstanceId: Maybe<CreateCastsByAppInstanceIdPayload>;
  /** Create casts by supplying a channel id and a list of screen ids. One cast-object will be created for each distinct space of all the supplied screens. Screens already receiving a cast with a higher priority will be skipped. */
  createCastsByChannelId: Maybe<CreateCastsByChannelIdPayload>;
  /** Create casts by supplying a file id and a list of screen ids. One cast-object will be created for each distinct space of all the supplied screens. Screens already receiving a cast with a higher priority will be skipped. */
  createCastsByFileId: Maybe<CreateCastsByFileIdPayload>;
  /** Create casts by supplying a link id and a list of screen ids. One cast-object will be created for each distinct space of all the supplied screens. Screens already receiving a cast with a higher priority will be skipped. */
  createCastsByLinkId: Maybe<CreateCastsByLinkIdPayload>;
  /** Create casts by supplying a playlist id and a list of screen ids. One cast-object will be created for each distinct space of all the supplied screens. Screens already receiving a cast with a higher priority will be skipped. */
  createCastsByPlaylistId: Maybe<CreateCastsByPlaylistIdPayload>;
  /** Create casts by supplying a site id and a list of screen ids. One cast-object will be created for each distinct space of all the supplied screens. Screens already receiving a cast with a higher priority will be skipped. */
  createCastsBySiteId: Maybe<CreateCastsBySiteIdPayload>;
  /** Create a channel */
  createChannel: Maybe<CreateChannelPayload>;
  /** Create a common app instance template based on an existing app instance */
  createCommonAppInstanceTemplateBySourceAppInstanceId: Maybe<CreateCommonAppInstanceTemplateBySourceAppInstanceIdPayload>;
  /** Create a content replacement job */
  createContentReplacementJob: Maybe<CreateContentReplacementJobPayload>;
  /** Create a new 3rd party credential */
  createCredential: Maybe<CreateCredentialPayload>;
  createCustomer: Maybe<CreateCustomerPayload>;
  /** Create new duplicate files */
  createDuplicateFiles: Maybe<CreateDuplicateFilesPayload>;
  createDuplicateJob: Maybe<CreateDuplicateJobPayload>;
  createDuplicateJobs: Maybe<Array<Maybe<CreateDuplicateJobPayload>>>;
  createEnrollmentToken: Maybe<CreateEnrollmentTokenPayload>;
  /** Create a new file */
  createFile: Maybe<CreateFilePayload>;
  /** Insert into studio.file an existing studio.common_file */
  createFileByCommonFileId: Maybe<CreateFileByCommonFileIdPayload>;
  /** Create a new Folder. */
  createFolder: Maybe<CreateFolderPayload>;
  /** Create a new custom font */
  createFont: Maybe<CreateFontPayload>;
  /** Create org font from common font ID. */
  createFontFromCommon: Maybe<CreateFontFromCommonPayload>;
  /** Create a new user group */
  createGroup: Maybe<CreateGroupPayload>;
  /** Create a new layout */
  createLayout: Maybe<CreateLayoutPayload>;
  /** Create a link */
  createLink: Maybe<CreateLinkPayload>;
  /** Create notification rule. */
  createNotificationRule: Maybe<CreateNotificationRulePayload>;
  /** Upsert white label. */
  createOrUpdateWhiteLabel: Maybe<CreateOrUpdateWhiteLabelPayload>;
  /** Create a new organization, user to the organization, space then create defaults screen group, library and folder */
  createOrg: Maybe<CreateOrgPayload>;
  createOrgConnection: Maybe<CreateOrgConnectionPayload>;
  createPaymentSourceCard: Maybe<CreatePaymentSourceCardPayload>;
  createPaymentSourceDirectdebit: Maybe<CreatePaymentSourceDirectdebitPayload>;
  createPaymentSourcePaypal: Maybe<CreatePaymentSourcePaypalPayload>;
  createPermissionSet: Maybe<CreatePermissionSetPayload>;
  createPermissionSetFromCommon: Maybe<CreatePermissionSetFromCommonPayload>;
  /** Create a playlist */
  createPlaylist: Maybe<CreatePlaylistPayload>;
  createQr: Maybe<CreateQrPayload>;
  createRelationship: Maybe<CreateRelationshipPayload>;
  /** Create a new screen by supplying a pairing code. */
  createScreen: Maybe<CreateScreenPayload>;
  /** Create a new screen group */
  createScreenGroup: Maybe<CreateScreenGroupPayload>;
  /** Create screen's note */
  createScreenNote: Maybe<CreateScreenNotePayload>;
  /** Create a signed JWT for apps to manage app connections and configurations. */
  createSignedAppManagementJwt: Maybe<AppManagementJwtPayload>;
  /** Create a signed JWT for apps to access backend services. */
  createSignedAppViewerJwt: Maybe<AppViewerJwtPayload>;
  /** Create a signed JWT for player or embed player. */
  createSignedRuntimeJwt: Maybe<RuntimeJwtPayload>;
  createSite: Maybe<CreateSitePayload>;
  /** Create a new Space, also will auto create default Screen Group and Default Folder. */
  createSpace: Maybe<CreateSpacePayload>;
  /** Create a new theme. */
  createTheme: Maybe<CreateThemePayload>;
  /** Create new access api to the current org. */
  createToken: Maybe<CreateTokenPayload>;
  /** Deactivate a user token */
  deactivateToken: Maybe<DeactivateTokenPayload>;
  /** Get the id of the default one zone landscape layout */
  defaultLayoutLandscape: Maybe<DefaultLayoutLandscapePayload>;
  /** Get the id of the default one zone portrait layout */
  defaultLayoutPortrait: Maybe<DefaultLayoutPortraitPayload>;
  /** Delete a app instance. */
  deleteAppInstance: Maybe<DeleteAppInstancePayload>;
  /** Delete multiple app instances. */
  deleteAppInstances: Maybe<DeleteAppInstancesPayload>;
  /**
   * Will be removed June 2021. Please use deleteChannels instead.
   * @deprecated true
   */
  deleteChannel: Maybe<DeleteChannelPayload>;
  /** Delete multiple playlists */
  deleteChannels: Maybe<DeleteChannelsPayload>;
  /** Deletes a common app instance template by id */
  deleteCommonAppInstanceTemplateById: Maybe<DeleteCommonAppInstanceTemplateByIdPayload>;
  /** Delete a 3rd party credential */
  deleteCredential: Maybe<DeleteCredentialPayload>;
  /** Delete a 3rd party credential */
  deleteCredentialByKey: Maybe<DeleteCredentialByKeyPayload>;
  deleteDuplicateJob: Maybe<DeleteDuplicateJobPayload>;
  deleteDuplicateJobs: Maybe<Array<Maybe<DeleteDuplicateJobsPayload>>>;
  deleteFileById: Maybe<DeleteFileByIdPayload>;
  deleteFileByIds: Maybe<DeleteFileByIdsPayload>;
  /** Delete a folder */
  deleteFolderById: Maybe<DeleteFolderByIdPayload>;
  /** Delete multiple folders */
  deleteFolderByIds: Maybe<DeleteFolderByIdsPayload>;
  /** Delete font by ID and unused font files will also be deleted. */
  deleteFont: Maybe<DeleteFontPayload>;
  deleteGroup: Maybe<DeleteGroupPayload>;
  /** Delete permission sets from a group */
  deleteGroupPermissionSets: Maybe<DeleteGroupPermissionSetsPayload>;
  /** Delete permission sets from a group space */
  deleteGroupSpacePermissionSets: Maybe<DeleteGroupSpacePermissionSetsPayload>;
  /** Create a existing layout */
  deleteLayout: Maybe<DeleteLayoutPayload>;
  /** Delete a link */
  deleteLinkById: Maybe<DeleteLinkByIdPayload>;
  /** Delete multiple links. */
  deleteLinks: Maybe<DeleteLinksPayload>;
  /** Delete a notification rule by ID */
  deleteNotificationRuleById: Maybe<DeleteNotificationRuleByIdPayload>;
  /** Delete a subscribed notification channel for a user by notification subscription ID (1 user per 1 channel per 1 event) */
  deleteNotificationSubscriptionById: Maybe<DeleteNotificationSubscriptionByIdPayload>;
  /** Delete an organisation, soft delete by deleted_at, status also auto update to disabled. */
  deleteOrg: Maybe<DeleteOrgPayload>;
  /** Delete an org connection */
  deleteOrgConnection: Maybe<DeleteOrgConnectionPayload>;
  deletePermissionSet: Maybe<DeletePermissionSetPayload>;
  /** @deprecated\nWill be deprecated in May 2021\nUse delete_playlists instead. */
  deletePlaylist: Maybe<DeletePlaylistPayload>;
  /** Delete multiple playlists */
  deletePlaylists: Maybe<DeletePlaylistsPayload>;
  /** Delete a screen */
  deleteScreen: Maybe<DeleteScreenPayload>;
  /** Delete a screen group */
  deleteScreenGroupById: Maybe<DeleteScreenGroupByIdPayload>;
  /** Delete screen's note by note id */
  deleteScreenNoteById: Maybe<DeleteScreenNoteByIdPayload>;
  deleteSiteById: Maybe<DeleteSiteByIdPayload>;
  /** Delete a space */
  deleteSpace: Maybe<DeleteSpacePayload>;
  /** Delete a new theme. */
  deleteTheme: Maybe<DeleteThemePayload>;
  /** Delete a user token. */
  deleteToken: Maybe<DeleteTokenPayload>;
  /** Archives a user in the same organization. Fully deletes them if their current status is pending. */
  deleteUser: Maybe<DeleteUserPayload>;
  /** Delete a user from groups */
  deleteUserFromGroups: Maybe<DeleteUserFromGroupsPayload>;
  /** Depair and delete a screen */
  depairScreen: Maybe<DepairScreenPayload>;
  directdebitAuthorize: Maybe<DirectdebitAuthorizePayload>;
  /** Duplicate an app instance. */
  duplicateAppInstance: Maybe<DuplicateAppInstancePayload>;
  /** Duplicate a channel */
  duplicateChannel: Maybe<DuplicateChannelPayload>;
  /** Duplicate an editable shared channel */
  duplicateEditableSharedChannel: Maybe<DuplicateEditableSharedChannelPayload>;
  /** Duplicate a playlist */
  duplicatePlaylist: Maybe<DuplicatePlaylistPayload>;
  ensurePolicyReadOnly: Maybe<EnsurePolicyReadOnlyPayload>;
  ensurePolicyStudioJobRunner: Maybe<EnsurePolicyStudioJobRunnerPayload>;
  exchangePreviewToken: Maybe<ExchangePreviewTokenPayload>;
  installAppForSpace: Maybe<InstallAppForSpacePayload>;
  /** To install a new app for organization to all space. */
  installOrgApp: Maybe<InstallOrgAppPayload>;
  /** To install a new app for a space. */
  installSpaceApp: Maybe<InstallSpaceAppPayload>;
  /** Invite user(s) to an organization and group(s) */
  inviteUser: Maybe<InviteUserPayload>;
  /** Verify if current session is valid */
  isSessionValid: Maybe<IsSessionValidPayload>;
  /** Check if current session user is a system user */
  isSystemUser: Maybe<IsSystemUserPayload>;
  migrationBilling: Maybe<MigrationBillingPayload>;
  migrationInit: Maybe<MigrationInitPayload>;
  migrationScreen: Maybe<MigrationScreenPayload>;
  /** Wrap pg_notify in a nice extendable interface */
  notifyBase: Maybe<NotifyBasePayload>;
  /**
   * @deprecated Use `createScreen` instead.
   * Create a new screen by supplying a pairing code.
   */
  pairScreen: Maybe<PairScreenPayload>;
  paypalAuthorize: Maybe<PaypalAuthorizePayload>;
  /** Permanently delete all soft-deleted items by space id */
  permanentlyDeleteAllItems: Maybe<PermanentlyDeleteAllItemsPayload>;
  /** Permanently delete soft-deleted items by ids */
  permanentlyDeleteByIdsAndSpaceId: Maybe<PermanentlyDeleteByIdsAndSpaceIdPayload>;
  /** Publish a draft version of channel */
  publishDraftChannel: Maybe<PublishDraftChannelPayload>;
  /** Publish a draft version of playlist */
  publishDraftPlaylist: Maybe<PublishDraftPlaylistPayload>;
  publishTheme: Maybe<PublishThemePayload>;
  pullEventBuffers: Maybe<PullEventBuffersPayload>;
  /** Reactivate a user token */
  reactivateToken: Maybe<ReactivateTokenPayload>;
  /** To refresh a screen. */
  refreshScreen: Maybe<RefreshScreenPayload>;
  refreshScreensByOrgId: Maybe<RefreshScreensByOrgIdPayload>;
  refreshScreensByScreenIds: Maybe<RefreshScreensByScreenIdsPayload>;
  refreshScreensBySpaceId: Maybe<RefreshScreensBySpaceIdPayload>;
  /** Regenerate a user token. */
  regenerateToken: Maybe<RegenerateTokenPayload>;
  /**
   * Use update_file_is_favorite instead
   * @deprecated true
   */
  removeFavoriteFile: Maybe<RemoveFavoriteFilePayload>;
  /**
   * Use update_folder_is_favorite instead
   * @deprecated true
   */
  removeFavoriteFolder: Maybe<RemoveFavoriteFolderPayload>;
  /**
   * Use update_link_is_favorite instead
   * @deprecated true
   */
  removeFavoriteLink: Maybe<RemoveFavoriteLinkPayload>;
  /**
   * Use update_playlist_is_favorite instead
   * @deprecated true
   */
  removeFavoritePlaylist: Maybe<RemoveFavoritePlaylistPayload>;
  /**
   * Use update_site_is_favorite instead
   * @deprecated true
   */
  removeFavoriteSite: Maybe<RemoveFavoriteSitePayload>;
  /** @deprecated\nUse deleteGroupPermissionSets instead */
  removeGroupPermissionSets: Maybe<RemoveGroupPermissionSetsPayload>;
  /** @deprecated\nUse deleteGroupSpacePermissionSets instead */
  removeGroupSpacePermissionSets: Maybe<RemoveGroupSpacePermissionSetsPayload>;
  /** @deprecated\nUse deleteOrgConnection instead */
  removeOrgConnection: Maybe<RemoveOrgConnectionPayload>;
  /** Reset Splash Channel back to default. */
  removeSplashTheme: Maybe<RemoveSplashThemePayload>;
  /** To remove logo from a theme. */
  removeThemeLogo: Maybe<RemoveThemeLogoPayload>;
  /**
   * Use deleteUserFromGroups instead
   * @deprecated true
   */
  removeUserFromGroups: Maybe<RemoveUserFromGroupsPayload>;
  replaceScreenDevice: Maybe<ReplaceScreenDeviceResponse>;
  /** Reset an org feature flag to default. */
  resetFeatureFlagOrg: Maybe<ResetFeatureFlagOrgPayload>;
  /** Reset multiple screen location */
  resetScreenLocationByIds: Maybe<ResetScreenLocationByIdsPayload>;
  /** Delete white label by ID. */
  resetWhiteLabel: Maybe<ResetWhiteLabelPayload>;
  /** Reset white label's glyph by ID. */
  resetWhiteLabelGlyph: Maybe<ResetWhiteLabelGlyphPayload>;
  /** Restore all soft-deleted items by space id */
  restoreAllItems: Maybe<RestoreAllItemsPayload>;
  /** Restore soft-deleted items by ids */
  restoreByIdsAndSpaceId: Maybe<RestoreByIdsAndSpaceIdPayload>;
  /** Revert changes on a draft version to the publish version. */
  revertDraftChannel: Maybe<RevertDraftChannelPayload>;
  /** Revert changes on a draft version to the publish version. */
  revertDraftPlaylist: Maybe<RevertDraftPlaylistPayload>;
  revertTheme: Maybe<RevertThemePayload>;
  saveInvoicePayment: Maybe<SaveInvoicePaymentPayload>;
  /** Stop cast content to a screen. */
  screenCastStop: Maybe<ScreenCastStopPayload>;
  sendCommandToScreensByScreenIds: Maybe<SendCommandToScreensByScreenIdsPayload>;
  /** Set permissions for this bot by user ids. */
  setBotPermissionsByUserIds: Maybe<SetBotPermissionsByUserIdsPayload>;
  /** Updates the org's feature flag */
  setFeatureFlagOrg: Maybe<SetFeatureFlagOrgPayload>;
  /** Set group to be a default group for newly created users. */
  setGroupIsDefault: Maybe<SetGroupIsDefaultPayload>;
  /** Set organization default channel theme. (allow null) */
  setOrgDefaultChannelTheme: Maybe<SetOrgDefaultChannelThemePayload>;
  /** Set org-wide default user permissions */
  setOrgDefaultUserPermissions: Maybe<SetOrgDefaultUserPermissionsPayload>;
  /** Set organization splash channel's theme. */
  setOrgSplashChannelTheme: Maybe<SetOrgSplashChannelThemePayload>;
  /** To set screen content */
  setScreenContent: Maybe<SetScreenContentPayload>;
  /** To set screen content to an app instance. */
  setScreenContentByAppInstanceId: Maybe<SetScreenContentByAppInstanceIdPayload>;
  /** To set channel to a screen. */
  setScreenContentByChannelId: Maybe<SetScreenContentByChannelIdPayload>;
  /** To set screen content to a file. */
  setScreenContentByFileId: Maybe<SetScreenContentByFileIdPayload>;
  /** To set screen content to a link. */
  setScreenContentByLinkId: Maybe<SetScreenContentByLinkIdPayload>;
  /** To set screen content to a playlist. */
  setScreenContentByPlaylistId: Maybe<SetScreenContentByPlaylistIdPayload>;
  /** To set screen content to a Site. */
  setScreenContentBySiteId: Maybe<SetScreenContentBySiteIdPayload>;
  /** To set multiple screens content */
  setScreensContent: Maybe<SetScreensContentPayload>;
  /** To set multiple screens content to an app instance. */
  setScreensContentByAppInstanceId: Maybe<SetScreensContentByAppInstanceIdPayload>;
  /** To set multiple screens content to a channel. */
  setScreensContentByChannelId: Maybe<SetScreensContentByChannelIdPayload>;
  /** To set multiple screens content to a file. */
  setScreensContentByFileId: Maybe<SetScreensContentByFileIdPayload>;
  /** To set multiple screens content to a link. */
  setScreensContentByLinkId: Maybe<SetScreensContentByLinkIdPayload>;
  /** To set multiple screens content to a playlist. */
  setScreensContentByPlaylistId: Maybe<SetScreensContentByPlaylistIdPayload>;
  /** To set multiple screens content to a Site. */
  setScreensContentBySiteId: Maybe<SetScreensContentBySiteIdPayload>;
  /** Set the channel to be the start channel for the organization. */
  setStartChannel: Maybe<SetStartChannelPayload>;
  /** Grant or remove owner permissions from this user */
  setUserIsOwner: Maybe<SetUserIsOwnerPayload>;
  /**
   *   Share channel to all spaces with sharing mode.
   *   Supported sharing mode: disabled, read_only, editable.
   *
   *   This function is based from update_share_channel_to_all_spaces.
   *
   */
  shareChannelToAllSpaces: Maybe<ShareChannelToAllSpacesPayload>;
  /**
   *   Share channel to specific space by space id and sharing mode.
   *   Supported sharing mode: disabled, read_only, editable.
   *
   *   This function is based from update_share_channel_to_spaces.
   *
   */
  shareChannelToSpace: Maybe<ShareChannelToSpacePayload>;
  ssoCreateConnection: Maybe<SsoCreateConnectionPayload>;
  ssoEditConnection: Maybe<SsoEditConnectionPayload>;
  /** Set screen content back to default channel if exists, otherwise set to None. */
  stopSetContent: Maybe<StopSetContentPayload>;
  /** Subscribe a user to notifications. */
  subscribeNotification: Maybe<SubscribeNotificationPayload>;
  /** Create screen notification rule and subscribe for all screens. */
  subscribeScreenNotificationByOrgId: Maybe<SubscribeScreenNotificationByOrgIdPayload>;
  /** Create screen notification rule and subscribe for multiple screens. */
  subscribeScreenNotificationByScreenIds: Maybe<SubscribeScreenNotificationByScreenIdsPayload>;
  /** Create screen notification rule and subscribe for all screen in the space. */
  subscribeScreenNotificationBySpaceId: Maybe<SubscribeScreenNotificationBySpaceIdPayload>;
  takeScreenshotByScreenId: Maybe<TakeScreenshotByScreenIdPayload>;
  /** Transfer a screen from one space to another, and set to play default channel, except when space-based billing is enabled. */
  transferScreens: Maybe<TransferScreensPayload>;
  /** Unsubscribe Gainsight preferences for a user. */
  unSubGainsightForUser: UnSubGainsightForUserResponse;
  /** To uninstall an app from organization. */
  uninstallOrgApp: Maybe<UninstallOrgAppPayload>;
  /** To uninstall an app from space. */
  uninstallSpaceApp: Maybe<UninstallSpaceAppPayload>;
  /** Unsubscribe and delete all notification rules on current organization */
  unsubscribeAllScreenNotifications: Maybe<UnsubscribeAllScreenNotificationsPayload>;
  /** Unsubscribe and delete all notification rules for multiple screens */
  unsubscribeScreenNotificationByScreenIds: Maybe<UnsubscribeScreenNotificationByScreenIdsPayload>;
  /** Unsubscribe and delete all notification rules on specific space ID */
  unsubscribeScreenNotificationBySpaceId: Maybe<UnsubscribeScreenNotificationBySpaceIdPayload>;
  /** To update an existing app instance data. */
  updateAppInstance: Maybe<UpdateAppInstancePayload>;
  /**
   *  Use updateAppInstanceAvailableByIds instead.
   * @deprecated true
   */
  updateAppInstanceAvailable: Maybe<UpdateAppInstanceAvailablePayload>;
  /** Update multiple app instance's available date. */
  updateAppInstanceAvailableByIds: Maybe<UpdateAppInstanceAvailableByIdsPayload>;
  /**
   *  Use updateAppInstanceExpireByIds instead.
   * @deprecated true
   */
  updateAppInstanceExpire: Maybe<UpdateAppInstanceExpirePayload>;
  /** Update multiple app instance's expire date. */
  updateAppInstanceExpireByIds: Maybe<UpdateAppInstanceExpireByIdsPayload>;
  /** Update tags to an app instance */
  updateAppInstanceTags: Maybe<UpdateAppInstanceTagsPayload>;
  /** Update tags on multiple app instances by app id and space id */
  updateAppInstanceTagsByAppIdAndSpaceId: Maybe<UpdateAppInstanceTagsByAppIdAndSpaceIdPayload>;
  /** Update tags on multiple app instances by app instance ids */
  updateAppInstanceTagsByIds: Maybe<UpdateAppInstanceTagsByIdsPayload>;
  updateBillingAddress: Maybe<UpdateBillingAddressPayload>;
  /** Update a bot user. */
  updateBot: Maybe<UpdateBotPayload>;
  /** Update a channel */
  updateChannel: Maybe<UpdateChannelPayload>;
  /** Update a channel's cover data */
  updateChannelCover: Maybe<UpdateChannelCoverPayload>;
  /**
   *   Update channel default sharing mode
   *   Supported sharing mode: disabled, read_only, editable.
   *
   */
  updateChannelDefaultSharingMode: Maybe<UpdateChannelDefaultSharingModePayload>;
  /** Update channel embed properties. enabled and public */
  updateChannelEmbed: Maybe<UpdateChannelEmbedPayload>;
  /** Update a channel's is_enabled */
  updateChannelEnable: Maybe<UpdateChannelEnablePayload>;
  /** Update a channel's name */
  updateChannelName: Maybe<UpdateChannelNamePayload>;
  /** Update embed short ID of channel by channel ID */
  updateChannelShortIdByChannelId: Maybe<UpdateChannelShortIdByChannelIdPayload>;
  /** Update tags to a channel. */
  updateChannelTags: Maybe<UpdateChannelTagsPayload>;
  /** Update theme of the channel. */
  updateChannelTheme: Maybe<UpdateChannelThemePayload>;
  /** Update a common app instance template based on an existing app instance. */
  updateCommonAppInstanceTemplateBySourceAppInstanceId: Maybe<UpdateCommonAppInstanceTemplateBySourceAppInstanceIdPayload>;
  /** Update description of a 3rd party credential */
  updateCredential: Maybe<UpdateCredentialPayload>;
  /**
   *  Use updateFileAvailableByIds instead.
   * @deprecated true
   */
  updateFileAvailable: Maybe<UpdateFileAvailablePayload>;
  /** Update multiple file's available date. */
  updateFileAvailableByIds: Maybe<UpdateFileAvailableByIdsPayload>;
  /** Update a file. */
  updateFileById: Maybe<UpdateFileByIdPayload>;
  /** Update multiple files. */
  updateFileByIds: Maybe<UpdateFileByIdsPayload>;
  /**
   *  Use updateFileExpireByIds instead.
   * @deprecated true
   */
  updateFileExpire: Maybe<UpdateFileExpirePayload>;
  /** Update multiple file's expire date. */
  updateFileExpireByIds: Maybe<UpdateFileExpireByIdsPayload>;
  /** Add or remove these files as a favorite */
  updateFileIsFavorite: Maybe<UpdateFileIsFavoritePayload>;
  /** Update a folder */
  updateFolderById: Maybe<UpdateFolderByIdPayload>;
  /** Update multiple folder. */
  updateFolderByIds: Maybe<UpdateFolderByIdsPayload>;
  /** Add or remove these folders as a favorite */
  updateFolderIsFavorite: Maybe<UpdateFolderIsFavoritePayload>;
  /** Update a font */
  updateFont: Maybe<UpdateFontPayload>;
  /** Update a group */
  updateGroup: Maybe<UpdateGroupPayload>;
  /** Set permissions for a group on a space */
  updateGroupSpace: Maybe<UpdateGroupSpacePayload>;
  /** Update details related to an invited (pending) user only. */
  updateInviteUser: Maybe<UpdateInviteUserPayload>;
  /** Update existing layout */
  updateLayout: Maybe<UpdateLayoutPayload>;
  /** Update a link */
  updateLinkById: Maybe<UpdateLinkByIdPayload>;
  /** Add or remove these links as a favorite */
  updateLinkIsFavorite: Maybe<UpdateLinkIsFavoritePayload>;
  /** Update notification rule by ID */
  updateNotificationRuleById: Maybe<UpdateNotificationRuleByIdPayload>;
  /** Update a subscribed notification channel (1 user per 1 channel per 1 event) by ID */
  updateNotificationSubscriptionById: Maybe<UpdateNotificationSubscriptionByIdPayload>;
  /** Update organisation. */
  updateOrg: Maybe<UpdateOrgPayload>;
  /** Update organization branding information. */
  updateOrgBrandingConfig: Maybe<UpdateOrgBrandingConfigPayload>;
  /** Set org connection SSO settings */
  updateOrgConnection: Maybe<UpdateOrgConnectionPayload>;
  /** Update organization's onboarding data. */
  updateOrgOnboardingData: Maybe<UpdateOrgOnboardingDataPayload>;
  /** To override the player logging service url, omit or set the player_config to null to use the default logging service. */
  updateOrgOverridePlayerLogConfig: Maybe<UpdateOrgOverridePlayerLogConfigPayload>;
  /** To override the player url after pairing, for the existing player to take effect requires a screen refresh action, set the url to blank to use the default player. */
  updateOrgOverridePlayerUrl: Maybe<UpdateOrgOverridePlayerUrlPayload>;
  /** Update Org's player show error mode, can be 'temporary' and 'permanent'. */
  updateOrgPlayerDisplayErrorMode: Maybe<UpdateOrgPlayerDisplayErrorModePayload>;
  /** Update a single org setting */
  updateOrgSetting: Maybe<UpdateOrgSettingPayload>;
  /** Update org's setting data. */
  updateOrgSettings: Maybe<UpdateOrgSettingsPayload>;
  /** Update a permission set */
  updatePermissionSet: Maybe<UpdatePermissionSetPayload>;
  /** Update a draft version of playlist */
  updatePlaylist: Maybe<UpdatePlaylistPayload>;
  /** @deprecated\nWill be deprecated in February 2021\nUse update_playlist instead. */
  updatePlaylistColor: Maybe<UpdatePlaylistColorPayload>;
  /** Update playlist embed properties. enabled and public */
  updatePlaylistEmbed: Maybe<UpdatePlaylistEmbedPayload>;
  /** Update playlist's is_enabled. */
  updatePlaylistEnable: Maybe<UpdatePlaylistEnablePayload>;
  /** Add or remove these playlists as a favorite */
  updatePlaylistIsFavorite: Maybe<UpdatePlaylistIsFavoritePayload>;
  /** Update playlist's name. */
  updatePlaylistName: Maybe<UpdatePlaylistNamePayload>;
  /** Update embed short ID of playlist by playlist ID */
  updatePlaylistShortIdByPlaylistId: Maybe<UpdatePlaylistShortIdByPlaylistIdPayload>;
  /** Update tags to a playlist. */
  updatePlaylistTags: Maybe<UpdatePlaylistTagsPayload>;
  updateRelationship: Maybe<UpdateRelationshipPayload>;
  /** Update a screen */
  updateScreenById: Maybe<UpdateScreenByIdPayload>;
  /** Update multiple screens */
  updateScreenByIds: Maybe<UpdateScreenByIdsPayload>;
  updateScreenDeviceAllowClone: Maybe<UpdateScreenDeviceAllowClonePayload>;
  /** For screen to call to update itself only related device_info & device_raw information. */
  updateScreenDeviceInfo: Maybe<UpdateScreenDeviceInfoPayload>;
  /** Update a screen display error mode, can be 'temporary' and 'permanent'. */
  updateScreenDisplayErrorMode: Maybe<UpdateScreenDisplayErrorModePayload>;
  /** Update a screen group */
  updateScreenGroupById: Maybe<UpdateScreenGroupByIdPayload>;
  /** Update multiple screen location */
  updateScreenLocationByIds: Maybe<UpdateScreenLocationByIdsPayload>;
  /** Update screen's note by note id */
  updateScreenNoteById: Maybe<UpdateScreenNoteByIdPayload>;
  /** Update a self information. (user) */
  updateSelf: Maybe<UpdateSelfPayload>;
  /** For screen to call to update itself for device information. */
  updateSelfScreenDevice: Maybe<UpdateSelfScreenDevicePayload>;
  /** To share app instance to all spaces in the same organization. */
  updateShareAppInstanceToAllSpaces: Maybe<UpdateShareAppInstanceToAllSpacesPayload>;
  /** Share app instance to some spaces in the same organization. */
  updateShareAppInstanceToSpaces: Maybe<UpdateShareAppInstanceToSpacesPayload>;
  /** To share channel to all spaces in the same organization. */
  updateShareChannelToAllSpaces: Maybe<UpdateShareChannelToAllSpacesPayload>;
  /** To share channel to other space(s). */
  updateShareChannelToSpaces: Maybe<UpdateShareChannelToSpacesPayload>;
  /** To share credential to all spaces in the same organization. */
  updateShareCredentialToAllSpaces: Maybe<UpdateShareCredentialToAllSpacesPayload>;
  /** To share credential to some spaces in the same organization. */
  updateShareCredentialToSpaces: Maybe<UpdateShareCredentialToSpacesPayload>;
  /** To share file to all spaces in the same organization. */
  updateShareFileToAllSpaces: Maybe<UpdateShareFileToAllSpacesPayload>;
  /** To share file to some spaces in the same organization. */
  updateShareFileToSpaces: Maybe<UpdateShareFileToSpacesPayload>;
  /** To share folder to all spaces in the same organization. */
  updateShareFolderToAllSpaces: Maybe<UpdateShareFolderToAllSpacesPayload>;
  /** To share folder to some spaces in the same organization. */
  updateShareFolderToSpaces: Maybe<UpdateShareFolderToSpacesPayload>;
  /** To share link to all spaces in the same organization. */
  updateShareLinkToAllSpaces: Maybe<UpdateShareLinkToAllSpacesPayload>;
  /** To share link to some spaces in the same organization. */
  updateShareLinkToSpaces: Maybe<UpdateShareLinkToSpacesPayload>;
  /** To share playlist to all spaces in the same organization. */
  updateSharePlaylistToAllSpaces: Maybe<UpdateSharePlaylistToAllSpacesPayload>;
  /** To share playlist to other space(s) */
  updateSharePlaylistToSpaces: Maybe<UpdateSharePlaylistToSpacesPayload>;
  /** To share site to all spaces in the same organization. */
  updateShareSiteToAllSpaces: Maybe<UpdateShareSiteToAllSpacesPayload>;
  /** To share site to some spaces in the same organization. */
  updateShareSiteToSpaces: Maybe<UpdateShareSiteToSpacesPayload>;
  updateSite: Maybe<UpdateSitePayload>;
  /** Add or remove these sites as a favorite */
  updateSiteIsFavorite: Maybe<UpdateSiteIsFavoritePayload>;
  /** Update a space */
  updateSpace: Maybe<UpdateSpacePayload>;
  updateSubscription: Maybe<UpdateSubscriptionPayload>;
  /** Update a new theme name, config and logo. To remove logo please using removeThemeLogo. */
  updateTheme: Maybe<UpdateThemePayload>;
  /** Update access api name and permissions. */
  updateToken: Maybe<UpdateTokenPayload>;
  /** Update a user in the same organization. */
  updateUser: Maybe<UpdateUserPayload>;
  /** Update user's infomation data. */
  updateUserInfo: Maybe<UpdateUserInfoPayload>;
  /** Update user's onboarding data. */
  updateUserOnboardingData: Maybe<UpdateUserOnboardingDataPayload>;
  /** Update user's provider information. */
  updateUserProvider: Maybe<UpdateUserProviderPayload>;
  /** Update user's setting data. */
  updateUserSettings: Maybe<UpdateUserSettingsPayload>;
  /** @omit\nRaise an error if color is not in hex format */
  validateColorCode: Maybe<ValidateColorCodePayload>;
  /** Validate the config json for layout. */
  validateLayoutConfigJson: Maybe<ValidateLayoutConfigJsonPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationActivateSubscriptionArgs = {
  input: ActivateSubscriptionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddAppInstanceToChannelArgs = {
  input: AddAppInstanceToChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddAppInstanceToPlaylistArgs = {
  input: AddAppInstanceToPlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddFavoriteFileArgs = {
  input: AddFavoriteFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddFavoriteFolderArgs = {
  input: AddFavoriteFolderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddFavoriteLinkArgs = {
  input: AddFavoriteLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddFavoritePlaylistArgs = {
  input: AddFavoritePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddFavoriteSiteArgs = {
  input: AddFavoriteSiteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddFileToChannelArgs = {
  input: AddFileToChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddFileToPlaylistArgs = {
  input: AddFileToPlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddGroupPermissionSetsArgs = {
  input: AddGroupPermissionSetsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddGroupSpacePermissionSetsArgs = {
  input: AddGroupSpacePermissionSetsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddLinkToChannelArgs = {
  input: AddLinkToChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddLinkToPlaylistArgs = {
  input: AddLinkToPlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddPlaylistToChannelArgs = {
  input: AddPlaylistToChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddUserToGroupsArgs = {
  input: AddUserToGroupsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthAcceptPendingUserInviteArgs = {
  input: AuthAcceptPendingUserInviteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationBulkCreateCastsArgs = {
  input: BulkCreateCastsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationBulkResetScreenLocationArgs = {
  input: BulkResetScreenLocationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationBulkUpdateScreenContentArgs = {
  input: BulkUpdateScreenContentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationBulkUpdateScreenLocationArgs = {
  input: BulkUpdateScreenLocationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationBulkUpdateScreenTimezoneArgs = {
  input: BulkUpdateScreenTimezoneInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCardIntentConfirmArgs = {
  input: CardIntentConfirmInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCardIntentPaymentArgs = {
  input: CardIntentPaymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCardIntentSetupArgs = {
  input: CardIntentSetupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCastStartArgs = {
  input: CastStartInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCastStopArgs = {
  input: CastStopInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCastableScreensArgs = {
  input: CastableScreensInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearCacheScreenArgs = {
  input: ClearCacheScreenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearCacheScreensByScreenIdsArgs = {
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearCacheScreensBySpaceIdArgs = {
  spaceId: Scalars["UUID"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdditionalQrContextArgs = {
  input: CreateAdditionalQrContextInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppInstanceArgs = {
  input: CreateAppInstanceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppInstanceByCommonAppInstanceTemplateIdArgs = {
  input: CreateAppInstanceByCommonAppInstanceTemplateIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBotArgs = {
  input: CreateBotInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCastsArgs = {
  input: CreateCastsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCastsByAppInstanceIdArgs = {
  input: CreateCastsByAppInstanceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCastsByChannelIdArgs = {
  input: CreateCastsByChannelIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCastsByFileIdArgs = {
  input: CreateCastsByFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCastsByLinkIdArgs = {
  input: CreateCastsByLinkIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCastsByPlaylistIdArgs = {
  input: CreateCastsByPlaylistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCastsBySiteIdArgs = {
  input: CreateCastsBySiteIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCommonAppInstanceTemplateBySourceAppInstanceIdArgs = {
  input: CreateCommonAppInstanceTemplateBySourceAppInstanceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateContentReplacementJobArgs = {
  input: CreateContentReplacementJobInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCredentialArgs = {
  input: CreateCredentialInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDuplicateFilesArgs = {
  input: CreateDuplicateFilesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDuplicateJobArgs = {
  input: CreateDuplicateJobInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDuplicateJobsArgs = {
  input: CreateDuplicateJobsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEnrollmentTokenArgs = {
  input: CreateEnrollmentTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileArgs = {
  input: CreateFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileByCommonFileIdArgs = {
  input: CreateFileByCommonFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFontArgs = {
  input: CreateFontInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFontFromCommonArgs = {
  input: CreateFontFromCommonInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLayoutArgs = {
  input: CreateLayoutInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLinkArgs = {
  input: CreateLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNotificationRuleArgs = {
  input: CreateNotificationRuleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrUpdateWhiteLabelArgs = {
  input: CreateOrUpdateWhiteLabelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrgConnectionArgs = {
  input: CreateOrgConnectionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentSourceCardArgs = {
  input: CreatePaymentSourceCardInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentSourceDirectdebitArgs = {
  input: CreatePaymentSourceDirectdebitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentSourcePaypalArgs = {
  input: CreatePaymentSourcePaypalInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePermissionSetArgs = {
  input: CreatePermissionSetInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePermissionSetFromCommonArgs = {
  input: CreatePermissionSetFromCommonInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlaylistArgs = {
  input: CreatePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateQrArgs = {
  input: CreateQrInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRelationshipArgs = {
  input: CreateRelationshipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateScreenArgs = {
  input: CreateScreenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateScreenGroupArgs = {
  input: CreateScreenGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateScreenNoteArgs = {
  input: CreateScreenNoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSignedAppManagementJwtArgs = {
  input: AppManagementJwtRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSignedAppViewerJwtArgs = {
  input: AppViewerJwtRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSignedRuntimeJwtArgs = {
  input: RuntimeJwtRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSiteArgs = {
  input: CreateSiteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSpaceArgs = {
  input: CreateSpaceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateThemeArgs = {
  input: CreateThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTokenArgs = {
  input: CreateTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeactivateTokenArgs = {
  input: DeactivateTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDefaultLayoutLandscapeArgs = {
  input: DefaultLayoutLandscapeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDefaultLayoutPortraitArgs = {
  input: DefaultLayoutPortraitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppInstanceArgs = {
  input: DeleteAppInstanceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppInstancesArgs = {
  input: DeleteAppInstancesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChannelArgs = {
  input: DeleteChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChannelsArgs = {
  input: DeleteChannelsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommonAppInstanceTemplateByIdArgs = {
  input: DeleteCommonAppInstanceTemplateByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCredentialArgs = {
  input: DeleteCredentialInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCredentialByKeyArgs = {
  input: DeleteCredentialByKeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDuplicateJobArgs = {
  input: DeleteDuplicateJobInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDuplicateJobsArgs = {
  input: DeleteDuplicateJobsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByIdArgs = {
  input: DeleteFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByIdsArgs = {
  input: DeleteFileByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFolderByIdArgs = {
  input: DeleteFolderByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFolderByIdsArgs = {
  input: DeleteFolderByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFontArgs = {
  input: DeleteFontInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupPermissionSetsArgs = {
  input: DeleteGroupPermissionSetsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupSpacePermissionSetsArgs = {
  input: DeleteGroupSpacePermissionSetsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLayoutArgs = {
  input: DeleteLayoutInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLinkByIdArgs = {
  input: DeleteLinkByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLinksArgs = {
  input: DeleteLinksInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationRuleByIdArgs = {
  input: DeleteNotificationRuleByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationSubscriptionByIdArgs = {
  input: DeleteNotificationSubscriptionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrgArgs = {
  input: DeleteOrgInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrgConnectionArgs = {
  input: DeleteOrgConnectionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePermissionSetArgs = {
  input: DeletePermissionSetInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlaylistArgs = {
  input: DeletePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlaylistsArgs = {
  input: DeletePlaylistsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteScreenArgs = {
  input: DeleteScreenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteScreenGroupByIdArgs = {
  input: DeleteScreenGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteScreenNoteByIdArgs = {
  input: DeleteScreenNoteByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSiteByIdArgs = {
  input: DeleteSiteByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSpaceArgs = {
  input: DeleteSpaceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteThemeArgs = {
  input: DeleteThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTokenArgs = {
  input: DeleteTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserFromGroupsArgs = {
  input: DeleteUserFromGroupsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDepairScreenArgs = {
  input: DepairScreenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDirectdebitAuthorizeArgs = {
  input: DirectdebitAuthorizeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicateAppInstanceArgs = {
  input: DuplicateAppInstanceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicateChannelArgs = {
  input: DuplicateChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicateEditableSharedChannelArgs = {
  input: DuplicateEditableSharedChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicatePlaylistArgs = {
  input: DuplicatePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationEnsurePolicyReadOnlyArgs = {
  input: EnsurePolicyReadOnlyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationEnsurePolicyStudioJobRunnerArgs = {
  input: EnsurePolicyStudioJobRunnerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationExchangePreviewTokenArgs = {
  screenId: Scalars["UUID"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInstallAppForSpaceArgs = {
  input: InstallAppForSpaceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInstallOrgAppArgs = {
  input: InstallOrgAppInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInstallSpaceAppArgs = {
  input: InstallSpaceAppInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsSessionValidArgs = {
  input: IsSessionValidInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsSystemUserArgs = {
  input: IsSystemUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigrationBillingArgs = {
  input: MigrationBillingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigrationInitArgs = {
  input: MigrationInitInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigrationScreenArgs = {
  input: MigrationScreenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationNotifyBaseArgs = {
  input: NotifyBaseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPairScreenArgs = {
  input: PairScreenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPaypalAuthorizeArgs = {
  input: PaypalAuthorizeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPermanentlyDeleteAllItemsArgs = {
  input: PermanentlyDeleteAllItemsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPermanentlyDeleteByIdsAndSpaceIdArgs = {
  input: PermanentlyDeleteByIdsAndSpaceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishDraftChannelArgs = {
  input: PublishDraftChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishDraftPlaylistArgs = {
  input: PublishDraftPlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishThemeArgs = {
  input: PublishThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPullEventBuffersArgs = {
  input: PullEventBuffersInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReactivateTokenArgs = {
  input: ReactivateTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshScreenArgs = {
  input: RefreshScreenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshScreensByScreenIdsArgs = {
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshScreensBySpaceIdArgs = {
  spaceId: Scalars["UUID"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegenerateTokenArgs = {
  input: RegenerateTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveFavoriteFileArgs = {
  input: RemoveFavoriteFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveFavoriteFolderArgs = {
  input: RemoveFavoriteFolderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveFavoriteLinkArgs = {
  input: RemoveFavoriteLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveFavoritePlaylistArgs = {
  input: RemoveFavoritePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveFavoriteSiteArgs = {
  input: RemoveFavoriteSiteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveGroupPermissionSetsArgs = {
  input: RemoveGroupPermissionSetsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveGroupSpacePermissionSetsArgs = {
  input: RemoveGroupSpacePermissionSetsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveOrgConnectionArgs = {
  input: RemoveOrgConnectionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveSplashThemeArgs = {
  input: RemoveSplashThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveThemeLogoArgs = {
  input: RemoveThemeLogoInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveUserFromGroupsArgs = {
  input: RemoveUserFromGroupsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReplaceScreenDeviceArgs = {
  input: ReplaceScreenDeviceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetFeatureFlagOrgArgs = {
  input: ResetFeatureFlagOrgInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetScreenLocationByIdsArgs = {
  input: ResetScreenLocationByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetWhiteLabelArgs = {
  input: ResetWhiteLabelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetWhiteLabelGlyphArgs = {
  input: ResetWhiteLabelGlyphInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRestoreAllItemsArgs = {
  input: RestoreAllItemsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRestoreByIdsAndSpaceIdArgs = {
  input: RestoreByIdsAndSpaceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRevertDraftChannelArgs = {
  input: RevertDraftChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRevertDraftPlaylistArgs = {
  input: RevertDraftPlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRevertThemeArgs = {
  input: RevertThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSaveInvoicePaymentArgs = {
  input: SaveInvoicePaymentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationScreenCastStopArgs = {
  input: ScreenCastStopInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendCommandToScreensByScreenIdsArgs = {
  input: SendCommandInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetBotPermissionsByUserIdsArgs = {
  input: SetBotPermissionsByUserIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetFeatureFlagOrgArgs = {
  input: SetFeatureFlagOrgInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetGroupIsDefaultArgs = {
  input: SetGroupIsDefaultInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetOrgDefaultChannelThemeArgs = {
  input: SetOrgDefaultChannelThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetOrgDefaultUserPermissionsArgs = {
  input: SetOrgDefaultUserPermissionsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetOrgSplashChannelThemeArgs = {
  input: SetOrgSplashChannelThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreenContentArgs = {
  input: SetScreenContentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreenContentByAppInstanceIdArgs = {
  input: SetScreenContentByAppInstanceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreenContentByChannelIdArgs = {
  input: SetScreenContentByChannelIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreenContentByFileIdArgs = {
  input: SetScreenContentByFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreenContentByLinkIdArgs = {
  input: SetScreenContentByLinkIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreenContentByPlaylistIdArgs = {
  input: SetScreenContentByPlaylistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreenContentBySiteIdArgs = {
  input: SetScreenContentBySiteIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreensContentArgs = {
  input: SetScreensContentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreensContentByAppInstanceIdArgs = {
  input: SetScreensContentByAppInstanceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreensContentByChannelIdArgs = {
  input: SetScreensContentByChannelIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreensContentByFileIdArgs = {
  input: SetScreensContentByFileIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreensContentByLinkIdArgs = {
  input: SetScreensContentByLinkIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreensContentByPlaylistIdArgs = {
  input: SetScreensContentByPlaylistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetScreensContentBySiteIdArgs = {
  input: SetScreensContentBySiteIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetStartChannelArgs = {
  input: SetStartChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetUserIsOwnerArgs = {
  input: SetUserIsOwnerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationShareChannelToAllSpacesArgs = {
  input: ShareChannelToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationShareChannelToSpaceArgs = {
  input: ShareChannelToSpaceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSsoCreateConnectionArgs = {
  input: SsoCreateConnectionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSsoEditConnectionArgs = {
  input: SsoEditConnectionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationStopSetContentArgs = {
  input: StopSetContentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubscribeNotificationArgs = {
  input: SubscribeNotificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubscribeScreenNotificationByOrgIdArgs = {
  input: SubscribeScreenNotificationByOrgIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubscribeScreenNotificationByScreenIdsArgs = {
  input: SubscribeScreenNotificationByScreenIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubscribeScreenNotificationBySpaceIdArgs = {
  input: SubscribeScreenNotificationBySpaceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationTakeScreenshotByScreenIdArgs = {
  input: TakeScreenshotByScreenIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationTransferScreensArgs = {
  input: TransferScreensInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUnSubGainsightForUserArgs = {
  globalUnsubscribe: Scalars["Boolean"];
  guidedTours: InputMaybe<Scalars["Boolean"]>;
  identifyId: Scalars["String"];
  onboardingBot: InputMaybe<Scalars["Boolean"]>;
  productUpdates: InputMaybe<Scalars["Boolean"]>;
  surveys: InputMaybe<Scalars["Boolean"]>;
  trackEmailAnalytics: InputMaybe<Scalars["Boolean"]>;
  trackUsage: InputMaybe<Scalars["Boolean"]>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUninstallOrgAppArgs = {
  input: UninstallOrgAppInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUninstallSpaceAppArgs = {
  input: UninstallSpaceAppInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUnsubscribeAllScreenNotificationsArgs = {
  input: UnsubscribeAllScreenNotificationsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUnsubscribeScreenNotificationByScreenIdsArgs = {
  input: UnsubscribeScreenNotificationByScreenIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUnsubscribeScreenNotificationBySpaceIdArgs = {
  input: UnsubscribeScreenNotificationBySpaceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppInstanceArgs = {
  input: UpdateAppInstanceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppInstanceAvailableArgs = {
  input: UpdateAppInstanceAvailableInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppInstanceAvailableByIdsArgs = {
  input: UpdateAppInstanceAvailableByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppInstanceExpireArgs = {
  input: UpdateAppInstanceExpireInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppInstanceExpireByIdsArgs = {
  input: UpdateAppInstanceExpireByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppInstanceTagsArgs = {
  input: UpdateAppInstanceTagsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppInstanceTagsByAppIdAndSpaceIdArgs = {
  input: UpdateAppInstanceTagsByAppIdAndSpaceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppInstanceTagsByIdsArgs = {
  input: UpdateAppInstanceTagsByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBillingAddressArgs = {
  input: UpdateBillingAddressInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBotArgs = {
  input: UpdateBotInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelCoverArgs = {
  input: UpdateChannelCoverInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelDefaultSharingModeArgs = {
  input: UpdateChannelDefaultSharingModeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelEmbedArgs = {
  input: UpdateChannelEmbedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelEnableArgs = {
  input: UpdateChannelEnableInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelNameArgs = {
  input: UpdateChannelNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelShortIdByChannelIdArgs = {
  input: UpdateChannelShortIdByChannelIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelTagsArgs = {
  input: UpdateChannelTagsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChannelThemeArgs = {
  input: UpdateChannelThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommonAppInstanceTemplateBySourceAppInstanceIdArgs = {
  input: UpdateCommonAppInstanceTemplateBySourceAppInstanceIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCredentialArgs = {
  input: UpdateCredentialInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileAvailableArgs = {
  input: UpdateFileAvailableInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileAvailableByIdsArgs = {
  input: UpdateFileAvailableByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByIdArgs = {
  input: UpdateFileByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByIdsArgs = {
  input: UpdateFileByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileExpireArgs = {
  input: UpdateFileExpireInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileExpireByIdsArgs = {
  input: UpdateFileExpireByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileIsFavoriteArgs = {
  input: UpdateFileIsFavoriteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFolderByIdArgs = {
  input: UpdateFolderByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFolderByIdsArgs = {
  input: UpdateFolderByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFolderIsFavoriteArgs = {
  input: UpdateFolderIsFavoriteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFontArgs = {
  input: UpdateFontInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupSpaceArgs = {
  input: UpdateGroupSpaceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInviteUserArgs = {
  input: UpdateInviteUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLayoutArgs = {
  input: UpdateLayoutInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLinkByIdArgs = {
  input: UpdateLinkByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLinkIsFavoriteArgs = {
  input: UpdateLinkIsFavoriteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationRuleByIdArgs = {
  input: UpdateNotificationRuleByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationSubscriptionByIdArgs = {
  input: UpdateNotificationSubscriptionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgArgs = {
  input: UpdateOrgInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgBrandingConfigArgs = {
  input: UpdateOrgBrandingConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgConnectionArgs = {
  input: UpdateOrgConnectionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgOnboardingDataArgs = {
  input: UpdateOrgOnboardingDataInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgOverridePlayerLogConfigArgs = {
  input: UpdateOrgOverridePlayerLogConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgOverridePlayerUrlArgs = {
  input: UpdateOrgOverridePlayerUrlInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgPlayerDisplayErrorModeArgs = {
  input: UpdateOrgPlayerDisplayErrorModeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgSettingArgs = {
  input: UpdateOrgSettingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrgSettingsArgs = {
  input: UpdateOrgSettingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePermissionSetArgs = {
  input: UpdatePermissionSetInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistArgs = {
  input: UpdatePlaylistInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistColorArgs = {
  input: UpdatePlaylistColorInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistEmbedArgs = {
  input: UpdatePlaylistEmbedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistEnableArgs = {
  input: UpdatePlaylistEnableInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistIsFavoriteArgs = {
  input: UpdatePlaylistIsFavoriteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistNameArgs = {
  input: UpdatePlaylistNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistShortIdByPlaylistIdArgs = {
  input: UpdatePlaylistShortIdByPlaylistIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlaylistTagsArgs = {
  input: UpdatePlaylistTagsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRelationshipArgs = {
  input: UpdateRelationshipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenByIdArgs = {
  input: UpdateScreenByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenByIdsArgs = {
  input: UpdateScreenByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenDeviceAllowCloneArgs = {
  input: UpdateScreenDeviceAllowCloneInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenDeviceInfoArgs = {
  input: UpdateScreenDeviceInfoInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenDisplayErrorModeArgs = {
  input: UpdateScreenDisplayErrorModeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenGroupByIdArgs = {
  input: UpdateScreenGroupByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenLocationByIdsArgs = {
  input: UpdateScreenLocationByIdsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateScreenNoteByIdArgs = {
  input: UpdateScreenNoteByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSelfArgs = {
  input: UpdateSelfInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSelfScreenDeviceArgs = {
  input: UpdateSelfScreenDeviceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareAppInstanceToAllSpacesArgs = {
  input: UpdateShareAppInstanceToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareAppInstanceToSpacesArgs = {
  input: UpdateShareAppInstanceToSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareChannelToAllSpacesArgs = {
  input: UpdateShareChannelToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareChannelToSpacesArgs = {
  input: UpdateShareChannelToSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareCredentialToAllSpacesArgs = {
  input: UpdateShareCredentialToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareCredentialToSpacesArgs = {
  input: UpdateShareCredentialToSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareFileToAllSpacesArgs = {
  input: UpdateShareFileToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareFileToSpacesArgs = {
  input: UpdateShareFileToSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareFolderToAllSpacesArgs = {
  input: UpdateShareFolderToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareFolderToSpacesArgs = {
  input: UpdateShareFolderToSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareLinkToAllSpacesArgs = {
  input: UpdateShareLinkToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareLinkToSpacesArgs = {
  input: UpdateShareLinkToSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSharePlaylistToAllSpacesArgs = {
  input: UpdateSharePlaylistToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSharePlaylistToSpacesArgs = {
  input: UpdateSharePlaylistToSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareSiteToAllSpacesArgs = {
  input: UpdateShareSiteToAllSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShareSiteToSpacesArgs = {
  input: UpdateShareSiteToSpacesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSiteArgs = {
  input: UpdateSiteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSiteIsFavoriteArgs = {
  input: UpdateSiteIsFavoriteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSpaceArgs = {
  input: UpdateSpaceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateThemeArgs = {
  input: UpdateThemeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTokenArgs = {
  input: UpdateTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserInfoArgs = {
  input: UpdateUserInfoInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserOnboardingDataArgs = {
  input: UpdateUserOnboardingDataInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProviderArgs = {
  input: UpdateUserProviderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationValidateColorCodeArgs = {
  input: ValidateColorCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationValidateLayoutConfigJsonArgs = {
  input: ValidateLayoutConfigJsonInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
};

/** Rule to notify a user for a certain type of object event. */
export type NotificationRule = Node & {
  __typename: "NotificationRule";
  active: Scalars["Boolean"];
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  event: Scalars["String"];
  id: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads and enables pagination through a set of `NotificationSubscription`. */
  notificationSubscriptionsByNotificationRuleId: NotificationSubscriptionsConnection;
  /** Reads a single `Org` that is related to this `NotificationRule`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Screen` that is related to this `NotificationRule`. */
  screenByScreenIdAndOrgId: Maybe<Screen>;
  screenId: Scalars["UUID"];
  /** Reads a single `Space` that is related to this `NotificationRule`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `NotificationRule`. */
  userByCreatedBy: Maybe<User>;
  waitTime: Scalars["Int"];
};

/** Rule to notify a user for a certain type of object event. */
export type NotificationRuleNotificationSubscriptionsByNotificationRuleIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationSubscriptionCondition>;
  filter: InputMaybe<NotificationSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationSubscriptionsOrderBy>>;
};

/**
 * A condition to be used against `NotificationRule` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NotificationRuleCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `event` field. */
  event?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `screenId` field. */
  screenId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `waitTime` field. */
  waitTime?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `NotificationRule` object types. All fields are combined with a logical ‘and.’ */
export type NotificationRuleFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NotificationRuleFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `event` field. */
  event?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NotificationRuleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NotificationRuleFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `screenId` field. */
  screenId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `waitTime` field. */
  waitTime?: InputMaybe<IntFilter>;
};

/** A connection to a list of `NotificationRule` values. */
export type NotificationRulesConnection = {
  __typename: "NotificationRulesConnection";
  /** A list of edges which contains the `NotificationRule` and cursor to aid in pagination. */
  edges: Array<NotificationRulesEdge>;
  /** A list of `NotificationRule` objects. */
  nodes: Array<NotificationRule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NotificationRule` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `NotificationRule` edge in the connection. */
export type NotificationRulesEdge = {
  __typename: "NotificationRulesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `NotificationRule` at the end of the edge. */
  node: NotificationRule;
};

/** Methods to use when ordering `NotificationRule`. */
export enum NotificationRulesOrderBy {
  ActiveAsc = "ACTIVE_ASC",
  ActiveDesc = "ACTIVE_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  EventAsc = "EVENT_ASC",
  EventDesc = "EVENT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ScreenIdAsc = "SCREEN_ID_ASC",
  ScreenIdDesc = "SCREEN_ID_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  WaitTimeAsc = "WAIT_TIME_ASC",
  WaitTimeDesc = "WAIT_TIME_DESC",
}

/** A user to be notified for an event via a specified channel. */
export type NotificationSubscription = Node & {
  __typename: "NotificationSubscription";
  active: Scalars["Boolean"];
  channel: Scalars["String"];
  id: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `NotificationRule` that is related to this `NotificationSubscription`. */
  notificationRuleByNotificationRuleId: Maybe<NotificationRule>;
  notificationRuleId: Scalars["UUID"];
  /** Reads a single `Org` that is related to this `NotificationSubscription`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Space` that is related to this `NotificationSubscription`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `NotificationSubscription`. */
  userByUserIdAndOrgId: Maybe<User>;
  userId: Scalars["UUID"];
};

/**
 * A condition to be used against `NotificationSubscription` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type NotificationSubscriptionCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `channel` field. */
  channel?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `notificationRuleId` field. */
  notificationRuleId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `NotificationSubscription` object types. All fields are combined with a logical ‘and.’ */
export type NotificationSubscriptionFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NotificationSubscriptionFilter>>;
  /** Filter by the object’s `channel` field. */
  channel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NotificationSubscriptionFilter>;
  /** Filter by the object’s `notificationRuleId` field. */
  notificationRuleId?: InputMaybe<UuidFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NotificationSubscriptionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `NotificationSubscription` values. */
export type NotificationSubscriptionsConnection = {
  __typename: "NotificationSubscriptionsConnection";
  /** A list of edges which contains the `NotificationSubscription` and cursor to aid in pagination. */
  edges: Array<NotificationSubscriptionsEdge>;
  /** A list of `NotificationSubscription` objects. */
  nodes: Array<NotificationSubscription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NotificationSubscription` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `NotificationSubscription` edge in the connection. */
export type NotificationSubscriptionsEdge = {
  __typename: "NotificationSubscriptionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `NotificationSubscription` at the end of the edge. */
  node: NotificationSubscription;
};

/** Methods to use when ordering `NotificationSubscription`. */
export enum NotificationSubscriptionsOrderBy {
  ActiveAsc = "ACTIVE_ASC",
  ActiveDesc = "ACTIVE_DESC",
  ChannelAsc = "CHANNEL_ASC",
  ChannelDesc = "CHANNEL_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  NotificationRuleIdAsc = "NOTIFICATION_RULE_ID_ASC",
  NotificationRuleIdDesc = "NOTIFICATION_RULE_ID_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** All input for the `notifyBase` mutation. */
export type NotifyBaseInput = {
  additionalJson?: InputMaybe<Scalars["JSON"]>;
  channel?: InputMaybe<Scalars["String"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  orgId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `notifyBase` mutation. */
export type NotifyBasePayload = {
  __typename: "NotifyBasePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** A organization in Studio. */
export type Org = Node & {
  __typename: "Org";
  /** Reads and enables pagination through a set of `AppInstall`. */
  appInstallsByOrgId: AppInstallsConnection;
  /** Reads and enables pagination through a set of `AppInstance`. */
  appInstancesByOrgId: AppInstancesConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByOrgId: AssociationsConnection;
  /** Reads and enables pagination through a set of `BillingAddonSubscriptionFuture`. */
  billingAddonSubscriptionFuturesByOrgId: BillingAddonSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingAddonSubscription`. */
  billingAddonSubscriptionsByOrgId: BillingAddonSubscriptionsConnection;
  /** Reads and enables pagination through a set of `BillingAddress`. */
  billingAddressesByOrgId: BillingAddressesConnection;
  /** Reads and enables pagination through a set of `BillingCouponSubscriptionFuture`. */
  billingCouponSubscriptionFuturesByOrgId: BillingCouponSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingCouponSubscription`. */
  billingCouponSubscriptionsByOrgId: BillingCouponSubscriptionsConnection;
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Chargebee customer_id (joins studio.billing.id) */
  billingCustomerId: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `BillingCustomer`. */
  billingCustomersByOrgId: BillingCustomersConnection;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  billingDistributorId: Maybe<Scalars["String"]>;
  /** Billing expiration end of the month date from org-wide subscription using last calendar day of expiration month/year specified on credit card */
  billingExpiresAt: Maybe<Scalars["Date"]>;
  /** The cached feature flags from billing side with value equal true. */
  billingFeatureFlagsCache: Maybe<Array<Maybe<Scalars["JSON"]>>>;
  /** Reads and enables pagination through a set of `BillingHierarchy`. */
  billingHierarchiesByOrgId: BillingHierarchiesConnection;
  /** Reads and enables pagination through a set of `BillingPaymentSource`. */
  billingPaymentSourcesByOrgId: BillingPaymentSourcesConnection;
  /** Reads and enables pagination through a set of `BillingRenewalFuture`. */
  billingRenewalFuturesByOrgId: BillingRenewalFuturesConnection;
  /** Reads and enables pagination through a set of `BillingRenewal`. */
  billingRenewalsByOrgId: BillingRenewalsConnection;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  billingResellerId: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `BillingSubscriptionFuture`. */
  billingSubscriptionFuturesByOrgId: BillingSubscriptionFuturesConnection;
  /** Reads and enables pagination through a set of `BillingSubscription`. */
  billingSubscriptionsByOrgId: BillingSubscriptionsConnection;
  /** The blank channel for using as the starting channel. */
  blankChannelId: Maybe<Scalars["UUID"]>;
  /** The organization branding background color. */
  brandBackgroundColor: Scalars["String"];
  /** The background for branding. */
  brandBackgroundId: Maybe<Scalars["UUID"]>;
  /** The brand channel contain branding data for using as a starting channel. */
  brandChannelId: Maybe<Scalars["UUID"]>;
  brandForegroundColor: Scalars["String"];
  /** The branding logo file. */
  brandLogoId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Cast`. */
  castsByOrgId: CastsConnection;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByOrgId: ChannelsConnection;
  /** Reads and enables pagination through a set of `CommonAppInstanceTemplate`. */
  commonAppInstanceTemplatesBySourceOrgId: CommonAppInstanceTemplatesConnection;
  /** Reads and enables pagination through a set of `ContentReplacementJob`. */
  contentReplacementJobsByOrgId: ContentReplacementJobsConnection;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Credential`. */
  credentialsByOrgId: CredentialsConnection;
  /** The default theme for all channels in the organization that haven not specify any theme. */
  defaultChannelThemeId: Maybe<Scalars["UUID"]>;
  /** The default user permissions for all users of the org. */
  defaultUserPermissions: Maybe<Array<Maybe<Scalars["String"]>>>;
  deletedAt: Maybe<Scalars["Datetime"]>;
  deletedBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Embed`. */
  embedsByOrgId: EmbedsConnection;
  /** The key value pairs data for organization. */
  env: Scalars["JSON"];
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByOrgId: FavoritesConnection;
  /** Reads and enables pagination through a set of `FeatureFlagOrg`. */
  featureFlagOrgsByOrgId: FeatureFlagOrgsConnection;
  /** The cached feature flags with value equal true. */
  featureFlagsCache: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  /** Reads and enables pagination through a set of `FileOutput`. */
  fileOutputsByOrgId: FileOutputsConnection;
  /** Reads and enables pagination through a set of `FileProcessingJob`. */
  fileProcessingJobsByOrgId: FileProcessingJobsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByOrgId: FilesConnection;
  /** Reads and enables pagination through a set of `Folder`. */
  foldersByOrgId: FoldersConnection;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByOrgId: FontsConnection;
  /** Reads and enables pagination through a set of `GroupCombination`. */
  groupCombinationsByOrgId: GroupCombinationsConnection;
  /** Reads and enables pagination through a set of `GroupSpace`. */
  groupSpacesByOrgId: GroupSpacesConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByOrgId: GroupsConnection;
  /** The primary unique identifier for the org. (auto gen) */
  id: Scalars["UUID"];
  /** The organization with draft state is not allowed to perform certain actions */
  isDraft: Scalars["Boolean"];
  /** Reads and enables pagination through a set of `Layout`. */
  layoutsByOrgId: LayoutsConnection;
  /** Reads and enables pagination through a set of `Link`. */
  linksByOrgId: LinksConnection;
  /** The organization name (max 160 characters). */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads and enables pagination through a set of `NotificationRule`. */
  notificationRulesByOrgId: NotificationRulesConnection;
  /** Reads and enables pagination through a set of `NotificationSubscription`. */
  notificationSubscriptionsByOrgId: NotificationSubscriptionsConnection;
  /** Reads a single `OrgConnection` that is related to this `Org`. */
  orgConnectionByOrgId: Maybe<OrgConnection>;
  /** Reads and enables pagination through a set of `PermissionSet`. */
  permissionSetsByOrgId: PermissionSetsConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByOrgId: PlaylistsConnection;
  /** The preference which contain settings and onboarding data of the organization. */
  preferences: Maybe<Scalars["JSON"]>;
  /** Reads and enables pagination through a set of `RecycleBin`. */
  recycleBinsByOrgId: RecycleBinsConnection;
  /** Reads and enables pagination through a set of `ScreenConnectionHistory`. */
  screenConnectionHistoriesByOrgId: ScreenConnectionHistoriesConnection;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByOrgId: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ScreenGroup`. */
  screenGroupsByOrgId: ScreenGroupsConnection;
  /** Reads and enables pagination through a set of `ScreenNote`. */
  screenNotesByOrgId: ScreenNotesConnection;
  /** Reads and enables pagination through a set of `Screen`. */
  screensByOrgId: ScreensConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByOrgId: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `Site`. */
  sitesByOrgId: SitesConnection;
  /** The organization URL slug. */
  slug: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `Space`. */
  spacesByOrgId: SpacesConnection;
  /** The theme that used as the splash screen for the organization. */
  splashChannelThemeId: Maybe<Scalars["UUID"]>;
  /** The organization default start channel after pairing a screen. */
  startChannelId: Maybe<Scalars["UUID"]>;
  status: OrgStatus;
  /** Reads and enables pagination through a set of `SystemAccess`. */
  systemAccessesByOrgId: SystemAccessesConnection;
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByOrgId: TagAssociationsConnection;
  /** Reads and enables pagination through a set of `Tag`. */
  tagsByOrgId: TagsConnection;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByOrgId: ThemesConnection;
  /** Reads and enables pagination through a set of `Token`. */
  tokensByOrgId: TokensConnection;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `UserActionLog`. */
  userActionLogsByOrgId: UserActionLogsConnection;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads and enables pagination through a set of `UserGroup`. */
  userGroupsByOrgId: UserGroupsConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByOrgId: UsersConnection;
  /** Reads and enables pagination through a set of `WhiteLabel`. */
  whiteLabelsByOrgId: WhiteLabelsConnection;
};

/** A organization in Studio. */
export type OrgAppInstallsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstallCondition>;
  filter: InputMaybe<AppInstallFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** A organization in Studio. */
export type OrgAppInstancesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** A organization in Studio. */
export type OrgAssociationsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingAddonSubscriptionFuturesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionFutureCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionFuturesOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingAddonSubscriptionsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionsOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingAddressesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddressCondition>;
  filter: InputMaybe<BillingAddressFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddressesOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingCouponSubscriptionFuturesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionFutureCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionFuturesOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingCouponSubscriptionsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionsOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingCustomersByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCustomerCondition>;
  filter: InputMaybe<BillingCustomerFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCustomersOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingHierarchiesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingHierarchyCondition>;
  filter: InputMaybe<BillingHierarchyFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingHierarchiesOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingPaymentSourcesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingPaymentSourceCondition>;
  filter: InputMaybe<BillingPaymentSourceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingPaymentSourcesOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingRenewalFuturesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingRenewalFutureCondition>;
  filter: InputMaybe<BillingRenewalFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingRenewalFuturesOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingRenewalsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingRenewalCondition>;
  filter: InputMaybe<BillingRenewalFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingRenewalsOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingSubscriptionFuturesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingSubscriptionFutureCondition>;
  filter: InputMaybe<BillingSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingSubscriptionFuturesOrderBy>>;
};

/** A organization in Studio. */
export type OrgBillingSubscriptionsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingSubscriptionCondition>;
  filter: InputMaybe<BillingSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingSubscriptionsOrderBy>>;
};

/** A organization in Studio. */
export type OrgCastsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CastCondition>;
  filter: InputMaybe<CastFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CastsOrderBy>>;
};

/** A organization in Studio. */
export type OrgChannelsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** A organization in Studio. */
export type OrgCommonAppInstanceTemplatesBySourceOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonAppInstanceTemplateCondition>;
  filter: InputMaybe<CommonAppInstanceTemplateFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonAppInstanceTemplatesOrderBy>>;
};

/** A organization in Studio. */
export type OrgContentReplacementJobsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ContentReplacementJobCondition>;
  filter: InputMaybe<ContentReplacementJobFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContentReplacementJobsOrderBy>>;
};

/** A organization in Studio. */
export type OrgCredentialsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CredentialCondition>;
  filter: InputMaybe<CredentialFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

/** A organization in Studio. */
export type OrgEmbedsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<EmbedCondition>;
  filter: InputMaybe<EmbedFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EmbedsOrderBy>>;
};

/** A organization in Studio. */
export type OrgFavoritesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** A organization in Studio. */
export type OrgFeatureFlagOrgsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FeatureFlagOrgCondition>;
  filter: InputMaybe<FeatureFlagOrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FeatureFlagOrgsOrderBy>>;
};

/** A organization in Studio. */
export type OrgFileOutputsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileOutputCondition>;
  filter: InputMaybe<FileOutputFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileOutputsOrderBy>>;
};

/** A organization in Studio. */
export type OrgFileProcessingJobsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileProcessingJobCondition>;
  filter: InputMaybe<FileProcessingJobFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileProcessingJobsOrderBy>>;
};

/** A organization in Studio. */
export type OrgFilesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A organization in Studio. */
export type OrgFoldersByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FolderCondition>;
  filter: InputMaybe<FolderFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** A organization in Studio. */
export type OrgFontsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** A organization in Studio. */
export type OrgGroupCombinationsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupCombinationCondition>;
  filter: InputMaybe<GroupCombinationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupCombinationsOrderBy>>;
};

/** A organization in Studio. */
export type OrgGroupSpacesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupSpaceCondition>;
  filter: InputMaybe<GroupSpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

/** A organization in Studio. */
export type OrgGroupsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupCondition>;
  filter: InputMaybe<GroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** A organization in Studio. */
export type OrgLayoutsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LayoutCondition>;
  filter: InputMaybe<LayoutFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LayoutsOrderBy>>;
};

/** A organization in Studio. */
export type OrgLinksByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LinkCondition>;
  filter: InputMaybe<LinkFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** A organization in Studio. */
export type OrgNotificationRulesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationRuleCondition>;
  filter: InputMaybe<NotificationRuleFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** A organization in Studio. */
export type OrgNotificationSubscriptionsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationSubscriptionCondition>;
  filter: InputMaybe<NotificationSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationSubscriptionsOrderBy>>;
};

/** A organization in Studio. */
export type OrgPermissionSetsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PermissionSetCondition>;
  filter: InputMaybe<PermissionSetFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionSetsOrderBy>>;
};

/** A organization in Studio. */
export type OrgPlaylistsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** A organization in Studio. */
export type OrgRecycleBinsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<RecycleBinCondition>;
  filter: InputMaybe<RecycleBinFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<RecycleBinsOrderBy>>;
};

/** A organization in Studio. */
export type OrgScreenConnectionHistoriesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenConnectionHistoryCondition>;
  filter: InputMaybe<ScreenConnectionHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenConnectionHistoriesOrderBy>>;
};

/** A organization in Studio. */
export type OrgScreenContentHistoriesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** A organization in Studio. */
export type OrgScreenGroupsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenGroupCondition>;
  filter: InputMaybe<ScreenGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenGroupsOrderBy>>;
};

/** A organization in Studio. */
export type OrgScreenNotesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenNoteCondition>;
  filter: InputMaybe<ScreenNoteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** A organization in Studio. */
export type OrgScreensByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** A organization in Studio. */
export type OrgShareAssociationsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** A organization in Studio. */
export type OrgSitesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

/** A organization in Studio. */
export type OrgSpacesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SpaceCondition>;
  filter: InputMaybe<SpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

/** A organization in Studio. */
export type OrgSystemAccessesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SystemAccessCondition>;
  filter: InputMaybe<SystemAccessFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SystemAccessesOrderBy>>;
};

/** A organization in Studio. */
export type OrgTagAssociationsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** A organization in Studio. */
export type OrgTagsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagCondition>;
  filter: InputMaybe<TagFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** A organization in Studio. */
export type OrgThemesByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** A organization in Studio. */
export type OrgTokensByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TokenCondition>;
  filter: InputMaybe<TokenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** A organization in Studio. */
export type OrgUserActionLogsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserActionLogCondition>;
  filter: InputMaybe<UserActionLogFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserActionLogsOrderBy>>;
};

/** A organization in Studio. */
export type OrgUserGroupsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserGroupCondition>;
  filter: InputMaybe<UserGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
};

/** A organization in Studio. */
export type OrgUsersByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A organization in Studio. */
export type OrgWhiteLabelsByOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<WhiteLabelCondition>;
  filter: InputMaybe<WhiteLabelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

/** A condition to be used against `Org` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type OrgCondition = {
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingDistributorId` field. */
  billingDistributorId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingExpiresAt` field. */
  billingExpiresAt?: InputMaybe<Scalars["Date"]>;
  /** Checks for equality with the object’s `billingFeatureFlagsCache` field. */
  billingFeatureFlagsCache?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Checks for equality with the object’s `billingResellerId` field. */
  billingResellerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `blankChannelId` field. */
  blankChannelId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `brandBackgroundColor` field. */
  brandBackgroundColor?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `brandBackgroundId` field. */
  brandBackgroundId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `brandChannelId` field. */
  brandChannelId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `brandForegroundColor` field. */
  brandForegroundColor?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `brandLogoId` field. */
  brandLogoId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `defaultChannelThemeId` field. */
  defaultChannelThemeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `defaultUserPermissions` field. */
  defaultUserPermissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `env` field. */
  env?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `featureFlagsCache` field. */
  featureFlagsCache?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isDraft` field. */
  isDraft?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `preferences` field. */
  preferences?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `splashChannelThemeId` field. */
  splashChannelThemeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `startChannelId` field. */
  startChannelId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<OrgStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

export type OrgConnection = Node & {
  __typename: "OrgConnection";
  automaticEnroll: Maybe<Scalars["Boolean"]>;
  connection: Scalars["String"];
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  idpDomains: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `OrgConnection`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  provider: Scalars["String"];
  required: Maybe<Scalars["Boolean"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `OrgConnection`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `OrgConnection`. */
  userByUpdatedBy: Maybe<User>;
};

/**
 * A condition to be used against `OrgConnection` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrgConnectionCondition = {
  /** Checks for equality with the object’s `automaticEnroll` field. */
  automaticEnroll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `connection` field. */
  connection?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `idpDomains` field. */
  idpDomains?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `required` field. */
  required?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `OrgConnection` object types. All fields are combined with a logical ‘and.’ */
export type OrgConnectionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrgConnectionFilter>>;
  /** Filter by the object’s `automaticEnroll` field. */
  automaticEnroll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `connection` field. */
  connection?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `idpDomains` field. */
  idpDomains?: InputMaybe<StringListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrgConnectionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrgConnectionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `required` field. */
  required?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `OrgConnection` values. */
export type OrgConnectionsConnection = {
  __typename: "OrgConnectionsConnection";
  /** A list of edges which contains the `OrgConnection` and cursor to aid in pagination. */
  edges: Array<OrgConnectionsEdge>;
  /** A list of `OrgConnection` objects. */
  nodes: Array<OrgConnection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrgConnection` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `OrgConnection` edge in the connection. */
export type OrgConnectionsEdge = {
  __typename: "OrgConnectionsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `OrgConnection` at the end of the edge. */
  node: OrgConnection;
};

/** Methods to use when ordering `OrgConnection`. */
export enum OrgConnectionsOrderBy {
  AutomaticEnrollAsc = "AUTOMATIC_ENROLL_ASC",
  AutomaticEnrollDesc = "AUTOMATIC_ENROLL_DESC",
  ConnectionAsc = "CONNECTION_ASC",
  ConnectionDesc = "CONNECTION_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  IdpDomainsAsc = "IDP_DOMAINS_ASC",
  IdpDomainsDesc = "IDP_DOMAINS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProviderAsc = "PROVIDER_ASC",
  ProviderDesc = "PROVIDER_DESC",
  RequiredAsc = "REQUIRED_ASC",
  RequiredDesc = "REQUIRED_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

/** A filter to be used against `Org` object types. All fields are combined with a logical ‘and.’ */
export type OrgFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrgFilter>>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingDistributorId` field. */
  billingDistributorId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingExpiresAt` field. */
  billingExpiresAt?: InputMaybe<DateFilter>;
  /** Filter by the object’s `billingFeatureFlagsCache` field. */
  billingFeatureFlagsCache?: InputMaybe<JsonListFilter>;
  /** Filter by the object’s `billingResellerId` field. */
  billingResellerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `blankChannelId` field. */
  blankChannelId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `brandBackgroundColor` field. */
  brandBackgroundColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `brandBackgroundId` field. */
  brandBackgroundId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `brandChannelId` field. */
  brandChannelId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `brandForegroundColor` field. */
  brandForegroundColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `brandLogoId` field. */
  brandLogoId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `defaultChannelThemeId` field. */
  defaultChannelThemeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `defaultUserPermissions` field. */
  defaultUserPermissions?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `env` field. */
  env?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `featureFlagsCache` field. */
  featureFlagsCache?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDraft` field. */
  isDraft?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrgFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrgFilter>>;
  /** Filter by the object’s `preferences` field. */
  preferences?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `splashChannelThemeId` field. */
  splashChannelThemeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `startChannelId` field. */
  startChannelId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<OrgStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

export enum OrgStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Disabled = "DISABLED",
  Pending = "PENDING",
}

/** A filter to be used against OrgStatus fields. All fields are combined with a logical ‘and.’ */
export type OrgStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<OrgStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<OrgStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<OrgStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<OrgStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<OrgStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<OrgStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<OrgStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<OrgStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<OrgStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<OrgStatus>>;
};

/** A connection to a list of `Org` values. */
export type OrgsConnection = {
  __typename: "OrgsConnection";
  /** A list of edges which contains the `Org` and cursor to aid in pagination. */
  edges: Array<OrgsEdge>;
  /** A list of `Org` objects. */
  nodes: Array<Org>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Org` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Org` edge in the connection. */
export type OrgsEdge = {
  __typename: "OrgsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Org` at the end of the edge. */
  node: Org;
};

/** Methods to use when ordering `Org`. */
export enum OrgsOrderBy {
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingDistributorIdAsc = "BILLING_DISTRIBUTOR_ID_ASC",
  BillingDistributorIdDesc = "BILLING_DISTRIBUTOR_ID_DESC",
  BillingExpiresAtAsc = "BILLING_EXPIRES_AT_ASC",
  BillingExpiresAtDesc = "BILLING_EXPIRES_AT_DESC",
  BillingFeatureFlagsCacheAsc = "BILLING_FEATURE_FLAGS_CACHE_ASC",
  BillingFeatureFlagsCacheDesc = "BILLING_FEATURE_FLAGS_CACHE_DESC",
  BillingResellerIdAsc = "BILLING_RESELLER_ID_ASC",
  BillingResellerIdDesc = "BILLING_RESELLER_ID_DESC",
  BlankChannelIdAsc = "BLANK_CHANNEL_ID_ASC",
  BlankChannelIdDesc = "BLANK_CHANNEL_ID_DESC",
  BrandBackgroundColorAsc = "BRAND_BACKGROUND_COLOR_ASC",
  BrandBackgroundColorDesc = "BRAND_BACKGROUND_COLOR_DESC",
  BrandBackgroundIdAsc = "BRAND_BACKGROUND_ID_ASC",
  BrandBackgroundIdDesc = "BRAND_BACKGROUND_ID_DESC",
  BrandChannelIdAsc = "BRAND_CHANNEL_ID_ASC",
  BrandChannelIdDesc = "BRAND_CHANNEL_ID_DESC",
  BrandForegroundColorAsc = "BRAND_FOREGROUND_COLOR_ASC",
  BrandForegroundColorDesc = "BRAND_FOREGROUND_COLOR_DESC",
  BrandLogoIdAsc = "BRAND_LOGO_ID_ASC",
  BrandLogoIdDesc = "BRAND_LOGO_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DefaultChannelThemeIdAsc = "DEFAULT_CHANNEL_THEME_ID_ASC",
  DefaultChannelThemeIdDesc = "DEFAULT_CHANNEL_THEME_ID_DESC",
  DefaultUserPermissionsAsc = "DEFAULT_USER_PERMISSIONS_ASC",
  DefaultUserPermissionsDesc = "DEFAULT_USER_PERMISSIONS_DESC",
  DeletedAtAsc = "DELETED_AT_ASC",
  DeletedAtDesc = "DELETED_AT_DESC",
  DeletedByAsc = "DELETED_BY_ASC",
  DeletedByDesc = "DELETED_BY_DESC",
  EnvAsc = "ENV_ASC",
  EnvDesc = "ENV_DESC",
  FeatureFlagsCacheAsc = "FEATURE_FLAGS_CACHE_ASC",
  FeatureFlagsCacheDesc = "FEATURE_FLAGS_CACHE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsDraftAsc = "IS_DRAFT_ASC",
  IsDraftDesc = "IS_DRAFT_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  PreferencesAsc = "PREFERENCES_ASC",
  PreferencesDesc = "PREFERENCES_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SlugAsc = "SLUG_ASC",
  SlugDesc = "SLUG_DESC",
  SplashChannelThemeIdAsc = "SPLASH_CHANNEL_THEME_ID_ASC",
  SplashChannelThemeIdDesc = "SPLASH_CHANNEL_THEME_ID_DESC",
  StartChannelIdAsc = "START_CHANNEL_ID_ASC",
  StartChannelIdDesc = "START_CHANNEL_ID_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars["Cursor"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars["Cursor"]>;
};

/** All input for the `createScreen` mutation. */
export type PairScreenInput = {
  pairing: Scalars["JSON"];
  preview?: InputMaybe<Scalars["Boolean"]>;
  screenGroupId?: InputMaybe<Scalars["UUID"]>;
  spaceId: Scalars["UUID"];
};

/** The output of our `pairScreen` mutation. */
export type PairScreenPayload = {
  __typename: "PairScreenPayload";
  query: Maybe<Query>;
  /** The newly created screen */
  screen: Maybe<Screen>;
};

export type ParentChannelByChildOf = {
  __typename: "ParentChannelByChildOf";
  content: Maybe<Scalars["JSON"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  orgId: Maybe<Scalars["UUID"]>;
  spaceId: Maybe<Scalars["UUID"]>;
  spaceName: Maybe<Scalars["String"]>;
};

export type PaypalAuthorizeInput = {
  cancelPath: Scalars["String"];
  currencyCode: Scalars["String"];
  returnPath: Scalars["String"];
  spaceId?: InputMaybe<Scalars["String"]>;
};

export type PaypalAuthorizePayload = {
  __typename: "PaypalAuthorizePayload";
  acknowledgement: Maybe<Scalars["String"]>;
  query: Maybe<Query>;
  redirectUrl: Maybe<Scalars["String"]>;
  token: Maybe<Scalars["String"]>;
};

/** All input for the `permanentlyDeleteAllItems` mutation. */
export type PermanentlyDeleteAllItemsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `permanentlyDeleteAllItems` mutation. */
export type PermanentlyDeleteAllItemsPayload = {
  __typename: "PermanentlyDeleteAllItemsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  recycleBins: Maybe<Array<Maybe<RecycleBin>>>;
};

/** All input for the `permanentlyDeleteByIdsAndSpaceId` mutation. */
export type PermanentlyDeleteByIdsAndSpaceIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `permanentlyDeleteByIdsAndSpaceId` mutation. */
export type PermanentlyDeleteByIdsAndSpaceIdPayload = {
  __typename: "PermanentlyDeleteByIdsAndSpaceIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  recycleBins: Maybe<Array<Maybe<RecycleBin>>>;
};

export type PermissionSet = {
  __typename: "PermissionSet";
  color: Maybe<Scalars["String"]>;
  /** Reads a single `CommonPermissionSet` that is related to this `PermissionSet`. */
  commonPermissionSetByCommonPermissionSetId: Maybe<CommonPermissionSet>;
  commonPermissionSetId: Maybe<Scalars["UUID"]>;
  description: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Scalars["String"];
  /** Reads a single `Org` that is related to this `PermissionSet`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  permissions: Array<Maybe<Scalars["String"]>>;
};

/**
 * A condition to be used against `PermissionSet` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PermissionSetCondition = {
  /** Checks for equality with the object’s `color` field. */
  color?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `commonPermissionSetId` field. */
  commonPermissionSetId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `permissions` field. */
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** A filter to be used against `PermissionSet` object types. All fields are combined with a logical ‘and.’ */
export type PermissionSetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PermissionSetFilter>>;
  /** Filter by the object’s `color` field. */
  color?: InputMaybe<StringFilter>;
  /** Filter by the object’s `commonPermissionSetId` field. */
  commonPermissionSetId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PermissionSetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PermissionSetFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `permissions` field. */
  permissions?: InputMaybe<StringListFilter>;
};

/** A connection to a list of `PermissionSet` values. */
export type PermissionSetsConnection = {
  __typename: "PermissionSetsConnection";
  /** A list of edges which contains the `PermissionSet` and cursor to aid in pagination. */
  edges: Array<PermissionSetsEdge>;
  /** A list of `PermissionSet` objects. */
  nodes: Array<PermissionSet>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PermissionSet` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `PermissionSet` edge in the connection. */
export type PermissionSetsEdge = {
  __typename: "PermissionSetsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `PermissionSet` at the end of the edge. */
  node: PermissionSet;
};

/** Methods to use when ordering `PermissionSet`. */
export enum PermissionSetsOrderBy {
  ColorAsc = "COLOR_ASC",
  ColorDesc = "COLOR_DESC",
  CommonPermissionSetIdAsc = "COMMON_PERMISSION_SET_ID_ASC",
  CommonPermissionSetIdDesc = "COMMON_PERMISSION_SET_ID_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PermissionsAsc = "PERMISSIONS_ASC",
  PermissionsDesc = "PERMISSIONS_DESC",
}

/** The playlist information. */
export type Playlist = Node & {
  __typename: "Playlist";
  /** Get apps that contained in a playlist. */
  appInstancesByPlaylistId: AppInstancesConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByFromPlaylistAndOrgId: AssociationsConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByToPlaylistAndOrgId: AssociationsConnection;
  /** Get casted screen(s) by playlist's id. */
  castedScreenByPlaylistId: ScreensConnection;
  /** The color of the playlist. */
  color: Scalars["String"];
  /** The information of the content structure in the playlist. */
  content: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Helper function for return draft version of a playlist. */
  draft: Maybe<Playlist>;
  /** The uuid of the published playlist of this playlist. */
  draftOf: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Embed`. */
  embedsByPlaylistId: EmbedsConnection;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByPlaylistId: FavoritesConnection;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByPlaylistIdAndOrgId: FavoritesConnection;
  /** Get files that contained in a playlist. */
  filesByPlaylistId: FilesConnection;
  /** The uuid of the playlist. */
  id: Scalars["UUID"];
  /** Indicate embed is enabled */
  isEmbedEnabled: Maybe<Scalars["Boolean"]>;
  /** Indicate embed is public */
  isEmbedPublic: Maybe<Scalars["Boolean"]>;
  isEnabled: Maybe<Scalars["Boolean"]>;
  /** Return favorite status of a playlist. */
  isFavorite: Maybe<Scalars["Boolean"]>;
  isPublished: Maybe<Scalars["Boolean"]>;
  isShared: Maybe<Scalars["Boolean"]>;
  isSharedAll: Maybe<Scalars["Boolean"]>;
  isSharedExplicit: Maybe<Scalars["Boolean"]>;
  /** Get files that contained in a playlist. */
  linksByPlaylistId: LinksConnection;
  /** The name of the playlist. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization that playlist belong to. */
  orgId: Scalars["UUID"];
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByDraftOf: PlaylistsConnection;
  /** Get playlists that contained in a playlist. */
  playlistsByPlaylistId: PlaylistsConnection;
  /** Helper function for return published version of a playlist. */
  published: Maybe<Playlist>;
  publishedAt: Maybe<Scalars["Datetime"]>;
  publishedBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByPlaylistIdAndOrgId: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsBySharePlaylist: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsBySharePlaylistAndOrgId: ShareAssociationsConnection;
  /** Spaces that currently got shared access to the playlist. */
  sharedSpacesByPlaylistId: SpacesConnection;
  /** shortId for embeding */
  shortId: Maybe<Scalars["String"]>;
  /** Get Sites that are contained in a playlist. */
  sitesByPlaylistId: SitesConnection;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** The uuid of the space that playlist belong to. */
  spaceId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByToPlaylistAndOrgId: TagAssociationsConnection;
  tags: Array<Maybe<Scalars["String"]>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The playlist information. */
export type PlaylistAppInstancesByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The playlist information. */
export type PlaylistAssociationsByFromPlaylistAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The playlist information. */
export type PlaylistAssociationsByToPlaylistAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The playlist information. */
export type PlaylistCastedScreenByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The playlist information. */
export type PlaylistEmbedsByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<EmbedCondition>;
  filter: InputMaybe<EmbedFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EmbedsOrderBy>>;
};

/** The playlist information. */
export type PlaylistFavoritesByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The playlist information. */
export type PlaylistFavoritesByPlaylistIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The playlist information. */
export type PlaylistFilesByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The playlist information. */
export type PlaylistLinksByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The playlist information. */
export type PlaylistPlaylistsByDraftOfArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** The playlist information. */
export type PlaylistPlaylistsByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The playlist information. */
export type PlaylistScreenContentHistoriesByPlaylistIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** The playlist information. */
export type PlaylistShareAssociationsBySharePlaylistArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The playlist information. */
export type PlaylistShareAssociationsBySharePlaylistAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The playlist information. */
export type PlaylistSharedSpacesByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The playlist information. */
export type PlaylistSitesByPlaylistIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The playlist information. */
export type PlaylistTagAssociationsByToPlaylistAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/**
 * A condition to be used against `Playlist` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PlaylistCondition = {
  /** Checks for equality with the object’s `color` field. */
  color?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `draftOf` field. */
  draftOf?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isEmbedEnabled` field. */
  isEmbedEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isEmbedPublic` field. */
  isEmbedPublic?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isEnabled` field. */
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isPublished` field. */
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isShared` field. */
  isShared?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `publishedBy` field. */
  publishedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Playlist` object types. All fields are combined with a logical ‘and.’ */
export type PlaylistFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PlaylistFilter>>;
  /** Filter by the object’s `color` field. */
  color?: InputMaybe<StringFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `draftOf` field. */
  draftOf?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isEmbedEnabled` field. */
  isEmbedEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isEmbedPublic` field. */
  isEmbedPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isEnabled` field. */
  isEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPublished` field. */
  isPublished?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PlaylistFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PlaylistFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `publishedAt` field. */
  publishedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `publishedBy` field. */
  publishedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Playlist` values. */
export type PlaylistsConnection = {
  __typename: "PlaylistsConnection";
  /** A list of edges which contains the `Playlist` and cursor to aid in pagination. */
  edges: Array<PlaylistsEdge>;
  /** A list of `Playlist` objects. */
  nodes: Array<Playlist>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Playlist` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Playlist` edge in the connection. */
export type PlaylistsEdge = {
  __typename: "PlaylistsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Playlist` at the end of the edge. */
  node: Playlist;
};

/** Methods to use when ordering `Playlist`. */
export enum PlaylistsOrderBy {
  ColorAsc = "COLOR_ASC",
  ColorDesc = "COLOR_DESC",
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DraftOfAsc = "DRAFT_OF_ASC",
  DraftOfDesc = "DRAFT_OF_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsEmbedEnabledAsc = "IS_EMBED_ENABLED_ASC",
  IsEmbedEnabledDesc = "IS_EMBED_ENABLED_DESC",
  IsEmbedPublicAsc = "IS_EMBED_PUBLIC_ASC",
  IsEmbedPublicDesc = "IS_EMBED_PUBLIC_DESC",
  IsEnabledAsc = "IS_ENABLED_ASC",
  IsEnabledDesc = "IS_ENABLED_DESC",
  IsPublishedAsc = "IS_PUBLISHED_ASC",
  IsPublishedDesc = "IS_PUBLISHED_DESC",
  IsSharedAllAsc = "IS_SHARED_ALL_ASC",
  IsSharedAllDesc = "IS_SHARED_ALL_DESC",
  IsSharedAsc = "IS_SHARED_ASC",
  IsSharedDesc = "IS_SHARED_DESC",
  IsSharedExplicitAsc = "IS_SHARED_EXPLICIT_ASC",
  IsSharedExplicitDesc = "IS_SHARED_EXPLICIT_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  PublishedAtAsc = "PUBLISHED_AT_ASC",
  PublishedAtDesc = "PUBLISHED_AT_DESC",
  PublishedByAsc = "PUBLISHED_BY_ASC",
  PublishedByDesc = "PUBLISHED_BY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

export type PublicOrg = {
  __typename: "PublicOrg";
  brandBackgroundColor: Maybe<Scalars["String"]>;
  brandBackgroundFileKey: Maybe<Scalars["String"]>;
  brandForegroundColor: Maybe<Scalars["String"]>;
  brandLogoFileKey: Maybe<Scalars["String"]>;
  connection: Maybe<Scalars["String"]>;
  connectionRequired: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  provider: Maybe<Scalars["String"]>;
  slug: Maybe<Scalars["String"]>;
};

export type PublicOrgByPendingUserType = {
  __typename: "PublicOrgByPendingUserType";
  brandBackgroundColor: Maybe<Scalars["String"]>;
  brandBackgroundFileKey: Maybe<Scalars["String"]>;
  brandForegroundColor: Maybe<Scalars["String"]>;
  brandLogoFileKey: Maybe<Scalars["String"]>;
  connection: Maybe<Scalars["String"]>;
  connectionRequired: Maybe<Scalars["Boolean"]>;
  id: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  preferences: Maybe<Scalars["String"]>;
  provider: Maybe<Scalars["String"]>;
  slug: Maybe<Scalars["String"]>;
};

/** All input for the `publishDraftChannel` mutation. */
export type PublishDraftChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `publishDraftChannel` mutation. */
export type PublishDraftChannelPayload = {
  __typename: "PublishDraftChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `publishDraftChannel` mutation. */
export type PublishDraftChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `publishDraftPlaylist` mutation. */
export type PublishDraftPlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `publishDraftPlaylist` mutation. */
export type PublishDraftPlaylistPayload = {
  __typename: "PublishDraftPlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `publishDraftPlaylist` mutation. */
export type PublishDraftPlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `publishTheme` mutation. */
export type PublishThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `publishTheme` mutation. */
export type PublishThemePayload = {
  __typename: "PublishThemePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileIdDraft: Maybe<File>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontIdDraft: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontIdDraft: Maybe<Font>;
  /** Reads a single `Org` that is related to this `Theme`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  theme: Maybe<Theme>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge: Maybe<ThemesEdge>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `publishTheme` mutation. */
export type PublishThemePayloadThemeEdgeArgs = {
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** All input for the `pullEventBuffers` mutation. */
export type PullEventBuffersInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  rows?: InputMaybe<Scalars["Int"]>;
};

/** The output of our `pullEventBuffers` mutation. */
export type PullEventBuffersPayload = {
  __typename: "PullEventBuffersPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  strings: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename: "Query";
  /** Get list of app's categories. */
  allAppCategories: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Reads and enables pagination through a set of `AppInstall`. */
  allAppInstalls: Maybe<AppInstallsConnection>;
  /** Reads and enables pagination through a set of `AppInstance`. */
  allAppInstances: Maybe<AppInstancesConnection>;
  /** Reads and enables pagination through a set of `AppVersion`. */
  allAppVersions: Maybe<AppVersionsConnection>;
  /** Reads and enables pagination through a set of `App`. */
  allApps: Maybe<AppsConnection>;
  /** Reads and enables pagination through a set of `Association`. */
  allAssociations: Maybe<AssociationsConnection>;
  /** Reads and enables pagination through a set of `BillingAddonPlan`. */
  allBillingAddonPlans: Maybe<BillingAddonPlansConnection>;
  /** Reads and enables pagination through a set of `BillingAddonSubscriptionFuture`. */
  allBillingAddonSubscriptionFutures: Maybe<BillingAddonSubscriptionFuturesConnection>;
  /** Reads and enables pagination through a set of `BillingAddonSubscription`. */
  allBillingAddonSubscriptions: Maybe<BillingAddonSubscriptionsConnection>;
  /** Reads and enables pagination through a set of `BillingAddon`. */
  allBillingAddons: Maybe<BillingAddonsConnection>;
  /** Reads and enables pagination through a set of `BillingAddress`. */
  allBillingAddresses: Maybe<BillingAddressesConnection>;
  /** Reads and enables pagination through a set of `BillingCouponSubscriptionFuture`. */
  allBillingCouponSubscriptionFutures: Maybe<BillingCouponSubscriptionFuturesConnection>;
  /** Reads and enables pagination through a set of `BillingCouponSubscription`. */
  allBillingCouponSubscriptions: Maybe<BillingCouponSubscriptionsConnection>;
  /** Reads and enables pagination through a set of `BillingCoupon`. */
  allBillingCoupons: Maybe<BillingCouponsConnection>;
  /** Reads and enables pagination through a set of `BillingCustomer`. */
  allBillingCustomers: Maybe<BillingCustomersConnection>;
  /** Reads and enables pagination through a set of `BillingDistributor`. */
  allBillingDistributors: Maybe<BillingDistributorsConnection>;
  /** Reads and enables pagination through a set of `BillingHierarchy`. */
  allBillingHierarchies: Maybe<BillingHierarchiesConnection>;
  /** Reads and enables pagination through a set of `BillingInvoice`. */
  allBillingInvoices: Maybe<BillingInvoicesConnection>;
  /** Reads and enables pagination through a set of `BillingPaymentSource`. */
  allBillingPaymentSources: Maybe<BillingPaymentSourcesConnection>;
  /** Reads and enables pagination through a set of `BillingPlan`. */
  allBillingPlans: Maybe<BillingPlansConnection>;
  /** Reads and enables pagination through a set of `BillingRenewalFuture`. */
  allBillingRenewalFutures: Maybe<BillingRenewalFuturesConnection>;
  /** Reads and enables pagination through a set of `BillingRenewal`. */
  allBillingRenewals: Maybe<BillingRenewalsConnection>;
  /** Reads and enables pagination through a set of `BillingReseller`. */
  allBillingResellers: Maybe<BillingResellersConnection>;
  /** Reads and enables pagination through a set of `BillingSubscriptionFuture`. */
  allBillingSubscriptionFutures: Maybe<BillingSubscriptionFuturesConnection>;
  /** Reads and enables pagination through a set of `BillingSubscription`. */
  allBillingSubscriptions: Maybe<BillingSubscriptionsConnection>;
  /** Reads and enables pagination through a set of `Cast`. */
  allCasts: Maybe<CastsConnection>;
  /** Reads and enables pagination through a set of `Channel`. */
  allChannels: Maybe<ChannelsConnection>;
  /** Get all child folders from all root folders. */
  allChildFoldersFromAllRootFolders: Maybe<FoldersConnection>;
  /** Reads and enables pagination through a set of `CommonAppInstanceTemplate`. */
  allCommonAppInstanceTemplates: Maybe<CommonAppInstanceTemplatesConnection>;
  /** Reads and enables pagination through a set of `CommonFont`. */
  allCommonFonts: Maybe<CommonFontsConnection>;
  /** Reads and enables pagination through a set of `CommonLayout`. */
  allCommonLayouts: Maybe<CommonLayoutsConnection>;
  /** Reads and enables pagination through a set of `CommonPermissionSet`. */
  allCommonPermissionSets: Maybe<CommonPermissionSetsConnection>;
  /** Reads and enables pagination through a set of `ContentReplacementJob`. */
  allContentReplacementJobs: Maybe<ContentReplacementJobsConnection>;
  /** Reads and enables pagination through a set of `Credential`. */
  allCredentials: Maybe<CredentialsConnection>;
  /** Get embed view logs */
  allEmbedViewLogs: Maybe<EmbedViewLogsConnection>;
  /** Get embed view logs by content type and content id */
  allEmbedViewLogsByContentTypeAndContentId: Maybe<EmbedViewLogsConnection>;
  /** Reads and enables pagination through a set of `Embed`. */
  allEmbeds: Maybe<EmbedsConnection>;
  /** Reads and enables pagination through a set of `EventBuffer`. */
  allEventBuffers: Maybe<EventBuffersConnection>;
  /** Reads and enables pagination through a set of `Favorite`. */
  allFavorites: Maybe<FavoritesConnection>;
  /** Reads and enables pagination through a set of `FeatureFlagOrg`. */
  allFeatureFlagOrgs: Maybe<FeatureFlagOrgsConnection>;
  /** Reads and enables pagination through a set of `FeatureFlag`. */
  allFeatureFlags: Maybe<FeatureFlagsConnection>;
  /** Reads and enables pagination through a set of `FileOutput`. */
  allFileOutputs: Maybe<FileOutputsConnection>;
  /** Reads and enables pagination through a set of `FileProcessingJob`. */
  allFileProcessingJobs: Maybe<FileProcessingJobsConnection>;
  /** Reads and enables pagination through a set of `FileToDelete`. */
  allFileToDeletes: Maybe<FileToDeletesConnection>;
  /** Reads and enables pagination through a set of `File`. */
  allFiles: Maybe<FilesConnection>;
  /** Get all files from all root folders. */
  allFilesFromAllRootFolders: Maybe<FilesConnection>;
  /** Reads and enables pagination through a set of `Folder`. */
  allFolders: Maybe<FoldersConnection>;
  /** Reads and enables pagination through a set of `Font`. */
  allFonts: Maybe<FontsConnection>;
  /** Reads and enables pagination through a set of `GroupCombination`. */
  allGroupCombinations: Maybe<GroupCombinationsConnection>;
  /** Reads and enables pagination through a set of `GroupSpace`. */
  allGroupSpaces: Maybe<GroupSpacesConnection>;
  /** Reads and enables pagination through a set of `Group`. */
  allGroups: Maybe<GroupsConnection>;
  allIdentityProviders: Maybe<Array<IdentityProvider>>;
  /** Reads and enables pagination through a set of `Layout`. */
  allLayouts: Maybe<LayoutsConnection>;
  /** Reads and enables pagination through a set of `LegacyLog`. */
  allLegacyLogs: Maybe<LegacyLogsConnection>;
  /** Reads and enables pagination through a set of `Link`. */
  allLinks: Maybe<LinksConnection>;
  /** Reads and enables pagination through a set of `Log`. */
  allLogs: Maybe<LogsConnection>;
  /** Get all audit logs */
  allLogsV2: Maybe<LogsConnection>;
  /** Get all audit logs by object id */
  allLogsV2ByObjectId: Maybe<LogsConnection>;
  /** Total count of all audit logs by object ID */
  allLogsV2ByObjectIdCount: Maybe<Scalars["BigInt"]>;
  /** Get all audit logs by object type */
  allLogsV2ByObjectType: Maybe<LogsConnection>;
  /** Total count of all audit logs by object type */
  allLogsV2ByObjectTypeCount: Maybe<Scalars["BigInt"]>;
  /** Get all audit logs by user ID */
  allLogsV2ByUserId: Maybe<LogsConnection>;
  /** Total count of all audit logs by user ID */
  allLogsV2ByUserIdCount: Maybe<Scalars["BigInt"]>;
  /** Total count of all audit logs */
  allLogsV2Count: Maybe<Scalars["BigInt"]>;
  /** Reads and enables pagination through a set of `NotificationRule`. */
  allNotificationRules: Maybe<NotificationRulesConnection>;
  /** Reads and enables pagination through a set of `NotificationSubscription`. */
  allNotificationSubscriptions: Maybe<NotificationSubscriptionsConnection>;
  /** Reads and enables pagination through a set of `OrgConnection`. */
  allOrgConnections: Maybe<OrgConnectionsConnection>;
  /** Reads and enables pagination through a set of `Org`. */
  allOrgs: Maybe<OrgsConnection>;
  /** Reads and enables pagination through a set of `PermissionSet`. */
  allPermissionSets: Maybe<PermissionSetsConnection>;
  /** Reads and enables pagination through a set of `Playlist`. */
  allPlaylists: Maybe<PlaylistsConnection>;
  /** Reads and enables pagination through a set of `RecycleBin`. */
  allRecycleBins: Maybe<RecycleBinsConnection>;
  /** Reads and enables pagination through a set of `ScreenConnectionHistory`. */
  allScreenConnectionHistories: Maybe<ScreenConnectionHistoriesConnection>;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  allScreenContentHistories: Maybe<ScreenContentHistoriesConnection>;
  /** Reads and enables pagination through a set of `ScreenGroup`. */
  allScreenGroups: Maybe<ScreenGroupsConnection>;
  /** Reads and enables pagination through a set of `ScreenNote`. */
  allScreenNotes: Maybe<ScreenNotesConnection>;
  /** Reads and enables pagination through a set of `Screen`. */
  allScreens: Maybe<ScreensConnection>;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  allShareAssociations: Maybe<ShareAssociationsConnection>;
  /** Reads and enables pagination through a set of `Site`. */
  allSites: Maybe<SitesConnection>;
  /** Reads and enables pagination through a set of `Space`. */
  allSpaces: Maybe<SpacesConnection>;
  /** Reads and enables pagination through a set of `SystemAccess`. */
  allSystemAccesses: Maybe<SystemAccessesConnection>;
  /** Reads and enables pagination through a set of `TagAssociation`. */
  allTagAssociations: Maybe<TagAssociationsConnection>;
  /** Reads and enables pagination through a set of `Tag`. */
  allTags: Maybe<TagsConnection>;
  /** Reads and enables pagination through a set of `Theme`. */
  allThemes: Maybe<ThemesConnection>;
  /** Reads and enables pagination through a set of `Token`. */
  allTokens: Maybe<TokensConnection>;
  /** Reads and enables pagination through a set of `UserActionLog`. */
  allUserActionLogs: Maybe<UserActionLogsConnection>;
  /** Reads and enables pagination through a set of `UserGroup`. */
  allUserGroups: Maybe<UserGroupsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  allUsers: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `WhiteLabel`. */
  allWhiteLabels: Maybe<WhiteLabelsConnection>;
  /** Reads a single `App` using its globally unique `ID`. */
  app: Maybe<App>;
  appById: Maybe<App>;
  appByScrn: Maybe<App>;
  appBySlug: Maybe<App>;
  /** Reads a single `AppInstall` using its globally unique `ID`. */
  appInstall: Maybe<AppInstall>;
  appInstallByAppIdAndOrgIdAndSpaceId: Maybe<AppInstall>;
  appInstallById: Maybe<AppInstall>;
  /** Reads a single `AppInstance` using its globally unique `ID`. */
  appInstance: Maybe<AppInstance>;
  appInstanceById: Maybe<AppInstance>;
  appInstanceByIdAndOrgId: Maybe<AppInstance>;
  appInstanceByTargetCommonAppInstanceTemplateId: Maybe<AppInstance>;
  /** Get all app instances by app ID and space ID. */
  appInstancesByAppIdAndSpaceId: Maybe<AppInstancesConnection>;
  /** Reads a single `AppVersion` using its globally unique `ID`. */
  appVersion: Maybe<AppVersion>;
  appVersionByAppIdAndVersion: Maybe<AppVersion>;
  appVersionById: Maybe<AppVersion>;
  /** Get available channels by space's id. */
  availableChannelsBySpaceId: Maybe<ChannelsConnection>;
  /** Get list of available layouts that compatible with the size. */
  availableLayoutsForSize: Maybe<LayoutsConnection>;
  /** Reads a single `BillingAddon` using its globally unique `ID`. */
  billingAddon: Maybe<BillingAddon>;
  billingAddonById: Maybe<BillingAddon>;
  /** Reads a single `BillingAddonPlan` using its globally unique `ID`. */
  billingAddonPlan: Maybe<BillingAddonPlan>;
  billingAddonPlanByBillingAddonIdAndBillingPlanId: Maybe<BillingAddonPlan>;
  /** Reads a single `BillingAddonSubscription` using its globally unique `ID`. */
  billingAddonSubscription: Maybe<BillingAddonSubscription>;
  billingAddonSubscriptionByBillingAddonIdAndBillingSubscriptionId: Maybe<BillingAddonSubscription>;
  /** Reads a single `BillingAddonSubscriptionFuture` using its globally unique `ID`. */
  billingAddonSubscriptionFuture: Maybe<BillingAddonSubscriptionFuture>;
  billingAddonSubscriptionFutureByBillingAddonIdAndBillingSubscriptionId: Maybe<BillingAddonSubscriptionFuture>;
  /** Reads a single `BillingAddress` using its globally unique `ID`. */
  billingAddress: Maybe<BillingAddress>;
  billingAddressByBillingCustomerId: Maybe<BillingAddress>;
  /** Reads a single `BillingCoupon` using its globally unique `ID`. */
  billingCoupon: Maybe<BillingCoupon>;
  billingCouponById: Maybe<BillingCoupon>;
  /** Reads a single `BillingCouponSubscription` using its globally unique `ID`. */
  billingCouponSubscription: Maybe<BillingCouponSubscription>;
  billingCouponSubscriptionByBillingCouponIdAndBillingSubscriptionId: Maybe<BillingCouponSubscription>;
  /** Reads a single `BillingCouponSubscriptionFuture` using its globally unique `ID`. */
  billingCouponSubscriptionFuture: Maybe<BillingCouponSubscriptionFuture>;
  billingCouponSubscriptionFutureByBillingCouponIdAndBillingSubscriptionId: Maybe<BillingCouponSubscriptionFuture>;
  /** Reads a single `BillingCustomer` using its globally unique `ID`. */
  billingCustomer: Maybe<BillingCustomer>;
  billingCustomerById: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` using its globally unique `ID`. */
  billingDistributor: Maybe<BillingDistributor>;
  billingDistributorById: Maybe<BillingDistributor>;
  /** Reads a single `BillingHierarchy` using its globally unique `ID`. */
  billingHierarchy: Maybe<BillingHierarchy>;
  billingHierarchyByParentIdAndChildId: Maybe<BillingHierarchy>;
  /** Reads a single `BillingInvoice` using its globally unique `ID`. */
  billingInvoice: Maybe<BillingInvoice>;
  billingInvoiceById: Maybe<BillingInvoice>;
  /** Reads a single `BillingPaymentSource` using its globally unique `ID`. */
  billingPaymentSource: Maybe<BillingPaymentSource>;
  billingPaymentSourceById: Maybe<BillingPaymentSource>;
  /** Reads a single `BillingPlan` using its globally unique `ID`. */
  billingPlan: Maybe<BillingPlan>;
  billingPlanById: Maybe<BillingPlan>;
  /** Reads a single `BillingRenewal` using its globally unique `ID`. */
  billingRenewal: Maybe<BillingRenewal>;
  billingRenewalByBillingSubscriptionId: Maybe<BillingRenewal>;
  /** Reads a single `BillingRenewalFuture` using its globally unique `ID`. */
  billingRenewalFuture: Maybe<BillingRenewalFuture>;
  billingRenewalFutureByBillingSubscriptionId: Maybe<BillingRenewalFuture>;
  /** Reads a single `BillingReseller` using its globally unique `ID`. */
  billingReseller: Maybe<BillingReseller>;
  billingResellerById: Maybe<BillingReseller>;
  /** Reads a single `BillingSubscription` using its globally unique `ID`. */
  billingSubscription: Maybe<BillingSubscription>;
  billingSubscriptionById: Maybe<BillingSubscription>;
  /** Reads a single `BillingSubscriptionFuture` using its globally unique `ID`. */
  billingSubscriptionFuture: Maybe<BillingSubscriptionFuture>;
  billingSubscriptionFutureById: Maybe<BillingSubscriptionFuture>;
  /** Reads a single `Cast` using its globally unique `ID`. */
  cast: Maybe<Cast>;
  castById: Maybe<Cast>;
  castByIdAndOrgId: Maybe<Cast>;
  /** Reads a single `Channel` using its globally unique `ID`. */
  channel: Maybe<Channel>;
  /** Get a channel from embed short ID */
  channelByEmbedShortId: Maybe<Channel>;
  channelById: Maybe<Channel>;
  channelByIdAndOrgId: Maybe<Channel>;
  channelByNameAndSpaceIdAndDraftOf: Maybe<Channel>;
  /** Check if in restricted trial plan and under screen quota */
  checkRestrictedTrialCanAddScreen: Maybe<Scalars["Boolean"]>;
  /** Reads a single `CommonAppInstanceTemplate` using its globally unique `ID`. */
  commonAppInstanceTemplate: Maybe<CommonAppInstanceTemplate>;
  commonAppInstanceTemplateById: Maybe<CommonAppInstanceTemplate>;
  commonFileById: Maybe<CommonFile>;
  commonFileOutputById: Maybe<CommonFileOutput>;
  commonFileToDeleteById: Maybe<CommonFileToDelete>;
  /** Reads a single `CommonFont` using its globally unique `ID`. */
  commonFont: Maybe<CommonFont>;
  commonFontById: Maybe<CommonFont>;
  /** Reads a single `CommonLayout` using its globally unique `ID`. */
  commonLayout: Maybe<CommonLayout>;
  commonLayoutById: Maybe<CommonLayout>;
  /** Reads a single `CommonPermissionSet` using its globally unique `ID`. */
  commonPermissionSet: Maybe<CommonPermissionSet>;
  commonPermissionSetById: Maybe<CommonPermissionSet>;
  computeBillingFeatureFlags: Maybe<Array<Maybe<Scalars["JSON"]>>>;
  contentNestedRefsHelper: Maybe<Array<Maybe<Scalars["JSON"]>>>;
  contentRefsDiff: Maybe<Scalars["JSON"]>;
  /** Reads a single `ContentReplacementJob` using its globally unique `ID`. */
  contentReplacementJob: Maybe<ContentReplacementJob>;
  contentReplacementJobById: Maybe<ContentReplacementJob>;
  contentReplacementJobByIdAndOrgId: Maybe<ContentReplacementJob>;
  /** Reads a single `Credential` using its globally unique `ID`. */
  credential: Maybe<Credential>;
  credentialById: Maybe<Credential>;
  credentialByIdAndOrgId: Maybe<Credential>;
  /**
   * Get the billing feature flags for the given space.
   *
   *        If the given space is in space-based billing, then get feature flags from the given space.
   *
   *        Otherwise, get the feature flags from the currently logged in user' org
   */
  currentBillingFeatureFlags: Maybe<Array<Maybe<Scalars["JSON"]>>>;
  /** Get current connection from user JWT Claims. */
  currentConnection: Maybe<Scalars["String"]>;
  /** Basic information of current embed request. NULL if not embed request */
  currentEmbedRequest: Maybe<EmbedRequest>;
  /** Get content ID of current embed request. NULL if not embed request */
  currentEmbedRequestContentId: Maybe<Scalars["UUID"]>;
  /** Get content type of current embed request. NULL if not embed request */
  currentEmbedRequestContentType: Maybe<Scalars["String"]>;
  /** Check if current embed request is a public request. NULL if not embed request */
  currentEmbedRequestIsPublic: Maybe<Scalars["Boolean"]>;
  /** Get the currently logged in user (org) feature flag */
  currentFeatureFlag: Maybe<Scalars["Boolean"]>;
  /** Get the currently logged in user (org) feature flags */
  currentFeatureFlags: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Get current user's given name. */
  currentGivenName: Maybe<Scalars["String"]>;
  currentGraphqlSession: Maybe<GraphqlSession>;
  currentGroupCombination: Maybe<GroupCombination>;
  currentGroupIds: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  /** Get list of all related ids for current user. */
  currentIds: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  /** Get the current org */
  currentOrg: Maybe<Org>;
  /** Get current org id from the current session. */
  currentOrgId: Maybe<Scalars["UUID"]>;
  /** Get channels that can be use as start channel for the current organization. */
  currentOrgStartableChannels: Maybe<ChannelsConnection>;
  /** Get current provider from user JWT Claims. */
  currentProvider: Maybe<Scalars["String"]>;
  /** Get screen data for the screen id, which is in jwt token. */
  currentScreen: Maybe<Screen>;
  /** Get current screen id from the current session (when database is accessed by a screen). */
  currentScreenId: Maybe<Scalars["UUID"]>;
  /** Get current system access id. */
  currentSystemAccessId: Maybe<Scalars["UUID"]>;
  /** Get api access token from current token id. */
  currentToken: Maybe<Token>;
  /** Get current user token id. */
  currentTokenId: Maybe<Scalars["UUID"]>;
  /** Get the current user */
  currentUser: Maybe<User>;
  /** Get current user's email from the current session. */
  currentUserEmail: Maybe<Scalars["String"]>;
  /** Get current user id from the current session. */
  currentUserId: Maybe<Scalars["UUID"]>;
  /** Check is current session user is owner of the organization */
  currentUserIsOwner: Maybe<Scalars["Boolean"]>;
  /** Check is current session user is owner or admin of the organization */
  currentUserIsOwnerOrAdmin: Maybe<Scalars["Boolean"]>;
  /** Check is current session user is a system user */
  currentUserIsSystemUser: Maybe<Scalars["Boolean"]>;
  /** List of feature flags that can customizable for org. */
  customizableFeatureFlags: Maybe<FeatureFlagsConnection>;
  /** Get default screen group by space's id. */
  defaultScreenGroupBySpaceId: Maybe<ScreenGroup>;
  downloadQRMetrics: Maybe<DownloadMetricsPayload>;
  /** Reads a single `Embed` using its globally unique `ID`. */
  embed: Maybe<Embed>;
  embedById: Maybe<Embed>;
  embedByShortId: Maybe<Embed>;
  /** Reads a single `EmbedViewLog` using its globally unique `ID`. */
  embedViewLog: Maybe<EmbedViewLog>;
  embedViewLogById: Maybe<EmbedViewLog>;
  /** Reads a single `EventBuffer` using its globally unique `ID`. */
  eventBuffer: Maybe<EventBuffer>;
  eventBufferById: Maybe<EventBuffer>;
  exportPlaybackLogs: Maybe<Scalars["String"]>;
  favoriteByOrgIdAndUserIdAndFileId: Maybe<Favorite>;
  favoriteByOrgIdAndUserIdAndFolderId: Maybe<Favorite>;
  favoriteByOrgIdAndUserIdAndLinkId: Maybe<Favorite>;
  favoriteByOrgIdAndUserIdAndPlaylistId: Maybe<Favorite>;
  favoriteByOrgIdAndUserIdAndSiteId: Maybe<Favorite>;
  /** Reads a single `FeatureFlag` using its globally unique `ID`. */
  featureFlag: Maybe<FeatureFlag>;
  featureFlagByKey: Maybe<FeatureFlag>;
  /** Reads a single `FeatureFlagOrg` using its globally unique `ID`. */
  featureFlagOrg: Maybe<FeatureFlagOrg>;
  featureFlagOrgByKeyAndOrgId: Maybe<FeatureFlagOrg>;
  /** Get the input org's feature flags */
  featureFlagsByOrgId: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Reads a single `File` using its globally unique `ID`. */
  file: Maybe<File>;
  /** Return associations to file by file id and space id. */
  fileAssociationsByFileIdAndSpaceId: Maybe<AssociationsConnection>;
  fileById: Maybe<File>;
  fileByIdAndOrgId: Maybe<File>;
  /** Reads a single `FileOutput` using its globally unique `ID`. */
  fileOutput: Maybe<FileOutput>;
  fileOutputById: Maybe<FileOutput>;
  fileOutputByIdAndOrgId: Maybe<FileOutput>;
  /** Reads a single `FileProcessingJob` using its globally unique `ID`. */
  fileProcessingJob: Maybe<FileProcessingJob>;
  fileProcessingJobById: Maybe<FileProcessingJob>;
  fileProcessingJobByIdAndOrgId: Maybe<FileProcessingJob>;
  /** Reads a single `FileToDelete` using its globally unique `ID`. */
  fileToDelete: Maybe<FileToDelete>;
  fileToDeleteById: Maybe<FileToDelete>;
  fileToDeleteByIdAndOrgId: Maybe<FileToDelete>;
  /** Get files by tag names. */
  filesByTagNames: Maybe<FilesConnection>;
  /** Reads a single `Folder` using its globally unique `ID`. */
  folder: Maybe<Folder>;
  folderById: Maybe<Folder>;
  folderByIdAndOrgId: Maybe<Folder>;
  folderByNameAndParentId: Maybe<Folder>;
  /** Get folder hierarchy from input folder's id. */
  folderHierarchy: Maybe<FolderHierarchyDetailsConnection>;
  /** Return all parent folders and the input folder's details. */
  folderParents: Maybe<FoldersConnection>;
  /** Reads a single `Font` using its globally unique `ID`. */
  font: Maybe<Font>;
  fontById: Maybe<Font>;
  getBillingAddons: Maybe<GetBillingAddonsConnection>;
  getBillingAddonsFuture: Maybe<GetBillingAddonsFutureConnection>;
  /** Retrieve customer billing address details. */
  getBillingAddress: Maybe<BillingAddress>;
  /** Retrieve customer billing subscription coupon details. */
  getBillingCoupons: Maybe<BillingCouponsConnection>;
  /** Retrieve customer future billing subscription coupon details. */
  getBillingCouponsFuture: Maybe<BillingCouponsConnection>;
  /** Retrieve customer billing details. */
  getBillingCustomer: Maybe<BillingCustomer>;
  /** Retrieve customer payment history. */
  getBillingInvoices: Maybe<BillingInvoicesConnection>;
  /** Retrieve customer billing payment source details. */
  getBillingPaymentSource: Maybe<BillingPaymentSourcesConnection>;
  /** Retrieve customer billing subscription plan details. */
  getBillingPlan: Maybe<BillingPlan>;
  /** Retrieve customer billing subscription future plan details. */
  getBillingPlanFuture: Maybe<BillingPlan>;
  /** Retrieve customer billing renewal details. */
  getBillingRenewal: Maybe<BillingRenewal>;
  /** Retrieve customer future billing renewal details. */
  getBillingRenewalFuture: Maybe<BillingRenewalFuture>;
  /** Retrieve customer billing subscription details. */
  getBillingSubscription: Maybe<BillingSubscription>;
  /** Retrieve customer billing future subscription details. */
  getBillingSubscriptionFuture: Maybe<BillingSubscriptionFuture>;
  /** Returns brand info from specified domain name. */
  getBrandInfo: GetBrandInfoPayload;
  /** Get current processing replacement jobs by space ID. */
  getCurrentReplacementJobs: Maybe<ContentReplacementJobsConnection>;
  getDuplicateJob: Maybe<DuplicateJobPayload>;
  getImportJobs: Maybe<Array<Maybe<GetImportJobsPayload>>>;
  getInvoicePdf: Maybe<GetInvoicePdfPayload>;
  getInvoices: Maybe<GetInvoicesPayload>;
  getPlaybackLogs: Maybe<GetPlaybackLogsPayload>;
  getQRMetrics: Maybe<GetMetricsPayload>;
  /** Get remaining user quota. Returns -1 if not in restricted trial plan. */
  getRemainingUserQuota: Maybe<Scalars["Int"]>;
  getScreenDeviceAllowClone: Maybe<GetScreenDeviceAllowClonePayload>;
  getScreenLicences: Maybe<GetScreenLicencesPayload>;
  getSnapshots: Maybe<Array<Maybe<GetSnapshotsPayload>>>;
  getTokenPayload: Maybe<Scalars["JSON"]>;
  /** Reads a single `Group` using its globally unique `ID`. */
  group: Maybe<Group>;
  groupById: Maybe<Group>;
  groupByOrgIdAndId: Maybe<Group>;
  /** Reads a single `GroupCombination` using its globally unique `ID`. */
  groupCombination: Maybe<GroupCombination>;
  groupCombinationById: Maybe<GroupCombination>;
  groupCombinationByOrgIdAndGroupIds: Maybe<GroupCombination>;
  groupCombinationByOrgIdAndId: Maybe<GroupCombination>;
  /** Reads a single `GroupSpace` using its globally unique `ID`. */
  groupSpace: Maybe<GroupSpace>;
  groupSpaceByOrgIdAndGroupIdAndSpaceId: Maybe<GroupSpace>;
  identityProviderLaunchUrl: Scalars["String"];
  idsWithGrant: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  /** To check is email already registerd as any user. */
  isEmailRegistered: Maybe<Scalars["JSON"]>;
  /** Reads a single `Layout` using its globally unique `ID`. */
  layout: Maybe<Layout>;
  layoutById: Maybe<Layout>;
  layoutByIdAndOrgIdAndSpaceId: Maybe<Layout>;
  /** Reads and enables pagination through a set of `Layout`. */
  layoutsBySpaceId: Maybe<LayoutsConnection>;
  /** Reads a single `LegacyLog` using its globally unique `ID`. */
  legacyLog: Maybe<LegacyLog>;
  legacyLogByIdAndOrgId: Maybe<LegacyLog>;
  /** Reads a single `Link` using its globally unique `ID`. */
  link: Maybe<Link>;
  linkById: Maybe<Link>;
  linkByIdAndOrgId: Maybe<Link>;
  /** Reads a single `Log` using its globally unique `ID`. */
  log: Maybe<Log>;
  logById: Maybe<Log>;
  /** Reads a single `LogObjectFieldValue` using its globally unique `ID`. */
  logObjectFieldValue: Maybe<LogObjectFieldValue>;
  logObjectFieldValueByLogId: Maybe<LogObjectFieldValue>;
  /** Check for ongoing migration job associate with this user */
  migrationCheck: Maybe<Scalars["JSON"]>;
  migrationStatus: Maybe<Scalars["JSON"]>;
  /** Reads and enables pagination through a set of `File`. */
  nestedFilesByFolderId: Maybe<FilesConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars["ID"];
  /** Reads a single `NotificationRule` using its globally unique `ID`. */
  notificationRule: Maybe<NotificationRule>;
  notificationRuleById: Maybe<NotificationRule>;
  /** Reads a single `NotificationSubscription` using its globally unique `ID`. */
  notificationSubscription: Maybe<NotificationSubscription>;
  notificationSubscriptionById: Maybe<NotificationSubscription>;
  notificationSubscriptionByNotificationRuleIdAndUserIdAndChannel: Maybe<NotificationSubscription>;
  /** Reads a single `Org` using its globally unique `ID`. */
  org: Maybe<Org>;
  orgByBillingCustomerId: Maybe<Org>;
  orgById: Maybe<Org>;
  orgBySlug: Maybe<Org>;
  /** Reads a single `OrgConnection` using its globally unique `ID`. */
  orgConnection: Maybe<OrgConnection>;
  orgConnectionByOrgId: Maybe<OrgConnection>;
  orgConnectionByOrgIdAndId: Maybe<OrgConnection>;
  /** Get organization's start channel id. */
  orgStartChannelId: Maybe<Scalars["UUID"]>;
  /** Returns the quota limits and usage since the last reconciliation. */
  organisationQuota: Quota;
  /** Get organizations that user belong to with the latest activity. */
  orgsByCurrentEmail: Maybe<Scalars["JSON"]>;
  permissionSetByOrgIdAndCommonPermissionSetId: Maybe<PermissionSet>;
  permissionsList: Maybe<Scalars["JSON"]>;
  /** Reads a single `Playlist` using its globally unique `ID`. */
  playlist: Maybe<Playlist>;
  playlistByDraftOfAndOrgId: Maybe<Playlist>;
  /** Get a playlist from embed short ID */
  playlistByEmbedShortId: Maybe<Playlist>;
  playlistById: Maybe<Playlist>;
  playlistByIdAndOrgId: Maybe<Playlist>;
  publicOrgByDomain: Maybe<PublicOrg>;
  publicOrgBySlug: Maybe<PublicOrg>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  recycleBinByObjectId: Maybe<RecycleBin>;
  /** Get Recycle Bin List */
  recycleBinBySpaceId: Maybe<RecycleBinsConnection>;
  /** Reads a single `Screen` using its globally unique `ID`. */
  screen: Maybe<Screen>;
  screenByDeviceId: Maybe<Screen>;
  screenById: Maybe<Screen>;
  screenByIdAndOrgId: Maybe<Screen>;
  /** Get screens by ids. */
  screenByIds: Maybe<ScreensConnection>;
  /** Reads a single `ScreenConnectionHistory` using its globally unique `ID`. */
  screenConnectionHistory: Maybe<ScreenConnectionHistory>;
  screenConnectionHistoryById: Maybe<ScreenConnectionHistory>;
  /** Get content of the current a screen. */
  screenContent: Maybe<Scalars["JSON"]>;
  /** Reads a single `ScreenContentHistory` using its globally unique `ID`. */
  screenContentHistory: Maybe<ScreenContentHistory>;
  screenContentHistoryByIdAndOrgId: Maybe<ScreenContentHistory>;
  /** Reads a single `ScreenGroup` using its globally unique `ID`. */
  screenGroup: Maybe<ScreenGroup>;
  screenGroupById: Maybe<ScreenGroup>;
  screenGroupByIdAndOrgId: Maybe<ScreenGroup>;
  screenGroupBySpaceIdAndName: Maybe<ScreenGroup>;
  /** Reads a single `ScreenNote` using its globally unique `ID`. */
  screenNote: Maybe<ScreenNote>;
  screenNoteById: Maybe<ScreenNote>;
  /** Get screen tags by space id */
  screenTagsBySpaceId: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Get screens by tag names. */
  screensByTagNames: Maybe<ScreensConnection>;
  /** Get screen's total count of current organization */
  screensCount: Maybe<Scalars["Int"]>;
  screenshotsByScreenId: Maybe<ScreenshotsByScreenIdPayload>;
  /** Search for app instance by name. */
  searchAppInstance: Maybe<AppInstancesConnection>;
  /** Search for app instance by app id. */
  searchAppInstanceSpecificAppId: Maybe<AppInstancesConnection>;
  /** Search for Channel. */
  searchChannel: Maybe<ChannelsConnection>;
  /** Search for File. */
  searchFile: Maybe<FilesConnection>;
  /** Search for Folder. */
  searchFolder: Maybe<FoldersConnection>;
  /** Search for Link. */
  searchLink: Maybe<LinksConnection>;
  /** Search for Playlist. */
  searchPlaylist: Maybe<PlaylistsConnection>;
  /** Search for Screen. */
  searchScreen: Maybe<ScreensConnection>;
  /** Search for a Site. */
  searchSite: Maybe<SitesConnection>;
  /** Search for spaces. */
  searchSpace: Maybe<SpacesConnection>;
  /** Search for users. */
  searchUser: Maybe<UsersConnection>;
  shareAssociationByOrgIdAndFromSpaceAndShareAppInstanceAndToSpace: Maybe<ShareAssociation>;
  shareAssociationByOrgIdAndFromSpaceAndShareChannelAndToSpace: Maybe<ShareAssociation>;
  shareAssociationByOrgIdAndFromSpaceAndShareCredentialAndToSpace: Maybe<ShareAssociation>;
  shareAssociationByOrgIdAndFromSpaceAndShareFileAndToSpace: Maybe<ShareAssociation>;
  shareAssociationByOrgIdAndFromSpaceAndShareFolderAndToSpace: Maybe<ShareAssociation>;
  shareAssociationByOrgIdAndFromSpaceAndShareLinkAndToSpace: Maybe<ShareAssociation>;
  shareAssociationByOrgIdAndFromSpaceAndSharePlaylistAndToSpace: Maybe<ShareAssociation>;
  shareAssociationByOrgIdAndFromSpaceAndShareSiteAndToSpace: Maybe<ShareAssociation>;
  /** Reads a single `Site` using its globally unique `ID`. */
  site: Maybe<Site>;
  siteById: Maybe<Site>;
  siteByIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` using its globally unique `ID`. */
  space: Maybe<Space>;
  spaceById: Maybe<Space>;
  spaceByIdAndOrgId: Maybe<Space>;
  spaceByOrgIdAndName: Maybe<Space>;
  /** Returns the SSO connection detail. */
  ssoGetConnection: Maybe<SsoConnection>;
  /** Returns the SSO Logs by org connection ID. */
  ssoGetLogs: SsoLogPayload;
  /** Reads a single `SystemAccess` using its globally unique `ID`. */
  systemAccess: Maybe<SystemAccess>;
  systemAccessById: Maybe<SystemAccess>;
  systemAccessByIdAndOrgId: Maybe<SystemAccess>;
  /** Reads a single `Tag` using its globally unique `ID`. */
  tag: Maybe<Tag>;
  tagAssociationByOrgIdAndTagNameAndSpaceIdAndToAppInstance: Maybe<TagAssociation>;
  tagAssociationByOrgIdAndTagNameAndSpaceIdAndToChannel: Maybe<TagAssociation>;
  tagAssociationByOrgIdAndTagNameAndSpaceIdAndToFile: Maybe<TagAssociation>;
  tagAssociationByOrgIdAndTagNameAndSpaceIdAndToFolder: Maybe<TagAssociation>;
  tagAssociationByOrgIdAndTagNameAndSpaceIdAndToLink: Maybe<TagAssociation>;
  tagAssociationByOrgIdAndTagNameAndSpaceIdAndToPlaylist: Maybe<TagAssociation>;
  tagAssociationByOrgIdAndTagNameAndSpaceIdAndToScreen: Maybe<TagAssociation>;
  tagAssociationByOrgIdAndTagNameAndSpaceIdAndToSite: Maybe<TagAssociation>;
  tagByNameAndOrgIdAndSpaceId: Maybe<Tag>;
  /** Get tags by tag name. */
  tagByTagName: Maybe<TagsConnection>;
  /** Get tags by app id and space id */
  tagsByAppIdAndSpaceId: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Reads a single `Theme` using its globally unique `ID`. */
  theme: Maybe<Theme>;
  themeById: Maybe<Theme>;
  themeByIdAndOrgId: Maybe<Theme>;
  /** Reads a single `Token` using its globally unique `ID`. */
  token: Maybe<Token>;
  tokenById: Maybe<Token>;
  /** Reads a single `User` using its globally unique `ID`. */
  user: Maybe<User>;
  /** Reads a single `UserActionLog` using its globally unique `ID`. */
  userActionLog: Maybe<UserActionLog>;
  userActionLogById: Maybe<UserActionLog>;
  userById: Maybe<User>;
  userByIdAndOrgId: Maybe<User>;
  /** Reads a single `UserGroup` using its globally unique `ID`. */
  userGroup: Maybe<UserGroup>;
  userGroupByOrgIdAndUserIdAndGroupId: Maybe<UserGroup>;
  /** Reads a single `WhiteLabel` using its globally unique `ID`. */
  whiteLabel: Maybe<WhiteLabel>;
  whiteLabelById: Maybe<WhiteLabel>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAppInstallsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstallCondition>;
  filter: InputMaybe<AppInstallFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAppInstancesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAppVersionsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppVersionCondition>;
  filter: InputMaybe<AppVersionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppVersionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAppsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppCondition>;
  filter: InputMaybe<AppFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllAssociationsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingAddonPlansArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonPlanCondition>;
  filter: InputMaybe<BillingAddonPlanFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonPlansOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingAddonSubscriptionFuturesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionFutureCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionFuturesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingAddonSubscriptionsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonSubscriptionCondition>;
  filter: InputMaybe<BillingAddonSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonSubscriptionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingAddonsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddonCondition>;
  filter: InputMaybe<BillingAddonFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddonsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingAddressesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingAddressCondition>;
  filter: InputMaybe<BillingAddressFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingAddressesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingCouponSubscriptionFuturesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionFutureCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionFuturesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingCouponSubscriptionsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponSubscriptionCondition>;
  filter: InputMaybe<BillingCouponSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponSubscriptionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingCouponsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCouponCondition>;
  filter: InputMaybe<BillingCouponFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCouponsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingCustomersArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingCustomerCondition>;
  filter: InputMaybe<BillingCustomerFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingCustomersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingDistributorsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingDistributorCondition>;
  filter: InputMaybe<BillingDistributorFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingDistributorsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingHierarchiesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingHierarchyCondition>;
  filter: InputMaybe<BillingHierarchyFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingHierarchiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingInvoicesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingInvoiceCondition>;
  filter: InputMaybe<BillingInvoiceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingInvoicesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingPaymentSourcesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingPaymentSourceCondition>;
  filter: InputMaybe<BillingPaymentSourceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingPaymentSourcesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingPlansArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingPlanCondition>;
  filter: InputMaybe<BillingPlanFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingPlansOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingRenewalFuturesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingRenewalFutureCondition>;
  filter: InputMaybe<BillingRenewalFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingRenewalFuturesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingRenewalsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingRenewalCondition>;
  filter: InputMaybe<BillingRenewalFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingRenewalsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingResellersArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingResellerCondition>;
  filter: InputMaybe<BillingResellerFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingResellersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingSubscriptionFuturesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingSubscriptionFutureCondition>;
  filter: InputMaybe<BillingSubscriptionFutureFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingSubscriptionFuturesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllBillingSubscriptionsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<BillingSubscriptionCondition>;
  filter: InputMaybe<BillingSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<BillingSubscriptionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCastsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CastCondition>;
  filter: InputMaybe<CastFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CastsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllChannelsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllChildFoldersFromAllRootFoldersArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<FoldersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCommonAppInstanceTemplatesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonAppInstanceTemplateCondition>;
  filter: InputMaybe<CommonAppInstanceTemplateFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonAppInstanceTemplatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCommonFontsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonFontCondition>;
  filter: InputMaybe<CommonFontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonFontsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCommonLayoutsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonLayoutCondition>;
  filter: InputMaybe<CommonLayoutFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonLayoutsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCommonPermissionSetsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CommonPermissionSetCondition>;
  filter: InputMaybe<CommonPermissionSetFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CommonPermissionSetsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllContentReplacementJobsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ContentReplacementJobCondition>;
  filter: InputMaybe<ContentReplacementJobFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContentReplacementJobsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllCredentialsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CredentialCondition>;
  filter: InputMaybe<CredentialFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEmbedViewLogsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  afterId: InputMaybe<Scalars["BigInt"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  total: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEmbedViewLogsByContentTypeAndContentIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  afterId: InputMaybe<Scalars["BigInt"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  contentId: InputMaybe<Scalars["UUID"]>;
  contentType: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  total: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEmbedsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<EmbedCondition>;
  filter: InputMaybe<EmbedFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EmbedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllEventBuffersArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<EventBufferCondition>;
  filter: InputMaybe<EventBufferFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EventBuffersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFavoritesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFeatureFlagOrgsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FeatureFlagOrgCondition>;
  filter: InputMaybe<FeatureFlagOrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FeatureFlagOrgsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFeatureFlagsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FeatureFlagCondition>;
  filter: InputMaybe<FeatureFlagFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FeatureFlagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileOutputsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileOutputCondition>;
  filter: InputMaybe<FileOutputFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileOutputsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileProcessingJobsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileProcessingJobCondition>;
  filter: InputMaybe<FileProcessingJobFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileProcessingJobsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFileToDeletesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileToDeleteCondition>;
  filter: InputMaybe<FileToDeleteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileToDeletesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFilesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFilesFromAllRootFoldersArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFoldersArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FolderCondition>;
  filter: InputMaybe<FolderFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllFontsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllGroupCombinationsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupCombinationCondition>;
  filter: InputMaybe<GroupCombinationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupCombinationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllGroupSpacesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupSpaceCondition>;
  filter: InputMaybe<GroupSpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllGroupsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupCondition>;
  filter: InputMaybe<GroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLayoutsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LayoutCondition>;
  filter: InputMaybe<LayoutFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LayoutsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLegacyLogsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LegacyLogCondition>;
  filter: InputMaybe<LegacyLogFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LegacyLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLinksArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LinkCondition>;
  filter: InputMaybe<LinkFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLogsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LogCondition>;
  filter: InputMaybe<LogFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLogsV2Args = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  skipFirst: InputMaybe<Scalars["Int"]>;
  total: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLogsV2ByObjectIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  objectId: InputMaybe<Scalars["UUID"]>;
  offset: InputMaybe<Scalars["Int"]>;
  skipFirst: InputMaybe<Scalars["Int"]>;
  total: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLogsV2ByObjectIdCountArgs = {
  objectId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLogsV2ByObjectTypeArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  objectType: InputMaybe<Scalars["String"]>;
  offset: InputMaybe<Scalars["Int"]>;
  skipFirst: InputMaybe<Scalars["Int"]>;
  total: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLogsV2ByObjectTypeCountArgs = {
  objectType: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLogsV2ByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  skipFirst: InputMaybe<Scalars["Int"]>;
  total: InputMaybe<Scalars["Int"]>;
  userId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllLogsV2ByUserIdCountArgs = {
  userId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllNotificationRulesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationRuleCondition>;
  filter: InputMaybe<NotificationRuleFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllNotificationSubscriptionsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationSubscriptionCondition>;
  filter: InputMaybe<NotificationSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationSubscriptionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllOrgConnectionsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgConnectionCondition>;
  filter: InputMaybe<OrgConnectionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgConnectionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllOrgsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPermissionSetsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PermissionSetCondition>;
  filter: InputMaybe<PermissionSetFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionSetsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllPlaylistsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllRecycleBinsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<RecycleBinCondition>;
  filter: InputMaybe<RecycleBinFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<RecycleBinsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllScreenConnectionHistoriesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenConnectionHistoryCondition>;
  filter: InputMaybe<ScreenConnectionHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenConnectionHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllScreenContentHistoriesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllScreenGroupsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenGroupCondition>;
  filter: InputMaybe<ScreenGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllScreenNotesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenNoteCondition>;
  filter: InputMaybe<ScreenNoteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllScreensArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllShareAssociationsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSitesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSpacesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SpaceCondition>;
  filter: InputMaybe<SpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllSystemAccessesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SystemAccessCondition>;
  filter: InputMaybe<SystemAccessFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SystemAccessesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTagAssociationsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTagsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagCondition>;
  filter: InputMaybe<TagFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllThemesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllTokensArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TokenCondition>;
  filter: InputMaybe<TokenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUserActionLogsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserActionLogCondition>;
  filter: InputMaybe<UserActionLogFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserActionLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUserGroupsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserGroupCondition>;
  filter: InputMaybe<UserGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllUsersArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAllWhiteLabelsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<WhiteLabelCondition>;
  filter: InputMaybe<WhiteLabelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAppArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppByScrnArgs = {
  scrn: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppBySlugArgs = {
  slug: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppInstallArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppInstallByAppIdAndOrgIdAndSpaceIdArgs = {
  appId: Scalars["UUID"];
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppInstallByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppInstanceArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppInstanceByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppInstanceByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppInstanceByTargetCommonAppInstanceTemplateIdArgs = {
  targetCommonAppInstanceTemplateId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppInstancesByAppIdAndSpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  appId: InputMaybe<Scalars["UUID"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAppVersionArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppVersionByAppIdAndVersionArgs = {
  appId: Scalars["UUID"];
  version: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAppVersionByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAvailableChannelsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAvailableLayoutsForSizeArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  height: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  width: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddonArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddonByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddonPlanArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddonPlanByBillingAddonIdAndBillingPlanIdArgs = {
  billingAddonId: Scalars["String"];
  billingPlanId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddonSubscriptionArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddonSubscriptionByBillingAddonIdAndBillingSubscriptionIdArgs = {
  billingAddonId: Scalars["String"];
  billingSubscriptionId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddonSubscriptionFutureArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddonSubscriptionFutureByBillingAddonIdAndBillingSubscriptionIdArgs = {
  billingAddonId: Scalars["String"];
  billingSubscriptionId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddressArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingAddressByBillingCustomerIdArgs = {
  billingCustomerId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingCouponArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingCouponByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingCouponSubscriptionArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingCouponSubscriptionByBillingCouponIdAndBillingSubscriptionIdArgs = {
  billingCouponId: Scalars["String"];
  billingSubscriptionId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingCouponSubscriptionFutureArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingCouponSubscriptionFutureByBillingCouponIdAndBillingSubscriptionIdArgs = {
  billingCouponId: Scalars["String"];
  billingSubscriptionId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingCustomerArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingCustomerByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingDistributorArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingDistributorByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingHierarchyArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingHierarchyByParentIdAndChildIdArgs = {
  childId: Scalars["String"];
  parentId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingInvoiceArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingInvoiceByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingPaymentSourceArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingPaymentSourceByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingPlanArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingPlanByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingRenewalArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingRenewalByBillingSubscriptionIdArgs = {
  billingSubscriptionId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingRenewalFutureArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingRenewalFutureByBillingSubscriptionIdArgs = {
  billingSubscriptionId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingResellerArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingResellerByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingSubscriptionArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingSubscriptionByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingSubscriptionFutureArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryBillingSubscriptionFutureByIdArgs = {
  id: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCastArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCastByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCastByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryChannelArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryChannelByEmbedShortIdArgs = {
  shortId: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryChannelByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryChannelByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryChannelByNameAndSpaceIdAndDraftOfArgs = {
  draftOf: Scalars["UUID"];
  name: Scalars["String"];
  spaceId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonAppInstanceTemplateArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonAppInstanceTemplateByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonFileByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonFileOutputByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonFileToDeleteByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonFontArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonFontByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonLayoutArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonLayoutByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonPermissionSetArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCommonPermissionSetByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryComputeBillingFeatureFlagsArgs = {
  billingCustomerId: InputMaybe<Scalars["String"]>;
  orgId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryContentNestedRefsHelperArgs = {
  content: InputMaybe<Scalars["JSON"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryContentRefsDiffArgs = {
  contentOf: InputMaybe<Scalars["String"]>;
  newContent: InputMaybe<Scalars["JSON"]>;
  oldContent: InputMaybe<Scalars["JSON"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryContentReplacementJobArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryContentReplacementJobByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryContentReplacementJobByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCredentialArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCredentialByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCredentialByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryCurrentBillingFeatureFlagsArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCurrentFeatureFlagArgs = {
  id: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCurrentOrgStartableChannelsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCustomizableFeatureFlagsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDefaultScreenGroupBySpaceIdArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDownloadQrMetricsArgs = {
  input: InputMaybe<DownloadMetricsInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEmbedArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmbedByIdArgs = {
  id: Scalars["BigInt"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmbedByShortIdArgs = {
  shortId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmbedViewLogArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmbedViewLogByIdArgs = {
  id: Scalars["BigInt"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventBufferArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventBufferByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExportPlaybackLogsArgs = {
  input: InputMaybe<ExportPlaybackLogsInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFavoriteByOrgIdAndUserIdAndFileIdArgs = {
  fileId: Scalars["UUID"];
  orgId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFavoriteByOrgIdAndUserIdAndFolderIdArgs = {
  folderId: Scalars["UUID"];
  orgId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFavoriteByOrgIdAndUserIdAndLinkIdArgs = {
  linkId: Scalars["UUID"];
  orgId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFavoriteByOrgIdAndUserIdAndPlaylistIdArgs = {
  orgId: Scalars["UUID"];
  playlistId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFavoriteByOrgIdAndUserIdAndSiteIdArgs = {
  orgId: Scalars["UUID"];
  siteId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeatureFlagArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeatureFlagByKeyArgs = {
  key: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeatureFlagOrgArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeatureFlagOrgByKeyAndOrgIdArgs = {
  key: Scalars["String"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeatureFlagsByOrgIdArgs = {
  orgId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileAssociationsByFileIdAndSpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  fileId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFileByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileOutputArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileOutputByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileOutputByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileProcessingJobArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileProcessingJobByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileProcessingJobByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileToDeleteArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileToDeleteByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFileToDeleteByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFilesByTagNamesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  tagNames: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFolderArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFolderByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFolderByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFolderByNameAndParentIdArgs = {
  name: Scalars["String"];
  parentId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFolderHierarchyArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  folderId: InputMaybe<Scalars["UUID"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFolderParentsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  folderId: InputMaybe<Scalars["UUID"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFontArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryFontByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingAddonsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingAddonsFutureArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingAddressArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingCouponsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingCouponsFutureArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingCustomerArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingInvoicesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingPaymentSourceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingPlanArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingPlanFutureArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingRenewalArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingRenewalFutureArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingSubscriptionArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBillingSubscriptionFutureArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetBrandInfoArgs = {
  domainName: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetCurrentReplacementJobsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<ContentReplacementJobsOrderBy>>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetDuplicateJobArgs = {
  input: GetDuplicateJobInput;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetImportJobsArgs = {
  input: InputMaybe<GetImportJobsInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetInvoicePdfArgs = {
  invoiceId: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetInvoicesArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetPlaybackLogsArgs = {
  input: InputMaybe<GetPlaybackLogsInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetQrMetricsArgs = {
  input: InputMaybe<GetMetricsInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetScreenDeviceAllowCloneArgs = {
  screenId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGetScreenLicencesArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetSnapshotsArgs = {
  input: InputMaybe<GetSnapshotInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetTokenPayloadArgs = {
  orgId: InputMaybe<Scalars["UUID"]>;
  token: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupByOrgIdAndIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupCombinationArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupCombinationByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupCombinationByOrgIdAndGroupIdsArgs = {
  groupIds: Array<InputMaybe<Scalars["UUID"]>>;
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupCombinationByOrgIdAndIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupSpaceArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupSpaceByOrgIdAndGroupIdAndSpaceIdArgs = {
  groupId: Scalars["UUID"];
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIdentityProviderLaunchUrlArgs = {
  input: IdentityProviderLaunchUrlInput;
};

/** The root query type which gives access points into the data universe. */
export type QueryIdsWithGrantArgs = {
  aclGrantsForSpaces: InputMaybe<Scalars["JSON"]>;
  targetAction: InputMaybe<Scalars["String"]>;
  targetObject: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLayoutArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLayoutByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLayoutByIdAndOrgIdAndSpaceIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLayoutsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLegacyLogArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLegacyLogByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLinkArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLinkByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLinkByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLogArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLogByIdArgs = {
  id: Scalars["BigInt"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLogObjectFieldValueArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLogObjectFieldValueByLogIdArgs = {
  logId: Scalars["BigInt"];
};

/** The root query type which gives access points into the data universe. */
export type QueryMigrationStatusArgs = {
  orgId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNestedFilesByFolderIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  folderId: InputMaybe<Scalars["UUID"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNotificationRuleArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNotificationRuleByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNotificationSubscriptionArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNotificationSubscriptionByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryNotificationSubscriptionByNotificationRuleIdAndUserIdAndChannelArgs = {
  channel: Scalars["String"];
  notificationRuleId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrgArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrgByBillingCustomerIdArgs = {
  billingCustomerId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrgByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrgBySlugArgs = {
  slug: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrgConnectionArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrgConnectionByOrgIdArgs = {
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrgConnectionByOrgIdAndIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganisationQuotaArgs = {
  orgId: Scalars["UUID"];
  usageType: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPermissionSetByOrgIdAndCommonPermissionSetIdArgs = {
  commonPermissionSetId: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistByDraftOfAndOrgIdArgs = {
  draftOf: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistByEmbedShortIdArgs = {
  shortId: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlaylistByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPublicOrgByDomainArgs = {
  domain: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPublicOrgBySlugArgs = {
  slug: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryRecycleBinByObjectIdArgs = {
  objectId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryRecycleBinBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenByDeviceIdArgs = {
  deviceId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenByIdsArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  screenIds: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenConnectionHistoryArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenConnectionHistoryByIdArgs = {
  id: Scalars["BigInt"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenContentHistoryArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenContentHistoryByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenGroupArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenGroupByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenGroupByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenGroupBySpaceIdAndNameArgs = {
  name: Scalars["String"];
  spaceId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenNoteArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenNoteByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenTagsBySpaceIdArgs = {
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreensByTagNamesArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  tagNames: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryScreenshotsByScreenIdArgs = {
  input: ScreenshotsByScreenIdInput;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchAppInstanceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<AppInstancesOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchAppInstanceSpecificAppIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  appId: InputMaybe<Scalars["UUID"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<AppInstancesOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchChannelArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  isShared: InputMaybe<Scalars["Boolean"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<ChannelsOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchFileArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchFolderArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FolderCondition>;
  filter: InputMaybe<FolderFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<FoldersOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchLinkArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LinkCondition>;
  filter: InputMaybe<LinkFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<LinksOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchPlaylistArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<PlaylistsOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchScreenArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<ScreensOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchSiteArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<SitesOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchSpaceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SpaceCondition>;
  filter: InputMaybe<SpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<SpacesOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySearchUserArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<UsersOrderBy>>;
  query: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryShareAssociationByOrgIdAndFromSpaceAndShareAppInstanceAndToSpaceArgs = {
  fromSpace: Scalars["UUID"];
  orgId: Scalars["UUID"];
  shareAppInstance: Scalars["UUID"];
  toSpace: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryShareAssociationByOrgIdAndFromSpaceAndShareChannelAndToSpaceArgs = {
  fromSpace: Scalars["UUID"];
  orgId: Scalars["UUID"];
  shareChannel: Scalars["UUID"];
  toSpace: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryShareAssociationByOrgIdAndFromSpaceAndShareCredentialAndToSpaceArgs = {
  fromSpace: Scalars["UUID"];
  orgId: Scalars["UUID"];
  shareCredential: Scalars["UUID"];
  toSpace: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryShareAssociationByOrgIdAndFromSpaceAndShareFileAndToSpaceArgs = {
  fromSpace: Scalars["UUID"];
  orgId: Scalars["UUID"];
  shareFile: Scalars["UUID"];
  toSpace: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryShareAssociationByOrgIdAndFromSpaceAndShareFolderAndToSpaceArgs = {
  fromSpace: Scalars["UUID"];
  orgId: Scalars["UUID"];
  shareFolder: Scalars["UUID"];
  toSpace: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryShareAssociationByOrgIdAndFromSpaceAndShareLinkAndToSpaceArgs = {
  fromSpace: Scalars["UUID"];
  orgId: Scalars["UUID"];
  shareLink: Scalars["UUID"];
  toSpace: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryShareAssociationByOrgIdAndFromSpaceAndSharePlaylistAndToSpaceArgs = {
  fromSpace: Scalars["UUID"];
  orgId: Scalars["UUID"];
  sharePlaylist: Scalars["UUID"];
  toSpace: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryShareAssociationByOrgIdAndFromSpaceAndShareSiteAndToSpaceArgs = {
  fromSpace: Scalars["UUID"];
  orgId: Scalars["UUID"];
  shareSite: Scalars["UUID"];
  toSpace: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySiteArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySiteByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySiteByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySpaceArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySpaceByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySpaceByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySpaceByOrgIdAndNameArgs = {
  name: Scalars["String"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySsoGetConnectionArgs = {
  orgConnectionId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySsoGetLogsArgs = {
  orgConnectionId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySystemAccessArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySystemAccessByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySystemAccessByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagAssociationByOrgIdAndTagNameAndSpaceIdAndToAppInstanceArgs = {
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tagName: Scalars["String"];
  toAppInstance: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagAssociationByOrgIdAndTagNameAndSpaceIdAndToChannelArgs = {
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tagName: Scalars["String"];
  toChannel: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagAssociationByOrgIdAndTagNameAndSpaceIdAndToFileArgs = {
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tagName: Scalars["String"];
  toFile: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagAssociationByOrgIdAndTagNameAndSpaceIdAndToFolderArgs = {
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tagName: Scalars["String"];
  toFolder: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagAssociationByOrgIdAndTagNameAndSpaceIdAndToLinkArgs = {
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tagName: Scalars["String"];
  toLink: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagAssociationByOrgIdAndTagNameAndSpaceIdAndToPlaylistArgs = {
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tagName: Scalars["String"];
  toPlaylist: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagAssociationByOrgIdAndTagNameAndSpaceIdAndToScreenArgs = {
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tagName: Scalars["String"];
  toScreen: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagAssociationByOrgIdAndTagNameAndSpaceIdAndToSiteArgs = {
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tagName: Scalars["String"];
  toSite: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagByNameAndOrgIdAndSpaceIdArgs = {
  name: Scalars["String"];
  orgId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagByTagNameArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  tagName: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTagsByAppIdAndSpaceIdArgs = {
  appId: InputMaybe<Scalars["UUID"]>;
  isShared: InputMaybe<Scalars["Boolean"]>;
  isTemplate: InputMaybe<Scalars["Boolean"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryThemeArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryThemeByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryThemeByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserActionLogArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserActionLogByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByIdArgs = {
  id: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByIdAndOrgIdArgs = {
  id: Scalars["UUID"];
  orgId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserGroupArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserGroupByOrgIdAndUserIdAndGroupIdArgs = {
  groupId: Scalars["UUID"];
  orgId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWhiteLabelArgs = {
  nodeId: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryWhiteLabelByIdArgs = {
  id: Scalars["UUID"];
};

export type Quota = {
  __typename: "Quota";
  orgId: Scalars["String"];
  quotaLimit: Scalars["Int"];
  quotaUnits: Scalars["Int"];
  unitsUsed: Scalars["Int"];
};

/** All input for the `reactivateToken` mutation. */
export type ReactivateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `reactivateToken` mutation. */
export type ReactivateTokenPayload = {
  __typename: "ReactivateTokenPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Token`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  token: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge: Maybe<TokensEdge>;
  /** Reads a single `User` that is related to this `Token`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUserId: Maybe<User>;
};

/** The output of our `reactivateToken` mutation. */
export type ReactivateTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

export enum ReceiveUpdates {
  Confirmed = "CONFIRMED",
  No = "NO",
  Requested = "REQUESTED",
}

/** A filter to be used against ReceiveUpdates fields. All fields are combined with a logical ‘and.’ */
export type ReceiveUpdatesFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ReceiveUpdates>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ReceiveUpdates>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ReceiveUpdates>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ReceiveUpdates>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ReceiveUpdates>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ReceiveUpdates>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ReceiveUpdates>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ReceiveUpdates>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ReceiveUpdates>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ReceiveUpdates>>;
};

export type RecycleBin = {
  __typename: "RecycleBin";
  deletedAt: Scalars["Datetime"];
  name: Maybe<Scalars["String"]>;
  objectId: Scalars["UUID"];
  objectSubType: Maybe<Scalars["String"]>;
  objectType: Scalars["String"];
  /** Reads a single `Org` that is related to this `RecycleBin`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Space` that is related to this `RecycleBin`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Scalars["UUID"];
  thumbnail: Maybe<Scalars["String"]>;
};

/**
 * A condition to be used against `RecycleBin` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type RecycleBinCondition = {
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `objectSubType` field. */
  objectSubType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `objectType` field. */
  objectType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `thumbnail` field. */
  thumbnail?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `RecycleBin` object types. All fields are combined with a logical ‘and.’ */
export type RecycleBinFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RecycleBinFilter>>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RecycleBinFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `objectSubType` field. */
  objectSubType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `objectType` field. */
  objectType?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RecycleBinFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `thumbnail` field. */
  thumbnail?: InputMaybe<StringFilter>;
};

/** A connection to a list of `RecycleBin` values. */
export type RecycleBinsConnection = {
  __typename: "RecycleBinsConnection";
  /** A list of edges which contains the `RecycleBin` and cursor to aid in pagination. */
  edges: Array<RecycleBinsEdge>;
  /** A list of `RecycleBin` objects. */
  nodes: Array<RecycleBin>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecycleBin` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `RecycleBin` edge in the connection. */
export type RecycleBinsEdge = {
  __typename: "RecycleBinsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `RecycleBin` at the end of the edge. */
  node: RecycleBin;
};

/** Methods to use when ordering `RecycleBin`. */
export enum RecycleBinsOrderBy {
  DeletedAtAsc = "DELETED_AT_ASC",
  DeletedAtDesc = "DELETED_AT_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  ObjectIdAsc = "OBJECT_ID_ASC",
  ObjectIdDesc = "OBJECT_ID_DESC",
  ObjectSubTypeAsc = "OBJECT_SUB_TYPE_ASC",
  ObjectSubTypeDesc = "OBJECT_SUB_TYPE_DESC",
  ObjectTypeAsc = "OBJECT_TYPE_ASC",
  ObjectTypeDesc = "OBJECT_TYPE_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  ThumbnailAsc = "THUMBNAIL_ASC",
  ThumbnailDesc = "THUMBNAIL_DESC",
}

/** All input for the `refreshScreen` mutation. */
export type RefreshScreenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  device: Scalars["JSON"];
  screenId: Scalars["UUID"];
};

/** The output of our `refreshScreen` mutation. */
export type RefreshScreenPayload = {
  __typename: "RefreshScreenPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `refreshScreen` mutation. */
export type RefreshScreenPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

export type RefreshScreensByOrgIdPayload = {
  __typename: "RefreshScreensByOrgIdPayload";
  error: Maybe<Scalars["String"]>;
  refreshFailed: Maybe<Scalars["Int"]>;
  refreshSucceeded: Maybe<Scalars["Int"]>;
};

export type RefreshScreensByScreenIdsPayload = {
  __typename: "RefreshScreensByScreenIdsPayload";
  error: Maybe<Scalars["String"]>;
  refreshFailed: Maybe<Scalars["Int"]>;
  refreshSucceeded: Maybe<Scalars["Int"]>;
};

export type RefreshScreensBySpaceIdPayload = {
  __typename: "RefreshScreensBySpaceIdPayload";
  error: Maybe<Scalars["String"]>;
  refreshFailed: Maybe<Scalars["Int"]>;
  refreshSucceeded: Maybe<Scalars["Int"]>;
};

/** All input for the `regenerateToken` mutation. */
export type RegenerateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `regenerateToken` mutation. */
export type RegenerateTokenPayload = {
  __typename: "RegenerateTokenPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  tokenSecretPayload: Maybe<TokenSecretPayload>;
};

/** All input for the `removeFavoriteFile` mutation. */
export type RemoveFavoriteFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId: Scalars["UUID"];
};

/** The output of our `removeFavoriteFile` mutation. */
export type RemoveFavoriteFilePayload = {
  __typename: "RemoveFavoriteFilePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `removeFavoriteFile` mutation. */
export type RemoveFavoriteFilePayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `removeFavoriteFolder` mutation. */
export type RemoveFavoriteFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderId: Scalars["UUID"];
};

/** The output of our `removeFavoriteFolder` mutation. */
export type RemoveFavoriteFolderPayload = {
  __typename: "RemoveFavoriteFolderPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `removeFavoriteFolder` mutation. */
export type RemoveFavoriteFolderPayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `removeFavoriteLink` mutation. */
export type RemoveFavoriteLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  linkId: Scalars["UUID"];
};

/** The output of our `removeFavoriteLink` mutation. */
export type RemoveFavoriteLinkPayload = {
  __typename: "RemoveFavoriteLinkPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `removeFavoriteLink` mutation. */
export type RemoveFavoriteLinkPayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `removeFavoritePlaylist` mutation. */
export type RemoveFavoritePlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  playlistId: Scalars["UUID"];
};

/** The output of our `removeFavoritePlaylist` mutation. */
export type RemoveFavoritePlaylistPayload = {
  __typename: "RemoveFavoritePlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `removeFavoritePlaylist` mutation. */
export type RemoveFavoritePlaylistPayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `removeFavoriteSite` mutation. */
export type RemoveFavoriteSiteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  siteId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `removeFavoriteSite` mutation. */
export type RemoveFavoriteSitePayload = {
  __typename: "RemoveFavoriteSitePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorite: Maybe<Favorite>;
  /** An edge for our `Favorite`. May be used by Relay 1. */
  favoriteEdge: Maybe<FavoritesEdge>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Favorite`. */
  fileByFileIdAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Favorite`. */
  folderByFolderIdAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkId: Maybe<Link>;
  /** Reads a single `Link` that is related to this `Favorite`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `Favorite`. */
  orgByOrgId: Maybe<Org>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistId: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Favorite`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteId: Maybe<Site>;
  /** Reads a single `Site` that is related to this `Favorite`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `Favorite`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserId: Maybe<User>;
  /** Reads a single `User` that is related to this `Favorite`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `removeFavoriteSite` mutation. */
export type RemoveFavoriteSitePayloadFavoriteEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** All input for the `removeGroupPermissionSets` mutation. */
export type RemoveGroupPermissionSetsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `removeGroupPermissionSets` mutation. */
export type RemoveGroupPermissionSetsPayload = {
  __typename: "RemoveGroupPermissionSetsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  group: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge: Maybe<GroupsEdge>;
  /** Reads a single `Org` that is related to this `Group`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Group`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Group`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `removeGroupPermissionSets` mutation. */
export type RemoveGroupPermissionSetsPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `removeGroupSpacePermissionSets` mutation. */
export type RemoveGroupSpacePermissionSetsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `removeGroupSpacePermissionSets` mutation. */
export type RemoveGroupSpacePermissionSetsPayload = {
  __typename: "RemoveGroupSpacePermissionSetsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupId: Maybe<Group>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupIdAndOrgId: Maybe<Group>;
  groupSpace: Maybe<GroupSpace>;
  /** An edge for our `GroupSpace`. May be used by Relay 1. */
  groupSpaceEdge: Maybe<GroupSpacesEdge>;
  /** Reads a single `Org` that is related to this `GroupSpace`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `GroupSpace`. */
  spaceBySpaceId: Maybe<Space>;
};

/** The output of our `removeGroupSpacePermissionSets` mutation. */
export type RemoveGroupSpacePermissionSetsPayloadGroupSpaceEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

/** All input for the `removeOrgConnection` mutation. */
export type RemoveOrgConnectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

/** The output of our `removeOrgConnection` mutation. */
export type RemoveOrgConnectionPayload = {
  __typename: "RemoveOrgConnectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `OrgConnection`. */
  orgByOrgId: Maybe<Org>;
  orgConnection: Maybe<OrgConnection>;
  /** An edge for our `OrgConnection`. May be used by Relay 1. */
  orgConnectionEdge: Maybe<OrgConnectionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `OrgConnection`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `OrgConnection`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `removeOrgConnection` mutation. */
export type RemoveOrgConnectionPayloadOrgConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgConnectionsOrderBy>>;
};

/** All input for the `removeSplashTheme` mutation. */
export type RemoveSplashThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `removeSplashTheme` mutation. */
export type RemoveSplashThemePayload = {
  __typename: "RemoveSplashThemePayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `removeSplashTheme` mutation. */
export type RemoveSplashThemePayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `removeThemeLogo` mutation. */
export type RemoveThemeLogoInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `removeThemeLogo` mutation. */
export type RemoveThemeLogoPayload = {
  __typename: "RemoveThemeLogoPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileIdDraft: Maybe<File>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontIdDraft: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontIdDraft: Maybe<Font>;
  /** Reads a single `Org` that is related to this `Theme`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  theme: Maybe<Theme>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge: Maybe<ThemesEdge>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `removeThemeLogo` mutation. */
export type RemoveThemeLogoPayloadThemeEdgeArgs = {
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** All input for the `removeUserFromGroups` mutation. */
export type RemoveUserFromGroupsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `removeUserFromGroups` mutation. */
export type RemoveUserFromGroupsPayload = {
  __typename: "RemoveUserFromGroupsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  userGroups: Maybe<Array<UserGroup>>;
};

export type ReplaceScreenDeviceInput = {
  currentScreenId: Scalars["UUID"];
  pairingCode: Scalars["String"];
  spaceId: Scalars["UUID"];
};

export type ReplaceScreenDeviceResponse = {
  __typename: "ReplaceScreenDeviceResponse";
  screen: Maybe<Screen>;
};

/** All input for the `resetFeatureFlagOrg` mutation. */
export type ResetFeatureFlagOrgInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  key: Scalars["String"];
};

/** The output of our `resetFeatureFlagOrg` mutation. */
export type ResetFeatureFlagOrgPayload = {
  __typename: "ResetFeatureFlagOrgPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `FeatureFlag` that is related to this `FeatureFlagOrg`. */
  featureFlagByKey: Maybe<FeatureFlag>;
  featureFlagOrg: Maybe<FeatureFlagOrg>;
  /** An edge for our `FeatureFlagOrg`. May be used by Relay 1. */
  featureFlagOrgEdge: Maybe<FeatureFlagOrgsEdge>;
  /** Reads a single `Org` that is related to this `FeatureFlagOrg`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `resetFeatureFlagOrg` mutation. */
export type ResetFeatureFlagOrgPayloadFeatureFlagOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureFlagOrgsOrderBy>>;
};

/** All input for the `resetScreenLocationByIds` mutation. */
export type ResetScreenLocationByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `resetScreenLocationByIds` mutation. */
export type ResetScreenLocationByIdsPayload = {
  __typename: "ResetScreenLocationByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `resetWhiteLabelGlyph` mutation. */
export type ResetWhiteLabelGlyphInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `resetWhiteLabelGlyph` mutation. */
export type ResetWhiteLabelGlyphPayload = {
  __typename: "ResetWhiteLabelGlyphPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `WhiteLabel`. */
  fileByGlyphFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `WhiteLabel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `WhiteLabel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `WhiteLabel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `WhiteLabel`. */
  userByUpdatedBy: Maybe<User>;
  whiteLabel: Maybe<WhiteLabel>;
  /** An edge for our `WhiteLabel`. May be used by Relay 1. */
  whiteLabelEdge: Maybe<WhiteLabelsEdge>;
};

/** The output of our `resetWhiteLabelGlyph` mutation. */
export type ResetWhiteLabelGlyphPayloadWhiteLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

/** All input for the `resetWhiteLabel` mutation. */
export type ResetWhiteLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `resetWhiteLabel` mutation. */
export type ResetWhiteLabelPayload = {
  __typename: "ResetWhiteLabelPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `WhiteLabel`. */
  fileByGlyphFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `WhiteLabel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `WhiteLabel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `WhiteLabel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `WhiteLabel`. */
  userByUpdatedBy: Maybe<User>;
  whiteLabel: Maybe<WhiteLabel>;
  /** An edge for our `WhiteLabel`. May be used by Relay 1. */
  whiteLabelEdge: Maybe<WhiteLabelsEdge>;
};

/** The output of our `resetWhiteLabel` mutation. */
export type ResetWhiteLabelPayloadWhiteLabelEdgeArgs = {
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

/** All input for the `restoreAllItems` mutation. */
export type RestoreAllItemsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `restoreAllItems` mutation. */
export type RestoreAllItemsPayload = {
  __typename: "RestoreAllItemsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  recycleBins: Maybe<Array<Maybe<RecycleBin>>>;
};

/** All input for the `restoreByIdsAndSpaceId` mutation. */
export type RestoreByIdsAndSpaceIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `restoreByIdsAndSpaceId` mutation. */
export type RestoreByIdsAndSpaceIdPayload = {
  __typename: "RestoreByIdsAndSpaceIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  recycleBins: Maybe<Array<Maybe<RecycleBin>>>;
};

/** All input for the `revertDraftChannel` mutation. */
export type RevertDraftChannelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `revertDraftChannel` mutation. */
export type RevertDraftChannelPayload = {
  __typename: "RevertDraftChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `revertDraftChannel` mutation. */
export type RevertDraftChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `revertDraftPlaylist` mutation. */
export type RevertDraftPlaylistInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `revertDraftPlaylist` mutation. */
export type RevertDraftPlaylistPayload = {
  __typename: "RevertDraftPlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `revertDraftPlaylist` mutation. */
export type RevertDraftPlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `revertTheme` mutation. */
export type RevertThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `revertTheme` mutation. */
export type RevertThemePayload = {
  __typename: "RevertThemePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileIdDraft: Maybe<File>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontIdDraft: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontIdDraft: Maybe<Font>;
  /** Reads a single `Org` that is related to this `Theme`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  theme: Maybe<Theme>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge: Maybe<ThemesEdge>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `revertTheme` mutation. */
export type RevertThemePayloadThemeEdgeArgs = {
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

export type RuntimeJwtPayload = {
  __typename: "RuntimeJwtPayload";
  expiresAt: Maybe<Scalars["Int"]>;
  signedRuntimeToken: Maybe<Scalars["String"]>;
  tokenType: Maybe<Scalars["String"]>;
};

export type RuntimeJwtRequestInput = {
  appId: Scalars["String"];
  appInstanceId: Scalars["String"];
  name: Scalars["String"];
  screenId?: InputMaybe<Scalars["String"]>;
};

export type SaveInvoicePaymentInput = {
  spaceId?: InputMaybe<Scalars["String"]>;
};

export type SaveInvoicePaymentPayload = {
  __typename: "SaveInvoicePaymentPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

/** A screen detail for each organization. */
export type Screen = Node & {
  __typename: "Screen";
  additionalDeviceSettings: Scalars["JSON"];
  additionalPlayerSettings: Scalars["JSON"];
  /** Get apps that contained in a screen. */
  appInstancesByScreenId: AppInstancesConnection;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByFromScreenAndOrgId: AssociationsConnection;
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  castId: Maybe<Scalars["UUID"]>;
  /** Get channel that contained in a screen. */
  channelsByScreenId: ChannelsConnection;
  /** If the screen is online, the timestamp of when the screen connected. */
  connectedAt: Maybe<Scalars["Datetime"]>;
  /** The json data of content which is channel id. */
  content: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Json data of device information from screencloud eg id, device name, serial. */
  device: Maybe<Scalars["JSON"]>;
  /** @omit: backup of device-column, to be removed in the future */
  deviceBak: Maybe<Scalars["JSON"]>;
  deviceHostname: Maybe<Scalars["String"]>;
  deviceId: Scalars["String"];
  deviceInfo: Maybe<Scalars["JSON"]>;
  deviceIpAddress: Maybe<Scalars["String"]>;
  deviceModel: Maybe<Scalars["String"]>;
  devicePlatform: Maybe<Scalars["String"]>;
  deviceProvider: Maybe<Scalars["String"]>;
  deviceRaw: Maybe<Scalars["JSON"]>;
  deviceRelease: Maybe<Scalars["String"]>;
  /** If the screen is not online, the timestamp of when the screen disconnected. */
  disconnectedAt: Maybe<Scalars["Datetime"]>;
  displayErrorMode: Maybe<Scalars["String"]>;
  /** The key value pairs data for screen. */
  env: Scalars["JSON"];
  /** Get files that contained in a screen. */
  filesByScreenId: FilesConnection;
  highResolutionImage: Scalars["Boolean"];
  /** The primary unique identifier for the screen. */
  id: Scalars["UUID"];
  /** TRUE if the screen is online. */
  isConnected: Scalars["Boolean"];
  /** Get screen notification active status */
  isNotificationActive: Maybe<Scalars["Boolean"]>;
  /** Get links that contained in a screen. */
  linksByScreenId: LinksConnection;
  messages: Maybe<Array<Maybe<Scalars["JSON"]>>>;
  /** The name of the screen. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** A long text input for notes anything. */
  notes: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `NotificationRule`. */
  notificationRulesByScreenIdAndOrgId: NotificationRulesConnection;
  operatingHours: Maybe<Scalars["JSON"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** The id of the organization that screen belong to. */
  orgId: Scalars["UUID"];
  playbackMode: Scalars["String"];
  playerHeight: Maybe<Scalars["Int"]>;
  playerRelease: Maybe<Scalars["String"]>;
  playerTimezone: Scalars["String"];
  playerWidth: Maybe<Scalars["Int"]>;
  /** Get playlist that contained in a screen. */
  playlistsByScreenId: PlaylistsConnection;
  /** A preferences for screen eg. operating hours */
  preferences: Scalars["JSON"];
  /** @omit: backup of preferences-column, to be removed in the future */
  preferencesBak: Maybe<Scalars["JSON"]>;
  /** To indecate the screen is in preview mode, no charge. */
  preview: Scalars["Boolean"];
  /** Rotation enum for 0, 90, 180 and 270. */
  rotation: ScreenRotation;
  /** Reads and enables pagination through a set of `ScreenConnectionHistory`. */
  screenConnectionHistoriesByScreenId: ScreenConnectionHistoriesConnection;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByScreenId: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByScreenIdAndOrgId: ScreenContentHistoriesConnection;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** The id of the screen_group that screen belong to. */
  screenGroupId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `ScreenNote`. */
  screenNotesByScreenId: ScreenNotesConnection;
  /** Get Sites that are contained in a screen. */
  sitesByScreenId: SitesConnection;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** The id of the space that screen belong to. */
  spaceId: Scalars["UUID"];
  /** Enum type of screen' status. */
  status: ScreenStatus;
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByToScreenAndOrgId: TagAssociationsConnection;
  /** The json array of tags for the screen. */
  tags: Array<Maybe<Scalars["String"]>>;
  timezone: Maybe<Scalars["String"]>;
  timezoneOverride: Scalars["String"];
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `UserActionLog`. */
  userActionLogsByScreenId: UserActionLogsConnection;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
  videoSource: Scalars["String"];
};

/** A screen detail for each organization. */
export type ScreenAppInstancesByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A screen detail for each organization. */
export type ScreenAssociationsByFromScreenAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** A screen detail for each organization. */
export type ScreenChannelsByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A screen detail for each organization. */
export type ScreenFilesByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A screen detail for each organization. */
export type ScreenLinksByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A screen detail for each organization. */
export type ScreenNotificationRulesByScreenIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationRuleCondition>;
  filter: InputMaybe<NotificationRuleFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** A screen detail for each organization. */
export type ScreenPlaylistsByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A screen detail for each organization. */
export type ScreenScreenConnectionHistoriesByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenConnectionHistoryCondition>;
  filter: InputMaybe<ScreenConnectionHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenConnectionHistoriesOrderBy>>;
};

/** A screen detail for each organization. */
export type ScreenScreenContentHistoriesByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** A screen detail for each organization. */
export type ScreenScreenContentHistoriesByScreenIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** A screen detail for each organization. */
export type ScreenScreenNotesByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenNoteCondition>;
  filter: InputMaybe<ScreenNoteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** A screen detail for each organization. */
export type ScreenSitesByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A screen detail for each organization. */
export type ScreenTagAssociationsByToScreenAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** A screen detail for each organization. */
export type ScreenUserActionLogsByScreenIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserActionLogCondition>;
  filter: InputMaybe<UserActionLogFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserActionLogsOrderBy>>;
};

/** All input for the `screenCastStop` mutation. */
export type ScreenCastStopInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenId: Scalars["UUID"];
};

/** The output of our `screenCastStop` mutation. */
export type ScreenCastStopPayload = {
  __typename: "ScreenCastStopPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `screenCastStop` mutation. */
export type ScreenCastStopPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** A condition to be used against `Screen` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ScreenCondition = {
  /** Checks for equality with the object’s `additionalDeviceSettings` field. */
  additionalDeviceSettings?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `additionalPlayerSettings` field. */
  additionalPlayerSettings?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `castId` field. */
  castId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `connectedAt` field. */
  connectedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `device` field. */
  device?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `deviceBak` field. */
  deviceBak?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `deviceHostname` field. */
  deviceHostname?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `deviceId` field. */
  deviceId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `deviceInfo` field. */
  deviceInfo?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `deviceIpAddress` field. */
  deviceIpAddress?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `deviceModel` field. */
  deviceModel?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `devicePlatform` field. */
  devicePlatform?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `deviceProvider` field. */
  deviceProvider?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `deviceRaw` field. */
  deviceRaw?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `deviceRelease` field. */
  deviceRelease?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `disconnectedAt` field. */
  disconnectedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `displayErrorMode` field. */
  displayErrorMode?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `env` field. */
  env?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `highResolutionImage` field. */
  highResolutionImage?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isConnected` field. */
  isConnected?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `messages` field. */
  messages?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `operatingHours` field. */
  operatingHours?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `playbackMode` field. */
  playbackMode?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `playerHeight` field. */
  playerHeight?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `playerRelease` field. */
  playerRelease?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `playerTimezone` field. */
  playerTimezone?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `playerWidth` field. */
  playerWidth?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `preferences` field. */
  preferences?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `preferencesBak` field. */
  preferencesBak?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `preview` field. */
  preview?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `rotation` field. */
  rotation?: InputMaybe<ScreenRotation>;
  /** Checks for equality with the object’s `screenGroupId` field. */
  screenGroupId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<ScreenStatus>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `timezoneOverride` field. */
  timezoneOverride?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `videoSource` field. */
  videoSource?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `ScreenConnectionHistory` values. */
export type ScreenConnectionHistoriesConnection = {
  __typename: "ScreenConnectionHistoriesConnection";
  /** A list of edges which contains the `ScreenConnectionHistory` and cursor to aid in pagination. */
  edges: Array<ScreenConnectionHistoriesEdge>;
  /** A list of `ScreenConnectionHistory` objects. */
  nodes: Array<ScreenConnectionHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenConnectionHistory` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `ScreenConnectionHistory` edge in the connection. */
export type ScreenConnectionHistoriesEdge = {
  __typename: "ScreenConnectionHistoriesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `ScreenConnectionHistory` at the end of the edge. */
  node: ScreenConnectionHistory;
};

/** Methods to use when ordering `ScreenConnectionHistory`. */
export enum ScreenConnectionHistoriesOrderBy {
  ConnectedAtAsc = "CONNECTED_AT_ASC",
  ConnectedAtDesc = "CONNECTED_AT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DisconnectedAtAsc = "DISCONNECTED_AT_ASC",
  DisconnectedAtDesc = "DISCONNECTED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ScreenIdAsc = "SCREEN_ID_ASC",
  ScreenIdDesc = "SCREEN_ID_DESC",
}

export type ScreenConnectionHistory = Node & {
  __typename: "ScreenConnectionHistory";
  connectedAt: Maybe<Scalars["Datetime"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  disconnectedAt: Maybe<Scalars["Datetime"]>;
  id: Scalars["BigInt"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `ScreenConnectionHistory`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Screen` that is related to this `ScreenConnectionHistory`. */
  screenByScreenId: Maybe<Screen>;
  screenId: Scalars["UUID"];
};

/**
 * A condition to be used against `ScreenConnectionHistory` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ScreenConnectionHistoryCondition = {
  /** Checks for equality with the object’s `connectedAt` field. */
  connectedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `disconnectedAt` field. */
  disconnectedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["BigInt"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `screenId` field. */
  screenId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `ScreenConnectionHistory` object types. All fields are combined with a logical ‘and.’ */
export type ScreenConnectionHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenConnectionHistoryFilter>>;
  /** Filter by the object’s `connectedAt` field. */
  connectedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `disconnectedAt` field. */
  disconnectedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenConnectionHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenConnectionHistoryFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `screenId` field. */
  screenId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `ScreenContentHistory` values. */
export type ScreenContentHistoriesConnection = {
  __typename: "ScreenContentHistoriesConnection";
  /** A list of edges which contains the `ScreenContentHistory` and cursor to aid in pagination. */
  edges: Array<ScreenContentHistoriesEdge>;
  /** A list of `ScreenContentHistory` objects. */
  nodes: Array<ScreenContentHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenContentHistory` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `ScreenContentHistory` edge in the connection. */
export type ScreenContentHistoriesEdge = {
  __typename: "ScreenContentHistoriesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `ScreenContentHistory` at the end of the edge. */
  node: ScreenContentHistory;
};

/** Methods to use when ordering `ScreenContentHistory`. */
export enum ScreenContentHistoriesOrderBy {
  AppInstanceIdAsc = "APP_INSTANCE_ID_ASC",
  AppInstanceIdDesc = "APP_INSTANCE_ID_DESC",
  ChannelIdAsc = "CHANNEL_ID_ASC",
  ChannelIdDesc = "CHANNEL_ID_DESC",
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  FileIdAsc = "FILE_ID_ASC",
  FileIdDesc = "FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LinkIdAsc = "LINK_ID_ASC",
  LinkIdDesc = "LINK_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PlaylistIdAsc = "PLAYLIST_ID_ASC",
  PlaylistIdDesc = "PLAYLIST_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ScreenIdAsc = "SCREEN_ID_ASC",
  ScreenIdDesc = "SCREEN_ID_DESC",
  SiteIdAsc = "SITE_ID_ASC",
  SiteIdDesc = "SITE_ID_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
}

/** A screen content history. */
export type ScreenContentHistory = Node & {
  __typename: "ScreenContentHistory";
  /** Reads a single `AppInstance` that is related to this `ScreenContentHistory`. */
  appInstanceByAppInstanceIdAndOrgId: Maybe<AppInstance>;
  appInstanceId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Channel` that is related to this `ScreenContentHistory`. */
  channelByChannelIdAndOrgId: Maybe<Channel>;
  channelId: Maybe<Scalars["UUID"]>;
  content: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `File` that is related to this `ScreenContentHistory`. */
  fileByFileIdAndOrgId: Maybe<File>;
  fileId: Maybe<Scalars["UUID"]>;
  /** The primary unique identifier for the screen content record. */
  id: Scalars["UUID"];
  /** Reads a single `Link` that is related to this `ScreenContentHistory`. */
  linkByLinkIdAndOrgId: Maybe<Link>;
  linkId: Maybe<Scalars["UUID"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `ScreenContentHistory`. */
  orgByOrgId: Maybe<Org>;
  /** The id of the organization that screen belong to. */
  orgId: Scalars["UUID"];
  /** Reads a single `Playlist` that is related to this `ScreenContentHistory`. */
  playlistByPlaylistIdAndOrgId: Maybe<Playlist>;
  playlistId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Screen` that is related to this `ScreenContentHistory`. */
  screenByScreenId: Maybe<Screen>;
  /** Reads a single `Screen` that is related to this `ScreenContentHistory`. */
  screenByScreenIdAndOrgId: Maybe<Screen>;
  screenId: Scalars["UUID"];
  /** Reads a single `Site` that is related to this `ScreenContentHistory`. */
  siteBySiteIdAndOrgId: Maybe<Site>;
  siteId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Space` that is related to this `ScreenContentHistory`. */
  spaceBySpaceId: Maybe<Space>;
  /** The id of the space that screen belong to. */
  spaceId: Scalars["UUID"];
  /** Reads a single `User` that is related to this `ScreenContentHistory`. */
  userByCreatedBy: Maybe<User>;
};

/**
 * A condition to be used against `ScreenContentHistory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ScreenContentHistoryCondition = {
  /** Checks for equality with the object’s `appInstanceId` field. */
  appInstanceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `channelId` field. */
  channelId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `linkId` field. */
  linkId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `playlistId` field. */
  playlistId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `screenId` field. */
  screenId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `siteId` field. */
  siteId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `ScreenContentHistory` object types. All fields are combined with a logical ‘and.’ */
export type ScreenContentHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenContentHistoryFilter>>;
  /** Filter by the object’s `appInstanceId` field. */
  appInstanceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `channelId` field. */
  channelId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `linkId` field. */
  linkId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenContentHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenContentHistoryFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `playlistId` field. */
  playlistId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `screenId` field. */
  screenId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `siteId` field. */
  siteId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
};

export enum ScreenContentType {
  App = "APP",
  Channel = "CHANNEL",
  File = "FILE",
  Link = "LINK",
  Playlist = "PLAYLIST",
  Site = "SITE",
}

/** A filter to be used against `Screen` object types. All fields are combined with a logical ‘and.’ */
export type ScreenFilter = {
  /** Filter by the object’s `additionalDeviceSettings` field. */
  additionalDeviceSettings?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `additionalPlayerSettings` field. */
  additionalPlayerSettings?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenFilter>>;
  /** Filter by the object’s `castId` field. */
  castId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `connectedAt` field. */
  connectedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `device` field. */
  device?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `deviceBak` field. */
  deviceBak?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `deviceHostname` field. */
  deviceHostname?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deviceId` field. */
  deviceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deviceInfo` field. */
  deviceInfo?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `deviceIpAddress` field. */
  deviceIpAddress?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deviceModel` field. */
  deviceModel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `devicePlatform` field. */
  devicePlatform?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deviceProvider` field. */
  deviceProvider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deviceRaw` field. */
  deviceRaw?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `deviceRelease` field. */
  deviceRelease?: InputMaybe<StringFilter>;
  /** Filter by the object’s `disconnectedAt` field. */
  disconnectedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `displayErrorMode` field. */
  displayErrorMode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `env` field. */
  env?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `highResolutionImage` field. */
  highResolutionImage?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isConnected` field. */
  isConnected?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messages` field. */
  messages?: InputMaybe<JsonListFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `operatingHours` field. */
  operatingHours?: InputMaybe<JsonFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `playbackMode` field. */
  playbackMode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `playerHeight` field. */
  playerHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `playerRelease` field. */
  playerRelease?: InputMaybe<StringFilter>;
  /** Filter by the object’s `playerTimezone` field. */
  playerTimezone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `playerWidth` field. */
  playerWidth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `preferences` field. */
  preferences?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `preferencesBak` field. */
  preferencesBak?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `preview` field. */
  preview?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `rotation` field. */
  rotation?: InputMaybe<ScreenRotationFilter>;
  /** Filter by the object’s `screenGroupId` field. */
  screenGroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<ScreenStatusFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timezoneOverride` field. */
  timezoneOverride?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `videoSource` field. */
  videoSource?: InputMaybe<StringFilter>;
};

/** A space detail for screen_group. */
export type ScreenGroup = Node & {
  __typename: "ScreenGroup";
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** The key value pairs data for screen_group. */
  env: Maybe<Scalars["JSON"]>;
  /** The primary unique identifier for the screen_group. */
  id: Scalars["UUID"];
  /** True if screen group is the default implicitly created screen group on a certain space. */
  isPrimary: Scalars["Boolean"];
  /** The name of the screen_group. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `ScreenGroup`. */
  orgByOrgId: Maybe<Org>;
  /** The id of the organization that screen_group belong to. */
  orgId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `Screen`. */
  screensByScreenGroupId: ScreensConnection;
  /** Reads and enables pagination through a set of `Screen`. */
  screensByScreenGroupIdAndOrgId: ScreensConnection;
  /** Reads a single `Space` that is related to this `ScreenGroup`. */
  spaceBySpaceId: Maybe<Space>;
  /** The id of the space that screen_group belong to. */
  spaceId: Scalars["UUID"];
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `ScreenGroup`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `ScreenGroup`. */
  userByUpdatedBy: Maybe<User>;
};

/** A space detail for screen_group. */
export type ScreenGroupScreensByScreenGroupIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** A space detail for screen_group. */
export type ScreenGroupScreensByScreenGroupIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/**
 * A condition to be used against `ScreenGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ScreenGroupCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `env` field. */
  env?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isPrimary` field. */
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `ScreenGroup` object types. All fields are combined with a logical ‘and.’ */
export type ScreenGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenGroupFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `env` field. */
  env?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isPrimary` field. */
  isPrimary?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenGroupFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `ScreenGroup` values. */
export type ScreenGroupsConnection = {
  __typename: "ScreenGroupsConnection";
  /** A list of edges which contains the `ScreenGroup` and cursor to aid in pagination. */
  edges: Array<ScreenGroupsEdge>;
  /** A list of `ScreenGroup` objects. */
  nodes: Array<ScreenGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenGroup` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `ScreenGroup` edge in the connection. */
export type ScreenGroupsEdge = {
  __typename: "ScreenGroupsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `ScreenGroup` at the end of the edge. */
  node: ScreenGroup;
};

/** Methods to use when ordering `ScreenGroup`. */
export enum ScreenGroupsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  EnvAsc = "ENV_ASC",
  EnvDesc = "ENV_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsPrimaryAsc = "IS_PRIMARY_ASC",
  IsPrimaryDesc = "IS_PRIMARY_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

/** Each screen can have note(s) show up on the Studio CMS */
export type ScreenNote = Node & {
  __typename: "ScreenNote";
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  note: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `ScreenNote`. */
  orgByOrgId: Maybe<Org>;
  orgId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Screen` that is related to this `ScreenNote`. */
  screenByScreenId: Maybe<Screen>;
  screenId: Maybe<Scalars["UUID"]>;
  /** Reads a single `Space` that is related to this `ScreenNote`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Maybe<Scalars["UUID"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `ScreenNote`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `ScreenNote`. */
  userByUpdatedBy: Maybe<User>;
};

/**
 * A condition to be used against `ScreenNote` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ScreenNoteCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `note` field. */
  note?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `screenId` field. */
  screenId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `ScreenNote` object types. All fields are combined with a logical ‘and.’ */
export type ScreenNoteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ScreenNoteFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ScreenNoteFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ScreenNoteFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `screenId` field. */
  screenId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `ScreenNote` values. */
export type ScreenNotesConnection = {
  __typename: "ScreenNotesConnection";
  /** A list of edges which contains the `ScreenNote` and cursor to aid in pagination. */
  edges: Array<ScreenNotesEdge>;
  /** A list of `ScreenNote` objects. */
  nodes: Array<ScreenNote>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScreenNote` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `ScreenNote` edge in the connection. */
export type ScreenNotesEdge = {
  __typename: "ScreenNotesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `ScreenNote` at the end of the edge. */
  node: ScreenNote;
};

/** Methods to use when ordering `ScreenNote`. */
export enum ScreenNotesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  NoteAsc = "NOTE_ASC",
  NoteDesc = "NOTE_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ScreenIdAsc = "SCREEN_ID_ASC",
  ScreenIdDesc = "SCREEN_ID_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

export enum ScreenRotation {
  Rotation_0 = "ROTATION_0",
  Rotation_90 = "ROTATION_90",
  Rotation_180 = "ROTATION_180",
  Rotation_270 = "ROTATION_270",
}

/** A filter to be used against ScreenRotation fields. All fields are combined with a logical ‘and.’ */
export type ScreenRotationFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ScreenRotation>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ScreenRotation>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ScreenRotation>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ScreenRotation>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ScreenRotation>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ScreenRotation>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ScreenRotation>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ScreenRotation>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ScreenRotation>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ScreenRotation>>;
};

export enum ScreenStatus {
  Live = "LIVE",
  Paused = "PAUSED",
  Pending = "PENDING",
  Suspended = "SUSPENDED",
}

/** A filter to be used against ScreenStatus fields. All fields are combined with a logical ‘and.’ */
export type ScreenStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ScreenStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ScreenStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ScreenStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ScreenStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ScreenStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ScreenStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ScreenStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ScreenStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ScreenStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ScreenStatus>>;
};

/** A connection to a list of `Screen` values. */
export type ScreensConnection = {
  __typename: "ScreensConnection";
  /** A list of edges which contains the `Screen` and cursor to aid in pagination. */
  edges: Array<ScreensEdge>;
  /** A list of `Screen` objects. */
  nodes: Array<Screen>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Screen` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Screen` edge in the connection. */
export type ScreensEdge = {
  __typename: "ScreensEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Screen` at the end of the edge. */
  node: Screen;
};

/** Methods to use when ordering `Screen`. */
export enum ScreensOrderBy {
  AdditionalDeviceSettingsAsc = "ADDITIONAL_DEVICE_SETTINGS_ASC",
  AdditionalDeviceSettingsDesc = "ADDITIONAL_DEVICE_SETTINGS_DESC",
  AdditionalPlayerSettingsAsc = "ADDITIONAL_PLAYER_SETTINGS_ASC",
  AdditionalPlayerSettingsDesc = "ADDITIONAL_PLAYER_SETTINGS_DESC",
  CastIdAsc = "CAST_ID_ASC",
  CastIdDesc = "CAST_ID_DESC",
  ConnectedAtAsc = "CONNECTED_AT_ASC",
  ConnectedAtDesc = "CONNECTED_AT_DESC",
  ContentAsc = "CONTENT_ASC",
  ContentDesc = "CONTENT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DeviceAsc = "DEVICE_ASC",
  DeviceBakAsc = "DEVICE_BAK_ASC",
  DeviceBakDesc = "DEVICE_BAK_DESC",
  DeviceDesc = "DEVICE_DESC",
  DeviceHostnameAsc = "DEVICE_HOSTNAME_ASC",
  DeviceHostnameDesc = "DEVICE_HOSTNAME_DESC",
  DeviceIdAsc = "DEVICE_ID_ASC",
  DeviceIdDesc = "DEVICE_ID_DESC",
  DeviceInfoAsc = "DEVICE_INFO_ASC",
  DeviceInfoDesc = "DEVICE_INFO_DESC",
  DeviceIpAddressAsc = "DEVICE_IP_ADDRESS_ASC",
  DeviceIpAddressDesc = "DEVICE_IP_ADDRESS_DESC",
  DeviceModelAsc = "DEVICE_MODEL_ASC",
  DeviceModelDesc = "DEVICE_MODEL_DESC",
  DevicePlatformAsc = "DEVICE_PLATFORM_ASC",
  DevicePlatformDesc = "DEVICE_PLATFORM_DESC",
  DeviceProviderAsc = "DEVICE_PROVIDER_ASC",
  DeviceProviderDesc = "DEVICE_PROVIDER_DESC",
  DeviceRawAsc = "DEVICE_RAW_ASC",
  DeviceRawDesc = "DEVICE_RAW_DESC",
  DeviceReleaseAsc = "DEVICE_RELEASE_ASC",
  DeviceReleaseDesc = "DEVICE_RELEASE_DESC",
  DisconnectedAtAsc = "DISCONNECTED_AT_ASC",
  DisconnectedAtDesc = "DISCONNECTED_AT_DESC",
  DisplayErrorModeAsc = "DISPLAY_ERROR_MODE_ASC",
  DisplayErrorModeDesc = "DISPLAY_ERROR_MODE_DESC",
  EnvAsc = "ENV_ASC",
  EnvDesc = "ENV_DESC",
  HighResolutionImageAsc = "HIGH_RESOLUTION_IMAGE_ASC",
  HighResolutionImageDesc = "HIGH_RESOLUTION_IMAGE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsConnectedAsc = "IS_CONNECTED_ASC",
  IsConnectedDesc = "IS_CONNECTED_DESC",
  MessagesAsc = "MESSAGES_ASC",
  MessagesDesc = "MESSAGES_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  NotesAsc = "NOTES_ASC",
  NotesDesc = "NOTES_DESC",
  OperatingHoursAsc = "OPERATING_HOURS_ASC",
  OperatingHoursDesc = "OPERATING_HOURS_DESC",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PlaybackModeAsc = "PLAYBACK_MODE_ASC",
  PlaybackModeDesc = "PLAYBACK_MODE_DESC",
  PlayerHeightAsc = "PLAYER_HEIGHT_ASC",
  PlayerHeightDesc = "PLAYER_HEIGHT_DESC",
  PlayerReleaseAsc = "PLAYER_RELEASE_ASC",
  PlayerReleaseDesc = "PLAYER_RELEASE_DESC",
  PlayerTimezoneAsc = "PLAYER_TIMEZONE_ASC",
  PlayerTimezoneDesc = "PLAYER_TIMEZONE_DESC",
  PlayerWidthAsc = "PLAYER_WIDTH_ASC",
  PlayerWidthDesc = "PLAYER_WIDTH_DESC",
  PreferencesAsc = "PREFERENCES_ASC",
  PreferencesBakAsc = "PREFERENCES_BAK_ASC",
  PreferencesBakDesc = "PREFERENCES_BAK_DESC",
  PreferencesDesc = "PREFERENCES_DESC",
  PreviewAsc = "PREVIEW_ASC",
  PreviewDesc = "PREVIEW_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RotationAsc = "ROTATION_ASC",
  RotationDesc = "ROTATION_DESC",
  ScreenGroupIdAsc = "SCREEN_GROUP_ID_ASC",
  ScreenGroupIdDesc = "SCREEN_GROUP_ID_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  TimezoneAsc = "TIMEZONE_ASC",
  TimezoneDesc = "TIMEZONE_DESC",
  TimezoneOverrideAsc = "TIMEZONE_OVERRIDE_ASC",
  TimezoneOverrideDesc = "TIMEZONE_OVERRIDE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  VideoSourceAsc = "VIDEO_SOURCE_ASC",
  VideoSourceDesc = "VIDEO_SOURCE_DESC",
}

export type Screenshot = {
  __typename: "Screenshot";
  action_log_id: Maybe<Scalars["String"]>;
  app_id: Maybe<Scalars["String"]>;
  created_at: Maybe<Scalars["String"]>;
  expires_at: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["String"]>;
  player_id: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  updated_at: Maybe<Scalars["String"]>;
  url: Maybe<Scalars["String"]>;
};

export type ScreenshotWithUserInfo = {
  __typename: "ScreenshotWithUserInfo";
  action_log_id: Maybe<Scalars["String"]>;
  app_id: Maybe<Scalars["String"]>;
  created_at: Maybe<Scalars["String"]>;
  expires_at: Maybe<Scalars["String"]>;
  family_name: Maybe<Scalars["String"]>;
  given_name: Maybe<Scalars["String"]>;
  id: Maybe<Scalars["String"]>;
  player_id: Maybe<Scalars["String"]>;
  status: Maybe<Scalars["String"]>;
  updated_at: Maybe<Scalars["String"]>;
  url: Maybe<Scalars["String"]>;
};

export type ScreenshotsByScreenIdInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  screenId: Scalars["String"];
};

export type ScreenshotsByScreenIdPayload = {
  __typename: "ScreenshotsByScreenIdPayload";
  screenshots: Maybe<Array<Maybe<ScreenshotWithUserInfo>>>;
  totalCount: Maybe<Scalars["Int"]>;
};

export type SendCommandInput = {
  command: CommandType;
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

export type SendCommandToScreensByScreenIdsPayload = {
  __typename: "SendCommandToScreensByScreenIdsPayload";
  commandFailed: Maybe<Scalars["Int"]>;
  commandSucceeded: Maybe<Scalars["Int"]>;
  error: Maybe<Scalars["String"]>;
};

/** All input for the `setBotPermissionsByUserIds` mutation. */
export type SetBotPermissionsByUserIdsInput = {
  botId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `setBotPermissionsByUserIds` mutation. */
export type SetBotPermissionsByUserIdsPayload = {
  __typename: "SetBotPermissionsByUserIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `setBotPermissionsByUserIds` mutation. */
export type SetBotPermissionsByUserIdsPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `setFeatureFlagOrg` mutation. */
export type SetFeatureFlagOrgInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  customValue: Scalars["Boolean"];
  key: Scalars["String"];
};

/** The output of our `setFeatureFlagOrg` mutation. */
export type SetFeatureFlagOrgPayload = {
  __typename: "SetFeatureFlagOrgPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `FeatureFlag` that is related to this `FeatureFlagOrg`. */
  featureFlagByKey: Maybe<FeatureFlag>;
  featureFlagOrg: Maybe<FeatureFlagOrg>;
  /** An edge for our `FeatureFlagOrg`. May be used by Relay 1. */
  featureFlagOrgEdge: Maybe<FeatureFlagOrgsEdge>;
  /** Reads a single `Org` that is related to this `FeatureFlagOrg`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `setFeatureFlagOrg` mutation. */
export type SetFeatureFlagOrgPayloadFeatureFlagOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<FeatureFlagOrgsOrderBy>>;
};

/** All input for the `setGroupIsDefault` mutation. */
export type SetGroupIsDefaultInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exclusive?: InputMaybe<Scalars["Boolean"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]>;
};

/** The output of our `setGroupIsDefault` mutation. */
export type SetGroupIsDefaultPayload = {
  __typename: "SetGroupIsDefaultPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  group: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge: Maybe<GroupsEdge>;
  /** Reads a single `Org` that is related to this `Group`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Group`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Group`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setGroupIsDefault` mutation. */
export type SetGroupIsDefaultPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `setOrgDefaultChannelTheme` mutation. */
export type SetOrgDefaultChannelThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `setOrgDefaultChannelTheme` mutation. */
export type SetOrgDefaultChannelThemePayload = {
  __typename: "SetOrgDefaultChannelThemePayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setOrgDefaultChannelTheme` mutation. */
export type SetOrgDefaultChannelThemePayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `setOrgDefaultUserPermissions` mutation. */
export type SetOrgDefaultUserPermissionsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  permissions: Array<InputMaybe<Scalars["String"]>>;
};

/** The output of our `setOrgDefaultUserPermissions` mutation. */
export type SetOrgDefaultUserPermissionsPayload = {
  __typename: "SetOrgDefaultUserPermissionsPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setOrgDefaultUserPermissions` mutation. */
export type SetOrgDefaultUserPermissionsPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `setOrgSplashChannelTheme` mutation. */
export type SetOrgSplashChannelThemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  themeId: Scalars["UUID"];
};

/** The output of our `setOrgSplashChannelTheme` mutation. */
export type SetOrgSplashChannelThemePayload = {
  __typename: "SetOrgSplashChannelThemePayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setOrgSplashChannelTheme` mutation. */
export type SetOrgSplashChannelThemePayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `setScreenContentByAppInstanceId` mutation. */
export type SetScreenContentByAppInstanceIdInput = {
  appInstanceId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenId: Scalars["UUID"];
};

/** The output of our `setScreenContentByAppInstanceId` mutation. */
export type SetScreenContentByAppInstanceIdPayload = {
  __typename: "SetScreenContentByAppInstanceIdPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setScreenContentByAppInstanceId` mutation. */
export type SetScreenContentByAppInstanceIdPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `setScreenContentByChannelId` mutation. */
export type SetScreenContentByChannelIdInput = {
  channelId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenId: Scalars["UUID"];
};

/** The output of our `setScreenContentByChannelId` mutation. */
export type SetScreenContentByChannelIdPayload = {
  __typename: "SetScreenContentByChannelIdPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setScreenContentByChannelId` mutation. */
export type SetScreenContentByChannelIdPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `setScreenContentByFileId` mutation. */
export type SetScreenContentByFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId: Scalars["UUID"];
  screenId: Scalars["UUID"];
};

/** The output of our `setScreenContentByFileId` mutation. */
export type SetScreenContentByFileIdPayload = {
  __typename: "SetScreenContentByFileIdPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setScreenContentByFileId` mutation. */
export type SetScreenContentByFileIdPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `setScreenContentByLinkId` mutation. */
export type SetScreenContentByLinkIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  linkId: Scalars["UUID"];
  screenId: Scalars["UUID"];
};

/** The output of our `setScreenContentByLinkId` mutation. */
export type SetScreenContentByLinkIdPayload = {
  __typename: "SetScreenContentByLinkIdPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setScreenContentByLinkId` mutation. */
export type SetScreenContentByLinkIdPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `setScreenContentByPlaylistId` mutation. */
export type SetScreenContentByPlaylistIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  playlistId: Scalars["UUID"];
  screenId: Scalars["UUID"];
};

/** The output of our `setScreenContentByPlaylistId` mutation. */
export type SetScreenContentByPlaylistIdPayload = {
  __typename: "SetScreenContentByPlaylistIdPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setScreenContentByPlaylistId` mutation. */
export type SetScreenContentByPlaylistIdPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `setScreenContentBySiteId` mutation. */
export type SetScreenContentBySiteIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenId?: InputMaybe<Scalars["UUID"]>;
  siteId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `setScreenContentBySiteId` mutation. */
export type SetScreenContentBySiteIdPayload = {
  __typename: "SetScreenContentBySiteIdPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setScreenContentBySiteId` mutation. */
export type SetScreenContentBySiteIdPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `setScreenContent` mutation. */
export type SetScreenContentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentId: Scalars["UUID"];
  contentType: ScreenContentType;
  screenId: Scalars["UUID"];
};

/** The output of our `setScreenContent` mutation. */
export type SetScreenContentPayload = {
  __typename: "SetScreenContentPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setScreenContent` mutation. */
export type SetScreenContentPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `setScreensContentByAppInstanceId` mutation. */
export type SetScreensContentByAppInstanceIdInput = {
  appInstanceId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `setScreensContentByAppInstanceId` mutation. */
export type SetScreensContentByAppInstanceIdPayload = {
  __typename: "SetScreensContentByAppInstanceIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `setScreensContentByChannelId` mutation. */
export type SetScreensContentByChannelIdInput = {
  channelId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `setScreensContentByChannelId` mutation. */
export type SetScreensContentByChannelIdPayload = {
  __typename: "SetScreensContentByChannelIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `setScreensContentByFileId` mutation. */
export type SetScreensContentByFileIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId: Scalars["UUID"];
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `setScreensContentByFileId` mutation. */
export type SetScreensContentByFileIdPayload = {
  __typename: "SetScreensContentByFileIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `setScreensContentByLinkId` mutation. */
export type SetScreensContentByLinkIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  linkId: Scalars["UUID"];
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `setScreensContentByLinkId` mutation. */
export type SetScreensContentByLinkIdPayload = {
  __typename: "SetScreensContentByLinkIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `setScreensContentByPlaylistId` mutation. */
export type SetScreensContentByPlaylistIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  playlistId: Scalars["UUID"];
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `setScreensContentByPlaylistId` mutation. */
export type SetScreensContentByPlaylistIdPayload = {
  __typename: "SetScreensContentByPlaylistIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `setScreensContentBySiteId` mutation. */
export type SetScreensContentBySiteIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  siteId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `setScreensContentBySiteId` mutation. */
export type SetScreensContentBySiteIdPayload = {
  __typename: "SetScreensContentBySiteIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `setScreensContent` mutation. */
export type SetScreensContentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  contentId: Scalars["UUID"];
  contentType: ScreenContentType;
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `setScreensContent` mutation. */
export type SetScreensContentPayload = {
  __typename: "SetScreensContentPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `setStartChannel` mutation. */
export type SetStartChannelInput = {
  channelId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `setStartChannel` mutation. */
export type SetStartChannelPayload = {
  __typename: "SetStartChannelPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `setStartChannel` mutation. */
export type SetStartChannelPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `setUserIsOwner` mutation. */
export type SetUserIsOwnerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isOwner?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `setUserIsOwner` mutation. */
export type SetUserIsOwnerPayload = {
  __typename: "SetUserIsOwnerPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `setUserIsOwner` mutation. */
export type SetUserIsOwnerPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type ShareAssociation = {
  __typename: "ShareAssociation";
  /** Reads a single `AppInstance` that is related to this `ShareAssociation`. */
  appInstanceByShareAppInstance: Maybe<AppInstance>;
  /** Reads a single `AppInstance` that is related to this `ShareAssociation`. */
  appInstanceByShareAppInstanceAndOrgId: Maybe<AppInstance>;
  /** Reads a single `Channel` that is related to this `ShareAssociation`. */
  channelByShareChannel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `ShareAssociation`. */
  channelByShareChannelAndOrgId: Maybe<Channel>;
  /** Reads a single `Credential` that is related to this `ShareAssociation`. */
  credentialByShareCredential: Maybe<Credential>;
  /** Reads a single `Credential` that is related to this `ShareAssociation`. */
  credentialByShareCredentialAndOrgId: Maybe<Credential>;
  /** Reads a single `File` that is related to this `ShareAssociation`. */
  fileByShareFile: Maybe<File>;
  /** Reads a single `File` that is related to this `ShareAssociation`. */
  fileByShareFileAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `ShareAssociation`. */
  folderByShareFolder: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `ShareAssociation`. */
  folderByShareFolderAndOrgId: Maybe<Folder>;
  fromSpace: Maybe<Scalars["UUID"]>;
  /** Reads a single `Link` that is related to this `ShareAssociation`. */
  linkByShareLink: Maybe<Link>;
  /** Reads a single `Link` that is related to this `ShareAssociation`. */
  linkByShareLinkAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `ShareAssociation`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Playlist` that is related to this `ShareAssociation`. */
  playlistBySharePlaylist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `ShareAssociation`. */
  playlistBySharePlaylistAndOrgId: Maybe<Playlist>;
  shareAppInstance: Maybe<Scalars["UUID"]>;
  shareChannel: Maybe<Scalars["UUID"]>;
  shareCredential: Maybe<Scalars["UUID"]>;
  shareFile: Maybe<Scalars["UUID"]>;
  shareFolder: Maybe<Scalars["UUID"]>;
  shareLink: Maybe<Scalars["UUID"]>;
  sharePlaylist: Maybe<Scalars["UUID"]>;
  shareSite: Maybe<Scalars["UUID"]>;
  sharedAt: Maybe<Scalars["Datetime"]>;
  sharedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `Site` that is related to this `ShareAssociation`. */
  siteByShareSite: Maybe<Site>;
  /** Reads a single `Site` that is related to this `ShareAssociation`. */
  siteByShareSiteAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `ShareAssociation`. */
  spaceByFromSpace: Maybe<Space>;
  /** Reads a single `Space` that is related to this `ShareAssociation`. */
  spaceByToSpace: Maybe<Space>;
  /** Reads a single `Space` that is related to this `ShareAssociation`. */
  spaceByToSpaceAndOrgId: Maybe<Space>;
  toSpace: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `ShareAssociation`. */
  userBySharedBy: Maybe<User>;
};

/**
 * A condition to be used against `ShareAssociation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ShareAssociationCondition = {
  /** Checks for equality with the object’s `fromSpace` field. */
  fromSpace?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `shareAppInstance` field. */
  shareAppInstance?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `shareChannel` field. */
  shareChannel?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `shareCredential` field. */
  shareCredential?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `shareFile` field. */
  shareFile?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `shareFolder` field. */
  shareFolder?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `shareLink` field. */
  shareLink?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `sharePlaylist` field. */
  sharePlaylist?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `shareSite` field. */
  shareSite?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `sharedAt` field. */
  sharedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `sharedBy` field. */
  sharedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toSpace` field. */
  toSpace?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `ShareAssociation` object types. All fields are combined with a logical ‘and.’ */
export type ShareAssociationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ShareAssociationFilter>>;
  /** Filter by the object’s `fromSpace` field. */
  fromSpace?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ShareAssociationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ShareAssociationFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `shareAppInstance` field. */
  shareAppInstance?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `shareChannel` field. */
  shareChannel?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `shareCredential` field. */
  shareCredential?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `shareFile` field. */
  shareFile?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `shareFolder` field. */
  shareFolder?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `shareLink` field. */
  shareLink?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sharePlaylist` field. */
  sharePlaylist?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `shareSite` field. */
  shareSite?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sharedAt` field. */
  sharedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sharedBy` field. */
  sharedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toSpace` field. */
  toSpace?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `ShareAssociation` values. */
export type ShareAssociationsConnection = {
  __typename: "ShareAssociationsConnection";
  /** A list of edges which contains the `ShareAssociation` and cursor to aid in pagination. */
  edges: Array<ShareAssociationsEdge>;
  /** A list of `ShareAssociation` objects. */
  nodes: Array<ShareAssociation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ShareAssociation` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `ShareAssociation` edge in the connection. */
export type ShareAssociationsEdge = {
  __typename: "ShareAssociationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `ShareAssociation` at the end of the edge. */
  node: ShareAssociation;
};

/** Methods to use when ordering `ShareAssociation`. */
export enum ShareAssociationsOrderBy {
  FromSpaceAsc = "FROM_SPACE_ASC",
  FromSpaceDesc = "FROM_SPACE_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  SharedAtAsc = "SHARED_AT_ASC",
  SharedAtDesc = "SHARED_AT_DESC",
  SharedByAsc = "SHARED_BY_ASC",
  SharedByDesc = "SHARED_BY_DESC",
  ShareAppInstanceAsc = "SHARE_APP_INSTANCE_ASC",
  ShareAppInstanceDesc = "SHARE_APP_INSTANCE_DESC",
  ShareChannelAsc = "SHARE_CHANNEL_ASC",
  ShareChannelDesc = "SHARE_CHANNEL_DESC",
  ShareCredentialAsc = "SHARE_CREDENTIAL_ASC",
  ShareCredentialDesc = "SHARE_CREDENTIAL_DESC",
  ShareFileAsc = "SHARE_FILE_ASC",
  ShareFileDesc = "SHARE_FILE_DESC",
  ShareFolderAsc = "SHARE_FOLDER_ASC",
  ShareFolderDesc = "SHARE_FOLDER_DESC",
  ShareLinkAsc = "SHARE_LINK_ASC",
  ShareLinkDesc = "SHARE_LINK_DESC",
  SharePlaylistAsc = "SHARE_PLAYLIST_ASC",
  SharePlaylistDesc = "SHARE_PLAYLIST_DESC",
  ShareSiteAsc = "SHARE_SITE_ASC",
  ShareSiteDesc = "SHARE_SITE_DESC",
  ToSpaceAsc = "TO_SPACE_ASC",
  ToSpaceDesc = "TO_SPACE_DESC",
}

/** All input for the `shareChannelToAllSpaces` mutation. */
export type ShareChannelToAllSpacesInput = {
  channelId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  sharingMode: Scalars["String"];
};

/** The output of our `shareChannelToAllSpaces` mutation. */
export type ShareChannelToAllSpacesPayload = {
  __typename: "ShareChannelToAllSpacesPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `shareChannelToAllSpaces` mutation. */
export type ShareChannelToAllSpacesPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `shareChannelToSpace` mutation. */
export type ShareChannelToSpaceInput = {
  channelId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  sharingMode: Scalars["String"];
  spaceId: Scalars["UUID"];
};

/** The output of our `shareChannelToSpace` mutation. */
export type ShareChannelToSpacePayload = {
  __typename: "ShareChannelToSpacePayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `shareChannelToSpace` mutation. */
export type ShareChannelToSpacePayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

export type SharedSpaceWithSharingMode = {
  __typename: "SharedSpaceWithSharingMode";
  readOnly: Maybe<Scalars["Boolean"]>;
  space: Maybe<Space>;
};

/** A filter to be used against `SharedSpaceWithSharingMode` object types. All fields are combined with a logical ‘and.’ */
export type SharedSpaceWithSharingModeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SharedSpaceWithSharingModeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<SharedSpaceWithSharingModeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SharedSpaceWithSharingModeFilter>>;
  /** Filter by the object’s `readOnly` field. */
  readOnly?: InputMaybe<BooleanFilter>;
};

/** A connection to a list of `SharedSpaceWithSharingMode` values. */
export type SharedSpaceWithSharingModesConnection = {
  __typename: "SharedSpaceWithSharingModesConnection";
  /** A list of edges which contains the `SharedSpaceWithSharingMode` and cursor to aid in pagination. */
  edges: Array<SharedSpaceWithSharingModesEdge>;
  /** A list of `SharedSpaceWithSharingMode` objects. */
  nodes: Array<SharedSpaceWithSharingMode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SharedSpaceWithSharingMode` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `SharedSpaceWithSharingMode` edge in the connection. */
export type SharedSpaceWithSharingModesEdge = {
  __typename: "SharedSpaceWithSharingModesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `SharedSpaceWithSharingMode` at the end of the edge. */
  node: SharedSpaceWithSharingMode;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type Site = Node & {
  __typename: "Site";
  /** Actions to navigate around the secure site */
  actions: Maybe<Scalars["JSON"]>;
  /** Reads and enables pagination through a set of `Association`. */
  associationsByToSiteAndOrgId: AssociationsConnection;
  /** Get casted screen(s) by site's id. */
  castedScreenBySiteId: ScreensConnection;
  /** Site renderer compute configuration */
  compute: Maybe<Scalars["JSON"]>;
  /** Configuration for advanced features within sites navigation (refresh Interval, scroll, zoom etc) */
  config: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  credentialIds: Array<Maybe<Scalars["UUID"]>>;
  /** Temporary column for Dashboard credentials migration. */
  credentialsStoredInConnections: Scalars["Boolean"];
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesBySiteId: FavoritesConnection;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesBySiteIdAndOrgId: FavoritesConnection;
  /** Reads a single `File` that is related to this `Site`. */
  fileByThumbnailId: Maybe<File>;
  /** Reads a single `File` that is related to this `Site`. */
  fileByThumbnailIdAndOrgId: Maybe<File>;
  id: Scalars["UUID"];
  /** Return favorite status of a site. */
  isFavorite: Maybe<Scalars["Boolean"]>;
  isShared: Maybe<Scalars["Boolean"]>;
  isSharedAll: Maybe<Scalars["Boolean"]>;
  isSharedExplicit: Maybe<Scalars["Boolean"]>;
  /** The friendly name of the site. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Site`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesBySiteIdAndOrgId: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareSite: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByShareSiteAndOrgId: ShareAssociationsConnection;
  /** Spaces that currently got shared access to the Site. */
  sharedSpacesBySiteId: SpacesConnection;
  /** Reads a single `Space` that is related to this `Site`. */
  spaceBySpaceId: Maybe<Space>;
  /** The uuid of the space of creator. */
  spaceId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByToSiteAndOrgId: TagAssociationsConnection;
  /** The json array of tags for the site. */
  tags: Array<Maybe<Scalars["String"]>>;
  /** The thumbnail of the site to display on the card. */
  thumbnailId: Maybe<Scalars["UUID"]>;
  /** Enum type of site type. */
  type: SiteType;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** URL of the site */
  url: Scalars["String"];
  /** Reads a single `User` that is related to this `Site`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Site`. */
  userByUpdatedBy: Maybe<User>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteAssociationsByToSiteAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AssociationCondition>;
  filter: InputMaybe<AssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AssociationsOrderBy>>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteCastedScreenBySiteIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteFavoritesBySiteIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteFavoritesBySiteIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteScreenContentHistoriesBySiteIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteShareAssociationsByShareSiteArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteShareAssociationsByShareSiteAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteSharedSpacesBySiteIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The site table for storing site configs (sites, cloud-rendered sites, secure sites) etc. */
export type SiteTagAssociationsByToSiteAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** A condition to be used against `Site` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SiteCondition = {
  /** Checks for equality with the object’s `actions` field. */
  actions?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `compute` field. */
  compute?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `credentialIds` field. */
  credentialIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Checks for equality with the object’s `credentialsStoredInConnections` field. */
  credentialsStoredInConnections?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isShared` field. */
  isShared?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `thumbnailId` field. */
  thumbnailId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<SiteType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `Site` object types. All fields are combined with a logical ‘and.’ */
export type SiteFilter = {
  /** Filter by the object’s `actions` field. */
  actions?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SiteFilter>>;
  /** Filter by the object’s `compute` field. */
  compute?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `credentialIds` field. */
  credentialIds?: InputMaybe<UuidListFilter>;
  /** Filter by the object’s `credentialsStoredInConnections` field. */
  credentialsStoredInConnections?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedAll` field. */
  isSharedAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSharedExplicit` field. */
  isSharedExplicit?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SiteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SiteFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `thumbnailId` field. */
  thumbnailId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<SiteTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

export enum SiteType {
  Basic = "BASIC",
  BasicInternal = "BASIC_INTERNAL",
  Cloud = "CLOUD",
  CloudInternal = "CLOUD_INTERNAL",
  Secure = "SECURE",
  SecureInternal = "SECURE_INTERNAL",
}

/** A filter to be used against SiteType fields. All fields are combined with a logical ‘and.’ */
export type SiteTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SiteType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SiteType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SiteType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SiteType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SiteType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SiteType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SiteType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SiteType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SiteType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SiteType>>;
};

/** A connection to a list of `Site` values. */
export type SitesConnection = {
  __typename: "SitesConnection";
  /** A list of edges which contains the `Site` and cursor to aid in pagination. */
  edges: Array<SitesEdge>;
  /** A list of `Site` objects. */
  nodes: Array<Site>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Site` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Site` edge in the connection. */
export type SitesEdge = {
  __typename: "SitesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Site` at the end of the edge. */
  node: Site;
};

/** Methods to use when ordering `Site`. */
export enum SitesOrderBy {
  ActionsAsc = "ACTIONS_ASC",
  ActionsDesc = "ACTIONS_DESC",
  ComputeAsc = "COMPUTE_ASC",
  ComputeDesc = "COMPUTE_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  CredentialsStoredInConnectionsAsc = "CREDENTIALS_STORED_IN_CONNECTIONS_ASC",
  CredentialsStoredInConnectionsDesc = "CREDENTIALS_STORED_IN_CONNECTIONS_DESC",
  CredentialIdsAsc = "CREDENTIAL_IDS_ASC",
  CredentialIdsDesc = "CREDENTIAL_IDS_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsSharedAllAsc = "IS_SHARED_ALL_ASC",
  IsSharedAllDesc = "IS_SHARED_ALL_DESC",
  IsSharedAsc = "IS_SHARED_ASC",
  IsSharedDesc = "IS_SHARED_DESC",
  IsSharedExplicitAsc = "IS_SHARED_EXPLICIT_ASC",
  IsSharedExplicitDesc = "IS_SHARED_EXPLICIT_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  TagsAsc = "TAGS_ASC",
  TagsDesc = "TAGS_DESC",
  ThumbnailIdAsc = "THUMBNAIL_ID_ASC",
  ThumbnailIdDesc = "THUMBNAIL_ID_DESC",
  TypeAsc = "TYPE_ASC",
  TypeDesc = "TYPE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  UrlAsc = "URL_ASC",
  UrlDesc = "URL_DESC",
}

export enum SnapshotType {
  Channel = "channel",
  Org = "org",
  Playlist = "playlist",
  Space = "space",
}

/** A space detail for each organization. */
export type Space = Node & {
  __typename: "Space";
  /** List of apps installed for the current space including global app install by organization. */
  allAppInstallsBySpaceId: AppInstallsConnection;
  /** Get sites by given space. */
  allSitesBySpaceId: SitesConnection;
  /** Reads and enables pagination through a set of `AppInstall`. */
  appInstallsBySpaceId: AppInstallsConnection;
  /** Get app instances by given space. */
  appInstancesBySpaceId: AppInstancesConnection;
  /** Get available app installs for the current space including global app installs, shared app installs, and app installs that were implicitly shared by sharing app instances. */
  availableAppInstallsBySpaceId: AppInstallsConnection;
  /** Get available app instances for the current space including global app installs and shared app installs. */
  availableAppInstancesBySpaceId: AppInstancesConnection;
  /** Get available app instances for the current space and specific appId including global app instances and shared app instances. */
  availableAppInstancesBySpaceIdAndAppId: AppInstancesConnection;
  /** Get available channels by space. */
  availableChannelsBySpaceId: ChannelsConnection;
  /** Get available files for the current space including global files and shared files. */
  availableFilesBySpaceId: FilesConnection;
  /** Get available folders for the current space including shared folders. */
  availableFoldersBySpaceId: FoldersConnection;
  /** Get available links for the current space including shared links. */
  availableLinksBySpaceId: LinksConnection;
  /** Get available playlists for the current space including shared playlists. */
  availablePlaylistsBySpaceId: PlaylistsConnection;
  /** Get available sites for the current space including shared sites. */
  availableSitesBySpaceId: SitesConnection;
  /** Reads a single `BillingCustomer` that is related to this `Space`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Chargebee customer_id (joins studio.billing.id) */
  billingCustomerId: Maybe<Scalars["String"]>;
  /** Billing expiration end of the month date from space-level subscription using last calendar day of expiration month/year specified on credit card */
  billingExpiresAt: Maybe<Scalars["Date"]>;
  /** Get default screen group by space. */
  castsBySpace: CastsConnection;
  /** Reads and enables pagination through a set of `Cast`. */
  castsBySpaceId: CastsConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsBySpaceId: ChannelsConnection;
  /** Reads and enables pagination through a set of `ContentReplacementJob`. */
  contentReplacementJobsBySpaceId: ContentReplacementJobsConnection;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Get credentials by given space. */
  credentialsBySpaceId: CredentialsConnection;
  /** Get default screen group by space. */
  defaultScreenGroupBySpace: Maybe<ScreenGroup>;
  /** The key value pairs data for space. */
  env: Scalars["JSON"];
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesBySpaceId: FavoritesConnection;
  /** Reads and enables pagination through a set of `FileOutput`. */
  fileOutputsBySpaceId: FileOutputsConnection;
  /** Reads and enables pagination through a set of `FileProcessingJob`. */
  fileProcessingJobsBySpaceId: FileProcessingJobsConnection;
  /** Get files by given space. */
  filesBySpaceId: FilesConnection;
  /** Reads a single `Folder` that is related to this `Space`. */
  folderByRootFolderId: Maybe<Folder>;
  /** Get folders by given space. */
  foldersBySpaceId: FoldersConnection;
  /** Reads and enables pagination through a set of `GroupSpace`. */
  groupSpacesBySpaceId: GroupSpacesConnection;
  /** List of groups for the current space including the group from org wide access granted. */
  groupsBySpaceId: GroupsConnection;
  /** The primary unique identifier for the space. */
  id: Scalars["UUID"];
  isEnabled: Scalars["Boolean"];
  /** Reads and enables pagination through a set of `Layout`. */
  layoutsBySpace: LayoutsConnection;
  /** Reads and enables pagination through a set of `Layout`. */
  layoutsBySpaceId: LayoutsConnection;
  /** Get links by given space. */
  linksBySpaceId: LinksConnection;
  /** The name of the space, unique name for each organization. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads and enables pagination through a set of `NotificationRule`. */
  notificationRulesBySpaceId: NotificationRulesConnection;
  /** Reads and enables pagination through a set of `NotificationSubscription`. */
  notificationSubscriptionsBySpaceId: NotificationSubscriptionsConnection;
  /** Reads a single `Org` that is related to this `Space`. */
  orgByOrgId: Maybe<Org>;
  /** The id of the organization that space belong to. */
  orgId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsBySpaceId: PlaylistsConnection;
  /** A default preferences for screens in space eg. operating hours */
  preferences: Scalars["JSON"];
  /** Get published channel by space. */
  publishedChannelsBySpaceId: ChannelsConnection;
  /** Get published playlists by space. */
  publishedPlaylistsBySpaceId: PlaylistsConnection;
  /** Reads and enables pagination through a set of `RecycleBin`. */
  recycleBinsBySpaceId: RecycleBinsConnection;
  rootFolderId: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesBySpaceId: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ScreenGroup`. */
  screenGroupsBySpaceId: ScreenGroupsConnection;
  /** Reads and enables pagination through a set of `ScreenNote`. */
  screenNotesBySpaceId: ScreenNotesConnection;
  /** Reads and enables pagination through a set of `Screen`. */
  screensBySpaceId: ScreensConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByFromSpace: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByToSpace: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsByToSpaceAndOrgId: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `Site`. */
  sitesBySpaceId: SitesConnection;
  slug: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsBySpaceId: TagAssociationsConnection;
  /** Reads and enables pagination through a set of `Tag`. */
  tagsBySpaceId: TagsConnection;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `UserActionLog`. */
  userActionLogsBySpaceId: UserActionLogsConnection;
  /** Reads a single `User` that is related to this `Space`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Space`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads and enables pagination through a set of `WhiteLabel`. */
  whiteLabelsBySpaceId: WhiteLabelsConnection;
};

/** A space detail for each organization. */
export type SpaceAllAppInstallsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A space detail for each organization. */
export type SpaceAllSitesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<SitesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAppInstallsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstallCondition>;
  filter: InputMaybe<AppInstallFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAppInstancesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAvailableAppInstallsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstallCondition>;
  filter: InputMaybe<AppInstallFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAvailableAppInstancesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAvailableAppInstancesBySpaceIdAndAppIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  appId: InputMaybe<Scalars["UUID"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAvailableChannelsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A space detail for each organization. */
export type SpaceAvailableFilesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAvailableFoldersBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<FoldersOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAvailableLinksBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<LinksOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAvailablePlaylistsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceAvailableSitesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<SitesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceCastsBySpaceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A space detail for each organization. */
export type SpaceCastsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CastCondition>;
  filter: InputMaybe<CastFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CastsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceChannelsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceContentReplacementJobsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ContentReplacementJobCondition>;
  filter: InputMaybe<ContentReplacementJobFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContentReplacementJobsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceCredentialsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<CredentialsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceFavoritesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceFileOutputsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileOutputCondition>;
  filter: InputMaybe<FileOutputFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileOutputsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceFileProcessingJobsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileProcessingJobCondition>;
  filter: InputMaybe<FileProcessingJobFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FileProcessingJobsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceFilesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceFoldersBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<FoldersOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceGroupSpacesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupSpaceCondition>;
  filter: InputMaybe<GroupSpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceGroupsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A space detail for each organization. */
export type SpaceLayoutsBySpaceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A space detail for each organization. */
export type SpaceLayoutsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LayoutCondition>;
  filter: InputMaybe<LayoutFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LayoutsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceLinksBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<LinksOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceNotificationRulesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationRuleCondition>;
  filter: InputMaybe<NotificationRuleFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceNotificationSubscriptionsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationSubscriptionCondition>;
  filter: InputMaybe<NotificationSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationSubscriptionsOrderBy>>;
};

/** A space detail for each organization. */
export type SpacePlaylistsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** A space detail for each organization. */
export type SpacePublishedChannelsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  isShared: InputMaybe<Scalars["Boolean"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<ChannelsOrderBy>>;
};

/** A space detail for each organization. */
export type SpacePublishedPlaylistsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceRecycleBinsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<RecycleBinCondition>;
  filter: InputMaybe<RecycleBinFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<RecycleBinsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceScreenContentHistoriesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceScreenGroupsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenGroupCondition>;
  filter: InputMaybe<ScreenGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenGroupsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceScreenNotesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenNoteCondition>;
  filter: InputMaybe<ScreenNoteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceScreensBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceShareAssociationsByFromSpaceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceShareAssociationsByToSpaceArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceShareAssociationsByToSpaceAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceSitesBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceTagAssociationsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceTagsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagCondition>;
  filter: InputMaybe<TagFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceUserActionLogsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserActionLogCondition>;
  filter: InputMaybe<UserActionLogFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserActionLogsOrderBy>>;
};

/** A space detail for each organization. */
export type SpaceWhiteLabelsBySpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<WhiteLabelCondition>;
  filter: InputMaybe<WhiteLabelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

/** A condition to be used against `Space` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SpaceCondition = {
  /** Checks for equality with the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `billingExpiresAt` field. */
  billingExpiresAt?: InputMaybe<Scalars["Date"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `env` field. */
  env?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isEnabled` field. */
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `preferences` field. */
  preferences?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `rootFolderId` field. */
  rootFolderId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Space` object types. All fields are combined with a logical ‘and.’ */
export type SpaceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SpaceFilter>>;
  /** Filter by the object’s `billingCustomerId` field. */
  billingCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `billingExpiresAt` field. */
  billingExpiresAt?: InputMaybe<DateFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `env` field. */
  env?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isEnabled` field. */
  isEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SpaceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SpaceFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `preferences` field. */
  preferences?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootFolderId` field. */
  rootFolderId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Space` values. */
export type SpacesConnection = {
  __typename: "SpacesConnection";
  /** A list of edges which contains the `Space` and cursor to aid in pagination. */
  edges: Array<SpacesEdge>;
  /** A list of `Space` objects. */
  nodes: Array<Space>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Space` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Space` edge in the connection. */
export type SpacesEdge = {
  __typename: "SpacesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Space` at the end of the edge. */
  node: Space;
};

/** Methods to use when ordering `Space`. */
export enum SpacesOrderBy {
  BillingCustomerIdAsc = "BILLING_CUSTOMER_ID_ASC",
  BillingCustomerIdDesc = "BILLING_CUSTOMER_ID_DESC",
  BillingExpiresAtAsc = "BILLING_EXPIRES_AT_ASC",
  BillingExpiresAtDesc = "BILLING_EXPIRES_AT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  EnvAsc = "ENV_ASC",
  EnvDesc = "ENV_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsEnabledAsc = "IS_ENABLED_ASC",
  IsEnabledDesc = "IS_ENABLED_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PreferencesAsc = "PREFERENCES_ASC",
  PreferencesDesc = "PREFERENCES_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RootFolderIdAsc = "ROOT_FOLDER_ID_ASC",
  RootFolderIdDesc = "ROOT_FOLDER_ID_DESC",
  SlugAsc = "SLUG_ASC",
  SlugDesc = "SLUG_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

export type SsoConnection = {
  __typename: "SsoConnection";
  audienceUrl: Scalars["String"];
  cert: Maybe<Scalars["String"]>;
  certProvider: Maybe<Scalars["String"]>;
  connectionName: Scalars["String"];
  debugMode: Maybe<Scalars["Boolean"]>;
  emailMapping: Maybe<Scalars["String"]>;
  enableUserEnroll: Scalars["Boolean"];
  forceSSOOnly: Scalars["Boolean"];
  idpDomain: Maybe<Scalars["String"]>;
  nameMapping: Maybe<Scalars["String"]>;
  orgConnectionId: Scalars["UUID"];
  orgSlug: Maybe<Scalars["String"]>;
  protocolBinding: Maybe<Scalars["String"]>;
  replyUrl: Scalars["String"];
  signInURL: Maybe<Scalars["String"]>;
  signOnUrl: Scalars["String"];
  signOutURL: Maybe<Scalars["String"]>;
  signRequest: Maybe<Scalars["Boolean"]>;
  signRequestAlgorithm: Maybe<Scalars["String"]>;
  signRequestAlgorithmDigest: Maybe<Scalars["String"]>;
  userIDMapping: Maybe<Scalars["String"]>;
};

export type SsoCreateConnectionInput = {
  cert: Scalars["String"];
  debugMode: Scalars["Boolean"];
  emailMapping?: InputMaybe<Scalars["String"]>;
  enableUserEnroll: Scalars["Boolean"];
  forceSSOOnly?: InputMaybe<Scalars["Boolean"]>;
  idpDomain?: InputMaybe<Scalars["String"]>;
  nameMapping?: InputMaybe<Scalars["String"]>;
  orgConnectionId: Scalars["UUID"];
  orgSlug: Scalars["String"];
  protocolBinding: Scalars["String"];
  signInURL: Scalars["String"];
  signOutURL?: InputMaybe<Scalars["String"]>;
  signRequest: Scalars["Boolean"];
  signRequestAlgorithm: Scalars["String"];
  signRequestAlgorithmDigest: Scalars["String"];
  userIDMapping?: InputMaybe<Scalars["String"]>;
};

export type SsoCreateConnectionPayload = {
  __typename: "SsoCreateConnectionPayload";
  audienceUrl: Scalars["String"];
  cert: Scalars["String"];
  certProvider: Scalars["String"];
  connectionName: Scalars["String"];
  debugMode: Scalars["Boolean"];
  emailMapping: Maybe<Scalars["String"]>;
  enableUserEnroll: Scalars["Boolean"];
  forceSSOOnly: Maybe<Scalars["Boolean"]>;
  idpDomain: Maybe<Scalars["String"]>;
  nameMapping: Maybe<Scalars["String"]>;
  orgConnectionId: Scalars["UUID"];
  orgSlug: Scalars["String"];
  protocolBinding: Scalars["String"];
  replyUrl: Scalars["String"];
  signInURL: Scalars["String"];
  signOnUrl: Scalars["String"];
  signOutURL: Maybe<Scalars["String"]>;
  signRequest: Scalars["Boolean"];
  signRequestAlgorithm: Scalars["String"];
  signRequestAlgorithmDigest: Scalars["String"];
  userIDMapping: Maybe<Scalars["String"]>;
};

export type SsoEditConnectionInput = {
  cert: Scalars["String"];
  debugMode: Scalars["Boolean"];
  emailMapping?: InputMaybe<Scalars["String"]>;
  enableUserEnroll: Scalars["Boolean"];
  forceSSOOnly?: InputMaybe<Scalars["Boolean"]>;
  idpDomain?: InputMaybe<Scalars["String"]>;
  nameMapping?: InputMaybe<Scalars["String"]>;
  orgConnectionId: Scalars["UUID"];
  orgSlug: Scalars["String"];
  protocolBinding: Scalars["String"];
  signInURL: Scalars["String"];
  signOutURL?: InputMaybe<Scalars["String"]>;
  signRequest: Scalars["Boolean"];
  signRequestAlgorithm: Scalars["String"];
  signRequestAlgorithmDigest: Scalars["String"];
  userIDMapping?: InputMaybe<Scalars["String"]>;
};

export type SsoEditConnectionPayload = {
  __typename: "SsoEditConnectionPayload";
  audienceUrl: Scalars["String"];
  cert: Scalars["String"];
  certProvider: Scalars["String"];
  connectionName: Scalars["String"];
  debugMode: Scalars["Boolean"];
  emailMapping: Maybe<Scalars["String"]>;
  enableUserEnroll: Scalars["Boolean"];
  forceSSOOnly: Maybe<Scalars["Boolean"]>;
  idpDomain: Maybe<Scalars["String"]>;
  nameMapping: Maybe<Scalars["String"]>;
  orgConnectionId: Scalars["UUID"];
  orgSlug: Scalars["String"];
  protocolBinding: Scalars["String"];
  replyUrl: Scalars["String"];
  signInURL: Scalars["String"];
  signOnUrl: Scalars["String"];
  signOutURL: Maybe<Scalars["String"]>;
  signRequest: Scalars["Boolean"];
  signRequestAlgorithm: Scalars["String"];
  signRequestAlgorithmDigest: Scalars["String"];
  userIDMapping: Maybe<Scalars["String"]>;
};

export type SsoLog = {
  __typename: "SsoLog";
  date: Scalars["String"];
  description: Scalars["String"];
  eventStatus: Scalars["String"];
  eventType: Scalars["String"];
  rawData: Scalars["JSON"];
};

export type SsoLogPayload = {
  __typename: "SsoLogPayload";
  nodes: Array<Maybe<SsoLog>>;
  totalCount: Scalars["Int"];
};

/** All input for the `stopSetContent` mutation. */
export type StopSetContentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `stopSetContent` mutation. */
export type StopSetContentPayload = {
  __typename: "StopSetContentPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `stopSetContent` mutation. */
export type StopSetContentPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["String"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["String"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["String"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["String"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["String"]>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars["String"]>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars["String"]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["String"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["String"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["String"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["String"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["String"]>>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars["String"]>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** All input for the `subscribeNotification` mutation. */
export type SubscribeNotificationInput = {
  _channel?: InputMaybe<Scalars["String"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ruleId?: InputMaybe<Scalars["UUID"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `subscribeNotification` mutation. */
export type SubscribeNotificationPayload = {
  __typename: "SubscribeNotificationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRule: Maybe<NotificationRule>;
  /** An edge for our `NotificationRule`. May be used by Relay 1. */
  notificationRuleEdge: Maybe<NotificationRulesEdge>;
  /** Reads a single `Org` that is related to this `NotificationRule`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Screen` that is related to this `NotificationRule`. */
  screenByScreenIdAndOrgId: Maybe<Screen>;
  /** Reads a single `Space` that is related to this `NotificationRule`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `NotificationRule`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `subscribeNotification` mutation. */
export type SubscribeNotificationPayloadNotificationRuleEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** All input for the `subscribeScreenNotificationByOrgId` mutation. */
export type SubscribeScreenNotificationByOrgIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  waitTimeInSecond?: InputMaybe<Scalars["Int"]>;
};

/** The output of our `subscribeScreenNotificationByOrgId` mutation. */
export type SubscribeScreenNotificationByOrgIdPayload = {
  __typename: "SubscribeScreenNotificationByOrgIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRules: Maybe<Array<NotificationRule>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `subscribeScreenNotificationByScreenIds` mutation. */
export type SubscribeScreenNotificationByScreenIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  waitTimeInSecond?: InputMaybe<Scalars["Int"]>;
};

/** The output of our `subscribeScreenNotificationByScreenIds` mutation. */
export type SubscribeScreenNotificationByScreenIdsPayload = {
  __typename: "SubscribeScreenNotificationByScreenIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRules: Maybe<Array<NotificationRule>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `subscribeScreenNotificationBySpaceId` mutation. */
export type SubscribeScreenNotificationBySpaceIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  waitTimeInSecond?: InputMaybe<Scalars["Int"]>;
};

/** The output of our `subscribeScreenNotificationBySpaceId` mutation. */
export type SubscribeScreenNotificationBySpaceIdPayload = {
  __typename: "SubscribeScreenNotificationBySpaceIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRules: Maybe<Array<NotificationRule>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

export type SystemAccess = Node & {
  __typename: "SystemAccess";
  createdAt: Scalars["Datetime"];
  createdBy: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `SystemAccess`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  reason: Scalars["String"];
  status: SystemAccessStatus;
  targets: Array<Maybe<SystemAccessTarget>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `SystemAccess`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `SystemAccess`. */
  userByUpdatedBy: Maybe<User>;
  zendeskTicketId: Maybe<Scalars["Int"]>;
};

/**
 * A condition to be used against `SystemAccess` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SystemAccessCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `reason` field. */
  reason?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<SystemAccessStatus>;
  /** Checks for equality with the object’s `targets` field. */
  targets?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `zendeskTicketId` field. */
  zendeskTicketId?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against `SystemAccess` object types. All fields are combined with a logical ‘and.’ */
export type SystemAccessFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SystemAccessFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SystemAccessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SystemAccessFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `reason` field. */
  reason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<SystemAccessStatusFilter>;
  /** Filter by the object’s `targets` field. */
  targets?: InputMaybe<SystemAccessTargetListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `zendeskTicketId` field. */
  zendeskTicketId?: InputMaybe<IntFilter>;
};

export enum SystemAccessStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Disabled = "DISABLED",
  Pending = "PENDING",
}

/** A filter to be used against SystemAccessStatus fields. All fields are combined with a logical ‘and.’ */
export type SystemAccessStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SystemAccessStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SystemAccessStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SystemAccessStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SystemAccessStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SystemAccessStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SystemAccessStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SystemAccessStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SystemAccessStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SystemAccessStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SystemAccessStatus>>;
};

export enum SystemAccessTarget {
  AccountManagement = "ACCOUNT_MANAGEMENT",
  Development = "DEVELOPMENT",
  Finance = "FINANCE",
  Marketing = "MARKETING",
  Product = "PRODUCT",
  ProfessionalServices = "PROFESSIONAL_SERVICES",
  Sales = "SALES",
  Support = "SUPPORT",
}

/** A filter to be used against SystemAccessTarget List fields. All fields are combined with a logical ‘and.’ */
export type SystemAccessTargetListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<SystemAccessTarget>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<SystemAccessTarget>>>;
};

/** A connection to a list of `SystemAccess` values. */
export type SystemAccessesConnection = {
  __typename: "SystemAccessesConnection";
  /** A list of edges which contains the `SystemAccess` and cursor to aid in pagination. */
  edges: Array<SystemAccessesEdge>;
  /** A list of `SystemAccess` objects. */
  nodes: Array<SystemAccess>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SystemAccess` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `SystemAccess` edge in the connection. */
export type SystemAccessesEdge = {
  __typename: "SystemAccessesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `SystemAccess` at the end of the edge. */
  node: SystemAccess;
};

/** Methods to use when ordering `SystemAccess`. */
export enum SystemAccessesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ReasonAsc = "REASON_ASC",
  ReasonDesc = "REASON_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  TargetsAsc = "TARGETS_ASC",
  TargetsDesc = "TARGETS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  ZendeskTicketIdAsc = "ZENDESK_TICKET_ID_ASC",
  ZendeskTicketIdDesc = "ZENDESK_TICKET_ID_DESC",
}

/** The tag table which share among all member of the organization. */
export type Tag = Node & {
  __typename: "Tag";
  /** Get files by tag. */
  filesByTagName: FilesConnection;
  /** The name of the tag and also using as primary key. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Tag`. */
  orgByOrgId: Maybe<Org>;
  /** The uuid of the organization. */
  orgId: Scalars["UUID"];
  /** Get screens by tag. */
  screensByTagName: ScreensConnection;
  /** Reads a single `Space` that is related to this `Tag`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `TagAssociation`. */
  tagAssociationsByTagNameAndOrgIdAndSpaceId: TagAssociationsConnection;
};

/** The tag table which share among all member of the organization. */
export type TagFilesByTagNameArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The tag table which share among all member of the organization. */
export type TagScreensByTagNameArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** The tag table which share among all member of the organization. */
export type TagTagAssociationsByTagNameAndOrgIdAndSpaceIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TagAssociationCondition>;
  filter: InputMaybe<TagAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TagAssociationsOrderBy>>;
};

export type TagAssociation = {
  __typename: "TagAssociation";
  /** Reads a single `AppInstance` that is related to this `TagAssociation`. */
  appInstanceByToAppInstanceAndOrgId: Maybe<AppInstance>;
  /** Reads a single `Channel` that is related to this `TagAssociation`. */
  channelByToChannelAndOrgId: Maybe<Channel>;
  /** Reads a single `File` that is related to this `TagAssociation`. */
  fileByToFileAndOrgId: Maybe<File>;
  /** Reads a single `Folder` that is related to this `TagAssociation`. */
  folderByToFolderAndOrgId: Maybe<Folder>;
  /** Reads a single `Link` that is related to this `TagAssociation`. */
  linkByToLinkAndOrgId: Maybe<Link>;
  /** Reads a single `Org` that is related to this `TagAssociation`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Playlist` that is related to this `TagAssociation`. */
  playlistByToPlaylistAndOrgId: Maybe<Playlist>;
  /** Reads a single `Screen` that is related to this `TagAssociation`. */
  screenByToScreenAndOrgId: Maybe<Screen>;
  /** Reads a single `Site` that is related to this `TagAssociation`. */
  siteByToSiteAndOrgId: Maybe<Site>;
  /** Reads a single `Space` that is related to this `TagAssociation`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Scalars["UUID"];
  /** Reads a single `Tag` that is related to this `TagAssociation`. */
  tagByTagNameAndOrgIdAndSpaceId: Maybe<Tag>;
  tagName: Maybe<Scalars["String"]>;
  toAppInstance: Maybe<Scalars["UUID"]>;
  toChannel: Maybe<Scalars["UUID"]>;
  toFile: Maybe<Scalars["UUID"]>;
  toFolder: Maybe<Scalars["UUID"]>;
  toLink: Maybe<Scalars["UUID"]>;
  toPlaylist: Maybe<Scalars["UUID"]>;
  toScreen: Maybe<Scalars["UUID"]>;
  toSite: Maybe<Scalars["UUID"]>;
};

/**
 * A condition to be used against `TagAssociation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TagAssociationCondition = {
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `tagName` field. */
  tagName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `toAppInstance` field. */
  toAppInstance?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toChannel` field. */
  toChannel?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toFile` field. */
  toFile?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toFolder` field. */
  toFolder?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toLink` field. */
  toLink?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toPlaylist` field. */
  toPlaylist?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toScreen` field. */
  toScreen?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `toSite` field. */
  toSite?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `TagAssociation` object types. All fields are combined with a logical ‘and.’ */
export type TagAssociationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TagAssociationFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TagAssociationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TagAssociationFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tagName` field. */
  tagName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `toAppInstance` field. */
  toAppInstance?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toChannel` field. */
  toChannel?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toFile` field. */
  toFile?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toFolder` field. */
  toFolder?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toLink` field. */
  toLink?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toPlaylist` field. */
  toPlaylist?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toScreen` field. */
  toScreen?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `toSite` field. */
  toSite?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `TagAssociation` values. */
export type TagAssociationsConnection = {
  __typename: "TagAssociationsConnection";
  /** A list of edges which contains the `TagAssociation` and cursor to aid in pagination. */
  edges: Array<TagAssociationsEdge>;
  /** A list of `TagAssociation` objects. */
  nodes: Array<TagAssociation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TagAssociation` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `TagAssociation` edge in the connection. */
export type TagAssociationsEdge = {
  __typename: "TagAssociationsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `TagAssociation` at the end of the edge. */
  node: TagAssociation;
};

/** Methods to use when ordering `TagAssociation`. */
export enum TagAssociationsOrderBy {
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  TagNameAsc = "TAG_NAME_ASC",
  TagNameDesc = "TAG_NAME_DESC",
  ToAppInstanceAsc = "TO_APP_INSTANCE_ASC",
  ToAppInstanceDesc = "TO_APP_INSTANCE_DESC",
  ToChannelAsc = "TO_CHANNEL_ASC",
  ToChannelDesc = "TO_CHANNEL_DESC",
  ToFileAsc = "TO_FILE_ASC",
  ToFileDesc = "TO_FILE_DESC",
  ToFolderAsc = "TO_FOLDER_ASC",
  ToFolderDesc = "TO_FOLDER_DESC",
  ToLinkAsc = "TO_LINK_ASC",
  ToLinkDesc = "TO_LINK_DESC",
  ToPlaylistAsc = "TO_PLAYLIST_ASC",
  ToPlaylistDesc = "TO_PLAYLIST_DESC",
  ToScreenAsc = "TO_SCREEN_ASC",
  ToScreenDesc = "TO_SCREEN_DESC",
  ToSiteAsc = "TO_SITE_ASC",
  ToSiteDesc = "TO_SITE_DESC",
}

/** A condition to be used against `Tag` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TagCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Tag` object types. All fields are combined with a logical ‘and.’ */
export type TagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Tag` values. */
export type TagsConnection = {
  __typename: "TagsConnection";
  /** A list of edges which contains the `Tag` and cursor to aid in pagination. */
  edges: Array<TagsEdge>;
  /** A list of `Tag` objects. */
  nodes: Array<Tag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tag` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Tag` edge in the connection. */
export type TagsEdge = {
  __typename: "TagsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Tag` at the end of the edge. */
  node: Tag;
};

/** Methods to use when ordering `Tag`. */
export enum TagsOrderBy {
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
}

export type TakeScreenshotByScreenIdInput = {
  screenId: Scalars["String"];
};

export type TakeScreenshotByScreenIdPayload = {
  __typename: "TakeScreenshotByScreenIdPayload";
  screenshot: Maybe<Screenshot>;
};

/** Theme detail for each organization. */
export type Theme = Node & {
  __typename: "Theme";
  bodyFontId: Maybe<Scalars["UUID"]>;
  bodyFontIdDraft: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByThemeId: ChannelsConnection;
  /** The published theme configurations. */
  config: Scalars["JSON"];
  /** The drafted theme configuration. */
  configDraft: Maybe<Scalars["JSON"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileIdDraft: Maybe<File>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontIdDraft: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontIdDraft: Maybe<Font>;
  /** The primary unique identifier for the theme. */
  id: Scalars["UUID"];
  /** The file id of the uploaded logo. */
  logoFileId: Maybe<Scalars["UUID"]>;
  logoFileIdDraft: Maybe<Scalars["UUID"]>;
  /** The name of each theme. */
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Theme`. */
  orgByOrgId: Maybe<Org>;
  /** The id of the organization that theme belong to. */
  orgId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `Org`. */
  orgsByDefaultChannelThemeId: OrgsConnection;
  /** Reads and enables pagination through a set of `Org`. */
  orgsBySplashChannelThemeId: OrgsConnection;
  titleFontId: Maybe<Scalars["UUID"]>;
  titleFontIdDraft: Maybe<Scalars["UUID"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByUpdatedBy: Maybe<User>;
};

/** Theme detail for each organization. */
export type ThemeChannelsByThemeIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** Theme detail for each organization. */
export type ThemeOrgsByDefaultChannelThemeIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** Theme detail for each organization. */
export type ThemeOrgsBySplashChannelThemeIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** A condition to be used against `Theme` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ThemeCondition = {
  /** Checks for equality with the object’s `bodyFontId` field. */
  bodyFontId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `bodyFontIdDraft` field. */
  bodyFontIdDraft?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `configDraft` field. */
  configDraft?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `logoFileId` field. */
  logoFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `logoFileIdDraft` field. */
  logoFileIdDraft?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `titleFontId` field. */
  titleFontId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `titleFontIdDraft` field. */
  titleFontIdDraft?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Theme` object types. All fields are combined with a logical ‘and.’ */
export type ThemeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ThemeFilter>>;
  /** Filter by the object’s `bodyFontId` field. */
  bodyFontId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `bodyFontIdDraft` field. */
  bodyFontIdDraft?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `configDraft` field. */
  configDraft?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `logoFileId` field. */
  logoFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `logoFileIdDraft` field. */
  logoFileIdDraft?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ThemeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ThemeFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `titleFontId` field. */
  titleFontId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `titleFontIdDraft` field. */
  titleFontIdDraft?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Theme` values. */
export type ThemesConnection = {
  __typename: "ThemesConnection";
  /** A list of edges which contains the `Theme` and cursor to aid in pagination. */
  edges: Array<ThemesEdge>;
  /** A list of `Theme` objects. */
  nodes: Array<Theme>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Theme` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Theme` edge in the connection. */
export type ThemesEdge = {
  __typename: "ThemesEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Theme` at the end of the edge. */
  node: Theme;
};

/** Methods to use when ordering `Theme`. */
export enum ThemesOrderBy {
  BodyFontIdAsc = "BODY_FONT_ID_ASC",
  BodyFontIdDesc = "BODY_FONT_ID_DESC",
  BodyFontIdDraftAsc = "BODY_FONT_ID_DRAFT_ASC",
  BodyFontIdDraftDesc = "BODY_FONT_ID_DRAFT_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  ConfigDraftAsc = "CONFIG_DRAFT_ASC",
  ConfigDraftDesc = "CONFIG_DRAFT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  LogoFileIdAsc = "LOGO_FILE_ID_ASC",
  LogoFileIdDesc = "LOGO_FILE_ID_DESC",
  LogoFileIdDraftAsc = "LOGO_FILE_ID_DRAFT_ASC",
  LogoFileIdDraftDesc = "LOGO_FILE_ID_DRAFT_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TitleFontIdAsc = "TITLE_FONT_ID_ASC",
  TitleFontIdDesc = "TITLE_FONT_ID_DESC",
  TitleFontIdDraftAsc = "TITLE_FONT_ID_DRAFT_ASC",
  TitleFontIdDraftDesc = "TITLE_FONT_ID_DRAFT_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
}

/** A api access in Studio for the org. */
export type Token = Node & {
  __typename: "Token";
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** The timestamp when the api is got deactivated. */
  deactivatedAt: Maybe<Scalars["Datetime"]>;
  deletedAt: Maybe<Scalars["Datetime"]>;
  expireAt: Maybe<Scalars["Datetime"]>;
  /** The primary unique identifier for the api access. */
  id: Scalars["UUID"];
  isBot: Maybe<Scalars["Boolean"]>;
  lastUsed: Maybe<Scalars["Datetime"]>;
  name: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `Token`. */
  orgByOrgId: Maybe<Org>;
  /** The id of the organization that api belong to. */
  orgId: Scalars["UUID"];
  permissionSets: Array<Maybe<Scalars["UUID"]>>;
  permissions: Array<Maybe<Scalars["String"]>>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `Token`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUserId: Maybe<User>;
  /** The user/system connected to this api token */
  userId: Scalars["UUID"];
};

/** A condition to be used against `Token` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TokenCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `deactivatedAt` field. */
  deactivatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `expireAt` field. */
  expireAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isBot` field. */
  isBot?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `lastUsed` field. */
  lastUsed?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `permissionSets` field. */
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Checks for equality with the object’s `permissions` field. */
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `Token` object types. All fields are combined with a logical ‘and.’ */
export type TokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `deactivatedAt` field. */
  deactivatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `expireAt` field. */
  expireAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isBot` field. */
  isBot?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastUsed` field. */
  lastUsed?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `permissionSets` field. */
  permissionSets?: InputMaybe<UuidListFilter>;
  /** Filter by the object’s `permissions` field. */
  permissions?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

export type TokenSecretPayload = {
  __typename: "TokenSecretPayload";
  secret: Maybe<Scalars["String"]>;
  token: Maybe<Token>;
};

/** A connection to a list of `Token` values. */
export type TokensConnection = {
  __typename: "TokensConnection";
  /** A list of edges which contains the `Token` and cursor to aid in pagination. */
  edges: Array<TokensEdge>;
  /** A list of `Token` objects. */
  nodes: Array<Token>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Token` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `Token` edge in the connection. */
export type TokensEdge = {
  __typename: "TokensEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `Token` at the end of the edge. */
  node: Token;
};

/** Methods to use when ordering `Token`. */
export enum TokensOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DeactivatedAtAsc = "DEACTIVATED_AT_ASC",
  DeactivatedAtDesc = "DEACTIVATED_AT_DESC",
  DeletedAtAsc = "DELETED_AT_ASC",
  DeletedAtDesc = "DELETED_AT_DESC",
  ExpireAtAsc = "EXPIRE_AT_ASC",
  ExpireAtDesc = "EXPIRE_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsBotAsc = "IS_BOT_ASC",
  IsBotDesc = "IS_BOT_DESC",
  LastUsedAsc = "LAST_USED_ASC",
  LastUsedDesc = "LAST_USED_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PermissionsAsc = "PERMISSIONS_ASC",
  PermissionsDesc = "PERMISSIONS_DESC",
  PermissionSetsAsc = "PERMISSION_SETS_ASC",
  PermissionSetsDesc = "PERMISSION_SETS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** All input for the `transferScreens` mutation. */
export type TransferScreensInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenIds: Array<InputMaybe<Scalars["UUID"]>>;
  targetSpaceId: Scalars["UUID"];
};

/** The output of our `transferScreens` mutation. */
export type TransferScreensPayload = {
  __typename: "TransferScreensPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["UUID"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["UUID"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["UUID"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["UUID"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["UUID"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["UUID"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["UUID"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["UUID"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["UUID"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["UUID"]>>;
};

/** A filter to be used against UUID List fields. All fields are combined with a logical ‘and.’ */
export type UuidListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars["UUID"]>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

export type UnSubGainsightForUserResponse = {
  __typename: "UnSubGainsightForUserResponse";
  globalUnsubscribeUpdateStatus: Scalars["Int"];
  userPreferencesUpdateStatus: Scalars["Int"];
};

/** All input for the `uninstallOrgApp` mutation. */
export type UninstallOrgAppInput = {
  appInstallId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `uninstallOrgApp` mutation. */
export type UninstallOrgAppPayload = {
  __typename: "UninstallOrgAppPayload";
  /** Reads a single `App` that is related to this `AppInstall`. */
  appByAppId: Maybe<App>;
  appInstall: Maybe<AppInstall>;
  /** An edge for our `AppInstall`. May be used by Relay 1. */
  appInstallEdge: Maybe<AppInstallsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `AppInstall`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `AppInstall`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `uninstallOrgApp` mutation. */
export type UninstallOrgAppPayloadAppInstallEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** All input for the `uninstallSpaceApp` mutation. */
export type UninstallSpaceAppInput = {
  appInstallId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaceId: Scalars["UUID"];
};

/** The output of our `uninstallSpaceApp` mutation. */
export type UninstallSpaceAppPayload = {
  __typename: "UninstallSpaceAppPayload";
  /** Reads a single `App` that is related to this `AppInstall`. */
  appByAppId: Maybe<App>;
  appInstall: Maybe<AppInstall>;
  /** An edge for our `AppInstall`. May be used by Relay 1. */
  appInstallEdge: Maybe<AppInstallsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `AppInstall`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `AppInstall`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstall`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `uninstallSpaceApp` mutation. */
export type UninstallSpaceAppPayloadAppInstallEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** All input for the `unsubscribeAllScreenNotifications` mutation. */
export type UnsubscribeAllScreenNotificationsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `unsubscribeAllScreenNotifications` mutation. */
export type UnsubscribeAllScreenNotificationsPayload = {
  __typename: "UnsubscribeAllScreenNotificationsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRules: Maybe<Array<NotificationRule>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `unsubscribeScreenNotificationByScreenIds` mutation. */
export type UnsubscribeScreenNotificationByScreenIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  screenIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `unsubscribeScreenNotificationByScreenIds` mutation. */
export type UnsubscribeScreenNotificationByScreenIdsPayload = {
  __typename: "UnsubscribeScreenNotificationByScreenIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRules: Maybe<Array<NotificationRule>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `unsubscribeScreenNotificationBySpaceId` mutation. */
export type UnsubscribeScreenNotificationBySpaceIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `unsubscribeScreenNotificationBySpaceId` mutation. */
export type UnsubscribeScreenNotificationBySpaceIdPayload = {
  __typename: "UnsubscribeScreenNotificationBySpaceIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRules: Maybe<Array<NotificationRule>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateAppInstanceAvailableByIds` mutation. */
export type UpdateAppInstanceAvailableByIdsInput = {
  availableAt?: InputMaybe<Scalars["Datetime"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updateAppInstanceAvailableByIds` mutation. */
export type UpdateAppInstanceAvailableByIdsPayload = {
  __typename: "UpdateAppInstanceAvailableByIdsPayload";
  appInstances: Maybe<Array<AppInstance>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateAppInstanceAvailable` mutation. */
export type UpdateAppInstanceAvailableInput = {
  appInstanceId?: InputMaybe<Scalars["UUID"]>;
  availableAt?: InputMaybe<Scalars["Datetime"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateAppInstanceAvailable` mutation. */
export type UpdateAppInstanceAvailablePayload = {
  __typename: "UpdateAppInstanceAvailablePayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateAppInstanceAvailable` mutation. */
export type UpdateAppInstanceAvailablePayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `updateAppInstanceExpireByIds` mutation. */
export type UpdateAppInstanceExpireByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expireAt?: InputMaybe<Scalars["Datetime"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updateAppInstanceExpireByIds` mutation. */
export type UpdateAppInstanceExpireByIdsPayload = {
  __typename: "UpdateAppInstanceExpireByIdsPayload";
  appInstances: Maybe<Array<AppInstance>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateAppInstanceExpire` mutation. */
export type UpdateAppInstanceExpireInput = {
  appInstanceId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expireAt?: InputMaybe<Scalars["Datetime"]>;
};

/** The output of our `updateAppInstanceExpire` mutation. */
export type UpdateAppInstanceExpirePayload = {
  __typename: "UpdateAppInstanceExpirePayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateAppInstanceExpire` mutation. */
export type UpdateAppInstanceExpirePayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `updateAppInstance` mutation. */
export type UpdateAppInstanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  isTemplate?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  state?: InputMaybe<Scalars["JSON"]>;
  status?: InputMaybe<AppInstanceStatus>;
  thumbnailFileId?: InputMaybe<Scalars["UUID"]>;
  version?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateAppInstance` mutation. */
export type UpdateAppInstancePayload = {
  __typename: "UpdateAppInstancePayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateAppInstance` mutation. */
export type UpdateAppInstancePayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `updateAppInstanceTagsByAppIdAndSpaceId` mutation. */
export type UpdateAppInstanceTagsByAppIdAndSpaceIdInput = {
  appId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateAppInstanceTagsByAppIdAndSpaceId` mutation. */
export type UpdateAppInstanceTagsByAppIdAndSpaceIdPayload = {
  __typename: "UpdateAppInstanceTagsByAppIdAndSpaceIdPayload";
  appInstances: Maybe<Array<AppInstance>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateAppInstanceTagsByIds` mutation. */
export type UpdateAppInstanceTagsByIdsInput = {
  appInstanceIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateAppInstanceTagsByIds` mutation. */
export type UpdateAppInstanceTagsByIdsPayload = {
  __typename: "UpdateAppInstanceTagsByIdsPayload";
  appInstances: Maybe<Array<AppInstance>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateAppInstanceTags` mutation. */
export type UpdateAppInstanceTagsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateAppInstanceTags` mutation. */
export type UpdateAppInstanceTagsPayload = {
  __typename: "UpdateAppInstanceTagsPayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateAppInstanceTags` mutation. */
export type UpdateAppInstanceTagsPayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

export type UpdateBillingAddressInput = {
  city?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  country: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  line1: Scalars["String"];
  line2?: InputMaybe<Scalars["String"]>;
  line3?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  stateCode?: InputMaybe<Scalars["String"]>;
  vatNumber?: InputMaybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export type UpdateBillingAddressPayload = {
  __typename: "UpdateBillingAddressPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `updateBot` mutation. */
export type UpdateBotInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  status?: InputMaybe<UserStatus>;
};

/** The output of our `updateBot` mutation. */
export type UpdateBotPayload = {
  __typename: "UpdateBotPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `updateBot` mutation. */
export type UpdateBotPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateChannelCover` mutation. */
export type UpdateChannelCoverInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  coverData?: InputMaybe<Scalars["JSON"]>;
  coverImageId?: InputMaybe<Scalars["UUID"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateChannelCover` mutation. */
export type UpdateChannelCoverPayload = {
  __typename: "UpdateChannelCoverPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannelCover` mutation. */
export type UpdateChannelCoverPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateChannelDefaultSharingMode` mutation. */
export type UpdateChannelDefaultSharingModeInput = {
  channelId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  sharingMode: Scalars["String"];
};

/** The output of our `updateChannelDefaultSharingMode` mutation. */
export type UpdateChannelDefaultSharingModePayload = {
  __typename: "UpdateChannelDefaultSharingModePayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannelDefaultSharingMode` mutation. */
export type UpdateChannelDefaultSharingModePayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateChannelEmbed` mutation. */
export type UpdateChannelEmbedInput = {
  channelId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isEmbedEnabled?: InputMaybe<Scalars["Boolean"]>;
  isEmbedPublic?: InputMaybe<Scalars["Boolean"]>;
};

/** The output of our `updateChannelEmbed` mutation. */
export type UpdateChannelEmbedPayload = {
  __typename: "UpdateChannelEmbedPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannelEmbed` mutation. */
export type UpdateChannelEmbedPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateChannelEnable` mutation. */
export type UpdateChannelEnableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  isEnabled: Scalars["Boolean"];
};

/** The output of our `updateChannelEnable` mutation. */
export type UpdateChannelEnablePayload = {
  __typename: "UpdateChannelEnablePayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannelEnable` mutation. */
export type UpdateChannelEnablePayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateChannel` mutation. */
export type UpdateChannelInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  height?: InputMaybe<Scalars["Int"]>;
  hiddenZone?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  layoutId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

/** All input for the `updateChannelName` mutation. */
export type UpdateChannelNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
};

/** The output of our `updateChannelName` mutation. */
export type UpdateChannelNamePayload = {
  __typename: "UpdateChannelNamePayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannelName` mutation. */
export type UpdateChannelNamePayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** The output of our `updateChannel` mutation. */
export type UpdateChannelPayload = {
  __typename: "UpdateChannelPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannel` mutation. */
export type UpdateChannelPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateChannelShortIdByChannelId` mutation. */
export type UpdateChannelShortIdByChannelIdInput = {
  channelId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  newShortId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateChannelShortIdByChannelId` mutation. */
export type UpdateChannelShortIdByChannelIdPayload = {
  __typename: "UpdateChannelShortIdByChannelIdPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannelShortIdByChannelId` mutation. */
export type UpdateChannelShortIdByChannelIdPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateChannelTags` mutation. */
export type UpdateChannelTagsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateChannelTags` mutation. */
export type UpdateChannelTagsPayload = {
  __typename: "UpdateChannelTagsPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannelTags` mutation. */
export type UpdateChannelTagsPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateChannelTheme` mutation. */
export type UpdateChannelThemeInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  themeId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateChannelTheme` mutation. */
export type UpdateChannelThemePayload = {
  __typename: "UpdateChannelThemePayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateChannelTheme` mutation. */
export type UpdateChannelThemePayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateCommonAppInstanceTemplateBySourceAppInstanceId` mutation. */
export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isDeprecated?: InputMaybe<Scalars["Boolean"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  sourceAppInstanceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateCommonAppInstanceTemplateBySourceAppInstanceId` mutation. */
export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdPayload = {
  __typename: "UpdateCommonAppInstanceTemplateBySourceAppInstanceIdPayload";
  /** Reads a single `App` that is related to this `CommonAppInstanceTemplate`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstance` that is related to this `CommonAppInstanceTemplate`. */
  appInstanceBySourceAppInstanceId: Maybe<AppInstance>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  commonAppInstanceTemplate: Maybe<CommonAppInstanceTemplate>;
  /** An edge for our `CommonAppInstanceTemplate`. May be used by Relay 1. */
  commonAppInstanceTemplateEdge: Maybe<CommonAppInstanceTemplatesEdge>;
  /** Reads a single `CommonFile` that is related to this `CommonAppInstanceTemplate`. */
  commonFileByThumbnailFileId: Maybe<CommonFile>;
  /** Reads a single `Org` that is related to this `CommonAppInstanceTemplate`. */
  orgBySourceOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `updateCommonAppInstanceTemplateBySourceAppInstanceId` mutation. */
export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdPayloadCommonAppInstanceTemplateEdgeArgs = {
  orderBy?: InputMaybe<Array<CommonAppInstanceTemplatesOrderBy>>;
};

/** All input for the `updateCredential` mutation. */
export type UpdateCredentialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateCredential` mutation. */
export type UpdateCredentialPayload = {
  __typename: "UpdateCredentialPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  credential: Maybe<Credential>;
  /** An edge for our `Credential`. May be used by Relay 1. */
  credentialEdge: Maybe<CredentialsEdge>;
  /** Reads a single `Org` that is related to this `Credential`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Credential`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `updateCredential` mutation. */
export type UpdateCredentialPayloadCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

/** All input for the `updateFileAvailableByIds` mutation. */
export type UpdateFileAvailableByIdsInput = {
  availableAt?: InputMaybe<Scalars["Datetime"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updateFileAvailableByIds` mutation. */
export type UpdateFileAvailableByIdsPayload = {
  __typename: "UpdateFileAvailableByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  files: Maybe<Array<File>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateFileAvailable` mutation. */
export type UpdateFileAvailableInput = {
  availableAt?: InputMaybe<Scalars["Datetime"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateFileAvailable` mutation. */
export type UpdateFileAvailablePayload = {
  __typename: "UpdateFileAvailablePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateFileAvailable` mutation. */
export type UpdateFileAvailablePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateFileById` mutation. */
export type UpdateFileByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
  folderId?: InputMaybe<Scalars["UUID"]>;
  metadata?: InputMaybe<Scalars["JSON"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["BigInt"]>;
  source?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateFileById` mutation. */
export type UpdateFileByIdPayload = {
  __typename: "UpdateFileByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateFileById` mutation. */
export type UpdateFileByIdPayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateFileByIds` mutation. */
export type UpdateFileByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderId?: InputMaybe<Scalars["UUID"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  metadata?: InputMaybe<Scalars["JSON"]>;
  name?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateFileByIds` mutation. */
export type UpdateFileByIdsPayload = {
  __typename: "UpdateFileByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  files: Maybe<Array<File>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateFileExpireByIds` mutation. */
export type UpdateFileExpireByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expireAt?: InputMaybe<Scalars["Datetime"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updateFileExpireByIds` mutation. */
export type UpdateFileExpireByIdsPayload = {
  __typename: "UpdateFileExpireByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  files: Maybe<Array<File>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateFileExpire` mutation. */
export type UpdateFileExpireInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expireAt?: InputMaybe<Scalars["Datetime"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateFileExpire` mutation. */
export type UpdateFileExpirePayload = {
  __typename: "UpdateFileExpirePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateFileExpire` mutation. */
export type UpdateFileExpirePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateFileIsFavorite` mutation. */
export type UpdateFileIsFavoriteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
};

/** The output of our `updateFileIsFavorite` mutation. */
export type UpdateFileIsFavoritePayload = {
  __typename: "UpdateFileIsFavoritePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorites: Maybe<Array<Favorite>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateFolderById` mutation. */
export type UpdateFolderByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateFolderById` mutation. */
export type UpdateFolderByIdPayload = {
  __typename: "UpdateFolderByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  folder: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Folder`. */
  folderByParentId: Maybe<Folder>;
  /** An edge for our `Folder`. May be used by Relay 1. */
  folderEdge: Maybe<FoldersEdge>;
  /** Reads a single `Org` that is related to this `Folder`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateFolderById` mutation. */
export type UpdateFolderByIdPayloadFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** All input for the `updateFolderByIds` mutation. */
export type UpdateFolderByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  parentId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateFolderByIds` mutation. */
export type UpdateFolderByIdsPayload = {
  __typename: "UpdateFolderByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  folders: Maybe<Array<Folder>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateFolderIsFavorite` mutation. */
export type UpdateFolderIsFavoriteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
};

/** The output of our `updateFolderIsFavorite` mutation. */
export type UpdateFolderIsFavoritePayload = {
  __typename: "UpdateFolderIsFavoritePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorites: Maybe<Array<Favorite>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateFont` mutation. */
export type UpdateFontInput = {
  boldFileId?: InputMaybe<Scalars["UUID"]>;
  boldItalicFileId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  cssUrl?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  italicFileId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  regularFileId?: InputMaybe<Scalars["UUID"]>;
  thumbnailFileId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateFont` mutation. */
export type UpdateFontPayload = {
  __typename: "UpdateFontPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFont` that is related to this `Font`. */
  commonFontByCommonFontId: Maybe<CommonFont>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByBoldItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByItalicFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByRegularFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Font`. */
  fileByThumbnailFileId: Maybe<File>;
  font: Maybe<Font>;
  /** An edge for our `Font`. May be used by Relay 1. */
  fontEdge: Maybe<FontsEdge>;
  /** Reads a single `Org` that is related to this `Font`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Font`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Font`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateFont` mutation. */
export type UpdateFontPayloadFontEdgeArgs = {
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** All input for the `updateGroup` mutation. */
export type UpdateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateGroup` mutation. */
export type UpdateGroupPayload = {
  __typename: "UpdateGroupPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  group: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge: Maybe<GroupsEdge>;
  /** Reads a single `Org` that is related to this `Group`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Group`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Group`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateGroup` mutation. */
export type UpdateGroupPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `updateGroupSpace` mutation. */
export type UpdateGroupSpaceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["UUID"]>;
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateGroupSpace` mutation. */
export type UpdateGroupSpacePayload = {
  __typename: "UpdateGroupSpacePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupId: Maybe<Group>;
  /** Reads a single `Group` that is related to this `GroupSpace`. */
  groupByGroupIdAndOrgId: Maybe<Group>;
  groupSpace: Maybe<GroupSpace>;
  /** An edge for our `GroupSpace`. May be used by Relay 1. */
  groupSpaceEdge: Maybe<GroupSpacesEdge>;
  /** Reads a single `Org` that is related to this `GroupSpace`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `GroupSpace`. */
  spaceBySpaceId: Maybe<Space>;
};

/** The output of our `updateGroupSpace` mutation. */
export type UpdateGroupSpacePayloadGroupSpaceEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupSpacesOrderBy>>;
};

/** All input for the `updateInviteUser` mutation. */
export type UpdateInviteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  connection?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateInviteUser` mutation. */
export type UpdateInviteUserPayload = {
  __typename: "UpdateInviteUserPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `updateInviteUser` mutation. */
export type UpdateInviteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateLayout` mutation. */
export type UpdateLayoutInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateLayout` mutation. */
export type UpdateLayoutPayload = {
  __typename: "UpdateLayoutPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  layout: Maybe<Layout>;
  /** An edge for our `Layout`. May be used by Relay 1. */
  layoutEdge: Maybe<LayoutsEdge>;
  /** Reads a single `Org` that is related to this `Layout`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Layout`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Layout`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Layout`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateLayout` mutation. */
export type UpdateLayoutPayloadLayoutEdgeArgs = {
  orderBy?: InputMaybe<Array<LayoutsOrderBy>>;
};

/** All input for the `updateLinkById` mutation. */
export type UpdateLinkByIdInput = {
  autoReload?: InputMaybe<Scalars["Boolean"]>;
  autoReloadDurationMs?: InputMaybe<Scalars["Int"]>;
  cacheBusting?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  cloudConfig?: InputMaybe<Scalars["JSON"]>;
  fileId?: InputMaybe<Scalars["UUID"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  linkType?: InputMaybe<LinkType>;
  name?: InputMaybe<Scalars["String"]>;
  params?: InputMaybe<Scalars["JSON"]>;
  qrcodeConfig?: InputMaybe<Scalars["JSON"]>;
  qrcodeEnabled?: InputMaybe<Scalars["Boolean"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateLinkById` mutation. */
export type UpdateLinkByIdPayload = {
  __typename: "UpdateLinkByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileIdAndOrgId: Maybe<File>;
  link: Maybe<Link>;
  /** An edge for our `Link`. May be used by Relay 1. */
  linkEdge: Maybe<LinksEdge>;
  /** Reads a single `Org` that is related to this `Link`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Link`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Link`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateLinkById` mutation. */
export type UpdateLinkByIdPayloadLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** All input for the `updateLinkIsFavorite` mutation. */
export type UpdateLinkIsFavoriteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  linkIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updateLinkIsFavorite` mutation. */
export type UpdateLinkIsFavoritePayload = {
  __typename: "UpdateLinkIsFavoritePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorites: Maybe<Array<Favorite>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updateNotificationRuleById` mutation. */
export type UpdateNotificationRuleByIdInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  notificationRuleId?: InputMaybe<Scalars["UUID"]>;
  waitTimeInSecond?: InputMaybe<Scalars["Int"]>;
};

/** The output of our `updateNotificationRuleById` mutation. */
export type UpdateNotificationRuleByIdPayload = {
  __typename: "UpdateNotificationRuleByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  notificationRule: Maybe<NotificationRule>;
  /** An edge for our `NotificationRule`. May be used by Relay 1. */
  notificationRuleEdge: Maybe<NotificationRulesEdge>;
  /** Reads a single `Org` that is related to this `NotificationRule`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Screen` that is related to this `NotificationRule`. */
  screenByScreenIdAndOrgId: Maybe<Screen>;
  /** Reads a single `Space` that is related to this `NotificationRule`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `NotificationRule`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `updateNotificationRuleById` mutation. */
export type UpdateNotificationRuleByIdPayloadNotificationRuleEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** All input for the `updateNotificationSubscriptionById` mutation. */
export type UpdateNotificationSubscriptionByIdInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  notificationSubscriptionId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateNotificationSubscriptionById` mutation. */
export type UpdateNotificationSubscriptionByIdPayload = {
  __typename: "UpdateNotificationSubscriptionByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `NotificationRule` that is related to this `NotificationSubscription`. */
  notificationRuleByNotificationRuleId: Maybe<NotificationRule>;
  notificationSubscription: Maybe<NotificationSubscription>;
  /** An edge for our `NotificationSubscription`. May be used by Relay 1. */
  notificationSubscriptionEdge: Maybe<NotificationSubscriptionsEdge>;
  /** Reads a single `Org` that is related to this `NotificationSubscription`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `NotificationSubscription`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `NotificationSubscription`. */
  userByUserIdAndOrgId: Maybe<User>;
};

/** The output of our `updateNotificationSubscriptionById` mutation. */
export type UpdateNotificationSubscriptionByIdPayloadNotificationSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationSubscriptionsOrderBy>>;
};

/** All input for the `updateOrgBrandingConfig` mutation. */
export type UpdateOrgBrandingConfigInput = {
  brandBackgroundColor?: InputMaybe<Scalars["String"]>;
  brandBackgroundId?: InputMaybe<Scalars["UUID"]>;
  brandForegroundColor?: InputMaybe<Scalars["String"]>;
  brandLogoId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateOrgBrandingConfig` mutation. */
export type UpdateOrgBrandingConfigPayload = {
  __typename: "UpdateOrgBrandingConfigPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrgBrandingConfig` mutation. */
export type UpdateOrgBrandingConfigPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `updateOrgConnection` mutation. */
export type UpdateOrgConnectionInput = {
  automaticEnroll?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  required?: InputMaybe<Scalars["Boolean"]>;
};

/** The output of our `updateOrgConnection` mutation. */
export type UpdateOrgConnectionPayload = {
  __typename: "UpdateOrgConnectionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `OrgConnection`. */
  orgByOrgId: Maybe<Org>;
  orgConnection: Maybe<OrgConnection>;
  /** An edge for our `OrgConnection`. May be used by Relay 1. */
  orgConnectionEdge: Maybe<OrgConnectionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `OrgConnection`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `OrgConnection`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrgConnection` mutation. */
export type UpdateOrgConnectionPayloadOrgConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgConnectionsOrderBy>>;
};

/** All input for the `updateOrg` mutation. */
export type UpdateOrgInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  env?: InputMaybe<Scalars["JSON"]>;
  name?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

/** All input for the `updateOrgOnboardingData` mutation. */
export type UpdateOrgOnboardingDataInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  key: Scalars["String"];
  value: Scalars["Boolean"];
};

/** The output of our `updateOrgOnboardingData` mutation. */
export type UpdateOrgOnboardingDataPayload = {
  __typename: "UpdateOrgOnboardingDataPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrgOnboardingData` mutation. */
export type UpdateOrgOnboardingDataPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `updateOrgOverridePlayerLogConfig` mutation. */
export type UpdateOrgOverridePlayerLogConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  playerConfig?: InputMaybe<Scalars["JSON"]>;
};

/** The output of our `updateOrgOverridePlayerLogConfig` mutation. */
export type UpdateOrgOverridePlayerLogConfigPayload = {
  __typename: "UpdateOrgOverridePlayerLogConfigPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrgOverridePlayerLogConfig` mutation. */
export type UpdateOrgOverridePlayerLogConfigPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `updateOrgOverridePlayerUrl` mutation. */
export type UpdateOrgOverridePlayerUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  playerUrl?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateOrgOverridePlayerUrl` mutation. */
export type UpdateOrgOverridePlayerUrlPayload = {
  __typename: "UpdateOrgOverridePlayerUrlPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrgOverridePlayerUrl` mutation. */
export type UpdateOrgOverridePlayerUrlPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** The output of our `updateOrg` mutation. */
export type UpdateOrgPayload = {
  __typename: "UpdateOrgPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrg` mutation. */
export type UpdateOrgPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `updateOrgPlayerDisplayErrorMode` mutation. */
export type UpdateOrgPlayerDisplayErrorModeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  displayErrorMode?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateOrgPlayerDisplayErrorMode` mutation. */
export type UpdateOrgPlayerDisplayErrorModePayload = {
  __typename: "UpdateOrgPlayerDisplayErrorModePayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrgPlayerDisplayErrorMode` mutation. */
export type UpdateOrgPlayerDisplayErrorModePayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `updateOrgSetting` mutation. */
export type UpdateOrgSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  key?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateOrgSetting` mutation. */
export type UpdateOrgSettingPayload = {
  __typename: "UpdateOrgSettingPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrgSetting` mutation. */
export type UpdateOrgSettingPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `updateOrgSettings` mutation. */
export type UpdateOrgSettingsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  settingsData: Scalars["JSON"];
};

/** The output of our `updateOrgSettings` mutation. */
export type UpdateOrgSettingsPayload = {
  __typename: "UpdateOrgSettingsPayload";
  /** Reads a single `BillingCustomer` that is related to this `Org`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /** Reads a single `BillingDistributor` that is related to this `Org`. */
  billingDistributorByBillingDistributorId: Maybe<BillingDistributor>;
  /** Reads a single `BillingReseller` that is related to this `Org`. */
  billingResellerByBillingResellerId: Maybe<BillingReseller>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBlankChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByBrandChannelId: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Org`. */
  channelByStartChannelId: Maybe<Channel>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandBackgroundId: Maybe<File>;
  /** Reads a single `File` that is related to this `Org`. */
  fileByBrandLogoId: Maybe<File>;
  org: Maybe<Org>;
  /** An edge for our `Org`. May be used by Relay 1. */
  orgEdge: Maybe<OrgsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeByDefaultChannelThemeId: Maybe<Theme>;
  /** Reads a single `Theme` that is related to this `Org`. */
  themeBySplashChannelThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Org`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Org`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateOrgSettings` mutation. */
export type UpdateOrgSettingsPayloadOrgEdgeArgs = {
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** All input for the `updatePermissionSet` mutation. */
export type UpdatePermissionSetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updatePermissionSet` mutation. */
export type UpdatePermissionSetPayload = {
  __typename: "UpdatePermissionSetPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonPermissionSet` that is related to this `PermissionSet`. */
  commonPermissionSetByCommonPermissionSetId: Maybe<CommonPermissionSet>;
  /** Reads a single `Org` that is related to this `PermissionSet`. */
  orgByOrgId: Maybe<Org>;
  permissionSet: Maybe<PermissionSet>;
  /** An edge for our `PermissionSet`. May be used by Relay 1. */
  permissionSetEdge: Maybe<PermissionSetsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our `updatePermissionSet` mutation. */
export type UpdatePermissionSetPayloadPermissionSetEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionSetsOrderBy>>;
};

/** All input for the `updatePlaylistColor` mutation. */
export type UpdatePlaylistColorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  color: Scalars["String"];
  id: Scalars["UUID"];
};

/** The output of our `updatePlaylistColor` mutation. */
export type UpdatePlaylistColorPayload = {
  __typename: "UpdatePlaylistColorPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updatePlaylistColor` mutation. */
export type UpdatePlaylistColorPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `updatePlaylistEmbed` mutation. */
export type UpdatePlaylistEmbedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isEmbedEnabled?: InputMaybe<Scalars["Boolean"]>;
  isEmbedPublic?: InputMaybe<Scalars["Boolean"]>;
  playlistId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updatePlaylistEmbed` mutation. */
export type UpdatePlaylistEmbedPayload = {
  __typename: "UpdatePlaylistEmbedPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updatePlaylistEmbed` mutation. */
export type UpdatePlaylistEmbedPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `updatePlaylistEnable` mutation. */
export type UpdatePlaylistEnableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  isEnabled: Scalars["Boolean"];
};

/** The output of our `updatePlaylistEnable` mutation. */
export type UpdatePlaylistEnablePayload = {
  __typename: "UpdatePlaylistEnablePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updatePlaylistEnable` mutation. */
export type UpdatePlaylistEnablePayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `updatePlaylist` mutation. */
export type UpdatePlaylistInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** All input for the `updatePlaylistIsFavorite` mutation. */
export type UpdatePlaylistIsFavoriteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  playlistIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updatePlaylistIsFavorite` mutation. */
export type UpdatePlaylistIsFavoritePayload = {
  __typename: "UpdatePlaylistIsFavoritePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorites: Maybe<Array<Favorite>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `updatePlaylistName` mutation. */
export type UpdatePlaylistNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
};

/** The output of our `updatePlaylistName` mutation. */
export type UpdatePlaylistNamePayload = {
  __typename: "UpdatePlaylistNamePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updatePlaylistName` mutation. */
export type UpdatePlaylistNamePayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** The output of our `updatePlaylist` mutation. */
export type UpdatePlaylistPayload = {
  __typename: "UpdatePlaylistPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updatePlaylist` mutation. */
export type UpdatePlaylistPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `updatePlaylistShortIdByPlaylistId` mutation. */
export type UpdatePlaylistShortIdByPlaylistIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  newShortId?: InputMaybe<Scalars["String"]>;
  playlistId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updatePlaylistShortIdByPlaylistId` mutation. */
export type UpdatePlaylistShortIdByPlaylistIdPayload = {
  __typename: "UpdatePlaylistShortIdByPlaylistIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updatePlaylistShortIdByPlaylistId` mutation. */
export type UpdatePlaylistShortIdByPlaylistIdPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `updatePlaylistTags` mutation. */
export type UpdatePlaylistTagsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updatePlaylistTags` mutation. */
export type UpdatePlaylistTagsPayload = {
  __typename: "UpdatePlaylistTagsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updatePlaylistTags` mutation. */
export type UpdatePlaylistTagsPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

export type UpdateRelationshipInput = {
  customerId: Scalars["String"];
  email: Scalars["String"];
  invoicee: Scalars["String"];
  name: Scalars["String"];
  payee: Scalars["String"];
  spaceIds: Array<Scalars["String"]>;
};

export type UpdateRelationshipPayload = {
  __typename: "UpdateRelationshipPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `updateScreenById` mutation. */
export type UpdateScreenByIdInput = {
  additionalDeviceSettings?: InputMaybe<Scalars["JSON"]>;
  additionalPlayerSettings?: InputMaybe<Scalars["JSON"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  device?: InputMaybe<Scalars["JSON"]>;
  env?: InputMaybe<Scalars["JSON"]>;
  highResolutionImage?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  operatingHours?: InputMaybe<Scalars["JSON"]>;
  playbackMode?: InputMaybe<Scalars["String"]>;
  preferences?: InputMaybe<Scalars["JSON"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  rotation?: InputMaybe<ScreenRotation>;
  screenGroupId?: InputMaybe<Scalars["UUID"]>;
  status?: InputMaybe<ScreenStatus>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  timezoneOverride?: InputMaybe<Scalars["String"]>;
  videoSource?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateScreenById` mutation. */
export type UpdateScreenByIdPayload = {
  __typename: "UpdateScreenByIdPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateScreenById` mutation. */
export type UpdateScreenByIdPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `updateScreenByIds` mutation. */
export type UpdateScreenByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["JSON"]>;
  env?: InputMaybe<Scalars["JSON"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  preferences?: InputMaybe<Scalars["JSON"]>;
  rotation?: InputMaybe<ScreenRotation>;
  screenGroupId?: InputMaybe<Scalars["UUID"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateScreenByIds` mutation. */
export type UpdateScreenByIdsPayload = {
  __typename: "UpdateScreenByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

export type UpdateScreenDeviceAllowCloneInput = {
  allowClone: Scalars["Boolean"];
  screenId: Scalars["UUID"];
};

export type UpdateScreenDeviceAllowClonePayload = {
  __typename: "UpdateScreenDeviceAllowClonePayload";
  allowClone: Maybe<Scalars["Boolean"]>;
  playerId: Maybe<Scalars["UUID"]>;
  screenId: Maybe<Scalars["UUID"]>;
};

/** All input for the `updateScreenDeviceInfo` mutation. */
export type UpdateScreenDeviceInfoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  deviceInfo?: InputMaybe<Scalars["JSON"]>;
  deviceRaw?: InputMaybe<Scalars["JSON"]>;
};

/** The output of our `updateScreenDeviceInfo` mutation. */
export type UpdateScreenDeviceInfoPayload = {
  __typename: "UpdateScreenDeviceInfoPayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateScreenDeviceInfo` mutation. */
export type UpdateScreenDeviceInfoPayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `updateScreenDisplayErrorMode` mutation. */
export type UpdateScreenDisplayErrorModeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  displayErrorMode?: InputMaybe<Scalars["String"]>;
  screenId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateScreenDisplayErrorMode` mutation. */
export type UpdateScreenDisplayErrorModePayload = {
  __typename: "UpdateScreenDisplayErrorModePayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateScreenDisplayErrorMode` mutation. */
export type UpdateScreenDisplayErrorModePayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `updateScreenGroupById` mutation. */
export type UpdateScreenGroupByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  env: Scalars["JSON"];
  name: Scalars["String"];
  screenGroupId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
};

/** The output of our `updateScreenGroupById` mutation. */
export type UpdateScreenGroupByIdPayload = {
  __typename: "UpdateScreenGroupByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `ScreenGroup`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screenGroup: Maybe<ScreenGroup>;
  /** An edge for our `ScreenGroup`. May be used by Relay 1. */
  screenGroupEdge: Maybe<ScreenGroupsEdge>;
  /** Reads a single `Space` that is related to this `ScreenGroup`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `ScreenGroup`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `ScreenGroup`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateScreenGroupById` mutation. */
export type UpdateScreenGroupByIdPayloadScreenGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenGroupsOrderBy>>;
};

/** All input for the `updateScreenLocationByIds` mutation. */
export type UpdateScreenLocationByIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  locationDetail?: InputMaybe<Scalars["JSON"]>;
};

/** The output of our `updateScreenLocationByIds` mutation. */
export type UpdateScreenLocationByIdsPayload = {
  __typename: "UpdateScreenLocationByIdsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screens: Maybe<Array<Screen>>;
};

/** All input for the `updateScreenNoteById` mutation. */
export type UpdateScreenNoteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  newNote: Scalars["String"];
  screenNoteId: Scalars["UUID"];
};

/** The output of our `updateScreenNoteById` mutation. */
export type UpdateScreenNoteByIdPayload = {
  __typename: "UpdateScreenNoteByIdPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `ScreenNote`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Screen` that is related to this `ScreenNote`. */
  screenByScreenId: Maybe<Screen>;
  screenNote: Maybe<ScreenNote>;
  /** An edge for our `ScreenNote`. May be used by Relay 1. */
  screenNoteEdge: Maybe<ScreenNotesEdge>;
  /** Reads a single `Space` that is related to this `ScreenNote`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `ScreenNote`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `ScreenNote`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateScreenNoteById` mutation. */
export type UpdateScreenNoteByIdPayloadScreenNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** All input for the `updateSelf` mutation. */
export type UpdateSelfInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
};

/** The output of our `updateSelf` mutation. */
export type UpdateSelfPayload = {
  __typename: "UpdateSelfPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `updateSelf` mutation. */
export type UpdateSelfPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateSelfScreenDevice` mutation. */
export type UpdateSelfScreenDeviceInput = {
  additionalPlayerSettings?: InputMaybe<Scalars["JSON"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  device?: InputMaybe<Scalars["JSON"]>;
  deviceHostname?: InputMaybe<Scalars["String"]>;
  deviceIpAddress?: InputMaybe<Scalars["String"]>;
  playerHeight?: InputMaybe<Scalars["Int"]>;
  playerRelease?: InputMaybe<Scalars["String"]>;
  playerTimezone?: InputMaybe<Scalars["String"]>;
  playerWidth?: InputMaybe<Scalars["Int"]>;
};

/** The output of our `updateSelfScreenDevice` mutation. */
export type UpdateSelfScreenDevicePayload = {
  __typename: "UpdateSelfScreenDevicePayload";
  /** Reads a single `Cast` that is related to this `Screen`. */
  castByCastId: Maybe<Cast>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Screen`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  screen: Maybe<Screen>;
  /** An edge for our `Screen`. May be used by Relay 1. */
  screenEdge: Maybe<ScreensEdge>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupId: Maybe<ScreenGroup>;
  /** Reads a single `ScreenGroup` that is related to this `Screen`. */
  screenGroupByScreenGroupIdAndOrgId: Maybe<ScreenGroup>;
  /** Reads a single `Space` that is related to this `Screen`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Screen`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateSelfScreenDevice` mutation. */
export type UpdateSelfScreenDevicePayloadScreenEdgeArgs = {
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** All input for the `updateShareAppInstanceToAllSpaces` mutation. */
export type UpdateShareAppInstanceToAllSpacesInput = {
  appInstanceId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isSharedAll: Scalars["Boolean"];
};

/** The output of our `updateShareAppInstanceToAllSpaces` mutation. */
export type UpdateShareAppInstanceToAllSpacesPayload = {
  __typename: "UpdateShareAppInstanceToAllSpacesPayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareAppInstanceToAllSpaces` mutation. */
export type UpdateShareAppInstanceToAllSpacesPayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `updateShareAppInstanceToSpaces` mutation. */
export type UpdateShareAppInstanceToSpacesInput = {
  appInstanceId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaces?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updateShareAppInstanceToSpaces` mutation. */
export type UpdateShareAppInstanceToSpacesPayload = {
  __typename: "UpdateShareAppInstanceToSpacesPayload";
  /** Reads a single `App` that is related to this `AppInstance`. */
  appByAppId: Maybe<App>;
  /** Reads a single `AppInstall` that is related to this `AppInstance`. */
  appInstallByAppInstallId: Maybe<AppInstall>;
  appInstance: Maybe<AppInstance>;
  /** An edge for our `AppInstance`. May be used by Relay 1. */
  appInstanceEdge: Maybe<AppInstancesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `CommonAppInstanceTemplate` that is related to this `AppInstance`. */
  commonAppInstanceTemplateByTargetCommonAppInstanceTemplateId: Maybe<CommonAppInstanceTemplate>;
  /** Reads a single `File` that is related to this `AppInstance`. */
  fileByThumbnailFileId: Maybe<File>;
  /** Reads a single `Org` that is related to this `AppInstance`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `AppInstance`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareAppInstanceToSpaces` mutation. */
export type UpdateShareAppInstanceToSpacesPayloadAppInstanceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** All input for the `updateShareChannelToAllSpaces` mutation. */
export type UpdateShareChannelToAllSpacesInput = {
  channelId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isSharedAll: Scalars["Boolean"];
};

/** The output of our `updateShareChannelToAllSpaces` mutation. */
export type UpdateShareChannelToAllSpacesPayload = {
  __typename: "UpdateShareChannelToAllSpacesPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareChannelToAllSpaces` mutation. */
export type UpdateShareChannelToAllSpacesPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateShareChannelToSpaces` mutation. */
export type UpdateShareChannelToSpacesInput = {
  channelId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  spaces: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `updateShareChannelToSpaces` mutation. */
export type UpdateShareChannelToSpacesPayload = {
  __typename: "UpdateShareChannelToSpacesPayload";
  channel: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByChildOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDraftOf: Maybe<Channel>;
  /** Reads a single `Channel` that is related to this `Channel`. */
  channelByDuplicateOf: Maybe<Channel>;
  /** An edge for our `Channel`. May be used by Relay 1. */
  channelEdge: Maybe<ChannelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Channel`. */
  fileByCoverImageId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Channel`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Channel`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `Theme` that is related to this `Channel`. */
  themeByThemeId: Maybe<Theme>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Channel`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareChannelToSpaces` mutation. */
export type UpdateShareChannelToSpacesPayloadChannelEdgeArgs = {
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** All input for the `updateShareCredentialToAllSpaces` mutation. */
export type UpdateShareCredentialToAllSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  credentialId: Scalars["UUID"];
  isSharedAll: Scalars["Boolean"];
};

/** The output of our `updateShareCredentialToAllSpaces` mutation. */
export type UpdateShareCredentialToAllSpacesPayload = {
  __typename: "UpdateShareCredentialToAllSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  credential: Maybe<Credential>;
  /** An edge for our `Credential`. May be used by Relay 1. */
  credentialEdge: Maybe<CredentialsEdge>;
  /** Reads a single `Org` that is related to this `Credential`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Credential`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `updateShareCredentialToAllSpaces` mutation. */
export type UpdateShareCredentialToAllSpacesPayloadCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

/** All input for the `updateShareCredentialToSpaces` mutation. */
export type UpdateShareCredentialToSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  credentialId: Scalars["UUID"];
  spaces: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `updateShareCredentialToSpaces` mutation. */
export type UpdateShareCredentialToSpacesPayload = {
  __typename: "UpdateShareCredentialToSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  credential: Maybe<Credential>;
  /** An edge for our `Credential`. May be used by Relay 1. */
  credentialEdge: Maybe<CredentialsEdge>;
  /** Reads a single `Org` that is related to this `Credential`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Credential`. */
  userByCreatedBy: Maybe<User>;
};

/** The output of our `updateShareCredentialToSpaces` mutation. */
export type UpdateShareCredentialToSpacesPayloadCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

/** All input for the `updateShareFileToAllSpaces` mutation. */
export type UpdateShareFileToAllSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId: Scalars["UUID"];
  isSharedAll: Scalars["Boolean"];
};

/** The output of our `updateShareFileToAllSpaces` mutation. */
export type UpdateShareFileToAllSpacesPayload = {
  __typename: "UpdateShareFileToAllSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareFileToAllSpaces` mutation. */
export type UpdateShareFileToAllSpacesPayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateShareFileToSpaces` mutation. */
export type UpdateShareFileToSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  fileId: Scalars["UUID"];
  spaces: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `updateShareFileToSpaces` mutation. */
export type UpdateShareFileToSpacesPayload = {
  __typename: "UpdateShareFileToSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `CommonFile` that is related to this `File`. */
  commonFileByCommonFileId: Maybe<CommonFile>;
  file: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge: Maybe<FilesEdge>;
  /** Reads a single `Folder` that is related to this `File`. */
  folderByFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `File`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `File`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `File`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareFileToSpaces` mutation. */
export type UpdateShareFileToSpacesPayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateShareFolderToAllSpaces` mutation. */
export type UpdateShareFolderToAllSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderId: Scalars["UUID"];
  isSharedAll: Scalars["Boolean"];
};

/** The output of our `updateShareFolderToAllSpaces` mutation. */
export type UpdateShareFolderToAllSpacesPayload = {
  __typename: "UpdateShareFolderToAllSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  folder: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Folder`. */
  folderByParentId: Maybe<Folder>;
  /** An edge for our `Folder`. May be used by Relay 1. */
  folderEdge: Maybe<FoldersEdge>;
  /** Reads a single `Org` that is related to this `Folder`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareFolderToAllSpaces` mutation. */
export type UpdateShareFolderToAllSpacesPayloadFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** All input for the `updateShareFolderToSpaces` mutation. */
export type UpdateShareFolderToSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  folderId: Scalars["UUID"];
  spaces: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `updateShareFolderToSpaces` mutation. */
export type UpdateShareFolderToSpacesPayload = {
  __typename: "UpdateShareFolderToSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  folder: Maybe<Folder>;
  /** Reads a single `Folder` that is related to this `Folder`. */
  folderByParentId: Maybe<Folder>;
  /** An edge for our `Folder`. May be used by Relay 1. */
  folderEdge: Maybe<FoldersEdge>;
  /** Reads a single `Org` that is related to this `Folder`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Folder`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareFolderToSpaces` mutation. */
export type UpdateShareFolderToSpacesPayloadFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** All input for the `updateShareLinkToAllSpaces` mutation. */
export type UpdateShareLinkToAllSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isSharedAll: Scalars["Boolean"];
  linkId: Scalars["UUID"];
};

/** The output of our `updateShareLinkToAllSpaces` mutation. */
export type UpdateShareLinkToAllSpacesPayload = {
  __typename: "UpdateShareLinkToAllSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileIdAndOrgId: Maybe<File>;
  link: Maybe<Link>;
  /** An edge for our `Link`. May be used by Relay 1. */
  linkEdge: Maybe<LinksEdge>;
  /** Reads a single `Org` that is related to this `Link`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Link`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Link`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareLinkToAllSpaces` mutation. */
export type UpdateShareLinkToAllSpacesPayloadLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** All input for the `updateShareLinkToSpaces` mutation. */
export type UpdateShareLinkToSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  linkId: Scalars["UUID"];
  spaces: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `updateShareLinkToSpaces` mutation. */
export type UpdateShareLinkToSpacesPayload = {
  __typename: "UpdateShareLinkToSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Link`. */
  fileByFileIdAndOrgId: Maybe<File>;
  link: Maybe<Link>;
  /** An edge for our `Link`. May be used by Relay 1. */
  linkEdge: Maybe<LinksEdge>;
  /** Reads a single `Org` that is related to this `Link`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Link`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Link`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareLinkToSpaces` mutation. */
export type UpdateShareLinkToSpacesPayloadLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** All input for the `updateSharePlaylistToAllSpaces` mutation. */
export type UpdateSharePlaylistToAllSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isSharedAll: Scalars["Boolean"];
  playlistId: Scalars["UUID"];
};

/** The output of our `updateSharePlaylistToAllSpaces` mutation. */
export type UpdateSharePlaylistToAllSpacesPayload = {
  __typename: "UpdateSharePlaylistToAllSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateSharePlaylistToAllSpaces` mutation. */
export type UpdateSharePlaylistToAllSpacesPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `updateSharePlaylistToSpaces` mutation. */
export type UpdateSharePlaylistToSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  playlistId: Scalars["UUID"];
  spaces: Array<InputMaybe<Scalars["UUID"]>>;
};

/** The output of our `updateSharePlaylistToSpaces` mutation. */
export type UpdateSharePlaylistToSpacesPayload = {
  __typename: "UpdateSharePlaylistToSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Playlist`. */
  orgByOrgId: Maybe<Org>;
  playlist: Maybe<Playlist>;
  /** Reads a single `Playlist` that is related to this `Playlist`. */
  playlistByDraftOf: Maybe<Playlist>;
  /** An edge for our `Playlist`. May be used by Relay 1. */
  playlistEdge: Maybe<PlaylistsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Space` that is related to this `Playlist`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByPublishedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Playlist`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateSharePlaylistToSpaces` mutation. */
export type UpdateSharePlaylistToSpacesPayloadPlaylistEdgeArgs = {
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** All input for the `updateShareSiteToAllSpaces` mutation. */
export type UpdateShareSiteToAllSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isSharedAll?: InputMaybe<Scalars["Boolean"]>;
  siteId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateShareSiteToAllSpaces` mutation. */
export type UpdateShareSiteToAllSpacesPayload = {
  __typename: "UpdateShareSiteToAllSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Site`. */
  fileByThumbnailId: Maybe<File>;
  /** Reads a single `File` that is related to this `Site`. */
  fileByThumbnailIdAndOrgId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Site`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  site: Maybe<Site>;
  /** An edge for our `Site`. May be used by Relay 1. */
  siteEdge: Maybe<SitesEdge>;
  /** Reads a single `Space` that is related to this `Site`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Site`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Site`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareSiteToAllSpaces` mutation. */
export type UpdateShareSiteToAllSpacesPayloadSiteEdgeArgs = {
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

/** All input for the `updateShareSiteToSpaces` mutation. */
export type UpdateShareSiteToSpacesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  siteId?: InputMaybe<Scalars["UUID"]>;
  spaces?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updateShareSiteToSpaces` mutation. */
export type UpdateShareSiteToSpacesPayload = {
  __typename: "UpdateShareSiteToSpacesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Site`. */
  fileByThumbnailId: Maybe<File>;
  /** Reads a single `File` that is related to this `Site`. */
  fileByThumbnailIdAndOrgId: Maybe<File>;
  /** Reads a single `Org` that is related to this `Site`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  site: Maybe<Site>;
  /** An edge for our `Site`. May be used by Relay 1. */
  siteEdge: Maybe<SitesEdge>;
  /** Reads a single `Space` that is related to this `Site`. */
  spaceBySpaceId: Maybe<Space>;
  /** Reads a single `User` that is related to this `Site`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Site`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateShareSiteToSpaces` mutation. */
export type UpdateShareSiteToSpacesPayloadSiteEdgeArgs = {
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

export type UpdateSiteInput = {
  actions?: InputMaybe<Array<Scalars["JSON"]>>;
  compute?: InputMaybe<Scalars["JSON"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  id: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  thumbnailId?: InputMaybe<Scalars["UUID"]>;
  type: SiteType;
  url?: InputMaybe<Scalars["String"]>;
};

/** All input for the `updateSiteIsFavorite` mutation. */
export type UpdateSiteIsFavoriteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  siteIds?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
};

/** The output of our `updateSiteIsFavorite` mutation. */
export type UpdateSiteIsFavoritePayload = {
  __typename: "UpdateSiteIsFavoritePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  favorites: Maybe<Array<Favorite>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

export type UpdateSitePayload = {
  __typename: "UpdateSitePayload";
  site: Maybe<Site>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `updateSpace` mutation. */
export type UpdateSpaceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  env?: InputMaybe<Scalars["JSON"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  preferences?: InputMaybe<Scalars["JSON"]>;
  slug?: InputMaybe<Scalars["String"]>;
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateSpace` mutation. */
export type UpdateSpacePayload = {
  __typename: "UpdateSpacePayload";
  /** Reads a single `BillingCustomer` that is related to this `Space`. */
  billingCustomerByBillingCustomerId: Maybe<BillingCustomer>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Folder` that is related to this `Space`. */
  folderByRootFolderId: Maybe<Folder>;
  /** Reads a single `Org` that is related to this `Space`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  space: Maybe<Space>;
  /** An edge for our `Space`. May be used by Relay 1. */
  spaceEdge: Maybe<SpacesEdge>;
  /** Reads a single `User` that is related to this `Space`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Space`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateSpace` mutation. */
export type UpdateSpacePayloadSpaceEdgeArgs = {
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

export type UpdateSubscriptionInput = {
  currency?: InputMaybe<Scalars["String"]>;
  invoice?: InputMaybe<Scalars["Boolean"]>;
  licences?: InputMaybe<Scalars["Int"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]>;
  plan?: InputMaybe<Scalars["String"]>;
  poNumber?: InputMaybe<Scalars["String"]>;
  reactivate?: InputMaybe<Scalars["Boolean"]>;
  spaceId?: InputMaybe<Scalars["String"]>;
  term?: InputMaybe<Scalars["String"]>;
};

export type UpdateSubscriptionPayload = {
  __typename: "UpdateSubscriptionPayload";
  query: Maybe<Query>;
  success: Maybe<Scalars["Boolean"]>;
};

/** All input for the `updateTheme` mutation. */
export type UpdateThemeInput = {
  autoPublish?: InputMaybe<Scalars["Boolean"]>;
  bodyFontId?: InputMaybe<Scalars["UUID"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  logoFileId?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  themeId?: InputMaybe<Scalars["UUID"]>;
  titleFontId?: InputMaybe<Scalars["UUID"]>;
};

/** The output of our `updateTheme` mutation. */
export type UpdateThemePayload = {
  __typename: "UpdateThemePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileId: Maybe<File>;
  /** Reads a single `File` that is related to this `Theme`. */
  fileByLogoFileIdDraft: Maybe<File>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByBodyFontIdDraft: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontId: Maybe<Font>;
  /** Reads a single `Font` that is related to this `Theme`. */
  fontByTitleFontIdDraft: Maybe<Font>;
  /** Reads a single `Org` that is related to this `Theme`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  theme: Maybe<Theme>;
  /** An edge for our `Theme`. May be used by Relay 1. */
  themeEdge: Maybe<ThemesEdge>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Theme`. */
  userByUpdatedBy: Maybe<User>;
};

/** The output of our `updateTheme` mutation. */
export type UpdateThemePayloadThemeEdgeArgs = {
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** All input for the `updateToken` mutation. */
export type UpdateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The output of our `updateToken` mutation. */
export type UpdateTokenPayload = {
  __typename: "UpdateTokenPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `Token`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  token: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge: Maybe<TokensEdge>;
  /** Reads a single `User` that is related to this `Token`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `Token`. */
  userByUserId: Maybe<User>;
};

/** The output of our `updateToken` mutation. */
export type UpdateTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the `updateUserInfo` mutation. */
export type UpdateUserInfoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userInfo: Scalars["JSON"];
};

/** The output of our `updateUserInfo` mutation. */
export type UpdateUserInfoPayload = {
  __typename: "UpdateUserInfoPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `updateUserInfo` mutation. */
export type UpdateUserInfoPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  receiveUpdates?: InputMaybe<ReceiveUpdates>;
  status?: InputMaybe<UserStatus>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** All input for the `updateUserOnboardingData` mutation. */
export type UpdateUserOnboardingDataInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  key: Scalars["String"];
  value: Scalars["Boolean"];
};

/** The output of our `updateUserOnboardingData` mutation. */
export type UpdateUserOnboardingDataPayload = {
  __typename: "UpdateUserOnboardingDataPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `updateUserOnboardingData` mutation. */
export type UpdateUserOnboardingDataPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** The output of our `updateUser` mutation. */
export type UpdateUserPayload = {
  __typename: "UpdateUserPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `updateUser` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUserProvider` mutation. */
export type UpdateUserProviderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  provider: Scalars["String"];
  userId: Scalars["UUID"];
};

/** The output of our `updateUserProvider` mutation. */
export type UpdateUserProviderPayload = {
  __typename: "UpdateUserProviderPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `updateUserProvider` mutation. */
export type UpdateUserProviderPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUserSettings` mutation. */
export type UpdateUserSettingsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  settingsData: Scalars["JSON"];
};

/** The output of our `updateUserSettings` mutation. */
export type UpdateUserSettingsPayload = {
  __typename: "UpdateUserSettingsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};

/** The output of our `updateUserSettings` mutation. */
export type UpdateUserSettingsPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A user detail in studio. */
export type User = Node & {
  __typename: "User";
  /** Reads and enables pagination through a set of `AppInstall`. */
  appInstallsByCreatedBy: AppInstallsConnection;
  /** Reads and enables pagination through a set of `AppInstall`. */
  appInstallsByUpdatedBy: AppInstallsConnection;
  /** Reads and enables pagination through a set of `AppInstance`. */
  appInstancesByCreatedBy: AppInstancesConnection;
  /** Reads and enables pagination through a set of `AppInstance`. */
  appInstancesByUpdatedBy: AppInstancesConnection;
  /** Get bot's permissionsByUserIds. */
  botPermissionsByUserIds: Maybe<Scalars["JSON"]>;
  /** Reads and enables pagination through a set of `Cast`. */
  castsByCreatedBy: CastsConnection;
  /** Reads and enables pagination through a set of `Cast`. */
  castsByUpdatedBy: CastsConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByCreatedBy: ChannelsConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByPublishedBy: ChannelsConnection;
  /** Reads and enables pagination through a set of `Channel`. */
  channelsByUpdatedBy: ChannelsConnection;
  /** The connection of the user e.g. google. (SSO related) */
  connection: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `Credential`. */
  credentialsByCreatedBy: CredentialsConnection;
  deletedAt: Maybe<Scalars["Datetime"]>;
  deletedBy: Maybe<Scalars["UUID"]>;
  /** The email of the user that returned from Auth0. */
  email: Maybe<Scalars["String"]>;
  /** The last name of the user. */
  familyName: Scalars["String"];
  /** Get favorite files by user */
  favoriteFilesByUserId: FilesConnection;
  /** Get favorite folders by user */
  favoriteFoldersByUserId: FoldersConnection;
  /** Get favorite link by user */
  favoriteLinksByUserId: LinksConnection;
  /** Get favorite playlist by user */
  favoritePlaylistsByUserId: PlaylistsConnection;
  /** Get favorite Site by user */
  favoriteSitesByUserId: SitesConnection;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByUserId: FavoritesConnection;
  /** Reads and enables pagination through a set of `Favorite`. */
  favoritesByUserIdAndOrgId: FavoritesConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: FilesConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByUpdatedBy: FilesConnection;
  /** Reads and enables pagination through a set of `Folder`. */
  foldersByCreatedBy: FoldersConnection;
  /** Reads and enables pagination through a set of `Folder`. */
  foldersByUpdatedBy: FoldersConnection;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByCreatedBy: FontsConnection;
  /** Reads and enables pagination through a set of `Font`. */
  fontsByUpdatedBy: FontsConnection;
  /** The first name of the user. */
  givenName: Scalars["String"];
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCreatedBy: GroupsConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByUpdatedBy: GroupsConnection;
  /** The primary unique identifier for the user. */
  id: Scalars["UUID"];
  /** The user is an owner of the organization. */
  isOwner: Maybe<Scalars["Boolean"]>;
  /** The last authenticated timestamp when authenticate user. */
  lastAuthenticatedAt: Maybe<Scalars["Datetime"]>;
  /** Reads and enables pagination through a set of `Layout`. */
  layoutsByCreatedBy: LayoutsConnection;
  /** Reads and enables pagination through a set of `Layout`. */
  layoutsByUpdatedBy: LayoutsConnection;
  /** Reads and enables pagination through a set of `Link`. */
  linksByCreatedBy: LinksConnection;
  /** Reads and enables pagination through a set of `Link`. */
  linksByUpdatedBy: LinksConnection;
  /** Meta data storage for misc data e.g. avatar */
  metadata: Maybe<Scalars["JSON"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads and enables pagination through a set of `NotificationRule`. */
  notificationRulesByCreatedBy: NotificationRulesConnection;
  /** Reads and enables pagination through a set of `NotificationSubscription`. */
  notificationSubscriptionsByUserIdAndOrgId: NotificationSubscriptionsConnection;
  /** Reads a single `Org` that is related to this `User`. */
  orgByOrgId: Maybe<Org>;
  /** Reads and enables pagination through a set of `OrgConnection`. */
  orgConnectionsByCreatedBy: OrgConnectionsConnection;
  /** Reads and enables pagination through a set of `OrgConnection`. */
  orgConnectionsByUpdatedBy: OrgConnectionsConnection;
  /** The id of the organization that user belong to. */
  orgId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `Org`. */
  orgsByCreatedBy: OrgsConnection;
  /** Reads and enables pagination through a set of `Org`. */
  orgsByDeletedBy: OrgsConnection;
  /** Reads and enables pagination through a set of `Org`. */
  orgsByUpdatedBy: OrgsConnection;
  /** Get user's permissions. */
  permissions: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Get user's permissionsBySpaceIds. */
  permissionsByGroupIds: Maybe<Scalars["JSON"]>;
  /** Get user's permissionsBySpaceIds. */
  permissionsBySpaceIds: Maybe<Scalars["JSON"]>;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByCreatedBy: PlaylistsConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByPublishedBy: PlaylistsConnection;
  /** Reads and enables pagination through a set of `Playlist`. */
  playlistsByUpdatedBy: PlaylistsConnection;
  /** The preference which contain settings and onboarding data of the user. */
  preferences: Maybe<Scalars["JSON"]>;
  /** The provider of the user e.g. google. */
  provider: Maybe<Scalars["String"]>;
  receiveUpdates: ReceiveUpdates;
  /** Reads and enables pagination through a set of `ScreenContentHistory`. */
  screenContentHistoriesByCreatedBy: ScreenContentHistoriesConnection;
  /** Reads and enables pagination through a set of `ScreenGroup`. */
  screenGroupsByCreatedBy: ScreenGroupsConnection;
  /** Reads and enables pagination through a set of `ScreenGroup`. */
  screenGroupsByUpdatedBy: ScreenGroupsConnection;
  /** Reads and enables pagination through a set of `ScreenNote`. */
  screenNotesByCreatedBy: ScreenNotesConnection;
  /** Reads and enables pagination through a set of `ScreenNote`. */
  screenNotesByUpdatedBy: ScreenNotesConnection;
  /** Reads and enables pagination through a set of `Screen`. */
  screensByCreatedBy: ScreensConnection;
  /** Reads and enables pagination through a set of `Screen`. */
  screensByUpdatedBy: ScreensConnection;
  /** Reads and enables pagination through a set of `ShareAssociation`. */
  shareAssociationsBySharedBy: ShareAssociationsConnection;
  /** Reads and enables pagination through a set of `Site`. */
  sitesByCreatedBy: SitesConnection;
  /** Reads and enables pagination through a set of `Site`. */
  sitesByUpdatedBy: SitesConnection;
  /** Reads and enables pagination through a set of `Space`. */
  spacesByCreatedBy: SpacesConnection;
  /** Reads and enables pagination through a set of `Space`. */
  spacesByUpdatedBy: SpacesConnection;
  /** The current status of user including active, pending, disabled, archived. */
  status: UserStatus;
  /** Reads and enables pagination through a set of `SystemAccess`. */
  systemAccessesByCreatedBy: SystemAccessesConnection;
  /** Reads and enables pagination through a set of `SystemAccess`. */
  systemAccessesByUpdatedBy: SystemAccessesConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByCreatedBy: ThemesConnection;
  /** Reads and enables pagination through a set of `Theme`. */
  themesByUpdatedBy: ThemesConnection;
  /** Reads and enables pagination through a set of `Token`. */
  tokensByCreatedBy: TokensConnection;
  /** Reads and enables pagination through a set of `Token`. */
  tokensByUpdatedBy: TokensConnection;
  /** Reads and enables pagination through a set of `Token`. */
  tokensByUserId: TokensConnection;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads and enables pagination through a set of `UserActionLog`. */
  userActionLogsByCreatedBy: UserActionLogsConnection;
  /** Reads a single `User` that is related to this `User`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByDeletedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `User`. */
  userByUpdatedBy: Maybe<User>;
  /** Reads and enables pagination through a set of `UserGroup`. */
  userGroupsByCreatedBy: UserGroupsConnection;
  /** Reads and enables pagination through a set of `UserGroup`. */
  userGroupsByUserId: UserGroupsConnection;
  userType: Maybe<UserType>;
  /** Reads and enables pagination through a set of `User`. */
  usersByCreatedBy: UsersConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByDeletedBy: UsersConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByUpdatedBy: UsersConnection;
  /** Reads and enables pagination through a set of `WhiteLabel`. */
  whiteLabelsByCreatedBy: WhiteLabelsConnection;
  /** Reads and enables pagination through a set of `WhiteLabel`. */
  whiteLabelsByUpdatedBy: WhiteLabelsConnection;
};

/** A user detail in studio. */
export type UserAppInstallsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstallCondition>;
  filter: InputMaybe<AppInstallFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** A user detail in studio. */
export type UserAppInstallsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstallCondition>;
  filter: InputMaybe<AppInstallFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstallsOrderBy>>;
};

/** A user detail in studio. */
export type UserAppInstancesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** A user detail in studio. */
export type UserAppInstancesByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AppInstancesOrderBy>>;
};

/** A user detail in studio. */
export type UserCastsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CastCondition>;
  filter: InputMaybe<CastFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CastsOrderBy>>;
};

/** A user detail in studio. */
export type UserCastsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CastCondition>;
  filter: InputMaybe<CastFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CastsOrderBy>>;
};

/** A user detail in studio. */
export type UserChannelsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** A user detail in studio. */
export type UserChannelsByPublishedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** A user detail in studio. */
export type UserChannelsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ChannelCondition>;
  filter: InputMaybe<ChannelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ChannelsOrderBy>>;
};

/** A user detail in studio. */
export type UserCredentialsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<CredentialCondition>;
  filter: InputMaybe<CredentialFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<CredentialsOrderBy>>;
};

/** A user detail in studio. */
export type UserFavoriteFilesByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A user detail in studio. */
export type UserFavoriteFoldersByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A user detail in studio. */
export type UserFavoriteLinksByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A user detail in studio. */
export type UserFavoritePlaylistsByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A user detail in studio. */
export type UserFavoriteSitesByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
};

/** A user detail in studio. */
export type UserFavoritesByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** A user detail in studio. */
export type UserFavoritesByUserIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FavoriteCondition>;
  filter: InputMaybe<FavoriteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FavoritesOrderBy>>;
};

/** A user detail in studio. */
export type UserFilesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A user detail in studio. */
export type UserFilesByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A user detail in studio. */
export type UserFoldersByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FolderCondition>;
  filter: InputMaybe<FolderFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** A user detail in studio. */
export type UserFoldersByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FolderCondition>;
  filter: InputMaybe<FolderFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FoldersOrderBy>>;
};

/** A user detail in studio. */
export type UserFontsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** A user detail in studio. */
export type UserFontsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<FontCondition>;
  filter: InputMaybe<FontFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<FontsOrderBy>>;
};

/** A user detail in studio. */
export type UserGroupsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupCondition>;
  filter: InputMaybe<GroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** A user detail in studio. */
export type UserGroupsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<GroupCondition>;
  filter: InputMaybe<GroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** A user detail in studio. */
export type UserLayoutsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LayoutCondition>;
  filter: InputMaybe<LayoutFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LayoutsOrderBy>>;
};

/** A user detail in studio. */
export type UserLayoutsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LayoutCondition>;
  filter: InputMaybe<LayoutFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LayoutsOrderBy>>;
};

/** A user detail in studio. */
export type UserLinksByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LinkCondition>;
  filter: InputMaybe<LinkFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** A user detail in studio. */
export type UserLinksByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<LinkCondition>;
  filter: InputMaybe<LinkFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LinksOrderBy>>;
};

/** A user detail in studio. */
export type UserNotificationRulesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationRuleCondition>;
  filter: InputMaybe<NotificationRuleFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationRulesOrderBy>>;
};

/** A user detail in studio. */
export type UserNotificationSubscriptionsByUserIdAndOrgIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<NotificationSubscriptionCondition>;
  filter: InputMaybe<NotificationSubscriptionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<NotificationSubscriptionsOrderBy>>;
};

/** A user detail in studio. */
export type UserOrgConnectionsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgConnectionCondition>;
  filter: InputMaybe<OrgConnectionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgConnectionsOrderBy>>;
};

/** A user detail in studio. */
export type UserOrgConnectionsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgConnectionCondition>;
  filter: InputMaybe<OrgConnectionFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgConnectionsOrderBy>>;
};

/** A user detail in studio. */
export type UserOrgsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** A user detail in studio. */
export type UserOrgsByDeletedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** A user detail in studio. */
export type UserOrgsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<OrgCondition>;
  filter: InputMaybe<OrgFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrgsOrderBy>>;
};

/** A user detail in studio. */
export type UserPlaylistsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** A user detail in studio. */
export type UserPlaylistsByPublishedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** A user detail in studio. */
export type UserPlaylistsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<PlaylistCondition>;
  filter: InputMaybe<PlaylistFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlaylistsOrderBy>>;
};

/** A user detail in studio. */
export type UserScreenContentHistoriesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenContentHistoryCondition>;
  filter: InputMaybe<ScreenContentHistoryFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenContentHistoriesOrderBy>>;
};

/** A user detail in studio. */
export type UserScreenGroupsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenGroupCondition>;
  filter: InputMaybe<ScreenGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenGroupsOrderBy>>;
};

/** A user detail in studio. */
export type UserScreenGroupsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenGroupCondition>;
  filter: InputMaybe<ScreenGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenGroupsOrderBy>>;
};

/** A user detail in studio. */
export type UserScreenNotesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenNoteCondition>;
  filter: InputMaybe<ScreenNoteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** A user detail in studio. */
export type UserScreenNotesByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenNoteCondition>;
  filter: InputMaybe<ScreenNoteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreenNotesOrderBy>>;
};

/** A user detail in studio. */
export type UserScreensByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** A user detail in studio. */
export type UserScreensByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ScreenCondition>;
  filter: InputMaybe<ScreenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ScreensOrderBy>>;
};

/** A user detail in studio. */
export type UserShareAssociationsBySharedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ShareAssociationCondition>;
  filter: InputMaybe<ShareAssociationFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ShareAssociationsOrderBy>>;
};

/** A user detail in studio. */
export type UserSitesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

/** A user detail in studio. */
export type UserSitesByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SiteCondition>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SitesOrderBy>>;
};

/** A user detail in studio. */
export type UserSpacesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SpaceCondition>;
  filter: InputMaybe<SpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

/** A user detail in studio. */
export type UserSpacesByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SpaceCondition>;
  filter: InputMaybe<SpaceFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SpacesOrderBy>>;
};

/** A user detail in studio. */
export type UserSystemAccessesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SystemAccessCondition>;
  filter: InputMaybe<SystemAccessFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SystemAccessesOrderBy>>;
};

/** A user detail in studio. */
export type UserSystemAccessesByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<SystemAccessCondition>;
  filter: InputMaybe<SystemAccessFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SystemAccessesOrderBy>>;
};

/** A user detail in studio. */
export type UserThemesByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** A user detail in studio. */
export type UserThemesByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<ThemeCondition>;
  filter: InputMaybe<ThemeFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ThemesOrderBy>>;
};

/** A user detail in studio. */
export type UserTokensByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TokenCondition>;
  filter: InputMaybe<TokenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** A user detail in studio. */
export type UserTokensByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TokenCondition>;
  filter: InputMaybe<TokenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** A user detail in studio. */
export type UserTokensByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<TokenCondition>;
  filter: InputMaybe<TokenFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** A user detail in studio. */
export type UserUserActionLogsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserActionLogCondition>;
  filter: InputMaybe<UserActionLogFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserActionLogsOrderBy>>;
};

/** A user detail in studio. */
export type UserUserGroupsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserGroupCondition>;
  filter: InputMaybe<UserGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
};

/** A user detail in studio. */
export type UserUserGroupsByUserIdArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserGroupCondition>;
  filter: InputMaybe<UserGroupFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserGroupsOrderBy>>;
};

/** A user detail in studio. */
export type UserUsersByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A user detail in studio. */
export type UserUsersByDeletedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A user detail in studio. */
export type UserUsersByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A user detail in studio. */
export type UserWhiteLabelsByCreatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<WhiteLabelCondition>;
  filter: InputMaybe<WhiteLabelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

/** A user detail in studio. */
export type UserWhiteLabelsByUpdatedByArgs = {
  after: InputMaybe<Scalars["Cursor"]>;
  before: InputMaybe<Scalars["Cursor"]>;
  condition: InputMaybe<WhiteLabelCondition>;
  filter: InputMaybe<WhiteLabelFilter>;
  first: InputMaybe<Scalars["Int"]>;
  last: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WhiteLabelsOrderBy>>;
};

export type UserActionLog = Node & {
  __typename: "UserActionLog";
  actionDetail: Maybe<Scalars["String"]>;
  actionRefId: Maybe<Scalars["UUID"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `UserActionLog`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Screen` that is related to this `UserActionLog`. */
  screenByScreenId: Maybe<Screen>;
  screenId: Scalars["UUID"];
  /** Reads a single `Space` that is related to this `UserActionLog`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Scalars["UUID"];
  /** Reads a single `User` that is related to this `UserActionLog`. */
  userByCreatedBy: Maybe<User>;
};

/**
 * A condition to be used against `UserActionLog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserActionLogCondition = {
  /** Checks for equality with the object’s `actionDetail` field. */
  actionDetail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `actionRefId` field. */
  actionRefId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `screenId` field. */
  screenId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `UserActionLog` object types. All fields are combined with a logical ‘and.’ */
export type UserActionLogFilter = {
  /** Filter by the object’s `actionDetail` field. */
  actionDetail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `actionRefId` field. */
  actionRefId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserActionLogFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserActionLogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserActionLogFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `screenId` field. */
  screenId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `UserActionLog` values. */
export type UserActionLogsConnection = {
  __typename: "UserActionLogsConnection";
  /** A list of edges which contains the `UserActionLog` and cursor to aid in pagination. */
  edges: Array<UserActionLogsEdge>;
  /** A list of `UserActionLog` objects. */
  nodes: Array<UserActionLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserActionLog` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `UserActionLog` edge in the connection. */
export type UserActionLogsEdge = {
  __typename: "UserActionLogsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `UserActionLog` at the end of the edge. */
  node: UserActionLog;
};

/** Methods to use when ordering `UserActionLog`. */
export enum UserActionLogsOrderBy {
  ActionDetailAsc = "ACTION_DETAIL_ASC",
  ActionDetailDesc = "ACTION_DETAIL_DESC",
  ActionRefIdAsc = "ACTION_REF_ID_ASC",
  ActionRefIdDesc = "ACTION_REF_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ScreenIdAsc = "SCREEN_ID_ASC",
  ScreenIdDesc = "SCREEN_ID_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
}

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `connection` field. */
  connection?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `familyName` field. */
  familyName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `givenName` field. */
  givenName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isOwner` field. */
  isOwner?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `lastAuthenticatedAt` field. */
  lastAuthenticatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `preferences` field. */
  preferences?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `receiveUpdates` field. */
  receiveUpdates?: InputMaybe<ReceiveUpdates>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<UserStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `userType` field. */
  userType?: InputMaybe<UserType>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `connection` field. */
  connection?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedBy` field. */
  deletedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `familyName` field. */
  familyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `givenName` field. */
  givenName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isOwner` field. */
  isOwner?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastAuthenticatedAt` field. */
  lastAuthenticatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `preferences` field. */
  preferences?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `receiveUpdates` field. */
  receiveUpdates?: InputMaybe<ReceiveUpdatesFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<UserStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userType` field. */
  userType?: InputMaybe<UserTypeFilter>;
};

/** User memberships in groups */
export type UserGroup = Node & {
  __typename: "UserGroup";
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `Group` that is related to this `UserGroup`. */
  groupByGroupId: Maybe<Group>;
  groupId: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `UserGroup`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  permissionSets: Array<Maybe<Scalars["UUID"]>>;
  permissions: Array<Maybe<Scalars["String"]>>;
  /** Reads a single `User` that is related to this `UserGroup`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `UserGroup`. */
  userByUserId: Maybe<User>;
  userId: Scalars["UUID"];
};

/**
 * A condition to be used against `UserGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserGroupCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `permissionSets` field. */
  permissionSets?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  /** Checks for equality with the object’s `permissions` field. */
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against `UserGroup` object types. All fields are combined with a logical ‘and.’ */
export type UserGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserGroupFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserGroupFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `permissionSets` field. */
  permissionSets?: InputMaybe<UuidListFilter>;
  /** Filter by the object’s `permissions` field. */
  permissions?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `UserGroup` values. */
export type UserGroupsConnection = {
  __typename: "UserGroupsConnection";
  /** A list of edges which contains the `UserGroup` and cursor to aid in pagination. */
  edges: Array<UserGroupsEdge>;
  /** A list of `UserGroup` objects. */
  nodes: Array<UserGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserGroup` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `UserGroup` edge in the connection. */
export type UserGroupsEdge = {
  __typename: "UserGroupsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `UserGroup` at the end of the edge. */
  node: UserGroup;
};

/** Methods to use when ordering `UserGroup`. */
export enum UserGroupsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  GroupIdAsc = "GROUP_ID_ASC",
  GroupIdDesc = "GROUP_ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PermissionsAsc = "PERMISSIONS_ASC",
  PermissionsDesc = "PERMISSIONS_DESC",
  PermissionSetsAsc = "PERMISSION_SETS_ASC",
  PermissionSetsDesc = "PERMISSION_SETS_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

export enum UserStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Disabled = "DISABLED",
  Pending = "PENDING",
}

/** A filter to be used against UserStatus fields. All fields are combined with a logical ‘and.’ */
export type UserStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UserStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UserStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UserStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UserStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UserStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UserStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UserStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UserStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UserStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UserStatus>>;
};

export enum UserType {
  Bot = "BOT",
  System = "SYSTEM",
  User = "USER",
}

/** A filter to be used against UserType fields. All fields are combined with a logical ‘and.’ */
export type UserTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UserType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UserType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UserType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UserType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UserType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UserType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UserType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UserType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UserType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UserType>>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename: "UsersConnection";
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename: "UsersEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  ConnectionAsc = "CONNECTION_ASC",
  ConnectionDesc = "CONNECTION_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  DeletedAtAsc = "DELETED_AT_ASC",
  DeletedAtDesc = "DELETED_AT_DESC",
  DeletedByAsc = "DELETED_BY_ASC",
  DeletedByDesc = "DELETED_BY_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  FamilyNameAsc = "FAMILY_NAME_ASC",
  FamilyNameDesc = "FAMILY_NAME_DESC",
  GivenNameAsc = "GIVEN_NAME_ASC",
  GivenNameDesc = "GIVEN_NAME_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  IsOwnerAsc = "IS_OWNER_ASC",
  IsOwnerDesc = "IS_OWNER_DESC",
  LastAuthenticatedAtAsc = "LAST_AUTHENTICATED_AT_ASC",
  LastAuthenticatedAtDesc = "LAST_AUTHENTICATED_AT_DESC",
  MetadataAsc = "METADATA_ASC",
  MetadataDesc = "METADATA_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PreferencesAsc = "PREFERENCES_ASC",
  PreferencesDesc = "PREFERENCES_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ProviderAsc = "PROVIDER_ASC",
  ProviderDesc = "PROVIDER_DESC",
  ReceiveUpdatesAsc = "RECEIVE_UPDATES_ASC",
  ReceiveUpdatesDesc = "RECEIVE_UPDATES_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  UserTypeAsc = "USER_TYPE_ASC",
  UserTypeDesc = "USER_TYPE_DESC",
}

/** All input for the `validateColorCode` mutation. */
export type ValidateColorCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
};

/** The output of our `validateColorCode` mutation. */
export type ValidateColorCodePayload = {
  __typename: "ValidateColorCodePayload";
  boolean: Maybe<Scalars["Boolean"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `validateLayoutConfigJson` mutation. */
export type ValidateLayoutConfigJsonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  config?: InputMaybe<Scalars["JSON"]>;
};

/** The output of our `validateLayoutConfigJson` mutation. */
export type ValidateLayoutConfigJsonPayload = {
  __typename: "ValidateLayoutConfigJsonPayload";
  boolean: Maybe<Scalars["Boolean"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

export type WhiteLabel = Node & {
  __typename: "WhiteLabel";
  activeItemColor: Maybe<Scalars["String"]>;
  activeTextColor: Maybe<Scalars["String"]>;
  bgColor: Maybe<Scalars["String"]>;
  createdAt: Maybe<Scalars["Datetime"]>;
  createdBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `File` that is related to this `WhiteLabel`. */
  fileByGlyphFileId: Maybe<File>;
  glyphFileId: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"];
  /** Reads a single `Org` that is related to this `WhiteLabel`. */
  orgByOrgId: Maybe<Org>;
  orgId: Scalars["UUID"];
  /** Reads a single `Space` that is related to this `WhiteLabel`. */
  spaceBySpaceId: Maybe<Space>;
  spaceId: Maybe<Scalars["UUID"]>;
  textColor: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
  updatedAt: Maybe<Scalars["Datetime"]>;
  updatedBy: Maybe<Scalars["UUID"]>;
  /** Reads a single `User` that is related to this `WhiteLabel`. */
  userByCreatedBy: Maybe<User>;
  /** Reads a single `User` that is related to this `WhiteLabel`. */
  userByUpdatedBy: Maybe<User>;
  /** Wording for show along with logo. */
  wordmark: Maybe<Scalars["String"]>;
};

/**
 * A condition to be used against `WhiteLabel` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type WhiteLabelCondition = {
  /** Checks for equality with the object’s `activeItemColor` field. */
  activeItemColor?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `activeTextColor` field. */
  activeTextColor?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `bgColor` field. */
  bgColor?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `glyphFileId` field. */
  glyphFileId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `spaceId` field. */
  spaceId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `textColor` field. */
  textColor?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `wordmark` field. */
  wordmark?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against `WhiteLabel` object types. All fields are combined with a logical ‘and.’ */
export type WhiteLabelFilter = {
  /** Filter by the object’s `activeItemColor` field. */
  activeItemColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `activeTextColor` field. */
  activeTextColor?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WhiteLabelFilter>>;
  /** Filter by the object’s `bgColor` field. */
  bgColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `glyphFileId` field. */
  glyphFileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WhiteLabelFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WhiteLabelFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `spaceId` field. */
  spaceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `textColor` field. */
  textColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `wordmark` field. */
  wordmark?: InputMaybe<StringFilter>;
};

/** A connection to a list of `WhiteLabel` values. */
export type WhiteLabelsConnection = {
  __typename: "WhiteLabelsConnection";
  /** A list of edges which contains the `WhiteLabel` and cursor to aid in pagination. */
  edges: Array<WhiteLabelsEdge>;
  /** A list of `WhiteLabel` objects. */
  nodes: Array<WhiteLabel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WhiteLabel` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A `WhiteLabel` edge in the connection. */
export type WhiteLabelsEdge = {
  __typename: "WhiteLabelsEdge";
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars["Cursor"]>;
  /** The `WhiteLabel` at the end of the edge. */
  node: WhiteLabel;
};

/** Methods to use when ordering `WhiteLabel`. */
export enum WhiteLabelsOrderBy {
  ActiveItemColorAsc = "ACTIVE_ITEM_COLOR_ASC",
  ActiveItemColorDesc = "ACTIVE_ITEM_COLOR_DESC",
  ActiveTextColorAsc = "ACTIVE_TEXT_COLOR_ASC",
  ActiveTextColorDesc = "ACTIVE_TEXT_COLOR_DESC",
  BgColorAsc = "BG_COLOR_ASC",
  BgColorDesc = "BG_COLOR_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByAsc = "CREATED_BY_ASC",
  CreatedByDesc = "CREATED_BY_DESC",
  GlyphFileIdAsc = "GLYPH_FILE_ID_ASC",
  GlyphFileIdDesc = "GLYPH_FILE_ID_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  Natural = "NATURAL",
  OrgIdAsc = "ORG_ID_ASC",
  OrgIdDesc = "ORG_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SpaceIdAsc = "SPACE_ID_ASC",
  SpaceIdDesc = "SPACE_ID_DESC",
  TextColorAsc = "TEXT_COLOR_ASC",
  TextColorDesc = "TEXT_COLOR_DESC",
  TitleAsc = "TITLE_ASC",
  TitleDesc = "TITLE_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByAsc = "UPDATED_BY_ASC",
  UpdatedByDesc = "UPDATED_BY_DESC",
  WordmarkAsc = "WORDMARK_ASC",
  WordmarkDesc = "WORDMARK_DESC",
}

export type ContextProviderDataQueryVariables = Exact<{ [key: string]: never }>;

export type ContextProviderDataQuery = {
  __typename: "Query";
  migrationCheck: any | null;
  currentOrg: {
    __typename: "Org";
    id: any;
    name: string;
    brandForegroundColor: string;
    brandBackgroundColor: string;
    startChannelId: any | null;
    blankChannelId: any | null;
    brandChannelId: any | null;
    splashChannelThemeId: any | null;
    preferences: any | null;
    defaultChannelThemeId: any | null;
    brandLogoId: any | null;
    brandBackgroundId: any | null;
    billingExpiresAt: any | null;
    isDraft: boolean;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    featureFlagOrgsByOrgId: {
      __typename: "FeatureFlagOrgsConnection";
      nodes: Array<{
        __typename: "FeatureFlagOrg";
        key: string;
        orgId: any;
        value: boolean | null;
        customizable: boolean | null;
        customValue: boolean | null;
      }>;
    };
    screensByOrgId: { __typename: "ScreensConnection"; totalCount: number };
    spacesByOrgId: { __typename: "SpacesConnection"; totalCount: number };
    orgConnectionByOrgId: {
      __typename: "OrgConnection";
      id: any;
      orgId: any;
      automaticEnroll: boolean | null;
      connection: string;
      provider: string;
    } | null;
  } | null;
  allApps: {
    __typename: "AppsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "App";
      id: any;
      name: string;
      scrn: string;
      categories: Array<string | null>;
      requirements: Array<string | null>;
      description: string | null;
      defaultInstall: AppDefaultInstallType;
      isInstalled: boolean | null;
      iconUrl: string | null;
      developer: string | null;
      tags: Array<string | null>;
      createdAt: any | null;
      updatedAt: any | null;
      appInstallsByAppId: {
        __typename: "AppInstallsConnection";
        nodes: Array<{
          __typename: "AppInstall";
          appId: any;
          id: any;
          orgId: any;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          spaceId: any | null;
        }>;
      };
      appVersionByAppId: {
        __typename: "AppVersion";
        id: any;
        appId: any;
        version: string | null;
        betaFlag: boolean | null;
        stableFlag: boolean | null;
        manifestJson: any | null;
        viewerUrl: string | null;
        editorUrl: string | null;
        mediaSandboxAccess: boolean | null;
        configurationType: AppConfiguration | null;
        storeData: any | null;
        createdAt: any | null;
        updatedAt: any | null;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      } | null;
    }>;
  } | null;
  allSpaces: {
    __typename: "SpacesConnection";
    nodes: Array<{
      __typename: "Space";
      id: any;
      name: string;
      preferences: any;
      billingCustomerId: string | null;
      rootFolderId: any | null;
    }>;
  } | null;
  currentOrgStartableChannels: {
    __typename: "ChannelsConnection";
    nodes: Array<{
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    }>;
  } | null;
};

export type CustomerFragment = {
  __typename: "BillingCustomer";
  id: string;
  parentId: string | null;
  paymentOwnerId: string | null;
  invoiceOwnerId: string | null;
  company: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  vatNumber: string | null;
  vatNumberStatus: string | null;
  autoCollection: string | null;
  taxability: string | null;
  billingDate: number | null;
  billingDateMode: string | null;
  billingDayOfWeek: string | null;
  billingDayOfWeekMode: string | null;
  preferredCurrencyCode: CurrencyCode | null;
  cfAllowLicence: boolean | null;
  cfPartner: string | null;
  cfInvoicedPayment: boolean | null;
  createdAt: any | null;
  updatedAt: any | null;
};

export type AddressFragment = {
  __typename: "BillingAddress";
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  company: string | null;
  phone: string | null;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  city: string | null;
  stateCode: string | null;
  state: string | null;
  country: CountryCode | null;
  zip: string | null;
  validationStatus: string | null;
};

export type SubscriptionFragment = {
  __typename: "BillingSubscription";
  id: string;
  currencyCode: CurrencyCode | null;
  planId: string | null;
  planQuantity: number | null;
  planUnitPrice: number | null;
  planAmount: number | null;
  status: string | null;
  poNumber: string | null;
  autoCollection: string | null;
  dueInvoicesCount: number | null;
  totalDues: number | null;
  baseCurrencyCode: CurrencyCode | null;
  cfPrimary: string | null;
  dueSince: any | null;
  trialStart: any | null;
  trialEnd: any | null;
  currentTermStart: any | null;
  currentTermEnd: any | null;
  pauseDate: any | null;
  resumeDate: any | null;
  startDate: any | null;
  startedAt: any | null;
  activatedAt: any | null;
  cancelledAt: any | null;
  nextBillingAt: any | null;
  createdAt: any | null;
  billingAddonSubscriptionsByBillingSubscriptionId: {
    __typename: "BillingAddonSubscriptionsConnection";
    nodes: Array<{
      __typename: "BillingAddonSubscription";
      billingAddonId: string;
      billingSubscriptionId: string;
      billingCustomerId: string;
      unitPrice: number | null;
      billingAddonByBillingAddonId: {
        __typename: "BillingAddon";
        id: string;
        name: string | null;
        currencyCode: CurrencyCode | null;
        price: number | null;
      } | null;
    }>;
  };
};

export type SubscriptionFutureFragment = {
  __typename: "BillingSubscriptionFuture";
  id: string;
  currencyCode: CurrencyCode | null;
  planId: string | null;
  planQuantity: number | null;
  planUnitPrice: number | null;
  planAmount: number | null;
  status: string | null;
  poNumber: string | null;
  autoCollection: string | null;
  dueInvoicesCount: number | null;
  totalDues: number | null;
  baseCurrencyCode: CurrencyCode | null;
  cfPrimary: string | null;
  dueSince: any | null;
  trialStart: any | null;
  trialEnd: any | null;
  currentTermStart: any | null;
  currentTermEnd: any | null;
  pauseDate: any | null;
  resumeDate: any | null;
  startDate: any | null;
  startedAt: any | null;
  activatedAt: any | null;
  cancelledAt: any | null;
  nextBillingAt: any | null;
  createdAt: any | null;
};

export type PlanFragment = {
  __typename: "BillingPlan";
  id: string;
  name: string | null;
  description: string | null;
  price: number | null;
  currencyCode: CurrencyCode | null;
  period: number | null;
  periodUnit: string | null;
  trialPeriod: number | null;
  trialPeriodUnit: string | null;
  pricingModel: string | null;
  freeQuantity: number | null;
  cfSubscriptionType: string | null;
  cfIsLegacy: boolean | null;
  cfEnterprise: boolean | null;
  cfSpecial: string | null;
  cfName: string | null;
  cfIncludedPhysicalScreenLicenses: number | null;
  cfIncludedVirtualScreenLicenses: number | null;
  billingAddonPlansByBillingPlanId: {
    __typename: "BillingAddonPlansConnection";
    nodes: Array<{
      __typename: "BillingAddonPlan";
      billingAddonByBillingAddonId: {
        __typename: "BillingAddon";
        id: string;
        name: string | null;
        currencyCode: CurrencyCode | null;
      } | null;
    }>;
  };
  billingSubscriptionsByPlanId: {
    __typename: "BillingSubscriptionsConnection";
    nodes: Array<{
      __typename: "BillingSubscription";
      id: string;
      planAmount: number | null;
      planQuantity: number | null;
      planUnitPrice: number | null;
      baseCurrencyCode: CurrencyCode | null;
      billingAddonSubscriptionsByBillingSubscriptionId: {
        __typename: "BillingAddonSubscriptionsConnection";
        nodes: Array<{
          __typename: "BillingAddonSubscription";
          billingAddonByBillingAddonId: {
            __typename: "BillingAddon";
            id: string;
            name: string | null;
            currencyCode: CurrencyCode | null;
          } | null;
        }>;
      };
    }>;
  };
};

export type CouponFragment = {
  __typename: "BillingCoupon";
  id: string;
  name: string | null;
  discountType: string | null;
  discountPercentage: any | null;
  discountAmount: number | null;
  currencyCode: CurrencyCode | null;
  status: string | null;
};

export type PaymentSourceFragment = {
  __typename: "BillingPaymentSource";
  id: string;
  type: string | null;
  status: string | null;
  card: any | null;
  bankAccount: any | null;
  paypal: any | null;
  createdAt: any | null;
  updatedAt: any | null;
};

export type RenewalFragment = {
  __typename: "BillingRenewal";
  subTotal: number | null;
  total: number | null;
  creditsApplied: number | null;
  amountPaid: number | null;
  amountDue: number | null;
  taxDescription: string | null;
  taxRate: any | null;
  taxAmount: number | null;
  lineItems: any | null;
  discounts: any | null;
  taxes: any | null;
  lineItemTaxes: any | null;
  lineItemDiscounts: any | null;
  nextBillingAt: any | null;
  createdAt: any | null;
};

export type RenewalFutureFragment = {
  __typename: "BillingRenewalFuture";
  subTotal: number | null;
  total: number | null;
  creditsApplied: number | null;
  amountPaid: number | null;
  amountDue: number | null;
  taxDescription: string | null;
  taxRate: any | null;
  taxAmount: number | null;
  lineItems: any | null;
  discounts: any | null;
  taxes: any | null;
  lineItemTaxes: any | null;
  lineItemDiscounts: any | null;
  nextBillingAt: any | null;
  createdAt: any | null;
};

export type AccountSpaceFragment = {
  __typename: "Space";
  id: any;
  name: string;
  screens: { __typename: "ScreensConnection"; totalCount: number };
};

export type AccountPlanFragment = {
  __typename: "BillingPlan";
  id: string;
  cfSubscriptionType: string | null;
};

export type AccountSubscriptionFragment = {
  __typename: "BillingSubscription";
  status: string | null;
  currencyCode: CurrencyCode | null;
  planQuantity: number | null;
  billingPlanByPlanId: {
    __typename: "BillingPlan";
    id: string;
    cfSubscriptionType: string | null;
  } | null;
};

export type AccountRenewalFragment = {
  __typename: "BillingRenewal";
  total: number | null;
};

export type AccountFragment = {
  __typename: "BillingCustomer";
  id: string;
  company: string | null;
  billingSubscriptionsByBillingCustomerId: {
    __typename: "BillingSubscriptionsConnection";
    nodes: Array<{
      __typename: "BillingSubscription";
      status: string | null;
      currencyCode: CurrencyCode | null;
      planQuantity: number | null;
      billingPlanByPlanId: {
        __typename: "BillingPlan";
        id: string;
        cfSubscriptionType: string | null;
      } | null;
    }>;
  };
  billingRenewalsByBillingCustomerId: {
    __typename: "BillingRenewalsConnection";
    nodes: Array<{ __typename: "BillingRenewal"; total: number | null }>;
  };
  spacesByBillingCustomerId: {
    __typename: "SpacesConnection";
    nodes: Array<{
      __typename: "Space";
      id: any;
      name: string;
      screens: { __typename: "ScreensConnection"; totalCount: number };
    }>;
  };
};

export type ActivateSubscriptionMutationVariables = Exact<{
  input: ActivateSubscriptionInput;
}>;

export type ActivateSubscriptionMutation = {
  __typename: "Mutation";
  activateSubscription: {
    __typename: "ActivateSubscriptionPayload";
    success: boolean | null;
  } | null;
};

export type CardIntentConfirmMutationVariables = Exact<{
  input: CardIntentConfirmInput;
}>;

export type CardIntentConfirmMutation = {
  __typename: "Mutation";
  cardIntentConfirm: {
    __typename: "CardIntentConfirmPayload";
    id: string;
    clientSecret: string;
    status: string;
  } | null;
};

export type CardIntentPaymentMutationVariables = Exact<{
  input: CardIntentPaymentInput;
}>;

export type CardIntentPaymentMutation = {
  __typename: "Mutation";
  cardIntentPayment: {
    __typename: "CardIntentPaymentPayload";
    id: string | null;
    clientSecret: string | null;
    nextActionType: string | null;
    requiresAction: boolean;
    status: string | null;
  } | null;
};

export type CardIntentSetupMutationVariables = Exact<{
  input: CardIntentSetupInput;
}>;

export type CardIntentSetupMutation = {
  __typename: "Mutation";
  cardIntentSetup: {
    __typename: "CardIntentSetupPayload";
    id: string;
    clientSecret: string;
    nextActionType: string | null;
    requiresAction: boolean;
    status: string;
  } | null;
};

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename: "Mutation";
  createCustomer: {
    __typename: "CreateCustomerPayload";
    success: boolean | null;
  } | null;
};

export type CreatePaymentSourceCardMutationVariables = Exact<{
  input: CreatePaymentSourceCardInput;
}>;

export type CreatePaymentSourceCardMutation = {
  __typename: "Mutation";
  createPaymentSourceCard: {
    __typename: "CreatePaymentSourceCardPayload";
    success: boolean | null;
  } | null;
};

export type CreatePaymentSourceDirectdebitMutationVariables = Exact<{
  input: CreatePaymentSourceDirectdebitInput;
}>;

export type CreatePaymentSourceDirectdebitMutation = {
  __typename: "Mutation";
  createPaymentSourceDirectdebit: {
    __typename: "CreatePaymentSourceDirectdebitPayload";
    success: boolean | null;
  } | null;
};

export type CreatePaymentSourcePaypalMutationVariables = Exact<{
  input: CreatePaymentSourcePaypalInput;
}>;

export type CreatePaymentSourcePaypalMutation = {
  __typename: "Mutation";
  createPaymentSourcePaypal: {
    __typename: "CreatePaymentSourcePaypalPayload";
    success: boolean | null;
  } | null;
};

export type DirectdebitAuthorizeMutationVariables = Exact<{
  input: DirectdebitAuthorizeInput;
}>;

export type DirectdebitAuthorizeMutation = {
  __typename: "Mutation";
  directdebitAuthorize: {
    __typename: "DirectdebitAuthorizePayload";
    id: string | null;
    redirectUrl: string | null;
    successRedirectUrl: string | null;
  } | null;
};

export type GetAddressQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetAddressQuery = {
  __typename: "Query";
  getBillingAddress: {
    __typename: "BillingAddress";
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    company: string | null;
    phone: string | null;
    line1: string | null;
    line2: string | null;
    line3: string | null;
    city: string | null;
    stateCode: string | null;
    state: string | null;
    country: CountryCode | null;
    zip: string | null;
    validationStatus: string | null;
  } | null;
};

export type GetBillingAddOnQueryVariables = Exact<{ [key: string]: never }>;

export type GetBillingAddOnQuery = {
  __typename: "Query";
  allBillingAddons: {
    __typename: "BillingAddonsConnection";
    nodes: Array<{
      __typename: "BillingAddon";
      id: string;
      name: string | null;
      currencyCode: CurrencyCode | null;
      price: number | null;
    }>;
  } | null;
};

export type GetBillingDetailsQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetBillingDetailsQuery = {
  __typename: "Query";
  getScreenLicences: {
    __typename: "GetScreenLicencesPayload";
    allowLicence: boolean;
    remainingLicences: number;
    usedLicences: number;
    totalLicences: number;
    futureLicences: number;
    quotaReached: boolean;
  } | null;
  getBillingCustomer: {
    __typename: "BillingCustomer";
    cfPartner: string | null;
  } | null;
  getBillingPaymentSource: {
    __typename: "BillingPaymentSourcesConnection";
    nodes: Array<{
      __typename: "BillingPaymentSource";
      id: string;
      type: string | null;
      status: string | null;
      card: any | null;
      bankAccount: any | null;
      paypal: any | null;
      createdAt: any | null;
      updatedAt: any | null;
    }>;
  } | null;
  getBillingPlan: {
    __typename: "BillingPlan";
    id: string;
    name: string | null;
    description: string | null;
    price: number | null;
    currencyCode: CurrencyCode | null;
    period: number | null;
    periodUnit: string | null;
    trialPeriod: number | null;
    trialPeriodUnit: string | null;
    pricingModel: string | null;
    freeQuantity: number | null;
    cfSubscriptionType: string | null;
    cfIsLegacy: boolean | null;
    cfEnterprise: boolean | null;
    cfSpecial: string | null;
    cfName: string | null;
    cfIncludedPhysicalScreenLicenses: number | null;
    cfIncludedVirtualScreenLicenses: number | null;
    billingAddonPlansByBillingPlanId: {
      __typename: "BillingAddonPlansConnection";
      nodes: Array<{
        __typename: "BillingAddonPlan";
        billingAddonByBillingAddonId: {
          __typename: "BillingAddon";
          id: string;
          name: string | null;
          currencyCode: CurrencyCode | null;
        } | null;
      }>;
    };
    billingSubscriptionsByPlanId: {
      __typename: "BillingSubscriptionsConnection";
      nodes: Array<{
        __typename: "BillingSubscription";
        id: string;
        planAmount: number | null;
        planQuantity: number | null;
        planUnitPrice: number | null;
        baseCurrencyCode: CurrencyCode | null;
        billingAddonSubscriptionsByBillingSubscriptionId: {
          __typename: "BillingAddonSubscriptionsConnection";
          nodes: Array<{
            __typename: "BillingAddonSubscription";
            billingAddonByBillingAddonId: {
              __typename: "BillingAddon";
              id: string;
              name: string | null;
              currencyCode: CurrencyCode | null;
            } | null;
          }>;
        };
      }>;
    };
  } | null;
  getBillingSubscription: {
    __typename: "BillingSubscription";
    id: string;
    currencyCode: CurrencyCode | null;
    planId: string | null;
    planQuantity: number | null;
    planUnitPrice: number | null;
    planAmount: number | null;
    status: string | null;
    poNumber: string | null;
    autoCollection: string | null;
    dueInvoicesCount: number | null;
    totalDues: number | null;
    baseCurrencyCode: CurrencyCode | null;
    cfPrimary: string | null;
    dueSince: any | null;
    trialStart: any | null;
    trialEnd: any | null;
    currentTermStart: any | null;
    currentTermEnd: any | null;
    pauseDate: any | null;
    resumeDate: any | null;
    startDate: any | null;
    startedAt: any | null;
    activatedAt: any | null;
    cancelledAt: any | null;
    nextBillingAt: any | null;
    createdAt: any | null;
    billingAddonSubscriptionsByBillingSubscriptionId: {
      __typename: "BillingAddonSubscriptionsConnection";
      nodes: Array<{
        __typename: "BillingAddonSubscription";
        billingAddonId: string;
        billingSubscriptionId: string;
        billingCustomerId: string;
        unitPrice: number | null;
        billingAddonByBillingAddonId: {
          __typename: "BillingAddon";
          id: string;
          name: string | null;
          currencyCode: CurrencyCode | null;
          price: number | null;
        } | null;
      }>;
    };
  } | null;
  getBillingCoupons: {
    __typename: "BillingCouponsConnection";
    nodes: Array<{
      __typename: "BillingCoupon";
      id: string;
      name: string | null;
      discountType: string | null;
      discountPercentage: any | null;
      discountAmount: number | null;
      currencyCode: CurrencyCode | null;
      status: string | null;
    }>;
  } | null;
};

export type GetCouponsQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetCouponsQuery = {
  __typename: "Query";
  getBillingCoupons: {
    __typename: "BillingCouponsConnection";
    nodes: Array<{
      __typename: "BillingCoupon";
      id: string;
      name: string | null;
      discountType: string | null;
      discountPercentage: any | null;
      discountAmount: number | null;
      currencyCode: CurrencyCode | null;
      status: string | null;
    }>;
  } | null;
};

export type GetCouponsFutureQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetCouponsFutureQuery = {
  __typename: "Query";
  getBillingCouponsFuture: {
    __typename: "BillingCouponsConnection";
    nodes: Array<{
      __typename: "BillingCoupon";
      id: string;
      name: string | null;
      discountType: string | null;
      discountPercentage: any | null;
      discountAmount: number | null;
      currencyCode: CurrencyCode | null;
      status: string | null;
    }>;
  } | null;
};

export type GetCustomerQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetCustomerQuery = {
  __typename: "Query";
  getBillingCustomer: {
    __typename: "BillingCustomer";
    id: string;
    parentId: string | null;
    paymentOwnerId: string | null;
    invoiceOwnerId: string | null;
    company: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    vatNumber: string | null;
    vatNumberStatus: string | null;
    autoCollection: string | null;
    taxability: string | null;
    billingDate: number | null;
    billingDateMode: string | null;
    billingDayOfWeek: string | null;
    billingDayOfWeekMode: string | null;
    preferredCurrencyCode: CurrencyCode | null;
    cfAllowLicence: boolean | null;
    cfPartner: string | null;
    cfInvoicedPayment: boolean | null;
    cfCustomerType: string | null;
    cfAccountManager: string | null;
    createdAt: any | null;
    updatedAt: any | null;
    billingResellerByBillingResellerId: {
      __typename: "BillingReseller";
      id: string;
      company: string | null;
    } | null;
  } | null;
};

export type GetLicencesQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetLicencesQuery = {
  __typename: "Query";
  getScreenLicences: {
    __typename: "GetScreenLicencesPayload";
    allowLicence: boolean;
    remainingLicences: number;
    usedLicences: number;
    totalLicences: number;
    futureLicences: number;
    quotaReached: boolean;
  } | null;
};

export type GetPaymentSourceQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetPaymentSourceQuery = {
  __typename: "Query";
  getBillingPaymentSource: {
    __typename: "BillingPaymentSourcesConnection";
    nodes: Array<{
      __typename: "BillingPaymentSource";
      id: string;
      type: string | null;
      status: string | null;
      card: any | null;
      bankAccount: any | null;
      paypal: any | null;
      createdAt: any | null;
      updatedAt: any | null;
    }>;
  } | null;
};

export type GetPlanQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetPlanQuery = {
  __typename: "Query";
  getBillingPlan: {
    __typename: "BillingPlan";
    id: string;
    name: string | null;
    description: string | null;
    price: number | null;
    currencyCode: CurrencyCode | null;
    period: number | null;
    periodUnit: string | null;
    trialPeriod: number | null;
    trialPeriodUnit: string | null;
    pricingModel: string | null;
    freeQuantity: number | null;
    cfSubscriptionType: string | null;
    cfIsLegacy: boolean | null;
    cfEnterprise: boolean | null;
    cfSpecial: string | null;
    cfName: string | null;
    cfIncludedPhysicalScreenLicenses: number | null;
    cfIncludedVirtualScreenLicenses: number | null;
    billingAddonPlansByBillingPlanId: {
      __typename: "BillingAddonPlansConnection";
      nodes: Array<{
        __typename: "BillingAddonPlan";
        billingAddonByBillingAddonId: {
          __typename: "BillingAddon";
          id: string;
          name: string | null;
          currencyCode: CurrencyCode | null;
        } | null;
      }>;
    };
    billingSubscriptionsByPlanId: {
      __typename: "BillingSubscriptionsConnection";
      nodes: Array<{
        __typename: "BillingSubscription";
        id: string;
        planAmount: number | null;
        planQuantity: number | null;
        planUnitPrice: number | null;
        baseCurrencyCode: CurrencyCode | null;
        billingAddonSubscriptionsByBillingSubscriptionId: {
          __typename: "BillingAddonSubscriptionsConnection";
          nodes: Array<{
            __typename: "BillingAddonSubscription";
            billingAddonByBillingAddonId: {
              __typename: "BillingAddon";
              id: string;
              name: string | null;
              currencyCode: CurrencyCode | null;
            } | null;
          }>;
        };
      }>;
    };
  } | null;
};

export type GetRenewalQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetRenewalQuery = {
  __typename: "Query";
  getBillingRenewal: {
    __typename: "BillingRenewal";
    subTotal: number | null;
    total: number | null;
    creditsApplied: number | null;
    amountPaid: number | null;
    amountDue: number | null;
    taxDescription: string | null;
    taxRate: any | null;
    taxAmount: number | null;
    lineItems: any | null;
    discounts: any | null;
    taxes: any | null;
    lineItemTaxes: any | null;
    lineItemDiscounts: any | null;
    nextBillingAt: any | null;
    createdAt: any | null;
  } | null;
};

export type GetRenewalFutureQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetRenewalFutureQuery = {
  __typename: "Query";
  getBillingRenewalFuture: {
    __typename: "BillingRenewalFuture";
    subTotal: number | null;
    total: number | null;
    creditsApplied: number | null;
    amountPaid: number | null;
    amountDue: number | null;
    taxDescription: string | null;
    taxRate: any | null;
    taxAmount: number | null;
    lineItems: any | null;
    discounts: any | null;
    taxes: any | null;
    lineItemTaxes: any | null;
    lineItemDiscounts: any | null;
    nextBillingAt: any | null;
    createdAt: any | null;
  } | null;
};

export type GetSpacesByCustomerQueryVariables = Exact<{
  billingCustomerId: InputMaybe<Scalars["String"]>;
}>;

export type GetSpacesByCustomerQuery = {
  __typename: "Query";
  allSpaces: {
    __typename: "SpacesConnection";
    nodes: Array<{
      __typename: "Space";
      id: any;
      name: string;
      screens: { __typename: "ScreensConnection"; totalCount: number };
    }>;
  } | null;
};

export type GetSubscriptionQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetSubscriptionQuery = {
  __typename: "Query";
  getBillingSubscription: {
    __typename: "BillingSubscription";
    id: string;
    currencyCode: CurrencyCode | null;
    planId: string | null;
    planQuantity: number | null;
    planUnitPrice: number | null;
    planAmount: number | null;
    status: string | null;
    poNumber: string | null;
    autoCollection: string | null;
    dueInvoicesCount: number | null;
    totalDues: number | null;
    baseCurrencyCode: CurrencyCode | null;
    cfPrimary: string | null;
    dueSince: any | null;
    trialStart: any | null;
    trialEnd: any | null;
    currentTermStart: any | null;
    currentTermEnd: any | null;
    pauseDate: any | null;
    resumeDate: any | null;
    startDate: any | null;
    startedAt: any | null;
    activatedAt: any | null;
    cancelledAt: any | null;
    nextBillingAt: any | null;
    createdAt: any | null;
    billingAddonSubscriptionsByBillingSubscriptionId: {
      __typename: "BillingAddonSubscriptionsConnection";
      nodes: Array<{
        __typename: "BillingAddonSubscription";
        billingAddonId: string;
        billingSubscriptionId: string;
        billingCustomerId: string;
        unitPrice: number | null;
        billingAddonByBillingAddonId: {
          __typename: "BillingAddon";
          id: string;
          name: string | null;
          currencyCode: CurrencyCode | null;
          price: number | null;
        } | null;
      }>;
    };
  } | null;
};

export type GetSubscriptionFutureQueryVariables = Exact<{
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type GetSubscriptionFutureQuery = {
  __typename: "Query";
  getBillingSubscriptionFuture: {
    __typename: "BillingSubscriptionFuture";
    id: string;
    currencyCode: CurrencyCode | null;
    planId: string | null;
    planQuantity: number | null;
    planUnitPrice: number | null;
    planAmount: number | null;
    status: string | null;
    poNumber: string | null;
    autoCollection: string | null;
    dueInvoicesCount: number | null;
    totalDues: number | null;
    baseCurrencyCode: CurrencyCode | null;
    cfPrimary: string | null;
    dueSince: any | null;
    trialStart: any | null;
    trialEnd: any | null;
    currentTermStart: any | null;
    currentTermEnd: any | null;
    pauseDate: any | null;
    resumeDate: any | null;
    startDate: any | null;
    startedAt: any | null;
    activatedAt: any | null;
    cancelledAt: any | null;
    nextBillingAt: any | null;
    createdAt: any | null;
  } | null;
};

export type ListPlansQueryVariables = Exact<{ [key: string]: never }>;

export type ListPlansQuery = {
  __typename: "Query";
  allBillingPlans: {
    __typename: "BillingPlansConnection";
    nodes: Array<{
      __typename: "BillingPlan";
      id: string;
      cfName: string | null;
      cfIncludedPhysicalScreenLicenses: number | null;
      cfIncludedVirtualScreenLicenses: number | null;
      cfSubscriptionType: string | null;
      cfIsLegacy: boolean | null;
      cfSpecial: string | null;
      cfEnterprise: boolean | null;
      currencyCode: CurrencyCode | null;
      price: number | null;
      periodUnit: string | null;
    }>;
  } | null;
};

export type PaypalAuthorizeMutationVariables = Exact<{
  input: PaypalAuthorizeInput;
}>;

export type PaypalAuthorizeMutation = {
  __typename: "Mutation";
  paypalAuthorize: {
    __typename: "PaypalAuthorizePayload";
    token: string | null;
    acknowledgement: string | null;
    redirectUrl: string | null;
  } | null;
};

export type SaveInvoicePaymentMutationVariables = Exact<{
  input: SaveInvoicePaymentInput;
}>;

export type SaveInvoicePaymentMutation = {
  __typename: "Mutation";
  saveInvoicePayment: {
    __typename: "SaveInvoicePaymentPayload";
    success: boolean | null;
  } | null;
};

export type UpdateBillingAddressMutationVariables = Exact<{
  input: UpdateBillingAddressInput;
}>;

export type UpdateBillingAddressMutation = {
  __typename: "Mutation";
  updateBillingAddress: {
    __typename: "UpdateBillingAddressPayload";
    success: boolean | null;
  } | null;
};

export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
}>;

export type UpdateSubscriptionMutation = {
  __typename: "Mutation";
  updateSubscription: {
    __typename: "UpdateSubscriptionPayload";
    success: boolean | null;
  } | null;
};

export type BillingPlanByIdQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type BillingPlanByIdQuery = {
  __typename: "Query";
  billingPlanById: {
    __typename: "BillingPlan";
    id: string;
    name: string | null;
    price: number | null;
    periodUnit: string | null;
    cfName: string | null;
    cfIncludedPhysicalScreenLicenses: number | null;
  } | null;
};

export type CreateRelationshipMutationVariables = Exact<{
  input: CreateRelationshipInput;
}>;

export type CreateRelationshipMutation = {
  __typename: "Mutation";
  createRelationship: {
    __typename: "CreateRelationshipPayload";
    success: boolean | null;
  } | null;
};

export type BillingPlanByIdFragment = {
  __typename: "BillingPlan";
  id: string;
  name: string | null;
  price: number | null;
  periodUnit: string | null;
  cfName: string | null;
  cfIncludedPhysicalScreenLicenses: number | null;
};

export type SpaceSimpleFragment = {
  __typename: "Space";
  id: any;
  name: string;
};

export type PaymentAccountSimpleFragment = {
  __typename: "BillingCustomer";
  id: string;
  company: string | null;
  spacesByBillingCustomerId: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any; name: string }>;
  };
  billingSubscriptionsByBillingCustomerId: {
    __typename: "BillingSubscriptionsConnection";
    nodes: Array<{
      __typename: "BillingSubscription";
      id: string;
      status: string | null;
      planQuantity: number | null;
      billingPlanByPlanId: {
        __typename: "BillingPlan";
        name: string | null;
        cfName: string | null;
      } | null;
    }>;
  };
};

export type PaymentAccountFragment = {
  __typename: "BillingCustomer";
  id: string;
  parentId: string | null;
  invoiceOwnerId: string | null;
  paymentOwnerId: string | null;
  company: string | null;
  email: string | null;
  cfPrimary: string | null;
  spacesByBillingCustomerId: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any; name: string }>;
  };
  billingSubscriptionsByBillingCustomerId: {
    __typename: "BillingSubscriptionsConnection";
    nodes: Array<{
      __typename: "BillingSubscription";
      currencyCode: CurrencyCode | null;
    }>;
  };
};

export type GetPaymentAccountQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetPaymentAccountQuery = {
  __typename: "Query";
  billingCustomerById: {
    __typename: "BillingCustomer";
    id: string;
    parentId: string | null;
    invoiceOwnerId: string | null;
    paymentOwnerId: string | null;
    company: string | null;
    email: string | null;
    cfPrimary: string | null;
    spacesByBillingCustomerId: {
      __typename: "SpacesConnection";
      nodes: Array<{ __typename: "Space"; id: any; name: string }>;
    };
    billingSubscriptionsByBillingCustomerId: {
      __typename: "BillingSubscriptionsConnection";
      nodes: Array<{
        __typename: "BillingSubscription";
        currencyCode: CurrencyCode | null;
      }>;
    };
  } | null;
};

export type GetPaymentAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaymentAccountsQuery = {
  __typename: "Query";
  allBillingCustomers: {
    __typename: "BillingCustomersConnection";
    nodes: Array<{
      __typename: "BillingCustomer";
      id: string;
      company: string | null;
      spacesByBillingCustomerId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any; name: string }>;
      };
      billingSubscriptionsByBillingCustomerId: {
        __typename: "BillingSubscriptionsConnection";
        nodes: Array<{
          __typename: "BillingSubscription";
          id: string;
          status: string | null;
          planQuantity: number | null;
          billingPlanByPlanId: {
            __typename: "BillingPlan";
            name: string | null;
            cfName: string | null;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type GetSpacesUnattachedQueryVariables = Exact<{ [key: string]: never }>;

export type GetSpacesUnattachedQuery = {
  __typename: "Query";
  allSpaces: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any; name: string }>;
  } | null;
};

export type UpdateRelationshipMutationVariables = Exact<{
  input: UpdateRelationshipInput;
}>;

export type UpdateRelationshipMutation = {
  __typename: "Mutation";
  updateRelationship: {
    __typename: "UpdateRelationshipPayload";
    success: boolean | null;
  } | null;
};

export type SpaceWithUsedLicensesFragment = {
  __typename: "Space";
  id: any;
  name: string;
  licenses: { __typename: "ScreensConnection"; used: number };
};

export type GetBillingScreenCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBillingScreenCountQuery = {
  __typename: "Query";
  screens: { __typename: "ScreensConnection"; count: number } | null;
};

export type GetBillingSpacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetBillingSpacesQuery = {
  __typename: "Query";
  all: {
    __typename: "SpacesConnection";
    nodes: Array<{
      __typename: "Space";
      id: any;
      name: string;
      licenses: { __typename: "ScreensConnection"; used: number };
    }>;
  } | null;
};

export type PairScreenMutationVariables = Exact<{
  input: CreateScreenInput;
}>;

export type PairScreenMutation = {
  __typename: "Mutation";
  createScreen: {
    __typename: "CreateScreenPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type GetPrimaryScreenGroupQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type GetPrimaryScreenGroupQuery = {
  __typename: "Query";
  allScreenGroups: {
    __typename: "ScreenGroupsConnection";
    nodes: Array<{
      __typename: "ScreenGroup";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      env: any | null;
    }>;
  } | null;
};

export type TokenByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type TokenByIdQuery = {
  __typename: "Query";
  tokenById: {
    __typename: "Token";
    id: any;
    orgId: any;
    name: string;
    permissions: Array<string | null>;
    userId: any;
    lastUsed: any | null;
    deactivatedAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    deletedAt: any | null;
    userByUserId: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
};

export type DeactivateTokenMutationVariables = Exact<{
  input: DeactivateTokenInput;
}>;

export type DeactivateTokenMutation = {
  __typename: "Mutation";
  deactivateToken: {
    __typename: "DeactivateTokenPayload";
    token: {
      __typename: "Token";
      id: any;
      orgId: any;
      name: string;
      permissions: Array<string | null>;
      userId: any;
      lastUsed: any | null;
      deactivatedAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      deletedAt: any | null;
    } | null;
    orgByOrgId: { __typename: "Org"; id: any } | null;
    userByUserId: { __typename: "User"; id: any } | null;
    userByCreatedBy: { __typename: "User"; id: any } | null;
    userByUpdatedBy: { __typename: "User"; id: any } | null;
  } | null;
};

export type ReactivateTokenMutationVariables = Exact<{
  input: ReactivateTokenInput;
}>;

export type ReactivateTokenMutation = {
  __typename: "Mutation";
  reactivateToken: {
    __typename: "ReactivateTokenPayload";
    token: {
      __typename: "Token";
      id: any;
      orgId: any;
      name: string;
      permissions: Array<string | null>;
      userId: any;
      lastUsed: any | null;
      deactivatedAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      deletedAt: any | null;
    } | null;
    orgByOrgId: { __typename: "Org"; id: any } | null;
    userByUserId: { __typename: "User"; id: any } | null;
    userByCreatedBy: { __typename: "User"; id: any } | null;
    userByUpdatedBy: { __typename: "User"; id: any } | null;
  } | null;
};

export type RegenerateTokenMutationVariables = Exact<{
  input: RegenerateTokenInput;
}>;

export type RegenerateTokenMutation = {
  __typename: "Mutation";
  regenerateToken: {
    __typename: "RegenerateTokenPayload";
    tokenSecretPayload: {
      __typename: "TokenSecretPayload";
      secret: string | null;
      token: {
        __typename: "Token";
        id: any;
        orgId: any;
        name: string;
        permissions: Array<string | null>;
        userId: any;
        lastUsed: any | null;
        deactivatedAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        deletedAt: any | null;
      } | null;
    } | null;
  } | null;
};

export type AddGroupPermissionSetsMutationVariables = Exact<{
  input: AddGroupPermissionSetsInput;
}>;

export type AddGroupPermissionSetsMutation = {
  __typename: "Mutation";
  addGroupPermissionSets: {
    __typename: "AddGroupPermissionSetsPayload";
    group: {
      __typename: "Group";
      id: any;
      name: string;
      permissionSets: Array<any | null>;
    } | null;
  } | null;
};

export type AllMembersQueryVariables = Exact<{ [key: string]: never }>;

export type AllMembersQuery = {
  __typename: "Query";
  allUsers: {
    __typename: "UsersConnection";
    nodes: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    }>;
  } | null;
};

export type CreateEnrollmentTokenMutationVariables = Exact<{
  input: CreateEnrollmentTokenInput;
}>;

export type CreateEnrollmentTokenMutation = {
  __typename: "Mutation";
  createEnrollmentToken: {
    __typename: "CreateEnrollmentTokenPayload";
    token: string | null;
    payload: any | null;
  } | null;
};

export type DeleteGroupPermissionSetsMutationVariables = Exact<{
  input: DeleteGroupPermissionSetsInput;
}>;

export type DeleteGroupPermissionSetsMutation = {
  __typename: "Mutation";
  deleteGroupPermissionSets: {
    __typename: "DeleteGroupPermissionSetsPayload";
    group: {
      __typename: "Group";
      id: any;
      name: string;
      permissionSets: Array<any | null>;
    } | null;
  } | null;
};

export type GroupByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GroupByIdQuery = {
  __typename: "Query";
  groupById: {
    __typename: "Group";
    id: any;
    name: string;
    permissionSets: Array<any | null>;
    userGroupsByGroupId: {
      __typename: "UserGroupsConnection";
      nodes: Array<{
        __typename: "UserGroup";
        userId: any;
        userByUserId: {
          __typename: "User";
          id: any;
          email: string | null;
          givenName: string;
          familyName: string;
          isOwner: boolean | null;
          metadata: any | null;
          status: UserStatus;
        } | null;
      }>;
    };
    groupSpacesByGroupId: {
      __typename: "GroupSpacesConnection";
      nodes: Array<{
        __typename: "GroupSpace";
        groupId: any;
        spaceId: any;
        permissionSets: Array<any | null>;
        permissions: Array<string | null>;
        spaceBySpaceId: { __typename: "Space"; id: any; name: string } | null;
      }>;
    };
  } | null;
};

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
}>;

export type UpdateGroupMutation = {
  __typename: "Mutation";
  updateGroup: {
    __typename: "UpdateGroupPayload";
    group: {
      __typename: "Group";
      id: any;
      orgId: any;
      name: string;
      description: string;
      createdAt: any | null;
      updatedAt: any | null;
      createdBy: any | null;
      updatedBy: any | null;
      permissions: Array<string | null>;
      permissionSets: Array<any | null>;
    } | null;
  } | null;
};

export type AllGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AllGroupsQuery = {
  __typename: "Query";
  allGroups: {
    __typename: "GroupsConnection";
    nodes: Array<{
      __typename: "Group";
      id: any;
      name: string;
      description: string;
      permissionSets: Array<any | null>;
      isDefault: boolean | null;
      groupSpacesByGroupId: {
        __typename: "GroupSpacesConnection";
        nodes: Array<{
          __typename: "GroupSpace";
          spaceId: any;
          permissionSets: Array<any | null>;
          spaceBySpaceId: { __typename: "Space"; id: any; name: string } | null;
        }>;
      };
    }>;
  } | null;
};

export type GetRemainingUserQuotaQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRemainingUserQuotaQuery = {
  __typename: "Query";
  getRemainingUserQuota: number | null;
};

export type PermissionsListQueryVariables = Exact<{ [key: string]: never }>;

export type PermissionsListQuery = {
  __typename: "Query";
  permissionsList: any | null;
};

export type AllCommonPermissionSetsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllCommonPermissionSetsQuery = {
  __typename: "Query";
  allCommonPermissionSets: {
    __typename: "CommonPermissionSetsConnection";
    nodes: Array<{
      __typename: "CommonPermissionSet";
      id: any;
      name: string;
      isDefaultProvided: boolean;
    }>;
  } | null;
};

export type AllPermissionSetsQueryVariables = Exact<{ [key: string]: never }>;

export type AllPermissionSetsQuery = {
  __typename: "Query";
  allPermissionSets: {
    __typename: "PermissionSetsConnection";
    nodes: Array<{
      __typename: "PermissionSet";
      id: any | null;
      name: string;
      commonPermissionSetId: any | null;
      description: string | null;
      color: string | null;
      permissions: Array<string | null>;
    }>;
  } | null;
};

export type CreateFontFromCommonMutationVariables = Exact<{
  input: CreateFontFromCommonInput;
}>;

export type CreateFontFromCommonMutation = {
  __typename: "Mutation";
  createFontFromCommon: {
    __typename: "CreateFontFromCommonPayload";
    font: { __typename: "Font"; id: any; name: string } | null;
  } | null;
};

export type CreateThemeMutationVariables = Exact<{
  input: CreateThemeInput;
}>;

export type CreateThemeMutation = {
  __typename: "Mutation";
  createTheme: {
    __typename: "CreateThemePayload";
    theme: {
      __typename: "Theme";
      id: any;
      orgId: any;
      name: string;
      logoFileId: any | null;
      config: any;
      titleFontId: any | null;
      bodyFontId: any | null;
      createdAt: any | null;
      updatedAt: any | null;
      createdBy: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type RemoveThemeLogoMutationVariables = Exact<{
  input: RemoveThemeLogoInput;
}>;

export type RemoveThemeLogoMutation = {
  __typename: "Mutation";
  removeThemeLogo: {
    __typename: "RemoveThemeLogoPayload";
    theme: {
      __typename: "Theme";
      id: any;
      orgId: any;
      name: string;
      logoFileId: any | null;
      config: any;
      titleFontId: any | null;
      bodyFontId: any | null;
      createdAt: any | null;
      updatedAt: any | null;
      createdBy: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type UpdateThemeMutationVariables = Exact<{
  input: UpdateThemeInput;
}>;

export type UpdateThemeMutation = {
  __typename: "Mutation";
  updateTheme: {
    __typename: "UpdateThemePayload";
    theme: {
      __typename: "Theme";
      id: any;
      orgId: any;
      name: string;
      logoFileId: any | null;
      config: any;
      titleFontId: any | null;
      bodyFontId: any | null;
      createdAt: any | null;
      updatedAt: any | null;
      createdBy: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type AddUserToGroupsMutationVariables = Exact<{
  input: AddUserToGroupsInput;
}>;

export type AddUserToGroupsMutation = {
  __typename: "Mutation";
  addUserToGroups: {
    __typename: "AddUserToGroupsPayload";
    userGroups: Array<{
      __typename: "UserGroup";
      orgId: any;
      userId: any;
      groupId: any;
      createdAt: any | null;
      createdBy: any | null;
      permissions: Array<string | null>;
      permissionSets: Array<any | null>;
    }> | null;
  } | null;
};

export type DeleteUserFromGroupsMutationVariables = Exact<{
  input: DeleteUserFromGroupsInput;
}>;

export type DeleteUserFromGroupsMutation = {
  __typename: "Mutation";
  deleteUserFromGroups: {
    __typename: "DeleteUserFromGroupsPayload";
    userGroups: Array<{
      __typename: "UserGroup";
      orgId: any;
      userId: any;
      groupId: any;
      createdAt: any | null;
      createdBy: any | null;
      permissions: Array<string | null>;
      permissionSets: Array<any | null>;
    }> | null;
  } | null;
};

export type UserByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type UserByIdQuery = {
  __typename: "Query";
  userById: {
    __typename: "User";
    id: any;
    orgId: any;
    email: string | null;
    status: UserStatus;
    userType: UserType | null;
    givenName: string;
    familyName: string;
    receiveUpdates: ReceiveUpdates;
    isOwner: boolean | null;
    metadata: any | null;
    preferences: any | null;
    permissions: Array<string | null> | null;
    permissionsByGroupIds: any | null;
    permissionsBySpaceIds: any | null;
    deletedAt: any | null;
    deletedBy: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    lastAuthenticatedAt: any | null;
    userGroupsByUserId: {
      __typename: "UserGroupsConnection";
      nodes: Array<{
        __typename: "UserGroup";
        orgId: any;
        userId: any;
        groupId: any;
        createdAt: any | null;
        createdBy: any | null;
        permissions: Array<string | null>;
        permissionSets: Array<any | null>;
        groupByGroupId: {
          __typename: "Group";
          id: any;
          orgId: any;
          name: string;
          description: string;
          createdAt: any | null;
          updatedAt: any | null;
          createdBy: any | null;
          updatedBy: any | null;
          permissions: Array<string | null>;
          permissionSets: Array<any | null>;
          groupSpacesByGroupId: {
            __typename: "GroupSpacesConnection";
            nodes: Array<{
              __typename: "GroupSpace";
              orgId: any;
              groupId: any;
              spaceId: any;
              permissions: Array<string | null>;
              permissionSets: Array<any | null>;
              spaceBySpaceId: {
                __typename: "Space";
                env: any;
                id: any;
                orgId: any;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                name: string;
                preferences: any;
                billingCustomerId: string | null;
                billingExpiresAt: any | null;
                rootFolderId: any | null;
              } | null;
            }>;
          };
        } | null;
      }>;
    };
  } | null;
};

export type AppInstanceByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AppInstanceByIdQuery = {
  __typename: "Query";
  appInstanceById: {
    __typename: "AppInstance";
    isTemplate: boolean;
    targetCommonAppInstanceTemplateId: any | null;
    id: any;
    orgId: any;
    appInstallId: any;
    appId: any;
    config: any | null;
    state: any | null;
    version: string | null;
    name: string | null;
    tags: Array<string | null>;
    isSharedAll: boolean | null;
    isShared: boolean | null;
    isSharedExplicit: boolean | null;
    spaceId: any;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    availableAt: any | null;
    expireAt: any | null;
    fileByThumbnailFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    filesByAppInstanceId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      }>;
    };
    castedScreenByAppInstanceId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        orgId: any;
        screenGroupId: any;
        castId: any | null;
        name: string;
        content: any | null;
        status: ScreenStatus;
        preview: boolean;
        device: any | null;
        deviceInfo: any | null;
        deviceIpAddress: string | null;
        deviceHostname: string | null;
        rotation: ScreenRotation;
        env: any;
        deviceId: string;
        deviceModel: string | null;
        devicePlatform: string | null;
        playerTimezone: string;
        playerHeight: number | null;
        playerWidth: number | null;
        timezoneOverride: string;
        additionalPlayerSettings: any;
        deviceProvider: string | null;
        preferences: any;
        operatingHours: any | null;
        tags: Array<string | null>;
        spaceId: any;
        playbackMode: string;
        videoSource: string;
        highResolutionImage: boolean;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isConnected: boolean;
        connectedAt: any | null;
        disconnectedAt: any | null;
        isNotificationActive: boolean | null;
        messages: Array<any | null> | null;
        screenNotesByScreenId: {
          __typename: "ScreenNotesConnection";
          nodes: Array<{
            __typename: "ScreenNote";
            id: any;
            note: string | null;
            updatedAt: any | null;
            userByUpdatedBy: {
              __typename: "User";
              givenName: string;
              familyName: string;
            } | null;
          }>;
        };
      }>;
    };
    appInstallByAppInstallId: {
      __typename: "AppInstall";
      appId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      spaceId: any | null;
    } | null;
    appVersionByAppInstanceId: {
      __typename: "AppVersion";
      id: any;
      appId: any;
      version: string | null;
      betaFlag: boolean | null;
      stableFlag: boolean | null;
      manifestJson: any | null;
      viewerUrl: string | null;
      editorUrl: string | null;
      mediaSandboxAccess: boolean | null;
      configurationType: AppConfiguration | null;
      storeData: any | null;
      createdAt: any | null;
      updatedAt: any | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
    sharedSpacesByAppInstanceId: {
      __typename: "SpacesConnection";
      nodes: Array<{ __typename: "Space"; id: any; name: string }>;
    };
  } | null;
};

export type AppInstanceByIdAssociationsQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AppInstanceByIdAssociationsQuery = {
  __typename: "Query";
  appInstanceById: {
    __typename: "AppInstance";
    associationsByToAppInstanceAndOrgId: {
      __typename: "AssociationsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Association";
        castByFromCastAndOrgId: {
          __typename: "Cast";
          id: any;
          screensByCastId: {
            __typename: "ScreensConnection";
            nodes: Array<{ __typename: "Screen"; id: any; name: string }>;
          };
        } | null;
        channelByFromChannelAndOrgId: {
          __typename: "Channel";
          id: any;
          name: string;
          draft: { __typename: "Channel"; id: any; name: string } | null;
        } | null;
        playlistByFromPlaylistAndOrgId: {
          __typename: "Playlist";
          id: any;
          name: string;
          draft: { __typename: "Playlist"; id: any; name: string } | null;
        } | null;
        appInstanceByFromAppInstanceAndOrgId: {
          __typename: "AppInstance";
          id: any;
          name: string | null;
        } | null;
        screenByFromScreenAndOrgId: {
          __typename: "Screen";
          id: any;
          name: string;
        } | null;
      }>;
    };
  } | null;
};

export type AppInstanceByIdExtendedAssociationQueryVariables = Exact<{
  id: Scalars["UUID"];
  spaceId: Scalars["UUID"];
}>;

export type AppInstanceByIdExtendedAssociationQuery = {
  __typename: "Query";
  appInstanceById: {
    __typename: "AppInstance";
    isTemplate: boolean;
    targetCommonAppInstanceTemplateId: any | null;
    id: any;
    orgId: any;
    appInstallId: any;
    appId: any;
    config: any | null;
    state: any | null;
    version: string | null;
    name: string | null;
    tags: Array<string | null>;
    isSharedAll: boolean | null;
    isShared: boolean | null;
    isSharedExplicit: boolean | null;
    spaceId: any;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    availableAt: any | null;
    expireAt: any | null;
    associationsByAppInstanceIdAndSpaceId: {
      __typename: "AssociationsConnection";
      nodes: Array<{
        __typename: "Association";
        channelByFromChannelAndOrgId: {
          __typename: "Channel";
          id: any;
          name: string;
          coverData: any | null;
          fileByCoverImageId: {
            __typename: "File";
            source: string | null;
          } | null;
          draft: { __typename: "Channel"; id: any; name: string } | null;
        } | null;
        playlistByFromPlaylistAndOrgId: {
          __typename: "Playlist";
          id: any;
          name: string;
          color: string;
          draft: { __typename: "Playlist"; id: any; name: string } | null;
        } | null;
      }>;
    };
    fileByThumbnailFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    filesByAppInstanceId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      }>;
    };
    castedScreenByAppInstanceId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        orgId: any;
        screenGroupId: any;
        castId: any | null;
        name: string;
        content: any | null;
        status: ScreenStatus;
        preview: boolean;
        device: any | null;
        deviceInfo: any | null;
        deviceIpAddress: string | null;
        deviceHostname: string | null;
        rotation: ScreenRotation;
        env: any;
        deviceId: string;
        deviceModel: string | null;
        devicePlatform: string | null;
        playerTimezone: string;
        playerHeight: number | null;
        playerWidth: number | null;
        timezoneOverride: string;
        additionalPlayerSettings: any;
        deviceProvider: string | null;
        preferences: any;
        operatingHours: any | null;
        tags: Array<string | null>;
        spaceId: any;
        playbackMode: string;
        videoSource: string;
        highResolutionImage: boolean;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isConnected: boolean;
        connectedAt: any | null;
        disconnectedAt: any | null;
        isNotificationActive: boolean | null;
        messages: Array<any | null> | null;
        screenNotesByScreenId: {
          __typename: "ScreenNotesConnection";
          nodes: Array<{
            __typename: "ScreenNote";
            id: any;
            note: string | null;
            updatedAt: any | null;
            userByUpdatedBy: {
              __typename: "User";
              givenName: string;
              familyName: string;
            } | null;
          }>;
        };
      }>;
    };
    appInstallByAppInstallId: {
      __typename: "AppInstall";
      appId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      spaceId: any | null;
    } | null;
    appVersionByAppInstanceId: {
      __typename: "AppVersion";
      id: any;
      appId: any;
      version: string | null;
      betaFlag: boolean | null;
      stableFlag: boolean | null;
      manifestJson: any | null;
      viewerUrl: string | null;
      editorUrl: string | null;
      mediaSandboxAccess: boolean | null;
      configurationType: AppConfiguration | null;
      storeData: any | null;
      createdAt: any | null;
      updatedAt: any | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
    sharedSpacesByAppInstanceId: {
      __typename: "SpacesConnection";
      nodes: Array<{ __typename: "Space"; id: any; name: string }>;
    };
  } | null;
};

export type AllAppInstallsInOrgQueryVariables = Exact<{
  orgId: Scalars["UUID"];
}>;

export type AllAppInstallsInOrgQuery = {
  __typename: "Query";
  orgById: {
    __typename: "Org";
    id: any;
    appInstallsByOrgId: {
      __typename: "AppInstallsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstall";
        id: any;
        spaceId: any | null;
        appInstancesByAppInstallId: {
          __typename: "AppInstancesConnection";
          totalCount: number;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
          categories: Array<string | null>;
          defaultInstall: AppDefaultInstallType;
          appVersionByAppId: {
            __typename: "AppVersion";
            storeData: any | null;
          } | null;
        } | null;
      }>;
    };
  } | null;
};

export type AllAppInstancesInOrgQueryVariables = Exact<{
  orgId: Scalars["UUID"];
  appId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type AllAppInstancesInOrgQuery = {
  __typename: "Query";
  orgById: {
    __typename: "Org";
    id: any;
    appInstancesByOrgId: {
      __typename: "AppInstancesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        appId: any;
        appInstallId: any;
        spaceId: any;
        state: any | null;
        version: string | null;
        name: string | null;
        config: any | null;
        isTemplate: boolean;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        createdAt: any | null;
        updatedAt: any | null;
        tags: Array<string | null>;
        targetCommonAppInstanceTemplateId: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        sharedSpacesByAppInstanceId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
        castedScreenByAppInstanceId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
            spaceId: any;
          }>;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AppInstancePickerQueryVariables = Exact<{
  orgId: Scalars["UUID"];
  appId: Scalars["UUID"];
  spaceId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type AppInstancePickerQuery = {
  __typename: "Query";
  orgById: {
    __typename: "Org";
    id: any;
    appInstancesByOrgId: {
      __typename: "AppInstancesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        appId: any;
        appInstallId: any;
        spaceId: any;
        state: any | null;
        version: string | null;
        name: string | null;
        config: any | null;
        isTemplate: boolean;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        createdAt: any | null;
        updatedAt: any | null;
        tags: Array<string | null>;
        targetCommonAppInstanceTemplateId: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        sharedSpacesByAppInstanceId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
        castedScreenByAppInstanceId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
            spaceId: any;
          }>;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type SearchAppInstancePickerQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  filter: InputMaybe<AppInstanceFilter>;
}>;

export type SearchAppInstancePickerQuery = {
  __typename: "Query";
  searchAppInstance: {
    __typename: "AppInstancesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type SearchAppInstancePickerByAppIdQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  appId: Scalars["UUID"];
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type SearchAppInstancePickerByAppIdQuery = {
  __typename: "Query";
  searchAppInstanceSpecificAppId: {
    __typename: "AppInstancesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type CastsBySpaceIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type CastsBySpaceIdQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    castsBySpace: {
      __typename: "CastsConnection";
      nodes: Array<{
        __typename: "Cast";
        id: any;
        orgId: any;
        spaceId: any;
        priority: number | null;
        content: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        expiresAt: any | null;
        channelByCastId: {
          __typename: "Channel";
          id: any;
          spaceId: any | null;
          name: string;
          coverData: any | null;
          coverImageId: any | null;
          layoutId: any | null;
          width: number | null;
          height: number | null;
          createdAt: any | null;
          hiddenZone: boolean | null;
          isSharedAll: boolean | null;
          preferences: any | null;
          isSharedExplicit: boolean | null;
          isEmbedEnabled: boolean | null;
          isEmbedPublic: boolean | null;
          childOf: any | null;
          duplicateOf: any | null;
          layoutByChannel: {
            __typename: "Layout";
            id: any;
            name: string;
            config: any | null;
          } | null;
          fileByCoverImageId: {
            __typename: "File";
            id: any;
            orgId: any;
            spaceId: any | null;
            folderId: any | null;
            name: string;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            tags: Array<string | null>;
            size: any | null;
            isSharedAll: boolean | null;
            isFavorite: boolean | null;
            isSharedExplicit: boolean | null;
            fileProcessingStatus: JobStatus | null;
            createdAt: any | null;
            expireAt: any | null;
            availableAt: any | null;
            castedScreenByFileId: {
              __typename: "ScreensConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "Screen";
                id: any;
                name: string;
                device: any | null;
                spaceId: any;
              }>;
            };
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
            userByCreatedBy: {
              __typename: "User";
              id: any;
              orgId: any;
              email: string | null;
              status: UserStatus;
              userType: UserType | null;
              givenName: string;
              familyName: string;
              receiveUpdates: ReceiveUpdates;
              isOwner: boolean | null;
              metadata: any | null;
              preferences: any | null;
              permissions: Array<string | null> | null;
              permissionsByGroupIds: any | null;
              permissionsBySpaceIds: any | null;
              deletedAt: any | null;
              deletedBy: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              lastAuthenticatedAt: any | null;
            } | null;
          } | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
          } | null;
          embedsByChannelId: {
            __typename: "EmbedsConnection";
            nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
          };
          castedScreenByChannelId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              spaceId: any;
            }>;
          };
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        } | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        appInstanceByCastId: {
          __typename: "AppInstance";
          id: any;
          name: string | null;
          createdAt: any | null;
          fileByThumbnailFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            spaceId: any | null;
            folderId: any | null;
            name: string;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            tags: Array<string | null>;
            size: any | null;
            isSharedAll: boolean | null;
            isFavorite: boolean | null;
            isSharedExplicit: boolean | null;
            fileProcessingStatus: JobStatus | null;
            createdAt: any | null;
            expireAt: any | null;
            availableAt: any | null;
            castedScreenByFileId: {
              __typename: "ScreensConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "Screen";
                id: any;
                name: string;
                device: any | null;
                spaceId: any;
              }>;
            };
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
            userByCreatedBy: {
              __typename: "User";
              id: any;
              orgId: any;
              email: string | null;
              status: UserStatus;
              userType: UserType | null;
              givenName: string;
              familyName: string;
              receiveUpdates: ReceiveUpdates;
              isOwner: boolean | null;
              metadata: any | null;
              preferences: any | null;
              permissions: Array<string | null> | null;
              permissionsByGroupIds: any | null;
              permissionsBySpaceIds: any | null;
              deletedAt: any | null;
              deletedBy: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              lastAuthenticatedAt: any | null;
            } | null;
          } | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          name: string;
          color: string;
          createdAt: any | null;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          name: string;
          createdAt: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            spaceId: any | null;
            folderId: any | null;
            name: string;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            tags: Array<string | null>;
            size: any | null;
            isSharedAll: boolean | null;
            isFavorite: boolean | null;
            isSharedExplicit: boolean | null;
            fileProcessingStatus: JobStatus | null;
            createdAt: any | null;
            expireAt: any | null;
            availableAt: any | null;
            castedScreenByFileId: {
              __typename: "ScreensConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "Screen";
                id: any;
                name: string;
                device: any | null;
                spaceId: any;
              }>;
            };
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
            userByCreatedBy: {
              __typename: "User";
              id: any;
              orgId: any;
              email: string | null;
              status: UserStatus;
              userType: UserType | null;
              givenName: string;
              familyName: string;
              receiveUpdates: ReceiveUpdates;
              isOwner: boolean | null;
              metadata: any | null;
              preferences: any | null;
              permissions: Array<string | null> | null;
              permissionsByGroupIds: any | null;
              permissionsBySpaceIds: any | null;
              deletedAt: any | null;
              deletedBy: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              lastAuthenticatedAt: any | null;
            } | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          name: string;
          createdAt: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            spaceId: any | null;
            folderId: any | null;
            name: string;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            tags: Array<string | null>;
            size: any | null;
            isSharedAll: boolean | null;
            isFavorite: boolean | null;
            isSharedExplicit: boolean | null;
            fileProcessingStatus: JobStatus | null;
            createdAt: any | null;
            expireAt: any | null;
            availableAt: any | null;
            castedScreenByFileId: {
              __typename: "ScreensConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "Screen";
                id: any;
                name: string;
                device: any | null;
                spaceId: any;
              }>;
            };
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
            userByCreatedBy: {
              __typename: "User";
              id: any;
              orgId: any;
              email: string | null;
              status: UserStatus;
              userType: UserType | null;
              givenName: string;
              familyName: string;
              receiveUpdates: ReceiveUpdates;
              isOwner: boolean | null;
              metadata: any | null;
              preferences: any | null;
              permissions: Array<string | null> | null;
              permissionsByGroupIds: any | null;
              permissionsBySpaceIds: any | null;
              deletedAt: any | null;
              deletedBy: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              lastAuthenticatedAt: any | null;
            } | null;
          } | null;
        } | null;
        screensByCastId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            createdAt: any | null;
            castId: any | null;
          }>;
        };
      }>;
    };
  } | null;
};

export type CastByIdQueryVariables = Exact<{
  castId: Scalars["UUID"];
}>;

export type CastByIdQuery = {
  __typename: "Query";
  castById: {
    __typename: "Cast";
    screensByCastId: {
      __typename: "ScreensConnection";
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        tags: Array<string | null>;
        status: ScreenStatus;
        preview: boolean;
        spaceId: any;
        deviceModel: string | null;
        devicePlatform: string | null;
        deviceInfo: any | null;
        playerTimezone: string;
        playerHeight: number | null;
        playerWidth: number | null;
        timezoneOverride: string;
        additionalPlayerSettings: any;
        isNotificationActive: boolean | null;
        castByCastId: {
          __typename: "Cast";
          id: any;
          content: any | null;
          channelByCastId: {
            __typename: "Channel";
            id: any;
            name: string;
          } | null;
          fileByCastId: {
            __typename: "File";
            id: any;
            folderId: any | null;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          playlistByCastId: {
            __typename: "Playlist";
            id: any;
            name: string;
          } | null;
          linkByCastId: {
            __typename: "Link";
            id: any;
            name: string;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
            } | null;
          } | null;
          siteByCastId: {
            __typename: "Site";
            id: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            compute: any | null;
            actions: any | null;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
              metadata: any;
              source: string | null;
              fileProcessingStatus: JobStatus | null;
            } | null;
          } | null;
          appInstanceByCastId: {
            __typename: "AppInstance";
            id: any;
            name: string | null;
            config: any | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              manifestJson: any | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              id: any;
            } | null;
          } | null;
        } | null;
      }>;
    };
  } | null;
};

export type AllChannelsInOrgQueryVariables = Exact<{
  orgId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type AllChannelsInOrgQuery = {
  __typename: "Query";
  orgById: {
    __typename: "Org";
    id: any;
    name: string;
    channelsByOrgId: {
      __typename: "ChannelsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Channel";
        id: any;
        spaceId: any | null;
        name: string;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        hiddenZone: boolean | null;
        isSharedAll: boolean | null;
        preferences: any | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        childOf: any | null;
        duplicateOf: any | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          name: string;
          config: any | null;
        } | null;
        fileByCoverImageId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        draft: {
          __typename: "Channel";
          id: any;
          isPublished: boolean | null;
        } | null;
        embedsByChannelId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByChannelId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
          }>;
        };
        parentChannelByChildOf: {
          __typename: "ParentChannelByChildOf";
          id: any | null;
          name: string | null;
          spaceId: any | null;
          spaceName: string | null;
          content: any | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type SearchChannelPickerQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type SearchChannelPickerQuery = {
  __typename: "Query";
  searchChannel: {
    __typename: "ChannelsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Channel";
      id: any;
      spaceId: any | null;
      name: string;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      hiddenZone: boolean | null;
      isSharedAll: boolean | null;
      preferences: any | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      childOf: any | null;
      duplicateOf: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        name: string;
        config: any | null;
      } | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
      } | null;
      embedsByChannelId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByChannelId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
        }>;
      };
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type ScreensByChannelIdQueryVariables = Exact<{
  id: Scalars["UUID"];
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type ScreensByChannelIdQuery = {
  __typename: "Query";
  channelById: {
    __typename: "Channel";
    id: any;
    name: string;
    screensByChannelId: {
      __typename: "ScreensConnection";
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        device: any | null;
        castByCastId: { __typename: "Cast"; id: any; orgId: any } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AllCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type AllCredentialsQuery = {
  __typename: "Query";
  allCredentials: {
    __typename: "CredentialsConnection";
    nodes: Array<{
      __typename: "Credential";
      id: any;
      spaceId: any;
      description: string | null;
      provider: string;
      username: string | null;
      credentialKey: string;
    }>;
  } | null;
};

export type AllCredentialsBySpaceIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type AllCredentialsBySpaceIdQuery = {
  __typename: "Query";
  allCredentials: {
    __typename: "CredentialsConnection";
    nodes: Array<{
      __typename: "Credential";
      id: any;
      spaceId: any;
      description: string | null;
      provider: string;
      username: string | null;
      credentialKey: string;
    }>;
  } | null;
};

export type AllIdentityProvidersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllIdentityProvidersQuery = {
  __typename: "Query";
  allIdentityProviders: Array<{
    __typename: "IdentityProvider";
    id: string;
    name: string;
    description: string;
    type: string;
    iconUrl: string | null;
  }> | null;
};

export type CreateCredentialMutationVariables = Exact<{
  input: CreateCredentialInput;
}>;

export type CreateCredentialMutation = {
  __typename: "Mutation";
  createCredential: {
    __typename: "CreateCredentialPayload";
    credential: {
      __typename: "Credential";
      id: any;
      description: string | null;
      provider: string;
      username: string | null;
      credentialKey: string;
    } | null;
  } | null;
};

export type CredentialsByKeyQueryVariables = Exact<{
  credentialKey: Scalars["String"];
}>;

export type CredentialsByKeyQuery = {
  __typename: "Query";
  allCredentials: {
    __typename: "CredentialsConnection";
    nodes: Array<{
      __typename: "Credential";
      id: any;
      spaceId: any;
      description: string | null;
      provider: string;
      username: string | null;
      credentialKey: string;
      isUsed: boolean | null;
    }>;
  } | null;
};

export type DeleteCredentialMutationVariables = Exact<{
  input: DeleteCredentialInput;
}>;

export type DeleteCredentialMutation = {
  __typename: "Mutation";
  deleteCredential: {
    __typename: "DeleteCredentialPayload";
    credential: {
      __typename: "Credential";
      id: any;
      description: string | null;
      provider: string;
      username: string | null;
      credentialKey: string;
    } | null;
  } | null;
};

export type DeleteCredentialByKeyMutationVariables = Exact<{
  input: DeleteCredentialByKeyInput;
}>;

export type DeleteCredentialByKeyMutation = {
  __typename: "Mutation";
  deleteCredentialByKey: {
    __typename: "DeleteCredentialByKeyPayload";
    credential: { __typename: "Credential"; credentialKey: string } | null;
  } | null;
};

export type IdentityProviderLaunchUrlQueryVariables = Exact<{
  input: IdentityProviderLaunchUrlInput;
}>;

export type IdentityProviderLaunchUrlQuery = {
  __typename: "Query";
  identityProviderLaunchUrl: string;
};

export type CreateFontMutationVariables = Exact<{
  input: CreateFontInput;
}>;

export type CreateFontMutation = {
  __typename: "Mutation";
  createFont: {
    __typename: "CreateFontPayload";
    font: {
      __typename: "Font";
      id: any;
      commonFontId: any | null;
      orgId: any;
      name: string;
      fontFamily: string;
      thumbnailFileId: any | null;
      regularFileId: any | null;
      boldFileId: any | null;
      italicFileId: any | null;
      boldItalicFileId: any | null;
      cssUrl: string | null;
      createdAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByRegularFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBoldFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByItalicFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBoldItalicFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    } | null;
  } | null;
};

export type DeviceInfoByScreenIdQueryVariables = Exact<{
  screenId: Scalars["UUID"];
}>;

export type DeviceInfoByScreenIdQuery = {
  __typename: "Query";
  screenById: { __typename: "Screen"; deviceInfo: any | null } | null;
};

export type CurrentFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentFeatureFlagsQuery = {
  __typename: "Query";
  currentFeatureFlags: Array<string | null> | null;
};

export type CustomizableFeatureFlagsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CustomizableFeatureFlagsQuery = {
  __typename: "Query";
  customizableFeatureFlags: {
    __typename: "FeatureFlagsConnection";
    nodes: Array<{
      __typename: "FeatureFlag";
      key: string;
      value: boolean;
      overwriteValue: boolean | null;
      description: string | null;
      defaultCustomizable: boolean;
      tags: Array<FeatureFlagTags | null>;
      visibility: FeatureFlagVisibility;
      createdAt: any | null;
      updatedAt: any | null;
    }>;
  } | null;
};

export type SetFeatureFlagOrgMutationVariables = Exact<{
  input: SetFeatureFlagOrgInput;
}>;

export type SetFeatureFlagOrgMutation = {
  __typename: "Mutation";
  setFeatureFlagOrg: {
    __typename: "SetFeatureFlagOrgPayload";
    featureFlagOrg: {
      __typename: "FeatureFlagOrg";
      key: string;
      orgId: any;
      value: boolean | null;
      customizable: boolean | null;
      customValue: boolean | null;
    } | null;
  } | null;
};

export type AllChannelsByLayoutIdQueryVariables = Exact<{
  layoutId: InputMaybe<Scalars["UUID"]>;
}>;

export type AllChannelsByLayoutIdQuery = {
  __typename: "Query";
  allChannels: {
    __typename: "ChannelsConnection";
    nodes: Array<{
      __typename: "Channel";
      name: string;
      id: any;
      draftOf: any | null;
    }>;
  } | null;
};

export type AvailableLayoutsForSizeQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  width: InputMaybe<Scalars["Int"]>;
  height: InputMaybe<Scalars["Int"]>;
}>;

export type AvailableLayoutsForSizeQuery = {
  __typename: "Query";
  availableLayoutsForSize: {
    __typename: "LayoutsConnection";
    nodes: Array<{
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    }>;
  } | null;
};

export type CreateLayoutMutationVariables = Exact<{
  input: CreateLayoutInput;
}>;

export type CreateLayoutMutation = {
  __typename: "Mutation";
  createLayout: {
    __typename: "CreateLayoutPayload";
    layout: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type DeleteLayoutMutationVariables = Exact<{
  input: DeleteLayoutInput;
}>;

export type DeleteLayoutMutation = {
  __typename: "Mutation";
  deleteLayout: {
    __typename: "DeleteLayoutPayload";
    layout: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type LayoutBySpaceIdQueryQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type LayoutBySpaceIdQueryQuery = {
  __typename: "Query";
  layoutsBySpaceId: {
    __typename: "LayoutsConnection";
    nodes: Array<{
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    }>;
  } | null;
};

export type UpdateLayoutMutationVariables = Exact<{
  input: UpdateLayoutInput;
}>;

export type UpdateLayoutMutation = {
  __typename: "Mutation";
  updateLayout: {
    __typename: "UpdateLayoutPayload";
    layout: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type AllLegacyLogsQueryVariables = Exact<{
  cursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type AllLegacyLogsQuery = {
  __typename: "Query";
  allLegacyLogs: {
    __typename: "LegacyLogsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "LegacyLog";
      id: any;
      orgId: any;
      action: string | null;
      objectId: any | null;
      objectType: string | null;
      extra: any | null;
      actionedBy: any | null;
      actionedAt: any | null;
      clientAddr: any | null;
      clientPort: number | null;
      msg: string | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: any | null;
      endCursor: any | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type CountLegacyLogsQueryVariables = Exact<{ [key: string]: never }>;

export type CountLegacyLogsQuery = {
  __typename: "Query";
  allLegacyLogs: {
    __typename: "LegacyLogsConnection";
    totalCount: number;
  } | null;
};

export type LinkPickerQueryVariables = Exact<{
  orgId: Scalars["UUID"];
  spaceId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type LinkPickerQuery = {
  __typename: "Query";
  orgById: {
    __typename: "Org";
    id: any;
    name: string;
    linksByOrgId: {
      __typename: "LinksConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Link";
        id: any;
        orgId: any;
        url: string;
        cacheBusting: boolean | null;
        autoReload: boolean | null;
        autoReloadDurationMs: number | null;
        qrcodeEnabled: boolean | null;
        qrcodeConfig: any | null;
        linkType: LinkType;
        cloudConfig: any | null;
        isFavorite: boolean | null;
        name: string;
        tags: Array<string | null>;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        castedScreenByLinkId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
          email: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type SearchLinkPickerQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type SearchLinkPickerQuery = {
  __typename: "Query";
  searchLink: {
    __typename: "LinksConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Link";
      id: any;
      orgId: any;
      url: string;
      cacheBusting: boolean | null;
      autoReload: boolean | null;
      autoReloadDurationMs: number | null;
      qrcodeEnabled: boolean | null;
      qrcodeConfig: any | null;
      linkType: LinkType;
      cloudConfig: any | null;
      isFavorite: boolean | null;
      name: string;
      tags: Array<string | null>;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileByFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      castedScreenByLinkId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type UpdateLinkIsFavoriteMutationVariables = Exact<{
  input: UpdateLinkIsFavoriteInput;
}>;

export type UpdateLinkIsFavoriteMutation = {
  __typename: "Mutation";
  updateLinkIsFavorite: {
    __typename: "UpdateLinkIsFavoritePayload";
    favorites: Array<{
      __typename: "Favorite";
      linkId: any | null;
      linkByLinkId: {
        __typename: "Link";
        id: any;
        isFavorite: boolean | null;
      } | null;
    }> | null;
  } | null;
};

export type CastedScreensCountQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type CastedScreensCountQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    castsBySpaceId: {
      __typename: "CastsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Cast";
        screensByCastId: {
          __typename: "ScreensConnection";
          totalCount: number;
        };
      }>;
    };
  } | null;
};

export type MainMenuQueryQueryVariables = Exact<{ [key: string]: never }>;

export type MainMenuQueryQuery = {
  __typename: "Query";
  currentUser: {
    __typename: "User";
    id: any;
    orgId: any;
    email: string | null;
    status: UserStatus;
    userType: UserType | null;
    givenName: string;
    familyName: string;
    receiveUpdates: ReceiveUpdates;
    isOwner: boolean | null;
    metadata: any | null;
    preferences: any | null;
    permissions: Array<string | null> | null;
    permissionsByGroupIds: any | null;
    permissionsBySpaceIds: any | null;
    deletedAt: any | null;
    deletedBy: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    lastAuthenticatedAt: any | null;
    orgByOrgId: { __typename: "Org"; id: any; name: string } | null;
  } | null;
};

export type MediaFilePickerForOrgWideQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type MediaFilePickerForOrgWideQuery = {
  __typename: "Query";
  allFilesFromAllRootFolders: {
    __typename: "FilesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      spaceId: any | null;
      folderId: any | null;
      name: string;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      tags: Array<string | null>;
      size: any | null;
      isSharedAll: boolean | null;
      isFavorite: boolean | null;
      isSharedExplicit: boolean | null;
      fileProcessingStatus: JobStatus | null;
      createdAt: any | null;
      expireAt: any | null;
      availableAt: any | null;
      castedScreenByFileId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          device: any | null;
          spaceId: any;
        }>;
      };
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type MediaFolderPickerForOrgWideQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type MediaFolderPickerForOrgWideQuery = {
  __typename: "Query";
  allChildFoldersFromAllRootFolders: {
    __typename: "FoldersConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Folder";
      parentId: any | null;
      name: string;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      spaceId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isShared: boolean | null;
      isSharedAll: boolean | null;
      foldersByParentId: {
        __typename: "FoldersConnection";
        totalCount: number;
      };
      filesByFolderId: { __typename: "FilesConnection"; totalCount: number };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type RootFolderBySpaceIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type RootFolderBySpaceIdQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    rootFolderId: any | null;
  } | null;
};

export type SearchFilePickerForOrgWideQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type SearchFilePickerForOrgWideQuery = {
  __typename: "Query";
  searchFile: {
    __typename: "FilesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      spaceId: any | null;
      folderId: any | null;
      name: string;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      tags: Array<string | null>;
      size: any | null;
      isSharedAll: boolean | null;
      isFavorite: boolean | null;
      isSharedExplicit: boolean | null;
      fileProcessingStatus: JobStatus | null;
      createdAt: any | null;
      expireAt: any | null;
      availableAt: any | null;
      castedScreenByFileId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          device: any | null;
          spaceId: any;
        }>;
      };
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type SearchFolderPickerForOrgWideQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type SearchFolderPickerForOrgWideQuery = {
  __typename: "Query";
  searchFolder: {
    __typename: "FoldersConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Folder";
      parentId: any | null;
      name: string;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      spaceId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isShared: boolean | null;
      isSharedAll: boolean | null;
      foldersByParentId: {
        __typename: "FoldersConnection";
        totalCount: number;
      };
      filesByFolderId: {
        __typename: "FilesConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type UpdateShareFolderToAllSpacesMutationVariables = Exact<{
  input: UpdateShareFolderToAllSpacesInput;
}>;

export type UpdateShareFolderToAllSpacesMutation = {
  __typename: "Mutation";
  updateShareFolderToAllSpaces: {
    __typename: "UpdateShareFolderToAllSpacesPayload";
    folder: { __typename: "Folder"; id: any; name: string } | null;
  } | null;
};

export type UpdateShareFolderToSpacesMutationVariables = Exact<{
  input: UpdateShareFolderToSpacesInput;
}>;

export type UpdateShareFolderToSpacesMutation = {
  __typename: "Mutation";
  updateShareFolderToSpaces: {
    __typename: "UpdateShareFolderToSpacesPayload";
    folder: { __typename: "Folder"; id: any; name: string } | null;
  } | null;
};

export type GetInvoicesQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type GetInvoicesQuery = {
  __typename: "Query";
  getInvoices: {
    __typename: "GetInvoicesPayload";
    invoices: any | null;
  } | null;
};

export type GetInvoicePdfQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  invoiceId: InputMaybe<Scalars["String"]>;
}>;

export type GetInvoicePdfQuery = {
  __typename: "Query";
  getInvoicePdf: {
    __typename: "GetInvoicePdfPayload";
    downloadUrl: string;
    validTill: any;
  } | null;
};

export type CreatePermissionSetMutationVariables = Exact<{
  input: CreatePermissionSetInput;
}>;

export type CreatePermissionSetMutation = {
  __typename: "Mutation";
  createPermissionSet: {
    __typename: "CreatePermissionSetPayload";
    permissionSet: {
      __typename: "PermissionSet";
      id: any | null;
      name: string;
      commonPermissionSetId: any | null;
      description: string | null;
      color: string | null;
      permissions: Array<string | null>;
    } | null;
  } | null;
};

export type DeletePermissionSetMutationVariables = Exact<{
  input: DeletePermissionSetInput;
}>;

export type DeletePermissionSetMutation = {
  __typename: "Mutation";
  deletePermissionSet: {
    __typename: "DeletePermissionSetPayload";
    permissionSet: {
      __typename: "PermissionSet";
      id: any | null;
      name: string;
      commonPermissionSetId: any | null;
      description: string | null;
      color: string | null;
      permissions: Array<string | null>;
    } | null;
  } | null;
};

export type UpdatePermissionSetMutationVariables = Exact<{
  input: UpdatePermissionSetInput;
}>;

export type UpdatePermissionSetMutation = {
  __typename: "Mutation";
  updatePermissionSet: {
    __typename: "UpdatePermissionSetPayload";
    permissionSet: {
      __typename: "PermissionSet";
      id: any | null;
      name: string;
      commonPermissionSetId: any | null;
      description: string | null;
      color: string | null;
      permissions: Array<string | null>;
    } | null;
  } | null;
};

export type ChannelByIdForPlayerPreviewQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ChannelByIdForPlayerPreviewQuery = {
  __typename: "Query";
  channelById: {
    __typename: "Channel";
    childOf: any | null;
    id: any;
    orgId: any;
    spaceId: any | null;
    themeId: any | null;
    tags: Array<string | null>;
    name: string;
    publishedAt: any | null;
    publishedBy: any | null;
    coverData: any | null;
    coverImageId: any | null;
    layoutId: any | null;
    hiddenZone: boolean | null;
    content: any | null;
    isPublished: boolean | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isParent: boolean | null;
    playingScreenNum: any | null;
    width: number | null;
    height: number | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    orgByOrgId: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      themeByDefaultChannelThemeId: {
        __typename: "Theme";
        id: any;
        name: string;
        orgId: any;
        logoFileId: any | null;
        config: any;
        fileByLogoFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          source: string | null;
        } | null;
      } | null;
    } | null;
    layoutByChannel: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    playlistsByChannelId: {
      __typename: "PlaylistsConnection";
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        orgId: any;
        spaceId: any;
        tags: Array<string | null>;
        name: string;
        color: string;
        publishedAt: any | null;
        publishedBy: any | null;
        content: any | null;
        isFavorite: boolean | null;
        isPublished: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      }>;
    };
    filesByChannelId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    linksByChannelId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        orgId: any;
        url: string;
        createdAt: any | null;
        linkType: LinkType;
        cloudConfig: any | null;
        isFavorite: boolean | null;
        name: string;
        tags: Array<string | null>;
        cacheBusting: boolean | null;
        qrcodeEnabled: boolean | null;
        qrcodeConfig: any | null;
        autoReload: boolean | null;
        autoReloadDurationMs: number | null;
        params: any | null;
        spaceId: any;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
      }>;
    };
    sitesByChannelId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        isFavorite: boolean | null;
        tags: Array<string | null>;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        castedScreenBySiteId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
        } | null;
        sharedSpacesBySiteId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
      }>;
    };
    appInstancesByChannelId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
        appInstallByAppInstallId: {
          __typename: "AppInstall";
          appId: any;
          id: any;
          orgId: any;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          spaceId: any | null;
        } | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        appByAppId: {
          __typename: "App";
          id: any;
          iconUrl: string | null;
        } | null;
      }>;
    };
    userByUpdatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
    userByPublishedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
    parentChannelByChildOf: {
      __typename: "ParentChannelByChildOf";
      id: any | null;
      name: string | null;
      spaceId: any | null;
      spaceName: string | null;
      content: any | null;
    } | null;
  } | null;
};

export type PlaylistByIdForPlayerPreviewQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type PlaylistByIdForPlayerPreviewQuery = {
  __typename: "Query";
  playlistById: {
    __typename: "Playlist";
    id: any;
    tags: Array<string | null>;
    name: string;
    color: string;
    publishedAt: any | null;
    publishedBy: any | null;
    content: any | null;
    isFavorite: boolean | null;
    isPublished: boolean | null;
    orgByOrgId: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      themeByDefaultChannelThemeId: {
        __typename: "Theme";
        id: any;
        name: string;
        orgId: any;
        logoFileId: any | null;
        config: any;
        fileByLogoFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          source: string | null;
        } | null;
      } | null;
    } | null;
    appInstancesByPlaylistId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
        appInstallByAppInstallId: {
          __typename: "AppInstall";
          appId: any;
          id: any;
        } | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        appByAppId: {
          __typename: "App";
          id: any;
          iconUrl: string | null;
        } | null;
      }>;
    };
    filesByPlaylistId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      }>;
    };
    linksByPlaylistId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        orgId: any;
        url: string;
        createdAt: any | null;
        linkType: LinkType;
        cloudConfig: any | null;
        isFavorite: boolean | null;
        name: string;
        tags: Array<string | null>;
        cacheBusting: boolean | null;
        qrcodeEnabled: boolean | null;
        qrcodeConfig: any | null;
        autoReload: boolean | null;
        autoReloadDurationMs: number | null;
        params: any | null;
        spaceId: any;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
      }>;
    };
    sitesByPlaylistId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        isFavorite: boolean | null;
        tags: Array<string | null>;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        castedScreenBySiteId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
        } | null;
        sharedSpacesBySiteId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
      }>;
    };
    userByPublishedBy: {
      __typename: "User";
      id: any;
      givenName: string;
      familyName: string;
    } | null;
  } | null;
};

export type ChannelByFromChannelAssociationFragment = {
  __typename: "Channel";
  id: any;
  name: string;
  draftOf: any | null;
  coverData: any | null;
  coverImageId: any | null;
  fileByCoverImageId: {
    __typename: "File";
    id: any;
    orgId: any;
    metadata: any;
    name: string;
    source: string | null;
    mimetype: string | null;
    fileProcessingStatus: JobStatus | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  spaceBySpaceId: { __typename: "Space"; id: any; name: string } | null;
};

export type PlaylistAssociationFragment = {
  __typename: "Playlist";
  id: any;
  name: string;
  associationsByToPlaylistAndOrgId: {
    __typename: "AssociationsConnection";
    nodes: Array<{
      __typename: "Association";
      channelByFromChannelAndOrgId: {
        __typename: "Channel";
        id: any;
        name: string;
        draftOf: any | null;
        coverData: any | null;
        coverImageId: any | null;
        fileByCoverImageId: {
          __typename: "File";
          id: any;
          orgId: any;
          metadata: any;
          name: string;
          source: string | null;
          mimetype: string | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        spaceBySpaceId: { __typename: "Space"; id: any; name: string } | null;
      } | null;
    }>;
  };
};

export type PlaylistAssociationByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type PlaylistAssociationByIdQuery = {
  __typename: "Query";
  playlistById: {
    __typename: "Playlist";
    id: any;
    name: string;
    associationsByToPlaylistAndOrgId: {
      __typename: "AssociationsConnection";
      nodes: Array<{
        __typename: "Association";
        channelByFromChannelAndOrgId: {
          __typename: "Channel";
          id: any;
          name: string;
          draftOf: any | null;
          coverData: any | null;
          coverImageId: any | null;
          fileByCoverImageId: {
            __typename: "File";
            id: any;
            orgId: any;
            metadata: any;
            name: string;
            source: string | null;
            mimetype: string | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          spaceBySpaceId: { __typename: "Space"; id: any; name: string } | null;
        } | null;
      }>;
    };
  } | null;
};

export type AllInternalPlaylistsInOrgQueryVariables = Exact<{
  orgId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type AllInternalPlaylistsInOrgQuery = {
  __typename: "Query";
  orgById: {
    __typename: "Org";
    id: any;
    name: string;
    playlistsByOrgId: {
      __typename: "PlaylistsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        isFavorite: boolean | null;
        isSharedAll: boolean | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        color: string;
        content: any | null;
        spaceId: any;
        tags: Array<string | null>;
        embedsByPlaylistId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByPlaylistId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
            deviceId: string;
          }>;
        };
        draft: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        published: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        sharedSpacesByPlaylistId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any; name: string }>;
        };
        associationsByToPlaylistAndOrgId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              spaceId: any | null;
              draftOf: any | null;
            } | null;
          }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AllInternalPlaylistsInSpaceQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type AllInternalPlaylistsInSpaceQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedPlaylistsBySpaceId: {
      __typename: "PlaylistsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        isFavorite: boolean | null;
        isSharedAll: boolean | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        color: string;
        content: any | null;
        spaceId: any;
        tags: Array<string | null>;
        embedsByPlaylistId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByPlaylistId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
            deviceId: string;
          }>;
        };
        draft: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        published: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        sharedSpacesByPlaylistId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any; name: string }>;
        };
        associationsByToPlaylistAndOrgId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              spaceId: any | null;
              draftOf: any | null;
            } | null;
          }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AllPlaylistsInOrgQueryVariables = Exact<{
  orgId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type AllPlaylistsInOrgQuery = {
  __typename: "Query";
  orgById: {
    __typename: "Org";
    id: any;
    name: string;
    playlistsByOrgId: {
      __typename: "PlaylistsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        isFavorite: boolean | null;
        isSharedAll: boolean | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        color: string;
        content: any | null;
        spaceId: any;
        tags: Array<string | null>;
        embedsByPlaylistId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByPlaylistId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
            deviceId: string;
          }>;
        };
        draft: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        published: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        sharedSpacesByPlaylistId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any; name: string }>;
        };
        associationsByToPlaylistAndOrgId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              spaceId: any | null;
              draftOf: any | null;
            } | null;
          }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AllPlaylistsInSpaceQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type AllPlaylistsInSpaceQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedPlaylistsBySpaceId: {
      __typename: "PlaylistsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        isFavorite: boolean | null;
        isSharedAll: boolean | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        color: string;
        content: any | null;
        spaceId: any;
        tags: Array<string | null>;
        embedsByPlaylistId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByPlaylistId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
            deviceId: string;
          }>;
        };
        draft: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        published: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        sharedSpacesByPlaylistId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any; name: string }>;
        };
        associationsByToPlaylistAndOrgId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              spaceId: any | null;
              draftOf: any | null;
            } | null;
          }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type SearchPlaylistPickerQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type SearchPlaylistPickerQuery = {
  __typename: "Query";
  searchPlaylist: {
    __typename: "PlaylistsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Playlist";
      id: any;
      isFavorite: boolean | null;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      name: string;
      color: string;
      content: any | null;
      spaceId: any;
      tags: Array<string | null>;
      embedsByPlaylistId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByPlaylistId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
          device: any | null;
          deviceModel: string | null;
          deviceId: string;
        }>;
      };
      draft: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      published: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      sharedSpacesByPlaylistId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any; name: string }>;
      };
      associationsByToPlaylistAndOrgId: {
        __typename: "AssociationsConnection";
        nodes: Array<{
          __typename: "Association";
          channelByFromChannelAndOrgId: {
            __typename: "Channel";
            id: any;
            spaceId: any | null;
            draftOf: any | null;
          } | null;
        }>;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type OrganisationQuotaQueryVariables = Exact<{
  orgId: Scalars["UUID"];
  usageType: Scalars["String"];
}>;

export type OrganisationQuotaQuery = {
  __typename: "Query";
  organisationQuota: {
    __typename: "Quota";
    orgId: string;
    quotaLimit: number;
    quotaUnits: number;
    unitsUsed: number;
  };
};

export type ScreenContentHistoriesByScreenIdQueryVariables = Exact<{
  id: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type ScreenContentHistoriesByScreenIdQuery = {
  __typename: "Query";
  screenById: {
    __typename: "Screen";
    id: any;
    name: string;
    content: any | null;
    deviceProvider: string | null;
    screenContentHistoriesByScreenId: {
      __typename: "ScreenContentHistoriesConnection";
      nodes: Array<{
        __typename: "ScreenContentHistory";
        id: any;
        content: any | null;
        linkByLinkIdAndOrgId: {
          __typename: "Link";
          id: any;
          orgId: any;
          name: string;
          createdAt: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            fileProcessingStatus: JobStatus | null;
          } | null;
        } | null;
        siteBySiteIdAndOrgId: {
          __typename: "Site";
          id: any;
          orgId: any;
          name: string;
          createdAt: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            fileProcessingStatus: JobStatus | null;
          } | null;
        } | null;
        channelByChannelIdAndOrgId: {
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          coverData: any | null;
          coverImageId: any | null;
          createdAt: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          fileByCoverImageId: {
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        } | null;
        playlistByPlaylistIdAndOrgId: {
          __typename: "Playlist";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          color: string;
          createdAt: any | null;
          draft: {
            __typename: "Playlist";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
        } | null;
        fileByFileIdAndOrgId: {
          __typename: "File";
          id: any;
          orgId: any;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          createdAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        appInstanceByAppInstanceIdAndOrgId: {
          __typename: "AppInstance";
          id: any;
          orgId: any;
          name: string | null;
          createdAt: any | null;
          fileByThumbnailFileId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
          } | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            appId: any;
            viewerUrl: string | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
        } | null;
      }>;
    };
  } | null;
};

export type CreateContentReplacementJobMutationVariables = Exact<{
  input: CreateContentReplacementJobInput;
}>;

export type CreateContentReplacementJobMutation = {
  __typename: "Mutation";
  createContentReplacementJob: {
    __typename: "CreateContentReplacementJobPayload";
    clientMutationId: string | null;
    contentReplacementJob: {
      __typename: "ContentReplacementJob";
      id: any;
      orgId: any;
      spaceId: any;
      originContentId: any;
      originContentType: string;
      newContentId: any;
      newContentType: string;
      targetEntities: Array<any | null> | null;
      status: string;
      createdAt: any | null;
    } | null;
  } | null;
};

export type GetCurrentReplacementJobsQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type GetCurrentReplacementJobsQuery = {
  __typename: "Query";
  getCurrentReplacementJobs: {
    __typename: "ContentReplacementJobsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "ContentReplacementJob";
      id: any;
      originalFile: { __typename: "File"; name: string } | null;
      originalAppInstance: {
        __typename: "AppInstance";
        name: string | null;
      } | null;
    }>;
  } | null;
};

export type ScreenNoteByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ScreenNoteByIdQuery = {
  __typename: "Query";
  screenNoteById: {
    __typename: "ScreenNote";
    id: any;
    note: string | null;
    updatedAt: any | null;
    userByUpdatedBy: {
      __typename: "User";
      givenName: string;
      familyName: string;
    } | null;
  } | null;
};

export type ScreenNotesByScreenIdQueryVariables = Exact<{
  screenId: Scalars["UUID"];
}>;

export type ScreenNotesByScreenIdQuery = {
  __typename: "Query";
  screenById: {
    __typename: "Screen";
    screenNotesByScreenId: {
      __typename: "ScreenNotesConnection";
      nodes: Array<{
        __typename: "ScreenNote";
        id: any;
        note: string | null;
        updatedAt: any | null;
        userByUpdatedBy: {
          __typename: "User";
          givenName: string;
          familyName: string;
        } | null;
      }>;
    };
  } | null;
};

export type CreateScreenNoteMutationVariables = Exact<{
  input: CreateScreenNoteInput;
}>;

export type CreateScreenNoteMutation = {
  __typename: "Mutation";
  createScreenNote: {
    __typename: "CreateScreenNotePayload";
    screenNote: {
      __typename: "ScreenNote";
      id: any;
      note: string | null;
      updatedAt: any | null;
      userByUpdatedBy: {
        __typename: "User";
        givenName: string;
        familyName: string;
      } | null;
    } | null;
  } | null;
};

export type UpdateScreenNoteByIdMutationVariables = Exact<{
  input: UpdateScreenNoteByIdInput;
}>;

export type UpdateScreenNoteByIdMutation = {
  __typename: "Mutation";
  updateScreenNoteById: {
    __typename: "UpdateScreenNoteByIdPayload";
    screenNote: {
      __typename: "ScreenNote";
      id: any;
      note: string | null;
      updatedAt: any | null;
      userByUpdatedBy: {
        __typename: "User";
        givenName: string;
        familyName: string;
      } | null;
    } | null;
  } | null;
};

export type DeleteScreenNoteByIdMutationVariables = Exact<{
  input: DeleteScreenNoteByIdInput;
}>;

export type DeleteScreenNoteByIdMutation = {
  __typename: "Mutation";
  deleteScreenNoteById: {
    __typename: "DeleteScreenNoteByIdPayload";
    screenNote: {
      __typename: "ScreenNote";
      id: any;
      note: string | null;
      updatedAt: any | null;
      userByUpdatedBy: {
        __typename: "User";
        givenName: string;
        familyName: string;
      } | null;
    } | null;
  } | null;
};

export type DeleteNotificationRuleByIdMutationVariables = Exact<{
  input: DeleteNotificationRuleByIdInput;
}>;

export type DeleteNotificationRuleByIdMutation = {
  __typename: "Mutation";
  deleteNotificationRuleById: {
    __typename: "DeleteNotificationRuleByIdPayload";
    notificationRule: {
      __typename: "NotificationRule";
      notificationSubscriptionsByNotificationRuleId: {
        __typename: "NotificationSubscriptionsConnection";
        nodes: Array<{
          __typename: "NotificationSubscription";
          active: boolean;
          id: any;
          userByUserIdAndOrgId: { __typename: "User"; id: any } | null;
        }>;
      };
    } | null;
  } | null;
};

export type DeleteNotificationSubscriptionByIdMutationVariables = Exact<{
  input: DeleteNotificationSubscriptionByIdInput;
}>;

export type DeleteNotificationSubscriptionByIdMutation = {
  __typename: "Mutation";
  deleteNotificationSubscriptionById: {
    __typename: "DeleteNotificationSubscriptionByIdPayload";
    notificationSubscription: {
      __typename: "NotificationSubscription";
      id: any;
    } | null;
  } | null;
};

export type SearchUserBySpaceIdQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
}>;

export type SearchUserBySpaceIdQuery = {
  __typename: "Query";
  searchUser: {
    __typename: "UsersConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      metadata: any | null;
    }>;
  } | null;
};

export type UpdateNotificationRuleByIdMutationVariables = Exact<{
  input: UpdateNotificationRuleByIdInput;
}>;

export type UpdateNotificationRuleByIdMutation = {
  __typename: "Mutation";
  updateNotificationRuleById: {
    __typename: "UpdateNotificationRuleByIdPayload";
    notificationRule: {
      __typename: "NotificationRule";
      id: any;
      active: boolean;
      waitTime: number;
    } | null;
  } | null;
};

export type UpdateNotificationSubscriptionByIdMutationVariables = Exact<{
  input: UpdateNotificationSubscriptionByIdInput;
}>;

export type UpdateNotificationSubscriptionByIdMutation = {
  __typename: "Mutation";
  updateNotificationSubscriptionById: {
    __typename: "UpdateNotificationSubscriptionByIdPayload";
    notificationSubscription: {
      __typename: "NotificationSubscription";
      id: any;
      userId: any;
      channel: string;
      active: boolean;
    } | null;
  } | null;
};

export type SidebarQueryQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type SidebarQueryQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    filesBySpaceId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        source: string | null;
        metadata: any;
        expireAt: any | null;
        availableAt: any | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    linksBySpaceId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        name: string;
        spaceId: any;
        fileByFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
      }>;
    };
    availableSitesBySpaceId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        isFavorite: boolean | null;
        tags: Array<string | null>;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        castedScreenBySiteId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
        } | null;
        sharedSpacesBySiteId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
      }>;
    };
    availableAppInstancesBySpaceId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        appId: any;
        appInstallId: any;
        spaceId: any;
        state: any | null;
        version: string | null;
        name: string | null;
        config: any | null;
        isTemplate: boolean;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        createdAt: any | null;
        updatedAt: any | null;
        tags: Array<string | null>;
        targetCommonAppInstanceTemplateId: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        sharedSpacesByAppInstanceId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
        castedScreenByAppInstanceId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
            spaceId: any;
          }>;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      }>;
    };
  } | null;
};

export type SidebarSearchQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type SidebarSearchQuery = {
  __typename: "Query";
  searchFile: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      source: string | null;
      metadata: any;
      expireAt: any | null;
      availableAt: any | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    }>;
  } | null;
  searchFolder: {
    __typename: "FoldersConnection";
    nodes: Array<{
      __typename: "Folder";
      id: any;
      parentId: any | null;
      name: string;
      spaceId: any;
      foldersByParentId: {
        __typename: "FoldersConnection";
        totalCount: number;
      };
      filesByFolderId: {
        __typename: "FilesConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "File";
          id: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          source: string | null;
          metadata: any;
          expireAt: any | null;
          availableAt: any | null;
        }>;
      };
    }>;
  } | null;
  searchLink: {
    __typename: "LinksConnection";
    nodes: Array<{
      __typename: "Link";
      id: any;
      name: string;
      spaceId: any;
      fileByFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    }>;
  } | null;
  searchSite: {
    __typename: "SitesConnection";
    nodes: Array<{
      __typename: "Site";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      type: SiteType;
      url: string;
      config: any | null;
      compute: any | null;
      actions: any | null;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      castedScreenBySiteId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
          device: any | null;
          deviceModel: string | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
      } | null;
      sharedSpacesBySiteId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
    }>;
  } | null;
  searchAppInstance: {
    __typename: "AppInstancesConnection";
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    }>;
  } | null;
};

export type ShareChannelToAllSpacesMutationVariables = Exact<{
  input: ShareChannelToAllSpacesInput;
}>;

export type ShareChannelToAllSpacesMutation = {
  __typename: "Mutation";
  shareChannelToAllSpaces: {
    __typename: "ShareChannelToAllSpacesPayload";
    channel: {
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      name: string;
      isSharedAll: boolean | null;
      preferences: any | null;
    } | null;
  } | null;
};

export type ShareChannelToSpaceMutationVariables = Exact<{
  input: ShareChannelToSpaceInput;
}>;

export type ShareChannelToSpaceMutation = {
  __typename: "Mutation";
  shareChannelToSpace: {
    __typename: "ShareChannelToSpacePayload";
    channel: {
      __typename: "Channel";
      id: any;
      orgId: any;
      isShared: boolean | null;
      isSharedAll: boolean | null;
      preferences: any | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      shortId: string | null;
    } | null;
  } | null;
};

export type SharedSpacesByChannelIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type SharedSpacesByChannelIdQuery = {
  __typename: "Query";
  channelById: {
    __typename: "Channel";
    id: any;
    sharedSpacesWithSharingModeByChannelId: {
      __typename: "SharedSpaceWithSharingModesConnection";
      nodes: Array<{
        __typename: "SharedSpaceWithSharingMode";
        readOnly: boolean | null;
        space: { __typename: "Space"; id: any } | null;
      }>;
    };
  } | null;
};

export type UpdateChannelDefaultSharingModeMutationVariables = Exact<{
  input: UpdateChannelDefaultSharingModeInput;
}>;

export type UpdateChannelDefaultSharingModeMutation = {
  __typename: "Mutation";
  updateChannelDefaultSharingMode: {
    __typename: "UpdateChannelDefaultSharingModePayload";
    channel: { __typename: "Channel"; id: any; preferences: any | null } | null;
  } | null;
};

export type ReplaceScreenDeviceMutationVariables = Exact<{
  input: ReplaceScreenDeviceInput;
}>;

export type ReplaceScreenDeviceMutation = {
  __typename: "Mutation";
  replaceScreenDevice: {
    __typename: "ReplaceScreenDeviceResponse";
    screen: {
      __typename: "Screen";
      id: any;
      name: string;
      deviceId: string;
    } | null;
  } | null;
};

export type AllScreenGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AllScreenGroupsQuery = {
  __typename: "Query";
  allScreenGroups: {
    __typename: "ScreenGroupsConnection";
    nodes: Array<{
      __typename: "ScreenGroup";
      id: any;
      name: string;
      spaceId: any;
    }>;
  } | null;
};

export type AllTagsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTagsQuery = {
  __typename: "Query";
  allTags: {
    __typename: "TagsConnection";
    nodes: Array<{ __typename: "Tag"; orgId: any; spaceId: any; name: string }>;
  } | null;
};

export type AllWhiteLabelsQueryVariables = Exact<{ [key: string]: never }>;

export type AllWhiteLabelsQuery = {
  __typename: "Query";
  allWhiteLabels: {
    __typename: "WhiteLabelsConnection";
    nodes: Array<{
      __typename: "WhiteLabel";
      id: any;
      spaceId: any | null;
      bgColor: string | null;
      textColor: string | null;
      activeItemColor: string | null;
      activeTextColor: string | null;
      fileByGlyphFileId: { __typename: "File"; source: string | null } | null;
    }>;
  } | null;
};

export type BulkCreateCastsMutationVariables = Exact<{
  input: BulkCreateCastsInput;
}>;

export type BulkCreateCastsMutation = {
  __typename: "Mutation";
  bulkCreateCasts: {
    __typename: "BulkCreateCastsPayload";
    casts: Array<{
      __typename: "Cast";
      id: any;
      orgId: any;
      spaceId: any;
      content: any | null;
    }> | null;
  } | null;
};

export type BulkResetScreenLocationMutationVariables = Exact<{
  input: BulkResetScreenLocationInput;
}>;

export type BulkResetScreenLocationMutation = {
  __typename: "Mutation";
  bulkResetScreenLocation: {
    __typename: "BulkResetScreenLocationPayload";
    screens: Array<{ __typename: "Screen"; id: any }> | null;
  } | null;
};

export type BulkUpdateScreenContentMutationVariables = Exact<{
  input: BulkUpdateScreenContentInput;
}>;

export type BulkUpdateScreenContentMutation = {
  __typename: "Mutation";
  bulkUpdateScreenContent: {
    __typename: "BulkUpdateScreenContentPayload";
    screens: Array<{
      __typename: "Screen";
      orgId: any;
      spaceId: any;
      id: any;
      name: string;
      content: any | null;
    }> | null;
  } | null;
};

export type BulkUpdateScreenLocationMutationVariables = Exact<{
  input: BulkUpdateScreenLocationInput;
}>;

export type BulkUpdateScreenLocationMutation = {
  __typename: "Mutation";
  bulkUpdateScreenLocation: {
    __typename: "BulkUpdateScreenLocationPayload";
    screens: Array<{ __typename: "Screen"; id: any }> | null;
  } | null;
};

export type CastStartMutationVariables = Exact<{
  input: CastStartInput;
}>;

export type CastStartMutation = {
  __typename: "Mutation";
  castStart: {
    __typename: "CastStartPayload";
    cast: {
      __typename: "Cast";
      id: any;
      content: any | null;
      screensByCastId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          orgId: any;
          screenGroupId: any;
          castId: any | null;
          name: string;
          content: any | null;
          status: ScreenStatus;
          device: any | null;
          preview: boolean;
          tags: Array<string | null>;
          preferences: any;
          screenContentHistoriesByScreenId: {
            __typename: "ScreenContentHistoriesConnection";
            nodes: Array<{
              __typename: "ScreenContentHistory";
              id: any;
              content: any | null;
              channelByChannelIdAndOrgId: {
                __typename: "Channel";
                id: any;
                spaceId: any | null;
                content: any | null;
                name: string;
                childOf: any | null;
                draft: {
                  __typename: "Channel";
                  id: any;
                  isPublished: boolean | null;
                } | null;
                parentChannelByChildOf: {
                  __typename: "ParentChannelByChildOf";
                  id: any | null;
                  name: string | null;
                  spaceId: any | null;
                  spaceName: string | null;
                  content: any | null;
                } | null;
              } | null;
              playlistByPlaylistIdAndOrgId: {
                __typename: "Playlist";
                id: any;
                name: string;
                content: any | null;
                draft: {
                  __typename: "Playlist";
                  id: any;
                  isPublished: boolean | null;
                } | null;
              } | null;
            }>;
          };
          screenGroupByScreenGroupId: {
            __typename: "ScreenGroup";
            id: any;
            name: string;
            isPrimary: boolean;
          } | null;
          castByCastId: {
            __typename: "Cast";
            id: any;
            orgId: any;
            spaceId: any;
            priority: number | null;
            content: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            expiresAt: any | null;
            channelByCastId: {
              __typename: "Channel";
              childOf: any | null;
              id: any;
              orgId: any;
              spaceId: any | null;
              themeId: any | null;
              tags: Array<string | null>;
              name: string;
              publishedAt: any | null;
              publishedBy: any | null;
              coverData: any | null;
              coverImageId: any | null;
              layoutId: any | null;
              hiddenZone: boolean | null;
              content: any | null;
              isPublished: boolean | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              isParent: boolean | null;
              playingScreenNum: any | null;
              width: number | null;
              height: number | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              parentChannelByChildOf: {
                __typename: "ParentChannelByChildOf";
                id: any | null;
                name: string | null;
                spaceId: any | null;
                spaceName: string | null;
                content: any | null;
              } | null;
            } | null;
            fileByCastId: {
              __typename: "File";
              id: any;
              orgId: any;
              fileProcessingStatus: JobStatus | null;
              folderId: any | null;
              mimetype: string | null;
              name: string;
              spaceId: any | null;
              tags: Array<string | null>;
              size: any | null;
              source: string | null;
              metadata: any;
              isFavorite: boolean | null;
              expireAt: any | null;
              availableAt: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                totalCount: number;
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            playlistByCastId: {
              __typename: "Playlist";
              id: any;
              orgId: any;
              spaceId: any;
              tags: Array<string | null>;
              name: string;
              color: string;
              publishedAt: any | null;
              publishedBy: any | null;
              content: any | null;
              isFavorite: boolean | null;
              isPublished: boolean | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
            } | null;
            linkByCastId: {
              __typename: "Link";
              id: any;
              orgId: any;
              url: string;
              createdAt: any | null;
              linkType: LinkType;
              cloudConfig: any | null;
              isFavorite: boolean | null;
              name: string;
              tags: Array<string | null>;
              cacheBusting: boolean | null;
              qrcodeEnabled: boolean | null;
              qrcodeConfig: any | null;
              autoReload: boolean | null;
              autoReloadDurationMs: number | null;
              params: any | null;
              spaceId: any;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              fileByFileId: {
                __typename: "File";
                id: any;
                orgId: any;
                fileProcessingStatus: JobStatus | null;
                folderId: any | null;
                mimetype: string | null;
                name: string;
                spaceId: any | null;
                tags: Array<string | null>;
                size: any | null;
                source: string | null;
                metadata: any;
                isFavorite: boolean | null;
                expireAt: any | null;
                availableAt: any | null;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
              } | null;
            } | null;
            appInstanceByCastId: {
              __typename: "AppInstance";
              id: any;
              orgId: any;
              appInstallId: any;
              appId: any;
              config: any | null;
              state: any | null;
              version: string | null;
              name: string | null;
              tags: Array<string | null>;
              isTemplate: boolean;
              isSharedAll: boolean | null;
              isShared: boolean | null;
              isSharedExplicit: boolean | null;
              spaceId: any;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              availableAt: any | null;
              expireAt: any | null;
              appVersionByAppInstanceId: {
                __typename: "AppVersion";
                id: any;
                appId: any;
                version: string | null;
                betaFlag: boolean | null;
                stableFlag: boolean | null;
                manifestJson: any | null;
                viewerUrl: string | null;
                editorUrl: string | null;
                mediaSandboxAccess: boolean | null;
                configurationType: AppConfiguration | null;
                storeData: any | null;
                createdAt: any | null;
                updatedAt: any | null;
                appByAppId: {
                  __typename: "App";
                  id: any;
                  name: string;
                  iconUrl: string | null;
                } | null;
              } | null;
              appInstallByAppInstallId: {
                __typename: "AppInstall";
                appId: any;
                id: any;
                orgId: any;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                spaceId: any | null;
              } | null;
              filesByAppInstanceId: {
                __typename: "FilesConnection";
                nodes: Array<{
                  __typename: "File";
                  id: any;
                  orgId: any;
                  fileProcessingStatus: JobStatus | null;
                  folderId: any | null;
                  mimetype: string | null;
                  name: string;
                  spaceId: any | null;
                  tags: Array<string | null>;
                  size: any | null;
                  source: string | null;
                  metadata: any;
                  isFavorite: boolean | null;
                  expireAt: any | null;
                  availableAt: any | null;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                }>;
              };
            } | null;
          } | null;
          linksByScreenId: {
            __typename: "LinksConnection";
            nodes: Array<{ __typename: "Link"; id: any; name: string }>;
          };
          channelsByScreenId: {
            __typename: "ChannelsConnection";
            nodes: Array<{ __typename: "Channel"; id: any; name: string }>;
          };
          filesByScreenId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
            }>;
          };
          playlistsByScreenId: {
            __typename: "PlaylistsConnection";
            nodes: Array<{ __typename: "Playlist"; id: any; name: string }>;
          };
          appInstancesByScreenId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
            }>;
          };
        }>;
      };
    } | null;
  } | null;
};

export type CastStopMutationVariables = Exact<{
  input: CastStopInput;
}>;

export type CastStopMutation = {
  __typename: "Mutation";
  castStop: {
    __typename: "CastStopPayload";
    screens: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      castId: any | null;
    }> | null;
  } | null;
};

export type ClearCacheScreensByOrgIdMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ClearCacheScreensByOrgIdMutation = {
  __typename: "Mutation";
  clearCacheScreensByOrgId: {
    __typename: "ClearCacheScreensByOrgIdPayload";
    clearCacheSucceeded: number | null;
    clearCacheFailed: number | null;
    error: string | null;
  } | null;
};

export type ClearCacheScreensByScreenIdsMutationVariables = Exact<{
  screenIds: Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>;
}>;

export type ClearCacheScreensByScreenIdsMutation = {
  __typename: "Mutation";
  clearCacheScreensByScreenIds: {
    __typename: "ClearCacheScreensByScreenIdsPayload";
    clearCacheSucceeded: number | null;
    clearCacheFailed: number | null;
    error: string | null;
  } | null;
};

export type ClearCacheScreensBySpaceIdMutationVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type ClearCacheScreensBySpaceIdMutation = {
  __typename: "Mutation";
  clearCacheScreensBySpaceId: {
    __typename: "ClearCacheScreensBySpaceIdPayload";
    clearCacheSucceeded: number | null;
    clearCacheFailed: number | null;
    error: string | null;
  } | null;
};

export type CreateCastsByAppInstanceIdMutationVariables = Exact<{
  input: CreateCastsByAppInstanceIdInput;
}>;

export type CreateCastsByAppInstanceIdMutation = {
  __typename: "Mutation";
  createCastsByAppInstanceId: {
    __typename: "CreateCastsByAppInstanceIdPayload";
    casts: Array<{
      __typename: "Cast";
      id: any;
      content: any | null;
      screensByCastId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          castId: any | null;
          name: string;
          content: any | null;
          device: any | null;
          spaceId: any;
        }>;
      };
      channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
      fileByCastId: {
        __typename: "File";
        id: any;
        folderId: any | null;
        name: string;
        mimetype: string | null;
        metadata: any;
        source: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      playlistByCastId: {
        __typename: "Playlist";
        id: any;
        name: string;
      } | null;
      linkByCastId: {
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        } | null;
      } | null;
      siteByCastId: {
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      } | null;
      appInstanceByCastId: {
        __typename: "AppInstance";
        id: any;
        name: string | null;
        config: any | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          manifestJson: any | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
        appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
      } | null;
    }> | null;
  } | null;
};

export type CreateCastsByChannelIdMutationVariables = Exact<{
  input: CreateCastsByChannelIdInput;
}>;

export type CreateCastsByChannelIdMutation = {
  __typename: "Mutation";
  createCastsByChannelId: {
    __typename: "CreateCastsByChannelIdPayload";
    casts: Array<{
      __typename: "Cast";
      id: any;
      content: any | null;
      screensByCastId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          castId: any | null;
          name: string;
          content: any | null;
          castByCastId: {
            __typename: "Cast";
            id: any;
            content: any | null;
            channelByCastId: {
              __typename: "Channel";
              id: any;
              name: string;
            } | null;
          } | null;
        }>;
      };
    }> | null;
  } | null;
};

export type CreateCastsByFileIdMutationVariables = Exact<{
  input: CreateCastsByFileIdInput;
}>;

export type CreateCastsByFileIdMutation = {
  __typename: "Mutation";
  createCastsByFileId: {
    __typename: "CreateCastsByFileIdPayload";
    casts: Array<{
      __typename: "Cast";
      id: any;
      content: any | null;
      screensByCastId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          castId: any | null;
          name: string;
          content: any | null;
          device: any | null;
          spaceId: any;
        }>;
      };
      channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
      fileByCastId: {
        __typename: "File";
        id: any;
        folderId: any | null;
        name: string;
        mimetype: string | null;
        metadata: any;
        source: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      playlistByCastId: {
        __typename: "Playlist";
        id: any;
        name: string;
      } | null;
      linkByCastId: {
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        } | null;
      } | null;
      siteByCastId: {
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      } | null;
      appInstanceByCastId: {
        __typename: "AppInstance";
        id: any;
        name: string | null;
        config: any | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          manifestJson: any | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
        appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
      } | null;
    }> | null;
  } | null;
};

export type CreateCastsByLinkIdMutationVariables = Exact<{
  input: CreateCastsByLinkIdInput;
}>;

export type CreateCastsByLinkIdMutation = {
  __typename: "Mutation";
  createCastsByLinkId: {
    __typename: "CreateCastsByLinkIdPayload";
    casts: Array<{
      __typename: "Cast";
      id: any;
      content: any | null;
      screensByCastId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          castId: any | null;
          name: string;
          content: any | null;
          device: any | null;
          spaceId: any;
        }>;
      };
      channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
      fileByCastId: {
        __typename: "File";
        id: any;
        folderId: any | null;
        name: string;
        mimetype: string | null;
        metadata: any;
        source: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      playlistByCastId: {
        __typename: "Playlist";
        id: any;
        name: string;
      } | null;
      linkByCastId: {
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        } | null;
      } | null;
      siteByCastId: {
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      } | null;
      appInstanceByCastId: {
        __typename: "AppInstance";
        id: any;
        name: string | null;
        config: any | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          manifestJson: any | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
        appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
      } | null;
    }> | null;
  } | null;
};

export type CreateCastsByPlaylistIdMutationVariables = Exact<{
  input: CreateCastsByPlaylistIdInput;
}>;

export type CreateCastsByPlaylistIdMutation = {
  __typename: "Mutation";
  createCastsByPlaylistId: {
    __typename: "CreateCastsByPlaylistIdPayload";
    casts: Array<{
      __typename: "Cast";
      id: any;
      content: any | null;
      screensByCastId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          castId: any | null;
          name: string;
          content: any | null;
          device: any | null;
          spaceId: any;
        }>;
      };
      channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
      fileByCastId: {
        __typename: "File";
        id: any;
        folderId: any | null;
        name: string;
        mimetype: string | null;
        metadata: any;
        source: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      playlistByCastId: {
        __typename: "Playlist";
        id: any;
        name: string;
      } | null;
      linkByCastId: {
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        } | null;
      } | null;
      siteByCastId: {
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      } | null;
      appInstanceByCastId: {
        __typename: "AppInstance";
        id: any;
        name: string | null;
        config: any | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          manifestJson: any | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
        appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
      } | null;
    }> | null;
  } | null;
};

export type CreateCastsBySiteIdMutationVariables = Exact<{
  input: CreateCastsBySiteIdInput;
}>;

export type CreateCastsBySiteIdMutation = {
  __typename: "Mutation";
  createCastsBySiteId: {
    __typename: "CreateCastsBySiteIdPayload";
    casts: Array<{
      __typename: "Cast";
      id: any;
      content: any | null;
      screensByCastId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          castId: any | null;
          name: string;
          content: any | null;
          castByCastId: {
            __typename: "Cast";
            id: any;
            content: any | null;
            siteByCastId: { __typename: "Site"; id: any; name: string } | null;
          } | null;
        }>;
      };
    }> | null;
  } | null;
};

export type CreateCastsMutationVariables = Exact<{
  input: CreateCastsInput;
}>;

export type CreateCastsMutation = {
  __typename: "Mutation";
  createCasts: {
    __typename: "CreateCastsPayload";
    casts: Array<{
      __typename: "Cast";
      id: any;
      content: any | null;
      screensByCastId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          castId: any | null;
          name: string;
          content: any | null;
          device: any | null;
          spaceId: any;
        }>;
      };
      channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
      fileByCastId: {
        __typename: "File";
        id: any;
        folderId: any | null;
        name: string;
        mimetype: string | null;
        metadata: any;
        source: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      playlistByCastId: {
        __typename: "Playlist";
        id: any;
        name: string;
      } | null;
      linkByCastId: {
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        } | null;
      } | null;
      siteByCastId: {
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      } | null;
      appInstanceByCastId: {
        __typename: "AppInstance";
        id: any;
        name: string | null;
        config: any | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          manifestJson: any | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
        appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
      } | null;
    }> | null;
  } | null;
};

export type CreateOrUpdateWhiteLabelMutationVariables = Exact<{
  input: CreateOrUpdateWhiteLabelInput;
}>;

export type CreateOrUpdateWhiteLabelMutation = {
  __typename: "Mutation";
  createOrUpdateWhiteLabel: {
    __typename: "CreateOrUpdateWhiteLabelPayload";
    clientMutationId: string | null;
  } | null;
};

export type CreateSignedAppManagementJwtMutationVariables = Exact<{
  input: AppManagementJwtRequestInput;
}>;

export type CreateSignedAppManagementJwtMutation = {
  __typename: "Mutation";
  createSignedAppManagementJwt: {
    __typename: "AppManagementJwtPayload";
    signedAppManagementToken: string | null;
    tokenType: string | null;
    expiresAt: number | null;
  } | null;
};

export type CreateSignedRuntimeJwtMutationVariables = Exact<{
  input: RuntimeJwtRequestInput;
}>;

export type CreateSignedRuntimeJwtMutation = {
  __typename: "Mutation";
  createSignedRuntimeJwt: {
    __typename: "RuntimeJwtPayload";
    signedRuntimeToken: string | null;
    tokenType: string | null;
    expiresAt: number | null;
  } | null;
};

export type CurrentOrgQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentOrgQuery = {
  __typename: "Query";
  currentOrg: {
    __typename: "Org";
    id: any;
    name: string;
    brandForegroundColor: string;
    brandBackgroundColor: string;
    startChannelId: any | null;
    blankChannelId: any | null;
    brandChannelId: any | null;
    splashChannelThemeId: any | null;
    preferences: any | null;
    defaultChannelThemeId: any | null;
    brandLogoId: any | null;
    brandBackgroundId: any | null;
    billingExpiresAt: any | null;
    isDraft: boolean;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    featureFlagOrgsByOrgId: {
      __typename: "FeatureFlagOrgsConnection";
      nodes: Array<{
        __typename: "FeatureFlagOrg";
        key: string;
        orgId: any;
        value: boolean | null;
        customizable: boolean | null;
        customValue: boolean | null;
      }>;
    };
    screensByOrgId: { __typename: "ScreensConnection"; totalCount: number };
    spacesByOrgId: { __typename: "SpacesConnection"; totalCount: number };
  } | null;
};

export type AvailableAppInstanceFragment = {
  __typename: "AppInstance";
  id: any;
  appId: any;
  appInstallId: any;
  spaceId: any;
  state: any | null;
  version: string | null;
  name: string | null;
  config: any | null;
  isTemplate: boolean;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  createdAt: any | null;
  updatedAt: any | null;
  tags: Array<string | null>;
  targetCommonAppInstanceTemplateId: any | null;
  availableAt: any | null;
  expireAt: any | null;
  fileByThumbnailFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    spaceId: any | null;
    folderId: any | null;
    name: string;
    metadata: any;
    source: string | null;
    mimetype: string | null;
    tags: Array<string | null>;
    size: any | null;
    isSharedAll: boolean | null;
    isFavorite: boolean | null;
    isSharedExplicit: boolean | null;
    fileProcessingStatus: JobStatus | null;
    createdAt: any | null;
    expireAt: any | null;
    availableAt: any | null;
    castedScreenByFileId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        device: any | null;
        spaceId: any;
      }>;
    };
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
    userByCreatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
  sharedSpacesByAppInstanceId: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any }>;
  };
  castedScreenByAppInstanceId: {
    __typename: "ScreensConnection";
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      deviceId: string;
      deviceModel: string | null;
      spaceId: any;
    }>;
  };
  appByAppId: {
    __typename: "App";
    id: any;
    name: string;
    iconUrl: string | null;
  } | null;
};

export type AppInstanceByIdForContentFragment = {
  __typename: "AppInstance";
  id: any;
  orgId: any;
  config: any | null;
  name: string | null;
  availableAt: any | null;
  expireAt: any | null;
  fileByThumbnailFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
  appByAppId: {
    __typename: "App";
    id: any;
    name: string;
    iconUrl: string | null;
  } | null;
};

export type AppInstanceForContentListFragment = {
  __typename: "AppInstance";
  id: any;
  name: string | null;
  fileByThumbnailFileId: {
    __typename: "File";
    id: any;
    name: string;
    metadata: any;
    mimetype: string | null;
    source: string | null;
    expireAt: any | null;
    fileProcessingStatus: JobStatus | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  appByAppId: { __typename: "App"; id: any; iconUrl: string | null } | null;
};

export type AppInstanceForContentListWithConfigFragment = {
  __typename: "AppInstance";
  id: any;
  name: string | null;
  config: any | null;
  fileByThumbnailFileId: {
    __typename: "File";
    id: any;
    name: string;
    metadata: any;
    mimetype: string | null;
    source: string | null;
    expireAt: any | null;
    fileProcessingStatus: JobStatus | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  appByAppId: { __typename: "App"; id: any; iconUrl: string | null } | null;
};

export type AvailableAppInstallsFragment = {
  __typename: "AppInstall";
  id: any;
  spaceId: any | null;
  appByAppId: {
    __typename: "App";
    id: any;
    name: string;
    iconUrl: string | null;
    categories: Array<string | null>;
    defaultInstall: AppDefaultInstallType;
    appVersionByAppId: {
      __typename: "AppVersion";
      storeData: any | null;
    } | null;
  } | null;
};

export type AppInstanceForMultiSelectFragment = {
  __typename: "AppInstance";
  id: any;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  spaceId: any;
};

export type AvailableAppInstallsWithAvailableAppInstancesByAppInstallIdAndSpaceFragment = {
  __typename: "AppInstall";
  id: any;
  spaceId: any | null;
  availableAppInstancesByAppInstallIdAndSpace: {
    __typename: "AppInstancesConnection";
    totalCount: number;
  };
  appByAppId: {
    __typename: "App";
    id: any;
    name: string;
    iconUrl: string | null;
    categories: Array<string | null>;
    defaultInstall: AppDefaultInstallType;
    appVersionByAppId: {
      __typename: "AppVersion";
      storeData: any | null;
    } | null;
  } | null;
};

export type AvailableAppInstallsWithAppInstancesByAppInstallIdFragment = {
  __typename: "AppInstall";
  id: any;
  spaceId: any | null;
  appInstancesByAppInstallId: {
    __typename: "AppInstancesConnection";
    totalCount: number;
  };
  appByAppId: {
    __typename: "App";
    id: any;
    name: string;
    iconUrl: string | null;
    categories: Array<string | null>;
    defaultInstall: AppDefaultInstallType;
    appVersionByAppId: {
      __typename: "AppVersion";
      storeData: any | null;
    } | null;
  } | null;
};

export type AssociationsFragment = {
  __typename: "Association";
  castByFromCastAndOrgId: {
    __typename: "Cast";
    id: any;
    screensByCastId: {
      __typename: "ScreensConnection";
      nodes: Array<{ __typename: "Screen"; id: any; name: string }>;
    };
  } | null;
  channelByFromChannelAndOrgId: {
    __typename: "Channel";
    id: any;
    name: string;
    draft: { __typename: "Channel"; id: any; name: string } | null;
  } | null;
  playlistByFromPlaylistAndOrgId: {
    __typename: "Playlist";
    id: any;
    name: string;
    draft: { __typename: "Playlist"; id: any; name: string } | null;
  } | null;
  appInstanceByFromAppInstanceAndOrgId: {
    __typename: "AppInstance";
    id: any;
    name: string | null;
  } | null;
  screenByFromScreenAndOrgId: {
    __typename: "Screen";
    id: any;
    name: string;
  } | null;
};

export type PlaylistAndChannelAssociationsFragment = {
  __typename: "Association";
  channelByFromChannelAndOrgId: {
    __typename: "Channel";
    id: any;
    name: string;
    coverData: any | null;
    fileByCoverImageId: { __typename: "File"; source: string | null } | null;
    draft: { __typename: "Channel"; id: any; name: string } | null;
  } | null;
  playlistByFromPlaylistAndOrgId: {
    __typename: "Playlist";
    id: any;
    name: string;
    color: string;
    draft: { __typename: "Playlist"; id: any; name: string } | null;
  } | null;
};

export type ChannelListItemFragment = {
  __typename: "Channel";
  id: any;
  spaceId: any | null;
  name: string;
  coverData: any | null;
  coverImageId: any | null;
  layoutId: any | null;
  width: number | null;
  height: number | null;
  createdAt: any | null;
  hiddenZone: boolean | null;
  isSharedAll: boolean | null;
  preferences: any | null;
  isSharedExplicit: boolean | null;
  isEmbedEnabled: boolean | null;
  isEmbedPublic: boolean | null;
  childOf: any | null;
  duplicateOf: any | null;
  layoutByChannel: {
    __typename: "Layout";
    id: any;
    name: string;
    config: any | null;
  } | null;
  fileByCoverImageId: {
    __typename: "File";
    id: any;
    orgId: any;
    spaceId: any | null;
    folderId: any | null;
    name: string;
    metadata: any;
    source: string | null;
    mimetype: string | null;
    tags: Array<string | null>;
    size: any | null;
    isSharedAll: boolean | null;
    isFavorite: boolean | null;
    isSharedExplicit: boolean | null;
    fileProcessingStatus: JobStatus | null;
    createdAt: any | null;
    expireAt: any | null;
    availableAt: any | null;
    castedScreenByFileId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        device: any | null;
        spaceId: any;
      }>;
    };
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
    userByCreatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
  draft: { __typename: "Channel"; id: any; isPublished: boolean | null } | null;
  embedsByChannelId: {
    __typename: "EmbedsConnection";
    nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
  };
  castedScreenByChannelId: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{ __typename: "Screen"; id: any; name: string; spaceId: any }>;
  };
  parentChannelByChildOf: {
    __typename: "ParentChannelByChildOf";
    id: any | null;
    name: string | null;
    spaceId: any | null;
    spaceName: string | null;
    content: any | null;
  } | null;
};

export type SharedChannelListItemFragment = {
  __typename: "Channel";
  id: any;
  spaceId: any | null;
  name: string;
  coverData: any | null;
  coverImageId: any | null;
  layoutId: any | null;
  width: number | null;
  height: number | null;
  createdAt: any | null;
  hiddenZone: boolean | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  isEmbedEnabled: boolean | null;
  isEmbedPublic: boolean | null;
  childOf: any | null;
  layoutByChannel: {
    __typename: "Layout";
    id: any;
    name: string;
    config: any | null;
  } | null;
  fileByCoverImageId: {
    __typename: "File";
    id: any;
    orgId: any;
    spaceId: any | null;
    folderId: any | null;
    name: string;
    metadata: any;
    source: string | null;
    mimetype: string | null;
    tags: Array<string | null>;
    size: any | null;
    isSharedAll: boolean | null;
    isFavorite: boolean | null;
    isSharedExplicit: boolean | null;
    fileProcessingStatus: JobStatus | null;
    createdAt: any | null;
    expireAt: any | null;
    availableAt: any | null;
    castedScreenByFileId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        device: any | null;
        spaceId: any;
      }>;
    };
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
    userByCreatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
  draft: { __typename: "Channel"; id: any; isPublished: boolean | null } | null;
  castedScreenByChannelId: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{ __typename: "Screen"; id: any; name: string; spaceId: any }>;
  };
  embedsByChannelId: {
    __typename: "EmbedsConnection";
    nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
  };
};

export type ChannelDetailFragment = {
  __typename: "Channel";
  id: any;
  orgId: any;
  spaceId: any | null;
  themeId: any | null;
  tags: Array<string | null>;
  name: string;
  publishedAt: any | null;
  publishedBy: any | null;
  coverData: any | null;
  coverImageId: any | null;
  layoutId: any | null;
  hiddenZone: boolean | null;
  content: any | null;
  isPublished: boolean | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  isParent: boolean | null;
  playingScreenNum: any | null;
  width: number | null;
  height: number | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  layoutByChannel: {
    __typename: "Layout";
    id: any;
    orgId: any;
    spaceId: any;
    name: string;
    zones: number;
    config: any | null;
    width: number | null;
    height: number | null;
    isFlexible: boolean | null;
    isScalable: boolean | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
  playlistsByChannelId: {
    __typename: "PlaylistsConnection";
    nodes: Array<{
      __typename: "Playlist";
      color: string;
      content: any | null;
      id: any;
      isFavorite: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      name: string;
      spaceId: any;
      tags: Array<string | null>;
      draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
    }>;
  };
  filesByChannelId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      name: string;
      expireAt: any | null;
    }>;
  };
  linksByChannelId: {
    __typename: "LinksConnection";
    nodes: Array<{ __typename: "Link"; id: any; name: string }>;
  };
  sitesByChannelId: {
    __typename: "SitesConnection";
    nodes: Array<{
      __typename: "Site";
      id: any;
      orgId: any;
      name: string;
      type: SiteType;
      url: string;
      config: any | null;
      actions: any | null;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        orgId: any;
        metadata: any;
        source: string | null;
        mimetype: string | null;
      } | null;
    }>;
  };
  appInstancesByChannelId: {
    __typename: "AppInstancesConnection";
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      name: string | null;
      expireAt: any | null;
    }>;
  };
};

export type ChannelByIdFragment = {
  __typename: "Channel";
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  isEmbedEnabled: boolean | null;
  isEmbedPublic: boolean | null;
  preferences: any | null;
  childOf: any | null;
  duplicateOf: any | null;
  id: any;
  orgId: any;
  spaceId: any | null;
  themeId: any | null;
  tags: Array<string | null>;
  name: string;
  publishedAt: any | null;
  publishedBy: any | null;
  coverData: any | null;
  coverImageId: any | null;
  layoutId: any | null;
  hiddenZone: boolean | null;
  content: any | null;
  isPublished: boolean | null;
  isParent: boolean | null;
  playingScreenNum: any | null;
  width: number | null;
  height: number | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  fileByCoverImageId: {
    __typename: "File";
    id: any;
    orgId: any;
    metadata: any;
    name: string;
    source: string | null;
    mimetype: string | null;
    fileProcessingStatus: JobStatus | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  draft: {
    __typename: "Channel";
    id: any;
    orgId: any;
    spaceId: any | null;
    themeId: any | null;
    tags: Array<string | null>;
    name: string;
    publishedAt: any | null;
    publishedBy: any | null;
    coverData: any | null;
    coverImageId: any | null;
    layoutId: any | null;
    hiddenZone: boolean | null;
    content: any | null;
    isPublished: boolean | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isParent: boolean | null;
    playingScreenNum: any | null;
    width: number | null;
    height: number | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    layoutByChannel: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    playlistsByChannelId: {
      __typename: "PlaylistsConnection";
      nodes: Array<{
        __typename: "Playlist";
        color: string;
        content: any | null;
        id: any;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        spaceId: any;
        tags: Array<string | null>;
        draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
      }>;
    };
    filesByChannelId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        name: string;
        expireAt: any | null;
      }>;
    };
    linksByChannelId: {
      __typename: "LinksConnection";
      nodes: Array<{ __typename: "Link"; id: any; name: string }>;
    };
    sitesByChannelId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          metadata: any;
          source: string | null;
          mimetype: string | null;
        } | null;
      }>;
    };
    appInstancesByChannelId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        expireAt: any | null;
      }>;
    };
  } | null;
  published: {
    __typename: "Channel";
    id: any;
    orgId: any;
    spaceId: any | null;
    themeId: any | null;
    tags: Array<string | null>;
    name: string;
    publishedAt: any | null;
    publishedBy: any | null;
    coverData: any | null;
    coverImageId: any | null;
    layoutId: any | null;
    hiddenZone: boolean | null;
    content: any | null;
    isPublished: boolean | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isParent: boolean | null;
    playingScreenNum: any | null;
    width: number | null;
    height: number | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    layoutByChannel: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    playlistsByChannelId: {
      __typename: "PlaylistsConnection";
      nodes: Array<{
        __typename: "Playlist";
        color: string;
        content: any | null;
        id: any;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        spaceId: any;
        tags: Array<string | null>;
        draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
      }>;
    };
    filesByChannelId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        name: string;
        expireAt: any | null;
      }>;
    };
    linksByChannelId: {
      __typename: "LinksConnection";
      nodes: Array<{ __typename: "Link"; id: any; name: string }>;
    };
    sitesByChannelId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          metadata: any;
          source: string | null;
          mimetype: string | null;
        } | null;
      }>;
    };
    appInstancesByChannelId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        expireAt: any | null;
      }>;
    };
  } | null;
  embedsByChannelId: {
    __typename: "EmbedsConnection";
    nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
  };
  castedScreenByChannelId: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{ __typename: "Screen"; id: any; name: string; spaceId: any }>;
  };
  parentChannelByChildOf: {
    __typename: "ParentChannelByChildOf";
    id: any | null;
    name: string | null;
    spaceId: any | null;
    spaceName: string | null;
    content: any | null;
  } | null;
};

export type SearchChannelFragment = {
  __typename: "Channel";
  id: any;
  spaceId: any | null;
  name: string;
  coverData: any | null;
  coverImageId: any | null;
  content: any | null;
  layoutId: any | null;
  width: number | null;
  height: number | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  layoutByChannel: {
    __typename: "Layout";
    id: any;
    name: string;
    config: any | null;
  } | null;
  fileByCoverImageId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
  draft: { __typename: "Channel"; id: any; isPublished: boolean | null } | null;
  screensByChannelId: { __typename: "ScreensConnection"; totalCount: number };
};

export type CastItemFragment = {
  __typename: "Cast";
  id: any;
  screensByCastId: {
    __typename: "ScreensConnection";
    nodes: Array<{
      __typename: "Screen";
      id: any;
      spaceId: any;
      name: string;
      device: any | null;
    }>;
  };
};

export type ParentChannelFragment = {
  __typename: "ParentChannelByChildOf";
  id: any | null;
  name: string | null;
  spaceId: any | null;
  spaceName: string | null;
  content: any | null;
};

export type FontFragment = {
  __typename: "Font";
  id: any;
  orgId: any;
  name: string;
  fontFamily: string;
  regularFileId: any | null;
  boldFileId: any | null;
  italicFileId: any | null;
  boldItalicFileId: any | null;
  thumbnailFileId: any | null;
  cssUrl: string | null;
  createdAt: any | null;
  updatedAt: any | null;
  createdBy: any | null;
  updatedBy: any | null;
};

export type AllFontItemsFragment = {
  __typename: "Font";
  id: any;
  commonFontId: any | null;
  orgId: any;
  name: string;
  fontFamily: string;
  thumbnailFileId: any | null;
  regularFileId: any | null;
  boldFileId: any | null;
  italicFileId: any | null;
  boldItalicFileId: any | null;
  cssUrl: string | null;
  createdAt: any | null;
  fileByRegularFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
  fileByBoldFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
  fileByItalicFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
  fileByBoldItalicFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
};

export type AllCommonFontItemsFragment = {
  __typename: "CommonFont";
  id: any;
  name: string;
  fontFamily: string;
  cssUrl: string | null;
};

export type LinkListItemFragment = {
  __typename: "Link";
  id: any;
  orgId: any;
  url: string;
  cacheBusting: boolean | null;
  autoReload: boolean | null;
  autoReloadDurationMs: number | null;
  qrcodeEnabled: boolean | null;
  qrcodeConfig: any | null;
  linkType: LinkType;
  cloudConfig: any | null;
  isFavorite: boolean | null;
  name: string;
  tags: Array<string | null>;
  spaceId: any;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  fileByFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
  castedScreenByLinkId: {
    __typename: "ScreensConnection";
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      deviceId: string;
      deviceModel: string | null;
    }>;
  };
  userByCreatedBy: {
    __typename: "User";
    id: any;
    givenName: string;
    familyName: string;
    email: string | null;
  } | null;
};

export type FileListItemFragment = {
  __typename: "File";
  id: any;
  orgId: any;
  spaceId: any | null;
  folderId: any | null;
  name: string;
  metadata: any;
  source: string | null;
  mimetype: string | null;
  tags: Array<string | null>;
  size: any | null;
  isSharedAll: boolean | null;
  isFavorite: boolean | null;
  isSharedExplicit: boolean | null;
  fileProcessingStatus: JobStatus | null;
  createdAt: any | null;
  expireAt: any | null;
  availableAt: any | null;
  castedScreenByFileId: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      device: any | null;
      spaceId: any;
    }>;
  };
  fileOutputsByFileId: {
    __typename: "FileOutputsConnection";
    nodes: Array<{
      __typename: "FileOutput";
      id: any;
      content: any | null;
      metadata: any | null;
      mimetype: string | null;
      url: string | null;
      size: any | null;
    }>;
  };
  userByCreatedBy: {
    __typename: "User";
    id: any;
    orgId: any;
    email: string | null;
    status: UserStatus;
    userType: UserType | null;
    givenName: string;
    familyName: string;
    receiveUpdates: ReceiveUpdates;
    isOwner: boolean | null;
    metadata: any | null;
    preferences: any | null;
    permissions: Array<string | null> | null;
    permissionsByGroupIds: any | null;
    permissionsBySpaceIds: any | null;
    deletedAt: any | null;
    deletedBy: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    lastAuthenticatedAt: any | null;
  } | null;
};

export type FileListItemForPlaylistFragment = {
  __typename: "File";
  id: any;
  name: string;
  metadata: any;
  mimetype: string | null;
  source: string | null;
  expireAt: any | null;
  fileProcessingStatus: JobStatus | null;
  fileOutputsByFileId: {
    __typename: "FileOutputsConnection";
    nodes: Array<{
      __typename: "FileOutput";
      id: any;
      content: any | null;
      metadata: any | null;
      mimetype: string | null;
      url: string | null;
      size: any | null;
    }>;
  };
};

export type CommonFileItemFragment = {
  __typename: "CommonFile";
  id: any | null;
  name: string;
  metadata: any;
  source: string | null;
  mimetype: string | null;
  fileProcessingStatus: JobStatus | null;
  createdAt: any | null;
  expireAt: any | null;
  availableAt: any | null;
  filesByCommonFileId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      spaceId: any | null;
      folderId: any | null;
      name: string;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      tags: Array<string | null>;
      size: any | null;
      isSharedAll: boolean | null;
      isFavorite: boolean | null;
      isSharedExplicit: boolean | null;
      fileProcessingStatus: JobStatus | null;
      createdAt: any | null;
      expireAt: any | null;
      availableAt: any | null;
      castedScreenByFileId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          device: any | null;
          spaceId: any;
        }>;
      };
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    }>;
  };
};

export type FileAssociationsFragment = {
  __typename: "Association";
  castByFromCastAndOrgId: {
    __typename: "Cast";
    id: any;
    screensByCastId: {
      __typename: "ScreensConnection";
      nodes: Array<{ __typename: "Screen"; id: any; name: string }>;
    };
  } | null;
  channelByFromChannelAndOrgId: {
    __typename: "Channel";
    id: any;
    name: string;
    draft: { __typename: "Channel"; id: any; name: string } | null;
  } | null;
  playlistByFromPlaylistAndOrgId: {
    __typename: "Playlist";
    id: any;
    name: string;
    draft: { __typename: "Playlist"; id: any; name: string } | null;
  } | null;
  appInstanceByFromAppInstanceAndOrgId: {
    __typename: "AppInstance";
    id: any;
    name: string | null;
  } | null;
  screenByFromScreenAndOrgId: {
    __typename: "Screen";
    id: any;
    name: string;
  } | null;
};

export type PlaylistByIdForListingFragment = {
  __typename: "Playlist";
  id: any;
  tags: Array<string | null>;
  content: any | null;
  isPublished: boolean | null;
  appInstancesByPlaylistId: {
    __typename: "AppInstancesConnection";
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      name: string | null;
      config: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      appByAppId: { __typename: "App"; id: any; iconUrl: string | null } | null;
    }>;
  };
  filesByPlaylistId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      name: string;
      metadata: any;
      mimetype: string | null;
      source: string | null;
      expireAt: any | null;
      fileProcessingStatus: JobStatus | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    }>;
  };
  linksByPlaylistId: {
    __typename: "LinksConnection";
    nodes: Array<{
      __typename: "Link";
      id: any;
      name: string;
      fileByFileId: {
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
    }>;
  };
  sitesByPlaylistId: {
    __typename: "SitesConnection";
    nodes: Array<{
      __typename: "Site";
      id: any;
      name: string;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
    }>;
  };
};

export type PlaylistByIdForPlaylistListingFragment = {
  __typename: "Playlist";
  id: any;
  tags: Array<string | null>;
  content: any | null;
  isPublished: boolean | null;
  appInstancesByPlaylistId: {
    __typename: "AppInstancesConnection";
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      name: string | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      appByAppId: { __typename: "App"; id: any; iconUrl: string | null } | null;
    }>;
  };
  filesByPlaylistId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      name: string;
      metadata: any;
      mimetype: string | null;
      source: string | null;
      expireAt: any | null;
      fileProcessingStatus: JobStatus | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    }>;
  };
  linksByPlaylistId: {
    __typename: "LinksConnection";
    nodes: Array<{
      __typename: "Link";
      id: any;
      name: string;
      fileByFileId: {
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
    }>;
  };
  sitesByPlaylistId: {
    __typename: "SitesConnection";
    nodes: Array<{
      __typename: "Site";
      id: any;
      name: string;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
    }>;
  };
};

export type PlaylistDetailFragment = {
  __typename: "Playlist";
  id: any;
  name: string;
  content: any | null;
  draft: {
    __typename: "Playlist";
    id: any;
    isPublished: boolean | null;
  } | null;
  filesByPlaylistId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      name: string;
      mimetype: string | null;
      source: string | null;
      metadata: any;
      fileProcessingStatus: JobStatus | null;
      availableAt: any | null;
      expireAt: any | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    }>;
  };
  appInstancesByPlaylistId: {
    __typename: "AppInstancesConnection";
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          name: string;
          mimetype: string | null;
          source: string | null;
          metadata: any;
          fileProcessingStatus: JobStatus | null;
          availableAt: any | null;
          expireAt: any | null;
        }>;
      };
      appInstallByAppInstallId: {
        __typename: "AppInstall";
        appId: any;
        id: any;
        orgId: any;
      } | null;
      appVersionByAppInstanceId: {
        __typename: "AppVersion";
        id: any;
        version: string | null;
        manifestJson: any | null;
        viewerUrl: string | null;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      } | null;
    }>;
  };
  linksByPlaylistId: {
    __typename: "LinksConnection";
    nodes: Array<{
      __typename: "Link";
      id: any;
      name: string;
      url: string;
      linkType: LinkType;
      cloudConfig: any | null;
    }>;
  };
  sitesByPlaylistId: {
    __typename: "SitesConnection";
    nodes: Array<{
      __typename: "Site";
      id: any;
      name: string;
      type: SiteType;
      url: string;
      config: any | null;
      compute: any | null;
      actions: any | null;
    }>;
  };
};

export type PlaylistListItemFragment = {
  __typename: "Playlist";
  id: any;
  isFavorite: boolean | null;
  isSharedAll: boolean | null;
  isSharedExplicit: boolean | null;
  isEmbedEnabled: boolean | null;
  isEmbedPublic: boolean | null;
  name: string;
  color: string;
  content: any | null;
  spaceId: any;
  tags: Array<string | null>;
  embedsByPlaylistId: {
    __typename: "EmbedsConnection";
    nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
  };
  castedScreenByPlaylistId: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      spaceId: any;
      device: any | null;
      deviceModel: string | null;
      deviceId: string;
    }>;
  };
  draft: {
    __typename: "Playlist";
    id: any;
    tags: Array<string | null>;
    content: any | null;
    isPublished: boolean | null;
    appInstancesByPlaylistId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        appByAppId: {
          __typename: "App";
          id: any;
          iconUrl: string | null;
        } | null;
      }>;
    };
    filesByPlaylistId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    linksByPlaylistId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        name: string;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
      }>;
    };
    sitesByPlaylistId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        name: string;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
      }>;
    };
  } | null;
  published: {
    __typename: "Playlist";
    id: any;
    tags: Array<string | null>;
    content: any | null;
    isPublished: boolean | null;
    appInstancesByPlaylistId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        appByAppId: {
          __typename: "App";
          id: any;
          iconUrl: string | null;
        } | null;
      }>;
    };
    filesByPlaylistId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    linksByPlaylistId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        name: string;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
      }>;
    };
    sitesByPlaylistId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        name: string;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
      }>;
    };
  } | null;
  sharedSpacesByPlaylistId: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any; name: string }>;
  };
  associationsByToPlaylistAndOrgId: {
    __typename: "AssociationsConnection";
    nodes: Array<{
      __typename: "Association";
      channelByFromChannelAndOrgId: {
        __typename: "Channel";
        id: any;
        spaceId: any | null;
        draftOf: any | null;
      } | null;
    }>;
  };
};

export type PlaylistByIdDataFragment = {
  __typename: "Playlist";
  id: any;
  tags: Array<string | null>;
  name: string;
  color: string;
  publishedAt: any | null;
  publishedBy: any | null;
  content: any | null;
  isFavorite: boolean | null;
  isPublished: boolean | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  isEmbedEnabled: boolean | null;
  isEmbedPublic: boolean | null;
  spaceId: any;
  createdAt: any | null;
  createdBy: any | null;
  embedsByPlaylistId: {
    __typename: "EmbedsConnection";
    nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
  };
  sharedSpacesByPlaylistId: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any }>;
  };
  castedScreenByPlaylistId: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      castId: any | null;
      name: string;
      device: any | null;
    }>;
  };
  draft: {
    __typename: "Playlist";
    id: any;
    tags: Array<string | null>;
    name: string;
    color: string;
    publishedAt: any | null;
    publishedBy: any | null;
    content: any | null;
    isFavorite: boolean | null;
    isPublished: boolean | null;
    appInstancesByPlaylistId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        config: any | null;
        expireAt: any | null;
      }>;
    };
    filesByPlaylistId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        name: string;
        metadata: any;
        mimetype: string | null;
        source: string | null;
        expireAt: any | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    linksByPlaylistId: {
      __typename: "LinksConnection";
      nodes: Array<{ __typename: "Link"; id: any; name: string }>;
    };
    sitesByPlaylistId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        spaceId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        actions: any | null;
        tags: Array<string | null>;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
      }>;
    };
  } | null;
};

export type ScreenContentHistoryItemFragment = {
  __typename: "ScreenContentHistory";
  id: any;
  content: any | null;
  linkByLinkIdAndOrgId: {
    __typename: "Link";
    id: any;
    orgId: any;
    name: string;
    createdAt: any | null;
    fileByFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      fileProcessingStatus: JobStatus | null;
    } | null;
  } | null;
  siteBySiteIdAndOrgId: {
    __typename: "Site";
    id: any;
    orgId: any;
    name: string;
    createdAt: any | null;
    fileByThumbnailId: {
      __typename: "File";
      id: any;
      orgId: any;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      fileProcessingStatus: JobStatus | null;
    } | null;
  } | null;
  channelByChannelIdAndOrgId: {
    __typename: "Channel";
    id: any;
    name: string;
    spaceId: any | null;
    coverData: any | null;
    coverImageId: any | null;
    createdAt: any | null;
    content: any | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    childOf: any | null;
    fileByCoverImageId: {
      __typename: "File";
      id: any;
      orgId: any;
      name: string;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      fileProcessingStatus: JobStatus | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    } | null;
    draft: {
      __typename: "Channel";
      id: any;
      isPublished: boolean | null;
      content: any | null;
    } | null;
    published: {
      __typename: "Channel";
      id: any;
      isPublished: boolean | null;
      content: any | null;
    } | null;
    parentChannelByChildOf: {
      __typename: "ParentChannelByChildOf";
      id: any | null;
      name: string | null;
      spaceId: any | null;
      spaceName: string | null;
      content: any | null;
    } | null;
  } | null;
  playlistByPlaylistIdAndOrgId: {
    __typename: "Playlist";
    id: any;
    orgId: any;
    spaceId: any;
    name: string;
    color: string;
    createdAt: any | null;
    draft: {
      __typename: "Playlist";
      id: any;
      isPublished: boolean | null;
      content: any | null;
    } | null;
  } | null;
  fileByFileIdAndOrgId: {
    __typename: "File";
    id: any;
    orgId: any;
    name: string;
    metadata: any;
    source: string | null;
    mimetype: string | null;
    createdAt: any | null;
    fileProcessingStatus: JobStatus | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  appInstanceByAppInstanceIdAndOrgId: {
    __typename: "AppInstance";
    id: any;
    orgId: any;
    name: string | null;
    createdAt: any | null;
    fileByThumbnailFileId: {
      __typename: "File";
      id: any;
      name: string;
      mimetype: string | null;
      metadata: any;
      source: string | null;
      fileProcessingStatus: JobStatus | null;
    } | null;
    appVersionByAppInstanceId: {
      __typename: "AppVersion";
      id: any;
      appId: any;
      viewerUrl: string | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
  } | null;
};

export type CastByCastIdFragment = {
  __typename: "Cast";
  id: any;
  content: any | null;
  channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
  fileByCastId: {
    __typename: "File";
    id: any;
    folderId: any | null;
    name: string;
    mimetype: string | null;
    metadata: any;
    source: string | null;
    fileProcessingStatus: JobStatus | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  playlistByCastId: { __typename: "Playlist"; id: any; name: string } | null;
  linkByCastId: {
    __typename: "Link";
    id: any;
    name: string;
    url: string;
    linkType: LinkType;
    cloudConfig: any | null;
    fileByFileId: {
      __typename: "File";
      id: any;
      name: string;
      mimetype: string | null;
    } | null;
  } | null;
  siteByCastId: {
    __typename: "Site";
    id: any;
    name: string;
    type: SiteType;
    url: string;
    config: any | null;
    compute: any | null;
    actions: any | null;
    fileByThumbnailId: {
      __typename: "File";
      id: any;
      name: string;
      mimetype: string | null;
      metadata: any;
      source: string | null;
      fileProcessingStatus: JobStatus | null;
    } | null;
  } | null;
  appInstanceByCastId: {
    __typename: "AppInstance";
    id: any;
    name: string | null;
    config: any | null;
    appVersionByAppInstanceId: {
      __typename: "AppVersion";
      id: any;
      manifestJson: any | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
    appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
  } | null;
};

export type CastByCastIdForScreenDetailFragment = {
  __typename: "Cast";
  id: any;
  content: any | null;
  channelByCastId: {
    __typename: "Channel";
    childOf: any | null;
    id: any;
    orgId: any;
    spaceId: any | null;
    themeId: any | null;
    tags: Array<string | null>;
    name: string;
    publishedAt: any | null;
    publishedBy: any | null;
    coverData: any | null;
    coverImageId: any | null;
    layoutId: any | null;
    hiddenZone: boolean | null;
    content: any | null;
    isPublished: boolean | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isParent: boolean | null;
    playingScreenNum: any | null;
    width: number | null;
    height: number | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    draft: {
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        zones: number;
        config: any | null;
        width: number | null;
        height: number | null;
        isFlexible: boolean | null;
        isScalable: boolean | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      playlistsByChannelId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          color: string;
          content: any | null;
          id: any;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isEmbedEnabled: boolean | null;
          isEmbedPublic: boolean | null;
          name: string;
          spaceId: any;
          tags: Array<string | null>;
          draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
        }>;
      };
      filesByChannelId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          expireAt: any | null;
        }>;
      };
      linksByChannelId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByChannelId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          orgId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          actions: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            metadata: any;
            source: string | null;
            mimetype: string | null;
          } | null;
        }>;
      };
      appInstancesByChannelId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          expireAt: any | null;
        }>;
      };
    } | null;
    published: {
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        zones: number;
        config: any | null;
        width: number | null;
        height: number | null;
        isFlexible: boolean | null;
        isScalable: boolean | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      playlistsByChannelId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          color: string;
          content: any | null;
          id: any;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isEmbedEnabled: boolean | null;
          isEmbedPublic: boolean | null;
          name: string;
          spaceId: any;
          tags: Array<string | null>;
          draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
        }>;
      };
      filesByChannelId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          expireAt: any | null;
        }>;
      };
      linksByChannelId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByChannelId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          orgId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          actions: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            metadata: any;
            source: string | null;
            mimetype: string | null;
          } | null;
        }>;
      };
      appInstancesByChannelId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          expireAt: any | null;
        }>;
      };
    } | null;
    layoutByChannel: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    playlistsByChannelId: {
      __typename: "PlaylistsConnection";
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        name: string;
        content: any | null;
        draft: {
          __typename: "Playlist";
          id: any;
          isPublished: boolean | null;
        } | null;
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            mimetype: string | null;
            source: string | null;
            metadata: any;
            fileProcessingStatus: JobStatus | null;
            availableAt: any | null;
            expireAt: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            config: any | null;
            state: any | null;
            version: string | null;
            name: string | null;
            filesByAppInstanceId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                name: string;
                mimetype: string | null;
                source: string | null;
                metadata: any;
                fileProcessingStatus: JobStatus | null;
                availableAt: any | null;
                expireAt: any | null;
              }>;
            };
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              appId: any;
              id: any;
              orgId: any;
            } | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              version: string | null;
              manifestJson: any | null;
              viewerUrl: string | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            compute: any | null;
            actions: any | null;
          }>;
        };
      }>;
    };
    filesByChannelId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        name: string;
        mimetype: string | null;
        source: string | null;
        metadata: any;
        fileProcessingStatus: JobStatus | null;
        availableAt: any | null;
        expireAt: any | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    linksByChannelId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        } | null;
      }>;
    };
    sitesByChannelId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          metadata: any;
          source: string | null;
          mimetype: string | null;
        } | null;
      }>;
    };
    appInstancesByChannelId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            mimetype: string | null;
            source: string | null;
            metadata: any;
            fileProcessingStatus: JobStatus | null;
            availableAt: any | null;
            expireAt: any | null;
          }>;
        };
        appInstallByAppInstallId: {
          __typename: "AppInstall";
          appId: any;
          id: any;
          orgId: any;
        } | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          version: string | null;
          manifestJson: any | null;
          viewerUrl: string | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
      }>;
    };
    parentChannelByChildOf: {
      __typename: "ParentChannelByChildOf";
      id: any | null;
      name: string | null;
      spaceId: any | null;
      spaceName: string | null;
      content: any | null;
    } | null;
  } | null;
  fileByCastId: {
    __typename: "File";
    id: any;
    folderId: any | null;
    name: string;
    mimetype: string | null;
    metadata: any;
    source: string | null;
    fileProcessingStatus: JobStatus | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  playlistByCastId: {
    __typename: "Playlist";
    id: any;
    name: string;
    content: any | null;
    draft: {
      __typename: "Playlist";
      id: any;
      isPublished: boolean | null;
    } | null;
    filesByPlaylistId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        name: string;
        mimetype: string | null;
        source: string | null;
        metadata: any;
        fileProcessingStatus: JobStatus | null;
        availableAt: any | null;
        expireAt: any | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    appInstancesByPlaylistId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            mimetype: string | null;
            source: string | null;
            metadata: any;
            fileProcessingStatus: JobStatus | null;
            availableAt: any | null;
            expireAt: any | null;
          }>;
        };
        appInstallByAppInstallId: {
          __typename: "AppInstall";
          appId: any;
          id: any;
          orgId: any;
        } | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          version: string | null;
          manifestJson: any | null;
          viewerUrl: string | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
      }>;
    };
    linksByPlaylistId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
      }>;
    };
    sitesByPlaylistId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
      }>;
    };
  } | null;
  linkByCastId: {
    __typename: "Link";
    id: any;
    name: string;
    url: string;
    linkType: LinkType;
    cloudConfig: any | null;
    fileByFileId: {
      __typename: "File";
      id: any;
      name: string;
      mimetype: string | null;
    } | null;
  } | null;
  siteByCastId: {
    __typename: "Site";
    id: any;
    name: string;
    type: SiteType;
    url: string;
    config: any | null;
    compute: any | null;
    actions: any | null;
    fileByThumbnailId: {
      __typename: "File";
      id: any;
      name: string;
      mimetype: string | null;
      metadata: any;
      source: string | null;
      fileProcessingStatus: JobStatus | null;
    } | null;
  } | null;
  appInstanceByCastId: {
    __typename: "AppInstance";
    id: any;
    name: string | null;
    config: any | null;
    appVersionByAppInstanceId: {
      __typename: "AppVersion";
      id: any;
      manifestJson: any | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
    appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
  } | null;
};

export type ChannelDetailForScreenFragment = {
  __typename: "Channel";
  childOf: any | null;
  id: any;
  orgId: any;
  spaceId: any | null;
  themeId: any | null;
  tags: Array<string | null>;
  name: string;
  publishedAt: any | null;
  publishedBy: any | null;
  coverData: any | null;
  coverImageId: any | null;
  layoutId: any | null;
  hiddenZone: boolean | null;
  content: any | null;
  isPublished: boolean | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  isParent: boolean | null;
  playingScreenNum: any | null;
  width: number | null;
  height: number | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  draft: {
    __typename: "Channel";
    id: any;
    orgId: any;
    spaceId: any | null;
    themeId: any | null;
    tags: Array<string | null>;
    name: string;
    publishedAt: any | null;
    publishedBy: any | null;
    coverData: any | null;
    coverImageId: any | null;
    layoutId: any | null;
    hiddenZone: boolean | null;
    content: any | null;
    isPublished: boolean | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isParent: boolean | null;
    playingScreenNum: any | null;
    width: number | null;
    height: number | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    layoutByChannel: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    playlistsByChannelId: {
      __typename: "PlaylistsConnection";
      nodes: Array<{
        __typename: "Playlist";
        color: string;
        content: any | null;
        id: any;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        spaceId: any;
        tags: Array<string | null>;
        draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
      }>;
    };
    filesByChannelId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        name: string;
        expireAt: any | null;
      }>;
    };
    linksByChannelId: {
      __typename: "LinksConnection";
      nodes: Array<{ __typename: "Link"; id: any; name: string }>;
    };
    sitesByChannelId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          metadata: any;
          source: string | null;
          mimetype: string | null;
        } | null;
      }>;
    };
    appInstancesByChannelId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        expireAt: any | null;
      }>;
    };
  } | null;
  published: {
    __typename: "Channel";
    id: any;
    orgId: any;
    spaceId: any | null;
    themeId: any | null;
    tags: Array<string | null>;
    name: string;
    publishedAt: any | null;
    publishedBy: any | null;
    coverData: any | null;
    coverImageId: any | null;
    layoutId: any | null;
    hiddenZone: boolean | null;
    content: any | null;
    isPublished: boolean | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isParent: boolean | null;
    playingScreenNum: any | null;
    width: number | null;
    height: number | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    layoutByChannel: {
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    playlistsByChannelId: {
      __typename: "PlaylistsConnection";
      nodes: Array<{
        __typename: "Playlist";
        color: string;
        content: any | null;
        id: any;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        spaceId: any;
        tags: Array<string | null>;
        draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
      }>;
    };
    filesByChannelId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        name: string;
        expireAt: any | null;
      }>;
    };
    linksByChannelId: {
      __typename: "LinksConnection";
      nodes: Array<{ __typename: "Link"; id: any; name: string }>;
    };
    sitesByChannelId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          metadata: any;
          source: string | null;
          mimetype: string | null;
        } | null;
      }>;
    };
    appInstancesByChannelId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        name: string | null;
        expireAt: any | null;
      }>;
    };
  } | null;
  layoutByChannel: {
    __typename: "Layout";
    id: any;
    orgId: any;
    spaceId: any;
    name: string;
    zones: number;
    config: any | null;
    width: number | null;
    height: number | null;
    isFlexible: boolean | null;
    isScalable: boolean | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
  playlistsByChannelId: {
    __typename: "PlaylistsConnection";
    nodes: Array<{
      __typename: "Playlist";
      id: any;
      name: string;
      content: any | null;
      draft: {
        __typename: "Playlist";
        id: any;
        isPublished: boolean | null;
      } | null;
      filesByPlaylistId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          name: string;
          mimetype: string | null;
          source: string | null;
          metadata: any;
          fileProcessingStatus: JobStatus | null;
          availableAt: any | null;
          expireAt: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        }>;
      };
      appInstancesByPlaylistId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          config: any | null;
          state: any | null;
          version: string | null;
          name: string | null;
          filesByAppInstanceId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              orgId: any;
              name: string;
              mimetype: string | null;
              source: string | null;
              metadata: any;
              fileProcessingStatus: JobStatus | null;
              availableAt: any | null;
              expireAt: any | null;
            }>;
          };
          appInstallByAppInstallId: {
            __typename: "AppInstall";
            appId: any;
            id: any;
            orgId: any;
          } | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            version: string | null;
            manifestJson: any | null;
            viewerUrl: string | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
        }>;
      };
      linksByPlaylistId: {
        __typename: "LinksConnection";
        nodes: Array<{
          __typename: "Link";
          id: any;
          name: string;
          url: string;
          linkType: LinkType;
          cloudConfig: any | null;
        }>;
      };
      sitesByPlaylistId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
        }>;
      };
    }>;
  };
  filesByChannelId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      name: string;
      mimetype: string | null;
      source: string | null;
      metadata: any;
      fileProcessingStatus: JobStatus | null;
      availableAt: any | null;
      expireAt: any | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    }>;
  };
  linksByChannelId: {
    __typename: "LinksConnection";
    nodes: Array<{
      __typename: "Link";
      id: any;
      name: string;
      url: string;
      linkType: LinkType;
      cloudConfig: any | null;
      fileByFileId: {
        __typename: "File";
        id: any;
        name: string;
        mimetype: string | null;
      } | null;
    }>;
  };
  sitesByChannelId: {
    __typename: "SitesConnection";
    nodes: Array<{
      __typename: "Site";
      id: any;
      orgId: any;
      name: string;
      type: SiteType;
      url: string;
      config: any | null;
      actions: any | null;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        orgId: any;
        metadata: any;
        source: string | null;
        mimetype: string | null;
      } | null;
    }>;
  };
  appInstancesByChannelId: {
    __typename: "AppInstancesConnection";
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          name: string;
          mimetype: string | null;
          source: string | null;
          metadata: any;
          fileProcessingStatus: JobStatus | null;
          availableAt: any | null;
          expireAt: any | null;
        }>;
      };
      appInstallByAppInstallId: {
        __typename: "AppInstall";
        appId: any;
        id: any;
        orgId: any;
      } | null;
      appVersionByAppInstanceId: {
        __typename: "AppVersion";
        id: any;
        version: string | null;
        manifestJson: any | null;
        viewerUrl: string | null;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      } | null;
    }>;
  };
  parentChannelByChildOf: {
    __typename: "ParentChannelByChildOf";
    id: any | null;
    name: string | null;
    spaceId: any | null;
    spaceName: string | null;
    content: any | null;
  } | null;
};

export type ScreensByScreenGroupIdAndOrgIdFragment = {
  __typename: "Screen";
  id: any;
  orgId: any;
  spaceId: any;
  screenGroupId: any;
  castId: any | null;
  name: string;
  content: any | null;
  status: ScreenStatus;
  rotation: ScreenRotation;
  isConnected: boolean;
  connectedAt: any | null;
  disconnectedAt: any | null;
  device: any | null;
  deviceInfo: any | null;
  deviceProvider: string | null;
  deviceId: string;
  deviceModel: string | null;
  devicePlatform: string | null;
  deviceIpAddress: string | null;
  deviceHostname: string | null;
  playerTimezone: string;
  playerHeight: number | null;
  playerWidth: number | null;
  timezoneOverride: string;
  additionalPlayerSettings: any;
  preview: boolean;
  tags: Array<string | null>;
  env: any;
  preferences: any;
  operatingHours: any | null;
  playbackMode: string;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  isNotificationActive: boolean | null;
  messages: Array<any | null> | null;
  screenGroupByScreenGroupId: {
    __typename: "ScreenGroup";
    id: any;
    name: string;
    isPrimary: boolean;
  } | null;
  castByCastId: {
    __typename: "Cast";
    id: any;
    content: any | null;
    channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
    fileByCastId: {
      __typename: "File";
      id: any;
      folderId: any | null;
      name: string;
      mimetype: string | null;
      metadata: any;
      source: string | null;
      fileProcessingStatus: JobStatus | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    } | null;
    playlistByCastId: { __typename: "Playlist"; id: any; name: string } | null;
    linkByCastId: {
      __typename: "Link";
      id: any;
      name: string;
      url: string;
      linkType: LinkType;
      cloudConfig: any | null;
      fileByFileId: {
        __typename: "File";
        id: any;
        name: string;
        mimetype: string | null;
      } | null;
    } | null;
    siteByCastId: {
      __typename: "Site";
      id: any;
      name: string;
      type: SiteType;
      url: string;
      config: any | null;
      compute: any | null;
      actions: any | null;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        name: string;
        mimetype: string | null;
        metadata: any;
        source: string | null;
        fileProcessingStatus: JobStatus | null;
      } | null;
    } | null;
    appInstanceByCastId: {
      __typename: "AppInstance";
      id: any;
      name: string | null;
      config: any | null;
      appVersionByAppInstanceId: {
        __typename: "AppVersion";
        id: any;
        manifestJson: any | null;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      } | null;
      appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
    } | null;
  } | null;
  linksByScreenId: {
    __typename: "LinksConnection";
    nodes: Array<{ __typename: "Link"; id: any; name: string }>;
  };
  sitesByScreenId: {
    __typename: "SitesConnection";
    nodes: Array<{ __typename: "Site"; id: any; name: string }>;
  };
  channelsByScreenId: {
    __typename: "ChannelsConnection";
    nodes: Array<{
      __typename: "Channel";
      id: any;
      name: string;
      spaceId: any | null;
      content: any | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      childOf: any | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
        content: any | null;
      } | null;
      published: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
        content: any | null;
      } | null;
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    }>;
  };
  filesByScreenId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      name: string;
      mimetype: string | null;
      metadata: any;
      source: string | null;
      fileProcessingStatus: JobStatus | null;
    }>;
  };
  playlistsByScreenId: {
    __typename: "PlaylistsConnection";
    nodes: Array<{
      __typename: "Playlist";
      id: any;
      name: string;
      content: any | null;
      spaceId: any;
      draft: {
        __typename: "Playlist";
        id: any;
        isPublished: boolean | null;
      } | null;
    }>;
  };
  appInstancesByScreenId: {
    __typename: "AppInstancesConnection";
    nodes: Array<{ __typename: "AppInstance"; id: any; name: string | null }>;
  };
  screenNotesByScreenId: {
    __typename: "ScreenNotesConnection";
    nodes: Array<{
      __typename: "ScreenNote";
      id: any;
      note: string | null;
      updatedAt: any | null;
      userByUpdatedBy: {
        __typename: "User";
        givenName: string;
        familyName: string;
      } | null;
    }>;
  };
};

export type FileSidebarFragment = {
  __typename: "File";
  id: any;
  fileProcessingStatus: JobStatus | null;
  folderId: any | null;
  mimetype: string | null;
  name: string;
  spaceId: any | null;
  source: string | null;
  metadata: any;
  expireAt: any | null;
  availableAt: any | null;
  fileOutputsByFileId: {
    __typename: "FileOutputsConnection";
    nodes: Array<{
      __typename: "FileOutput";
      id: any;
      content: any | null;
      metadata: any | null;
      mimetype: string | null;
      url: string | null;
      size: any | null;
    }>;
  };
};

export type FolderSidebarFragment = {
  __typename: "Folder";
  id: any;
  parentId: any | null;
  name: string;
  spaceId: any;
  foldersByParentId: { __typename: "FoldersConnection"; totalCount: number };
  filesByFolderId: {
    __typename: "FilesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "File";
      id: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      source: string | null;
      metadata: any;
      expireAt: any | null;
      availableAt: any | null;
    }>;
  };
};

export type LinkSidebarFragment = {
  __typename: "Link";
  id: any;
  name: string;
  spaceId: any;
  fileByFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
};

export type SiteSidebarFragment = {
  __typename: "Site";
  id: any;
  orgId: any;
  spaceId: any;
  name: string;
  type: SiteType;
  url: string;
  config: any | null;
  compute: any | null;
  actions: any | null;
  isFavorite: boolean | null;
  tags: Array<string | null>;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  fileByThumbnailId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  castedScreenBySiteId: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      spaceId: any;
      device: any | null;
      deviceModel: string | null;
    }>;
  };
  userByCreatedBy: {
    __typename: "User";
    id: any;
    givenName: string;
    familyName: string;
  } | null;
  sharedSpacesBySiteId: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any }>;
  };
};

export type AppInstanceSidebarFragment = {
  __typename: "AppInstance";
  id: any;
  appId: any;
  appInstallId: any;
  spaceId: any;
  state: any | null;
  version: string | null;
  name: string | null;
  config: any | null;
  isTemplate: boolean;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  createdAt: any | null;
  updatedAt: any | null;
  tags: Array<string | null>;
  targetCommonAppInstanceTemplateId: any | null;
  availableAt: any | null;
  expireAt: any | null;
  fileByThumbnailFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    spaceId: any | null;
    folderId: any | null;
    name: string;
    metadata: any;
    source: string | null;
    mimetype: string | null;
    tags: Array<string | null>;
    size: any | null;
    isSharedAll: boolean | null;
    isFavorite: boolean | null;
    isSharedExplicit: boolean | null;
    fileProcessingStatus: JobStatus | null;
    createdAt: any | null;
    expireAt: any | null;
    availableAt: any | null;
    castedScreenByFileId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        device: any | null;
        spaceId: any;
      }>;
    };
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
    userByCreatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
  sharedSpacesByAppInstanceId: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any }>;
  };
  castedScreenByAppInstanceId: {
    __typename: "ScreensConnection";
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      deviceId: string;
      deviceModel: string | null;
      spaceId: any;
    }>;
  };
  appByAppId: {
    __typename: "App";
    id: any;
    name: string;
    iconUrl: string | null;
  } | null;
};

export type SiteFragment = {
  __typename: "Site";
  id: any;
  orgId: any;
  spaceId: any;
  name: string;
  type: SiteType;
  url: string;
  config: any | null;
  compute: any | null;
  actions: any | null;
  isFavorite: boolean | null;
  tags: Array<string | null>;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  fileByThumbnailId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  castedScreenBySiteId: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      spaceId: any;
      device: any | null;
      deviceModel: string | null;
    }>;
  };
  userByCreatedBy: {
    __typename: "User";
    id: any;
    givenName: string;
    familyName: string;
  } | null;
  sharedSpacesBySiteId: {
    __typename: "SpacesConnection";
    nodes: Array<{ __typename: "Space"; id: any }>;
  };
};

export type SiteMutationFieldFragment = {
  __typename: "Site";
  id: any;
  orgId: any;
  spaceId: any;
  url: string;
  type: SiteType;
  config: any | null;
  compute: any | null;
  actions: any | null;
  name: string;
  fileByThumbnailId: {
    __typename: "File";
    id: any;
    orgId: any;
    metadata: any;
    source: string | null;
    mimetype: string | null;
    fileProcessingStatus: JobStatus | null;
  } | null;
};

export type ThemeListItemFragment = {
  __typename: "Theme";
  id: any;
  orgId: any;
  name: string;
  logoFileId: any | null;
  config: any;
  titleFontId: any | null;
  bodyFontId: any | null;
  fileByLogoFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
};

export type OrgFragment = {
  __typename: "Org";
  id: any;
  name: string;
  brandForegroundColor: string;
  brandBackgroundColor: string;
  startChannelId: any | null;
  blankChannelId: any | null;
  brandChannelId: any | null;
  splashChannelThemeId: any | null;
  preferences: any | null;
  defaultChannelThemeId: any | null;
  brandLogoId: any | null;
  brandBackgroundId: any | null;
  billingExpiresAt: any | null;
  isDraft: boolean;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
};

export type SpaceFragment = {
  __typename: "Space";
  env: any;
  id: any;
  orgId: any;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  name: string;
  preferences: any;
  billingCustomerId: string | null;
  billingExpiresAt: any | null;
  rootFolderId: any | null;
};

export type FolderFragment = {
  __typename: "Folder";
  parentId: any | null;
  name: string;
  isFavorite: boolean | null;
  tags: Array<string | null>;
  spaceId: any;
  id: any;
  orgId: any;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  isShared: boolean | null;
  isSharedAll: boolean | null;
};

export type FileOutputFragment = {
  __typename: "FileOutput";
  id: any;
  content: any | null;
  metadata: any | null;
  mimetype: string | null;
  url: string | null;
  size: any | null;
};

export type FileFragment = {
  __typename: "File";
  id: any;
  orgId: any;
  fileProcessingStatus: JobStatus | null;
  folderId: any | null;
  mimetype: string | null;
  name: string;
  spaceId: any | null;
  tags: Array<string | null>;
  size: any | null;
  source: string | null;
  metadata: any;
  isFavorite: boolean | null;
  expireAt: any | null;
  availableAt: any | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
};

export type LinkFragment = {
  __typename: "Link";
  id: any;
  orgId: any;
  url: string;
  createdAt: any | null;
  linkType: LinkType;
  cloudConfig: any | null;
  isFavorite: boolean | null;
  name: string;
  tags: Array<string | null>;
  cacheBusting: boolean | null;
  qrcodeEnabled: boolean | null;
  qrcodeConfig: any | null;
  autoReload: boolean | null;
  autoReloadDurationMs: number | null;
  params: any | null;
  spaceId: any;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  fileByFileId: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
  } | null;
};

export type UserFragment = {
  __typename: "User";
  id: any;
  orgId: any;
  email: string | null;
  status: UserStatus;
  userType: UserType | null;
  givenName: string;
  familyName: string;
  receiveUpdates: ReceiveUpdates;
  isOwner: boolean | null;
  metadata: any | null;
  preferences: any | null;
  permissions: Array<string | null> | null;
  permissionsByGroupIds: any | null;
  permissionsBySpaceIds: any | null;
  deletedAt: any | null;
  deletedBy: any | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  lastAuthenticatedAt: any | null;
};

export type ScreenNoteFragment = {
  __typename: "ScreenNote";
  id: any;
  note: string | null;
  updatedAt: any | null;
  userByUpdatedBy: {
    __typename: "User";
    givenName: string;
    familyName: string;
  } | null;
};

export type ScreenFragment = {
  __typename: "Screen";
  id: any;
  orgId: any;
  screenGroupId: any;
  castId: any | null;
  name: string;
  content: any | null;
  status: ScreenStatus;
  preview: boolean;
  device: any | null;
  deviceInfo: any | null;
  deviceIpAddress: string | null;
  deviceHostname: string | null;
  rotation: ScreenRotation;
  env: any;
  deviceId: string;
  deviceModel: string | null;
  devicePlatform: string | null;
  playerTimezone: string;
  playerHeight: number | null;
  playerWidth: number | null;
  timezoneOverride: string;
  additionalPlayerSettings: any;
  deviceProvider: string | null;
  preferences: any;
  operatingHours: any | null;
  tags: Array<string | null>;
  spaceId: any;
  playbackMode: string;
  videoSource: string;
  highResolutionImage: boolean;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  isConnected: boolean;
  connectedAt: any | null;
  disconnectedAt: any | null;
  isNotificationActive: boolean | null;
  messages: Array<any | null> | null;
  screenNotesByScreenId: {
    __typename: "ScreenNotesConnection";
    nodes: Array<{
      __typename: "ScreenNote";
      id: any;
      note: string | null;
      updatedAt: any | null;
      userByUpdatedBy: {
        __typename: "User";
        givenName: string;
        familyName: string;
      } | null;
    }>;
  };
};

export type AppInstallFragment = {
  __typename: "AppInstall";
  appId: any;
  id: any;
  orgId: any;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  spaceId: any | null;
};

export type AppInstanceFragment = {
  __typename: "AppInstance";
  id: any;
  orgId: any;
  appInstallId: any;
  appId: any;
  config: any | null;
  state: any | null;
  version: string | null;
  name: string | null;
  tags: Array<string | null>;
  isTemplate: boolean;
  isSharedAll: boolean | null;
  isShared: boolean | null;
  isSharedExplicit: boolean | null;
  spaceId: any;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  availableAt: any | null;
  expireAt: any | null;
  filesByAppInstanceId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    }>;
  };
};

export type AppVersionFragment = {
  __typename: "AppVersion";
  id: any;
  appId: any;
  version: string | null;
  betaFlag: boolean | null;
  stableFlag: boolean | null;
  manifestJson: any | null;
  viewerUrl: string | null;
  editorUrl: string | null;
  mediaSandboxAccess: boolean | null;
  configurationType: AppConfiguration | null;
  storeData: any | null;
  createdAt: any | null;
  updatedAt: any | null;
};

export type LayoutFragment = {
  __typename: "Layout";
  id: any;
  orgId: any;
  spaceId: any;
  name: string;
  zones: number;
  config: any | null;
  width: number | null;
  height: number | null;
  isFlexible: boolean | null;
  isScalable: boolean | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
};

export type PlaylistFragment = {
  __typename: "Playlist";
  id: any;
  orgId: any;
  spaceId: any;
  tags: Array<string | null>;
  name: string;
  color: string;
  publishedAt: any | null;
  publishedBy: any | null;
  content: any | null;
  isFavorite: boolean | null;
  isPublished: boolean | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
};

export type ScreenGroupFragment = {
  __typename: "ScreenGroup";
  id: any;
  orgId: any;
  spaceId: any;
  name: string;
  isPrimary: boolean;
  env: any | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
};

export type ChannelFragment = {
  __typename: "Channel";
  id: any;
  orgId: any;
  spaceId: any | null;
  themeId: any | null;
  tags: Array<string | null>;
  name: string;
  publishedAt: any | null;
  publishedBy: any | null;
  coverData: any | null;
  coverImageId: any | null;
  layoutId: any | null;
  hiddenZone: boolean | null;
  content: any | null;
  isPublished: boolean | null;
  isSharedExplicit: boolean | null;
  isSharedAll: boolean | null;
  isParent: boolean | null;
  playingScreenNum: any | null;
  width: number | null;
  height: number | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
};

export type CastFragment = {
  __typename: "Cast";
  id: any;
  orgId: any;
  spaceId: any;
  priority: number | null;
  content: any | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  expiresAt: any | null;
};

export type FavoriteFragment = {
  __typename: "Favorite";
  orgId: any;
  spaceId: any;
  fileId: any | null;
  folderId: any | null;
  playlistId: any | null;
  userId: any;
  linkId: any | null;
  siteId: any | null;
};

export type GroupSpaceFragment = {
  __typename: "GroupSpace";
  orgId: any;
  groupId: any;
  spaceId: any;
  permissions: Array<string | null>;
  permissionSets: Array<any | null>;
};

export type GroupFragment = {
  __typename: "Group";
  id: any;
  orgId: any;
  name: string;
  description: string;
  createdAt: any | null;
  updatedAt: any | null;
  createdBy: any | null;
  updatedBy: any | null;
  permissions: Array<string | null>;
  permissionSets: Array<any | null>;
};

export type UserGroupFragment = {
  __typename: "UserGroup";
  orgId: any;
  userId: any;
  groupId: any;
  createdAt: any | null;
  createdBy: any | null;
  permissions: Array<string | null>;
  permissionSets: Array<any | null>;
};

export type FeatureFlagOrgFragment = {
  __typename: "FeatureFlagOrg";
  key: string;
  orgId: any;
  value: boolean | null;
  customizable: boolean | null;
  customValue: boolean | null;
};

export type FeatureFlagFragment = {
  __typename: "FeatureFlag";
  key: string;
  value: boolean;
  overwriteValue: boolean | null;
  description: string | null;
  defaultCustomizable: boolean;
  tags: Array<FeatureFlagTags | null>;
  visibility: FeatureFlagVisibility;
  createdAt: any | null;
  updatedAt: any | null;
};

export type ThemeFragment = {
  __typename: "Theme";
  id: any;
  orgId: any;
  name: string;
  logoFileId: any | null;
  config: any;
  titleFontId: any | null;
  bodyFontId: any | null;
  createdAt: any | null;
  updatedAt: any | null;
  createdBy: any | null;
  updatedBy: any | null;
};

export type TokenFragment = {
  __typename: "Token";
  id: any;
  orgId: any;
  name: string;
  permissions: Array<string | null>;
  userId: any;
  lastUsed: any | null;
  deactivatedAt: any | null;
  createdAt: any | null;
  createdBy: any | null;
  updatedAt: any | null;
  updatedBy: any | null;
  deletedAt: any | null;
};

export type AppFragment = {
  __typename: "App";
  id: any;
  name: string;
  scrn: string;
  categories: Array<string | null>;
  requirements: Array<string | null>;
  description: string | null;
  defaultInstall: AppDefaultInstallType;
  isInstalled: boolean | null;
  iconUrl: string | null;
  developer: string | null;
  tags: Array<string | null>;
  createdAt: any | null;
  updatedAt: any | null;
};

export type LogFragment = {
  __typename: "Log";
  id: any;
  orgId: any | null;
  objectId: any | null;
  objectType: string | null;
  objectName: string | null;
  action: string | null;
  previousFields: any | null;
  changedFields: any | null;
  clientAddr: any | null;
  clientPort: number | null;
  applicationName: string | null;
  timestamp: any | null;
  sessionUserName: string | null;
  userId: any | null;
  systemUserId: any | null;
  spaceId: any | null;
};

export type LegacyLogFragment = {
  __typename: "LegacyLog";
  id: any;
  orgId: any;
  action: string | null;
  objectId: any | null;
  objectType: string | null;
  extra: any | null;
  actionedBy: any | null;
  actionedAt: any | null;
  clientAddr: any | null;
  clientPort: number | null;
  msg: string | null;
};

export type CreateCastPayloadFragment = {
  __typename: "Cast";
  id: any;
  content: any | null;
  screensByCastId: {
    __typename: "ScreensConnection";
    nodes: Array<{
      __typename: "Screen";
      id: any;
      castId: any | null;
      name: string;
      content: any | null;
      device: any | null;
      spaceId: any;
    }>;
  };
  channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
  fileByCastId: {
    __typename: "File";
    id: any;
    folderId: any | null;
    name: string;
    mimetype: string | null;
    metadata: any;
    source: string | null;
    fileProcessingStatus: JobStatus | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
  playlistByCastId: { __typename: "Playlist"; id: any; name: string } | null;
  linkByCastId: {
    __typename: "Link";
    id: any;
    name: string;
    url: string;
    linkType: LinkType;
    cloudConfig: any | null;
    fileByFileId: {
      __typename: "File";
      id: any;
      name: string;
      mimetype: string | null;
    } | null;
  } | null;
  siteByCastId: {
    __typename: "Site";
    id: any;
    name: string;
    type: SiteType;
    url: string;
    config: any | null;
    compute: any | null;
    actions: any | null;
    fileByThumbnailId: {
      __typename: "File";
      id: any;
      name: string;
      mimetype: string | null;
      metadata: any;
      source: string | null;
      fileProcessingStatus: JobStatus | null;
    } | null;
  } | null;
  appInstanceByCastId: {
    __typename: "AppInstance";
    id: any;
    name: string | null;
    config: any | null;
    appVersionByAppInstanceId: {
      __typename: "AppVersion";
      id: any;
      manifestJson: any | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
    appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
  } | null;
};

export type BillingCustomerFragment = {
  __typename: "BillingCustomer";
  id: string;
  parentId: string | null;
  paymentOwnerId: string | null;
  invoiceOwnerId: string | null;
  company: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  vatNumber: string | null;
  vatNumberStatus: string | null;
  autoCollection: string | null;
  taxability: string | null;
  billingDate: number | null;
  billingDateMode: string | null;
  billingDayOfWeek: string | null;
  billingDayOfWeekMode: string | null;
  preferredCurrencyCode: CurrencyCode | null;
  cfAllowLicence: boolean | null;
  cfPartner: string | null;
  cfInvoicedPayment: boolean | null;
  cfCustomerType: string | null;
  cfAccountManager: string | null;
  createdAt: any | null;
  updatedAt: any | null;
};

export type MigrationCheckQueryVariables = Exact<{ [key: string]: never }>;

export type MigrationCheckQuery = {
  __typename: "Query";
  migrationCheck: any | null;
};

export type CreateOnboardingAppInstanceMutationVariables = Exact<{
  input: CreateAppInstanceInput;
}>;

export type CreateOnboardingAppInstanceMutation = {
  __typename: "Mutation";
  createAppInstance: {
    __typename: "CreateAppInstancePayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      name: string | null;
    } | null;
  } | null;
};

export type CreateOnboardingChannelMutationVariables = Exact<{
  input: CreateChannelInput;
}>;

export type CreateOnboardingChannelMutation = {
  __typename: "Mutation";
  createChannel: {
    __typename: "CreateChannelPayload";
    channel: { __typename: "Channel"; id: any; name: string } | null;
  } | null;
};

export type CreateOnboardingFileMutationVariables = Exact<{
  input: CreateFileInput;
}>;

export type CreateOnboardingFileMutation = {
  __typename: "Mutation";
  createFile: {
    __typename: "CreateFilePayload";
    file: {
      __typename: "File";
      id: any;
      name: string;
      source: string | null;
    } | null;
  } | null;
};

export type CreateOnboardingPlaylistMutationVariables = Exact<{
  input: CreatePlaylistInput;
}>;

export type CreateOnboardingPlaylistMutation = {
  __typename: "Mutation";
  createPlaylist: {
    __typename: "CreatePlaylistPayload";
    playlist: { __typename: "Playlist"; id: any; name: string } | null;
  } | null;
};

export type GetBrandInfoQueryVariables = Exact<{
  domainName: Scalars["String"];
}>;

export type GetBrandInfoQuery = {
  __typename: "Query";
  getBrandInfo: {
    __typename: "GetBrandInfoPayload";
    name: string | null;
    domain: string | null;
    claimed: boolean | null;
    description: string | null;
    images: Array<{
      __typename: "BrandInfoImage";
      type: string | null;
      formats: Array<{
        __typename: "BrandInfoContentFormat";
        src: string | null;
        background: string | null;
        format: string | null;
        height: number | null;
        width: number | null;
        size: number | null;
      } | null> | null;
    } | null> | null;
    fonts: Array<{
      __typename: "BrandInfoFont";
      name: string | null;
      type: string | null;
      origin: string | null;
      originId: string | null;
    } | null> | null;
    colors: Array<{
      __typename: "BrandInfoColor";
      hex: string | null;
      type: string | null;
      brightness: number | null;
    } | null> | null;
    logos: Array<{
      __typename: "BrandInfoLogo";
      type: string | null;
      theme: string | null;
      formats: Array<{
        __typename: "BrandInfoContentFormat";
        src: string | null;
        background: string | null;
        format: string | null;
        height: number | null;
        width: number | null;
        size: number | null;
      } | null> | null;
    } | null> | null;
    links: Array<{
      __typename: "BrandInfoLink";
      name: string | null;
      url: string | null;
    } | null> | null;
  };
};

export type InstallOnboardingAppMutationVariables = Exact<{
  input: InstallSpaceAppInput;
}>;

export type InstallOnboardingAppMutation = {
  __typename: "Mutation";
  installSpaceApp: {
    __typename: "InstallSpaceAppPayload";
    appInstall: {
      __typename: "AppInstall";
      id: any;
      appByAppId: { __typename: "App"; id: any; name: string } | null;
    } | null;
  } | null;
};

export type UpdateOnboardingAppInstanceMutationVariables = Exact<{
  input: UpdateAppInstanceInput;
}>;

export type UpdateOnboardingAppInstanceMutation = {
  __typename: "Mutation";
  updateAppInstance: {
    __typename: "UpdateAppInstancePayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      name: string | null;
    } | null;
  } | null;
};

export type UpdateOnboardingChannelThemeMutationVariables = Exact<{
  input: UpdateChannelThemeInput;
}>;

export type UpdateOnboardingChannelThemeMutation = {
  __typename: "Mutation";
  updateChannelTheme: {
    __typename: "UpdateChannelThemePayload";
    channel: { __typename: "Channel"; id: any; name: string } | null;
  } | null;
};

export type UpdateOnboardingFileByIdMutationVariables = Exact<{
  input: UpdateFileByIdInput;
}>;

export type UpdateOnboardingFileByIdMutation = {
  __typename: "Mutation";
  updateFileById: {
    __typename: "UpdateFileByIdPayload";
    file: {
      __typename: "File";
      id: any;
      name: string;
      source: string | null;
    } | null;
  } | null;
};

export type UpdateOrgOnboardingDataMutationVariables = Exact<{
  input: UpdateOrgOnboardingDataInput;
}>;

export type UpdateOrgOnboardingDataMutation = {
  __typename: "Mutation";
  updateOrgOnboardingData: {
    __typename: "UpdateOrgOnboardingDataPayload";
    org: {
      __typename: "Org";
      id: any;
      name: string;
      preferences: any | null;
    } | null;
  } | null;
};

export type UpdateUserOnboardingDataMutationVariables = Exact<{
  input: UpdateUserOnboardingDataInput;
}>;

export type UpdateUserOnboardingDataMutation = {
  __typename: "Mutation";
  updateUserOnboardingData: {
    __typename: "UpdateUserOnboardingDataPayload";
    user: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      preferences: any | null;
    } | null;
  } | null;
};

export type RefreshScreensByOrgIdMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RefreshScreensByOrgIdMutation = {
  __typename: "Mutation";
  refreshScreensByOrgId: {
    __typename: "RefreshScreensByOrgIdPayload";
    refreshSucceeded: number | null;
    refreshFailed: number | null;
    error: string | null;
  } | null;
};

export type RefreshScreensByScreenIdsMutationVariables = Exact<{
  screenIds: Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>;
}>;

export type RefreshScreensByScreenIdsMutation = {
  __typename: "Mutation";
  refreshScreensByScreenIds: {
    __typename: "RefreshScreensByScreenIdsPayload";
    refreshSucceeded: number | null;
    refreshFailed: number | null;
    error: string | null;
  } | null;
};

export type RefreshScreensBySpaceIdMutationVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type RefreshScreensBySpaceIdMutation = {
  __typename: "Mutation";
  refreshScreensBySpaceId: {
    __typename: "RefreshScreensBySpaceIdPayload";
    refreshSucceeded: number | null;
    refreshFailed: number | null;
    error: string | null;
  } | null;
};

export type RemoveSplashThemeMutationVariables = Exact<{
  input: RemoveSplashThemeInput;
}>;

export type RemoveSplashThemeMutation = {
  __typename: "Mutation";
  removeSplashTheme: {
    __typename: "RemoveSplashThemePayload";
    org: { __typename: "Org"; id: any; name: string } | null;
  } | null;
};

export type ResetWhiteLabelMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ResetWhiteLabelMutation = {
  __typename: "Mutation";
  resetWhiteLabel: {
    __typename: "ResetWhiteLabelPayload";
    clientMutationId: string | null;
  } | null;
};

export type ResetWhiteLabelGlyphMutationVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ResetWhiteLabelGlyphMutation = {
  __typename: "Mutation";
  resetWhiteLabelGlyph: {
    __typename: "ResetWhiteLabelGlyphPayload";
    whiteLabel: {
      __typename: "WhiteLabel";
      id: any;
      glyphFileId: any | null;
    } | null;
  } | null;
};

export type ScreenCastStopMutationVariables = Exact<{
  input: ScreenCastStopInput;
}>;

export type ScreenCastStopMutation = {
  __typename: "Mutation";
  screenCastStop: {
    __typename: "ScreenCastStopPayload";
    screen: {
      __typename: "Screen";
      id: any;
      name: string;
      castId: any | null;
      spaceId: any;
      castByCastId: {
        __typename: "Cast";
        id: any;
        content: any | null;
        channelByCastId: {
          __typename: "Channel";
          id: any;
          name: string;
        } | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          folderId: any | null;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          name: string;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          name: string;
          url: string;
          linkType: LinkType;
          cloudConfig: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
          } | null;
        } | null;
        appInstanceByCastId: {
          __typename: "AppInstance";
          id: any;
          name: string | null;
          config: any | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            manifestJson: any | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
          appInstallByAppInstallId: {
            __typename: "AppInstall";
            id: any;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ScreenNamesByScreenIdsQueryVariables = Exact<{
  screenIds: Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>;
}>;

export type ScreenNamesByScreenIdsQuery = {
  __typename: "Query";
  screenByIds: {
    __typename: "ScreensConnection";
    nodes: Array<{ __typename: "Screen"; id: any; name: string }>;
  } | null;
};

export type SendCommandToScreensByScreenIdsMutationVariables = Exact<{
  input: SendCommandInput;
}>;

export type SendCommandToScreensByScreenIdsMutation = {
  __typename: "Mutation";
  sendCommandToScreensByScreenIds: {
    __typename: "SendCommandToScreensByScreenIdsPayload";
    commandSucceeded: number | null;
    commandFailed: number | null;
    error: string | null;
  } | null;
};

export type TagsByAppIdAndSpaceIdQueryVariables = Exact<{
  appId: InputMaybe<Scalars["UUID"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  isTemplate: InputMaybe<Scalars["Boolean"]>;
  isShared: InputMaybe<Scalars["Boolean"]>;
}>;

export type TagsByAppIdAndSpaceIdQuery = {
  __typename: "Query";
  tagsByAppIdAndSpaceId: Array<string | null> | null;
};

export type GetAllNotificationRulesByScreenIdQueryVariables = Exact<{
  screenId: InputMaybe<UuidFilter>;
}>;

export type GetAllNotificationRulesByScreenIdQuery = {
  __typename: "Query";
  allNotificationRules: {
    __typename: "NotificationRulesConnection";
    nodes: Array<{
      __typename: "NotificationRule";
      id: any;
      screenId: any;
      active: boolean;
      waitTime: number;
      notificationSubscriptionsByNotificationRuleId: {
        __typename: "NotificationSubscriptionsConnection";
        nodes: Array<{
          __typename: "NotificationSubscription";
          id: any;
          channel: string;
          active: boolean;
          userByUserIdAndOrgId: {
            __typename: "User";
            id: any;
            email: string | null;
            givenName: string;
            metadata: any | null;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type CreateNotificationRuleMutationVariables = Exact<{
  input: CreateNotificationRuleInput;
}>;

export type CreateNotificationRuleMutation = {
  __typename: "Mutation";
  createNotificationRule: {
    __typename: "CreateNotificationRulePayload";
    notificationRule: {
      __typename: "NotificationRule";
      id: any;
      active: boolean;
      waitTime: number;
    } | null;
  } | null;
};

export type SubscribeNotificationMutationVariables = Exact<{
  input: SubscribeNotificationInput;
}>;

export type SubscribeNotificationMutation = {
  __typename: "Mutation";
  subscribeNotification: {
    __typename: "SubscribeNotificationPayload";
    notificationRule: {
      __typename: "NotificationRule";
      id: any;
      active: boolean;
      notificationSubscriptionsByNotificationRuleId: {
        __typename: "NotificationSubscriptionsConnection";
        nodes: Array<{
          __typename: "NotificationSubscription";
          id: any;
          channel: string;
          active: boolean;
          userByUserIdAndOrgId: { __typename: "User"; id: any } | null;
        }>;
      };
    } | null;
  } | null;
};

export type CreateDuplicateJobMutationVariables = Exact<{
  input: CreateDuplicateJobInput;
}>;

export type CreateDuplicateJobMutation = {
  __typename: "Mutation";
  createDuplicateJob: {
    __typename: "CreateDuplicateJobPayload";
    id: string | null;
    success: boolean | null;
  } | null;
};

export type CreateDuplicateJobsMutationVariables = Exact<{
  input: CreateDuplicateJobsInput;
}>;

export type CreateDuplicateJobsMutation = {
  __typename: "Mutation";
  createDuplicateJobs: Array<{
    __typename: "CreateDuplicateJobPayload";
    id: string | null;
    success: boolean | null;
  } | null> | null;
};

export type DeleteDuplicateJobMutationVariables = Exact<{
  input: DeleteDuplicateJobInput;
}>;

export type DeleteDuplicateJobMutation = {
  __typename: "Mutation";
  deleteDuplicateJob: {
    __typename: "DeleteDuplicateJobPayload";
    id: string | null;
    success: boolean | null;
  } | null;
};

export type GetImportJobsQueryVariables = Exact<{
  snapshotType: SnapshotType;
  sourceSpaceId: InputMaybe<Scalars["String"]>;
}>;

export type GetImportJobsQuery = {
  __typename: "Query";
  getImportJobs: Array<{
    __typename: "GetImportJobsPayload";
    jobId: string | null;
    snapshotType: SnapshotType | null;
    snapshotId: string | null;
    newName: string | null;
    targetOrgId: string | null;
    targetSpaceId: string | null;
    targetContentId: string | null;
    isPublicSnapshot: boolean | null;
    userId: string | null;
    status: DuplicateJobStatus | null;
    createdAt: any | null;
    updatedAt: any | null;
  } | null> | null;
};

export type DeleteFileByIdMutationVariables = Exact<{
  input: DeleteFileByIdInput;
}>;

export type DeleteFileByIdMutation = {
  __typename: "Mutation";
  deleteFileById: {
    __typename: "DeleteFileByIdPayload";
    file: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      castedScreenByFileId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          device: any | null;
          spaceId: any;
        }>;
      };
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteFileByIdsMutationVariables = Exact<{
  input: DeleteFileByIdsInput;
}>;

export type DeleteFileByIdsMutation = {
  __typename: "Mutation";
  deleteFileByIds: {
    __typename: "DeleteFileByIdsPayload";
    files: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      castedScreenByFileId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          device: any | null;
          spaceId: any;
        }>;
      };
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    }> | null;
  } | null;
};

export type DeleteFolderByIdMutationVariables = Exact<{
  input: DeleteFolderByIdInput;
}>;

export type DeleteFolderByIdMutation = {
  __typename: "Mutation";
  deleteFolderById: {
    __typename: "DeleteFolderByIdPayload";
    folder: {
      __typename: "Folder";
      parentId: any | null;
      name: string;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      spaceId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isShared: boolean | null;
      isSharedAll: boolean | null;
    } | null;
  } | null;
};

export type GetAllActiveContentQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type GetAllActiveContentQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    filesBySpaceId: {
      __typename: "FilesConnection";
      nodes: Array<{ __typename: "File"; id: any }>;
    };
    appInstancesBySpaceId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{ __typename: "AppInstance"; id: any }>;
    };
    linksBySpaceId: {
      __typename: "LinksConnection";
      nodes: Array<{ __typename: "Link"; id: any }>;
    };
    sitesBySpaceId: {
      __typename: "SitesConnection";
      nodes: Array<{ __typename: "Site"; id: any }>;
    };
  } | null;
};

export type UnSubGainsightForUserMutationVariables = Exact<{
  identifyId: Scalars["String"];
  globalUnsubscribe: Scalars["Boolean"];
  guidedTours: InputMaybe<Scalars["Boolean"]>;
  onboardingBot: InputMaybe<Scalars["Boolean"]>;
  productUpdates: InputMaybe<Scalars["Boolean"]>;
  surveys: InputMaybe<Scalars["Boolean"]>;
  trackEmailAnalytics: InputMaybe<Scalars["Boolean"]>;
  trackUsage: InputMaybe<Scalars["Boolean"]>;
}>;

export type UnSubGainsightForUserMutation = {
  __typename: "Mutation";
  unSubGainsightForUser: {
    __typename: "UnSubGainsightForUserResponse";
    globalUnsubscribeUpdateStatus: number;
    userPreferencesUpdateStatus: number;
  };
};

export type GetScreenTagsQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type GetScreenTagsQuery = {
  __typename: "Query";
  screenTagsBySpaceId: Array<string | null> | null;
};

export type GetMetricsDownloadUrlQueryVariables = Exact<{
  fileName: InputMaybe<Scalars["String"]>;
}>;

export type GetMetricsDownloadUrlQuery = {
  __typename: "Query";
  downloadQRMetrics: {
    __typename: "DownloadMetricsPayload";
    url: string | null;
  } | null;
};

export type GetQrCodeMetricsQueryVariables = Exact<{
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  metric: Metric;
  pagination: InputMaybe<Scalars["Int"]>;
  token: InputMaybe<Scalars["String"]>;
  executionId: InputMaybe<Scalars["String"]>;
  targetUrl: InputMaybe<Scalars["String"]>;
  secondaryTenantId: InputMaybe<Scalars["String"]>;
  screenId: InputMaybe<Scalars["String"]>;
}>;

export type GetQrCodeMetricsQuery = {
  __typename: "Query";
  getQRMetrics: {
    __typename: "GetMetricsPayload";
    items: Array<any | null> | null;
    nextToken: string | null;
    queryExecutionId: string | null;
    fileName: string | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename: "Mutation";
  updateUser: {
    __typename: "UpdateUserPayload";
    user: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
};

export type UpdateUserInfoMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;

export type UpdateUserInfoMutation = {
  __typename: "Mutation";
  updateUserInfo: {
    __typename: "UpdateUserInfoPayload";
    user: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
};

export type AllTokensQueryVariables = Exact<{ [key: string]: never }>;

export type AllTokensQuery = {
  __typename: "Query";
  allTokens: {
    __typename: "TokensConnection";
    nodes: Array<{
      __typename: "Token";
      id: any;
      orgId: any;
      name: string;
      permissions: Array<string | null>;
      userId: any;
      lastUsed: any | null;
      deactivatedAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      deletedAt: any | null;
      orgByOrgId: {
        __typename: "Org";
        id: any;
        name: string;
        brandForegroundColor: string;
        brandBackgroundColor: string;
        startChannelId: any | null;
        blankChannelId: any | null;
        brandChannelId: any | null;
        splashChannelThemeId: any | null;
        defaultChannelThemeId: any | null;
        brandLogoId: any | null;
        brandBackgroundId: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      userByUserId: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
      userByUpdatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
    }>;
  } | null;
};

export type CreateTokenMutationVariables = Exact<{
  input: CreateTokenInput;
}>;

export type CreateTokenMutation = {
  __typename: "Mutation";
  createToken: {
    __typename: "CreateTokenPayload";
    tokenSecretPayload: {
      __typename: "TokenSecretPayload";
      secret: string | null;
      token: {
        __typename: "Token";
        id: any;
        orgId: any;
        name: string;
        permissions: Array<string | null>;
        userId: any;
        lastUsed: any | null;
        deactivatedAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        deletedAt: any | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteTokenMutationVariables = Exact<{
  input: DeleteTokenInput;
}>;

export type DeleteTokenMutation = {
  __typename: "Mutation";
  deleteToken: {
    __typename: "DeleteTokenPayload";
    token: {
      __typename: "Token";
      id: any;
      orgId: any;
      name: string;
      permissions: Array<string | null>;
      userId: any;
      lastUsed: any | null;
      deactivatedAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      deletedAt: any | null;
    } | null;
  } | null;
};

export type UpdateTokenMutationVariables = Exact<{
  input: UpdateTokenInput;
}>;

export type UpdateTokenMutation = {
  __typename: "Mutation";
  updateToken: {
    __typename: "UpdateTokenPayload";
    token: {
      __typename: "Token";
      id: any;
      orgId: any;
      name: string;
      permissions: Array<string | null>;
      userId: any;
      lastUsed: any | null;
      deactivatedAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      deletedAt: any | null;
    } | null;
    orgByOrgId: { __typename: "Org"; id: any } | null;
    userByUserId: { __typename: "User"; id: any } | null;
    userByCreatedBy: { __typename: "User"; id: any } | null;
    userByUpdatedBy: { __typename: "User"; id: any } | null;
  } | null;
};

export type AllGroupsWithPaginationQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  name: InputMaybe<Scalars["String"]>;
}>;

export type AllGroupsWithPaginationQuery = {
  __typename: "Query";
  allGroups: {
    __typename: "GroupsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Group";
      id: any;
      name: string;
      description: string;
      permissionSets: Array<any | null>;
      isDefault: boolean | null;
      groupSpacesByGroupId: {
        __typename: "GroupSpacesConnection";
        nodes: Array<{
          __typename: "GroupSpace";
          spaceId: any;
          permissionSets: Array<any | null>;
          spaceBySpaceId: { __typename: "Space"; id: any; name: string } | null;
        }>;
      };
    }>;
  } | null;
};

export type AllLogsV2ByObjectTypeCountQueryVariables = Exact<{
  objectType: InputMaybe<Scalars["String"]>;
}>;

export type AllLogsV2ByObjectTypeCountQuery = {
  __typename: "Query";
  allLogsV2ByObjectTypeCount: any | null;
};

export type AllLogsV2ByObjectTypeQueryVariables = Exact<{
  total: InputMaybe<Scalars["Int"]>;
  objectType: InputMaybe<Scalars["String"]>;
  offset: InputMaybe<Scalars["Int"]>;
  expectedItemPerPage: InputMaybe<Scalars["Int"]>;
}>;

export type AllLogsV2ByObjectTypeQuery = {
  __typename: "Query";
  allLogsV2ByObjectType: {
    __typename: "LogsConnection";
    nodes: Array<{
      __typename: "Log";
      id: any;
      orgId: any | null;
      objectId: any | null;
      objectType: string | null;
      objectName: string | null;
      action: string | null;
      previousFields: any | null;
      changedFields: any | null;
      clientAddr: any | null;
      clientPort: number | null;
      applicationName: string | null;
      timestamp: any | null;
      sessionUserName: string | null;
      userId: any | null;
      systemUserId: any | null;
      spaceId: any | null;
    }>;
  } | null;
};

export type AllLogsV2QueryVariables = Exact<{
  offset: InputMaybe<Scalars["Int"]>;
  expectedItemPerPage: InputMaybe<Scalars["Int"]>;
}>;

export type AllLogsV2Query = {
  __typename: "Query";
  allLogsV2: {
    __typename: "LogsConnection";
    nodes: Array<{
      __typename: "Log";
      id: any;
      orgId: any | null;
      objectId: any | null;
      objectType: string | null;
      objectName: string | null;
      action: string | null;
      previousFields: any | null;
      changedFields: any | null;
      clientAddr: any | null;
      clientPort: number | null;
      applicationName: string | null;
      timestamp: any | null;
      sessionUserName: string | null;
      userId: any | null;
      systemUserId: any | null;
      spaceId: any | null;
    }>;
  } | null;
};

export type AllLogsV2CountQueryVariables = Exact<{ [key: string]: never }>;

export type AllLogsV2CountQuery = {
  __typename: "Query";
  allLogsV2Count: any | null;
};

export type CreateStudioOrgConnectionMutationVariables = Exact<{
  input: CreateOrgConnectionInput;
}>;

export type CreateStudioOrgConnectionMutation = {
  __typename: "Mutation";
  createOrgConnection: {
    __typename: "CreateOrgConnectionPayload";
    orgConnection: {
      __typename: "OrgConnection";
      id: any;
      orgId: any;
      automaticEnroll: boolean | null;
      connection: string;
      provider: string;
    };
  } | null;
};

export type SsoCreateConnectionMutationVariables = Exact<{
  input: SsoCreateConnectionInput;
}>;

export type SsoCreateConnectionMutation = {
  __typename: "Mutation";
  ssoCreateConnection: {
    __typename: "SsoCreateConnectionPayload";
    connectionName: string;
    cert: string;
    certProvider: string;
    signInURL: string;
    signRequest: boolean;
    signRequestAlgorithm: string;
    signRequestAlgorithmDigest: string;
    protocolBinding: string;
    debugMode: boolean;
    idpDomain: string | null;
    signOutURL: string | null;
    userIDMapping: string | null;
    nameMapping: string | null;
    emailMapping: string | null;
    replyUrl: string;
    audienceUrl: string;
    signOnUrl: string;
    orgConnectionId: any;
    enableUserEnroll: boolean;
    forceSSOOnly: boolean | null;
    orgSlug: string;
  } | null;
};

export type CurrentOrgDetailQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentOrgDetailQuery = {
  __typename: "Query";
  currentOrg: {
    __typename: "Org";
    id: any;
    name: string;
    brandForegroundColor: string;
    brandBackgroundColor: string;
    startChannelId: any | null;
    blankChannelId: any | null;
    brandChannelId: any | null;
    splashChannelThemeId: any | null;
    preferences: any | null;
    defaultChannelThemeId: any | null;
    brandLogoId: any | null;
    brandBackgroundId: any | null;
    billingExpiresAt: any | null;
    isDraft: boolean;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    featureFlagOrgsByOrgId: {
      __typename: "FeatureFlagOrgsConnection";
      nodes: Array<{
        __typename: "FeatureFlagOrg";
        key: string;
        orgId: any;
        value: boolean | null;
        customizable: boolean | null;
        customValue: boolean | null;
      }>;
    };
    channelByStartChannelId: {
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      orgByOrgId: {
        __typename: "Org";
        id: any;
        brandChannelId: any | null;
      } | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        zones: number;
        config: any | null;
        width: number | null;
        height: number | null;
        isFlexible: boolean | null;
        isScalable: boolean | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      playlistsByChannelId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          id: any;
          orgId: any;
          spaceId: any;
          tags: Array<string | null>;
          name: string;
          color: string;
          publishedAt: any | null;
          publishedBy: any | null;
          content: any | null;
          isFavorite: boolean | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          draft: {
            __typename: "Playlist";
            id: any;
            orgId: any;
            spaceId: any;
            tags: Array<string | null>;
            name: string;
            color: string;
            publishedAt: any | null;
            publishedBy: any | null;
            content: any | null;
            isFavorite: boolean | null;
            isPublished: boolean | null;
            isSharedExplicit: boolean | null;
            isSharedAll: boolean | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            filesByPlaylistId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                fileProcessingStatus: JobStatus | null;
                folderId: any | null;
                mimetype: string | null;
                name: string;
                spaceId: any | null;
                tags: Array<string | null>;
                size: any | null;
                source: string | null;
                metadata: any;
                isFavorite: boolean | null;
                expireAt: any | null;
                availableAt: any | null;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              }>;
            };
            appInstancesByPlaylistId: {
              __typename: "AppInstancesConnection";
              nodes: Array<{
                __typename: "AppInstance";
                id: any;
                orgId: any;
                appInstallId: any;
                appId: any;
                config: any | null;
                state: any | null;
                version: string | null;
                name: string | null;
                tags: Array<string | null>;
                isTemplate: boolean;
                isSharedAll: boolean | null;
                isShared: boolean | null;
                isSharedExplicit: boolean | null;
                spaceId: any;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                availableAt: any | null;
                expireAt: any | null;
                appInstallByAppInstallId: {
                  __typename: "AppInstall";
                  appId: any;
                  id: any;
                  orgId: any;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  spaceId: any | null;
                } | null;
                appVersionByAppInstanceId: {
                  __typename: "AppVersion";
                  id: any;
                  appId: any;
                  version: string | null;
                  betaFlag: boolean | null;
                  stableFlag: boolean | null;
                  manifestJson: any | null;
                  viewerUrl: string | null;
                  editorUrl: string | null;
                  mediaSandboxAccess: boolean | null;
                  configurationType: AppConfiguration | null;
                  storeData: any | null;
                  createdAt: any | null;
                  updatedAt: any | null;
                  appByAppId: {
                    __typename: "App";
                    id: any;
                    name: string;
                    iconUrl: string | null;
                  } | null;
                } | null;
                filesByAppInstanceId: {
                  __typename: "FilesConnection";
                  nodes: Array<{
                    __typename: "File";
                    id: any;
                    orgId: any;
                    fileProcessingStatus: JobStatus | null;
                    folderId: any | null;
                    mimetype: string | null;
                    name: string;
                    spaceId: any | null;
                    tags: Array<string | null>;
                    size: any | null;
                    source: string | null;
                    metadata: any;
                    isFavorite: boolean | null;
                    expireAt: any | null;
                    availableAt: any | null;
                    createdAt: any | null;
                    createdBy: any | null;
                    updatedAt: any | null;
                    updatedBy: any | null;
                  }>;
                };
              }>;
            };
            linksByPlaylistId: {
              __typename: "LinksConnection";
              nodes: Array<{
                __typename: "Link";
                id: any;
                orgId: any;
                url: string;
                createdAt: any | null;
                linkType: LinkType;
                cloudConfig: any | null;
                isFavorite: boolean | null;
                name: string;
                tags: Array<string | null>;
                cacheBusting: boolean | null;
                qrcodeEnabled: boolean | null;
                qrcodeConfig: any | null;
                autoReload: boolean | null;
                autoReloadDurationMs: number | null;
                params: any | null;
                spaceId: any;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                fileByFileId: {
                  __typename: "File";
                  id: any;
                  orgId: any;
                  fileProcessingStatus: JobStatus | null;
                  folderId: any | null;
                  mimetype: string | null;
                  name: string;
                  spaceId: any | null;
                  tags: Array<string | null>;
                  size: any | null;
                  source: string | null;
                  metadata: any;
                  isFavorite: boolean | null;
                  expireAt: any | null;
                  availableAt: any | null;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                } | null;
              }>;
            };
            sitesByPlaylistId: {
              __typename: "SitesConnection";
              nodes: Array<{
                __typename: "Site";
                id: any;
                orgId: any;
                spaceId: any;
                name: string;
                type: SiteType;
                url: string;
                config: any | null;
                compute: any | null;
                actions: any | null;
                isFavorite: boolean | null;
                tags: Array<string | null>;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                isSharedExplicit: boolean | null;
                isSharedAll: boolean | null;
                fileByThumbnailId: {
                  __typename: "File";
                  id: any;
                  orgId: any;
                  fileProcessingStatus: JobStatus | null;
                  folderId: any | null;
                  mimetype: string | null;
                  name: string;
                  spaceId: any | null;
                  tags: Array<string | null>;
                  size: any | null;
                  source: string | null;
                  metadata: any;
                  isFavorite: boolean | null;
                  expireAt: any | null;
                  availableAt: any | null;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  fileOutputsByFileId: {
                    __typename: "FileOutputsConnection";
                    totalCount: number;
                    nodes: Array<{
                      __typename: "FileOutput";
                      id: any;
                      content: any | null;
                      metadata: any | null;
                      mimetype: string | null;
                      url: string | null;
                      size: any | null;
                    }>;
                  };
                } | null;
                castedScreenBySiteId: {
                  __typename: "ScreensConnection";
                  totalCount: number;
                  nodes: Array<{
                    __typename: "Screen";
                    id: any;
                    name: string;
                    spaceId: any;
                    device: any | null;
                    deviceModel: string | null;
                  }>;
                };
                userByCreatedBy: {
                  __typename: "User";
                  id: any;
                  givenName: string;
                  familyName: string;
                } | null;
                sharedSpacesBySiteId: {
                  __typename: "SpacesConnection";
                  nodes: Array<{ __typename: "Space"; id: any }>;
                };
              }>;
            };
          } | null;
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              orgId: any;
              fileProcessingStatus: JobStatus | null;
              folderId: any | null;
              mimetype: string | null;
              name: string;
              spaceId: any | null;
              tags: Array<string | null>;
              size: any | null;
              source: string | null;
              metadata: any;
              isFavorite: boolean | null;
              expireAt: any | null;
              availableAt: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              orgId: any;
              appInstallId: any;
              appId: any;
              config: any | null;
              state: any | null;
              version: string | null;
              name: string | null;
              tags: Array<string | null>;
              isTemplate: boolean;
              isSharedAll: boolean | null;
              isShared: boolean | null;
              isSharedExplicit: boolean | null;
              spaceId: any;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              availableAt: any | null;
              expireAt: any | null;
              appInstallByAppInstallId: {
                __typename: "AppInstall";
                appId: any;
                id: any;
                orgId: any;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                spaceId: any | null;
              } | null;
              appVersionByAppInstanceId: {
                __typename: "AppVersion";
                id: any;
                appId: any;
                version: string | null;
                betaFlag: boolean | null;
                stableFlag: boolean | null;
                manifestJson: any | null;
                viewerUrl: string | null;
                editorUrl: string | null;
                mediaSandboxAccess: boolean | null;
                configurationType: AppConfiguration | null;
                storeData: any | null;
                createdAt: any | null;
                updatedAt: any | null;
                appByAppId: {
                  __typename: "App";
                  id: any;
                  name: string;
                  iconUrl: string | null;
                } | null;
              } | null;
              filesByAppInstanceId: {
                __typename: "FilesConnection";
                nodes: Array<{
                  __typename: "File";
                  id: any;
                  orgId: any;
                  fileProcessingStatus: JobStatus | null;
                  folderId: any | null;
                  mimetype: string | null;
                  name: string;
                  spaceId: any | null;
                  tags: Array<string | null>;
                  size: any | null;
                  source: string | null;
                  metadata: any;
                  isFavorite: boolean | null;
                  expireAt: any | null;
                  availableAt: any | null;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              orgId: any;
              url: string;
              createdAt: any | null;
              linkType: LinkType;
              cloudConfig: any | null;
              isFavorite: boolean | null;
              name: string;
              tags: Array<string | null>;
              cacheBusting: boolean | null;
              qrcodeEnabled: boolean | null;
              qrcodeConfig: any | null;
              autoReload: boolean | null;
              autoReloadDurationMs: number | null;
              params: any | null;
              spaceId: any;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              fileByFileId: {
                __typename: "File";
                id: any;
                orgId: any;
                fileProcessingStatus: JobStatus | null;
                folderId: any | null;
                mimetype: string | null;
                name: string;
                spaceId: any | null;
                tags: Array<string | null>;
                size: any | null;
                source: string | null;
                metadata: any;
                isFavorite: boolean | null;
                expireAt: any | null;
                availableAt: any | null;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              orgId: any;
              spaceId: any;
              name: string;
              type: SiteType;
              url: string;
              config: any | null;
              compute: any | null;
              actions: any | null;
              isFavorite: boolean | null;
              tags: Array<string | null>;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                orgId: any;
                fileProcessingStatus: JobStatus | null;
                folderId: any | null;
                mimetype: string | null;
                name: string;
                spaceId: any | null;
                tags: Array<string | null>;
                size: any | null;
                source: string | null;
                metadata: any;
                isFavorite: boolean | null;
                expireAt: any | null;
                availableAt: any | null;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  totalCount: number;
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              castedScreenBySiteId: {
                __typename: "ScreensConnection";
                totalCount: number;
                nodes: Array<{
                  __typename: "Screen";
                  id: any;
                  name: string;
                  spaceId: any;
                  device: any | null;
                  deviceModel: string | null;
                }>;
              };
              userByCreatedBy: {
                __typename: "User";
                id: any;
                givenName: string;
                familyName: string;
              } | null;
              sharedSpacesBySiteId: {
                __typename: "SpacesConnection";
                nodes: Array<{ __typename: "Space"; id: any }>;
              };
            }>;
          };
        }>;
      };
      filesByChannelId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        }>;
      };
      linksByChannelId: {
        __typename: "LinksConnection";
        nodes: Array<{
          __typename: "Link";
          id: any;
          orgId: any;
          url: string;
          createdAt: any | null;
          linkType: LinkType;
          cloudConfig: any | null;
          isFavorite: boolean | null;
          name: string;
          tags: Array<string | null>;
          cacheBusting: boolean | null;
          qrcodeEnabled: boolean | null;
          qrcodeConfig: any | null;
          autoReload: boolean | null;
          autoReloadDurationMs: number | null;
          params: any | null;
          spaceId: any;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
        }>;
      };
      sitesByChannelId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          isFavorite: boolean | null;
          tags: Array<string | null>;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          castedScreenBySiteId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              spaceId: any;
              device: any | null;
              deviceModel: string | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            givenName: string;
            familyName: string;
          } | null;
          sharedSpacesBySiteId: {
            __typename: "SpacesConnection";
            nodes: Array<{ __typename: "Space"; id: any }>;
          };
        }>;
      };
      appInstancesByChannelId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          orgId: any;
          appInstallId: any;
          appId: any;
          config: any | null;
          state: any | null;
          version: string | null;
          name: string | null;
          tags: Array<string | null>;
          isTemplate: boolean;
          isSharedAll: boolean | null;
          isShared: boolean | null;
          isSharedExplicit: boolean | null;
          spaceId: any;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          availableAt: any | null;
          expireAt: any | null;
          appInstallByAppInstallId: {
            __typename: "AppInstall";
            appId: any;
            id: any;
            orgId: any;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            spaceId: any | null;
          } | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            appId: any;
            version: string | null;
            betaFlag: boolean | null;
            stableFlag: boolean | null;
            manifestJson: any | null;
            viewerUrl: string | null;
            editorUrl: string | null;
            mediaSandboxAccess: boolean | null;
            configurationType: AppConfiguration | null;
            storeData: any | null;
            createdAt: any | null;
            updatedAt: any | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
          filesByAppInstanceId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              orgId: any;
              fileProcessingStatus: JobStatus | null;
              folderId: any | null;
              mimetype: string | null;
              name: string;
              spaceId: any | null;
              tags: Array<string | null>;
              size: any | null;
              source: string | null;
              metadata: any;
              isFavorite: boolean | null;
              expireAt: any | null;
              availableAt: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
            }>;
          };
        }>;
      };
      userByUpdatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    } | null;
    fileByBrandLogoId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    fileByBrandBackgroundId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type DeleteOrgMutationVariables = Exact<{
  input: DeleteOrgInput;
}>;

export type DeleteOrgMutation = {
  __typename: "Mutation";
  deleteOrg: {
    __typename: "DeleteOrgPayload";
    org: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type SsoEditConnectionMutationVariables = Exact<{
  input: SsoEditConnectionInput;
}>;

export type SsoEditConnectionMutation = {
  __typename: "Mutation";
  ssoEditConnection: {
    __typename: "SsoEditConnectionPayload";
    connectionName: string;
    cert: string;
    certProvider: string;
    signInURL: string;
    signRequest: boolean;
    signRequestAlgorithm: string;
    signRequestAlgorithmDigest: string;
    protocolBinding: string;
    debugMode: boolean;
    idpDomain: string | null;
    signOutURL: string | null;
    userIDMapping: string | null;
    nameMapping: string | null;
    emailMapping: string | null;
    replyUrl: string;
    audienceUrl: string;
    signOnUrl: string;
    orgConnectionId: any;
    enableUserEnroll: boolean;
    forceSSOOnly: boolean | null;
    orgSlug: string;
  } | null;
};

export type SsoGetConnectionDataQueryVariables = Exact<{
  orgConnectionId: Scalars["UUID"];
}>;

export type SsoGetConnectionDataQuery = {
  __typename: "Query";
  ssoGetConnection: {
    __typename: "SsoConnection";
    connectionName: string;
    cert: string | null;
    certProvider: string | null;
    signInURL: string | null;
    signRequest: boolean | null;
    signRequestAlgorithm: string | null;
    signRequestAlgorithmDigest: string | null;
    protocolBinding: string | null;
    debugMode: boolean | null;
    idpDomain: string | null;
    signOutURL: string | null;
    userIDMapping: string | null;
    nameMapping: string | null;
    emailMapping: string | null;
    orgConnectionId: any;
    enableUserEnroll: boolean;
    forceSSOOnly: boolean;
    orgSlug: string | null;
    replyUrl: string;
    audienceUrl: string;
    signOnUrl: string;
  } | null;
};

export type SsoGetLogsQueryVariables = Exact<{
  orgConnectionId: Scalars["UUID"];
}>;

export type SsoGetLogsQuery = {
  __typename: "Query";
  ssoGetLogs: {
    __typename: "SsoLogPayload";
    totalCount: number;
    nodes: Array<{
      __typename: "SsoLog";
      eventStatus: string;
      eventType: string;
      description: string;
      date: string;
      rawData: any;
    } | null>;
  };
};

export type OrgConnectionByOrgIdQueryVariables = Exact<{
  orgId: Scalars["UUID"];
}>;

export type OrgConnectionByOrgIdQuery = {
  __typename: "Query";
  orgConnectionByOrgId: {
    __typename: "OrgConnection";
    id: any;
    orgId: any;
    automaticEnroll: boolean | null;
    connection: string;
    provider: string;
  } | null;
};

export type SetStartChannelMutationVariables = Exact<{
  input: SetStartChannelInput;
}>;

export type SetStartChannelMutation = {
  __typename: "Mutation";
  setStartChannel: {
    __typename: "SetStartChannelPayload";
    org: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      featureFlagOrgsByOrgId: {
        __typename: "FeatureFlagOrgsConnection";
        nodes: Array<{
          __typename: "FeatureFlagOrg";
          key: string;
          orgId: any;
          value: boolean | null;
          customizable: boolean | null;
          customValue: boolean | null;
        }>;
      };
      channelByStartChannelId: {
        __typename: "Channel";
        id: any;
        orgId: any;
        spaceId: any | null;
        themeId: any | null;
        tags: Array<string | null>;
        name: string;
        publishedAt: any | null;
        publishedBy: any | null;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        hiddenZone: boolean | null;
        content: any | null;
        isPublished: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isParent: boolean | null;
        playingScreenNum: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          zones: number;
          config: any | null;
          width: number | null;
          height: number | null;
          isFlexible: boolean | null;
          isScalable: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        playlistsByChannelId: {
          __typename: "PlaylistsConnection";
          nodes: Array<{
            __typename: "Playlist";
            id: any;
            orgId: any;
            spaceId: any;
            tags: Array<string | null>;
            name: string;
            color: string;
            publishedAt: any | null;
            publishedBy: any | null;
            content: any | null;
            isFavorite: boolean | null;
            isPublished: boolean | null;
            isSharedExplicit: boolean | null;
            isSharedAll: boolean | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            draft: {
              __typename: "Playlist";
              id: any;
              orgId: any;
              spaceId: any;
              tags: Array<string | null>;
              name: string;
              color: string;
              publishedAt: any | null;
              publishedBy: any | null;
              content: any | null;
              isFavorite: boolean | null;
              isPublished: boolean | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              filesByPlaylistId: {
                __typename: "FilesConnection";
                nodes: Array<{
                  __typename: "File";
                  id: any;
                  orgId: any;
                  fileProcessingStatus: JobStatus | null;
                  folderId: any | null;
                  mimetype: string | null;
                  name: string;
                  spaceId: any | null;
                  tags: Array<string | null>;
                  size: any | null;
                  source: string | null;
                  metadata: any;
                  isFavorite: boolean | null;
                  expireAt: any | null;
                  availableAt: any | null;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  fileOutputsByFileId: {
                    __typename: "FileOutputsConnection";
                    nodes: Array<{
                      __typename: "FileOutput";
                      id: any;
                      content: any | null;
                      metadata: any | null;
                      mimetype: string | null;
                      url: string | null;
                      size: any | null;
                    }>;
                  };
                }>;
              };
              appInstancesByPlaylistId: {
                __typename: "AppInstancesConnection";
                nodes: Array<{
                  __typename: "AppInstance";
                  id: any;
                  orgId: any;
                  appInstallId: any;
                  appId: any;
                  config: any | null;
                  state: any | null;
                  version: string | null;
                  name: string | null;
                  tags: Array<string | null>;
                  isTemplate: boolean;
                  isSharedAll: boolean | null;
                  isShared: boolean | null;
                  isSharedExplicit: boolean | null;
                  spaceId: any;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  availableAt: any | null;
                  expireAt: any | null;
                  appInstallByAppInstallId: {
                    __typename: "AppInstall";
                    appId: any;
                    id: any;
                    orgId: any;
                    createdAt: any | null;
                    createdBy: any | null;
                    updatedAt: any | null;
                    updatedBy: any | null;
                    spaceId: any | null;
                  } | null;
                  appVersionByAppInstanceId: {
                    __typename: "AppVersion";
                    id: any;
                    appId: any;
                    version: string | null;
                    betaFlag: boolean | null;
                    stableFlag: boolean | null;
                    manifestJson: any | null;
                    viewerUrl: string | null;
                    editorUrl: string | null;
                    mediaSandboxAccess: boolean | null;
                    configurationType: AppConfiguration | null;
                    storeData: any | null;
                    createdAt: any | null;
                    updatedAt: any | null;
                    appByAppId: {
                      __typename: "App";
                      id: any;
                      name: string;
                      iconUrl: string | null;
                    } | null;
                  } | null;
                  filesByAppInstanceId: {
                    __typename: "FilesConnection";
                    nodes: Array<{
                      __typename: "File";
                      id: any;
                      orgId: any;
                      fileProcessingStatus: JobStatus | null;
                      folderId: any | null;
                      mimetype: string | null;
                      name: string;
                      spaceId: any | null;
                      tags: Array<string | null>;
                      size: any | null;
                      source: string | null;
                      metadata: any;
                      isFavorite: boolean | null;
                      expireAt: any | null;
                      availableAt: any | null;
                      createdAt: any | null;
                      createdBy: any | null;
                      updatedAt: any | null;
                      updatedBy: any | null;
                    }>;
                  };
                }>;
              };
              linksByPlaylistId: {
                __typename: "LinksConnection";
                nodes: Array<{
                  __typename: "Link";
                  id: any;
                  orgId: any;
                  url: string;
                  createdAt: any | null;
                  linkType: LinkType;
                  cloudConfig: any | null;
                  isFavorite: boolean | null;
                  name: string;
                  tags: Array<string | null>;
                  cacheBusting: boolean | null;
                  qrcodeEnabled: boolean | null;
                  qrcodeConfig: any | null;
                  autoReload: boolean | null;
                  autoReloadDurationMs: number | null;
                  params: any | null;
                  spaceId: any;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  fileByFileId: {
                    __typename: "File";
                    id: any;
                    orgId: any;
                    fileProcessingStatus: JobStatus | null;
                    folderId: any | null;
                    mimetype: string | null;
                    name: string;
                    spaceId: any | null;
                    tags: Array<string | null>;
                    size: any | null;
                    source: string | null;
                    metadata: any;
                    isFavorite: boolean | null;
                    expireAt: any | null;
                    availableAt: any | null;
                    createdAt: any | null;
                    createdBy: any | null;
                    updatedAt: any | null;
                    updatedBy: any | null;
                  } | null;
                }>;
              };
              sitesByPlaylistId: {
                __typename: "SitesConnection";
                nodes: Array<{
                  __typename: "Site";
                  id: any;
                  orgId: any;
                  spaceId: any;
                  name: string;
                  type: SiteType;
                  url: string;
                  config: any | null;
                  compute: any | null;
                  actions: any | null;
                  isFavorite: boolean | null;
                  tags: Array<string | null>;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  isSharedExplicit: boolean | null;
                  isSharedAll: boolean | null;
                  fileByThumbnailId: {
                    __typename: "File";
                    id: any;
                    orgId: any;
                    fileProcessingStatus: JobStatus | null;
                    folderId: any | null;
                    mimetype: string | null;
                    name: string;
                    spaceId: any | null;
                    tags: Array<string | null>;
                    size: any | null;
                    source: string | null;
                    metadata: any;
                    isFavorite: boolean | null;
                    expireAt: any | null;
                    availableAt: any | null;
                    createdAt: any | null;
                    createdBy: any | null;
                    updatedAt: any | null;
                    updatedBy: any | null;
                    fileOutputsByFileId: {
                      __typename: "FileOutputsConnection";
                      totalCount: number;
                      nodes: Array<{
                        __typename: "FileOutput";
                        id: any;
                        content: any | null;
                        metadata: any | null;
                        mimetype: string | null;
                        url: string | null;
                        size: any | null;
                      }>;
                    };
                  } | null;
                  castedScreenBySiteId: {
                    __typename: "ScreensConnection";
                    totalCount: number;
                    nodes: Array<{
                      __typename: "Screen";
                      id: any;
                      name: string;
                      spaceId: any;
                      device: any | null;
                      deviceModel: string | null;
                    }>;
                  };
                  userByCreatedBy: {
                    __typename: "User";
                    id: any;
                    givenName: string;
                    familyName: string;
                  } | null;
                  sharedSpacesBySiteId: {
                    __typename: "SpacesConnection";
                    nodes: Array<{ __typename: "Space"; id: any }>;
                  };
                }>;
              };
            } | null;
            filesByPlaylistId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                fileProcessingStatus: JobStatus | null;
                folderId: any | null;
                mimetype: string | null;
                name: string;
                spaceId: any | null;
                tags: Array<string | null>;
                size: any | null;
                source: string | null;
                metadata: any;
                isFavorite: boolean | null;
                expireAt: any | null;
                availableAt: any | null;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              }>;
            };
            appInstancesByPlaylistId: {
              __typename: "AppInstancesConnection";
              nodes: Array<{
                __typename: "AppInstance";
                id: any;
                orgId: any;
                appInstallId: any;
                appId: any;
                config: any | null;
                state: any | null;
                version: string | null;
                name: string | null;
                tags: Array<string | null>;
                isTemplate: boolean;
                isSharedAll: boolean | null;
                isShared: boolean | null;
                isSharedExplicit: boolean | null;
                spaceId: any;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                availableAt: any | null;
                expireAt: any | null;
                appInstallByAppInstallId: {
                  __typename: "AppInstall";
                  appId: any;
                  id: any;
                  orgId: any;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  spaceId: any | null;
                } | null;
                appVersionByAppInstanceId: {
                  __typename: "AppVersion";
                  id: any;
                  appId: any;
                  version: string | null;
                  betaFlag: boolean | null;
                  stableFlag: boolean | null;
                  manifestJson: any | null;
                  viewerUrl: string | null;
                  editorUrl: string | null;
                  mediaSandboxAccess: boolean | null;
                  configurationType: AppConfiguration | null;
                  storeData: any | null;
                  createdAt: any | null;
                  updatedAt: any | null;
                  appByAppId: {
                    __typename: "App";
                    id: any;
                    name: string;
                    iconUrl: string | null;
                  } | null;
                } | null;
                filesByAppInstanceId: {
                  __typename: "FilesConnection";
                  nodes: Array<{
                    __typename: "File";
                    id: any;
                    orgId: any;
                    fileProcessingStatus: JobStatus | null;
                    folderId: any | null;
                    mimetype: string | null;
                    name: string;
                    spaceId: any | null;
                    tags: Array<string | null>;
                    size: any | null;
                    source: string | null;
                    metadata: any;
                    isFavorite: boolean | null;
                    expireAt: any | null;
                    availableAt: any | null;
                    createdAt: any | null;
                    createdBy: any | null;
                    updatedAt: any | null;
                    updatedBy: any | null;
                  }>;
                };
              }>;
            };
            linksByPlaylistId: {
              __typename: "LinksConnection";
              nodes: Array<{
                __typename: "Link";
                id: any;
                orgId: any;
                url: string;
                createdAt: any | null;
                linkType: LinkType;
                cloudConfig: any | null;
                isFavorite: boolean | null;
                name: string;
                tags: Array<string | null>;
                cacheBusting: boolean | null;
                qrcodeEnabled: boolean | null;
                qrcodeConfig: any | null;
                autoReload: boolean | null;
                autoReloadDurationMs: number | null;
                params: any | null;
                spaceId: any;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                fileByFileId: {
                  __typename: "File";
                  id: any;
                  orgId: any;
                  fileProcessingStatus: JobStatus | null;
                  folderId: any | null;
                  mimetype: string | null;
                  name: string;
                  spaceId: any | null;
                  tags: Array<string | null>;
                  size: any | null;
                  source: string | null;
                  metadata: any;
                  isFavorite: boolean | null;
                  expireAt: any | null;
                  availableAt: any | null;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                } | null;
              }>;
            };
            sitesByPlaylistId: {
              __typename: "SitesConnection";
              nodes: Array<{
                __typename: "Site";
                id: any;
                orgId: any;
                spaceId: any;
                name: string;
                type: SiteType;
                url: string;
                config: any | null;
                compute: any | null;
                actions: any | null;
                isFavorite: boolean | null;
                tags: Array<string | null>;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
                isSharedExplicit: boolean | null;
                isSharedAll: boolean | null;
                fileByThumbnailId: {
                  __typename: "File";
                  id: any;
                  orgId: any;
                  fileProcessingStatus: JobStatus | null;
                  folderId: any | null;
                  mimetype: string | null;
                  name: string;
                  spaceId: any | null;
                  tags: Array<string | null>;
                  size: any | null;
                  source: string | null;
                  metadata: any;
                  isFavorite: boolean | null;
                  expireAt: any | null;
                  availableAt: any | null;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  fileOutputsByFileId: {
                    __typename: "FileOutputsConnection";
                    totalCount: number;
                    nodes: Array<{
                      __typename: "FileOutput";
                      id: any;
                      content: any | null;
                      metadata: any | null;
                      mimetype: string | null;
                      url: string | null;
                      size: any | null;
                    }>;
                  };
                } | null;
                castedScreenBySiteId: {
                  __typename: "ScreensConnection";
                  totalCount: number;
                  nodes: Array<{
                    __typename: "Screen";
                    id: any;
                    name: string;
                    spaceId: any;
                    device: any | null;
                    deviceModel: string | null;
                  }>;
                };
                userByCreatedBy: {
                  __typename: "User";
                  id: any;
                  givenName: string;
                  familyName: string;
                } | null;
                sharedSpacesBySiteId: {
                  __typename: "SpacesConnection";
                  nodes: Array<{ __typename: "Space"; id: any }>;
                };
              }>;
            };
          }>;
        };
        filesByChannelId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByChannelId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            orgId: any;
            url: string;
            createdAt: any | null;
            linkType: LinkType;
            cloudConfig: any | null;
            isFavorite: boolean | null;
            name: string;
            tags: Array<string | null>;
            cacheBusting: boolean | null;
            qrcodeEnabled: boolean | null;
            qrcodeConfig: any | null;
            autoReload: boolean | null;
            autoReloadDurationMs: number | null;
            params: any | null;
            spaceId: any;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileByFileId: {
              __typename: "File";
              id: any;
              orgId: any;
              fileProcessingStatus: JobStatus | null;
              folderId: any | null;
              mimetype: string | null;
              name: string;
              spaceId: any | null;
              tags: Array<string | null>;
              size: any | null;
              source: string | null;
              metadata: any;
              isFavorite: boolean | null;
              expireAt: any | null;
              availableAt: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
            } | null;
          }>;
        };
        sitesByChannelId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            orgId: any;
            spaceId: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            compute: any | null;
            actions: any | null;
            isFavorite: boolean | null;
            tags: Array<string | null>;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            isSharedExplicit: boolean | null;
            isSharedAll: boolean | null;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              orgId: any;
              fileProcessingStatus: JobStatus | null;
              folderId: any | null;
              mimetype: string | null;
              name: string;
              spaceId: any | null;
              tags: Array<string | null>;
              size: any | null;
              source: string | null;
              metadata: any;
              isFavorite: boolean | null;
              expireAt: any | null;
              availableAt: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                totalCount: number;
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            castedScreenBySiteId: {
              __typename: "ScreensConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "Screen";
                id: any;
                name: string;
                spaceId: any;
                device: any | null;
                deviceModel: string | null;
              }>;
            };
            userByCreatedBy: {
              __typename: "User";
              id: any;
              givenName: string;
              familyName: string;
            } | null;
            sharedSpacesBySiteId: {
              __typename: "SpacesConnection";
              nodes: Array<{ __typename: "Space"; id: any }>;
            };
          }>;
        };
        appInstancesByChannelId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            orgId: any;
            appInstallId: any;
            appId: any;
            config: any | null;
            state: any | null;
            version: string | null;
            name: string | null;
            tags: Array<string | null>;
            isTemplate: boolean;
            isSharedAll: boolean | null;
            isShared: boolean | null;
            isSharedExplicit: boolean | null;
            spaceId: any;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            availableAt: any | null;
            expireAt: any | null;
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              appId: any;
              id: any;
              orgId: any;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              spaceId: any | null;
            } | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              appId: any;
              version: string | null;
              betaFlag: boolean | null;
              stableFlag: boolean | null;
              manifestJson: any | null;
              viewerUrl: string | null;
              editorUrl: string | null;
              mediaSandboxAccess: boolean | null;
              configurationType: AppConfiguration | null;
              storeData: any | null;
              createdAt: any | null;
              updatedAt: any | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
            filesByAppInstanceId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                fileProcessingStatus: JobStatus | null;
                folderId: any | null;
                mimetype: string | null;
                name: string;
                spaceId: any | null;
                tags: Array<string | null>;
                size: any | null;
                source: string | null;
                metadata: any;
                isFavorite: boolean | null;
                expireAt: any | null;
                availableAt: any | null;
                createdAt: any | null;
                createdBy: any | null;
                updatedAt: any | null;
                updatedBy: any | null;
              }>;
            };
          }>;
        };
        userByUpdatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      fileByBrandLogoId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBrandBackgroundId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateOrgMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgMutation = {
  __typename: "Mutation";
  updateOrg: {
    __typename: "UpdateOrgPayload";
    org: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type UpdateOrgBrandingConfigMutationVariables = Exact<{
  input: UpdateOrgBrandingConfigInput;
}>;

export type UpdateOrgBrandingConfigMutation = {
  __typename: "Mutation";
  updateOrgBrandingConfig: {
    __typename: "UpdateOrgBrandingConfigPayload";
    org: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type UpdateOrgSettingsMutationVariables = Exact<{
  input: UpdateOrgSettingsInput;
}>;

export type UpdateOrgSettingsMutation = {
  __typename: "Mutation";
  updateOrgSettings: {
    __typename: "UpdateOrgSettingsPayload";
    org: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type AllActiveUsersQueryVariables = Exact<{
  expectItems?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<UsersOrderBy> | UsersOrderBy>;
}>;

export type AllActiveUsersQuery = {
  __typename: "Query";
  allUsers: {
    __typename: "UsersConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
      userGroupsByUserId: {
        __typename: "UserGroupsConnection";
        nodes: Array<{
          __typename: "UserGroup";
          orgId: any;
          userId: any;
          groupId: any;
          createdAt: any | null;
          createdBy: any | null;
          permissions: Array<string | null>;
          permissionSets: Array<any | null>;
          groupByGroupId: {
            __typename: "Group";
            id: any;
            name: string;
            description: string;
            permissionSets: Array<any | null>;
            isDefault: boolean | null;
            groupSpacesByGroupId: {
              __typename: "GroupSpacesConnection";
              nodes: Array<{
                __typename: "GroupSpace";
                spaceId: any;
                permissionSets: Array<any | null>;
                spaceBySpaceId: {
                  __typename: "Space";
                  id: any;
                  name: string;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type AllOwnersQueryVariables = Exact<{
  offset: InputMaybe<Scalars["Int"]>;
}>;

export type AllOwnersQuery = {
  __typename: "Query";
  allUsers: {
    __typename: "UsersConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
      userGroupsByUserId: {
        __typename: "UserGroupsConnection";
        nodes: Array<{
          __typename: "UserGroup";
          orgId: any;
          userId: any;
          groupId: any;
          createdAt: any | null;
          createdBy: any | null;
          permissions: Array<string | null>;
          permissionSets: Array<any | null>;
          groupByGroupId: {
            __typename: "Group";
            id: any;
            orgId: any;
            name: string;
            description: string;
            createdAt: any | null;
            updatedAt: any | null;
            createdBy: any | null;
            updatedBy: any | null;
            permissions: Array<string | null>;
            permissionSets: Array<any | null>;
            groupSpacesByGroupId: {
              __typename: "GroupSpacesConnection";
              nodes: Array<{
                __typename: "GroupSpace";
                orgId: any;
                groupId: any;
                spaceId: any;
                permissions: Array<string | null>;
                permissionSets: Array<any | null>;
                spaceBySpaceId: {
                  __typename: "Space";
                  env: any;
                  id: any;
                  orgId: any;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  name: string;
                  preferences: any;
                  billingCustomerId: string | null;
                  billingExpiresAt: any | null;
                  rootFolderId: any | null;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type AllPendingUsersQueryVariables = Exact<{
  expectItems?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
}>;

export type AllPendingUsersQuery = {
  __typename: "Query";
  allUsers: {
    __typename: "UsersConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
      userGroupsByUserId: {
        __typename: "UserGroupsConnection";
        nodes: Array<{
          __typename: "UserGroup";
          orgId: any;
          userId: any;
          groupId: any;
          createdAt: any | null;
          createdBy: any | null;
          permissions: Array<string | null>;
          permissionSets: Array<any | null>;
          groupByGroupId: {
            __typename: "Group";
            id: any;
            name: string;
            description: string;
            permissionSets: Array<any | null>;
            isDefault: boolean | null;
            groupSpacesByGroupId: {
              __typename: "GroupSpacesConnection";
              nodes: Array<{
                __typename: "GroupSpace";
                spaceId: any;
                permissionSets: Array<any | null>;
                spaceBySpaceId: {
                  __typename: "Space";
                  id: any;
                  name: string;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type AllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type AllUsersQuery = {
  __typename: "Query";
  allUsers: {
    __typename: "UsersConnection";
    nodes: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    }>;
  } | null;
};

export type AllUsersOrgRoleOwnerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllUsersOrgRoleOwnerQuery = {
  __typename: "Query";
  allUsers: {
    __typename: "UsersConnection";
    nodes: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    }>;
  } | null;
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;

export type CreateGroupMutation = {
  __typename: "Mutation";
  createGroup: {
    __typename: "CreateGroupPayload";
    group: { __typename: "Group"; id: any; name: string } | null;
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename: "Query";
  currentUser: {
    __typename: "User";
    id: any;
    orgId: any;
    email: string | null;
    status: UserStatus;
    userType: UserType | null;
    givenName: string;
    familyName: string;
    receiveUpdates: ReceiveUpdates;
    isOwner: boolean | null;
    metadata: any | null;
    preferences: any | null;
    permissions: Array<string | null> | null;
    permissionsByGroupIds: any | null;
    permissionsBySpaceIds: any | null;
    deletedAt: any | null;
    deletedBy: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    lastAuthenticatedAt: any | null;
    orgByOrgId: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type DeleteGroupMutationVariables = Exact<{
  input: DeleteGroupInput;
}>;

export type DeleteGroupMutation = {
  __typename: "Mutation";
  deleteGroup: {
    __typename: "DeleteGroupPayload";
    group: { __typename: "Group"; id: any; name: string } | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename: "Mutation";
  deleteUser: {
    __typename: "DeleteUserPayload";
    user: {
      __typename: "User";
      id: any;
      email: string | null;
      givenName: string;
      familyName: string;
      deletedAt: any | null;
      deletedBy: any | null;
    } | null;
  } | null;
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename: "Mutation";
  inviteUser: {
    __typename: "InviteUserPayload";
    users: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
      userGroupsByUserId: {
        __typename: "UserGroupsConnection";
        nodes: Array<{
          __typename: "UserGroup";
          orgId: any;
          userId: any;
          groupId: any;
          createdAt: any | null;
          createdBy: any | null;
          permissions: Array<string | null>;
          permissionSets: Array<any | null>;
          groupByGroupId: {
            __typename: "Group";
            id: any;
            orgId: any;
            name: string;
            description: string;
            createdAt: any | null;
            updatedAt: any | null;
            createdBy: any | null;
            updatedBy: any | null;
            permissions: Array<string | null>;
            permissionSets: Array<any | null>;
            groupSpacesByGroupId: {
              __typename: "GroupSpacesConnection";
              nodes: Array<{
                __typename: "GroupSpace";
                orgId: any;
                groupId: any;
                spaceId: any;
                permissions: Array<string | null>;
                permissionSets: Array<any | null>;
                spaceBySpaceId: {
                  __typename: "Space";
                  env: any;
                  id: any;
                  orgId: any;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  name: string;
                  preferences: any;
                  billingCustomerId: string | null;
                  billingExpiresAt: any | null;
                  rootFolderId: any | null;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
    } | null> | null;
  } | null;
};

export type SearchUserQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
}>;

export type SearchUserQuery = {
  __typename: "Query";
  searchUser: {
    __typename: "UsersConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
      userGroupsByUserId: {
        __typename: "UserGroupsConnection";
        nodes: Array<{
          __typename: "UserGroup";
          orgId: any;
          userId: any;
          groupId: any;
          createdAt: any | null;
          createdBy: any | null;
          permissions: Array<string | null>;
          permissionSets: Array<any | null>;
          groupByGroupId: {
            __typename: "Group";
            id: any;
            orgId: any;
            name: string;
            description: string;
            createdAt: any | null;
            updatedAt: any | null;
            createdBy: any | null;
            updatedBy: any | null;
            permissions: Array<string | null>;
            permissionSets: Array<any | null>;
            groupSpacesByGroupId: {
              __typename: "GroupSpacesConnection";
              nodes: Array<{
                __typename: "GroupSpace";
                orgId: any;
                groupId: any;
                spaceId: any;
                permissions: Array<string | null>;
                permissionSets: Array<any | null>;
                spaceBySpaceId: {
                  __typename: "Space";
                  env: any;
                  id: any;
                  orgId: any;
                  createdAt: any | null;
                  createdBy: any | null;
                  updatedAt: any | null;
                  updatedBy: any | null;
                  name: string;
                  preferences: any;
                  billingCustomerId: string | null;
                  billingExpiresAt: any | null;
                  rootFolderId: any | null;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type SetGroupIsDefaultMutationVariables = Exact<{
  input: SetGroupIsDefaultInput;
}>;

export type SetGroupIsDefaultMutation = {
  __typename: "Mutation";
  setGroupIsDefault: {
    __typename: "SetGroupIsDefaultPayload";
    group: {
      __typename: "Group";
      id: any;
      name: string;
      description: string;
      permissionSets: Array<any | null>;
      isDefault: boolean | null;
      groupSpacesByGroupId: {
        __typename: "GroupSpacesConnection";
        nodes: Array<{
          __typename: "GroupSpace";
          spaceId: any;
          permissionSets: Array<any | null>;
          spaceBySpaceId: { __typename: "Space"; id: any; name: string } | null;
        }>;
      };
    } | null;
  } | null;
};

export type SetUserIsOwnerMutationVariables = Exact<{
  input: SetUserIsOwnerInput;
}>;

export type SetUserIsOwnerMutation = {
  __typename: "Mutation";
  setUserIsOwner: {
    __typename: "SetUserIsOwnerPayload";
    user: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
};

export type UpdateUserSettingsMutationVariables = Exact<{
  input: UpdateUserSettingsInput;
}>;

export type UpdateUserSettingsMutation = {
  __typename: "Mutation";
  updateUserSettings: {
    __typename: "UpdateUserSettingsPayload";
    user: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
};

export type AppByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AppByIdQuery = {
  __typename: "Query";
  appById: {
    __typename: "App";
    id: any;
    name: string;
    iconUrl: string | null;
  } | null;
};

export type FilterContentQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
}>;

export type FilterContentQuery = {
  __typename: "Query";
  searchFile: {
    __typename: "FilesConnection";
    nodes: Array<{ __typename: "File"; id: any; name: string }>;
  } | null;
  searchPlaylist: {
    __typename: "PlaylistsConnection";
    nodes: Array<{ __typename: "Playlist"; id: any; name: string }>;
  } | null;
  searchChannel: {
    __typename: "ChannelsConnection";
    nodes: Array<{ __typename: "Channel"; id: any; name: string }>;
  } | null;
  searchLink: {
    __typename: "LinksConnection";
    nodes: Array<{ __typename: "Link"; id: any; name: string }>;
  } | null;
  searchSite: {
    __typename: "SitesConnection";
    nodes: Array<{ __typename: "Site"; id: any; name: string }>;
  } | null;
  searchAppInstance: {
    __typename: "AppInstancesConnection";
    nodes: Array<{ __typename: "AppInstance"; id: any; name: string | null }>;
  } | null;
};

export type FilterScreenQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<ScreensOrderBy> | ScreensOrderBy>;
}>;

export type FilterScreenQuery = {
  __typename: "Query";
  searchScreen: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerHeight: number | null;
      playerWidth: number | null;
    }>;
  } | null;
};

export type AddGroupSpacePermissionSetsMutationVariables = Exact<{
  input: AddGroupSpacePermissionSetsInput;
}>;

export type AddGroupSpacePermissionSetsMutation = {
  __typename: "Mutation";
  addGroupSpacePermissionSets: {
    __typename: "AddGroupSpacePermissionSetsPayload";
    groupSpace: {
      __typename: "GroupSpace";
      orgId: any;
      groupId: any;
      spaceId: any;
      permissions: Array<string | null>;
      permissionSets: Array<any | null>;
      spaceBySpaceId: { __typename: "Space"; id: any; name: string } | null;
    } | null;
  } | null;
};

export type AllSpaceIdsAndNamesQueryVariables = Exact<{ [key: string]: never }>;

export type AllSpaceIdsAndNamesQuery = {
  __typename: "Query";
  allSpaces: {
    __typename: "SpacesConnection";
    nodes: Array<{
      __typename: "Space";
      id: any;
      name: string;
      preferences: any;
      billingCustomerId: string | null;
      rootFolderId: any | null;
    }>;
  } | null;
};

export type AllSpacesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type AllSpacesQuery = {
  __typename: "Query";
  allSpaces: {
    __typename: "SpacesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Space";
      id: any;
      name: string;
      preferences: any;
      billingCustomerId: string | null;
      rootFolderId: any | null;
      groupSpacesBySpaceId: {
        __typename: "GroupSpacesConnection";
        nodes: Array<{
          __typename: "GroupSpace";
          groupId: any;
          permissionSets: Array<any | null>;
          groupByGroupId: { __typename: "Group"; name: string } | null;
        }>;
      };
      groupsBySpaceId: {
        __typename: "GroupsConnection";
        nodes: Array<{
          __typename: "Group";
          id: any;
          name: string;
          permissionSets: Array<any | null>;
        }>;
      };
    }>;
  } | null;
};

export type AllUserGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AllUserGroupsQuery = {
  __typename: "Query";
  allUserGroups: {
    __typename: "UserGroupsConnection";
    nodes: Array<{ __typename: "UserGroup"; userId: any; groupId: any }>;
  } | null;
};

export type CreateSpaceMutationVariables = Exact<{
  input: CreateSpaceInput;
}>;

export type CreateSpaceMutation = {
  __typename: "Mutation";
  createSpace: {
    __typename: "CreateSpacePayload";
    space: {
      __typename: "Space";
      env: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      name: string;
      preferences: any;
      billingCustomerId: string | null;
      billingExpiresAt: any | null;
      rootFolderId: any | null;
      groupSpacesBySpaceId: {
        __typename: "GroupSpacesConnection";
        nodes: Array<{
          __typename: "GroupSpace";
          groupId: any;
          groupByGroupId: { __typename: "Group"; name: string } | null;
        }>;
      };
    } | null;
  } | null;
};

export type DeleteGroupSpacePermissionSetsMutationVariables = Exact<{
  input: DeleteGroupSpacePermissionSetsInput;
}>;

export type DeleteGroupSpacePermissionSetsMutation = {
  __typename: "Mutation";
  deleteGroupSpacePermissionSets: {
    __typename: "DeleteGroupSpacePermissionSetsPayload";
    groupSpace: {
      __typename: "GroupSpace";
      orgId: any;
      groupId: any;
      spaceId: any;
      permissions: Array<string | null>;
      permissionSets: Array<any | null>;
    } | null;
  } | null;
};

export type DeleteSpaceMutationVariables = Exact<{
  input: DeleteSpaceInput;
}>;

export type DeleteSpaceMutation = {
  __typename: "Mutation";
  deleteSpace: {
    __typename: "DeleteSpacePayload";
    space: {
      __typename: "Space";
      env: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      name: string;
      preferences: any;
      billingCustomerId: string | null;
      billingExpiresAt: any | null;
      rootFolderId: any | null;
      groupSpacesBySpaceId: {
        __typename: "GroupSpacesConnection";
        nodes: Array<{
          __typename: "GroupSpace";
          groupId: any;
          groupByGroupId: { __typename: "Group"; name: string } | null;
        }>;
      };
    } | null;
  } | null;
};

export type SuperUserFragment = {
  __typename: "User";
  id: any;
  givenName: string;
  familyName: string;
  email: string | null;
  status: UserStatus;
  isOwner: boolean | null;
  metadata: any | null;
};

export type OrgOwnerAndAdminsQueryVariables = Exact<{ [key: string]: never }>;

export type OrgOwnerAndAdminsQuery = {
  __typename: "Query";
  owners: {
    __typename: "UsersConnection";
    nodes: Array<{
      __typename: "User";
      id: any;
      givenName: string;
      familyName: string;
      email: string | null;
      status: UserStatus;
      isOwner: boolean | null;
      metadata: any | null;
    }>;
  } | null;
  admins: {
    __typename: "UsersConnection";
    nodes: Array<{
      __typename: "User";
      id: any;
      givenName: string;
      familyName: string;
      email: string | null;
      status: UserStatus;
      isOwner: boolean | null;
      metadata: any | null;
    }>;
  } | null;
};

export type SearchSpacesQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  limit: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
}>;

export type SearchSpacesQuery = {
  __typename: "Query";
  searchSpace: {
    __typename: "SpacesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Space";
      env: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      name: string;
      preferences: any;
      billingCustomerId: string | null;
      billingExpiresAt: any | null;
      rootFolderId: any | null;
      groupSpacesBySpaceId: {
        __typename: "GroupSpacesConnection";
        nodes: Array<{
          __typename: "GroupSpace";
          groupId: any;
          permissionSets: Array<any | null>;
          groupByGroupId: { __typename: "Group"; name: string } | null;
        }>;
      };
      groupsBySpaceId: {
        __typename: "GroupsConnection";
        nodes: Array<{
          __typename: "Group";
          id: any;
          name: string;
          permissionSets: Array<any | null>;
        }>;
      };
    }>;
  } | null;
};

export type SpaceByIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type SpaceByIdQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    env: any;
    id: any;
    orgId: any;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    name: string;
    preferences: any;
    billingCustomerId: string | null;
    billingExpiresAt: any | null;
    rootFolderId: any | null;
    groupSpacesBySpaceId: {
      __typename: "GroupSpacesConnection";
      nodes: Array<{
        __typename: "GroupSpace";
        groupId: any;
        spaceId: any;
        permissionSets: Array<any | null>;
        permissions: Array<string | null>;
        groupByGroupId: {
          __typename: "Group";
          userGroupsByGroupId: {
            __typename: "UserGroupsConnection";
            nodes: Array<{
              __typename: "UserGroup";
              userByUserId: {
                __typename: "User";
                id: any;
                email: string | null;
                status: UserStatus;
              } | null;
            }>;
          };
        } | null;
      }>;
    };
  } | null;
};

export type UpdateGroupSpaceMutationVariables = Exact<{
  input: UpdateGroupSpaceInput;
}>;

export type UpdateGroupSpaceMutation = {
  __typename: "Mutation";
  updateGroupSpace: {
    __typename: "UpdateGroupSpacePayload";
    groupSpace: {
      __typename: "GroupSpace";
      orgId: any;
      groupId: any;
      spaceId: any;
      permissions: Array<string | null>;
      permissionSets: Array<any | null>;
      spaceBySpaceId: {
        __typename: "Space";
        id: any;
        groupSpacesBySpaceId: {
          __typename: "GroupSpacesConnection";
          nodes: Array<{
            __typename: "GroupSpace";
            groupId: any;
            spaceId: any;
            permissionSets: Array<any | null>;
            permissions: Array<string | null>;
          }>;
        };
      } | null;
      groupByGroupId: {
        __typename: "Group";
        id: any;
        permissions: Array<string | null>;
        permissionSets: Array<any | null>;
        groupSpacesByGroupId: {
          __typename: "GroupSpacesConnection";
          nodes: Array<{
            __typename: "GroupSpace";
            groupId: any;
            spaceId: any;
            permissionSets: Array<any | null>;
            permissions: Array<string | null>;
          }>;
        };
      } | null;
    } | null;
  } | null;
};

export type UpdateSpaceMutationVariables = Exact<{
  input: UpdateSpaceInput;
}>;

export type UpdateSpaceMutation = {
  __typename: "Mutation";
  updateSpace: {
    __typename: "UpdateSpacePayload";
    space: {
      __typename: "Space";
      env: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      name: string;
      preferences: any;
      billingCustomerId: string | null;
      billingExpiresAt: any | null;
      rootFolderId: any | null;
    } | null;
  } | null;
};

export type DeleteFontMutationVariables = Exact<{
  input: DeleteFontInput;
}>;

export type DeleteFontMutation = {
  __typename: "Mutation";
  deleteFont: {
    __typename: "DeleteFontPayload";
    font: {
      __typename: "Font";
      id: any;
      orgId: any;
      name: string;
      fontFamily: string;
      regularFileId: any | null;
      boldFileId: any | null;
      italicFileId: any | null;
      boldItalicFileId: any | null;
      thumbnailFileId: any | null;
      cssUrl: string | null;
      createdAt: any | null;
      updatedAt: any | null;
      createdBy: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type FontByIdQueryVariables = Exact<{
  fontId: Scalars["UUID"];
}>;

export type FontByIdQuery = {
  __typename: "Query";
  fontById: {
    __typename: "Font";
    id: any;
    orgId: any;
    name: string;
    fontFamily: string;
    regularFileId: any | null;
    boldFileId: any | null;
    italicFileId: any | null;
    boldItalicFileId: any | null;
    thumbnailFileId: any | null;
    cssUrl: string | null;
    createdAt: any | null;
    updatedAt: any | null;
    createdBy: any | null;
    updatedBy: any | null;
    fileByRegularFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    fileByBoldFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    fileByItalicFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    fileByBoldItalicFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type FontByIdAssociationsQueryVariables = Exact<{
  fontId: Scalars["UUID"];
}>;

export type FontByIdAssociationsQuery = {
  __typename: "Query";
  fontById: {
    __typename: "Font";
    id: any;
    name: string;
    themesByTitleFontId: {
      __typename: "ThemesConnection";
      totalCount: number;
      nodes: Array<{ __typename: "Theme"; id: any; name: string }>;
    };
    themesByBodyFontId: {
      __typename: "ThemesConnection";
      totalCount: number;
      nodes: Array<{ __typename: "Theme"; id: any; name: string }>;
    };
  } | null;
};

export type CustomFontListQueryVariables = Exact<{ [key: string]: never }>;

export type CustomFontListQuery = {
  __typename: "Query";
  allFonts: {
    __typename: "FontsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Font";
      id: any;
      commonFontId: any | null;
      orgId: any;
      name: string;
      fontFamily: string;
      thumbnailFileId: any | null;
      regularFileId: any | null;
      boldFileId: any | null;
      italicFileId: any | null;
      boldItalicFileId: any | null;
      cssUrl: string | null;
      createdAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByRegularFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBoldFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByItalicFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBoldItalicFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type AllFontListQueryVariables = Exact<{ [key: string]: never }>;

export type AllFontListQuery = {
  __typename: "Query";
  allFonts: {
    __typename: "FontsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Font";
      id: any;
      commonFontId: any | null;
      orgId: any;
      name: string;
      fontFamily: string;
      thumbnailFileId: any | null;
      regularFileId: any | null;
      boldFileId: any | null;
      italicFileId: any | null;
      boldItalicFileId: any | null;
      cssUrl: string | null;
      createdAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByRegularFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBoldFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByItalicFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBoldItalicFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type CommonFontListQueryVariables = Exact<{ [key: string]: never }>;

export type CommonFontListQuery = {
  __typename: "Query";
  allCommonFonts: {
    __typename: "CommonFontsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "CommonFont";
      id: any;
      name: string;
      fontFamily: string;
      cssUrl: string | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type UpdateFontMutationVariables = Exact<{
  input: UpdateFontInput;
}>;

export type UpdateFontMutation = {
  __typename: "Mutation";
  updateFont: {
    __typename: "UpdateFontPayload";
    font: {
      __typename: "Font";
      id: any;
      commonFontId: any | null;
      orgId: any;
      name: string;
      fontFamily: string;
      thumbnailFileId: any | null;
      regularFileId: any | null;
      boldFileId: any | null;
      italicFileId: any | null;
      boldItalicFileId: any | null;
      cssUrl: string | null;
      createdAt: any | null;
      fileByRegularFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBoldFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByItalicFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      fileByBoldItalicFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteThemeMutationVariables = Exact<{
  input: DeleteThemeInput;
}>;

export type DeleteThemeMutation = {
  __typename: "Mutation";
  deleteTheme: {
    __typename: "DeleteThemePayload";
    theme: { __typename: "Theme"; id: any; name: string } | null;
  } | null;
};

export type SetOrgDefaultChannelThemeMutationVariables = Exact<{
  input: SetOrgDefaultChannelThemeInput;
}>;

export type SetOrgDefaultChannelThemeMutation = {
  __typename: "Mutation";
  setOrgDefaultChannelTheme: {
    __typename: "SetOrgDefaultChannelThemePayload";
    org: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type SetOrgSplashChannelThemeMutationVariables = Exact<{
  input: SetOrgSplashChannelThemeInput;
}>;

export type SetOrgSplashChannelThemeMutation = {
  __typename: "Mutation";
  setOrgSplashChannelTheme: {
    __typename: "SetOrgSplashChannelThemePayload";
    org: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type ThemeListQueryVariables = Exact<{ [key: string]: never }>;

export type ThemeListQuery = {
  __typename: "Query";
  allThemes: {
    __typename: "ThemesConnection";
    nodes: Array<{
      __typename: "Theme";
      id: any;
      orgId: any;
      name: string;
      logoFileId: any | null;
      config: any;
      titleFontId: any | null;
      bodyFontId: any | null;
      fileByLogoFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    }>;
  } | null;
};

export type AppInstallByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
  endCursor: InputMaybe<Scalars["Cursor"]>;
  spaceId: Scalars["UUID"];
}>;

export type AppInstallByIdQuery = {
  __typename: "Query";
  appInstallById: {
    __typename: "AppInstall";
    id: any;
    appByAppId: {
      __typename: "App";
      id: any;
      name: string;
      iconUrl: string | null;
      defaultInstall: AppDefaultInstallType;
    } | null;
    allAppInstancesByAppInstallIdAndSpaceId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        spaceId: any;
        name: string | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        config: any | null;
        availableAt: any | null;
        expireAt: any | null;
        sharedSpacesByAppInstanceId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        castedScreenByAppInstanceId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
          }>;
        };
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          version: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
    stableAppVersionByAppInstallId: {
      __typename: "AppVersion";
      id: any;
      configurationType: AppConfiguration | null;
      editorUrl: string | null;
      manifestJson: any | null;
      viewerUrl: string | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
  } | null;
};

export type AppInstanceByIdForContentQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AppInstanceByIdForContentQuery = {
  __typename: "Query";
  appInstanceById: {
    __typename: "AppInstance";
    id: any;
    orgId: any;
    config: any | null;
    name: string | null;
    availableAt: any | null;
    expireAt: any | null;
    fileByThumbnailFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    appByAppId: {
      __typename: "App";
      id: any;
      name: string;
      iconUrl: string | null;
    } | null;
  } | null;
};

export type CreateAppInstanceMutationVariables = Exact<{
  input: CreateAppInstanceInput;
}>;

export type CreateAppInstanceMutation = {
  __typename: "Mutation";
  createAppInstance: {
    __typename: "CreateAppInstancePayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteAppInstanceMutationVariables = Exact<{
  input: DeleteAppInstanceInput;
}>;

export type DeleteAppInstanceMutation = {
  __typename: "Mutation";
  deleteAppInstance: {
    __typename: "DeleteAppInstancePayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      name: string | null;
      appId: any;
    } | null;
  } | null;
};

export type UpdateShareAppInstanceToAllSpacesMutationVariables = Exact<{
  input: UpdateShareAppInstanceToAllSpacesInput;
}>;

export type UpdateShareAppInstanceToAllSpacesMutation = {
  __typename: "Mutation";
  updateShareAppInstanceToAllSpaces: {
    __typename: "UpdateShareAppInstanceToAllSpacesPayload";
    appInstance: {
      __typename: "AppInstance";
      isTemplate: boolean;
      targetCommonAppInstanceTemplateId: any | null;
      id: any;
      orgId: any;
      appInstallId: any;
      appId: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      tags: Array<string | null>;
      isSharedAll: boolean | null;
      isShared: boolean | null;
      isSharedExplicit: boolean | null;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          orgId: any;
          screenGroupId: any;
          castId: any | null;
          name: string;
          content: any | null;
          status: ScreenStatus;
          preview: boolean;
          device: any | null;
          deviceInfo: any | null;
          deviceIpAddress: string | null;
          deviceHostname: string | null;
          rotation: ScreenRotation;
          env: any;
          deviceId: string;
          deviceModel: string | null;
          devicePlatform: string | null;
          playerTimezone: string;
          playerHeight: number | null;
          playerWidth: number | null;
          timezoneOverride: string;
          additionalPlayerSettings: any;
          deviceProvider: string | null;
          preferences: any;
          operatingHours: any | null;
          tags: Array<string | null>;
          spaceId: any;
          playbackMode: string;
          videoSource: string;
          highResolutionImage: boolean;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isConnected: boolean;
          connectedAt: any | null;
          disconnectedAt: any | null;
          isNotificationActive: boolean | null;
          messages: Array<any | null> | null;
          screenNotesByScreenId: {
            __typename: "ScreenNotesConnection";
            nodes: Array<{
              __typename: "ScreenNote";
              id: any;
              note: string | null;
              updatedAt: any | null;
              userByUpdatedBy: {
                __typename: "User";
                givenName: string;
                familyName: string;
              } | null;
            }>;
          };
        }>;
      };
      appInstallByAppInstallId: {
        __typename: "AppInstall";
        appId: any;
        id: any;
        orgId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        spaceId: any | null;
      } | null;
      appVersionByAppInstanceId: {
        __typename: "AppVersion";
        id: any;
        appId: any;
        version: string | null;
        betaFlag: boolean | null;
        stableFlag: boolean | null;
        manifestJson: any | null;
        viewerUrl: string | null;
        editorUrl: string | null;
        mediaSandboxAccess: boolean | null;
        configurationType: AppConfiguration | null;
        storeData: any | null;
        createdAt: any | null;
        updatedAt: any | null;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any; name: string }>;
      };
    } | null;
  } | null;
};

export type UpdateShareAppInstanceToSpacesMutationVariables = Exact<{
  input: UpdateShareAppInstanceToSpacesInput;
}>;

export type UpdateShareAppInstanceToSpacesMutation = {
  __typename: "Mutation";
  updateShareAppInstanceToSpaces: {
    __typename: "UpdateShareAppInstanceToSpacesPayload";
    appInstance: {
      __typename: "AppInstance";
      isTemplate: boolean;
      targetCommonAppInstanceTemplateId: any | null;
      id: any;
      orgId: any;
      appInstallId: any;
      appId: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      tags: Array<string | null>;
      isSharedAll: boolean | null;
      isShared: boolean | null;
      isSharedExplicit: boolean | null;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          orgId: any;
          screenGroupId: any;
          castId: any | null;
          name: string;
          content: any | null;
          status: ScreenStatus;
          preview: boolean;
          device: any | null;
          deviceInfo: any | null;
          deviceIpAddress: string | null;
          deviceHostname: string | null;
          rotation: ScreenRotation;
          env: any;
          deviceId: string;
          deviceModel: string | null;
          devicePlatform: string | null;
          playerTimezone: string;
          playerHeight: number | null;
          playerWidth: number | null;
          timezoneOverride: string;
          additionalPlayerSettings: any;
          deviceProvider: string | null;
          preferences: any;
          operatingHours: any | null;
          tags: Array<string | null>;
          spaceId: any;
          playbackMode: string;
          videoSource: string;
          highResolutionImage: boolean;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isConnected: boolean;
          connectedAt: any | null;
          disconnectedAt: any | null;
          isNotificationActive: boolean | null;
          messages: Array<any | null> | null;
          screenNotesByScreenId: {
            __typename: "ScreenNotesConnection";
            nodes: Array<{
              __typename: "ScreenNote";
              id: any;
              note: string | null;
              updatedAt: any | null;
              userByUpdatedBy: {
                __typename: "User";
                givenName: string;
                familyName: string;
              } | null;
            }>;
          };
        }>;
      };
      appInstallByAppInstallId: {
        __typename: "AppInstall";
        appId: any;
        id: any;
        orgId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        spaceId: any | null;
      } | null;
      appVersionByAppInstanceId: {
        __typename: "AppVersion";
        id: any;
        appId: any;
        version: string | null;
        betaFlag: boolean | null;
        stableFlag: boolean | null;
        manifestJson: any | null;
        viewerUrl: string | null;
        editorUrl: string | null;
        mediaSandboxAccess: boolean | null;
        configurationType: AppConfiguration | null;
        storeData: any | null;
        createdAt: any | null;
        updatedAt: any | null;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any; name: string }>;
      };
    } | null;
  } | null;
};

export type UpdateAppInstanceMutationVariables = Exact<{
  input: UpdateAppInstanceInput;
}>;

export type UpdateAppInstanceMutation = {
  __typename: "Mutation";
  updateAppInstance: {
    __typename: "UpdateAppInstancePayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      spaceId: any;
      name: string | null;
      config: any | null;
      version: string | null;
      state: any | null;
      isTemplate: boolean;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateAppInstanceAvailableMutationVariables = Exact<{
  input: UpdateAppInstanceAvailableInput;
}>;

export type UpdateAppInstanceAvailableMutation = {
  __typename: "Mutation";
  updateAppInstanceAvailable: {
    __typename: "UpdateAppInstanceAvailablePayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      orgId: any;
      appInstallId: any;
      appId: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      tags: Array<string | null>;
      isTemplate: boolean;
      isSharedAll: boolean | null;
      isShared: boolean | null;
      isSharedExplicit: boolean | null;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      availableAt: any | null;
      expireAt: any | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
    } | null;
  } | null;
};

export type UpdateAppInstanceAvailableByIdsMutationVariables = Exact<{
  input: UpdateAppInstanceAvailableByIdsInput;
}>;

export type UpdateAppInstanceAvailableByIdsMutation = {
  __typename: "Mutation";
  updateAppInstanceAvailableByIds: {
    __typename: "UpdateAppInstanceAvailableByIdsPayload";
    appInstances: Array<{
      __typename: "AppInstance";
      id: any;
      orgId: any;
      config: any | null;
      name: string | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    }> | null;
  } | null;
};

export type UpdateAppInstanceExpireMutationVariables = Exact<{
  input: UpdateAppInstanceExpireInput;
}>;

export type UpdateAppInstanceExpireMutation = {
  __typename: "Mutation";
  updateAppInstanceExpire: {
    __typename: "UpdateAppInstanceExpirePayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      orgId: any;
      appInstallId: any;
      appId: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      tags: Array<string | null>;
      isTemplate: boolean;
      isSharedAll: boolean | null;
      isShared: boolean | null;
      isSharedExplicit: boolean | null;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      availableAt: any | null;
      expireAt: any | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
    } | null;
  } | null;
};

export type UpdateAppInstanceExpireByIdsMutationVariables = Exact<{
  input: UpdateAppInstanceExpireByIdsInput;
}>;

export type UpdateAppInstanceExpireByIdsMutation = {
  __typename: "Mutation";
  updateAppInstanceExpireByIds: {
    __typename: "UpdateAppInstanceExpireByIdsPayload";
    appInstances: Array<{
      __typename: "AppInstance";
      id: any;
      orgId: any;
      config: any | null;
      name: string | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    }> | null;
  } | null;
};

export type UpdateAppInstanceTagsMutationVariables = Exact<{
  input: UpdateAppInstanceTagsInput;
}>;

export type UpdateAppInstanceTagsMutation = {
  __typename: "Mutation";
  updateAppInstanceTags: {
    __typename: "UpdateAppInstanceTagsPayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      spaceId: any;
      name: string | null;
      config: any | null;
      version: string | null;
      state: any | null;
      isTemplate: boolean;
      tags: Array<string | null>;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
    } | null;
  } | null;
};

export type AllAppsQueryVariables = Exact<{ [key: string]: never }>;

export type AllAppsQuery = {
  __typename: "Query";
  allApps: {
    __typename: "AppsConnection";
    nodes: Array<{
      __typename: "App";
      id: any;
      name: string;
      scrn: string;
      categories: Array<string | null>;
      requirements: Array<string | null>;
      description: string | null;
      defaultInstall: AppDefaultInstallType;
      isInstalled: boolean | null;
      iconUrl: string | null;
      developer: string | null;
      tags: Array<string | null>;
      createdAt: any | null;
      updatedAt: any | null;
      appInstallsByAppId: {
        __typename: "AppInstallsConnection";
        nodes: Array<{
          __typename: "AppInstall";
          appId: any;
          id: any;
          orgId: any;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          spaceId: any | null;
        }>;
      };
      appVersionByAppId: {
        __typename: "AppVersion";
        id: any;
        appId: any;
        version: string | null;
        betaFlag: boolean | null;
        stableFlag: boolean | null;
        manifestJson: any | null;
        viewerUrl: string | null;
        editorUrl: string | null;
        mediaSandboxAccess: boolean | null;
        configurationType: AppConfiguration | null;
        storeData: any | null;
        createdAt: any | null;
        updatedAt: any | null;
      } | null;
    }>;
  } | null;
};

export type AppByScrnQueryVariables = Exact<{
  scrn: Scalars["String"];
}>;

export type AppByScrnQuery = {
  __typename: "Query";
  appByScrn: {
    __typename: "App";
    id: any;
    name: string;
    scrn: string;
    categories: Array<string | null>;
    requirements: Array<string | null>;
    description: string | null;
    defaultInstall: AppDefaultInstallType;
    isInstalled: boolean | null;
    iconUrl: string | null;
    developer: string | null;
    tags: Array<string | null>;
    createdAt: any | null;
    updatedAt: any | null;
    appVersionByAppId: {
      __typename: "AppVersion";
      id: any;
      appId: any;
      version: string | null;
      betaFlag: boolean | null;
      stableFlag: boolean | null;
      manifestJson: any | null;
      viewerUrl: string | null;
      editorUrl: string | null;
      mediaSandboxAccess: boolean | null;
      configurationType: AppConfiguration | null;
      storeData: any | null;
      createdAt: any | null;
      updatedAt: any | null;
    } | null;
  } | null;
};

export type InstallAppForSpaceMutationVariables = Exact<{
  input: InstallAppForSpaceInput;
}>;

export type InstallAppForSpaceMutation = {
  __typename: "Mutation";
  installAppForSpace: {
    __typename: "InstallAppForSpacePayload";
    appInstall: {
      __typename: "AppInstall";
      appId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      spaceId: any | null;
    } | null;
  } | null;
};

export type AllSearchAppInstanceIdsQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  appId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
}>;

export type AllSearchAppInstanceIdsQuery = {
  __typename: "Query";
  searchAppInstance: {
    __typename: "AppInstancesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      spaceId: any;
    }>;
  } | null;
};

export type AvailableAppInstancesBySpaceIdQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]>;
  spaceId: Scalars["UUID"];
  endCursor: InputMaybe<Scalars["Cursor"]>;
  appId: InputMaybe<Scalars["UUID"]>;
}>;

export type AvailableAppInstancesBySpaceIdQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableAppInstancesBySpaceId: {
      __typename: "AppInstancesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        appId: any;
        appInstallId: any;
        spaceId: any;
        state: any | null;
        version: string | null;
        name: string | null;
        config: any | null;
        isTemplate: boolean;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        createdAt: any | null;
        updatedAt: any | null;
        tags: Array<string | null>;
        targetCommonAppInstanceTemplateId: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        sharedSpacesByAppInstanceId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
        castedScreenByAppInstanceId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
            spaceId: any;
          }>;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AvailableAppInstallsQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type AvailableAppInstallsQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableAppInstallsBySpaceId: {
      __typename: "AppInstallsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstall";
        id: any;
        spaceId: any | null;
        availableAppInstancesByAppInstallIdAndSpace: {
          __typename: "AppInstancesConnection";
          totalCount: number;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
          categories: Array<string | null>;
          defaultInstall: AppDefaultInstallType;
          appVersionByAppId: {
            __typename: "AppVersion";
            storeData: any | null;
          } | null;
        } | null;
      }>;
    };
  } | null;
};

export type AvailableAppInstallsBySpaceIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type AvailableAppInstallsBySpaceIdQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableAppInstallsBySpaceId: {
      __typename: "AppInstallsConnection";
      nodes: Array<{
        __typename: "AppInstall";
        id: any;
        appId: any;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          scrn: string;
          iconUrl: string | null;
          categories: Array<string | null>;
        } | null;
        appInstancesByAppInstallId: {
          __typename: "AppInstancesConnection";
          totalCount: number;
        };
      }>;
    };
  } | null;
};

export type SearchAppInstanceQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  spaceId: Scalars["UUID"];
  filter: InputMaybe<AppInstanceFilter>;
}>;

export type SearchAppInstanceQuery = {
  __typename: "Query";
  searchAppInstance: {
    __typename: "AppInstancesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type SearchAppInstanceSpecificAppIdQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  spaceId: Scalars["UUID"];
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  appId: Scalars["UUID"];
}>;

export type SearchAppInstanceSpecificAppIdQuery = {
  __typename: "Query";
  searchAppInstanceSpecificAppId: {
    __typename: "AppInstancesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      associationsByAppInstanceIdAndSpaceId: {
        __typename: "AssociationsConnection";
        nodes: Array<{
          __typename: "Association";
          channelByFromChannelAndOrgId: {
            __typename: "Channel";
            id: any;
            name: string;
            coverData: any | null;
            fileByCoverImageId: {
              __typename: "File";
              source: string | null;
            } | null;
            draft: { __typename: "Channel"; id: any; name: string } | null;
          } | null;
          playlistByFromPlaylistAndOrgId: {
            __typename: "Playlist";
            id: any;
            name: string;
            color: string;
            draft: { __typename: "Playlist"; id: any; name: string } | null;
          } | null;
        }>;
      };
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type UninstallSpaceAppMutationVariables = Exact<{
  input: UninstallSpaceAppInput;
}>;

export type UninstallSpaceAppMutation = {
  __typename: "Mutation";
  uninstallSpaceApp: {
    __typename: "UninstallSpaceAppPayload";
    appInstall: {
      __typename: "AppInstall";
      appId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      spaceId: any | null;
    } | null;
  } | null;
};

export type AllAppInstanceIdsQueryVariables = Exact<{
  appId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  tags?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type AllAppInstanceIdsQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableAppInstancesBySpaceIdAndAppId: {
      __typename: "AppInstancesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        spaceId: any;
      }>;
    };
  } | null;
};

export type AllAppInstancesQueryVariables = Exact<{
  appId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  orderBy: InputMaybe<Array<AppInstancesOrderBy> | AppInstancesOrderBy>;
  filter: InputMaybe<AppInstanceFilter>;
}>;

export type AllAppInstancesQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableAppInstancesBySpaceIdAndAppId: {
      __typename: "AppInstancesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        appId: any;
        appInstallId: any;
        spaceId: any;
        state: any | null;
        version: string | null;
        name: string | null;
        config: any | null;
        isTemplate: boolean;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        createdAt: any | null;
        updatedAt: any | null;
        tags: Array<string | null>;
        targetCommonAppInstanceTemplateId: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        sharedSpacesByAppInstanceId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
        castedScreenByAppInstanceId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
            spaceId: any;
          }>;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AllAppInstancesExtendedAssociationQueryVariables = Exact<{
  appId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  orderBy: InputMaybe<Array<AppInstancesOrderBy> | AppInstancesOrderBy>;
  filter: InputMaybe<AppInstanceFilter>;
}>;

export type AllAppInstancesExtendedAssociationQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableAppInstancesBySpaceIdAndAppId: {
      __typename: "AppInstancesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        appId: any;
        appInstallId: any;
        spaceId: any;
        state: any | null;
        version: string | null;
        name: string | null;
        config: any | null;
        isTemplate: boolean;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        createdAt: any | null;
        updatedAt: any | null;
        tags: Array<string | null>;
        targetCommonAppInstanceTemplateId: any | null;
        availableAt: any | null;
        expireAt: any | null;
        associationsByAppInstanceIdAndSpaceId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              name: string;
              coverData: any | null;
              fileByCoverImageId: {
                __typename: "File";
                source: string | null;
              } | null;
              draft: { __typename: "Channel"; id: any; name: string } | null;
            } | null;
            playlistByFromPlaylistAndOrgId: {
              __typename: "Playlist";
              id: any;
              name: string;
              color: string;
              draft: { __typename: "Playlist"; id: any; name: string } | null;
            } | null;
          }>;
        };
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        sharedSpacesByAppInstanceId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
        castedScreenByAppInstanceId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
            spaceId: any;
          }>;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AllCommonAppInstanceTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllCommonAppInstanceTemplatesQuery = {
  __typename: "Query";
  allCommonAppInstanceTemplates: {
    __typename: "CommonAppInstanceTemplatesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "CommonAppInstanceTemplate";
      id: any;
      name: string | null;
      version: string | null;
      config: any | null;
      state: any | null;
      tags: Array<string | null>;
      status: AppInstanceStatus | null;
      thumbnailFileId: any | null;
      isPublished: boolean;
      isDeprecated: boolean;
      sourceOrgId: any | null;
      sourceAppInstanceId: any | null;
      commonFileByThumbnailFileId: {
        __typename: "CommonFile";
        id: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        filesByCommonFileId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            spaceId: any | null;
            folderId: any | null;
            name: string;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            tags: Array<string | null>;
            size: any | null;
            isSharedAll: boolean | null;
            isFavorite: boolean | null;
            isSharedExplicit: boolean | null;
            fileProcessingStatus: JobStatus | null;
            createdAt: any | null;
            expireAt: any | null;
            availableAt: any | null;
            castedScreenByFileId: {
              __typename: "ScreensConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "Screen";
                id: any;
                name: string;
                device: any | null;
                spaceId: any;
              }>;
            };
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
            userByCreatedBy: {
              __typename: "User";
              id: any;
              orgId: any;
              email: string | null;
              status: UserStatus;
              userType: UserType | null;
              givenName: string;
              familyName: string;
              receiveUpdates: ReceiveUpdates;
              isOwner: boolean | null;
              metadata: any | null;
              preferences: any | null;
              permissions: Array<string | null> | null;
              permissionsByGroupIds: any | null;
              permissionsBySpaceIds: any | null;
              deletedAt: any | null;
              deletedBy: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
              lastAuthenticatedAt: any | null;
            } | null;
          }>;
        };
      } | null;
      appInstanceBySourceAppInstanceId: {
        __typename: "AppInstance";
        id: any;
        orgId: any;
        appInstallId: any;
        appId: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        tags: Array<string | null>;
        isTemplate: boolean;
        isSharedAll: boolean | null;
        isShared: boolean | null;
        isSharedExplicit: boolean | null;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
      } | null;
      appInstanceByTargetCommonAppInstanceTemplateId: {
        __typename: "AppInstance";
        id: any;
        orgId: any;
        appInstallId: any;
        appId: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        tags: Array<string | null>;
        isTemplate: boolean;
        isSharedAll: boolean | null;
        isShared: boolean | null;
        isSharedExplicit: boolean | null;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
      } | null;
    }>;
  } | null;
};

export type GetAppInstanceTemplatesQueryVariables = Exact<{
  appId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  endCursor: InputMaybe<Scalars["Cursor"]>;
  filter: InputMaybe<AppInstanceFilter>;
}>;

export type GetAppInstanceTemplatesQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableAppInstancesBySpaceIdAndAppId: {
      __typename: "AppInstancesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        appId: any;
        appInstallId: any;
        spaceId: any;
        state: any | null;
        version: string | null;
        name: string | null;
        config: any | null;
        isTemplate: boolean;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        createdAt: any | null;
        updatedAt: any | null;
        tags: Array<string | null>;
        targetCommonAppInstanceTemplateId: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        sharedSpacesByAppInstanceId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
        castedScreenByAppInstanceId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
            spaceId: any;
          }>;
        };
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AllTemplateInstancesIdsQueryVariables = Exact<{
  appId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
}>;

export type AllTemplateInstancesIdsQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableAppInstancesBySpaceIdAndAppId: {
      __typename: "AppInstancesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        spaceId: any;
      }>;
    };
  } | null;
};

export type AppInstancesByAppIdAndSpaceIdQueryVariables = Exact<{
  appId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
}>;

export type AppInstancesByAppIdAndSpaceIdQuery = {
  __typename: "Query";
  appInstancesByAppIdAndSpaceId: {
    __typename: "AppInstancesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "AppInstance";
      isTemplate: boolean;
      targetCommonAppInstanceTemplateId: any | null;
      id: any;
      orgId: any;
      appInstallId: any;
      appId: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      tags: Array<string | null>;
      isSharedAll: boolean | null;
      isShared: boolean | null;
      isSharedExplicit: boolean | null;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      appVersionByAppInstanceId: {
        __typename: "AppVersion";
        id: any;
        appId: any;
        version: string | null;
        betaFlag: boolean | null;
        stableFlag: boolean | null;
        manifestJson: any | null;
        viewerUrl: string | null;
        editorUrl: string | null;
        mediaSandboxAccess: boolean | null;
        configurationType: AppConfiguration | null;
        storeData: any | null;
      } | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
    }>;
  } | null;
};

export type CanvasAppQueryVariables = Exact<{ [key: string]: never }>;

export type CanvasAppQuery = {
  __typename: "Query";
  allApps: {
    __typename: "AppsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "App";
      id: any;
      name: string;
      scrn: string;
      categories: Array<string | null>;
      requirements: Array<string | null>;
      description: string | null;
      defaultInstall: AppDefaultInstallType;
      isInstalled: boolean | null;
      iconUrl: string | null;
      developer: string | null;
      tags: Array<string | null>;
      createdAt: any | null;
      updatedAt: any | null;
      appInstallsByAppId: {
        __typename: "AppInstallsConnection";
        nodes: Array<{
          __typename: "AppInstall";
          appId: any;
          id: any;
          orgId: any;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          spaceId: any | null;
        }>;
      };
      appVersionByAppId: {
        __typename: "AppVersion";
        id: any;
        appId: any;
        version: string | null;
        betaFlag: boolean | null;
        stableFlag: boolean | null;
        manifestJson: any | null;
        viewerUrl: string | null;
        editorUrl: string | null;
        mediaSandboxAccess: boolean | null;
        configurationType: AppConfiguration | null;
        storeData: any | null;
        createdAt: any | null;
        updatedAt: any | null;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type CanvasCountQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: Scalars["UUID"];
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  appId: Scalars["UUID"];
}>;

export type CanvasCountQuery = {
  __typename: "Query";
  searchAppInstanceSpecificAppId: {
    __typename: "AppInstancesConnection";
    totalCount: number;
  } | null;
};

export type CreateAppInstanceByCommonAppInstanceTemplateIdMutationVariables = Exact<{
  input: CreateAppInstanceByCommonAppInstanceTemplateIdInput;
}>;

export type CreateAppInstanceByCommonAppInstanceTemplateIdMutation = {
  __typename: "Mutation";
  createAppInstanceByCommonAppInstanceTemplateId: {
    __typename: "CreateAppInstanceByCommonAppInstanceTemplateIdPayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
    orgByOrgId: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    appInstallByAppInstallId: {
      __typename: "AppInstall";
      appId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      spaceId: any | null;
    } | null;
    fileByThumbnailFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    commonAppInstanceTemplateByCommonAppInstanceTemplateId: {
      __typename: "CommonAppInstanceTemplate";
      id: any;
      name: string | null;
      version: string | null;
      config: any | null;
      state: any | null;
      tags: Array<string | null>;
      status: AppInstanceStatus | null;
      thumbnailFileId: any | null;
      isPublished: boolean;
      isDeprecated: boolean;
      sourceOrgId: any | null;
      sourceAppInstanceId: any | null;
      appInstanceBySourceAppInstanceId: {
        __typename: "AppInstance";
        id: any;
        orgId: any;
        appInstallId: any;
        appId: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        tags: Array<string | null>;
        isTemplate: boolean;
        isSharedAll: boolean | null;
        isShared: boolean | null;
        isSharedExplicit: boolean | null;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
      } | null;
      appInstanceByTargetCommonAppInstanceTemplateId: {
        __typename: "AppInstance";
        id: any;
        orgId: any;
        appInstallId: any;
        appId: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        tags: Array<string | null>;
        isTemplate: boolean;
        isSharedAll: boolean | null;
        isShared: boolean | null;
        isSharedExplicit: boolean | null;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
      } | null;
    } | null;
    appByAppId: {
      __typename: "App";
      id: any;
      name: string;
      iconUrl: string | null;
    } | null;
  } | null;
};

export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables = Exact<{
  input: CreateCommonAppInstanceTemplateBySourceAppInstanceIdInput;
}>;

export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation = {
  __typename: "Mutation";
  createCommonAppInstanceTemplateBySourceAppInstanceId: {
    __typename: "CreateCommonAppInstanceTemplateBySourceAppInstanceIdPayload";
    commonAppInstanceTemplate: {
      __typename: "CommonAppInstanceTemplate";
      id: any;
      name: string | null;
      version: string | null;
      config: any | null;
      state: any | null;
      tags: Array<string | null>;
      status: AppInstanceStatus | null;
      thumbnailFileId: any | null;
      isPublished: boolean;
      isDeprecated: boolean;
      sourceOrgId: any | null;
      sourceAppInstanceId: any | null;
      appInstanceBySourceAppInstanceId: {
        __typename: "AppInstance";
        id: any;
        orgId: any;
        appInstallId: any;
        appId: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        tags: Array<string | null>;
        isTemplate: boolean;
        isSharedAll: boolean | null;
        isShared: boolean | null;
        isSharedExplicit: boolean | null;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        availableAt: any | null;
        expireAt: any | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
      } | null;
      appInstanceByTargetCommonAppInstanceTemplateId: {
        __typename: "AppInstance";
        id: any;
        orgId: any;
        appInstallId: any;
        appId: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        tags: Array<string | null>;
        isTemplate: boolean;
        isSharedAll: boolean | null;
        isShared: boolean | null;
        isSharedExplicit: boolean | null;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
      } | null;
    } | null;
    orgBySourceOrgId: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    appInstanceBySourceAppInstanceId: {
      __typename: "AppInstance";
      id: any;
      orgId: any;
      appInstallId: any;
      appId: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      tags: Array<string | null>;
      isTemplate: boolean;
      isSharedAll: boolean | null;
      isShared: boolean | null;
      isSharedExplicit: boolean | null;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
    } | null;
  } | null;
};

export type DeleteCommonAppInstanceTemplateByIdMutationVariables = Exact<{
  input: DeleteCommonAppInstanceTemplateByIdInput;
}>;

export type DeleteCommonAppInstanceTemplateByIdMutation = {
  __typename: "Mutation";
  deleteCommonAppInstanceTemplateById: {
    __typename: "DeleteCommonAppInstanceTemplateByIdPayload";
    commonAppInstanceTemplate: {
      __typename: "CommonAppInstanceTemplate";
      id: any;
      sourceAppInstanceId: any | null;
    } | null;
  } | null;
};

export type DuplicateAppInstanceMutationVariables = Exact<{
  input: DuplicateAppInstanceInput;
}>;

export type DuplicateAppInstanceMutation = {
  __typename: "Mutation";
  duplicateAppInstance: {
    __typename: "DuplicateAppInstancePayload";
    appInstance: {
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    } | null;
  } | null;
};

export type SearchAllCanvasInstanceIdsQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: Scalars["UUID"];
  appId: InputMaybe<Scalars["UUID"]>;
}>;

export type SearchAllCanvasInstanceIdsQuery = {
  __typename: "Query";
  searchAppInstance: {
    __typename: "AppInstancesConnection";
    totalCount: number;
    nodes: Array<{ __typename: "AppInstance"; id: any; isTemplate: boolean }>;
  } | null;
};

export type SearchCanvasQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  first: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  spaceId: Scalars["UUID"];
  condition: InputMaybe<AppInstanceCondition>;
  filter: InputMaybe<AppInstanceFilter>;
  appId: Scalars["UUID"];
}>;

export type SearchCanvasQuery = {
  __typename: "Query";
  searchAppInstanceSpecificAppId: {
    __typename: "AppInstancesConnection";
    nodes: Array<{
      __typename: "AppInstance";
      id: any;
      appId: any;
      appInstallId: any;
      spaceId: any;
      state: any | null;
      version: string | null;
      name: string | null;
      config: any | null;
      isTemplate: boolean;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      createdAt: any | null;
      updatedAt: any | null;
      tags: Array<string | null>;
      targetCommonAppInstanceTemplateId: any | null;
      availableAt: any | null;
      expireAt: any | null;
      associationsByAppInstanceIdAndSpaceId: {
        __typename: "AssociationsConnection";
        nodes: Array<{
          __typename: "Association";
          channelByFromChannelAndOrgId: {
            __typename: "Channel";
            id: any;
            name: string;
            coverData: any | null;
            fileByCoverImageId: {
              __typename: "File";
              source: string | null;
            } | null;
            draft: { __typename: "Channel"; id: any; name: string } | null;
          } | null;
          playlistByFromPlaylistAndOrgId: {
            __typename: "Playlist";
            id: any;
            name: string;
            color: string;
            draft: { __typename: "Playlist"; id: any; name: string } | null;
          } | null;
        }>;
      };
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      sharedSpacesByAppInstanceId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
      castedScreenByAppInstanceId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
          spaceId: any;
        }>;
      };
      appByAppId: {
        __typename: "App";
        id: any;
        name: string;
        iconUrl: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateAppInstanceTagsByIdsMutationVariables = Exact<{
  input: UpdateAppInstanceTagsByIdsInput;
}>;

export type UpdateAppInstanceTagsByIdsMutation = {
  __typename: "Mutation";
  updateAppInstanceTagsByIds: {
    __typename: "UpdateAppInstanceTagsByIdsPayload";
    appInstances: Array<{
      __typename: "AppInstance";
      id: any;
      orgId: any;
      appInstallId: any;
      appId: any;
      spaceId: any;
      name: string | null;
      tags: Array<string | null>;
    }> | null;
  } | null;
};

export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables = Exact<{
  input: UpdateCommonAppInstanceTemplateBySourceAppInstanceIdInput;
}>;

export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation = {
  __typename: "Mutation";
  updateCommonAppInstanceTemplateBySourceAppInstanceId: {
    __typename: "UpdateCommonAppInstanceTemplateBySourceAppInstanceIdPayload";
    commonAppInstanceTemplate: {
      __typename: "CommonAppInstanceTemplate";
      id: any;
      name: string | null;
      version: string | null;
      config: any | null;
      state: any | null;
      tags: Array<string | null>;
      status: AppInstanceStatus | null;
      thumbnailFileId: any | null;
      isPublished: boolean;
      isDeprecated: boolean;
      sourceOrgId: any | null;
      sourceAppInstanceId: any | null;
      appInstanceBySourceAppInstanceId: {
        __typename: "AppInstance";
        id: any;
        orgId: any;
        appInstallId: any;
        appId: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        tags: Array<string | null>;
        isTemplate: boolean;
        isSharedAll: boolean | null;
        isShared: boolean | null;
        isSharedExplicit: boolean | null;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        availableAt: any | null;
        expireAt: any | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
      } | null;
      appInstanceByTargetCommonAppInstanceTemplateId: {
        __typename: "AppInstance";
        id: any;
        orgId: any;
        appInstallId: any;
        appId: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        tags: Array<string | null>;
        isTemplate: boolean;
        isSharedAll: boolean | null;
        isShared: boolean | null;
        isSharedExplicit: boolean | null;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        availableAt: any | null;
        expireAt: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          }>;
        };
      } | null;
    } | null;
    orgBySourceOrgId: {
      __typename: "Org";
      id: any;
      name: string;
      brandForegroundColor: string;
      brandBackgroundColor: string;
      startChannelId: any | null;
      blankChannelId: any | null;
      brandChannelId: any | null;
      splashChannelThemeId: any | null;
      preferences: any | null;
      defaultChannelThemeId: any | null;
      brandLogoId: any | null;
      brandBackgroundId: any | null;
      billingExpiresAt: any | null;
      isDraft: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
    appInstanceBySourceAppInstanceId: {
      __typename: "AppInstance";
      id: any;
      orgId: any;
      appInstallId: any;
      appId: any;
      config: any | null;
      state: any | null;
      version: string | null;
      name: string | null;
      tags: Array<string | null>;
      isTemplate: boolean;
      isSharedAll: boolean | null;
      isShared: boolean | null;
      isSharedExplicit: boolean | null;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      availableAt: any | null;
      expireAt: any | null;
      fileByThumbnailFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      filesByAppInstanceId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
    } | null;
  } | null;
};

export type CastByChannelByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
  expectedItemPerPage: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
}>;

export type CastByChannelByIdQuery = {
  __typename: "Query";
  channelById: {
    __typename: "Channel";
    id: any;
    associationsByToChannelAndOrgId: {
      __typename: "AssociationsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Association";
        fromCast: any | null;
        castByFromCastAndOrgId: {
          __typename: "Cast";
          id: any;
          screensByCastId: {
            __typename: "ScreensConnection";
            nodes: Array<{
              __typename: "Screen";
              id: any;
              spaceId: any;
              name: string;
              device: any | null;
            }>;
          };
        } | null;
      }>;
    };
  } | null;
};

export type ChannelByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ChannelByIdQuery = {
  __typename: "Query";
  channelById: {
    __typename: "Channel";
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isEmbedEnabled: boolean | null;
    isEmbedPublic: boolean | null;
    preferences: any | null;
    childOf: any | null;
    duplicateOf: any | null;
    id: any;
    orgId: any;
    spaceId: any | null;
    themeId: any | null;
    tags: Array<string | null>;
    name: string;
    publishedAt: any | null;
    publishedBy: any | null;
    coverData: any | null;
    coverImageId: any | null;
    layoutId: any | null;
    hiddenZone: boolean | null;
    content: any | null;
    isPublished: boolean | null;
    isParent: boolean | null;
    playingScreenNum: any | null;
    width: number | null;
    height: number | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    fileByCoverImageId: {
      __typename: "File";
      id: any;
      orgId: any;
      metadata: any;
      name: string;
      source: string | null;
      mimetype: string | null;
      fileProcessingStatus: JobStatus | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    } | null;
    draft: {
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        zones: number;
        config: any | null;
        width: number | null;
        height: number | null;
        isFlexible: boolean | null;
        isScalable: boolean | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      playlistsByChannelId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          color: string;
          content: any | null;
          id: any;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isEmbedEnabled: boolean | null;
          isEmbedPublic: boolean | null;
          name: string;
          spaceId: any;
          tags: Array<string | null>;
          draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
        }>;
      };
      filesByChannelId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          expireAt: any | null;
        }>;
      };
      linksByChannelId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByChannelId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          orgId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          actions: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            metadata: any;
            source: string | null;
            mimetype: string | null;
          } | null;
        }>;
      };
      appInstancesByChannelId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          expireAt: any | null;
        }>;
      };
    } | null;
    published: {
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        zones: number;
        config: any | null;
        width: number | null;
        height: number | null;
        isFlexible: boolean | null;
        isScalable: boolean | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      playlistsByChannelId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          color: string;
          content: any | null;
          id: any;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isEmbedEnabled: boolean | null;
          isEmbedPublic: boolean | null;
          name: string;
          spaceId: any;
          tags: Array<string | null>;
          draft: { __typename: "Playlist"; isPublished: boolean | null } | null;
        }>;
      };
      filesByChannelId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          expireAt: any | null;
        }>;
      };
      linksByChannelId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByChannelId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          orgId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          actions: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            metadata: any;
            source: string | null;
            mimetype: string | null;
          } | null;
        }>;
      };
      appInstancesByChannelId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          expireAt: any | null;
        }>;
      };
    } | null;
    embedsByChannelId: {
      __typename: "EmbedsConnection";
      nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
    };
    castedScreenByChannelId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        spaceId: any;
      }>;
    };
    parentChannelByChildOf: {
      __typename: "ParentChannelByChildOf";
      id: any | null;
      name: string | null;
      spaceId: any | null;
      spaceName: string | null;
      content: any | null;
    } | null;
  } | null;
};

export type EmbedsByChannelIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type EmbedsByChannelIdQuery = {
  __typename: "Query";
  channelById: {
    __typename: "Channel";
    id: any;
    name: string;
    embedsByChannelId: {
      __typename: "EmbedsConnection";
      nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
    };
  } | null;
};

export type DuplicateChannelMutationVariables = Exact<{
  input: DuplicateChannelInput;
}>;

export type DuplicateChannelMutation = {
  __typename: "Mutation";
  duplicateChannel: {
    __typename: "DuplicateChannelPayload";
    channel: {
      __typename: "Channel";
      id: any;
      spaceId: any | null;
      name: string;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      hiddenZone: boolean | null;
      isSharedAll: boolean | null;
      preferences: any | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      childOf: any | null;
      duplicateOf: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        name: string;
        config: any | null;
      } | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
      } | null;
      embedsByChannelId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByChannelId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
        }>;
      };
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    } | null;
  } | null;
};

export type PublishDraftChannelMutationVariables = Exact<{
  input: PublishDraftChannelInput;
}>;

export type PublishDraftChannelMutation = {
  __typename: "Mutation";
  publishDraftChannel: {
    __typename: "PublishDraftChannelPayload";
    channel: {
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type RevertDraftChannelMutationVariables = Exact<{
  input: RevertDraftChannelInput;
}>;

export type RevertDraftChannelMutation = {
  __typename: "Mutation";
  revertDraftChannel: {
    __typename: "RevertDraftChannelPayload";
    channel: {
      __typename: "Channel";
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      childOf: any | null;
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        metadata: any;
        name: string;
        source: string | null;
        mimetype: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        orgId: any;
        spaceId: any | null;
        themeId: any | null;
        tags: Array<string | null>;
        name: string;
        publishedAt: any | null;
        publishedBy: any | null;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        hiddenZone: boolean | null;
        content: any | null;
        isPublished: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isParent: boolean | null;
        playingScreenNum: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          zones: number;
          config: any | null;
          width: number | null;
          height: number | null;
          isFlexible: boolean | null;
          isScalable: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        playlistsByChannelId: {
          __typename: "PlaylistsConnection";
          nodes: Array<{
            __typename: "Playlist";
            id: any;
            orgId: any;
            spaceId: any;
            name: string;
            color: string;
            content: any | null;
            isPublished: boolean | null;
            draft: {
              __typename: "Playlist";
              id: any;
              isPublished: boolean | null;
            } | null;
            filesByPlaylistId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                name: string;
                metadata: any;
                source: string | null;
                mimetype: string | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              }>;
            };
            appInstancesByPlaylistId: {
              __typename: "AppInstancesConnection";
              nodes: Array<{
                __typename: "AppInstance";
                id: any;
                orgId: any;
                config: any | null;
                name: string | null;
                appVersionByAppInstanceId: {
                  __typename: "AppVersion";
                  id: any;
                  appId: any;
                  manifestJson: any | null;
                  viewerUrl: string | null;
                  appByAppId: {
                    __typename: "App";
                    id: any;
                    name: string;
                    iconUrl: string | null;
                  } | null;
                } | null;
              }>;
            };
            linksByPlaylistId: {
              __typename: "LinksConnection";
              nodes: Array<{
                __typename: "Link";
                id: any;
                orgId: any;
                url: string;
                linkType: LinkType;
                cloudConfig: any | null;
                name: string;
                fileByFileId: {
                  __typename: "File";
                  id: any;
                  orgId: any;
                  metadata: any;
                  source: string | null;
                  mimetype: string | null;
                  fileProcessingStatus: JobStatus | null;
                } | null;
              }>;
            };
            sitesByPlaylistId: {
              __typename: "SitesConnection";
              nodes: Array<{
                __typename: "Site";
                id: any;
                orgId: any;
                name: string;
                type: SiteType;
                url: string;
                config: any | null;
                actions: any | null;
                fileByThumbnailId: {
                  __typename: "File";
                  id: any;
                  orgId: any;
                  metadata: any;
                  source: string | null;
                  mimetype: string | null;
                  fileProcessingStatus: JobStatus | null;
                } | null;
              }>;
            };
          }>;
        };
        filesByChannelId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            metadata: any;
            source: string | null;
            mimetype: string | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByChannelId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            orgId: any;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              orgId: any;
              metadata: any;
              source: string | null;
              mimetype: string | null;
            } | null;
          }>;
        };
        sitesByChannelId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            orgId: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            actions: any | null;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              orgId: any;
              metadata: any;
              source: string | null;
              mimetype: string | null;
            } | null;
          }>;
        };
        appInstancesByChannelId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            orgId: any;
            config: any | null;
            name: string | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              appId: any;
              manifestJson: any | null;
              viewerUrl: string | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
          }>;
        };
        userByUpdatedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
          email: string | null;
        } | null;
        userByCreatedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
          email: string | null;
        } | null;
        userByPublishedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
          email: string | null;
        } | null;
      } | null;
      userByPublishedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
      userByUpdatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
      screensByChannelId: {
        __typename: "ScreensConnection";
        totalCount: number;
      };
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    } | null;
  } | null;
};

export type SnapshotsByOrgQueryVariables = Exact<{
  input: InputMaybe<GetSnapshotInput>;
}>;

export type SnapshotsByOrgQuery = {
  __typename: "Query";
  getSnapshots: Array<{
    __typename: "GetSnapshotsPayload";
    jobId: string | null;
    status: string | null;
    snapshotType: SnapshotType | null;
    contentId: string | null;
    sourceOrgId: string | null;
    sourceSpaceId: string | null;
    isPublicSnapshot: boolean | null;
    userId: string | null;
  } | null> | null;
};

export type UpdateChannelMutationVariables = Exact<{
  input: UpdateChannelInput;
}>;

export type UpdateChannelMutation = {
  __typename: "Mutation";
  updateChannel: {
    __typename: "UpdateChannelPayload";
    channel: {
      __typename: "Channel";
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      childOf: any | null;
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        metadata: any;
        name: string;
        source: string | null;
        mimetype: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        orgId: any;
        spaceId: any | null;
        themeId: any | null;
        tags: Array<string | null>;
        name: string;
        publishedAt: any | null;
        publishedBy: any | null;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        hiddenZone: boolean | null;
        content: any | null;
        isPublished: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isParent: boolean | null;
        playingScreenNum: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          zones: number;
          config: any | null;
          width: number | null;
          height: number | null;
          isFlexible: boolean | null;
          isScalable: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        playlistsByChannelId: {
          __typename: "PlaylistsConnection";
          nodes: Array<{ __typename: "Playlist"; id: any }>;
        };
        filesByChannelId: {
          __typename: "FilesConnection";
          nodes: Array<{ __typename: "File"; id: any }>;
        };
        linksByChannelId: {
          __typename: "LinksConnection";
          nodes: Array<{ __typename: "Link"; id: any }>;
        };
        sitesByChannelId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            orgId: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            actions: any | null;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              orgId: any;
              metadata: any;
              source: string | null;
              mimetype: string | null;
            } | null;
          }>;
        };
        appInstancesByChannelId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{ __typename: "AppInstance"; id: any }>;
        };
      } | null;
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateChannelCoverMutationVariables = Exact<{
  input: UpdateChannelCoverInput;
}>;

export type UpdateChannelCoverMutation = {
  __typename: "Mutation";
  updateChannelCover: {
    __typename: "UpdateChannelCoverPayload";
    channel: {
      __typename: "Channel";
      childOf: any | null;
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        metadata: any;
        name: string;
        source: string | null;
        mimetype: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        orgId: any;
        spaceId: any | null;
        themeId: any | null;
        tags: Array<string | null>;
        name: string;
        publishedAt: any | null;
        publishedBy: any | null;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        hiddenZone: boolean | null;
        content: any | null;
        isPublished: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isParent: boolean | null;
        playingScreenNum: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateChannelNameMutationVariables = Exact<{
  input: UpdateChannelNameInput;
}>;

export type UpdateChannelNameMutation = {
  __typename: "Mutation";
  updateChannelName: {
    __typename: "UpdateChannelNamePayload";
    channel: {
      __typename: "Channel";
      id: any;
      spaceId: any | null;
      name: string;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      hiddenZone: boolean | null;
      isSharedAll: boolean | null;
      preferences: any | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      childOf: any | null;
      duplicateOf: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        name: string;
        config: any | null;
      } | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
      } | null;
      embedsByChannelId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByChannelId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
        }>;
      };
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateChannelThemeMutationVariables = Exact<{
  input: UpdateChannelThemeInput;
}>;

export type UpdateChannelThemeMutation = {
  __typename: "Mutation";
  updateChannelTheme: {
    __typename: "UpdateChannelThemePayload";
    channel: {
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      draft: {
        __typename: "Channel";
        id: any;
        themeId: any | null;
        isPublished: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type AllChannelInSpaceQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endChannel: InputMaybe<Scalars["Cursor"]>;
}>;

export type AllChannelInSpaceQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedChannelsBySpaceId: {
      __typename: "ChannelsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Channel";
        id: any;
        spaceId: any | null;
        name: string;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        hiddenZone: boolean | null;
        isSharedAll: boolean | null;
        preferences: any | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        childOf: any | null;
        duplicateOf: any | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          name: string;
          config: any | null;
        } | null;
        fileByCoverImageId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        draft: {
          __typename: "Channel";
          id: any;
          isPublished: boolean | null;
        } | null;
        embedsByChannelId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByChannelId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
          }>;
        };
        parentChannelByChildOf: {
          __typename: "ParentChannelByChildOf";
          id: any | null;
          name: string | null;
          spaceId: any | null;
          spaceName: string | null;
          content: any | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type AllChannelsQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type AllChannelsQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedChannelsBySpaceId: {
      __typename: "ChannelsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Channel";
        id: any;
        name: string;
        spaceId: any | null;
        childOf: any | null;
        duplicateOf: any | null;
        castedScreenByChannelId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
          }>;
        };
      }>;
    };
  } | null;
};

export type AllSearchChannelQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type AllSearchChannelQuery = {
  __typename: "Query";
  searchChannel: {
    __typename: "ChannelsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Channel";
      id: any;
      name: string;
      spaceId: any | null;
      childOf: any | null;
      duplicateOf: any | null;
    }>;
  } | null;
};

export type ChannelListQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endChannel: InputMaybe<Scalars["Cursor"]>;
}>;

export type ChannelListQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedChannelsBySpaceId: {
      __typename: "ChannelsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Channel";
        id: any;
        spaceId: any | null;
        name: string;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        hiddenZone: boolean | null;
        isSharedAll: boolean | null;
        preferences: any | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        childOf: any | null;
        duplicateOf: any | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          name: string;
          config: any | null;
        } | null;
        fileByCoverImageId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        draft: {
          __typename: "Channel";
          id: any;
          isPublished: boolean | null;
        } | null;
        embedsByChannelId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByChannelId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
          }>;
        };
        parentChannelByChildOf: {
          __typename: "ParentChannelByChildOf";
          id: any | null;
          name: string | null;
          spaceId: any | null;
          spaceName: string | null;
          content: any | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type CreateChannelMutationVariables = Exact<{
  input: CreateChannelInput;
}>;

export type CreateChannelMutation = {
  __typename: "Mutation";
  createChannel: {
    __typename: "CreateChannelPayload";
    channel: {
      __typename: "Channel";
      id: any;
      spaceId: any | null;
      name: string;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      hiddenZone: boolean | null;
      isSharedAll: boolean | null;
      preferences: any | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      childOf: any | null;
      duplicateOf: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        name: string;
        config: any | null;
      } | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
      } | null;
      embedsByChannelId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByChannelId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
        }>;
      };
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    } | null;
  } | null;
};

export type CurrentOrgStartableChannelsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentOrgStartableChannelsQuery = {
  __typename: "Query";
  currentOrgStartableChannels: {
    __typename: "ChannelsConnection";
    nodes: Array<{
      __typename: "Channel";
      id: any;
      orgId: any;
      spaceId: any | null;
      themeId: any | null;
      tags: Array<string | null>;
      name: string;
      publishedAt: any | null;
      publishedBy: any | null;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      hiddenZone: boolean | null;
      content: any | null;
      isPublished: boolean | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      isParent: boolean | null;
      playingScreenNum: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    }>;
  } | null;
};

export type DeleteChannelMutationVariables = Exact<{
  input: DeleteChannelInput;
}>;

export type DeleteChannelMutation = {
  __typename: "Mutation";
  deleteChannel: {
    __typename: "DeleteChannelPayload";
    channel: { __typename: "Channel"; id: any; name: string } | null;
  } | null;
};

export type DuplicateEditableSharedChannelMutationVariables = Exact<{
  input: DuplicateEditableSharedChannelInput;
}>;

export type DuplicateEditableSharedChannelMutation = {
  __typename: "Mutation";
  duplicateEditableSharedChannel: {
    __typename: "DuplicateEditableSharedChannelPayload";
    channel: {
      __typename: "Channel";
      id: any;
      spaceId: any | null;
      name: string;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      hiddenZone: boolean | null;
      isSharedAll: boolean | null;
      preferences: any | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      childOf: any | null;
      duplicateOf: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        name: string;
        config: any | null;
      } | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
      } | null;
      embedsByChannelId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByChannelId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
        }>;
      };
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    } | null;
  } | null;
};

export type LayoutsBySpaceIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type LayoutsBySpaceIdQuery = {
  __typename: "Query";
  layoutsBySpaceId: {
    __typename: "LayoutsConnection";
    nodes: Array<{
      __typename: "Layout";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      zones: number;
      config: any | null;
      width: number | null;
      height: number | null;
      isFlexible: boolean | null;
      isScalable: boolean | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    }>;
  } | null;
};

export type SearchChannelQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  endChannel: InputMaybe<Scalars["Cursor"]>;
}>;

export type SearchChannelQuery = {
  __typename: "Query";
  searchChannel: {
    __typename: "ChannelsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Channel";
      id: any;
      spaceId: any | null;
      name: string;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      hiddenZone: boolean | null;
      isSharedAll: boolean | null;
      preferences: any | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      childOf: any | null;
      duplicateOf: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        name: string;
        config: any | null;
      } | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
      } | null;
      embedsByChannelId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByChannelId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
        }>;
      };
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type SearchSharedChannelQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  endChannel: InputMaybe<Scalars["Cursor"]>;
}>;

export type SearchSharedChannelQuery = {
  __typename: "Query";
  searchChannel: {
    __typename: "ChannelsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Channel";
      id: any;
      spaceId: any | null;
      name: string;
      coverData: any | null;
      coverImageId: any | null;
      layoutId: any | null;
      width: number | null;
      height: number | null;
      createdAt: any | null;
      hiddenZone: boolean | null;
      isSharedAll: boolean | null;
      preferences: any | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      childOf: any | null;
      duplicateOf: any | null;
      layoutByChannel: {
        __typename: "Layout";
        id: any;
        name: string;
        config: any | null;
      } | null;
      fileByCoverImageId: {
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      } | null;
      draft: {
        __typename: "Channel";
        id: any;
        isPublished: boolean | null;
      } | null;
      embedsByChannelId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByChannelId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
        }>;
      };
      parentChannelByChildOf: {
        __typename: "ParentChannelByChildOf";
        id: any | null;
        name: string | null;
        spaceId: any | null;
        spaceName: string | null;
        content: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type SharedChannelListQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endChannel: InputMaybe<Scalars["Cursor"]>;
}>;

export type SharedChannelListQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedChannelsBySpaceId: {
      __typename: "ChannelsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Channel";
        id: any;
        spaceId: any | null;
        name: string;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        hiddenZone: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        childOf: any | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          name: string;
          config: any | null;
        } | null;
        fileByCoverImageId: {
          __typename: "File";
          id: any;
          orgId: any;
          spaceId: any | null;
          folderId: any | null;
          name: string;
          metadata: any;
          source: string | null;
          mimetype: string | null;
          tags: Array<string | null>;
          size: any | null;
          isSharedAll: boolean | null;
          isFavorite: boolean | null;
          isSharedExplicit: boolean | null;
          fileProcessingStatus: JobStatus | null;
          createdAt: any | null;
          expireAt: any | null;
          availableAt: any | null;
          castedScreenByFileId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              device: any | null;
              spaceId: any;
            }>;
          };
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            orgId: any;
            email: string | null;
            status: UserStatus;
            userType: UserType | null;
            givenName: string;
            familyName: string;
            receiveUpdates: ReceiveUpdates;
            isOwner: boolean | null;
            metadata: any | null;
            preferences: any | null;
            permissions: Array<string | null> | null;
            permissionsByGroupIds: any | null;
            permissionsBySpaceIds: any | null;
            deletedAt: any | null;
            deletedBy: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            lastAuthenticatedAt: any | null;
          } | null;
        } | null;
        draft: {
          __typename: "Channel";
          id: any;
          isPublished: boolean | null;
        } | null;
        castedScreenByChannelId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
          }>;
        };
        embedsByChannelId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type UpdateChannelEmbedMutationVariables = Exact<{
  input: UpdateChannelEmbedInput;
}>;

export type UpdateChannelEmbedMutation = {
  __typename: "Mutation";
  updateChannelEmbed: {
    __typename: "UpdateChannelEmbedPayload";
    channel: {
      __typename: "Channel";
      id: any;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
    } | null;
  } | null;
};

export type UpdateShareChannelToAllSpacesMutationVariables = Exact<{
  input: UpdateShareChannelToAllSpacesInput;
}>;

export type UpdateShareChannelToAllSpacesMutation = {
  __typename: "Mutation";
  updateShareChannelToAllSpaces: {
    __typename: "UpdateShareChannelToAllSpacesPayload";
    channel: {
      __typename: "Channel";
      id: any;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
    } | null;
  } | null;
};

export type UpdateShareChannelToSpacesMutationVariables = Exact<{
  input: UpdateShareChannelToSpacesInput;
}>;

export type UpdateShareChannelToSpacesMutation = {
  __typename: "Mutation";
  updateShareChannelToSpaces: {
    __typename: "UpdateShareChannelToSpacesPayload";
    channel: {
      __typename: "Channel";
      id: any;
      isSharedExplicit: boolean | null;
    } | null;
  } | null;
};

export type MigrationBillingMutationVariables = Exact<{
  input: MigrationBillingInput;
}>;

export type MigrationBillingMutation = {
  __typename: "Mutation";
  migrationBilling: {
    __typename: "MigrationBillingPayload";
    json: any | null;
  } | null;
};

export type MigrationInitMutationVariables = Exact<{
  input: MigrationInitInput;
}>;

export type MigrationInitMutation = {
  __typename: "Mutation";
  migrationInit: {
    __typename: "MigrationInitPayload";
    json: any | null;
  } | null;
};

export type AllLinksQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  orderBy: InputMaybe<Array<LinksOrderBy> | LinksOrderBy>;
}>;

export type AllLinksQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    linksBySpaceId: {
      __typename: "LinksConnection";
      totalCount: number;
      nodes: Array<{ __typename: "Link"; id: any }>;
    };
  } | null;
};

export type AllSearchLinksQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type AllSearchLinksQuery = {
  __typename: "Query";
  searchLink: {
    __typename: "LinksConnection";
    totalCount: number;
    nodes: Array<{ __typename: "Link"; id: any }>;
  } | null;
};

export type CreateLinkMutationVariables = Exact<{
  input: CreateLinkInput;
}>;

export type CreateLinkMutation = {
  __typename: "Mutation";
  createLink: {
    __typename: "CreateLinkPayload";
    link: {
      __typename: "Link";
      id: any;
      orgId: any;
      url: string;
      cacheBusting: boolean | null;
      autoReload: boolean | null;
      autoReloadDurationMs: number | null;
      qrcodeEnabled: boolean | null;
      qrcodeConfig: any | null;
      linkType: LinkType;
      cloudConfig: any | null;
      isFavorite: boolean | null;
      name: string;
      tags: Array<string | null>;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileByFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      castedScreenByLinkId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteLinkByIdMutationVariables = Exact<{
  input: DeleteLinkByIdInput;
}>;

export type DeleteLinkByIdMutation = {
  __typename: "Mutation";
  deleteLinkById: {
    __typename: "DeleteLinkByIdPayload";
    link: {
      __typename: "Link";
      id: any;
      orgId: any;
      url: string;
      cacheBusting: boolean | null;
      autoReload: boolean | null;
      autoReloadDurationMs: number | null;
      qrcodeEnabled: boolean | null;
      qrcodeConfig: any | null;
      linkType: LinkType;
      cloudConfig: any | null;
      isFavorite: boolean | null;
      name: string;
      tags: Array<string | null>;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileByFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      castedScreenByLinkId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
    } | null;
  } | null;
};

export type LinkByIdForContentQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LinkByIdForContentQuery = {
  __typename: "Query";
  linkById: {
    __typename: "Link";
    id: any;
    orgId: any;
    url: string;
    linkType: LinkType;
    cloudConfig: any | null;
    name: string;
    fileByFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      fileProcessingStatus: JobStatus | null;
    } | null;
  } | null;
};

export type LinkByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LinkByIdQuery = {
  __typename: "Query";
  linkById: {
    __typename: "Link";
    id: any;
    orgId: any;
    url: string;
    createdAt: any | null;
    linkType: LinkType;
    cloudConfig: any | null;
    isFavorite: boolean | null;
    name: string;
    tags: Array<string | null>;
    cacheBusting: boolean | null;
    qrcodeEnabled: boolean | null;
    qrcodeConfig: any | null;
    autoReload: boolean | null;
    autoReloadDurationMs: number | null;
    params: any | null;
    spaceId: any;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    castedScreenByLinkId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        deviceId: string;
        deviceModel: string | null;
      }>;
    };
    userByCreatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
    fileByFileId: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type LinkByIdAssociationsQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type LinkByIdAssociationsQuery = {
  __typename: "Query";
  linkById: {
    __typename: "Link";
    associationsByToLinkAndOrgId: {
      __typename: "AssociationsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Association";
        castByFromCastAndOrgId: {
          __typename: "Cast";
          id: any;
          screensByCastId: {
            __typename: "ScreensConnection";
            nodes: Array<{ __typename: "Screen"; id: any; name: string }>;
          };
        } | null;
        channelByFromChannelAndOrgId: {
          __typename: "Channel";
          id: any;
          name: string;
          draft: { __typename: "Channel"; id: any; name: string } | null;
        } | null;
        playlistByFromPlaylistAndOrgId: {
          __typename: "Playlist";
          id: any;
          name: string;
          draft: { __typename: "Playlist"; id: any; name: string } | null;
        } | null;
        appInstanceByFromAppInstanceAndOrgId: {
          __typename: "AppInstance";
          id: any;
          name: string | null;
        } | null;
        screenByFromScreenAndOrgId: {
          __typename: "Screen";
          id: any;
          name: string;
        } | null;
      }>;
    };
  } | null;
};

export type LinkListQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  endCursor: InputMaybe<Scalars["Cursor"]>;
  orderBy: InputMaybe<Array<LinksOrderBy> | LinksOrderBy>;
}>;

export type LinkListQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    linksBySpaceId: {
      __typename: "LinksConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Link";
        id: any;
        orgId: any;
        url: string;
        cacheBusting: boolean | null;
        autoReload: boolean | null;
        autoReloadDurationMs: number | null;
        qrcodeEnabled: boolean | null;
        qrcodeConfig: any | null;
        linkType: LinkType;
        cloudConfig: any | null;
        isFavorite: boolean | null;
        name: string;
        tags: Array<string | null>;
        spaceId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        castedScreenByLinkId: {
          __typename: "ScreensConnection";
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            deviceId: string;
            deviceModel: string | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
          email: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type SearchLinkQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type SearchLinkQuery = {
  __typename: "Query";
  searchLink: {
    __typename: "LinksConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Link";
      id: any;
      orgId: any;
      url: string;
      cacheBusting: boolean | null;
      autoReload: boolean | null;
      autoReloadDurationMs: number | null;
      qrcodeEnabled: boolean | null;
      qrcodeConfig: any | null;
      linkType: LinkType;
      cloudConfig: any | null;
      isFavorite: boolean | null;
      name: string;
      tags: Array<string | null>;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileByFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      castedScreenByLinkId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type UpdateLinkByIdMutationVariables = Exact<{
  input: UpdateLinkByIdInput;
}>;

export type UpdateLinkByIdMutation = {
  __typename: "Mutation";
  updateLinkById: {
    __typename: "UpdateLinkByIdPayload";
    link: {
      __typename: "Link";
      id: any;
      orgId: any;
      url: string;
      cacheBusting: boolean | null;
      autoReload: boolean | null;
      autoReloadDurationMs: number | null;
      qrcodeEnabled: boolean | null;
      qrcodeConfig: any | null;
      linkType: LinkType;
      cloudConfig: any | null;
      isFavorite: boolean | null;
      name: string;
      tags: Array<string | null>;
      spaceId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileByFileId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      castedScreenByLinkId: {
        __typename: "ScreensConnection";
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          deviceId: string;
          deviceModel: string | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
        email: string | null;
      } | null;
    } | null;
  } | null;
};

export type AvailableFoldersBySpaceIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type AvailableFoldersBySpaceIdQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    availableFoldersBySpaceId: {
      __typename: "FoldersConnection";
      nodes: Array<{
        __typename: "Folder";
        parentId: any | null;
        name: string;
        isFavorite: boolean | null;
        tags: Array<string | null>;
        spaceId: any;
        id: any;
        orgId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isShared: boolean | null;
        isSharedAll: boolean | null;
        foldersByParentId: {
          __typename: "FoldersConnection";
          totalCount: number;
          pageInfo: {
            __typename: "PageInfo";
            hasNextPage: boolean;
            endCursor: any | null;
          };
        };
        filesByFolderId: {
          __typename: "FilesConnection";
          totalCount: number;
          pageInfo: {
            __typename: "PageInfo";
            hasNextPage: boolean;
            endCursor: any | null;
          };
        };
        sharedSpacesByFolderId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
      }>;
    };
  } | null;
};

export type CreateFileMutationVariables = Exact<{
  input: CreateFileInput;
}>;

export type CreateFileMutation = {
  __typename: "Mutation";
  createFile: {
    __typename: "CreateFilePayload";
    file: {
      __typename: "File";
      id: any;
      orgId: any;
      spaceId: any | null;
      folderId: any | null;
      name: string;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      tags: Array<string | null>;
      size: any | null;
      isSharedAll: boolean | null;
      isFavorite: boolean | null;
      isSharedExplicit: boolean | null;
      fileProcessingStatus: JobStatus | null;
      createdAt: any | null;
      expireAt: any | null;
      availableAt: any | null;
      castedScreenByFileId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          device: any | null;
          spaceId: any;
        }>;
      };
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    } | null;
  } | null;
};

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput;
}>;

export type CreateFolderMutation = {
  __typename: "Mutation";
  createFolder: {
    __typename: "CreateFolderPayload";
    folder: {
      __typename: "Folder";
      parentId: any | null;
      name: string;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      spaceId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isShared: boolean | null;
      isSharedAll: boolean | null;
      foldersByParentId: {
        __typename: "FoldersConnection";
        totalCount: number;
      };
      filesByFolderId: {
        __typename: "FilesConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        }>;
      };
      sharedSpacesByFolderId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
    } | null;
  } | null;
};

export type DeleteFolderByIdsMutationVariables = Exact<{
  input: DeleteFolderByIdsInput;
}>;

export type DeleteFolderByIdsMutation = {
  __typename: "Mutation";
  deleteFolderByIds: {
    __typename: "DeleteFolderByIdsPayload";
    folders: Array<{
      __typename: "Folder";
      parentId: any | null;
      name: string;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      spaceId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isShared: boolean | null;
      isSharedAll: boolean | null;
    }> | null;
  } | null;
};

export type FileByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type FileByIdQuery = {
  __typename: "Query";
  fileById: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    castedScreenByFileId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        device: any | null;
      }>;
    };
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
    userByCreatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
};

export type FileByIdAssociationsQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type FileByIdAssociationsQuery = {
  __typename: "Query";
  fileById: {
    __typename: "File";
    associationsByToFileAndOrgId: {
      __typename: "AssociationsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Association";
        castByFromCastAndOrgId: {
          __typename: "Cast";
          id: any;
          screensByCastId: {
            __typename: "ScreensConnection";
            nodes: Array<{ __typename: "Screen"; id: any; name: string }>;
          };
        } | null;
        channelByFromChannelAndOrgId: {
          __typename: "Channel";
          id: any;
          name: string;
          draft: { __typename: "Channel"; id: any; name: string } | null;
        } | null;
        playlistByFromPlaylistAndOrgId: {
          __typename: "Playlist";
          id: any;
          name: string;
          draft: { __typename: "Playlist"; id: any; name: string } | null;
        } | null;
        appInstanceByFromAppInstanceAndOrgId: {
          __typename: "AppInstance";
          id: any;
          name: string | null;
        } | null;
        screenByFromScreenAndOrgId: {
          __typename: "Screen";
          id: any;
          name: string;
        } | null;
      }>;
    };
  } | null;
};

export type FileByIdExtendedAssociationsQueryVariables = Exact<{
  id: Scalars["UUID"];
  spaceId: Scalars["UUID"];
}>;

export type FileByIdExtendedAssociationsQuery = {
  __typename: "Query";
  fileById: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    castedScreenByFileId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        device: any | null;
      }>;
    };
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
    userByCreatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
    associationsByFileAndSpaceId: {
      __typename: "AssociationsConnection";
      nodes: Array<{
        __typename: "Association";
        channelByFromChannelAndOrgId: {
          __typename: "Channel";
          id: any;
          name: string;
          coverData: any | null;
          fileByCoverImageId: {
            __typename: "File";
            source: string | null;
          } | null;
          draft: { __typename: "Channel"; id: any; name: string } | null;
        } | null;
        playlistByFromPlaylistAndOrgId: {
          __typename: "Playlist";
          id: any;
          name: string;
          color: string;
          draft: { __typename: "Playlist"; id: any; name: string } | null;
        } | null;
      }>;
    };
  } | null;
};

export type FileByIdExtendedQueryStringQueryVariables = Exact<{
  fileId: Scalars["UUID"];
}>;

export type FileByIdExtendedQueryStringQuery = {
  __typename: "Query";
  fileById: {
    __typename: "File";
    id: any;
    orgId: any;
    fileProcessingStatus: JobStatus | null;
    folderId: any | null;
    mimetype: string | null;
    name: string;
    spaceId: any | null;
    tags: Array<string | null>;
    size: any | null;
    source: string | null;
    metadata: any;
    isFavorite: boolean | null;
    expireAt: any | null;
    availableAt: any | null;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
  } | null;
};

export type FileByIdForContentQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type FileByIdForContentQuery = {
  __typename: "Query";
  fileById: {
    __typename: "File";
    id: any;
    orgId: any;
    spaceId: any | null;
    folderId: any | null;
    name: string;
    metadata: any;
    source: string | null;
    mimetype: string | null;
    tags: Array<string | null>;
    size: any | null;
    isSharedAll: boolean | null;
    isFavorite: boolean | null;
    isSharedExplicit: boolean | null;
    fileProcessingStatus: JobStatus | null;
    createdAt: any | null;
    expireAt: any | null;
    availableAt: any | null;
    castedScreenByFileId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        device: any | null;
        spaceId: any;
      }>;
    };
    fileOutputsByFileId: {
      __typename: "FileOutputsConnection";
      nodes: Array<{
        __typename: "FileOutput";
        id: any;
        content: any | null;
        metadata: any | null;
        mimetype: string | null;
        url: string | null;
        size: any | null;
      }>;
    };
    userByCreatedBy: {
      __typename: "User";
      id: any;
      orgId: any;
      email: string | null;
      status: UserStatus;
      userType: UserType | null;
      givenName: string;
      familyName: string;
      receiveUpdates: ReceiveUpdates;
      isOwner: boolean | null;
      metadata: any | null;
      preferences: any | null;
      permissions: Array<string | null> | null;
      permissionsByGroupIds: any | null;
      permissionsBySpaceIds: any | null;
      deletedAt: any | null;
      deletedBy: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      lastAuthenticatedAt: any | null;
    } | null;
  } | null;
};

export type FolderByIdQueryVariables = Exact<{
  folderId: Scalars["UUID"];
  fileOrderBy: InputMaybe<Array<FilesOrderBy> | FilesOrderBy>;
  folderOrderBy: InputMaybe<Array<FoldersOrderBy> | FoldersOrderBy>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type FolderByIdQuery = {
  __typename: "Query";
  folderById: {
    __typename: "Folder";
    parentId: any | null;
    name: string;
    isFavorite: boolean | null;
    tags: Array<string | null>;
    spaceId: any;
    id: any;
    orgId: any;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    isShared: boolean | null;
    isSharedAll: boolean | null;
    foldersByParentId: {
      __typename: "FoldersConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Folder";
        parentId: any | null;
        name: string;
        isFavorite: boolean | null;
        tags: Array<string | null>;
        spaceId: any;
        id: any;
        orgId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isShared: boolean | null;
        isSharedAll: boolean | null;
        foldersByParentId: {
          __typename: "FoldersConnection";
          totalCount: number;
        };
        filesByFolderId: { __typename: "FilesConnection"; totalCount: number };
        sharedSpacesByFolderId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
      }>;
    };
    filesByFolderId: {
      __typename: "FilesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        associationsByToFileAndOrgId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              name: string;
              coverData: any | null;
              fileByCoverImageId: {
                __typename: "File";
                source: string | null;
              } | null;
              draft: { __typename: "Channel"; id: any; name: string } | null;
            } | null;
            playlistByFromPlaylistAndOrgId: {
              __typename: "Playlist";
              id: any;
              name: string;
              color: string;
              draft: { __typename: "Playlist"; id: any; name: string } | null;
            } | null;
          }>;
        };
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type FolderByIdExtendedAssociationsQueryVariables = Exact<{
  folderId: Scalars["UUID"];
  spaceId: Scalars["UUID"];
  fileOrderBy: InputMaybe<Array<FilesOrderBy> | FilesOrderBy>;
  folderOrderBy: InputMaybe<Array<FoldersOrderBy> | FoldersOrderBy>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type FolderByIdExtendedAssociationsQuery = {
  __typename: "Query";
  folderById: {
    __typename: "Folder";
    parentId: any | null;
    name: string;
    isFavorite: boolean | null;
    tags: Array<string | null>;
    spaceId: any;
    id: any;
    orgId: any;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    isShared: boolean | null;
    isSharedAll: boolean | null;
    foldersByParentId: {
      __typename: "FoldersConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Folder";
        parentId: any | null;
        name: string;
        isFavorite: boolean | null;
        tags: Array<string | null>;
        spaceId: any;
        id: any;
        orgId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isShared: boolean | null;
        isSharedAll: boolean | null;
        foldersByParentId: {
          __typename: "FoldersConnection";
          totalCount: number;
        };
        filesByFolderId: { __typename: "FilesConnection"; totalCount: number };
        sharedSpacesByFolderId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
      }>;
    };
    filesByFolderId: {
      __typename: "FilesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        spaceId: any | null;
        folderId: any | null;
        name: string;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        tags: Array<string | null>;
        size: any | null;
        isSharedAll: boolean | null;
        isFavorite: boolean | null;
        isSharedExplicit: boolean | null;
        fileProcessingStatus: JobStatus | null;
        createdAt: any | null;
        expireAt: any | null;
        availableAt: any | null;
        associationsByFileAndSpaceId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              name: string;
              coverData: any | null;
              fileByCoverImageId: {
                __typename: "File";
                source: string | null;
              } | null;
              draft: { __typename: "Channel"; id: any; name: string } | null;
            } | null;
            playlistByFromPlaylistAndOrgId: {
              __typename: "Playlist";
              id: any;
              name: string;
              color: string;
              draft: { __typename: "Playlist"; id: any; name: string } | null;
            } | null;
          }>;
        };
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type FolderByIdFilterByMimeTypeQueryVariables = Exact<{
  folderId: Scalars["UUID"];
  fileOrderBy: InputMaybe<Array<FilesOrderBy> | FilesOrderBy>;
  folderOrderBy: InputMaybe<Array<FoldersOrderBy> | FoldersOrderBy>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  allowMimeType: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type FolderByIdFilterByMimeTypeQuery = {
  __typename: "Query";
  folderById: {
    __typename: "Folder";
    parentId: any | null;
    name: string;
    isFavorite: boolean | null;
    tags: Array<string | null>;
    spaceId: any;
    id: any;
    orgId: any;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    isShared: boolean | null;
    isSharedAll: boolean | null;
    foldersByParentId: {
      __typename: "FoldersConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Folder";
        parentId: any | null;
        name: string;
        isFavorite: boolean | null;
        tags: Array<string | null>;
        spaceId: any;
        id: any;
        orgId: any;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isShared: boolean | null;
        isSharedAll: boolean | null;
        foldersByParentId: {
          __typename: "FoldersConnection";
          totalCount: number;
        };
        filesByFolderId: { __typename: "FilesConnection"; totalCount: number };
        sharedSpacesByFolderId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
      }>;
    };
    filesByFolderId: {
      __typename: "FilesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            device: any | null;
            spaceId: any;
          }>;
        };
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          orgId: any;
          email: string | null;
          status: UserStatus;
          userType: UserType | null;
          givenName: string;
          familyName: string;
          receiveUpdates: ReceiveUpdates;
          isOwner: boolean | null;
          metadata: any | null;
          preferences: any | null;
          permissions: Array<string | null> | null;
          permissionsByGroupIds: any | null;
          permissionsBySpaceIds: any | null;
          deletedAt: any | null;
          deletedBy: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          lastAuthenticatedAt: any | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type FolderByIdRefetchAfterShareQueryVariables = Exact<{
  folderId: Scalars["UUID"];
}>;

export type FolderByIdRefetchAfterShareQuery = {
  __typename: "Query";
  folderById: {
    __typename: "Folder";
    parentId: any | null;
    name: string;
    isFavorite: boolean | null;
    tags: Array<string | null>;
    spaceId: any;
    id: any;
    orgId: any;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    isShared: boolean | null;
    isSharedAll: boolean | null;
    foldersByParentId: { __typename: "FoldersConnection"; totalCount: number };
    filesByFolderId: { __typename: "FilesConnection"; totalCount: number };
    sharedSpacesByFolderId: {
      __typename: "SpacesConnection";
      nodes: Array<{ __typename: "Space"; id: any }>;
    };
  } | null;
};

export type FolderHierarchyQueryVariables = Exact<{
  folderId: Scalars["UUID"];
}>;

export type FolderHierarchyQuery = {
  __typename: "Query";
  folderHierarchy: {
    __typename: "FolderHierarchyDetailsConnection";
    nodes: Array<{
      __typename: "FolderHierarchyDetail";
      id: any | null;
      level: number | null;
      name: string | null;
      namePath: Array<string | null> | null;
      idPath: Array<any | null> | null;
    }>;
  } | null;
};

export type FolderParentsQueryVariables = Exact<{
  folderid: Scalars["UUID"];
}>;

export type FolderParentsQuery = {
  __typename: "Query";
  folderParents: {
    __typename: "FoldersConnection";
    nodes: Array<{
      __typename: "Folder";
      id: any;
      orgId: any;
      name: string;
      parentId: any | null;
      spaceId: any;
    }>;
  } | null;
};

export type GetAllMediasQueryVariables = Exact<{
  folderId: Scalars["UUID"];
}>;

export type GetAllMediasQuery = {
  __typename: "Query";
  folderById: {
    __typename: "Folder";
    id: any;
    name: string;
    foldersByParentId: {
      __typename: "FoldersConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Folder";
        id: any;
        name: string;
        spaceId: any;
        tags: Array<string | null>;
      }>;
    };
    filesByFolderId: {
      __typename: "FilesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "File";
        id: any;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        folderId: any | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            mimetype: string | null;
          }>;
          pageInfo: {
            __typename: "PageInfo";
            hasNextPage: boolean;
            endCursor: any | null;
          };
        };
        castedScreenByFileId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{ __typename: "Screen"; id: any }>;
          pageInfo: {
            __typename: "PageInfo";
            hasNextPage: boolean;
            endCursor: any | null;
          };
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type NestedFilesByFolderIdQueryVariables = Exact<{
  folderId: Scalars["UUID"];
}>;

export type NestedFilesByFolderIdQuery = {
  __typename: "Query";
  nestedFilesByFolderId: {
    __typename: "FilesConnection";
    nodes: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
    }>;
  } | null;
};

export type SearchMediaQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  fileOrderBy: InputMaybe<Array<FilesOrderBy> | FilesOrderBy>;
  folderOrderBy: InputMaybe<Array<FoldersOrderBy> | FoldersOrderBy>;
}>;

export type SearchMediaQuery = {
  __typename: "Query";
  searchFile: {
    __typename: "FilesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "File";
      id: any;
      orgId: any;
      spaceId: any | null;
      folderId: any | null;
      name: string;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      tags: Array<string | null>;
      size: any | null;
      isSharedAll: boolean | null;
      isFavorite: boolean | null;
      isSharedExplicit: boolean | null;
      fileProcessingStatus: JobStatus | null;
      createdAt: any | null;
      expireAt: any | null;
      availableAt: any | null;
      castedScreenByFileId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          device: any | null;
          spaceId: any;
        }>;
      };
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    }>;
  } | null;
  searchFolder: {
    __typename: "FoldersConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Folder";
      parentId: any | null;
      name: string;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      spaceId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isShared: boolean | null;
      isSharedAll: boolean | null;
      foldersByParentId: {
        __typename: "FoldersConnection";
        totalCount: number;
      };
      filesByFolderId: {
        __typename: "FilesConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        }>;
      };
    }>;
  } | null;
};

export type UpdateFileAvailableMutationVariables = Exact<{
  input: UpdateFileAvailableInput;
}>;

export type UpdateFileAvailableMutation = {
  __typename: "Mutation";
  updateFileAvailable: {
    __typename: "UpdateFileAvailablePayload";
    file: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type UpdateFileByIdMutationVariables = Exact<{
  input: UpdateFileByIdInput;
}>;

export type UpdateFileByIdMutation = {
  __typename: "Mutation";
  updateFileById: {
    __typename: "UpdateFileByIdPayload";
    file: {
      __typename: "File";
      id: any;
      orgId: any;
      spaceId: any | null;
      folderId: any | null;
      name: string;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      tags: Array<string | null>;
      size: any | null;
      isSharedAll: boolean | null;
      isFavorite: boolean | null;
      isSharedExplicit: boolean | null;
      fileProcessingStatus: JobStatus | null;
      createdAt: any | null;
      expireAt: any | null;
      availableAt: any | null;
      castedScreenByFileId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          device: any | null;
          spaceId: any;
        }>;
      };
      fileOutputsByFileId: {
        __typename: "FileOutputsConnection";
        nodes: Array<{
          __typename: "FileOutput";
          id: any;
          content: any | null;
          metadata: any | null;
          mimetype: string | null;
          url: string | null;
          size: any | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        orgId: any;
        email: string | null;
        status: UserStatus;
        userType: UserType | null;
        givenName: string;
        familyName: string;
        receiveUpdates: ReceiveUpdates;
        isOwner: boolean | null;
        metadata: any | null;
        preferences: any | null;
        permissions: Array<string | null> | null;
        permissionsByGroupIds: any | null;
        permissionsBySpaceIds: any | null;
        deletedAt: any | null;
        deletedBy: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        lastAuthenticatedAt: any | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateFileExpireMutationVariables = Exact<{
  input: UpdateFileExpireInput;
}>;

export type UpdateFileExpireMutation = {
  __typename: "Mutation";
  updateFileExpire: {
    __typename: "UpdateFileExpirePayload";
    file: {
      __typename: "File";
      id: any;
      orgId: any;
      fileProcessingStatus: JobStatus | null;
      folderId: any | null;
      mimetype: string | null;
      name: string;
      spaceId: any | null;
      tags: Array<string | null>;
      size: any | null;
      source: string | null;
      metadata: any;
      isFavorite: boolean | null;
      expireAt: any | null;
      availableAt: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type UpdateFileIsFavoriteMutationVariables = Exact<{
  input: UpdateFileIsFavoriteInput;
}>;

export type UpdateFileIsFavoriteMutation = {
  __typename: "Mutation";
  updateFileIsFavorite: {
    __typename: "UpdateFileIsFavoritePayload";
    favorites: Array<{
      __typename: "Favorite";
      fileId: any | null;
      fileByFileId: {
        __typename: "File";
        id: any;
        isFavorite: boolean | null;
      } | null;
    }> | null;
  } | null;
};

export type UpdateFolderByIdMutationVariables = Exact<{
  input: UpdateFolderByIdInput;
}>;

export type UpdateFolderByIdMutation = {
  __typename: "Mutation";
  updateFolderById: {
    __typename: "UpdateFolderByIdPayload";
    folder: {
      __typename: "Folder";
      parentId: any | null;
      name: string;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      spaceId: any;
      id: any;
      orgId: any;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isShared: boolean | null;
      isSharedAll: boolean | null;
    } | null;
  } | null;
};

export type UpdateFolderIsFavoriteMutationVariables = Exact<{
  input: UpdateFolderIsFavoriteInput;
}>;

export type UpdateFolderIsFavoriteMutation = {
  __typename: "Mutation";
  updateFolderIsFavorite: {
    __typename: "UpdateFolderIsFavoritePayload";
    favorites: Array<{
      __typename: "Favorite";
      folderId: any | null;
      folderByFolderId: {
        __typename: "Folder";
        id: any;
        isFavorite: boolean | null;
      } | null;
    }> | null;
  } | null;
};

export type MigrationScreenMutationVariables = Exact<{
  input: MigrationScreenInput;
}>;

export type MigrationScreenMutation = {
  __typename: "Mutation";
  migrationScreen: {
    __typename: "MigrationScreenPayload";
    json: any | null;
  } | null;
};

export type MigrationStatusQueryVariables = Exact<{
  orgId: InputMaybe<Scalars["UUID"]>;
}>;

export type MigrationStatusQuery = {
  __typename: "Query";
  migrationStatus: any | null;
};

export type PlaylistByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type PlaylistByIdQuery = {
  __typename: "Query";
  playlistById: {
    __typename: "Playlist";
    id: any;
    tags: Array<string | null>;
    name: string;
    color: string;
    publishedAt: any | null;
    publishedBy: any | null;
    content: any | null;
    isFavorite: boolean | null;
    isPublished: boolean | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isEmbedEnabled: boolean | null;
    isEmbedPublic: boolean | null;
    spaceId: any;
    createdAt: any | null;
    createdBy: any | null;
    embedsByPlaylistId: {
      __typename: "EmbedsConnection";
      nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
    };
    sharedSpacesByPlaylistId: {
      __typename: "SpacesConnection";
      nodes: Array<{ __typename: "Space"; id: any }>;
    };
    castedScreenByPlaylistId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        castId: any | null;
        name: string;
        device: any | null;
      }>;
    };
    draft: {
      __typename: "Playlist";
      id: any;
      tags: Array<string | null>;
      name: string;
      color: string;
      publishedAt: any | null;
      publishedBy: any | null;
      content: any | null;
      isFavorite: boolean | null;
      isPublished: boolean | null;
      appInstancesByPlaylistId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          config: any | null;
          expireAt: any | null;
        }>;
      };
      filesByPlaylistId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        }>;
      };
      linksByPlaylistId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByPlaylistId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          spaceId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          actions: any | null;
          tags: Array<string | null>;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type PlaylistByIdForListCachingQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type PlaylistByIdForListCachingQuery = {
  __typename: "Query";
  playlistById: {
    __typename: "Playlist";
    id: any;
    isFavorite: boolean | null;
    isSharedAll: boolean | null;
    isSharedExplicit: boolean | null;
    isEmbedEnabled: boolean | null;
    isEmbedPublic: boolean | null;
    name: string;
    color: string;
    content: any | null;
    spaceId: any;
    tags: Array<string | null>;
    embedsByPlaylistId: {
      __typename: "EmbedsConnection";
      nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
    };
    castedScreenByPlaylistId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        spaceId: any;
        device: any | null;
        deviceModel: string | null;
        deviceId: string;
      }>;
    };
    draft: {
      __typename: "Playlist";
      id: any;
      tags: Array<string | null>;
      content: any | null;
      isPublished: boolean | null;
      appInstancesByPlaylistId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          fileByThumbnailFileId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          appByAppId: {
            __typename: "App";
            id: any;
            iconUrl: string | null;
          } | null;
        }>;
      };
      filesByPlaylistId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        }>;
      };
      linksByPlaylistId: {
        __typename: "LinksConnection";
        nodes: Array<{
          __typename: "Link";
          id: any;
          name: string;
          fileByFileId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
      sitesByPlaylistId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          name: string;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
    } | null;
    published: {
      __typename: "Playlist";
      id: any;
      tags: Array<string | null>;
      content: any | null;
      isPublished: boolean | null;
      appInstancesByPlaylistId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          fileByThumbnailFileId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          appByAppId: {
            __typename: "App";
            id: any;
            iconUrl: string | null;
          } | null;
        }>;
      };
      filesByPlaylistId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        }>;
      };
      linksByPlaylistId: {
        __typename: "LinksConnection";
        nodes: Array<{
          __typename: "Link";
          id: any;
          name: string;
          fileByFileId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
      sitesByPlaylistId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          name: string;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
    } | null;
    sharedSpacesByPlaylistId: {
      __typename: "SpacesConnection";
      nodes: Array<{ __typename: "Space"; id: any; name: string }>;
    };
    associationsByToPlaylistAndOrgId: {
      __typename: "AssociationsConnection";
      nodes: Array<{
        __typename: "Association";
        channelByFromChannelAndOrgId: {
          __typename: "Channel";
          id: any;
          spaceId: any | null;
          draftOf: any | null;
        } | null;
      }>;
    };
  } | null;
};

export type PublishDraftPlaylistMutationVariables = Exact<{
  input: PublishDraftPlaylistInput;
}>;

export type PublishDraftPlaylistMutation = {
  __typename: "Mutation";
  publishDraftPlaylist: {
    __typename: "PublishDraftPlaylistPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      name: string;
      content: any | null;
      draft: {
        __typename: "Playlist";
        id: any;
        name: string;
        content: any | null;
        isPublished: boolean | null;
      } | null;
      userByPublishedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
      } | null;
    } | null;
  } | null;
};

export type RevertDraftPlaylistMutationVariables = Exact<{
  input: RevertDraftPlaylistInput;
}>;

export type RevertDraftPlaylistMutation = {
  __typename: "Mutation";
  revertDraftPlaylist: {
    __typename: "RevertDraftPlaylistPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      name: string;
      content: any | null;
      draft: {
        __typename: "Playlist";
        id: any;
        name: string;
        content: any | null;
        isPublished: boolean | null;
      } | null;
      userByPublishedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
      } | null;
    } | null;
  } | null;
};

export type UpdatePlaylistMutationVariables = Exact<{
  input: UpdatePlaylistInput;
}>;

export type UpdatePlaylistMutation = {
  __typename: "Mutation";
  updatePlaylist: {
    __typename: "UpdatePlaylistPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      name: string;
      draft: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
    } | null;
  } | null;
};

export type UpdatePlaylistColorMutationVariables = Exact<{
  input: UpdatePlaylistColorInput;
}>;

export type UpdatePlaylistColorMutation = {
  __typename: "Mutation";
  updatePlaylistColor: {
    __typename: "UpdatePlaylistColorPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      name: string;
      color: string;
    } | null;
  } | null;
};

export type UpdatePlaylistTagsMutationVariables = Exact<{
  input: UpdatePlaylistTagsInput;
}>;

export type UpdatePlaylistTagsMutation = {
  __typename: "Mutation";
  updatePlaylistTags: {
    __typename: "UpdatePlaylistTagsPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      name: string;
      tags: Array<string | null>;
    } | null;
  } | null;
};

export type AllPlaylistListQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type AllPlaylistListQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedPlaylistsBySpaceId: {
      __typename: "PlaylistsConnection";
      nodes: Array<{ __typename: "Playlist"; id: any; spaceId: any }>;
    };
  } | null;
};

export type AllSearchPlaylistQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type AllSearchPlaylistQuery = {
  __typename: "Query";
  searchPlaylist: {
    __typename: "PlaylistsConnection";
    nodes: Array<{ __typename: "Playlist"; id: any; spaceId: any }>;
  } | null;
};

export type CreatePlaylistMutationVariables = Exact<{
  input: CreatePlaylistInput;
}>;

export type CreatePlaylistMutation = {
  __typename: "Mutation";
  createPlaylist: {
    __typename: "CreatePlaylistPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      isFavorite: boolean | null;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      name: string;
      color: string;
      content: any | null;
      spaceId: any;
      tags: Array<string | null>;
      embedsByPlaylistId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByPlaylistId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
          device: any | null;
          deviceModel: string | null;
          deviceId: string;
        }>;
      };
      draft: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      published: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      sharedSpacesByPlaylistId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any; name: string }>;
      };
      associationsByToPlaylistAndOrgId: {
        __typename: "AssociationsConnection";
        nodes: Array<{
          __typename: "Association";
          channelByFromChannelAndOrgId: {
            __typename: "Channel";
            id: any;
            spaceId: any | null;
            draftOf: any | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type DeletePlaylistMutationVariables = Exact<{
  input: DeletePlaylistInput;
}>;

export type DeletePlaylistMutation = {
  __typename: "Mutation";
  deletePlaylist: {
    __typename: "DeletePlaylistPayload";
    playlist: { __typename: "Playlist"; id: any; name: string } | null;
  } | null;
};

export type DuplicatePlaylistMutationVariables = Exact<{
  input: DuplicatePlaylistInput;
}>;

export type DuplicatePlaylistMutation = {
  __typename: "Mutation";
  duplicatePlaylist: {
    __typename: "DuplicatePlaylistPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      isFavorite: boolean | null;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      name: string;
      color: string;
      content: any | null;
      spaceId: any;
      tags: Array<string | null>;
      embedsByPlaylistId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByPlaylistId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
          device: any | null;
          deviceModel: string | null;
          deviceId: string;
        }>;
      };
      draft: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      published: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      sharedSpacesByPlaylistId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any; name: string }>;
      };
      associationsByToPlaylistAndOrgId: {
        __typename: "AssociationsConnection";
        nodes: Array<{
          __typename: "Association";
          channelByFromChannelAndOrgId: {
            __typename: "Channel";
            id: any;
            spaceId: any | null;
            draftOf: any | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type GetTotalPlaylistsQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type GetTotalPlaylistsQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedPlaylistsBySpaceId: {
      __typename: "PlaylistsConnection";
      totalCount: number;
    };
  } | null;
};

export type PlaylistByIdForContentQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type PlaylistByIdForContentQuery = {
  __typename: "Query";
  playlistById: {
    __typename: "Playlist";
    id: any;
    orgId: any;
    spaceId: any;
    name: string;
    color: string;
    content: any | null;
    isPublished: boolean | null;
    draft: {
      __typename: "Playlist";
      id: any;
      isPublished: boolean | null;
    } | null;
    appInstancesByPlaylistId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        config: any | null;
        fileByThumbnailFileId: {
          __typename: "File";
          id: any;
          metadata: any;
          mimetype: string | null;
          fileProcessingStatus: JobStatus | null;
          source: string | null;
        } | null;
        appByAppId: {
          __typename: "App";
          id: any;
          name: string;
          iconUrl: string | null;
        } | null;
      }>;
    };
    filesByPlaylistId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        metadata: any;
        mimetype: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    linksByPlaylistId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        fileByFileId: {
          __typename: "File";
          id: any;
          metadata: any;
          mimetype: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      }>;
    };
    sitesByPlaylistId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          metadata: any;
          mimetype: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      }>;
    };
  } | null;
};

export type PlaylistByIdForListingQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type PlaylistByIdForListingQuery = {
  __typename: "Query";
  playlistById: {
    __typename: "Playlist";
    id: any;
    tags: Array<string | null>;
    color: string;
    content: any | null;
    spaceId: any;
    isFavorite: boolean | null;
    isSharedExplicit: boolean | null;
    isSharedAll: boolean | null;
    isEmbedEnabled: boolean | null;
    isEmbedPublic: boolean | null;
    embedsByPlaylistId: {
      __typename: "EmbedsConnection";
      nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
    };
    sharedSpacesByPlaylistId: {
      __typename: "SpacesConnection";
      nodes: Array<{ __typename: "Space"; id: any }>;
    };
    castedScreenByPlaylistId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        spaceId: any;
        device: any | null;
      }>;
    };
    draft: {
      __typename: "Playlist";
      id: any;
      tags: Array<string | null>;
      content: any | null;
      isPublished: boolean | null;
      appInstancesByPlaylistId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          config: any | null;
          fileByThumbnailFileId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          appByAppId: {
            __typename: "App";
            id: any;
            iconUrl: string | null;
          } | null;
        }>;
      };
      filesByPlaylistId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        }>;
      };
      linksByPlaylistId: {
        __typename: "LinksConnection";
        nodes: Array<{
          __typename: "Link";
          id: any;
          name: string;
          fileByFileId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
      sitesByPlaylistId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          name: string;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
    } | null;
    published: {
      __typename: "Playlist";
      id: any;
      tags: Array<string | null>;
      content: any | null;
      isPublished: boolean | null;
      appInstancesByPlaylistId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
          config: any | null;
          fileByThumbnailFileId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          appByAppId: {
            __typename: "App";
            id: any;
            iconUrl: string | null;
          } | null;
        }>;
      };
      filesByPlaylistId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          metadata: any;
          mimetype: string | null;
          source: string | null;
          expireAt: any | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        }>;
      };
      linksByPlaylistId: {
        __typename: "LinksConnection";
        nodes: Array<{
          __typename: "Link";
          id: any;
          name: string;
          fileByFileId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
      sitesByPlaylistId: {
        __typename: "SitesConnection";
        nodes: Array<{
          __typename: "Site";
          id: any;
          name: string;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type PlaylistListQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type PlaylistListQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedPlaylistsBySpaceId: {
      __typename: "PlaylistsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        isFavorite: boolean | null;
        isSharedAll: boolean | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        color: string;
        content: any | null;
        spaceId: any;
        tags: Array<string | null>;
        embedsByPlaylistId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByPlaylistId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
            deviceId: string;
          }>;
        };
        draft: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        published: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        sharedSpacesByPlaylistId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any; name: string }>;
        };
        associationsByToPlaylistAndOrgId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              spaceId: any | null;
              draftOf: any | null;
            } | null;
          }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type PlaylistSharedListQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type PlaylistSharedListQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    publishedPlaylistsBySpaceId: {
      __typename: "PlaylistsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        isFavorite: boolean | null;
        isSharedAll: boolean | null;
        isSharedExplicit: boolean | null;
        isEmbedEnabled: boolean | null;
        isEmbedPublic: boolean | null;
        name: string;
        color: string;
        content: any | null;
        spaceId: any;
        tags: Array<string | null>;
        embedsByPlaylistId: {
          __typename: "EmbedsConnection";
          nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
        };
        castedScreenByPlaylistId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
            deviceId: string;
          }>;
        };
        draft: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        published: {
          __typename: "Playlist";
          id: any;
          tags: Array<string | null>;
          content: any | null;
          isPublished: boolean | null;
          appInstancesByPlaylistId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              fileByThumbnailFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              appByAppId: {
                __typename: "App";
                id: any;
                iconUrl: string | null;
              } | null;
            }>;
          };
          filesByPlaylistId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            }>;
          };
          linksByPlaylistId: {
            __typename: "LinksConnection";
            nodes: Array<{
              __typename: "Link";
              id: any;
              name: string;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
          sitesByPlaylistId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              name: string;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                metadata: any;
                mimetype: string | null;
                source: string | null;
                expireAt: any | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
            }>;
          };
        } | null;
        sharedSpacesByPlaylistId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any; name: string }>;
        };
        associationsByToPlaylistAndOrgId: {
          __typename: "AssociationsConnection";
          nodes: Array<{
            __typename: "Association";
            channelByFromChannelAndOrgId: {
              __typename: "Channel";
              id: any;
              spaceId: any | null;
              draftOf: any | null;
            } | null;
          }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type SearchPlaylistQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  first: InputMaybe<Scalars["Int"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  playlistFilter: InputMaybe<PlaylistFilter>;
}>;

export type SearchPlaylistQuery = {
  __typename: "Query";
  searchPlaylist: {
    __typename: "PlaylistsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Playlist";
      id: any;
      isFavorite: boolean | null;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      name: string;
      color: string;
      content: any | null;
      spaceId: any;
      tags: Array<string | null>;
      embedsByPlaylistId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByPlaylistId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
          device: any | null;
          deviceModel: string | null;
          deviceId: string;
        }>;
      };
      draft: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      published: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      sharedSpacesByPlaylistId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any; name: string }>;
      };
      associationsByToPlaylistAndOrgId: {
        __typename: "AssociationsConnection";
        nodes: Array<{
          __typename: "Association";
          channelByFromChannelAndOrgId: {
            __typename: "Channel";
            id: any;
            spaceId: any | null;
            draftOf: any | null;
          } | null;
        }>;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type UpdatePlaylistEmbedMutationVariables = Exact<{
  input: UpdatePlaylistEmbedInput;
}>;

export type UpdatePlaylistEmbedMutation = {
  __typename: "Mutation";
  updatePlaylistEmbed: {
    __typename: "UpdatePlaylistEmbedPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
    } | null;
  } | null;
};

export type UpdatePlaylistIsFavoriteMutationVariables = Exact<{
  input: UpdatePlaylistIsFavoriteInput;
}>;

export type UpdatePlaylistIsFavoriteMutation = {
  __typename: "Mutation";
  updatePlaylistIsFavorite: {
    __typename: "UpdatePlaylistIsFavoritePayload";
    favorites: Array<{
      __typename: "Favorite";
      playlistId: any | null;
      playlistByPlaylistId: {
        __typename: "Playlist";
        id: any;
        isFavorite: boolean | null;
      } | null;
    }> | null;
  } | null;
};

export type UpdatePlaylistNameMutationVariables = Exact<{
  input: UpdatePlaylistNameInput;
}>;

export type UpdatePlaylistNameMutation = {
  __typename: "Mutation";
  updatePlaylistName: {
    __typename: "UpdatePlaylistNamePayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      isFavorite: boolean | null;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
      isEmbedEnabled: boolean | null;
      isEmbedPublic: boolean | null;
      name: string;
      color: string;
      content: any | null;
      spaceId: any;
      tags: Array<string | null>;
      embedsByPlaylistId: {
        __typename: "EmbedsConnection";
        nodes: Array<{ __typename: "Embed"; id: any; shortId: string }>;
      };
      castedScreenByPlaylistId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
          device: any | null;
          deviceModel: string | null;
          deviceId: string;
        }>;
      };
      draft: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      published: {
        __typename: "Playlist";
        id: any;
        tags: Array<string | null>;
        content: any | null;
        isPublished: boolean | null;
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
            fileByThumbnailFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            appByAppId: {
              __typename: "App";
              id: any;
              iconUrl: string | null;
            } | null;
          }>;
        };
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            metadata: any;
            mimetype: string | null;
            source: string | null;
            expireAt: any | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              metadata: any;
              mimetype: string | null;
              source: string | null;
              expireAt: any | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
          }>;
        };
      } | null;
      sharedSpacesByPlaylistId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any; name: string }>;
      };
      associationsByToPlaylistAndOrgId: {
        __typename: "AssociationsConnection";
        nodes: Array<{
          __typename: "Association";
          channelByFromChannelAndOrgId: {
            __typename: "Channel";
            id: any;
            spaceId: any | null;
            draftOf: any | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type UpdateSharePlaylistToAllSpacesMutationVariables = Exact<{
  input: UpdateSharePlaylistToAllSpacesInput;
}>;

export type UpdateSharePlaylistToAllSpacesMutation = {
  __typename: "Mutation";
  updateSharePlaylistToAllSpaces: {
    __typename: "UpdateSharePlaylistToAllSpacesPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
      sharedSpacesByPlaylistId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
    } | null;
  } | null;
};

export type UpdateSharePlaylistToSpacesMutationVariables = Exact<{
  input: UpdateSharePlaylistToSpacesInput;
}>;

export type UpdateSharePlaylistToSpacesMutation = {
  __typename: "Mutation";
  updateSharePlaylistToSpaces: {
    __typename: "UpdateSharePlaylistToSpacesPayload";
    playlist: {
      __typename: "Playlist";
      id: any;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      sharedSpacesByPlaylistId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
    } | null;
  } | null;
};

export type CreateScreenGroupMutationVariables = Exact<{
  input: CreateScreenGroupInput;
}>;

export type CreateScreenGroupMutation = {
  __typename: "Mutation";
  createScreenGroup: {
    __typename: "CreateScreenGroupPayload";
    screenGroup: {
      __typename: "ScreenGroup";
      id: any;
      name: string;
      isPrimary: boolean;
      screensByScreenGroupIdAndOrgId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          orgId: any;
          screenGroupId: any;
          castId: any | null;
          name: string;
          content: any | null;
          status: ScreenStatus;
          device: any | null;
          preview: boolean;
          tags: Array<string | null>;
          preferences: any;
          screenGroupByScreenGroupId: {
            __typename: "ScreenGroup";
            id: any;
            name: string;
            isPrimary: boolean;
          } | null;
        }>;
        pageInfo: {
          __typename: "PageInfo";
          hasNextPage: boolean;
          endCursor: any | null;
        };
      };
    } | null;
  } | null;
};

export type ExchangePreviewTokenMutationVariables = Exact<{
  screenId: Scalars["UUID"];
}>;

export type ExchangePreviewTokenMutation = {
  __typename: "Mutation";
  exchangePreviewToken: {
    __typename: "ExchangePreviewTokenPayload";
    previewToken: string | null;
  } | null;
};

export type RefreshScreenMutationVariables = Exact<{
  input: RefreshScreenInput;
}>;

export type RefreshScreenMutation = {
  __typename: "Mutation";
  refreshScreen: {
    __typename: "RefreshScreenPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      castByCastId: {
        __typename: "Cast";
        id: any;
        orgId: any;
        spaceId: any;
        priority: number | null;
        content: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        expiresAt: any | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          orgId: any;
          url: string;
          createdAt: any | null;
          linkType: LinkType;
          cloudConfig: any | null;
          isFavorite: boolean | null;
          name: string;
          tags: Array<string | null>;
          cacheBusting: boolean | null;
          qrcodeEnabled: boolean | null;
          qrcodeConfig: any | null;
          autoReload: boolean | null;
          autoReloadDurationMs: number | null;
          params: any | null;
          spaceId: any;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          isFavorite: boolean | null;
          tags: Array<string | null>;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          castedScreenBySiteId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              spaceId: any;
              device: any | null;
              deviceModel: string | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            givenName: string;
            familyName: string;
          } | null;
          sharedSpacesBySiteId: {
            __typename: "SpacesConnection";
            nodes: Array<{ __typename: "Space"; id: any }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          orgId: any;
          spaceId: any;
          tags: Array<string | null>;
          name: string;
          color: string;
          publishedAt: any | null;
          publishedBy: any | null;
          content: any | null;
          isFavorite: boolean | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
      } | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type ScreenByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ScreenByIdQuery = {
  __typename: "Query";
  screenById: {
    __typename: "Screen";
    id: any;
    orgId: any;
    screenGroupId: any;
    castId: any | null;
    name: string;
    content: any | null;
    status: ScreenStatus;
    preview: boolean;
    device: any | null;
    deviceInfo: any | null;
    deviceIpAddress: string | null;
    deviceHostname: string | null;
    rotation: ScreenRotation;
    env: any;
    deviceId: string;
    deviceModel: string | null;
    devicePlatform: string | null;
    playerTimezone: string;
    playerHeight: number | null;
    playerWidth: number | null;
    timezoneOverride: string;
    additionalPlayerSettings: any;
    deviceProvider: string | null;
    preferences: any;
    operatingHours: any | null;
    tags: Array<string | null>;
    spaceId: any;
    playbackMode: string;
    videoSource: string;
    highResolutionImage: boolean;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    isConnected: boolean;
    connectedAt: any | null;
    disconnectedAt: any | null;
    isNotificationActive: boolean | null;
    messages: Array<any | null> | null;
    orgByOrgId: {
      __typename: "Org";
      id: any;
      brandChannelId: any | null;
    } | null;
    castByCastId: {
      __typename: "Cast";
      id: any;
      content: any | null;
      channelByCastId: { __typename: "Channel"; id: any; name: string } | null;
      fileByCastId: {
        __typename: "File";
        id: any;
        folderId: any | null;
        name: string;
        mimetype: string | null;
        metadata: any;
        source: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      playlistByCastId: {
        __typename: "Playlist";
        id: any;
        name: string;
      } | null;
      linkByCastId: {
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        } | null;
      } | null;
      siteByCastId: {
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      } | null;
      appInstanceByCastId: {
        __typename: "AppInstance";
        id: any;
        name: string | null;
        config: any | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          manifestJson: any | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
        appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
      } | null;
    } | null;
    spaceBySpaceId: { __typename: "Space"; id: any; preferences: any } | null;
    screenNotesByScreenId: {
      __typename: "ScreenNotesConnection";
      nodes: Array<{
        __typename: "ScreenNote";
        id: any;
        note: string | null;
        updatedAt: any | null;
        userByUpdatedBy: {
          __typename: "User";
          givenName: string;
          familyName: string;
        } | null;
      }>;
    };
  } | null;
};

export type ScreenDetailQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type ScreenDetailQuery = {
  __typename: "Query";
  screenById: {
    __typename: "Screen";
    id: any;
    orgId: any;
    screenGroupId: any;
    castId: any | null;
    name: string;
    content: any | null;
    status: ScreenStatus;
    preview: boolean;
    device: any | null;
    deviceInfo: any | null;
    deviceIpAddress: string | null;
    deviceHostname: string | null;
    rotation: ScreenRotation;
    env: any;
    deviceId: string;
    deviceModel: string | null;
    devicePlatform: string | null;
    playerTimezone: string;
    playerHeight: number | null;
    playerWidth: number | null;
    timezoneOverride: string;
    additionalPlayerSettings: any;
    deviceProvider: string | null;
    preferences: any;
    operatingHours: any | null;
    tags: Array<string | null>;
    spaceId: any;
    playbackMode: string;
    videoSource: string;
    highResolutionImage: boolean;
    createdAt: any | null;
    createdBy: any | null;
    updatedAt: any | null;
    updatedBy: any | null;
    isConnected: boolean;
    connectedAt: any | null;
    disconnectedAt: any | null;
    isNotificationActive: boolean | null;
    messages: Array<any | null> | null;
    screenGroupByScreenGroupId: {
      __typename: "ScreenGroup";
      id: any;
      name: string;
    } | null;
    castByCastId: {
      __typename: "Cast";
      id: any;
      content: any | null;
      channelByCastId: {
        __typename: "Channel";
        childOf: any | null;
        id: any;
        orgId: any;
        spaceId: any | null;
        themeId: any | null;
        tags: Array<string | null>;
        name: string;
        publishedAt: any | null;
        publishedBy: any | null;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        hiddenZone: boolean | null;
        content: any | null;
        isPublished: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isParent: boolean | null;
        playingScreenNum: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        draft: {
          __typename: "Channel";
          id: any;
          orgId: any;
          spaceId: any | null;
          themeId: any | null;
          tags: Array<string | null>;
          name: string;
          publishedAt: any | null;
          publishedBy: any | null;
          coverData: any | null;
          coverImageId: any | null;
          layoutId: any | null;
          hiddenZone: boolean | null;
          content: any | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isParent: boolean | null;
          playingScreenNum: any | null;
          width: number | null;
          height: number | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          layoutByChannel: {
            __typename: "Layout";
            id: any;
            orgId: any;
            spaceId: any;
            name: string;
            zones: number;
            config: any | null;
            width: number | null;
            height: number | null;
            isFlexible: boolean | null;
            isScalable: boolean | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
          playlistsByChannelId: {
            __typename: "PlaylistsConnection";
            nodes: Array<{
              __typename: "Playlist";
              color: string;
              content: any | null;
              id: any;
              isFavorite: boolean | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              isEmbedEnabled: boolean | null;
              isEmbedPublic: boolean | null;
              name: string;
              spaceId: any;
              tags: Array<string | null>;
              draft: {
                __typename: "Playlist";
                isPublished: boolean | null;
              } | null;
            }>;
          };
          filesByChannelId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              expireAt: any | null;
            }>;
          };
          linksByChannelId: {
            __typename: "LinksConnection";
            nodes: Array<{ __typename: "Link"; id: any; name: string }>;
          };
          sitesByChannelId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              orgId: any;
              name: string;
              type: SiteType;
              url: string;
              config: any | null;
              actions: any | null;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                orgId: any;
                metadata: any;
                source: string | null;
                mimetype: string | null;
              } | null;
            }>;
          };
          appInstancesByChannelId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              expireAt: any | null;
            }>;
          };
        } | null;
        published: {
          __typename: "Channel";
          id: any;
          orgId: any;
          spaceId: any | null;
          themeId: any | null;
          tags: Array<string | null>;
          name: string;
          publishedAt: any | null;
          publishedBy: any | null;
          coverData: any | null;
          coverImageId: any | null;
          layoutId: any | null;
          hiddenZone: boolean | null;
          content: any | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isParent: boolean | null;
          playingScreenNum: any | null;
          width: number | null;
          height: number | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          layoutByChannel: {
            __typename: "Layout";
            id: any;
            orgId: any;
            spaceId: any;
            name: string;
            zones: number;
            config: any | null;
            width: number | null;
            height: number | null;
            isFlexible: boolean | null;
            isScalable: boolean | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
          playlistsByChannelId: {
            __typename: "PlaylistsConnection";
            nodes: Array<{
              __typename: "Playlist";
              color: string;
              content: any | null;
              id: any;
              isFavorite: boolean | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              isEmbedEnabled: boolean | null;
              isEmbedPublic: boolean | null;
              name: string;
              spaceId: any;
              tags: Array<string | null>;
              draft: {
                __typename: "Playlist";
                isPublished: boolean | null;
              } | null;
            }>;
          };
          filesByChannelId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              expireAt: any | null;
            }>;
          };
          linksByChannelId: {
            __typename: "LinksConnection";
            nodes: Array<{ __typename: "Link"; id: any; name: string }>;
          };
          sitesByChannelId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              orgId: any;
              name: string;
              type: SiteType;
              url: string;
              config: any | null;
              actions: any | null;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                orgId: any;
                metadata: any;
                source: string | null;
                mimetype: string | null;
              } | null;
            }>;
          };
          appInstancesByChannelId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              expireAt: any | null;
            }>;
          };
        } | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          zones: number;
          config: any | null;
          width: number | null;
          height: number | null;
          isFlexible: boolean | null;
          isScalable: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        playlistsByChannelId: {
          __typename: "PlaylistsConnection";
          nodes: Array<{
            __typename: "Playlist";
            id: any;
            name: string;
            content: any | null;
            draft: {
              __typename: "Playlist";
              id: any;
              isPublished: boolean | null;
            } | null;
            filesByPlaylistId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                name: string;
                mimetype: string | null;
                source: string | null;
                metadata: any;
                fileProcessingStatus: JobStatus | null;
                availableAt: any | null;
                expireAt: any | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              }>;
            };
            appInstancesByPlaylistId: {
              __typename: "AppInstancesConnection";
              nodes: Array<{
                __typename: "AppInstance";
                id: any;
                config: any | null;
                state: any | null;
                version: string | null;
                name: string | null;
                filesByAppInstanceId: {
                  __typename: "FilesConnection";
                  nodes: Array<{
                    __typename: "File";
                    id: any;
                    orgId: any;
                    name: string;
                    mimetype: string | null;
                    source: string | null;
                    metadata: any;
                    fileProcessingStatus: JobStatus | null;
                    availableAt: any | null;
                    expireAt: any | null;
                  }>;
                };
                appInstallByAppInstallId: {
                  __typename: "AppInstall";
                  appId: any;
                  id: any;
                  orgId: any;
                } | null;
                appVersionByAppInstanceId: {
                  __typename: "AppVersion";
                  id: any;
                  version: string | null;
                  manifestJson: any | null;
                  viewerUrl: string | null;
                  appByAppId: {
                    __typename: "App";
                    id: any;
                    name: string;
                    iconUrl: string | null;
                  } | null;
                } | null;
              }>;
            };
            linksByPlaylistId: {
              __typename: "LinksConnection";
              nodes: Array<{
                __typename: "Link";
                id: any;
                name: string;
                url: string;
                linkType: LinkType;
                cloudConfig: any | null;
              }>;
            };
            sitesByPlaylistId: {
              __typename: "SitesConnection";
              nodes: Array<{
                __typename: "Site";
                id: any;
                name: string;
                type: SiteType;
                url: string;
                config: any | null;
                compute: any | null;
                actions: any | null;
              }>;
            };
          }>;
        };
        filesByChannelId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            mimetype: string | null;
            source: string | null;
            metadata: any;
            fileProcessingStatus: JobStatus | null;
            availableAt: any | null;
            expireAt: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByChannelId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
            } | null;
          }>;
        };
        sitesByChannelId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            orgId: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            actions: any | null;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              orgId: any;
              metadata: any;
              source: string | null;
              mimetype: string | null;
            } | null;
          }>;
        };
        appInstancesByChannelId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            config: any | null;
            state: any | null;
            version: string | null;
            name: string | null;
            filesByAppInstanceId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                name: string;
                mimetype: string | null;
                source: string | null;
                metadata: any;
                fileProcessingStatus: JobStatus | null;
                availableAt: any | null;
                expireAt: any | null;
              }>;
            };
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              appId: any;
              id: any;
              orgId: any;
            } | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              version: string | null;
              manifestJson: any | null;
              viewerUrl: string | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
          }>;
        };
        parentChannelByChildOf: {
          __typename: "ParentChannelByChildOf";
          id: any | null;
          name: string | null;
          spaceId: any | null;
          spaceName: string | null;
          content: any | null;
        } | null;
      } | null;
      fileByCastId: {
        __typename: "File";
        id: any;
        folderId: any | null;
        name: string;
        mimetype: string | null;
        metadata: any;
        source: string | null;
        fileProcessingStatus: JobStatus | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      playlistByCastId: {
        __typename: "Playlist";
        id: any;
        name: string;
        content: any | null;
        draft: {
          __typename: "Playlist";
          id: any;
          isPublished: boolean | null;
        } | null;
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            mimetype: string | null;
            source: string | null;
            metadata: any;
            fileProcessingStatus: JobStatus | null;
            availableAt: any | null;
            expireAt: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            config: any | null;
            state: any | null;
            version: string | null;
            name: string | null;
            filesByAppInstanceId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                name: string;
                mimetype: string | null;
                source: string | null;
                metadata: any;
                fileProcessingStatus: JobStatus | null;
                availableAt: any | null;
                expireAt: any | null;
              }>;
            };
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              appId: any;
              id: any;
              orgId: any;
            } | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              version: string | null;
              manifestJson: any | null;
              viewerUrl: string | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            compute: any | null;
            actions: any | null;
          }>;
        };
      } | null;
      linkByCastId: {
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
        fileByFileId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        } | null;
      } | null;
      siteByCastId: {
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        } | null;
      } | null;
      appInstanceByCastId: {
        __typename: "AppInstance";
        id: any;
        name: string | null;
        config: any | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          manifestJson: any | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
        appInstallByAppInstallId: { __typename: "AppInstall"; id: any } | null;
      } | null;
    } | null;
    linksByScreenId: {
      __typename: "LinksConnection";
      nodes: Array<{
        __typename: "Link";
        id: any;
        name: string;
        url: string;
        linkType: LinkType;
        cloudConfig: any | null;
      }>;
    };
    sitesByScreenId: {
      __typename: "SitesConnection";
      nodes: Array<{
        __typename: "Site";
        id: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
      }>;
    };
    filesByScreenId: {
      __typename: "FilesConnection";
      nodes: Array<{
        __typename: "File";
        id: any;
        orgId: any;
        name: string;
        mimetype: string | null;
        source: string | null;
        metadata: any;
        fileProcessingStatus: JobStatus | null;
        availableAt: any | null;
        expireAt: any | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      }>;
    };
    playlistsByScreenId: {
      __typename: "PlaylistsConnection";
      nodes: Array<{
        __typename: "Playlist";
        id: any;
        name: string;
        content: any | null;
        draft: {
          __typename: "Playlist";
          id: any;
          isPublished: boolean | null;
        } | null;
        filesByPlaylistId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            mimetype: string | null;
            source: string | null;
            metadata: any;
            fileProcessingStatus: JobStatus | null;
            availableAt: any | null;
            expireAt: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        appInstancesByPlaylistId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            config: any | null;
            state: any | null;
            version: string | null;
            name: string | null;
            filesByAppInstanceId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                name: string;
                mimetype: string | null;
                source: string | null;
                metadata: any;
                fileProcessingStatus: JobStatus | null;
                availableAt: any | null;
                expireAt: any | null;
              }>;
            };
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              appId: any;
              id: any;
              orgId: any;
            } | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              version: string | null;
              manifestJson: any | null;
              viewerUrl: string | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
          }>;
        };
        linksByPlaylistId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
          }>;
        };
        sitesByPlaylistId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            compute: any | null;
            actions: any | null;
          }>;
        };
      }>;
    };
    appInstancesByScreenId: {
      __typename: "AppInstancesConnection";
      nodes: Array<{
        __typename: "AppInstance";
        id: any;
        config: any | null;
        state: any | null;
        version: string | null;
        name: string | null;
        filesByAppInstanceId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            mimetype: string | null;
            source: string | null;
            metadata: any;
            fileProcessingStatus: JobStatus | null;
            availableAt: any | null;
            expireAt: any | null;
          }>;
        };
        appInstallByAppInstallId: {
          __typename: "AppInstall";
          appId: any;
          id: any;
          orgId: any;
        } | null;
        appVersionByAppInstanceId: {
          __typename: "AppVersion";
          id: any;
          version: string | null;
          manifestJson: any | null;
          viewerUrl: string | null;
          appByAppId: {
            __typename: "App";
            id: any;
            name: string;
            iconUrl: string | null;
          } | null;
        } | null;
      }>;
    };
    channelsByScreenId: {
      __typename: "ChannelsConnection";
      nodes: Array<{
        __typename: "Channel";
        childOf: any | null;
        id: any;
        orgId: any;
        spaceId: any | null;
        themeId: any | null;
        tags: Array<string | null>;
        name: string;
        publishedAt: any | null;
        publishedBy: any | null;
        coverData: any | null;
        coverImageId: any | null;
        layoutId: any | null;
        hiddenZone: boolean | null;
        content: any | null;
        isPublished: boolean | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        isParent: boolean | null;
        playingScreenNum: any | null;
        width: number | null;
        height: number | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        draft: {
          __typename: "Channel";
          id: any;
          orgId: any;
          spaceId: any | null;
          themeId: any | null;
          tags: Array<string | null>;
          name: string;
          publishedAt: any | null;
          publishedBy: any | null;
          coverData: any | null;
          coverImageId: any | null;
          layoutId: any | null;
          hiddenZone: boolean | null;
          content: any | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isParent: boolean | null;
          playingScreenNum: any | null;
          width: number | null;
          height: number | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          layoutByChannel: {
            __typename: "Layout";
            id: any;
            orgId: any;
            spaceId: any;
            name: string;
            zones: number;
            config: any | null;
            width: number | null;
            height: number | null;
            isFlexible: boolean | null;
            isScalable: boolean | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
          playlistsByChannelId: {
            __typename: "PlaylistsConnection";
            nodes: Array<{
              __typename: "Playlist";
              color: string;
              content: any | null;
              id: any;
              isFavorite: boolean | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              isEmbedEnabled: boolean | null;
              isEmbedPublic: boolean | null;
              name: string;
              spaceId: any;
              tags: Array<string | null>;
              draft: {
                __typename: "Playlist";
                isPublished: boolean | null;
              } | null;
            }>;
          };
          filesByChannelId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              expireAt: any | null;
            }>;
          };
          linksByChannelId: {
            __typename: "LinksConnection";
            nodes: Array<{ __typename: "Link"; id: any; name: string }>;
          };
          sitesByChannelId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              orgId: any;
              name: string;
              type: SiteType;
              url: string;
              config: any | null;
              actions: any | null;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                orgId: any;
                metadata: any;
                source: string | null;
                mimetype: string | null;
              } | null;
            }>;
          };
          appInstancesByChannelId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              expireAt: any | null;
            }>;
          };
        } | null;
        published: {
          __typename: "Channel";
          id: any;
          orgId: any;
          spaceId: any | null;
          themeId: any | null;
          tags: Array<string | null>;
          name: string;
          publishedAt: any | null;
          publishedBy: any | null;
          coverData: any | null;
          coverImageId: any | null;
          layoutId: any | null;
          hiddenZone: boolean | null;
          content: any | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isParent: boolean | null;
          playingScreenNum: any | null;
          width: number | null;
          height: number | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          layoutByChannel: {
            __typename: "Layout";
            id: any;
            orgId: any;
            spaceId: any;
            name: string;
            zones: number;
            config: any | null;
            width: number | null;
            height: number | null;
            isFlexible: boolean | null;
            isScalable: boolean | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
          playlistsByChannelId: {
            __typename: "PlaylistsConnection";
            nodes: Array<{
              __typename: "Playlist";
              color: string;
              content: any | null;
              id: any;
              isFavorite: boolean | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              isEmbedEnabled: boolean | null;
              isEmbedPublic: boolean | null;
              name: string;
              spaceId: any;
              tags: Array<string | null>;
              draft: {
                __typename: "Playlist";
                isPublished: boolean | null;
              } | null;
            }>;
          };
          filesByChannelId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              expireAt: any | null;
            }>;
          };
          linksByChannelId: {
            __typename: "LinksConnection";
            nodes: Array<{ __typename: "Link"; id: any; name: string }>;
          };
          sitesByChannelId: {
            __typename: "SitesConnection";
            nodes: Array<{
              __typename: "Site";
              id: any;
              orgId: any;
              name: string;
              type: SiteType;
              url: string;
              config: any | null;
              actions: any | null;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                orgId: any;
                metadata: any;
                source: string | null;
                mimetype: string | null;
              } | null;
            }>;
          };
          appInstancesByChannelId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
              expireAt: any | null;
            }>;
          };
        } | null;
        layoutByChannel: {
          __typename: "Layout";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          zones: number;
          config: any | null;
          width: number | null;
          height: number | null;
          isFlexible: boolean | null;
          isScalable: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        playlistsByChannelId: {
          __typename: "PlaylistsConnection";
          nodes: Array<{
            __typename: "Playlist";
            id: any;
            name: string;
            content: any | null;
            draft: {
              __typename: "Playlist";
              id: any;
              isPublished: boolean | null;
            } | null;
            filesByPlaylistId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                name: string;
                mimetype: string | null;
                source: string | null;
                metadata: any;
                fileProcessingStatus: JobStatus | null;
                availableAt: any | null;
                expireAt: any | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              }>;
            };
            appInstancesByPlaylistId: {
              __typename: "AppInstancesConnection";
              nodes: Array<{
                __typename: "AppInstance";
                id: any;
                config: any | null;
                state: any | null;
                version: string | null;
                name: string | null;
                filesByAppInstanceId: {
                  __typename: "FilesConnection";
                  nodes: Array<{
                    __typename: "File";
                    id: any;
                    orgId: any;
                    name: string;
                    mimetype: string | null;
                    source: string | null;
                    metadata: any;
                    fileProcessingStatus: JobStatus | null;
                    availableAt: any | null;
                    expireAt: any | null;
                  }>;
                };
                appInstallByAppInstallId: {
                  __typename: "AppInstall";
                  appId: any;
                  id: any;
                  orgId: any;
                } | null;
                appVersionByAppInstanceId: {
                  __typename: "AppVersion";
                  id: any;
                  version: string | null;
                  manifestJson: any | null;
                  viewerUrl: string | null;
                  appByAppId: {
                    __typename: "App";
                    id: any;
                    name: string;
                    iconUrl: string | null;
                  } | null;
                } | null;
              }>;
            };
            linksByPlaylistId: {
              __typename: "LinksConnection";
              nodes: Array<{
                __typename: "Link";
                id: any;
                name: string;
                url: string;
                linkType: LinkType;
                cloudConfig: any | null;
              }>;
            };
            sitesByPlaylistId: {
              __typename: "SitesConnection";
              nodes: Array<{
                __typename: "Site";
                id: any;
                name: string;
                type: SiteType;
                url: string;
                config: any | null;
                compute: any | null;
                actions: any | null;
              }>;
            };
          }>;
        };
        filesByChannelId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            orgId: any;
            name: string;
            mimetype: string | null;
            source: string | null;
            metadata: any;
            fileProcessingStatus: JobStatus | null;
            availableAt: any | null;
            expireAt: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          }>;
        };
        linksByChannelId: {
          __typename: "LinksConnection";
          nodes: Array<{
            __typename: "Link";
            id: any;
            name: string;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
            } | null;
          }>;
        };
        sitesByChannelId: {
          __typename: "SitesConnection";
          nodes: Array<{
            __typename: "Site";
            id: any;
            orgId: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            actions: any | null;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              orgId: any;
              metadata: any;
              source: string | null;
              mimetype: string | null;
            } | null;
          }>;
        };
        appInstancesByChannelId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            config: any | null;
            state: any | null;
            version: string | null;
            name: string | null;
            filesByAppInstanceId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                orgId: any;
                name: string;
                mimetype: string | null;
                source: string | null;
                metadata: any;
                fileProcessingStatus: JobStatus | null;
                availableAt: any | null;
                expireAt: any | null;
              }>;
            };
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              appId: any;
              id: any;
              orgId: any;
            } | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              version: string | null;
              manifestJson: any | null;
              viewerUrl: string | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
          }>;
        };
        parentChannelByChildOf: {
          __typename: "ParentChannelByChildOf";
          id: any | null;
          name: string | null;
          spaceId: any | null;
          spaceName: string | null;
          content: any | null;
        } | null;
      }>;
    };
    notificationRulesByScreenIdAndOrgId: {
      __typename: "NotificationRulesConnection";
      nodes: Array<{
        __typename: "NotificationRule";
        id: any;
        notificationSubscriptionsByNotificationRuleId: {
          __typename: "NotificationSubscriptionsConnection";
          nodes: Array<{
            __typename: "NotificationSubscription";
            id: any;
            userByUserIdAndOrgId: { __typename: "User"; id: any } | null;
          }>;
        };
      }>;
    };
    screenNotesByScreenId: {
      __typename: "ScreenNotesConnection";
      nodes: Array<{
        __typename: "ScreenNote";
        id: any;
        note: string | null;
        updatedAt: any | null;
        userByUpdatedBy: {
          __typename: "User";
          givenName: string;
          familyName: string;
        } | null;
      }>;
    };
  } | null;
};

export type ScreenGroupsBySpaceIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type ScreenGroupsBySpaceIdQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    screenGroupsBySpaceId: {
      __typename: "ScreenGroupsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "ScreenGroup";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        isPrimary: boolean;
      }>;
    };
  } | null;
};

export type TakeScreenshotByScreenIdMutationVariables = Exact<{
  input: TakeScreenshotByScreenIdInput;
}>;

export type TakeScreenshotByScreenIdMutation = {
  __typename: "Mutation";
  takeScreenshotByScreenId: {
    __typename: "TakeScreenshotByScreenIdPayload";
    screenshot: {
      __typename: "Screenshot";
      id: string | null;
      url: string | null;
      status: string | null;
      expires_at: string | null;
      created_at: string | null;
      updated_at: string | null;
      app_id: string | null;
      player_id: string | null;
      action_log_id: string | null;
    } | null;
  } | null;
};

export type ScreenshotsByScreenIdQueryVariables = Exact<{
  input: ScreenshotsByScreenIdInput;
}>;

export type ScreenshotsByScreenIdQuery = {
  __typename: "Query";
  screenshotsByScreenId: {
    __typename: "ScreenshotsByScreenIdPayload";
    totalCount: number | null;
    screenshots: Array<{
      __typename: "ScreenshotWithUserInfo";
      id: string | null;
      url: string | null;
      status: string | null;
      expires_at: string | null;
      created_at: string | null;
      updated_at: string | null;
      app_id: string | null;
      player_id: string | null;
      action_log_id: string | null;
      given_name: string | null;
      family_name: string | null;
    } | null> | null;
  } | null;
};

export type UpdateScreenByIdMutationVariables = Exact<{
  input: UpdateScreenByIdInput;
}>;

export type UpdateScreenByIdMutation = {
  __typename: "Mutation";
  updateScreenById: {
    __typename: "UpdateScreenByIdPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
        isPrimary: boolean;
      } | null;
      castByCastId: {
        __typename: "Cast";
        id: any;
        orgId: any;
        spaceId: any;
        priority: number | null;
        content: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        expiresAt: any | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          orgId: any;
          spaceId: any;
          tags: Array<string | null>;
          name: string;
          color: string;
          publishedAt: any | null;
          publishedBy: any | null;
          content: any | null;
          isFavorite: boolean | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          orgId: any;
          url: string;
          createdAt: any | null;
          linkType: LinkType;
          cloudConfig: any | null;
          isFavorite: boolean | null;
          name: string;
          tags: Array<string | null>;
          cacheBusting: boolean | null;
          qrcodeEnabled: boolean | null;
          qrcodeConfig: any | null;
          autoReload: boolean | null;
          autoReloadDurationMs: number | null;
          params: any | null;
          spaceId: any;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          isFavorite: boolean | null;
          tags: Array<string | null>;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          castedScreenBySiteId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              spaceId: any;
              device: any | null;
              deviceModel: string | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            givenName: string;
            familyName: string;
          } | null;
          sharedSpacesBySiteId: {
            __typename: "SpacesConnection";
            nodes: Array<{ __typename: "Space"; id: any }>;
          };
        } | null;
        appInstanceByCastId: {
          __typename: "AppInstance";
          id: any;
          orgId: any;
          appInstallId: any;
          appId: any;
          config: any | null;
          state: any | null;
          version: string | null;
          name: string | null;
          tags: Array<string | null>;
          isTemplate: boolean;
          isSharedAll: boolean | null;
          isShared: boolean | null;
          isSharedExplicit: boolean | null;
          spaceId: any;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          availableAt: any | null;
          expireAt: any | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            appId: any;
            version: string | null;
            betaFlag: boolean | null;
            stableFlag: boolean | null;
            manifestJson: any | null;
            viewerUrl: string | null;
            editorUrl: string | null;
            mediaSandboxAccess: boolean | null;
            configurationType: AppConfiguration | null;
            storeData: any | null;
            createdAt: any | null;
            updatedAt: any | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
          appInstallByAppInstallId: {
            __typename: "AppInstall";
            appId: any;
            id: any;
            orgId: any;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            spaceId: any | null;
          } | null;
          filesByAppInstanceId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              orgId: any;
              fileProcessingStatus: JobStatus | null;
              folderId: any | null;
              mimetype: string | null;
              name: string;
              spaceId: any | null;
              tags: Array<string | null>;
              size: any | null;
              source: string | null;
              metadata: any;
              isFavorite: boolean | null;
              expireAt: any | null;
              availableAt: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
            }>;
          };
        } | null;
      } | null;
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        }>;
      };
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        }>;
      };
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{ __typename: "Playlist"; id: any; name: string }>;
      };
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type AllScreensInCurrentSpaceQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type AllScreensInCurrentSpaceQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    screensBySpaceId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        name: string;
        env: any;
        device: any | null;
        deviceProvider: string | null;
        screenGroupId: any;
        screenGroupByScreenGroupId: {
          __typename: "ScreenGroup";
          id: any;
          name: string;
        } | null;
        notificationRulesByScreenIdAndOrgId: {
          __typename: "NotificationRulesConnection";
          nodes: Array<{ __typename: "NotificationRule"; id: any }>;
        };
      }>;
    };
  } | null;
};

export type ClearCacheScreenMutationVariables = Exact<{
  input: ClearCacheScreenInput;
}>;

export type ClearCacheScreenMutation = {
  __typename: "Mutation";
  clearCacheScreen: {
    __typename: "ClearCacheScreenPayload";
    screen: {
      __typename: "Screen";
      id: any;
      name: string;
      device: any | null;
    } | null;
  } | null;
};

export type DeleteScreenGroupMutationVariables = Exact<{
  input: DeleteScreenGroupByIdInput;
}>;

export type DeleteScreenGroupMutation = {
  __typename: "Mutation";
  deleteScreenGroupById: {
    __typename: "DeleteScreenGroupByIdPayload";
    screenGroup: {
      __typename: "ScreenGroup";
      id: any;
      name: string;
      isPrimary: boolean;
      screensByScreenGroupIdAndOrgId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          orgId: any;
          spaceId: any;
          screenGroupId: any;
          castId: any | null;
          name: string;
          content: any | null;
          status: ScreenStatus;
          rotation: ScreenRotation;
          isConnected: boolean;
          connectedAt: any | null;
          disconnectedAt: any | null;
          device: any | null;
          deviceInfo: any | null;
          deviceProvider: string | null;
          deviceId: string;
          deviceModel: string | null;
          devicePlatform: string | null;
          deviceIpAddress: string | null;
          deviceHostname: string | null;
          playerTimezone: string;
          playerHeight: number | null;
          playerWidth: number | null;
          timezoneOverride: string;
          additionalPlayerSettings: any;
          preview: boolean;
          tags: Array<string | null>;
          env: any;
          preferences: any;
          operatingHours: any | null;
          playbackMode: string;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isNotificationActive: boolean | null;
          messages: Array<any | null> | null;
          screenGroupByScreenGroupId: {
            __typename: "ScreenGroup";
            id: any;
            name: string;
            isPrimary: boolean;
          } | null;
          castByCastId: {
            __typename: "Cast";
            id: any;
            content: any | null;
            channelByCastId: {
              __typename: "Channel";
              id: any;
              name: string;
            } | null;
            fileByCastId: {
              __typename: "File";
              id: any;
              folderId: any | null;
              name: string;
              mimetype: string | null;
              metadata: any;
              source: string | null;
              fileProcessingStatus: JobStatus | null;
              fileOutputsByFileId: {
                __typename: "FileOutputsConnection";
                nodes: Array<{
                  __typename: "FileOutput";
                  id: any;
                  content: any | null;
                  metadata: any | null;
                  mimetype: string | null;
                  url: string | null;
                  size: any | null;
                }>;
              };
            } | null;
            playlistByCastId: {
              __typename: "Playlist";
              id: any;
              name: string;
            } | null;
            linkByCastId: {
              __typename: "Link";
              id: any;
              name: string;
              url: string;
              linkType: LinkType;
              cloudConfig: any | null;
              fileByFileId: {
                __typename: "File";
                id: any;
                name: string;
                mimetype: string | null;
              } | null;
            } | null;
            siteByCastId: {
              __typename: "Site";
              id: any;
              name: string;
              type: SiteType;
              url: string;
              config: any | null;
              compute: any | null;
              actions: any | null;
              fileByThumbnailId: {
                __typename: "File";
                id: any;
                name: string;
                mimetype: string | null;
                metadata: any;
                source: string | null;
                fileProcessingStatus: JobStatus | null;
              } | null;
            } | null;
            appInstanceByCastId: {
              __typename: "AppInstance";
              id: any;
              name: string | null;
              config: any | null;
              appVersionByAppInstanceId: {
                __typename: "AppVersion";
                id: any;
                manifestJson: any | null;
                appByAppId: {
                  __typename: "App";
                  id: any;
                  name: string;
                  iconUrl: string | null;
                } | null;
              } | null;
              appInstallByAppInstallId: {
                __typename: "AppInstall";
                id: any;
              } | null;
            } | null;
          } | null;
          linksByScreenId: {
            __typename: "LinksConnection";
            nodes: Array<{ __typename: "Link"; id: any; name: string }>;
          };
          sitesByScreenId: {
            __typename: "SitesConnection";
            nodes: Array<{ __typename: "Site"; id: any; name: string }>;
          };
          channelsByScreenId: {
            __typename: "ChannelsConnection";
            nodes: Array<{
              __typename: "Channel";
              id: any;
              name: string;
              spaceId: any | null;
              content: any | null;
              isSharedExplicit: boolean | null;
              isSharedAll: boolean | null;
              childOf: any | null;
              draft: {
                __typename: "Channel";
                id: any;
                isPublished: boolean | null;
                content: any | null;
              } | null;
              published: {
                __typename: "Channel";
                id: any;
                isPublished: boolean | null;
                content: any | null;
              } | null;
              parentChannelByChildOf: {
                __typename: "ParentChannelByChildOf";
                id: any | null;
                name: string | null;
                spaceId: any | null;
                spaceName: string | null;
                content: any | null;
              } | null;
            }>;
          };
          filesByScreenId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
              metadata: any;
              source: string | null;
              fileProcessingStatus: JobStatus | null;
            }>;
          };
          playlistsByScreenId: {
            __typename: "PlaylistsConnection";
            nodes: Array<{
              __typename: "Playlist";
              id: any;
              name: string;
              content: any | null;
              spaceId: any;
              draft: {
                __typename: "Playlist";
                id: any;
                isPublished: boolean | null;
              } | null;
            }>;
          };
          appInstancesByScreenId: {
            __typename: "AppInstancesConnection";
            nodes: Array<{
              __typename: "AppInstance";
              id: any;
              name: string | null;
            }>;
          };
          screenNotesByScreenId: {
            __typename: "ScreenNotesConnection";
            nodes: Array<{
              __typename: "ScreenNote";
              id: any;
              note: string | null;
              updatedAt: any | null;
              userByUpdatedBy: {
                __typename: "User";
                givenName: string;
                familyName: string;
              } | null;
            }>;
          };
        }>;
        pageInfo: {
          __typename: "PageInfo";
          hasNextPage: boolean;
          endCursor: any | null;
        };
      };
    } | null;
  } | null;
};

export type DepairScreenMutationVariables = Exact<{
  input: DeleteScreenInput;
}>;

export type DepairScreenMutation = {
  __typename: "Mutation";
  deleteScreen: {
    __typename: "DeleteScreenPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      castByCastId: {
        __typename: "Cast";
        id: any;
        orgId: any;
        spaceId: any;
        priority: number | null;
        content: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        expiresAt: any | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          orgId: any;
          url: string;
          createdAt: any | null;
          linkType: LinkType;
          cloudConfig: any | null;
          isFavorite: boolean | null;
          name: string;
          tags: Array<string | null>;
          cacheBusting: boolean | null;
          qrcodeEnabled: boolean | null;
          qrcodeConfig: any | null;
          autoReload: boolean | null;
          autoReloadDurationMs: number | null;
          params: any | null;
          spaceId: any;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          isFavorite: boolean | null;
          tags: Array<string | null>;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          castedScreenBySiteId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              spaceId: any;
              device: any | null;
              deviceModel: string | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            givenName: string;
            familyName: string;
          } | null;
          sharedSpacesBySiteId: {
            __typename: "SpacesConnection";
            nodes: Array<{ __typename: "Space"; id: any }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          orgId: any;
          spaceId: any;
          tags: Array<string | null>;
          name: string;
          color: string;
          publishedAt: any | null;
          publishedBy: any | null;
          content: any | null;
          isFavorite: boolean | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
      } | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type GetBillingSubscriptionQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type GetBillingSubscriptionQuery = {
  __typename: "Query";
  getBillingSubscription: {
    __typename: "BillingSubscription";
    id: string;
    currencyCode: CurrencyCode | null;
    planId: string | null;
    planQuantity: number | null;
    planUnitPrice: number | null;
    planAmount: number | null;
    status: string | null;
    poNumber: string | null;
    autoCollection: string | null;
    dueInvoicesCount: number | null;
    totalDues: number | null;
    baseCurrencyCode: CurrencyCode | null;
    cfPrimary: string | null;
    dueSince: any | null;
    trialStart: any | null;
    trialEnd: any | null;
    currentTermStart: any | null;
    currentTermEnd: any | null;
    pauseDate: any | null;
    resumeDate: any | null;
    startDate: any | null;
    startedAt: any | null;
    activatedAt: any | null;
    cancelledAt: any | null;
    nextBillingAt: any | null;
    createdAt: any | null;
    billingAddonSubscriptionsByBillingSubscriptionId: {
      __typename: "BillingAddonSubscriptionsConnection";
      nodes: Array<{
        __typename: "BillingAddonSubscription";
        billingAddonId: string;
        billingSubscriptionId: string;
        billingCustomerId: string;
        unitPrice: number | null;
        billingAddonByBillingAddonId: {
          __typename: "BillingAddon";
          id: string;
          name: string | null;
          currencyCode: CurrencyCode | null;
          price: number | null;
        } | null;
      }>;
    };
  } | null;
};

export type GetScreenLicencesQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
}>;

export type GetScreenLicencesQuery = {
  __typename: "Query";
  getScreenLicences: {
    __typename: "GetScreenLicencesPayload";
    usedLicences: number;
    totalLicences: number;
  } | null;
};

export type ResetScreenLocationByIdsMutationVariables = Exact<{
  input: ResetScreenLocationByIdsInput;
}>;

export type ResetScreenLocationByIdsMutation = {
  __typename: "Mutation";
  resetScreenLocationByIds: {
    __typename: "ResetScreenLocationByIdsPayload";
    screens: Array<{
      __typename: "Screen";
      id: any;
      orgId: any;
      spaceId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      rotation: ScreenRotation;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      device: any | null;
      deviceInfo: any | null;
      deviceProvider: string | null;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      preview: boolean;
      tags: Array<string | null>;
      env: any;
      preferences: any;
      operatingHours: any | null;
      playbackMode: string;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
        isPrimary: boolean;
      } | null;
      castByCastId: {
        __typename: "Cast";
        id: any;
        content: any | null;
        channelByCastId: {
          __typename: "Channel";
          id: any;
          name: string;
        } | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          folderId: any | null;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          name: string;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          name: string;
          url: string;
          linkType: LinkType;
          cloudConfig: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
          } | null;
        } | null;
        appInstanceByCastId: {
          __typename: "AppInstance";
          id: any;
          name: string | null;
          config: any | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            manifestJson: any | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
          appInstallByAppInstallId: {
            __typename: "AppInstall";
            id: any;
          } | null;
        } | null;
      } | null;
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        }>;
      };
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        }>;
      };
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          id: any;
          name: string;
          content: any | null;
          spaceId: any;
          draft: {
            __typename: "Playlist";
            id: any;
            isPublished: boolean | null;
          } | null;
        }>;
      };
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    }> | null;
  } | null;
};

export type ScreenListQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  filter: InputMaybe<ScreenFilter>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
}>;

export type ScreenListQuery = {
  __typename: "Query";
  defaultScreenGroupBySpaceId: {
    __typename: "ScreenGroup";
    id: any;
    name: string;
    isPrimary: boolean;
    screensByScreenGroupIdAndOrgId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        orgId: any;
        spaceId: any;
        screenGroupId: any;
        castId: any | null;
        name: string;
        content: any | null;
        status: ScreenStatus;
        rotation: ScreenRotation;
        isConnected: boolean;
        connectedAt: any | null;
        disconnectedAt: any | null;
        device: any | null;
        deviceInfo: any | null;
        deviceProvider: string | null;
        deviceId: string;
        deviceModel: string | null;
        devicePlatform: string | null;
        deviceIpAddress: string | null;
        deviceHostname: string | null;
        playerTimezone: string;
        playerHeight: number | null;
        playerWidth: number | null;
        timezoneOverride: string;
        additionalPlayerSettings: any;
        preview: boolean;
        tags: Array<string | null>;
        env: any;
        preferences: any;
        operatingHours: any | null;
        playbackMode: string;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isNotificationActive: boolean | null;
        messages: Array<any | null> | null;
        screenGroupByScreenGroupId: {
          __typename: "ScreenGroup";
          id: any;
          name: string;
          isPrimary: boolean;
        } | null;
        castByCastId: {
          __typename: "Cast";
          id: any;
          content: any | null;
          channelByCastId: {
            __typename: "Channel";
            id: any;
            name: string;
          } | null;
          fileByCastId: {
            __typename: "File";
            id: any;
            folderId: any | null;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          playlistByCastId: {
            __typename: "Playlist";
            id: any;
            name: string;
          } | null;
          linkByCastId: {
            __typename: "Link";
            id: any;
            name: string;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
            } | null;
          } | null;
          siteByCastId: {
            __typename: "Site";
            id: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            compute: any | null;
            actions: any | null;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
              metadata: any;
              source: string | null;
              fileProcessingStatus: JobStatus | null;
            } | null;
          } | null;
          appInstanceByCastId: {
            __typename: "AppInstance";
            id: any;
            name: string | null;
            config: any | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              manifestJson: any | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              id: any;
            } | null;
          } | null;
        } | null;
        linksByScreenId: {
          __typename: "LinksConnection";
          nodes: Array<{ __typename: "Link"; id: any; name: string }>;
        };
        sitesByScreenId: {
          __typename: "SitesConnection";
          nodes: Array<{ __typename: "Site"; id: any; name: string }>;
        };
        channelsByScreenId: {
          __typename: "ChannelsConnection";
          nodes: Array<{
            __typename: "Channel";
            id: any;
            name: string;
            spaceId: any | null;
            content: any | null;
            isSharedExplicit: boolean | null;
            isSharedAll: boolean | null;
            childOf: any | null;
            draft: {
              __typename: "Channel";
              id: any;
              isPublished: boolean | null;
              content: any | null;
            } | null;
            published: {
              __typename: "Channel";
              id: any;
              isPublished: boolean | null;
              content: any | null;
            } | null;
            parentChannelByChildOf: {
              __typename: "ParentChannelByChildOf";
              id: any | null;
              name: string | null;
              spaceId: any | null;
              spaceName: string | null;
              content: any | null;
            } | null;
          }>;
        };
        filesByScreenId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
          }>;
        };
        playlistsByScreenId: {
          __typename: "PlaylistsConnection";
          nodes: Array<{
            __typename: "Playlist";
            id: any;
            name: string;
            content: any | null;
            spaceId: any;
            draft: {
              __typename: "Playlist";
              id: any;
              isPublished: boolean | null;
            } | null;
          }>;
        };
        appInstancesByScreenId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
          }>;
        };
        screenNotesByScreenId: {
          __typename: "ScreenNotesConnection";
          nodes: Array<{
            __typename: "ScreenNote";
            id: any;
            note: string | null;
            updatedAt: any | null;
            userByUpdatedBy: {
              __typename: "User";
              givenName: string;
              familyName: string;
            } | null;
          }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
  spaceById: {
    __typename: "Space";
    id: any;
    name: string;
    castsBySpaceId: {
      __typename: "CastsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Cast";
        screensByCastId: {
          __typename: "ScreensConnection";
          totalCount: number;
        };
      }>;
    };
    screensBySpaceId: {
      __typename: "ScreensConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Screen";
        id: any;
        orgId: any;
        spaceId: any;
        screenGroupId: any;
        castId: any | null;
        name: string;
        content: any | null;
        status: ScreenStatus;
        rotation: ScreenRotation;
        isConnected: boolean;
        connectedAt: any | null;
        disconnectedAt: any | null;
        device: any | null;
        deviceInfo: any | null;
        deviceProvider: string | null;
        deviceId: string;
        deviceModel: string | null;
        devicePlatform: string | null;
        deviceIpAddress: string | null;
        deviceHostname: string | null;
        playerTimezone: string;
        playerHeight: number | null;
        playerWidth: number | null;
        timezoneOverride: string;
        additionalPlayerSettings: any;
        preview: boolean;
        tags: Array<string | null>;
        env: any;
        preferences: any;
        operatingHours: any | null;
        playbackMode: string;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isNotificationActive: boolean | null;
        messages: Array<any | null> | null;
        screenGroupByScreenGroupId: {
          __typename: "ScreenGroup";
          id: any;
          name: string;
          isPrimary: boolean;
        } | null;
        castByCastId: {
          __typename: "Cast";
          id: any;
          content: any | null;
          channelByCastId: {
            __typename: "Channel";
            id: any;
            name: string;
          } | null;
          fileByCastId: {
            __typename: "File";
            id: any;
            folderId: any | null;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          playlistByCastId: {
            __typename: "Playlist";
            id: any;
            name: string;
          } | null;
          linkByCastId: {
            __typename: "Link";
            id: any;
            name: string;
            url: string;
            linkType: LinkType;
            cloudConfig: any | null;
            fileByFileId: {
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
            } | null;
          } | null;
          siteByCastId: {
            __typename: "Site";
            id: any;
            name: string;
            type: SiteType;
            url: string;
            config: any | null;
            compute: any | null;
            actions: any | null;
            fileByThumbnailId: {
              __typename: "File";
              id: any;
              name: string;
              mimetype: string | null;
              metadata: any;
              source: string | null;
              fileProcessingStatus: JobStatus | null;
            } | null;
          } | null;
          appInstanceByCastId: {
            __typename: "AppInstance";
            id: any;
            name: string | null;
            config: any | null;
            appVersionByAppInstanceId: {
              __typename: "AppVersion";
              id: any;
              manifestJson: any | null;
              appByAppId: {
                __typename: "App";
                id: any;
                name: string;
                iconUrl: string | null;
              } | null;
            } | null;
            appInstallByAppInstallId: {
              __typename: "AppInstall";
              id: any;
            } | null;
          } | null;
        } | null;
        linksByScreenId: {
          __typename: "LinksConnection";
          nodes: Array<{ __typename: "Link"; id: any; name: string }>;
        };
        sitesByScreenId: {
          __typename: "SitesConnection";
          nodes: Array<{ __typename: "Site"; id: any; name: string }>;
        };
        channelsByScreenId: {
          __typename: "ChannelsConnection";
          nodes: Array<{
            __typename: "Channel";
            id: any;
            name: string;
            spaceId: any | null;
            content: any | null;
            isSharedExplicit: boolean | null;
            isSharedAll: boolean | null;
            childOf: any | null;
            draft: {
              __typename: "Channel";
              id: any;
              isPublished: boolean | null;
              content: any | null;
            } | null;
            published: {
              __typename: "Channel";
              id: any;
              isPublished: boolean | null;
              content: any | null;
            } | null;
            parentChannelByChildOf: {
              __typename: "ParentChannelByChildOf";
              id: any | null;
              name: string | null;
              spaceId: any | null;
              spaceName: string | null;
              content: any | null;
            } | null;
          }>;
        };
        filesByScreenId: {
          __typename: "FilesConnection";
          nodes: Array<{
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
          }>;
        };
        playlistsByScreenId: {
          __typename: "PlaylistsConnection";
          nodes: Array<{
            __typename: "Playlist";
            id: any;
            name: string;
            content: any | null;
            spaceId: any;
            draft: {
              __typename: "Playlist";
              id: any;
              isPublished: boolean | null;
            } | null;
          }>;
        };
        appInstancesByScreenId: {
          __typename: "AppInstancesConnection";
          nodes: Array<{
            __typename: "AppInstance";
            id: any;
            name: string | null;
          }>;
        };
        screenNotesByScreenId: {
          __typename: "ScreenNotesConnection";
          nodes: Array<{
            __typename: "ScreenNote";
            id: any;
            note: string | null;
            updatedAt: any | null;
            userByUpdatedBy: {
              __typename: "User";
              givenName: string;
              familyName: string;
            } | null;
          }>;
        };
      }>;
    };
    screenGroupsBySpaceId: {
      __typename: "ScreenGroupsConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "ScreenGroup";
        id: any;
        name: string;
        isPrimary: boolean;
        screensByScreenGroupIdAndOrgId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            orgId: any;
            spaceId: any;
            screenGroupId: any;
            castId: any | null;
            name: string;
            content: any | null;
            status: ScreenStatus;
            rotation: ScreenRotation;
            isConnected: boolean;
            connectedAt: any | null;
            disconnectedAt: any | null;
            device: any | null;
            deviceInfo: any | null;
            deviceProvider: string | null;
            deviceId: string;
            deviceModel: string | null;
            devicePlatform: string | null;
            deviceIpAddress: string | null;
            deviceHostname: string | null;
            playerTimezone: string;
            playerHeight: number | null;
            playerWidth: number | null;
            timezoneOverride: string;
            additionalPlayerSettings: any;
            preview: boolean;
            tags: Array<string | null>;
            env: any;
            preferences: any;
            operatingHours: any | null;
            playbackMode: string;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            isNotificationActive: boolean | null;
            messages: Array<any | null> | null;
            screenGroupByScreenGroupId: {
              __typename: "ScreenGroup";
              id: any;
              name: string;
              isPrimary: boolean;
            } | null;
            castByCastId: {
              __typename: "Cast";
              id: any;
              content: any | null;
              channelByCastId: {
                __typename: "Channel";
                id: any;
                name: string;
              } | null;
              fileByCastId: {
                __typename: "File";
                id: any;
                folderId: any | null;
                name: string;
                mimetype: string | null;
                metadata: any;
                source: string | null;
                fileProcessingStatus: JobStatus | null;
                fileOutputsByFileId: {
                  __typename: "FileOutputsConnection";
                  nodes: Array<{
                    __typename: "FileOutput";
                    id: any;
                    content: any | null;
                    metadata: any | null;
                    mimetype: string | null;
                    url: string | null;
                    size: any | null;
                  }>;
                };
              } | null;
              playlistByCastId: {
                __typename: "Playlist";
                id: any;
                name: string;
              } | null;
              linkByCastId: {
                __typename: "Link";
                id: any;
                name: string;
                url: string;
                linkType: LinkType;
                cloudConfig: any | null;
                fileByFileId: {
                  __typename: "File";
                  id: any;
                  name: string;
                  mimetype: string | null;
                } | null;
              } | null;
              siteByCastId: {
                __typename: "Site";
                id: any;
                name: string;
                type: SiteType;
                url: string;
                config: any | null;
                compute: any | null;
                actions: any | null;
                fileByThumbnailId: {
                  __typename: "File";
                  id: any;
                  name: string;
                  mimetype: string | null;
                  metadata: any;
                  source: string | null;
                  fileProcessingStatus: JobStatus | null;
                } | null;
              } | null;
              appInstanceByCastId: {
                __typename: "AppInstance";
                id: any;
                name: string | null;
                config: any | null;
                appVersionByAppInstanceId: {
                  __typename: "AppVersion";
                  id: any;
                  manifestJson: any | null;
                  appByAppId: {
                    __typename: "App";
                    id: any;
                    name: string;
                    iconUrl: string | null;
                  } | null;
                } | null;
                appInstallByAppInstallId: {
                  __typename: "AppInstall";
                  id: any;
                } | null;
              } | null;
            } | null;
            linksByScreenId: {
              __typename: "LinksConnection";
              nodes: Array<{ __typename: "Link"; id: any; name: string }>;
            };
            sitesByScreenId: {
              __typename: "SitesConnection";
              nodes: Array<{ __typename: "Site"; id: any; name: string }>;
            };
            channelsByScreenId: {
              __typename: "ChannelsConnection";
              nodes: Array<{
                __typename: "Channel";
                id: any;
                name: string;
                spaceId: any | null;
                content: any | null;
                isSharedExplicit: boolean | null;
                isSharedAll: boolean | null;
                childOf: any | null;
                draft: {
                  __typename: "Channel";
                  id: any;
                  isPublished: boolean | null;
                  content: any | null;
                } | null;
                published: {
                  __typename: "Channel";
                  id: any;
                  isPublished: boolean | null;
                  content: any | null;
                } | null;
                parentChannelByChildOf: {
                  __typename: "ParentChannelByChildOf";
                  id: any | null;
                  name: string | null;
                  spaceId: any | null;
                  spaceName: string | null;
                  content: any | null;
                } | null;
              }>;
            };
            filesByScreenId: {
              __typename: "FilesConnection";
              nodes: Array<{
                __typename: "File";
                id: any;
                name: string;
                mimetype: string | null;
                metadata: any;
                source: string | null;
                fileProcessingStatus: JobStatus | null;
              }>;
            };
            playlistsByScreenId: {
              __typename: "PlaylistsConnection";
              nodes: Array<{
                __typename: "Playlist";
                id: any;
                name: string;
                content: any | null;
                spaceId: any;
                draft: {
                  __typename: "Playlist";
                  id: any;
                  isPublished: boolean | null;
                } | null;
              }>;
            };
            appInstancesByScreenId: {
              __typename: "AppInstancesConnection";
              nodes: Array<{
                __typename: "AppInstance";
                id: any;
                name: string | null;
              }>;
            };
            screenNotesByScreenId: {
              __typename: "ScreenNotesConnection";
              nodes: Array<{
                __typename: "ScreenNote";
                id: any;
                note: string | null;
                updatedAt: any | null;
                userByUpdatedBy: {
                  __typename: "User";
                  givenName: string;
                  familyName: string;
                } | null;
              }>;
            };
          }>;
          pageInfo: {
            __typename: "PageInfo";
            hasNextPage: boolean;
            endCursor: any | null;
          };
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type SearchScreenQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
}>;

export type SearchScreenQuery = {
  __typename: "Query";
  searchScreen: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      orgId: any;
      spaceId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      rotation: ScreenRotation;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      device: any | null;
      deviceInfo: any | null;
      deviceProvider: string | null;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      preview: boolean;
      tags: Array<string | null>;
      env: any;
      preferences: any;
      operatingHours: any | null;
      playbackMode: string;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
        isPrimary: boolean;
      } | null;
      castByCastId: {
        __typename: "Cast";
        id: any;
        content: any | null;
        channelByCastId: {
          __typename: "Channel";
          id: any;
          name: string;
        } | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          folderId: any | null;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          name: string;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          name: string;
          url: string;
          linkType: LinkType;
          cloudConfig: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
          } | null;
        } | null;
        appInstanceByCastId: {
          __typename: "AppInstance";
          id: any;
          name: string | null;
          config: any | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            manifestJson: any | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
          appInstallByAppInstallId: {
            __typename: "AppInstall";
            id: any;
          } | null;
        } | null;
      } | null;
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        }>;
      };
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        }>;
      };
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          id: any;
          name: string;
          content: any | null;
          spaceId: any;
          draft: {
            __typename: "Playlist";
            id: any;
            isPublished: boolean | null;
          } | null;
        }>;
      };
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type SetScreenContentByAppInstanceIdMutationVariables = Exact<{
  input: SetScreenContentByAppInstanceIdInput;
}>;

export type SetScreenContentByAppInstanceIdMutation = {
  __typename: "Mutation";
  setScreenContentByAppInstanceId: {
    __typename: "SetScreenContentByAppInstanceIdPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
      } | null;
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      castByCastId: { __typename: "Cast"; id: any; content: any | null } | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type SetScreenContentByChannelIdMutationVariables = Exact<{
  input: SetScreenContentByChannelIdInput;
}>;

export type SetScreenContentByChannelIdMutation = {
  __typename: "Mutation";
  setScreenContentByChannelId: {
    __typename: "SetScreenContentByChannelIdPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        isPrimary: boolean;
        env: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          spaceId: any | null;
          name: string;
        }>;
      };
      castByCastId: { __typename: "Cast"; id: any; content: any | null } | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type SetScreenContentByFileIdMutationVariables = Exact<{
  input: SetScreenContentByFileIdInput;
}>;

export type SetScreenContentByFileIdMutation = {
  __typename: "Mutation";
  setScreenContentByFileId: {
    __typename: "SetScreenContentByFileIdPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
      } | null;
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        }>;
      };
      castByCastId: { __typename: "Cast"; id: any; content: any | null } | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type SetScreenContentByLinkIdMutationVariables = Exact<{
  input: SetScreenContentByLinkIdInput;
}>;

export type SetScreenContentByLinkIdMutation = {
  __typename: "Mutation";
  setScreenContentByLinkId: {
    __typename: "SetScreenContentByLinkIdPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
      } | null;
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      castByCastId: { __typename: "Cast"; id: any; content: any | null } | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type SetScreenContentByPlaylistIdMutationVariables = Exact<{
  input: SetScreenContentByPlaylistIdInput;
}>;

export type SetScreenContentByPlaylistIdMutation = {
  __typename: "Mutation";
  setScreenContentByPlaylistId: {
    __typename: "SetScreenContentByPlaylistIdPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
      } | null;
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{ __typename: "Playlist"; id: any; name: string }>;
      };
      castByCastId: { __typename: "Cast"; id: any; content: any | null } | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type SetScreenContentBySiteIdMutationVariables = Exact<{
  input: SetScreenContentBySiteIdInput;
}>;

export type SetScreenContentBySiteIdMutation = {
  __typename: "Mutation";
  setScreenContentBySiteId: {
    __typename: "SetScreenContentBySiteIdPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
      } | null;
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
      castByCastId: { __typename: "Cast"; id: any; content: any | null } | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type TransferScreensMutationVariables = Exact<{
  input: TransferScreensInput;
}>;

export type TransferScreensMutation = {
  __typename: "Mutation";
  transferScreens: {
    __typename: "TransferScreensPayload";
    screens: Array<{
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    }> | null;
  } | null;
};

export type UpdateScreenByIdsMutationVariables = Exact<{
  input: UpdateScreenByIdsInput;
}>;

export type UpdateScreenByIdsMutation = {
  __typename: "Mutation";
  updateScreenByIds: {
    __typename: "UpdateScreenByIdsPayload";
    screens: Array<{
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      preview: boolean;
      device: any | null;
      deviceInfo: any | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      rotation: ScreenRotation;
      env: any;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      deviceProvider: string | null;
      preferences: any;
      operatingHours: any | null;
      tags: Array<string | null>;
      spaceId: any;
      playbackMode: string;
      videoSource: string;
      highResolutionImage: boolean;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        isPrimary: boolean;
        env: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
      } | null;
      castByCastId: {
        __typename: "Cast";
        id: any;
        orgId: any;
        spaceId: any;
        priority: number | null;
        content: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        expiresAt: any | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          orgId: any;
          url: string;
          createdAt: any | null;
          linkType: LinkType;
          cloudConfig: any | null;
          isFavorite: boolean | null;
          name: string;
          tags: Array<string | null>;
          cacheBusting: boolean | null;
          qrcodeEnabled: boolean | null;
          qrcodeConfig: any | null;
          autoReload: boolean | null;
          autoReloadDurationMs: number | null;
          params: any | null;
          spaceId: any;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          isFavorite: boolean | null;
          tags: Array<string | null>;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          castedScreenBySiteId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              spaceId: any;
              device: any | null;
              deviceModel: string | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            givenName: string;
            familyName: string;
          } | null;
          sharedSpacesBySiteId: {
            __typename: "SpacesConnection";
            nodes: Array<{ __typename: "Space"; id: any }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          orgId: any;
          spaceId: any;
          tags: Array<string | null>;
          name: string;
          color: string;
          publishedAt: any | null;
          publishedBy: any | null;
          content: any | null;
          isFavorite: boolean | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
      } | null;
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        }>;
      };
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        }>;
      };
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{ __typename: "Playlist"; id: any; name: string }>;
      };
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    }> | null;
  } | null;
};

export type UpdateScreenGroupMutationVariables = Exact<{
  input: UpdateScreenGroupByIdInput;
}>;

export type UpdateScreenGroupMutation = {
  __typename: "Mutation";
  updateScreenGroupById: {
    __typename: "UpdateScreenGroupByIdPayload";
    screenGroup: {
      __typename: "ScreenGroup";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      isPrimary: boolean;
      env: any | null;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
    } | null;
  } | null;
};

export type UpdateScreenListByScreenIdMutationVariables = Exact<{
  input: UpdateScreenByIdInput;
}>;

export type UpdateScreenListByScreenIdMutation = {
  __typename: "Mutation";
  updateScreenById: {
    __typename: "UpdateScreenByIdPayload";
    screen: {
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      device: any | null;
      preview: boolean;
      tags: Array<string | null>;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
        isPrimary: boolean;
      } | null;
      castByCastId: {
        __typename: "Cast";
        id: any;
        orgId: any;
        spaceId: any;
        priority: number | null;
        content: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        expiresAt: any | null;
        channelByCastId: {
          __typename: "Channel";
          childOf: any | null;
          id: any;
          orgId: any;
          spaceId: any | null;
          themeId: any | null;
          tags: Array<string | null>;
          name: string;
          publishedAt: any | null;
          publishedBy: any | null;
          coverData: any | null;
          coverImageId: any | null;
          layoutId: any | null;
          hiddenZone: boolean | null;
          content: any | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          isParent: boolean | null;
          playingScreenNum: any | null;
          width: number | null;
          height: number | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        } | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          orgId: any;
          spaceId: any;
          tags: Array<string | null>;
          name: string;
          color: string;
          publishedAt: any | null;
          publishedBy: any | null;
          content: any | null;
          isFavorite: boolean | null;
          isPublished: boolean | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          orgId: any;
          url: string;
          createdAt: any | null;
          linkType: LinkType;
          cloudConfig: any | null;
          isFavorite: boolean | null;
          name: string;
          tags: Array<string | null>;
          cacheBusting: boolean | null;
          qrcodeEnabled: boolean | null;
          qrcodeConfig: any | null;
          autoReload: boolean | null;
          autoReloadDurationMs: number | null;
          params: any | null;
          spaceId: any;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          orgId: any;
          spaceId: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          isFavorite: boolean | null;
          tags: Array<string | null>;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            orgId: any;
            fileProcessingStatus: JobStatus | null;
            folderId: any | null;
            mimetype: string | null;
            name: string;
            spaceId: any | null;
            tags: Array<string | null>;
            size: any | null;
            source: string | null;
            metadata: any;
            isFavorite: boolean | null;
            expireAt: any | null;
            availableAt: any | null;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            fileOutputsByFileId: {
              __typename: "FileOutputsConnection";
              totalCount: number;
              nodes: Array<{
                __typename: "FileOutput";
                id: any;
                content: any | null;
                metadata: any | null;
                mimetype: string | null;
                url: string | null;
                size: any | null;
              }>;
            };
          } | null;
          castedScreenBySiteId: {
            __typename: "ScreensConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "Screen";
              id: any;
              name: string;
              spaceId: any;
              device: any | null;
              deviceModel: string | null;
            }>;
          };
          userByCreatedBy: {
            __typename: "User";
            id: any;
            givenName: string;
            familyName: string;
          } | null;
          sharedSpacesBySiteId: {
            __typename: "SpacesConnection";
            nodes: Array<{ __typename: "Space"; id: any }>;
          };
        } | null;
        appInstanceByCastId: {
          __typename: "AppInstance";
          id: any;
          orgId: any;
          appInstallId: any;
          appId: any;
          config: any | null;
          state: any | null;
          version: string | null;
          name: string | null;
          tags: Array<string | null>;
          isTemplate: boolean;
          isSharedAll: boolean | null;
          isShared: boolean | null;
          isSharedExplicit: boolean | null;
          spaceId: any;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          availableAt: any | null;
          expireAt: any | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            appId: any;
            version: string | null;
            betaFlag: boolean | null;
            stableFlag: boolean | null;
            manifestJson: any | null;
            viewerUrl: string | null;
            editorUrl: string | null;
            mediaSandboxAccess: boolean | null;
            configurationType: AppConfiguration | null;
            storeData: any | null;
            createdAt: any | null;
            updatedAt: any | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
          appInstallByAppInstallId: {
            __typename: "AppInstall";
            appId: any;
            id: any;
            orgId: any;
            createdAt: any | null;
            createdBy: any | null;
            updatedAt: any | null;
            updatedBy: any | null;
            spaceId: any | null;
          } | null;
          filesByAppInstanceId: {
            __typename: "FilesConnection";
            nodes: Array<{
              __typename: "File";
              id: any;
              orgId: any;
              fileProcessingStatus: JobStatus | null;
              folderId: any | null;
              mimetype: string | null;
              name: string;
              spaceId: any | null;
              tags: Array<string | null>;
              size: any | null;
              source: string | null;
              metadata: any;
              isFavorite: boolean | null;
              expireAt: any | null;
              availableAt: any | null;
              createdAt: any | null;
              createdBy: any | null;
              updatedAt: any | null;
              updatedBy: any | null;
            }>;
          };
        } | null;
      } | null;
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        }>;
      };
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
        }>;
      };
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{ __typename: "Playlist"; id: any; name: string }>;
      };
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      spaceBySpaceId: {
        __typename: "Space";
        id: any;
        castsBySpaceId: {
          __typename: "CastsConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Cast";
            screensByCastId: {
              __typename: "ScreensConnection";
              totalCount: number;
            };
          }>;
        };
      } | null;
    } | null;
  } | null;
};

export type UpdateScreenLocationByIdsMutationVariables = Exact<{
  input: UpdateScreenLocationByIdsInput;
}>;

export type UpdateScreenLocationByIdsMutation = {
  __typename: "Mutation";
  updateScreenLocationByIds: {
    __typename: "UpdateScreenLocationByIdsPayload";
    screens: Array<{
      __typename: "Screen";
      id: any;
      orgId: any;
      spaceId: any;
      screenGroupId: any;
      castId: any | null;
      name: string;
      content: any | null;
      status: ScreenStatus;
      rotation: ScreenRotation;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      device: any | null;
      deviceInfo: any | null;
      deviceProvider: string | null;
      deviceId: string;
      deviceModel: string | null;
      devicePlatform: string | null;
      deviceIpAddress: string | null;
      deviceHostname: string | null;
      playerTimezone: string;
      playerHeight: number | null;
      playerWidth: number | null;
      timezoneOverride: string;
      additionalPlayerSettings: any;
      preview: boolean;
      tags: Array<string | null>;
      env: any;
      preferences: any;
      operatingHours: any | null;
      playbackMode: string;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isNotificationActive: boolean | null;
      messages: Array<any | null> | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
        isPrimary: boolean;
      } | null;
      castByCastId: {
        __typename: "Cast";
        id: any;
        content: any | null;
        channelByCastId: {
          __typename: "Channel";
          id: any;
          name: string;
        } | null;
        fileByCastId: {
          __typename: "File";
          id: any;
          folderId: any | null;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        playlistByCastId: {
          __typename: "Playlist";
          id: any;
          name: string;
        } | null;
        linkByCastId: {
          __typename: "Link";
          id: any;
          name: string;
          url: string;
          linkType: LinkType;
          cloudConfig: any | null;
          fileByFileId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
          } | null;
        } | null;
        siteByCastId: {
          __typename: "Site";
          id: any;
          name: string;
          type: SiteType;
          url: string;
          config: any | null;
          compute: any | null;
          actions: any | null;
          fileByThumbnailId: {
            __typename: "File";
            id: any;
            name: string;
            mimetype: string | null;
            metadata: any;
            source: string | null;
            fileProcessingStatus: JobStatus | null;
          } | null;
        } | null;
        appInstanceByCastId: {
          __typename: "AppInstance";
          id: any;
          name: string | null;
          config: any | null;
          appVersionByAppInstanceId: {
            __typename: "AppVersion";
            id: any;
            manifestJson: any | null;
            appByAppId: {
              __typename: "App";
              id: any;
              name: string;
              iconUrl: string | null;
            } | null;
          } | null;
          appInstallByAppInstallId: {
            __typename: "AppInstall";
            id: any;
          } | null;
        } | null;
      } | null;
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        }>;
      };
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        }>;
      };
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          id: any;
          name: string;
          content: any | null;
          spaceId: any;
          draft: {
            __typename: "Playlist";
            id: any;
            isPublished: boolean | null;
          } | null;
        }>;
      };
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    }> | null;
  } | null;
};

export type AllScreensQueryVariables = Exact<{
  fetchSize: InputMaybe<Scalars["Int"]>;
  offset: InputMaybe<Scalars["Int"]>;
  orderBy: InputMaybe<Array<ScreensOrderBy> | ScreensOrderBy>;
  filter: InputMaybe<ScreenFilter>;
}>;

export type AllScreensQuery = {
  __typename: "Query";
  allScreens: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      device: any | null;
      name: string;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      status: ScreenStatus;
      deviceInfo: any | null;
      deviceModel: string | null;
      devicePlatform: string | null;
      playerWidth: number | null;
      playerHeight: number | null;
      content: any | null;
      env: any;
      messages: Array<any | null> | null;
      operatingHours: any | null;
      isNotificationActive: boolean | null;
      spaceBySpaceId: {
        __typename: "Space";
        id: any;
        name: string;
        preferences: any;
      } | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        id: any;
        name: string;
      } | null;
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        }>;
      };
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        }>;
      };
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          id: any;
          name: string;
          content: any | null;
          draft: {
            __typename: "Playlist";
            id: any;
            isPublished: boolean | null;
          } | null;
        }>;
      };
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
      screenNotesByScreenId: {
        __typename: "ScreenNotesConnection";
        nodes: Array<{
          __typename: "ScreenNote";
          id: any;
          note: string | null;
          updatedAt: any | null;
          userByUpdatedBy: {
            __typename: "User";
            givenName: string;
            familyName: string;
          } | null;
        }>;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type BulkUpdateScreenTimezoneMutationVariables = Exact<{
  input: BulkUpdateScreenTimezoneInput;
}>;

export type BulkUpdateScreenTimezoneMutation = {
  __typename: "Mutation";
  bulkUpdateScreenTimezone: {
    __typename: "BulkUpdateScreenTimezonePayload";
    screens: Array<{
      __typename: "Screen";
      id: any;
      orgId: any;
      screenGroupId: any;
      name: string;
      timezoneOverride: string;
    }> | null;
  } | null;
};

export type ExportScreensQueryVariables = Exact<{
  orderBy: InputMaybe<Array<ScreensOrderBy> | ScreensOrderBy>;
  filter: InputMaybe<ScreenFilter>;
}>;

export type ExportScreensQuery = {
  __typename: "Query";
  allScreens: {
    __typename: "ScreensConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Screen";
      id: any;
      name: string;
      devicePlatform: string | null;
      deviceModel: string | null;
      status: ScreenStatus;
      spaceId: any;
      isConnected: boolean;
      connectedAt: any | null;
      disconnectedAt: any | null;
      deviceInfo: any | null;
      content: any | null;
      screenGroupByScreenGroupId: {
        __typename: "ScreenGroup";
        name: string;
      } | null;
      spaceBySpaceId: { __typename: "Space"; name: string } | null;
      channelsByScreenId: {
        __typename: "ChannelsConnection";
        nodes: Array<{
          __typename: "Channel";
          id: any;
          name: string;
          spaceId: any | null;
          content: any | null;
          isSharedExplicit: boolean | null;
          isSharedAll: boolean | null;
          childOf: any | null;
          draft: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          published: {
            __typename: "Channel";
            id: any;
            isPublished: boolean | null;
            content: any | null;
          } | null;
          parentChannelByChildOf: {
            __typename: "ParentChannelByChildOf";
            id: any | null;
            name: string | null;
            spaceId: any | null;
            spaceName: string | null;
            content: any | null;
          } | null;
        }>;
      };
      appInstancesByScreenId: {
        __typename: "AppInstancesConnection";
        nodes: Array<{
          __typename: "AppInstance";
          id: any;
          name: string | null;
        }>;
      };
      filesByScreenId: {
        __typename: "FilesConnection";
        nodes: Array<{
          __typename: "File";
          id: any;
          name: string;
          mimetype: string | null;
          metadata: any;
          source: string | null;
          fileProcessingStatus: JobStatus | null;
        }>;
      };
      linksByScreenId: {
        __typename: "LinksConnection";
        nodes: Array<{ __typename: "Link"; id: any; name: string }>;
      };
      playlistsByScreenId: {
        __typename: "PlaylistsConnection";
        nodes: Array<{
          __typename: "Playlist";
          id: any;
          name: string;
          content: any | null;
          draft: {
            __typename: "Playlist";
            id: any;
            isPublished: boolean | null;
          } | null;
        }>;
      };
      sitesByScreenId: {
        __typename: "SitesConnection";
        nodes: Array<{ __typename: "Site"; id: any; name: string }>;
      };
    }>;
  } | null;
};

export type ScreensByDevicePlatformQueryVariables = Exact<{
  devicePlatformList: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type ScreensByDevicePlatformQuery = {
  __typename: "Query";
  allScreens: { __typename: "ScreensConnection"; totalCount: number } | null;
};

export type SubscribeScreenNotificationByOrgIdMutationVariables = Exact<{
  input: SubscribeScreenNotificationByOrgIdInput;
}>;

export type SubscribeScreenNotificationByOrgIdMutation = {
  __typename: "Mutation";
  subscribeScreenNotificationByOrgId: {
    __typename: "SubscribeScreenNotificationByOrgIdPayload";
    notificationRules: Array<{
      __typename: "NotificationRule";
      id: any;
      orgId: any;
      spaceId: any | null;
      event: string;
      active: boolean;
      waitTime: number;
      createdBy: any | null;
      screenId: any;
    }> | null;
  } | null;
};

export type SubscribeScreenNotificationByScreenIdsMutationVariables = Exact<{
  input: SubscribeScreenNotificationByScreenIdsInput;
}>;

export type SubscribeScreenNotificationByScreenIdsMutation = {
  __typename: "Mutation";
  subscribeScreenNotificationByScreenIds: {
    __typename: "SubscribeScreenNotificationByScreenIdsPayload";
    notificationRules: Array<{
      __typename: "NotificationRule";
      id: any;
      orgId: any;
      spaceId: any | null;
      event: string;
      active: boolean;
      waitTime: number;
      createdBy: any | null;
      screenId: any;
    }> | null;
  } | null;
};

export type SubscribeScreenNotificationBySpaceIdMutationVariables = Exact<{
  input: SubscribeScreenNotificationBySpaceIdInput;
}>;

export type SubscribeScreenNotificationBySpaceIdMutation = {
  __typename: "Mutation";
  subscribeScreenNotificationBySpaceId: {
    __typename: "SubscribeScreenNotificationBySpaceIdPayload";
    notificationRules: Array<{
      __typename: "NotificationRule";
      id: any;
      orgId: any;
      spaceId: any | null;
      event: string;
      active: boolean;
      waitTime: number;
      createdBy: any | null;
      screenId: any;
    }> | null;
  } | null;
};

export type UnsubscribeAllScreenNotificationsMutationVariables = Exact<{
  input: UnsubscribeAllScreenNotificationsInput;
}>;

export type UnsubscribeAllScreenNotificationsMutation = {
  __typename: "Mutation";
  unsubscribeAllScreenNotifications: {
    __typename: "UnsubscribeAllScreenNotificationsPayload";
    notificationRules: Array<{
      __typename: "NotificationRule";
      id: any;
      orgId: any;
      screenId: any;
    }> | null;
  } | null;
};

export type UnsubscribeScreenNotificationByScreenIdsMutationVariables = Exact<{
  input: UnsubscribeScreenNotificationByScreenIdsInput;
}>;

export type UnsubscribeScreenNotificationByScreenIdsMutation = {
  __typename: "Mutation";
  unsubscribeScreenNotificationByScreenIds: {
    __typename: "UnsubscribeScreenNotificationByScreenIdsPayload";
    notificationRules: Array<{
      __typename: "NotificationRule";
      id: any;
      orgId: any;
      screenId: any;
    }> | null;
  } | null;
};

export type UnsubscribeScreenNotificationBySpaceIdMutationVariables = Exact<{
  input: UnsubscribeScreenNotificationBySpaceIdInput;
}>;

export type UnsubscribeScreenNotificationBySpaceIdMutation = {
  __typename: "Mutation";
  unsubscribeScreenNotificationBySpaceId: {
    __typename: "UnsubscribeScreenNotificationBySpaceIdPayload";
    notificationRules: Array<{
      __typename: "NotificationRule";
      id: any;
      orgId: any;
      screenId: any;
    }> | null;
  } | null;
};

export type CreateSiteMutationVariables = Exact<{
  input: CreateSiteInput;
}>;

export type CreateSiteMutation = {
  __typename: "Mutation";
  createSite: {
    __typename: "CreateSitePayload";
    success: boolean | null;
    site: {
      __typename: "Site";
      id: any;
      orgId: any;
      spaceId: any;
      url: string;
      type: SiteType;
      config: any | null;
      compute: any | null;
      actions: any | null;
      name: string;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        orgId: any;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        fileProcessingStatus: JobStatus | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteSiteByIdMutationVariables = Exact<{
  input: DeleteSiteByIdInput;
}>;

export type DeleteSiteByIdMutation = {
  __typename: "Mutation";
  deleteSiteById: {
    __typename: "DeleteSiteByIdPayload";
    site: { __typename: "Site"; id: any } | null;
  } | null;
};

export type SearchSiteQueryVariables = Exact<{
  query: InputMaybe<Scalars["String"]>;
  spaceId: InputMaybe<Scalars["UUID"]>;
  endCursor: InputMaybe<Scalars["Cursor"]>;
  orderBy: InputMaybe<Array<SitesOrderBy> | SitesOrderBy>;
  first: InputMaybe<Scalars["Int"]>;
  filter: InputMaybe<SiteFilter>;
}>;

export type SearchSiteQuery = {
  __typename: "Query";
  searchSite: {
    __typename: "SitesConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "Site";
      id: any;
      orgId: any;
      spaceId: any;
      name: string;
      type: SiteType;
      url: string;
      config: any | null;
      compute: any | null;
      actions: any | null;
      isFavorite: boolean | null;
      tags: Array<string | null>;
      createdAt: any | null;
      createdBy: any | null;
      updatedAt: any | null;
      updatedBy: any | null;
      isSharedExplicit: boolean | null;
      isSharedAll: boolean | null;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        orgId: any;
        fileProcessingStatus: JobStatus | null;
        folderId: any | null;
        mimetype: string | null;
        name: string;
        spaceId: any | null;
        tags: Array<string | null>;
        size: any | null;
        source: string | null;
        metadata: any;
        isFavorite: boolean | null;
        expireAt: any | null;
        availableAt: any | null;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        fileOutputsByFileId: {
          __typename: "FileOutputsConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "FileOutput";
            id: any;
            content: any | null;
            metadata: any | null;
            mimetype: string | null;
            url: string | null;
            size: any | null;
          }>;
        };
      } | null;
      castedScreenBySiteId: {
        __typename: "ScreensConnection";
        totalCount: number;
        nodes: Array<{
          __typename: "Screen";
          id: any;
          name: string;
          spaceId: any;
          device: any | null;
          deviceModel: string | null;
        }>;
      };
      userByCreatedBy: {
        __typename: "User";
        id: any;
        givenName: string;
        familyName: string;
      } | null;
      sharedSpacesBySiteId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type SiteByIdForContentQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type SiteByIdForContentQuery = {
  __typename: "Query";
  siteById: {
    __typename: "Site";
    id: any;
    orgId: any;
    spaceId: any;
    url: string;
    type: SiteType;
    config: any | null;
    compute: any | null;
    actions: any | null;
    name: string;
    fileByThumbnailId: {
      __typename: "File";
      id: any;
      orgId: any;
      metadata: any;
      source: string | null;
      mimetype: string | null;
      fileProcessingStatus: JobStatus | null;
    } | null;
  } | null;
};

export type SiteListQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  endCursor: InputMaybe<Scalars["Cursor"]>;
  orderBy: InputMaybe<Array<SitesOrderBy> | SitesOrderBy>;
  filter: InputMaybe<SiteFilter>;
  first: InputMaybe<Scalars["Int"]>;
}>;

export type SiteListQuery = {
  __typename: "Query";
  spaceById: {
    __typename: "Space";
    id: any;
    availableSitesBySpaceId: {
      __typename: "SitesConnection";
      totalCount: number;
      nodes: Array<{
        __typename: "Site";
        id: any;
        orgId: any;
        spaceId: any;
        name: string;
        type: SiteType;
        url: string;
        config: any | null;
        compute: any | null;
        actions: any | null;
        isFavorite: boolean | null;
        tags: Array<string | null>;
        createdAt: any | null;
        createdBy: any | null;
        updatedAt: any | null;
        updatedBy: any | null;
        isSharedExplicit: boolean | null;
        isSharedAll: boolean | null;
        fileByThumbnailId: {
          __typename: "File";
          id: any;
          orgId: any;
          fileProcessingStatus: JobStatus | null;
          folderId: any | null;
          mimetype: string | null;
          name: string;
          spaceId: any | null;
          tags: Array<string | null>;
          size: any | null;
          source: string | null;
          metadata: any;
          isFavorite: boolean | null;
          expireAt: any | null;
          availableAt: any | null;
          createdAt: any | null;
          createdBy: any | null;
          updatedAt: any | null;
          updatedBy: any | null;
          fileOutputsByFileId: {
            __typename: "FileOutputsConnection";
            totalCount: number;
            nodes: Array<{
              __typename: "FileOutput";
              id: any;
              content: any | null;
              metadata: any | null;
              mimetype: string | null;
              url: string | null;
              size: any | null;
            }>;
          };
        } | null;
        castedScreenBySiteId: {
          __typename: "ScreensConnection";
          totalCount: number;
          nodes: Array<{
            __typename: "Screen";
            id: any;
            name: string;
            spaceId: any;
            device: any | null;
            deviceModel: string | null;
          }>;
        };
        userByCreatedBy: {
          __typename: "User";
          id: any;
          givenName: string;
          familyName: string;
        } | null;
        sharedSpacesBySiteId: {
          __typename: "SpacesConnection";
          nodes: Array<{ __typename: "Space"; id: any }>;
        };
      }>;
      pageInfo: {
        __typename: "PageInfo";
        hasNextPage: boolean;
        endCursor: any | null;
      };
    };
  } | null;
};

export type UpdateShareSiteToAllSpacesMutationVariables = Exact<{
  input: UpdateShareSiteToAllSpacesInput;
}>;

export type UpdateShareSiteToAllSpacesMutation = {
  __typename: "Mutation";
  updateShareSiteToAllSpaces: {
    __typename: "UpdateShareSiteToAllSpacesPayload";
    site: {
      __typename: "Site";
      id: any;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
      sharedSpacesBySiteId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
    } | null;
  } | null;
};

export type UpdateShareSiteToSpacesMutationVariables = Exact<{
  input: UpdateShareSiteToSpacesInput;
}>;

export type UpdateShareSiteToSpacesMutation = {
  __typename: "Mutation";
  updateShareSiteToSpaces: {
    __typename: "UpdateShareSiteToSpacesPayload";
    site: {
      __typename: "Site";
      id: any;
      isSharedAll: boolean | null;
      isSharedExplicit: boolean | null;
      sharedSpacesBySiteId: {
        __typename: "SpacesConnection";
        nodes: Array<{ __typename: "Space"; id: any }>;
      };
    } | null;
  } | null;
};

export type UpdateSiteByIdMutationVariables = Exact<{
  input: UpdateSiteInput;
}>;

export type UpdateSiteByIdMutation = {
  __typename: "Mutation";
  updateSite: {
    __typename: "UpdateSitePayload";
    success: boolean | null;
    site: {
      __typename: "Site";
      id: any;
      orgId: any;
      spaceId: any;
      url: string;
      type: SiteType;
      config: any | null;
      compute: any | null;
      actions: any | null;
      name: string;
      fileByThumbnailId: {
        __typename: "File";
        id: any;
        orgId: any;
        metadata: any;
        source: string | null;
        mimetype: string | null;
        fileProcessingStatus: JobStatus | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateSiteIsFavoriteMutationVariables = Exact<{
  input: UpdateSiteIsFavoriteInput;
}>;

export type UpdateSiteIsFavoriteMutation = {
  __typename: "Mutation";
  updateSiteIsFavorite: {
    __typename: "UpdateSiteIsFavoritePayload";
    favorites: Array<{
      __typename: "Favorite";
      siteId: any | null;
      siteBySiteId: {
        __typename: "Site";
        id: any;
        isFavorite: boolean | null;
      } | null;
    }> | null;
  } | null;
};

export type RecycleBinFragment = {
  __typename: "RecycleBin";
  orgId: any;
  spaceId: any;
  objectId: any;
  objectType: string;
  objectSubType: string | null;
  name: string | null;
  thumbnail: string | null;
  deletedAt: any;
};

export type PermanentlyDeleteAllItemsMutationVariables = Exact<{
  input: PermanentlyDeleteAllItemsInput;
}>;

export type PermanentlyDeleteAllItemsMutation = {
  __typename: "Mutation";
  permanentlyDeleteAllItems: {
    __typename: "PermanentlyDeleteAllItemsPayload";
    recycleBins: Array<{
      __typename: "RecycleBin";
      orgId: any;
      spaceId: any;
      objectId: any;
      objectType: string;
      objectSubType: string | null;
      name: string | null;
      thumbnail: string | null;
      deletedAt: any;
    } | null> | null;
  } | null;
};

export type PermanentlyDeleteByIdsAndSpaceIdMutationVariables = Exact<{
  input: PermanentlyDeleteByIdsAndSpaceIdInput;
}>;

export type PermanentlyDeleteByIdsAndSpaceIdMutation = {
  __typename: "Mutation";
  permanentlyDeleteByIdsAndSpaceId: {
    __typename: "PermanentlyDeleteByIdsAndSpaceIdPayload";
    recycleBins: Array<{
      __typename: "RecycleBin";
      orgId: any;
      spaceId: any;
      objectId: any;
      objectType: string;
      objectSubType: string | null;
      name: string | null;
      thumbnail: string | null;
      deletedAt: any;
    } | null> | null;
  } | null;
};

export type RecycleBinBySpaceIdQueryVariables = Exact<{
  spaceId: Scalars["UUID"];
  first: InputMaybe<Scalars["Int"]>;
  after: InputMaybe<Scalars["Cursor"]>;
}>;

export type RecycleBinBySpaceIdQuery = {
  __typename: "Query";
  recycleBinBySpaceId: {
    __typename: "RecycleBinsConnection";
    totalCount: number;
    nodes: Array<{
      __typename: "RecycleBin";
      orgId: any;
      spaceId: any;
      objectId: any;
      objectType: string;
      objectSubType: string | null;
      name: string | null;
      thumbnail: string | null;
      deletedAt: any;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: any | null;
    };
  } | null;
};

export type RestoreAllItemsMutationVariables = Exact<{
  input: RestoreAllItemsInput;
}>;

export type RestoreAllItemsMutation = {
  __typename: "Mutation";
  restoreAllItems: {
    __typename: "RestoreAllItemsPayload";
    recycleBins: Array<{
      __typename: "RecycleBin";
      orgId: any;
      spaceId: any;
      objectId: any;
      objectType: string;
      objectSubType: string | null;
      name: string | null;
      thumbnail: string | null;
      deletedAt: any;
    } | null> | null;
  } | null;
};

export type RestoreByIdsAndSpaceIdMutationVariables = Exact<{
  input: RestoreByIdsAndSpaceIdInput;
}>;

export type RestoreByIdsAndSpaceIdMutation = {
  __typename: "Mutation";
  restoreByIdsAndSpaceId: {
    __typename: "RestoreByIdsAndSpaceIdPayload";
    recycleBins: Array<{
      __typename: "RecycleBin";
      orgId: any;
      spaceId: any;
      objectId: any;
      objectType: string;
      objectSubType: string | null;
      name: string | null;
      thumbnail: string | null;
      deletedAt: any;
    } | null> | null;
  } | null;
};

export const CustomerFragmentDoc = gql`
  fragment Customer on BillingCustomer {
    __typename
    id
    parentId
    paymentOwnerId
    invoiceOwnerId
    company
    firstName
    lastName
    email
    phone
    vatNumber
    vatNumberStatus
    autoCollection
    taxability
    billingDate
    billingDateMode
    billingDayOfWeek
    billingDayOfWeekMode
    preferredCurrencyCode
    cfAllowLicence
    cfPartner
    cfInvoicedPayment
    createdAt
    updatedAt
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on BillingAddress {
    __typename
    firstName
    lastName
    email
    company
    phone
    line1
    line2
    line3
    city
    stateCode
    state
    country
    zip
    validationStatus
  }
`;
export const SubscriptionFragmentDoc = gql`
  fragment Subscription on BillingSubscription {
    __typename
    id
    currencyCode
    planId
    planQuantity
    planUnitPrice
    planAmount
    status
    poNumber
    autoCollection
    dueInvoicesCount
    totalDues
    baseCurrencyCode
    cfPrimary
    dueSince
    trialStart
    trialEnd
    currentTermStart
    currentTermEnd
    pauseDate
    resumeDate
    startDate
    startedAt
    activatedAt
    cancelledAt
    nextBillingAt
    createdAt
    billingAddonSubscriptionsByBillingSubscriptionId {
      nodes {
        __typename
        billingAddonId
        billingSubscriptionId
        billingCustomerId
        billingAddonByBillingAddonId {
          id
          name
          currencyCode
          price
        }
        unitPrice
      }
    }
  }
`;
export const SubscriptionFutureFragmentDoc = gql`
  fragment SubscriptionFuture on BillingSubscriptionFuture {
    __typename
    id
    currencyCode
    planId
    planQuantity
    planUnitPrice
    planAmount
    status
    poNumber
    autoCollection
    dueInvoicesCount
    totalDues
    baseCurrencyCode
    cfPrimary
    dueSince
    trialStart
    trialEnd
    currentTermStart
    currentTermEnd
    pauseDate
    resumeDate
    startDate
    startedAt
    activatedAt
    cancelledAt
    nextBillingAt
    createdAt
  }
`;
export const PlanFragmentDoc = gql`
  fragment Plan on BillingPlan {
    __typename
    id
    name
    description
    price
    currencyCode
    period
    periodUnit
    trialPeriod
    trialPeriodUnit
    pricingModel
    freeQuantity
    cfSubscriptionType
    cfIsLegacy
    cfEnterprise
    cfSpecial
    cfName
    cfIncludedPhysicalScreenLicenses
    cfIncludedVirtualScreenLicenses
    billingAddonPlansByBillingPlanId {
      nodes {
        billingAddonByBillingAddonId {
          id
          name
          currencyCode
        }
      }
    }
    billingSubscriptionsByPlanId {
      nodes {
        id
        planAmount
        planQuantity
        planUnitPrice
        baseCurrencyCode
        billingAddonSubscriptionsByBillingSubscriptionId {
          nodes {
            billingAddonByBillingAddonId {
              id
              name
              currencyCode
            }
          }
        }
      }
    }
  }
`;
export const CouponFragmentDoc = gql`
  fragment Coupon on BillingCoupon {
    __typename
    id
    name
    discountType
    discountPercentage
    discountAmount
    currencyCode
    status
  }
`;
export const PaymentSourceFragmentDoc = gql`
  fragment PaymentSource on BillingPaymentSource {
    __typename
    id
    type
    status
    card
    bankAccount
    paypal
    createdAt
    updatedAt
  }
`;
export const RenewalFragmentDoc = gql`
  fragment Renewal on BillingRenewal {
    __typename
    subTotal
    total
    creditsApplied
    amountPaid
    amountDue
    taxDescription
    taxRate
    taxAmount
    lineItems
    discounts
    taxes
    lineItemTaxes
    lineItemDiscounts
    nextBillingAt
    createdAt
  }
`;
export const RenewalFutureFragmentDoc = gql`
  fragment RenewalFuture on BillingRenewalFuture {
    __typename
    subTotal
    total
    creditsApplied
    amountPaid
    amountDue
    taxDescription
    taxRate
    taxAmount
    lineItems
    discounts
    taxes
    lineItemTaxes
    lineItemDiscounts
    nextBillingAt
    createdAt
  }
`;
export const AccountPlanFragmentDoc = gql`
  fragment AccountPlan on BillingPlan {
    __typename
    id
    cfSubscriptionType
  }
`;
export const AccountSubscriptionFragmentDoc = gql`
  fragment AccountSubscription on BillingSubscription {
    __typename
    status
    currencyCode
    planQuantity
    billingPlanByPlanId {
      ...AccountPlan
    }
  }
  ${AccountPlanFragmentDoc}
`;
export const AccountRenewalFragmentDoc = gql`
  fragment AccountRenewal on BillingRenewal {
    __typename
    total
  }
`;
export const AccountSpaceFragmentDoc = gql`
  fragment AccountSpace on Space {
    __typename
    id
    name
    screens: screensBySpaceId(
      filter: { status: { in: [LIVE, SUSPENDED] }, preview: { equalTo: false } }
    ) {
      totalCount
    }
  }
`;
export const AccountFragmentDoc = gql`
  fragment Account on BillingCustomer {
    __typename
    id
    company
    billingSubscriptionsByBillingCustomerId {
      nodes {
        ...AccountSubscription
      }
    }
    billingRenewalsByBillingCustomerId {
      nodes {
        ...AccountRenewal
      }
    }
    spacesByBillingCustomerId {
      nodes {
        ...AccountSpace
      }
    }
  }
  ${AccountSubscriptionFragmentDoc}
  ${AccountRenewalFragmentDoc}
  ${AccountSpaceFragmentDoc}
`;
export const BillingPlanByIdFragmentDoc = gql`
  fragment BillingPlanById on BillingPlan {
    __typename
    id
    name
    price
    periodUnit
    cfName
    cfIncludedPhysicalScreenLicenses
  }
`;
export const SpaceSimpleFragmentDoc = gql`
  fragment SpaceSimple on Space {
    __typename
    id
    name
  }
`;
export const PaymentAccountSimpleFragmentDoc = gql`
  fragment PaymentAccountSimple on BillingCustomer {
    __typename
    id
    company
    spacesByBillingCustomerId {
      nodes {
        __typename
        id
        name
      }
    }
    billingSubscriptionsByBillingCustomerId {
      nodes {
        __typename
        id
        status
        planQuantity
        billingPlanByPlanId {
          __typename
          name
          cfName
        }
      }
    }
  }
`;
export const PaymentAccountFragmentDoc = gql`
  fragment PaymentAccount on BillingCustomer {
    __typename
    id
    parentId
    invoiceOwnerId
    paymentOwnerId
    company
    email
    cfPrimary
    spacesByBillingCustomerId {
      nodes {
        __typename
        id
        name
      }
    }
    billingSubscriptionsByBillingCustomerId {
      nodes {
        currencyCode
      }
    }
  }
`;
export const SpaceWithUsedLicensesFragmentDoc = gql`
  fragment SpaceWithUsedLicenses on Space {
    __typename
    id
    name
    licenses: screensBySpaceId(
      filter: { status: { in: [LIVE, SUSPENDED] }, preview: { equalTo: false } }
    ) {
      used: totalCount
    }
  }
`;
export const FileOutputFragmentDoc = gql`
  fragment FileOutput on FileOutput {
    __typename
    id
    content
    metadata
    mimetype
    url
    size
  }
`;
export const ChannelByFromChannelAssociationFragmentDoc = gql`
  fragment ChannelByFromChannelAssociation on Channel {
    __typename
    id
    name
    draftOf
    coverData
    coverImageId
    fileByCoverImageId {
      __typename
      id
      orgId
      metadata
      name
      source
      mimetype
      fileProcessingStatus
      fileOutputsByFileId(first: 10) {
        nodes {
          ...FileOutput
        }
      }
    }
    spaceBySpaceId {
      __typename
      id
      name
    }
  }
  ${FileOutputFragmentDoc}
`;
export const PlaylistAssociationFragmentDoc = gql`
  fragment PlaylistAssociation on Playlist {
    __typename
    id
    name
    associationsByToPlaylistAndOrgId {
      nodes {
        channelByFromChannelAndOrgId {
          ...ChannelByFromChannelAssociation
        }
      }
    }
  }
  ${ChannelByFromChannelAssociationFragmentDoc}
`;
export const FileFragmentDoc = gql`
  fragment File on File {
    __typename
    id
    orgId
    fileProcessingStatus
    folderId
    mimetype
    name
    spaceId
    tags
    size
    source
    metadata
    isFavorite
    expireAt
    availableAt
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
export const AppInstanceByIdForContentFragmentDoc = gql`
  fragment AppInstanceByIdForContent on AppInstance {
    __typename
    id
    orgId
    config
    name
    availableAt
    expireAt
    fileByThumbnailFileId {
      ...File
    }
    appByAppId {
      __typename
      id
      name
      iconUrl
    }
  }
  ${FileFragmentDoc}
`;
export const AppInstanceForMultiSelectFragmentDoc = gql`
  fragment AppInstanceForMultiSelect on AppInstance {
    __typename
    id
    isSharedExplicit
    isSharedAll
    spaceId
  }
`;
export const AvailableAppInstallsFragmentDoc = gql`
  fragment AvailableAppInstalls on AppInstall {
    __typename
    id
    spaceId
    appByAppId {
      __typename
      id
      name
      iconUrl
      categories
      defaultInstall
      appVersionByAppId {
        storeData
      }
    }
  }
`;
export const AvailableAppInstallsWithAvailableAppInstancesByAppInstallIdAndSpaceFragmentDoc = gql`
  fragment AvailableAppInstallsWithAvailableAppInstancesByAppInstallIdAndSpace on AppInstall {
    ...AvailableAppInstalls
    availableAppInstancesByAppInstallIdAndSpace(spaceId: $spaceId) {
      totalCount
    }
  }
  ${AvailableAppInstallsFragmentDoc}
`;
export const AvailableAppInstallsWithAppInstancesByAppInstallIdFragmentDoc = gql`
  fragment AvailableAppInstallsWithAppInstancesByAppInstallId on AppInstall {
    ...AvailableAppInstalls
    appInstancesByAppInstallId {
      totalCount
    }
  }
  ${AvailableAppInstallsFragmentDoc}
`;
export const AssociationsFragmentDoc = gql`
  fragment Associations on Association {
    castByFromCastAndOrgId {
      __typename
      id
      screensByCastId {
        nodes {
          __typename
          id
          name
        }
      }
    }
    channelByFromChannelAndOrgId {
      __typename
      id
      name
      draft {
        id
        name
      }
    }
    playlistByFromPlaylistAndOrgId {
      __typename
      id
      name
      draft {
        id
        name
      }
    }
    appInstanceByFromAppInstanceAndOrgId {
      __typename
      id
      name
    }
    screenByFromScreenAndOrgId {
      __typename
      id
      name
    }
  }
`;
export const PlaylistAndChannelAssociationsFragmentDoc = gql`
  fragment PlaylistAndChannelAssociations on Association {
    channelByFromChannelAndOrgId {
      __typename
      id
      name
      coverData
      fileByCoverImageId {
        source
      }
      draft {
        id
        name
        __typename
      }
    }
    playlistByFromPlaylistAndOrgId {
      __typename
      id
      name
      color
      draft {
        id
        name
        __typename
      }
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    __typename
    id
    orgId
    email
    status
    userType
    givenName
    familyName
    receiveUpdates
    isOwner
    metadata
    preferences
    permissions
    permissionsByGroupIds
    permissionsBySpaceIds
    deletedAt
    deletedBy
    createdAt
    createdBy
    updatedAt
    updatedBy
    lastAuthenticatedAt
  }
`;
export const FileListItemFragmentDoc = gql`
  fragment FileListItem on File {
    __typename
    id
    orgId
    spaceId
    folderId
    name
    metadata
    source
    mimetype
    tags
    size
    isSharedAll
    isFavorite
    isSharedExplicit
    fileProcessingStatus
    castedScreenByFileId {
      nodes {
        __typename
        id
        name
        device
        spaceId
      }
      totalCount
    }
    fileOutputsByFileId {
      nodes {
        ...FileOutput
      }
    }
    userByCreatedBy {
      ...User
    }
    createdAt
    expireAt
    availableAt
  }
  ${FileOutputFragmentDoc}
  ${UserFragmentDoc}
`;
export const ParentChannelFragmentDoc = gql`
  fragment ParentChannel on ParentChannelByChildOf {
    __typename
    id
    name
    spaceId
    spaceName
    content
  }
`;
export const ChannelListItemFragmentDoc = gql`
  fragment ChannelListItem on Channel {
    __typename
    id
    spaceId
    name
    coverData
    coverImageId
    layoutId
    width
    height
    createdAt
    layoutByChannel {
      __typename
      id
      name
      config
    }
    fileByCoverImageId {
      ...FileListItem
    }
    draft {
      __typename
      id
      isPublished
    }
    hiddenZone
    isSharedAll
    preferences
    isSharedExplicit
    isEmbedEnabled
    isEmbedPublic
    embedsByChannelId {
      nodes {
        __typename
        id
        shortId
      }
    }
    castedScreenByChannelId {
      nodes {
        __typename
        id
        name
        spaceId
      }
      totalCount
    }
    childOf
    parentChannelByChildOf {
      ...ParentChannel
    }
    duplicateOf
  }
  ${FileListItemFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export const SharedChannelListItemFragmentDoc = gql`
  fragment SharedChannelListItem on Channel {
    __typename
    id
    spaceId
    name
    coverData
    coverImageId
    layoutId
    width
    height
    createdAt
    layoutByChannel {
      __typename
      id
      name
      config
    }
    fileByCoverImageId {
      ...FileListItem
    }
    draft {
      __typename
      id
      isPublished
    }
    hiddenZone
    isSharedExplicit
    isSharedAll
    isEmbedEnabled
    isEmbedPublic
    castedScreenByChannelId {
      nodes {
        __typename
        id
        name
        spaceId
      }
      totalCount
    }
    embedsByChannelId {
      nodes {
        __typename
        id
        shortId
      }
    }
    childOf
  }
  ${FileListItemFragmentDoc}
`;
export const ChannelFragmentDoc = gql`
  fragment Channel on Channel {
    __typename
    id
    orgId
    spaceId
    themeId
    tags
    name
    publishedAt
    publishedBy
    coverData
    coverImageId
    layoutId
    hiddenZone
    content
    isPublished
    isSharedExplicit
    isSharedAll
    isParent
    playingScreenNum
    width
    height
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
export const LayoutFragmentDoc = gql`
  fragment Layout on Layout {
    __typename
    id
    orgId
    spaceId
    name
    zones
    config
    width
    height
    isFlexible
    isScalable
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
export const ChannelDetailFragmentDoc = gql`
  fragment ChannelDetail on Channel {
    ...Channel
    layoutByChannel {
      ...Layout
    }
    playlistsByChannelId {
      nodes {
        __typename
        color
        content
        draft {
          isPublished
        }
        id
        isFavorite
        isSharedExplicit
        isSharedAll
        isEmbedEnabled
        isEmbedPublic
        name
        spaceId
        tags
      }
    }
    filesByChannelId {
      nodes {
        __typename
        id
        name
        expireAt
      }
    }
    linksByChannelId {
      nodes {
        __typename
        id
        name
      }
    }
    sitesByChannelId {
      nodes {
        __typename
        id
        orgId
        name
        type
        url
        config
        actions
        fileByThumbnailId {
          __typename
          id
          orgId
          metadata
          source
          mimetype
        }
      }
    }
    appInstancesByChannelId {
      nodes {
        __typename
        id
        name
        expireAt
      }
    }
  }
  ${ChannelFragmentDoc}
  ${LayoutFragmentDoc}
`;
export const ChannelByIdFragmentDoc = gql`
  fragment ChannelById on Channel {
    ...Channel
    fileByCoverImageId {
      __typename
      id
      orgId
      metadata
      name
      source
      mimetype
      fileProcessingStatus
      fileOutputsByFileId(first: 10) {
        nodes {
          ...FileOutput
        }
      }
    }
    draft {
      ...ChannelDetail
    }
    published {
      ...ChannelDetail
    }
    isSharedExplicit
    isSharedAll
    isEmbedEnabled
    isEmbedPublic
    embedsByChannelId {
      nodes {
        __typename
        id
        shortId
      }
    }
    preferences
    castedScreenByChannelId {
      nodes {
        __typename
        id
        name
        spaceId
      }
      totalCount
    }
    childOf
    parentChannelByChildOf {
      ...ParentChannel
    }
    duplicateOf
  }
  ${ChannelFragmentDoc}
  ${FileOutputFragmentDoc}
  ${ChannelDetailFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export const SearchChannelFragmentDoc = gql`
  fragment SearchChannel on Channel {
    __typename
    id
    spaceId
    name
    coverData
    coverImageId
    content
    layoutId
    width
    height
    layoutByChannel {
      __typename
      id
      name
      config
    }
    fileByCoverImageId {
      ...File
    }
    draft {
      __typename
      id
      isPublished
    }
    isSharedExplicit
    isSharedAll
    screensByChannelId {
      totalCount
    }
  }
  ${FileFragmentDoc}
`;
export const CastItemFragmentDoc = gql`
  fragment CastItem on Cast {
    __typename
    id
    screensByCastId(first: $expectedItemPerPage, offset: $offset) {
      nodes {
        __typename
        id
        spaceId
        name
        device
      }
    }
  }
`;
export const FontFragmentDoc = gql`
  fragment Font on Font {
    __typename
    id
    orgId
    name
    fontFamily
    regularFileId
    boldFileId
    italicFileId
    boldItalicFileId
    thumbnailFileId
    cssUrl
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
`;
export const AllFontItemsFragmentDoc = gql`
  fragment AllFontItems on Font {
    __typename
    id
    commonFontId
    orgId
    name
    fontFamily
    thumbnailFileId
    regularFileId
    boldFileId
    italicFileId
    boldItalicFileId
    cssUrl
    createdAt
    fileByRegularFileId {
      ...File
    }
    fileByBoldFileId {
      ...File
    }
    fileByItalicFileId {
      ...File
    }
    fileByBoldItalicFileId {
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export const AllCommonFontItemsFragmentDoc = gql`
  fragment AllCommonFontItems on CommonFont {
    __typename
    id
    name
    fontFamily
    cssUrl
  }
`;
export const LinkListItemFragmentDoc = gql`
  fragment LinkListItem on Link {
    __typename
    id
    orgId
    url
    cacheBusting
    autoReload
    autoReloadDurationMs
    qrcodeEnabled
    qrcodeConfig
    fileByFileId {
      ...File
    }
    linkType
    cloudConfig
    isFavorite
    name
    tags
    spaceId
    createdAt
    createdBy
    updatedAt
    updatedBy
    castedScreenByLinkId {
      nodes {
        __typename
        id
        name
        deviceId
        deviceModel
      }
    }
    userByCreatedBy {
      __typename
      id
      givenName
      familyName
      email
    }
  }
  ${FileFragmentDoc}
`;
export const CommonFileItemFragmentDoc = gql`
  fragment CommonFileItem on CommonFile {
    __typename
    id
    name
    metadata
    source
    mimetype
    fileProcessingStatus
    filesByCommonFileId(first: 10) {
      nodes {
        ...FileListItem
      }
    }
    createdAt
    expireAt
    availableAt
  }
  ${FileListItemFragmentDoc}
`;
export const FileAssociationsFragmentDoc = gql`
  fragment FileAssociations on Association {
    castByFromCastAndOrgId {
      __typename
      id
      screensByCastId {
        nodes {
          __typename
          id
          name
        }
      }
    }
    channelByFromChannelAndOrgId {
      __typename
      id
      name
      draft {
        id
        name
      }
    }
    playlistByFromPlaylistAndOrgId {
      __typename
      id
      name
      draft {
        id
        name
      }
    }
    appInstanceByFromAppInstanceAndOrgId {
      __typename
      id
      name
    }
    screenByFromScreenAndOrgId {
      __typename
      id
      name
    }
  }
`;
export const FileListItemForPlaylistFragmentDoc = gql`
  fragment FileListItemForPlaylist on File {
    __typename
    id
    name
    metadata
    mimetype
    source
    expireAt
    fileProcessingStatus
    fileOutputsByFileId(first: 3) {
      nodes {
        ...FileOutput
      }
    }
  }
  ${FileOutputFragmentDoc}
`;
export const AppInstanceForContentListWithConfigFragmentDoc = gql`
  fragment AppInstanceForContentListWithConfig on AppInstance {
    __typename
    id
    name
    config
    fileByThumbnailFileId {
      ...FileListItemForPlaylist
    }
    appByAppId {
      __typename
      id
      iconUrl
    }
  }
  ${FileListItemForPlaylistFragmentDoc}
`;
export const PlaylistByIdForListingFragmentDoc = gql`
  fragment PlaylistByIdForListing on Playlist {
    __typename
    id
    tags
    content
    isPublished
    appInstancesByPlaylistId(first: 10) {
      nodes {
        ...AppInstanceForContentListWithConfig
      }
    }
    filesByPlaylistId(first: 10) {
      nodes {
        ...FileListItemForPlaylist
      }
    }
    linksByPlaylistId(first: 10) {
      nodes {
        __typename
        id
        name
        fileByFileId {
          ...FileListItemForPlaylist
        }
      }
    }
    sitesByPlaylistId(first: 10) {
      nodes {
        __typename
        id
        name
        fileByThumbnailId {
          ...FileListItemForPlaylist
        }
      }
    }
  }
  ${AppInstanceForContentListWithConfigFragmentDoc}
  ${FileListItemForPlaylistFragmentDoc}
`;
export const AppInstanceForContentListFragmentDoc = gql`
  fragment AppInstanceForContentList on AppInstance {
    __typename
    id
    name
    fileByThumbnailFileId {
      ...FileListItemForPlaylist
    }
    appByAppId {
      __typename
      id
      iconUrl
    }
  }
  ${FileListItemForPlaylistFragmentDoc}
`;
export const PlaylistByIdForPlaylistListingFragmentDoc = gql`
  fragment PlaylistByIdForPlaylistListing on Playlist {
    __typename
    id
    tags
    content
    isPublished
    appInstancesByPlaylistId(first: 10) {
      nodes {
        ...AppInstanceForContentList
      }
    }
    filesByPlaylistId(first: 10) {
      nodes {
        ...FileListItemForPlaylist
      }
    }
    linksByPlaylistId(first: 10) {
      nodes {
        __typename
        id
        name
        fileByFileId {
          ...FileListItemForPlaylist
        }
      }
    }
    sitesByPlaylistId(first: 10) {
      nodes {
        __typename
        id
        name
        fileByThumbnailId {
          ...FileListItemForPlaylist
        }
      }
    }
  }
  ${AppInstanceForContentListFragmentDoc}
  ${FileListItemForPlaylistFragmentDoc}
`;
export const PlaylistListItemFragmentDoc = gql`
  fragment PlaylistListItem on Playlist {
    __typename
    id
    isFavorite
    isSharedAll
    isSharedExplicit
    isEmbedEnabled
    isEmbedPublic
    embedsByPlaylistId {
      nodes {
        __typename
        id
        shortId
      }
    }
    name
    castedScreenByPlaylistId {
      nodes {
        __typename
        id
        name
        spaceId
        device
        deviceModel
        deviceId
      }
      totalCount
    }
    color
    content
    draft {
      ...PlaylistByIdForPlaylistListing
    }
    published {
      ...PlaylistByIdForPlaylistListing
    }
    sharedSpacesByPlaylistId {
      nodes {
        __typename
        id
        name
      }
    }
    spaceId
    tags
    associationsByToPlaylistAndOrgId {
      nodes {
        channelByFromChannelAndOrgId {
          id
          spaceId
          draftOf
        }
      }
    }
  }
  ${PlaylistByIdForPlaylistListingFragmentDoc}
`;
export const PlaylistByIdDataFragmentDoc = gql`
  fragment PlaylistByIdData on Playlist {
    __typename
    id
    tags
    name
    color
    publishedAt
    publishedBy
    content
    isFavorite
    isPublished
    isSharedExplicit
    isSharedAll
    isEmbedEnabled
    isEmbedPublic
    embedsByPlaylistId {
      nodes {
        __typename
        id
        shortId
      }
    }
    spaceId
    createdAt
    createdBy
    sharedSpacesByPlaylistId {
      nodes {
        __typename
        id
      }
    }
    castedScreenByPlaylistId {
      nodes {
        __typename
        id
        castId
        name
        device
      }
      totalCount
    }
    draft {
      __typename
      id
      tags
      name
      color
      publishedAt
      publishedBy
      content
      isFavorite
      isPublished
      appInstancesByPlaylistId {
        nodes {
          __typename
          id
          name
          config
          expireAt
        }
      }
      filesByPlaylistId {
        nodes {
          ...FileListItemForPlaylist
        }
      }
      linksByPlaylistId {
        nodes {
          __typename
          id
          name
        }
      }
      sitesByPlaylistId {
        nodes {
          __typename
          id
          spaceId
          name
          type
          url
          config
          actions
          fileByThumbnailId {
            ...File
            fileOutputsByFileId {
              nodes {
                ...FileOutput
              }
            }
          }
          tags
        }
      }
    }
  }
  ${FileListItemForPlaylistFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
`;
export const ScreenContentHistoryItemFragmentDoc = gql`
  fragment ScreenContentHistoryItem on ScreenContentHistory {
    __typename
    id
    content
    linkByLinkIdAndOrgId {
      __typename
      id
      orgId
      name
      createdAt
      fileByFileId {
        __typename
        id
        orgId
        metadata
        source
        mimetype
        fileProcessingStatus
      }
    }
    siteBySiteIdAndOrgId {
      __typename
      id
      orgId
      name
      createdAt
      fileByThumbnailId {
        __typename
        id
        orgId
        metadata
        source
        mimetype
        fileProcessingStatus
      }
    }
    channelByChannelIdAndOrgId {
      __typename
      id
      name
      spaceId
      coverData
      coverImageId
      createdAt
      content
      isSharedExplicit
      isSharedAll
      fileByCoverImageId {
        __typename
        id
        orgId
        name
        metadata
        source
        mimetype
        fileProcessingStatus
        fileOutputsByFileId(first: 10) {
          nodes {
            ...FileOutput
          }
        }
      }
      draft {
        __typename
        id
        isPublished
        content
      }
      published {
        __typename
        id
        isPublished
        content
      }
      childOf
      parentChannelByChildOf {
        ...ParentChannel
      }
    }
    playlistByPlaylistIdAndOrgId {
      __typename
      id
      orgId
      spaceId
      name
      color
      createdAt
      draft {
        __typename
        id
        isPublished
        content
      }
    }
    fileByFileIdAndOrgId {
      __typename
      id
      orgId
      name
      metadata
      source
      mimetype
      createdAt
      fileProcessingStatus
      fileOutputsByFileId(first: 10) {
        nodes {
          ...FileOutput
        }
      }
    }
    appInstanceByAppInstanceIdAndOrgId {
      __typename
      id
      orgId
      name
      createdAt
      fileByThumbnailFileId {
        __typename
        id
        name
        mimetype
        metadata
        source
        fileProcessingStatus
      }
      appVersionByAppInstanceId {
        __typename
        id
        appId
        viewerUrl
        appByAppId {
          __typename
          id
          name
          iconUrl
        }
      }
    }
  }
  ${FileOutputFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export const PlaylistDetailFragmentDoc = gql`
  fragment PlaylistDetail on Playlist {
    __typename
    id
    name
    content
    draft {
      __typename
      id
      isPublished
    }
    filesByPlaylistId {
      nodes {
        __typename
        id
        orgId
        name
        mimetype
        source
        metadata
        fileProcessingStatus
        availableAt
        expireAt
        fileOutputsByFileId(first: 10) {
          nodes {
            ...FileOutput
          }
        }
      }
    }
    appInstancesByPlaylistId {
      nodes {
        __typename
        id
        config
        state
        version
        name
        filesByAppInstanceId {
          nodes {
            __typename
            id
            orgId
            name
            mimetype
            source
            metadata
            fileProcessingStatus
            availableAt
            expireAt
          }
        }
        appInstallByAppInstallId {
          __typename
          appId
          id
          orgId
        }
        appVersionByAppInstanceId {
          __typename
          id
          version
          manifestJson
          viewerUrl
          appByAppId {
            __typename
            id
            name
            iconUrl
          }
        }
      }
    }
    linksByPlaylistId {
      nodes {
        __typename
        id
        name
        url
        linkType
        cloudConfig
      }
    }
    sitesByPlaylistId {
      nodes {
        __typename
        id
        name
        type
        url
        config
        compute
        actions
      }
    }
  }
  ${FileOutputFragmentDoc}
`;
export const ChannelDetailForScreenFragmentDoc = gql`
  fragment ChannelDetailForScreen on Channel {
    ...Channel
    draft {
      ...ChannelDetail
    }
    published {
      ...ChannelDetail
    }
    layoutByChannel {
      ...Layout
    }
    playlistsByChannelId {
      nodes {
        ...PlaylistDetail
      }
    }
    filesByChannelId {
      nodes {
        __typename
        id
        orgId
        name
        mimetype
        source
        metadata
        fileProcessingStatus
        availableAt
        expireAt
        fileOutputsByFileId(first: 10) {
          nodes {
            ...FileOutput
          }
        }
      }
    }
    linksByChannelId {
      nodes {
        __typename
        id
        name
        url
        linkType
        cloudConfig
        fileByFileId {
          __typename
          id
          name
          mimetype
        }
      }
    }
    sitesByChannelId {
      nodes {
        __typename
        id
        orgId
        name
        type
        url
        config
        actions
        fileByThumbnailId {
          __typename
          id
          orgId
          metadata
          source
          mimetype
        }
      }
    }
    appInstancesByChannelId {
      nodes {
        __typename
        id
        config
        state
        version
        name
        filesByAppInstanceId {
          nodes {
            __typename
            id
            orgId
            name
            mimetype
            source
            metadata
            fileProcessingStatus
            availableAt
            expireAt
          }
        }
        appInstallByAppInstallId {
          __typename
          appId
          id
          orgId
        }
        appVersionByAppInstanceId {
          __typename
          id
          version
          manifestJson
          viewerUrl
          appByAppId {
            __typename
            id
            name
            iconUrl
          }
        }
      }
    }
    childOf
    parentChannelByChildOf {
      ...ParentChannel
    }
  }
  ${ChannelFragmentDoc}
  ${ChannelDetailFragmentDoc}
  ${LayoutFragmentDoc}
  ${PlaylistDetailFragmentDoc}
  ${FileOutputFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export const CastByCastIdForScreenDetailFragmentDoc = gql`
  fragment CastByCastIdForScreenDetail on Cast {
    __typename
    id
    content
    channelByCastId {
      ...ChannelDetailForScreen
    }
    fileByCastId {
      __typename
      id
      folderId
      name
      mimetype
      metadata
      source
      fileProcessingStatus
      fileOutputsByFileId(first: 10) {
        nodes {
          ...FileOutput
        }
      }
    }
    playlistByCastId {
      ...PlaylistDetail
    }
    linkByCastId {
      __typename
      id
      name
      url
      linkType
      cloudConfig
      fileByFileId {
        __typename
        id
        name
        mimetype
      }
    }
    siteByCastId {
      __typename
      id
      name
      type
      url
      config
      compute
      actions
      fileByThumbnailId {
        __typename
        id
        name
        mimetype
        metadata
        source
        fileProcessingStatus
      }
    }
    appInstanceByCastId {
      __typename
      id
      name
      config
      appVersionByAppInstanceId {
        __typename
        id
        manifestJson
        appByAppId {
          __typename
          id
          name
          iconUrl
        }
      }
      appInstallByAppInstallId {
        __typename
        id
      }
    }
  }
  ${ChannelDetailForScreenFragmentDoc}
  ${FileOutputFragmentDoc}
  ${PlaylistDetailFragmentDoc}
`;
export const CastByCastIdFragmentDoc = gql`
  fragment CastByCastId on Cast {
    __typename
    id
    content
    channelByCastId {
      __typename
      id
      name
    }
    fileByCastId {
      __typename
      id
      folderId
      name
      mimetype
      metadata
      source
      fileProcessingStatus
      fileOutputsByFileId(first: 10) {
        nodes {
          ...FileOutput
        }
      }
    }
    playlistByCastId {
      __typename
      id
      name
    }
    linkByCastId {
      __typename
      id
      name
      url
      linkType
      cloudConfig
      fileByFileId {
        __typename
        id
        name
        mimetype
      }
    }
    siteByCastId {
      __typename
      id
      name
      type
      url
      config
      compute
      actions
      fileByThumbnailId {
        __typename
        id
        name
        mimetype
        metadata
        source
        fileProcessingStatus
      }
    }
    appInstanceByCastId {
      __typename
      id
      name
      config
      appVersionByAppInstanceId {
        __typename
        id
        manifestJson
        appByAppId {
          __typename
          id
          name
          iconUrl
        }
      }
      appInstallByAppInstallId {
        __typename
        id
      }
    }
  }
  ${FileOutputFragmentDoc}
`;
export const ScreenNoteFragmentDoc = gql`
  fragment ScreenNote on ScreenNote {
    __typename
    id
    note
    updatedAt
    userByUpdatedBy {
      givenName
      familyName
    }
  }
`;
export const ScreensByScreenGroupIdAndOrgIdFragmentDoc = gql`
  fragment screensByScreenGroupIdAndOrgId on Screen {
    __typename
    id
    orgId
    spaceId
    screenGroupId
    castId
    name
    content
    status
    rotation
    isConnected
    connectedAt
    disconnectedAt
    device
    deviceInfo
    deviceProvider
    deviceId
    deviceModel
    devicePlatform
    deviceIpAddress
    deviceHostname
    playerTimezone
    playerHeight
    playerWidth
    timezoneOverride
    additionalPlayerSettings
    deviceProvider
    preview
    tags
    env
    preferences
    operatingHours
    playbackMode
    createdAt
    createdBy
    updatedAt
    updatedBy
    isNotificationActive
    messages
    screenGroupByScreenGroupId {
      __typename
      id
      name
      isPrimary
    }
    castByCastId {
      ...CastByCastId
    }
    linksByScreenId(first: 1) {
      nodes {
        __typename
        id
        name
      }
    }
    sitesByScreenId(first: 1) {
      nodes {
        __typename
        id
        name
      }
    }
    channelsByScreenId(first: 1) {
      nodes {
        __typename
        id
        name
        spaceId
        content
        isSharedExplicit
        isSharedAll
        draft {
          __typename
          id
          isPublished
          content
        }
        published {
          __typename
          id
          isPublished
          content
        }
        childOf
        parentChannelByChildOf {
          ...ParentChannel
        }
      }
    }
    filesByScreenId(first: 1) {
      nodes {
        __typename
        id
        name
        mimetype
        metadata
        source
        fileProcessingStatus
      }
    }
    playlistsByScreenId(first: 1) {
      nodes {
        __typename
        id
        name
        content
        spaceId
        draft {
          __typename
          id
          isPublished
        }
      }
    }
    appInstancesByScreenId(first: 1) {
      nodes {
        __typename
        id
        name
      }
    }
    screenNotesByScreenId {
      nodes {
        ...ScreenNote
      }
    }
  }
  ${CastByCastIdFragmentDoc}
  ${ParentChannelFragmentDoc}
  ${ScreenNoteFragmentDoc}
`;
export const FileSidebarFragmentDoc = gql`
  fragment FileSidebar on File {
    __typename
    id
    fileProcessingStatus
    folderId
    mimetype
    name
    spaceId
    source
    metadata
    expireAt
    availableAt
    fileOutputsByFileId {
      nodes {
        ...FileOutput
      }
    }
  }
  ${FileOutputFragmentDoc}
`;
export const FolderSidebarFragmentDoc = gql`
  fragment FolderSidebar on Folder {
    __typename
    id
    parentId
    name
    spaceId
    foldersByParentId {
      totalCount
    }
    filesByFolderId {
      nodes {
        __typename
        id
        fileProcessingStatus
        folderId
        mimetype
        name
        spaceId
        source
        metadata
        expireAt
        availableAt
      }
      totalCount
    }
  }
`;
export const LinkSidebarFragmentDoc = gql`
  fragment LinkSidebar on Link {
    __typename
    id
    name
    spaceId
    fileByFileId {
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export const SiteFragmentDoc = gql`
  fragment Site on Site {
    __typename
    id
    orgId
    spaceId
    name
    type
    url
    config
    compute
    actions
    fileByThumbnailId {
      ...File
      fileOutputsByFileId {
        nodes {
          ...FileOutput
        }
        totalCount
      }
    }
    isFavorite
    tags
    createdAt
    createdBy
    updatedAt
    updatedBy
    castedScreenBySiteId {
      nodes {
        __typename
        id
        name
        spaceId
        device
        deviceModel
      }
      totalCount
    }
    userByCreatedBy {
      __typename
      id
      givenName
      familyName
    }
    isSharedExplicit
    isSharedAll
    sharedSpacesBySiteId {
      nodes {
        __typename
        id
      }
    }
  }
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
`;
export const SiteSidebarFragmentDoc = gql`
  fragment SiteSidebar on Site {
    ...Site
  }
  ${SiteFragmentDoc}
`;
export const AvailableAppInstanceFragmentDoc = gql`
  fragment AvailableAppInstance on AppInstance {
    __typename
    id
    appId
    appInstallId
    spaceId
    state
    version
    name
    config
    isTemplate
    isSharedExplicit
    isSharedAll
    createdAt
    updatedAt
    tags
    targetCommonAppInstanceTemplateId
    availableAt
    expireAt
    fileByThumbnailFileId {
      ...FileListItem
    }
    sharedSpacesByAppInstanceId {
      nodes {
        __typename
        id
      }
    }
    castedScreenByAppInstanceId {
      nodes {
        __typename
        id
        name
        deviceId
        deviceModel
        spaceId
      }
    }
    appByAppId {
      __typename
      id
      name
      iconUrl
    }
  }
  ${FileListItemFragmentDoc}
`;
export const AppInstanceSidebarFragmentDoc = gql`
  fragment AppInstanceSidebar on AppInstance {
    ...AvailableAppInstance
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export const SiteMutationFieldFragmentDoc = gql`
  fragment SiteMutationField on Site {
    __typename
    id
    orgId
    spaceId
    url
    fileByThumbnailId {
      __typename
      id
      orgId
      metadata
      source
      mimetype
      fileProcessingStatus
    }
    type
    config
    compute
    actions
    name
  }
`;
export const ThemeListItemFragmentDoc = gql`
  fragment ThemeListItem on Theme {
    __typename
    id
    orgId
    name
    logoFileId
    config
    titleFontId
    bodyFontId
    fileByLogoFileId {
      ...File
    }
  }
  ${FileFragmentDoc}
`;
export const OrgFragmentDoc = gql`
  fragment Org on Org {
    __typename
    id
    name
    brandForegroundColor
    brandBackgroundColor
    startChannelId
    blankChannelId
    brandChannelId
    splashChannelThemeId
    preferences
    defaultChannelThemeId
    brandLogoId
    brandBackgroundId
    billingExpiresAt
    isDraft
    preferences
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
export const SpaceFragmentDoc = gql`
  fragment Space on Space {
    __typename
    env
    id
    orgId
    createdAt
    createdBy
    updatedAt
    updatedBy
    name
    preferences
    billingCustomerId
    billingExpiresAt
    rootFolderId
  }
`;
export const FolderFragmentDoc = gql`
  fragment Folder on Folder {
    __typename
    parentId
    name
    isFavorite
    tags
    spaceId
    id
    orgId
    createdAt
    createdBy
    updatedAt
    updatedBy
    isShared
    isSharedAll
  }
`;
export const LinkFragmentDoc = gql`
  fragment Link on Link {
    __typename
    id
    orgId
    url
    fileByFileId {
      ...File
    }
    createdAt
    linkType
    cloudConfig
    isFavorite
    name
    tags
    cacheBusting
    qrcodeEnabled
    qrcodeConfig
    autoReload
    autoReloadDurationMs
    params
    spaceId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  ${FileFragmentDoc}
`;
export const ScreenFragmentDoc = gql`
  fragment Screen on Screen {
    __typename
    id
    orgId
    screenGroupId
    castId
    name
    content
    status
    preview
    device
    deviceInfo
    deviceIpAddress
    deviceHostname
    rotation
    env
    deviceId
    deviceModel
    devicePlatform
    playerTimezone
    playerHeight
    playerWidth
    timezoneOverride
    additionalPlayerSettings
    deviceProvider
    preferences
    operatingHours
    tags
    spaceId
    playbackMode
    videoSource
    highResolutionImage
    createdAt
    createdBy
    updatedAt
    updatedBy
    isConnected
    connectedAt
    disconnectedAt
    isNotificationActive
    screenNotesByScreenId {
      nodes {
        ...ScreenNote
      }
    }
    messages
  }
  ${ScreenNoteFragmentDoc}
`;
export const AppInstallFragmentDoc = gql`
  fragment AppInstall on AppInstall {
    __typename
    appId
    id
    orgId
    createdAt
    createdBy
    updatedAt
    updatedBy
    spaceId
  }
`;
export const AppInstanceFragmentDoc = gql`
  fragment AppInstance on AppInstance {
    __typename
    id
    orgId
    appInstallId
    appId
    config
    state
    version
    name
    tags
    isTemplate
    isSharedAll
    isShared
    isSharedExplicit
    spaceId
    createdAt
    createdBy
    updatedAt
    updatedBy
    availableAt
    expireAt
    filesByAppInstanceId {
      nodes {
        ...File
      }
    }
  }
  ${FileFragmentDoc}
`;
export const AppVersionFragmentDoc = gql`
  fragment AppVersion on AppVersion {
    __typename
    id
    appId
    version
    betaFlag
    stableFlag
    manifestJson
    viewerUrl
    editorUrl
    mediaSandboxAccess
    configurationType
    storeData
    createdAt
    updatedAt
  }
`;
export const PlaylistFragmentDoc = gql`
  fragment Playlist on Playlist {
    __typename
    id
    orgId
    spaceId
    tags
    name
    color
    publishedAt
    publishedBy
    content
    isFavorite
    isPublished
    isSharedExplicit
    isSharedAll
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
export const ScreenGroupFragmentDoc = gql`
  fragment ScreenGroup on ScreenGroup {
    __typename
    id
    orgId
    spaceId
    name
    isPrimary
    env
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;
export const CastFragmentDoc = gql`
  fragment Cast on Cast {
    __typename
    id
    orgId
    spaceId
    priority
    content
    createdAt
    createdBy
    updatedAt
    updatedBy
    expiresAt
  }
`;
export const FavoriteFragmentDoc = gql`
  fragment Favorite on Favorite {
    __typename
    orgId
    spaceId
    fileId
    folderId
    playlistId
    userId
    linkId
    siteId
  }
`;
export const GroupSpaceFragmentDoc = gql`
  fragment GroupSpace on GroupSpace {
    __typename
    orgId
    groupId
    spaceId
    permissions
    permissionSets
  }
`;
export const GroupFragmentDoc = gql`
  fragment Group on Group {
    __typename
    id
    orgId
    name
    description
    createdAt
    updatedAt
    createdBy
    updatedBy
    permissions
    permissionSets
  }
`;
export const UserGroupFragmentDoc = gql`
  fragment UserGroup on UserGroup {
    __typename
    orgId
    userId
    groupId
    createdAt
    createdBy
    permissions
    permissionSets
  }
`;
export const FeatureFlagOrgFragmentDoc = gql`
  fragment FeatureFlagOrg on FeatureFlagOrg {
    __typename
    key
    orgId
    value
    customizable
    customValue
  }
`;
export const FeatureFlagFragmentDoc = gql`
  fragment FeatureFlag on FeatureFlag {
    __typename
    key
    value
    overwriteValue
    description
    defaultCustomizable
    tags
    description
    visibility
    createdAt
    updatedAt
  }
`;
export const ThemeFragmentDoc = gql`
  fragment Theme on Theme {
    __typename
    id
    orgId
    name
    logoFileId
    config
    titleFontId
    bodyFontId
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
`;
export const TokenFragmentDoc = gql`
  fragment Token on Token {
    __typename
    id
    orgId
    name
    permissions
    userId
    lastUsed
    deactivatedAt
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
  }
`;
export const AppFragmentDoc = gql`
  fragment App on App {
    __typename
    id
    name
    scrn
    categories
    requirements
    description
    defaultInstall
    isInstalled
    iconUrl
    developer
    tags
    createdAt
    updatedAt
  }
`;
export const LogFragmentDoc = gql`
  fragment Log on Log {
    __typename
    id
    orgId
    objectId
    objectType
    objectName
    action
    previousFields
    changedFields
    clientAddr
    clientPort
    applicationName
    timestamp
    sessionUserName
    userId
    systemUserId
    spaceId
  }
`;
export const LegacyLogFragmentDoc = gql`
  fragment LegacyLog on LegacyLog {
    __typename
    id
    orgId
    action
    objectId
    objectType
    extra
    actionedBy
    actionedAt
    clientAddr
    clientPort
    msg
  }
`;
export const CreateCastPayloadFragmentDoc = gql`
  fragment CreateCastPayload on Cast {
    ...CastByCastId
    screensByCastId {
      nodes {
        __typename
        id
        castId
        name
        content
        device
        spaceId
      }
    }
  }
  ${CastByCastIdFragmentDoc}
`;
export const BillingCustomerFragmentDoc = gql`
  fragment BillingCustomer on BillingCustomer {
    __typename
    id
    parentId
    paymentOwnerId
    invoiceOwnerId
    company
    firstName
    lastName
    email
    phone
    vatNumber
    vatNumberStatus
    autoCollection
    taxability
    billingDate
    billingDateMode
    billingDayOfWeek
    billingDayOfWeekMode
    preferredCurrencyCode
    cfAllowLicence
    cfPartner
    cfInvoicedPayment
    cfCustomerType
    cfAccountManager
    createdAt
    updatedAt
  }
`;
export const SuperUserFragmentDoc = gql`
  fragment SuperUser on User {
    __typename
    id
    givenName
    familyName
    email
    status
    isOwner
    metadata
  }
`;
export const RecycleBinFragmentDoc = gql`
  fragment RecycleBin on RecycleBin {
    __typename
    orgId
    spaceId
    objectId
    objectType
    objectSubType
    name
    thumbnail
    deletedAt
  }
`;
export const ContextProviderDataDocument = gql`
  query contextProviderData {
    currentOrg {
      ...Org
      featureFlagOrgsByOrgId {
        nodes {
          ...FeatureFlagOrg
        }
      }
      screensByOrgId {
        totalCount
      }
      spacesByOrgId {
        totalCount
      }
      orgConnectionByOrgId {
        __typename
        id
        orgId
        automaticEnroll
        connection
        provider
      }
    }
    allApps(filter: { name: { equalTo: "Canvas" } }) {
      nodes {
        ...App
        appInstallsByAppId {
          nodes {
            ...AppInstall
          }
        }
        appVersionByAppId {
          ...AppVersion
          appByAppId {
            __typename
            id
            name
            iconUrl
          }
        }
      }
      totalCount
    }
    allSpaces(condition: { isEnabled: true }) {
      nodes {
        __typename
        id
        name
        preferences
        billingCustomerId
        rootFolderId
      }
    }
    currentOrgStartableChannels {
      nodes {
        ...Channel
      }
    }
    migrationCheck
  }
  ${OrgFragmentDoc}
  ${FeatureFlagOrgFragmentDoc}
  ${AppFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
  ${ChannelFragmentDoc}
`;
export type ContextProviderDataProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ContextProviderDataQuery,
    ContextProviderDataQueryVariables
  >;
} &
  TChildProps;
export function withContextProviderData<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ContextProviderDataQuery,
    ContextProviderDataQueryVariables,
    ContextProviderDataProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ContextProviderDataQuery,
    ContextProviderDataQueryVariables,
    ContextProviderDataProps<TChildProps, TDataName>
  >(ContextProviderDataDocument, {
    alias: "contextProviderData",
    ...operationOptions,
  });
}

/**
 * __useContextProviderDataQuery__
 *
 * To run a query within a React component, call `useContextProviderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useContextProviderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContextProviderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useContextProviderDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContextProviderDataQuery,
    ContextProviderDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContextProviderDataQuery,
    ContextProviderDataQueryVariables
  >(ContextProviderDataDocument, options);
}
export function useContextProviderDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContextProviderDataQuery,
    ContextProviderDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContextProviderDataQuery,
    ContextProviderDataQueryVariables
  >(ContextProviderDataDocument, options);
}
export type ContextProviderDataQueryHookResult = ReturnType<
  typeof useContextProviderDataQuery
>;
export type ContextProviderDataLazyQueryHookResult = ReturnType<
  typeof useContextProviderDataLazyQuery
>;
export type ContextProviderDataQueryResult = Apollo.QueryResult<
  ContextProviderDataQuery,
  ContextProviderDataQueryVariables
>;
export function refetchContextProviderDataQuery(
  variables?: ContextProviderDataQueryVariables
) {
  return { query: ContextProviderDataDocument, variables: variables };
}
export const ActivateSubscriptionDocument = gql`
  mutation activateSubscription($input: ActivateSubscriptionInput!) {
    activateSubscription(input: $input) {
      success
    }
  }
`;
export type ActivateSubscriptionMutationFn = Apollo.MutationFunction<
  ActivateSubscriptionMutation,
  ActivateSubscriptionMutationVariables
>;
export type ActivateSubscriptionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ActivateSubscriptionMutation,
    ActivateSubscriptionMutationVariables
  >;
} &
  TChildProps;
export function withActivateSubscription<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ActivateSubscriptionMutation,
    ActivateSubscriptionMutationVariables,
    ActivateSubscriptionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ActivateSubscriptionMutation,
    ActivateSubscriptionMutationVariables,
    ActivateSubscriptionProps<TChildProps, TDataName>
  >(ActivateSubscriptionDocument, {
    alias: "activateSubscription",
    ...operationOptions,
  });
}

/**
 * __useActivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSubscriptionMutation, { data, loading, error }] = useActivateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateSubscriptionMutation,
    ActivateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateSubscriptionMutation,
    ActivateSubscriptionMutationVariables
  >(ActivateSubscriptionDocument, options);
}
export type ActivateSubscriptionMutationHookResult = ReturnType<
  typeof useActivateSubscriptionMutation
>;
export type ActivateSubscriptionMutationResult = Apollo.MutationResult<ActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ActivateSubscriptionMutation,
  ActivateSubscriptionMutationVariables
>;
export const CardIntentConfirmDocument = gql`
  mutation cardIntentConfirm($input: CardIntentConfirmInput!) {
    cardIntentConfirm(input: $input) {
      id
      clientSecret
      status
    }
  }
`;
export type CardIntentConfirmMutationFn = Apollo.MutationFunction<
  CardIntentConfirmMutation,
  CardIntentConfirmMutationVariables
>;
export type CardIntentConfirmProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CardIntentConfirmMutation,
    CardIntentConfirmMutationVariables
  >;
} &
  TChildProps;
export function withCardIntentConfirm<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CardIntentConfirmMutation,
    CardIntentConfirmMutationVariables,
    CardIntentConfirmProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CardIntentConfirmMutation,
    CardIntentConfirmMutationVariables,
    CardIntentConfirmProps<TChildProps, TDataName>
  >(CardIntentConfirmDocument, {
    alias: "cardIntentConfirm",
    ...operationOptions,
  });
}

/**
 * __useCardIntentConfirmMutation__
 *
 * To run a mutation, you first call `useCardIntentConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCardIntentConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cardIntentConfirmMutation, { data, loading, error }] = useCardIntentConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCardIntentConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CardIntentConfirmMutation,
    CardIntentConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CardIntentConfirmMutation,
    CardIntentConfirmMutationVariables
  >(CardIntentConfirmDocument, options);
}
export type CardIntentConfirmMutationHookResult = ReturnType<
  typeof useCardIntentConfirmMutation
>;
export type CardIntentConfirmMutationResult = Apollo.MutationResult<CardIntentConfirmMutation>;
export type CardIntentConfirmMutationOptions = Apollo.BaseMutationOptions<
  CardIntentConfirmMutation,
  CardIntentConfirmMutationVariables
>;
export const CardIntentPaymentDocument = gql`
  mutation cardIntentPayment($input: CardIntentPaymentInput!) {
    cardIntentPayment(input: $input) {
      id
      clientSecret
      nextActionType
      requiresAction
      status
    }
  }
`;
export type CardIntentPaymentMutationFn = Apollo.MutationFunction<
  CardIntentPaymentMutation,
  CardIntentPaymentMutationVariables
>;
export type CardIntentPaymentProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CardIntentPaymentMutation,
    CardIntentPaymentMutationVariables
  >;
} &
  TChildProps;
export function withCardIntentPayment<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CardIntentPaymentMutation,
    CardIntentPaymentMutationVariables,
    CardIntentPaymentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CardIntentPaymentMutation,
    CardIntentPaymentMutationVariables,
    CardIntentPaymentProps<TChildProps, TDataName>
  >(CardIntentPaymentDocument, {
    alias: "cardIntentPayment",
    ...operationOptions,
  });
}

/**
 * __useCardIntentPaymentMutation__
 *
 * To run a mutation, you first call `useCardIntentPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCardIntentPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cardIntentPaymentMutation, { data, loading, error }] = useCardIntentPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCardIntentPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CardIntentPaymentMutation,
    CardIntentPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CardIntentPaymentMutation,
    CardIntentPaymentMutationVariables
  >(CardIntentPaymentDocument, options);
}
export type CardIntentPaymentMutationHookResult = ReturnType<
  typeof useCardIntentPaymentMutation
>;
export type CardIntentPaymentMutationResult = Apollo.MutationResult<CardIntentPaymentMutation>;
export type CardIntentPaymentMutationOptions = Apollo.BaseMutationOptions<
  CardIntentPaymentMutation,
  CardIntentPaymentMutationVariables
>;
export const CardIntentSetupDocument = gql`
  mutation cardIntentSetup($input: CardIntentSetupInput!) {
    cardIntentSetup(input: $input) {
      id
      clientSecret
      nextActionType
      requiresAction
      status
    }
  }
`;
export type CardIntentSetupMutationFn = Apollo.MutationFunction<
  CardIntentSetupMutation,
  CardIntentSetupMutationVariables
>;
export type CardIntentSetupProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CardIntentSetupMutation,
    CardIntentSetupMutationVariables
  >;
} &
  TChildProps;
export function withCardIntentSetup<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CardIntentSetupMutation,
    CardIntentSetupMutationVariables,
    CardIntentSetupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CardIntentSetupMutation,
    CardIntentSetupMutationVariables,
    CardIntentSetupProps<TChildProps, TDataName>
  >(CardIntentSetupDocument, {
    alias: "cardIntentSetup",
    ...operationOptions,
  });
}

/**
 * __useCardIntentSetupMutation__
 *
 * To run a mutation, you first call `useCardIntentSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCardIntentSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cardIntentSetupMutation, { data, loading, error }] = useCardIntentSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCardIntentSetupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CardIntentSetupMutation,
    CardIntentSetupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CardIntentSetupMutation,
    CardIntentSetupMutationVariables
  >(CardIntentSetupDocument, options);
}
export type CardIntentSetupMutationHookResult = ReturnType<
  typeof useCardIntentSetupMutation
>;
export type CardIntentSetupMutationResult = Apollo.MutationResult<CardIntentSetupMutation>;
export type CardIntentSetupMutationOptions = Apollo.BaseMutationOptions<
  CardIntentSetupMutation,
  CardIntentSetupMutationVariables
>;
export const CreateCustomerDocument = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      success
    }
  }
`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export type CreateCustomerProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >;
} &
  TChildProps;
export function withCreateCustomer<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCustomerMutation,
    CreateCustomerMutationVariables,
    CreateCustomerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCustomerMutation,
    CreateCustomerMutationVariables,
    CreateCustomerProps<TChildProps, TDataName>
  >(CreateCustomerDocument, {
    alias: "createCustomer",
    ...operationOptions,
  });
}

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CreateCustomerDocument, options);
}
export type CreateCustomerMutationHookResult = ReturnType<
  typeof useCreateCustomerMutation
>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const CreatePaymentSourceCardDocument = gql`
  mutation createPaymentSourceCard($input: CreatePaymentSourceCardInput!) {
    createPaymentSourceCard(input: $input) {
      success
    }
  }
`;
export type CreatePaymentSourceCardMutationFn = Apollo.MutationFunction<
  CreatePaymentSourceCardMutation,
  CreatePaymentSourceCardMutationVariables
>;
export type CreatePaymentSourceCardProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreatePaymentSourceCardMutation,
    CreatePaymentSourceCardMutationVariables
  >;
} &
  TChildProps;
export function withCreatePaymentSourceCard<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePaymentSourceCardMutation,
    CreatePaymentSourceCardMutationVariables,
    CreatePaymentSourceCardProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePaymentSourceCardMutation,
    CreatePaymentSourceCardMutationVariables,
    CreatePaymentSourceCardProps<TChildProps, TDataName>
  >(CreatePaymentSourceCardDocument, {
    alias: "createPaymentSourceCard",
    ...operationOptions,
  });
}

/**
 * __useCreatePaymentSourceCardMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSourceCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSourceCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSourceCardMutation, { data, loading, error }] = useCreatePaymentSourceCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentSourceCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentSourceCardMutation,
    CreatePaymentSourceCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentSourceCardMutation,
    CreatePaymentSourceCardMutationVariables
  >(CreatePaymentSourceCardDocument, options);
}
export type CreatePaymentSourceCardMutationHookResult = ReturnType<
  typeof useCreatePaymentSourceCardMutation
>;
export type CreatePaymentSourceCardMutationResult = Apollo.MutationResult<CreatePaymentSourceCardMutation>;
export type CreatePaymentSourceCardMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentSourceCardMutation,
  CreatePaymentSourceCardMutationVariables
>;
export const CreatePaymentSourceDirectdebitDocument = gql`
  mutation createPaymentSourceDirectdebit(
    $input: CreatePaymentSourceDirectdebitInput!
  ) {
    createPaymentSourceDirectdebit(input: $input) {
      success
    }
  }
`;
export type CreatePaymentSourceDirectdebitMutationFn = Apollo.MutationFunction<
  CreatePaymentSourceDirectdebitMutation,
  CreatePaymentSourceDirectdebitMutationVariables
>;
export type CreatePaymentSourceDirectdebitProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreatePaymentSourceDirectdebitMutation,
    CreatePaymentSourceDirectdebitMutationVariables
  >;
} &
  TChildProps;
export function withCreatePaymentSourceDirectdebit<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePaymentSourceDirectdebitMutation,
    CreatePaymentSourceDirectdebitMutationVariables,
    CreatePaymentSourceDirectdebitProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePaymentSourceDirectdebitMutation,
    CreatePaymentSourceDirectdebitMutationVariables,
    CreatePaymentSourceDirectdebitProps<TChildProps, TDataName>
  >(CreatePaymentSourceDirectdebitDocument, {
    alias: "createPaymentSourceDirectdebit",
    ...operationOptions,
  });
}

/**
 * __useCreatePaymentSourceDirectdebitMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSourceDirectdebitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSourceDirectdebitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSourceDirectdebitMutation, { data, loading, error }] = useCreatePaymentSourceDirectdebitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentSourceDirectdebitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentSourceDirectdebitMutation,
    CreatePaymentSourceDirectdebitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentSourceDirectdebitMutation,
    CreatePaymentSourceDirectdebitMutationVariables
  >(CreatePaymentSourceDirectdebitDocument, options);
}
export type CreatePaymentSourceDirectdebitMutationHookResult = ReturnType<
  typeof useCreatePaymentSourceDirectdebitMutation
>;
export type CreatePaymentSourceDirectdebitMutationResult = Apollo.MutationResult<CreatePaymentSourceDirectdebitMutation>;
export type CreatePaymentSourceDirectdebitMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentSourceDirectdebitMutation,
  CreatePaymentSourceDirectdebitMutationVariables
>;
export const CreatePaymentSourcePaypalDocument = gql`
  mutation createPaymentSourcePaypal($input: CreatePaymentSourcePaypalInput!) {
    createPaymentSourcePaypal(input: $input) {
      success
    }
  }
`;
export type CreatePaymentSourcePaypalMutationFn = Apollo.MutationFunction<
  CreatePaymentSourcePaypalMutation,
  CreatePaymentSourcePaypalMutationVariables
>;
export type CreatePaymentSourcePaypalProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreatePaymentSourcePaypalMutation,
    CreatePaymentSourcePaypalMutationVariables
  >;
} &
  TChildProps;
export function withCreatePaymentSourcePaypal<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePaymentSourcePaypalMutation,
    CreatePaymentSourcePaypalMutationVariables,
    CreatePaymentSourcePaypalProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePaymentSourcePaypalMutation,
    CreatePaymentSourcePaypalMutationVariables,
    CreatePaymentSourcePaypalProps<TChildProps, TDataName>
  >(CreatePaymentSourcePaypalDocument, {
    alias: "createPaymentSourcePaypal",
    ...operationOptions,
  });
}

/**
 * __useCreatePaymentSourcePaypalMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSourcePaypalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSourcePaypalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSourcePaypalMutation, { data, loading, error }] = useCreatePaymentSourcePaypalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentSourcePaypalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentSourcePaypalMutation,
    CreatePaymentSourcePaypalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentSourcePaypalMutation,
    CreatePaymentSourcePaypalMutationVariables
  >(CreatePaymentSourcePaypalDocument, options);
}
export type CreatePaymentSourcePaypalMutationHookResult = ReturnType<
  typeof useCreatePaymentSourcePaypalMutation
>;
export type CreatePaymentSourcePaypalMutationResult = Apollo.MutationResult<CreatePaymentSourcePaypalMutation>;
export type CreatePaymentSourcePaypalMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentSourcePaypalMutation,
  CreatePaymentSourcePaypalMutationVariables
>;
export const DirectdebitAuthorizeDocument = gql`
  mutation directdebitAuthorize($input: DirectdebitAuthorizeInput!) {
    directdebitAuthorize(input: $input) {
      id
      redirectUrl
      successRedirectUrl
    }
  }
`;
export type DirectdebitAuthorizeMutationFn = Apollo.MutationFunction<
  DirectdebitAuthorizeMutation,
  DirectdebitAuthorizeMutationVariables
>;
export type DirectdebitAuthorizeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DirectdebitAuthorizeMutation,
    DirectdebitAuthorizeMutationVariables
  >;
} &
  TChildProps;
export function withDirectdebitAuthorize<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DirectdebitAuthorizeMutation,
    DirectdebitAuthorizeMutationVariables,
    DirectdebitAuthorizeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DirectdebitAuthorizeMutation,
    DirectdebitAuthorizeMutationVariables,
    DirectdebitAuthorizeProps<TChildProps, TDataName>
  >(DirectdebitAuthorizeDocument, {
    alias: "directdebitAuthorize",
    ...operationOptions,
  });
}

/**
 * __useDirectdebitAuthorizeMutation__
 *
 * To run a mutation, you first call `useDirectdebitAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDirectdebitAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [directdebitAuthorizeMutation, { data, loading, error }] = useDirectdebitAuthorizeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDirectdebitAuthorizeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DirectdebitAuthorizeMutation,
    DirectdebitAuthorizeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DirectdebitAuthorizeMutation,
    DirectdebitAuthorizeMutationVariables
  >(DirectdebitAuthorizeDocument, options);
}
export type DirectdebitAuthorizeMutationHookResult = ReturnType<
  typeof useDirectdebitAuthorizeMutation
>;
export type DirectdebitAuthorizeMutationResult = Apollo.MutationResult<DirectdebitAuthorizeMutation>;
export type DirectdebitAuthorizeMutationOptions = Apollo.BaseMutationOptions<
  DirectdebitAuthorizeMutation,
  DirectdebitAuthorizeMutationVariables
>;
export const GetAddressDocument = gql`
  query getAddress($spaceId: UUID) {
    getBillingAddress(spaceId: $spaceId) {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`;
export type GetAddressProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAddressQuery,
    GetAddressQueryVariables
  >;
} &
  TChildProps;
export function withGetAddress<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAddressQuery,
    GetAddressQueryVariables,
    GetAddressProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAddressQuery,
    GetAddressQueryVariables,
    GetAddressProps<TChildProps, TDataName>
  >(GetAddressDocument, {
    alias: "getAddress",
    ...operationOptions,
  });
}

/**
 * __useGetAddressQuery__
 *
 * To run a query within a React component, call `useGetAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAddressQuery,
    GetAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddressQuery, GetAddressQueryVariables>(
    GetAddressDocument,
    options
  );
}
export function useGetAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddressQuery,
    GetAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddressQuery, GetAddressQueryVariables>(
    GetAddressDocument,
    options
  );
}
export type GetAddressQueryHookResult = ReturnType<typeof useGetAddressQuery>;
export type GetAddressLazyQueryHookResult = ReturnType<
  typeof useGetAddressLazyQuery
>;
export type GetAddressQueryResult = Apollo.QueryResult<
  GetAddressQuery,
  GetAddressQueryVariables
>;
export function refetchGetAddressQuery(variables?: GetAddressQueryVariables) {
  return { query: GetAddressDocument, variables: variables };
}
export const GetBillingAddOnDocument = gql`
  query getBillingAddOn {
    allBillingAddons {
      nodes {
        __typename
        id
        name
        currencyCode
        price
      }
    }
  }
`;
export type GetBillingAddOnProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetBillingAddOnQuery,
    GetBillingAddOnQueryVariables
  >;
} &
  TChildProps;
export function withGetBillingAddOn<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBillingAddOnQuery,
    GetBillingAddOnQueryVariables,
    GetBillingAddOnProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBillingAddOnQuery,
    GetBillingAddOnQueryVariables,
    GetBillingAddOnProps<TChildProps, TDataName>
  >(GetBillingAddOnDocument, {
    alias: "getBillingAddOn",
    ...operationOptions,
  });
}

/**
 * __useGetBillingAddOnQuery__
 *
 * To run a query within a React component, call `useGetBillingAddOnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingAddOnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingAddOnQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingAddOnQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBillingAddOnQuery,
    GetBillingAddOnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBillingAddOnQuery, GetBillingAddOnQueryVariables>(
    GetBillingAddOnDocument,
    options
  );
}
export function useGetBillingAddOnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillingAddOnQuery,
    GetBillingAddOnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBillingAddOnQuery,
    GetBillingAddOnQueryVariables
  >(GetBillingAddOnDocument, options);
}
export type GetBillingAddOnQueryHookResult = ReturnType<
  typeof useGetBillingAddOnQuery
>;
export type GetBillingAddOnLazyQueryHookResult = ReturnType<
  typeof useGetBillingAddOnLazyQuery
>;
export type GetBillingAddOnQueryResult = Apollo.QueryResult<
  GetBillingAddOnQuery,
  GetBillingAddOnQueryVariables
>;
export function refetchGetBillingAddOnQuery(
  variables?: GetBillingAddOnQueryVariables
) {
  return { query: GetBillingAddOnDocument, variables: variables };
}
export const GetBillingDetailsDocument = gql`
  query getBillingDetails($spaceId: UUID) {
    getScreenLicences(spaceId: $spaceId) {
      allowLicence
      remainingLicences
      usedLicences
      totalLicences
      futureLicences
      quotaReached
    }
    getBillingCustomer(spaceId: $spaceId) {
      cfPartner
    }
    getBillingPaymentSource(spaceId: $spaceId) {
      nodes {
        ...PaymentSource
      }
    }
    getBillingPlan(spaceId: $spaceId) {
      ...Plan
    }
    getBillingSubscription(spaceId: $spaceId) {
      ...Subscription
    }
    getBillingCoupons(spaceId: $spaceId) {
      nodes {
        ...Coupon
      }
    }
  }
  ${PaymentSourceFragmentDoc}
  ${PlanFragmentDoc}
  ${SubscriptionFragmentDoc}
  ${CouponFragmentDoc}
`;
export type GetBillingDetailsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >;
} &
  TChildProps;
export function withGetBillingDetails<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables,
    GetBillingDetailsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables,
    GetBillingDetailsProps<TChildProps, TDataName>
  >(GetBillingDetailsDocument, {
    alias: "getBillingDetails",
    ...operationOptions,
  });
}

/**
 * __useGetBillingDetailsQuery__
 *
 * To run a query within a React component, call `useGetBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingDetailsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetBillingDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >(GetBillingDetailsDocument, options);
}
export function useGetBillingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >(GetBillingDetailsDocument, options);
}
export type GetBillingDetailsQueryHookResult = ReturnType<
  typeof useGetBillingDetailsQuery
>;
export type GetBillingDetailsLazyQueryHookResult = ReturnType<
  typeof useGetBillingDetailsLazyQuery
>;
export type GetBillingDetailsQueryResult = Apollo.QueryResult<
  GetBillingDetailsQuery,
  GetBillingDetailsQueryVariables
>;
export function refetchGetBillingDetailsQuery(
  variables?: GetBillingDetailsQueryVariables
) {
  return { query: GetBillingDetailsDocument, variables: variables };
}
export const GetCouponsDocument = gql`
  query getCoupons($spaceId: UUID) {
    getBillingCoupons(spaceId: $spaceId) {
      nodes {
        ...Coupon
      }
    }
  }
  ${CouponFragmentDoc}
`;
export type GetCouponsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCouponsQuery,
    GetCouponsQueryVariables
  >;
} &
  TChildProps;
export function withGetCoupons<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCouponsQuery,
    GetCouponsQueryVariables,
    GetCouponsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCouponsQuery,
    GetCouponsQueryVariables,
    GetCouponsProps<TChildProps, TDataName>
  >(GetCouponsDocument, {
    alias: "getCoupons",
    ...operationOptions,
  });
}

/**
 * __useGetCouponsQuery__
 *
 * To run a query within a React component, call `useGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCouponsQuery,
    GetCouponsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponsQuery, GetCouponsQueryVariables>(
    GetCouponsDocument,
    options
  );
}
export function useGetCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCouponsQuery,
    GetCouponsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponsQuery, GetCouponsQueryVariables>(
    GetCouponsDocument,
    options
  );
}
export type GetCouponsQueryHookResult = ReturnType<typeof useGetCouponsQuery>;
export type GetCouponsLazyQueryHookResult = ReturnType<
  typeof useGetCouponsLazyQuery
>;
export type GetCouponsQueryResult = Apollo.QueryResult<
  GetCouponsQuery,
  GetCouponsQueryVariables
>;
export function refetchGetCouponsQuery(variables?: GetCouponsQueryVariables) {
  return { query: GetCouponsDocument, variables: variables };
}
export const GetCouponsFutureDocument = gql`
  query getCouponsFuture($spaceId: UUID) {
    getBillingCouponsFuture(spaceId: $spaceId) {
      nodes {
        ...Coupon
      }
    }
  }
  ${CouponFragmentDoc}
`;
export type GetCouponsFutureProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCouponsFutureQuery,
    GetCouponsFutureQueryVariables
  >;
} &
  TChildProps;
export function withGetCouponsFuture<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCouponsFutureQuery,
    GetCouponsFutureQueryVariables,
    GetCouponsFutureProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCouponsFutureQuery,
    GetCouponsFutureQueryVariables,
    GetCouponsFutureProps<TChildProps, TDataName>
  >(GetCouponsFutureDocument, {
    alias: "getCouponsFuture",
    ...operationOptions,
  });
}

/**
 * __useGetCouponsFutureQuery__
 *
 * To run a query within a React component, call `useGetCouponsFutureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsFutureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsFutureQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetCouponsFutureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCouponsFutureQuery,
    GetCouponsFutureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponsFutureQuery, GetCouponsFutureQueryVariables>(
    GetCouponsFutureDocument,
    options
  );
}
export function useGetCouponsFutureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCouponsFutureQuery,
    GetCouponsFutureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCouponsFutureQuery,
    GetCouponsFutureQueryVariables
  >(GetCouponsFutureDocument, options);
}
export type GetCouponsFutureQueryHookResult = ReturnType<
  typeof useGetCouponsFutureQuery
>;
export type GetCouponsFutureLazyQueryHookResult = ReturnType<
  typeof useGetCouponsFutureLazyQuery
>;
export type GetCouponsFutureQueryResult = Apollo.QueryResult<
  GetCouponsFutureQuery,
  GetCouponsFutureQueryVariables
>;
export function refetchGetCouponsFutureQuery(
  variables?: GetCouponsFutureQueryVariables
) {
  return { query: GetCouponsFutureDocument, variables: variables };
}
export const GetCustomerDocument = gql`
  query getCustomer($spaceId: UUID) {
    getBillingCustomer(spaceId: $spaceId) {
      ...BillingCustomer
      billingResellerByBillingResellerId {
        __typename
        id
        company
      }
    }
  }
  ${BillingCustomerFragmentDoc}
`;
export type GetCustomerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >;
} &
  TChildProps;
export function withGetCustomer<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCustomerQuery,
    GetCustomerQueryVariables,
    GetCustomerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCustomerQuery,
    GetCustomerQueryVariables,
    GetCustomerProps<TChildProps, TDataName>
  >(GetCustomerDocument, {
    alias: "getCustomer",
    ...operationOptions,
  });
}

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCustomerLazyQuery
>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export function refetchGetCustomerQuery(variables?: GetCustomerQueryVariables) {
  return { query: GetCustomerDocument, variables: variables };
}
export const GetLicencesDocument = gql`
  query getLicences($spaceId: UUID) {
    getScreenLicences(spaceId: $spaceId) {
      allowLicence
      remainingLicences
      usedLicences
      totalLicences
      futureLicences
      quotaReached
    }
  }
`;
export type GetLicencesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetLicencesQuery,
    GetLicencesQueryVariables
  >;
} &
  TChildProps;
export function withGetLicences<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetLicencesQuery,
    GetLicencesQueryVariables,
    GetLicencesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetLicencesQuery,
    GetLicencesQueryVariables,
    GetLicencesProps<TChildProps, TDataName>
  >(GetLicencesDocument, {
    alias: "getLicences",
    ...operationOptions,
  });
}

/**
 * __useGetLicencesQuery__
 *
 * To run a query within a React component, call `useGetLicencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicencesQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetLicencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLicencesQuery,
    GetLicencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLicencesQuery, GetLicencesQueryVariables>(
    GetLicencesDocument,
    options
  );
}
export function useGetLicencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLicencesQuery,
    GetLicencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLicencesQuery, GetLicencesQueryVariables>(
    GetLicencesDocument,
    options
  );
}
export type GetLicencesQueryHookResult = ReturnType<typeof useGetLicencesQuery>;
export type GetLicencesLazyQueryHookResult = ReturnType<
  typeof useGetLicencesLazyQuery
>;
export type GetLicencesQueryResult = Apollo.QueryResult<
  GetLicencesQuery,
  GetLicencesQueryVariables
>;
export function refetchGetLicencesQuery(variables?: GetLicencesQueryVariables) {
  return { query: GetLicencesDocument, variables: variables };
}
export const GetPaymentSourceDocument = gql`
  query getPaymentSource($spaceId: UUID) {
    getBillingPaymentSource(spaceId: $spaceId) {
      nodes {
        ...PaymentSource
      }
    }
  }
  ${PaymentSourceFragmentDoc}
`;
export type GetPaymentSourceProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetPaymentSourceQuery,
    GetPaymentSourceQueryVariables
  >;
} &
  TChildProps;
export function withGetPaymentSource<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetPaymentSourceQuery,
    GetPaymentSourceQueryVariables,
    GetPaymentSourceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetPaymentSourceQuery,
    GetPaymentSourceQueryVariables,
    GetPaymentSourceProps<TChildProps, TDataName>
  >(GetPaymentSourceDocument, {
    alias: "getPaymentSource",
    ...operationOptions,
  });
}

/**
 * __useGetPaymentSourceQuery__
 *
 * To run a query within a React component, call `useGetPaymentSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentSourceQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetPaymentSourceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentSourceQuery,
    GetPaymentSourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaymentSourceQuery, GetPaymentSourceQueryVariables>(
    GetPaymentSourceDocument,
    options
  );
}
export function useGetPaymentSourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentSourceQuery,
    GetPaymentSourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentSourceQuery,
    GetPaymentSourceQueryVariables
  >(GetPaymentSourceDocument, options);
}
export type GetPaymentSourceQueryHookResult = ReturnType<
  typeof useGetPaymentSourceQuery
>;
export type GetPaymentSourceLazyQueryHookResult = ReturnType<
  typeof useGetPaymentSourceLazyQuery
>;
export type GetPaymentSourceQueryResult = Apollo.QueryResult<
  GetPaymentSourceQuery,
  GetPaymentSourceQueryVariables
>;
export function refetchGetPaymentSourceQuery(
  variables?: GetPaymentSourceQueryVariables
) {
  return { query: GetPaymentSourceDocument, variables: variables };
}
export const GetPlanDocument = gql`
  query getPlan($spaceId: UUID) {
    getBillingPlan(spaceId: $spaceId) {
      ...Plan
    }
  }
  ${PlanFragmentDoc}
`;
export type GetPlanProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetPlanQuery,
    GetPlanQueryVariables
  >;
} &
  TChildProps;
export function withGetPlan<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetPlanQuery,
    GetPlanQueryVariables,
    GetPlanProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetPlanQuery,
    GetPlanQueryVariables,
    GetPlanProps<TChildProps, TDataName>
  >(GetPlanDocument, {
    alias: "getPlan",
    ...operationOptions,
  });
}

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(
    GetPlanDocument,
    options
  );
}
export function useGetPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(
    GetPlanDocument,
    options
  );
}
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<
  GetPlanQuery,
  GetPlanQueryVariables
>;
export function refetchGetPlanQuery(variables?: GetPlanQueryVariables) {
  return { query: GetPlanDocument, variables: variables };
}
export const GetRenewalDocument = gql`
  query getRenewal($spaceId: UUID) {
    getBillingRenewal(spaceId: $spaceId) {
      ...Renewal
    }
  }
  ${RenewalFragmentDoc}
`;
export type GetRenewalProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetRenewalQuery,
    GetRenewalQueryVariables
  >;
} &
  TChildProps;
export function withGetRenewal<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetRenewalQuery,
    GetRenewalQueryVariables,
    GetRenewalProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetRenewalQuery,
    GetRenewalQueryVariables,
    GetRenewalProps<TChildProps, TDataName>
  >(GetRenewalDocument, {
    alias: "getRenewal",
    ...operationOptions,
  });
}

/**
 * __useGetRenewalQuery__
 *
 * To run a query within a React component, call `useGetRenewalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenewalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenewalQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetRenewalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRenewalQuery,
    GetRenewalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRenewalQuery, GetRenewalQueryVariables>(
    GetRenewalDocument,
    options
  );
}
export function useGetRenewalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRenewalQuery,
    GetRenewalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRenewalQuery, GetRenewalQueryVariables>(
    GetRenewalDocument,
    options
  );
}
export type GetRenewalQueryHookResult = ReturnType<typeof useGetRenewalQuery>;
export type GetRenewalLazyQueryHookResult = ReturnType<
  typeof useGetRenewalLazyQuery
>;
export type GetRenewalQueryResult = Apollo.QueryResult<
  GetRenewalQuery,
  GetRenewalQueryVariables
>;
export function refetchGetRenewalQuery(variables?: GetRenewalQueryVariables) {
  return { query: GetRenewalDocument, variables: variables };
}
export const GetRenewalFutureDocument = gql`
  query getRenewalFuture($spaceId: UUID) {
    getBillingRenewalFuture(spaceId: $spaceId) {
      ...RenewalFuture
    }
  }
  ${RenewalFutureFragmentDoc}
`;
export type GetRenewalFutureProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetRenewalFutureQuery,
    GetRenewalFutureQueryVariables
  >;
} &
  TChildProps;
export function withGetRenewalFuture<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetRenewalFutureQuery,
    GetRenewalFutureQueryVariables,
    GetRenewalFutureProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetRenewalFutureQuery,
    GetRenewalFutureQueryVariables,
    GetRenewalFutureProps<TChildProps, TDataName>
  >(GetRenewalFutureDocument, {
    alias: "getRenewalFuture",
    ...operationOptions,
  });
}

/**
 * __useGetRenewalFutureQuery__
 *
 * To run a query within a React component, call `useGetRenewalFutureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenewalFutureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenewalFutureQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetRenewalFutureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRenewalFutureQuery,
    GetRenewalFutureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRenewalFutureQuery, GetRenewalFutureQueryVariables>(
    GetRenewalFutureDocument,
    options
  );
}
export function useGetRenewalFutureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRenewalFutureQuery,
    GetRenewalFutureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRenewalFutureQuery,
    GetRenewalFutureQueryVariables
  >(GetRenewalFutureDocument, options);
}
export type GetRenewalFutureQueryHookResult = ReturnType<
  typeof useGetRenewalFutureQuery
>;
export type GetRenewalFutureLazyQueryHookResult = ReturnType<
  typeof useGetRenewalFutureLazyQuery
>;
export type GetRenewalFutureQueryResult = Apollo.QueryResult<
  GetRenewalFutureQuery,
  GetRenewalFutureQueryVariables
>;
export function refetchGetRenewalFutureQuery(
  variables?: GetRenewalFutureQueryVariables
) {
  return { query: GetRenewalFutureDocument, variables: variables };
}
export const GetSpacesByCustomerDocument = gql`
  query getSpacesByCustomer($billingCustomerId: String) {
    allSpaces(
      condition: { billingCustomerId: $billingCustomerId }
      orderBy: [NAME_ASC]
    ) {
      nodes {
        ...AccountSpace
      }
    }
  }
  ${AccountSpaceFragmentDoc}
`;
export type GetSpacesByCustomerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetSpacesByCustomerQuery,
    GetSpacesByCustomerQueryVariables
  >;
} &
  TChildProps;
export function withGetSpacesByCustomer<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSpacesByCustomerQuery,
    GetSpacesByCustomerQueryVariables,
    GetSpacesByCustomerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSpacesByCustomerQuery,
    GetSpacesByCustomerQueryVariables,
    GetSpacesByCustomerProps<TChildProps, TDataName>
  >(GetSpacesByCustomerDocument, {
    alias: "getSpacesByCustomer",
    ...operationOptions,
  });
}

/**
 * __useGetSpacesByCustomerQuery__
 *
 * To run a query within a React component, call `useGetSpacesByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpacesByCustomerQuery({
 *   variables: {
 *      billingCustomerId: // value for 'billingCustomerId'
 *   },
 * });
 */
export function useGetSpacesByCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSpacesByCustomerQuery,
    GetSpacesByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSpacesByCustomerQuery,
    GetSpacesByCustomerQueryVariables
  >(GetSpacesByCustomerDocument, options);
}
export function useGetSpacesByCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpacesByCustomerQuery,
    GetSpacesByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSpacesByCustomerQuery,
    GetSpacesByCustomerQueryVariables
  >(GetSpacesByCustomerDocument, options);
}
export type GetSpacesByCustomerQueryHookResult = ReturnType<
  typeof useGetSpacesByCustomerQuery
>;
export type GetSpacesByCustomerLazyQueryHookResult = ReturnType<
  typeof useGetSpacesByCustomerLazyQuery
>;
export type GetSpacesByCustomerQueryResult = Apollo.QueryResult<
  GetSpacesByCustomerQuery,
  GetSpacesByCustomerQueryVariables
>;
export function refetchGetSpacesByCustomerQuery(
  variables?: GetSpacesByCustomerQueryVariables
) {
  return { query: GetSpacesByCustomerDocument, variables: variables };
}
export const GetSubscriptionDocument = gql`
  query getSubscription($spaceId: UUID) {
    getBillingSubscription(spaceId: $spaceId) {
      ...Subscription
    }
  }
  ${SubscriptionFragmentDoc}
`;
export type GetSubscriptionProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >;
} &
  TChildProps;
export function withGetSubscription<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables,
    GetSubscriptionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables,
    GetSubscriptionProps<TChildProps, TDataName>
  >(GetSubscriptionDocument, {
    alias: "getSubscription",
    ...operationOptions,
  });
}

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(
    GetSubscriptionDocument,
    options
  );
}
export function useGetSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionQuery,
    GetSubscriptionQueryVariables
  >(GetSubscriptionDocument, options);
}
export type GetSubscriptionQueryHookResult = ReturnType<
  typeof useGetSubscriptionQuery
>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionLazyQuery
>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<
  GetSubscriptionQuery,
  GetSubscriptionQueryVariables
>;
export function refetchGetSubscriptionQuery(
  variables?: GetSubscriptionQueryVariables
) {
  return { query: GetSubscriptionDocument, variables: variables };
}
export const GetSubscriptionFutureDocument = gql`
  query getSubscriptionFuture($spaceId: UUID) {
    getBillingSubscriptionFuture(spaceId: $spaceId) {
      ...SubscriptionFuture
    }
  }
  ${SubscriptionFutureFragmentDoc}
`;
export type GetSubscriptionFutureProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetSubscriptionFutureQuery,
    GetSubscriptionFutureQueryVariables
  >;
} &
  TChildProps;
export function withGetSubscriptionFuture<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSubscriptionFutureQuery,
    GetSubscriptionFutureQueryVariables,
    GetSubscriptionFutureProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSubscriptionFutureQuery,
    GetSubscriptionFutureQueryVariables,
    GetSubscriptionFutureProps<TChildProps, TDataName>
  >(GetSubscriptionFutureDocument, {
    alias: "getSubscriptionFuture",
    ...operationOptions,
  });
}

/**
 * __useGetSubscriptionFutureQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionFutureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionFutureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionFutureQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetSubscriptionFutureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionFutureQuery,
    GetSubscriptionFutureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionFutureQuery,
    GetSubscriptionFutureQueryVariables
  >(GetSubscriptionFutureDocument, options);
}
export function useGetSubscriptionFutureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionFutureQuery,
    GetSubscriptionFutureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionFutureQuery,
    GetSubscriptionFutureQueryVariables
  >(GetSubscriptionFutureDocument, options);
}
export type GetSubscriptionFutureQueryHookResult = ReturnType<
  typeof useGetSubscriptionFutureQuery
>;
export type GetSubscriptionFutureLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionFutureLazyQuery
>;
export type GetSubscriptionFutureQueryResult = Apollo.QueryResult<
  GetSubscriptionFutureQuery,
  GetSubscriptionFutureQueryVariables
>;
export function refetchGetSubscriptionFutureQuery(
  variables?: GetSubscriptionFutureQueryVariables
) {
  return { query: GetSubscriptionFutureDocument, variables: variables };
}
export const ListPlansDocument = gql`
  query listPlans {
    allBillingPlans {
      nodes {
        id
        cfName
        cfIncludedPhysicalScreenLicenses
        cfIncludedVirtualScreenLicenses
        cfSubscriptionType
        cfIsLegacy
        cfSpecial
        cfEnterprise
        currencyCode
        price
        periodUnit
      }
    }
  }
`;
export type ListPlansProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListPlansQuery,
    ListPlansQueryVariables
  >;
} &
  TChildProps;
export function withListPlans<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListPlansQuery,
    ListPlansQueryVariables,
    ListPlansProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListPlansQuery,
    ListPlansQueryVariables,
    ListPlansProps<TChildProps, TDataName>
  >(ListPlansDocument, {
    alias: "listPlans",
    ...operationOptions,
  });
}

/**
 * __useListPlansQuery__
 *
 * To run a query within a React component, call `useListPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<ListPlansQuery, ListPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPlansQuery, ListPlansQueryVariables>(
    ListPlansDocument,
    options
  );
}
export function useListPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPlansQuery,
    ListPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPlansQuery, ListPlansQueryVariables>(
    ListPlansDocument,
    options
  );
}
export type ListPlansQueryHookResult = ReturnType<typeof useListPlansQuery>;
export type ListPlansLazyQueryHookResult = ReturnType<
  typeof useListPlansLazyQuery
>;
export type ListPlansQueryResult = Apollo.QueryResult<
  ListPlansQuery,
  ListPlansQueryVariables
>;
export function refetchListPlansQuery(variables?: ListPlansQueryVariables) {
  return { query: ListPlansDocument, variables: variables };
}
export const PaypalAuthorizeDocument = gql`
  mutation paypalAuthorize($input: PaypalAuthorizeInput!) {
    paypalAuthorize(input: $input) {
      token
      acknowledgement
      redirectUrl
    }
  }
`;
export type PaypalAuthorizeMutationFn = Apollo.MutationFunction<
  PaypalAuthorizeMutation,
  PaypalAuthorizeMutationVariables
>;
export type PaypalAuthorizeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    PaypalAuthorizeMutation,
    PaypalAuthorizeMutationVariables
  >;
} &
  TChildProps;
export function withPaypalAuthorize<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PaypalAuthorizeMutation,
    PaypalAuthorizeMutationVariables,
    PaypalAuthorizeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PaypalAuthorizeMutation,
    PaypalAuthorizeMutationVariables,
    PaypalAuthorizeProps<TChildProps, TDataName>
  >(PaypalAuthorizeDocument, {
    alias: "paypalAuthorize",
    ...operationOptions,
  });
}

/**
 * __usePaypalAuthorizeMutation__
 *
 * To run a mutation, you first call `usePaypalAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaypalAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paypalAuthorizeMutation, { data, loading, error }] = usePaypalAuthorizeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaypalAuthorizeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaypalAuthorizeMutation,
    PaypalAuthorizeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaypalAuthorizeMutation,
    PaypalAuthorizeMutationVariables
  >(PaypalAuthorizeDocument, options);
}
export type PaypalAuthorizeMutationHookResult = ReturnType<
  typeof usePaypalAuthorizeMutation
>;
export type PaypalAuthorizeMutationResult = Apollo.MutationResult<PaypalAuthorizeMutation>;
export type PaypalAuthorizeMutationOptions = Apollo.BaseMutationOptions<
  PaypalAuthorizeMutation,
  PaypalAuthorizeMutationVariables
>;
export const SaveInvoicePaymentDocument = gql`
  mutation saveInvoicePayment($input: SaveInvoicePaymentInput!) {
    saveInvoicePayment(input: $input) {
      success
    }
  }
`;
export type SaveInvoicePaymentMutationFn = Apollo.MutationFunction<
  SaveInvoicePaymentMutation,
  SaveInvoicePaymentMutationVariables
>;
export type SaveInvoicePaymentProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SaveInvoicePaymentMutation,
    SaveInvoicePaymentMutationVariables
  >;
} &
  TChildProps;
export function withSaveInvoicePayment<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SaveInvoicePaymentMutation,
    SaveInvoicePaymentMutationVariables,
    SaveInvoicePaymentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SaveInvoicePaymentMutation,
    SaveInvoicePaymentMutationVariables,
    SaveInvoicePaymentProps<TChildProps, TDataName>
  >(SaveInvoicePaymentDocument, {
    alias: "saveInvoicePayment",
    ...operationOptions,
  });
}

/**
 * __useSaveInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useSaveInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInvoicePaymentMutation, { data, loading, error }] = useSaveInvoicePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveInvoicePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveInvoicePaymentMutation,
    SaveInvoicePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveInvoicePaymentMutation,
    SaveInvoicePaymentMutationVariables
  >(SaveInvoicePaymentDocument, options);
}
export type SaveInvoicePaymentMutationHookResult = ReturnType<
  typeof useSaveInvoicePaymentMutation
>;
export type SaveInvoicePaymentMutationResult = Apollo.MutationResult<SaveInvoicePaymentMutation>;
export type SaveInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<
  SaveInvoicePaymentMutation,
  SaveInvoicePaymentMutationVariables
>;
export const UpdateBillingAddressDocument = gql`
  mutation updateBillingAddress($input: UpdateBillingAddressInput!) {
    updateBillingAddress(input: $input) {
      success
    }
  }
`;
export type UpdateBillingAddressMutationFn = Apollo.MutationFunction<
  UpdateBillingAddressMutation,
  UpdateBillingAddressMutationVariables
>;
export type UpdateBillingAddressProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateBillingAddressMutation,
    UpdateBillingAddressMutationVariables
  >;
} &
  TChildProps;
export function withUpdateBillingAddress<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateBillingAddressMutation,
    UpdateBillingAddressMutationVariables,
    UpdateBillingAddressProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateBillingAddressMutation,
    UpdateBillingAddressMutationVariables,
    UpdateBillingAddressProps<TChildProps, TDataName>
  >(UpdateBillingAddressDocument, {
    alias: "updateBillingAddress",
    ...operationOptions,
  });
}

/**
 * __useUpdateBillingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingAddressMutation, { data, loading, error }] = useUpdateBillingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillingAddressMutation,
    UpdateBillingAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBillingAddressMutation,
    UpdateBillingAddressMutationVariables
  >(UpdateBillingAddressDocument, options);
}
export type UpdateBillingAddressMutationHookResult = ReturnType<
  typeof useUpdateBillingAddressMutation
>;
export type UpdateBillingAddressMutationResult = Apollo.MutationResult<UpdateBillingAddressMutation>;
export type UpdateBillingAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillingAddressMutation,
  UpdateBillingAddressMutationVariables
>;
export const UpdateSubscriptionDocument = gql`
  mutation updateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      success
    }
  }
`;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;
export type UpdateSubscriptionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >;
} &
  TChildProps;
export function withUpdateSubscription<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables,
    UpdateSubscriptionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables,
    UpdateSubscriptionProps<TChildProps, TDataName>
  >(UpdateSubscriptionDocument, {
    alias: "updateSubscription",
    ...operationOptions,
  });
}

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >(UpdateSubscriptionDocument, options);
}
export type UpdateSubscriptionMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionMutation
>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;
export const BillingPlanByIdDocument = gql`
  query billingPlanById($id: String!) {
    billingPlanById(id: $id) {
      ...BillingPlanById
    }
  }
  ${BillingPlanByIdFragmentDoc}
`;
export type BillingPlanByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    BillingPlanByIdQuery,
    BillingPlanByIdQueryVariables
  >;
} &
  TChildProps;
export function withBillingPlanById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BillingPlanByIdQuery,
    BillingPlanByIdQueryVariables,
    BillingPlanByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    BillingPlanByIdQuery,
    BillingPlanByIdQueryVariables,
    BillingPlanByIdProps<TChildProps, TDataName>
  >(BillingPlanByIdDocument, {
    alias: "billingPlanById",
    ...operationOptions,
  });
}

/**
 * __useBillingPlanByIdQuery__
 *
 * To run a query within a React component, call `useBillingPlanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPlanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPlanByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingPlanByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillingPlanByIdQuery,
    BillingPlanByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingPlanByIdQuery, BillingPlanByIdQueryVariables>(
    BillingPlanByIdDocument,
    options
  );
}
export function useBillingPlanByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingPlanByIdQuery,
    BillingPlanByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BillingPlanByIdQuery,
    BillingPlanByIdQueryVariables
  >(BillingPlanByIdDocument, options);
}
export type BillingPlanByIdQueryHookResult = ReturnType<
  typeof useBillingPlanByIdQuery
>;
export type BillingPlanByIdLazyQueryHookResult = ReturnType<
  typeof useBillingPlanByIdLazyQuery
>;
export type BillingPlanByIdQueryResult = Apollo.QueryResult<
  BillingPlanByIdQuery,
  BillingPlanByIdQueryVariables
>;
export function refetchBillingPlanByIdQuery(
  variables?: BillingPlanByIdQueryVariables
) {
  return { query: BillingPlanByIdDocument, variables: variables };
}
export const CreateRelationshipDocument = gql`
  mutation createRelationship($input: CreateRelationshipInput!) {
    createRelationship(input: $input) {
      success
    }
  }
`;
export type CreateRelationshipMutationFn = Apollo.MutationFunction<
  CreateRelationshipMutation,
  CreateRelationshipMutationVariables
>;
export type CreateRelationshipProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateRelationshipMutation,
    CreateRelationshipMutationVariables
  >;
} &
  TChildProps;
export function withCreateRelationship<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateRelationshipMutation,
    CreateRelationshipMutationVariables,
    CreateRelationshipProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateRelationshipMutation,
    CreateRelationshipMutationVariables,
    CreateRelationshipProps<TChildProps, TDataName>
  >(CreateRelationshipDocument, {
    alias: "createRelationship",
    ...operationOptions,
  });
}

/**
 * __useCreateRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRelationshipMutation, { data, loading, error }] = useCreateRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRelationshipMutation,
    CreateRelationshipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRelationshipMutation,
    CreateRelationshipMutationVariables
  >(CreateRelationshipDocument, options);
}
export type CreateRelationshipMutationHookResult = ReturnType<
  typeof useCreateRelationshipMutation
>;
export type CreateRelationshipMutationResult = Apollo.MutationResult<CreateRelationshipMutation>;
export type CreateRelationshipMutationOptions = Apollo.BaseMutationOptions<
  CreateRelationshipMutation,
  CreateRelationshipMutationVariables
>;
export const GetPaymentAccountDocument = gql`
  query getPaymentAccount($id: String!) {
    billingCustomerById(id: $id) {
      ...PaymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type GetPaymentAccountProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetPaymentAccountQuery,
    GetPaymentAccountQueryVariables
  >;
} &
  TChildProps;
export function withGetPaymentAccount<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetPaymentAccountQuery,
    GetPaymentAccountQueryVariables,
    GetPaymentAccountProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetPaymentAccountQuery,
    GetPaymentAccountQueryVariables,
    GetPaymentAccountProps<TChildProps, TDataName>
  >(GetPaymentAccountDocument, {
    alias: "getPaymentAccount",
    ...operationOptions,
  });
}

/**
 * __useGetPaymentAccountQuery__
 *
 * To run a query within a React component, call `useGetPaymentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentAccountQuery,
    GetPaymentAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentAccountQuery,
    GetPaymentAccountQueryVariables
  >(GetPaymentAccountDocument, options);
}
export function useGetPaymentAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentAccountQuery,
    GetPaymentAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentAccountQuery,
    GetPaymentAccountQueryVariables
  >(GetPaymentAccountDocument, options);
}
export type GetPaymentAccountQueryHookResult = ReturnType<
  typeof useGetPaymentAccountQuery
>;
export type GetPaymentAccountLazyQueryHookResult = ReturnType<
  typeof useGetPaymentAccountLazyQuery
>;
export type GetPaymentAccountQueryResult = Apollo.QueryResult<
  GetPaymentAccountQuery,
  GetPaymentAccountQueryVariables
>;
export function refetchGetPaymentAccountQuery(
  variables?: GetPaymentAccountQueryVariables
) {
  return { query: GetPaymentAccountDocument, variables: variables };
}
export const GetPaymentAccountsDocument = gql`
  query getPaymentAccounts {
    allBillingCustomers(
      orderBy: [COMPANY_ASC]
      filter: { parentId: { isNull: false } }
    ) {
      nodes {
        ...PaymentAccountSimple
      }
    }
  }
  ${PaymentAccountSimpleFragmentDoc}
`;
export type GetPaymentAccountsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetPaymentAccountsQuery,
    GetPaymentAccountsQueryVariables
  >;
} &
  TChildProps;
export function withGetPaymentAccounts<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetPaymentAccountsQuery,
    GetPaymentAccountsQueryVariables,
    GetPaymentAccountsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetPaymentAccountsQuery,
    GetPaymentAccountsQueryVariables,
    GetPaymentAccountsProps<TChildProps, TDataName>
  >(GetPaymentAccountsDocument, {
    alias: "getPaymentAccounts",
    ...operationOptions,
  });
}

/**
 * __useGetPaymentAccountsQuery__
 *
 * To run a query within a React component, call `useGetPaymentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentAccountsQuery,
    GetPaymentAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentAccountsQuery,
    GetPaymentAccountsQueryVariables
  >(GetPaymentAccountsDocument, options);
}
export function useGetPaymentAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentAccountsQuery,
    GetPaymentAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentAccountsQuery,
    GetPaymentAccountsQueryVariables
  >(GetPaymentAccountsDocument, options);
}
export type GetPaymentAccountsQueryHookResult = ReturnType<
  typeof useGetPaymentAccountsQuery
>;
export type GetPaymentAccountsLazyQueryHookResult = ReturnType<
  typeof useGetPaymentAccountsLazyQuery
>;
export type GetPaymentAccountsQueryResult = Apollo.QueryResult<
  GetPaymentAccountsQuery,
  GetPaymentAccountsQueryVariables
>;
export function refetchGetPaymentAccountsQuery(
  variables?: GetPaymentAccountsQueryVariables
) {
  return { query: GetPaymentAccountsDocument, variables: variables };
}
export const GetSpacesUnattachedDocument = gql`
  query getSpacesUnattached {
    allSpaces(condition: { billingCustomerId: null }, orderBy: [NAME_ASC]) {
      nodes {
        ...SpaceSimple
      }
    }
  }
  ${SpaceSimpleFragmentDoc}
`;
export type GetSpacesUnattachedProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetSpacesUnattachedQuery,
    GetSpacesUnattachedQueryVariables
  >;
} &
  TChildProps;
export function withGetSpacesUnattached<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetSpacesUnattachedQuery,
    GetSpacesUnattachedQueryVariables,
    GetSpacesUnattachedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetSpacesUnattachedQuery,
    GetSpacesUnattachedQueryVariables,
    GetSpacesUnattachedProps<TChildProps, TDataName>
  >(GetSpacesUnattachedDocument, {
    alias: "getSpacesUnattached",
    ...operationOptions,
  });
}

/**
 * __useGetSpacesUnattachedQuery__
 *
 * To run a query within a React component, call `useGetSpacesUnattachedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesUnattachedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpacesUnattachedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpacesUnattachedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSpacesUnattachedQuery,
    GetSpacesUnattachedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSpacesUnattachedQuery,
    GetSpacesUnattachedQueryVariables
  >(GetSpacesUnattachedDocument, options);
}
export function useGetSpacesUnattachedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpacesUnattachedQuery,
    GetSpacesUnattachedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSpacesUnattachedQuery,
    GetSpacesUnattachedQueryVariables
  >(GetSpacesUnattachedDocument, options);
}
export type GetSpacesUnattachedQueryHookResult = ReturnType<
  typeof useGetSpacesUnattachedQuery
>;
export type GetSpacesUnattachedLazyQueryHookResult = ReturnType<
  typeof useGetSpacesUnattachedLazyQuery
>;
export type GetSpacesUnattachedQueryResult = Apollo.QueryResult<
  GetSpacesUnattachedQuery,
  GetSpacesUnattachedQueryVariables
>;
export function refetchGetSpacesUnattachedQuery(
  variables?: GetSpacesUnattachedQueryVariables
) {
  return { query: GetSpacesUnattachedDocument, variables: variables };
}
export const UpdateRelationshipDocument = gql`
  mutation updateRelationship($input: UpdateRelationshipInput!) {
    updateRelationship(input: $input) {
      success
    }
  }
`;
export type UpdateRelationshipMutationFn = Apollo.MutationFunction<
  UpdateRelationshipMutation,
  UpdateRelationshipMutationVariables
>;
export type UpdateRelationshipProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateRelationshipMutation,
    UpdateRelationshipMutationVariables
  >;
} &
  TChildProps;
export function withUpdateRelationship<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateRelationshipMutation,
    UpdateRelationshipMutationVariables,
    UpdateRelationshipProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateRelationshipMutation,
    UpdateRelationshipMutationVariables,
    UpdateRelationshipProps<TChildProps, TDataName>
  >(UpdateRelationshipDocument, {
    alias: "updateRelationship",
    ...operationOptions,
  });
}

/**
 * __useUpdateRelationshipMutation__
 *
 * To run a mutation, you first call `useUpdateRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelationshipMutation, { data, loading, error }] = useUpdateRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRelationshipMutation,
    UpdateRelationshipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRelationshipMutation,
    UpdateRelationshipMutationVariables
  >(UpdateRelationshipDocument, options);
}
export type UpdateRelationshipMutationHookResult = ReturnType<
  typeof useUpdateRelationshipMutation
>;
export type UpdateRelationshipMutationResult = Apollo.MutationResult<UpdateRelationshipMutation>;
export type UpdateRelationshipMutationOptions = Apollo.BaseMutationOptions<
  UpdateRelationshipMutation,
  UpdateRelationshipMutationVariables
>;
export const GetBillingScreenCountDocument = gql`
  query getBillingScreenCount {
    screens: allScreens(
      filter: { status: { in: [LIVE, SUSPENDED] }, preview: { equalTo: false } }
    ) {
      count: totalCount
    }
  }
`;
export type GetBillingScreenCountProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetBillingScreenCountQuery,
    GetBillingScreenCountQueryVariables
  >;
} &
  TChildProps;
export function withGetBillingScreenCount<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBillingScreenCountQuery,
    GetBillingScreenCountQueryVariables,
    GetBillingScreenCountProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBillingScreenCountQuery,
    GetBillingScreenCountQueryVariables,
    GetBillingScreenCountProps<TChildProps, TDataName>
  >(GetBillingScreenCountDocument, {
    alias: "getBillingScreenCount",
    ...operationOptions,
  });
}

/**
 * __useGetBillingScreenCountQuery__
 *
 * To run a query within a React component, call `useGetBillingScreenCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingScreenCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingScreenCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingScreenCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBillingScreenCountQuery,
    GetBillingScreenCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBillingScreenCountQuery,
    GetBillingScreenCountQueryVariables
  >(GetBillingScreenCountDocument, options);
}
export function useGetBillingScreenCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillingScreenCountQuery,
    GetBillingScreenCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBillingScreenCountQuery,
    GetBillingScreenCountQueryVariables
  >(GetBillingScreenCountDocument, options);
}
export type GetBillingScreenCountQueryHookResult = ReturnType<
  typeof useGetBillingScreenCountQuery
>;
export type GetBillingScreenCountLazyQueryHookResult = ReturnType<
  typeof useGetBillingScreenCountLazyQuery
>;
export type GetBillingScreenCountQueryResult = Apollo.QueryResult<
  GetBillingScreenCountQuery,
  GetBillingScreenCountQueryVariables
>;
export function refetchGetBillingScreenCountQuery(
  variables?: GetBillingScreenCountQueryVariables
) {
  return { query: GetBillingScreenCountDocument, variables: variables };
}
export const GetBillingSpacesDocument = gql`
  query getBillingSpaces {
    all: allSpaces(orderBy: [NAME_ASC]) {
      nodes {
        ...SpaceWithUsedLicenses
      }
    }
  }
  ${SpaceWithUsedLicensesFragmentDoc}
`;
export type GetBillingSpacesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetBillingSpacesQuery,
    GetBillingSpacesQueryVariables
  >;
} &
  TChildProps;
export function withGetBillingSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBillingSpacesQuery,
    GetBillingSpacesQueryVariables,
    GetBillingSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBillingSpacesQuery,
    GetBillingSpacesQueryVariables,
    GetBillingSpacesProps<TChildProps, TDataName>
  >(GetBillingSpacesDocument, {
    alias: "getBillingSpaces",
    ...operationOptions,
  });
}

/**
 * __useGetBillingSpacesQuery__
 *
 * To run a query within a React component, call `useGetBillingSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingSpacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingSpacesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBillingSpacesQuery,
    GetBillingSpacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBillingSpacesQuery, GetBillingSpacesQueryVariables>(
    GetBillingSpacesDocument,
    options
  );
}
export function useGetBillingSpacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillingSpacesQuery,
    GetBillingSpacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBillingSpacesQuery,
    GetBillingSpacesQueryVariables
  >(GetBillingSpacesDocument, options);
}
export type GetBillingSpacesQueryHookResult = ReturnType<
  typeof useGetBillingSpacesQuery
>;
export type GetBillingSpacesLazyQueryHookResult = ReturnType<
  typeof useGetBillingSpacesLazyQuery
>;
export type GetBillingSpacesQueryResult = Apollo.QueryResult<
  GetBillingSpacesQuery,
  GetBillingSpacesQueryVariables
>;
export function refetchGetBillingSpacesQuery(
  variables?: GetBillingSpacesQueryVariables
) {
  return { query: GetBillingSpacesDocument, variables: variables };
}
export const PairScreenDocument = gql`
  mutation pairScreen($input: CreateScreenInput!) {
    createScreen(input: $input) {
      screen {
        ...Screen
      }
    }
  }
  ${ScreenFragmentDoc}
`;
export type PairScreenMutationFn = Apollo.MutationFunction<
  PairScreenMutation,
  PairScreenMutationVariables
>;
export type PairScreenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    PairScreenMutation,
    PairScreenMutationVariables
  >;
} &
  TChildProps;
export function withPairScreen<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PairScreenMutation,
    PairScreenMutationVariables,
    PairScreenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PairScreenMutation,
    PairScreenMutationVariables,
    PairScreenProps<TChildProps, TDataName>
  >(PairScreenDocument, {
    alias: "pairScreen",
    ...operationOptions,
  });
}

/**
 * __usePairScreenMutation__
 *
 * To run a mutation, you first call `usePairScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePairScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pairScreenMutation, { data, loading, error }] = usePairScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePairScreenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PairScreenMutation,
    PairScreenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PairScreenMutation, PairScreenMutationVariables>(
    PairScreenDocument,
    options
  );
}
export type PairScreenMutationHookResult = ReturnType<
  typeof usePairScreenMutation
>;
export type PairScreenMutationResult = Apollo.MutationResult<PairScreenMutation>;
export type PairScreenMutationOptions = Apollo.BaseMutationOptions<
  PairScreenMutation,
  PairScreenMutationVariables
>;
export const GetPrimaryScreenGroupDocument = gql`
  query getPrimaryScreenGroup($spaceId: UUID!) {
    allScreenGroups(condition: { isPrimary: true, spaceId: $spaceId }) {
      nodes {
        __typename
        id
        orgId
        spaceId
        name
        env
      }
    }
  }
`;
export type GetPrimaryScreenGroupProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetPrimaryScreenGroupQuery,
    GetPrimaryScreenGroupQueryVariables
  >;
} &
  TChildProps;
export function withGetPrimaryScreenGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetPrimaryScreenGroupQuery,
    GetPrimaryScreenGroupQueryVariables,
    GetPrimaryScreenGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetPrimaryScreenGroupQuery,
    GetPrimaryScreenGroupQueryVariables,
    GetPrimaryScreenGroupProps<TChildProps, TDataName>
  >(GetPrimaryScreenGroupDocument, {
    alias: "getPrimaryScreenGroup",
    ...operationOptions,
  });
}

/**
 * __useGetPrimaryScreenGroupQuery__
 *
 * To run a query within a React component, call `useGetPrimaryScreenGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrimaryScreenGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrimaryScreenGroupQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetPrimaryScreenGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrimaryScreenGroupQuery,
    GetPrimaryScreenGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPrimaryScreenGroupQuery,
    GetPrimaryScreenGroupQueryVariables
  >(GetPrimaryScreenGroupDocument, options);
}
export function useGetPrimaryScreenGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrimaryScreenGroupQuery,
    GetPrimaryScreenGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPrimaryScreenGroupQuery,
    GetPrimaryScreenGroupQueryVariables
  >(GetPrimaryScreenGroupDocument, options);
}
export type GetPrimaryScreenGroupQueryHookResult = ReturnType<
  typeof useGetPrimaryScreenGroupQuery
>;
export type GetPrimaryScreenGroupLazyQueryHookResult = ReturnType<
  typeof useGetPrimaryScreenGroupLazyQuery
>;
export type GetPrimaryScreenGroupQueryResult = Apollo.QueryResult<
  GetPrimaryScreenGroupQuery,
  GetPrimaryScreenGroupQueryVariables
>;
export function refetchGetPrimaryScreenGroupQuery(
  variables?: GetPrimaryScreenGroupQueryVariables
) {
  return { query: GetPrimaryScreenGroupDocument, variables: variables };
}
export const TokenByIdDocument = gql`
  query tokenById($id: UUID!) {
    tokenById(id: $id) {
      ...Token
      userByUserId {
        ...User
      }
    }
  }
  ${TokenFragmentDoc}
  ${UserFragmentDoc}
`;
export type TokenByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    TokenByIdQuery,
    TokenByIdQueryVariables
  >;
} &
  TChildProps;
export function withTokenById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TokenByIdQuery,
    TokenByIdQueryVariables,
    TokenByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TokenByIdQuery,
    TokenByIdQueryVariables,
    TokenByIdProps<TChildProps, TDataName>
  >(TokenByIdDocument, {
    alias: "tokenById",
    ...operationOptions,
  });
}

/**
 * __useTokenByIdQuery__
 *
 * To run a query within a React component, call `useTokenByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTokenByIdQuery(
  baseOptions: Apollo.QueryHookOptions<TokenByIdQuery, TokenByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokenByIdQuery, TokenByIdQueryVariables>(
    TokenByIdDocument,
    options
  );
}
export function useTokenByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokenByIdQuery,
    TokenByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TokenByIdQuery, TokenByIdQueryVariables>(
    TokenByIdDocument,
    options
  );
}
export type TokenByIdQueryHookResult = ReturnType<typeof useTokenByIdQuery>;
export type TokenByIdLazyQueryHookResult = ReturnType<
  typeof useTokenByIdLazyQuery
>;
export type TokenByIdQueryResult = Apollo.QueryResult<
  TokenByIdQuery,
  TokenByIdQueryVariables
>;
export function refetchTokenByIdQuery(variables?: TokenByIdQueryVariables) {
  return { query: TokenByIdDocument, variables: variables };
}
export const DeactivateTokenDocument = gql`
  mutation deactivateToken($input: DeactivateTokenInput!) {
    deactivateToken(input: $input) {
      token {
        ...Token
      }
      orgByOrgId {
        __typename
        id
      }
      userByUserId {
        __typename
        id
      }
      userByCreatedBy {
        __typename
        id
      }
      userByUpdatedBy {
        __typename
        id
      }
    }
  }
  ${TokenFragmentDoc}
`;
export type DeactivateTokenMutationFn = Apollo.MutationFunction<
  DeactivateTokenMutation,
  DeactivateTokenMutationVariables
>;
export type DeactivateTokenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeactivateTokenMutation,
    DeactivateTokenMutationVariables
  >;
} &
  TChildProps;
export function withDeactivateToken<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeactivateTokenMutation,
    DeactivateTokenMutationVariables,
    DeactivateTokenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeactivateTokenMutation,
    DeactivateTokenMutationVariables,
    DeactivateTokenProps<TChildProps, TDataName>
  >(DeactivateTokenDocument, {
    alias: "deactivateToken",
    ...operationOptions,
  });
}

/**
 * __useDeactivateTokenMutation__
 *
 * To run a mutation, you first call `useDeactivateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTokenMutation, { data, loading, error }] = useDeactivateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateTokenMutation,
    DeactivateTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateTokenMutation,
    DeactivateTokenMutationVariables
  >(DeactivateTokenDocument, options);
}
export type DeactivateTokenMutationHookResult = ReturnType<
  typeof useDeactivateTokenMutation
>;
export type DeactivateTokenMutationResult = Apollo.MutationResult<DeactivateTokenMutation>;
export type DeactivateTokenMutationOptions = Apollo.BaseMutationOptions<
  DeactivateTokenMutation,
  DeactivateTokenMutationVariables
>;
export const ReactivateTokenDocument = gql`
  mutation reactivateToken($input: ReactivateTokenInput!) {
    reactivateToken(input: $input) {
      token {
        ...Token
      }
      orgByOrgId {
        __typename
        id
      }
      userByUserId {
        __typename
        id
      }
      userByCreatedBy {
        __typename
        id
      }
      userByUpdatedBy {
        __typename
        id
      }
    }
  }
  ${TokenFragmentDoc}
`;
export type ReactivateTokenMutationFn = Apollo.MutationFunction<
  ReactivateTokenMutation,
  ReactivateTokenMutationVariables
>;
export type ReactivateTokenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ReactivateTokenMutation,
    ReactivateTokenMutationVariables
  >;
} &
  TChildProps;
export function withReactivateToken<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReactivateTokenMutation,
    ReactivateTokenMutationVariables,
    ReactivateTokenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReactivateTokenMutation,
    ReactivateTokenMutationVariables,
    ReactivateTokenProps<TChildProps, TDataName>
  >(ReactivateTokenDocument, {
    alias: "reactivateToken",
    ...operationOptions,
  });
}

/**
 * __useReactivateTokenMutation__
 *
 * To run a mutation, you first call `useReactivateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateTokenMutation, { data, loading, error }] = useReactivateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactivateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateTokenMutation,
    ReactivateTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactivateTokenMutation,
    ReactivateTokenMutationVariables
  >(ReactivateTokenDocument, options);
}
export type ReactivateTokenMutationHookResult = ReturnType<
  typeof useReactivateTokenMutation
>;
export type ReactivateTokenMutationResult = Apollo.MutationResult<ReactivateTokenMutation>;
export type ReactivateTokenMutationOptions = Apollo.BaseMutationOptions<
  ReactivateTokenMutation,
  ReactivateTokenMutationVariables
>;
export const RegenerateTokenDocument = gql`
  mutation regenerateToken($input: RegenerateTokenInput!) {
    regenerateToken(input: $input) {
      tokenSecretPayload {
        secret
        token {
          ...Token
        }
      }
    }
  }
  ${TokenFragmentDoc}
`;
export type RegenerateTokenMutationFn = Apollo.MutationFunction<
  RegenerateTokenMutation,
  RegenerateTokenMutationVariables
>;
export type RegenerateTokenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RegenerateTokenMutation,
    RegenerateTokenMutationVariables
  >;
} &
  TChildProps;
export function withRegenerateToken<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegenerateTokenMutation,
    RegenerateTokenMutationVariables,
    RegenerateTokenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegenerateTokenMutation,
    RegenerateTokenMutationVariables,
    RegenerateTokenProps<TChildProps, TDataName>
  >(RegenerateTokenDocument, {
    alias: "regenerateToken",
    ...operationOptions,
  });
}

/**
 * __useRegenerateTokenMutation__
 *
 * To run a mutation, you first call `useRegenerateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateTokenMutation, { data, loading, error }] = useRegenerateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegenerateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegenerateTokenMutation,
    RegenerateTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegenerateTokenMutation,
    RegenerateTokenMutationVariables
  >(RegenerateTokenDocument, options);
}
export type RegenerateTokenMutationHookResult = ReturnType<
  typeof useRegenerateTokenMutation
>;
export type RegenerateTokenMutationResult = Apollo.MutationResult<RegenerateTokenMutation>;
export type RegenerateTokenMutationOptions = Apollo.BaseMutationOptions<
  RegenerateTokenMutation,
  RegenerateTokenMutationVariables
>;
export const AddGroupPermissionSetsDocument = gql`
  mutation addGroupPermissionSets($input: AddGroupPermissionSetsInput!) {
    addGroupPermissionSets(input: $input) {
      group {
        id
        __typename
        name
        permissionSets
      }
    }
  }
`;
export type AddGroupPermissionSetsMutationFn = Apollo.MutationFunction<
  AddGroupPermissionSetsMutation,
  AddGroupPermissionSetsMutationVariables
>;
export type AddGroupPermissionSetsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AddGroupPermissionSetsMutation,
    AddGroupPermissionSetsMutationVariables
  >;
} &
  TChildProps;
export function withAddGroupPermissionSets<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddGroupPermissionSetsMutation,
    AddGroupPermissionSetsMutationVariables,
    AddGroupPermissionSetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddGroupPermissionSetsMutation,
    AddGroupPermissionSetsMutationVariables,
    AddGroupPermissionSetsProps<TChildProps, TDataName>
  >(AddGroupPermissionSetsDocument, {
    alias: "addGroupPermissionSets",
    ...operationOptions,
  });
}

/**
 * __useAddGroupPermissionSetsMutation__
 *
 * To run a mutation, you first call `useAddGroupPermissionSetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupPermissionSetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupPermissionSetsMutation, { data, loading, error }] = useAddGroupPermissionSetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupPermissionSetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupPermissionSetsMutation,
    AddGroupPermissionSetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupPermissionSetsMutation,
    AddGroupPermissionSetsMutationVariables
  >(AddGroupPermissionSetsDocument, options);
}
export type AddGroupPermissionSetsMutationHookResult = ReturnType<
  typeof useAddGroupPermissionSetsMutation
>;
export type AddGroupPermissionSetsMutationResult = Apollo.MutationResult<AddGroupPermissionSetsMutation>;
export type AddGroupPermissionSetsMutationOptions = Apollo.BaseMutationOptions<
  AddGroupPermissionSetsMutation,
  AddGroupPermissionSetsMutationVariables
>;
export const AllMembersDocument = gql`
  query allMembers {
    allUsers(
      orderBy: FAMILY_NAME_ASC
      condition: {
        deletedAt: null
        userType: USER
        isOwner: false
        status: ACTIVE
      }
    ) {
      nodes {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type AllMembersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllMembersQuery,
    AllMembersQueryVariables
  >;
} &
  TChildProps;
export function withAllMembers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllMembersQuery,
    AllMembersQueryVariables,
    AllMembersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllMembersQuery,
    AllMembersQueryVariables,
    AllMembersProps<TChildProps, TDataName>
  >(AllMembersDocument, {
    alias: "allMembers",
    ...operationOptions,
  });
}

/**
 * __useAllMembersQuery__
 *
 * To run a query within a React component, call `useAllMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllMembersQuery,
    AllMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllMembersQuery, AllMembersQueryVariables>(
    AllMembersDocument,
    options
  );
}
export function useAllMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllMembersQuery,
    AllMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllMembersQuery, AllMembersQueryVariables>(
    AllMembersDocument,
    options
  );
}
export type AllMembersQueryHookResult = ReturnType<typeof useAllMembersQuery>;
export type AllMembersLazyQueryHookResult = ReturnType<
  typeof useAllMembersLazyQuery
>;
export type AllMembersQueryResult = Apollo.QueryResult<
  AllMembersQuery,
  AllMembersQueryVariables
>;
export function refetchAllMembersQuery(variables?: AllMembersQueryVariables) {
  return { query: AllMembersDocument, variables: variables };
}
export const CreateEnrollmentTokenDocument = gql`
  mutation createEnrollmentToken($input: CreateEnrollmentTokenInput!) {
    createEnrollmentToken(input: $input) {
      __typename
      token
      payload
    }
  }
`;
export type CreateEnrollmentTokenMutationFn = Apollo.MutationFunction<
  CreateEnrollmentTokenMutation,
  CreateEnrollmentTokenMutationVariables
>;
export type CreateEnrollmentTokenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateEnrollmentTokenMutation,
    CreateEnrollmentTokenMutationVariables
  >;
} &
  TChildProps;
export function withCreateEnrollmentToken<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateEnrollmentTokenMutation,
    CreateEnrollmentTokenMutationVariables,
    CreateEnrollmentTokenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateEnrollmentTokenMutation,
    CreateEnrollmentTokenMutationVariables,
    CreateEnrollmentTokenProps<TChildProps, TDataName>
  >(CreateEnrollmentTokenDocument, {
    alias: "createEnrollmentToken",
    ...operationOptions,
  });
}

/**
 * __useCreateEnrollmentTokenMutation__
 *
 * To run a mutation, you first call `useCreateEnrollmentTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnrollmentTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnrollmentTokenMutation, { data, loading, error }] = useCreateEnrollmentTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnrollmentTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEnrollmentTokenMutation,
    CreateEnrollmentTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEnrollmentTokenMutation,
    CreateEnrollmentTokenMutationVariables
  >(CreateEnrollmentTokenDocument, options);
}
export type CreateEnrollmentTokenMutationHookResult = ReturnType<
  typeof useCreateEnrollmentTokenMutation
>;
export type CreateEnrollmentTokenMutationResult = Apollo.MutationResult<CreateEnrollmentTokenMutation>;
export type CreateEnrollmentTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateEnrollmentTokenMutation,
  CreateEnrollmentTokenMutationVariables
>;
export const DeleteGroupPermissionSetsDocument = gql`
  mutation deleteGroupPermissionSets($input: DeleteGroupPermissionSetsInput!) {
    deleteGroupPermissionSets(input: $input) {
      group {
        __typename
        id
        name
        permissionSets
      }
    }
  }
`;
export type DeleteGroupPermissionSetsMutationFn = Apollo.MutationFunction<
  DeleteGroupPermissionSetsMutation,
  DeleteGroupPermissionSetsMutationVariables
>;
export type DeleteGroupPermissionSetsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteGroupPermissionSetsMutation,
    DeleteGroupPermissionSetsMutationVariables
  >;
} &
  TChildProps;
export function withDeleteGroupPermissionSets<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteGroupPermissionSetsMutation,
    DeleteGroupPermissionSetsMutationVariables,
    DeleteGroupPermissionSetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteGroupPermissionSetsMutation,
    DeleteGroupPermissionSetsMutationVariables,
    DeleteGroupPermissionSetsProps<TChildProps, TDataName>
  >(DeleteGroupPermissionSetsDocument, {
    alias: "deleteGroupPermissionSets",
    ...operationOptions,
  });
}

/**
 * __useDeleteGroupPermissionSetsMutation__
 *
 * To run a mutation, you first call `useDeleteGroupPermissionSetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupPermissionSetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupPermissionSetsMutation, { data, loading, error }] = useDeleteGroupPermissionSetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupPermissionSetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupPermissionSetsMutation,
    DeleteGroupPermissionSetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGroupPermissionSetsMutation,
    DeleteGroupPermissionSetsMutationVariables
  >(DeleteGroupPermissionSetsDocument, options);
}
export type DeleteGroupPermissionSetsMutationHookResult = ReturnType<
  typeof useDeleteGroupPermissionSetsMutation
>;
export type DeleteGroupPermissionSetsMutationResult = Apollo.MutationResult<DeleteGroupPermissionSetsMutation>;
export type DeleteGroupPermissionSetsMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupPermissionSetsMutation,
  DeleteGroupPermissionSetsMutationVariables
>;
export const GroupByIdDocument = gql`
  query groupById($id: UUID!) {
    groupById(id: $id) {
      __typename
      id
      name
      permissionSets
      userGroupsByGroupId {
        nodes {
          __typename
          userId
          userByUserId {
            __typename
            id
            email
            givenName
            familyName
            isOwner
            metadata
            status
          }
        }
      }
      groupSpacesByGroupId {
        nodes {
          __typename
          groupId
          spaceId
          permissionSets
          permissions
          spaceBySpaceId {
            __typename
            id
            name
          }
        }
      }
    }
  }
`;
export type GroupByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GroupByIdQuery,
    GroupByIdQueryVariables
  >;
} &
  TChildProps;
export function withGroupById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GroupByIdQuery,
    GroupByIdQueryVariables,
    GroupByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GroupByIdQuery,
    GroupByIdQueryVariables,
    GroupByIdProps<TChildProps, TDataName>
  >(GroupByIdDocument, {
    alias: "groupById",
    ...operationOptions,
  });
}

/**
 * __useGroupByIdQuery__
 *
 * To run a query within a React component, call `useGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GroupByIdQuery, GroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupByIdQuery, GroupByIdQueryVariables>(
    GroupByIdDocument,
    options
  );
}
export function useGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupByIdQuery,
    GroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupByIdQuery, GroupByIdQueryVariables>(
    GroupByIdDocument,
    options
  );
}
export type GroupByIdQueryHookResult = ReturnType<typeof useGroupByIdQuery>;
export type GroupByIdLazyQueryHookResult = ReturnType<
  typeof useGroupByIdLazyQuery
>;
export type GroupByIdQueryResult = Apollo.QueryResult<
  GroupByIdQuery,
  GroupByIdQueryVariables
>;
export function refetchGroupByIdQuery(variables?: GroupByIdQueryVariables) {
  return { query: GroupByIdDocument, variables: variables };
}
export const UpdateGroupDocument = gql`
  mutation updateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      __typename
      group {
        ...Group
      }
    }
  }
  ${GroupFragmentDoc}
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export type UpdateGroupProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >;
} &
  TChildProps;
export function withUpdateGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateGroupMutation,
    UpdateGroupMutationVariables,
    UpdateGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateGroupMutation,
    UpdateGroupMutationVariables,
    UpdateGroupProps<TChildProps, TDataName>
  >(UpdateGroupDocument, {
    alias: "updateGroup",
    ...operationOptions,
  });
}

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    options
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const AllGroupsDocument = gql`
  query allGroups {
    allGroups(orderBy: [NAME_ASC]) {
      nodes {
        __typename
        id
        name
        description
        permissionSets
        isDefault
        groupSpacesByGroupId {
          nodes {
            spaceId
            permissionSets
            spaceBySpaceId {
              __typename
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AllGroupsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllGroupsQuery,
    AllGroupsQueryVariables
  >;
} &
  TChildProps;
export function withAllGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllGroupsQuery,
    AllGroupsQueryVariables,
    AllGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllGroupsQuery,
    AllGroupsQueryVariables,
    AllGroupsProps<TChildProps, TDataName>
  >(AllGroupsDocument, {
    alias: "allGroups",
    ...operationOptions,
  });
}

/**
 * __useAllGroupsQuery__
 *
 * To run a query within a React component, call `useAllGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllGroupsQuery, AllGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllGroupsQuery, AllGroupsQueryVariables>(
    AllGroupsDocument,
    options
  );
}
export function useAllGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllGroupsQuery,
    AllGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllGroupsQuery, AllGroupsQueryVariables>(
    AllGroupsDocument,
    options
  );
}
export type AllGroupsQueryHookResult = ReturnType<typeof useAllGroupsQuery>;
export type AllGroupsLazyQueryHookResult = ReturnType<
  typeof useAllGroupsLazyQuery
>;
export type AllGroupsQueryResult = Apollo.QueryResult<
  AllGroupsQuery,
  AllGroupsQueryVariables
>;
export function refetchAllGroupsQuery(variables?: AllGroupsQueryVariables) {
  return { query: AllGroupsDocument, variables: variables };
}
export const GetRemainingUserQuotaDocument = gql`
  query getRemainingUserQuota {
    getRemainingUserQuota
  }
`;
export type GetRemainingUserQuotaProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetRemainingUserQuotaQuery,
    GetRemainingUserQuotaQueryVariables
  >;
} &
  TChildProps;
export function withGetRemainingUserQuota<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetRemainingUserQuotaQuery,
    GetRemainingUserQuotaQueryVariables,
    GetRemainingUserQuotaProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetRemainingUserQuotaQuery,
    GetRemainingUserQuotaQueryVariables,
    GetRemainingUserQuotaProps<TChildProps, TDataName>
  >(GetRemainingUserQuotaDocument, {
    alias: "getRemainingUserQuota",
    ...operationOptions,
  });
}

/**
 * __useGetRemainingUserQuotaQuery__
 *
 * To run a query within a React component, call `useGetRemainingUserQuotaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemainingUserQuotaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemainingUserQuotaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRemainingUserQuotaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRemainingUserQuotaQuery,
    GetRemainingUserQuotaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRemainingUserQuotaQuery,
    GetRemainingUserQuotaQueryVariables
  >(GetRemainingUserQuotaDocument, options);
}
export function useGetRemainingUserQuotaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRemainingUserQuotaQuery,
    GetRemainingUserQuotaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRemainingUserQuotaQuery,
    GetRemainingUserQuotaQueryVariables
  >(GetRemainingUserQuotaDocument, options);
}
export type GetRemainingUserQuotaQueryHookResult = ReturnType<
  typeof useGetRemainingUserQuotaQuery
>;
export type GetRemainingUserQuotaLazyQueryHookResult = ReturnType<
  typeof useGetRemainingUserQuotaLazyQuery
>;
export type GetRemainingUserQuotaQueryResult = Apollo.QueryResult<
  GetRemainingUserQuotaQuery,
  GetRemainingUserQuotaQueryVariables
>;
export function refetchGetRemainingUserQuotaQuery(
  variables?: GetRemainingUserQuotaQueryVariables
) {
  return { query: GetRemainingUserQuotaDocument, variables: variables };
}
export const PermissionsListDocument = gql`
  query permissionsList {
    permissionsList
  }
`;
export type PermissionsListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PermissionsListQuery,
    PermissionsListQueryVariables
  >;
} &
  TChildProps;
export function withPermissionsList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PermissionsListQuery,
    PermissionsListQueryVariables,
    PermissionsListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PermissionsListQuery,
    PermissionsListQueryVariables,
    PermissionsListProps<TChildProps, TDataName>
  >(PermissionsListDocument, {
    alias: "permissionsList",
    ...operationOptions,
  });
}

/**
 * __usePermissionsListQuery__
 *
 * To run a query within a React component, call `usePermissionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PermissionsListQuery,
    PermissionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PermissionsListQuery, PermissionsListQueryVariables>(
    PermissionsListDocument,
    options
  );
}
export function usePermissionsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PermissionsListQuery,
    PermissionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PermissionsListQuery,
    PermissionsListQueryVariables
  >(PermissionsListDocument, options);
}
export type PermissionsListQueryHookResult = ReturnType<
  typeof usePermissionsListQuery
>;
export type PermissionsListLazyQueryHookResult = ReturnType<
  typeof usePermissionsListLazyQuery
>;
export type PermissionsListQueryResult = Apollo.QueryResult<
  PermissionsListQuery,
  PermissionsListQueryVariables
>;
export function refetchPermissionsListQuery(
  variables?: PermissionsListQueryVariables
) {
  return { query: PermissionsListDocument, variables: variables };
}
export const AllCommonPermissionSetsDocument = gql`
  query allCommonPermissionSets {
    allCommonPermissionSets(filter: { isDefaultProvided: { equalTo: true } }) {
      nodes {
        __typename
        id
        name
        isDefaultProvided
      }
    }
  }
`;
export type AllCommonPermissionSetsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllCommonPermissionSetsQuery,
    AllCommonPermissionSetsQueryVariables
  >;
} &
  TChildProps;
export function withAllCommonPermissionSets<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCommonPermissionSetsQuery,
    AllCommonPermissionSetsQueryVariables,
    AllCommonPermissionSetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCommonPermissionSetsQuery,
    AllCommonPermissionSetsQueryVariables,
    AllCommonPermissionSetsProps<TChildProps, TDataName>
  >(AllCommonPermissionSetsDocument, {
    alias: "allCommonPermissionSets",
    ...operationOptions,
  });
}

/**
 * __useAllCommonPermissionSetsQuery__
 *
 * To run a query within a React component, call `useAllCommonPermissionSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommonPermissionSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommonPermissionSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCommonPermissionSetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCommonPermissionSetsQuery,
    AllCommonPermissionSetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCommonPermissionSetsQuery,
    AllCommonPermissionSetsQueryVariables
  >(AllCommonPermissionSetsDocument, options);
}
export function useAllCommonPermissionSetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCommonPermissionSetsQuery,
    AllCommonPermissionSetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCommonPermissionSetsQuery,
    AllCommonPermissionSetsQueryVariables
  >(AllCommonPermissionSetsDocument, options);
}
export type AllCommonPermissionSetsQueryHookResult = ReturnType<
  typeof useAllCommonPermissionSetsQuery
>;
export type AllCommonPermissionSetsLazyQueryHookResult = ReturnType<
  typeof useAllCommonPermissionSetsLazyQuery
>;
export type AllCommonPermissionSetsQueryResult = Apollo.QueryResult<
  AllCommonPermissionSetsQuery,
  AllCommonPermissionSetsQueryVariables
>;
export function refetchAllCommonPermissionSetsQuery(
  variables?: AllCommonPermissionSetsQueryVariables
) {
  return { query: AllCommonPermissionSetsDocument, variables: variables };
}
export const AllPermissionSetsDocument = gql`
  query allPermissionSets {
    allPermissionSets(orderBy: [NAME_ASC]) {
      nodes {
        __typename
        id
        name
        commonPermissionSetId
        description
        color
        permissions
      }
    }
  }
`;
export type AllPermissionSetsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllPermissionSetsQuery,
    AllPermissionSetsQueryVariables
  >;
} &
  TChildProps;
export function withAllPermissionSets<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPermissionSetsQuery,
    AllPermissionSetsQueryVariables,
    AllPermissionSetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPermissionSetsQuery,
    AllPermissionSetsQueryVariables,
    AllPermissionSetsProps<TChildProps, TDataName>
  >(AllPermissionSetsDocument, {
    alias: "allPermissionSets",
    ...operationOptions,
  });
}

/**
 * __useAllPermissionSetsQuery__
 *
 * To run a query within a React component, call `useAllPermissionSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPermissionSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPermissionSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPermissionSetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllPermissionSetsQuery,
    AllPermissionSetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllPermissionSetsQuery,
    AllPermissionSetsQueryVariables
  >(AllPermissionSetsDocument, options);
}
export function useAllPermissionSetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPermissionSetsQuery,
    AllPermissionSetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPermissionSetsQuery,
    AllPermissionSetsQueryVariables
  >(AllPermissionSetsDocument, options);
}
export type AllPermissionSetsQueryHookResult = ReturnType<
  typeof useAllPermissionSetsQuery
>;
export type AllPermissionSetsLazyQueryHookResult = ReturnType<
  typeof useAllPermissionSetsLazyQuery
>;
export type AllPermissionSetsQueryResult = Apollo.QueryResult<
  AllPermissionSetsQuery,
  AllPermissionSetsQueryVariables
>;
export function refetchAllPermissionSetsQuery(
  variables?: AllPermissionSetsQueryVariables
) {
  return { query: AllPermissionSetsDocument, variables: variables };
}
export const CreateFontFromCommonDocument = gql`
  mutation createFontFromCommon($input: CreateFontFromCommonInput!) {
    createFontFromCommon(input: $input) {
      font {
        __typename
        id
        name
      }
    }
  }
`;
export type CreateFontFromCommonMutationFn = Apollo.MutationFunction<
  CreateFontFromCommonMutation,
  CreateFontFromCommonMutationVariables
>;
export type CreateFontFromCommonProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateFontFromCommonMutation,
    CreateFontFromCommonMutationVariables
  >;
} &
  TChildProps;
export function withCreateFontFromCommon<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateFontFromCommonMutation,
    CreateFontFromCommonMutationVariables,
    CreateFontFromCommonProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateFontFromCommonMutation,
    CreateFontFromCommonMutationVariables,
    CreateFontFromCommonProps<TChildProps, TDataName>
  >(CreateFontFromCommonDocument, {
    alias: "createFontFromCommon",
    ...operationOptions,
  });
}

/**
 * __useCreateFontFromCommonMutation__
 *
 * To run a mutation, you first call `useCreateFontFromCommonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFontFromCommonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFontFromCommonMutation, { data, loading, error }] = useCreateFontFromCommonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFontFromCommonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFontFromCommonMutation,
    CreateFontFromCommonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFontFromCommonMutation,
    CreateFontFromCommonMutationVariables
  >(CreateFontFromCommonDocument, options);
}
export type CreateFontFromCommonMutationHookResult = ReturnType<
  typeof useCreateFontFromCommonMutation
>;
export type CreateFontFromCommonMutationResult = Apollo.MutationResult<CreateFontFromCommonMutation>;
export type CreateFontFromCommonMutationOptions = Apollo.BaseMutationOptions<
  CreateFontFromCommonMutation,
  CreateFontFromCommonMutationVariables
>;
export const CreateThemeDocument = gql`
  mutation CreateTheme($input: CreateThemeInput!) {
    createTheme(input: $input) {
      theme {
        ...Theme
      }
    }
  }
  ${ThemeFragmentDoc}
`;
export type CreateThemeMutationFn = Apollo.MutationFunction<
  CreateThemeMutation,
  CreateThemeMutationVariables
>;
export type CreateThemeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateThemeMutation,
    CreateThemeMutationVariables
  >;
} &
  TChildProps;
export function withCreateTheme<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateThemeMutation,
    CreateThemeMutationVariables,
    CreateThemeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateThemeMutation,
    CreateThemeMutationVariables,
    CreateThemeProps<TChildProps, TDataName>
  >(CreateThemeDocument, {
    alias: "createTheme",
    ...operationOptions,
  });
}

/**
 * __useCreateThemeMutation__
 *
 * To run a mutation, you first call `useCreateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThemeMutation, { data, loading, error }] = useCreateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateThemeMutation,
    CreateThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateThemeMutation, CreateThemeMutationVariables>(
    CreateThemeDocument,
    options
  );
}
export type CreateThemeMutationHookResult = ReturnType<
  typeof useCreateThemeMutation
>;
export type CreateThemeMutationResult = Apollo.MutationResult<CreateThemeMutation>;
export type CreateThemeMutationOptions = Apollo.BaseMutationOptions<
  CreateThemeMutation,
  CreateThemeMutationVariables
>;
export const RemoveThemeLogoDocument = gql`
  mutation RemoveThemeLogo($input: RemoveThemeLogoInput!) {
    removeThemeLogo(input: $input) {
      theme {
        ...Theme
      }
    }
  }
  ${ThemeFragmentDoc}
`;
export type RemoveThemeLogoMutationFn = Apollo.MutationFunction<
  RemoveThemeLogoMutation,
  RemoveThemeLogoMutationVariables
>;
export type RemoveThemeLogoProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RemoveThemeLogoMutation,
    RemoveThemeLogoMutationVariables
  >;
} &
  TChildProps;
export function withRemoveThemeLogo<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveThemeLogoMutation,
    RemoveThemeLogoMutationVariables,
    RemoveThemeLogoProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveThemeLogoMutation,
    RemoveThemeLogoMutationVariables,
    RemoveThemeLogoProps<TChildProps, TDataName>
  >(RemoveThemeLogoDocument, {
    alias: "removeThemeLogo",
    ...operationOptions,
  });
}

/**
 * __useRemoveThemeLogoMutation__
 *
 * To run a mutation, you first call `useRemoveThemeLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveThemeLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeThemeLogoMutation, { data, loading, error }] = useRemoveThemeLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveThemeLogoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveThemeLogoMutation,
    RemoveThemeLogoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveThemeLogoMutation,
    RemoveThemeLogoMutationVariables
  >(RemoveThemeLogoDocument, options);
}
export type RemoveThemeLogoMutationHookResult = ReturnType<
  typeof useRemoveThemeLogoMutation
>;
export type RemoveThemeLogoMutationResult = Apollo.MutationResult<RemoveThemeLogoMutation>;
export type RemoveThemeLogoMutationOptions = Apollo.BaseMutationOptions<
  RemoveThemeLogoMutation,
  RemoveThemeLogoMutationVariables
>;
export const UpdateThemeDocument = gql`
  mutation UpdateTheme($input: UpdateThemeInput!) {
    updateTheme(input: $input) {
      theme {
        ...Theme
      }
    }
  }
  ${ThemeFragmentDoc}
`;
export type UpdateThemeMutationFn = Apollo.MutationFunction<
  UpdateThemeMutation,
  UpdateThemeMutationVariables
>;
export type UpdateThemeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateThemeMutation,
    UpdateThemeMutationVariables
  >;
} &
  TChildProps;
export function withUpdateTheme<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateThemeMutation,
    UpdateThemeMutationVariables,
    UpdateThemeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateThemeMutation,
    UpdateThemeMutationVariables,
    UpdateThemeProps<TChildProps, TDataName>
  >(UpdateThemeDocument, {
    alias: "updateTheme",
    ...operationOptions,
  });
}

/**
 * __useUpdateThemeMutation__
 *
 * To run a mutation, you first call `useUpdateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThemeMutation, { data, loading, error }] = useUpdateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThemeMutation,
    UpdateThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateThemeMutation, UpdateThemeMutationVariables>(
    UpdateThemeDocument,
    options
  );
}
export type UpdateThemeMutationHookResult = ReturnType<
  typeof useUpdateThemeMutation
>;
export type UpdateThemeMutationResult = Apollo.MutationResult<UpdateThemeMutation>;
export type UpdateThemeMutationOptions = Apollo.BaseMutationOptions<
  UpdateThemeMutation,
  UpdateThemeMutationVariables
>;
export const AddUserToGroupsDocument = gql`
  mutation addUserToGroups($input: AddUserToGroupsInput!) {
    addUserToGroups(input: $input) {
      userGroups {
        ...UserGroup
      }
    }
  }
  ${UserGroupFragmentDoc}
`;
export type AddUserToGroupsMutationFn = Apollo.MutationFunction<
  AddUserToGroupsMutation,
  AddUserToGroupsMutationVariables
>;
export type AddUserToGroupsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AddUserToGroupsMutation,
    AddUserToGroupsMutationVariables
  >;
} &
  TChildProps;
export function withAddUserToGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddUserToGroupsMutation,
    AddUserToGroupsMutationVariables,
    AddUserToGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddUserToGroupsMutation,
    AddUserToGroupsMutationVariables,
    AddUserToGroupsProps<TChildProps, TDataName>
  >(AddUserToGroupsDocument, {
    alias: "addUserToGroups",
    ...operationOptions,
  });
}

/**
 * __useAddUserToGroupsMutation__
 *
 * To run a mutation, you first call `useAddUserToGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToGroupsMutation, { data, loading, error }] = useAddUserToGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserToGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToGroupsMutation,
    AddUserToGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserToGroupsMutation,
    AddUserToGroupsMutationVariables
  >(AddUserToGroupsDocument, options);
}
export type AddUserToGroupsMutationHookResult = ReturnType<
  typeof useAddUserToGroupsMutation
>;
export type AddUserToGroupsMutationResult = Apollo.MutationResult<AddUserToGroupsMutation>;
export type AddUserToGroupsMutationOptions = Apollo.BaseMutationOptions<
  AddUserToGroupsMutation,
  AddUserToGroupsMutationVariables
>;
export const DeleteUserFromGroupsDocument = gql`
  mutation deleteUserFromGroups($input: DeleteUserFromGroupsInput!) {
    deleteUserFromGroups(input: $input) {
      userGroups {
        ...UserGroup
      }
    }
  }
  ${UserGroupFragmentDoc}
`;
export type DeleteUserFromGroupsMutationFn = Apollo.MutationFunction<
  DeleteUserFromGroupsMutation,
  DeleteUserFromGroupsMutationVariables
>;
export type DeleteUserFromGroupsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserFromGroupsMutation,
    DeleteUserFromGroupsMutationVariables
  >;
} &
  TChildProps;
export function withDeleteUserFromGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserFromGroupsMutation,
    DeleteUserFromGroupsMutationVariables,
    DeleteUserFromGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserFromGroupsMutation,
    DeleteUserFromGroupsMutationVariables,
    DeleteUserFromGroupsProps<TChildProps, TDataName>
  >(DeleteUserFromGroupsDocument, {
    alias: "deleteUserFromGroups",
    ...operationOptions,
  });
}

/**
 * __useDeleteUserFromGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromGroupsMutation, { data, loading, error }] = useDeleteUserFromGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserFromGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserFromGroupsMutation,
    DeleteUserFromGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserFromGroupsMutation,
    DeleteUserFromGroupsMutationVariables
  >(DeleteUserFromGroupsDocument, options);
}
export type DeleteUserFromGroupsMutationHookResult = ReturnType<
  typeof useDeleteUserFromGroupsMutation
>;
export type DeleteUserFromGroupsMutationResult = Apollo.MutationResult<DeleteUserFromGroupsMutation>;
export type DeleteUserFromGroupsMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserFromGroupsMutation,
  DeleteUserFromGroupsMutationVariables
>;
export const UserByIdDocument = gql`
  query userById($id: UUID!) {
    userById(id: $id) {
      ...User
      userGroupsByUserId {
        nodes {
          ...UserGroup
          groupByGroupId {
            ...Group
            groupSpacesByGroupId {
              __typename
              nodes {
                ...GroupSpace
                spaceBySpaceId {
                  ...Space
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${UserGroupFragmentDoc}
  ${GroupFragmentDoc}
  ${GroupSpaceFragmentDoc}
  ${SpaceFragmentDoc}
`;
export type UserByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserByIdQuery,
    UserByIdQueryVariables
  >;
} &
  TChildProps;
export function withUserById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserByIdQuery,
    UserByIdQueryVariables,
    UserByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserByIdQuery,
    UserByIdQueryVariables,
    UserByIdProps<TChildProps, TDataName>
  >(UserByIdDocument, {
    alias: "userById",
    ...operationOptions,
  });
}

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export function useUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserByIdQuery,
    UserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<
  typeof useUserByIdLazyQuery
>;
export type UserByIdQueryResult = Apollo.QueryResult<
  UserByIdQuery,
  UserByIdQueryVariables
>;
export function refetchUserByIdQuery(variables?: UserByIdQueryVariables) {
  return { query: UserByIdDocument, variables: variables };
}
export const AppInstanceByIdDocument = gql`
  query appInstanceById($id: UUID!) {
    appInstanceById(id: $id) {
      ...AppInstance
      isTemplate
      targetCommonAppInstanceTemplateId
      fileByThumbnailFileId {
        ...File
      }
      filesByAppInstanceId {
        nodes {
          ...File
        }
      }
      castedScreenByAppInstanceId {
        nodes {
          ...Screen
        }
        totalCount
      }
      appInstallByAppInstallId {
        ...AppInstall
      }
      appVersionByAppInstanceId {
        ...AppVersion
        appByAppId {
          __typename
          id
          name
          iconUrl
        }
      }
      sharedSpacesByAppInstanceId {
        nodes {
          __typename
          id
          name
        }
      }
    }
  }
  ${AppInstanceFragmentDoc}
  ${FileFragmentDoc}
  ${ScreenFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
`;
export type AppInstanceByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppInstanceByIdQuery,
    AppInstanceByIdQueryVariables
  >;
} &
  TChildProps;
export function withAppInstanceById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppInstanceByIdQuery,
    AppInstanceByIdQueryVariables,
    AppInstanceByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppInstanceByIdQuery,
    AppInstanceByIdQueryVariables,
    AppInstanceByIdProps<TChildProps, TDataName>
  >(AppInstanceByIdDocument, {
    alias: "appInstanceById",
    ...operationOptions,
  });
}

/**
 * __useAppInstanceByIdQuery__
 *
 * To run a query within a React component, call `useAppInstanceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppInstanceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppInstanceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppInstanceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppInstanceByIdQuery,
    AppInstanceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppInstanceByIdQuery, AppInstanceByIdQueryVariables>(
    AppInstanceByIdDocument,
    options
  );
}
export function useAppInstanceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppInstanceByIdQuery,
    AppInstanceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppInstanceByIdQuery,
    AppInstanceByIdQueryVariables
  >(AppInstanceByIdDocument, options);
}
export type AppInstanceByIdQueryHookResult = ReturnType<
  typeof useAppInstanceByIdQuery
>;
export type AppInstanceByIdLazyQueryHookResult = ReturnType<
  typeof useAppInstanceByIdLazyQuery
>;
export type AppInstanceByIdQueryResult = Apollo.QueryResult<
  AppInstanceByIdQuery,
  AppInstanceByIdQueryVariables
>;
export function refetchAppInstanceByIdQuery(
  variables?: AppInstanceByIdQueryVariables
) {
  return { query: AppInstanceByIdDocument, variables: variables };
}
export const AppInstanceByIdAssociationsDocument = gql`
  query appInstanceByIdAssociations($id: UUID!) {
    appInstanceById(id: $id) {
      associationsByToAppInstanceAndOrgId {
        nodes {
          ...Associations
        }
        totalCount
      }
    }
  }
  ${AssociationsFragmentDoc}
`;
export type AppInstanceByIdAssociationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppInstanceByIdAssociationsQuery,
    AppInstanceByIdAssociationsQueryVariables
  >;
} &
  TChildProps;
export function withAppInstanceByIdAssociations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppInstanceByIdAssociationsQuery,
    AppInstanceByIdAssociationsQueryVariables,
    AppInstanceByIdAssociationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppInstanceByIdAssociationsQuery,
    AppInstanceByIdAssociationsQueryVariables,
    AppInstanceByIdAssociationsProps<TChildProps, TDataName>
  >(AppInstanceByIdAssociationsDocument, {
    alias: "appInstanceByIdAssociations",
    ...operationOptions,
  });
}

/**
 * __useAppInstanceByIdAssociationsQuery__
 *
 * To run a query within a React component, call `useAppInstanceByIdAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppInstanceByIdAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppInstanceByIdAssociationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppInstanceByIdAssociationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppInstanceByIdAssociationsQuery,
    AppInstanceByIdAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppInstanceByIdAssociationsQuery,
    AppInstanceByIdAssociationsQueryVariables
  >(AppInstanceByIdAssociationsDocument, options);
}
export function useAppInstanceByIdAssociationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppInstanceByIdAssociationsQuery,
    AppInstanceByIdAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppInstanceByIdAssociationsQuery,
    AppInstanceByIdAssociationsQueryVariables
  >(AppInstanceByIdAssociationsDocument, options);
}
export type AppInstanceByIdAssociationsQueryHookResult = ReturnType<
  typeof useAppInstanceByIdAssociationsQuery
>;
export type AppInstanceByIdAssociationsLazyQueryHookResult = ReturnType<
  typeof useAppInstanceByIdAssociationsLazyQuery
>;
export type AppInstanceByIdAssociationsQueryResult = Apollo.QueryResult<
  AppInstanceByIdAssociationsQuery,
  AppInstanceByIdAssociationsQueryVariables
>;
export function refetchAppInstanceByIdAssociationsQuery(
  variables?: AppInstanceByIdAssociationsQueryVariables
) {
  return { query: AppInstanceByIdAssociationsDocument, variables: variables };
}
export const AppInstanceByIdExtendedAssociationDocument = gql`
  query appInstanceByIdExtendedAssociation($id: UUID!, $spaceId: UUID!) {
    appInstanceById(id: $id) {
      ...AppInstance
      associationsByAppInstanceIdAndSpaceId(spaceId: $spaceId) {
        nodes {
          ...PlaylistAndChannelAssociations
        }
      }
      isTemplate
      targetCommonAppInstanceTemplateId
      fileByThumbnailFileId {
        ...File
      }
      filesByAppInstanceId {
        nodes {
          ...File
        }
      }
      castedScreenByAppInstanceId {
        nodes {
          ...Screen
        }
        totalCount
      }
      appInstallByAppInstallId {
        ...AppInstall
      }
      appVersionByAppInstanceId {
        ...AppVersion
        appByAppId {
          __typename
          id
          name
          iconUrl
        }
      }
      sharedSpacesByAppInstanceId {
        nodes {
          __typename
          id
          name
        }
      }
    }
  }
  ${AppInstanceFragmentDoc}
  ${PlaylistAndChannelAssociationsFragmentDoc}
  ${FileFragmentDoc}
  ${ScreenFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
`;
export type AppInstanceByIdExtendedAssociationProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppInstanceByIdExtendedAssociationQuery,
    AppInstanceByIdExtendedAssociationQueryVariables
  >;
} &
  TChildProps;
export function withAppInstanceByIdExtendedAssociation<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppInstanceByIdExtendedAssociationQuery,
    AppInstanceByIdExtendedAssociationQueryVariables,
    AppInstanceByIdExtendedAssociationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppInstanceByIdExtendedAssociationQuery,
    AppInstanceByIdExtendedAssociationQueryVariables,
    AppInstanceByIdExtendedAssociationProps<TChildProps, TDataName>
  >(AppInstanceByIdExtendedAssociationDocument, {
    alias: "appInstanceByIdExtendedAssociation",
    ...operationOptions,
  });
}

/**
 * __useAppInstanceByIdExtendedAssociationQuery__
 *
 * To run a query within a React component, call `useAppInstanceByIdExtendedAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppInstanceByIdExtendedAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppInstanceByIdExtendedAssociationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAppInstanceByIdExtendedAssociationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppInstanceByIdExtendedAssociationQuery,
    AppInstanceByIdExtendedAssociationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppInstanceByIdExtendedAssociationQuery,
    AppInstanceByIdExtendedAssociationQueryVariables
  >(AppInstanceByIdExtendedAssociationDocument, options);
}
export function useAppInstanceByIdExtendedAssociationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppInstanceByIdExtendedAssociationQuery,
    AppInstanceByIdExtendedAssociationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppInstanceByIdExtendedAssociationQuery,
    AppInstanceByIdExtendedAssociationQueryVariables
  >(AppInstanceByIdExtendedAssociationDocument, options);
}
export type AppInstanceByIdExtendedAssociationQueryHookResult = ReturnType<
  typeof useAppInstanceByIdExtendedAssociationQuery
>;
export type AppInstanceByIdExtendedAssociationLazyQueryHookResult = ReturnType<
  typeof useAppInstanceByIdExtendedAssociationLazyQuery
>;
export type AppInstanceByIdExtendedAssociationQueryResult = Apollo.QueryResult<
  AppInstanceByIdExtendedAssociationQuery,
  AppInstanceByIdExtendedAssociationQueryVariables
>;
export function refetchAppInstanceByIdExtendedAssociationQuery(
  variables?: AppInstanceByIdExtendedAssociationQueryVariables
) {
  return {
    query: AppInstanceByIdExtendedAssociationDocument,
    variables: variables,
  };
}
export const AllAppInstallsInOrgDocument = gql`
  query allAppInstallsInOrg($orgId: UUID!) {
    orgById(id: $orgId) {
      __typename
      id
      appInstallsByOrgId {
        nodes {
          ...AvailableAppInstallsWithAppInstancesByAppInstallId
        }
        totalCount
      }
    }
  }
  ${AvailableAppInstallsWithAppInstancesByAppInstallIdFragmentDoc}
`;
export type AllAppInstallsInOrgProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllAppInstallsInOrgQuery,
    AllAppInstallsInOrgQueryVariables
  >;
} &
  TChildProps;
export function withAllAppInstallsInOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllAppInstallsInOrgQuery,
    AllAppInstallsInOrgQueryVariables,
    AllAppInstallsInOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllAppInstallsInOrgQuery,
    AllAppInstallsInOrgQueryVariables,
    AllAppInstallsInOrgProps<TChildProps, TDataName>
  >(AllAppInstallsInOrgDocument, {
    alias: "allAppInstallsInOrg",
    ...operationOptions,
  });
}

/**
 * __useAllAppInstallsInOrgQuery__
 *
 * To run a query within a React component, call `useAllAppInstallsInOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppInstallsInOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppInstallsInOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useAllAppInstallsInOrgQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllAppInstallsInOrgQuery,
    AllAppInstallsInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllAppInstallsInOrgQuery,
    AllAppInstallsInOrgQueryVariables
  >(AllAppInstallsInOrgDocument, options);
}
export function useAllAppInstallsInOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAppInstallsInOrgQuery,
    AllAppInstallsInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllAppInstallsInOrgQuery,
    AllAppInstallsInOrgQueryVariables
  >(AllAppInstallsInOrgDocument, options);
}
export type AllAppInstallsInOrgQueryHookResult = ReturnType<
  typeof useAllAppInstallsInOrgQuery
>;
export type AllAppInstallsInOrgLazyQueryHookResult = ReturnType<
  typeof useAllAppInstallsInOrgLazyQuery
>;
export type AllAppInstallsInOrgQueryResult = Apollo.QueryResult<
  AllAppInstallsInOrgQuery,
  AllAppInstallsInOrgQueryVariables
>;
export function refetchAllAppInstallsInOrgQuery(
  variables?: AllAppInstallsInOrgQueryVariables
) {
  return { query: AllAppInstallsInOrgDocument, variables: variables };
}
export const AllAppInstancesInOrgDocument = gql`
  query allAppInstancesInOrg(
    $orgId: UUID!
    $appId: UUID!
    $first: Int
    $endCursor: Cursor
  ) {
    orgById(id: $orgId) {
      __typename
      id
      appInstancesByOrgId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        condition: { isTemplate: false }
        filter: { appId: { equalTo: $appId } }
      ) {
        nodes {
          ...AvailableAppInstance
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type AllAppInstancesInOrgProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllAppInstancesInOrgQuery,
    AllAppInstancesInOrgQueryVariables
  >;
} &
  TChildProps;
export function withAllAppInstancesInOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllAppInstancesInOrgQuery,
    AllAppInstancesInOrgQueryVariables,
    AllAppInstancesInOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllAppInstancesInOrgQuery,
    AllAppInstancesInOrgQueryVariables,
    AllAppInstancesInOrgProps<TChildProps, TDataName>
  >(AllAppInstancesInOrgDocument, {
    alias: "allAppInstancesInOrg",
    ...operationOptions,
  });
}

/**
 * __useAllAppInstancesInOrgQuery__
 *
 * To run a query within a React component, call `useAllAppInstancesInOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppInstancesInOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppInstancesInOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      appId: // value for 'appId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useAllAppInstancesInOrgQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllAppInstancesInOrgQuery,
    AllAppInstancesInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllAppInstancesInOrgQuery,
    AllAppInstancesInOrgQueryVariables
  >(AllAppInstancesInOrgDocument, options);
}
export function useAllAppInstancesInOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAppInstancesInOrgQuery,
    AllAppInstancesInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllAppInstancesInOrgQuery,
    AllAppInstancesInOrgQueryVariables
  >(AllAppInstancesInOrgDocument, options);
}
export type AllAppInstancesInOrgQueryHookResult = ReturnType<
  typeof useAllAppInstancesInOrgQuery
>;
export type AllAppInstancesInOrgLazyQueryHookResult = ReturnType<
  typeof useAllAppInstancesInOrgLazyQuery
>;
export type AllAppInstancesInOrgQueryResult = Apollo.QueryResult<
  AllAppInstancesInOrgQuery,
  AllAppInstancesInOrgQueryVariables
>;
export function refetchAllAppInstancesInOrgQuery(
  variables?: AllAppInstancesInOrgQueryVariables
) {
  return { query: AllAppInstancesInOrgDocument, variables: variables };
}
export const AppInstancePickerDocument = gql`
  query appInstancePicker(
    $orgId: UUID!
    $appId: UUID!
    $spaceId: UUID
    $first: Int
    $endCursor: Cursor
  ) {
    orgById(id: $orgId) {
      __typename
      id
      appInstancesByOrgId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        condition: { isTemplate: false }
        filter: { spaceId: { equalTo: $spaceId }, appId: { equalTo: $appId } }
      ) {
        nodes {
          ...AvailableAppInstance
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type AppInstancePickerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppInstancePickerQuery,
    AppInstancePickerQueryVariables
  >;
} &
  TChildProps;
export function withAppInstancePicker<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppInstancePickerQuery,
    AppInstancePickerQueryVariables,
    AppInstancePickerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppInstancePickerQuery,
    AppInstancePickerQueryVariables,
    AppInstancePickerProps<TChildProps, TDataName>
  >(AppInstancePickerDocument, {
    alias: "appInstancePicker",
    ...operationOptions,
  });
}

/**
 * __useAppInstancePickerQuery__
 *
 * To run a query within a React component, call `useAppInstancePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppInstancePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppInstancePickerQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useAppInstancePickerQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppInstancePickerQuery,
    AppInstancePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppInstancePickerQuery,
    AppInstancePickerQueryVariables
  >(AppInstancePickerDocument, options);
}
export function useAppInstancePickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppInstancePickerQuery,
    AppInstancePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppInstancePickerQuery,
    AppInstancePickerQueryVariables
  >(AppInstancePickerDocument, options);
}
export type AppInstancePickerQueryHookResult = ReturnType<
  typeof useAppInstancePickerQuery
>;
export type AppInstancePickerLazyQueryHookResult = ReturnType<
  typeof useAppInstancePickerLazyQuery
>;
export type AppInstancePickerQueryResult = Apollo.QueryResult<
  AppInstancePickerQuery,
  AppInstancePickerQueryVariables
>;
export function refetchAppInstancePickerQuery(
  variables?: AppInstancePickerQueryVariables
) {
  return { query: AppInstancePickerDocument, variables: variables };
}
export const SearchAppInstancePickerDocument = gql`
  query searchAppInstancePicker(
    $query: String
    $first: Int
    $endCursor: Cursor
    $spaceId: UUID
    $filter: AppInstanceFilter
  ) {
    searchAppInstance(
      query: $query
      first: $first
      after: $endCursor
      orderBy: [NAME_ASC, ID_DESC]
      spaceId: $spaceId
      filter: $filter
    ) {
      nodes {
        ...AvailableAppInstance
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type SearchAppInstancePickerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchAppInstancePickerQuery,
    SearchAppInstancePickerQueryVariables
  >;
} &
  TChildProps;
export function withSearchAppInstancePicker<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchAppInstancePickerQuery,
    SearchAppInstancePickerQueryVariables,
    SearchAppInstancePickerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchAppInstancePickerQuery,
    SearchAppInstancePickerQueryVariables,
    SearchAppInstancePickerProps<TChildProps, TDataName>
  >(SearchAppInstancePickerDocument, {
    alias: "searchAppInstancePicker",
    ...operationOptions,
  });
}

/**
 * __useSearchAppInstancePickerQuery__
 *
 * To run a query within a React component, call `useSearchAppInstancePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAppInstancePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAppInstancePickerQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *      spaceId: // value for 'spaceId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchAppInstancePickerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchAppInstancePickerQuery,
    SearchAppInstancePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAppInstancePickerQuery,
    SearchAppInstancePickerQueryVariables
  >(SearchAppInstancePickerDocument, options);
}
export function useSearchAppInstancePickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAppInstancePickerQuery,
    SearchAppInstancePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAppInstancePickerQuery,
    SearchAppInstancePickerQueryVariables
  >(SearchAppInstancePickerDocument, options);
}
export type SearchAppInstancePickerQueryHookResult = ReturnType<
  typeof useSearchAppInstancePickerQuery
>;
export type SearchAppInstancePickerLazyQueryHookResult = ReturnType<
  typeof useSearchAppInstancePickerLazyQuery
>;
export type SearchAppInstancePickerQueryResult = Apollo.QueryResult<
  SearchAppInstancePickerQuery,
  SearchAppInstancePickerQueryVariables
>;
export function refetchSearchAppInstancePickerQuery(
  variables?: SearchAppInstancePickerQueryVariables
) {
  return { query: SearchAppInstancePickerDocument, variables: variables };
}
export const SearchAppInstancePickerByAppIdDocument = gql`
  query searchAppInstancePickerByAppId(
    $query: String
    $first: Int
    $endCursor: Cursor
    $appId: UUID!
    $spaceId: UUID
  ) {
    searchAppInstanceSpecificAppId(
      query: $query
      first: $first
      after: $endCursor
      orderBy: [NAME_ASC, ID_DESC]
      appId: $appId
      spaceId: $spaceId
    ) {
      nodes {
        ...AvailableAppInstance
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type SearchAppInstancePickerByAppIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchAppInstancePickerByAppIdQuery,
    SearchAppInstancePickerByAppIdQueryVariables
  >;
} &
  TChildProps;
export function withSearchAppInstancePickerByAppId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchAppInstancePickerByAppIdQuery,
    SearchAppInstancePickerByAppIdQueryVariables,
    SearchAppInstancePickerByAppIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchAppInstancePickerByAppIdQuery,
    SearchAppInstancePickerByAppIdQueryVariables,
    SearchAppInstancePickerByAppIdProps<TChildProps, TDataName>
  >(SearchAppInstancePickerByAppIdDocument, {
    alias: "searchAppInstancePickerByAppId",
    ...operationOptions,
  });
}

/**
 * __useSearchAppInstancePickerByAppIdQuery__
 *
 * To run a query within a React component, call `useSearchAppInstancePickerByAppIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAppInstancePickerByAppIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAppInstancePickerByAppIdQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useSearchAppInstancePickerByAppIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAppInstancePickerByAppIdQuery,
    SearchAppInstancePickerByAppIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAppInstancePickerByAppIdQuery,
    SearchAppInstancePickerByAppIdQueryVariables
  >(SearchAppInstancePickerByAppIdDocument, options);
}
export function useSearchAppInstancePickerByAppIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAppInstancePickerByAppIdQuery,
    SearchAppInstancePickerByAppIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAppInstancePickerByAppIdQuery,
    SearchAppInstancePickerByAppIdQueryVariables
  >(SearchAppInstancePickerByAppIdDocument, options);
}
export type SearchAppInstancePickerByAppIdQueryHookResult = ReturnType<
  typeof useSearchAppInstancePickerByAppIdQuery
>;
export type SearchAppInstancePickerByAppIdLazyQueryHookResult = ReturnType<
  typeof useSearchAppInstancePickerByAppIdLazyQuery
>;
export type SearchAppInstancePickerByAppIdQueryResult = Apollo.QueryResult<
  SearchAppInstancePickerByAppIdQuery,
  SearchAppInstancePickerByAppIdQueryVariables
>;
export function refetchSearchAppInstancePickerByAppIdQuery(
  variables?: SearchAppInstancePickerByAppIdQueryVariables
) {
  return {
    query: SearchAppInstancePickerByAppIdDocument,
    variables: variables,
  };
}
export const CastsBySpaceIdDocument = gql`
  query castsBySpaceId($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      castsBySpace {
        nodes {
          ...Cast
          channelByCastId {
            ...ChannelListItem
          }
          fileByCastId {
            ...FileListItem
          }
          appInstanceByCastId {
            __typename
            id
            name
            createdAt
            fileByThumbnailFileId {
              ...FileListItem
            }
            appVersionByAppInstanceId {
              __typename
              id
              appByAppId {
                __typename
                id
                name
                iconUrl
              }
            }
          }
          playlistByCastId {
            __typename
            id
            name
            color
            createdAt
          }
          linkByCastId {
            __typename
            id
            name
            createdAt
            fileByFileId {
              ...FileListItem
            }
          }
          siteByCastId {
            __typename
            id
            name
            createdAt
            fileByThumbnailId {
              ...FileListItem
            }
          }
          screensByCastId {
            nodes {
              __typename
              id
              name
              device
              createdAt
              castId
            }
            totalCount
          }
        }
      }
    }
  }
  ${CastFragmentDoc}
  ${ChannelListItemFragmentDoc}
  ${FileListItemFragmentDoc}
`;
export type CastsBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CastsBySpaceIdQuery,
    CastsBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withCastsBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CastsBySpaceIdQuery,
    CastsBySpaceIdQueryVariables,
    CastsBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CastsBySpaceIdQuery,
    CastsBySpaceIdQueryVariables,
    CastsBySpaceIdProps<TChildProps, TDataName>
  >(CastsBySpaceIdDocument, {
    alias: "castsBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useCastsBySpaceIdQuery__
 *
 * To run a query within a React component, call `useCastsBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCastsBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCastsBySpaceIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useCastsBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CastsBySpaceIdQuery,
    CastsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CastsBySpaceIdQuery, CastsBySpaceIdQueryVariables>(
    CastsBySpaceIdDocument,
    options
  );
}
export function useCastsBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CastsBySpaceIdQuery,
    CastsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CastsBySpaceIdQuery, CastsBySpaceIdQueryVariables>(
    CastsBySpaceIdDocument,
    options
  );
}
export type CastsBySpaceIdQueryHookResult = ReturnType<
  typeof useCastsBySpaceIdQuery
>;
export type CastsBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useCastsBySpaceIdLazyQuery
>;
export type CastsBySpaceIdQueryResult = Apollo.QueryResult<
  CastsBySpaceIdQuery,
  CastsBySpaceIdQueryVariables
>;
export function refetchCastsBySpaceIdQuery(
  variables?: CastsBySpaceIdQueryVariables
) {
  return { query: CastsBySpaceIdDocument, variables: variables };
}
export const CastByIdDocument = gql`
  query castById($castId: UUID!) {
    castById(id: $castId) {
      screensByCastId {
        nodes {
          __typename
          id
          name
          tags
          status
          preview
          spaceId
          deviceModel
          devicePlatform
          deviceInfo
          playerTimezone
          playerHeight
          playerWidth
          timezoneOverride
          additionalPlayerSettings
          isNotificationActive
          castByCastId {
            ...CastByCastId
          }
        }
      }
    }
  }
  ${CastByCastIdFragmentDoc}
`;
export type CastByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CastByIdQuery,
    CastByIdQueryVariables
  >;
} &
  TChildProps;
export function withCastById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CastByIdQuery,
    CastByIdQueryVariables,
    CastByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CastByIdQuery,
    CastByIdQueryVariables,
    CastByIdProps<TChildProps, TDataName>
  >(CastByIdDocument, {
    alias: "castById",
    ...operationOptions,
  });
}

/**
 * __useCastByIdQuery__
 *
 * To run a query within a React component, call `useCastByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCastByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCastByIdQuery({
 *   variables: {
 *      castId: // value for 'castId'
 *   },
 * });
 */
export function useCastByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CastByIdQuery, CastByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CastByIdQuery, CastByIdQueryVariables>(
    CastByIdDocument,
    options
  );
}
export function useCastByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CastByIdQuery,
    CastByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CastByIdQuery, CastByIdQueryVariables>(
    CastByIdDocument,
    options
  );
}
export type CastByIdQueryHookResult = ReturnType<typeof useCastByIdQuery>;
export type CastByIdLazyQueryHookResult = ReturnType<
  typeof useCastByIdLazyQuery
>;
export type CastByIdQueryResult = Apollo.QueryResult<
  CastByIdQuery,
  CastByIdQueryVariables
>;
export function refetchCastByIdQuery(variables?: CastByIdQueryVariables) {
  return { query: CastByIdDocument, variables: variables };
}
export const AllChannelsInOrgDocument = gql`
  query allChannelsInOrg($orgId: UUID!, $first: Int, $endCursor: Cursor) {
    orgById(id: $orgId) {
      __typename
      id
      name
      channelsByOrgId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        filter: {
          draftOf: { isNull: true }
          isPublished: { equalTo: true }
          isHidden: { equalTo: false }
          isEnabled: { equalTo: true }
        }
      ) {
        nodes {
          ...ChannelListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type AllChannelsInOrgProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllChannelsInOrgQuery,
    AllChannelsInOrgQueryVariables
  >;
} &
  TChildProps;
export function withAllChannelsInOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllChannelsInOrgQuery,
    AllChannelsInOrgQueryVariables,
    AllChannelsInOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllChannelsInOrgQuery,
    AllChannelsInOrgQueryVariables,
    AllChannelsInOrgProps<TChildProps, TDataName>
  >(AllChannelsInOrgDocument, {
    alias: "allChannelsInOrg",
    ...operationOptions,
  });
}

/**
 * __useAllChannelsInOrgQuery__
 *
 * To run a query within a React component, call `useAllChannelsInOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChannelsInOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChannelsInOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useAllChannelsInOrgQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllChannelsInOrgQuery,
    AllChannelsInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllChannelsInOrgQuery, AllChannelsInOrgQueryVariables>(
    AllChannelsInOrgDocument,
    options
  );
}
export function useAllChannelsInOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllChannelsInOrgQuery,
    AllChannelsInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllChannelsInOrgQuery,
    AllChannelsInOrgQueryVariables
  >(AllChannelsInOrgDocument, options);
}
export type AllChannelsInOrgQueryHookResult = ReturnType<
  typeof useAllChannelsInOrgQuery
>;
export type AllChannelsInOrgLazyQueryHookResult = ReturnType<
  typeof useAllChannelsInOrgLazyQuery
>;
export type AllChannelsInOrgQueryResult = Apollo.QueryResult<
  AllChannelsInOrgQuery,
  AllChannelsInOrgQueryVariables
>;
export function refetchAllChannelsInOrgQuery(
  variables?: AllChannelsInOrgQueryVariables
) {
  return { query: AllChannelsInOrgDocument, variables: variables };
}
export const SearchChannelPickerDocument = gql`
  query searchChannelPicker(
    $query: String
    $spaceId: UUID
    $first: Int
    $endCursor: Cursor
  ) {
    searchChannel(
      query: $query
      spaceId: $spaceId
      first: $first
      after: $endCursor
      orderBy: [NAME_ASC, ID_DESC]
      filter: { isEnabled: { equalTo: true } }
    ) {
      nodes {
        ...ChannelListItem
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type SearchChannelPickerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchChannelPickerQuery,
    SearchChannelPickerQueryVariables
  >;
} &
  TChildProps;
export function withSearchChannelPicker<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchChannelPickerQuery,
    SearchChannelPickerQueryVariables,
    SearchChannelPickerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchChannelPickerQuery,
    SearchChannelPickerQueryVariables,
    SearchChannelPickerProps<TChildProps, TDataName>
  >(SearchChannelPickerDocument, {
    alias: "searchChannelPicker",
    ...operationOptions,
  });
}

/**
 * __useSearchChannelPickerQuery__
 *
 * To run a query within a React component, call `useSearchChannelPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChannelPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChannelPickerQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useSearchChannelPickerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchChannelPickerQuery,
    SearchChannelPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchChannelPickerQuery,
    SearchChannelPickerQueryVariables
  >(SearchChannelPickerDocument, options);
}
export function useSearchChannelPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchChannelPickerQuery,
    SearchChannelPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchChannelPickerQuery,
    SearchChannelPickerQueryVariables
  >(SearchChannelPickerDocument, options);
}
export type SearchChannelPickerQueryHookResult = ReturnType<
  typeof useSearchChannelPickerQuery
>;
export type SearchChannelPickerLazyQueryHookResult = ReturnType<
  typeof useSearchChannelPickerLazyQuery
>;
export type SearchChannelPickerQueryResult = Apollo.QueryResult<
  SearchChannelPickerQuery,
  SearchChannelPickerQueryVariables
>;
export function refetchSearchChannelPickerQuery(
  variables?: SearchChannelPickerQueryVariables
) {
  return { query: SearchChannelPickerDocument, variables: variables };
}
export const ScreensByChannelIdDocument = gql`
  query screensByChannelId($id: UUID!, $endCursor: Cursor) {
    channelById(id: $id) {
      id
      name
      screensByChannelId(first: 5, after: $endCursor) {
        nodes {
          id
          name
          device
          castByCastId {
            id
            orgId
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
export type ScreensByChannelIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreensByChannelIdQuery,
    ScreensByChannelIdQueryVariables
  >;
} &
  TChildProps;
export function withScreensByChannelId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreensByChannelIdQuery,
    ScreensByChannelIdQueryVariables,
    ScreensByChannelIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreensByChannelIdQuery,
    ScreensByChannelIdQueryVariables,
    ScreensByChannelIdProps<TChildProps, TDataName>
  >(ScreensByChannelIdDocument, {
    alias: "screensByChannelId",
    ...operationOptions,
  });
}

/**
 * __useScreensByChannelIdQuery__
 *
 * To run a query within a React component, call `useScreensByChannelIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreensByChannelIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreensByChannelIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useScreensByChannelIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreensByChannelIdQuery,
    ScreensByChannelIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScreensByChannelIdQuery,
    ScreensByChannelIdQueryVariables
  >(ScreensByChannelIdDocument, options);
}
export function useScreensByChannelIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreensByChannelIdQuery,
    ScreensByChannelIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScreensByChannelIdQuery,
    ScreensByChannelIdQueryVariables
  >(ScreensByChannelIdDocument, options);
}
export type ScreensByChannelIdQueryHookResult = ReturnType<
  typeof useScreensByChannelIdQuery
>;
export type ScreensByChannelIdLazyQueryHookResult = ReturnType<
  typeof useScreensByChannelIdLazyQuery
>;
export type ScreensByChannelIdQueryResult = Apollo.QueryResult<
  ScreensByChannelIdQuery,
  ScreensByChannelIdQueryVariables
>;
export function refetchScreensByChannelIdQuery(
  variables?: ScreensByChannelIdQueryVariables
) {
  return { query: ScreensByChannelIdDocument, variables: variables };
}
export const AllCredentialsDocument = gql`
  query allCredentials {
    allCredentials {
      nodes {
        __typename
        id
        spaceId
        description
        provider
        username
        credentialKey
      }
    }
  }
`;
export type AllCredentialsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllCredentialsQuery,
    AllCredentialsQueryVariables
  >;
} &
  TChildProps;
export function withAllCredentials<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCredentialsQuery,
    AllCredentialsQueryVariables,
    AllCredentialsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCredentialsQuery,
    AllCredentialsQueryVariables,
    AllCredentialsProps<TChildProps, TDataName>
  >(AllCredentialsDocument, {
    alias: "allCredentials",
    ...operationOptions,
  });
}

/**
 * __useAllCredentialsQuery__
 *
 * To run a query within a React component, call `useAllCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCredentialsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCredentialsQuery,
    AllCredentialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllCredentialsQuery, AllCredentialsQueryVariables>(
    AllCredentialsDocument,
    options
  );
}
export function useAllCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCredentialsQuery,
    AllCredentialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllCredentialsQuery, AllCredentialsQueryVariables>(
    AllCredentialsDocument,
    options
  );
}
export type AllCredentialsQueryHookResult = ReturnType<
  typeof useAllCredentialsQuery
>;
export type AllCredentialsLazyQueryHookResult = ReturnType<
  typeof useAllCredentialsLazyQuery
>;
export type AllCredentialsQueryResult = Apollo.QueryResult<
  AllCredentialsQuery,
  AllCredentialsQueryVariables
>;
export function refetchAllCredentialsQuery(
  variables?: AllCredentialsQueryVariables
) {
  return { query: AllCredentialsDocument, variables: variables };
}
export const AllCredentialsBySpaceIdDocument = gql`
  query allCredentialsBySpaceId($spaceId: UUID!) {
    allCredentials(filter: { spaceId: { equalTo: $spaceId } }) {
      nodes {
        __typename
        id
        spaceId
        description
        provider
        username
        credentialKey
      }
    }
  }
`;
export type AllCredentialsBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllCredentialsBySpaceIdQuery,
    AllCredentialsBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withAllCredentialsBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCredentialsBySpaceIdQuery,
    AllCredentialsBySpaceIdQueryVariables,
    AllCredentialsBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCredentialsBySpaceIdQuery,
    AllCredentialsBySpaceIdQueryVariables,
    AllCredentialsBySpaceIdProps<TChildProps, TDataName>
  >(AllCredentialsBySpaceIdDocument, {
    alias: "allCredentialsBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useAllCredentialsBySpaceIdQuery__
 *
 * To run a query within a React component, call `useAllCredentialsBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCredentialsBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCredentialsBySpaceIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllCredentialsBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllCredentialsBySpaceIdQuery,
    AllCredentialsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCredentialsBySpaceIdQuery,
    AllCredentialsBySpaceIdQueryVariables
  >(AllCredentialsBySpaceIdDocument, options);
}
export function useAllCredentialsBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCredentialsBySpaceIdQuery,
    AllCredentialsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCredentialsBySpaceIdQuery,
    AllCredentialsBySpaceIdQueryVariables
  >(AllCredentialsBySpaceIdDocument, options);
}
export type AllCredentialsBySpaceIdQueryHookResult = ReturnType<
  typeof useAllCredentialsBySpaceIdQuery
>;
export type AllCredentialsBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useAllCredentialsBySpaceIdLazyQuery
>;
export type AllCredentialsBySpaceIdQueryResult = Apollo.QueryResult<
  AllCredentialsBySpaceIdQuery,
  AllCredentialsBySpaceIdQueryVariables
>;
export function refetchAllCredentialsBySpaceIdQuery(
  variables?: AllCredentialsBySpaceIdQueryVariables
) {
  return { query: AllCredentialsBySpaceIdDocument, variables: variables };
}
export const AllIdentityProvidersDocument = gql`
  query allIdentityProviders {
    allIdentityProviders {
      id
      name
      description
      type
      iconUrl
    }
  }
`;
export type AllIdentityProvidersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllIdentityProvidersQuery,
    AllIdentityProvidersQueryVariables
  >;
} &
  TChildProps;
export function withAllIdentityProviders<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllIdentityProvidersQuery,
    AllIdentityProvidersQueryVariables,
    AllIdentityProvidersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllIdentityProvidersQuery,
    AllIdentityProvidersQueryVariables,
    AllIdentityProvidersProps<TChildProps, TDataName>
  >(AllIdentityProvidersDocument, {
    alias: "allIdentityProviders",
    ...operationOptions,
  });
}

/**
 * __useAllIdentityProvidersQuery__
 *
 * To run a query within a React component, call `useAllIdentityProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIdentityProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIdentityProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllIdentityProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllIdentityProvidersQuery,
    AllIdentityProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllIdentityProvidersQuery,
    AllIdentityProvidersQueryVariables
  >(AllIdentityProvidersDocument, options);
}
export function useAllIdentityProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllIdentityProvidersQuery,
    AllIdentityProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllIdentityProvidersQuery,
    AllIdentityProvidersQueryVariables
  >(AllIdentityProvidersDocument, options);
}
export type AllIdentityProvidersQueryHookResult = ReturnType<
  typeof useAllIdentityProvidersQuery
>;
export type AllIdentityProvidersLazyQueryHookResult = ReturnType<
  typeof useAllIdentityProvidersLazyQuery
>;
export type AllIdentityProvidersQueryResult = Apollo.QueryResult<
  AllIdentityProvidersQuery,
  AllIdentityProvidersQueryVariables
>;
export function refetchAllIdentityProvidersQuery(
  variables?: AllIdentityProvidersQueryVariables
) {
  return { query: AllIdentityProvidersDocument, variables: variables };
}
export const CreateCredentialDocument = gql`
  mutation createCredential($input: CreateCredentialInput!) {
    createCredential(input: $input) {
      credential {
        id
        description
        provider
        username
        credentialKey
      }
    }
  }
`;
export type CreateCredentialMutationFn = Apollo.MutationFunction<
  CreateCredentialMutation,
  CreateCredentialMutationVariables
>;
export type CreateCredentialProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCredentialMutation,
    CreateCredentialMutationVariables
  >;
} &
  TChildProps;
export function withCreateCredential<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCredentialMutation,
    CreateCredentialMutationVariables,
    CreateCredentialProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCredentialMutation,
    CreateCredentialMutationVariables,
    CreateCredentialProps<TChildProps, TDataName>
  >(CreateCredentialDocument, {
    alias: "createCredential",
    ...operationOptions,
  });
}

/**
 * __useCreateCredentialMutation__
 *
 * To run a mutation, you first call `useCreateCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCredentialMutation, { data, loading, error }] = useCreateCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCredentialMutation,
    CreateCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCredentialMutation,
    CreateCredentialMutationVariables
  >(CreateCredentialDocument, options);
}
export type CreateCredentialMutationHookResult = ReturnType<
  typeof useCreateCredentialMutation
>;
export type CreateCredentialMutationResult = Apollo.MutationResult<CreateCredentialMutation>;
export type CreateCredentialMutationOptions = Apollo.BaseMutationOptions<
  CreateCredentialMutation,
  CreateCredentialMutationVariables
>;
export const CredentialsByKeyDocument = gql`
  query credentialsByKey($credentialKey: String!) {
    allCredentials(filter: { credentialKey: { equalTo: $credentialKey } }) {
      nodes {
        __typename
        id
        spaceId
        description
        provider
        username
        credentialKey
        isUsed
      }
    }
  }
`;
export type CredentialsByKeyProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CredentialsByKeyQuery,
    CredentialsByKeyQueryVariables
  >;
} &
  TChildProps;
export function withCredentialsByKey<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CredentialsByKeyQuery,
    CredentialsByKeyQueryVariables,
    CredentialsByKeyProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CredentialsByKeyQuery,
    CredentialsByKeyQueryVariables,
    CredentialsByKeyProps<TChildProps, TDataName>
  >(CredentialsByKeyDocument, {
    alias: "credentialsByKey",
    ...operationOptions,
  });
}

/**
 * __useCredentialsByKeyQuery__
 *
 * To run a query within a React component, call `useCredentialsByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCredentialsByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCredentialsByKeyQuery({
 *   variables: {
 *      credentialKey: // value for 'credentialKey'
 *   },
 * });
 */
export function useCredentialsByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CredentialsByKeyQuery,
    CredentialsByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CredentialsByKeyQuery, CredentialsByKeyQueryVariables>(
    CredentialsByKeyDocument,
    options
  );
}
export function useCredentialsByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CredentialsByKeyQuery,
    CredentialsByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CredentialsByKeyQuery,
    CredentialsByKeyQueryVariables
  >(CredentialsByKeyDocument, options);
}
export type CredentialsByKeyQueryHookResult = ReturnType<
  typeof useCredentialsByKeyQuery
>;
export type CredentialsByKeyLazyQueryHookResult = ReturnType<
  typeof useCredentialsByKeyLazyQuery
>;
export type CredentialsByKeyQueryResult = Apollo.QueryResult<
  CredentialsByKeyQuery,
  CredentialsByKeyQueryVariables
>;
export function refetchCredentialsByKeyQuery(
  variables?: CredentialsByKeyQueryVariables
) {
  return { query: CredentialsByKeyDocument, variables: variables };
}
export const DeleteCredentialDocument = gql`
  mutation deleteCredential($input: DeleteCredentialInput!) {
    deleteCredential(input: $input) {
      credential {
        id
        description
        provider
        username
        credentialKey
      }
    }
  }
`;
export type DeleteCredentialMutationFn = Apollo.MutationFunction<
  DeleteCredentialMutation,
  DeleteCredentialMutationVariables
>;
export type DeleteCredentialProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables
  >;
} &
  TChildProps;
export function withDeleteCredential<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables,
    DeleteCredentialProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables,
    DeleteCredentialProps<TChildProps, TDataName>
  >(DeleteCredentialDocument, {
    alias: "deleteCredential",
    ...operationOptions,
  });
}

/**
 * __useDeleteCredentialMutation__
 *
 * To run a mutation, you first call `useDeleteCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCredentialMutation, { data, loading, error }] = useDeleteCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCredentialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCredentialMutation,
    DeleteCredentialMutationVariables
  >(DeleteCredentialDocument, options);
}
export type DeleteCredentialMutationHookResult = ReturnType<
  typeof useDeleteCredentialMutation
>;
export type DeleteCredentialMutationResult = Apollo.MutationResult<DeleteCredentialMutation>;
export type DeleteCredentialMutationOptions = Apollo.BaseMutationOptions<
  DeleteCredentialMutation,
  DeleteCredentialMutationVariables
>;
export const DeleteCredentialByKeyDocument = gql`
  mutation deleteCredentialByKey($input: DeleteCredentialByKeyInput!) {
    deleteCredentialByKey(input: $input) {
      credential {
        credentialKey
      }
    }
  }
`;
export type DeleteCredentialByKeyMutationFn = Apollo.MutationFunction<
  DeleteCredentialByKeyMutation,
  DeleteCredentialByKeyMutationVariables
>;
export type DeleteCredentialByKeyProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteCredentialByKeyMutation,
    DeleteCredentialByKeyMutationVariables
  >;
} &
  TChildProps;
export function withDeleteCredentialByKey<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCredentialByKeyMutation,
    DeleteCredentialByKeyMutationVariables,
    DeleteCredentialByKeyProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCredentialByKeyMutation,
    DeleteCredentialByKeyMutationVariables,
    DeleteCredentialByKeyProps<TChildProps, TDataName>
  >(DeleteCredentialByKeyDocument, {
    alias: "deleteCredentialByKey",
    ...operationOptions,
  });
}

/**
 * __useDeleteCredentialByKeyMutation__
 *
 * To run a mutation, you first call `useDeleteCredentialByKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCredentialByKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCredentialByKeyMutation, { data, loading, error }] = useDeleteCredentialByKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCredentialByKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCredentialByKeyMutation,
    DeleteCredentialByKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCredentialByKeyMutation,
    DeleteCredentialByKeyMutationVariables
  >(DeleteCredentialByKeyDocument, options);
}
export type DeleteCredentialByKeyMutationHookResult = ReturnType<
  typeof useDeleteCredentialByKeyMutation
>;
export type DeleteCredentialByKeyMutationResult = Apollo.MutationResult<DeleteCredentialByKeyMutation>;
export type DeleteCredentialByKeyMutationOptions = Apollo.BaseMutationOptions<
  DeleteCredentialByKeyMutation,
  DeleteCredentialByKeyMutationVariables
>;
export const IdentityProviderLaunchUrlDocument = gql`
  query identityProviderLaunchUrl($input: IdentityProviderLaunchUrlInput!) {
    identityProviderLaunchUrl(input: $input)
  }
`;
export type IdentityProviderLaunchUrlProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    IdentityProviderLaunchUrlQuery,
    IdentityProviderLaunchUrlQueryVariables
  >;
} &
  TChildProps;
export function withIdentityProviderLaunchUrl<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IdentityProviderLaunchUrlQuery,
    IdentityProviderLaunchUrlQueryVariables,
    IdentityProviderLaunchUrlProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IdentityProviderLaunchUrlQuery,
    IdentityProviderLaunchUrlQueryVariables,
    IdentityProviderLaunchUrlProps<TChildProps, TDataName>
  >(IdentityProviderLaunchUrlDocument, {
    alias: "identityProviderLaunchUrl",
    ...operationOptions,
  });
}

/**
 * __useIdentityProviderLaunchUrlQuery__
 *
 * To run a query within a React component, call `useIdentityProviderLaunchUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityProviderLaunchUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityProviderLaunchUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIdentityProviderLaunchUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    IdentityProviderLaunchUrlQuery,
    IdentityProviderLaunchUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IdentityProviderLaunchUrlQuery,
    IdentityProviderLaunchUrlQueryVariables
  >(IdentityProviderLaunchUrlDocument, options);
}
export function useIdentityProviderLaunchUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IdentityProviderLaunchUrlQuery,
    IdentityProviderLaunchUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IdentityProviderLaunchUrlQuery,
    IdentityProviderLaunchUrlQueryVariables
  >(IdentityProviderLaunchUrlDocument, options);
}
export type IdentityProviderLaunchUrlQueryHookResult = ReturnType<
  typeof useIdentityProviderLaunchUrlQuery
>;
export type IdentityProviderLaunchUrlLazyQueryHookResult = ReturnType<
  typeof useIdentityProviderLaunchUrlLazyQuery
>;
export type IdentityProviderLaunchUrlQueryResult = Apollo.QueryResult<
  IdentityProviderLaunchUrlQuery,
  IdentityProviderLaunchUrlQueryVariables
>;
export function refetchIdentityProviderLaunchUrlQuery(
  variables?: IdentityProviderLaunchUrlQueryVariables
) {
  return { query: IdentityProviderLaunchUrlDocument, variables: variables };
}
export const CreateFontDocument = gql`
  mutation createFont($input: CreateFontInput!) {
    createFont(input: $input) {
      font {
        ...AllFontItems
        fileByThumbnailFileId {
          ...File
        }
      }
    }
  }
  ${AllFontItemsFragmentDoc}
  ${FileFragmentDoc}
`;
export type CreateFontMutationFn = Apollo.MutationFunction<
  CreateFontMutation,
  CreateFontMutationVariables
>;
export type CreateFontProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateFontMutation,
    CreateFontMutationVariables
  >;
} &
  TChildProps;
export function withCreateFont<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateFontMutation,
    CreateFontMutationVariables,
    CreateFontProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateFontMutation,
    CreateFontMutationVariables,
    CreateFontProps<TChildProps, TDataName>
  >(CreateFontDocument, {
    alias: "createFont",
    ...operationOptions,
  });
}

/**
 * __useCreateFontMutation__
 *
 * To run a mutation, you first call `useCreateFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFontMutation, { data, loading, error }] = useCreateFontMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFontMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFontMutation,
    CreateFontMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFontMutation, CreateFontMutationVariables>(
    CreateFontDocument,
    options
  );
}
export type CreateFontMutationHookResult = ReturnType<
  typeof useCreateFontMutation
>;
export type CreateFontMutationResult = Apollo.MutationResult<CreateFontMutation>;
export type CreateFontMutationOptions = Apollo.BaseMutationOptions<
  CreateFontMutation,
  CreateFontMutationVariables
>;
export const DeviceInfoByScreenIdDocument = gql`
  query deviceInfoByScreenId($screenId: UUID!) {
    screenById(id: $screenId) {
      deviceInfo
    }
  }
`;
export type DeviceInfoByScreenIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    DeviceInfoByScreenIdQuery,
    DeviceInfoByScreenIdQueryVariables
  >;
} &
  TChildProps;
export function withDeviceInfoByScreenId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeviceInfoByScreenIdQuery,
    DeviceInfoByScreenIdQueryVariables,
    DeviceInfoByScreenIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DeviceInfoByScreenIdQuery,
    DeviceInfoByScreenIdQueryVariables,
    DeviceInfoByScreenIdProps<TChildProps, TDataName>
  >(DeviceInfoByScreenIdDocument, {
    alias: "deviceInfoByScreenId",
    ...operationOptions,
  });
}

/**
 * __useDeviceInfoByScreenIdQuery__
 *
 * To run a query within a React component, call `useDeviceInfoByScreenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceInfoByScreenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceInfoByScreenIdQuery({
 *   variables: {
 *      screenId: // value for 'screenId'
 *   },
 * });
 */
export function useDeviceInfoByScreenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DeviceInfoByScreenIdQuery,
    DeviceInfoByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DeviceInfoByScreenIdQuery,
    DeviceInfoByScreenIdQueryVariables
  >(DeviceInfoByScreenIdDocument, options);
}
export function useDeviceInfoByScreenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeviceInfoByScreenIdQuery,
    DeviceInfoByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DeviceInfoByScreenIdQuery,
    DeviceInfoByScreenIdQueryVariables
  >(DeviceInfoByScreenIdDocument, options);
}
export type DeviceInfoByScreenIdQueryHookResult = ReturnType<
  typeof useDeviceInfoByScreenIdQuery
>;
export type DeviceInfoByScreenIdLazyQueryHookResult = ReturnType<
  typeof useDeviceInfoByScreenIdLazyQuery
>;
export type DeviceInfoByScreenIdQueryResult = Apollo.QueryResult<
  DeviceInfoByScreenIdQuery,
  DeviceInfoByScreenIdQueryVariables
>;
export function refetchDeviceInfoByScreenIdQuery(
  variables?: DeviceInfoByScreenIdQueryVariables
) {
  return { query: DeviceInfoByScreenIdDocument, variables: variables };
}
export const CurrentFeatureFlagsDocument = gql`
  query currentFeatureFlags {
    currentFeatureFlags
  }
`;
export type CurrentFeatureFlagsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CurrentFeatureFlagsQuery,
    CurrentFeatureFlagsQueryVariables
  >;
} &
  TChildProps;
export function withCurrentFeatureFlags<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrentFeatureFlagsQuery,
    CurrentFeatureFlagsQueryVariables,
    CurrentFeatureFlagsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrentFeatureFlagsQuery,
    CurrentFeatureFlagsQueryVariables,
    CurrentFeatureFlagsProps<TChildProps, TDataName>
  >(CurrentFeatureFlagsDocument, {
    alias: "currentFeatureFlags",
    ...operationOptions,
  });
}

/**
 * __useCurrentFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useCurrentFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentFeatureFlagsQuery,
    CurrentFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentFeatureFlagsQuery,
    CurrentFeatureFlagsQueryVariables
  >(CurrentFeatureFlagsDocument, options);
}
export function useCurrentFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentFeatureFlagsQuery,
    CurrentFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentFeatureFlagsQuery,
    CurrentFeatureFlagsQueryVariables
  >(CurrentFeatureFlagsDocument, options);
}
export type CurrentFeatureFlagsQueryHookResult = ReturnType<
  typeof useCurrentFeatureFlagsQuery
>;
export type CurrentFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useCurrentFeatureFlagsLazyQuery
>;
export type CurrentFeatureFlagsQueryResult = Apollo.QueryResult<
  CurrentFeatureFlagsQuery,
  CurrentFeatureFlagsQueryVariables
>;
export function refetchCurrentFeatureFlagsQuery(
  variables?: CurrentFeatureFlagsQueryVariables
) {
  return { query: CurrentFeatureFlagsDocument, variables: variables };
}
export const CustomizableFeatureFlagsDocument = gql`
  query customizableFeatureFlags {
    customizableFeatureFlags {
      nodes {
        ...FeatureFlag
      }
    }
  }
  ${FeatureFlagFragmentDoc}
`;
export type CustomizableFeatureFlagsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CustomizableFeatureFlagsQuery,
    CustomizableFeatureFlagsQueryVariables
  >;
} &
  TChildProps;
export function withCustomizableFeatureFlags<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CustomizableFeatureFlagsQuery,
    CustomizableFeatureFlagsQueryVariables,
    CustomizableFeatureFlagsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CustomizableFeatureFlagsQuery,
    CustomizableFeatureFlagsQueryVariables,
    CustomizableFeatureFlagsProps<TChildProps, TDataName>
  >(CustomizableFeatureFlagsDocument, {
    alias: "customizableFeatureFlags",
    ...operationOptions,
  });
}

/**
 * __useCustomizableFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useCustomizableFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomizableFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomizableFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomizableFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomizableFeatureFlagsQuery,
    CustomizableFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomizableFeatureFlagsQuery,
    CustomizableFeatureFlagsQueryVariables
  >(CustomizableFeatureFlagsDocument, options);
}
export function useCustomizableFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomizableFeatureFlagsQuery,
    CustomizableFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomizableFeatureFlagsQuery,
    CustomizableFeatureFlagsQueryVariables
  >(CustomizableFeatureFlagsDocument, options);
}
export type CustomizableFeatureFlagsQueryHookResult = ReturnType<
  typeof useCustomizableFeatureFlagsQuery
>;
export type CustomizableFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useCustomizableFeatureFlagsLazyQuery
>;
export type CustomizableFeatureFlagsQueryResult = Apollo.QueryResult<
  CustomizableFeatureFlagsQuery,
  CustomizableFeatureFlagsQueryVariables
>;
export function refetchCustomizableFeatureFlagsQuery(
  variables?: CustomizableFeatureFlagsQueryVariables
) {
  return { query: CustomizableFeatureFlagsDocument, variables: variables };
}
export const SetFeatureFlagOrgDocument = gql`
  mutation setFeatureFlagOrg($input: SetFeatureFlagOrgInput!) {
    setFeatureFlagOrg(input: $input) {
      featureFlagOrg {
        ...FeatureFlagOrg
      }
    }
  }
  ${FeatureFlagOrgFragmentDoc}
`;
export type SetFeatureFlagOrgMutationFn = Apollo.MutationFunction<
  SetFeatureFlagOrgMutation,
  SetFeatureFlagOrgMutationVariables
>;
export type SetFeatureFlagOrgProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetFeatureFlagOrgMutation,
    SetFeatureFlagOrgMutationVariables
  >;
} &
  TChildProps;
export function withSetFeatureFlagOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetFeatureFlagOrgMutation,
    SetFeatureFlagOrgMutationVariables,
    SetFeatureFlagOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetFeatureFlagOrgMutation,
    SetFeatureFlagOrgMutationVariables,
    SetFeatureFlagOrgProps<TChildProps, TDataName>
  >(SetFeatureFlagOrgDocument, {
    alias: "setFeatureFlagOrg",
    ...operationOptions,
  });
}

/**
 * __useSetFeatureFlagOrgMutation__
 *
 * To run a mutation, you first call `useSetFeatureFlagOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeatureFlagOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeatureFlagOrgMutation, { data, loading, error }] = useSetFeatureFlagOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFeatureFlagOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetFeatureFlagOrgMutation,
    SetFeatureFlagOrgMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetFeatureFlagOrgMutation,
    SetFeatureFlagOrgMutationVariables
  >(SetFeatureFlagOrgDocument, options);
}
export type SetFeatureFlagOrgMutationHookResult = ReturnType<
  typeof useSetFeatureFlagOrgMutation
>;
export type SetFeatureFlagOrgMutationResult = Apollo.MutationResult<SetFeatureFlagOrgMutation>;
export type SetFeatureFlagOrgMutationOptions = Apollo.BaseMutationOptions<
  SetFeatureFlagOrgMutation,
  SetFeatureFlagOrgMutationVariables
>;
export const AllChannelsByLayoutIdDocument = gql`
  query allChannelsByLayoutId($layoutId: UUID) {
    allChannels(condition: { layoutId: $layoutId }) {
      nodes {
        name
        id
        draftOf
      }
    }
  }
`;
export type AllChannelsByLayoutIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllChannelsByLayoutIdQuery,
    AllChannelsByLayoutIdQueryVariables
  >;
} &
  TChildProps;
export function withAllChannelsByLayoutId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllChannelsByLayoutIdQuery,
    AllChannelsByLayoutIdQueryVariables,
    AllChannelsByLayoutIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllChannelsByLayoutIdQuery,
    AllChannelsByLayoutIdQueryVariables,
    AllChannelsByLayoutIdProps<TChildProps, TDataName>
  >(AllChannelsByLayoutIdDocument, {
    alias: "allChannelsByLayoutId",
    ...operationOptions,
  });
}

/**
 * __useAllChannelsByLayoutIdQuery__
 *
 * To run a query within a React component, call `useAllChannelsByLayoutIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChannelsByLayoutIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChannelsByLayoutIdQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useAllChannelsByLayoutIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllChannelsByLayoutIdQuery,
    AllChannelsByLayoutIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllChannelsByLayoutIdQuery,
    AllChannelsByLayoutIdQueryVariables
  >(AllChannelsByLayoutIdDocument, options);
}
export function useAllChannelsByLayoutIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllChannelsByLayoutIdQuery,
    AllChannelsByLayoutIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllChannelsByLayoutIdQuery,
    AllChannelsByLayoutIdQueryVariables
  >(AllChannelsByLayoutIdDocument, options);
}
export type AllChannelsByLayoutIdQueryHookResult = ReturnType<
  typeof useAllChannelsByLayoutIdQuery
>;
export type AllChannelsByLayoutIdLazyQueryHookResult = ReturnType<
  typeof useAllChannelsByLayoutIdLazyQuery
>;
export type AllChannelsByLayoutIdQueryResult = Apollo.QueryResult<
  AllChannelsByLayoutIdQuery,
  AllChannelsByLayoutIdQueryVariables
>;
export function refetchAllChannelsByLayoutIdQuery(
  variables?: AllChannelsByLayoutIdQueryVariables
) {
  return { query: AllChannelsByLayoutIdDocument, variables: variables };
}
export const AvailableLayoutsForSizeDocument = gql`
  query availableLayoutsForSize($spaceId: UUID!, $width: Int, $height: Int) {
    availableLayoutsForSize(spaceId: $spaceId, width: $width, height: $height) {
      nodes {
        ...Layout
      }
    }
  }
  ${LayoutFragmentDoc}
`;
export type AvailableLayoutsForSizeProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailableLayoutsForSizeQuery,
    AvailableLayoutsForSizeQueryVariables
  >;
} &
  TChildProps;
export function withAvailableLayoutsForSize<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailableLayoutsForSizeQuery,
    AvailableLayoutsForSizeQueryVariables,
    AvailableLayoutsForSizeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailableLayoutsForSizeQuery,
    AvailableLayoutsForSizeQueryVariables,
    AvailableLayoutsForSizeProps<TChildProps, TDataName>
  >(AvailableLayoutsForSizeDocument, {
    alias: "availableLayoutsForSize",
    ...operationOptions,
  });
}

/**
 * __useAvailableLayoutsForSizeQuery__
 *
 * To run a query within a React component, call `useAvailableLayoutsForSizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableLayoutsForSizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableLayoutsForSizeQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useAvailableLayoutsForSizeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableLayoutsForSizeQuery,
    AvailableLayoutsForSizeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableLayoutsForSizeQuery,
    AvailableLayoutsForSizeQueryVariables
  >(AvailableLayoutsForSizeDocument, options);
}
export function useAvailableLayoutsForSizeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableLayoutsForSizeQuery,
    AvailableLayoutsForSizeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableLayoutsForSizeQuery,
    AvailableLayoutsForSizeQueryVariables
  >(AvailableLayoutsForSizeDocument, options);
}
export type AvailableLayoutsForSizeQueryHookResult = ReturnType<
  typeof useAvailableLayoutsForSizeQuery
>;
export type AvailableLayoutsForSizeLazyQueryHookResult = ReturnType<
  typeof useAvailableLayoutsForSizeLazyQuery
>;
export type AvailableLayoutsForSizeQueryResult = Apollo.QueryResult<
  AvailableLayoutsForSizeQuery,
  AvailableLayoutsForSizeQueryVariables
>;
export function refetchAvailableLayoutsForSizeQuery(
  variables?: AvailableLayoutsForSizeQueryVariables
) {
  return { query: AvailableLayoutsForSizeDocument, variables: variables };
}
export const CreateLayoutDocument = gql`
  mutation createLayout($input: CreateLayoutInput!) {
    createLayout(input: $input) {
      layout {
        ...Layout
      }
    }
  }
  ${LayoutFragmentDoc}
`;
export type CreateLayoutMutationFn = Apollo.MutationFunction<
  CreateLayoutMutation,
  CreateLayoutMutationVariables
>;
export type CreateLayoutProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateLayoutMutation,
    CreateLayoutMutationVariables
  >;
} &
  TChildProps;
export function withCreateLayout<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateLayoutMutation,
    CreateLayoutMutationVariables,
    CreateLayoutProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateLayoutMutation,
    CreateLayoutMutationVariables,
    CreateLayoutProps<TChildProps, TDataName>
  >(CreateLayoutDocument, {
    alias: "createLayout",
    ...operationOptions,
  });
}

/**
 * __useCreateLayoutMutation__
 *
 * To run a mutation, you first call `useCreateLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLayoutMutation, { data, loading, error }] = useCreateLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLayoutMutation,
    CreateLayoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLayoutMutation,
    CreateLayoutMutationVariables
  >(CreateLayoutDocument, options);
}
export type CreateLayoutMutationHookResult = ReturnType<
  typeof useCreateLayoutMutation
>;
export type CreateLayoutMutationResult = Apollo.MutationResult<CreateLayoutMutation>;
export type CreateLayoutMutationOptions = Apollo.BaseMutationOptions<
  CreateLayoutMutation,
  CreateLayoutMutationVariables
>;
export const DeleteLayoutDocument = gql`
  mutation deleteLayout($input: DeleteLayoutInput!) {
    deleteLayout(input: $input) {
      layout {
        ...Layout
      }
    }
  }
  ${LayoutFragmentDoc}
`;
export type DeleteLayoutMutationFn = Apollo.MutationFunction<
  DeleteLayoutMutation,
  DeleteLayoutMutationVariables
>;
export type DeleteLayoutProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteLayoutMutation,
    DeleteLayoutMutationVariables
  >;
} &
  TChildProps;
export function withDeleteLayout<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteLayoutMutation,
    DeleteLayoutMutationVariables,
    DeleteLayoutProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteLayoutMutation,
    DeleteLayoutMutationVariables,
    DeleteLayoutProps<TChildProps, TDataName>
  >(DeleteLayoutDocument, {
    alias: "deleteLayout",
    ...operationOptions,
  });
}

/**
 * __useDeleteLayoutMutation__
 *
 * To run a mutation, you first call `useDeleteLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLayoutMutation, { data, loading, error }] = useDeleteLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLayoutMutation,
    DeleteLayoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLayoutMutation,
    DeleteLayoutMutationVariables
  >(DeleteLayoutDocument, options);
}
export type DeleteLayoutMutationHookResult = ReturnType<
  typeof useDeleteLayoutMutation
>;
export type DeleteLayoutMutationResult = Apollo.MutationResult<DeleteLayoutMutation>;
export type DeleteLayoutMutationOptions = Apollo.BaseMutationOptions<
  DeleteLayoutMutation,
  DeleteLayoutMutationVariables
>;
export const LayoutBySpaceIdQueryDocument = gql`
  query layoutBySpaceIdQuery($spaceId: UUID!) {
    layoutsBySpaceId(spaceId: $spaceId) {
      nodes {
        ...Layout
      }
    }
  }
  ${LayoutFragmentDoc}
`;
export type LayoutBySpaceIdQueryProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LayoutBySpaceIdQueryQuery,
    LayoutBySpaceIdQueryQueryVariables
  >;
} &
  TChildProps;
export function withLayoutBySpaceIdQuery<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LayoutBySpaceIdQueryQuery,
    LayoutBySpaceIdQueryQueryVariables,
    LayoutBySpaceIdQueryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LayoutBySpaceIdQueryQuery,
    LayoutBySpaceIdQueryQueryVariables,
    LayoutBySpaceIdQueryProps<TChildProps, TDataName>
  >(LayoutBySpaceIdQueryDocument, {
    alias: "layoutBySpaceIdQuery",
    ...operationOptions,
  });
}

/**
 * __useLayoutBySpaceIdQueryQuery__
 *
 * To run a query within a React component, call `useLayoutBySpaceIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutBySpaceIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutBySpaceIdQueryQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useLayoutBySpaceIdQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LayoutBySpaceIdQueryQuery,
    LayoutBySpaceIdQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LayoutBySpaceIdQueryQuery,
    LayoutBySpaceIdQueryQueryVariables
  >(LayoutBySpaceIdQueryDocument, options);
}
export function useLayoutBySpaceIdQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LayoutBySpaceIdQueryQuery,
    LayoutBySpaceIdQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LayoutBySpaceIdQueryQuery,
    LayoutBySpaceIdQueryQueryVariables
  >(LayoutBySpaceIdQueryDocument, options);
}
export type LayoutBySpaceIdQueryQueryHookResult = ReturnType<
  typeof useLayoutBySpaceIdQueryQuery
>;
export type LayoutBySpaceIdQueryLazyQueryHookResult = ReturnType<
  typeof useLayoutBySpaceIdQueryLazyQuery
>;
export type LayoutBySpaceIdQueryQueryResult = Apollo.QueryResult<
  LayoutBySpaceIdQueryQuery,
  LayoutBySpaceIdQueryQueryVariables
>;
export function refetchLayoutBySpaceIdQueryQuery(
  variables?: LayoutBySpaceIdQueryQueryVariables
) {
  return { query: LayoutBySpaceIdQueryDocument, variables: variables };
}
export const UpdateLayoutDocument = gql`
  mutation updateLayout($input: UpdateLayoutInput!) {
    updateLayout(input: $input) {
      layout {
        ...Layout
      }
    }
  }
  ${LayoutFragmentDoc}
`;
export type UpdateLayoutMutationFn = Apollo.MutationFunction<
  UpdateLayoutMutation,
  UpdateLayoutMutationVariables
>;
export type UpdateLayoutProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateLayoutMutation,
    UpdateLayoutMutationVariables
  >;
} &
  TChildProps;
export function withUpdateLayout<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateLayoutMutation,
    UpdateLayoutMutationVariables,
    UpdateLayoutProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateLayoutMutation,
    UpdateLayoutMutationVariables,
    UpdateLayoutProps<TChildProps, TDataName>
  >(UpdateLayoutDocument, {
    alias: "updateLayout",
    ...operationOptions,
  });
}

/**
 * __useUpdateLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayoutMutation, { data, loading, error }] = useUpdateLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLayoutMutation,
    UpdateLayoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLayoutMutation,
    UpdateLayoutMutationVariables
  >(UpdateLayoutDocument, options);
}
export type UpdateLayoutMutationHookResult = ReturnType<
  typeof useUpdateLayoutMutation
>;
export type UpdateLayoutMutationResult = Apollo.MutationResult<UpdateLayoutMutation>;
export type UpdateLayoutMutationOptions = Apollo.BaseMutationOptions<
  UpdateLayoutMutation,
  UpdateLayoutMutationVariables
>;
export const AllLegacyLogsDocument = gql`
  query allLegacyLogs($cursor: Cursor) {
    allLegacyLogs(first: 50, after: $cursor, orderBy: ACTION_DESC) {
      nodes {
        ...LegacyLog
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${LegacyLogFragmentDoc}
`;
export type AllLegacyLogsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllLegacyLogsQuery,
    AllLegacyLogsQueryVariables
  >;
} &
  TChildProps;
export function withAllLegacyLogs<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLegacyLogsQuery,
    AllLegacyLogsQueryVariables,
    AllLegacyLogsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLegacyLogsQuery,
    AllLegacyLogsQueryVariables,
    AllLegacyLogsProps<TChildProps, TDataName>
  >(AllLegacyLogsDocument, {
    alias: "allLegacyLogs",
    ...operationOptions,
  });
}

/**
 * __useAllLegacyLogsQuery__
 *
 * To run a query within a React component, call `useAllLegacyLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLegacyLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLegacyLogsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAllLegacyLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllLegacyLogsQuery,
    AllLegacyLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllLegacyLogsQuery, AllLegacyLogsQueryVariables>(
    AllLegacyLogsDocument,
    options
  );
}
export function useAllLegacyLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllLegacyLogsQuery,
    AllLegacyLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllLegacyLogsQuery, AllLegacyLogsQueryVariables>(
    AllLegacyLogsDocument,
    options
  );
}
export type AllLegacyLogsQueryHookResult = ReturnType<
  typeof useAllLegacyLogsQuery
>;
export type AllLegacyLogsLazyQueryHookResult = ReturnType<
  typeof useAllLegacyLogsLazyQuery
>;
export type AllLegacyLogsQueryResult = Apollo.QueryResult<
  AllLegacyLogsQuery,
  AllLegacyLogsQueryVariables
>;
export function refetchAllLegacyLogsQuery(
  variables?: AllLegacyLogsQueryVariables
) {
  return { query: AllLegacyLogsDocument, variables: variables };
}
export const CountLegacyLogsDocument = gql`
  query countLegacyLogs {
    allLegacyLogs {
      totalCount
    }
  }
`;
export type CountLegacyLogsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CountLegacyLogsQuery,
    CountLegacyLogsQueryVariables
  >;
} &
  TChildProps;
export function withCountLegacyLogs<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountLegacyLogsQuery,
    CountLegacyLogsQueryVariables,
    CountLegacyLogsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountLegacyLogsQuery,
    CountLegacyLogsQueryVariables,
    CountLegacyLogsProps<TChildProps, TDataName>
  >(CountLegacyLogsDocument, {
    alias: "countLegacyLogs",
    ...operationOptions,
  });
}

/**
 * __useCountLegacyLogsQuery__
 *
 * To run a query within a React component, call `useCountLegacyLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountLegacyLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountLegacyLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountLegacyLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountLegacyLogsQuery,
    CountLegacyLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountLegacyLogsQuery, CountLegacyLogsQueryVariables>(
    CountLegacyLogsDocument,
    options
  );
}
export function useCountLegacyLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountLegacyLogsQuery,
    CountLegacyLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountLegacyLogsQuery,
    CountLegacyLogsQueryVariables
  >(CountLegacyLogsDocument, options);
}
export type CountLegacyLogsQueryHookResult = ReturnType<
  typeof useCountLegacyLogsQuery
>;
export type CountLegacyLogsLazyQueryHookResult = ReturnType<
  typeof useCountLegacyLogsLazyQuery
>;
export type CountLegacyLogsQueryResult = Apollo.QueryResult<
  CountLegacyLogsQuery,
  CountLegacyLogsQueryVariables
>;
export function refetchCountLegacyLogsQuery(
  variables?: CountLegacyLogsQueryVariables
) {
  return { query: CountLegacyLogsDocument, variables: variables };
}
export const LinkPickerDocument = gql`
  query linkPicker(
    $orgId: UUID!
    $spaceId: UUID
    $first: Int
    $endCursor: Cursor
  ) {
    orgById(id: $orgId) {
      id
      name
      linksByOrgId(
        first: $first
        after: $endCursor
        orderBy: [CREATED_AT_DESC, ID_DESC]
        filter: { spaceId: { equalTo: $spaceId } }
      ) {
        nodes {
          ...LinkListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${LinkListItemFragmentDoc}
`;
export type LinkPickerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LinkPickerQuery,
    LinkPickerQueryVariables
  >;
} &
  TChildProps;
export function withLinkPicker<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LinkPickerQuery,
    LinkPickerQueryVariables,
    LinkPickerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LinkPickerQuery,
    LinkPickerQueryVariables,
    LinkPickerProps<TChildProps, TDataName>
  >(LinkPickerDocument, {
    alias: "linkPicker",
    ...operationOptions,
  });
}

/**
 * __useLinkPickerQuery__
 *
 * To run a query within a React component, call `useLinkPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkPickerQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useLinkPickerQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkPickerQuery,
    LinkPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinkPickerQuery, LinkPickerQueryVariables>(
    LinkPickerDocument,
    options
  );
}
export function useLinkPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkPickerQuery,
    LinkPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinkPickerQuery, LinkPickerQueryVariables>(
    LinkPickerDocument,
    options
  );
}
export type LinkPickerQueryHookResult = ReturnType<typeof useLinkPickerQuery>;
export type LinkPickerLazyQueryHookResult = ReturnType<
  typeof useLinkPickerLazyQuery
>;
export type LinkPickerQueryResult = Apollo.QueryResult<
  LinkPickerQuery,
  LinkPickerQueryVariables
>;
export function refetchLinkPickerQuery(variables?: LinkPickerQueryVariables) {
  return { query: LinkPickerDocument, variables: variables };
}
export const SearchLinkPickerDocument = gql`
  query searchLinkPicker(
    $query: String
    $spaceId: UUID
    $first: Int
    $endCursor: Cursor
  ) {
    searchLink(
      query: $query
      spaceId: $spaceId
      first: $first
      after: $endCursor
    ) {
      nodes {
        ...LinkListItem
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${LinkListItemFragmentDoc}
`;
export type SearchLinkPickerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchLinkPickerQuery,
    SearchLinkPickerQueryVariables
  >;
} &
  TChildProps;
export function withSearchLinkPicker<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchLinkPickerQuery,
    SearchLinkPickerQueryVariables,
    SearchLinkPickerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchLinkPickerQuery,
    SearchLinkPickerQueryVariables,
    SearchLinkPickerProps<TChildProps, TDataName>
  >(SearchLinkPickerDocument, {
    alias: "searchLinkPicker",
    ...operationOptions,
  });
}

/**
 * __useSearchLinkPickerQuery__
 *
 * To run a query within a React component, call `useSearchLinkPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLinkPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLinkPickerQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useSearchLinkPickerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchLinkPickerQuery,
    SearchLinkPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchLinkPickerQuery, SearchLinkPickerQueryVariables>(
    SearchLinkPickerDocument,
    options
  );
}
export function useSearchLinkPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchLinkPickerQuery,
    SearchLinkPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchLinkPickerQuery,
    SearchLinkPickerQueryVariables
  >(SearchLinkPickerDocument, options);
}
export type SearchLinkPickerQueryHookResult = ReturnType<
  typeof useSearchLinkPickerQuery
>;
export type SearchLinkPickerLazyQueryHookResult = ReturnType<
  typeof useSearchLinkPickerLazyQuery
>;
export type SearchLinkPickerQueryResult = Apollo.QueryResult<
  SearchLinkPickerQuery,
  SearchLinkPickerQueryVariables
>;
export function refetchSearchLinkPickerQuery(
  variables?: SearchLinkPickerQueryVariables
) {
  return { query: SearchLinkPickerDocument, variables: variables };
}
export const UpdateLinkIsFavoriteDocument = gql`
  mutation updateLinkIsFavorite($input: UpdateLinkIsFavoriteInput!) {
    updateLinkIsFavorite(input: $input) {
      favorites {
        linkId
        linkByLinkId {
          __typename
          id
          isFavorite
        }
      }
    }
  }
`;
export type UpdateLinkIsFavoriteMutationFn = Apollo.MutationFunction<
  UpdateLinkIsFavoriteMutation,
  UpdateLinkIsFavoriteMutationVariables
>;
export type UpdateLinkIsFavoriteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateLinkIsFavoriteMutation,
    UpdateLinkIsFavoriteMutationVariables
  >;
} &
  TChildProps;
export function withUpdateLinkIsFavorite<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateLinkIsFavoriteMutation,
    UpdateLinkIsFavoriteMutationVariables,
    UpdateLinkIsFavoriteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateLinkIsFavoriteMutation,
    UpdateLinkIsFavoriteMutationVariables,
    UpdateLinkIsFavoriteProps<TChildProps, TDataName>
  >(UpdateLinkIsFavoriteDocument, {
    alias: "updateLinkIsFavorite",
    ...operationOptions,
  });
}

/**
 * __useUpdateLinkIsFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateLinkIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkIsFavoriteMutation, { data, loading, error }] = useUpdateLinkIsFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLinkIsFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLinkIsFavoriteMutation,
    UpdateLinkIsFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLinkIsFavoriteMutation,
    UpdateLinkIsFavoriteMutationVariables
  >(UpdateLinkIsFavoriteDocument, options);
}
export type UpdateLinkIsFavoriteMutationHookResult = ReturnType<
  typeof useUpdateLinkIsFavoriteMutation
>;
export type UpdateLinkIsFavoriteMutationResult = Apollo.MutationResult<UpdateLinkIsFavoriteMutation>;
export type UpdateLinkIsFavoriteMutationOptions = Apollo.BaseMutationOptions<
  UpdateLinkIsFavoriteMutation,
  UpdateLinkIsFavoriteMutationVariables
>;
export const CastedScreensCountDocument = gql`
  query castedScreensCount($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      castsBySpaceId {
        nodes {
          screensByCastId {
            totalCount
          }
        }
        totalCount
      }
    }
  }
`;
export type CastedScreensCountProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CastedScreensCountQuery,
    CastedScreensCountQueryVariables
  >;
} &
  TChildProps;
export function withCastedScreensCount<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CastedScreensCountQuery,
    CastedScreensCountQueryVariables,
    CastedScreensCountProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CastedScreensCountQuery,
    CastedScreensCountQueryVariables,
    CastedScreensCountProps<TChildProps, TDataName>
  >(CastedScreensCountDocument, {
    alias: "castedScreensCount",
    ...operationOptions,
  });
}

/**
 * __useCastedScreensCountQuery__
 *
 * To run a query within a React component, call `useCastedScreensCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCastedScreensCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCastedScreensCountQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useCastedScreensCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    CastedScreensCountQuery,
    CastedScreensCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CastedScreensCountQuery,
    CastedScreensCountQueryVariables
  >(CastedScreensCountDocument, options);
}
export function useCastedScreensCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CastedScreensCountQuery,
    CastedScreensCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CastedScreensCountQuery,
    CastedScreensCountQueryVariables
  >(CastedScreensCountDocument, options);
}
export type CastedScreensCountQueryHookResult = ReturnType<
  typeof useCastedScreensCountQuery
>;
export type CastedScreensCountLazyQueryHookResult = ReturnType<
  typeof useCastedScreensCountLazyQuery
>;
export type CastedScreensCountQueryResult = Apollo.QueryResult<
  CastedScreensCountQuery,
  CastedScreensCountQueryVariables
>;
export function refetchCastedScreensCountQuery(
  variables?: CastedScreensCountQueryVariables
) {
  return { query: CastedScreensCountDocument, variables: variables };
}
export const MainMenuQueryDocument = gql`
  query mainMenuQuery {
    currentUser {
      ...User
      orgByOrgId {
        __typename
        id
        name
      }
    }
  }
  ${UserFragmentDoc}
`;
export type MainMenuQueryProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    MainMenuQueryQuery,
    MainMenuQueryQueryVariables
  >;
} &
  TChildProps;
export function withMainMenuQuery<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MainMenuQueryQuery,
    MainMenuQueryQueryVariables,
    MainMenuQueryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MainMenuQueryQuery,
    MainMenuQueryQueryVariables,
    MainMenuQueryProps<TChildProps, TDataName>
  >(MainMenuQueryDocument, {
    alias: "mainMenuQuery",
    ...operationOptions,
  });
}

/**
 * __useMainMenuQueryQuery__
 *
 * To run a query within a React component, call `useMainMenuQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainMenuQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainMenuQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainMenuQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MainMenuQueryQuery,
    MainMenuQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MainMenuQueryQuery, MainMenuQueryQueryVariables>(
    MainMenuQueryDocument,
    options
  );
}
export function useMainMenuQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MainMenuQueryQuery,
    MainMenuQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MainMenuQueryQuery, MainMenuQueryQueryVariables>(
    MainMenuQueryDocument,
    options
  );
}
export type MainMenuQueryQueryHookResult = ReturnType<
  typeof useMainMenuQueryQuery
>;
export type MainMenuQueryLazyQueryHookResult = ReturnType<
  typeof useMainMenuQueryLazyQuery
>;
export type MainMenuQueryQueryResult = Apollo.QueryResult<
  MainMenuQueryQuery,
  MainMenuQueryQueryVariables
>;
export function refetchMainMenuQueryQuery(
  variables?: MainMenuQueryQueryVariables
) {
  return { query: MainMenuQueryDocument, variables: variables };
}
export const MediaFilePickerForOrgWideDocument = gql`
  query mediaFilePickerForOrgWide($first: Int, $endCursor: Cursor) {
    allFilesFromAllRootFolders(
      first: $first
      after: $endCursor
      orderBy: [CREATED_AT_DESC, ID_DESC]
    ) {
      nodes {
        ...FileListItem
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${FileListItemFragmentDoc}
`;
export type MediaFilePickerForOrgWideProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    MediaFilePickerForOrgWideQuery,
    MediaFilePickerForOrgWideQueryVariables
  >;
} &
  TChildProps;
export function withMediaFilePickerForOrgWide<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MediaFilePickerForOrgWideQuery,
    MediaFilePickerForOrgWideQueryVariables,
    MediaFilePickerForOrgWideProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MediaFilePickerForOrgWideQuery,
    MediaFilePickerForOrgWideQueryVariables,
    MediaFilePickerForOrgWideProps<TChildProps, TDataName>
  >(MediaFilePickerForOrgWideDocument, {
    alias: "mediaFilePickerForOrgWide",
    ...operationOptions,
  });
}

/**
 * __useMediaFilePickerForOrgWideQuery__
 *
 * To run a query within a React component, call `useMediaFilePickerForOrgWideQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaFilePickerForOrgWideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaFilePickerForOrgWideQuery({
 *   variables: {
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useMediaFilePickerForOrgWideQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MediaFilePickerForOrgWideQuery,
    MediaFilePickerForOrgWideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaFilePickerForOrgWideQuery,
    MediaFilePickerForOrgWideQueryVariables
  >(MediaFilePickerForOrgWideDocument, options);
}
export function useMediaFilePickerForOrgWideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaFilePickerForOrgWideQuery,
    MediaFilePickerForOrgWideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaFilePickerForOrgWideQuery,
    MediaFilePickerForOrgWideQueryVariables
  >(MediaFilePickerForOrgWideDocument, options);
}
export type MediaFilePickerForOrgWideQueryHookResult = ReturnType<
  typeof useMediaFilePickerForOrgWideQuery
>;
export type MediaFilePickerForOrgWideLazyQueryHookResult = ReturnType<
  typeof useMediaFilePickerForOrgWideLazyQuery
>;
export type MediaFilePickerForOrgWideQueryResult = Apollo.QueryResult<
  MediaFilePickerForOrgWideQuery,
  MediaFilePickerForOrgWideQueryVariables
>;
export function refetchMediaFilePickerForOrgWideQuery(
  variables?: MediaFilePickerForOrgWideQueryVariables
) {
  return { query: MediaFilePickerForOrgWideDocument, variables: variables };
}
export const MediaFolderPickerForOrgWideDocument = gql`
  query mediaFolderPickerForOrgWide($first: Int, $endCursor: Cursor) {
    allChildFoldersFromAllRootFolders(
      first: $first
      after: $endCursor
      orderBy: [CREATED_AT_DESC, ID_DESC]
    ) {
      nodes {
        ...Folder
        foldersByParentId {
          __typename
          totalCount
        }
        filesByFolderId {
          __typename
          totalCount
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${FolderFragmentDoc}
`;
export type MediaFolderPickerForOrgWideProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    MediaFolderPickerForOrgWideQuery,
    MediaFolderPickerForOrgWideQueryVariables
  >;
} &
  TChildProps;
export function withMediaFolderPickerForOrgWide<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MediaFolderPickerForOrgWideQuery,
    MediaFolderPickerForOrgWideQueryVariables,
    MediaFolderPickerForOrgWideProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MediaFolderPickerForOrgWideQuery,
    MediaFolderPickerForOrgWideQueryVariables,
    MediaFolderPickerForOrgWideProps<TChildProps, TDataName>
  >(MediaFolderPickerForOrgWideDocument, {
    alias: "mediaFolderPickerForOrgWide",
    ...operationOptions,
  });
}

/**
 * __useMediaFolderPickerForOrgWideQuery__
 *
 * To run a query within a React component, call `useMediaFolderPickerForOrgWideQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaFolderPickerForOrgWideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaFolderPickerForOrgWideQuery({
 *   variables: {
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useMediaFolderPickerForOrgWideQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MediaFolderPickerForOrgWideQuery,
    MediaFolderPickerForOrgWideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MediaFolderPickerForOrgWideQuery,
    MediaFolderPickerForOrgWideQueryVariables
  >(MediaFolderPickerForOrgWideDocument, options);
}
export function useMediaFolderPickerForOrgWideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MediaFolderPickerForOrgWideQuery,
    MediaFolderPickerForOrgWideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MediaFolderPickerForOrgWideQuery,
    MediaFolderPickerForOrgWideQueryVariables
  >(MediaFolderPickerForOrgWideDocument, options);
}
export type MediaFolderPickerForOrgWideQueryHookResult = ReturnType<
  typeof useMediaFolderPickerForOrgWideQuery
>;
export type MediaFolderPickerForOrgWideLazyQueryHookResult = ReturnType<
  typeof useMediaFolderPickerForOrgWideLazyQuery
>;
export type MediaFolderPickerForOrgWideQueryResult = Apollo.QueryResult<
  MediaFolderPickerForOrgWideQuery,
  MediaFolderPickerForOrgWideQueryVariables
>;
export function refetchMediaFolderPickerForOrgWideQuery(
  variables?: MediaFolderPickerForOrgWideQueryVariables
) {
  return { query: MediaFolderPickerForOrgWideDocument, variables: variables };
}
export const RootFolderBySpaceIdDocument = gql`
  query rootFolderBySpaceId($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      rootFolderId
    }
  }
`;
export type RootFolderBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    RootFolderBySpaceIdQuery,
    RootFolderBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withRootFolderBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RootFolderBySpaceIdQuery,
    RootFolderBySpaceIdQueryVariables,
    RootFolderBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    RootFolderBySpaceIdQuery,
    RootFolderBySpaceIdQueryVariables,
    RootFolderBySpaceIdProps<TChildProps, TDataName>
  >(RootFolderBySpaceIdDocument, {
    alias: "rootFolderBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useRootFolderBySpaceIdQuery__
 *
 * To run a query within a React component, call `useRootFolderBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootFolderBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootFolderBySpaceIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useRootFolderBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    RootFolderBySpaceIdQuery,
    RootFolderBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RootFolderBySpaceIdQuery,
    RootFolderBySpaceIdQueryVariables
  >(RootFolderBySpaceIdDocument, options);
}
export function useRootFolderBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RootFolderBySpaceIdQuery,
    RootFolderBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RootFolderBySpaceIdQuery,
    RootFolderBySpaceIdQueryVariables
  >(RootFolderBySpaceIdDocument, options);
}
export type RootFolderBySpaceIdQueryHookResult = ReturnType<
  typeof useRootFolderBySpaceIdQuery
>;
export type RootFolderBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useRootFolderBySpaceIdLazyQuery
>;
export type RootFolderBySpaceIdQueryResult = Apollo.QueryResult<
  RootFolderBySpaceIdQuery,
  RootFolderBySpaceIdQueryVariables
>;
export function refetchRootFolderBySpaceIdQuery(
  variables?: RootFolderBySpaceIdQueryVariables
) {
  return { query: RootFolderBySpaceIdDocument, variables: variables };
}
export const SearchFilePickerForOrgWideDocument = gql`
  query searchFilePickerForOrgWide(
    $query: String
    $first: Int
    $endCursor: Cursor
  ) {
    searchFile(
      query: $query
      orderBy: [CREATED_AT_DESC, ID_DESC]
      first: $first
      after: $endCursor
    ) {
      nodes {
        ...FileListItem
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${FileListItemFragmentDoc}
`;
export type SearchFilePickerForOrgWideProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchFilePickerForOrgWideQuery,
    SearchFilePickerForOrgWideQueryVariables
  >;
} &
  TChildProps;
export function withSearchFilePickerForOrgWide<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchFilePickerForOrgWideQuery,
    SearchFilePickerForOrgWideQueryVariables,
    SearchFilePickerForOrgWideProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchFilePickerForOrgWideQuery,
    SearchFilePickerForOrgWideQueryVariables,
    SearchFilePickerForOrgWideProps<TChildProps, TDataName>
  >(SearchFilePickerForOrgWideDocument, {
    alias: "searchFilePickerForOrgWide",
    ...operationOptions,
  });
}

/**
 * __useSearchFilePickerForOrgWideQuery__
 *
 * To run a query within a React component, call `useSearchFilePickerForOrgWideQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFilePickerForOrgWideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFilePickerForOrgWideQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useSearchFilePickerForOrgWideQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchFilePickerForOrgWideQuery,
    SearchFilePickerForOrgWideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchFilePickerForOrgWideQuery,
    SearchFilePickerForOrgWideQueryVariables
  >(SearchFilePickerForOrgWideDocument, options);
}
export function useSearchFilePickerForOrgWideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchFilePickerForOrgWideQuery,
    SearchFilePickerForOrgWideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchFilePickerForOrgWideQuery,
    SearchFilePickerForOrgWideQueryVariables
  >(SearchFilePickerForOrgWideDocument, options);
}
export type SearchFilePickerForOrgWideQueryHookResult = ReturnType<
  typeof useSearchFilePickerForOrgWideQuery
>;
export type SearchFilePickerForOrgWideLazyQueryHookResult = ReturnType<
  typeof useSearchFilePickerForOrgWideLazyQuery
>;
export type SearchFilePickerForOrgWideQueryResult = Apollo.QueryResult<
  SearchFilePickerForOrgWideQuery,
  SearchFilePickerForOrgWideQueryVariables
>;
export function refetchSearchFilePickerForOrgWideQuery(
  variables?: SearchFilePickerForOrgWideQueryVariables
) {
  return { query: SearchFilePickerForOrgWideDocument, variables: variables };
}
export const SearchFolderPickerForOrgWideDocument = gql`
  query searchFolderPickerForOrgWide(
    $query: String
    $first: Int
    $endCursor: Cursor
  ) {
    searchFolder(
      query: $query
      orderBy: [CREATED_AT_DESC, ID_DESC]
      first: $first
      after: $endCursor
    ) {
      nodes {
        ...Folder
        foldersByParentId {
          totalCount
        }
        filesByFolderId {
          nodes {
            ...File
          }
          totalCount
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${FolderFragmentDoc}
  ${FileFragmentDoc}
`;
export type SearchFolderPickerForOrgWideProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchFolderPickerForOrgWideQuery,
    SearchFolderPickerForOrgWideQueryVariables
  >;
} &
  TChildProps;
export function withSearchFolderPickerForOrgWide<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchFolderPickerForOrgWideQuery,
    SearchFolderPickerForOrgWideQueryVariables,
    SearchFolderPickerForOrgWideProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchFolderPickerForOrgWideQuery,
    SearchFolderPickerForOrgWideQueryVariables,
    SearchFolderPickerForOrgWideProps<TChildProps, TDataName>
  >(SearchFolderPickerForOrgWideDocument, {
    alias: "searchFolderPickerForOrgWide",
    ...operationOptions,
  });
}

/**
 * __useSearchFolderPickerForOrgWideQuery__
 *
 * To run a query within a React component, call `useSearchFolderPickerForOrgWideQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFolderPickerForOrgWideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFolderPickerForOrgWideQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useSearchFolderPickerForOrgWideQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchFolderPickerForOrgWideQuery,
    SearchFolderPickerForOrgWideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchFolderPickerForOrgWideQuery,
    SearchFolderPickerForOrgWideQueryVariables
  >(SearchFolderPickerForOrgWideDocument, options);
}
export function useSearchFolderPickerForOrgWideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchFolderPickerForOrgWideQuery,
    SearchFolderPickerForOrgWideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchFolderPickerForOrgWideQuery,
    SearchFolderPickerForOrgWideQueryVariables
  >(SearchFolderPickerForOrgWideDocument, options);
}
export type SearchFolderPickerForOrgWideQueryHookResult = ReturnType<
  typeof useSearchFolderPickerForOrgWideQuery
>;
export type SearchFolderPickerForOrgWideLazyQueryHookResult = ReturnType<
  typeof useSearchFolderPickerForOrgWideLazyQuery
>;
export type SearchFolderPickerForOrgWideQueryResult = Apollo.QueryResult<
  SearchFolderPickerForOrgWideQuery,
  SearchFolderPickerForOrgWideQueryVariables
>;
export function refetchSearchFolderPickerForOrgWideQuery(
  variables?: SearchFolderPickerForOrgWideQueryVariables
) {
  return { query: SearchFolderPickerForOrgWideDocument, variables: variables };
}
export const UpdateShareFolderToAllSpacesDocument = gql`
  mutation UpdateShareFolderToAllSpaces(
    $input: UpdateShareFolderToAllSpacesInput!
  ) {
    updateShareFolderToAllSpaces(input: $input) {
      folder {
        __typename
        id
        name
      }
    }
  }
`;
export type UpdateShareFolderToAllSpacesMutationFn = Apollo.MutationFunction<
  UpdateShareFolderToAllSpacesMutation,
  UpdateShareFolderToAllSpacesMutationVariables
>;
export type UpdateShareFolderToAllSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateShareFolderToAllSpacesMutation,
    UpdateShareFolderToAllSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateShareFolderToAllSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateShareFolderToAllSpacesMutation,
    UpdateShareFolderToAllSpacesMutationVariables,
    UpdateShareFolderToAllSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateShareFolderToAllSpacesMutation,
    UpdateShareFolderToAllSpacesMutationVariables,
    UpdateShareFolderToAllSpacesProps<TChildProps, TDataName>
  >(UpdateShareFolderToAllSpacesDocument, {
    alias: "updateShareFolderToAllSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateShareFolderToAllSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateShareFolderToAllSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareFolderToAllSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareFolderToAllSpacesMutation, { data, loading, error }] = useUpdateShareFolderToAllSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareFolderToAllSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareFolderToAllSpacesMutation,
    UpdateShareFolderToAllSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareFolderToAllSpacesMutation,
    UpdateShareFolderToAllSpacesMutationVariables
  >(UpdateShareFolderToAllSpacesDocument, options);
}
export type UpdateShareFolderToAllSpacesMutationHookResult = ReturnType<
  typeof useUpdateShareFolderToAllSpacesMutation
>;
export type UpdateShareFolderToAllSpacesMutationResult = Apollo.MutationResult<UpdateShareFolderToAllSpacesMutation>;
export type UpdateShareFolderToAllSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareFolderToAllSpacesMutation,
  UpdateShareFolderToAllSpacesMutationVariables
>;
export const UpdateShareFolderToSpacesDocument = gql`
  mutation UpdateShareFolderToSpaces($input: UpdateShareFolderToSpacesInput!) {
    updateShareFolderToSpaces(input: $input) {
      folder {
        __typename
        id
        name
      }
    }
  }
`;
export type UpdateShareFolderToSpacesMutationFn = Apollo.MutationFunction<
  UpdateShareFolderToSpacesMutation,
  UpdateShareFolderToSpacesMutationVariables
>;
export type UpdateShareFolderToSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateShareFolderToSpacesMutation,
    UpdateShareFolderToSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateShareFolderToSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateShareFolderToSpacesMutation,
    UpdateShareFolderToSpacesMutationVariables,
    UpdateShareFolderToSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateShareFolderToSpacesMutation,
    UpdateShareFolderToSpacesMutationVariables,
    UpdateShareFolderToSpacesProps<TChildProps, TDataName>
  >(UpdateShareFolderToSpacesDocument, {
    alias: "updateShareFolderToSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateShareFolderToSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateShareFolderToSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareFolderToSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareFolderToSpacesMutation, { data, loading, error }] = useUpdateShareFolderToSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareFolderToSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareFolderToSpacesMutation,
    UpdateShareFolderToSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareFolderToSpacesMutation,
    UpdateShareFolderToSpacesMutationVariables
  >(UpdateShareFolderToSpacesDocument, options);
}
export type UpdateShareFolderToSpacesMutationHookResult = ReturnType<
  typeof useUpdateShareFolderToSpacesMutation
>;
export type UpdateShareFolderToSpacesMutationResult = Apollo.MutationResult<UpdateShareFolderToSpacesMutation>;
export type UpdateShareFolderToSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareFolderToSpacesMutation,
  UpdateShareFolderToSpacesMutationVariables
>;
export const GetInvoicesDocument = gql`
  query getInvoices($spaceId: UUID!) {
    getInvoices(spaceId: $spaceId) {
      invoices
    }
  }
`;
export type GetInvoicesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetInvoicesQuery,
    GetInvoicesQueryVariables
  >;
} &
  TChildProps;
export function withGetInvoices<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetInvoicesQuery,
    GetInvoicesQueryVariables,
    GetInvoicesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetInvoicesQuery,
    GetInvoicesQueryVariables,
    GetInvoicesProps<TChildProps, TDataName>
  >(GetInvoicesDocument, {
    alias: "getInvoices",
    ...operationOptions,
  });
}

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoicesQuery,
    GetInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
    GetInvoicesDocument,
    options
  );
}
export function useGetInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoicesQuery,
    GetInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
    GetInvoicesDocument,
    options
  );
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetInvoicesLazyQuery
>;
export type GetInvoicesQueryResult = Apollo.QueryResult<
  GetInvoicesQuery,
  GetInvoicesQueryVariables
>;
export function refetchGetInvoicesQuery(variables?: GetInvoicesQueryVariables) {
  return { query: GetInvoicesDocument, variables: variables };
}
export const GetInvoicePdfDocument = gql`
  query getInvoicePdf($spaceId: UUID!, $invoiceId: String) {
    getInvoicePdf(spaceId: $spaceId, invoiceId: $invoiceId) {
      downloadUrl
      validTill
    }
  }
`;
export type GetInvoicePdfProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetInvoicePdfQuery,
    GetInvoicePdfQueryVariables
  >;
} &
  TChildProps;
export function withGetInvoicePdf<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetInvoicePdfQuery,
    GetInvoicePdfQueryVariables,
    GetInvoicePdfProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetInvoicePdfQuery,
    GetInvoicePdfQueryVariables,
    GetInvoicePdfProps<TChildProps, TDataName>
  >(GetInvoicePdfDocument, {
    alias: "getInvoicePdf",
    ...operationOptions,
  });
}

/**
 * __useGetInvoicePdfQuery__
 *
 * To run a query within a React component, call `useGetInvoicePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicePdfQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoicePdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoicePdfQuery,
    GetInvoicePdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>(
    GetInvoicePdfDocument,
    options
  );
}
export function useGetInvoicePdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoicePdfQuery,
    GetInvoicePdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>(
    GetInvoicePdfDocument,
    options
  );
}
export type GetInvoicePdfQueryHookResult = ReturnType<
  typeof useGetInvoicePdfQuery
>;
export type GetInvoicePdfLazyQueryHookResult = ReturnType<
  typeof useGetInvoicePdfLazyQuery
>;
export type GetInvoicePdfQueryResult = Apollo.QueryResult<
  GetInvoicePdfQuery,
  GetInvoicePdfQueryVariables
>;
export function refetchGetInvoicePdfQuery(
  variables?: GetInvoicePdfQueryVariables
) {
  return { query: GetInvoicePdfDocument, variables: variables };
}
export const CreatePermissionSetDocument = gql`
  mutation CreatePermissionSet($input: CreatePermissionSetInput!) {
    createPermissionSet(input: $input) {
      permissionSet {
        __typename
        id
        name
        commonPermissionSetId
        description
        color
        permissions
      }
    }
  }
`;
export type CreatePermissionSetMutationFn = Apollo.MutationFunction<
  CreatePermissionSetMutation,
  CreatePermissionSetMutationVariables
>;
export type CreatePermissionSetProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreatePermissionSetMutation,
    CreatePermissionSetMutationVariables
  >;
} &
  TChildProps;
export function withCreatePermissionSet<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePermissionSetMutation,
    CreatePermissionSetMutationVariables,
    CreatePermissionSetProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePermissionSetMutation,
    CreatePermissionSetMutationVariables,
    CreatePermissionSetProps<TChildProps, TDataName>
  >(CreatePermissionSetDocument, {
    alias: "createPermissionSet",
    ...operationOptions,
  });
}

/**
 * __useCreatePermissionSetMutation__
 *
 * To run a mutation, you first call `useCreatePermissionSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionSetMutation, { data, loading, error }] = useCreatePermissionSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePermissionSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePermissionSetMutation,
    CreatePermissionSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePermissionSetMutation,
    CreatePermissionSetMutationVariables
  >(CreatePermissionSetDocument, options);
}
export type CreatePermissionSetMutationHookResult = ReturnType<
  typeof useCreatePermissionSetMutation
>;
export type CreatePermissionSetMutationResult = Apollo.MutationResult<CreatePermissionSetMutation>;
export type CreatePermissionSetMutationOptions = Apollo.BaseMutationOptions<
  CreatePermissionSetMutation,
  CreatePermissionSetMutationVariables
>;
export const DeletePermissionSetDocument = gql`
  mutation DeletePermissionSet($input: DeletePermissionSetInput!) {
    deletePermissionSet(input: $input) {
      permissionSet {
        __typename
        id
        name
        commonPermissionSetId
        description
        color
        permissions
      }
    }
  }
`;
export type DeletePermissionSetMutationFn = Apollo.MutationFunction<
  DeletePermissionSetMutation,
  DeletePermissionSetMutationVariables
>;
export type DeletePermissionSetProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeletePermissionSetMutation,
    DeletePermissionSetMutationVariables
  >;
} &
  TChildProps;
export function withDeletePermissionSet<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletePermissionSetMutation,
    DeletePermissionSetMutationVariables,
    DeletePermissionSetProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeletePermissionSetMutation,
    DeletePermissionSetMutationVariables,
    DeletePermissionSetProps<TChildProps, TDataName>
  >(DeletePermissionSetDocument, {
    alias: "deletePermissionSet",
    ...operationOptions,
  });
}

/**
 * __useDeletePermissionSetMutation__
 *
 * To run a mutation, you first call `useDeletePermissionSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionSetMutation, { data, loading, error }] = useDeletePermissionSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePermissionSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePermissionSetMutation,
    DeletePermissionSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePermissionSetMutation,
    DeletePermissionSetMutationVariables
  >(DeletePermissionSetDocument, options);
}
export type DeletePermissionSetMutationHookResult = ReturnType<
  typeof useDeletePermissionSetMutation
>;
export type DeletePermissionSetMutationResult = Apollo.MutationResult<DeletePermissionSetMutation>;
export type DeletePermissionSetMutationOptions = Apollo.BaseMutationOptions<
  DeletePermissionSetMutation,
  DeletePermissionSetMutationVariables
>;
export const UpdatePermissionSetDocument = gql`
  mutation UpdatePermissionSet($input: UpdatePermissionSetInput!) {
    updatePermissionSet(input: $input) {
      permissionSet {
        __typename
        id
        name
        commonPermissionSetId
        description
        color
        permissions
      }
    }
  }
`;
export type UpdatePermissionSetMutationFn = Apollo.MutationFunction<
  UpdatePermissionSetMutation,
  UpdatePermissionSetMutationVariables
>;
export type UpdatePermissionSetProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdatePermissionSetMutation,
    UpdatePermissionSetMutationVariables
  >;
} &
  TChildProps;
export function withUpdatePermissionSet<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePermissionSetMutation,
    UpdatePermissionSetMutationVariables,
    UpdatePermissionSetProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePermissionSetMutation,
    UpdatePermissionSetMutationVariables,
    UpdatePermissionSetProps<TChildProps, TDataName>
  >(UpdatePermissionSetDocument, {
    alias: "updatePermissionSet",
    ...operationOptions,
  });
}

/**
 * __useUpdatePermissionSetMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionSetMutation, { data, loading, error }] = useUpdatePermissionSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePermissionSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePermissionSetMutation,
    UpdatePermissionSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePermissionSetMutation,
    UpdatePermissionSetMutationVariables
  >(UpdatePermissionSetDocument, options);
}
export type UpdatePermissionSetMutationHookResult = ReturnType<
  typeof useUpdatePermissionSetMutation
>;
export type UpdatePermissionSetMutationResult = Apollo.MutationResult<UpdatePermissionSetMutation>;
export type UpdatePermissionSetMutationOptions = Apollo.BaseMutationOptions<
  UpdatePermissionSetMutation,
  UpdatePermissionSetMutationVariables
>;
export const ChannelByIdForPlayerPreviewDocument = gql`
  query channelByIdForPlayerPreview($id: UUID!) {
    channelById(id: $id) {
      ...Channel
      orgByOrgId {
        ...Org
        themeByDefaultChannelThemeId {
          __typename
          id
          name
          orgId
          logoFileId
          fileByLogoFileId {
            __typename
            id
            name
            mimetype
            source
          }
          config
        }
      }
      layoutByChannel {
        ...Layout
      }
      playlistsByChannelId {
        nodes {
          ...Playlist
        }
      }
      filesByChannelId {
        nodes {
          ...File
          fileOutputsByFileId {
            nodes {
              ...FileOutput
            }
          }
        }
      }
      linksByChannelId {
        nodes {
          ...Link
        }
      }
      sitesByChannelId {
        nodes {
          ...Site
        }
      }
      appInstancesByChannelId {
        nodes {
          ...AppInstanceForContentList
          filesByAppInstanceId {
            nodes {
              ...File
            }
          }
          appInstallByAppInstallId {
            ...AppInstall
          }
        }
      }
      userByUpdatedBy {
        ...User
      }
      userByPublishedBy {
        ...User
      }
      childOf
      parentChannelByChildOf {
        ...ParentChannel
      }
    }
  }
  ${ChannelFragmentDoc}
  ${OrgFragmentDoc}
  ${LayoutFragmentDoc}
  ${PlaylistFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
  ${AppInstanceForContentListFragmentDoc}
  ${AppInstallFragmentDoc}
  ${UserFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export type ChannelByIdForPlayerPreviewProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ChannelByIdForPlayerPreviewQuery,
    ChannelByIdForPlayerPreviewQueryVariables
  >;
} &
  TChildProps;
export function withChannelByIdForPlayerPreview<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChannelByIdForPlayerPreviewQuery,
    ChannelByIdForPlayerPreviewQueryVariables,
    ChannelByIdForPlayerPreviewProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ChannelByIdForPlayerPreviewQuery,
    ChannelByIdForPlayerPreviewQueryVariables,
    ChannelByIdForPlayerPreviewProps<TChildProps, TDataName>
  >(ChannelByIdForPlayerPreviewDocument, {
    alias: "channelByIdForPlayerPreview",
    ...operationOptions,
  });
}

/**
 * __useChannelByIdForPlayerPreviewQuery__
 *
 * To run a query within a React component, call `useChannelByIdForPlayerPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelByIdForPlayerPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelByIdForPlayerPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChannelByIdForPlayerPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChannelByIdForPlayerPreviewQuery,
    ChannelByIdForPlayerPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChannelByIdForPlayerPreviewQuery,
    ChannelByIdForPlayerPreviewQueryVariables
  >(ChannelByIdForPlayerPreviewDocument, options);
}
export function useChannelByIdForPlayerPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChannelByIdForPlayerPreviewQuery,
    ChannelByIdForPlayerPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChannelByIdForPlayerPreviewQuery,
    ChannelByIdForPlayerPreviewQueryVariables
  >(ChannelByIdForPlayerPreviewDocument, options);
}
export type ChannelByIdForPlayerPreviewQueryHookResult = ReturnType<
  typeof useChannelByIdForPlayerPreviewQuery
>;
export type ChannelByIdForPlayerPreviewLazyQueryHookResult = ReturnType<
  typeof useChannelByIdForPlayerPreviewLazyQuery
>;
export type ChannelByIdForPlayerPreviewQueryResult = Apollo.QueryResult<
  ChannelByIdForPlayerPreviewQuery,
  ChannelByIdForPlayerPreviewQueryVariables
>;
export function refetchChannelByIdForPlayerPreviewQuery(
  variables?: ChannelByIdForPlayerPreviewQueryVariables
) {
  return { query: ChannelByIdForPlayerPreviewDocument, variables: variables };
}
export const PlaylistByIdForPlayerPreviewDocument = gql`
  query playlistByIdForPlayerPreview($id: UUID!) {
    playlistById(id: $id) {
      __typename
      id
      tags
      name
      color
      publishedAt
      publishedBy
      content
      isFavorite
      isPublished
      orgByOrgId {
        ...Org
        themeByDefaultChannelThemeId {
          id
          name
          orgId
          logoFileId
          fileByLogoFileId {
            id
            name
            mimetype
            source
          }
          config
        }
      }
      appInstancesByPlaylistId {
        nodes {
          ...AppInstanceForContentList
          filesByAppInstanceId {
            nodes {
              ...File
            }
          }
          appInstallByAppInstallId {
            __typename
            appId
            id
          }
        }
      }
      filesByPlaylistId {
        nodes {
          ...FileListItem
        }
      }
      linksByPlaylistId {
        nodes {
          ...Link
        }
      }
      sitesByPlaylistId {
        nodes {
          ...Site
        }
      }
      userByPublishedBy {
        __typename
        id
        givenName
        familyName
      }
    }
  }
  ${OrgFragmentDoc}
  ${AppInstanceForContentListFragmentDoc}
  ${FileFragmentDoc}
  ${FileListItemFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
`;
export type PlaylistByIdForPlayerPreviewProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PlaylistByIdForPlayerPreviewQuery,
    PlaylistByIdForPlayerPreviewQueryVariables
  >;
} &
  TChildProps;
export function withPlaylistByIdForPlayerPreview<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PlaylistByIdForPlayerPreviewQuery,
    PlaylistByIdForPlayerPreviewQueryVariables,
    PlaylistByIdForPlayerPreviewProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PlaylistByIdForPlayerPreviewQuery,
    PlaylistByIdForPlayerPreviewQueryVariables,
    PlaylistByIdForPlayerPreviewProps<TChildProps, TDataName>
  >(PlaylistByIdForPlayerPreviewDocument, {
    alias: "playlistByIdForPlayerPreview",
    ...operationOptions,
  });
}

/**
 * __usePlaylistByIdForPlayerPreviewQuery__
 *
 * To run a query within a React component, call `usePlaylistByIdForPlayerPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistByIdForPlayerPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistByIdForPlayerPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistByIdForPlayerPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistByIdForPlayerPreviewQuery,
    PlaylistByIdForPlayerPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlaylistByIdForPlayerPreviewQuery,
    PlaylistByIdForPlayerPreviewQueryVariables
  >(PlaylistByIdForPlayerPreviewDocument, options);
}
export function usePlaylistByIdForPlayerPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistByIdForPlayerPreviewQuery,
    PlaylistByIdForPlayerPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlaylistByIdForPlayerPreviewQuery,
    PlaylistByIdForPlayerPreviewQueryVariables
  >(PlaylistByIdForPlayerPreviewDocument, options);
}
export type PlaylistByIdForPlayerPreviewQueryHookResult = ReturnType<
  typeof usePlaylistByIdForPlayerPreviewQuery
>;
export type PlaylistByIdForPlayerPreviewLazyQueryHookResult = ReturnType<
  typeof usePlaylistByIdForPlayerPreviewLazyQuery
>;
export type PlaylistByIdForPlayerPreviewQueryResult = Apollo.QueryResult<
  PlaylistByIdForPlayerPreviewQuery,
  PlaylistByIdForPlayerPreviewQueryVariables
>;
export function refetchPlaylistByIdForPlayerPreviewQuery(
  variables?: PlaylistByIdForPlayerPreviewQueryVariables
) {
  return { query: PlaylistByIdForPlayerPreviewDocument, variables: variables };
}
export const PlaylistAssociationByIdDocument = gql`
  query playlistAssociationById($id: UUID!) {
    playlistById(id: $id) {
      ...PlaylistAssociation
    }
  }
  ${PlaylistAssociationFragmentDoc}
`;
export type PlaylistAssociationByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PlaylistAssociationByIdQuery,
    PlaylistAssociationByIdQueryVariables
  >;
} &
  TChildProps;
export function withPlaylistAssociationById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PlaylistAssociationByIdQuery,
    PlaylistAssociationByIdQueryVariables,
    PlaylistAssociationByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PlaylistAssociationByIdQuery,
    PlaylistAssociationByIdQueryVariables,
    PlaylistAssociationByIdProps<TChildProps, TDataName>
  >(PlaylistAssociationByIdDocument, {
    alias: "playlistAssociationById",
    ...operationOptions,
  });
}

/**
 * __usePlaylistAssociationByIdQuery__
 *
 * To run a query within a React component, call `usePlaylistAssociationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistAssociationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistAssociationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistAssociationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistAssociationByIdQuery,
    PlaylistAssociationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlaylistAssociationByIdQuery,
    PlaylistAssociationByIdQueryVariables
  >(PlaylistAssociationByIdDocument, options);
}
export function usePlaylistAssociationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistAssociationByIdQuery,
    PlaylistAssociationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlaylistAssociationByIdQuery,
    PlaylistAssociationByIdQueryVariables
  >(PlaylistAssociationByIdDocument, options);
}
export type PlaylistAssociationByIdQueryHookResult = ReturnType<
  typeof usePlaylistAssociationByIdQuery
>;
export type PlaylistAssociationByIdLazyQueryHookResult = ReturnType<
  typeof usePlaylistAssociationByIdLazyQuery
>;
export type PlaylistAssociationByIdQueryResult = Apollo.QueryResult<
  PlaylistAssociationByIdQuery,
  PlaylistAssociationByIdQueryVariables
>;
export function refetchPlaylistAssociationByIdQuery(
  variables?: PlaylistAssociationByIdQueryVariables
) {
  return { query: PlaylistAssociationByIdDocument, variables: variables };
}
export const AllInternalPlaylistsInOrgDocument = gql`
  query allInternalPlaylistsInOrg(
    $orgId: UUID!
    $first: Int
    $endCursor: Cursor
  ) {
    orgById(id: $orgId) {
      id
      name
      playlistsByOrgId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        filter: {
          draftOf: { isNull: true }
          isPublished: { equalTo: true }
          isEnabled: { equalTo: true }
          isSharedAll: { equalTo: false }
          isShared: { equalTo: false }
        }
      ) {
        nodes {
          ...PlaylistListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type AllInternalPlaylistsInOrgProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllInternalPlaylistsInOrgQuery,
    AllInternalPlaylistsInOrgQueryVariables
  >;
} &
  TChildProps;
export function withAllInternalPlaylistsInOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllInternalPlaylistsInOrgQuery,
    AllInternalPlaylistsInOrgQueryVariables,
    AllInternalPlaylistsInOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllInternalPlaylistsInOrgQuery,
    AllInternalPlaylistsInOrgQueryVariables,
    AllInternalPlaylistsInOrgProps<TChildProps, TDataName>
  >(AllInternalPlaylistsInOrgDocument, {
    alias: "allInternalPlaylistsInOrg",
    ...operationOptions,
  });
}

/**
 * __useAllInternalPlaylistsInOrgQuery__
 *
 * To run a query within a React component, call `useAllInternalPlaylistsInOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInternalPlaylistsInOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInternalPlaylistsInOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useAllInternalPlaylistsInOrgQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllInternalPlaylistsInOrgQuery,
    AllInternalPlaylistsInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllInternalPlaylistsInOrgQuery,
    AllInternalPlaylistsInOrgQueryVariables
  >(AllInternalPlaylistsInOrgDocument, options);
}
export function useAllInternalPlaylistsInOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllInternalPlaylistsInOrgQuery,
    AllInternalPlaylistsInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllInternalPlaylistsInOrgQuery,
    AllInternalPlaylistsInOrgQueryVariables
  >(AllInternalPlaylistsInOrgDocument, options);
}
export type AllInternalPlaylistsInOrgQueryHookResult = ReturnType<
  typeof useAllInternalPlaylistsInOrgQuery
>;
export type AllInternalPlaylistsInOrgLazyQueryHookResult = ReturnType<
  typeof useAllInternalPlaylistsInOrgLazyQuery
>;
export type AllInternalPlaylistsInOrgQueryResult = Apollo.QueryResult<
  AllInternalPlaylistsInOrgQuery,
  AllInternalPlaylistsInOrgQueryVariables
>;
export function refetchAllInternalPlaylistsInOrgQuery(
  variables?: AllInternalPlaylistsInOrgQueryVariables
) {
  return { query: AllInternalPlaylistsInOrgDocument, variables: variables };
}
export const AllInternalPlaylistsInSpaceDocument = gql`
  query allInternalPlaylistsInSpace(
    $spaceId: UUID!
    $first: Int
    $endCursor: Cursor
  ) {
    spaceById(id: $spaceId) {
      id
      name
      publishedPlaylistsBySpaceId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        filter: { and: { isEnabled: { equalTo: true } } }
      ) {
        nodes {
          ...PlaylistListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type AllInternalPlaylistsInSpaceProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllInternalPlaylistsInSpaceQuery,
    AllInternalPlaylistsInSpaceQueryVariables
  >;
} &
  TChildProps;
export function withAllInternalPlaylistsInSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllInternalPlaylistsInSpaceQuery,
    AllInternalPlaylistsInSpaceQueryVariables,
    AllInternalPlaylistsInSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllInternalPlaylistsInSpaceQuery,
    AllInternalPlaylistsInSpaceQueryVariables,
    AllInternalPlaylistsInSpaceProps<TChildProps, TDataName>
  >(AllInternalPlaylistsInSpaceDocument, {
    alias: "allInternalPlaylistsInSpace",
    ...operationOptions,
  });
}

/**
 * __useAllInternalPlaylistsInSpaceQuery__
 *
 * To run a query within a React component, call `useAllInternalPlaylistsInSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInternalPlaylistsInSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInternalPlaylistsInSpaceQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useAllInternalPlaylistsInSpaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllInternalPlaylistsInSpaceQuery,
    AllInternalPlaylistsInSpaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllInternalPlaylistsInSpaceQuery,
    AllInternalPlaylistsInSpaceQueryVariables
  >(AllInternalPlaylistsInSpaceDocument, options);
}
export function useAllInternalPlaylistsInSpaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllInternalPlaylistsInSpaceQuery,
    AllInternalPlaylistsInSpaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllInternalPlaylistsInSpaceQuery,
    AllInternalPlaylistsInSpaceQueryVariables
  >(AllInternalPlaylistsInSpaceDocument, options);
}
export type AllInternalPlaylistsInSpaceQueryHookResult = ReturnType<
  typeof useAllInternalPlaylistsInSpaceQuery
>;
export type AllInternalPlaylistsInSpaceLazyQueryHookResult = ReturnType<
  typeof useAllInternalPlaylistsInSpaceLazyQuery
>;
export type AllInternalPlaylistsInSpaceQueryResult = Apollo.QueryResult<
  AllInternalPlaylistsInSpaceQuery,
  AllInternalPlaylistsInSpaceQueryVariables
>;
export function refetchAllInternalPlaylistsInSpaceQuery(
  variables?: AllInternalPlaylistsInSpaceQueryVariables
) {
  return { query: AllInternalPlaylistsInSpaceDocument, variables: variables };
}
export const AllPlaylistsInOrgDocument = gql`
  query allPlaylistsInOrg($orgId: UUID!, $first: Int, $endCursor: Cursor) {
    orgById(id: $orgId) {
      id
      name
      playlistsByOrgId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        filter: {
          draftOf: { isNull: true }
          isPublished: { equalTo: true }
          isEnabled: { equalTo: true }
        }
      ) {
        nodes {
          ...PlaylistListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type AllPlaylistsInOrgProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllPlaylistsInOrgQuery,
    AllPlaylistsInOrgQueryVariables
  >;
} &
  TChildProps;
export function withAllPlaylistsInOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPlaylistsInOrgQuery,
    AllPlaylistsInOrgQueryVariables,
    AllPlaylistsInOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPlaylistsInOrgQuery,
    AllPlaylistsInOrgQueryVariables,
    AllPlaylistsInOrgProps<TChildProps, TDataName>
  >(AllPlaylistsInOrgDocument, {
    alias: "allPlaylistsInOrg",
    ...operationOptions,
  });
}

/**
 * __useAllPlaylistsInOrgQuery__
 *
 * To run a query within a React component, call `useAllPlaylistsInOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPlaylistsInOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPlaylistsInOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useAllPlaylistsInOrgQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllPlaylistsInOrgQuery,
    AllPlaylistsInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllPlaylistsInOrgQuery,
    AllPlaylistsInOrgQueryVariables
  >(AllPlaylistsInOrgDocument, options);
}
export function useAllPlaylistsInOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPlaylistsInOrgQuery,
    AllPlaylistsInOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPlaylistsInOrgQuery,
    AllPlaylistsInOrgQueryVariables
  >(AllPlaylistsInOrgDocument, options);
}
export type AllPlaylistsInOrgQueryHookResult = ReturnType<
  typeof useAllPlaylistsInOrgQuery
>;
export type AllPlaylistsInOrgLazyQueryHookResult = ReturnType<
  typeof useAllPlaylistsInOrgLazyQuery
>;
export type AllPlaylistsInOrgQueryResult = Apollo.QueryResult<
  AllPlaylistsInOrgQuery,
  AllPlaylistsInOrgQueryVariables
>;
export function refetchAllPlaylistsInOrgQuery(
  variables?: AllPlaylistsInOrgQueryVariables
) {
  return { query: AllPlaylistsInOrgDocument, variables: variables };
}
export const AllPlaylistsInSpaceDocument = gql`
  query allPlaylistsInSpace($spaceId: UUID!, $first: Int, $endCursor: Cursor) {
    spaceById(id: $spaceId) {
      id
      name
      publishedPlaylistsBySpaceId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        filter: { isEnabled: { equalTo: true } }
      ) {
        nodes {
          ...PlaylistListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type AllPlaylistsInSpaceProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllPlaylistsInSpaceQuery,
    AllPlaylistsInSpaceQueryVariables
  >;
} &
  TChildProps;
export function withAllPlaylistsInSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPlaylistsInSpaceQuery,
    AllPlaylistsInSpaceQueryVariables,
    AllPlaylistsInSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPlaylistsInSpaceQuery,
    AllPlaylistsInSpaceQueryVariables,
    AllPlaylistsInSpaceProps<TChildProps, TDataName>
  >(AllPlaylistsInSpaceDocument, {
    alias: "allPlaylistsInSpace",
    ...operationOptions,
  });
}

/**
 * __useAllPlaylistsInSpaceQuery__
 *
 * To run a query within a React component, call `useAllPlaylistsInSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPlaylistsInSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPlaylistsInSpaceQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useAllPlaylistsInSpaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllPlaylistsInSpaceQuery,
    AllPlaylistsInSpaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllPlaylistsInSpaceQuery,
    AllPlaylistsInSpaceQueryVariables
  >(AllPlaylistsInSpaceDocument, options);
}
export function useAllPlaylistsInSpaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPlaylistsInSpaceQuery,
    AllPlaylistsInSpaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPlaylistsInSpaceQuery,
    AllPlaylistsInSpaceQueryVariables
  >(AllPlaylistsInSpaceDocument, options);
}
export type AllPlaylistsInSpaceQueryHookResult = ReturnType<
  typeof useAllPlaylistsInSpaceQuery
>;
export type AllPlaylistsInSpaceLazyQueryHookResult = ReturnType<
  typeof useAllPlaylistsInSpaceLazyQuery
>;
export type AllPlaylistsInSpaceQueryResult = Apollo.QueryResult<
  AllPlaylistsInSpaceQuery,
  AllPlaylistsInSpaceQueryVariables
>;
export function refetchAllPlaylistsInSpaceQuery(
  variables?: AllPlaylistsInSpaceQueryVariables
) {
  return { query: AllPlaylistsInSpaceDocument, variables: variables };
}
export const SearchPlaylistPickerDocument = gql`
  query searchPlaylistPicker(
    $query: String
    $spaceId: UUID
    $first: Int
    $endCursor: Cursor
  ) {
    searchPlaylist(
      query: $query
      spaceId: $spaceId
      first: $first
      after: $endCursor
      orderBy: [NAME_ASC, ID_DESC]
      filter: { isEnabled: { equalTo: true } }
    ) {
      nodes {
        ...PlaylistListItem
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type SearchPlaylistPickerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchPlaylistPickerQuery,
    SearchPlaylistPickerQueryVariables
  >;
} &
  TChildProps;
export function withSearchPlaylistPicker<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchPlaylistPickerQuery,
    SearchPlaylistPickerQueryVariables,
    SearchPlaylistPickerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchPlaylistPickerQuery,
    SearchPlaylistPickerQueryVariables,
    SearchPlaylistPickerProps<TChildProps, TDataName>
  >(SearchPlaylistPickerDocument, {
    alias: "searchPlaylistPicker",
    ...operationOptions,
  });
}

/**
 * __useSearchPlaylistPickerQuery__
 *
 * To run a query within a React component, call `useSearchPlaylistPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlaylistPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlaylistPickerQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useSearchPlaylistPickerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchPlaylistPickerQuery,
    SearchPlaylistPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchPlaylistPickerQuery,
    SearchPlaylistPickerQueryVariables
  >(SearchPlaylistPickerDocument, options);
}
export function useSearchPlaylistPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPlaylistPickerQuery,
    SearchPlaylistPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchPlaylistPickerQuery,
    SearchPlaylistPickerQueryVariables
  >(SearchPlaylistPickerDocument, options);
}
export type SearchPlaylistPickerQueryHookResult = ReturnType<
  typeof useSearchPlaylistPickerQuery
>;
export type SearchPlaylistPickerLazyQueryHookResult = ReturnType<
  typeof useSearchPlaylistPickerLazyQuery
>;
export type SearchPlaylistPickerQueryResult = Apollo.QueryResult<
  SearchPlaylistPickerQuery,
  SearchPlaylistPickerQueryVariables
>;
export function refetchSearchPlaylistPickerQuery(
  variables?: SearchPlaylistPickerQueryVariables
) {
  return { query: SearchPlaylistPickerDocument, variables: variables };
}
export const OrganisationQuotaDocument = gql`
  query organisationQuota($orgId: UUID!, $usageType: String!) {
    organisationQuota(orgId: $orgId, usageType: $usageType) {
      orgId
      quotaLimit
      quotaUnits
      unitsUsed
    }
  }
`;
export type OrganisationQuotaProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganisationQuotaQuery,
    OrganisationQuotaQueryVariables
  >;
} &
  TChildProps;
export function withOrganisationQuota<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganisationQuotaQuery,
    OrganisationQuotaQueryVariables,
    OrganisationQuotaProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganisationQuotaQuery,
    OrganisationQuotaQueryVariables,
    OrganisationQuotaProps<TChildProps, TDataName>
  >(OrganisationQuotaDocument, {
    alias: "organisationQuota",
    ...operationOptions,
  });
}

/**
 * __useOrganisationQuotaQuery__
 *
 * To run a query within a React component, call `useOrganisationQuotaQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationQuotaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationQuotaQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      usageType: // value for 'usageType'
 *   },
 * });
 */
export function useOrganisationQuotaQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganisationQuotaQuery,
    OrganisationQuotaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganisationQuotaQuery,
    OrganisationQuotaQueryVariables
  >(OrganisationQuotaDocument, options);
}
export function useOrganisationQuotaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganisationQuotaQuery,
    OrganisationQuotaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganisationQuotaQuery,
    OrganisationQuotaQueryVariables
  >(OrganisationQuotaDocument, options);
}
export type OrganisationQuotaQueryHookResult = ReturnType<
  typeof useOrganisationQuotaQuery
>;
export type OrganisationQuotaLazyQueryHookResult = ReturnType<
  typeof useOrganisationQuotaLazyQuery
>;
export type OrganisationQuotaQueryResult = Apollo.QueryResult<
  OrganisationQuotaQuery,
  OrganisationQuotaQueryVariables
>;
export function refetchOrganisationQuotaQuery(
  variables?: OrganisationQuotaQueryVariables
) {
  return { query: OrganisationQuotaDocument, variables: variables };
}
export const ScreenContentHistoriesByScreenIdDocument = gql`
  query screenContentHistoriesByScreenId(
    $id: UUID!
    $first: Int
    $spaceId: UUID
  ) {
    screenById(id: $id) {
      __typename
      id
      name
      content
      deviceProvider
      screenContentHistoriesByScreenId(
        orderBy: [CREATED_AT_DESC]
        first: $first
        condition: { spaceId: $spaceId }
      ) {
        nodes {
          ...ScreenContentHistoryItem
        }
      }
    }
  }
  ${ScreenContentHistoryItemFragmentDoc}
`;
export type ScreenContentHistoriesByScreenIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenContentHistoriesByScreenIdQuery,
    ScreenContentHistoriesByScreenIdQueryVariables
  >;
} &
  TChildProps;
export function withScreenContentHistoriesByScreenId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenContentHistoriesByScreenIdQuery,
    ScreenContentHistoriesByScreenIdQueryVariables,
    ScreenContentHistoriesByScreenIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenContentHistoriesByScreenIdQuery,
    ScreenContentHistoriesByScreenIdQueryVariables,
    ScreenContentHistoriesByScreenIdProps<TChildProps, TDataName>
  >(ScreenContentHistoriesByScreenIdDocument, {
    alias: "screenContentHistoriesByScreenId",
    ...operationOptions,
  });
}

/**
 * __useScreenContentHistoriesByScreenIdQuery__
 *
 * To run a query within a React component, call `useScreenContentHistoriesByScreenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenContentHistoriesByScreenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenContentHistoriesByScreenIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useScreenContentHistoriesByScreenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenContentHistoriesByScreenIdQuery,
    ScreenContentHistoriesByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScreenContentHistoriesByScreenIdQuery,
    ScreenContentHistoriesByScreenIdQueryVariables
  >(ScreenContentHistoriesByScreenIdDocument, options);
}
export function useScreenContentHistoriesByScreenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenContentHistoriesByScreenIdQuery,
    ScreenContentHistoriesByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScreenContentHistoriesByScreenIdQuery,
    ScreenContentHistoriesByScreenIdQueryVariables
  >(ScreenContentHistoriesByScreenIdDocument, options);
}
export type ScreenContentHistoriesByScreenIdQueryHookResult = ReturnType<
  typeof useScreenContentHistoriesByScreenIdQuery
>;
export type ScreenContentHistoriesByScreenIdLazyQueryHookResult = ReturnType<
  typeof useScreenContentHistoriesByScreenIdLazyQuery
>;
export type ScreenContentHistoriesByScreenIdQueryResult = Apollo.QueryResult<
  ScreenContentHistoriesByScreenIdQuery,
  ScreenContentHistoriesByScreenIdQueryVariables
>;
export function refetchScreenContentHistoriesByScreenIdQuery(
  variables?: ScreenContentHistoriesByScreenIdQueryVariables
) {
  return {
    query: ScreenContentHistoriesByScreenIdDocument,
    variables: variables,
  };
}
export const CreateContentReplacementJobDocument = gql`
  mutation CreateContentReplacementJob(
    $input: CreateContentReplacementJobInput!
  ) {
    createContentReplacementJob(input: $input) {
      clientMutationId
      contentReplacementJob {
        id
        orgId
        spaceId
        originContentId
        originContentType
        newContentId
        newContentType
        targetEntities
        status
        createdAt
      }
    }
  }
`;
export type CreateContentReplacementJobMutationFn = Apollo.MutationFunction<
  CreateContentReplacementJobMutation,
  CreateContentReplacementJobMutationVariables
>;
export type CreateContentReplacementJobProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateContentReplacementJobMutation,
    CreateContentReplacementJobMutationVariables
  >;
} &
  TChildProps;
export function withCreateContentReplacementJob<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateContentReplacementJobMutation,
    CreateContentReplacementJobMutationVariables,
    CreateContentReplacementJobProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateContentReplacementJobMutation,
    CreateContentReplacementJobMutationVariables,
    CreateContentReplacementJobProps<TChildProps, TDataName>
  >(CreateContentReplacementJobDocument, {
    alias: "createContentReplacementJob",
    ...operationOptions,
  });
}

/**
 * __useCreateContentReplacementJobMutation__
 *
 * To run a mutation, you first call `useCreateContentReplacementJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentReplacementJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentReplacementJobMutation, { data, loading, error }] = useCreateContentReplacementJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContentReplacementJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContentReplacementJobMutation,
    CreateContentReplacementJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContentReplacementJobMutation,
    CreateContentReplacementJobMutationVariables
  >(CreateContentReplacementJobDocument, options);
}
export type CreateContentReplacementJobMutationHookResult = ReturnType<
  typeof useCreateContentReplacementJobMutation
>;
export type CreateContentReplacementJobMutationResult = Apollo.MutationResult<CreateContentReplacementJobMutation>;
export type CreateContentReplacementJobMutationOptions = Apollo.BaseMutationOptions<
  CreateContentReplacementJobMutation,
  CreateContentReplacementJobMutationVariables
>;
export const GetCurrentReplacementJobsDocument = gql`
  query GetCurrentReplacementJobs($spaceId: UUID!) {
    getCurrentReplacementJobs(spaceId: $spaceId, orderBy: [CREATED_AT_DESC]) {
      totalCount
      nodes {
        id
        originalFile {
          name
        }
        originalAppInstance {
          name
        }
      }
    }
  }
`;
export type GetCurrentReplacementJobsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCurrentReplacementJobsQuery,
    GetCurrentReplacementJobsQueryVariables
  >;
} &
  TChildProps;
export function withGetCurrentReplacementJobs<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCurrentReplacementJobsQuery,
    GetCurrentReplacementJobsQueryVariables,
    GetCurrentReplacementJobsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCurrentReplacementJobsQuery,
    GetCurrentReplacementJobsQueryVariables,
    GetCurrentReplacementJobsProps<TChildProps, TDataName>
  >(GetCurrentReplacementJobsDocument, {
    alias: "getCurrentReplacementJobs",
    ...operationOptions,
  });
}

/**
 * __useGetCurrentReplacementJobsQuery__
 *
 * To run a query within a React component, call `useGetCurrentReplacementJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentReplacementJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentReplacementJobsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetCurrentReplacementJobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentReplacementJobsQuery,
    GetCurrentReplacementJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentReplacementJobsQuery,
    GetCurrentReplacementJobsQueryVariables
  >(GetCurrentReplacementJobsDocument, options);
}
export function useGetCurrentReplacementJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentReplacementJobsQuery,
    GetCurrentReplacementJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentReplacementJobsQuery,
    GetCurrentReplacementJobsQueryVariables
  >(GetCurrentReplacementJobsDocument, options);
}
export type GetCurrentReplacementJobsQueryHookResult = ReturnType<
  typeof useGetCurrentReplacementJobsQuery
>;
export type GetCurrentReplacementJobsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentReplacementJobsLazyQuery
>;
export type GetCurrentReplacementJobsQueryResult = Apollo.QueryResult<
  GetCurrentReplacementJobsQuery,
  GetCurrentReplacementJobsQueryVariables
>;
export function refetchGetCurrentReplacementJobsQuery(
  variables?: GetCurrentReplacementJobsQueryVariables
) {
  return { query: GetCurrentReplacementJobsDocument, variables: variables };
}
export const ScreenNoteByIdDocument = gql`
  query screenNoteById($id: UUID!) {
    screenNoteById(id: $id) {
      ...ScreenNote
    }
  }
  ${ScreenNoteFragmentDoc}
`;
export type ScreenNoteByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenNoteByIdQuery,
    ScreenNoteByIdQueryVariables
  >;
} &
  TChildProps;
export function withScreenNoteById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenNoteByIdQuery,
    ScreenNoteByIdQueryVariables,
    ScreenNoteByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenNoteByIdQuery,
    ScreenNoteByIdQueryVariables,
    ScreenNoteByIdProps<TChildProps, TDataName>
  >(ScreenNoteByIdDocument, {
    alias: "screenNoteById",
    ...operationOptions,
  });
}

/**
 * __useScreenNoteByIdQuery__
 *
 * To run a query within a React component, call `useScreenNoteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenNoteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenNoteByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScreenNoteByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenNoteByIdQuery,
    ScreenNoteByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScreenNoteByIdQuery, ScreenNoteByIdQueryVariables>(
    ScreenNoteByIdDocument,
    options
  );
}
export function useScreenNoteByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenNoteByIdQuery,
    ScreenNoteByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScreenNoteByIdQuery, ScreenNoteByIdQueryVariables>(
    ScreenNoteByIdDocument,
    options
  );
}
export type ScreenNoteByIdQueryHookResult = ReturnType<
  typeof useScreenNoteByIdQuery
>;
export type ScreenNoteByIdLazyQueryHookResult = ReturnType<
  typeof useScreenNoteByIdLazyQuery
>;
export type ScreenNoteByIdQueryResult = Apollo.QueryResult<
  ScreenNoteByIdQuery,
  ScreenNoteByIdQueryVariables
>;
export function refetchScreenNoteByIdQuery(
  variables?: ScreenNoteByIdQueryVariables
) {
  return { query: ScreenNoteByIdDocument, variables: variables };
}
export const ScreenNotesByScreenIdDocument = gql`
  query screenNotesByScreenId($screenId: UUID!) {
    screenById(id: $screenId) {
      screenNotesByScreenId {
        nodes {
          ...ScreenNote
        }
      }
    }
  }
  ${ScreenNoteFragmentDoc}
`;
export type ScreenNotesByScreenIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenNotesByScreenIdQuery,
    ScreenNotesByScreenIdQueryVariables
  >;
} &
  TChildProps;
export function withScreenNotesByScreenId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenNotesByScreenIdQuery,
    ScreenNotesByScreenIdQueryVariables,
    ScreenNotesByScreenIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenNotesByScreenIdQuery,
    ScreenNotesByScreenIdQueryVariables,
    ScreenNotesByScreenIdProps<TChildProps, TDataName>
  >(ScreenNotesByScreenIdDocument, {
    alias: "screenNotesByScreenId",
    ...operationOptions,
  });
}

/**
 * __useScreenNotesByScreenIdQuery__
 *
 * To run a query within a React component, call `useScreenNotesByScreenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenNotesByScreenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenNotesByScreenIdQuery({
 *   variables: {
 *      screenId: // value for 'screenId'
 *   },
 * });
 */
export function useScreenNotesByScreenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenNotesByScreenIdQuery,
    ScreenNotesByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScreenNotesByScreenIdQuery,
    ScreenNotesByScreenIdQueryVariables
  >(ScreenNotesByScreenIdDocument, options);
}
export function useScreenNotesByScreenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenNotesByScreenIdQuery,
    ScreenNotesByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScreenNotesByScreenIdQuery,
    ScreenNotesByScreenIdQueryVariables
  >(ScreenNotesByScreenIdDocument, options);
}
export type ScreenNotesByScreenIdQueryHookResult = ReturnType<
  typeof useScreenNotesByScreenIdQuery
>;
export type ScreenNotesByScreenIdLazyQueryHookResult = ReturnType<
  typeof useScreenNotesByScreenIdLazyQuery
>;
export type ScreenNotesByScreenIdQueryResult = Apollo.QueryResult<
  ScreenNotesByScreenIdQuery,
  ScreenNotesByScreenIdQueryVariables
>;
export function refetchScreenNotesByScreenIdQuery(
  variables?: ScreenNotesByScreenIdQueryVariables
) {
  return { query: ScreenNotesByScreenIdDocument, variables: variables };
}
export const CreateScreenNoteDocument = gql`
  mutation createScreenNote($input: CreateScreenNoteInput!) {
    createScreenNote(input: $input) {
      screenNote {
        ...ScreenNote
      }
    }
  }
  ${ScreenNoteFragmentDoc}
`;
export type CreateScreenNoteMutationFn = Apollo.MutationFunction<
  CreateScreenNoteMutation,
  CreateScreenNoteMutationVariables
>;
export type CreateScreenNoteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateScreenNoteMutation,
    CreateScreenNoteMutationVariables
  >;
} &
  TChildProps;
export function withCreateScreenNote<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateScreenNoteMutation,
    CreateScreenNoteMutationVariables,
    CreateScreenNoteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateScreenNoteMutation,
    CreateScreenNoteMutationVariables,
    CreateScreenNoteProps<TChildProps, TDataName>
  >(CreateScreenNoteDocument, {
    alias: "createScreenNote",
    ...operationOptions,
  });
}

/**
 * __useCreateScreenNoteMutation__
 *
 * To run a mutation, you first call `useCreateScreenNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScreenNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScreenNoteMutation, { data, loading, error }] = useCreateScreenNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScreenNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScreenNoteMutation,
    CreateScreenNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScreenNoteMutation,
    CreateScreenNoteMutationVariables
  >(CreateScreenNoteDocument, options);
}
export type CreateScreenNoteMutationHookResult = ReturnType<
  typeof useCreateScreenNoteMutation
>;
export type CreateScreenNoteMutationResult = Apollo.MutationResult<CreateScreenNoteMutation>;
export type CreateScreenNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateScreenNoteMutation,
  CreateScreenNoteMutationVariables
>;
export const UpdateScreenNoteByIdDocument = gql`
  mutation updateScreenNoteById($input: UpdateScreenNoteByIdInput!) {
    updateScreenNoteById(input: $input) {
      screenNote {
        ...ScreenNote
      }
    }
  }
  ${ScreenNoteFragmentDoc}
`;
export type UpdateScreenNoteByIdMutationFn = Apollo.MutationFunction<
  UpdateScreenNoteByIdMutation,
  UpdateScreenNoteByIdMutationVariables
>;
export type UpdateScreenNoteByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateScreenNoteByIdMutation,
    UpdateScreenNoteByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateScreenNoteById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateScreenNoteByIdMutation,
    UpdateScreenNoteByIdMutationVariables,
    UpdateScreenNoteByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateScreenNoteByIdMutation,
    UpdateScreenNoteByIdMutationVariables,
    UpdateScreenNoteByIdProps<TChildProps, TDataName>
  >(UpdateScreenNoteByIdDocument, {
    alias: "updateScreenNoteById",
    ...operationOptions,
  });
}

/**
 * __useUpdateScreenNoteByIdMutation__
 *
 * To run a mutation, you first call `useUpdateScreenNoteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScreenNoteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScreenNoteByIdMutation, { data, loading, error }] = useUpdateScreenNoteByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScreenNoteByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScreenNoteByIdMutation,
    UpdateScreenNoteByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScreenNoteByIdMutation,
    UpdateScreenNoteByIdMutationVariables
  >(UpdateScreenNoteByIdDocument, options);
}
export type UpdateScreenNoteByIdMutationHookResult = ReturnType<
  typeof useUpdateScreenNoteByIdMutation
>;
export type UpdateScreenNoteByIdMutationResult = Apollo.MutationResult<UpdateScreenNoteByIdMutation>;
export type UpdateScreenNoteByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateScreenNoteByIdMutation,
  UpdateScreenNoteByIdMutationVariables
>;
export const DeleteScreenNoteByIdDocument = gql`
  mutation deleteScreenNoteById($input: DeleteScreenNoteByIdInput!) {
    deleteScreenNoteById(input: $input) {
      screenNote {
        ...ScreenNote
      }
    }
  }
  ${ScreenNoteFragmentDoc}
`;
export type DeleteScreenNoteByIdMutationFn = Apollo.MutationFunction<
  DeleteScreenNoteByIdMutation,
  DeleteScreenNoteByIdMutationVariables
>;
export type DeleteScreenNoteByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteScreenNoteByIdMutation,
    DeleteScreenNoteByIdMutationVariables
  >;
} &
  TChildProps;
export function withDeleteScreenNoteById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteScreenNoteByIdMutation,
    DeleteScreenNoteByIdMutationVariables,
    DeleteScreenNoteByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteScreenNoteByIdMutation,
    DeleteScreenNoteByIdMutationVariables,
    DeleteScreenNoteByIdProps<TChildProps, TDataName>
  >(DeleteScreenNoteByIdDocument, {
    alias: "deleteScreenNoteById",
    ...operationOptions,
  });
}

/**
 * __useDeleteScreenNoteByIdMutation__
 *
 * To run a mutation, you first call `useDeleteScreenNoteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScreenNoteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScreenNoteByIdMutation, { data, loading, error }] = useDeleteScreenNoteByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScreenNoteByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScreenNoteByIdMutation,
    DeleteScreenNoteByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScreenNoteByIdMutation,
    DeleteScreenNoteByIdMutationVariables
  >(DeleteScreenNoteByIdDocument, options);
}
export type DeleteScreenNoteByIdMutationHookResult = ReturnType<
  typeof useDeleteScreenNoteByIdMutation
>;
export type DeleteScreenNoteByIdMutationResult = Apollo.MutationResult<DeleteScreenNoteByIdMutation>;
export type DeleteScreenNoteByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteScreenNoteByIdMutation,
  DeleteScreenNoteByIdMutationVariables
>;
export const DeleteNotificationRuleByIdDocument = gql`
  mutation DeleteNotificationRuleById(
    $input: DeleteNotificationRuleByIdInput!
  ) {
    deleteNotificationRuleById(input: $input) {
      notificationRule {
        notificationSubscriptionsByNotificationRuleId {
          nodes {
            active
            id
            userByUserIdAndOrgId {
              id
            }
          }
        }
      }
    }
  }
`;
export type DeleteNotificationRuleByIdMutationFn = Apollo.MutationFunction<
  DeleteNotificationRuleByIdMutation,
  DeleteNotificationRuleByIdMutationVariables
>;
export type DeleteNotificationRuleByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteNotificationRuleByIdMutation,
    DeleteNotificationRuleByIdMutationVariables
  >;
} &
  TChildProps;
export function withDeleteNotificationRuleById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteNotificationRuleByIdMutation,
    DeleteNotificationRuleByIdMutationVariables,
    DeleteNotificationRuleByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteNotificationRuleByIdMutation,
    DeleteNotificationRuleByIdMutationVariables,
    DeleteNotificationRuleByIdProps<TChildProps, TDataName>
  >(DeleteNotificationRuleByIdDocument, {
    alias: "deleteNotificationRuleById",
    ...operationOptions,
  });
}

/**
 * __useDeleteNotificationRuleByIdMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationRuleByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationRuleByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationRuleByIdMutation, { data, loading, error }] = useDeleteNotificationRuleByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNotificationRuleByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationRuleByIdMutation,
    DeleteNotificationRuleByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNotificationRuleByIdMutation,
    DeleteNotificationRuleByIdMutationVariables
  >(DeleteNotificationRuleByIdDocument, options);
}
export type DeleteNotificationRuleByIdMutationHookResult = ReturnType<
  typeof useDeleteNotificationRuleByIdMutation
>;
export type DeleteNotificationRuleByIdMutationResult = Apollo.MutationResult<DeleteNotificationRuleByIdMutation>;
export type DeleteNotificationRuleByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationRuleByIdMutation,
  DeleteNotificationRuleByIdMutationVariables
>;
export const DeleteNotificationSubscriptionByIdDocument = gql`
  mutation DeleteNotificationSubscriptionById(
    $input: DeleteNotificationSubscriptionByIdInput!
  ) {
    deleteNotificationSubscriptionById(input: $input) {
      notificationSubscription {
        id
      }
    }
  }
`;
export type DeleteNotificationSubscriptionByIdMutationFn = Apollo.MutationFunction<
  DeleteNotificationSubscriptionByIdMutation,
  DeleteNotificationSubscriptionByIdMutationVariables
>;
export type DeleteNotificationSubscriptionByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteNotificationSubscriptionByIdMutation,
    DeleteNotificationSubscriptionByIdMutationVariables
  >;
} &
  TChildProps;
export function withDeleteNotificationSubscriptionById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteNotificationSubscriptionByIdMutation,
    DeleteNotificationSubscriptionByIdMutationVariables,
    DeleteNotificationSubscriptionByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteNotificationSubscriptionByIdMutation,
    DeleteNotificationSubscriptionByIdMutationVariables,
    DeleteNotificationSubscriptionByIdProps<TChildProps, TDataName>
  >(DeleteNotificationSubscriptionByIdDocument, {
    alias: "deleteNotificationSubscriptionById",
    ...operationOptions,
  });
}

/**
 * __useDeleteNotificationSubscriptionByIdMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationSubscriptionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationSubscriptionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationSubscriptionByIdMutation, { data, loading, error }] = useDeleteNotificationSubscriptionByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNotificationSubscriptionByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationSubscriptionByIdMutation,
    DeleteNotificationSubscriptionByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNotificationSubscriptionByIdMutation,
    DeleteNotificationSubscriptionByIdMutationVariables
  >(DeleteNotificationSubscriptionByIdDocument, options);
}
export type DeleteNotificationSubscriptionByIdMutationHookResult = ReturnType<
  typeof useDeleteNotificationSubscriptionByIdMutation
>;
export type DeleteNotificationSubscriptionByIdMutationResult = Apollo.MutationResult<DeleteNotificationSubscriptionByIdMutation>;
export type DeleteNotificationSubscriptionByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationSubscriptionByIdMutation,
  DeleteNotificationSubscriptionByIdMutationVariables
>;
export const SearchUserBySpaceIdDocument = gql`
  query searchUserBySpaceId($query: String) {
    searchUser(query: $query) {
      nodes {
        __typename
        id
        orgId
        email
        status
        userType
        givenName
        familyName
        metadata
      }
      totalCount
    }
  }
`;
export type SearchUserBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchUserBySpaceIdQuery,
    SearchUserBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withSearchUserBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchUserBySpaceIdQuery,
    SearchUserBySpaceIdQueryVariables,
    SearchUserBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchUserBySpaceIdQuery,
    SearchUserBySpaceIdQueryVariables,
    SearchUserBySpaceIdProps<TChildProps, TDataName>
  >(SearchUserBySpaceIdDocument, {
    alias: "searchUserBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useSearchUserBySpaceIdQuery__
 *
 * To run a query within a React component, call `useSearchUserBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserBySpaceIdQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUserBySpaceIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchUserBySpaceIdQuery,
    SearchUserBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchUserBySpaceIdQuery,
    SearchUserBySpaceIdQueryVariables
  >(SearchUserBySpaceIdDocument, options);
}
export function useSearchUserBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUserBySpaceIdQuery,
    SearchUserBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchUserBySpaceIdQuery,
    SearchUserBySpaceIdQueryVariables
  >(SearchUserBySpaceIdDocument, options);
}
export type SearchUserBySpaceIdQueryHookResult = ReturnType<
  typeof useSearchUserBySpaceIdQuery
>;
export type SearchUserBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useSearchUserBySpaceIdLazyQuery
>;
export type SearchUserBySpaceIdQueryResult = Apollo.QueryResult<
  SearchUserBySpaceIdQuery,
  SearchUserBySpaceIdQueryVariables
>;
export function refetchSearchUserBySpaceIdQuery(
  variables?: SearchUserBySpaceIdQueryVariables
) {
  return { query: SearchUserBySpaceIdDocument, variables: variables };
}
export const UpdateNotificationRuleByIdDocument = gql`
  mutation UpdateNotificationRuleById(
    $input: UpdateNotificationRuleByIdInput!
  ) {
    updateNotificationRuleById(input: $input) {
      notificationRule {
        id
        active
        waitTime
      }
    }
  }
`;
export type UpdateNotificationRuleByIdMutationFn = Apollo.MutationFunction<
  UpdateNotificationRuleByIdMutation,
  UpdateNotificationRuleByIdMutationVariables
>;
export type UpdateNotificationRuleByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateNotificationRuleByIdMutation,
    UpdateNotificationRuleByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateNotificationRuleById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateNotificationRuleByIdMutation,
    UpdateNotificationRuleByIdMutationVariables,
    UpdateNotificationRuleByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateNotificationRuleByIdMutation,
    UpdateNotificationRuleByIdMutationVariables,
    UpdateNotificationRuleByIdProps<TChildProps, TDataName>
  >(UpdateNotificationRuleByIdDocument, {
    alias: "updateNotificationRuleById",
    ...operationOptions,
  });
}

/**
 * __useUpdateNotificationRuleByIdMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationRuleByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationRuleByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationRuleByIdMutation, { data, loading, error }] = useUpdateNotificationRuleByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationRuleByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationRuleByIdMutation,
    UpdateNotificationRuleByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationRuleByIdMutation,
    UpdateNotificationRuleByIdMutationVariables
  >(UpdateNotificationRuleByIdDocument, options);
}
export type UpdateNotificationRuleByIdMutationHookResult = ReturnType<
  typeof useUpdateNotificationRuleByIdMutation
>;
export type UpdateNotificationRuleByIdMutationResult = Apollo.MutationResult<UpdateNotificationRuleByIdMutation>;
export type UpdateNotificationRuleByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationRuleByIdMutation,
  UpdateNotificationRuleByIdMutationVariables
>;
export const UpdateNotificationSubscriptionByIdDocument = gql`
  mutation UpdateNotificationSubscriptionById(
    $input: UpdateNotificationSubscriptionByIdInput!
  ) {
    updateNotificationSubscriptionById(input: $input) {
      notificationSubscription {
        id
        userId
        channel
        active
      }
    }
  }
`;
export type UpdateNotificationSubscriptionByIdMutationFn = Apollo.MutationFunction<
  UpdateNotificationSubscriptionByIdMutation,
  UpdateNotificationSubscriptionByIdMutationVariables
>;
export type UpdateNotificationSubscriptionByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateNotificationSubscriptionByIdMutation,
    UpdateNotificationSubscriptionByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateNotificationSubscriptionById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateNotificationSubscriptionByIdMutation,
    UpdateNotificationSubscriptionByIdMutationVariables,
    UpdateNotificationSubscriptionByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateNotificationSubscriptionByIdMutation,
    UpdateNotificationSubscriptionByIdMutationVariables,
    UpdateNotificationSubscriptionByIdProps<TChildProps, TDataName>
  >(UpdateNotificationSubscriptionByIdDocument, {
    alias: "updateNotificationSubscriptionById",
    ...operationOptions,
  });
}

/**
 * __useUpdateNotificationSubscriptionByIdMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSubscriptionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSubscriptionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSubscriptionByIdMutation, { data, loading, error }] = useUpdateNotificationSubscriptionByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationSubscriptionByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationSubscriptionByIdMutation,
    UpdateNotificationSubscriptionByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationSubscriptionByIdMutation,
    UpdateNotificationSubscriptionByIdMutationVariables
  >(UpdateNotificationSubscriptionByIdDocument, options);
}
export type UpdateNotificationSubscriptionByIdMutationHookResult = ReturnType<
  typeof useUpdateNotificationSubscriptionByIdMutation
>;
export type UpdateNotificationSubscriptionByIdMutationResult = Apollo.MutationResult<UpdateNotificationSubscriptionByIdMutation>;
export type UpdateNotificationSubscriptionByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationSubscriptionByIdMutation,
  UpdateNotificationSubscriptionByIdMutationVariables
>;
export const SidebarQueryDocument = gql`
  query sidebarQuery($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      filesBySpaceId(first: 10, orderBy: CREATED_AT_DESC) {
        nodes {
          ...FileSidebar
        }
      }
      linksBySpaceId(first: 10, orderBy: CREATED_AT_DESC) {
        nodes {
          ...LinkSidebar
        }
      }
      availableSitesBySpaceId(first: 10, orderBy: CREATED_AT_DESC) {
        nodes {
          ...SiteSidebar
        }
      }
      availableAppInstancesBySpaceId(first: 10, orderBy: CREATED_AT_DESC) {
        nodes {
          ...AppInstanceSidebar
        }
      }
    }
  }
  ${FileSidebarFragmentDoc}
  ${LinkSidebarFragmentDoc}
  ${SiteSidebarFragmentDoc}
  ${AppInstanceSidebarFragmentDoc}
`;
export type SidebarQueryProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SidebarQueryQuery,
    SidebarQueryQueryVariables
  >;
} &
  TChildProps;
export function withSidebarQuery<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SidebarQueryQuery,
    SidebarQueryQueryVariables,
    SidebarQueryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SidebarQueryQuery,
    SidebarQueryQueryVariables,
    SidebarQueryProps<TChildProps, TDataName>
  >(SidebarQueryDocument, {
    alias: "sidebarQuery",
    ...operationOptions,
  });
}

/**
 * __useSidebarQueryQuery__
 *
 * To run a query within a React component, call `useSidebarQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarQueryQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useSidebarQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SidebarQueryQuery,
    SidebarQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SidebarQueryQuery, SidebarQueryQueryVariables>(
    SidebarQueryDocument,
    options
  );
}
export function useSidebarQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SidebarQueryQuery,
    SidebarQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SidebarQueryQuery, SidebarQueryQueryVariables>(
    SidebarQueryDocument,
    options
  );
}
export type SidebarQueryQueryHookResult = ReturnType<
  typeof useSidebarQueryQuery
>;
export type SidebarQueryLazyQueryHookResult = ReturnType<
  typeof useSidebarQueryLazyQuery
>;
export type SidebarQueryQueryResult = Apollo.QueryResult<
  SidebarQueryQuery,
  SidebarQueryQueryVariables
>;
export function refetchSidebarQueryQuery(
  variables?: SidebarQueryQueryVariables
) {
  return { query: SidebarQueryDocument, variables: variables };
}
export const SidebarSearchDocument = gql`
  query sidebarSearch($query: String, $spaceId: UUID) {
    searchFile(first: 10, query: $query, spaceId: $spaceId) {
      nodes {
        ...FileSidebar
      }
    }
    searchFolder(first: 10, query: $query, spaceId: $spaceId) {
      nodes {
        ...FolderSidebar
      }
    }
    searchLink(first: 10, query: $query, spaceId: $spaceId) {
      nodes {
        ...LinkSidebar
      }
    }
    searchSite(first: 10, query: $query, spaceId: $spaceId) {
      nodes {
        ...SiteSidebar
      }
    }
    searchAppInstance(first: 10, query: $query, spaceId: $spaceId) {
      nodes {
        ...AppInstanceSidebar
      }
    }
  }
  ${FileSidebarFragmentDoc}
  ${FolderSidebarFragmentDoc}
  ${LinkSidebarFragmentDoc}
  ${SiteSidebarFragmentDoc}
  ${AppInstanceSidebarFragmentDoc}
`;
export type SidebarSearchProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SidebarSearchQuery,
    SidebarSearchQueryVariables
  >;
} &
  TChildProps;
export function withSidebarSearch<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SidebarSearchQuery,
    SidebarSearchQueryVariables,
    SidebarSearchProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SidebarSearchQuery,
    SidebarSearchQueryVariables,
    SidebarSearchProps<TChildProps, TDataName>
  >(SidebarSearchDocument, {
    alias: "sidebarSearch",
    ...operationOptions,
  });
}

/**
 * __useSidebarSearchQuery__
 *
 * To run a query within a React component, call `useSidebarSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useSidebarSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SidebarSearchQuery,
    SidebarSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SidebarSearchQuery, SidebarSearchQueryVariables>(
    SidebarSearchDocument,
    options
  );
}
export function useSidebarSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SidebarSearchQuery,
    SidebarSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SidebarSearchQuery, SidebarSearchQueryVariables>(
    SidebarSearchDocument,
    options
  );
}
export type SidebarSearchQueryHookResult = ReturnType<
  typeof useSidebarSearchQuery
>;
export type SidebarSearchLazyQueryHookResult = ReturnType<
  typeof useSidebarSearchLazyQuery
>;
export type SidebarSearchQueryResult = Apollo.QueryResult<
  SidebarSearchQuery,
  SidebarSearchQueryVariables
>;
export function refetchSidebarSearchQuery(
  variables?: SidebarSearchQueryVariables
) {
  return { query: SidebarSearchDocument, variables: variables };
}
export const ShareChannelToAllSpacesDocument = gql`
  mutation shareChannelToAllSpaces($input: ShareChannelToAllSpacesInput!) {
    shareChannelToAllSpaces(input: $input) {
      __typename
      channel {
        id
        orgId
        spaceId
        name
        isSharedAll
        preferences
      }
    }
  }
`;
export type ShareChannelToAllSpacesMutationFn = Apollo.MutationFunction<
  ShareChannelToAllSpacesMutation,
  ShareChannelToAllSpacesMutationVariables
>;
export type ShareChannelToAllSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ShareChannelToAllSpacesMutation,
    ShareChannelToAllSpacesMutationVariables
  >;
} &
  TChildProps;
export function withShareChannelToAllSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ShareChannelToAllSpacesMutation,
    ShareChannelToAllSpacesMutationVariables,
    ShareChannelToAllSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ShareChannelToAllSpacesMutation,
    ShareChannelToAllSpacesMutationVariables,
    ShareChannelToAllSpacesProps<TChildProps, TDataName>
  >(ShareChannelToAllSpacesDocument, {
    alias: "shareChannelToAllSpaces",
    ...operationOptions,
  });
}

/**
 * __useShareChannelToAllSpacesMutation__
 *
 * To run a mutation, you first call `useShareChannelToAllSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareChannelToAllSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareChannelToAllSpacesMutation, { data, loading, error }] = useShareChannelToAllSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareChannelToAllSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareChannelToAllSpacesMutation,
    ShareChannelToAllSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareChannelToAllSpacesMutation,
    ShareChannelToAllSpacesMutationVariables
  >(ShareChannelToAllSpacesDocument, options);
}
export type ShareChannelToAllSpacesMutationHookResult = ReturnType<
  typeof useShareChannelToAllSpacesMutation
>;
export type ShareChannelToAllSpacesMutationResult = Apollo.MutationResult<ShareChannelToAllSpacesMutation>;
export type ShareChannelToAllSpacesMutationOptions = Apollo.BaseMutationOptions<
  ShareChannelToAllSpacesMutation,
  ShareChannelToAllSpacesMutationVariables
>;
export const ShareChannelToSpaceDocument = gql`
  mutation shareChannelToSpace($input: ShareChannelToSpaceInput!) {
    shareChannelToSpace(input: $input) {
      channel {
        id
        orgId
        isShared
        isSharedAll
        preferences
        isEmbedEnabled
        isEmbedPublic
        shortId
      }
    }
  }
`;
export type ShareChannelToSpaceMutationFn = Apollo.MutationFunction<
  ShareChannelToSpaceMutation,
  ShareChannelToSpaceMutationVariables
>;
export type ShareChannelToSpaceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ShareChannelToSpaceMutation,
    ShareChannelToSpaceMutationVariables
  >;
} &
  TChildProps;
export function withShareChannelToSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ShareChannelToSpaceMutation,
    ShareChannelToSpaceMutationVariables,
    ShareChannelToSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ShareChannelToSpaceMutation,
    ShareChannelToSpaceMutationVariables,
    ShareChannelToSpaceProps<TChildProps, TDataName>
  >(ShareChannelToSpaceDocument, {
    alias: "shareChannelToSpace",
    ...operationOptions,
  });
}

/**
 * __useShareChannelToSpaceMutation__
 *
 * To run a mutation, you first call `useShareChannelToSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareChannelToSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareChannelToSpaceMutation, { data, loading, error }] = useShareChannelToSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareChannelToSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareChannelToSpaceMutation,
    ShareChannelToSpaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareChannelToSpaceMutation,
    ShareChannelToSpaceMutationVariables
  >(ShareChannelToSpaceDocument, options);
}
export type ShareChannelToSpaceMutationHookResult = ReturnType<
  typeof useShareChannelToSpaceMutation
>;
export type ShareChannelToSpaceMutationResult = Apollo.MutationResult<ShareChannelToSpaceMutation>;
export type ShareChannelToSpaceMutationOptions = Apollo.BaseMutationOptions<
  ShareChannelToSpaceMutation,
  ShareChannelToSpaceMutationVariables
>;
export const SharedSpacesByChannelIdDocument = gql`
  query sharedSpacesByChannelId($id: UUID!) {
    channelById(id: $id) {
      id
      sharedSpacesWithSharingModeByChannelId {
        nodes {
          space {
            __typename
            id
          }
          readOnly
        }
      }
    }
  }
`;
export type SharedSpacesByChannelIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SharedSpacesByChannelIdQuery,
    SharedSpacesByChannelIdQueryVariables
  >;
} &
  TChildProps;
export function withSharedSpacesByChannelId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SharedSpacesByChannelIdQuery,
    SharedSpacesByChannelIdQueryVariables,
    SharedSpacesByChannelIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SharedSpacesByChannelIdQuery,
    SharedSpacesByChannelIdQueryVariables,
    SharedSpacesByChannelIdProps<TChildProps, TDataName>
  >(SharedSpacesByChannelIdDocument, {
    alias: "sharedSpacesByChannelId",
    ...operationOptions,
  });
}

/**
 * __useSharedSpacesByChannelIdQuery__
 *
 * To run a query within a React component, call `useSharedSpacesByChannelIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedSpacesByChannelIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedSpacesByChannelIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSharedSpacesByChannelIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SharedSpacesByChannelIdQuery,
    SharedSpacesByChannelIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SharedSpacesByChannelIdQuery,
    SharedSpacesByChannelIdQueryVariables
  >(SharedSpacesByChannelIdDocument, options);
}
export function useSharedSpacesByChannelIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SharedSpacesByChannelIdQuery,
    SharedSpacesByChannelIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SharedSpacesByChannelIdQuery,
    SharedSpacesByChannelIdQueryVariables
  >(SharedSpacesByChannelIdDocument, options);
}
export type SharedSpacesByChannelIdQueryHookResult = ReturnType<
  typeof useSharedSpacesByChannelIdQuery
>;
export type SharedSpacesByChannelIdLazyQueryHookResult = ReturnType<
  typeof useSharedSpacesByChannelIdLazyQuery
>;
export type SharedSpacesByChannelIdQueryResult = Apollo.QueryResult<
  SharedSpacesByChannelIdQuery,
  SharedSpacesByChannelIdQueryVariables
>;
export function refetchSharedSpacesByChannelIdQuery(
  variables?: SharedSpacesByChannelIdQueryVariables
) {
  return { query: SharedSpacesByChannelIdDocument, variables: variables };
}
export const UpdateChannelDefaultSharingModeDocument = gql`
  mutation updateChannelDefaultSharingMode(
    $input: UpdateChannelDefaultSharingModeInput!
  ) {
    updateChannelDefaultSharingMode(input: $input) {
      __typename
      channel {
        id
        preferences
      }
    }
  }
`;
export type UpdateChannelDefaultSharingModeMutationFn = Apollo.MutationFunction<
  UpdateChannelDefaultSharingModeMutation,
  UpdateChannelDefaultSharingModeMutationVariables
>;
export type UpdateChannelDefaultSharingModeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateChannelDefaultSharingModeMutation,
    UpdateChannelDefaultSharingModeMutationVariables
  >;
} &
  TChildProps;
export function withUpdateChannelDefaultSharingMode<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateChannelDefaultSharingModeMutation,
    UpdateChannelDefaultSharingModeMutationVariables,
    UpdateChannelDefaultSharingModeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateChannelDefaultSharingModeMutation,
    UpdateChannelDefaultSharingModeMutationVariables,
    UpdateChannelDefaultSharingModeProps<TChildProps, TDataName>
  >(UpdateChannelDefaultSharingModeDocument, {
    alias: "updateChannelDefaultSharingMode",
    ...operationOptions,
  });
}

/**
 * __useUpdateChannelDefaultSharingModeMutation__
 *
 * To run a mutation, you first call `useUpdateChannelDefaultSharingModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelDefaultSharingModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelDefaultSharingModeMutation, { data, loading, error }] = useUpdateChannelDefaultSharingModeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelDefaultSharingModeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelDefaultSharingModeMutation,
    UpdateChannelDefaultSharingModeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelDefaultSharingModeMutation,
    UpdateChannelDefaultSharingModeMutationVariables
  >(UpdateChannelDefaultSharingModeDocument, options);
}
export type UpdateChannelDefaultSharingModeMutationHookResult = ReturnType<
  typeof useUpdateChannelDefaultSharingModeMutation
>;
export type UpdateChannelDefaultSharingModeMutationResult = Apollo.MutationResult<UpdateChannelDefaultSharingModeMutation>;
export type UpdateChannelDefaultSharingModeMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelDefaultSharingModeMutation,
  UpdateChannelDefaultSharingModeMutationVariables
>;
export const ReplaceScreenDeviceDocument = gql`
  mutation replaceScreenDevice($input: ReplaceScreenDeviceInput!) {
    replaceScreenDevice(input: $input) {
      screen {
        id
        name
        deviceId
      }
    }
  }
`;
export type ReplaceScreenDeviceMutationFn = Apollo.MutationFunction<
  ReplaceScreenDeviceMutation,
  ReplaceScreenDeviceMutationVariables
>;
export type ReplaceScreenDeviceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ReplaceScreenDeviceMutation,
    ReplaceScreenDeviceMutationVariables
  >;
} &
  TChildProps;
export function withReplaceScreenDevice<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReplaceScreenDeviceMutation,
    ReplaceScreenDeviceMutationVariables,
    ReplaceScreenDeviceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReplaceScreenDeviceMutation,
    ReplaceScreenDeviceMutationVariables,
    ReplaceScreenDeviceProps<TChildProps, TDataName>
  >(ReplaceScreenDeviceDocument, {
    alias: "replaceScreenDevice",
    ...operationOptions,
  });
}

/**
 * __useReplaceScreenDeviceMutation__
 *
 * To run a mutation, you first call `useReplaceScreenDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceScreenDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceScreenDeviceMutation, { data, loading, error }] = useReplaceScreenDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceScreenDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplaceScreenDeviceMutation,
    ReplaceScreenDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReplaceScreenDeviceMutation,
    ReplaceScreenDeviceMutationVariables
  >(ReplaceScreenDeviceDocument, options);
}
export type ReplaceScreenDeviceMutationHookResult = ReturnType<
  typeof useReplaceScreenDeviceMutation
>;
export type ReplaceScreenDeviceMutationResult = Apollo.MutationResult<ReplaceScreenDeviceMutation>;
export type ReplaceScreenDeviceMutationOptions = Apollo.BaseMutationOptions<
  ReplaceScreenDeviceMutation,
  ReplaceScreenDeviceMutationVariables
>;
export const AllScreenGroupsDocument = gql`
  query allScreenGroups {
    allScreenGroups {
      nodes {
        __typename
        id
        name
        spaceId
      }
    }
  }
`;
export type AllScreenGroupsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllScreenGroupsQuery,
    AllScreenGroupsQueryVariables
  >;
} &
  TChildProps;
export function withAllScreenGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllScreenGroupsQuery,
    AllScreenGroupsQueryVariables,
    AllScreenGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllScreenGroupsQuery,
    AllScreenGroupsQueryVariables,
    AllScreenGroupsProps<TChildProps, TDataName>
  >(AllScreenGroupsDocument, {
    alias: "allScreenGroups",
    ...operationOptions,
  });
}

/**
 * __useAllScreenGroupsQuery__
 *
 * To run a query within a React component, call `useAllScreenGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllScreenGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllScreenGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllScreenGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllScreenGroupsQuery,
    AllScreenGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllScreenGroupsQuery, AllScreenGroupsQueryVariables>(
    AllScreenGroupsDocument,
    options
  );
}
export function useAllScreenGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllScreenGroupsQuery,
    AllScreenGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllScreenGroupsQuery,
    AllScreenGroupsQueryVariables
  >(AllScreenGroupsDocument, options);
}
export type AllScreenGroupsQueryHookResult = ReturnType<
  typeof useAllScreenGroupsQuery
>;
export type AllScreenGroupsLazyQueryHookResult = ReturnType<
  typeof useAllScreenGroupsLazyQuery
>;
export type AllScreenGroupsQueryResult = Apollo.QueryResult<
  AllScreenGroupsQuery,
  AllScreenGroupsQueryVariables
>;
export function refetchAllScreenGroupsQuery(
  variables?: AllScreenGroupsQueryVariables
) {
  return { query: AllScreenGroupsDocument, variables: variables };
}
export const AllTagsDocument = gql`
  query allTags {
    allTags(orderBy: NAME_ASC) {
      nodes {
        __typename
        orgId
        spaceId
        name
      }
    }
  }
`;
export type AllTagsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllTagsQuery,
    AllTagsQueryVariables
  >;
} &
  TChildProps;
export function withAllTags<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTagsQuery,
    AllTagsQueryVariables,
    AllTagsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTagsQuery,
    AllTagsQueryVariables,
    AllTagsProps<TChildProps, TDataName>
  >(AllTagsDocument, {
    alias: "allTags",
    ...operationOptions,
  });
}

/**
 * __useAllTagsQuery__
 *
 * To run a query within a React component, call `useAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTagsQuery, AllTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTagsQuery, AllTagsQueryVariables>(
    AllTagsDocument,
    options
  );
}
export function useAllTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllTagsQuery, AllTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTagsQuery, AllTagsQueryVariables>(
    AllTagsDocument,
    options
  );
}
export type AllTagsQueryHookResult = ReturnType<typeof useAllTagsQuery>;
export type AllTagsLazyQueryHookResult = ReturnType<typeof useAllTagsLazyQuery>;
export type AllTagsQueryResult = Apollo.QueryResult<
  AllTagsQuery,
  AllTagsQueryVariables
>;
export function refetchAllTagsQuery(variables?: AllTagsQueryVariables) {
  return { query: AllTagsDocument, variables: variables };
}
export const AllWhiteLabelsDocument = gql`
  query allWhiteLabels {
    allWhiteLabels {
      nodes {
        id
        spaceId
        bgColor
        textColor
        activeItemColor
        activeTextColor
        fileByGlyphFileId {
          source
        }
      }
    }
  }
`;
export type AllWhiteLabelsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllWhiteLabelsQuery,
    AllWhiteLabelsQueryVariables
  >;
} &
  TChildProps;
export function withAllWhiteLabels<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllWhiteLabelsQuery,
    AllWhiteLabelsQueryVariables,
    AllWhiteLabelsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllWhiteLabelsQuery,
    AllWhiteLabelsQueryVariables,
    AllWhiteLabelsProps<TChildProps, TDataName>
  >(AllWhiteLabelsDocument, {
    alias: "allWhiteLabels",
    ...operationOptions,
  });
}

/**
 * __useAllWhiteLabelsQuery__
 *
 * To run a query within a React component, call `useAllWhiteLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWhiteLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWhiteLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWhiteLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllWhiteLabelsQuery,
    AllWhiteLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllWhiteLabelsQuery, AllWhiteLabelsQueryVariables>(
    AllWhiteLabelsDocument,
    options
  );
}
export function useAllWhiteLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllWhiteLabelsQuery,
    AllWhiteLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllWhiteLabelsQuery, AllWhiteLabelsQueryVariables>(
    AllWhiteLabelsDocument,
    options
  );
}
export type AllWhiteLabelsQueryHookResult = ReturnType<
  typeof useAllWhiteLabelsQuery
>;
export type AllWhiteLabelsLazyQueryHookResult = ReturnType<
  typeof useAllWhiteLabelsLazyQuery
>;
export type AllWhiteLabelsQueryResult = Apollo.QueryResult<
  AllWhiteLabelsQuery,
  AllWhiteLabelsQueryVariables
>;
export function refetchAllWhiteLabelsQuery(
  variables?: AllWhiteLabelsQueryVariables
) {
  return { query: AllWhiteLabelsDocument, variables: variables };
}
export const BulkCreateCastsDocument = gql`
  mutation bulkCreateCasts($input: BulkCreateCastsInput!) {
    bulkCreateCasts(input: $input) {
      casts {
        id
        orgId
        spaceId
        content
      }
    }
  }
`;
export type BulkCreateCastsMutationFn = Apollo.MutationFunction<
  BulkCreateCastsMutation,
  BulkCreateCastsMutationVariables
>;
export type BulkCreateCastsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    BulkCreateCastsMutation,
    BulkCreateCastsMutationVariables
  >;
} &
  TChildProps;
export function withBulkCreateCasts<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BulkCreateCastsMutation,
    BulkCreateCastsMutationVariables,
    BulkCreateCastsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BulkCreateCastsMutation,
    BulkCreateCastsMutationVariables,
    BulkCreateCastsProps<TChildProps, TDataName>
  >(BulkCreateCastsDocument, {
    alias: "bulkCreateCasts",
    ...operationOptions,
  });
}

/**
 * __useBulkCreateCastsMutation__
 *
 * To run a mutation, you first call `useBulkCreateCastsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateCastsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateCastsMutation, { data, loading, error }] = useBulkCreateCastsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateCastsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkCreateCastsMutation,
    BulkCreateCastsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkCreateCastsMutation,
    BulkCreateCastsMutationVariables
  >(BulkCreateCastsDocument, options);
}
export type BulkCreateCastsMutationHookResult = ReturnType<
  typeof useBulkCreateCastsMutation
>;
export type BulkCreateCastsMutationResult = Apollo.MutationResult<BulkCreateCastsMutation>;
export type BulkCreateCastsMutationOptions = Apollo.BaseMutationOptions<
  BulkCreateCastsMutation,
  BulkCreateCastsMutationVariables
>;
export const BulkResetScreenLocationDocument = gql`
  mutation bulkResetScreenLocation($input: BulkResetScreenLocationInput!) {
    bulkResetScreenLocation(input: $input) {
      screens {
        id
      }
    }
  }
`;
export type BulkResetScreenLocationMutationFn = Apollo.MutationFunction<
  BulkResetScreenLocationMutation,
  BulkResetScreenLocationMutationVariables
>;
export type BulkResetScreenLocationProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    BulkResetScreenLocationMutation,
    BulkResetScreenLocationMutationVariables
  >;
} &
  TChildProps;
export function withBulkResetScreenLocation<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BulkResetScreenLocationMutation,
    BulkResetScreenLocationMutationVariables,
    BulkResetScreenLocationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BulkResetScreenLocationMutation,
    BulkResetScreenLocationMutationVariables,
    BulkResetScreenLocationProps<TChildProps, TDataName>
  >(BulkResetScreenLocationDocument, {
    alias: "bulkResetScreenLocation",
    ...operationOptions,
  });
}

/**
 * __useBulkResetScreenLocationMutation__
 *
 * To run a mutation, you first call `useBulkResetScreenLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkResetScreenLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkResetScreenLocationMutation, { data, loading, error }] = useBulkResetScreenLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkResetScreenLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkResetScreenLocationMutation,
    BulkResetScreenLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkResetScreenLocationMutation,
    BulkResetScreenLocationMutationVariables
  >(BulkResetScreenLocationDocument, options);
}
export type BulkResetScreenLocationMutationHookResult = ReturnType<
  typeof useBulkResetScreenLocationMutation
>;
export type BulkResetScreenLocationMutationResult = Apollo.MutationResult<BulkResetScreenLocationMutation>;
export type BulkResetScreenLocationMutationOptions = Apollo.BaseMutationOptions<
  BulkResetScreenLocationMutation,
  BulkResetScreenLocationMutationVariables
>;
export const BulkUpdateScreenContentDocument = gql`
  mutation bulkUpdateScreenContent($input: BulkUpdateScreenContentInput!) {
    bulkUpdateScreenContent(input: $input) {
      screens {
        orgId
        spaceId
        id
        name
        content
      }
    }
  }
`;
export type BulkUpdateScreenContentMutationFn = Apollo.MutationFunction<
  BulkUpdateScreenContentMutation,
  BulkUpdateScreenContentMutationVariables
>;
export type BulkUpdateScreenContentProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    BulkUpdateScreenContentMutation,
    BulkUpdateScreenContentMutationVariables
  >;
} &
  TChildProps;
export function withBulkUpdateScreenContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BulkUpdateScreenContentMutation,
    BulkUpdateScreenContentMutationVariables,
    BulkUpdateScreenContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BulkUpdateScreenContentMutation,
    BulkUpdateScreenContentMutationVariables,
    BulkUpdateScreenContentProps<TChildProps, TDataName>
  >(BulkUpdateScreenContentDocument, {
    alias: "bulkUpdateScreenContent",
    ...operationOptions,
  });
}

/**
 * __useBulkUpdateScreenContentMutation__
 *
 * To run a mutation, you first call `useBulkUpdateScreenContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateScreenContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateScreenContentMutation, { data, loading, error }] = useBulkUpdateScreenContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateScreenContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateScreenContentMutation,
    BulkUpdateScreenContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateScreenContentMutation,
    BulkUpdateScreenContentMutationVariables
  >(BulkUpdateScreenContentDocument, options);
}
export type BulkUpdateScreenContentMutationHookResult = ReturnType<
  typeof useBulkUpdateScreenContentMutation
>;
export type BulkUpdateScreenContentMutationResult = Apollo.MutationResult<BulkUpdateScreenContentMutation>;
export type BulkUpdateScreenContentMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateScreenContentMutation,
  BulkUpdateScreenContentMutationVariables
>;
export const BulkUpdateScreenLocationDocument = gql`
  mutation bulkUpdateScreenLocation($input: BulkUpdateScreenLocationInput!) {
    bulkUpdateScreenLocation(input: $input) {
      screens {
        id
      }
    }
  }
`;
export type BulkUpdateScreenLocationMutationFn = Apollo.MutationFunction<
  BulkUpdateScreenLocationMutation,
  BulkUpdateScreenLocationMutationVariables
>;
export type BulkUpdateScreenLocationProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    BulkUpdateScreenLocationMutation,
    BulkUpdateScreenLocationMutationVariables
  >;
} &
  TChildProps;
export function withBulkUpdateScreenLocation<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BulkUpdateScreenLocationMutation,
    BulkUpdateScreenLocationMutationVariables,
    BulkUpdateScreenLocationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BulkUpdateScreenLocationMutation,
    BulkUpdateScreenLocationMutationVariables,
    BulkUpdateScreenLocationProps<TChildProps, TDataName>
  >(BulkUpdateScreenLocationDocument, {
    alias: "bulkUpdateScreenLocation",
    ...operationOptions,
  });
}

/**
 * __useBulkUpdateScreenLocationMutation__
 *
 * To run a mutation, you first call `useBulkUpdateScreenLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateScreenLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateScreenLocationMutation, { data, loading, error }] = useBulkUpdateScreenLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateScreenLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateScreenLocationMutation,
    BulkUpdateScreenLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateScreenLocationMutation,
    BulkUpdateScreenLocationMutationVariables
  >(BulkUpdateScreenLocationDocument, options);
}
export type BulkUpdateScreenLocationMutationHookResult = ReturnType<
  typeof useBulkUpdateScreenLocationMutation
>;
export type BulkUpdateScreenLocationMutationResult = Apollo.MutationResult<BulkUpdateScreenLocationMutation>;
export type BulkUpdateScreenLocationMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateScreenLocationMutation,
  BulkUpdateScreenLocationMutationVariables
>;
export const CastStartDocument = gql`
  mutation castStart($input: CastStartInput!) {
    castStart(input: $input) {
      cast {
        __typename
        id
        content
        screensByCastId {
          nodes {
            __typename
            id
            orgId
            screenGroupId
            castId
            name
            content
            status
            device
            preview
            tags
            preferences
            screenContentHistoriesByScreenId(
              orderBy: [CREATED_AT_DESC]
              first: 5
            ) {
              nodes {
                __typename
                id
                content
                channelByChannelIdAndOrgId {
                  __typename
                  id
                  spaceId
                  content
                  name
                  draft {
                    __typename
                    id
                    isPublished
                  }
                  childOf
                  parentChannelByChildOf {
                    ...ParentChannel
                  }
                }
                playlistByPlaylistIdAndOrgId {
                  __typename
                  id
                  name
                  content
                  draft {
                    __typename
                    id
                    isPublished
                  }
                }
              }
            }
            screenGroupByScreenGroupId {
              __typename
              id
              name
              isPrimary
            }
            castByCastId {
              ...Cast
              channelByCastId {
                ...Channel
                childOf
                parentChannelByChildOf {
                  ...ParentChannel
                }
              }
              fileByCastId {
                ...File
                fileOutputsByFileId {
                  nodes {
                    ...FileOutput
                  }
                  totalCount
                }
              }
              playlistByCastId {
                ...Playlist
              }
              linkByCastId {
                ...Link
              }
              appInstanceByCastId {
                ...AppInstance
                appVersionByAppInstanceId {
                  ...AppVersion
                  appByAppId {
                    __typename
                    id
                    name
                    iconUrl
                  }
                }
                appInstallByAppInstallId {
                  ...AppInstall
                }
              }
            }
            linksByScreenId {
              nodes {
                __typename
                id
                name
              }
            }
            channelsByScreenId {
              nodes {
                __typename
                id
                name
              }
            }
            filesByScreenId {
              nodes {
                __typename
                id
                name
                mimetype
              }
            }
            playlistsByScreenId {
              nodes {
                __typename
                id
                name
              }
            }
            appInstancesByScreenId {
              nodes {
                __typename
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${ParentChannelFragmentDoc}
  ${CastFragmentDoc}
  ${ChannelFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${PlaylistFragmentDoc}
  ${LinkFragmentDoc}
  ${AppInstanceFragmentDoc}
  ${AppVersionFragmentDoc}
  ${AppInstallFragmentDoc}
`;
export type CastStartMutationFn = Apollo.MutationFunction<
  CastStartMutation,
  CastStartMutationVariables
>;
export type CastStartProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CastStartMutation,
    CastStartMutationVariables
  >;
} &
  TChildProps;
export function withCastStart<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CastStartMutation,
    CastStartMutationVariables,
    CastStartProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CastStartMutation,
    CastStartMutationVariables,
    CastStartProps<TChildProps, TDataName>
  >(CastStartDocument, {
    alias: "castStart",
    ...operationOptions,
  });
}

/**
 * __useCastStartMutation__
 *
 * To run a mutation, you first call `useCastStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCastStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [castStartMutation, { data, loading, error }] = useCastStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCastStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CastStartMutation,
    CastStartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CastStartMutation, CastStartMutationVariables>(
    CastStartDocument,
    options
  );
}
export type CastStartMutationHookResult = ReturnType<
  typeof useCastStartMutation
>;
export type CastStartMutationResult = Apollo.MutationResult<CastStartMutation>;
export type CastStartMutationOptions = Apollo.BaseMutationOptions<
  CastStartMutation,
  CastStartMutationVariables
>;
export const CastStopDocument = gql`
  mutation castStop($input: CastStopInput!) {
    castStop(input: $input) {
      screens {
        __typename
        id
        name
        castId
      }
    }
  }
`;
export type CastStopMutationFn = Apollo.MutationFunction<
  CastStopMutation,
  CastStopMutationVariables
>;
export type CastStopProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CastStopMutation,
    CastStopMutationVariables
  >;
} &
  TChildProps;
export function withCastStop<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CastStopMutation,
    CastStopMutationVariables,
    CastStopProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CastStopMutation,
    CastStopMutationVariables,
    CastStopProps<TChildProps, TDataName>
  >(CastStopDocument, {
    alias: "castStop",
    ...operationOptions,
  });
}

/**
 * __useCastStopMutation__
 *
 * To run a mutation, you first call `useCastStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCastStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [castStopMutation, { data, loading, error }] = useCastStopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCastStopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CastStopMutation,
    CastStopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CastStopMutation, CastStopMutationVariables>(
    CastStopDocument,
    options
  );
}
export type CastStopMutationHookResult = ReturnType<typeof useCastStopMutation>;
export type CastStopMutationResult = Apollo.MutationResult<CastStopMutation>;
export type CastStopMutationOptions = Apollo.BaseMutationOptions<
  CastStopMutation,
  CastStopMutationVariables
>;
export const ClearCacheScreensByOrgIdDocument = gql`
  mutation clearCacheScreensByOrgId {
    clearCacheScreensByOrgId {
      clearCacheSucceeded
      clearCacheFailed
      error
    }
  }
`;
export type ClearCacheScreensByOrgIdMutationFn = Apollo.MutationFunction<
  ClearCacheScreensByOrgIdMutation,
  ClearCacheScreensByOrgIdMutationVariables
>;
export type ClearCacheScreensByOrgIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ClearCacheScreensByOrgIdMutation,
    ClearCacheScreensByOrgIdMutationVariables
  >;
} &
  TChildProps;
export function withClearCacheScreensByOrgId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClearCacheScreensByOrgIdMutation,
    ClearCacheScreensByOrgIdMutationVariables,
    ClearCacheScreensByOrgIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ClearCacheScreensByOrgIdMutation,
    ClearCacheScreensByOrgIdMutationVariables,
    ClearCacheScreensByOrgIdProps<TChildProps, TDataName>
  >(ClearCacheScreensByOrgIdDocument, {
    alias: "clearCacheScreensByOrgId",
    ...operationOptions,
  });
}

/**
 * __useClearCacheScreensByOrgIdMutation__
 *
 * To run a mutation, you first call `useClearCacheScreensByOrgIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCacheScreensByOrgIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCacheScreensByOrgIdMutation, { data, loading, error }] = useClearCacheScreensByOrgIdMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearCacheScreensByOrgIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearCacheScreensByOrgIdMutation,
    ClearCacheScreensByOrgIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearCacheScreensByOrgIdMutation,
    ClearCacheScreensByOrgIdMutationVariables
  >(ClearCacheScreensByOrgIdDocument, options);
}
export type ClearCacheScreensByOrgIdMutationHookResult = ReturnType<
  typeof useClearCacheScreensByOrgIdMutation
>;
export type ClearCacheScreensByOrgIdMutationResult = Apollo.MutationResult<ClearCacheScreensByOrgIdMutation>;
export type ClearCacheScreensByOrgIdMutationOptions = Apollo.BaseMutationOptions<
  ClearCacheScreensByOrgIdMutation,
  ClearCacheScreensByOrgIdMutationVariables
>;
export const ClearCacheScreensByScreenIdsDocument = gql`
  mutation clearCacheScreensByScreenIds($screenIds: [UUID]!) {
    clearCacheScreensByScreenIds(screenIds: $screenIds) {
      clearCacheSucceeded
      clearCacheFailed
      error
    }
  }
`;
export type ClearCacheScreensByScreenIdsMutationFn = Apollo.MutationFunction<
  ClearCacheScreensByScreenIdsMutation,
  ClearCacheScreensByScreenIdsMutationVariables
>;
export type ClearCacheScreensByScreenIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ClearCacheScreensByScreenIdsMutation,
    ClearCacheScreensByScreenIdsMutationVariables
  >;
} &
  TChildProps;
export function withClearCacheScreensByScreenIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClearCacheScreensByScreenIdsMutation,
    ClearCacheScreensByScreenIdsMutationVariables,
    ClearCacheScreensByScreenIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ClearCacheScreensByScreenIdsMutation,
    ClearCacheScreensByScreenIdsMutationVariables,
    ClearCacheScreensByScreenIdsProps<TChildProps, TDataName>
  >(ClearCacheScreensByScreenIdsDocument, {
    alias: "clearCacheScreensByScreenIds",
    ...operationOptions,
  });
}

/**
 * __useClearCacheScreensByScreenIdsMutation__
 *
 * To run a mutation, you first call `useClearCacheScreensByScreenIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCacheScreensByScreenIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCacheScreensByScreenIdsMutation, { data, loading, error }] = useClearCacheScreensByScreenIdsMutation({
 *   variables: {
 *      screenIds: // value for 'screenIds'
 *   },
 * });
 */
export function useClearCacheScreensByScreenIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearCacheScreensByScreenIdsMutation,
    ClearCacheScreensByScreenIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearCacheScreensByScreenIdsMutation,
    ClearCacheScreensByScreenIdsMutationVariables
  >(ClearCacheScreensByScreenIdsDocument, options);
}
export type ClearCacheScreensByScreenIdsMutationHookResult = ReturnType<
  typeof useClearCacheScreensByScreenIdsMutation
>;
export type ClearCacheScreensByScreenIdsMutationResult = Apollo.MutationResult<ClearCacheScreensByScreenIdsMutation>;
export type ClearCacheScreensByScreenIdsMutationOptions = Apollo.BaseMutationOptions<
  ClearCacheScreensByScreenIdsMutation,
  ClearCacheScreensByScreenIdsMutationVariables
>;
export const ClearCacheScreensBySpaceIdDocument = gql`
  mutation clearCacheScreensBySpaceId($spaceId: UUID!) {
    clearCacheScreensBySpaceId(spaceId: $spaceId) {
      clearCacheSucceeded
      clearCacheFailed
      error
    }
  }
`;
export type ClearCacheScreensBySpaceIdMutationFn = Apollo.MutationFunction<
  ClearCacheScreensBySpaceIdMutation,
  ClearCacheScreensBySpaceIdMutationVariables
>;
export type ClearCacheScreensBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ClearCacheScreensBySpaceIdMutation,
    ClearCacheScreensBySpaceIdMutationVariables
  >;
} &
  TChildProps;
export function withClearCacheScreensBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClearCacheScreensBySpaceIdMutation,
    ClearCacheScreensBySpaceIdMutationVariables,
    ClearCacheScreensBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ClearCacheScreensBySpaceIdMutation,
    ClearCacheScreensBySpaceIdMutationVariables,
    ClearCacheScreensBySpaceIdProps<TChildProps, TDataName>
  >(ClearCacheScreensBySpaceIdDocument, {
    alias: "clearCacheScreensBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useClearCacheScreensBySpaceIdMutation__
 *
 * To run a mutation, you first call `useClearCacheScreensBySpaceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCacheScreensBySpaceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCacheScreensBySpaceIdMutation, { data, loading, error }] = useClearCacheScreensBySpaceIdMutation({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useClearCacheScreensBySpaceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearCacheScreensBySpaceIdMutation,
    ClearCacheScreensBySpaceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearCacheScreensBySpaceIdMutation,
    ClearCacheScreensBySpaceIdMutationVariables
  >(ClearCacheScreensBySpaceIdDocument, options);
}
export type ClearCacheScreensBySpaceIdMutationHookResult = ReturnType<
  typeof useClearCacheScreensBySpaceIdMutation
>;
export type ClearCacheScreensBySpaceIdMutationResult = Apollo.MutationResult<ClearCacheScreensBySpaceIdMutation>;
export type ClearCacheScreensBySpaceIdMutationOptions = Apollo.BaseMutationOptions<
  ClearCacheScreensBySpaceIdMutation,
  ClearCacheScreensBySpaceIdMutationVariables
>;
export const CreateCastsByAppInstanceIdDocument = gql`
  mutation createCastsByAppInstanceId(
    $input: CreateCastsByAppInstanceIdInput!
  ) {
    createCastsByAppInstanceId(input: $input) {
      casts {
        ...CreateCastPayload
      }
    }
  }
  ${CreateCastPayloadFragmentDoc}
`;
export type CreateCastsByAppInstanceIdMutationFn = Apollo.MutationFunction<
  CreateCastsByAppInstanceIdMutation,
  CreateCastsByAppInstanceIdMutationVariables
>;
export type CreateCastsByAppInstanceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCastsByAppInstanceIdMutation,
    CreateCastsByAppInstanceIdMutationVariables
  >;
} &
  TChildProps;
export function withCreateCastsByAppInstanceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCastsByAppInstanceIdMutation,
    CreateCastsByAppInstanceIdMutationVariables,
    CreateCastsByAppInstanceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCastsByAppInstanceIdMutation,
    CreateCastsByAppInstanceIdMutationVariables,
    CreateCastsByAppInstanceIdProps<TChildProps, TDataName>
  >(CreateCastsByAppInstanceIdDocument, {
    alias: "createCastsByAppInstanceId",
    ...operationOptions,
  });
}

/**
 * __useCreateCastsByAppInstanceIdMutation__
 *
 * To run a mutation, you first call `useCreateCastsByAppInstanceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCastsByAppInstanceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCastsByAppInstanceIdMutation, { data, loading, error }] = useCreateCastsByAppInstanceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCastsByAppInstanceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCastsByAppInstanceIdMutation,
    CreateCastsByAppInstanceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCastsByAppInstanceIdMutation,
    CreateCastsByAppInstanceIdMutationVariables
  >(CreateCastsByAppInstanceIdDocument, options);
}
export type CreateCastsByAppInstanceIdMutationHookResult = ReturnType<
  typeof useCreateCastsByAppInstanceIdMutation
>;
export type CreateCastsByAppInstanceIdMutationResult = Apollo.MutationResult<CreateCastsByAppInstanceIdMutation>;
export type CreateCastsByAppInstanceIdMutationOptions = Apollo.BaseMutationOptions<
  CreateCastsByAppInstanceIdMutation,
  CreateCastsByAppInstanceIdMutationVariables
>;
export const CreateCastsByChannelIdDocument = gql`
  mutation createCastsByChannelId($input: CreateCastsByChannelIdInput!) {
    createCastsByChannelId(input: $input) {
      casts {
        __typename
        id
        content
        screensByCastId {
          nodes {
            __typename
            id
            castId
            name
            content
            castByCastId {
              __typename
              id
              content
              channelByCastId {
                __typename
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateCastsByChannelIdMutationFn = Apollo.MutationFunction<
  CreateCastsByChannelIdMutation,
  CreateCastsByChannelIdMutationVariables
>;
export type CreateCastsByChannelIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCastsByChannelIdMutation,
    CreateCastsByChannelIdMutationVariables
  >;
} &
  TChildProps;
export function withCreateCastsByChannelId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCastsByChannelIdMutation,
    CreateCastsByChannelIdMutationVariables,
    CreateCastsByChannelIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCastsByChannelIdMutation,
    CreateCastsByChannelIdMutationVariables,
    CreateCastsByChannelIdProps<TChildProps, TDataName>
  >(CreateCastsByChannelIdDocument, {
    alias: "createCastsByChannelId",
    ...operationOptions,
  });
}

/**
 * __useCreateCastsByChannelIdMutation__
 *
 * To run a mutation, you first call `useCreateCastsByChannelIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCastsByChannelIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCastsByChannelIdMutation, { data, loading, error }] = useCreateCastsByChannelIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCastsByChannelIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCastsByChannelIdMutation,
    CreateCastsByChannelIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCastsByChannelIdMutation,
    CreateCastsByChannelIdMutationVariables
  >(CreateCastsByChannelIdDocument, options);
}
export type CreateCastsByChannelIdMutationHookResult = ReturnType<
  typeof useCreateCastsByChannelIdMutation
>;
export type CreateCastsByChannelIdMutationResult = Apollo.MutationResult<CreateCastsByChannelIdMutation>;
export type CreateCastsByChannelIdMutationOptions = Apollo.BaseMutationOptions<
  CreateCastsByChannelIdMutation,
  CreateCastsByChannelIdMutationVariables
>;
export const CreateCastsByFileIdDocument = gql`
  mutation createCastsByFileId($input: CreateCastsByFileIdInput!) {
    createCastsByFileId(input: $input) {
      casts {
        ...CreateCastPayload
      }
    }
  }
  ${CreateCastPayloadFragmentDoc}
`;
export type CreateCastsByFileIdMutationFn = Apollo.MutationFunction<
  CreateCastsByFileIdMutation,
  CreateCastsByFileIdMutationVariables
>;
export type CreateCastsByFileIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCastsByFileIdMutation,
    CreateCastsByFileIdMutationVariables
  >;
} &
  TChildProps;
export function withCreateCastsByFileId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCastsByFileIdMutation,
    CreateCastsByFileIdMutationVariables,
    CreateCastsByFileIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCastsByFileIdMutation,
    CreateCastsByFileIdMutationVariables,
    CreateCastsByFileIdProps<TChildProps, TDataName>
  >(CreateCastsByFileIdDocument, {
    alias: "createCastsByFileId",
    ...operationOptions,
  });
}

/**
 * __useCreateCastsByFileIdMutation__
 *
 * To run a mutation, you first call `useCreateCastsByFileIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCastsByFileIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCastsByFileIdMutation, { data, loading, error }] = useCreateCastsByFileIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCastsByFileIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCastsByFileIdMutation,
    CreateCastsByFileIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCastsByFileIdMutation,
    CreateCastsByFileIdMutationVariables
  >(CreateCastsByFileIdDocument, options);
}
export type CreateCastsByFileIdMutationHookResult = ReturnType<
  typeof useCreateCastsByFileIdMutation
>;
export type CreateCastsByFileIdMutationResult = Apollo.MutationResult<CreateCastsByFileIdMutation>;
export type CreateCastsByFileIdMutationOptions = Apollo.BaseMutationOptions<
  CreateCastsByFileIdMutation,
  CreateCastsByFileIdMutationVariables
>;
export const CreateCastsByLinkIdDocument = gql`
  mutation createCastsByLinkId($input: CreateCastsByLinkIdInput!) {
    createCastsByLinkId(input: $input) {
      casts {
        ...CreateCastPayload
      }
    }
  }
  ${CreateCastPayloadFragmentDoc}
`;
export type CreateCastsByLinkIdMutationFn = Apollo.MutationFunction<
  CreateCastsByLinkIdMutation,
  CreateCastsByLinkIdMutationVariables
>;
export type CreateCastsByLinkIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCastsByLinkIdMutation,
    CreateCastsByLinkIdMutationVariables
  >;
} &
  TChildProps;
export function withCreateCastsByLinkId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCastsByLinkIdMutation,
    CreateCastsByLinkIdMutationVariables,
    CreateCastsByLinkIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCastsByLinkIdMutation,
    CreateCastsByLinkIdMutationVariables,
    CreateCastsByLinkIdProps<TChildProps, TDataName>
  >(CreateCastsByLinkIdDocument, {
    alias: "createCastsByLinkId",
    ...operationOptions,
  });
}

/**
 * __useCreateCastsByLinkIdMutation__
 *
 * To run a mutation, you first call `useCreateCastsByLinkIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCastsByLinkIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCastsByLinkIdMutation, { data, loading, error }] = useCreateCastsByLinkIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCastsByLinkIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCastsByLinkIdMutation,
    CreateCastsByLinkIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCastsByLinkIdMutation,
    CreateCastsByLinkIdMutationVariables
  >(CreateCastsByLinkIdDocument, options);
}
export type CreateCastsByLinkIdMutationHookResult = ReturnType<
  typeof useCreateCastsByLinkIdMutation
>;
export type CreateCastsByLinkIdMutationResult = Apollo.MutationResult<CreateCastsByLinkIdMutation>;
export type CreateCastsByLinkIdMutationOptions = Apollo.BaseMutationOptions<
  CreateCastsByLinkIdMutation,
  CreateCastsByLinkIdMutationVariables
>;
export const CreateCastsByPlaylistIdDocument = gql`
  mutation createCastsByPlaylistId($input: CreateCastsByPlaylistIdInput!) {
    createCastsByPlaylistId(input: $input) {
      casts {
        ...CreateCastPayload
      }
    }
  }
  ${CreateCastPayloadFragmentDoc}
`;
export type CreateCastsByPlaylistIdMutationFn = Apollo.MutationFunction<
  CreateCastsByPlaylistIdMutation,
  CreateCastsByPlaylistIdMutationVariables
>;
export type CreateCastsByPlaylistIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCastsByPlaylistIdMutation,
    CreateCastsByPlaylistIdMutationVariables
  >;
} &
  TChildProps;
export function withCreateCastsByPlaylistId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCastsByPlaylistIdMutation,
    CreateCastsByPlaylistIdMutationVariables,
    CreateCastsByPlaylistIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCastsByPlaylistIdMutation,
    CreateCastsByPlaylistIdMutationVariables,
    CreateCastsByPlaylistIdProps<TChildProps, TDataName>
  >(CreateCastsByPlaylistIdDocument, {
    alias: "createCastsByPlaylistId",
    ...operationOptions,
  });
}

/**
 * __useCreateCastsByPlaylistIdMutation__
 *
 * To run a mutation, you first call `useCreateCastsByPlaylistIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCastsByPlaylistIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCastsByPlaylistIdMutation, { data, loading, error }] = useCreateCastsByPlaylistIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCastsByPlaylistIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCastsByPlaylistIdMutation,
    CreateCastsByPlaylistIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCastsByPlaylistIdMutation,
    CreateCastsByPlaylistIdMutationVariables
  >(CreateCastsByPlaylistIdDocument, options);
}
export type CreateCastsByPlaylistIdMutationHookResult = ReturnType<
  typeof useCreateCastsByPlaylistIdMutation
>;
export type CreateCastsByPlaylistIdMutationResult = Apollo.MutationResult<CreateCastsByPlaylistIdMutation>;
export type CreateCastsByPlaylistIdMutationOptions = Apollo.BaseMutationOptions<
  CreateCastsByPlaylistIdMutation,
  CreateCastsByPlaylistIdMutationVariables
>;
export const CreateCastsBySiteIdDocument = gql`
  mutation createCastsBySiteId($input: CreateCastsBySiteIdInput!) {
    createCastsBySiteId(input: $input) {
      casts {
        __typename
        id
        content
        screensByCastId {
          nodes {
            __typename
            id
            castId
            name
            content
            castByCastId {
              __typename
              id
              content
              siteByCastId {
                __typename
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateCastsBySiteIdMutationFn = Apollo.MutationFunction<
  CreateCastsBySiteIdMutation,
  CreateCastsBySiteIdMutationVariables
>;
export type CreateCastsBySiteIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCastsBySiteIdMutation,
    CreateCastsBySiteIdMutationVariables
  >;
} &
  TChildProps;
export function withCreateCastsBySiteId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCastsBySiteIdMutation,
    CreateCastsBySiteIdMutationVariables,
    CreateCastsBySiteIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCastsBySiteIdMutation,
    CreateCastsBySiteIdMutationVariables,
    CreateCastsBySiteIdProps<TChildProps, TDataName>
  >(CreateCastsBySiteIdDocument, {
    alias: "createCastsBySiteId",
    ...operationOptions,
  });
}

/**
 * __useCreateCastsBySiteIdMutation__
 *
 * To run a mutation, you first call `useCreateCastsBySiteIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCastsBySiteIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCastsBySiteIdMutation, { data, loading, error }] = useCreateCastsBySiteIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCastsBySiteIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCastsBySiteIdMutation,
    CreateCastsBySiteIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCastsBySiteIdMutation,
    CreateCastsBySiteIdMutationVariables
  >(CreateCastsBySiteIdDocument, options);
}
export type CreateCastsBySiteIdMutationHookResult = ReturnType<
  typeof useCreateCastsBySiteIdMutation
>;
export type CreateCastsBySiteIdMutationResult = Apollo.MutationResult<CreateCastsBySiteIdMutation>;
export type CreateCastsBySiteIdMutationOptions = Apollo.BaseMutationOptions<
  CreateCastsBySiteIdMutation,
  CreateCastsBySiteIdMutationVariables
>;
export const CreateCastsDocument = gql`
  mutation createCasts($input: CreateCastsInput!) {
    createCasts(input: $input) {
      casts {
        ...CastByCastId
        screensByCastId {
          nodes {
            __typename
            id
            castId
            name
            content
            device
            spaceId
          }
        }
      }
    }
  }
  ${CastByCastIdFragmentDoc}
`;
export type CreateCastsMutationFn = Apollo.MutationFunction<
  CreateCastsMutation,
  CreateCastsMutationVariables
>;
export type CreateCastsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCastsMutation,
    CreateCastsMutationVariables
  >;
} &
  TChildProps;
export function withCreateCasts<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCastsMutation,
    CreateCastsMutationVariables,
    CreateCastsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCastsMutation,
    CreateCastsMutationVariables,
    CreateCastsProps<TChildProps, TDataName>
  >(CreateCastsDocument, {
    alias: "createCasts",
    ...operationOptions,
  });
}

/**
 * __useCreateCastsMutation__
 *
 * To run a mutation, you first call `useCreateCastsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCastsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCastsMutation, { data, loading, error }] = useCreateCastsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCastsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCastsMutation,
    CreateCastsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCastsMutation, CreateCastsMutationVariables>(
    CreateCastsDocument,
    options
  );
}
export type CreateCastsMutationHookResult = ReturnType<
  typeof useCreateCastsMutation
>;
export type CreateCastsMutationResult = Apollo.MutationResult<CreateCastsMutation>;
export type CreateCastsMutationOptions = Apollo.BaseMutationOptions<
  CreateCastsMutation,
  CreateCastsMutationVariables
>;
export const CreateOrUpdateWhiteLabelDocument = gql`
  mutation createOrUpdateWhiteLabel($input: CreateOrUpdateWhiteLabelInput!) {
    createOrUpdateWhiteLabel(input: $input) {
      clientMutationId
    }
  }
`;
export type CreateOrUpdateWhiteLabelMutationFn = Apollo.MutationFunction<
  CreateOrUpdateWhiteLabelMutation,
  CreateOrUpdateWhiteLabelMutationVariables
>;
export type CreateOrUpdateWhiteLabelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOrUpdateWhiteLabelMutation,
    CreateOrUpdateWhiteLabelMutationVariables
  >;
} &
  TChildProps;
export function withCreateOrUpdateWhiteLabel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrUpdateWhiteLabelMutation,
    CreateOrUpdateWhiteLabelMutationVariables,
    CreateOrUpdateWhiteLabelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrUpdateWhiteLabelMutation,
    CreateOrUpdateWhiteLabelMutationVariables,
    CreateOrUpdateWhiteLabelProps<TChildProps, TDataName>
  >(CreateOrUpdateWhiteLabelDocument, {
    alias: "createOrUpdateWhiteLabel",
    ...operationOptions,
  });
}

/**
 * __useCreateOrUpdateWhiteLabelMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateWhiteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateWhiteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateWhiteLabelMutation, { data, loading, error }] = useCreateOrUpdateWhiteLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateWhiteLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateWhiteLabelMutation,
    CreateOrUpdateWhiteLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateWhiteLabelMutation,
    CreateOrUpdateWhiteLabelMutationVariables
  >(CreateOrUpdateWhiteLabelDocument, options);
}
export type CreateOrUpdateWhiteLabelMutationHookResult = ReturnType<
  typeof useCreateOrUpdateWhiteLabelMutation
>;
export type CreateOrUpdateWhiteLabelMutationResult = Apollo.MutationResult<CreateOrUpdateWhiteLabelMutation>;
export type CreateOrUpdateWhiteLabelMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateWhiteLabelMutation,
  CreateOrUpdateWhiteLabelMutationVariables
>;
export const CreateSignedAppManagementJwtDocument = gql`
  mutation createSignedAppManagementJwt($input: AppManagementJwtRequestInput!) {
    createSignedAppManagementJwt(input: $input) {
      signedAppManagementToken
      tokenType
      expiresAt
    }
  }
`;
export type CreateSignedAppManagementJwtMutationFn = Apollo.MutationFunction<
  CreateSignedAppManagementJwtMutation,
  CreateSignedAppManagementJwtMutationVariables
>;
export type CreateSignedAppManagementJwtProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateSignedAppManagementJwtMutation,
    CreateSignedAppManagementJwtMutationVariables
  >;
} &
  TChildProps;
export function withCreateSignedAppManagementJwt<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSignedAppManagementJwtMutation,
    CreateSignedAppManagementJwtMutationVariables,
    CreateSignedAppManagementJwtProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSignedAppManagementJwtMutation,
    CreateSignedAppManagementJwtMutationVariables,
    CreateSignedAppManagementJwtProps<TChildProps, TDataName>
  >(CreateSignedAppManagementJwtDocument, {
    alias: "createSignedAppManagementJwt",
    ...operationOptions,
  });
}

/**
 * __useCreateSignedAppManagementJwtMutation__
 *
 * To run a mutation, you first call `useCreateSignedAppManagementJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignedAppManagementJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignedAppManagementJwtMutation, { data, loading, error }] = useCreateSignedAppManagementJwtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSignedAppManagementJwtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSignedAppManagementJwtMutation,
    CreateSignedAppManagementJwtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSignedAppManagementJwtMutation,
    CreateSignedAppManagementJwtMutationVariables
  >(CreateSignedAppManagementJwtDocument, options);
}
export type CreateSignedAppManagementJwtMutationHookResult = ReturnType<
  typeof useCreateSignedAppManagementJwtMutation
>;
export type CreateSignedAppManagementJwtMutationResult = Apollo.MutationResult<CreateSignedAppManagementJwtMutation>;
export type CreateSignedAppManagementJwtMutationOptions = Apollo.BaseMutationOptions<
  CreateSignedAppManagementJwtMutation,
  CreateSignedAppManagementJwtMutationVariables
>;
export const CreateSignedRuntimeJwtDocument = gql`
  mutation createSignedRuntimeJwt($input: RuntimeJwtRequestInput!) {
    createSignedRuntimeJwt(input: $input) {
      signedRuntimeToken
      tokenType
      expiresAt
    }
  }
`;
export type CreateSignedRuntimeJwtMutationFn = Apollo.MutationFunction<
  CreateSignedRuntimeJwtMutation,
  CreateSignedRuntimeJwtMutationVariables
>;
export type CreateSignedRuntimeJwtProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateSignedRuntimeJwtMutation,
    CreateSignedRuntimeJwtMutationVariables
  >;
} &
  TChildProps;
export function withCreateSignedRuntimeJwt<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSignedRuntimeJwtMutation,
    CreateSignedRuntimeJwtMutationVariables,
    CreateSignedRuntimeJwtProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSignedRuntimeJwtMutation,
    CreateSignedRuntimeJwtMutationVariables,
    CreateSignedRuntimeJwtProps<TChildProps, TDataName>
  >(CreateSignedRuntimeJwtDocument, {
    alias: "createSignedRuntimeJwt",
    ...operationOptions,
  });
}

/**
 * __useCreateSignedRuntimeJwtMutation__
 *
 * To run a mutation, you first call `useCreateSignedRuntimeJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignedRuntimeJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignedRuntimeJwtMutation, { data, loading, error }] = useCreateSignedRuntimeJwtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSignedRuntimeJwtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSignedRuntimeJwtMutation,
    CreateSignedRuntimeJwtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSignedRuntimeJwtMutation,
    CreateSignedRuntimeJwtMutationVariables
  >(CreateSignedRuntimeJwtDocument, options);
}
export type CreateSignedRuntimeJwtMutationHookResult = ReturnType<
  typeof useCreateSignedRuntimeJwtMutation
>;
export type CreateSignedRuntimeJwtMutationResult = Apollo.MutationResult<CreateSignedRuntimeJwtMutation>;
export type CreateSignedRuntimeJwtMutationOptions = Apollo.BaseMutationOptions<
  CreateSignedRuntimeJwtMutation,
  CreateSignedRuntimeJwtMutationVariables
>;
export const CurrentOrgDocument = gql`
  query currentOrg {
    currentOrg {
      ...Org
      featureFlagOrgsByOrgId {
        nodes {
          ...FeatureFlagOrg
        }
      }
      screensByOrgId {
        totalCount
      }
      spacesByOrgId {
        totalCount
      }
    }
  }
  ${OrgFragmentDoc}
  ${FeatureFlagOrgFragmentDoc}
`;
export type CurrentOrgProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CurrentOrgQuery,
    CurrentOrgQueryVariables
  >;
} &
  TChildProps;
export function withCurrentOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrentOrgQuery,
    CurrentOrgQueryVariables,
    CurrentOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrentOrgQuery,
    CurrentOrgQueryVariables,
    CurrentOrgProps<TChildProps, TDataName>
  >(CurrentOrgDocument, {
    alias: "currentOrg",
    ...operationOptions,
  });
}

/**
 * __useCurrentOrgQuery__
 *
 * To run a query within a React component, call `useCurrentOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentOrgQuery,
    CurrentOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentOrgQuery, CurrentOrgQueryVariables>(
    CurrentOrgDocument,
    options
  );
}
export function useCurrentOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentOrgQuery,
    CurrentOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentOrgQuery, CurrentOrgQueryVariables>(
    CurrentOrgDocument,
    options
  );
}
export type CurrentOrgQueryHookResult = ReturnType<typeof useCurrentOrgQuery>;
export type CurrentOrgLazyQueryHookResult = ReturnType<
  typeof useCurrentOrgLazyQuery
>;
export type CurrentOrgQueryResult = Apollo.QueryResult<
  CurrentOrgQuery,
  CurrentOrgQueryVariables
>;
export function refetchCurrentOrgQuery(variables?: CurrentOrgQueryVariables) {
  return { query: CurrentOrgDocument, variables: variables };
}
export const MigrationCheckDocument = gql`
  query migrationCheck {
    migrationCheck
  }
`;
export type MigrationCheckProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    MigrationCheckQuery,
    MigrationCheckQueryVariables
  >;
} &
  TChildProps;
export function withMigrationCheck<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MigrationCheckQuery,
    MigrationCheckQueryVariables,
    MigrationCheckProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MigrationCheckQuery,
    MigrationCheckQueryVariables,
    MigrationCheckProps<TChildProps, TDataName>
  >(MigrationCheckDocument, {
    alias: "migrationCheck",
    ...operationOptions,
  });
}

/**
 * __useMigrationCheckQuery__
 *
 * To run a query within a React component, call `useMigrationCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useMigrationCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMigrationCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useMigrationCheckQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MigrationCheckQuery,
    MigrationCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MigrationCheckQuery, MigrationCheckQueryVariables>(
    MigrationCheckDocument,
    options
  );
}
export function useMigrationCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MigrationCheckQuery,
    MigrationCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MigrationCheckQuery, MigrationCheckQueryVariables>(
    MigrationCheckDocument,
    options
  );
}
export type MigrationCheckQueryHookResult = ReturnType<
  typeof useMigrationCheckQuery
>;
export type MigrationCheckLazyQueryHookResult = ReturnType<
  typeof useMigrationCheckLazyQuery
>;
export type MigrationCheckQueryResult = Apollo.QueryResult<
  MigrationCheckQuery,
  MigrationCheckQueryVariables
>;
export function refetchMigrationCheckQuery(
  variables?: MigrationCheckQueryVariables
) {
  return { query: MigrationCheckDocument, variables: variables };
}
export const CreateOnboardingAppInstanceDocument = gql`
  mutation createOnboardingAppInstance($input: CreateAppInstanceInput!) {
    createAppInstance(input: $input) {
      appInstance {
        __typename
        id
        name
      }
    }
  }
`;
export type CreateOnboardingAppInstanceMutationFn = Apollo.MutationFunction<
  CreateOnboardingAppInstanceMutation,
  CreateOnboardingAppInstanceMutationVariables
>;
export type CreateOnboardingAppInstanceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOnboardingAppInstanceMutation,
    CreateOnboardingAppInstanceMutationVariables
  >;
} &
  TChildProps;
export function withCreateOnboardingAppInstance<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOnboardingAppInstanceMutation,
    CreateOnboardingAppInstanceMutationVariables,
    CreateOnboardingAppInstanceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOnboardingAppInstanceMutation,
    CreateOnboardingAppInstanceMutationVariables,
    CreateOnboardingAppInstanceProps<TChildProps, TDataName>
  >(CreateOnboardingAppInstanceDocument, {
    alias: "createOnboardingAppInstance",
    ...operationOptions,
  });
}

/**
 * __useCreateOnboardingAppInstanceMutation__
 *
 * To run a mutation, you first call `useCreateOnboardingAppInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnboardingAppInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnboardingAppInstanceMutation, { data, loading, error }] = useCreateOnboardingAppInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnboardingAppInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOnboardingAppInstanceMutation,
    CreateOnboardingAppInstanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOnboardingAppInstanceMutation,
    CreateOnboardingAppInstanceMutationVariables
  >(CreateOnboardingAppInstanceDocument, options);
}
export type CreateOnboardingAppInstanceMutationHookResult = ReturnType<
  typeof useCreateOnboardingAppInstanceMutation
>;
export type CreateOnboardingAppInstanceMutationResult = Apollo.MutationResult<CreateOnboardingAppInstanceMutation>;
export type CreateOnboardingAppInstanceMutationOptions = Apollo.BaseMutationOptions<
  CreateOnboardingAppInstanceMutation,
  CreateOnboardingAppInstanceMutationVariables
>;
export const CreateOnboardingChannelDocument = gql`
  mutation createOnboardingChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      channel {
        __typename
        id
        name
      }
    }
  }
`;
export type CreateOnboardingChannelMutationFn = Apollo.MutationFunction<
  CreateOnboardingChannelMutation,
  CreateOnboardingChannelMutationVariables
>;
export type CreateOnboardingChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOnboardingChannelMutation,
    CreateOnboardingChannelMutationVariables
  >;
} &
  TChildProps;
export function withCreateOnboardingChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOnboardingChannelMutation,
    CreateOnboardingChannelMutationVariables,
    CreateOnboardingChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOnboardingChannelMutation,
    CreateOnboardingChannelMutationVariables,
    CreateOnboardingChannelProps<TChildProps, TDataName>
  >(CreateOnboardingChannelDocument, {
    alias: "createOnboardingChannel",
    ...operationOptions,
  });
}

/**
 * __useCreateOnboardingChannelMutation__
 *
 * To run a mutation, you first call `useCreateOnboardingChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnboardingChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnboardingChannelMutation, { data, loading, error }] = useCreateOnboardingChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnboardingChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOnboardingChannelMutation,
    CreateOnboardingChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOnboardingChannelMutation,
    CreateOnboardingChannelMutationVariables
  >(CreateOnboardingChannelDocument, options);
}
export type CreateOnboardingChannelMutationHookResult = ReturnType<
  typeof useCreateOnboardingChannelMutation
>;
export type CreateOnboardingChannelMutationResult = Apollo.MutationResult<CreateOnboardingChannelMutation>;
export type CreateOnboardingChannelMutationOptions = Apollo.BaseMutationOptions<
  CreateOnboardingChannelMutation,
  CreateOnboardingChannelMutationVariables
>;
export const CreateOnboardingFileDocument = gql`
  mutation createOnboardingFile($input: CreateFileInput!) {
    createFile(input: $input) {
      file {
        __typename
        id
        name
        source
      }
    }
  }
`;
export type CreateOnboardingFileMutationFn = Apollo.MutationFunction<
  CreateOnboardingFileMutation,
  CreateOnboardingFileMutationVariables
>;
export type CreateOnboardingFileProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOnboardingFileMutation,
    CreateOnboardingFileMutationVariables
  >;
} &
  TChildProps;
export function withCreateOnboardingFile<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOnboardingFileMutation,
    CreateOnboardingFileMutationVariables,
    CreateOnboardingFileProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOnboardingFileMutation,
    CreateOnboardingFileMutationVariables,
    CreateOnboardingFileProps<TChildProps, TDataName>
  >(CreateOnboardingFileDocument, {
    alias: "createOnboardingFile",
    ...operationOptions,
  });
}

/**
 * __useCreateOnboardingFileMutation__
 *
 * To run a mutation, you first call `useCreateOnboardingFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnboardingFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnboardingFileMutation, { data, loading, error }] = useCreateOnboardingFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnboardingFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOnboardingFileMutation,
    CreateOnboardingFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOnboardingFileMutation,
    CreateOnboardingFileMutationVariables
  >(CreateOnboardingFileDocument, options);
}
export type CreateOnboardingFileMutationHookResult = ReturnType<
  typeof useCreateOnboardingFileMutation
>;
export type CreateOnboardingFileMutationResult = Apollo.MutationResult<CreateOnboardingFileMutation>;
export type CreateOnboardingFileMutationOptions = Apollo.BaseMutationOptions<
  CreateOnboardingFileMutation,
  CreateOnboardingFileMutationVariables
>;
export const CreateOnboardingPlaylistDocument = gql`
  mutation createOnboardingPlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      playlist {
        __typename
        id
        name
      }
    }
  }
`;
export type CreateOnboardingPlaylistMutationFn = Apollo.MutationFunction<
  CreateOnboardingPlaylistMutation,
  CreateOnboardingPlaylistMutationVariables
>;
export type CreateOnboardingPlaylistProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOnboardingPlaylistMutation,
    CreateOnboardingPlaylistMutationVariables
  >;
} &
  TChildProps;
export function withCreateOnboardingPlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOnboardingPlaylistMutation,
    CreateOnboardingPlaylistMutationVariables,
    CreateOnboardingPlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOnboardingPlaylistMutation,
    CreateOnboardingPlaylistMutationVariables,
    CreateOnboardingPlaylistProps<TChildProps, TDataName>
  >(CreateOnboardingPlaylistDocument, {
    alias: "createOnboardingPlaylist",
    ...operationOptions,
  });
}

/**
 * __useCreateOnboardingPlaylistMutation__
 *
 * To run a mutation, you first call `useCreateOnboardingPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnboardingPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnboardingPlaylistMutation, { data, loading, error }] = useCreateOnboardingPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnboardingPlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOnboardingPlaylistMutation,
    CreateOnboardingPlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOnboardingPlaylistMutation,
    CreateOnboardingPlaylistMutationVariables
  >(CreateOnboardingPlaylistDocument, options);
}
export type CreateOnboardingPlaylistMutationHookResult = ReturnType<
  typeof useCreateOnboardingPlaylistMutation
>;
export type CreateOnboardingPlaylistMutationResult = Apollo.MutationResult<CreateOnboardingPlaylistMutation>;
export type CreateOnboardingPlaylistMutationOptions = Apollo.BaseMutationOptions<
  CreateOnboardingPlaylistMutation,
  CreateOnboardingPlaylistMutationVariables
>;
export const GetBrandInfoDocument = gql`
  query getBrandInfo($domainName: String!) {
    getBrandInfo(domainName: $domainName) {
      name
      domain
      claimed
      description
      images {
        type
        formats {
          src
          background
          format
          height
          width
          size
        }
      }
      fonts {
        name
        type
        origin
        originId
      }
      colors {
        hex
        type
        brightness
      }
      logos {
        type
        theme
        formats {
          src
          background
          format
          height
          width
          size
        }
      }
      links {
        name
        url
      }
    }
  }
`;
export type GetBrandInfoProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetBrandInfoQuery,
    GetBrandInfoQueryVariables
  >;
} &
  TChildProps;
export function withGetBrandInfo<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBrandInfoQuery,
    GetBrandInfoQueryVariables,
    GetBrandInfoProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBrandInfoQuery,
    GetBrandInfoQueryVariables,
    GetBrandInfoProps<TChildProps, TDataName>
  >(GetBrandInfoDocument, {
    alias: "getBrandInfo",
    ...operationOptions,
  });
}

/**
 * __useGetBrandInfoQuery__
 *
 * To run a query within a React component, call `useGetBrandInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandInfoQuery({
 *   variables: {
 *      domainName: // value for 'domainName'
 *   },
 * });
 */
export function useGetBrandInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBrandInfoQuery,
    GetBrandInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandInfoQuery, GetBrandInfoQueryVariables>(
    GetBrandInfoDocument,
    options
  );
}
export function useGetBrandInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandInfoQuery,
    GetBrandInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandInfoQuery, GetBrandInfoQueryVariables>(
    GetBrandInfoDocument,
    options
  );
}
export type GetBrandInfoQueryHookResult = ReturnType<
  typeof useGetBrandInfoQuery
>;
export type GetBrandInfoLazyQueryHookResult = ReturnType<
  typeof useGetBrandInfoLazyQuery
>;
export type GetBrandInfoQueryResult = Apollo.QueryResult<
  GetBrandInfoQuery,
  GetBrandInfoQueryVariables
>;
export function refetchGetBrandInfoQuery(
  variables?: GetBrandInfoQueryVariables
) {
  return { query: GetBrandInfoDocument, variables: variables };
}
export const InstallOnboardingAppDocument = gql`
  mutation installOnboardingApp($input: InstallSpaceAppInput!) {
    installSpaceApp(input: $input) {
      appInstall {
        __typename
        id
        appByAppId {
          __typename
          id
          name
        }
      }
    }
  }
`;
export type InstallOnboardingAppMutationFn = Apollo.MutationFunction<
  InstallOnboardingAppMutation,
  InstallOnboardingAppMutationVariables
>;
export type InstallOnboardingAppProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    InstallOnboardingAppMutation,
    InstallOnboardingAppMutationVariables
  >;
} &
  TChildProps;
export function withInstallOnboardingApp<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    InstallOnboardingAppMutation,
    InstallOnboardingAppMutationVariables,
    InstallOnboardingAppProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    InstallOnboardingAppMutation,
    InstallOnboardingAppMutationVariables,
    InstallOnboardingAppProps<TChildProps, TDataName>
  >(InstallOnboardingAppDocument, {
    alias: "installOnboardingApp",
    ...operationOptions,
  });
}

/**
 * __useInstallOnboardingAppMutation__
 *
 * To run a mutation, you first call `useInstallOnboardingAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallOnboardingAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installOnboardingAppMutation, { data, loading, error }] = useInstallOnboardingAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstallOnboardingAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstallOnboardingAppMutation,
    InstallOnboardingAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstallOnboardingAppMutation,
    InstallOnboardingAppMutationVariables
  >(InstallOnboardingAppDocument, options);
}
export type InstallOnboardingAppMutationHookResult = ReturnType<
  typeof useInstallOnboardingAppMutation
>;
export type InstallOnboardingAppMutationResult = Apollo.MutationResult<InstallOnboardingAppMutation>;
export type InstallOnboardingAppMutationOptions = Apollo.BaseMutationOptions<
  InstallOnboardingAppMutation,
  InstallOnboardingAppMutationVariables
>;
export const UpdateOnboardingAppInstanceDocument = gql`
  mutation updateOnboardingAppInstance($input: UpdateAppInstanceInput!) {
    updateAppInstance(input: $input) {
      appInstance {
        __typename
        id
        name
      }
    }
  }
`;
export type UpdateOnboardingAppInstanceMutationFn = Apollo.MutationFunction<
  UpdateOnboardingAppInstanceMutation,
  UpdateOnboardingAppInstanceMutationVariables
>;
export type UpdateOnboardingAppInstanceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateOnboardingAppInstanceMutation,
    UpdateOnboardingAppInstanceMutationVariables
  >;
} &
  TChildProps;
export function withUpdateOnboardingAppInstance<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOnboardingAppInstanceMutation,
    UpdateOnboardingAppInstanceMutationVariables,
    UpdateOnboardingAppInstanceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOnboardingAppInstanceMutation,
    UpdateOnboardingAppInstanceMutationVariables,
    UpdateOnboardingAppInstanceProps<TChildProps, TDataName>
  >(UpdateOnboardingAppInstanceDocument, {
    alias: "updateOnboardingAppInstance",
    ...operationOptions,
  });
}

/**
 * __useUpdateOnboardingAppInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingAppInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingAppInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingAppInstanceMutation, { data, loading, error }] = useUpdateOnboardingAppInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardingAppInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardingAppInstanceMutation,
    UpdateOnboardingAppInstanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOnboardingAppInstanceMutation,
    UpdateOnboardingAppInstanceMutationVariables
  >(UpdateOnboardingAppInstanceDocument, options);
}
export type UpdateOnboardingAppInstanceMutationHookResult = ReturnType<
  typeof useUpdateOnboardingAppInstanceMutation
>;
export type UpdateOnboardingAppInstanceMutationResult = Apollo.MutationResult<UpdateOnboardingAppInstanceMutation>;
export type UpdateOnboardingAppInstanceMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardingAppInstanceMutation,
  UpdateOnboardingAppInstanceMutationVariables
>;
export const UpdateOnboardingChannelThemeDocument = gql`
  mutation updateOnboardingChannelTheme($input: UpdateChannelThemeInput!) {
    updateChannelTheme(input: $input) {
      channel {
        __typename
        id
        name
      }
    }
  }
`;
export type UpdateOnboardingChannelThemeMutationFn = Apollo.MutationFunction<
  UpdateOnboardingChannelThemeMutation,
  UpdateOnboardingChannelThemeMutationVariables
>;
export type UpdateOnboardingChannelThemeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateOnboardingChannelThemeMutation,
    UpdateOnboardingChannelThemeMutationVariables
  >;
} &
  TChildProps;
export function withUpdateOnboardingChannelTheme<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOnboardingChannelThemeMutation,
    UpdateOnboardingChannelThemeMutationVariables,
    UpdateOnboardingChannelThemeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOnboardingChannelThemeMutation,
    UpdateOnboardingChannelThemeMutationVariables,
    UpdateOnboardingChannelThemeProps<TChildProps, TDataName>
  >(UpdateOnboardingChannelThemeDocument, {
    alias: "updateOnboardingChannelTheme",
    ...operationOptions,
  });
}

/**
 * __useUpdateOnboardingChannelThemeMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingChannelThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingChannelThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingChannelThemeMutation, { data, loading, error }] = useUpdateOnboardingChannelThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardingChannelThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardingChannelThemeMutation,
    UpdateOnboardingChannelThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOnboardingChannelThemeMutation,
    UpdateOnboardingChannelThemeMutationVariables
  >(UpdateOnboardingChannelThemeDocument, options);
}
export type UpdateOnboardingChannelThemeMutationHookResult = ReturnType<
  typeof useUpdateOnboardingChannelThemeMutation
>;
export type UpdateOnboardingChannelThemeMutationResult = Apollo.MutationResult<UpdateOnboardingChannelThemeMutation>;
export type UpdateOnboardingChannelThemeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardingChannelThemeMutation,
  UpdateOnboardingChannelThemeMutationVariables
>;
export const UpdateOnboardingFileByIdDocument = gql`
  mutation updateOnboardingFileById($input: UpdateFileByIdInput!) {
    updateFileById(input: $input) {
      file {
        __typename
        id
        name
        source
      }
    }
  }
`;
export type UpdateOnboardingFileByIdMutationFn = Apollo.MutationFunction<
  UpdateOnboardingFileByIdMutation,
  UpdateOnboardingFileByIdMutationVariables
>;
export type UpdateOnboardingFileByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateOnboardingFileByIdMutation,
    UpdateOnboardingFileByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateOnboardingFileById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOnboardingFileByIdMutation,
    UpdateOnboardingFileByIdMutationVariables,
    UpdateOnboardingFileByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOnboardingFileByIdMutation,
    UpdateOnboardingFileByIdMutationVariables,
    UpdateOnboardingFileByIdProps<TChildProps, TDataName>
  >(UpdateOnboardingFileByIdDocument, {
    alias: "updateOnboardingFileById",
    ...operationOptions,
  });
}

/**
 * __useUpdateOnboardingFileByIdMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingFileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingFileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingFileByIdMutation, { data, loading, error }] = useUpdateOnboardingFileByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardingFileByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardingFileByIdMutation,
    UpdateOnboardingFileByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOnboardingFileByIdMutation,
    UpdateOnboardingFileByIdMutationVariables
  >(UpdateOnboardingFileByIdDocument, options);
}
export type UpdateOnboardingFileByIdMutationHookResult = ReturnType<
  typeof useUpdateOnboardingFileByIdMutation
>;
export type UpdateOnboardingFileByIdMutationResult = Apollo.MutationResult<UpdateOnboardingFileByIdMutation>;
export type UpdateOnboardingFileByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardingFileByIdMutation,
  UpdateOnboardingFileByIdMutationVariables
>;
export const UpdateOrgOnboardingDataDocument = gql`
  mutation updateOrgOnboardingData($input: UpdateOrgOnboardingDataInput!) {
    updateOrgOnboardingData(input: $input) {
      org {
        __typename
        id
        name
        preferences
      }
    }
  }
`;
export type UpdateOrgOnboardingDataMutationFn = Apollo.MutationFunction<
  UpdateOrgOnboardingDataMutation,
  UpdateOrgOnboardingDataMutationVariables
>;
export type UpdateOrgOnboardingDataProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateOrgOnboardingDataMutation,
    UpdateOrgOnboardingDataMutationVariables
  >;
} &
  TChildProps;
export function withUpdateOrgOnboardingData<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOrgOnboardingDataMutation,
    UpdateOrgOnboardingDataMutationVariables,
    UpdateOrgOnboardingDataProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOrgOnboardingDataMutation,
    UpdateOrgOnboardingDataMutationVariables,
    UpdateOrgOnboardingDataProps<TChildProps, TDataName>
  >(UpdateOrgOnboardingDataDocument, {
    alias: "updateOrgOnboardingData",
    ...operationOptions,
  });
}

/**
 * __useUpdateOrgOnboardingDataMutation__
 *
 * To run a mutation, you first call `useUpdateOrgOnboardingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgOnboardingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgOnboardingDataMutation, { data, loading, error }] = useUpdateOrgOnboardingDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgOnboardingDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgOnboardingDataMutation,
    UpdateOrgOnboardingDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgOnboardingDataMutation,
    UpdateOrgOnboardingDataMutationVariables
  >(UpdateOrgOnboardingDataDocument, options);
}
export type UpdateOrgOnboardingDataMutationHookResult = ReturnType<
  typeof useUpdateOrgOnboardingDataMutation
>;
export type UpdateOrgOnboardingDataMutationResult = Apollo.MutationResult<UpdateOrgOnboardingDataMutation>;
export type UpdateOrgOnboardingDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgOnboardingDataMutation,
  UpdateOrgOnboardingDataMutationVariables
>;
export const UpdateUserOnboardingDataDocument = gql`
  mutation UpdateUserOnboardingData($input: UpdateUserOnboardingDataInput!) {
    updateUserOnboardingData(input: $input) {
      user {
        __typename
        id
        orgId
        email
        preferences
      }
    }
  }
`;
export type UpdateUserOnboardingDataMutationFn = Apollo.MutationFunction<
  UpdateUserOnboardingDataMutation,
  UpdateUserOnboardingDataMutationVariables
>;
export type UpdateUserOnboardingDataProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateUserOnboardingDataMutation,
    UpdateUserOnboardingDataMutationVariables
  >;
} &
  TChildProps;
export function withUpdateUserOnboardingData<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserOnboardingDataMutation,
    UpdateUserOnboardingDataMutationVariables,
    UpdateUserOnboardingDataProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserOnboardingDataMutation,
    UpdateUserOnboardingDataMutationVariables,
    UpdateUserOnboardingDataProps<TChildProps, TDataName>
  >(UpdateUserOnboardingDataDocument, {
    alias: "updateUserOnboardingData",
    ...operationOptions,
  });
}

/**
 * __useUpdateUserOnboardingDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserOnboardingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOnboardingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOnboardingDataMutation, { data, loading, error }] = useUpdateUserOnboardingDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserOnboardingDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserOnboardingDataMutation,
    UpdateUserOnboardingDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserOnboardingDataMutation,
    UpdateUserOnboardingDataMutationVariables
  >(UpdateUserOnboardingDataDocument, options);
}
export type UpdateUserOnboardingDataMutationHookResult = ReturnType<
  typeof useUpdateUserOnboardingDataMutation
>;
export type UpdateUserOnboardingDataMutationResult = Apollo.MutationResult<UpdateUserOnboardingDataMutation>;
export type UpdateUserOnboardingDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserOnboardingDataMutation,
  UpdateUserOnboardingDataMutationVariables
>;
export const RefreshScreensByOrgIdDocument = gql`
  mutation refreshScreensByOrgId {
    refreshScreensByOrgId {
      refreshSucceeded
      refreshFailed
      error
    }
  }
`;
export type RefreshScreensByOrgIdMutationFn = Apollo.MutationFunction<
  RefreshScreensByOrgIdMutation,
  RefreshScreensByOrgIdMutationVariables
>;
export type RefreshScreensByOrgIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RefreshScreensByOrgIdMutation,
    RefreshScreensByOrgIdMutationVariables
  >;
} &
  TChildProps;
export function withRefreshScreensByOrgId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RefreshScreensByOrgIdMutation,
    RefreshScreensByOrgIdMutationVariables,
    RefreshScreensByOrgIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RefreshScreensByOrgIdMutation,
    RefreshScreensByOrgIdMutationVariables,
    RefreshScreensByOrgIdProps<TChildProps, TDataName>
  >(RefreshScreensByOrgIdDocument, {
    alias: "refreshScreensByOrgId",
    ...operationOptions,
  });
}

/**
 * __useRefreshScreensByOrgIdMutation__
 *
 * To run a mutation, you first call `useRefreshScreensByOrgIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshScreensByOrgIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshScreensByOrgIdMutation, { data, loading, error }] = useRefreshScreensByOrgIdMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshScreensByOrgIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshScreensByOrgIdMutation,
    RefreshScreensByOrgIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshScreensByOrgIdMutation,
    RefreshScreensByOrgIdMutationVariables
  >(RefreshScreensByOrgIdDocument, options);
}
export type RefreshScreensByOrgIdMutationHookResult = ReturnType<
  typeof useRefreshScreensByOrgIdMutation
>;
export type RefreshScreensByOrgIdMutationResult = Apollo.MutationResult<RefreshScreensByOrgIdMutation>;
export type RefreshScreensByOrgIdMutationOptions = Apollo.BaseMutationOptions<
  RefreshScreensByOrgIdMutation,
  RefreshScreensByOrgIdMutationVariables
>;
export const RefreshScreensByScreenIdsDocument = gql`
  mutation refreshScreensByScreenIds($screenIds: [UUID]!) {
    refreshScreensByScreenIds(screenIds: $screenIds) {
      refreshSucceeded
      refreshFailed
      error
    }
  }
`;
export type RefreshScreensByScreenIdsMutationFn = Apollo.MutationFunction<
  RefreshScreensByScreenIdsMutation,
  RefreshScreensByScreenIdsMutationVariables
>;
export type RefreshScreensByScreenIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RefreshScreensByScreenIdsMutation,
    RefreshScreensByScreenIdsMutationVariables
  >;
} &
  TChildProps;
export function withRefreshScreensByScreenIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RefreshScreensByScreenIdsMutation,
    RefreshScreensByScreenIdsMutationVariables,
    RefreshScreensByScreenIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RefreshScreensByScreenIdsMutation,
    RefreshScreensByScreenIdsMutationVariables,
    RefreshScreensByScreenIdsProps<TChildProps, TDataName>
  >(RefreshScreensByScreenIdsDocument, {
    alias: "refreshScreensByScreenIds",
    ...operationOptions,
  });
}

/**
 * __useRefreshScreensByScreenIdsMutation__
 *
 * To run a mutation, you first call `useRefreshScreensByScreenIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshScreensByScreenIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshScreensByScreenIdsMutation, { data, loading, error }] = useRefreshScreensByScreenIdsMutation({
 *   variables: {
 *      screenIds: // value for 'screenIds'
 *   },
 * });
 */
export function useRefreshScreensByScreenIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshScreensByScreenIdsMutation,
    RefreshScreensByScreenIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshScreensByScreenIdsMutation,
    RefreshScreensByScreenIdsMutationVariables
  >(RefreshScreensByScreenIdsDocument, options);
}
export type RefreshScreensByScreenIdsMutationHookResult = ReturnType<
  typeof useRefreshScreensByScreenIdsMutation
>;
export type RefreshScreensByScreenIdsMutationResult = Apollo.MutationResult<RefreshScreensByScreenIdsMutation>;
export type RefreshScreensByScreenIdsMutationOptions = Apollo.BaseMutationOptions<
  RefreshScreensByScreenIdsMutation,
  RefreshScreensByScreenIdsMutationVariables
>;
export const RefreshScreensBySpaceIdDocument = gql`
  mutation refreshScreensBySpaceId($spaceId: UUID!) {
    refreshScreensBySpaceId(spaceId: $spaceId) {
      refreshSucceeded
      refreshFailed
      error
    }
  }
`;
export type RefreshScreensBySpaceIdMutationFn = Apollo.MutationFunction<
  RefreshScreensBySpaceIdMutation,
  RefreshScreensBySpaceIdMutationVariables
>;
export type RefreshScreensBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RefreshScreensBySpaceIdMutation,
    RefreshScreensBySpaceIdMutationVariables
  >;
} &
  TChildProps;
export function withRefreshScreensBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RefreshScreensBySpaceIdMutation,
    RefreshScreensBySpaceIdMutationVariables,
    RefreshScreensBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RefreshScreensBySpaceIdMutation,
    RefreshScreensBySpaceIdMutationVariables,
    RefreshScreensBySpaceIdProps<TChildProps, TDataName>
  >(RefreshScreensBySpaceIdDocument, {
    alias: "refreshScreensBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useRefreshScreensBySpaceIdMutation__
 *
 * To run a mutation, you first call `useRefreshScreensBySpaceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshScreensBySpaceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshScreensBySpaceIdMutation, { data, loading, error }] = useRefreshScreensBySpaceIdMutation({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useRefreshScreensBySpaceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshScreensBySpaceIdMutation,
    RefreshScreensBySpaceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshScreensBySpaceIdMutation,
    RefreshScreensBySpaceIdMutationVariables
  >(RefreshScreensBySpaceIdDocument, options);
}
export type RefreshScreensBySpaceIdMutationHookResult = ReturnType<
  typeof useRefreshScreensBySpaceIdMutation
>;
export type RefreshScreensBySpaceIdMutationResult = Apollo.MutationResult<RefreshScreensBySpaceIdMutation>;
export type RefreshScreensBySpaceIdMutationOptions = Apollo.BaseMutationOptions<
  RefreshScreensBySpaceIdMutation,
  RefreshScreensBySpaceIdMutationVariables
>;
export const RemoveSplashThemeDocument = gql`
  mutation removeSplashTheme($input: RemoveSplashThemeInput!) {
    removeSplashTheme(input: $input) {
      org {
        __typename
        id
        name
      }
    }
  }
`;
export type RemoveSplashThemeMutationFn = Apollo.MutationFunction<
  RemoveSplashThemeMutation,
  RemoveSplashThemeMutationVariables
>;
export type RemoveSplashThemeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RemoveSplashThemeMutation,
    RemoveSplashThemeMutationVariables
  >;
} &
  TChildProps;
export function withRemoveSplashTheme<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveSplashThemeMutation,
    RemoveSplashThemeMutationVariables,
    RemoveSplashThemeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveSplashThemeMutation,
    RemoveSplashThemeMutationVariables,
    RemoveSplashThemeProps<TChildProps, TDataName>
  >(RemoveSplashThemeDocument, {
    alias: "removeSplashTheme",
    ...operationOptions,
  });
}

/**
 * __useRemoveSplashThemeMutation__
 *
 * To run a mutation, you first call `useRemoveSplashThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSplashThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSplashThemeMutation, { data, loading, error }] = useRemoveSplashThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSplashThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSplashThemeMutation,
    RemoveSplashThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSplashThemeMutation,
    RemoveSplashThemeMutationVariables
  >(RemoveSplashThemeDocument, options);
}
export type RemoveSplashThemeMutationHookResult = ReturnType<
  typeof useRemoveSplashThemeMutation
>;
export type RemoveSplashThemeMutationResult = Apollo.MutationResult<RemoveSplashThemeMutation>;
export type RemoveSplashThemeMutationOptions = Apollo.BaseMutationOptions<
  RemoveSplashThemeMutation,
  RemoveSplashThemeMutationVariables
>;
export const ResetWhiteLabelDocument = gql`
  mutation resetWhiteLabel($id: UUID!) {
    resetWhiteLabel(input: { id: $id }) {
      clientMutationId
    }
  }
`;
export type ResetWhiteLabelMutationFn = Apollo.MutationFunction<
  ResetWhiteLabelMutation,
  ResetWhiteLabelMutationVariables
>;
export type ResetWhiteLabelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ResetWhiteLabelMutation,
    ResetWhiteLabelMutationVariables
  >;
} &
  TChildProps;
export function withResetWhiteLabel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResetWhiteLabelMutation,
    ResetWhiteLabelMutationVariables,
    ResetWhiteLabelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResetWhiteLabelMutation,
    ResetWhiteLabelMutationVariables,
    ResetWhiteLabelProps<TChildProps, TDataName>
  >(ResetWhiteLabelDocument, {
    alias: "resetWhiteLabel",
    ...operationOptions,
  });
}

/**
 * __useResetWhiteLabelMutation__
 *
 * To run a mutation, you first call `useResetWhiteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetWhiteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetWhiteLabelMutation, { data, loading, error }] = useResetWhiteLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetWhiteLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetWhiteLabelMutation,
    ResetWhiteLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetWhiteLabelMutation,
    ResetWhiteLabelMutationVariables
  >(ResetWhiteLabelDocument, options);
}
export type ResetWhiteLabelMutationHookResult = ReturnType<
  typeof useResetWhiteLabelMutation
>;
export type ResetWhiteLabelMutationResult = Apollo.MutationResult<ResetWhiteLabelMutation>;
export type ResetWhiteLabelMutationOptions = Apollo.BaseMutationOptions<
  ResetWhiteLabelMutation,
  ResetWhiteLabelMutationVariables
>;
export const ResetWhiteLabelGlyphDocument = gql`
  mutation resetWhiteLabelGlyph($id: UUID!) {
    resetWhiteLabelGlyph(input: { id: $id }) {
      whiteLabel {
        __typename
        id
        glyphFileId
      }
    }
  }
`;
export type ResetWhiteLabelGlyphMutationFn = Apollo.MutationFunction<
  ResetWhiteLabelGlyphMutation,
  ResetWhiteLabelGlyphMutationVariables
>;
export type ResetWhiteLabelGlyphProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ResetWhiteLabelGlyphMutation,
    ResetWhiteLabelGlyphMutationVariables
  >;
} &
  TChildProps;
export function withResetWhiteLabelGlyph<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResetWhiteLabelGlyphMutation,
    ResetWhiteLabelGlyphMutationVariables,
    ResetWhiteLabelGlyphProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResetWhiteLabelGlyphMutation,
    ResetWhiteLabelGlyphMutationVariables,
    ResetWhiteLabelGlyphProps<TChildProps, TDataName>
  >(ResetWhiteLabelGlyphDocument, {
    alias: "resetWhiteLabelGlyph",
    ...operationOptions,
  });
}

/**
 * __useResetWhiteLabelGlyphMutation__
 *
 * To run a mutation, you first call `useResetWhiteLabelGlyphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetWhiteLabelGlyphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetWhiteLabelGlyphMutation, { data, loading, error }] = useResetWhiteLabelGlyphMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetWhiteLabelGlyphMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetWhiteLabelGlyphMutation,
    ResetWhiteLabelGlyphMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetWhiteLabelGlyphMutation,
    ResetWhiteLabelGlyphMutationVariables
  >(ResetWhiteLabelGlyphDocument, options);
}
export type ResetWhiteLabelGlyphMutationHookResult = ReturnType<
  typeof useResetWhiteLabelGlyphMutation
>;
export type ResetWhiteLabelGlyphMutationResult = Apollo.MutationResult<ResetWhiteLabelGlyphMutation>;
export type ResetWhiteLabelGlyphMutationOptions = Apollo.BaseMutationOptions<
  ResetWhiteLabelGlyphMutation,
  ResetWhiteLabelGlyphMutationVariables
>;
export const ScreenCastStopDocument = gql`
  mutation screenCastStop($input: ScreenCastStopInput!) {
    screenCastStop(input: $input) {
      screen {
        __typename
        id
        name
        castId
        spaceId
        castByCastId {
          ...CastByCastId
        }
      }
    }
  }
  ${CastByCastIdFragmentDoc}
`;
export type ScreenCastStopMutationFn = Apollo.MutationFunction<
  ScreenCastStopMutation,
  ScreenCastStopMutationVariables
>;
export type ScreenCastStopProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ScreenCastStopMutation,
    ScreenCastStopMutationVariables
  >;
} &
  TChildProps;
export function withScreenCastStop<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenCastStopMutation,
    ScreenCastStopMutationVariables,
    ScreenCastStopProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ScreenCastStopMutation,
    ScreenCastStopMutationVariables,
    ScreenCastStopProps<TChildProps, TDataName>
  >(ScreenCastStopDocument, {
    alias: "screenCastStop",
    ...operationOptions,
  });
}

/**
 * __useScreenCastStopMutation__
 *
 * To run a mutation, you first call `useScreenCastStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScreenCastStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [screenCastStopMutation, { data, loading, error }] = useScreenCastStopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScreenCastStopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScreenCastStopMutation,
    ScreenCastStopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScreenCastStopMutation,
    ScreenCastStopMutationVariables
  >(ScreenCastStopDocument, options);
}
export type ScreenCastStopMutationHookResult = ReturnType<
  typeof useScreenCastStopMutation
>;
export type ScreenCastStopMutationResult = Apollo.MutationResult<ScreenCastStopMutation>;
export type ScreenCastStopMutationOptions = Apollo.BaseMutationOptions<
  ScreenCastStopMutation,
  ScreenCastStopMutationVariables
>;
export const ScreenNamesByScreenIdsDocument = gql`
  query screenNamesByScreenIds($screenIds: [UUID]!) {
    screenByIds(screenIds: $screenIds) {
      nodes {
        __typename
        id
        name
      }
    }
  }
`;
export type ScreenNamesByScreenIdsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenNamesByScreenIdsQuery,
    ScreenNamesByScreenIdsQueryVariables
  >;
} &
  TChildProps;
export function withScreenNamesByScreenIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenNamesByScreenIdsQuery,
    ScreenNamesByScreenIdsQueryVariables,
    ScreenNamesByScreenIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenNamesByScreenIdsQuery,
    ScreenNamesByScreenIdsQueryVariables,
    ScreenNamesByScreenIdsProps<TChildProps, TDataName>
  >(ScreenNamesByScreenIdsDocument, {
    alias: "screenNamesByScreenIds",
    ...operationOptions,
  });
}

/**
 * __useScreenNamesByScreenIdsQuery__
 *
 * To run a query within a React component, call `useScreenNamesByScreenIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenNamesByScreenIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenNamesByScreenIdsQuery({
 *   variables: {
 *      screenIds: // value for 'screenIds'
 *   },
 * });
 */
export function useScreenNamesByScreenIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenNamesByScreenIdsQuery,
    ScreenNamesByScreenIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScreenNamesByScreenIdsQuery,
    ScreenNamesByScreenIdsQueryVariables
  >(ScreenNamesByScreenIdsDocument, options);
}
export function useScreenNamesByScreenIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenNamesByScreenIdsQuery,
    ScreenNamesByScreenIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScreenNamesByScreenIdsQuery,
    ScreenNamesByScreenIdsQueryVariables
  >(ScreenNamesByScreenIdsDocument, options);
}
export type ScreenNamesByScreenIdsQueryHookResult = ReturnType<
  typeof useScreenNamesByScreenIdsQuery
>;
export type ScreenNamesByScreenIdsLazyQueryHookResult = ReturnType<
  typeof useScreenNamesByScreenIdsLazyQuery
>;
export type ScreenNamesByScreenIdsQueryResult = Apollo.QueryResult<
  ScreenNamesByScreenIdsQuery,
  ScreenNamesByScreenIdsQueryVariables
>;
export function refetchScreenNamesByScreenIdsQuery(
  variables?: ScreenNamesByScreenIdsQueryVariables
) {
  return { query: ScreenNamesByScreenIdsDocument, variables: variables };
}
export const SendCommandToScreensByScreenIdsDocument = gql`
  mutation sendCommandToScreensByScreenIds($input: SendCommandInput!) {
    sendCommandToScreensByScreenIds(input: $input) {
      commandSucceeded
      commandFailed
      error
    }
  }
`;
export type SendCommandToScreensByScreenIdsMutationFn = Apollo.MutationFunction<
  SendCommandToScreensByScreenIdsMutation,
  SendCommandToScreensByScreenIdsMutationVariables
>;
export type SendCommandToScreensByScreenIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SendCommandToScreensByScreenIdsMutation,
    SendCommandToScreensByScreenIdsMutationVariables
  >;
} &
  TChildProps;
export function withSendCommandToScreensByScreenIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SendCommandToScreensByScreenIdsMutation,
    SendCommandToScreensByScreenIdsMutationVariables,
    SendCommandToScreensByScreenIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SendCommandToScreensByScreenIdsMutation,
    SendCommandToScreensByScreenIdsMutationVariables,
    SendCommandToScreensByScreenIdsProps<TChildProps, TDataName>
  >(SendCommandToScreensByScreenIdsDocument, {
    alias: "sendCommandToScreensByScreenIds",
    ...operationOptions,
  });
}

/**
 * __useSendCommandToScreensByScreenIdsMutation__
 *
 * To run a mutation, you first call `useSendCommandToScreensByScreenIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCommandToScreensByScreenIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCommandToScreensByScreenIdsMutation, { data, loading, error }] = useSendCommandToScreensByScreenIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCommandToScreensByScreenIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCommandToScreensByScreenIdsMutation,
    SendCommandToScreensByScreenIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendCommandToScreensByScreenIdsMutation,
    SendCommandToScreensByScreenIdsMutationVariables
  >(SendCommandToScreensByScreenIdsDocument, options);
}
export type SendCommandToScreensByScreenIdsMutationHookResult = ReturnType<
  typeof useSendCommandToScreensByScreenIdsMutation
>;
export type SendCommandToScreensByScreenIdsMutationResult = Apollo.MutationResult<SendCommandToScreensByScreenIdsMutation>;
export type SendCommandToScreensByScreenIdsMutationOptions = Apollo.BaseMutationOptions<
  SendCommandToScreensByScreenIdsMutation,
  SendCommandToScreensByScreenIdsMutationVariables
>;
export const TagsByAppIdAndSpaceIdDocument = gql`
  query tagsByAppIdAndSpaceId(
    $appId: UUID
    $spaceId: UUID
    $isTemplate: Boolean
    $isShared: Boolean
  ) {
    tagsByAppIdAndSpaceId(
      appId: $appId
      spaceId: $spaceId
      isTemplate: $isTemplate
      isShared: $isShared
    )
  }
`;
export type TagsByAppIdAndSpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    TagsByAppIdAndSpaceIdQuery,
    TagsByAppIdAndSpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withTagsByAppIdAndSpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TagsByAppIdAndSpaceIdQuery,
    TagsByAppIdAndSpaceIdQueryVariables,
    TagsByAppIdAndSpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TagsByAppIdAndSpaceIdQuery,
    TagsByAppIdAndSpaceIdQueryVariables,
    TagsByAppIdAndSpaceIdProps<TChildProps, TDataName>
  >(TagsByAppIdAndSpaceIdDocument, {
    alias: "tagsByAppIdAndSpaceId",
    ...operationOptions,
  });
}

/**
 * __useTagsByAppIdAndSpaceIdQuery__
 *
 * To run a query within a React component, call `useTagsByAppIdAndSpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsByAppIdAndSpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsByAppIdAndSpaceIdQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *      isTemplate: // value for 'isTemplate'
 *      isShared: // value for 'isShared'
 *   },
 * });
 */
export function useTagsByAppIdAndSpaceIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TagsByAppIdAndSpaceIdQuery,
    TagsByAppIdAndSpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TagsByAppIdAndSpaceIdQuery,
    TagsByAppIdAndSpaceIdQueryVariables
  >(TagsByAppIdAndSpaceIdDocument, options);
}
export function useTagsByAppIdAndSpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagsByAppIdAndSpaceIdQuery,
    TagsByAppIdAndSpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TagsByAppIdAndSpaceIdQuery,
    TagsByAppIdAndSpaceIdQueryVariables
  >(TagsByAppIdAndSpaceIdDocument, options);
}
export type TagsByAppIdAndSpaceIdQueryHookResult = ReturnType<
  typeof useTagsByAppIdAndSpaceIdQuery
>;
export type TagsByAppIdAndSpaceIdLazyQueryHookResult = ReturnType<
  typeof useTagsByAppIdAndSpaceIdLazyQuery
>;
export type TagsByAppIdAndSpaceIdQueryResult = Apollo.QueryResult<
  TagsByAppIdAndSpaceIdQuery,
  TagsByAppIdAndSpaceIdQueryVariables
>;
export function refetchTagsByAppIdAndSpaceIdQuery(
  variables?: TagsByAppIdAndSpaceIdQueryVariables
) {
  return { query: TagsByAppIdAndSpaceIdDocument, variables: variables };
}
export const GetAllNotificationRulesByScreenIdDocument = gql`
  query getAllNotificationRulesByScreenId($screenId: UUIDFilter) {
    allNotificationRules(filter: { screenId: $screenId }) {
      nodes {
        id
        screenId
        active
        waitTime
        notificationSubscriptionsByNotificationRuleId {
          nodes {
            id
            channel
            active
            userByUserIdAndOrgId {
              id
              email
              givenName
              metadata
            }
          }
        }
      }
    }
  }
`;
export type GetAllNotificationRulesByScreenIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAllNotificationRulesByScreenIdQuery,
    GetAllNotificationRulesByScreenIdQueryVariables
  >;
} &
  TChildProps;
export function withGetAllNotificationRulesByScreenId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllNotificationRulesByScreenIdQuery,
    GetAllNotificationRulesByScreenIdQueryVariables,
    GetAllNotificationRulesByScreenIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllNotificationRulesByScreenIdQuery,
    GetAllNotificationRulesByScreenIdQueryVariables,
    GetAllNotificationRulesByScreenIdProps<TChildProps, TDataName>
  >(GetAllNotificationRulesByScreenIdDocument, {
    alias: "getAllNotificationRulesByScreenId",
    ...operationOptions,
  });
}

/**
 * __useGetAllNotificationRulesByScreenIdQuery__
 *
 * To run a query within a React component, call `useGetAllNotificationRulesByScreenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotificationRulesByScreenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotificationRulesByScreenIdQuery({
 *   variables: {
 *      screenId: // value for 'screenId'
 *   },
 * });
 */
export function useGetAllNotificationRulesByScreenIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllNotificationRulesByScreenIdQuery,
    GetAllNotificationRulesByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllNotificationRulesByScreenIdQuery,
    GetAllNotificationRulesByScreenIdQueryVariables
  >(GetAllNotificationRulesByScreenIdDocument, options);
}
export function useGetAllNotificationRulesByScreenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllNotificationRulesByScreenIdQuery,
    GetAllNotificationRulesByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllNotificationRulesByScreenIdQuery,
    GetAllNotificationRulesByScreenIdQueryVariables
  >(GetAllNotificationRulesByScreenIdDocument, options);
}
export type GetAllNotificationRulesByScreenIdQueryHookResult = ReturnType<
  typeof useGetAllNotificationRulesByScreenIdQuery
>;
export type GetAllNotificationRulesByScreenIdLazyQueryHookResult = ReturnType<
  typeof useGetAllNotificationRulesByScreenIdLazyQuery
>;
export type GetAllNotificationRulesByScreenIdQueryResult = Apollo.QueryResult<
  GetAllNotificationRulesByScreenIdQuery,
  GetAllNotificationRulesByScreenIdQueryVariables
>;
export function refetchGetAllNotificationRulesByScreenIdQuery(
  variables?: GetAllNotificationRulesByScreenIdQueryVariables
) {
  return {
    query: GetAllNotificationRulesByScreenIdDocument,
    variables: variables,
  };
}
export const CreateNotificationRuleDocument = gql`
  mutation createNotificationRule($input: CreateNotificationRuleInput!) {
    createNotificationRule(input: $input) {
      notificationRule {
        id
        active
        waitTime
      }
    }
  }
`;
export type CreateNotificationRuleMutationFn = Apollo.MutationFunction<
  CreateNotificationRuleMutation,
  CreateNotificationRuleMutationVariables
>;
export type CreateNotificationRuleProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateNotificationRuleMutation,
    CreateNotificationRuleMutationVariables
  >;
} &
  TChildProps;
export function withCreateNotificationRule<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateNotificationRuleMutation,
    CreateNotificationRuleMutationVariables,
    CreateNotificationRuleProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateNotificationRuleMutation,
    CreateNotificationRuleMutationVariables,
    CreateNotificationRuleProps<TChildProps, TDataName>
  >(CreateNotificationRuleDocument, {
    alias: "createNotificationRule",
    ...operationOptions,
  });
}

/**
 * __useCreateNotificationRuleMutation__
 *
 * To run a mutation, you first call `useCreateNotificationRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationRuleMutation, { data, loading, error }] = useCreateNotificationRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotificationRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationRuleMutation,
    CreateNotificationRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNotificationRuleMutation,
    CreateNotificationRuleMutationVariables
  >(CreateNotificationRuleDocument, options);
}
export type CreateNotificationRuleMutationHookResult = ReturnType<
  typeof useCreateNotificationRuleMutation
>;
export type CreateNotificationRuleMutationResult = Apollo.MutationResult<CreateNotificationRuleMutation>;
export type CreateNotificationRuleMutationOptions = Apollo.BaseMutationOptions<
  CreateNotificationRuleMutation,
  CreateNotificationRuleMutationVariables
>;
export const SubscribeNotificationDocument = gql`
  mutation subscribeNotification($input: SubscribeNotificationInput!) {
    subscribeNotification(input: $input) {
      notificationRule {
        id
        active
        notificationSubscriptionsByNotificationRuleId {
          nodes {
            id
            channel
            active
            userByUserIdAndOrgId {
              id
            }
          }
        }
      }
    }
  }
`;
export type SubscribeNotificationMutationFn = Apollo.MutationFunction<
  SubscribeNotificationMutation,
  SubscribeNotificationMutationVariables
>;
export type SubscribeNotificationProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SubscribeNotificationMutation,
    SubscribeNotificationMutationVariables
  >;
} &
  TChildProps;
export function withSubscribeNotification<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubscribeNotificationMutation,
    SubscribeNotificationMutationVariables,
    SubscribeNotificationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SubscribeNotificationMutation,
    SubscribeNotificationMutationVariables,
    SubscribeNotificationProps<TChildProps, TDataName>
  >(SubscribeNotificationDocument, {
    alias: "subscribeNotification",
    ...operationOptions,
  });
}

/**
 * __useSubscribeNotificationMutation__
 *
 * To run a mutation, you first call `useSubscribeNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeNotificationMutation, { data, loading, error }] = useSubscribeNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeNotificationMutation,
    SubscribeNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeNotificationMutation,
    SubscribeNotificationMutationVariables
  >(SubscribeNotificationDocument, options);
}
export type SubscribeNotificationMutationHookResult = ReturnType<
  typeof useSubscribeNotificationMutation
>;
export type SubscribeNotificationMutationResult = Apollo.MutationResult<SubscribeNotificationMutation>;
export type SubscribeNotificationMutationOptions = Apollo.BaseMutationOptions<
  SubscribeNotificationMutation,
  SubscribeNotificationMutationVariables
>;
export const CreateDuplicateJobDocument = gql`
  mutation createDuplicateJob($input: CreateDuplicateJobInput!) {
    createDuplicateJob(input: $input) {
      id
      success
    }
  }
`;
export type CreateDuplicateJobMutationFn = Apollo.MutationFunction<
  CreateDuplicateJobMutation,
  CreateDuplicateJobMutationVariables
>;
export type CreateDuplicateJobProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateDuplicateJobMutation,
    CreateDuplicateJobMutationVariables
  >;
} &
  TChildProps;
export function withCreateDuplicateJob<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDuplicateJobMutation,
    CreateDuplicateJobMutationVariables,
    CreateDuplicateJobProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDuplicateJobMutation,
    CreateDuplicateJobMutationVariables,
    CreateDuplicateJobProps<TChildProps, TDataName>
  >(CreateDuplicateJobDocument, {
    alias: "createDuplicateJob",
    ...operationOptions,
  });
}

/**
 * __useCreateDuplicateJobMutation__
 *
 * To run a mutation, you first call `useCreateDuplicateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDuplicateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDuplicateJobMutation, { data, loading, error }] = useCreateDuplicateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDuplicateJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDuplicateJobMutation,
    CreateDuplicateJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDuplicateJobMutation,
    CreateDuplicateJobMutationVariables
  >(CreateDuplicateJobDocument, options);
}
export type CreateDuplicateJobMutationHookResult = ReturnType<
  typeof useCreateDuplicateJobMutation
>;
export type CreateDuplicateJobMutationResult = Apollo.MutationResult<CreateDuplicateJobMutation>;
export type CreateDuplicateJobMutationOptions = Apollo.BaseMutationOptions<
  CreateDuplicateJobMutation,
  CreateDuplicateJobMutationVariables
>;
export const CreateDuplicateJobsDocument = gql`
  mutation createDuplicateJobs($input: CreateDuplicateJobsInput!) {
    createDuplicateJobs(input: $input) {
      id
      success
    }
  }
`;
export type CreateDuplicateJobsMutationFn = Apollo.MutationFunction<
  CreateDuplicateJobsMutation,
  CreateDuplicateJobsMutationVariables
>;
export type CreateDuplicateJobsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateDuplicateJobsMutation,
    CreateDuplicateJobsMutationVariables
  >;
} &
  TChildProps;
export function withCreateDuplicateJobs<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDuplicateJobsMutation,
    CreateDuplicateJobsMutationVariables,
    CreateDuplicateJobsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDuplicateJobsMutation,
    CreateDuplicateJobsMutationVariables,
    CreateDuplicateJobsProps<TChildProps, TDataName>
  >(CreateDuplicateJobsDocument, {
    alias: "createDuplicateJobs",
    ...operationOptions,
  });
}

/**
 * __useCreateDuplicateJobsMutation__
 *
 * To run a mutation, you first call `useCreateDuplicateJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDuplicateJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDuplicateJobsMutation, { data, loading, error }] = useCreateDuplicateJobsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDuplicateJobsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDuplicateJobsMutation,
    CreateDuplicateJobsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDuplicateJobsMutation,
    CreateDuplicateJobsMutationVariables
  >(CreateDuplicateJobsDocument, options);
}
export type CreateDuplicateJobsMutationHookResult = ReturnType<
  typeof useCreateDuplicateJobsMutation
>;
export type CreateDuplicateJobsMutationResult = Apollo.MutationResult<CreateDuplicateJobsMutation>;
export type CreateDuplicateJobsMutationOptions = Apollo.BaseMutationOptions<
  CreateDuplicateJobsMutation,
  CreateDuplicateJobsMutationVariables
>;
export const DeleteDuplicateJobDocument = gql`
  mutation deleteDuplicateJob($input: DeleteDuplicateJobInput!) {
    deleteDuplicateJob(input: $input) {
      id
      success
    }
  }
`;
export type DeleteDuplicateJobMutationFn = Apollo.MutationFunction<
  DeleteDuplicateJobMutation,
  DeleteDuplicateJobMutationVariables
>;
export type DeleteDuplicateJobProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteDuplicateJobMutation,
    DeleteDuplicateJobMutationVariables
  >;
} &
  TChildProps;
export function withDeleteDuplicateJob<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteDuplicateJobMutation,
    DeleteDuplicateJobMutationVariables,
    DeleteDuplicateJobProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteDuplicateJobMutation,
    DeleteDuplicateJobMutationVariables,
    DeleteDuplicateJobProps<TChildProps, TDataName>
  >(DeleteDuplicateJobDocument, {
    alias: "deleteDuplicateJob",
    ...operationOptions,
  });
}

/**
 * __useDeleteDuplicateJobMutation__
 *
 * To run a mutation, you first call `useDeleteDuplicateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDuplicateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDuplicateJobMutation, { data, loading, error }] = useDeleteDuplicateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDuplicateJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDuplicateJobMutation,
    DeleteDuplicateJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDuplicateJobMutation,
    DeleteDuplicateJobMutationVariables
  >(DeleteDuplicateJobDocument, options);
}
export type DeleteDuplicateJobMutationHookResult = ReturnType<
  typeof useDeleteDuplicateJobMutation
>;
export type DeleteDuplicateJobMutationResult = Apollo.MutationResult<DeleteDuplicateJobMutation>;
export type DeleteDuplicateJobMutationOptions = Apollo.BaseMutationOptions<
  DeleteDuplicateJobMutation,
  DeleteDuplicateJobMutationVariables
>;
export const GetImportJobsDocument = gql`
  query getImportJobs($snapshotType: SnapshotType!, $sourceSpaceId: String) {
    getImportJobs(
      input: {
        snapshotType: $snapshotType
        status: [not_started, queue, processing, failed]
        sourceSpaceId: $sourceSpaceId
      }
    ) {
      jobId
      snapshotType
      snapshotId
      newName
      targetOrgId
      targetSpaceId
      targetContentId
      isPublicSnapshot
      userId
      status
      createdAt
      updatedAt
    }
  }
`;
export type GetImportJobsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetImportJobsQuery,
    GetImportJobsQueryVariables
  >;
} &
  TChildProps;
export function withGetImportJobs<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetImportJobsQuery,
    GetImportJobsQueryVariables,
    GetImportJobsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetImportJobsQuery,
    GetImportJobsQueryVariables,
    GetImportJobsProps<TChildProps, TDataName>
  >(GetImportJobsDocument, {
    alias: "getImportJobs",
    ...operationOptions,
  });
}

/**
 * __useGetImportJobsQuery__
 *
 * To run a query within a React component, call `useGetImportJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImportJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImportJobsQuery({
 *   variables: {
 *      snapshotType: // value for 'snapshotType'
 *      sourceSpaceId: // value for 'sourceSpaceId'
 *   },
 * });
 */
export function useGetImportJobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImportJobsQuery,
    GetImportJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetImportJobsQuery, GetImportJobsQueryVariables>(
    GetImportJobsDocument,
    options
  );
}
export function useGetImportJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImportJobsQuery,
    GetImportJobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetImportJobsQuery, GetImportJobsQueryVariables>(
    GetImportJobsDocument,
    options
  );
}
export type GetImportJobsQueryHookResult = ReturnType<
  typeof useGetImportJobsQuery
>;
export type GetImportJobsLazyQueryHookResult = ReturnType<
  typeof useGetImportJobsLazyQuery
>;
export type GetImportJobsQueryResult = Apollo.QueryResult<
  GetImportJobsQuery,
  GetImportJobsQueryVariables
>;
export function refetchGetImportJobsQuery(
  variables?: GetImportJobsQueryVariables
) {
  return { query: GetImportJobsDocument, variables: variables };
}
export const DeleteFileByIdDocument = gql`
  mutation deleteFileById($input: DeleteFileByIdInput!) {
    deleteFileById(input: $input) {
      file {
        ...File
        castedScreenByFileId {
          nodes {
            __typename
            id
            name
            device
            spaceId
          }
          totalCount
        }
        fileOutputsByFileId {
          nodes {
            ...FileOutput
          }
          totalCount
        }
        userByCreatedBy {
          ...User
        }
      }
    }
  }
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${UserFragmentDoc}
`;
export type DeleteFileByIdMutationFn = Apollo.MutationFunction<
  DeleteFileByIdMutation,
  DeleteFileByIdMutationVariables
>;
export type DeleteFileByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteFileByIdMutation,
    DeleteFileByIdMutationVariables
  >;
} &
  TChildProps;
export function withDeleteFileById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteFileByIdMutation,
    DeleteFileByIdMutationVariables,
    DeleteFileByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteFileByIdMutation,
    DeleteFileByIdMutationVariables,
    DeleteFileByIdProps<TChildProps, TDataName>
  >(DeleteFileByIdDocument, {
    alias: "deleteFileById",
    ...operationOptions,
  });
}

/**
 * __useDeleteFileByIdMutation__
 *
 * To run a mutation, you first call `useDeleteFileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileByIdMutation, { data, loading, error }] = useDeleteFileByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFileByIdMutation,
    DeleteFileByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFileByIdMutation,
    DeleteFileByIdMutationVariables
  >(DeleteFileByIdDocument, options);
}
export type DeleteFileByIdMutationHookResult = ReturnType<
  typeof useDeleteFileByIdMutation
>;
export type DeleteFileByIdMutationResult = Apollo.MutationResult<DeleteFileByIdMutation>;
export type DeleteFileByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileByIdMutation,
  DeleteFileByIdMutationVariables
>;
export const DeleteFileByIdsDocument = gql`
  mutation deleteFileByIds($input: DeleteFileByIdsInput!) {
    deleteFileByIds(input: $input) {
      files {
        ...File
        castedScreenByFileId {
          nodes {
            __typename
            id
            name
            device
            spaceId
          }
          totalCount
        }
        fileOutputsByFileId {
          nodes {
            ...FileOutput
          }
          totalCount
        }
        userByCreatedBy {
          ...User
        }
      }
    }
  }
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${UserFragmentDoc}
`;
export type DeleteFileByIdsMutationFn = Apollo.MutationFunction<
  DeleteFileByIdsMutation,
  DeleteFileByIdsMutationVariables
>;
export type DeleteFileByIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteFileByIdsMutation,
    DeleteFileByIdsMutationVariables
  >;
} &
  TChildProps;
export function withDeleteFileByIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteFileByIdsMutation,
    DeleteFileByIdsMutationVariables,
    DeleteFileByIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteFileByIdsMutation,
    DeleteFileByIdsMutationVariables,
    DeleteFileByIdsProps<TChildProps, TDataName>
  >(DeleteFileByIdsDocument, {
    alias: "deleteFileByIds",
    ...operationOptions,
  });
}

/**
 * __useDeleteFileByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteFileByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileByIdsMutation, { data, loading, error }] = useDeleteFileByIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileByIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFileByIdsMutation,
    DeleteFileByIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFileByIdsMutation,
    DeleteFileByIdsMutationVariables
  >(DeleteFileByIdsDocument, options);
}
export type DeleteFileByIdsMutationHookResult = ReturnType<
  typeof useDeleteFileByIdsMutation
>;
export type DeleteFileByIdsMutationResult = Apollo.MutationResult<DeleteFileByIdsMutation>;
export type DeleteFileByIdsMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileByIdsMutation,
  DeleteFileByIdsMutationVariables
>;
export const DeleteFolderByIdDocument = gql`
  mutation deleteFolderById($input: DeleteFolderByIdInput!) {
    deleteFolderById(input: $input) {
      folder {
        ...Folder
      }
    }
  }
  ${FolderFragmentDoc}
`;
export type DeleteFolderByIdMutationFn = Apollo.MutationFunction<
  DeleteFolderByIdMutation,
  DeleteFolderByIdMutationVariables
>;
export type DeleteFolderByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteFolderByIdMutation,
    DeleteFolderByIdMutationVariables
  >;
} &
  TChildProps;
export function withDeleteFolderById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteFolderByIdMutation,
    DeleteFolderByIdMutationVariables,
    DeleteFolderByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteFolderByIdMutation,
    DeleteFolderByIdMutationVariables,
    DeleteFolderByIdProps<TChildProps, TDataName>
  >(DeleteFolderByIdDocument, {
    alias: "deleteFolderById",
    ...operationOptions,
  });
}

/**
 * __useDeleteFolderByIdMutation__
 *
 * To run a mutation, you first call `useDeleteFolderByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderByIdMutation, { data, loading, error }] = useDeleteFolderByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFolderByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFolderByIdMutation,
    DeleteFolderByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFolderByIdMutation,
    DeleteFolderByIdMutationVariables
  >(DeleteFolderByIdDocument, options);
}
export type DeleteFolderByIdMutationHookResult = ReturnType<
  typeof useDeleteFolderByIdMutation
>;
export type DeleteFolderByIdMutationResult = Apollo.MutationResult<DeleteFolderByIdMutation>;
export type DeleteFolderByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteFolderByIdMutation,
  DeleteFolderByIdMutationVariables
>;
export const GetAllActiveContentDocument = gql`
  query getAllActiveContent($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      filesBySpaceId {
        nodes {
          __typename
          id
        }
      }
      appInstancesBySpaceId {
        nodes {
          __typename
          id
        }
      }
      linksBySpaceId {
        nodes {
          __typename
          id
        }
      }
      sitesBySpaceId {
        nodes {
          __typename
          id
        }
      }
    }
  }
`;
export type GetAllActiveContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAllActiveContentQuery,
    GetAllActiveContentQueryVariables
  >;
} &
  TChildProps;
export function withGetAllActiveContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllActiveContentQuery,
    GetAllActiveContentQueryVariables,
    GetAllActiveContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllActiveContentQuery,
    GetAllActiveContentQueryVariables,
    GetAllActiveContentProps<TChildProps, TDataName>
  >(GetAllActiveContentDocument, {
    alias: "getAllActiveContent",
    ...operationOptions,
  });
}

/**
 * __useGetAllActiveContentQuery__
 *
 * To run a query within a React component, call `useGetAllActiveContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActiveContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActiveContentQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetAllActiveContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllActiveContentQuery,
    GetAllActiveContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllActiveContentQuery,
    GetAllActiveContentQueryVariables
  >(GetAllActiveContentDocument, options);
}
export function useGetAllActiveContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllActiveContentQuery,
    GetAllActiveContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllActiveContentQuery,
    GetAllActiveContentQueryVariables
  >(GetAllActiveContentDocument, options);
}
export type GetAllActiveContentQueryHookResult = ReturnType<
  typeof useGetAllActiveContentQuery
>;
export type GetAllActiveContentLazyQueryHookResult = ReturnType<
  typeof useGetAllActiveContentLazyQuery
>;
export type GetAllActiveContentQueryResult = Apollo.QueryResult<
  GetAllActiveContentQuery,
  GetAllActiveContentQueryVariables
>;
export function refetchGetAllActiveContentQuery(
  variables?: GetAllActiveContentQueryVariables
) {
  return { query: GetAllActiveContentDocument, variables: variables };
}
export const UnSubGainsightForUserDocument = gql`
  mutation unSubGainsightForUser(
    $identifyId: String!
    $globalUnsubscribe: Boolean!
    $guidedTours: Boolean
    $onboardingBot: Boolean
    $productUpdates: Boolean
    $surveys: Boolean
    $trackEmailAnalytics: Boolean
    $trackUsage: Boolean
  ) {
    unSubGainsightForUser(
      identifyId: $identifyId
      globalUnsubscribe: $globalUnsubscribe
      guidedTours: $guidedTours
      onboardingBot: $onboardingBot
      productUpdates: $productUpdates
      surveys: $surveys
      trackEmailAnalytics: $trackEmailAnalytics
      trackUsage: $trackUsage
    ) {
      globalUnsubscribeUpdateStatus
      userPreferencesUpdateStatus
    }
  }
`;
export type UnSubGainsightForUserMutationFn = Apollo.MutationFunction<
  UnSubGainsightForUserMutation,
  UnSubGainsightForUserMutationVariables
>;
export type UnSubGainsightForUserProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UnSubGainsightForUserMutation,
    UnSubGainsightForUserMutationVariables
  >;
} &
  TChildProps;
export function withUnSubGainsightForUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UnSubGainsightForUserMutation,
    UnSubGainsightForUserMutationVariables,
    UnSubGainsightForUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UnSubGainsightForUserMutation,
    UnSubGainsightForUserMutationVariables,
    UnSubGainsightForUserProps<TChildProps, TDataName>
  >(UnSubGainsightForUserDocument, {
    alias: "unSubGainsightForUser",
    ...operationOptions,
  });
}

/**
 * __useUnSubGainsightForUserMutation__
 *
 * To run a mutation, you first call `useUnSubGainsightForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSubGainsightForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSubGainsightForUserMutation, { data, loading, error }] = useUnSubGainsightForUserMutation({
 *   variables: {
 *      identifyId: // value for 'identifyId'
 *      globalUnsubscribe: // value for 'globalUnsubscribe'
 *      guidedTours: // value for 'guidedTours'
 *      onboardingBot: // value for 'onboardingBot'
 *      productUpdates: // value for 'productUpdates'
 *      surveys: // value for 'surveys'
 *      trackEmailAnalytics: // value for 'trackEmailAnalytics'
 *      trackUsage: // value for 'trackUsage'
 *   },
 * });
 */
export function useUnSubGainsightForUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnSubGainsightForUserMutation,
    UnSubGainsightForUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnSubGainsightForUserMutation,
    UnSubGainsightForUserMutationVariables
  >(UnSubGainsightForUserDocument, options);
}
export type UnSubGainsightForUserMutationHookResult = ReturnType<
  typeof useUnSubGainsightForUserMutation
>;
export type UnSubGainsightForUserMutationResult = Apollo.MutationResult<UnSubGainsightForUserMutation>;
export type UnSubGainsightForUserMutationOptions = Apollo.BaseMutationOptions<
  UnSubGainsightForUserMutation,
  UnSubGainsightForUserMutationVariables
>;
export const GetScreenTagsDocument = gql`
  query getScreenTags($spaceId: UUID!) {
    screenTagsBySpaceId(spaceId: $spaceId)
  }
`;
export type GetScreenTagsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetScreenTagsQuery,
    GetScreenTagsQueryVariables
  >;
} &
  TChildProps;
export function withGetScreenTags<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetScreenTagsQuery,
    GetScreenTagsQueryVariables,
    GetScreenTagsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetScreenTagsQuery,
    GetScreenTagsQueryVariables,
    GetScreenTagsProps<TChildProps, TDataName>
  >(GetScreenTagsDocument, {
    alias: "getScreenTags",
    ...operationOptions,
  });
}

/**
 * __useGetScreenTagsQuery__
 *
 * To run a query within a React component, call `useGetScreenTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScreenTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScreenTagsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetScreenTagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScreenTagsQuery,
    GetScreenTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScreenTagsQuery, GetScreenTagsQueryVariables>(
    GetScreenTagsDocument,
    options
  );
}
export function useGetScreenTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScreenTagsQuery,
    GetScreenTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScreenTagsQuery, GetScreenTagsQueryVariables>(
    GetScreenTagsDocument,
    options
  );
}
export type GetScreenTagsQueryHookResult = ReturnType<
  typeof useGetScreenTagsQuery
>;
export type GetScreenTagsLazyQueryHookResult = ReturnType<
  typeof useGetScreenTagsLazyQuery
>;
export type GetScreenTagsQueryResult = Apollo.QueryResult<
  GetScreenTagsQuery,
  GetScreenTagsQueryVariables
>;
export function refetchGetScreenTagsQuery(
  variables?: GetScreenTagsQueryVariables
) {
  return { query: GetScreenTagsDocument, variables: variables };
}
export const GetMetricsDownloadUrlDocument = gql`
  query getMetricsDownloadUrl($fileName: String) {
    downloadQRMetrics(input: { file_name: $fileName }) {
      url
    }
  }
`;
export type GetMetricsDownloadUrlProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMetricsDownloadUrlQuery,
    GetMetricsDownloadUrlQueryVariables
  >;
} &
  TChildProps;
export function withGetMetricsDownloadUrl<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMetricsDownloadUrlQuery,
    GetMetricsDownloadUrlQueryVariables,
    GetMetricsDownloadUrlProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMetricsDownloadUrlQuery,
    GetMetricsDownloadUrlQueryVariables,
    GetMetricsDownloadUrlProps<TChildProps, TDataName>
  >(GetMetricsDownloadUrlDocument, {
    alias: "getMetricsDownloadUrl",
    ...operationOptions,
  });
}

/**
 * __useGetMetricsDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGetMetricsDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricsDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricsDownloadUrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetMetricsDownloadUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMetricsDownloadUrlQuery,
    GetMetricsDownloadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMetricsDownloadUrlQuery,
    GetMetricsDownloadUrlQueryVariables
  >(GetMetricsDownloadUrlDocument, options);
}
export function useGetMetricsDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetricsDownloadUrlQuery,
    GetMetricsDownloadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMetricsDownloadUrlQuery,
    GetMetricsDownloadUrlQueryVariables
  >(GetMetricsDownloadUrlDocument, options);
}
export type GetMetricsDownloadUrlQueryHookResult = ReturnType<
  typeof useGetMetricsDownloadUrlQuery
>;
export type GetMetricsDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useGetMetricsDownloadUrlLazyQuery
>;
export type GetMetricsDownloadUrlQueryResult = Apollo.QueryResult<
  GetMetricsDownloadUrlQuery,
  GetMetricsDownloadUrlQueryVariables
>;
export function refetchGetMetricsDownloadUrlQuery(
  variables?: GetMetricsDownloadUrlQueryVariables
) {
  return { query: GetMetricsDownloadUrlDocument, variables: variables };
}
export const GetQrCodeMetricsDocument = gql`
  query getQrCodeMetrics(
    $startDate: String!
    $endDate: String!
    $metric: Metric!
    $pagination: Int
    $token: String
    $executionId: String
    $targetUrl: String
    $secondaryTenantId: String
    $screenId: String
  ) {
    getQRMetrics(
      input: {
        metric: $metric
        start: $startDate
        end: $endDate
        pagination: $pagination
        next_token: $token
        query_execution_id: $executionId
        target_url: $targetUrl
        screen_id: $screenId
        secondary_tenant_id: $secondaryTenantId
      }
    ) {
      items
      nextToken
      queryExecutionId
      fileName
    }
  }
`;
export type GetQrCodeMetricsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetQrCodeMetricsQuery,
    GetQrCodeMetricsQueryVariables
  >;
} &
  TChildProps;
export function withGetQrCodeMetrics<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetQrCodeMetricsQuery,
    GetQrCodeMetricsQueryVariables,
    GetQrCodeMetricsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetQrCodeMetricsQuery,
    GetQrCodeMetricsQueryVariables,
    GetQrCodeMetricsProps<TChildProps, TDataName>
  >(GetQrCodeMetricsDocument, {
    alias: "getQrCodeMetrics",
    ...operationOptions,
  });
}

/**
 * __useGetQrCodeMetricsQuery__
 *
 * To run a query within a React component, call `useGetQrCodeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrCodeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrCodeMetricsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      metric: // value for 'metric'
 *      pagination: // value for 'pagination'
 *      token: // value for 'token'
 *      executionId: // value for 'executionId'
 *      targetUrl: // value for 'targetUrl'
 *      secondaryTenantId: // value for 'secondaryTenantId'
 *      screenId: // value for 'screenId'
 *   },
 * });
 */
export function useGetQrCodeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQrCodeMetricsQuery,
    GetQrCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQrCodeMetricsQuery, GetQrCodeMetricsQueryVariables>(
    GetQrCodeMetricsDocument,
    options
  );
}
export function useGetQrCodeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQrCodeMetricsQuery,
    GetQrCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetQrCodeMetricsQuery,
    GetQrCodeMetricsQueryVariables
  >(GetQrCodeMetricsDocument, options);
}
export type GetQrCodeMetricsQueryHookResult = ReturnType<
  typeof useGetQrCodeMetricsQuery
>;
export type GetQrCodeMetricsLazyQueryHookResult = ReturnType<
  typeof useGetQrCodeMetricsLazyQuery
>;
export type GetQrCodeMetricsQueryResult = Apollo.QueryResult<
  GetQrCodeMetricsQuery,
  GetQrCodeMetricsQueryVariables
>;
export function refetchGetQrCodeMetricsQuery(
  variables?: GetQrCodeMetricsQueryVariables
) {
  return { query: GetQrCodeMetricsDocument, variables: variables };
}
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export type UpdateUserProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >;
} &
  TChildProps;
export function withUpdateUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps, TDataName>
  >(UpdateUserDocument, {
    alias: "updateUser",
    ...operationOptions,
  });
}

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserInfoDocument = gql`
  mutation updateUserInfo($input: UpdateUserInfoInput!) {
    updateUserInfo(input: $input) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables
>;
export type UpdateUserInfoProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >;
} &
  TChildProps;
export function withUpdateUserInfo<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables,
    UpdateUserInfoProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables,
    UpdateUserInfoProps<TChildProps, TDataName>
  >(UpdateUserInfoDocument, {
    alias: "updateUserInfo",
    ...operationOptions,
  });
}

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >(UpdateUserInfoDocument, options);
}
export type UpdateUserInfoMutationHookResult = ReturnType<
  typeof useUpdateUserInfoMutation
>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables
>;
export const AllTokensDocument = gql`
  query allTokens {
    allTokens(
      orderBy: NAME_ASC
      filter: { deletedAt: { isNull: true }, isBot: { equalTo: false } }
    ) {
      nodes {
        ...Token
        orgByOrgId {
          __typename
          id
          name
          brandForegroundColor
          brandBackgroundColor
          startChannelId
          blankChannelId
          brandChannelId
          splashChannelThemeId
          defaultChannelThemeId
          brandLogoId
          brandBackgroundId
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        userByUserId {
          __typename
          id
          givenName
          familyName
          email
        }
        userByCreatedBy {
          __typename
          id
          givenName
          familyName
          email
        }
        userByUpdatedBy {
          __typename
          id
          givenName
          familyName
          email
        }
      }
    }
  }
  ${TokenFragmentDoc}
`;
export type AllTokensProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllTokensQuery,
    AllTokensQueryVariables
  >;
} &
  TChildProps;
export function withAllTokens<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTokensQuery,
    AllTokensQueryVariables,
    AllTokensProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTokensQuery,
    AllTokensQueryVariables,
    AllTokensProps<TChildProps, TDataName>
  >(AllTokensDocument, {
    alias: "allTokens",
    ...operationOptions,
  });
}

/**
 * __useAllTokensQuery__
 *
 * To run a query within a React component, call `useAllTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTokensQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTokensQuery, AllTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTokensQuery, AllTokensQueryVariables>(
    AllTokensDocument,
    options
  );
}
export function useAllTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTokensQuery,
    AllTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTokensQuery, AllTokensQueryVariables>(
    AllTokensDocument,
    options
  );
}
export type AllTokensQueryHookResult = ReturnType<typeof useAllTokensQuery>;
export type AllTokensLazyQueryHookResult = ReturnType<
  typeof useAllTokensLazyQuery
>;
export type AllTokensQueryResult = Apollo.QueryResult<
  AllTokensQuery,
  AllTokensQueryVariables
>;
export function refetchAllTokensQuery(variables?: AllTokensQueryVariables) {
  return { query: AllTokensDocument, variables: variables };
}
export const CreateTokenDocument = gql`
  mutation createToken($input: CreateTokenInput!) {
    createToken(input: $input) {
      tokenSecretPayload {
        secret
        token {
          ...Token
        }
      }
    }
  }
  ${TokenFragmentDoc}
`;
export type CreateTokenMutationFn = Apollo.MutationFunction<
  CreateTokenMutation,
  CreateTokenMutationVariables
>;
export type CreateTokenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateTokenMutation,
    CreateTokenMutationVariables
  >;
} &
  TChildProps;
export function withCreateToken<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTokenMutation,
    CreateTokenMutationVariables,
    CreateTokenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTokenMutation,
    CreateTokenMutationVariables,
    CreateTokenProps<TChildProps, TDataName>
  >(CreateTokenDocument, {
    alias: "createToken",
    ...operationOptions,
  });
}

/**
 * __useCreateTokenMutation__
 *
 * To run a mutation, you first call `useCreateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenMutation, { data, loading, error }] = useCreateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTokenMutation,
    CreateTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTokenMutation, CreateTokenMutationVariables>(
    CreateTokenDocument,
    options
  );
}
export type CreateTokenMutationHookResult = ReturnType<
  typeof useCreateTokenMutation
>;
export type CreateTokenMutationResult = Apollo.MutationResult<CreateTokenMutation>;
export type CreateTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateTokenMutation,
  CreateTokenMutationVariables
>;
export const DeleteTokenDocument = gql`
  mutation deleteToken($input: DeleteTokenInput!) {
    deleteToken(input: $input) {
      token {
        ...Token
      }
    }
  }
  ${TokenFragmentDoc}
`;
export type DeleteTokenMutationFn = Apollo.MutationFunction<
  DeleteTokenMutation,
  DeleteTokenMutationVariables
>;
export type DeleteTokenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteTokenMutation,
    DeleteTokenMutationVariables
  >;
} &
  TChildProps;
export function withDeleteToken<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTokenMutation,
    DeleteTokenMutationVariables,
    DeleteTokenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTokenMutation,
    DeleteTokenMutationVariables,
    DeleteTokenProps<TChildProps, TDataName>
  >(DeleteTokenDocument, {
    alias: "deleteToken",
    ...operationOptions,
  });
}

/**
 * __useDeleteTokenMutation__
 *
 * To run a mutation, you first call `useDeleteTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTokenMutation, { data, loading, error }] = useDeleteTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTokenMutation,
    DeleteTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTokenMutation, DeleteTokenMutationVariables>(
    DeleteTokenDocument,
    options
  );
}
export type DeleteTokenMutationHookResult = ReturnType<
  typeof useDeleteTokenMutation
>;
export type DeleteTokenMutationResult = Apollo.MutationResult<DeleteTokenMutation>;
export type DeleteTokenMutationOptions = Apollo.BaseMutationOptions<
  DeleteTokenMutation,
  DeleteTokenMutationVariables
>;
export const UpdateTokenDocument = gql`
  mutation updateToken($input: UpdateTokenInput!) {
    updateToken(input: $input) {
      token {
        ...Token
      }
      orgByOrgId {
        __typename
        id
      }
      userByUserId {
        __typename
        id
      }
      userByCreatedBy {
        __typename
        id
      }
      userByUpdatedBy {
        __typename
        id
      }
    }
  }
  ${TokenFragmentDoc}
`;
export type UpdateTokenMutationFn = Apollo.MutationFunction<
  UpdateTokenMutation,
  UpdateTokenMutationVariables
>;
export type UpdateTokenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateTokenMutation,
    UpdateTokenMutationVariables
  >;
} &
  TChildProps;
export function withUpdateToken<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTokenMutation,
    UpdateTokenMutationVariables,
    UpdateTokenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTokenMutation,
    UpdateTokenMutationVariables,
    UpdateTokenProps<TChildProps, TDataName>
  >(UpdateTokenDocument, {
    alias: "updateToken",
    ...operationOptions,
  });
}

/**
 * __useUpdateTokenMutation__
 *
 * To run a mutation, you first call `useUpdateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokenMutation, { data, loading, error }] = useUpdateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTokenMutation,
    UpdateTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTokenMutation, UpdateTokenMutationVariables>(
    UpdateTokenDocument,
    options
  );
}
export type UpdateTokenMutationHookResult = ReturnType<
  typeof useUpdateTokenMutation
>;
export type UpdateTokenMutationResult = Apollo.MutationResult<UpdateTokenMutation>;
export type UpdateTokenMutationOptions = Apollo.BaseMutationOptions<
  UpdateTokenMutation,
  UpdateTokenMutationVariables
>;
export const AllGroupsWithPaginationDocument = gql`
  query allGroupsWithPagination($first: Int, $offset: Int, $name: String) {
    allGroups(
      first: $first
      offset: $offset
      filter: { name: { includesInsensitive: $name } }
      orderBy: [NAME_ASC]
    ) {
      totalCount
      nodes {
        __typename
        id
        name
        description
        permissionSets
        isDefault
        groupSpacesByGroupId {
          nodes {
            spaceId
            permissionSets
            spaceBySpaceId {
              __typename
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AllGroupsWithPaginationProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllGroupsWithPaginationQuery,
    AllGroupsWithPaginationQueryVariables
  >;
} &
  TChildProps;
export function withAllGroupsWithPagination<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllGroupsWithPaginationQuery,
    AllGroupsWithPaginationQueryVariables,
    AllGroupsWithPaginationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllGroupsWithPaginationQuery,
    AllGroupsWithPaginationQueryVariables,
    AllGroupsWithPaginationProps<TChildProps, TDataName>
  >(AllGroupsWithPaginationDocument, {
    alias: "allGroupsWithPagination",
    ...operationOptions,
  });
}

/**
 * __useAllGroupsWithPaginationQuery__
 *
 * To run a query within a React component, call `useAllGroupsWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGroupsWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGroupsWithPaginationQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAllGroupsWithPaginationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllGroupsWithPaginationQuery,
    AllGroupsWithPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllGroupsWithPaginationQuery,
    AllGroupsWithPaginationQueryVariables
  >(AllGroupsWithPaginationDocument, options);
}
export function useAllGroupsWithPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllGroupsWithPaginationQuery,
    AllGroupsWithPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllGroupsWithPaginationQuery,
    AllGroupsWithPaginationQueryVariables
  >(AllGroupsWithPaginationDocument, options);
}
export type AllGroupsWithPaginationQueryHookResult = ReturnType<
  typeof useAllGroupsWithPaginationQuery
>;
export type AllGroupsWithPaginationLazyQueryHookResult = ReturnType<
  typeof useAllGroupsWithPaginationLazyQuery
>;
export type AllGroupsWithPaginationQueryResult = Apollo.QueryResult<
  AllGroupsWithPaginationQuery,
  AllGroupsWithPaginationQueryVariables
>;
export function refetchAllGroupsWithPaginationQuery(
  variables?: AllGroupsWithPaginationQueryVariables
) {
  return { query: AllGroupsWithPaginationDocument, variables: variables };
}
export const AllLogsV2ByObjectTypeCountDocument = gql`
  query allLogsV2ByObjectTypeCount($objectType: String) {
    allLogsV2ByObjectTypeCount(objectType: $objectType)
  }
`;
export type AllLogsV2ByObjectTypeCountProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllLogsV2ByObjectTypeCountQuery,
    AllLogsV2ByObjectTypeCountQueryVariables
  >;
} &
  TChildProps;
export function withAllLogsV2ByObjectTypeCount<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLogsV2ByObjectTypeCountQuery,
    AllLogsV2ByObjectTypeCountQueryVariables,
    AllLogsV2ByObjectTypeCountProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLogsV2ByObjectTypeCountQuery,
    AllLogsV2ByObjectTypeCountQueryVariables,
    AllLogsV2ByObjectTypeCountProps<TChildProps, TDataName>
  >(AllLogsV2ByObjectTypeCountDocument, {
    alias: "allLogsV2ByObjectTypeCount",
    ...operationOptions,
  });
}

/**
 * __useAllLogsV2ByObjectTypeCountQuery__
 *
 * To run a query within a React component, call `useAllLogsV2ByObjectTypeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLogsV2ByObjectTypeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLogsV2ByObjectTypeCountQuery({
 *   variables: {
 *      objectType: // value for 'objectType'
 *   },
 * });
 */
export function useAllLogsV2ByObjectTypeCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllLogsV2ByObjectTypeCountQuery,
    AllLogsV2ByObjectTypeCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllLogsV2ByObjectTypeCountQuery,
    AllLogsV2ByObjectTypeCountQueryVariables
  >(AllLogsV2ByObjectTypeCountDocument, options);
}
export function useAllLogsV2ByObjectTypeCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllLogsV2ByObjectTypeCountQuery,
    AllLogsV2ByObjectTypeCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllLogsV2ByObjectTypeCountQuery,
    AllLogsV2ByObjectTypeCountQueryVariables
  >(AllLogsV2ByObjectTypeCountDocument, options);
}
export type AllLogsV2ByObjectTypeCountQueryHookResult = ReturnType<
  typeof useAllLogsV2ByObjectTypeCountQuery
>;
export type AllLogsV2ByObjectTypeCountLazyQueryHookResult = ReturnType<
  typeof useAllLogsV2ByObjectTypeCountLazyQuery
>;
export type AllLogsV2ByObjectTypeCountQueryResult = Apollo.QueryResult<
  AllLogsV2ByObjectTypeCountQuery,
  AllLogsV2ByObjectTypeCountQueryVariables
>;
export function refetchAllLogsV2ByObjectTypeCountQuery(
  variables?: AllLogsV2ByObjectTypeCountQueryVariables
) {
  return { query: AllLogsV2ByObjectTypeCountDocument, variables: variables };
}
export const AllLogsV2ByObjectTypeDocument = gql`
  query allLogsV2ByObjectType(
    $total: Int
    $objectType: String
    $offset: Int
    $expectedItemPerPage: Int
  ) {
    allLogsV2ByObjectType(
      total: $total
      objectType: $objectType
      first: $expectedItemPerPage
      offset: $offset
    ) {
      nodes {
        ...Log
      }
    }
  }
  ${LogFragmentDoc}
`;
export type AllLogsV2ByObjectTypeProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllLogsV2ByObjectTypeQuery,
    AllLogsV2ByObjectTypeQueryVariables
  >;
} &
  TChildProps;
export function withAllLogsV2ByObjectType<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLogsV2ByObjectTypeQuery,
    AllLogsV2ByObjectTypeQueryVariables,
    AllLogsV2ByObjectTypeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLogsV2ByObjectTypeQuery,
    AllLogsV2ByObjectTypeQueryVariables,
    AllLogsV2ByObjectTypeProps<TChildProps, TDataName>
  >(AllLogsV2ByObjectTypeDocument, {
    alias: "allLogsV2ByObjectType",
    ...operationOptions,
  });
}

/**
 * __useAllLogsV2ByObjectTypeQuery__
 *
 * To run a query within a React component, call `useAllLogsV2ByObjectTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLogsV2ByObjectTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLogsV2ByObjectTypeQuery({
 *   variables: {
 *      total: // value for 'total'
 *      objectType: // value for 'objectType'
 *      offset: // value for 'offset'
 *      expectedItemPerPage: // value for 'expectedItemPerPage'
 *   },
 * });
 */
export function useAllLogsV2ByObjectTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllLogsV2ByObjectTypeQuery,
    AllLogsV2ByObjectTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllLogsV2ByObjectTypeQuery,
    AllLogsV2ByObjectTypeQueryVariables
  >(AllLogsV2ByObjectTypeDocument, options);
}
export function useAllLogsV2ByObjectTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllLogsV2ByObjectTypeQuery,
    AllLogsV2ByObjectTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllLogsV2ByObjectTypeQuery,
    AllLogsV2ByObjectTypeQueryVariables
  >(AllLogsV2ByObjectTypeDocument, options);
}
export type AllLogsV2ByObjectTypeQueryHookResult = ReturnType<
  typeof useAllLogsV2ByObjectTypeQuery
>;
export type AllLogsV2ByObjectTypeLazyQueryHookResult = ReturnType<
  typeof useAllLogsV2ByObjectTypeLazyQuery
>;
export type AllLogsV2ByObjectTypeQueryResult = Apollo.QueryResult<
  AllLogsV2ByObjectTypeQuery,
  AllLogsV2ByObjectTypeQueryVariables
>;
export function refetchAllLogsV2ByObjectTypeQuery(
  variables?: AllLogsV2ByObjectTypeQueryVariables
) {
  return { query: AllLogsV2ByObjectTypeDocument, variables: variables };
}
export const AllLogsV2Document = gql`
  query allLogsV2($offset: Int, $expectedItemPerPage: Int) {
    allLogsV2(total: $expectedItemPerPage, skipFirst: $offset) {
      nodes {
        ...Log
      }
    }
  }
  ${LogFragmentDoc}
`;
export type AllLogsV2Props<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllLogsV2Query,
    AllLogsV2QueryVariables
  >;
} &
  TChildProps;
export function withAllLogsV2<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLogsV2Query,
    AllLogsV2QueryVariables,
    AllLogsV2Props<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLogsV2Query,
    AllLogsV2QueryVariables,
    AllLogsV2Props<TChildProps, TDataName>
  >(AllLogsV2Document, {
    alias: "allLogsV2",
    ...operationOptions,
  });
}

/**
 * __useAllLogsV2Query__
 *
 * To run a query within a React component, call `useAllLogsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAllLogsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLogsV2Query({
 *   variables: {
 *      offset: // value for 'offset'
 *      expectedItemPerPage: // value for 'expectedItemPerPage'
 *   },
 * });
 */
export function useAllLogsV2Query(
  baseOptions?: Apollo.QueryHookOptions<AllLogsV2Query, AllLogsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllLogsV2Query, AllLogsV2QueryVariables>(
    AllLogsV2Document,
    options
  );
}
export function useAllLogsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllLogsV2Query,
    AllLogsV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllLogsV2Query, AllLogsV2QueryVariables>(
    AllLogsV2Document,
    options
  );
}
export type AllLogsV2QueryHookResult = ReturnType<typeof useAllLogsV2Query>;
export type AllLogsV2LazyQueryHookResult = ReturnType<
  typeof useAllLogsV2LazyQuery
>;
export type AllLogsV2QueryResult = Apollo.QueryResult<
  AllLogsV2Query,
  AllLogsV2QueryVariables
>;
export function refetchAllLogsV2Query(variables?: AllLogsV2QueryVariables) {
  return { query: AllLogsV2Document, variables: variables };
}
export const AllLogsV2CountDocument = gql`
  query allLogsV2Count {
    allLogsV2Count
  }
`;
export type AllLogsV2CountProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllLogsV2CountQuery,
    AllLogsV2CountQueryVariables
  >;
} &
  TChildProps;
export function withAllLogsV2Count<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLogsV2CountQuery,
    AllLogsV2CountQueryVariables,
    AllLogsV2CountProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLogsV2CountQuery,
    AllLogsV2CountQueryVariables,
    AllLogsV2CountProps<TChildProps, TDataName>
  >(AllLogsV2CountDocument, {
    alias: "allLogsV2Count",
    ...operationOptions,
  });
}

/**
 * __useAllLogsV2CountQuery__
 *
 * To run a query within a React component, call `useAllLogsV2CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLogsV2CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLogsV2CountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllLogsV2CountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllLogsV2CountQuery,
    AllLogsV2CountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllLogsV2CountQuery, AllLogsV2CountQueryVariables>(
    AllLogsV2CountDocument,
    options
  );
}
export function useAllLogsV2CountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllLogsV2CountQuery,
    AllLogsV2CountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllLogsV2CountQuery, AllLogsV2CountQueryVariables>(
    AllLogsV2CountDocument,
    options
  );
}
export type AllLogsV2CountQueryHookResult = ReturnType<
  typeof useAllLogsV2CountQuery
>;
export type AllLogsV2CountLazyQueryHookResult = ReturnType<
  typeof useAllLogsV2CountLazyQuery
>;
export type AllLogsV2CountQueryResult = Apollo.QueryResult<
  AllLogsV2CountQuery,
  AllLogsV2CountQueryVariables
>;
export function refetchAllLogsV2CountQuery(
  variables?: AllLogsV2CountQueryVariables
) {
  return { query: AllLogsV2CountDocument, variables: variables };
}
export const CreateStudioOrgConnectionDocument = gql`
  mutation createStudioOrgConnection($input: CreateOrgConnectionInput!) {
    createOrgConnection(input: $input) {
      orgConnection {
        __typename
        id
        orgId
        automaticEnroll
        connection
        provider
      }
    }
  }
`;
export type CreateStudioOrgConnectionMutationFn = Apollo.MutationFunction<
  CreateStudioOrgConnectionMutation,
  CreateStudioOrgConnectionMutationVariables
>;
export type CreateStudioOrgConnectionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateStudioOrgConnectionMutation,
    CreateStudioOrgConnectionMutationVariables
  >;
} &
  TChildProps;
export function withCreateStudioOrgConnection<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateStudioOrgConnectionMutation,
    CreateStudioOrgConnectionMutationVariables,
    CreateStudioOrgConnectionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateStudioOrgConnectionMutation,
    CreateStudioOrgConnectionMutationVariables,
    CreateStudioOrgConnectionProps<TChildProps, TDataName>
  >(CreateStudioOrgConnectionDocument, {
    alias: "createStudioOrgConnection",
    ...operationOptions,
  });
}

/**
 * __useCreateStudioOrgConnectionMutation__
 *
 * To run a mutation, you first call `useCreateStudioOrgConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudioOrgConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudioOrgConnectionMutation, { data, loading, error }] = useCreateStudioOrgConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudioOrgConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStudioOrgConnectionMutation,
    CreateStudioOrgConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStudioOrgConnectionMutation,
    CreateStudioOrgConnectionMutationVariables
  >(CreateStudioOrgConnectionDocument, options);
}
export type CreateStudioOrgConnectionMutationHookResult = ReturnType<
  typeof useCreateStudioOrgConnectionMutation
>;
export type CreateStudioOrgConnectionMutationResult = Apollo.MutationResult<CreateStudioOrgConnectionMutation>;
export type CreateStudioOrgConnectionMutationOptions = Apollo.BaseMutationOptions<
  CreateStudioOrgConnectionMutation,
  CreateStudioOrgConnectionMutationVariables
>;
export const SsoCreateConnectionDocument = gql`
  mutation ssoCreateConnection($input: SsoCreateConnectionInput!) {
    ssoCreateConnection(input: $input) {
      connectionName
      cert
      certProvider
      signInURL
      signRequest
      signRequestAlgorithm
      signRequestAlgorithmDigest
      protocolBinding
      debugMode
      idpDomain
      signOutURL
      userIDMapping
      nameMapping
      emailMapping
      replyUrl
      audienceUrl
      signOnUrl
      orgConnectionId
      enableUserEnroll
      forceSSOOnly
      orgSlug
    }
  }
`;
export type SsoCreateConnectionMutationFn = Apollo.MutationFunction<
  SsoCreateConnectionMutation,
  SsoCreateConnectionMutationVariables
>;
export type SsoCreateConnectionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SsoCreateConnectionMutation,
    SsoCreateConnectionMutationVariables
  >;
} &
  TChildProps;
export function withSsoCreateConnection<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SsoCreateConnectionMutation,
    SsoCreateConnectionMutationVariables,
    SsoCreateConnectionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SsoCreateConnectionMutation,
    SsoCreateConnectionMutationVariables,
    SsoCreateConnectionProps<TChildProps, TDataName>
  >(SsoCreateConnectionDocument, {
    alias: "ssoCreateConnection",
    ...operationOptions,
  });
}

/**
 * __useSsoCreateConnectionMutation__
 *
 * To run a mutation, you first call `useSsoCreateConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSsoCreateConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ssoCreateConnectionMutation, { data, loading, error }] = useSsoCreateConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSsoCreateConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SsoCreateConnectionMutation,
    SsoCreateConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SsoCreateConnectionMutation,
    SsoCreateConnectionMutationVariables
  >(SsoCreateConnectionDocument, options);
}
export type SsoCreateConnectionMutationHookResult = ReturnType<
  typeof useSsoCreateConnectionMutation
>;
export type SsoCreateConnectionMutationResult = Apollo.MutationResult<SsoCreateConnectionMutation>;
export type SsoCreateConnectionMutationOptions = Apollo.BaseMutationOptions<
  SsoCreateConnectionMutation,
  SsoCreateConnectionMutationVariables
>;
export const CurrentOrgDetailDocument = gql`
  query currentOrgDetail {
    currentOrg {
      ...Org
      featureFlagOrgsByOrgId {
        nodes {
          ...FeatureFlagOrg
        }
      }
      channelByStartChannelId {
        ...Channel
        orgByOrgId {
          id
          __typename
          brandChannelId
        }
        layoutByChannel {
          ...Layout
        }
        playlistsByChannelId {
          nodes {
            ...Playlist
            draft {
              ...Playlist
              filesByPlaylistId(first: 6) {
                nodes {
                  ...File
                  fileOutputsByFileId {
                    nodes {
                      ...FileOutput
                    }
                  }
                }
              }
              appInstancesByPlaylistId(first: 6) {
                nodes {
                  ...AppInstance
                  appInstallByAppInstallId {
                    ...AppInstall
                  }
                  appVersionByAppInstanceId {
                    ...AppVersion
                    appByAppId {
                      __typename
                      id
                      name
                      iconUrl
                    }
                  }
                }
              }
              linksByPlaylistId(first: 6) {
                nodes {
                  ...Link
                }
              }
              sitesByPlaylistId(first: 6) {
                nodes {
                  ...Site
                }
              }
            }
            filesByPlaylistId(first: 6) {
              nodes {
                ...File
                fileOutputsByFileId {
                  nodes {
                    ...FileOutput
                  }
                }
              }
            }
            appInstancesByPlaylistId(first: 6) {
              nodes {
                ...AppInstance
                appInstallByAppInstallId {
                  ...AppInstall
                }
                appVersionByAppInstanceId {
                  ...AppVersion
                  appByAppId {
                    __typename
                    id
                    name
                    iconUrl
                  }
                }
              }
            }
            linksByPlaylistId(first: 6) {
              nodes {
                ...Link
              }
            }
            sitesByPlaylistId(first: 6) {
              nodes {
                ...Site
              }
            }
          }
        }
        filesByChannelId {
          nodes {
            ...File
            fileOutputsByFileId {
              nodes {
                ...FileOutput
              }
            }
          }
        }
        linksByChannelId {
          nodes {
            ...Link
          }
        }
        sitesByChannelId {
          nodes {
            ...Site
          }
        }
        appInstancesByChannelId {
          nodes {
            ...AppInstance
            appInstallByAppInstallId {
              ...AppInstall
            }
            appVersionByAppInstanceId {
              ...AppVersion
              appByAppId {
                __typename
                id
                name
                iconUrl
              }
            }
          }
        }
        userByUpdatedBy {
          ...User
        }
      }
      fileByBrandLogoId {
        ...File
      }
      fileByBrandBackgroundId {
        ...File
      }
    }
  }
  ${OrgFragmentDoc}
  ${FeatureFlagOrgFragmentDoc}
  ${ChannelFragmentDoc}
  ${LayoutFragmentDoc}
  ${PlaylistFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${AppInstanceFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
  ${UserFragmentDoc}
`;
export type CurrentOrgDetailProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CurrentOrgDetailQuery,
    CurrentOrgDetailQueryVariables
  >;
} &
  TChildProps;
export function withCurrentOrgDetail<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrentOrgDetailQuery,
    CurrentOrgDetailQueryVariables,
    CurrentOrgDetailProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrentOrgDetailQuery,
    CurrentOrgDetailQueryVariables,
    CurrentOrgDetailProps<TChildProps, TDataName>
  >(CurrentOrgDetailDocument, {
    alias: "currentOrgDetail",
    ...operationOptions,
  });
}

/**
 * __useCurrentOrgDetailQuery__
 *
 * To run a query within a React component, call `useCurrentOrgDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOrgDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOrgDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentOrgDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentOrgDetailQuery,
    CurrentOrgDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentOrgDetailQuery, CurrentOrgDetailQueryVariables>(
    CurrentOrgDetailDocument,
    options
  );
}
export function useCurrentOrgDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentOrgDetailQuery,
    CurrentOrgDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentOrgDetailQuery,
    CurrentOrgDetailQueryVariables
  >(CurrentOrgDetailDocument, options);
}
export type CurrentOrgDetailQueryHookResult = ReturnType<
  typeof useCurrentOrgDetailQuery
>;
export type CurrentOrgDetailLazyQueryHookResult = ReturnType<
  typeof useCurrentOrgDetailLazyQuery
>;
export type CurrentOrgDetailQueryResult = Apollo.QueryResult<
  CurrentOrgDetailQuery,
  CurrentOrgDetailQueryVariables
>;
export function refetchCurrentOrgDetailQuery(
  variables?: CurrentOrgDetailQueryVariables
) {
  return { query: CurrentOrgDetailDocument, variables: variables };
}
export const DeleteOrgDocument = gql`
  mutation deleteOrg($input: DeleteOrgInput!) {
    deleteOrg(input: $input) {
      org {
        ...Org
      }
    }
  }
  ${OrgFragmentDoc}
`;
export type DeleteOrgMutationFn = Apollo.MutationFunction<
  DeleteOrgMutation,
  DeleteOrgMutationVariables
>;
export type DeleteOrgProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteOrgMutation,
    DeleteOrgMutationVariables
  >;
} &
  TChildProps;
export function withDeleteOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteOrgMutation,
    DeleteOrgMutationVariables,
    DeleteOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteOrgMutation,
    DeleteOrgMutationVariables,
    DeleteOrgProps<TChildProps, TDataName>
  >(DeleteOrgDocument, {
    alias: "deleteOrg",
    ...operationOptions,
  });
}

/**
 * __useDeleteOrgMutation__
 *
 * To run a mutation, you first call `useDeleteOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgMutation, { data, loading, error }] = useDeleteOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrgMutation,
    DeleteOrgMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrgMutation, DeleteOrgMutationVariables>(
    DeleteOrgDocument,
    options
  );
}
export type DeleteOrgMutationHookResult = ReturnType<
  typeof useDeleteOrgMutation
>;
export type DeleteOrgMutationResult = Apollo.MutationResult<DeleteOrgMutation>;
export type DeleteOrgMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrgMutation,
  DeleteOrgMutationVariables
>;
export const SsoEditConnectionDocument = gql`
  mutation ssoEditConnection($input: SsoEditConnectionInput!) {
    ssoEditConnection(input: $input) {
      connectionName
      cert
      certProvider
      signInURL
      signRequest
      signRequestAlgorithm
      signRequestAlgorithmDigest
      protocolBinding
      debugMode
      idpDomain
      signOutURL
      userIDMapping
      nameMapping
      emailMapping
      replyUrl
      audienceUrl
      signOnUrl
      orgConnectionId
      enableUserEnroll
      forceSSOOnly
      orgSlug
    }
  }
`;
export type SsoEditConnectionMutationFn = Apollo.MutationFunction<
  SsoEditConnectionMutation,
  SsoEditConnectionMutationVariables
>;
export type SsoEditConnectionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SsoEditConnectionMutation,
    SsoEditConnectionMutationVariables
  >;
} &
  TChildProps;
export function withSsoEditConnection<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SsoEditConnectionMutation,
    SsoEditConnectionMutationVariables,
    SsoEditConnectionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SsoEditConnectionMutation,
    SsoEditConnectionMutationVariables,
    SsoEditConnectionProps<TChildProps, TDataName>
  >(SsoEditConnectionDocument, {
    alias: "ssoEditConnection",
    ...operationOptions,
  });
}

/**
 * __useSsoEditConnectionMutation__
 *
 * To run a mutation, you first call `useSsoEditConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSsoEditConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ssoEditConnectionMutation, { data, loading, error }] = useSsoEditConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSsoEditConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SsoEditConnectionMutation,
    SsoEditConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SsoEditConnectionMutation,
    SsoEditConnectionMutationVariables
  >(SsoEditConnectionDocument, options);
}
export type SsoEditConnectionMutationHookResult = ReturnType<
  typeof useSsoEditConnectionMutation
>;
export type SsoEditConnectionMutationResult = Apollo.MutationResult<SsoEditConnectionMutation>;
export type SsoEditConnectionMutationOptions = Apollo.BaseMutationOptions<
  SsoEditConnectionMutation,
  SsoEditConnectionMutationVariables
>;
export const SsoGetConnectionDataDocument = gql`
  query ssoGetConnectionData($orgConnectionId: UUID!) {
    ssoGetConnection(orgConnectionId: $orgConnectionId) {
      connectionName
      cert
      certProvider
      signInURL
      signRequest
      signRequestAlgorithm
      signRequestAlgorithmDigest
      protocolBinding
      debugMode
      idpDomain
      signOutURL
      userIDMapping
      nameMapping
      emailMapping
      orgConnectionId
      enableUserEnroll
      forceSSOOnly
      orgSlug
      replyUrl
      audienceUrl
      signOnUrl
    }
  }
`;
export type SsoGetConnectionDataProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SsoGetConnectionDataQuery,
    SsoGetConnectionDataQueryVariables
  >;
} &
  TChildProps;
export function withSsoGetConnectionData<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SsoGetConnectionDataQuery,
    SsoGetConnectionDataQueryVariables,
    SsoGetConnectionDataProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SsoGetConnectionDataQuery,
    SsoGetConnectionDataQueryVariables,
    SsoGetConnectionDataProps<TChildProps, TDataName>
  >(SsoGetConnectionDataDocument, {
    alias: "ssoGetConnectionData",
    ...operationOptions,
  });
}

/**
 * __useSsoGetConnectionDataQuery__
 *
 * To run a query within a React component, call `useSsoGetConnectionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSsoGetConnectionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSsoGetConnectionDataQuery({
 *   variables: {
 *      orgConnectionId: // value for 'orgConnectionId'
 *   },
 * });
 */
export function useSsoGetConnectionDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    SsoGetConnectionDataQuery,
    SsoGetConnectionDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SsoGetConnectionDataQuery,
    SsoGetConnectionDataQueryVariables
  >(SsoGetConnectionDataDocument, options);
}
export function useSsoGetConnectionDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SsoGetConnectionDataQuery,
    SsoGetConnectionDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SsoGetConnectionDataQuery,
    SsoGetConnectionDataQueryVariables
  >(SsoGetConnectionDataDocument, options);
}
export type SsoGetConnectionDataQueryHookResult = ReturnType<
  typeof useSsoGetConnectionDataQuery
>;
export type SsoGetConnectionDataLazyQueryHookResult = ReturnType<
  typeof useSsoGetConnectionDataLazyQuery
>;
export type SsoGetConnectionDataQueryResult = Apollo.QueryResult<
  SsoGetConnectionDataQuery,
  SsoGetConnectionDataQueryVariables
>;
export function refetchSsoGetConnectionDataQuery(
  variables?: SsoGetConnectionDataQueryVariables
) {
  return { query: SsoGetConnectionDataDocument, variables: variables };
}
export const SsoGetLogsDocument = gql`
  query ssoGetLogs($orgConnectionId: UUID!) {
    ssoGetLogs(orgConnectionId: $orgConnectionId) {
      nodes {
        eventStatus
        eventType
        description
        date
        rawData
      }
      totalCount
    }
  }
`;
export type SsoGetLogsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SsoGetLogsQuery,
    SsoGetLogsQueryVariables
  >;
} &
  TChildProps;
export function withSsoGetLogs<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SsoGetLogsQuery,
    SsoGetLogsQueryVariables,
    SsoGetLogsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SsoGetLogsQuery,
    SsoGetLogsQueryVariables,
    SsoGetLogsProps<TChildProps, TDataName>
  >(SsoGetLogsDocument, {
    alias: "ssoGetLogs",
    ...operationOptions,
  });
}

/**
 * __useSsoGetLogsQuery__
 *
 * To run a query within a React component, call `useSsoGetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSsoGetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSsoGetLogsQuery({
 *   variables: {
 *      orgConnectionId: // value for 'orgConnectionId'
 *   },
 * });
 */
export function useSsoGetLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SsoGetLogsQuery,
    SsoGetLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SsoGetLogsQuery, SsoGetLogsQueryVariables>(
    SsoGetLogsDocument,
    options
  );
}
export function useSsoGetLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SsoGetLogsQuery,
    SsoGetLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SsoGetLogsQuery, SsoGetLogsQueryVariables>(
    SsoGetLogsDocument,
    options
  );
}
export type SsoGetLogsQueryHookResult = ReturnType<typeof useSsoGetLogsQuery>;
export type SsoGetLogsLazyQueryHookResult = ReturnType<
  typeof useSsoGetLogsLazyQuery
>;
export type SsoGetLogsQueryResult = Apollo.QueryResult<
  SsoGetLogsQuery,
  SsoGetLogsQueryVariables
>;
export function refetchSsoGetLogsQuery(variables?: SsoGetLogsQueryVariables) {
  return { query: SsoGetLogsDocument, variables: variables };
}
export const OrgConnectionByOrgIdDocument = gql`
  query orgConnectionByOrgId($orgId: UUID!) {
    orgConnectionByOrgId(orgId: $orgId) {
      __typename
      id
      orgId
      automaticEnroll
      connection
      provider
    }
  }
`;
export type OrgConnectionByOrgIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrgConnectionByOrgIdQuery,
    OrgConnectionByOrgIdQueryVariables
  >;
} &
  TChildProps;
export function withOrgConnectionByOrgId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrgConnectionByOrgIdQuery,
    OrgConnectionByOrgIdQueryVariables,
    OrgConnectionByOrgIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrgConnectionByOrgIdQuery,
    OrgConnectionByOrgIdQueryVariables,
    OrgConnectionByOrgIdProps<TChildProps, TDataName>
  >(OrgConnectionByOrgIdDocument, {
    alias: "orgConnectionByOrgId",
    ...operationOptions,
  });
}

/**
 * __useOrgConnectionByOrgIdQuery__
 *
 * To run a query within a React component, call `useOrgConnectionByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgConnectionByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgConnectionByOrgIdQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useOrgConnectionByOrgIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgConnectionByOrgIdQuery,
    OrgConnectionByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgConnectionByOrgIdQuery,
    OrgConnectionByOrgIdQueryVariables
  >(OrgConnectionByOrgIdDocument, options);
}
export function useOrgConnectionByOrgIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgConnectionByOrgIdQuery,
    OrgConnectionByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgConnectionByOrgIdQuery,
    OrgConnectionByOrgIdQueryVariables
  >(OrgConnectionByOrgIdDocument, options);
}
export type OrgConnectionByOrgIdQueryHookResult = ReturnType<
  typeof useOrgConnectionByOrgIdQuery
>;
export type OrgConnectionByOrgIdLazyQueryHookResult = ReturnType<
  typeof useOrgConnectionByOrgIdLazyQuery
>;
export type OrgConnectionByOrgIdQueryResult = Apollo.QueryResult<
  OrgConnectionByOrgIdQuery,
  OrgConnectionByOrgIdQueryVariables
>;
export function refetchOrgConnectionByOrgIdQuery(
  variables?: OrgConnectionByOrgIdQueryVariables
) {
  return { query: OrgConnectionByOrgIdDocument, variables: variables };
}
export const SetStartChannelDocument = gql`
  mutation setStartChannel($input: SetStartChannelInput!) {
    setStartChannel(input: $input) {
      org {
        ...Org
        featureFlagOrgsByOrgId {
          nodes {
            ...FeatureFlagOrg
          }
        }
        channelByStartChannelId {
          ...Channel
          layoutByChannel {
            ...Layout
          }
          playlistsByChannelId {
            nodes {
              ...Playlist
              draft {
                ...Playlist
                filesByPlaylistId(first: 6) {
                  nodes {
                    ...File
                    fileOutputsByFileId {
                      nodes {
                        ...FileOutput
                      }
                    }
                  }
                }
                appInstancesByPlaylistId(first: 6) {
                  nodes {
                    ...AppInstance
                    appInstallByAppInstallId {
                      ...AppInstall
                    }
                    appVersionByAppInstanceId {
                      ...AppVersion
                      appByAppId {
                        __typename
                        id
                        name
                        iconUrl
                      }
                    }
                  }
                }
                linksByPlaylistId(first: 6) {
                  nodes {
                    ...Link
                  }
                }
                sitesByPlaylistId(first: 6) {
                  nodes {
                    ...Site
                  }
                }
              }
              filesByPlaylistId(first: 6) {
                nodes {
                  ...File
                  fileOutputsByFileId {
                    nodes {
                      ...FileOutput
                    }
                  }
                }
              }
              appInstancesByPlaylistId(first: 6) {
                nodes {
                  ...AppInstance
                  appInstallByAppInstallId {
                    ...AppInstall
                  }
                  appVersionByAppInstanceId {
                    ...AppVersion
                    appByAppId {
                      __typename
                      id
                      name
                      iconUrl
                    }
                  }
                }
              }
              linksByPlaylistId(first: 6) {
                nodes {
                  ...Link
                }
              }
              sitesByPlaylistId(first: 6) {
                nodes {
                  ...Site
                }
              }
            }
          }
          filesByChannelId {
            nodes {
              ...File
              fileOutputsByFileId {
                nodes {
                  ...FileOutput
                }
              }
            }
          }
          linksByChannelId {
            nodes {
              ...Link
            }
          }
          sitesByChannelId {
            nodes {
              ...Site
            }
          }
          appInstancesByChannelId {
            nodes {
              ...AppInstance
              appInstallByAppInstallId {
                ...AppInstall
              }
              appVersionByAppInstanceId {
                ...AppVersion
                appByAppId {
                  __typename
                  id
                  name
                  iconUrl
                }
              }
            }
          }
          userByUpdatedBy {
            ...User
          }
        }
        fileByBrandLogoId {
          ...File
        }
        fileByBrandBackgroundId {
          ...File
        }
      }
    }
  }
  ${OrgFragmentDoc}
  ${FeatureFlagOrgFragmentDoc}
  ${ChannelFragmentDoc}
  ${LayoutFragmentDoc}
  ${PlaylistFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${AppInstanceFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
  ${UserFragmentDoc}
`;
export type SetStartChannelMutationFn = Apollo.MutationFunction<
  SetStartChannelMutation,
  SetStartChannelMutationVariables
>;
export type SetStartChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetStartChannelMutation,
    SetStartChannelMutationVariables
  >;
} &
  TChildProps;
export function withSetStartChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetStartChannelMutation,
    SetStartChannelMutationVariables,
    SetStartChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetStartChannelMutation,
    SetStartChannelMutationVariables,
    SetStartChannelProps<TChildProps, TDataName>
  >(SetStartChannelDocument, {
    alias: "setStartChannel",
    ...operationOptions,
  });
}

/**
 * __useSetStartChannelMutation__
 *
 * To run a mutation, you first call `useSetStartChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStartChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStartChannelMutation, { data, loading, error }] = useSetStartChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStartChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetStartChannelMutation,
    SetStartChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetStartChannelMutation,
    SetStartChannelMutationVariables
  >(SetStartChannelDocument, options);
}
export type SetStartChannelMutationHookResult = ReturnType<
  typeof useSetStartChannelMutation
>;
export type SetStartChannelMutationResult = Apollo.MutationResult<SetStartChannelMutation>;
export type SetStartChannelMutationOptions = Apollo.BaseMutationOptions<
  SetStartChannelMutation,
  SetStartChannelMutationVariables
>;
export const UpdateOrgDocument = gql`
  mutation updateOrg($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      org {
        ...Org
      }
    }
  }
  ${OrgFragmentDoc}
`;
export type UpdateOrgMutationFn = Apollo.MutationFunction<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>;
export type UpdateOrgProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateOrgMutation,
    UpdateOrgMutationVariables
  >;
} &
  TChildProps;
export function withUpdateOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOrgMutation,
    UpdateOrgMutationVariables,
    UpdateOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOrgMutation,
    UpdateOrgMutationVariables,
    UpdateOrgProps<TChildProps, TDataName>
  >(UpdateOrgDocument, {
    alias: "updateOrg",
    ...operationOptions,
  });
}

/**
 * __useUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMutation, { data, loading, error }] = useUpdateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgMutation,
    UpdateOrgMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrgMutation, UpdateOrgMutationVariables>(
    UpdateOrgDocument,
    options
  );
}
export type UpdateOrgMutationHookResult = ReturnType<
  typeof useUpdateOrgMutation
>;
export type UpdateOrgMutationResult = Apollo.MutationResult<UpdateOrgMutation>;
export type UpdateOrgMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>;
export const UpdateOrgBrandingConfigDocument = gql`
  mutation updateOrgBrandingConfig($input: UpdateOrgBrandingConfigInput!) {
    updateOrgBrandingConfig(input: $input) {
      org {
        ...Org
      }
    }
  }
  ${OrgFragmentDoc}
`;
export type UpdateOrgBrandingConfigMutationFn = Apollo.MutationFunction<
  UpdateOrgBrandingConfigMutation,
  UpdateOrgBrandingConfigMutationVariables
>;
export type UpdateOrgBrandingConfigProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateOrgBrandingConfigMutation,
    UpdateOrgBrandingConfigMutationVariables
  >;
} &
  TChildProps;
export function withUpdateOrgBrandingConfig<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOrgBrandingConfigMutation,
    UpdateOrgBrandingConfigMutationVariables,
    UpdateOrgBrandingConfigProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOrgBrandingConfigMutation,
    UpdateOrgBrandingConfigMutationVariables,
    UpdateOrgBrandingConfigProps<TChildProps, TDataName>
  >(UpdateOrgBrandingConfigDocument, {
    alias: "updateOrgBrandingConfig",
    ...operationOptions,
  });
}

/**
 * __useUpdateOrgBrandingConfigMutation__
 *
 * To run a mutation, you first call `useUpdateOrgBrandingConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgBrandingConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgBrandingConfigMutation, { data, loading, error }] = useUpdateOrgBrandingConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgBrandingConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgBrandingConfigMutation,
    UpdateOrgBrandingConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgBrandingConfigMutation,
    UpdateOrgBrandingConfigMutationVariables
  >(UpdateOrgBrandingConfigDocument, options);
}
export type UpdateOrgBrandingConfigMutationHookResult = ReturnType<
  typeof useUpdateOrgBrandingConfigMutation
>;
export type UpdateOrgBrandingConfigMutationResult = Apollo.MutationResult<UpdateOrgBrandingConfigMutation>;
export type UpdateOrgBrandingConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgBrandingConfigMutation,
  UpdateOrgBrandingConfigMutationVariables
>;
export const UpdateOrgSettingsDocument = gql`
  mutation updateOrgSettings($input: UpdateOrgSettingsInput!) {
    updateOrgSettings(input: $input) {
      org {
        ...Org
      }
    }
  }
  ${OrgFragmentDoc}
`;
export type UpdateOrgSettingsMutationFn = Apollo.MutationFunction<
  UpdateOrgSettingsMutation,
  UpdateOrgSettingsMutationVariables
>;
export type UpdateOrgSettingsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateOrgSettingsMutation,
    UpdateOrgSettingsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateOrgSettings<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOrgSettingsMutation,
    UpdateOrgSettingsMutationVariables,
    UpdateOrgSettingsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOrgSettingsMutation,
    UpdateOrgSettingsMutationVariables,
    UpdateOrgSettingsProps<TChildProps, TDataName>
  >(UpdateOrgSettingsDocument, {
    alias: "updateOrgSettings",
    ...operationOptions,
  });
}

/**
 * __useUpdateOrgSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrgSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgSettingsMutation, { data, loading, error }] = useUpdateOrgSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgSettingsMutation,
    UpdateOrgSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgSettingsMutation,
    UpdateOrgSettingsMutationVariables
  >(UpdateOrgSettingsDocument, options);
}
export type UpdateOrgSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrgSettingsMutation
>;
export type UpdateOrgSettingsMutationResult = Apollo.MutationResult<UpdateOrgSettingsMutation>;
export type UpdateOrgSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgSettingsMutation,
  UpdateOrgSettingsMutationVariables
>;
export const AllActiveUsersDocument = gql`
  query allActiveUsers(
    $expectItems: Int = 10
    $page: Int = 0
    $orderBy: [UsersOrderBy!]
  ) {
    allUsers(
      filter: { status: { equalTo: ACTIVE } }
      condition: { userType: USER }
      first: $expectItems
      offset: $page
      orderBy: $orderBy
    ) {
      nodes {
        ...User
        userGroupsByUserId {
          nodes {
            ...UserGroup
            groupByGroupId {
              __typename
              id
              name
              description
              permissionSets
              isDefault
              groupSpacesByGroupId {
                nodes {
                  spaceId
                  permissionSets
                  spaceBySpaceId {
                    __typename
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
  ${UserFragmentDoc}
  ${UserGroupFragmentDoc}
`;
export type AllActiveUsersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllActiveUsersQuery,
    AllActiveUsersQueryVariables
  >;
} &
  TChildProps;
export function withAllActiveUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllActiveUsersQuery,
    AllActiveUsersQueryVariables,
    AllActiveUsersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllActiveUsersQuery,
    AllActiveUsersQueryVariables,
    AllActiveUsersProps<TChildProps, TDataName>
  >(AllActiveUsersDocument, {
    alias: "allActiveUsers",
    ...operationOptions,
  });
}

/**
 * __useAllActiveUsersQuery__
 *
 * To run a query within a React component, call `useAllActiveUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActiveUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActiveUsersQuery({
 *   variables: {
 *      expectItems: // value for 'expectItems'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllActiveUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllActiveUsersQuery,
    AllActiveUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllActiveUsersQuery, AllActiveUsersQueryVariables>(
    AllActiveUsersDocument,
    options
  );
}
export function useAllActiveUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllActiveUsersQuery,
    AllActiveUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllActiveUsersQuery, AllActiveUsersQueryVariables>(
    AllActiveUsersDocument,
    options
  );
}
export type AllActiveUsersQueryHookResult = ReturnType<
  typeof useAllActiveUsersQuery
>;
export type AllActiveUsersLazyQueryHookResult = ReturnType<
  typeof useAllActiveUsersLazyQuery
>;
export type AllActiveUsersQueryResult = Apollo.QueryResult<
  AllActiveUsersQuery,
  AllActiveUsersQueryVariables
>;
export function refetchAllActiveUsersQuery(
  variables?: AllActiveUsersQueryVariables
) {
  return { query: AllActiveUsersDocument, variables: variables };
}
export const AllOwnersDocument = gql`
  query allOwners($offset: Int) {
    allUsers(
      condition: { userType: USER, status: ACTIVE, isOwner: true }
      first: 200
      offset: $offset
      orderBy: [GIVEN_NAME_ASC, FAMILY_NAME_ASC, EMAIL_ASC]
    ) {
      nodes {
        ...User
        userGroupsByUserId {
          nodes {
            ...UserGroup
            groupByGroupId {
              ...Group
              groupSpacesByGroupId {
                nodes {
                  ...GroupSpace
                  spaceBySpaceId {
                    ...Space
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
  ${UserFragmentDoc}
  ${UserGroupFragmentDoc}
  ${GroupFragmentDoc}
  ${GroupSpaceFragmentDoc}
  ${SpaceFragmentDoc}
`;
export type AllOwnersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllOwnersQuery,
    AllOwnersQueryVariables
  >;
} &
  TChildProps;
export function withAllOwners<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllOwnersQuery,
    AllOwnersQueryVariables,
    AllOwnersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllOwnersQuery,
    AllOwnersQueryVariables,
    AllOwnersProps<TChildProps, TDataName>
  >(AllOwnersDocument, {
    alias: "allOwners",
    ...operationOptions,
  });
}

/**
 * __useAllOwnersQuery__
 *
 * To run a query within a React component, call `useAllOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOwnersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<AllOwnersQuery, AllOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllOwnersQuery, AllOwnersQueryVariables>(
    AllOwnersDocument,
    options
  );
}
export function useAllOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllOwnersQuery,
    AllOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllOwnersQuery, AllOwnersQueryVariables>(
    AllOwnersDocument,
    options
  );
}
export type AllOwnersQueryHookResult = ReturnType<typeof useAllOwnersQuery>;
export type AllOwnersLazyQueryHookResult = ReturnType<
  typeof useAllOwnersLazyQuery
>;
export type AllOwnersQueryResult = Apollo.QueryResult<
  AllOwnersQuery,
  AllOwnersQueryVariables
>;
export function refetchAllOwnersQuery(variables?: AllOwnersQueryVariables) {
  return { query: AllOwnersDocument, variables: variables };
}
export const AllPendingUsersDocument = gql`
  query allPendingUsers($expectItems: Int = 10, $page: Int = 0) {
    allUsers(
      filter: { status: { equalTo: PENDING } }
      orderBy: CREATED_AT_DESC
      condition: { userType: USER }
      first: $expectItems
      offset: $page
    ) {
      nodes {
        ...User
        userGroupsByUserId {
          nodes {
            ...UserGroup
            groupByGroupId {
              __typename
              id
              name
              description
              permissionSets
              isDefault
              groupSpacesByGroupId {
                nodes {
                  spaceId
                  permissionSets
                  spaceBySpaceId {
                    __typename
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
  ${UserFragmentDoc}
  ${UserGroupFragmentDoc}
`;
export type AllPendingUsersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllPendingUsersQuery,
    AllPendingUsersQueryVariables
  >;
} &
  TChildProps;
export function withAllPendingUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPendingUsersQuery,
    AllPendingUsersQueryVariables,
    AllPendingUsersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPendingUsersQuery,
    AllPendingUsersQueryVariables,
    AllPendingUsersProps<TChildProps, TDataName>
  >(AllPendingUsersDocument, {
    alias: "allPendingUsers",
    ...operationOptions,
  });
}

/**
 * __useAllPendingUsersQuery__
 *
 * To run a query within a React component, call `useAllPendingUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPendingUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPendingUsersQuery({
 *   variables: {
 *      expectItems: // value for 'expectItems'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAllPendingUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllPendingUsersQuery,
    AllPendingUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllPendingUsersQuery, AllPendingUsersQueryVariables>(
    AllPendingUsersDocument,
    options
  );
}
export function useAllPendingUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPendingUsersQuery,
    AllPendingUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPendingUsersQuery,
    AllPendingUsersQueryVariables
  >(AllPendingUsersDocument, options);
}
export type AllPendingUsersQueryHookResult = ReturnType<
  typeof useAllPendingUsersQuery
>;
export type AllPendingUsersLazyQueryHookResult = ReturnType<
  typeof useAllPendingUsersLazyQuery
>;
export type AllPendingUsersQueryResult = Apollo.QueryResult<
  AllPendingUsersQuery,
  AllPendingUsersQueryVariables
>;
export function refetchAllPendingUsersQuery(
  variables?: AllPendingUsersQueryVariables
) {
  return { query: AllPendingUsersDocument, variables: variables };
}
export const AllUsersDocument = gql`
  query allUsers {
    allUsers(
      orderBy: FAMILY_NAME_ASC
      condition: { deletedAt: null, isOwner: true }
    ) {
      nodes {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type AllUsersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllUsersQuery,
    AllUsersQueryVariables
  >;
} &
  TChildProps;
export function withAllUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUsersQuery,
    AllUsersQueryVariables,
    AllUsersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUsersQuery,
    AllUsersQueryVariables,
    AllUsersProps<TChildProps, TDataName>
  >(AllUsersDocument, {
    alias: "allUsers",
    ...operationOptions,
  });
}

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    options
  );
}
export function useAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    options
  );
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<
  typeof useAllUsersLazyQuery
>;
export type AllUsersQueryResult = Apollo.QueryResult<
  AllUsersQuery,
  AllUsersQueryVariables
>;
export function refetchAllUsersQuery(variables?: AllUsersQueryVariables) {
  return { query: AllUsersDocument, variables: variables };
}
export const AllUsersOrgRoleOwnerDocument = gql`
  query allUsersOrgRoleOwner {
    allUsers(
      orderBy: FAMILY_NAME_ASC
      condition: { deletedAt: null, isOwner: true }
    ) {
      nodes {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type AllUsersOrgRoleOwnerProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllUsersOrgRoleOwnerQuery,
    AllUsersOrgRoleOwnerQueryVariables
  >;
} &
  TChildProps;
export function withAllUsersOrgRoleOwner<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUsersOrgRoleOwnerQuery,
    AllUsersOrgRoleOwnerQueryVariables,
    AllUsersOrgRoleOwnerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUsersOrgRoleOwnerQuery,
    AllUsersOrgRoleOwnerQueryVariables,
    AllUsersOrgRoleOwnerProps<TChildProps, TDataName>
  >(AllUsersOrgRoleOwnerDocument, {
    alias: "allUsersOrgRoleOwner",
    ...operationOptions,
  });
}

/**
 * __useAllUsersOrgRoleOwnerQuery__
 *
 * To run a query within a React component, call `useAllUsersOrgRoleOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersOrgRoleOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersOrgRoleOwnerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersOrgRoleOwnerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllUsersOrgRoleOwnerQuery,
    AllUsersOrgRoleOwnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllUsersOrgRoleOwnerQuery,
    AllUsersOrgRoleOwnerQueryVariables
  >(AllUsersOrgRoleOwnerDocument, options);
}
export function useAllUsersOrgRoleOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUsersOrgRoleOwnerQuery,
    AllUsersOrgRoleOwnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllUsersOrgRoleOwnerQuery,
    AllUsersOrgRoleOwnerQueryVariables
  >(AllUsersOrgRoleOwnerDocument, options);
}
export type AllUsersOrgRoleOwnerQueryHookResult = ReturnType<
  typeof useAllUsersOrgRoleOwnerQuery
>;
export type AllUsersOrgRoleOwnerLazyQueryHookResult = ReturnType<
  typeof useAllUsersOrgRoleOwnerLazyQuery
>;
export type AllUsersOrgRoleOwnerQueryResult = Apollo.QueryResult<
  AllUsersOrgRoleOwnerQuery,
  AllUsersOrgRoleOwnerQueryVariables
>;
export function refetchAllUsersOrgRoleOwnerQuery(
  variables?: AllUsersOrgRoleOwnerQueryVariables
) {
  return { query: AllUsersOrgRoleOwnerDocument, variables: variables };
}
export const CreateGroupDocument = gql`
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      group {
        id
        name
      }
    }
  }
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export type CreateGroupProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >;
} &
  TChildProps;
export function withCreateGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateGroupMutation,
    CreateGroupMutationVariables,
    CreateGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateGroupMutation,
    CreateGroupMutationVariables,
    CreateGroupProps<TChildProps, TDataName>
  >(CreateGroupDocument, {
    alias: "createGroup",
    ...operationOptions,
  });
}

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...User
      orgByOrgId {
        ...Org
      }
    }
  }
  ${UserFragmentDoc}
  ${OrgFragmentDoc}
`;
export type CurrentUserProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >;
} &
  TChildProps;
export function withCurrentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrentUserQuery,
    CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrentUserQuery,
    CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >(CurrentUserDocument, {
    alias: "currentUser",
    ...operationOptions,
  });
}

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export function refetchCurrentUserQuery(variables?: CurrentUserQueryVariables) {
  return { query: CurrentUserDocument, variables: variables };
}
export const DeleteGroupDocument = gql`
  mutation deleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      group {
        id
        name
      }
    }
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export type DeleteGroupProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >;
} &
  TChildProps;
export function withDeleteGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteGroupMutation,
    DeleteGroupMutationVariables,
    DeleteGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteGroupMutation,
    DeleteGroupMutationVariables,
    DeleteGroupProps<TChildProps, TDataName>
  >(DeleteGroupDocument, {
    alias: "deleteGroup",
    ...operationOptions,
  });
}

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    options
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        __typename
        id
        email
        givenName
        familyName
        deletedAt
        deletedBy
      }
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export type DeleteUserProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >;
} &
  TChildProps;
export function withDeleteUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps, TDataName>
  >(DeleteUserDocument, {
    alias: "deleteUser",
    ...operationOptions,
  });
}

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const InviteUserDocument = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      users {
        ...User
        userGroupsByUserId {
          nodes {
            ...UserGroup
            groupByGroupId {
              ...Group
              groupSpacesByGroupId {
                nodes {
                  ...GroupSpace
                  spaceBySpaceId {
                    ...Space
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${UserGroupFragmentDoc}
  ${GroupFragmentDoc}
  ${GroupSpaceFragmentDoc}
  ${SpaceFragmentDoc}
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export type InviteUserProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    InviteUserMutation,
    InviteUserMutationVariables
  >;
} &
  TChildProps;
export function withInviteUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    InviteUserMutation,
    InviteUserMutationVariables,
    InviteUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    InviteUserMutation,
    InviteUserMutationVariables,
    InviteUserProps<TChildProps, TDataName>
  >(InviteUserDocument, {
    alias: "inviteUser",
    ...operationOptions,
  });
}

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserMutation,
    InviteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<
  typeof useInviteUserMutation
>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const SearchUserDocument = gql`
  query searchUser($query: String) {
    searchUser(query: $query) {
      nodes {
        ...User
        userGroupsByUserId {
          nodes {
            ...UserGroup
            groupByGroupId {
              ...Group
              groupSpacesByGroupId {
                nodes {
                  ...GroupSpace
                  spaceBySpaceId {
                    ...Space
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
  ${UserFragmentDoc}
  ${UserGroupFragmentDoc}
  ${GroupFragmentDoc}
  ${GroupSpaceFragmentDoc}
  ${SpaceFragmentDoc}
`;
export type SearchUserProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchUserQuery,
    SearchUserQueryVariables
  >;
} &
  TChildProps;
export function withSearchUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchUserQuery,
    SearchUserQueryVariables,
    SearchUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchUserQuery,
    SearchUserQueryVariables,
    SearchUserProps<TChildProps, TDataName>
  >(SearchUserDocument, {
    alias: "searchUser",
    ...operationOptions,
  });
}

/**
 * __useSearchUserQuery__
 *
 * To run a query within a React component, call `useSearchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchUserQuery,
    SearchUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUserQuery, SearchUserQueryVariables>(
    SearchUserDocument,
    options
  );
}
export function useSearchUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUserQuery,
    SearchUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchUserQuery, SearchUserQueryVariables>(
    SearchUserDocument,
    options
  );
}
export type SearchUserQueryHookResult = ReturnType<typeof useSearchUserQuery>;
export type SearchUserLazyQueryHookResult = ReturnType<
  typeof useSearchUserLazyQuery
>;
export type SearchUserQueryResult = Apollo.QueryResult<
  SearchUserQuery,
  SearchUserQueryVariables
>;
export function refetchSearchUserQuery(variables?: SearchUserQueryVariables) {
  return { query: SearchUserDocument, variables: variables };
}
export const SetGroupIsDefaultDocument = gql`
  mutation setGroupIsDefault($input: SetGroupIsDefaultInput!) {
    setGroupIsDefault(input: $input) {
      group {
        __typename
        id
        name
        description
        permissionSets
        isDefault
        groupSpacesByGroupId {
          nodes {
            spaceId
            permissionSets
            spaceBySpaceId {
              __typename
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type SetGroupIsDefaultMutationFn = Apollo.MutationFunction<
  SetGroupIsDefaultMutation,
  SetGroupIsDefaultMutationVariables
>;
export type SetGroupIsDefaultProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetGroupIsDefaultMutation,
    SetGroupIsDefaultMutationVariables
  >;
} &
  TChildProps;
export function withSetGroupIsDefault<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetGroupIsDefaultMutation,
    SetGroupIsDefaultMutationVariables,
    SetGroupIsDefaultProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetGroupIsDefaultMutation,
    SetGroupIsDefaultMutationVariables,
    SetGroupIsDefaultProps<TChildProps, TDataName>
  >(SetGroupIsDefaultDocument, {
    alias: "setGroupIsDefault",
    ...operationOptions,
  });
}

/**
 * __useSetGroupIsDefaultMutation__
 *
 * To run a mutation, you first call `useSetGroupIsDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGroupIsDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGroupIsDefaultMutation, { data, loading, error }] = useSetGroupIsDefaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetGroupIsDefaultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetGroupIsDefaultMutation,
    SetGroupIsDefaultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetGroupIsDefaultMutation,
    SetGroupIsDefaultMutationVariables
  >(SetGroupIsDefaultDocument, options);
}
export type SetGroupIsDefaultMutationHookResult = ReturnType<
  typeof useSetGroupIsDefaultMutation
>;
export type SetGroupIsDefaultMutationResult = Apollo.MutationResult<SetGroupIsDefaultMutation>;
export type SetGroupIsDefaultMutationOptions = Apollo.BaseMutationOptions<
  SetGroupIsDefaultMutation,
  SetGroupIsDefaultMutationVariables
>;
export const SetUserIsOwnerDocument = gql`
  mutation setUserIsOwner($input: SetUserIsOwnerInput!) {
    setUserIsOwner(input: $input) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type SetUserIsOwnerMutationFn = Apollo.MutationFunction<
  SetUserIsOwnerMutation,
  SetUserIsOwnerMutationVariables
>;
export type SetUserIsOwnerProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetUserIsOwnerMutation,
    SetUserIsOwnerMutationVariables
  >;
} &
  TChildProps;
export function withSetUserIsOwner<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetUserIsOwnerMutation,
    SetUserIsOwnerMutationVariables,
    SetUserIsOwnerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetUserIsOwnerMutation,
    SetUserIsOwnerMutationVariables,
    SetUserIsOwnerProps<TChildProps, TDataName>
  >(SetUserIsOwnerDocument, {
    alias: "setUserIsOwner",
    ...operationOptions,
  });
}

/**
 * __useSetUserIsOwnerMutation__
 *
 * To run a mutation, you first call `useSetUserIsOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserIsOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserIsOwnerMutation, { data, loading, error }] = useSetUserIsOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserIsOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserIsOwnerMutation,
    SetUserIsOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserIsOwnerMutation,
    SetUserIsOwnerMutationVariables
  >(SetUserIsOwnerDocument, options);
}
export type SetUserIsOwnerMutationHookResult = ReturnType<
  typeof useSetUserIsOwnerMutation
>;
export type SetUserIsOwnerMutationResult = Apollo.MutationResult<SetUserIsOwnerMutation>;
export type SetUserIsOwnerMutationOptions = Apollo.BaseMutationOptions<
  SetUserIsOwnerMutation,
  SetUserIsOwnerMutationVariables
>;
export const UpdateUserSettingsDocument = gql`
  mutation updateUserSettings($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export type UpdateUserSettingsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateUserSettings<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables,
    UpdateUserSettingsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables,
    UpdateUserSettingsProps<TChildProps, TDataName>
  >(UpdateUserSettingsDocument, {
    alias: "updateUserSettings",
    ...operationOptions,
  });
}

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >(UpdateUserSettingsDocument, options);
}
export type UpdateUserSettingsMutationHookResult = ReturnType<
  typeof useUpdateUserSettingsMutation
>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const AppByIdDocument = gql`
  query appById($id: UUID!) {
    appById(id: $id) {
      id
      name
      iconUrl
    }
  }
`;
export type AppByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppByIdQuery,
    AppByIdQueryVariables
  >;
} &
  TChildProps;
export function withAppById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppByIdQuery,
    AppByIdQueryVariables,
    AppByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppByIdQuery,
    AppByIdQueryVariables,
    AppByIdProps<TChildProps, TDataName>
  >(AppByIdDocument, {
    alias: "appById",
    ...operationOptions,
  });
}

/**
 * __useAppByIdQuery__
 *
 * To run a query within a React component, call `useAppByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppByIdQuery(
  baseOptions: Apollo.QueryHookOptions<AppByIdQuery, AppByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppByIdQuery, AppByIdQueryVariables>(
    AppByIdDocument,
    options
  );
}
export function useAppByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppByIdQuery, AppByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppByIdQuery, AppByIdQueryVariables>(
    AppByIdDocument,
    options
  );
}
export type AppByIdQueryHookResult = ReturnType<typeof useAppByIdQuery>;
export type AppByIdLazyQueryHookResult = ReturnType<typeof useAppByIdLazyQuery>;
export type AppByIdQueryResult = Apollo.QueryResult<
  AppByIdQuery,
  AppByIdQueryVariables
>;
export function refetchAppByIdQuery(variables?: AppByIdQueryVariables) {
  return { query: AppByIdDocument, variables: variables };
}
export const FilterContentDocument = gql`
  query filterContent($query: String, $first: Int) {
    searchFile(query: $query, first: $first, orderBy: UPDATED_AT_DESC) {
      __typename
      nodes {
        __typename
        id
        name
      }
    }
    searchPlaylist(
      query: $query
      first: $first
      orderBy: UPDATED_AT_DESC
      filter: { isEnabled: { equalTo: true } }
    ) {
      __typename
      nodes {
        __typename
        id
        name
      }
    }
    searchChannel(
      query: $query
      first: $first
      orderBy: UPDATED_AT_DESC
      filter: { isEnabled: { equalTo: true } }
    ) {
      __typename
      nodes {
        __typename
        id
        name
      }
    }
    searchLink(query: $query, first: $first, orderBy: UPDATED_AT_DESC) {
      __typename
      nodes {
        __typename
        id
        name
      }
    }
    searchSite(query: $query, first: $first, orderBy: UPDATED_AT_DESC) {
      __typename
      nodes {
        __typename
        id
        name
      }
    }
    searchAppInstance(query: $query, first: $first, orderBy: UPDATED_AT_DESC) {
      __typename
      nodes {
        __typename
        id
        name
      }
    }
  }
`;
export type FilterContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FilterContentQuery,
    FilterContentQueryVariables
  >;
} &
  TChildProps;
export function withFilterContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FilterContentQuery,
    FilterContentQueryVariables,
    FilterContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FilterContentQuery,
    FilterContentQueryVariables,
    FilterContentProps<TChildProps, TDataName>
  >(FilterContentDocument, {
    alias: "filterContent",
    ...operationOptions,
  });
}

/**
 * __useFilterContentQuery__
 *
 * To run a query within a React component, call `useFilterContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterContentQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFilterContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterContentQuery,
    FilterContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterContentQuery, FilterContentQueryVariables>(
    FilterContentDocument,
    options
  );
}
export function useFilterContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterContentQuery,
    FilterContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterContentQuery, FilterContentQueryVariables>(
    FilterContentDocument,
    options
  );
}
export type FilterContentQueryHookResult = ReturnType<
  typeof useFilterContentQuery
>;
export type FilterContentLazyQueryHookResult = ReturnType<
  typeof useFilterContentLazyQuery
>;
export type FilterContentQueryResult = Apollo.QueryResult<
  FilterContentQuery,
  FilterContentQueryVariables
>;
export function refetchFilterContentQuery(
  variables?: FilterContentQueryVariables
) {
  return { query: FilterContentDocument, variables: variables };
}
export const FilterScreenDocument = gql`
  query filterScreen($query: String, $first: Int, $orderBy: [ScreensOrderBy!]) {
    searchScreen(query: $query, first: $first, orderBy: $orderBy) {
      __typename
      nodes {
        __typename
        id
        name
        deviceModel
        devicePlatform
        playerHeight
        playerWidth
      }
      totalCount
    }
  }
`;
export type FilterScreenProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FilterScreenQuery,
    FilterScreenQueryVariables
  >;
} &
  TChildProps;
export function withFilterScreen<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FilterScreenQuery,
    FilterScreenQueryVariables,
    FilterScreenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FilterScreenQuery,
    FilterScreenQueryVariables,
    FilterScreenProps<TChildProps, TDataName>
  >(FilterScreenDocument, {
    alias: "filterScreen",
    ...operationOptions,
  });
}

/**
 * __useFilterScreenQuery__
 *
 * To run a query within a React component, call `useFilterScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterScreenQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFilterScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterScreenQuery,
    FilterScreenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterScreenQuery, FilterScreenQueryVariables>(
    FilterScreenDocument,
    options
  );
}
export function useFilterScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterScreenQuery,
    FilterScreenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterScreenQuery, FilterScreenQueryVariables>(
    FilterScreenDocument,
    options
  );
}
export type FilterScreenQueryHookResult = ReturnType<
  typeof useFilterScreenQuery
>;
export type FilterScreenLazyQueryHookResult = ReturnType<
  typeof useFilterScreenLazyQuery
>;
export type FilterScreenQueryResult = Apollo.QueryResult<
  FilterScreenQuery,
  FilterScreenQueryVariables
>;
export function refetchFilterScreenQuery(
  variables?: FilterScreenQueryVariables
) {
  return { query: FilterScreenDocument, variables: variables };
}
export const AddGroupSpacePermissionSetsDocument = gql`
  mutation addGroupSpacePermissionSets(
    $input: AddGroupSpacePermissionSetsInput!
  ) {
    addGroupSpacePermissionSets(input: $input) {
      groupSpace {
        ...GroupSpace
        spaceBySpaceId {
          __typename
          id
          name
        }
      }
    }
  }
  ${GroupSpaceFragmentDoc}
`;
export type AddGroupSpacePermissionSetsMutationFn = Apollo.MutationFunction<
  AddGroupSpacePermissionSetsMutation,
  AddGroupSpacePermissionSetsMutationVariables
>;
export type AddGroupSpacePermissionSetsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AddGroupSpacePermissionSetsMutation,
    AddGroupSpacePermissionSetsMutationVariables
  >;
} &
  TChildProps;
export function withAddGroupSpacePermissionSets<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddGroupSpacePermissionSetsMutation,
    AddGroupSpacePermissionSetsMutationVariables,
    AddGroupSpacePermissionSetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddGroupSpacePermissionSetsMutation,
    AddGroupSpacePermissionSetsMutationVariables,
    AddGroupSpacePermissionSetsProps<TChildProps, TDataName>
  >(AddGroupSpacePermissionSetsDocument, {
    alias: "addGroupSpacePermissionSets",
    ...operationOptions,
  });
}

/**
 * __useAddGroupSpacePermissionSetsMutation__
 *
 * To run a mutation, you first call `useAddGroupSpacePermissionSetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupSpacePermissionSetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupSpacePermissionSetsMutation, { data, loading, error }] = useAddGroupSpacePermissionSetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupSpacePermissionSetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupSpacePermissionSetsMutation,
    AddGroupSpacePermissionSetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupSpacePermissionSetsMutation,
    AddGroupSpacePermissionSetsMutationVariables
  >(AddGroupSpacePermissionSetsDocument, options);
}
export type AddGroupSpacePermissionSetsMutationHookResult = ReturnType<
  typeof useAddGroupSpacePermissionSetsMutation
>;
export type AddGroupSpacePermissionSetsMutationResult = Apollo.MutationResult<AddGroupSpacePermissionSetsMutation>;
export type AddGroupSpacePermissionSetsMutationOptions = Apollo.BaseMutationOptions<
  AddGroupSpacePermissionSetsMutation,
  AddGroupSpacePermissionSetsMutationVariables
>;
export const AllSpaceIdsAndNamesDocument = gql`
  query allSpaceIdsAndNames {
    allSpaces(condition: { isEnabled: true }) {
      nodes {
        __typename
        id
        name
        preferences
        billingCustomerId
        rootFolderId
      }
    }
  }
`;
export type AllSpaceIdsAndNamesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllSpaceIdsAndNamesQuery,
    AllSpaceIdsAndNamesQueryVariables
  >;
} &
  TChildProps;
export function withAllSpaceIdsAndNames<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSpaceIdsAndNamesQuery,
    AllSpaceIdsAndNamesQueryVariables,
    AllSpaceIdsAndNamesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSpaceIdsAndNamesQuery,
    AllSpaceIdsAndNamesQueryVariables,
    AllSpaceIdsAndNamesProps<TChildProps, TDataName>
  >(AllSpaceIdsAndNamesDocument, {
    alias: "allSpaceIdsAndNames",
    ...operationOptions,
  });
}

/**
 * __useAllSpaceIdsAndNamesQuery__
 *
 * To run a query within a React component, call `useAllSpaceIdsAndNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSpaceIdsAndNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSpaceIdsAndNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSpaceIdsAndNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllSpaceIdsAndNamesQuery,
    AllSpaceIdsAndNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllSpaceIdsAndNamesQuery,
    AllSpaceIdsAndNamesQueryVariables
  >(AllSpaceIdsAndNamesDocument, options);
}
export function useAllSpaceIdsAndNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllSpaceIdsAndNamesQuery,
    AllSpaceIdsAndNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllSpaceIdsAndNamesQuery,
    AllSpaceIdsAndNamesQueryVariables
  >(AllSpaceIdsAndNamesDocument, options);
}
export type AllSpaceIdsAndNamesQueryHookResult = ReturnType<
  typeof useAllSpaceIdsAndNamesQuery
>;
export type AllSpaceIdsAndNamesLazyQueryHookResult = ReturnType<
  typeof useAllSpaceIdsAndNamesLazyQuery
>;
export type AllSpaceIdsAndNamesQueryResult = Apollo.QueryResult<
  AllSpaceIdsAndNamesQuery,
  AllSpaceIdsAndNamesQueryVariables
>;
export function refetchAllSpaceIdsAndNamesQuery(
  variables?: AllSpaceIdsAndNamesQueryVariables
) {
  return { query: AllSpaceIdsAndNamesDocument, variables: variables };
}
export const AllSpacesDocument = gql`
  query allSpaces($limit: Int = 10, $offset: Int = 0) {
    allSpaces(first: $limit, offset: $offset, condition: { isEnabled: true }) {
      totalCount
      nodes {
        __typename
        id
        name
        preferences
        billingCustomerId
        rootFolderId
        groupSpacesBySpaceId {
          nodes {
            __typename
            groupId
            groupByGroupId {
              __typename
              name
            }
            permissionSets
          }
        }
        groupsBySpaceId {
          nodes {
            __typename
            id
            name
            permissionSets
          }
        }
      }
    }
  }
`;
export type AllSpacesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllSpacesQuery,
    AllSpacesQueryVariables
  >;
} &
  TChildProps;
export function withAllSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSpacesQuery,
    AllSpacesQueryVariables,
    AllSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSpacesQuery,
    AllSpacesQueryVariables,
    AllSpacesProps<TChildProps, TDataName>
  >(AllSpacesDocument, {
    alias: "allSpaces",
    ...operationOptions,
  });
}

/**
 * __useAllSpacesQuery__
 *
 * To run a query within a React component, call `useAllSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSpacesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllSpacesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllSpacesQuery, AllSpacesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllSpacesQuery, AllSpacesQueryVariables>(
    AllSpacesDocument,
    options
  );
}
export function useAllSpacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllSpacesQuery,
    AllSpacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllSpacesQuery, AllSpacesQueryVariables>(
    AllSpacesDocument,
    options
  );
}
export type AllSpacesQueryHookResult = ReturnType<typeof useAllSpacesQuery>;
export type AllSpacesLazyQueryHookResult = ReturnType<
  typeof useAllSpacesLazyQuery
>;
export type AllSpacesQueryResult = Apollo.QueryResult<
  AllSpacesQuery,
  AllSpacesQueryVariables
>;
export function refetchAllSpacesQuery(variables?: AllSpacesQueryVariables) {
  return { query: AllSpacesDocument, variables: variables };
}
export const AllUserGroupsDocument = gql`
  query allUserGroups {
    allUserGroups {
      nodes {
        __typename
        userId
        groupId
      }
    }
  }
`;
export type AllUserGroupsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllUserGroupsQuery,
    AllUserGroupsQueryVariables
  >;
} &
  TChildProps;
export function withAllUserGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllUserGroupsQuery,
    AllUserGroupsQueryVariables,
    AllUserGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllUserGroupsQuery,
    AllUserGroupsQueryVariables,
    AllUserGroupsProps<TChildProps, TDataName>
  >(AllUserGroupsDocument, {
    alias: "allUserGroups",
    ...operationOptions,
  });
}

/**
 * __useAllUserGroupsQuery__
 *
 * To run a query within a React component, call `useAllUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUserGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllUserGroupsQuery,
    AllUserGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllUserGroupsQuery, AllUserGroupsQueryVariables>(
    AllUserGroupsDocument,
    options
  );
}
export function useAllUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserGroupsQuery,
    AllUserGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllUserGroupsQuery, AllUserGroupsQueryVariables>(
    AllUserGroupsDocument,
    options
  );
}
export type AllUserGroupsQueryHookResult = ReturnType<
  typeof useAllUserGroupsQuery
>;
export type AllUserGroupsLazyQueryHookResult = ReturnType<
  typeof useAllUserGroupsLazyQuery
>;
export type AllUserGroupsQueryResult = Apollo.QueryResult<
  AllUserGroupsQuery,
  AllUserGroupsQueryVariables
>;
export function refetchAllUserGroupsQuery(
  variables?: AllUserGroupsQueryVariables
) {
  return { query: AllUserGroupsDocument, variables: variables };
}
export const CreateSpaceDocument = gql`
  mutation createSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      space {
        ...Space
        groupSpacesBySpaceId {
          nodes {
            __typename
            groupId
            groupByGroupId {
              __typename
              name
            }
          }
        }
      }
    }
  }
  ${SpaceFragmentDoc}
`;
export type CreateSpaceMutationFn = Apollo.MutationFunction<
  CreateSpaceMutation,
  CreateSpaceMutationVariables
>;
export type CreateSpaceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateSpaceMutation,
    CreateSpaceMutationVariables
  >;
} &
  TChildProps;
export function withCreateSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSpaceMutation,
    CreateSpaceMutationVariables,
    CreateSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSpaceMutation,
    CreateSpaceMutationVariables,
    CreateSpaceProps<TChildProps, TDataName>
  >(CreateSpaceDocument, {
    alias: "createSpace",
    ...operationOptions,
  });
}

/**
 * __useCreateSpaceMutation__
 *
 * To run a mutation, you first call `useCreateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpaceMutation, { data, loading, error }] = useCreateSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSpaceMutation,
    CreateSpaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSpaceMutation, CreateSpaceMutationVariables>(
    CreateSpaceDocument,
    options
  );
}
export type CreateSpaceMutationHookResult = ReturnType<
  typeof useCreateSpaceMutation
>;
export type CreateSpaceMutationResult = Apollo.MutationResult<CreateSpaceMutation>;
export type CreateSpaceMutationOptions = Apollo.BaseMutationOptions<
  CreateSpaceMutation,
  CreateSpaceMutationVariables
>;
export const DeleteGroupSpacePermissionSetsDocument = gql`
  mutation deleteGroupSpacePermissionSets(
    $input: DeleteGroupSpacePermissionSetsInput!
  ) {
    deleteGroupSpacePermissionSets(input: $input) {
      groupSpace {
        ...GroupSpace
      }
    }
  }
  ${GroupSpaceFragmentDoc}
`;
export type DeleteGroupSpacePermissionSetsMutationFn = Apollo.MutationFunction<
  DeleteGroupSpacePermissionSetsMutation,
  DeleteGroupSpacePermissionSetsMutationVariables
>;
export type DeleteGroupSpacePermissionSetsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteGroupSpacePermissionSetsMutation,
    DeleteGroupSpacePermissionSetsMutationVariables
  >;
} &
  TChildProps;
export function withDeleteGroupSpacePermissionSets<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteGroupSpacePermissionSetsMutation,
    DeleteGroupSpacePermissionSetsMutationVariables,
    DeleteGroupSpacePermissionSetsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteGroupSpacePermissionSetsMutation,
    DeleteGroupSpacePermissionSetsMutationVariables,
    DeleteGroupSpacePermissionSetsProps<TChildProps, TDataName>
  >(DeleteGroupSpacePermissionSetsDocument, {
    alias: "deleteGroupSpacePermissionSets",
    ...operationOptions,
  });
}

/**
 * __useDeleteGroupSpacePermissionSetsMutation__
 *
 * To run a mutation, you first call `useDeleteGroupSpacePermissionSetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupSpacePermissionSetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupSpacePermissionSetsMutation, { data, loading, error }] = useDeleteGroupSpacePermissionSetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupSpacePermissionSetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupSpacePermissionSetsMutation,
    DeleteGroupSpacePermissionSetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGroupSpacePermissionSetsMutation,
    DeleteGroupSpacePermissionSetsMutationVariables
  >(DeleteGroupSpacePermissionSetsDocument, options);
}
export type DeleteGroupSpacePermissionSetsMutationHookResult = ReturnType<
  typeof useDeleteGroupSpacePermissionSetsMutation
>;
export type DeleteGroupSpacePermissionSetsMutationResult = Apollo.MutationResult<DeleteGroupSpacePermissionSetsMutation>;
export type DeleteGroupSpacePermissionSetsMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupSpacePermissionSetsMutation,
  DeleteGroupSpacePermissionSetsMutationVariables
>;
export const DeleteSpaceDocument = gql`
  mutation deleteSpace($input: DeleteSpaceInput!) {
    deleteSpace(input: $input) {
      space {
        ...Space
        groupSpacesBySpaceId {
          nodes {
            __typename
            groupId
            groupByGroupId {
              __typename
              name
            }
          }
        }
      }
    }
  }
  ${SpaceFragmentDoc}
`;
export type DeleteSpaceMutationFn = Apollo.MutationFunction<
  DeleteSpaceMutation,
  DeleteSpaceMutationVariables
>;
export type DeleteSpaceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteSpaceMutation,
    DeleteSpaceMutationVariables
  >;
} &
  TChildProps;
export function withDeleteSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteSpaceMutation,
    DeleteSpaceMutationVariables,
    DeleteSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteSpaceMutation,
    DeleteSpaceMutationVariables,
    DeleteSpaceProps<TChildProps, TDataName>
  >(DeleteSpaceDocument, {
    alias: "deleteSpace",
    ...operationOptions,
  });
}

/**
 * __useDeleteSpaceMutation__
 *
 * To run a mutation, you first call `useDeleteSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpaceMutation, { data, loading, error }] = useDeleteSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSpaceMutation,
    DeleteSpaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSpaceMutation, DeleteSpaceMutationVariables>(
    DeleteSpaceDocument,
    options
  );
}
export type DeleteSpaceMutationHookResult = ReturnType<
  typeof useDeleteSpaceMutation
>;
export type DeleteSpaceMutationResult = Apollo.MutationResult<DeleteSpaceMutation>;
export type DeleteSpaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteSpaceMutation,
  DeleteSpaceMutationVariables
>;
export const OrgOwnerAndAdminsDocument = gql`
  query orgOwnerAndAdmins {
    owners: allUsers(condition: { isOwner: true }, orderBy: FAMILY_NAME_ASC) {
      nodes {
        ...SuperUser
      }
    }
    admins: allUsers(
      condition: { isOwner: false, userType: USER }
      orderBy: FAMILY_NAME_ASC
    ) {
      nodes {
        ...SuperUser
      }
    }
  }
  ${SuperUserFragmentDoc}
`;
export type OrgOwnerAndAdminsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrgOwnerAndAdminsQuery,
    OrgOwnerAndAdminsQueryVariables
  >;
} &
  TChildProps;
export function withOrgOwnerAndAdmins<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrgOwnerAndAdminsQuery,
    OrgOwnerAndAdminsQueryVariables,
    OrgOwnerAndAdminsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrgOwnerAndAdminsQuery,
    OrgOwnerAndAdminsQueryVariables,
    OrgOwnerAndAdminsProps<TChildProps, TDataName>
  >(OrgOwnerAndAdminsDocument, {
    alias: "orgOwnerAndAdmins",
    ...operationOptions,
  });
}

/**
 * __useOrgOwnerAndAdminsQuery__
 *
 * To run a query within a React component, call `useOrgOwnerAndAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOwnerAndAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOwnerAndAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgOwnerAndAdminsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgOwnerAndAdminsQuery,
    OrgOwnerAndAdminsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgOwnerAndAdminsQuery,
    OrgOwnerAndAdminsQueryVariables
  >(OrgOwnerAndAdminsDocument, options);
}
export function useOrgOwnerAndAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgOwnerAndAdminsQuery,
    OrgOwnerAndAdminsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgOwnerAndAdminsQuery,
    OrgOwnerAndAdminsQueryVariables
  >(OrgOwnerAndAdminsDocument, options);
}
export type OrgOwnerAndAdminsQueryHookResult = ReturnType<
  typeof useOrgOwnerAndAdminsQuery
>;
export type OrgOwnerAndAdminsLazyQueryHookResult = ReturnType<
  typeof useOrgOwnerAndAdminsLazyQuery
>;
export type OrgOwnerAndAdminsQueryResult = Apollo.QueryResult<
  OrgOwnerAndAdminsQuery,
  OrgOwnerAndAdminsQueryVariables
>;
export function refetchOrgOwnerAndAdminsQuery(
  variables?: OrgOwnerAndAdminsQueryVariables
) {
  return { query: OrgOwnerAndAdminsDocument, variables: variables };
}
export const SearchSpacesDocument = gql`
  query searchSpaces($query: String, $limit: Int, $offset: Int) {
    searchSpace(
      query: $query
      first: $limit
      offset: $offset
      condition: { isEnabled: true }
    ) {
      nodes {
        ...Space
        groupSpacesBySpaceId {
          nodes {
            __typename
            groupId
            groupByGroupId {
              __typename
              name
            }
            permissionSets
          }
        }
        groupsBySpaceId {
          nodes {
            __typename
            id
            name
            permissionSets
          }
        }
      }
      totalCount
    }
  }
  ${SpaceFragmentDoc}
`;
export type SearchSpacesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchSpacesQuery,
    SearchSpacesQueryVariables
  >;
} &
  TChildProps;
export function withSearchSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchSpacesQuery,
    SearchSpacesQueryVariables,
    SearchSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchSpacesQuery,
    SearchSpacesQueryVariables,
    SearchSpacesProps<TChildProps, TDataName>
  >(SearchSpacesDocument, {
    alias: "searchSpaces",
    ...operationOptions,
  });
}

/**
 * __useSearchSpacesQuery__
 *
 * To run a query within a React component, call `useSearchSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSpacesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchSpacesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchSpacesQuery,
    SearchSpacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchSpacesQuery, SearchSpacesQueryVariables>(
    SearchSpacesDocument,
    options
  );
}
export function useSearchSpacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSpacesQuery,
    SearchSpacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchSpacesQuery, SearchSpacesQueryVariables>(
    SearchSpacesDocument,
    options
  );
}
export type SearchSpacesQueryHookResult = ReturnType<
  typeof useSearchSpacesQuery
>;
export type SearchSpacesLazyQueryHookResult = ReturnType<
  typeof useSearchSpacesLazyQuery
>;
export type SearchSpacesQueryResult = Apollo.QueryResult<
  SearchSpacesQuery,
  SearchSpacesQueryVariables
>;
export function refetchSearchSpacesQuery(
  variables?: SearchSpacesQueryVariables
) {
  return { query: SearchSpacesDocument, variables: variables };
}
export const SpaceByIdDocument = gql`
  query spaceById($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      ...Space
      groupSpacesBySpaceId {
        nodes {
          __typename
          groupId
          spaceId
          permissionSets
          permissions
          groupByGroupId {
            userGroupsByGroupId {
              nodes {
                userByUserId {
                  id
                  __typename
                  email
                  status
                }
              }
            }
          }
        }
      }
    }
  }
  ${SpaceFragmentDoc}
`;
export type SpaceByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SpaceByIdQuery,
    SpaceByIdQueryVariables
  >;
} &
  TChildProps;
export function withSpaceById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SpaceByIdQuery,
    SpaceByIdQueryVariables,
    SpaceByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SpaceByIdQuery,
    SpaceByIdQueryVariables,
    SpaceByIdProps<TChildProps, TDataName>
  >(SpaceByIdDocument, {
    alias: "spaceById",
    ...operationOptions,
  });
}

/**
 * __useSpaceByIdQuery__
 *
 * To run a query within a React component, call `useSpaceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceByIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useSpaceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<SpaceByIdQuery, SpaceByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpaceByIdQuery, SpaceByIdQueryVariables>(
    SpaceByIdDocument,
    options
  );
}
export function useSpaceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpaceByIdQuery,
    SpaceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpaceByIdQuery, SpaceByIdQueryVariables>(
    SpaceByIdDocument,
    options
  );
}
export type SpaceByIdQueryHookResult = ReturnType<typeof useSpaceByIdQuery>;
export type SpaceByIdLazyQueryHookResult = ReturnType<
  typeof useSpaceByIdLazyQuery
>;
export type SpaceByIdQueryResult = Apollo.QueryResult<
  SpaceByIdQuery,
  SpaceByIdQueryVariables
>;
export function refetchSpaceByIdQuery(variables?: SpaceByIdQueryVariables) {
  return { query: SpaceByIdDocument, variables: variables };
}
export const UpdateGroupSpaceDocument = gql`
  mutation updateGroupSpace($input: UpdateGroupSpaceInput!) {
    updateGroupSpace(input: $input) {
      groupSpace {
        ...GroupSpace
        spaceBySpaceId {
          id
          __typename
          groupSpacesBySpaceId {
            nodes {
              __typename
              groupId
              spaceId
              permissionSets
              permissions
            }
          }
        }
        groupByGroupId {
          id
          __typename
          permissions
          permissionSets
          groupSpacesByGroupId {
            nodes {
              __typename
              groupId
              spaceId
              permissionSets
              permissions
            }
          }
        }
      }
    }
  }
  ${GroupSpaceFragmentDoc}
`;
export type UpdateGroupSpaceMutationFn = Apollo.MutationFunction<
  UpdateGroupSpaceMutation,
  UpdateGroupSpaceMutationVariables
>;
export type UpdateGroupSpaceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateGroupSpaceMutation,
    UpdateGroupSpaceMutationVariables
  >;
} &
  TChildProps;
export function withUpdateGroupSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateGroupSpaceMutation,
    UpdateGroupSpaceMutationVariables,
    UpdateGroupSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateGroupSpaceMutation,
    UpdateGroupSpaceMutationVariables,
    UpdateGroupSpaceProps<TChildProps, TDataName>
  >(UpdateGroupSpaceDocument, {
    alias: "updateGroupSpace",
    ...operationOptions,
  });
}

/**
 * __useUpdateGroupSpaceMutation__
 *
 * To run a mutation, you first call `useUpdateGroupSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupSpaceMutation, { data, loading, error }] = useUpdateGroupSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupSpaceMutation,
    UpdateGroupSpaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupSpaceMutation,
    UpdateGroupSpaceMutationVariables
  >(UpdateGroupSpaceDocument, options);
}
export type UpdateGroupSpaceMutationHookResult = ReturnType<
  typeof useUpdateGroupSpaceMutation
>;
export type UpdateGroupSpaceMutationResult = Apollo.MutationResult<UpdateGroupSpaceMutation>;
export type UpdateGroupSpaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupSpaceMutation,
  UpdateGroupSpaceMutationVariables
>;
export const UpdateSpaceDocument = gql`
  mutation updateSpace($input: UpdateSpaceInput!) {
    updateSpace(input: $input) {
      space {
        ...Space
      }
    }
  }
  ${SpaceFragmentDoc}
`;
export type UpdateSpaceMutationFn = Apollo.MutationFunction<
  UpdateSpaceMutation,
  UpdateSpaceMutationVariables
>;
export type UpdateSpaceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateSpaceMutation,
    UpdateSpaceMutationVariables
  >;
} &
  TChildProps;
export function withUpdateSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSpaceMutation,
    UpdateSpaceMutationVariables,
    UpdateSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSpaceMutation,
    UpdateSpaceMutationVariables,
    UpdateSpaceProps<TChildProps, TDataName>
  >(UpdateSpaceDocument, {
    alias: "updateSpace",
    ...operationOptions,
  });
}

/**
 * __useUpdateSpaceMutation__
 *
 * To run a mutation, you first call `useUpdateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpaceMutation, { data, loading, error }] = useUpdateSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSpaceMutation,
    UpdateSpaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSpaceMutation, UpdateSpaceMutationVariables>(
    UpdateSpaceDocument,
    options
  );
}
export type UpdateSpaceMutationHookResult = ReturnType<
  typeof useUpdateSpaceMutation
>;
export type UpdateSpaceMutationResult = Apollo.MutationResult<UpdateSpaceMutation>;
export type UpdateSpaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateSpaceMutation,
  UpdateSpaceMutationVariables
>;
export const DeleteFontDocument = gql`
  mutation DeleteFont($input: DeleteFontInput!) {
    deleteFont(input: $input) {
      font {
        ...Font
      }
    }
  }
  ${FontFragmentDoc}
`;
export type DeleteFontMutationFn = Apollo.MutationFunction<
  DeleteFontMutation,
  DeleteFontMutationVariables
>;
export type DeleteFontProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteFontMutation,
    DeleteFontMutationVariables
  >;
} &
  TChildProps;
export function withDeleteFont<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteFontMutation,
    DeleteFontMutationVariables,
    DeleteFontProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteFontMutation,
    DeleteFontMutationVariables,
    DeleteFontProps<TChildProps, TDataName>
  >(DeleteFontDocument, {
    alias: "deleteFont",
    ...operationOptions,
  });
}

/**
 * __useDeleteFontMutation__
 *
 * To run a mutation, you first call `useDeleteFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFontMutation, { data, loading, error }] = useDeleteFontMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFontMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFontMutation,
    DeleteFontMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFontMutation, DeleteFontMutationVariables>(
    DeleteFontDocument,
    options
  );
}
export type DeleteFontMutationHookResult = ReturnType<
  typeof useDeleteFontMutation
>;
export type DeleteFontMutationResult = Apollo.MutationResult<DeleteFontMutation>;
export type DeleteFontMutationOptions = Apollo.BaseMutationOptions<
  DeleteFontMutation,
  DeleteFontMutationVariables
>;
export const FontByIdDocument = gql`
  query fontById($fontId: UUID!) {
    fontById(id: $fontId) {
      ...Font
      fileByRegularFileId {
        ...File
      }
      fileByBoldFileId {
        ...File
      }
      fileByItalicFileId {
        ...File
      }
      fileByBoldItalicFileId {
        ...File
      }
    }
  }
  ${FontFragmentDoc}
  ${FileFragmentDoc}
`;
export type FontByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FontByIdQuery,
    FontByIdQueryVariables
  >;
} &
  TChildProps;
export function withFontById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FontByIdQuery,
    FontByIdQueryVariables,
    FontByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FontByIdQuery,
    FontByIdQueryVariables,
    FontByIdProps<TChildProps, TDataName>
  >(FontByIdDocument, {
    alias: "fontById",
    ...operationOptions,
  });
}

/**
 * __useFontByIdQuery__
 *
 * To run a query within a React component, call `useFontByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFontByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFontByIdQuery({
 *   variables: {
 *      fontId: // value for 'fontId'
 *   },
 * });
 */
export function useFontByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FontByIdQuery, FontByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FontByIdQuery, FontByIdQueryVariables>(
    FontByIdDocument,
    options
  );
}
export function useFontByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FontByIdQuery,
    FontByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FontByIdQuery, FontByIdQueryVariables>(
    FontByIdDocument,
    options
  );
}
export type FontByIdQueryHookResult = ReturnType<typeof useFontByIdQuery>;
export type FontByIdLazyQueryHookResult = ReturnType<
  typeof useFontByIdLazyQuery
>;
export type FontByIdQueryResult = Apollo.QueryResult<
  FontByIdQuery,
  FontByIdQueryVariables
>;
export function refetchFontByIdQuery(variables?: FontByIdQueryVariables) {
  return { query: FontByIdDocument, variables: variables };
}
export const FontByIdAssociationsDocument = gql`
  query fontByIdAssociations($fontId: UUID!) {
    fontById(id: $fontId) {
      id
      name
      themesByTitleFontId {
        nodes {
          id
          name
        }
        totalCount
      }
      themesByBodyFontId {
        nodes {
          id
          name
        }
        totalCount
      }
    }
  }
`;
export type FontByIdAssociationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FontByIdAssociationsQuery,
    FontByIdAssociationsQueryVariables
  >;
} &
  TChildProps;
export function withFontByIdAssociations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FontByIdAssociationsQuery,
    FontByIdAssociationsQueryVariables,
    FontByIdAssociationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FontByIdAssociationsQuery,
    FontByIdAssociationsQueryVariables,
    FontByIdAssociationsProps<TChildProps, TDataName>
  >(FontByIdAssociationsDocument, {
    alias: "fontByIdAssociations",
    ...operationOptions,
  });
}

/**
 * __useFontByIdAssociationsQuery__
 *
 * To run a query within a React component, call `useFontByIdAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFontByIdAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFontByIdAssociationsQuery({
 *   variables: {
 *      fontId: // value for 'fontId'
 *   },
 * });
 */
export function useFontByIdAssociationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FontByIdAssociationsQuery,
    FontByIdAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FontByIdAssociationsQuery,
    FontByIdAssociationsQueryVariables
  >(FontByIdAssociationsDocument, options);
}
export function useFontByIdAssociationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FontByIdAssociationsQuery,
    FontByIdAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FontByIdAssociationsQuery,
    FontByIdAssociationsQueryVariables
  >(FontByIdAssociationsDocument, options);
}
export type FontByIdAssociationsQueryHookResult = ReturnType<
  typeof useFontByIdAssociationsQuery
>;
export type FontByIdAssociationsLazyQueryHookResult = ReturnType<
  typeof useFontByIdAssociationsLazyQuery
>;
export type FontByIdAssociationsQueryResult = Apollo.QueryResult<
  FontByIdAssociationsQuery,
  FontByIdAssociationsQueryVariables
>;
export function refetchFontByIdAssociationsQuery(
  variables?: FontByIdAssociationsQueryVariables
) {
  return { query: FontByIdAssociationsDocument, variables: variables };
}
export const CustomFontListDocument = gql`
  query customFontList {
    allFonts(
      orderBy: CREATED_AT_DESC
      filter: { commonFontId: { isNull: true } }
    ) {
      nodes {
        ...AllFontItems
        fileByThumbnailFileId {
          ...File
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${AllFontItemsFragmentDoc}
  ${FileFragmentDoc}
`;
export type CustomFontListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CustomFontListQuery,
    CustomFontListQueryVariables
  >;
} &
  TChildProps;
export function withCustomFontList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CustomFontListQuery,
    CustomFontListQueryVariables,
    CustomFontListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CustomFontListQuery,
    CustomFontListQueryVariables,
    CustomFontListProps<TChildProps, TDataName>
  >(CustomFontListDocument, {
    alias: "customFontList",
    ...operationOptions,
  });
}

/**
 * __useCustomFontListQuery__
 *
 * To run a query within a React component, call `useCustomFontListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFontListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFontListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomFontListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomFontListQuery,
    CustomFontListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomFontListQuery, CustomFontListQueryVariables>(
    CustomFontListDocument,
    options
  );
}
export function useCustomFontListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomFontListQuery,
    CustomFontListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomFontListQuery, CustomFontListQueryVariables>(
    CustomFontListDocument,
    options
  );
}
export type CustomFontListQueryHookResult = ReturnType<
  typeof useCustomFontListQuery
>;
export type CustomFontListLazyQueryHookResult = ReturnType<
  typeof useCustomFontListLazyQuery
>;
export type CustomFontListQueryResult = Apollo.QueryResult<
  CustomFontListQuery,
  CustomFontListQueryVariables
>;
export function refetchCustomFontListQuery(
  variables?: CustomFontListQueryVariables
) {
  return { query: CustomFontListDocument, variables: variables };
}
export const AllFontListDocument = gql`
  query allFontList {
    allFonts(orderBy: CREATED_AT_DESC) {
      nodes {
        ...AllFontItems
        fileByThumbnailFileId {
          ...File
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${AllFontItemsFragmentDoc}
  ${FileFragmentDoc}
`;
export type AllFontListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllFontListQuery,
    AllFontListQueryVariables
  >;
} &
  TChildProps;
export function withAllFontList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllFontListQuery,
    AllFontListQueryVariables,
    AllFontListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllFontListQuery,
    AllFontListQueryVariables,
    AllFontListProps<TChildProps, TDataName>
  >(AllFontListDocument, {
    alias: "allFontList",
    ...operationOptions,
  });
}

/**
 * __useAllFontListQuery__
 *
 * To run a query within a React component, call `useAllFontListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFontListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFontListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFontListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllFontListQuery,
    AllFontListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllFontListQuery, AllFontListQueryVariables>(
    AllFontListDocument,
    options
  );
}
export function useAllFontListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllFontListQuery,
    AllFontListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllFontListQuery, AllFontListQueryVariables>(
    AllFontListDocument,
    options
  );
}
export type AllFontListQueryHookResult = ReturnType<typeof useAllFontListQuery>;
export type AllFontListLazyQueryHookResult = ReturnType<
  typeof useAllFontListLazyQuery
>;
export type AllFontListQueryResult = Apollo.QueryResult<
  AllFontListQuery,
  AllFontListQueryVariables
>;
export function refetchAllFontListQuery(variables?: AllFontListQueryVariables) {
  return { query: AllFontListDocument, variables: variables };
}
export const CommonFontListDocument = gql`
  query commonFontList {
    allCommonFonts {
      nodes {
        ...AllCommonFontItems
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${AllCommonFontItemsFragmentDoc}
`;
export type CommonFontListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CommonFontListQuery,
    CommonFontListQueryVariables
  >;
} &
  TChildProps;
export function withCommonFontList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CommonFontListQuery,
    CommonFontListQueryVariables,
    CommonFontListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CommonFontListQuery,
    CommonFontListQueryVariables,
    CommonFontListProps<TChildProps, TDataName>
  >(CommonFontListDocument, {
    alias: "commonFontList",
    ...operationOptions,
  });
}

/**
 * __useCommonFontListQuery__
 *
 * To run a query within a React component, call `useCommonFontListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonFontListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonFontListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommonFontListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CommonFontListQuery,
    CommonFontListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommonFontListQuery, CommonFontListQueryVariables>(
    CommonFontListDocument,
    options
  );
}
export function useCommonFontListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommonFontListQuery,
    CommonFontListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommonFontListQuery, CommonFontListQueryVariables>(
    CommonFontListDocument,
    options
  );
}
export type CommonFontListQueryHookResult = ReturnType<
  typeof useCommonFontListQuery
>;
export type CommonFontListLazyQueryHookResult = ReturnType<
  typeof useCommonFontListLazyQuery
>;
export type CommonFontListQueryResult = Apollo.QueryResult<
  CommonFontListQuery,
  CommonFontListQueryVariables
>;
export function refetchCommonFontListQuery(
  variables?: CommonFontListQueryVariables
) {
  return { query: CommonFontListDocument, variables: variables };
}
export const UpdateFontDocument = gql`
  mutation UpdateFont($input: UpdateFontInput!) {
    updateFont(input: $input) {
      font {
        ...AllFontItems
      }
    }
  }
  ${AllFontItemsFragmentDoc}
`;
export type UpdateFontMutationFn = Apollo.MutationFunction<
  UpdateFontMutation,
  UpdateFontMutationVariables
>;
export type UpdateFontProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateFontMutation,
    UpdateFontMutationVariables
  >;
} &
  TChildProps;
export function withUpdateFont<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateFontMutation,
    UpdateFontMutationVariables,
    UpdateFontProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateFontMutation,
    UpdateFontMutationVariables,
    UpdateFontProps<TChildProps, TDataName>
  >(UpdateFontDocument, {
    alias: "updateFont",
    ...operationOptions,
  });
}

/**
 * __useUpdateFontMutation__
 *
 * To run a mutation, you first call `useUpdateFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFontMutation, { data, loading, error }] = useUpdateFontMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFontMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFontMutation,
    UpdateFontMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFontMutation, UpdateFontMutationVariables>(
    UpdateFontDocument,
    options
  );
}
export type UpdateFontMutationHookResult = ReturnType<
  typeof useUpdateFontMutation
>;
export type UpdateFontMutationResult = Apollo.MutationResult<UpdateFontMutation>;
export type UpdateFontMutationOptions = Apollo.BaseMutationOptions<
  UpdateFontMutation,
  UpdateFontMutationVariables
>;
export const DeleteThemeDocument = gql`
  mutation DeleteTheme($input: DeleteThemeInput!) {
    deleteTheme(input: $input) {
      theme {
        __typename
        id
        name
      }
    }
  }
`;
export type DeleteThemeMutationFn = Apollo.MutationFunction<
  DeleteThemeMutation,
  DeleteThemeMutationVariables
>;
export type DeleteThemeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteThemeMutation,
    DeleteThemeMutationVariables
  >;
} &
  TChildProps;
export function withDeleteTheme<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteThemeMutation,
    DeleteThemeMutationVariables,
    DeleteThemeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteThemeMutation,
    DeleteThemeMutationVariables,
    DeleteThemeProps<TChildProps, TDataName>
  >(DeleteThemeDocument, {
    alias: "deleteTheme",
    ...operationOptions,
  });
}

/**
 * __useDeleteThemeMutation__
 *
 * To run a mutation, you first call `useDeleteThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThemeMutation, { data, loading, error }] = useDeleteThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteThemeMutation,
    DeleteThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteThemeMutation, DeleteThemeMutationVariables>(
    DeleteThemeDocument,
    options
  );
}
export type DeleteThemeMutationHookResult = ReturnType<
  typeof useDeleteThemeMutation
>;
export type DeleteThemeMutationResult = Apollo.MutationResult<DeleteThemeMutation>;
export type DeleteThemeMutationOptions = Apollo.BaseMutationOptions<
  DeleteThemeMutation,
  DeleteThemeMutationVariables
>;
export const SetOrgDefaultChannelThemeDocument = gql`
  mutation SetOrgDefaultChannelTheme($input: SetOrgDefaultChannelThemeInput!) {
    setOrgDefaultChannelTheme(input: $input) {
      org {
        ...Org
      }
    }
  }
  ${OrgFragmentDoc}
`;
export type SetOrgDefaultChannelThemeMutationFn = Apollo.MutationFunction<
  SetOrgDefaultChannelThemeMutation,
  SetOrgDefaultChannelThemeMutationVariables
>;
export type SetOrgDefaultChannelThemeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetOrgDefaultChannelThemeMutation,
    SetOrgDefaultChannelThemeMutationVariables
  >;
} &
  TChildProps;
export function withSetOrgDefaultChannelTheme<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetOrgDefaultChannelThemeMutation,
    SetOrgDefaultChannelThemeMutationVariables,
    SetOrgDefaultChannelThemeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetOrgDefaultChannelThemeMutation,
    SetOrgDefaultChannelThemeMutationVariables,
    SetOrgDefaultChannelThemeProps<TChildProps, TDataName>
  >(SetOrgDefaultChannelThemeDocument, {
    alias: "setOrgDefaultChannelTheme",
    ...operationOptions,
  });
}

/**
 * __useSetOrgDefaultChannelThemeMutation__
 *
 * To run a mutation, you first call `useSetOrgDefaultChannelThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrgDefaultChannelThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrgDefaultChannelThemeMutation, { data, loading, error }] = useSetOrgDefaultChannelThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrgDefaultChannelThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOrgDefaultChannelThemeMutation,
    SetOrgDefaultChannelThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOrgDefaultChannelThemeMutation,
    SetOrgDefaultChannelThemeMutationVariables
  >(SetOrgDefaultChannelThemeDocument, options);
}
export type SetOrgDefaultChannelThemeMutationHookResult = ReturnType<
  typeof useSetOrgDefaultChannelThemeMutation
>;
export type SetOrgDefaultChannelThemeMutationResult = Apollo.MutationResult<SetOrgDefaultChannelThemeMutation>;
export type SetOrgDefaultChannelThemeMutationOptions = Apollo.BaseMutationOptions<
  SetOrgDefaultChannelThemeMutation,
  SetOrgDefaultChannelThemeMutationVariables
>;
export const SetOrgSplashChannelThemeDocument = gql`
  mutation SetOrgSplashChannelTheme($input: SetOrgSplashChannelThemeInput!) {
    setOrgSplashChannelTheme(input: $input) {
      org {
        ...Org
      }
    }
  }
  ${OrgFragmentDoc}
`;
export type SetOrgSplashChannelThemeMutationFn = Apollo.MutationFunction<
  SetOrgSplashChannelThemeMutation,
  SetOrgSplashChannelThemeMutationVariables
>;
export type SetOrgSplashChannelThemeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetOrgSplashChannelThemeMutation,
    SetOrgSplashChannelThemeMutationVariables
  >;
} &
  TChildProps;
export function withSetOrgSplashChannelTheme<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetOrgSplashChannelThemeMutation,
    SetOrgSplashChannelThemeMutationVariables,
    SetOrgSplashChannelThemeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetOrgSplashChannelThemeMutation,
    SetOrgSplashChannelThemeMutationVariables,
    SetOrgSplashChannelThemeProps<TChildProps, TDataName>
  >(SetOrgSplashChannelThemeDocument, {
    alias: "setOrgSplashChannelTheme",
    ...operationOptions,
  });
}

/**
 * __useSetOrgSplashChannelThemeMutation__
 *
 * To run a mutation, you first call `useSetOrgSplashChannelThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrgSplashChannelThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrgSplashChannelThemeMutation, { data, loading, error }] = useSetOrgSplashChannelThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrgSplashChannelThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOrgSplashChannelThemeMutation,
    SetOrgSplashChannelThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOrgSplashChannelThemeMutation,
    SetOrgSplashChannelThemeMutationVariables
  >(SetOrgSplashChannelThemeDocument, options);
}
export type SetOrgSplashChannelThemeMutationHookResult = ReturnType<
  typeof useSetOrgSplashChannelThemeMutation
>;
export type SetOrgSplashChannelThemeMutationResult = Apollo.MutationResult<SetOrgSplashChannelThemeMutation>;
export type SetOrgSplashChannelThemeMutationOptions = Apollo.BaseMutationOptions<
  SetOrgSplashChannelThemeMutation,
  SetOrgSplashChannelThemeMutationVariables
>;
export const ThemeListDocument = gql`
  query themeList {
    allThemes(orderBy: NAME_ASC) {
      nodes {
        ...ThemeListItem
      }
    }
  }
  ${ThemeListItemFragmentDoc}
`;
export type ThemeListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ThemeListQuery,
    ThemeListQueryVariables
  >;
} &
  TChildProps;
export function withThemeList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ThemeListQuery,
    ThemeListQueryVariables,
    ThemeListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ThemeListQuery,
    ThemeListQueryVariables,
    ThemeListProps<TChildProps, TDataName>
  >(ThemeListDocument, {
    alias: "themeList",
    ...operationOptions,
  });
}

/**
 * __useThemeListQuery__
 *
 * To run a query within a React component, call `useThemeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemeListQuery(
  baseOptions?: Apollo.QueryHookOptions<ThemeListQuery, ThemeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThemeListQuery, ThemeListQueryVariables>(
    ThemeListDocument,
    options
  );
}
export function useThemeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThemeListQuery,
    ThemeListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThemeListQuery, ThemeListQueryVariables>(
    ThemeListDocument,
    options
  );
}
export type ThemeListQueryHookResult = ReturnType<typeof useThemeListQuery>;
export type ThemeListLazyQueryHookResult = ReturnType<
  typeof useThemeListLazyQuery
>;
export type ThemeListQueryResult = Apollo.QueryResult<
  ThemeListQuery,
  ThemeListQueryVariables
>;
export function refetchThemeListQuery(variables?: ThemeListQueryVariables) {
  return { query: ThemeListDocument, variables: variables };
}
export const AppInstallByIdDocument = gql`
  query appInstallById($id: UUID!, $endCursor: Cursor, $spaceId: UUID!) {
    appInstallById(id: $id) {
      __typename
      id
      appByAppId {
        __typename
        id
        name
        iconUrl
        defaultInstall
      }
      allAppInstancesByAppInstallIdAndSpaceId(
        first: 20
        after: $endCursor
        spaceId: $spaceId
      ) {
        nodes {
          __typename
          id
          spaceId
          name
          isSharedExplicit
          isSharedAll
          config
          availableAt
          expireAt
          sharedSpacesByAppInstanceId {
            nodes {
              __typename
              id
            }
          }
          fileByThumbnailFileId {
            ...File
          }
          castedScreenByAppInstanceId {
            nodes {
              __typename
              id
              name
              deviceId
              deviceModel
            }
          }
          appVersionByAppInstanceId {
            __typename
            id
            version
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      stableAppVersionByAppInstallId {
        id
        configurationType
        editorUrl
        manifestJson
        viewerUrl
        appByAppId {
          __typename
          id
          name
          iconUrl
        }
      }
    }
  }
  ${FileFragmentDoc}
`;
export type AppInstallByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppInstallByIdQuery,
    AppInstallByIdQueryVariables
  >;
} &
  TChildProps;
export function withAppInstallById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppInstallByIdQuery,
    AppInstallByIdQueryVariables,
    AppInstallByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppInstallByIdQuery,
    AppInstallByIdQueryVariables,
    AppInstallByIdProps<TChildProps, TDataName>
  >(AppInstallByIdDocument, {
    alias: "appInstallById",
    ...operationOptions,
  });
}

/**
 * __useAppInstallByIdQuery__
 *
 * To run a query within a React component, call `useAppInstallByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppInstallByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppInstallByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      endCursor: // value for 'endCursor'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAppInstallByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppInstallByIdQuery,
    AppInstallByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppInstallByIdQuery, AppInstallByIdQueryVariables>(
    AppInstallByIdDocument,
    options
  );
}
export function useAppInstallByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppInstallByIdQuery,
    AppInstallByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppInstallByIdQuery, AppInstallByIdQueryVariables>(
    AppInstallByIdDocument,
    options
  );
}
export type AppInstallByIdQueryHookResult = ReturnType<
  typeof useAppInstallByIdQuery
>;
export type AppInstallByIdLazyQueryHookResult = ReturnType<
  typeof useAppInstallByIdLazyQuery
>;
export type AppInstallByIdQueryResult = Apollo.QueryResult<
  AppInstallByIdQuery,
  AppInstallByIdQueryVariables
>;
export function refetchAppInstallByIdQuery(
  variables?: AppInstallByIdQueryVariables
) {
  return { query: AppInstallByIdDocument, variables: variables };
}
export const AppInstanceByIdForContentDocument = gql`
  query appInstanceByIdForContent($id: UUID!) {
    appInstanceById(id: $id) {
      ...AppInstanceByIdForContent
    }
  }
  ${AppInstanceByIdForContentFragmentDoc}
`;
export type AppInstanceByIdForContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables
  >;
} &
  TChildProps;
export function withAppInstanceByIdForContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables,
    AppInstanceByIdForContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables,
    AppInstanceByIdForContentProps<TChildProps, TDataName>
  >(AppInstanceByIdForContentDocument, {
    alias: "appInstanceByIdForContent",
    ...operationOptions,
  });
}

/**
 * __useAppInstanceByIdForContentQuery__
 *
 * To run a query within a React component, call `useAppInstanceByIdForContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppInstanceByIdForContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppInstanceByIdForContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppInstanceByIdForContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables
  >(AppInstanceByIdForContentDocument, options);
}
export function useAppInstanceByIdForContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppInstanceByIdForContentQuery,
    AppInstanceByIdForContentQueryVariables
  >(AppInstanceByIdForContentDocument, options);
}
export type AppInstanceByIdForContentQueryHookResult = ReturnType<
  typeof useAppInstanceByIdForContentQuery
>;
export type AppInstanceByIdForContentLazyQueryHookResult = ReturnType<
  typeof useAppInstanceByIdForContentLazyQuery
>;
export type AppInstanceByIdForContentQueryResult = Apollo.QueryResult<
  AppInstanceByIdForContentQuery,
  AppInstanceByIdForContentQueryVariables
>;
export function refetchAppInstanceByIdForContentQuery(
  variables?: AppInstanceByIdForContentQueryVariables
) {
  return { query: AppInstanceByIdForContentDocument, variables: variables };
}
export const CreateAppInstanceDocument = gql`
  mutation createAppInstance($input: CreateAppInstanceInput!) {
    createAppInstance(input: $input) {
      appInstance {
        ...AvailableAppInstance
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type CreateAppInstanceMutationFn = Apollo.MutationFunction<
  CreateAppInstanceMutation,
  CreateAppInstanceMutationVariables
>;
export type CreateAppInstanceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateAppInstanceMutation,
    CreateAppInstanceMutationVariables
  >;
} &
  TChildProps;
export function withCreateAppInstance<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateAppInstanceMutation,
    CreateAppInstanceMutationVariables,
    CreateAppInstanceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateAppInstanceMutation,
    CreateAppInstanceMutationVariables,
    CreateAppInstanceProps<TChildProps, TDataName>
  >(CreateAppInstanceDocument, {
    alias: "createAppInstance",
    ...operationOptions,
  });
}

/**
 * __useCreateAppInstanceMutation__
 *
 * To run a mutation, you first call `useCreateAppInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppInstanceMutation, { data, loading, error }] = useCreateAppInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppInstanceMutation,
    CreateAppInstanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppInstanceMutation,
    CreateAppInstanceMutationVariables
  >(CreateAppInstanceDocument, options);
}
export type CreateAppInstanceMutationHookResult = ReturnType<
  typeof useCreateAppInstanceMutation
>;
export type CreateAppInstanceMutationResult = Apollo.MutationResult<CreateAppInstanceMutation>;
export type CreateAppInstanceMutationOptions = Apollo.BaseMutationOptions<
  CreateAppInstanceMutation,
  CreateAppInstanceMutationVariables
>;
export const DeleteAppInstanceDocument = gql`
  mutation deleteAppInstance($input: DeleteAppInstanceInput!) {
    deleteAppInstance(input: $input) {
      appInstance {
        __typename
        id
        name
        appId
      }
    }
  }
`;
export type DeleteAppInstanceMutationFn = Apollo.MutationFunction<
  DeleteAppInstanceMutation,
  DeleteAppInstanceMutationVariables
>;
export type DeleteAppInstanceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteAppInstanceMutation,
    DeleteAppInstanceMutationVariables
  >;
} &
  TChildProps;
export function withDeleteAppInstance<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteAppInstanceMutation,
    DeleteAppInstanceMutationVariables,
    DeleteAppInstanceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteAppInstanceMutation,
    DeleteAppInstanceMutationVariables,
    DeleteAppInstanceProps<TChildProps, TDataName>
  >(DeleteAppInstanceDocument, {
    alias: "deleteAppInstance",
    ...operationOptions,
  });
}

/**
 * __useDeleteAppInstanceMutation__
 *
 * To run a mutation, you first call `useDeleteAppInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppInstanceMutation, { data, loading, error }] = useDeleteAppInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppInstanceMutation,
    DeleteAppInstanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAppInstanceMutation,
    DeleteAppInstanceMutationVariables
  >(DeleteAppInstanceDocument, options);
}
export type DeleteAppInstanceMutationHookResult = ReturnType<
  typeof useDeleteAppInstanceMutation
>;
export type DeleteAppInstanceMutationResult = Apollo.MutationResult<DeleteAppInstanceMutation>;
export type DeleteAppInstanceMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppInstanceMutation,
  DeleteAppInstanceMutationVariables
>;
export const UpdateShareAppInstanceToAllSpacesDocument = gql`
  mutation UpdateShareAppInstanceToAllSpaces(
    $input: UpdateShareAppInstanceToAllSpacesInput!
  ) {
    updateShareAppInstanceToAllSpaces(input: $input) {
      appInstance {
        ...AppInstance
        isTemplate
        targetCommonAppInstanceTemplateId
        fileByThumbnailFileId {
          ...File
        }
        filesByAppInstanceId {
          nodes {
            ...File
          }
        }
        castedScreenByAppInstanceId {
          nodes {
            ...Screen
          }
        }
        appInstallByAppInstallId {
          ...AppInstall
        }
        appVersionByAppInstanceId {
          ...AppVersion
          appByAppId {
            __typename
            id
            name
            iconUrl
          }
        }
        sharedSpacesByAppInstanceId {
          nodes {
            __typename
            id
            name
          }
        }
      }
    }
  }
  ${AppInstanceFragmentDoc}
  ${FileFragmentDoc}
  ${ScreenFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
`;
export type UpdateShareAppInstanceToAllSpacesMutationFn = Apollo.MutationFunction<
  UpdateShareAppInstanceToAllSpacesMutation,
  UpdateShareAppInstanceToAllSpacesMutationVariables
>;
export type UpdateShareAppInstanceToAllSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateShareAppInstanceToAllSpacesMutation,
    UpdateShareAppInstanceToAllSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateShareAppInstanceToAllSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateShareAppInstanceToAllSpacesMutation,
    UpdateShareAppInstanceToAllSpacesMutationVariables,
    UpdateShareAppInstanceToAllSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateShareAppInstanceToAllSpacesMutation,
    UpdateShareAppInstanceToAllSpacesMutationVariables,
    UpdateShareAppInstanceToAllSpacesProps<TChildProps, TDataName>
  >(UpdateShareAppInstanceToAllSpacesDocument, {
    alias: "updateShareAppInstanceToAllSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateShareAppInstanceToAllSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateShareAppInstanceToAllSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareAppInstanceToAllSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareAppInstanceToAllSpacesMutation, { data, loading, error }] = useUpdateShareAppInstanceToAllSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareAppInstanceToAllSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareAppInstanceToAllSpacesMutation,
    UpdateShareAppInstanceToAllSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareAppInstanceToAllSpacesMutation,
    UpdateShareAppInstanceToAllSpacesMutationVariables
  >(UpdateShareAppInstanceToAllSpacesDocument, options);
}
export type UpdateShareAppInstanceToAllSpacesMutationHookResult = ReturnType<
  typeof useUpdateShareAppInstanceToAllSpacesMutation
>;
export type UpdateShareAppInstanceToAllSpacesMutationResult = Apollo.MutationResult<UpdateShareAppInstanceToAllSpacesMutation>;
export type UpdateShareAppInstanceToAllSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareAppInstanceToAllSpacesMutation,
  UpdateShareAppInstanceToAllSpacesMutationVariables
>;
export const UpdateShareAppInstanceToSpacesDocument = gql`
  mutation UpdateShareAppInstanceToSpaces(
    $input: UpdateShareAppInstanceToSpacesInput!
  ) {
    updateShareAppInstanceToSpaces(input: $input) {
      appInstance {
        ...AppInstance
        isTemplate
        targetCommonAppInstanceTemplateId
        fileByThumbnailFileId {
          ...File
        }
        filesByAppInstanceId {
          nodes {
            ...File
          }
        }
        castedScreenByAppInstanceId {
          nodes {
            ...Screen
          }
        }
        appInstallByAppInstallId {
          ...AppInstall
        }
        appVersionByAppInstanceId {
          ...AppVersion
          appByAppId {
            __typename
            id
            name
            iconUrl
          }
        }
        sharedSpacesByAppInstanceId {
          nodes {
            __typename
            id
            name
          }
        }
      }
    }
  }
  ${AppInstanceFragmentDoc}
  ${FileFragmentDoc}
  ${ScreenFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
`;
export type UpdateShareAppInstanceToSpacesMutationFn = Apollo.MutationFunction<
  UpdateShareAppInstanceToSpacesMutation,
  UpdateShareAppInstanceToSpacesMutationVariables
>;
export type UpdateShareAppInstanceToSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateShareAppInstanceToSpacesMutation,
    UpdateShareAppInstanceToSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateShareAppInstanceToSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateShareAppInstanceToSpacesMutation,
    UpdateShareAppInstanceToSpacesMutationVariables,
    UpdateShareAppInstanceToSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateShareAppInstanceToSpacesMutation,
    UpdateShareAppInstanceToSpacesMutationVariables,
    UpdateShareAppInstanceToSpacesProps<TChildProps, TDataName>
  >(UpdateShareAppInstanceToSpacesDocument, {
    alias: "updateShareAppInstanceToSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateShareAppInstanceToSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateShareAppInstanceToSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareAppInstanceToSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareAppInstanceToSpacesMutation, { data, loading, error }] = useUpdateShareAppInstanceToSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareAppInstanceToSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareAppInstanceToSpacesMutation,
    UpdateShareAppInstanceToSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareAppInstanceToSpacesMutation,
    UpdateShareAppInstanceToSpacesMutationVariables
  >(UpdateShareAppInstanceToSpacesDocument, options);
}
export type UpdateShareAppInstanceToSpacesMutationHookResult = ReturnType<
  typeof useUpdateShareAppInstanceToSpacesMutation
>;
export type UpdateShareAppInstanceToSpacesMutationResult = Apollo.MutationResult<UpdateShareAppInstanceToSpacesMutation>;
export type UpdateShareAppInstanceToSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareAppInstanceToSpacesMutation,
  UpdateShareAppInstanceToSpacesMutationVariables
>;
export const UpdateAppInstanceDocument = gql`
  mutation updateAppInstance($input: UpdateAppInstanceInput!) {
    updateAppInstance(input: $input) {
      appInstance {
        __typename
        id
        spaceId
        name
        config
        version
        state
        isTemplate
        fileByThumbnailFileId {
          ...File
        }
      }
    }
  }
  ${FileFragmentDoc}
`;
export type UpdateAppInstanceMutationFn = Apollo.MutationFunction<
  UpdateAppInstanceMutation,
  UpdateAppInstanceMutationVariables
>;
export type UpdateAppInstanceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAppInstanceMutation,
    UpdateAppInstanceMutationVariables
  >;
} &
  TChildProps;
export function withUpdateAppInstance<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAppInstanceMutation,
    UpdateAppInstanceMutationVariables,
    UpdateAppInstanceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAppInstanceMutation,
    UpdateAppInstanceMutationVariables,
    UpdateAppInstanceProps<TChildProps, TDataName>
  >(UpdateAppInstanceDocument, {
    alias: "updateAppInstance",
    ...operationOptions,
  });
}

/**
 * __useUpdateAppInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateAppInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppInstanceMutation, { data, loading, error }] = useUpdateAppInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppInstanceMutation,
    UpdateAppInstanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppInstanceMutation,
    UpdateAppInstanceMutationVariables
  >(UpdateAppInstanceDocument, options);
}
export type UpdateAppInstanceMutationHookResult = ReturnType<
  typeof useUpdateAppInstanceMutation
>;
export type UpdateAppInstanceMutationResult = Apollo.MutationResult<UpdateAppInstanceMutation>;
export type UpdateAppInstanceMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppInstanceMutation,
  UpdateAppInstanceMutationVariables
>;
export const UpdateAppInstanceAvailableDocument = gql`
  mutation updateAppInstanceAvailable(
    $input: UpdateAppInstanceAvailableInput!
  ) {
    updateAppInstanceAvailable(input: $input) {
      appInstance {
        ...AppInstance
      }
    }
  }
  ${AppInstanceFragmentDoc}
`;
export type UpdateAppInstanceAvailableMutationFn = Apollo.MutationFunction<
  UpdateAppInstanceAvailableMutation,
  UpdateAppInstanceAvailableMutationVariables
>;
export type UpdateAppInstanceAvailableProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAppInstanceAvailableMutation,
    UpdateAppInstanceAvailableMutationVariables
  >;
} &
  TChildProps;
export function withUpdateAppInstanceAvailable<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAppInstanceAvailableMutation,
    UpdateAppInstanceAvailableMutationVariables,
    UpdateAppInstanceAvailableProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAppInstanceAvailableMutation,
    UpdateAppInstanceAvailableMutationVariables,
    UpdateAppInstanceAvailableProps<TChildProps, TDataName>
  >(UpdateAppInstanceAvailableDocument, {
    alias: "updateAppInstanceAvailable",
    ...operationOptions,
  });
}

/**
 * __useUpdateAppInstanceAvailableMutation__
 *
 * To run a mutation, you first call `useUpdateAppInstanceAvailableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppInstanceAvailableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppInstanceAvailableMutation, { data, loading, error }] = useUpdateAppInstanceAvailableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppInstanceAvailableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppInstanceAvailableMutation,
    UpdateAppInstanceAvailableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppInstanceAvailableMutation,
    UpdateAppInstanceAvailableMutationVariables
  >(UpdateAppInstanceAvailableDocument, options);
}
export type UpdateAppInstanceAvailableMutationHookResult = ReturnType<
  typeof useUpdateAppInstanceAvailableMutation
>;
export type UpdateAppInstanceAvailableMutationResult = Apollo.MutationResult<UpdateAppInstanceAvailableMutation>;
export type UpdateAppInstanceAvailableMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppInstanceAvailableMutation,
  UpdateAppInstanceAvailableMutationVariables
>;
export const UpdateAppInstanceAvailableByIdsDocument = gql`
  mutation updateAppInstanceAvailableByIds(
    $input: UpdateAppInstanceAvailableByIdsInput!
  ) {
    updateAppInstanceAvailableByIds(input: $input) {
      appInstances {
        ...AppInstanceByIdForContent
      }
    }
  }
  ${AppInstanceByIdForContentFragmentDoc}
`;
export type UpdateAppInstanceAvailableByIdsMutationFn = Apollo.MutationFunction<
  UpdateAppInstanceAvailableByIdsMutation,
  UpdateAppInstanceAvailableByIdsMutationVariables
>;
export type UpdateAppInstanceAvailableByIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAppInstanceAvailableByIdsMutation,
    UpdateAppInstanceAvailableByIdsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateAppInstanceAvailableByIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAppInstanceAvailableByIdsMutation,
    UpdateAppInstanceAvailableByIdsMutationVariables,
    UpdateAppInstanceAvailableByIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAppInstanceAvailableByIdsMutation,
    UpdateAppInstanceAvailableByIdsMutationVariables,
    UpdateAppInstanceAvailableByIdsProps<TChildProps, TDataName>
  >(UpdateAppInstanceAvailableByIdsDocument, {
    alias: "updateAppInstanceAvailableByIds",
    ...operationOptions,
  });
}

/**
 * __useUpdateAppInstanceAvailableByIdsMutation__
 *
 * To run a mutation, you first call `useUpdateAppInstanceAvailableByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppInstanceAvailableByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppInstanceAvailableByIdsMutation, { data, loading, error }] = useUpdateAppInstanceAvailableByIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppInstanceAvailableByIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppInstanceAvailableByIdsMutation,
    UpdateAppInstanceAvailableByIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppInstanceAvailableByIdsMutation,
    UpdateAppInstanceAvailableByIdsMutationVariables
  >(UpdateAppInstanceAvailableByIdsDocument, options);
}
export type UpdateAppInstanceAvailableByIdsMutationHookResult = ReturnType<
  typeof useUpdateAppInstanceAvailableByIdsMutation
>;
export type UpdateAppInstanceAvailableByIdsMutationResult = Apollo.MutationResult<UpdateAppInstanceAvailableByIdsMutation>;
export type UpdateAppInstanceAvailableByIdsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppInstanceAvailableByIdsMutation,
  UpdateAppInstanceAvailableByIdsMutationVariables
>;
export const UpdateAppInstanceExpireDocument = gql`
  mutation updateAppInstanceExpire($input: UpdateAppInstanceExpireInput!) {
    updateAppInstanceExpire(input: $input) {
      appInstance {
        ...AppInstance
      }
    }
  }
  ${AppInstanceFragmentDoc}
`;
export type UpdateAppInstanceExpireMutationFn = Apollo.MutationFunction<
  UpdateAppInstanceExpireMutation,
  UpdateAppInstanceExpireMutationVariables
>;
export type UpdateAppInstanceExpireProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAppInstanceExpireMutation,
    UpdateAppInstanceExpireMutationVariables
  >;
} &
  TChildProps;
export function withUpdateAppInstanceExpire<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAppInstanceExpireMutation,
    UpdateAppInstanceExpireMutationVariables,
    UpdateAppInstanceExpireProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAppInstanceExpireMutation,
    UpdateAppInstanceExpireMutationVariables,
    UpdateAppInstanceExpireProps<TChildProps, TDataName>
  >(UpdateAppInstanceExpireDocument, {
    alias: "updateAppInstanceExpire",
    ...operationOptions,
  });
}

/**
 * __useUpdateAppInstanceExpireMutation__
 *
 * To run a mutation, you first call `useUpdateAppInstanceExpireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppInstanceExpireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppInstanceExpireMutation, { data, loading, error }] = useUpdateAppInstanceExpireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppInstanceExpireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppInstanceExpireMutation,
    UpdateAppInstanceExpireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppInstanceExpireMutation,
    UpdateAppInstanceExpireMutationVariables
  >(UpdateAppInstanceExpireDocument, options);
}
export type UpdateAppInstanceExpireMutationHookResult = ReturnType<
  typeof useUpdateAppInstanceExpireMutation
>;
export type UpdateAppInstanceExpireMutationResult = Apollo.MutationResult<UpdateAppInstanceExpireMutation>;
export type UpdateAppInstanceExpireMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppInstanceExpireMutation,
  UpdateAppInstanceExpireMutationVariables
>;
export const UpdateAppInstanceExpireByIdsDocument = gql`
  mutation updateAppInstanceExpireByIds(
    $input: UpdateAppInstanceExpireByIdsInput!
  ) {
    updateAppInstanceExpireByIds(input: $input) {
      appInstances {
        ...AppInstanceByIdForContent
      }
    }
  }
  ${AppInstanceByIdForContentFragmentDoc}
`;
export type UpdateAppInstanceExpireByIdsMutationFn = Apollo.MutationFunction<
  UpdateAppInstanceExpireByIdsMutation,
  UpdateAppInstanceExpireByIdsMutationVariables
>;
export type UpdateAppInstanceExpireByIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAppInstanceExpireByIdsMutation,
    UpdateAppInstanceExpireByIdsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateAppInstanceExpireByIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAppInstanceExpireByIdsMutation,
    UpdateAppInstanceExpireByIdsMutationVariables,
    UpdateAppInstanceExpireByIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAppInstanceExpireByIdsMutation,
    UpdateAppInstanceExpireByIdsMutationVariables,
    UpdateAppInstanceExpireByIdsProps<TChildProps, TDataName>
  >(UpdateAppInstanceExpireByIdsDocument, {
    alias: "updateAppInstanceExpireByIds",
    ...operationOptions,
  });
}

/**
 * __useUpdateAppInstanceExpireByIdsMutation__
 *
 * To run a mutation, you first call `useUpdateAppInstanceExpireByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppInstanceExpireByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppInstanceExpireByIdsMutation, { data, loading, error }] = useUpdateAppInstanceExpireByIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppInstanceExpireByIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppInstanceExpireByIdsMutation,
    UpdateAppInstanceExpireByIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppInstanceExpireByIdsMutation,
    UpdateAppInstanceExpireByIdsMutationVariables
  >(UpdateAppInstanceExpireByIdsDocument, options);
}
export type UpdateAppInstanceExpireByIdsMutationHookResult = ReturnType<
  typeof useUpdateAppInstanceExpireByIdsMutation
>;
export type UpdateAppInstanceExpireByIdsMutationResult = Apollo.MutationResult<UpdateAppInstanceExpireByIdsMutation>;
export type UpdateAppInstanceExpireByIdsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppInstanceExpireByIdsMutation,
  UpdateAppInstanceExpireByIdsMutationVariables
>;
export const UpdateAppInstanceTagsDocument = gql`
  mutation updateAppInstanceTags($input: UpdateAppInstanceTagsInput!) {
    updateAppInstanceTags(input: $input) {
      appInstance {
        __typename
        id
        spaceId
        name
        config
        version
        state
        isTemplate
        tags
        fileByThumbnailFileId {
          ...File
        }
      }
    }
  }
  ${FileFragmentDoc}
`;
export type UpdateAppInstanceTagsMutationFn = Apollo.MutationFunction<
  UpdateAppInstanceTagsMutation,
  UpdateAppInstanceTagsMutationVariables
>;
export type UpdateAppInstanceTagsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAppInstanceTagsMutation,
    UpdateAppInstanceTagsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateAppInstanceTags<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAppInstanceTagsMutation,
    UpdateAppInstanceTagsMutationVariables,
    UpdateAppInstanceTagsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAppInstanceTagsMutation,
    UpdateAppInstanceTagsMutationVariables,
    UpdateAppInstanceTagsProps<TChildProps, TDataName>
  >(UpdateAppInstanceTagsDocument, {
    alias: "updateAppInstanceTags",
    ...operationOptions,
  });
}

/**
 * __useUpdateAppInstanceTagsMutation__
 *
 * To run a mutation, you first call `useUpdateAppInstanceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppInstanceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppInstanceTagsMutation, { data, loading, error }] = useUpdateAppInstanceTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppInstanceTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppInstanceTagsMutation,
    UpdateAppInstanceTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppInstanceTagsMutation,
    UpdateAppInstanceTagsMutationVariables
  >(UpdateAppInstanceTagsDocument, options);
}
export type UpdateAppInstanceTagsMutationHookResult = ReturnType<
  typeof useUpdateAppInstanceTagsMutation
>;
export type UpdateAppInstanceTagsMutationResult = Apollo.MutationResult<UpdateAppInstanceTagsMutation>;
export type UpdateAppInstanceTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppInstanceTagsMutation,
  UpdateAppInstanceTagsMutationVariables
>;
export const AllAppsDocument = gql`
  query allApps {
    allApps {
      nodes {
        ...App
        appInstallsByAppId {
          nodes {
            ...AppInstall
          }
        }
        appVersionByAppId {
          ...AppVersion
        }
      }
    }
  }
  ${AppFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
`;
export type AllAppsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllAppsQuery,
    AllAppsQueryVariables
  >;
} &
  TChildProps;
export function withAllApps<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllAppsQuery,
    AllAppsQueryVariables,
    AllAppsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllAppsQuery,
    AllAppsQueryVariables,
    AllAppsProps<TChildProps, TDataName>
  >(AllAppsDocument, {
    alias: "allApps",
    ...operationOptions,
  });
}

/**
 * __useAllAppsQuery__
 *
 * To run a query within a React component, call `useAllAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAppsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllAppsQuery, AllAppsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllAppsQuery, AllAppsQueryVariables>(
    AllAppsDocument,
    options
  );
}
export function useAllAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllAppsQuery, AllAppsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllAppsQuery, AllAppsQueryVariables>(
    AllAppsDocument,
    options
  );
}
export type AllAppsQueryHookResult = ReturnType<typeof useAllAppsQuery>;
export type AllAppsLazyQueryHookResult = ReturnType<typeof useAllAppsLazyQuery>;
export type AllAppsQueryResult = Apollo.QueryResult<
  AllAppsQuery,
  AllAppsQueryVariables
>;
export function refetchAllAppsQuery(variables?: AllAppsQueryVariables) {
  return { query: AllAppsDocument, variables: variables };
}
export const AppByScrnDocument = gql`
  query appByScrn($scrn: String!) {
    appByScrn(scrn: $scrn) {
      ...App
      appVersionByAppId {
        ...AppVersion
      }
    }
  }
  ${AppFragmentDoc}
  ${AppVersionFragmentDoc}
`;
export type AppByScrnProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppByScrnQuery,
    AppByScrnQueryVariables
  >;
} &
  TChildProps;
export function withAppByScrn<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppByScrnQuery,
    AppByScrnQueryVariables,
    AppByScrnProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppByScrnQuery,
    AppByScrnQueryVariables,
    AppByScrnProps<TChildProps, TDataName>
  >(AppByScrnDocument, {
    alias: "appByScrn",
    ...operationOptions,
  });
}

/**
 * __useAppByScrnQuery__
 *
 * To run a query within a React component, call `useAppByScrnQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppByScrnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppByScrnQuery({
 *   variables: {
 *      scrn: // value for 'scrn'
 *   },
 * });
 */
export function useAppByScrnQuery(
  baseOptions: Apollo.QueryHookOptions<AppByScrnQuery, AppByScrnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppByScrnQuery, AppByScrnQueryVariables>(
    AppByScrnDocument,
    options
  );
}
export function useAppByScrnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppByScrnQuery,
    AppByScrnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppByScrnQuery, AppByScrnQueryVariables>(
    AppByScrnDocument,
    options
  );
}
export type AppByScrnQueryHookResult = ReturnType<typeof useAppByScrnQuery>;
export type AppByScrnLazyQueryHookResult = ReturnType<
  typeof useAppByScrnLazyQuery
>;
export type AppByScrnQueryResult = Apollo.QueryResult<
  AppByScrnQuery,
  AppByScrnQueryVariables
>;
export function refetchAppByScrnQuery(variables?: AppByScrnQueryVariables) {
  return { query: AppByScrnDocument, variables: variables };
}
export const InstallAppForSpaceDocument = gql`
  mutation installAppForSpace($input: InstallAppForSpaceInput!) {
    installAppForSpace(input: $input) {
      appInstall {
        __typename
        appId
        id
        orgId
        createdAt
        createdBy
        updatedAt
        updatedBy
        spaceId
      }
    }
  }
`;
export type InstallAppForSpaceMutationFn = Apollo.MutationFunction<
  InstallAppForSpaceMutation,
  InstallAppForSpaceMutationVariables
>;
export type InstallAppForSpaceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    InstallAppForSpaceMutation,
    InstallAppForSpaceMutationVariables
  >;
} &
  TChildProps;
export function withInstallAppForSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    InstallAppForSpaceMutation,
    InstallAppForSpaceMutationVariables,
    InstallAppForSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    InstallAppForSpaceMutation,
    InstallAppForSpaceMutationVariables,
    InstallAppForSpaceProps<TChildProps, TDataName>
  >(InstallAppForSpaceDocument, {
    alias: "installAppForSpace",
    ...operationOptions,
  });
}

/**
 * __useInstallAppForSpaceMutation__
 *
 * To run a mutation, you first call `useInstallAppForSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallAppForSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installAppForSpaceMutation, { data, loading, error }] = useInstallAppForSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstallAppForSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstallAppForSpaceMutation,
    InstallAppForSpaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstallAppForSpaceMutation,
    InstallAppForSpaceMutationVariables
  >(InstallAppForSpaceDocument, options);
}
export type InstallAppForSpaceMutationHookResult = ReturnType<
  typeof useInstallAppForSpaceMutation
>;
export type InstallAppForSpaceMutationResult = Apollo.MutationResult<InstallAppForSpaceMutation>;
export type InstallAppForSpaceMutationOptions = Apollo.BaseMutationOptions<
  InstallAppForSpaceMutation,
  InstallAppForSpaceMutationVariables
>;
export const AllSearchAppInstanceIdsDocument = gql`
  query allSearchAppInstanceIds(
    $query: String
    $appId: UUID!
    $spaceId: UUID!
  ) {
    searchAppInstance(
      query: $query
      spaceId: $spaceId
      filter: { spaceId: { equalTo: $spaceId }, appId: { equalTo: $appId } }
    ) {
      nodes {
        ...AppInstanceForMultiSelect
      }
      totalCount
    }
  }
  ${AppInstanceForMultiSelectFragmentDoc}
`;
export type AllSearchAppInstanceIdsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllSearchAppInstanceIdsQuery,
    AllSearchAppInstanceIdsQueryVariables
  >;
} &
  TChildProps;
export function withAllSearchAppInstanceIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSearchAppInstanceIdsQuery,
    AllSearchAppInstanceIdsQueryVariables,
    AllSearchAppInstanceIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSearchAppInstanceIdsQuery,
    AllSearchAppInstanceIdsQueryVariables,
    AllSearchAppInstanceIdsProps<TChildProps, TDataName>
  >(AllSearchAppInstanceIdsDocument, {
    alias: "allSearchAppInstanceIds",
    ...operationOptions,
  });
}

/**
 * __useAllSearchAppInstanceIdsQuery__
 *
 * To run a query within a React component, call `useAllSearchAppInstanceIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSearchAppInstanceIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSearchAppInstanceIdsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllSearchAppInstanceIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllSearchAppInstanceIdsQuery,
    AllSearchAppInstanceIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllSearchAppInstanceIdsQuery,
    AllSearchAppInstanceIdsQueryVariables
  >(AllSearchAppInstanceIdsDocument, options);
}
export function useAllSearchAppInstanceIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllSearchAppInstanceIdsQuery,
    AllSearchAppInstanceIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllSearchAppInstanceIdsQuery,
    AllSearchAppInstanceIdsQueryVariables
  >(AllSearchAppInstanceIdsDocument, options);
}
export type AllSearchAppInstanceIdsQueryHookResult = ReturnType<
  typeof useAllSearchAppInstanceIdsQuery
>;
export type AllSearchAppInstanceIdsLazyQueryHookResult = ReturnType<
  typeof useAllSearchAppInstanceIdsLazyQuery
>;
export type AllSearchAppInstanceIdsQueryResult = Apollo.QueryResult<
  AllSearchAppInstanceIdsQuery,
  AllSearchAppInstanceIdsQueryVariables
>;
export function refetchAllSearchAppInstanceIdsQuery(
  variables?: AllSearchAppInstanceIdsQueryVariables
) {
  return { query: AllSearchAppInstanceIdsDocument, variables: variables };
}
export const AvailableAppInstancesBySpaceIdDocument = gql`
  query availableAppInstancesBySpaceId(
    $first: Int
    $spaceId: UUID!
    $endCursor: Cursor
    $appId: UUID
  ) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableAppInstancesBySpaceId(
        first: $first
        after: $endCursor
        orderBy: [CREATED_AT_DESC, ID_DESC]
        filter: { appId: { notEqualTo: $appId } }
      ) {
        nodes {
          ...AvailableAppInstance
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type AvailableAppInstancesBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailableAppInstancesBySpaceIdQuery,
    AvailableAppInstancesBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withAvailableAppInstancesBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailableAppInstancesBySpaceIdQuery,
    AvailableAppInstancesBySpaceIdQueryVariables,
    AvailableAppInstancesBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailableAppInstancesBySpaceIdQuery,
    AvailableAppInstancesBySpaceIdQueryVariables,
    AvailableAppInstancesBySpaceIdProps<TChildProps, TDataName>
  >(AvailableAppInstancesBySpaceIdDocument, {
    alias: "availableAppInstancesBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useAvailableAppInstancesBySpaceIdQuery__
 *
 * To run a query within a React component, call `useAvailableAppInstancesBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableAppInstancesBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableAppInstancesBySpaceIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *      spaceId: // value for 'spaceId'
 *      endCursor: // value for 'endCursor'
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useAvailableAppInstancesBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableAppInstancesBySpaceIdQuery,
    AvailableAppInstancesBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableAppInstancesBySpaceIdQuery,
    AvailableAppInstancesBySpaceIdQueryVariables
  >(AvailableAppInstancesBySpaceIdDocument, options);
}
export function useAvailableAppInstancesBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableAppInstancesBySpaceIdQuery,
    AvailableAppInstancesBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableAppInstancesBySpaceIdQuery,
    AvailableAppInstancesBySpaceIdQueryVariables
  >(AvailableAppInstancesBySpaceIdDocument, options);
}
export type AvailableAppInstancesBySpaceIdQueryHookResult = ReturnType<
  typeof useAvailableAppInstancesBySpaceIdQuery
>;
export type AvailableAppInstancesBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useAvailableAppInstancesBySpaceIdLazyQuery
>;
export type AvailableAppInstancesBySpaceIdQueryResult = Apollo.QueryResult<
  AvailableAppInstancesBySpaceIdQuery,
  AvailableAppInstancesBySpaceIdQueryVariables
>;
export function refetchAvailableAppInstancesBySpaceIdQuery(
  variables?: AvailableAppInstancesBySpaceIdQueryVariables
) {
  return {
    query: AvailableAppInstancesBySpaceIdDocument,
    variables: variables,
  };
}
export const AvailableAppInstallsDocument = gql`
  query availableAppInstalls($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableAppInstallsBySpaceId {
        nodes {
          ...AvailableAppInstallsWithAvailableAppInstancesByAppInstallIdAndSpace
        }
        totalCount
      }
    }
  }
  ${AvailableAppInstallsWithAvailableAppInstancesByAppInstallIdAndSpaceFragmentDoc}
`;
export type AvailableAppInstallsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailableAppInstallsQuery,
    AvailableAppInstallsQueryVariables
  >;
} &
  TChildProps;
export function withAvailableAppInstalls<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailableAppInstallsQuery,
    AvailableAppInstallsQueryVariables,
    AvailableAppInstallsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailableAppInstallsQuery,
    AvailableAppInstallsQueryVariables,
    AvailableAppInstallsProps<TChildProps, TDataName>
  >(AvailableAppInstallsDocument, {
    alias: "availableAppInstalls",
    ...operationOptions,
  });
}

/**
 * __useAvailableAppInstallsQuery__
 *
 * To run a query within a React component, call `useAvailableAppInstallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableAppInstallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableAppInstallsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAvailableAppInstallsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableAppInstallsQuery,
    AvailableAppInstallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableAppInstallsQuery,
    AvailableAppInstallsQueryVariables
  >(AvailableAppInstallsDocument, options);
}
export function useAvailableAppInstallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableAppInstallsQuery,
    AvailableAppInstallsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableAppInstallsQuery,
    AvailableAppInstallsQueryVariables
  >(AvailableAppInstallsDocument, options);
}
export type AvailableAppInstallsQueryHookResult = ReturnType<
  typeof useAvailableAppInstallsQuery
>;
export type AvailableAppInstallsLazyQueryHookResult = ReturnType<
  typeof useAvailableAppInstallsLazyQuery
>;
export type AvailableAppInstallsQueryResult = Apollo.QueryResult<
  AvailableAppInstallsQuery,
  AvailableAppInstallsQueryVariables
>;
export function refetchAvailableAppInstallsQuery(
  variables?: AvailableAppInstallsQueryVariables
) {
  return { query: AvailableAppInstallsDocument, variables: variables };
}
export const AvailableAppInstallsBySpaceIdDocument = gql`
  query availableAppInstallsBySpaceId($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableAppInstallsBySpaceId {
        nodes {
          __typename
          id
          appId
          appByAppId {
            __typename
            id
            name
            scrn
            iconUrl
            categories
          }
          appInstancesByAppInstallId {
            totalCount
          }
        }
      }
    }
  }
`;
export type AvailableAppInstallsBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailableAppInstallsBySpaceIdQuery,
    AvailableAppInstallsBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withAvailableAppInstallsBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailableAppInstallsBySpaceIdQuery,
    AvailableAppInstallsBySpaceIdQueryVariables,
    AvailableAppInstallsBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailableAppInstallsBySpaceIdQuery,
    AvailableAppInstallsBySpaceIdQueryVariables,
    AvailableAppInstallsBySpaceIdProps<TChildProps, TDataName>
  >(AvailableAppInstallsBySpaceIdDocument, {
    alias: "availableAppInstallsBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useAvailableAppInstallsBySpaceIdQuery__
 *
 * To run a query within a React component, call `useAvailableAppInstallsBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableAppInstallsBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableAppInstallsBySpaceIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAvailableAppInstallsBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableAppInstallsBySpaceIdQuery,
    AvailableAppInstallsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableAppInstallsBySpaceIdQuery,
    AvailableAppInstallsBySpaceIdQueryVariables
  >(AvailableAppInstallsBySpaceIdDocument, options);
}
export function useAvailableAppInstallsBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableAppInstallsBySpaceIdQuery,
    AvailableAppInstallsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableAppInstallsBySpaceIdQuery,
    AvailableAppInstallsBySpaceIdQueryVariables
  >(AvailableAppInstallsBySpaceIdDocument, options);
}
export type AvailableAppInstallsBySpaceIdQueryHookResult = ReturnType<
  typeof useAvailableAppInstallsBySpaceIdQuery
>;
export type AvailableAppInstallsBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useAvailableAppInstallsBySpaceIdLazyQuery
>;
export type AvailableAppInstallsBySpaceIdQueryResult = Apollo.QueryResult<
  AvailableAppInstallsBySpaceIdQuery,
  AvailableAppInstallsBySpaceIdQueryVariables
>;
export function refetchAvailableAppInstallsBySpaceIdQuery(
  variables?: AvailableAppInstallsBySpaceIdQueryVariables
) {
  return { query: AvailableAppInstallsBySpaceIdDocument, variables: variables };
}
export const SearchAppInstanceDocument = gql`
  query searchAppInstance(
    $query: String
    $first: Int
    $endCursor: Cursor
    $spaceId: UUID!
    $filter: AppInstanceFilter
  ) {
    searchAppInstance(
      query: $query
      first: $first
      after: $endCursor
      orderBy: [NAME_ASC, ID_DESC]
      spaceId: $spaceId
      filter: $filter
    ) {
      nodes {
        ...AvailableAppInstance
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type SearchAppInstanceProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchAppInstanceQuery,
    SearchAppInstanceQueryVariables
  >;
} &
  TChildProps;
export function withSearchAppInstance<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchAppInstanceQuery,
    SearchAppInstanceQueryVariables,
    SearchAppInstanceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchAppInstanceQuery,
    SearchAppInstanceQueryVariables,
    SearchAppInstanceProps<TChildProps, TDataName>
  >(SearchAppInstanceDocument, {
    alias: "searchAppInstance",
    ...operationOptions,
  });
}

/**
 * __useSearchAppInstanceQuery__
 *
 * To run a query within a React component, call `useSearchAppInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAppInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAppInstanceQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *      spaceId: // value for 'spaceId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchAppInstanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAppInstanceQuery,
    SearchAppInstanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAppInstanceQuery,
    SearchAppInstanceQueryVariables
  >(SearchAppInstanceDocument, options);
}
export function useSearchAppInstanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAppInstanceQuery,
    SearchAppInstanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAppInstanceQuery,
    SearchAppInstanceQueryVariables
  >(SearchAppInstanceDocument, options);
}
export type SearchAppInstanceQueryHookResult = ReturnType<
  typeof useSearchAppInstanceQuery
>;
export type SearchAppInstanceLazyQueryHookResult = ReturnType<
  typeof useSearchAppInstanceLazyQuery
>;
export type SearchAppInstanceQueryResult = Apollo.QueryResult<
  SearchAppInstanceQuery,
  SearchAppInstanceQueryVariables
>;
export function refetchSearchAppInstanceQuery(
  variables?: SearchAppInstanceQueryVariables
) {
  return { query: SearchAppInstanceDocument, variables: variables };
}
export const SearchAppInstanceSpecificAppIdDocument = gql`
  query searchAppInstanceSpecificAppId(
    $query: String
    $first: Int
    $endCursor: Cursor
    $spaceId: UUID!
    $condition: AppInstanceCondition
    $filter: AppInstanceFilter
    $appId: UUID!
  ) {
    searchAppInstanceSpecificAppId(
      query: $query
      first: $first
      after: $endCursor
      orderBy: [NAME_ASC, ID_DESC]
      spaceId: $spaceId
      condition: $condition
      filter: $filter
      appId: $appId
    ) {
      nodes {
        ...AvailableAppInstance
        associationsByAppInstanceIdAndSpaceId(spaceId: $spaceId) {
          nodes {
            ...PlaylistAndChannelAssociations
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${AvailableAppInstanceFragmentDoc}
  ${PlaylistAndChannelAssociationsFragmentDoc}
`;
export type SearchAppInstanceSpecificAppIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchAppInstanceSpecificAppIdQuery,
    SearchAppInstanceSpecificAppIdQueryVariables
  >;
} &
  TChildProps;
export function withSearchAppInstanceSpecificAppId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchAppInstanceSpecificAppIdQuery,
    SearchAppInstanceSpecificAppIdQueryVariables,
    SearchAppInstanceSpecificAppIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchAppInstanceSpecificAppIdQuery,
    SearchAppInstanceSpecificAppIdQueryVariables,
    SearchAppInstanceSpecificAppIdProps<TChildProps, TDataName>
  >(SearchAppInstanceSpecificAppIdDocument, {
    alias: "searchAppInstanceSpecificAppId",
    ...operationOptions,
  });
}

/**
 * __useSearchAppInstanceSpecificAppIdQuery__
 *
 * To run a query within a React component, call `useSearchAppInstanceSpecificAppIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAppInstanceSpecificAppIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAppInstanceSpecificAppIdQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *      spaceId: // value for 'spaceId'
 *      condition: // value for 'condition'
 *      filter: // value for 'filter'
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useSearchAppInstanceSpecificAppIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAppInstanceSpecificAppIdQuery,
    SearchAppInstanceSpecificAppIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAppInstanceSpecificAppIdQuery,
    SearchAppInstanceSpecificAppIdQueryVariables
  >(SearchAppInstanceSpecificAppIdDocument, options);
}
export function useSearchAppInstanceSpecificAppIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAppInstanceSpecificAppIdQuery,
    SearchAppInstanceSpecificAppIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAppInstanceSpecificAppIdQuery,
    SearchAppInstanceSpecificAppIdQueryVariables
  >(SearchAppInstanceSpecificAppIdDocument, options);
}
export type SearchAppInstanceSpecificAppIdQueryHookResult = ReturnType<
  typeof useSearchAppInstanceSpecificAppIdQuery
>;
export type SearchAppInstanceSpecificAppIdLazyQueryHookResult = ReturnType<
  typeof useSearchAppInstanceSpecificAppIdLazyQuery
>;
export type SearchAppInstanceSpecificAppIdQueryResult = Apollo.QueryResult<
  SearchAppInstanceSpecificAppIdQuery,
  SearchAppInstanceSpecificAppIdQueryVariables
>;
export function refetchSearchAppInstanceSpecificAppIdQuery(
  variables?: SearchAppInstanceSpecificAppIdQueryVariables
) {
  return {
    query: SearchAppInstanceSpecificAppIdDocument,
    variables: variables,
  };
}
export const UninstallSpaceAppDocument = gql`
  mutation uninstallSpaceApp($input: UninstallSpaceAppInput!) {
    uninstallSpaceApp(input: $input) {
      appInstall {
        ...AppInstall
      }
    }
  }
  ${AppInstallFragmentDoc}
`;
export type UninstallSpaceAppMutationFn = Apollo.MutationFunction<
  UninstallSpaceAppMutation,
  UninstallSpaceAppMutationVariables
>;
export type UninstallSpaceAppProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UninstallSpaceAppMutation,
    UninstallSpaceAppMutationVariables
  >;
} &
  TChildProps;
export function withUninstallSpaceApp<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UninstallSpaceAppMutation,
    UninstallSpaceAppMutationVariables,
    UninstallSpaceAppProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UninstallSpaceAppMutation,
    UninstallSpaceAppMutationVariables,
    UninstallSpaceAppProps<TChildProps, TDataName>
  >(UninstallSpaceAppDocument, {
    alias: "uninstallSpaceApp",
    ...operationOptions,
  });
}

/**
 * __useUninstallSpaceAppMutation__
 *
 * To run a mutation, you first call `useUninstallSpaceAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallSpaceAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallSpaceAppMutation, { data, loading, error }] = useUninstallSpaceAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUninstallSpaceAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninstallSpaceAppMutation,
    UninstallSpaceAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UninstallSpaceAppMutation,
    UninstallSpaceAppMutationVariables
  >(UninstallSpaceAppDocument, options);
}
export type UninstallSpaceAppMutationHookResult = ReturnType<
  typeof useUninstallSpaceAppMutation
>;
export type UninstallSpaceAppMutationResult = Apollo.MutationResult<UninstallSpaceAppMutation>;
export type UninstallSpaceAppMutationOptions = Apollo.BaseMutationOptions<
  UninstallSpaceAppMutation,
  UninstallSpaceAppMutationVariables
>;
export const AllAppInstanceIdsDocument = gql`
  query allAppInstanceIds(
    $appId: UUID!
    $spaceId: UUID!
    $tags: [String] = []
  ) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableAppInstancesBySpaceIdAndAppId(
        appId: $appId
        condition: { isTemplate: false }
        filter: { spaceId: { equalTo: $spaceId }, tags: { contains: $tags } }
      ) {
        nodes {
          ...AppInstanceForMultiSelect
        }
        totalCount
      }
    }
  }
  ${AppInstanceForMultiSelectFragmentDoc}
`;
export type AllAppInstanceIdsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllAppInstanceIdsQuery,
    AllAppInstanceIdsQueryVariables
  >;
} &
  TChildProps;
export function withAllAppInstanceIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllAppInstanceIdsQuery,
    AllAppInstanceIdsQueryVariables,
    AllAppInstanceIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllAppInstanceIdsQuery,
    AllAppInstanceIdsQueryVariables,
    AllAppInstanceIdsProps<TChildProps, TDataName>
  >(AllAppInstanceIdsDocument, {
    alias: "allAppInstanceIds",
    ...operationOptions,
  });
}

/**
 * __useAllAppInstanceIdsQuery__
 *
 * To run a query within a React component, call `useAllAppInstanceIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppInstanceIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppInstanceIdsQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useAllAppInstanceIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllAppInstanceIdsQuery,
    AllAppInstanceIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllAppInstanceIdsQuery,
    AllAppInstanceIdsQueryVariables
  >(AllAppInstanceIdsDocument, options);
}
export function useAllAppInstanceIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAppInstanceIdsQuery,
    AllAppInstanceIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllAppInstanceIdsQuery,
    AllAppInstanceIdsQueryVariables
  >(AllAppInstanceIdsDocument, options);
}
export type AllAppInstanceIdsQueryHookResult = ReturnType<
  typeof useAllAppInstanceIdsQuery
>;
export type AllAppInstanceIdsLazyQueryHookResult = ReturnType<
  typeof useAllAppInstanceIdsLazyQuery
>;
export type AllAppInstanceIdsQueryResult = Apollo.QueryResult<
  AllAppInstanceIdsQuery,
  AllAppInstanceIdsQueryVariables
>;
export function refetchAllAppInstanceIdsQuery(
  variables?: AllAppInstanceIdsQueryVariables
) {
  return { query: AllAppInstanceIdsDocument, variables: variables };
}
export const AllAppInstancesDocument = gql`
  query allAppInstances(
    $appId: UUID!
    $spaceId: UUID!
    $first: Int
    $endCursor: Cursor
    $orderBy: [AppInstancesOrderBy!]
    $filter: AppInstanceFilter
  ) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableAppInstancesBySpaceIdAndAppId(
        first: $first
        after: $endCursor
        appId: $appId
        orderBy: $orderBy
        condition: { isTemplate: false }
        filter: $filter
      ) {
        nodes {
          ...AvailableAppInstance
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type AllAppInstancesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllAppInstancesQuery,
    AllAppInstancesQueryVariables
  >;
} &
  TChildProps;
export function withAllAppInstances<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllAppInstancesQuery,
    AllAppInstancesQueryVariables,
    AllAppInstancesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllAppInstancesQuery,
    AllAppInstancesQueryVariables,
    AllAppInstancesProps<TChildProps, TDataName>
  >(AllAppInstancesDocument, {
    alias: "allAppInstances",
    ...operationOptions,
  });
}

/**
 * __useAllAppInstancesQuery__
 *
 * To run a query within a React component, call `useAllAppInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppInstancesQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllAppInstancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllAppInstancesQuery,
    AllAppInstancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllAppInstancesQuery, AllAppInstancesQueryVariables>(
    AllAppInstancesDocument,
    options
  );
}
export function useAllAppInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAppInstancesQuery,
    AllAppInstancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllAppInstancesQuery,
    AllAppInstancesQueryVariables
  >(AllAppInstancesDocument, options);
}
export type AllAppInstancesQueryHookResult = ReturnType<
  typeof useAllAppInstancesQuery
>;
export type AllAppInstancesLazyQueryHookResult = ReturnType<
  typeof useAllAppInstancesLazyQuery
>;
export type AllAppInstancesQueryResult = Apollo.QueryResult<
  AllAppInstancesQuery,
  AllAppInstancesQueryVariables
>;
export function refetchAllAppInstancesQuery(
  variables?: AllAppInstancesQueryVariables
) {
  return { query: AllAppInstancesDocument, variables: variables };
}
export const AllAppInstancesExtendedAssociationDocument = gql`
  query allAppInstancesExtendedAssociation(
    $appId: UUID!
    $spaceId: UUID!
    $first: Int
    $endCursor: Cursor
    $orderBy: [AppInstancesOrderBy!]
    $filter: AppInstanceFilter
  ) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableAppInstancesBySpaceIdAndAppId(
        first: $first
        after: $endCursor
        appId: $appId
        orderBy: $orderBy
        condition: { isTemplate: false }
        filter: $filter
      ) {
        nodes {
          ...AvailableAppInstance
          associationsByAppInstanceIdAndSpaceId(spaceId: $spaceId) {
            nodes {
              ...PlaylistAndChannelAssociations
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
  ${PlaylistAndChannelAssociationsFragmentDoc}
`;
export type AllAppInstancesExtendedAssociationProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllAppInstancesExtendedAssociationQuery,
    AllAppInstancesExtendedAssociationQueryVariables
  >;
} &
  TChildProps;
export function withAllAppInstancesExtendedAssociation<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllAppInstancesExtendedAssociationQuery,
    AllAppInstancesExtendedAssociationQueryVariables,
    AllAppInstancesExtendedAssociationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllAppInstancesExtendedAssociationQuery,
    AllAppInstancesExtendedAssociationQueryVariables,
    AllAppInstancesExtendedAssociationProps<TChildProps, TDataName>
  >(AllAppInstancesExtendedAssociationDocument, {
    alias: "allAppInstancesExtendedAssociation",
    ...operationOptions,
  });
}

/**
 * __useAllAppInstancesExtendedAssociationQuery__
 *
 * To run a query within a React component, call `useAllAppInstancesExtendedAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppInstancesExtendedAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppInstancesExtendedAssociationQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllAppInstancesExtendedAssociationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllAppInstancesExtendedAssociationQuery,
    AllAppInstancesExtendedAssociationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllAppInstancesExtendedAssociationQuery,
    AllAppInstancesExtendedAssociationQueryVariables
  >(AllAppInstancesExtendedAssociationDocument, options);
}
export function useAllAppInstancesExtendedAssociationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAppInstancesExtendedAssociationQuery,
    AllAppInstancesExtendedAssociationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllAppInstancesExtendedAssociationQuery,
    AllAppInstancesExtendedAssociationQueryVariables
  >(AllAppInstancesExtendedAssociationDocument, options);
}
export type AllAppInstancesExtendedAssociationQueryHookResult = ReturnType<
  typeof useAllAppInstancesExtendedAssociationQuery
>;
export type AllAppInstancesExtendedAssociationLazyQueryHookResult = ReturnType<
  typeof useAllAppInstancesExtendedAssociationLazyQuery
>;
export type AllAppInstancesExtendedAssociationQueryResult = Apollo.QueryResult<
  AllAppInstancesExtendedAssociationQuery,
  AllAppInstancesExtendedAssociationQueryVariables
>;
export function refetchAllAppInstancesExtendedAssociationQuery(
  variables?: AllAppInstancesExtendedAssociationQueryVariables
) {
  return {
    query: AllAppInstancesExtendedAssociationDocument,
    variables: variables,
  };
}
export const AllCommonAppInstanceTemplatesDocument = gql`
  query allCommonAppInstanceTemplates {
    allCommonAppInstanceTemplates(
      filter: { isDeprecated: { equalTo: false } }
    ) {
      nodes {
        id
        name
        version
        config
        state
        tags
        status
        thumbnailFileId
        isPublished
        isDeprecated
        sourceOrgId
        sourceAppInstanceId
        commonFileByThumbnailFileId {
          ...CommonFileItem
        }
        appInstanceBySourceAppInstanceId {
          ...AppInstance
          fileByThumbnailFileId {
            ...FileListItem
          }
        }
        appInstanceByTargetCommonAppInstanceTemplateId {
          ...AppInstance
          fileByThumbnailFileId {
            ...FileListItem
          }
        }
      }
      totalCount
    }
  }
  ${CommonFileItemFragmentDoc}
  ${AppInstanceFragmentDoc}
  ${FileListItemFragmentDoc}
`;
export type AllCommonAppInstanceTemplatesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllCommonAppInstanceTemplatesQuery,
    AllCommonAppInstanceTemplatesQueryVariables
  >;
} &
  TChildProps;
export function withAllCommonAppInstanceTemplates<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllCommonAppInstanceTemplatesQuery,
    AllCommonAppInstanceTemplatesQueryVariables,
    AllCommonAppInstanceTemplatesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllCommonAppInstanceTemplatesQuery,
    AllCommonAppInstanceTemplatesQueryVariables,
    AllCommonAppInstanceTemplatesProps<TChildProps, TDataName>
  >(AllCommonAppInstanceTemplatesDocument, {
    alias: "allCommonAppInstanceTemplates",
    ...operationOptions,
  });
}

/**
 * __useAllCommonAppInstanceTemplatesQuery__
 *
 * To run a query within a React component, call `useAllCommonAppInstanceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommonAppInstanceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommonAppInstanceTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCommonAppInstanceTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCommonAppInstanceTemplatesQuery,
    AllCommonAppInstanceTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCommonAppInstanceTemplatesQuery,
    AllCommonAppInstanceTemplatesQueryVariables
  >(AllCommonAppInstanceTemplatesDocument, options);
}
export function useAllCommonAppInstanceTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCommonAppInstanceTemplatesQuery,
    AllCommonAppInstanceTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCommonAppInstanceTemplatesQuery,
    AllCommonAppInstanceTemplatesQueryVariables
  >(AllCommonAppInstanceTemplatesDocument, options);
}
export type AllCommonAppInstanceTemplatesQueryHookResult = ReturnType<
  typeof useAllCommonAppInstanceTemplatesQuery
>;
export type AllCommonAppInstanceTemplatesLazyQueryHookResult = ReturnType<
  typeof useAllCommonAppInstanceTemplatesLazyQuery
>;
export type AllCommonAppInstanceTemplatesQueryResult = Apollo.QueryResult<
  AllCommonAppInstanceTemplatesQuery,
  AllCommonAppInstanceTemplatesQueryVariables
>;
export function refetchAllCommonAppInstanceTemplatesQuery(
  variables?: AllCommonAppInstanceTemplatesQueryVariables
) {
  return { query: AllCommonAppInstanceTemplatesDocument, variables: variables };
}
export const GetAppInstanceTemplatesDocument = gql`
  query getAppInstanceTemplates(
    $appId: UUID!
    $spaceId: UUID!
    $endCursor: Cursor
    $filter: AppInstanceFilter
  ) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableAppInstancesBySpaceIdAndAppId(
        first: 40
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        appId: $appId
        condition: { isTemplate: true }
        filter: $filter
      ) {
        nodes {
          ...AvailableAppInstance
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type GetAppInstanceTemplatesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAppInstanceTemplatesQuery,
    GetAppInstanceTemplatesQueryVariables
  >;
} &
  TChildProps;
export function withGetAppInstanceTemplates<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAppInstanceTemplatesQuery,
    GetAppInstanceTemplatesQueryVariables,
    GetAppInstanceTemplatesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAppInstanceTemplatesQuery,
    GetAppInstanceTemplatesQueryVariables,
    GetAppInstanceTemplatesProps<TChildProps, TDataName>
  >(GetAppInstanceTemplatesDocument, {
    alias: "getAppInstanceTemplates",
    ...operationOptions,
  });
}

/**
 * __useGetAppInstanceTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAppInstanceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppInstanceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppInstanceTemplatesQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *      endCursor: // value for 'endCursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAppInstanceTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppInstanceTemplatesQuery,
    GetAppInstanceTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppInstanceTemplatesQuery,
    GetAppInstanceTemplatesQueryVariables
  >(GetAppInstanceTemplatesDocument, options);
}
export function useGetAppInstanceTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppInstanceTemplatesQuery,
    GetAppInstanceTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppInstanceTemplatesQuery,
    GetAppInstanceTemplatesQueryVariables
  >(GetAppInstanceTemplatesDocument, options);
}
export type GetAppInstanceTemplatesQueryHookResult = ReturnType<
  typeof useGetAppInstanceTemplatesQuery
>;
export type GetAppInstanceTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetAppInstanceTemplatesLazyQuery
>;
export type GetAppInstanceTemplatesQueryResult = Apollo.QueryResult<
  GetAppInstanceTemplatesQuery,
  GetAppInstanceTemplatesQueryVariables
>;
export function refetchGetAppInstanceTemplatesQuery(
  variables?: GetAppInstanceTemplatesQueryVariables
) {
  return { query: GetAppInstanceTemplatesDocument, variables: variables };
}
export const AllTemplateInstancesIdsDocument = gql`
  query allTemplateInstancesIds($appId: UUID!, $spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableAppInstancesBySpaceIdAndAppId(
        appId: $appId
        condition: { isTemplate: true }
        filter: { spaceId: { equalTo: $spaceId } }
      ) {
        nodes {
          id
          isSharedExplicit
          isSharedAll
          spaceId
        }
        totalCount
      }
    }
  }
`;
export type AllTemplateInstancesIdsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllTemplateInstancesIdsQuery,
    AllTemplateInstancesIdsQueryVariables
  >;
} &
  TChildProps;
export function withAllTemplateInstancesIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllTemplateInstancesIdsQuery,
    AllTemplateInstancesIdsQueryVariables,
    AllTemplateInstancesIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllTemplateInstancesIdsQuery,
    AllTemplateInstancesIdsQueryVariables,
    AllTemplateInstancesIdsProps<TChildProps, TDataName>
  >(AllTemplateInstancesIdsDocument, {
    alias: "allTemplateInstancesIds",
    ...operationOptions,
  });
}

/**
 * __useAllTemplateInstancesIdsQuery__
 *
 * To run a query within a React component, call `useAllTemplateInstancesIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTemplateInstancesIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTemplateInstancesIdsQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllTemplateInstancesIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllTemplateInstancesIdsQuery,
    AllTemplateInstancesIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllTemplateInstancesIdsQuery,
    AllTemplateInstancesIdsQueryVariables
  >(AllTemplateInstancesIdsDocument, options);
}
export function useAllTemplateInstancesIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTemplateInstancesIdsQuery,
    AllTemplateInstancesIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllTemplateInstancesIdsQuery,
    AllTemplateInstancesIdsQueryVariables
  >(AllTemplateInstancesIdsDocument, options);
}
export type AllTemplateInstancesIdsQueryHookResult = ReturnType<
  typeof useAllTemplateInstancesIdsQuery
>;
export type AllTemplateInstancesIdsLazyQueryHookResult = ReturnType<
  typeof useAllTemplateInstancesIdsLazyQuery
>;
export type AllTemplateInstancesIdsQueryResult = Apollo.QueryResult<
  AllTemplateInstancesIdsQuery,
  AllTemplateInstancesIdsQueryVariables
>;
export function refetchAllTemplateInstancesIdsQuery(
  variables?: AllTemplateInstancesIdsQueryVariables
) {
  return { query: AllTemplateInstancesIdsDocument, variables: variables };
}
export const AppInstancesByAppIdAndSpaceIdDocument = gql`
  query appInstancesByAppIdAndSpaceId($appId: UUID!, $spaceId: UUID!) {
    appInstancesByAppIdAndSpaceId(appId: $appId, spaceId: $spaceId) {
      nodes {
        ...AppInstance
        isTemplate
        fileByThumbnailFileId {
          ...File
        }
        targetCommonAppInstanceTemplateId
        appVersionByAppInstanceId {
          __typename
          id
          appId
          version
          betaFlag
          stableFlag
          manifestJson
          viewerUrl
          editorUrl
          mediaSandboxAccess
          configurationType
          storeData
        }
      }
      totalCount
    }
  }
  ${AppInstanceFragmentDoc}
  ${FileFragmentDoc}
`;
export type AppInstancesByAppIdAndSpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AppInstancesByAppIdAndSpaceIdQuery,
    AppInstancesByAppIdAndSpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withAppInstancesByAppIdAndSpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AppInstancesByAppIdAndSpaceIdQuery,
    AppInstancesByAppIdAndSpaceIdQueryVariables,
    AppInstancesByAppIdAndSpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AppInstancesByAppIdAndSpaceIdQuery,
    AppInstancesByAppIdAndSpaceIdQueryVariables,
    AppInstancesByAppIdAndSpaceIdProps<TChildProps, TDataName>
  >(AppInstancesByAppIdAndSpaceIdDocument, {
    alias: "appInstancesByAppIdAndSpaceId",
    ...operationOptions,
  });
}

/**
 * __useAppInstancesByAppIdAndSpaceIdQuery__
 *
 * To run a query within a React component, call `useAppInstancesByAppIdAndSpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppInstancesByAppIdAndSpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppInstancesByAppIdAndSpaceIdQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAppInstancesByAppIdAndSpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppInstancesByAppIdAndSpaceIdQuery,
    AppInstancesByAppIdAndSpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppInstancesByAppIdAndSpaceIdQuery,
    AppInstancesByAppIdAndSpaceIdQueryVariables
  >(AppInstancesByAppIdAndSpaceIdDocument, options);
}
export function useAppInstancesByAppIdAndSpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppInstancesByAppIdAndSpaceIdQuery,
    AppInstancesByAppIdAndSpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppInstancesByAppIdAndSpaceIdQuery,
    AppInstancesByAppIdAndSpaceIdQueryVariables
  >(AppInstancesByAppIdAndSpaceIdDocument, options);
}
export type AppInstancesByAppIdAndSpaceIdQueryHookResult = ReturnType<
  typeof useAppInstancesByAppIdAndSpaceIdQuery
>;
export type AppInstancesByAppIdAndSpaceIdLazyQueryHookResult = ReturnType<
  typeof useAppInstancesByAppIdAndSpaceIdLazyQuery
>;
export type AppInstancesByAppIdAndSpaceIdQueryResult = Apollo.QueryResult<
  AppInstancesByAppIdAndSpaceIdQuery,
  AppInstancesByAppIdAndSpaceIdQueryVariables
>;
export function refetchAppInstancesByAppIdAndSpaceIdQuery(
  variables?: AppInstancesByAppIdAndSpaceIdQueryVariables
) {
  return { query: AppInstancesByAppIdAndSpaceIdDocument, variables: variables };
}
export const CanvasAppDocument = gql`
  query canvasApp {
    allApps(filter: { name: { equalTo: "Canvas" } }) {
      nodes {
        ...App
        appInstallsByAppId {
          nodes {
            ...AppInstall
          }
        }
        appVersionByAppId {
          ...AppVersion
          appByAppId {
            __typename
            id
            name
            iconUrl
          }
        }
      }
      totalCount
    }
  }
  ${AppFragmentDoc}
  ${AppInstallFragmentDoc}
  ${AppVersionFragmentDoc}
`;
export type CanvasAppProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CanvasAppQuery,
    CanvasAppQueryVariables
  >;
} &
  TChildProps;
export function withCanvasApp<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CanvasAppQuery,
    CanvasAppQueryVariables,
    CanvasAppProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CanvasAppQuery,
    CanvasAppQueryVariables,
    CanvasAppProps<TChildProps, TDataName>
  >(CanvasAppDocument, {
    alias: "canvasApp",
    ...operationOptions,
  });
}

/**
 * __useCanvasAppQuery__
 *
 * To run a query within a React component, call `useCanvasAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanvasAppQuery(
  baseOptions?: Apollo.QueryHookOptions<CanvasAppQuery, CanvasAppQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanvasAppQuery, CanvasAppQueryVariables>(
    CanvasAppDocument,
    options
  );
}
export function useCanvasAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanvasAppQuery,
    CanvasAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanvasAppQuery, CanvasAppQueryVariables>(
    CanvasAppDocument,
    options
  );
}
export type CanvasAppQueryHookResult = ReturnType<typeof useCanvasAppQuery>;
export type CanvasAppLazyQueryHookResult = ReturnType<
  typeof useCanvasAppLazyQuery
>;
export type CanvasAppQueryResult = Apollo.QueryResult<
  CanvasAppQuery,
  CanvasAppQueryVariables
>;
export function refetchCanvasAppQuery(variables?: CanvasAppQueryVariables) {
  return { query: CanvasAppDocument, variables: variables };
}
export const CanvasCountDocument = gql`
  query canvasCount(
    $query: String
    $spaceId: UUID!
    $condition: AppInstanceCondition
    $filter: AppInstanceFilter
    $appId: UUID!
  ) {
    searchAppInstanceSpecificAppId(
      query: $query
      spaceId: $spaceId
      condition: $condition
      filter: $filter
      appId: $appId
    ) {
      totalCount
    }
  }
`;
export type CanvasCountProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CanvasCountQuery,
    CanvasCountQueryVariables
  >;
} &
  TChildProps;
export function withCanvasCount<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CanvasCountQuery,
    CanvasCountQueryVariables,
    CanvasCountProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CanvasCountQuery,
    CanvasCountQueryVariables,
    CanvasCountProps<TChildProps, TDataName>
  >(CanvasCountDocument, {
    alias: "canvasCount",
    ...operationOptions,
  });
}

/**
 * __useCanvasCountQuery__
 *
 * To run a query within a React component, call `useCanvasCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasCountQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      condition: // value for 'condition'
 *      filter: // value for 'filter'
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useCanvasCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    CanvasCountQuery,
    CanvasCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanvasCountQuery, CanvasCountQueryVariables>(
    CanvasCountDocument,
    options
  );
}
export function useCanvasCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanvasCountQuery,
    CanvasCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanvasCountQuery, CanvasCountQueryVariables>(
    CanvasCountDocument,
    options
  );
}
export type CanvasCountQueryHookResult = ReturnType<typeof useCanvasCountQuery>;
export type CanvasCountLazyQueryHookResult = ReturnType<
  typeof useCanvasCountLazyQuery
>;
export type CanvasCountQueryResult = Apollo.QueryResult<
  CanvasCountQuery,
  CanvasCountQueryVariables
>;
export function refetchCanvasCountQuery(variables?: CanvasCountQueryVariables) {
  return { query: CanvasCountDocument, variables: variables };
}
export const CreateAppInstanceByCommonAppInstanceTemplateIdDocument = gql`
  mutation createAppInstanceByCommonAppInstanceTemplateId(
    $input: CreateAppInstanceByCommonAppInstanceTemplateIdInput!
  ) {
    createAppInstanceByCommonAppInstanceTemplateId(input: $input) {
      appInstance {
        ...AvailableAppInstance
      }
      orgByOrgId {
        ...Org
      }
      appInstallByAppInstallId {
        ...AppInstall
      }
      fileByThumbnailFileId {
        ...File
      }
      commonAppInstanceTemplateByCommonAppInstanceTemplateId {
        __typename
        id
        name
        version
        config
        state
        tags
        status
        thumbnailFileId
        isPublished
        isDeprecated
        sourceOrgId
        sourceAppInstanceId
        appInstanceBySourceAppInstanceId {
          ...AppInstance
          fileByThumbnailFileId {
            ...File
          }
        }
        appInstanceByTargetCommonAppInstanceTemplateId {
          ...AppInstance
          fileByThumbnailFileId {
            ...File
          }
        }
      }
      appByAppId {
        __typename
        id
        name
        iconUrl
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
  ${OrgFragmentDoc}
  ${AppInstallFragmentDoc}
  ${FileFragmentDoc}
  ${AppInstanceFragmentDoc}
`;
export type CreateAppInstanceByCommonAppInstanceTemplateIdMutationFn = Apollo.MutationFunction<
  CreateAppInstanceByCommonAppInstanceTemplateIdMutation,
  CreateAppInstanceByCommonAppInstanceTemplateIdMutationVariables
>;
export type CreateAppInstanceByCommonAppInstanceTemplateIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateAppInstanceByCommonAppInstanceTemplateIdMutation,
    CreateAppInstanceByCommonAppInstanceTemplateIdMutationVariables
  >;
} &
  TChildProps;
export function withCreateAppInstanceByCommonAppInstanceTemplateId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateAppInstanceByCommonAppInstanceTemplateIdMutation,
    CreateAppInstanceByCommonAppInstanceTemplateIdMutationVariables,
    CreateAppInstanceByCommonAppInstanceTemplateIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateAppInstanceByCommonAppInstanceTemplateIdMutation,
    CreateAppInstanceByCommonAppInstanceTemplateIdMutationVariables,
    CreateAppInstanceByCommonAppInstanceTemplateIdProps<TChildProps, TDataName>
  >(CreateAppInstanceByCommonAppInstanceTemplateIdDocument, {
    alias: "createAppInstanceByCommonAppInstanceTemplateId",
    ...operationOptions,
  });
}

/**
 * __useCreateAppInstanceByCommonAppInstanceTemplateIdMutation__
 *
 * To run a mutation, you first call `useCreateAppInstanceByCommonAppInstanceTemplateIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppInstanceByCommonAppInstanceTemplateIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppInstanceByCommonAppInstanceTemplateIdMutation, { data, loading, error }] = useCreateAppInstanceByCommonAppInstanceTemplateIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppInstanceByCommonAppInstanceTemplateIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppInstanceByCommonAppInstanceTemplateIdMutation,
    CreateAppInstanceByCommonAppInstanceTemplateIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppInstanceByCommonAppInstanceTemplateIdMutation,
    CreateAppInstanceByCommonAppInstanceTemplateIdMutationVariables
  >(CreateAppInstanceByCommonAppInstanceTemplateIdDocument, options);
}
export type CreateAppInstanceByCommonAppInstanceTemplateIdMutationHookResult = ReturnType<
  typeof useCreateAppInstanceByCommonAppInstanceTemplateIdMutation
>;
export type CreateAppInstanceByCommonAppInstanceTemplateIdMutationResult = Apollo.MutationResult<CreateAppInstanceByCommonAppInstanceTemplateIdMutation>;
export type CreateAppInstanceByCommonAppInstanceTemplateIdMutationOptions = Apollo.BaseMutationOptions<
  CreateAppInstanceByCommonAppInstanceTemplateIdMutation,
  CreateAppInstanceByCommonAppInstanceTemplateIdMutationVariables
>;
export const CreateCommonAppInstanceTemplateBySourceAppInstanceIdDocument = gql`
  mutation createCommonAppInstanceTemplateBySourceAppInstanceId(
    $input: CreateCommonAppInstanceTemplateBySourceAppInstanceIdInput!
  ) {
    createCommonAppInstanceTemplateBySourceAppInstanceId(input: $input) {
      commonAppInstanceTemplate {
        id
        name
        version
        config
        state
        tags
        status
        thumbnailFileId
        isPublished
        isDeprecated
        sourceOrgId
        sourceAppInstanceId
        appInstanceBySourceAppInstanceId {
          ...AppInstance
        }
        appInstanceByTargetCommonAppInstanceTemplateId {
          ...AppInstance
          fileByThumbnailFileId {
            ...File
          }
        }
      }
      orgBySourceOrgId {
        ...Org
      }
      appInstanceBySourceAppInstanceId {
        ...AppInstance
        fileByThumbnailFileId {
          ...File
        }
      }
    }
  }
  ${AppInstanceFragmentDoc}
  ${FileFragmentDoc}
  ${OrgFragmentDoc}
`;
export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationFn = Apollo.MutationFunction<
  CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
  CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
>;
export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
  >;
} &
  TChildProps;
export function withCreateCommonAppInstanceTemplateBySourceAppInstanceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdProps<
      TChildProps,
      TDataName
    >
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdProps<
      TChildProps,
      TDataName
    >
  >(CreateCommonAppInstanceTemplateBySourceAppInstanceIdDocument, {
    alias: "createCommonAppInstanceTemplateBySourceAppInstanceId",
    ...operationOptions,
  });
}

/**
 * __useCreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation__
 *
 * To run a mutation, you first call `useCreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommonAppInstanceTemplateBySourceAppInstanceIdMutation, { data, loading, error }] = useCreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
  >(CreateCommonAppInstanceTemplateBySourceAppInstanceIdDocument, options);
}
export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationHookResult = ReturnType<
  typeof useCreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation
>;
export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationResult = Apollo.MutationResult<CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation>;
export type CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationOptions = Apollo.BaseMutationOptions<
  CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
  CreateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
>;
export const DeleteCommonAppInstanceTemplateByIdDocument = gql`
  mutation deleteCommonAppInstanceTemplateById(
    $input: DeleteCommonAppInstanceTemplateByIdInput!
  ) {
    deleteCommonAppInstanceTemplateById(input: $input) {
      commonAppInstanceTemplate {
        id
        sourceAppInstanceId
      }
    }
  }
`;
export type DeleteCommonAppInstanceTemplateByIdMutationFn = Apollo.MutationFunction<
  DeleteCommonAppInstanceTemplateByIdMutation,
  DeleteCommonAppInstanceTemplateByIdMutationVariables
>;
export type DeleteCommonAppInstanceTemplateByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteCommonAppInstanceTemplateByIdMutation,
    DeleteCommonAppInstanceTemplateByIdMutationVariables
  >;
} &
  TChildProps;
export function withDeleteCommonAppInstanceTemplateById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCommonAppInstanceTemplateByIdMutation,
    DeleteCommonAppInstanceTemplateByIdMutationVariables,
    DeleteCommonAppInstanceTemplateByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCommonAppInstanceTemplateByIdMutation,
    DeleteCommonAppInstanceTemplateByIdMutationVariables,
    DeleteCommonAppInstanceTemplateByIdProps<TChildProps, TDataName>
  >(DeleteCommonAppInstanceTemplateByIdDocument, {
    alias: "deleteCommonAppInstanceTemplateById",
    ...operationOptions,
  });
}

/**
 * __useDeleteCommonAppInstanceTemplateByIdMutation__
 *
 * To run a mutation, you first call `useDeleteCommonAppInstanceTemplateByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommonAppInstanceTemplateByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommonAppInstanceTemplateByIdMutation, { data, loading, error }] = useDeleteCommonAppInstanceTemplateByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommonAppInstanceTemplateByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommonAppInstanceTemplateByIdMutation,
    DeleteCommonAppInstanceTemplateByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommonAppInstanceTemplateByIdMutation,
    DeleteCommonAppInstanceTemplateByIdMutationVariables
  >(DeleteCommonAppInstanceTemplateByIdDocument, options);
}
export type DeleteCommonAppInstanceTemplateByIdMutationHookResult = ReturnType<
  typeof useDeleteCommonAppInstanceTemplateByIdMutation
>;
export type DeleteCommonAppInstanceTemplateByIdMutationResult = Apollo.MutationResult<DeleteCommonAppInstanceTemplateByIdMutation>;
export type DeleteCommonAppInstanceTemplateByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommonAppInstanceTemplateByIdMutation,
  DeleteCommonAppInstanceTemplateByIdMutationVariables
>;
export const DuplicateAppInstanceDocument = gql`
  mutation duplicateAppInstance($input: DuplicateAppInstanceInput!) {
    duplicateAppInstance(input: $input) {
      appInstance {
        ...AvailableAppInstance
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
`;
export type DuplicateAppInstanceMutationFn = Apollo.MutationFunction<
  DuplicateAppInstanceMutation,
  DuplicateAppInstanceMutationVariables
>;
export type DuplicateAppInstanceProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DuplicateAppInstanceMutation,
    DuplicateAppInstanceMutationVariables
  >;
} &
  TChildProps;
export function withDuplicateAppInstance<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DuplicateAppInstanceMutation,
    DuplicateAppInstanceMutationVariables,
    DuplicateAppInstanceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DuplicateAppInstanceMutation,
    DuplicateAppInstanceMutationVariables,
    DuplicateAppInstanceProps<TChildProps, TDataName>
  >(DuplicateAppInstanceDocument, {
    alias: "duplicateAppInstance",
    ...operationOptions,
  });
}

/**
 * __useDuplicateAppInstanceMutation__
 *
 * To run a mutation, you first call `useDuplicateAppInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateAppInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateAppInstanceMutation, { data, loading, error }] = useDuplicateAppInstanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateAppInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateAppInstanceMutation,
    DuplicateAppInstanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateAppInstanceMutation,
    DuplicateAppInstanceMutationVariables
  >(DuplicateAppInstanceDocument, options);
}
export type DuplicateAppInstanceMutationHookResult = ReturnType<
  typeof useDuplicateAppInstanceMutation
>;
export type DuplicateAppInstanceMutationResult = Apollo.MutationResult<DuplicateAppInstanceMutation>;
export type DuplicateAppInstanceMutationOptions = Apollo.BaseMutationOptions<
  DuplicateAppInstanceMutation,
  DuplicateAppInstanceMutationVariables
>;
export const SearchAllCanvasInstanceIdsDocument = gql`
  query searchAllCanvasInstanceIds(
    $query: String
    $spaceId: UUID!
    $appId: UUID
  ) {
    searchAppInstance(
      query: $query
      spaceId: $spaceId
      filter: { appId: { equalTo: $appId } }
    ) {
      nodes {
        __typename
        id
        isTemplate
      }
      totalCount
    }
  }
`;
export type SearchAllCanvasInstanceIdsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchAllCanvasInstanceIdsQuery,
    SearchAllCanvasInstanceIdsQueryVariables
  >;
} &
  TChildProps;
export function withSearchAllCanvasInstanceIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchAllCanvasInstanceIdsQuery,
    SearchAllCanvasInstanceIdsQueryVariables,
    SearchAllCanvasInstanceIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchAllCanvasInstanceIdsQuery,
    SearchAllCanvasInstanceIdsQueryVariables,
    SearchAllCanvasInstanceIdsProps<TChildProps, TDataName>
  >(SearchAllCanvasInstanceIdsDocument, {
    alias: "searchAllCanvasInstanceIds",
    ...operationOptions,
  });
}

/**
 * __useSearchAllCanvasInstanceIdsQuery__
 *
 * To run a query within a React component, call `useSearchAllCanvasInstanceIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAllCanvasInstanceIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAllCanvasInstanceIdsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useSearchAllCanvasInstanceIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAllCanvasInstanceIdsQuery,
    SearchAllCanvasInstanceIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAllCanvasInstanceIdsQuery,
    SearchAllCanvasInstanceIdsQueryVariables
  >(SearchAllCanvasInstanceIdsDocument, options);
}
export function useSearchAllCanvasInstanceIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAllCanvasInstanceIdsQuery,
    SearchAllCanvasInstanceIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAllCanvasInstanceIdsQuery,
    SearchAllCanvasInstanceIdsQueryVariables
  >(SearchAllCanvasInstanceIdsDocument, options);
}
export type SearchAllCanvasInstanceIdsQueryHookResult = ReturnType<
  typeof useSearchAllCanvasInstanceIdsQuery
>;
export type SearchAllCanvasInstanceIdsLazyQueryHookResult = ReturnType<
  typeof useSearchAllCanvasInstanceIdsLazyQuery
>;
export type SearchAllCanvasInstanceIdsQueryResult = Apollo.QueryResult<
  SearchAllCanvasInstanceIdsQuery,
  SearchAllCanvasInstanceIdsQueryVariables
>;
export function refetchSearchAllCanvasInstanceIdsQuery(
  variables?: SearchAllCanvasInstanceIdsQueryVariables
) {
  return { query: SearchAllCanvasInstanceIdsDocument, variables: variables };
}
export const SearchCanvasDocument = gql`
  query searchCanvas(
    $query: String
    $first: Int
    $offset: Int
    $spaceId: UUID!
    $condition: AppInstanceCondition
    $filter: AppInstanceFilter
    $appId: UUID!
  ) {
    searchAppInstanceSpecificAppId(
      query: $query
      first: $first
      offset: $offset
      orderBy: [NAME_ASC, ID_DESC]
      spaceId: $spaceId
      condition: $condition
      filter: $filter
      appId: $appId
    ) {
      nodes {
        ...AvailableAppInstance
        associationsByAppInstanceIdAndSpaceId(spaceId: $spaceId) {
          nodes {
            ...PlaylistAndChannelAssociations
          }
        }
      }
    }
  }
  ${AvailableAppInstanceFragmentDoc}
  ${PlaylistAndChannelAssociationsFragmentDoc}
`;
export type SearchCanvasProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchCanvasQuery,
    SearchCanvasQueryVariables
  >;
} &
  TChildProps;
export function withSearchCanvas<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchCanvasQuery,
    SearchCanvasQueryVariables,
    SearchCanvasProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchCanvasQuery,
    SearchCanvasQueryVariables,
    SearchCanvasProps<TChildProps, TDataName>
  >(SearchCanvasDocument, {
    alias: "searchCanvas",
    ...operationOptions,
  });
}

/**
 * __useSearchCanvasQuery__
 *
 * To run a query within a React component, call `useSearchCanvasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCanvasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCanvasQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      spaceId: // value for 'spaceId'
 *      condition: // value for 'condition'
 *      filter: // value for 'filter'
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useSearchCanvasQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchCanvasQuery,
    SearchCanvasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchCanvasQuery, SearchCanvasQueryVariables>(
    SearchCanvasDocument,
    options
  );
}
export function useSearchCanvasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCanvasQuery,
    SearchCanvasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchCanvasQuery, SearchCanvasQueryVariables>(
    SearchCanvasDocument,
    options
  );
}
export type SearchCanvasQueryHookResult = ReturnType<
  typeof useSearchCanvasQuery
>;
export type SearchCanvasLazyQueryHookResult = ReturnType<
  typeof useSearchCanvasLazyQuery
>;
export type SearchCanvasQueryResult = Apollo.QueryResult<
  SearchCanvasQuery,
  SearchCanvasQueryVariables
>;
export function refetchSearchCanvasQuery(
  variables?: SearchCanvasQueryVariables
) {
  return { query: SearchCanvasDocument, variables: variables };
}
export const UpdateAppInstanceTagsByIdsDocument = gql`
  mutation updateAppInstanceTagsByIds(
    $input: UpdateAppInstanceTagsByIdsInput!
  ) {
    updateAppInstanceTagsByIds(input: $input) {
      appInstances {
        id
        orgId
        appInstallId
        appId
        spaceId
        name
        tags
      }
    }
  }
`;
export type UpdateAppInstanceTagsByIdsMutationFn = Apollo.MutationFunction<
  UpdateAppInstanceTagsByIdsMutation,
  UpdateAppInstanceTagsByIdsMutationVariables
>;
export type UpdateAppInstanceTagsByIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAppInstanceTagsByIdsMutation,
    UpdateAppInstanceTagsByIdsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateAppInstanceTagsByIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAppInstanceTagsByIdsMutation,
    UpdateAppInstanceTagsByIdsMutationVariables,
    UpdateAppInstanceTagsByIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAppInstanceTagsByIdsMutation,
    UpdateAppInstanceTagsByIdsMutationVariables,
    UpdateAppInstanceTagsByIdsProps<TChildProps, TDataName>
  >(UpdateAppInstanceTagsByIdsDocument, {
    alias: "updateAppInstanceTagsByIds",
    ...operationOptions,
  });
}

/**
 * __useUpdateAppInstanceTagsByIdsMutation__
 *
 * To run a mutation, you first call `useUpdateAppInstanceTagsByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppInstanceTagsByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppInstanceTagsByIdsMutation, { data, loading, error }] = useUpdateAppInstanceTagsByIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppInstanceTagsByIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppInstanceTagsByIdsMutation,
    UpdateAppInstanceTagsByIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppInstanceTagsByIdsMutation,
    UpdateAppInstanceTagsByIdsMutationVariables
  >(UpdateAppInstanceTagsByIdsDocument, options);
}
export type UpdateAppInstanceTagsByIdsMutationHookResult = ReturnType<
  typeof useUpdateAppInstanceTagsByIdsMutation
>;
export type UpdateAppInstanceTagsByIdsMutationResult = Apollo.MutationResult<UpdateAppInstanceTagsByIdsMutation>;
export type UpdateAppInstanceTagsByIdsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppInstanceTagsByIdsMutation,
  UpdateAppInstanceTagsByIdsMutationVariables
>;
export const UpdateCommonAppInstanceTemplateBySourceAppInstanceIdDocument = gql`
  mutation updateCommonAppInstanceTemplateBySourceAppInstanceId(
    $input: UpdateCommonAppInstanceTemplateBySourceAppInstanceIdInput!
  ) {
    updateCommonAppInstanceTemplateBySourceAppInstanceId(input: $input) {
      commonAppInstanceTemplate {
        id
        name
        version
        config
        state
        tags
        status
        thumbnailFileId
        isPublished
        isDeprecated
        sourceOrgId
        sourceAppInstanceId
        appInstanceBySourceAppInstanceId {
          ...AppInstance
        }
        appInstanceByTargetCommonAppInstanceTemplateId {
          ...AppInstance
          fileByThumbnailFileId {
            ...File
          }
        }
      }
      orgBySourceOrgId {
        ...Org
      }
      appInstanceBySourceAppInstanceId {
        ...AppInstance
        fileByThumbnailFileId {
          ...File
        }
      }
    }
  }
  ${AppInstanceFragmentDoc}
  ${FileFragmentDoc}
  ${OrgFragmentDoc}
`;
export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationFn = Apollo.MutationFunction<
  UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
  UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
>;
export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateCommonAppInstanceTemplateBySourceAppInstanceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdProps<
      TChildProps,
      TDataName
    >
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdProps<
      TChildProps,
      TDataName
    >
  >(UpdateCommonAppInstanceTemplateBySourceAppInstanceIdDocument, {
    alias: "updateCommonAppInstanceTemplateBySourceAppInstanceId",
    ...operationOptions,
  });
}

/**
 * __useUpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation__
 *
 * To run a mutation, you first call `useUpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommonAppInstanceTemplateBySourceAppInstanceIdMutation, { data, loading, error }] = useUpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
    UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
  >(UpdateCommonAppInstanceTemplateBySourceAppInstanceIdDocument, options);
}
export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationHookResult = ReturnType<
  typeof useUpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation
>;
export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationResult = Apollo.MutationResult<UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation>;
export type UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutation,
  UpdateCommonAppInstanceTemplateBySourceAppInstanceIdMutationVariables
>;
export const CastByChannelByIdDocument = gql`
  query castByChannelById($id: UUID!, $expectedItemPerPage: Int, $offset: Int) {
    channelById(id: $id) {
      __typename
      id
      associationsByToChannelAndOrgId(
        first: 200
        filter: { fromCast: { isNull: false } }
      ) {
        nodes {
          fromCast
          castByFromCastAndOrgId {
            ...CastItem
          }
        }
        totalCount
      }
    }
  }
  ${CastItemFragmentDoc}
`;
export type CastByChannelByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CastByChannelByIdQuery,
    CastByChannelByIdQueryVariables
  >;
} &
  TChildProps;
export function withCastByChannelById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CastByChannelByIdQuery,
    CastByChannelByIdQueryVariables,
    CastByChannelByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CastByChannelByIdQuery,
    CastByChannelByIdQueryVariables,
    CastByChannelByIdProps<TChildProps, TDataName>
  >(CastByChannelByIdDocument, {
    alias: "castByChannelById",
    ...operationOptions,
  });
}

/**
 * __useCastByChannelByIdQuery__
 *
 * To run a query within a React component, call `useCastByChannelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCastByChannelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCastByChannelByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      expectedItemPerPage: // value for 'expectedItemPerPage'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCastByChannelByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CastByChannelByIdQuery,
    CastByChannelByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CastByChannelByIdQuery,
    CastByChannelByIdQueryVariables
  >(CastByChannelByIdDocument, options);
}
export function useCastByChannelByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CastByChannelByIdQuery,
    CastByChannelByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CastByChannelByIdQuery,
    CastByChannelByIdQueryVariables
  >(CastByChannelByIdDocument, options);
}
export type CastByChannelByIdQueryHookResult = ReturnType<
  typeof useCastByChannelByIdQuery
>;
export type CastByChannelByIdLazyQueryHookResult = ReturnType<
  typeof useCastByChannelByIdLazyQuery
>;
export type CastByChannelByIdQueryResult = Apollo.QueryResult<
  CastByChannelByIdQuery,
  CastByChannelByIdQueryVariables
>;
export function refetchCastByChannelByIdQuery(
  variables?: CastByChannelByIdQueryVariables
) {
  return { query: CastByChannelByIdDocument, variables: variables };
}
export const ChannelByIdDocument = gql`
  query channelById($id: UUID!) {
    channelById(id: $id) {
      ...ChannelById
    }
  }
  ${ChannelByIdFragmentDoc}
`;
export type ChannelByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ChannelByIdQuery,
    ChannelByIdQueryVariables
  >;
} &
  TChildProps;
export function withChannelById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChannelByIdQuery,
    ChannelByIdQueryVariables,
    ChannelByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ChannelByIdQuery,
    ChannelByIdQueryVariables,
    ChannelByIdProps<TChildProps, TDataName>
  >(ChannelByIdDocument, {
    alias: "channelById",
    ...operationOptions,
  });
}

/**
 * __useChannelByIdQuery__
 *
 * To run a query within a React component, call `useChannelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChannelByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChannelByIdQuery,
    ChannelByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChannelByIdQuery, ChannelByIdQueryVariables>(
    ChannelByIdDocument,
    options
  );
}
export function useChannelByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChannelByIdQuery,
    ChannelByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChannelByIdQuery, ChannelByIdQueryVariables>(
    ChannelByIdDocument,
    options
  );
}
export type ChannelByIdQueryHookResult = ReturnType<typeof useChannelByIdQuery>;
export type ChannelByIdLazyQueryHookResult = ReturnType<
  typeof useChannelByIdLazyQuery
>;
export type ChannelByIdQueryResult = Apollo.QueryResult<
  ChannelByIdQuery,
  ChannelByIdQueryVariables
>;
export function refetchChannelByIdQuery(variables?: ChannelByIdQueryVariables) {
  return { query: ChannelByIdDocument, variables: variables };
}
export const EmbedsByChannelIdDocument = gql`
  query embedsByChannelId($id: UUID!) {
    channelById(id: $id) {
      __typename
      id
      name
      embedsByChannelId {
        nodes {
          __typename
          id
          shortId
        }
      }
    }
  }
`;
export type EmbedsByChannelIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    EmbedsByChannelIdQuery,
    EmbedsByChannelIdQueryVariables
  >;
} &
  TChildProps;
export function withEmbedsByChannelId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EmbedsByChannelIdQuery,
    EmbedsByChannelIdQueryVariables,
    EmbedsByChannelIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EmbedsByChannelIdQuery,
    EmbedsByChannelIdQueryVariables,
    EmbedsByChannelIdProps<TChildProps, TDataName>
  >(EmbedsByChannelIdDocument, {
    alias: "embedsByChannelId",
    ...operationOptions,
  });
}

/**
 * __useEmbedsByChannelIdQuery__
 *
 * To run a query within a React component, call `useEmbedsByChannelIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmbedsByChannelIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbedsByChannelIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmbedsByChannelIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmbedsByChannelIdQuery,
    EmbedsByChannelIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmbedsByChannelIdQuery,
    EmbedsByChannelIdQueryVariables
  >(EmbedsByChannelIdDocument, options);
}
export function useEmbedsByChannelIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmbedsByChannelIdQuery,
    EmbedsByChannelIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmbedsByChannelIdQuery,
    EmbedsByChannelIdQueryVariables
  >(EmbedsByChannelIdDocument, options);
}
export type EmbedsByChannelIdQueryHookResult = ReturnType<
  typeof useEmbedsByChannelIdQuery
>;
export type EmbedsByChannelIdLazyQueryHookResult = ReturnType<
  typeof useEmbedsByChannelIdLazyQuery
>;
export type EmbedsByChannelIdQueryResult = Apollo.QueryResult<
  EmbedsByChannelIdQuery,
  EmbedsByChannelIdQueryVariables
>;
export function refetchEmbedsByChannelIdQuery(
  variables?: EmbedsByChannelIdQueryVariables
) {
  return { query: EmbedsByChannelIdDocument, variables: variables };
}
export const DuplicateChannelDocument = gql`
  mutation duplicateChannel($input: DuplicateChannelInput!) {
    duplicateChannel(input: $input) {
      channel {
        ...ChannelListItem
      }
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type DuplicateChannelMutationFn = Apollo.MutationFunction<
  DuplicateChannelMutation,
  DuplicateChannelMutationVariables
>;
export type DuplicateChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DuplicateChannelMutation,
    DuplicateChannelMutationVariables
  >;
} &
  TChildProps;
export function withDuplicateChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DuplicateChannelMutation,
    DuplicateChannelMutationVariables,
    DuplicateChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DuplicateChannelMutation,
    DuplicateChannelMutationVariables,
    DuplicateChannelProps<TChildProps, TDataName>
  >(DuplicateChannelDocument, {
    alias: "duplicateChannel",
    ...operationOptions,
  });
}

/**
 * __useDuplicateChannelMutation__
 *
 * To run a mutation, you first call `useDuplicateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateChannelMutation, { data, loading, error }] = useDuplicateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateChannelMutation,
    DuplicateChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateChannelMutation,
    DuplicateChannelMutationVariables
  >(DuplicateChannelDocument, options);
}
export type DuplicateChannelMutationHookResult = ReturnType<
  typeof useDuplicateChannelMutation
>;
export type DuplicateChannelMutationResult = Apollo.MutationResult<DuplicateChannelMutation>;
export type DuplicateChannelMutationOptions = Apollo.BaseMutationOptions<
  DuplicateChannelMutation,
  DuplicateChannelMutationVariables
>;
export const PublishDraftChannelDocument = gql`
  mutation publishDraftChannel($input: PublishDraftChannelInput!) {
    publishDraftChannel(input: $input) {
      channel {
        ...Channel
        draft {
          __typename
          id
          isPublished
        }
      }
    }
  }
  ${ChannelFragmentDoc}
`;
export type PublishDraftChannelMutationFn = Apollo.MutationFunction<
  PublishDraftChannelMutation,
  PublishDraftChannelMutationVariables
>;
export type PublishDraftChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    PublishDraftChannelMutation,
    PublishDraftChannelMutationVariables
  >;
} &
  TChildProps;
export function withPublishDraftChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PublishDraftChannelMutation,
    PublishDraftChannelMutationVariables,
    PublishDraftChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PublishDraftChannelMutation,
    PublishDraftChannelMutationVariables,
    PublishDraftChannelProps<TChildProps, TDataName>
  >(PublishDraftChannelDocument, {
    alias: "publishDraftChannel",
    ...operationOptions,
  });
}

/**
 * __usePublishDraftChannelMutation__
 *
 * To run a mutation, you first call `usePublishDraftChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftChannelMutation, { data, loading, error }] = usePublishDraftChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishDraftChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishDraftChannelMutation,
    PublishDraftChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishDraftChannelMutation,
    PublishDraftChannelMutationVariables
  >(PublishDraftChannelDocument, options);
}
export type PublishDraftChannelMutationHookResult = ReturnType<
  typeof usePublishDraftChannelMutation
>;
export type PublishDraftChannelMutationResult = Apollo.MutationResult<PublishDraftChannelMutation>;
export type PublishDraftChannelMutationOptions = Apollo.BaseMutationOptions<
  PublishDraftChannelMutation,
  PublishDraftChannelMutationVariables
>;
export const RevertDraftChannelDocument = gql`
  mutation revertDraftChannel($input: RevertDraftChannelInput!) {
    revertDraftChannel(input: $input) {
      channel {
        ...Channel
        fileByCoverImageId {
          __typename
          id
          orgId
          metadata
          name
          source
          mimetype
          fileProcessingStatus
          fileOutputsByFileId(first: 10) {
            nodes {
              ...FileOutput
            }
          }
        }
        draft {
          ...Channel
          layoutByChannel {
            ...Layout
          }
          playlistsByChannelId {
            nodes {
              __typename
              id
              orgId
              spaceId
              name
              color
              content
              isPublished
              draft {
                __typename
                id
                isPublished
              }
              filesByPlaylistId {
                nodes {
                  __typename
                  id
                  orgId
                  name
                  metadata
                  source
                  mimetype
                  fileProcessingStatus
                  fileOutputsByFileId {
                    nodes {
                      ...FileOutput
                    }
                  }
                }
              }
              appInstancesByPlaylistId(first: 6) {
                nodes {
                  __typename
                  id
                  orgId
                  config
                  name
                  appVersionByAppInstanceId {
                    __typename
                    id
                    appId
                    manifestJson
                    viewerUrl
                    appByAppId {
                      __typename
                      id
                      name
                      iconUrl
                    }
                  }
                }
              }
              linksByPlaylistId(first: 6) {
                nodes {
                  __typename
                  id
                  orgId
                  url
                  linkType
                  cloudConfig
                  name
                  fileByFileId {
                    __typename
                    id
                    orgId
                    metadata
                    source
                    mimetype
                    fileProcessingStatus
                  }
                }
              }
              sitesByPlaylistId(first: 6) {
                nodes {
                  __typename
                  id
                  orgId
                  name
                  type
                  url
                  config
                  actions
                  fileByThumbnailId {
                    __typename
                    id
                    orgId
                    metadata
                    source
                    mimetype
                    fileProcessingStatus
                  }
                }
              }
            }
          }
          filesByChannelId {
            nodes {
              __typename
              id
              orgId
              name
              metadata
              source
              mimetype
              fileProcessingStatus
              fileOutputsByFileId {
                nodes {
                  ...FileOutput
                }
              }
            }
          }
          linksByChannelId {
            nodes {
              __typename
              id
              orgId
              url
              fileByFileId {
                __typename
                id
                orgId
                metadata
                source
                mimetype
              }
              linkType
              cloudConfig
              name
            }
          }
          sitesByChannelId {
            nodes {
              __typename
              id
              orgId
              name
              type
              url
              config
              actions
              fileByThumbnailId {
                __typename
                id
                orgId
                metadata
                source
                mimetype
              }
            }
          }
          appInstancesByChannelId {
            nodes {
              __typename
              id
              orgId
              config
              name
              appVersionByAppInstanceId {
                __typename
                id
                appId
                manifestJson
                viewerUrl
                appByAppId {
                  __typename
                  id
                  name
                  iconUrl
                }
              }
            }
          }
          userByUpdatedBy {
            __typename
            id
            givenName
            familyName
            email
          }
          userByCreatedBy {
            __typename
            id
            givenName
            familyName
            email
          }
          userByPublishedBy {
            __typename
            id
            givenName
            familyName
            email
          }
        }
        userByPublishedBy {
          __typename
          id
          givenName
          familyName
          email
        }
        userByUpdatedBy {
          __typename
          id
          givenName
          familyName
          email
        }
        userByCreatedBy {
          __typename
          id
          givenName
          familyName
          email
        }
        isSharedExplicit
        isSharedAll
        screensByChannelId {
          totalCount
        }
        childOf
        parentChannelByChildOf {
          ...ParentChannel
        }
      }
    }
  }
  ${ChannelFragmentDoc}
  ${FileOutputFragmentDoc}
  ${LayoutFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export type RevertDraftChannelMutationFn = Apollo.MutationFunction<
  RevertDraftChannelMutation,
  RevertDraftChannelMutationVariables
>;
export type RevertDraftChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RevertDraftChannelMutation,
    RevertDraftChannelMutationVariables
  >;
} &
  TChildProps;
export function withRevertDraftChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RevertDraftChannelMutation,
    RevertDraftChannelMutationVariables,
    RevertDraftChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RevertDraftChannelMutation,
    RevertDraftChannelMutationVariables,
    RevertDraftChannelProps<TChildProps, TDataName>
  >(RevertDraftChannelDocument, {
    alias: "revertDraftChannel",
    ...operationOptions,
  });
}

/**
 * __useRevertDraftChannelMutation__
 *
 * To run a mutation, you first call `useRevertDraftChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertDraftChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertDraftChannelMutation, { data, loading, error }] = useRevertDraftChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertDraftChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevertDraftChannelMutation,
    RevertDraftChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevertDraftChannelMutation,
    RevertDraftChannelMutationVariables
  >(RevertDraftChannelDocument, options);
}
export type RevertDraftChannelMutationHookResult = ReturnType<
  typeof useRevertDraftChannelMutation
>;
export type RevertDraftChannelMutationResult = Apollo.MutationResult<RevertDraftChannelMutation>;
export type RevertDraftChannelMutationOptions = Apollo.BaseMutationOptions<
  RevertDraftChannelMutation,
  RevertDraftChannelMutationVariables
>;
export const SnapshotsByOrgDocument = gql`
  query snapshotsByOrg($input: GetSnapshotInput) {
    getSnapshots(input: $input) {
      jobId
      status
      snapshotType
      contentId
      sourceOrgId
      sourceSpaceId
      isPublicSnapshot
      userId
    }
  }
`;
export type SnapshotsByOrgProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SnapshotsByOrgQuery,
    SnapshotsByOrgQueryVariables
  >;
} &
  TChildProps;
export function withSnapshotsByOrg<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SnapshotsByOrgQuery,
    SnapshotsByOrgQueryVariables,
    SnapshotsByOrgProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SnapshotsByOrgQuery,
    SnapshotsByOrgQueryVariables,
    SnapshotsByOrgProps<TChildProps, TDataName>
  >(SnapshotsByOrgDocument, {
    alias: "snapshotsByOrg",
    ...operationOptions,
  });
}

/**
 * __useSnapshotsByOrgQuery__
 *
 * To run a query within a React component, call `useSnapshotsByOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotsByOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotsByOrgQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSnapshotsByOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SnapshotsByOrgQuery,
    SnapshotsByOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SnapshotsByOrgQuery, SnapshotsByOrgQueryVariables>(
    SnapshotsByOrgDocument,
    options
  );
}
export function useSnapshotsByOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SnapshotsByOrgQuery,
    SnapshotsByOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SnapshotsByOrgQuery, SnapshotsByOrgQueryVariables>(
    SnapshotsByOrgDocument,
    options
  );
}
export type SnapshotsByOrgQueryHookResult = ReturnType<
  typeof useSnapshotsByOrgQuery
>;
export type SnapshotsByOrgLazyQueryHookResult = ReturnType<
  typeof useSnapshotsByOrgLazyQuery
>;
export type SnapshotsByOrgQueryResult = Apollo.QueryResult<
  SnapshotsByOrgQuery,
  SnapshotsByOrgQueryVariables
>;
export function refetchSnapshotsByOrgQuery(
  variables?: SnapshotsByOrgQueryVariables
) {
  return { query: SnapshotsByOrgDocument, variables: variables };
}
export const UpdateChannelDocument = gql`
  mutation updateChannel($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      channel {
        ...Channel
        fileByCoverImageId {
          __typename
          id
          orgId
          metadata
          name
          source
          mimetype
          fileProcessingStatus
          fileOutputsByFileId(first: 10) {
            nodes {
              ...FileOutput
            }
          }
        }
        draft {
          ...Channel
          layoutByChannel {
            ...Layout
          }
          playlistsByChannelId {
            nodes {
              __typename
              id
            }
          }
          filesByChannelId {
            nodes {
              __typename
              id
            }
          }
          linksByChannelId {
            nodes {
              __typename
              id
            }
          }
          sitesByChannelId {
            nodes {
              __typename
              id
              orgId
              name
              type
              url
              config
              actions
              fileByThumbnailId {
                __typename
                id
                orgId
                metadata
                source
                mimetype
              }
            }
          }
          appInstancesByChannelId {
            nodes {
              __typename
              id
            }
          }
        }
        isSharedExplicit
        isSharedAll
        childOf
        parentChannelByChildOf {
          ...ParentChannel
        }
      }
    }
  }
  ${ChannelFragmentDoc}
  ${FileOutputFragmentDoc}
  ${LayoutFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export type UpdateChannelMutationFn = Apollo.MutationFunction<
  UpdateChannelMutation,
  UpdateChannelMutationVariables
>;
export type UpdateChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateChannelMutation,
    UpdateChannelMutationVariables
  >;
} &
  TChildProps;
export function withUpdateChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateChannelMutation,
    UpdateChannelMutationVariables,
    UpdateChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateChannelMutation,
    UpdateChannelMutationVariables,
    UpdateChannelProps<TChildProps, TDataName>
  >(UpdateChannelDocument, {
    alias: "updateChannel",
    ...operationOptions,
  });
}

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelMutation,
    UpdateChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelMutation,
    UpdateChannelMutationVariables
  >(UpdateChannelDocument, options);
}
export type UpdateChannelMutationHookResult = ReturnType<
  typeof useUpdateChannelMutation
>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelMutation,
  UpdateChannelMutationVariables
>;
export const UpdateChannelCoverDocument = gql`
  mutation updateChannelCover($input: UpdateChannelCoverInput!) {
    updateChannelCover(input: $input) {
      channel {
        ...Channel
        fileByCoverImageId {
          __typename
          id
          orgId
          metadata
          name
          source
          mimetype
          fileProcessingStatus
          fileOutputsByFileId(first: 10) {
            nodes {
              ...FileOutput
            }
          }
        }
        draft {
          ...Channel
        }
        childOf
        parentChannelByChildOf {
          ...ParentChannel
        }
      }
    }
  }
  ${ChannelFragmentDoc}
  ${FileOutputFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export type UpdateChannelCoverMutationFn = Apollo.MutationFunction<
  UpdateChannelCoverMutation,
  UpdateChannelCoverMutationVariables
>;
export type UpdateChannelCoverProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateChannelCoverMutation,
    UpdateChannelCoverMutationVariables
  >;
} &
  TChildProps;
export function withUpdateChannelCover<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateChannelCoverMutation,
    UpdateChannelCoverMutationVariables,
    UpdateChannelCoverProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateChannelCoverMutation,
    UpdateChannelCoverMutationVariables,
    UpdateChannelCoverProps<TChildProps, TDataName>
  >(UpdateChannelCoverDocument, {
    alias: "updateChannelCover",
    ...operationOptions,
  });
}

/**
 * __useUpdateChannelCoverMutation__
 *
 * To run a mutation, you first call `useUpdateChannelCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelCoverMutation, { data, loading, error }] = useUpdateChannelCoverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelCoverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelCoverMutation,
    UpdateChannelCoverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelCoverMutation,
    UpdateChannelCoverMutationVariables
  >(UpdateChannelCoverDocument, options);
}
export type UpdateChannelCoverMutationHookResult = ReturnType<
  typeof useUpdateChannelCoverMutation
>;
export type UpdateChannelCoverMutationResult = Apollo.MutationResult<UpdateChannelCoverMutation>;
export type UpdateChannelCoverMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelCoverMutation,
  UpdateChannelCoverMutationVariables
>;
export const UpdateChannelNameDocument = gql`
  mutation updateChannelName($input: UpdateChannelNameInput!) {
    updateChannelName(input: $input) {
      channel {
        ...ChannelListItem
      }
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type UpdateChannelNameMutationFn = Apollo.MutationFunction<
  UpdateChannelNameMutation,
  UpdateChannelNameMutationVariables
>;
export type UpdateChannelNameProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateChannelNameMutation,
    UpdateChannelNameMutationVariables
  >;
} &
  TChildProps;
export function withUpdateChannelName<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateChannelNameMutation,
    UpdateChannelNameMutationVariables,
    UpdateChannelNameProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateChannelNameMutation,
    UpdateChannelNameMutationVariables,
    UpdateChannelNameProps<TChildProps, TDataName>
  >(UpdateChannelNameDocument, {
    alias: "updateChannelName",
    ...operationOptions,
  });
}

/**
 * __useUpdateChannelNameMutation__
 *
 * To run a mutation, you first call `useUpdateChannelNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelNameMutation, { data, loading, error }] = useUpdateChannelNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelNameMutation,
    UpdateChannelNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelNameMutation,
    UpdateChannelNameMutationVariables
  >(UpdateChannelNameDocument, options);
}
export type UpdateChannelNameMutationHookResult = ReturnType<
  typeof useUpdateChannelNameMutation
>;
export type UpdateChannelNameMutationResult = Apollo.MutationResult<UpdateChannelNameMutation>;
export type UpdateChannelNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelNameMutation,
  UpdateChannelNameMutationVariables
>;
export const UpdateChannelThemeDocument = gql`
  mutation updateChannelTheme($input: UpdateChannelThemeInput!) {
    updateChannelTheme(input: $input) {
      channel {
        ...Channel
        draft {
          __typename
          id
          themeId
          isPublished
        }
      }
    }
  }
  ${ChannelFragmentDoc}
`;
export type UpdateChannelThemeMutationFn = Apollo.MutationFunction<
  UpdateChannelThemeMutation,
  UpdateChannelThemeMutationVariables
>;
export type UpdateChannelThemeProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateChannelThemeMutation,
    UpdateChannelThemeMutationVariables
  >;
} &
  TChildProps;
export function withUpdateChannelTheme<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateChannelThemeMutation,
    UpdateChannelThemeMutationVariables,
    UpdateChannelThemeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateChannelThemeMutation,
    UpdateChannelThemeMutationVariables,
    UpdateChannelThemeProps<TChildProps, TDataName>
  >(UpdateChannelThemeDocument, {
    alias: "updateChannelTheme",
    ...operationOptions,
  });
}

/**
 * __useUpdateChannelThemeMutation__
 *
 * To run a mutation, you first call `useUpdateChannelThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelThemeMutation, { data, loading, error }] = useUpdateChannelThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelThemeMutation,
    UpdateChannelThemeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelThemeMutation,
    UpdateChannelThemeMutationVariables
  >(UpdateChannelThemeDocument, options);
}
export type UpdateChannelThemeMutationHookResult = ReturnType<
  typeof useUpdateChannelThemeMutation
>;
export type UpdateChannelThemeMutationResult = Apollo.MutationResult<UpdateChannelThemeMutation>;
export type UpdateChannelThemeMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelThemeMutation,
  UpdateChannelThemeMutationVariables
>;
export const AllChannelInSpaceDocument = gql`
  query allChannelInSpace($spaceId: UUID!, $first: Int, $endChannel: Cursor) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      publishedChannelsBySpaceId(
        first: $first
        after: $endChannel
        orderBy: [NAME_ASC, ID_DESC]
        filter: { isEnabled: { equalTo: true } }
      ) {
        nodes {
          ...ChannelListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type AllChannelInSpaceProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllChannelInSpaceQuery,
    AllChannelInSpaceQueryVariables
  >;
} &
  TChildProps;
export function withAllChannelInSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllChannelInSpaceQuery,
    AllChannelInSpaceQueryVariables,
    AllChannelInSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllChannelInSpaceQuery,
    AllChannelInSpaceQueryVariables,
    AllChannelInSpaceProps<TChildProps, TDataName>
  >(AllChannelInSpaceDocument, {
    alias: "allChannelInSpace",
    ...operationOptions,
  });
}

/**
 * __useAllChannelInSpaceQuery__
 *
 * To run a query within a React component, call `useAllChannelInSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChannelInSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChannelInSpaceQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endChannel: // value for 'endChannel'
 *   },
 * });
 */
export function useAllChannelInSpaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllChannelInSpaceQuery,
    AllChannelInSpaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllChannelInSpaceQuery,
    AllChannelInSpaceQueryVariables
  >(AllChannelInSpaceDocument, options);
}
export function useAllChannelInSpaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllChannelInSpaceQuery,
    AllChannelInSpaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllChannelInSpaceQuery,
    AllChannelInSpaceQueryVariables
  >(AllChannelInSpaceDocument, options);
}
export type AllChannelInSpaceQueryHookResult = ReturnType<
  typeof useAllChannelInSpaceQuery
>;
export type AllChannelInSpaceLazyQueryHookResult = ReturnType<
  typeof useAllChannelInSpaceLazyQuery
>;
export type AllChannelInSpaceQueryResult = Apollo.QueryResult<
  AllChannelInSpaceQuery,
  AllChannelInSpaceQueryVariables
>;
export function refetchAllChannelInSpaceQuery(
  variables?: AllChannelInSpaceQueryVariables
) {
  return { query: AllChannelInSpaceDocument, variables: variables };
}
export const AllChannelsDocument = gql`
  query allChannels($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      publishedChannelsBySpaceId(
        orderBy: [NAME_ASC, ID_DESC]
        filter: {
          spaceId: { equalTo: $spaceId }
          type: { equalTo: DEFAULT }
          isEnabled: { equalTo: true }
        }
        isShared: false
      ) {
        nodes {
          __typename
          id
          name
          spaceId
          castedScreenByChannelId {
            nodes {
              __typename
              id
              name
              spaceId
            }
            totalCount
          }
          childOf
          duplicateOf
        }
        totalCount
      }
    }
  }
`;
export type AllChannelsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllChannelsQuery,
    AllChannelsQueryVariables
  >;
} &
  TChildProps;
export function withAllChannels<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllChannelsQuery,
    AllChannelsQueryVariables,
    AllChannelsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllChannelsQuery,
    AllChannelsQueryVariables,
    AllChannelsProps<TChildProps, TDataName>
  >(AllChannelsDocument, {
    alias: "allChannels",
    ...operationOptions,
  });
}

/**
 * __useAllChannelsQuery__
 *
 * To run a query within a React component, call `useAllChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChannelsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllChannelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllChannelsQuery,
    AllChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllChannelsQuery, AllChannelsQueryVariables>(
    AllChannelsDocument,
    options
  );
}
export function useAllChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllChannelsQuery,
    AllChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllChannelsQuery, AllChannelsQueryVariables>(
    AllChannelsDocument,
    options
  );
}
export type AllChannelsQueryHookResult = ReturnType<typeof useAllChannelsQuery>;
export type AllChannelsLazyQueryHookResult = ReturnType<
  typeof useAllChannelsLazyQuery
>;
export type AllChannelsQueryResult = Apollo.QueryResult<
  AllChannelsQuery,
  AllChannelsQueryVariables
>;
export function refetchAllChannelsQuery(variables?: AllChannelsQueryVariables) {
  return { query: AllChannelsDocument, variables: variables };
}
export const AllSearchChannelDocument = gql`
  query allSearchChannel($query: String, $spaceId: UUID) {
    searchChannel(
      query: $query
      spaceId: $spaceId
      orderBy: [NAME_ASC, ID_DESC]
      filter: {
        spaceId: { equalTo: $spaceId }
        type: { equalTo: DEFAULT }
        isEnabled: { equalTo: true }
      }
    ) {
      nodes {
        __typename
        id
        name
        spaceId
        childOf
        duplicateOf
      }
      totalCount
    }
  }
`;
export type AllSearchChannelProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllSearchChannelQuery,
    AllSearchChannelQueryVariables
  >;
} &
  TChildProps;
export function withAllSearchChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSearchChannelQuery,
    AllSearchChannelQueryVariables,
    AllSearchChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSearchChannelQuery,
    AllSearchChannelQueryVariables,
    AllSearchChannelProps<TChildProps, TDataName>
  >(AllSearchChannelDocument, {
    alias: "allSearchChannel",
    ...operationOptions,
  });
}

/**
 * __useAllSearchChannelQuery__
 *
 * To run a query within a React component, call `useAllSearchChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSearchChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSearchChannelQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllSearchChannelQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllSearchChannelQuery,
    AllSearchChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllSearchChannelQuery, AllSearchChannelQueryVariables>(
    AllSearchChannelDocument,
    options
  );
}
export function useAllSearchChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllSearchChannelQuery,
    AllSearchChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllSearchChannelQuery,
    AllSearchChannelQueryVariables
  >(AllSearchChannelDocument, options);
}
export type AllSearchChannelQueryHookResult = ReturnType<
  typeof useAllSearchChannelQuery
>;
export type AllSearchChannelLazyQueryHookResult = ReturnType<
  typeof useAllSearchChannelLazyQuery
>;
export type AllSearchChannelQueryResult = Apollo.QueryResult<
  AllSearchChannelQuery,
  AllSearchChannelQueryVariables
>;
export function refetchAllSearchChannelQuery(
  variables?: AllSearchChannelQueryVariables
) {
  return { query: AllSearchChannelDocument, variables: variables };
}
export const ChannelListDocument = gql`
  query channelList($spaceId: UUID!, $first: Int, $endChannel: Cursor) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      publishedChannelsBySpaceId(
        first: $first
        after: $endChannel
        orderBy: [NAME_ASC, ID_DESC]
        filter: { spaceId: { equalTo: $spaceId }, isEnabled: { equalTo: true } }
        isShared: false
      ) {
        nodes {
          ...ChannelListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type ChannelListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ChannelListQuery,
    ChannelListQueryVariables
  >;
} &
  TChildProps;
export function withChannelList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChannelListQuery,
    ChannelListQueryVariables,
    ChannelListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ChannelListQuery,
    ChannelListQueryVariables,
    ChannelListProps<TChildProps, TDataName>
  >(ChannelListDocument, {
    alias: "channelList",
    ...operationOptions,
  });
}

/**
 * __useChannelListQuery__
 *
 * To run a query within a React component, call `useChannelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelListQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endChannel: // value for 'endChannel'
 *   },
 * });
 */
export function useChannelListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChannelListQuery,
    ChannelListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChannelListQuery, ChannelListQueryVariables>(
    ChannelListDocument,
    options
  );
}
export function useChannelListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChannelListQuery,
    ChannelListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChannelListQuery, ChannelListQueryVariables>(
    ChannelListDocument,
    options
  );
}
export type ChannelListQueryHookResult = ReturnType<typeof useChannelListQuery>;
export type ChannelListLazyQueryHookResult = ReturnType<
  typeof useChannelListLazyQuery
>;
export type ChannelListQueryResult = Apollo.QueryResult<
  ChannelListQuery,
  ChannelListQueryVariables
>;
export function refetchChannelListQuery(variables?: ChannelListQueryVariables) {
  return { query: ChannelListDocument, variables: variables };
}
export const CreateChannelDocument = gql`
  mutation createChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      channel {
        ...ChannelListItem
      }
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type CreateChannelMutationFn = Apollo.MutationFunction<
  CreateChannelMutation,
  CreateChannelMutationVariables
>;
export type CreateChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateChannelMutation,
    CreateChannelMutationVariables
  >;
} &
  TChildProps;
export function withCreateChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateChannelMutation,
    CreateChannelMutationVariables,
    CreateChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateChannelMutation,
    CreateChannelMutationVariables,
    CreateChannelProps<TChildProps, TDataName>
  >(CreateChannelDocument, {
    alias: "createChannel",
    ...operationOptions,
  });
}

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChannelMutation,
    CreateChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChannelMutation,
    CreateChannelMutationVariables
  >(CreateChannelDocument, options);
}
export type CreateChannelMutationHookResult = ReturnType<
  typeof useCreateChannelMutation
>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<
  CreateChannelMutation,
  CreateChannelMutationVariables
>;
export const CurrentOrgStartableChannelsDocument = gql`
  query currentOrgStartableChannels {
    currentOrgStartableChannels {
      nodes {
        ...Channel
      }
    }
  }
  ${ChannelFragmentDoc}
`;
export type CurrentOrgStartableChannelsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CurrentOrgStartableChannelsQuery,
    CurrentOrgStartableChannelsQueryVariables
  >;
} &
  TChildProps;
export function withCurrentOrgStartableChannels<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrentOrgStartableChannelsQuery,
    CurrentOrgStartableChannelsQueryVariables,
    CurrentOrgStartableChannelsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrentOrgStartableChannelsQuery,
    CurrentOrgStartableChannelsQueryVariables,
    CurrentOrgStartableChannelsProps<TChildProps, TDataName>
  >(CurrentOrgStartableChannelsDocument, {
    alias: "currentOrgStartableChannels",
    ...operationOptions,
  });
}

/**
 * __useCurrentOrgStartableChannelsQuery__
 *
 * To run a query within a React component, call `useCurrentOrgStartableChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOrgStartableChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOrgStartableChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentOrgStartableChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentOrgStartableChannelsQuery,
    CurrentOrgStartableChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentOrgStartableChannelsQuery,
    CurrentOrgStartableChannelsQueryVariables
  >(CurrentOrgStartableChannelsDocument, options);
}
export function useCurrentOrgStartableChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentOrgStartableChannelsQuery,
    CurrentOrgStartableChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentOrgStartableChannelsQuery,
    CurrentOrgStartableChannelsQueryVariables
  >(CurrentOrgStartableChannelsDocument, options);
}
export type CurrentOrgStartableChannelsQueryHookResult = ReturnType<
  typeof useCurrentOrgStartableChannelsQuery
>;
export type CurrentOrgStartableChannelsLazyQueryHookResult = ReturnType<
  typeof useCurrentOrgStartableChannelsLazyQuery
>;
export type CurrentOrgStartableChannelsQueryResult = Apollo.QueryResult<
  CurrentOrgStartableChannelsQuery,
  CurrentOrgStartableChannelsQueryVariables
>;
export function refetchCurrentOrgStartableChannelsQuery(
  variables?: CurrentOrgStartableChannelsQueryVariables
) {
  return { query: CurrentOrgStartableChannelsDocument, variables: variables };
}
export const DeleteChannelDocument = gql`
  mutation deleteChannel($input: DeleteChannelInput!) {
    deleteChannel(input: $input) {
      channel {
        __typename
        id
        name
      }
    }
  }
`;
export type DeleteChannelMutationFn = Apollo.MutationFunction<
  DeleteChannelMutation,
  DeleteChannelMutationVariables
>;
export type DeleteChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteChannelMutation,
    DeleteChannelMutationVariables
  >;
} &
  TChildProps;
export function withDeleteChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteChannelMutation,
    DeleteChannelMutationVariables,
    DeleteChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteChannelMutation,
    DeleteChannelMutationVariables,
    DeleteChannelProps<TChildProps, TDataName>
  >(DeleteChannelDocument, {
    alias: "deleteChannel",
    ...operationOptions,
  });
}

/**
 * __useDeleteChannelMutation__
 *
 * To run a mutation, you first call `useDeleteChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelMutation, { data, loading, error }] = useDeleteChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChannelMutation,
    DeleteChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteChannelMutation,
    DeleteChannelMutationVariables
  >(DeleteChannelDocument, options);
}
export type DeleteChannelMutationHookResult = ReturnType<
  typeof useDeleteChannelMutation
>;
export type DeleteChannelMutationResult = Apollo.MutationResult<DeleteChannelMutation>;
export type DeleteChannelMutationOptions = Apollo.BaseMutationOptions<
  DeleteChannelMutation,
  DeleteChannelMutationVariables
>;
export const DuplicateEditableSharedChannelDocument = gql`
  mutation duplicateEditableSharedChannel(
    $input: DuplicateEditableSharedChannelInput!
  ) {
    duplicateEditableSharedChannel(input: $input) {
      channel {
        ...ChannelListItem
      }
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type DuplicateEditableSharedChannelMutationFn = Apollo.MutationFunction<
  DuplicateEditableSharedChannelMutation,
  DuplicateEditableSharedChannelMutationVariables
>;
export type DuplicateEditableSharedChannelProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DuplicateEditableSharedChannelMutation,
    DuplicateEditableSharedChannelMutationVariables
  >;
} &
  TChildProps;
export function withDuplicateEditableSharedChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DuplicateEditableSharedChannelMutation,
    DuplicateEditableSharedChannelMutationVariables,
    DuplicateEditableSharedChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DuplicateEditableSharedChannelMutation,
    DuplicateEditableSharedChannelMutationVariables,
    DuplicateEditableSharedChannelProps<TChildProps, TDataName>
  >(DuplicateEditableSharedChannelDocument, {
    alias: "duplicateEditableSharedChannel",
    ...operationOptions,
  });
}

/**
 * __useDuplicateEditableSharedChannelMutation__
 *
 * To run a mutation, you first call `useDuplicateEditableSharedChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateEditableSharedChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateEditableSharedChannelMutation, { data, loading, error }] = useDuplicateEditableSharedChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateEditableSharedChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateEditableSharedChannelMutation,
    DuplicateEditableSharedChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateEditableSharedChannelMutation,
    DuplicateEditableSharedChannelMutationVariables
  >(DuplicateEditableSharedChannelDocument, options);
}
export type DuplicateEditableSharedChannelMutationHookResult = ReturnType<
  typeof useDuplicateEditableSharedChannelMutation
>;
export type DuplicateEditableSharedChannelMutationResult = Apollo.MutationResult<DuplicateEditableSharedChannelMutation>;
export type DuplicateEditableSharedChannelMutationOptions = Apollo.BaseMutationOptions<
  DuplicateEditableSharedChannelMutation,
  DuplicateEditableSharedChannelMutationVariables
>;
export const LayoutsBySpaceIdDocument = gql`
  query layoutsBySpaceId($spaceId: UUID!) {
    layoutsBySpaceId(spaceId: $spaceId) {
      nodes {
        ...Layout
      }
    }
  }
  ${LayoutFragmentDoc}
`;
export type LayoutsBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LayoutsBySpaceIdQuery,
    LayoutsBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withLayoutsBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LayoutsBySpaceIdQuery,
    LayoutsBySpaceIdQueryVariables,
    LayoutsBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LayoutsBySpaceIdQuery,
    LayoutsBySpaceIdQueryVariables,
    LayoutsBySpaceIdProps<TChildProps, TDataName>
  >(LayoutsBySpaceIdDocument, {
    alias: "layoutsBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useLayoutsBySpaceIdQuery__
 *
 * To run a query within a React component, call `useLayoutsBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutsBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutsBySpaceIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useLayoutsBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    LayoutsBySpaceIdQuery,
    LayoutsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LayoutsBySpaceIdQuery, LayoutsBySpaceIdQueryVariables>(
    LayoutsBySpaceIdDocument,
    options
  );
}
export function useLayoutsBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LayoutsBySpaceIdQuery,
    LayoutsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LayoutsBySpaceIdQuery,
    LayoutsBySpaceIdQueryVariables
  >(LayoutsBySpaceIdDocument, options);
}
export type LayoutsBySpaceIdQueryHookResult = ReturnType<
  typeof useLayoutsBySpaceIdQuery
>;
export type LayoutsBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useLayoutsBySpaceIdLazyQuery
>;
export type LayoutsBySpaceIdQueryResult = Apollo.QueryResult<
  LayoutsBySpaceIdQuery,
  LayoutsBySpaceIdQueryVariables
>;
export function refetchLayoutsBySpaceIdQuery(
  variables?: LayoutsBySpaceIdQueryVariables
) {
  return { query: LayoutsBySpaceIdDocument, variables: variables };
}
export const SearchChannelDocument = gql`
  query searchChannel(
    $query: String
    $spaceId: UUID
    $first: Int
    $endChannel: Cursor
  ) {
    searchChannel(
      query: $query
      spaceId: $spaceId
      first: $first
      after: $endChannel
      orderBy: [NAME_ASC, ID_DESC]
      filter: { spaceId: { equalTo: $spaceId }, isEnabled: { equalTo: true } }
      isShared: false
    ) {
      nodes {
        ...ChannelListItem
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type SearchChannelProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchChannelQuery,
    SearchChannelQueryVariables
  >;
} &
  TChildProps;
export function withSearchChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchChannelQuery,
    SearchChannelQueryVariables,
    SearchChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchChannelQuery,
    SearchChannelQueryVariables,
    SearchChannelProps<TChildProps, TDataName>
  >(SearchChannelDocument, {
    alias: "searchChannel",
    ...operationOptions,
  });
}

/**
 * __useSearchChannelQuery__
 *
 * To run a query within a React component, call `useSearchChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChannelQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endChannel: // value for 'endChannel'
 *   },
 * });
 */
export function useSearchChannelQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchChannelQuery,
    SearchChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchChannelQuery, SearchChannelQueryVariables>(
    SearchChannelDocument,
    options
  );
}
export function useSearchChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchChannelQuery,
    SearchChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchChannelQuery, SearchChannelQueryVariables>(
    SearchChannelDocument,
    options
  );
}
export type SearchChannelQueryHookResult = ReturnType<
  typeof useSearchChannelQuery
>;
export type SearchChannelLazyQueryHookResult = ReturnType<
  typeof useSearchChannelLazyQuery
>;
export type SearchChannelQueryResult = Apollo.QueryResult<
  SearchChannelQuery,
  SearchChannelQueryVariables
>;
export function refetchSearchChannelQuery(
  variables?: SearchChannelQueryVariables
) {
  return { query: SearchChannelDocument, variables: variables };
}
export const SearchSharedChannelDocument = gql`
  query searchSharedChannel(
    $query: String
    $spaceId: UUID
    $first: Int
    $endChannel: Cursor
  ) {
    searchChannel(
      query: $query
      spaceId: $spaceId
      first: $first
      after: $endChannel
      orderBy: [NAME_ASC, ID_DESC]
      filter: {
        spaceId: { notEqualTo: $spaceId }
        isEnabled: { equalTo: true }
      }
      isShared: true
    ) {
      nodes {
        ...ChannelListItem
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${ChannelListItemFragmentDoc}
`;
export type SearchSharedChannelProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchSharedChannelQuery,
    SearchSharedChannelQueryVariables
  >;
} &
  TChildProps;
export function withSearchSharedChannel<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchSharedChannelQuery,
    SearchSharedChannelQueryVariables,
    SearchSharedChannelProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchSharedChannelQuery,
    SearchSharedChannelQueryVariables,
    SearchSharedChannelProps<TChildProps, TDataName>
  >(SearchSharedChannelDocument, {
    alias: "searchSharedChannel",
    ...operationOptions,
  });
}

/**
 * __useSearchSharedChannelQuery__
 *
 * To run a query within a React component, call `useSearchSharedChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSharedChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSharedChannelQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endChannel: // value for 'endChannel'
 *   },
 * });
 */
export function useSearchSharedChannelQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchSharedChannelQuery,
    SearchSharedChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchSharedChannelQuery,
    SearchSharedChannelQueryVariables
  >(SearchSharedChannelDocument, options);
}
export function useSearchSharedChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSharedChannelQuery,
    SearchSharedChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchSharedChannelQuery,
    SearchSharedChannelQueryVariables
  >(SearchSharedChannelDocument, options);
}
export type SearchSharedChannelQueryHookResult = ReturnType<
  typeof useSearchSharedChannelQuery
>;
export type SearchSharedChannelLazyQueryHookResult = ReturnType<
  typeof useSearchSharedChannelLazyQuery
>;
export type SearchSharedChannelQueryResult = Apollo.QueryResult<
  SearchSharedChannelQuery,
  SearchSharedChannelQueryVariables
>;
export function refetchSearchSharedChannelQuery(
  variables?: SearchSharedChannelQueryVariables
) {
  return { query: SearchSharedChannelDocument, variables: variables };
}
export const SharedChannelListDocument = gql`
  query sharedChannelList($spaceId: UUID!, $first: Int, $endChannel: Cursor) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      publishedChannelsBySpaceId(
        first: $first
        after: $endChannel
        orderBy: [NAME_ASC, ID_DESC]
        filter: {
          spaceId: { notEqualTo: $spaceId }
          isEnabled: { equalTo: true }
        }
        isShared: true
      ) {
        nodes {
          ...SharedChannelListItem
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${SharedChannelListItemFragmentDoc}
`;
export type SharedChannelListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SharedChannelListQuery,
    SharedChannelListQueryVariables
  >;
} &
  TChildProps;
export function withSharedChannelList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SharedChannelListQuery,
    SharedChannelListQueryVariables,
    SharedChannelListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SharedChannelListQuery,
    SharedChannelListQueryVariables,
    SharedChannelListProps<TChildProps, TDataName>
  >(SharedChannelListDocument, {
    alias: "sharedChannelList",
    ...operationOptions,
  });
}

/**
 * __useSharedChannelListQuery__
 *
 * To run a query within a React component, call `useSharedChannelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedChannelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedChannelListQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endChannel: // value for 'endChannel'
 *   },
 * });
 */
export function useSharedChannelListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SharedChannelListQuery,
    SharedChannelListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SharedChannelListQuery,
    SharedChannelListQueryVariables
  >(SharedChannelListDocument, options);
}
export function useSharedChannelListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SharedChannelListQuery,
    SharedChannelListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SharedChannelListQuery,
    SharedChannelListQueryVariables
  >(SharedChannelListDocument, options);
}
export type SharedChannelListQueryHookResult = ReturnType<
  typeof useSharedChannelListQuery
>;
export type SharedChannelListLazyQueryHookResult = ReturnType<
  typeof useSharedChannelListLazyQuery
>;
export type SharedChannelListQueryResult = Apollo.QueryResult<
  SharedChannelListQuery,
  SharedChannelListQueryVariables
>;
export function refetchSharedChannelListQuery(
  variables?: SharedChannelListQueryVariables
) {
  return { query: SharedChannelListDocument, variables: variables };
}
export const UpdateChannelEmbedDocument = gql`
  mutation UpdateChannelEmbed($input: UpdateChannelEmbedInput!) {
    updateChannelEmbed(input: $input) {
      channel {
        __typename
        id
        isEmbedEnabled
        isEmbedPublic
      }
    }
  }
`;
export type UpdateChannelEmbedMutationFn = Apollo.MutationFunction<
  UpdateChannelEmbedMutation,
  UpdateChannelEmbedMutationVariables
>;
export type UpdateChannelEmbedProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateChannelEmbedMutation,
    UpdateChannelEmbedMutationVariables
  >;
} &
  TChildProps;
export function withUpdateChannelEmbed<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateChannelEmbedMutation,
    UpdateChannelEmbedMutationVariables,
    UpdateChannelEmbedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateChannelEmbedMutation,
    UpdateChannelEmbedMutationVariables,
    UpdateChannelEmbedProps<TChildProps, TDataName>
  >(UpdateChannelEmbedDocument, {
    alias: "updateChannelEmbed",
    ...operationOptions,
  });
}

/**
 * __useUpdateChannelEmbedMutation__
 *
 * To run a mutation, you first call `useUpdateChannelEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelEmbedMutation, { data, loading, error }] = useUpdateChannelEmbedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelEmbedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelEmbedMutation,
    UpdateChannelEmbedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelEmbedMutation,
    UpdateChannelEmbedMutationVariables
  >(UpdateChannelEmbedDocument, options);
}
export type UpdateChannelEmbedMutationHookResult = ReturnType<
  typeof useUpdateChannelEmbedMutation
>;
export type UpdateChannelEmbedMutationResult = Apollo.MutationResult<UpdateChannelEmbedMutation>;
export type UpdateChannelEmbedMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelEmbedMutation,
  UpdateChannelEmbedMutationVariables
>;
export const UpdateShareChannelToAllSpacesDocument = gql`
  mutation UpdateShareChannelToAllSpaces(
    $input: UpdateShareChannelToAllSpacesInput!
  ) {
    updateShareChannelToAllSpaces(input: $input) {
      channel {
        __typename
        id
        isSharedAll
        isSharedExplicit
      }
    }
  }
`;
export type UpdateShareChannelToAllSpacesMutationFn = Apollo.MutationFunction<
  UpdateShareChannelToAllSpacesMutation,
  UpdateShareChannelToAllSpacesMutationVariables
>;
export type UpdateShareChannelToAllSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateShareChannelToAllSpacesMutation,
    UpdateShareChannelToAllSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateShareChannelToAllSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateShareChannelToAllSpacesMutation,
    UpdateShareChannelToAllSpacesMutationVariables,
    UpdateShareChannelToAllSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateShareChannelToAllSpacesMutation,
    UpdateShareChannelToAllSpacesMutationVariables,
    UpdateShareChannelToAllSpacesProps<TChildProps, TDataName>
  >(UpdateShareChannelToAllSpacesDocument, {
    alias: "updateShareChannelToAllSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateShareChannelToAllSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateShareChannelToAllSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareChannelToAllSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareChannelToAllSpacesMutation, { data, loading, error }] = useUpdateShareChannelToAllSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareChannelToAllSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareChannelToAllSpacesMutation,
    UpdateShareChannelToAllSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareChannelToAllSpacesMutation,
    UpdateShareChannelToAllSpacesMutationVariables
  >(UpdateShareChannelToAllSpacesDocument, options);
}
export type UpdateShareChannelToAllSpacesMutationHookResult = ReturnType<
  typeof useUpdateShareChannelToAllSpacesMutation
>;
export type UpdateShareChannelToAllSpacesMutationResult = Apollo.MutationResult<UpdateShareChannelToAllSpacesMutation>;
export type UpdateShareChannelToAllSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareChannelToAllSpacesMutation,
  UpdateShareChannelToAllSpacesMutationVariables
>;
export const UpdateShareChannelToSpacesDocument = gql`
  mutation UpdateShareChannelToSpaces(
    $input: UpdateShareChannelToSpacesInput!
  ) {
    updateShareChannelToSpaces(input: $input) {
      channel {
        __typename
        id
        isSharedExplicit
      }
    }
  }
`;
export type UpdateShareChannelToSpacesMutationFn = Apollo.MutationFunction<
  UpdateShareChannelToSpacesMutation,
  UpdateShareChannelToSpacesMutationVariables
>;
export type UpdateShareChannelToSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateShareChannelToSpacesMutation,
    UpdateShareChannelToSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateShareChannelToSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateShareChannelToSpacesMutation,
    UpdateShareChannelToSpacesMutationVariables,
    UpdateShareChannelToSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateShareChannelToSpacesMutation,
    UpdateShareChannelToSpacesMutationVariables,
    UpdateShareChannelToSpacesProps<TChildProps, TDataName>
  >(UpdateShareChannelToSpacesDocument, {
    alias: "updateShareChannelToSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateShareChannelToSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateShareChannelToSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareChannelToSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareChannelToSpacesMutation, { data, loading, error }] = useUpdateShareChannelToSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareChannelToSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareChannelToSpacesMutation,
    UpdateShareChannelToSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareChannelToSpacesMutation,
    UpdateShareChannelToSpacesMutationVariables
  >(UpdateShareChannelToSpacesDocument, options);
}
export type UpdateShareChannelToSpacesMutationHookResult = ReturnType<
  typeof useUpdateShareChannelToSpacesMutation
>;
export type UpdateShareChannelToSpacesMutationResult = Apollo.MutationResult<UpdateShareChannelToSpacesMutation>;
export type UpdateShareChannelToSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareChannelToSpacesMutation,
  UpdateShareChannelToSpacesMutationVariables
>;
export const MigrationBillingDocument = gql`
  mutation migrationBilling($input: MigrationBillingInput!) {
    migrationBilling(input: $input) {
      json
    }
  }
`;
export type MigrationBillingMutationFn = Apollo.MutationFunction<
  MigrationBillingMutation,
  MigrationBillingMutationVariables
>;
export type MigrationBillingProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    MigrationBillingMutation,
    MigrationBillingMutationVariables
  >;
} &
  TChildProps;
export function withMigrationBilling<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MigrationBillingMutation,
    MigrationBillingMutationVariables,
    MigrationBillingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MigrationBillingMutation,
    MigrationBillingMutationVariables,
    MigrationBillingProps<TChildProps, TDataName>
  >(MigrationBillingDocument, {
    alias: "migrationBilling",
    ...operationOptions,
  });
}

/**
 * __useMigrationBillingMutation__
 *
 * To run a mutation, you first call `useMigrationBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrationBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrationBillingMutation, { data, loading, error }] = useMigrationBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMigrationBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MigrationBillingMutation,
    MigrationBillingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MigrationBillingMutation,
    MigrationBillingMutationVariables
  >(MigrationBillingDocument, options);
}
export type MigrationBillingMutationHookResult = ReturnType<
  typeof useMigrationBillingMutation
>;
export type MigrationBillingMutationResult = Apollo.MutationResult<MigrationBillingMutation>;
export type MigrationBillingMutationOptions = Apollo.BaseMutationOptions<
  MigrationBillingMutation,
  MigrationBillingMutationVariables
>;
export const MigrationInitDocument = gql`
  mutation migrationInit($input: MigrationInitInput!) {
    migrationInit(input: $input) {
      json
    }
  }
`;
export type MigrationInitMutationFn = Apollo.MutationFunction<
  MigrationInitMutation,
  MigrationInitMutationVariables
>;
export type MigrationInitProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    MigrationInitMutation,
    MigrationInitMutationVariables
  >;
} &
  TChildProps;
export function withMigrationInit<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MigrationInitMutation,
    MigrationInitMutationVariables,
    MigrationInitProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MigrationInitMutation,
    MigrationInitMutationVariables,
    MigrationInitProps<TChildProps, TDataName>
  >(MigrationInitDocument, {
    alias: "migrationInit",
    ...operationOptions,
  });
}

/**
 * __useMigrationInitMutation__
 *
 * To run a mutation, you first call `useMigrationInitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrationInitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrationInitMutation, { data, loading, error }] = useMigrationInitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMigrationInitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MigrationInitMutation,
    MigrationInitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MigrationInitMutation,
    MigrationInitMutationVariables
  >(MigrationInitDocument, options);
}
export type MigrationInitMutationHookResult = ReturnType<
  typeof useMigrationInitMutation
>;
export type MigrationInitMutationResult = Apollo.MutationResult<MigrationInitMutation>;
export type MigrationInitMutationOptions = Apollo.BaseMutationOptions<
  MigrationInitMutation,
  MigrationInitMutationVariables
>;
export const AllLinksDocument = gql`
  query allLinks($spaceId: UUID!, $orderBy: [LinksOrderBy!]) {
    spaceById(id: $spaceId) {
      __typename
      id
      linksBySpaceId(orderBy: $orderBy) {
        nodes {
          __typename
          id
        }
        totalCount
      }
    }
  }
`;
export type AllLinksProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllLinksQuery,
    AllLinksQueryVariables
  >;
} &
  TChildProps;
export function withAllLinks<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllLinksQuery,
    AllLinksQueryVariables,
    AllLinksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllLinksQuery,
    AllLinksQueryVariables,
    AllLinksProps<TChildProps, TDataName>
  >(AllLinksDocument, {
    alias: "allLinks",
    ...operationOptions,
  });
}

/**
 * __useAllLinksQuery__
 *
 * To run a query within a React component, call `useAllLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLinksQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllLinksQuery(
  baseOptions: Apollo.QueryHookOptions<AllLinksQuery, AllLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllLinksQuery, AllLinksQueryVariables>(
    AllLinksDocument,
    options
  );
}
export function useAllLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllLinksQuery,
    AllLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllLinksQuery, AllLinksQueryVariables>(
    AllLinksDocument,
    options
  );
}
export type AllLinksQueryHookResult = ReturnType<typeof useAllLinksQuery>;
export type AllLinksLazyQueryHookResult = ReturnType<
  typeof useAllLinksLazyQuery
>;
export type AllLinksQueryResult = Apollo.QueryResult<
  AllLinksQuery,
  AllLinksQueryVariables
>;
export function refetchAllLinksQuery(variables?: AllLinksQueryVariables) {
  return { query: AllLinksDocument, variables: variables };
}
export const AllSearchLinksDocument = gql`
  query allSearchLinks($query: String, $spaceId: UUID) {
    searchLink(query: $query, spaceId: $spaceId) {
      nodes {
        __typename
        id
      }
      totalCount
    }
  }
`;
export type AllSearchLinksProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllSearchLinksQuery,
    AllSearchLinksQueryVariables
  >;
} &
  TChildProps;
export function withAllSearchLinks<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSearchLinksQuery,
    AllSearchLinksQueryVariables,
    AllSearchLinksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSearchLinksQuery,
    AllSearchLinksQueryVariables,
    AllSearchLinksProps<TChildProps, TDataName>
  >(AllSearchLinksDocument, {
    alias: "allSearchLinks",
    ...operationOptions,
  });
}

/**
 * __useAllSearchLinksQuery__
 *
 * To run a query within a React component, call `useAllSearchLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSearchLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSearchLinksQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllSearchLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllSearchLinksQuery,
    AllSearchLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllSearchLinksQuery, AllSearchLinksQueryVariables>(
    AllSearchLinksDocument,
    options
  );
}
export function useAllSearchLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllSearchLinksQuery,
    AllSearchLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllSearchLinksQuery, AllSearchLinksQueryVariables>(
    AllSearchLinksDocument,
    options
  );
}
export type AllSearchLinksQueryHookResult = ReturnType<
  typeof useAllSearchLinksQuery
>;
export type AllSearchLinksLazyQueryHookResult = ReturnType<
  typeof useAllSearchLinksLazyQuery
>;
export type AllSearchLinksQueryResult = Apollo.QueryResult<
  AllSearchLinksQuery,
  AllSearchLinksQueryVariables
>;
export function refetchAllSearchLinksQuery(
  variables?: AllSearchLinksQueryVariables
) {
  return { query: AllSearchLinksDocument, variables: variables };
}
export const CreateLinkDocument = gql`
  mutation createLink($input: CreateLinkInput!) {
    createLink(input: $input) {
      link {
        ...LinkListItem
      }
    }
  }
  ${LinkListItemFragmentDoc}
`;
export type CreateLinkMutationFn = Apollo.MutationFunction<
  CreateLinkMutation,
  CreateLinkMutationVariables
>;
export type CreateLinkProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateLinkMutation,
    CreateLinkMutationVariables
  >;
} &
  TChildProps;
export function withCreateLink<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateLinkMutation,
    CreateLinkMutationVariables,
    CreateLinkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateLinkMutation,
    CreateLinkMutationVariables,
    CreateLinkProps<TChildProps, TDataName>
  >(CreateLinkDocument, {
    alias: "createLink",
    ...operationOptions,
  });
}

/**
 * __useCreateLinkMutation__
 *
 * To run a mutation, you first call `useCreateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkMutation, { data, loading, error }] = useCreateLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLinkMutation,
    CreateLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLinkMutation, CreateLinkMutationVariables>(
    CreateLinkDocument,
    options
  );
}
export type CreateLinkMutationHookResult = ReturnType<
  typeof useCreateLinkMutation
>;
export type CreateLinkMutationResult = Apollo.MutationResult<CreateLinkMutation>;
export type CreateLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateLinkMutation,
  CreateLinkMutationVariables
>;
export const DeleteLinkByIdDocument = gql`
  mutation deleteLinkById($input: DeleteLinkByIdInput!) {
    deleteLinkById(input: $input) {
      link {
        ...LinkListItem
      }
    }
  }
  ${LinkListItemFragmentDoc}
`;
export type DeleteLinkByIdMutationFn = Apollo.MutationFunction<
  DeleteLinkByIdMutation,
  DeleteLinkByIdMutationVariables
>;
export type DeleteLinkByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteLinkByIdMutation,
    DeleteLinkByIdMutationVariables
  >;
} &
  TChildProps;
export function withDeleteLinkById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteLinkByIdMutation,
    DeleteLinkByIdMutationVariables,
    DeleteLinkByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteLinkByIdMutation,
    DeleteLinkByIdMutationVariables,
    DeleteLinkByIdProps<TChildProps, TDataName>
  >(DeleteLinkByIdDocument, {
    alias: "deleteLinkById",
    ...operationOptions,
  });
}

/**
 * __useDeleteLinkByIdMutation__
 *
 * To run a mutation, you first call `useDeleteLinkByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkByIdMutation, { data, loading, error }] = useDeleteLinkByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLinkByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLinkByIdMutation,
    DeleteLinkByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLinkByIdMutation,
    DeleteLinkByIdMutationVariables
  >(DeleteLinkByIdDocument, options);
}
export type DeleteLinkByIdMutationHookResult = ReturnType<
  typeof useDeleteLinkByIdMutation
>;
export type DeleteLinkByIdMutationResult = Apollo.MutationResult<DeleteLinkByIdMutation>;
export type DeleteLinkByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteLinkByIdMutation,
  DeleteLinkByIdMutationVariables
>;
export const LinkByIdForContentDocument = gql`
  query linkByIdForContent($id: UUID!) {
    linkById(id: $id) {
      __typename
      id
      orgId
      url
      fileByFileId {
        __typename
        id
        orgId
        metadata
        source
        mimetype
        fileProcessingStatus
      }
      linkType
      cloudConfig
      name
    }
  }
`;
export type LinkByIdForContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables
  >;
} &
  TChildProps;
export function withLinkByIdForContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables,
    LinkByIdForContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables,
    LinkByIdForContentProps<TChildProps, TDataName>
  >(LinkByIdForContentDocument, {
    alias: "linkByIdForContent",
    ...operationOptions,
  });
}

/**
 * __useLinkByIdForContentQuery__
 *
 * To run a query within a React component, call `useLinkByIdForContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkByIdForContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkByIdForContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkByIdForContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables
  >(LinkByIdForContentDocument, options);
}
export function useLinkByIdForContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkByIdForContentQuery,
    LinkByIdForContentQueryVariables
  >(LinkByIdForContentDocument, options);
}
export type LinkByIdForContentQueryHookResult = ReturnType<
  typeof useLinkByIdForContentQuery
>;
export type LinkByIdForContentLazyQueryHookResult = ReturnType<
  typeof useLinkByIdForContentLazyQuery
>;
export type LinkByIdForContentQueryResult = Apollo.QueryResult<
  LinkByIdForContentQuery,
  LinkByIdForContentQueryVariables
>;
export function refetchLinkByIdForContentQuery(
  variables?: LinkByIdForContentQueryVariables
) {
  return { query: LinkByIdForContentDocument, variables: variables };
}
export const LinkByIdDocument = gql`
  query linkById($id: UUID!) {
    linkById(id: $id) {
      ...Link
      castedScreenByLinkId {
        nodes {
          __typename
          id
          name
          deviceId
          deviceModel
        }
        totalCount
      }
      userByCreatedBy {
        ...User
      }
    }
  }
  ${LinkFragmentDoc}
  ${UserFragmentDoc}
`;
export type LinkByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LinkByIdQuery,
    LinkByIdQueryVariables
  >;
} &
  TChildProps;
export function withLinkById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LinkByIdQuery,
    LinkByIdQueryVariables,
    LinkByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LinkByIdQuery,
    LinkByIdQueryVariables,
    LinkByIdProps<TChildProps, TDataName>
  >(LinkByIdDocument, {
    alias: "linkById",
    ...operationOptions,
  });
}

/**
 * __useLinkByIdQuery__
 *
 * To run a query within a React component, call `useLinkByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkByIdQuery(
  baseOptions: Apollo.QueryHookOptions<LinkByIdQuery, LinkByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinkByIdQuery, LinkByIdQueryVariables>(
    LinkByIdDocument,
    options
  );
}
export function useLinkByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkByIdQuery,
    LinkByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinkByIdQuery, LinkByIdQueryVariables>(
    LinkByIdDocument,
    options
  );
}
export type LinkByIdQueryHookResult = ReturnType<typeof useLinkByIdQuery>;
export type LinkByIdLazyQueryHookResult = ReturnType<
  typeof useLinkByIdLazyQuery
>;
export type LinkByIdQueryResult = Apollo.QueryResult<
  LinkByIdQuery,
  LinkByIdQueryVariables
>;
export function refetchLinkByIdQuery(variables?: LinkByIdQueryVariables) {
  return { query: LinkByIdDocument, variables: variables };
}
export const LinkByIdAssociationsDocument = gql`
  query linkByIdAssociations($id: UUID!) {
    linkById(id: $id) {
      associationsByToLinkAndOrgId {
        nodes {
          ...Associations
        }
        totalCount
      }
    }
  }
  ${AssociationsFragmentDoc}
`;
export type LinkByIdAssociationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LinkByIdAssociationsQuery,
    LinkByIdAssociationsQueryVariables
  >;
} &
  TChildProps;
export function withLinkByIdAssociations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LinkByIdAssociationsQuery,
    LinkByIdAssociationsQueryVariables,
    LinkByIdAssociationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LinkByIdAssociationsQuery,
    LinkByIdAssociationsQueryVariables,
    LinkByIdAssociationsProps<TChildProps, TDataName>
  >(LinkByIdAssociationsDocument, {
    alias: "linkByIdAssociations",
    ...operationOptions,
  });
}

/**
 * __useLinkByIdAssociationsQuery__
 *
 * To run a query within a React component, call `useLinkByIdAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkByIdAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkByIdAssociationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkByIdAssociationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkByIdAssociationsQuery,
    LinkByIdAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkByIdAssociationsQuery,
    LinkByIdAssociationsQueryVariables
  >(LinkByIdAssociationsDocument, options);
}
export function useLinkByIdAssociationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkByIdAssociationsQuery,
    LinkByIdAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkByIdAssociationsQuery,
    LinkByIdAssociationsQueryVariables
  >(LinkByIdAssociationsDocument, options);
}
export type LinkByIdAssociationsQueryHookResult = ReturnType<
  typeof useLinkByIdAssociationsQuery
>;
export type LinkByIdAssociationsLazyQueryHookResult = ReturnType<
  typeof useLinkByIdAssociationsLazyQuery
>;
export type LinkByIdAssociationsQueryResult = Apollo.QueryResult<
  LinkByIdAssociationsQuery,
  LinkByIdAssociationsQueryVariables
>;
export function refetchLinkByIdAssociationsQuery(
  variables?: LinkByIdAssociationsQueryVariables
) {
  return { query: LinkByIdAssociationsDocument, variables: variables };
}
export const LinkListDocument = gql`
  query linkList(
    $spaceId: UUID!
    $endCursor: Cursor
    $orderBy: [LinksOrderBy!]
  ) {
    spaceById(id: $spaceId) {
      __typename
      id
      linksBySpaceId(first: 30, after: $endCursor, orderBy: $orderBy) {
        nodes {
          ...LinkListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${LinkListItemFragmentDoc}
`;
export type LinkListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LinkListQuery,
    LinkListQueryVariables
  >;
} &
  TChildProps;
export function withLinkList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LinkListQuery,
    LinkListQueryVariables,
    LinkListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LinkListQuery,
    LinkListQueryVariables,
    LinkListProps<TChildProps, TDataName>
  >(LinkListDocument, {
    alias: "linkList",
    ...operationOptions,
  });
}

/**
 * __useLinkListQuery__
 *
 * To run a query within a React component, call `useLinkListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkListQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      endCursor: // value for 'endCursor'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLinkListQuery(
  baseOptions: Apollo.QueryHookOptions<LinkListQuery, LinkListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinkListQuery, LinkListQueryVariables>(
    LinkListDocument,
    options
  );
}
export function useLinkListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkListQuery,
    LinkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinkListQuery, LinkListQueryVariables>(
    LinkListDocument,
    options
  );
}
export type LinkListQueryHookResult = ReturnType<typeof useLinkListQuery>;
export type LinkListLazyQueryHookResult = ReturnType<
  typeof useLinkListLazyQuery
>;
export type LinkListQueryResult = Apollo.QueryResult<
  LinkListQuery,
  LinkListQueryVariables
>;
export function refetchLinkListQuery(variables?: LinkListQueryVariables) {
  return { query: LinkListDocument, variables: variables };
}
export const SearchLinkDocument = gql`
  query searchLink($query: String, $spaceId: UUID, $endCursor: Cursor) {
    searchLink(query: $query, spaceId: $spaceId, first: 30, after: $endCursor) {
      nodes {
        ...LinkListItem
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${LinkListItemFragmentDoc}
`;
export type SearchLinkProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchLinkQuery,
    SearchLinkQueryVariables
  >;
} &
  TChildProps;
export function withSearchLink<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchLinkQuery,
    SearchLinkQueryVariables,
    SearchLinkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchLinkQuery,
    SearchLinkQueryVariables,
    SearchLinkProps<TChildProps, TDataName>
  >(SearchLinkDocument, {
    alias: "searchLink",
    ...operationOptions,
  });
}

/**
 * __useSearchLinkQuery__
 *
 * To run a query within a React component, call `useSearchLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLinkQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useSearchLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchLinkQuery,
    SearchLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchLinkQuery, SearchLinkQueryVariables>(
    SearchLinkDocument,
    options
  );
}
export function useSearchLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchLinkQuery,
    SearchLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchLinkQuery, SearchLinkQueryVariables>(
    SearchLinkDocument,
    options
  );
}
export type SearchLinkQueryHookResult = ReturnType<typeof useSearchLinkQuery>;
export type SearchLinkLazyQueryHookResult = ReturnType<
  typeof useSearchLinkLazyQuery
>;
export type SearchLinkQueryResult = Apollo.QueryResult<
  SearchLinkQuery,
  SearchLinkQueryVariables
>;
export function refetchSearchLinkQuery(variables?: SearchLinkQueryVariables) {
  return { query: SearchLinkDocument, variables: variables };
}
export const UpdateLinkByIdDocument = gql`
  mutation updateLinkById($input: UpdateLinkByIdInput!) {
    updateLinkById(input: $input) {
      link {
        ...LinkListItem
      }
    }
  }
  ${LinkListItemFragmentDoc}
`;
export type UpdateLinkByIdMutationFn = Apollo.MutationFunction<
  UpdateLinkByIdMutation,
  UpdateLinkByIdMutationVariables
>;
export type UpdateLinkByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateLinkByIdMutation,
    UpdateLinkByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateLinkById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateLinkByIdMutation,
    UpdateLinkByIdMutationVariables,
    UpdateLinkByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateLinkByIdMutation,
    UpdateLinkByIdMutationVariables,
    UpdateLinkByIdProps<TChildProps, TDataName>
  >(UpdateLinkByIdDocument, {
    alias: "updateLinkById",
    ...operationOptions,
  });
}

/**
 * __useUpdateLinkByIdMutation__
 *
 * To run a mutation, you first call `useUpdateLinkByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkByIdMutation, { data, loading, error }] = useUpdateLinkByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLinkByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLinkByIdMutation,
    UpdateLinkByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLinkByIdMutation,
    UpdateLinkByIdMutationVariables
  >(UpdateLinkByIdDocument, options);
}
export type UpdateLinkByIdMutationHookResult = ReturnType<
  typeof useUpdateLinkByIdMutation
>;
export type UpdateLinkByIdMutationResult = Apollo.MutationResult<UpdateLinkByIdMutation>;
export type UpdateLinkByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateLinkByIdMutation,
  UpdateLinkByIdMutationVariables
>;
export const AvailableFoldersBySpaceIdDocument = gql`
  query availableFoldersBySpaceId($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      availableFoldersBySpaceId {
        nodes {
          ...Folder
          foldersByParentId {
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
          filesByFolderId {
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
          sharedSpacesByFolderId {
            nodes {
              __typename
              id
            }
          }
        }
      }
    }
  }
  ${FolderFragmentDoc}
`;
export type AvailableFoldersBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailableFoldersBySpaceIdQuery,
    AvailableFoldersBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withAvailableFoldersBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailableFoldersBySpaceIdQuery,
    AvailableFoldersBySpaceIdQueryVariables,
    AvailableFoldersBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailableFoldersBySpaceIdQuery,
    AvailableFoldersBySpaceIdQueryVariables,
    AvailableFoldersBySpaceIdProps<TChildProps, TDataName>
  >(AvailableFoldersBySpaceIdDocument, {
    alias: "availableFoldersBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useAvailableFoldersBySpaceIdQuery__
 *
 * To run a query within a React component, call `useAvailableFoldersBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableFoldersBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableFoldersBySpaceIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAvailableFoldersBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableFoldersBySpaceIdQuery,
    AvailableFoldersBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableFoldersBySpaceIdQuery,
    AvailableFoldersBySpaceIdQueryVariables
  >(AvailableFoldersBySpaceIdDocument, options);
}
export function useAvailableFoldersBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableFoldersBySpaceIdQuery,
    AvailableFoldersBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableFoldersBySpaceIdQuery,
    AvailableFoldersBySpaceIdQueryVariables
  >(AvailableFoldersBySpaceIdDocument, options);
}
export type AvailableFoldersBySpaceIdQueryHookResult = ReturnType<
  typeof useAvailableFoldersBySpaceIdQuery
>;
export type AvailableFoldersBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useAvailableFoldersBySpaceIdLazyQuery
>;
export type AvailableFoldersBySpaceIdQueryResult = Apollo.QueryResult<
  AvailableFoldersBySpaceIdQuery,
  AvailableFoldersBySpaceIdQueryVariables
>;
export function refetchAvailableFoldersBySpaceIdQuery(
  variables?: AvailableFoldersBySpaceIdQueryVariables
) {
  return { query: AvailableFoldersBySpaceIdDocument, variables: variables };
}
export const CreateFileDocument = gql`
  mutation createFile($input: CreateFileInput!) {
    createFile(input: $input) {
      file {
        ...FileListItem
      }
    }
  }
  ${FileListItemFragmentDoc}
`;
export type CreateFileMutationFn = Apollo.MutationFunction<
  CreateFileMutation,
  CreateFileMutationVariables
>;
export type CreateFileProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateFileMutation,
    CreateFileMutationVariables
  >;
} &
  TChildProps;
export function withCreateFile<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateFileMutation,
    CreateFileMutationVariables,
    CreateFileProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateFileMutation,
    CreateFileMutationVariables,
    CreateFileProps<TChildProps, TDataName>
  >(CreateFileDocument, {
    alias: "createFile",
    ...operationOptions,
  });
}

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFileMutation,
    CreateFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFileMutation, CreateFileMutationVariables>(
    CreateFileDocument,
    options
  );
}
export type CreateFileMutationHookResult = ReturnType<
  typeof useCreateFileMutation
>;
export type CreateFileMutationResult = Apollo.MutationResult<CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<
  CreateFileMutation,
  CreateFileMutationVariables
>;
export const CreateFolderDocument = gql`
  mutation createFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      folder {
        ...Folder
        foldersByParentId {
          totalCount
        }
        filesByFolderId {
          nodes {
            ...File
            fileOutputsByFileId {
              nodes {
                ...FileOutput
              }
              totalCount
            }
          }
          totalCount
        }
        sharedSpacesByFolderId {
          nodes {
            __typename
            id
          }
        }
      }
    }
  }
  ${FolderFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
`;
export type CreateFolderMutationFn = Apollo.MutationFunction<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;
export type CreateFolderProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >;
} &
  TChildProps;
export function withCreateFolder<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateFolderMutation,
    CreateFolderMutationVariables,
    CreateFolderProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateFolderMutation,
    CreateFolderMutationVariables,
    CreateFolderProps<TChildProps, TDataName>
  >(CreateFolderDocument, {
    alias: "createFolder",
    ...operationOptions,
  });
}

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >(CreateFolderDocument, options);
}
export type CreateFolderMutationHookResult = ReturnType<
  typeof useCreateFolderMutation
>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;
export const DeleteFolderByIdsDocument = gql`
  mutation deleteFolderByIds($input: DeleteFolderByIdsInput!) {
    deleteFolderByIds(input: $input) {
      folders {
        ...Folder
      }
    }
  }
  ${FolderFragmentDoc}
`;
export type DeleteFolderByIdsMutationFn = Apollo.MutationFunction<
  DeleteFolderByIdsMutation,
  DeleteFolderByIdsMutationVariables
>;
export type DeleteFolderByIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteFolderByIdsMutation,
    DeleteFolderByIdsMutationVariables
  >;
} &
  TChildProps;
export function withDeleteFolderByIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteFolderByIdsMutation,
    DeleteFolderByIdsMutationVariables,
    DeleteFolderByIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteFolderByIdsMutation,
    DeleteFolderByIdsMutationVariables,
    DeleteFolderByIdsProps<TChildProps, TDataName>
  >(DeleteFolderByIdsDocument, {
    alias: "deleteFolderByIds",
    ...operationOptions,
  });
}

/**
 * __useDeleteFolderByIdsMutation__
 *
 * To run a mutation, you first call `useDeleteFolderByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderByIdsMutation, { data, loading, error }] = useDeleteFolderByIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFolderByIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFolderByIdsMutation,
    DeleteFolderByIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFolderByIdsMutation,
    DeleteFolderByIdsMutationVariables
  >(DeleteFolderByIdsDocument, options);
}
export type DeleteFolderByIdsMutationHookResult = ReturnType<
  typeof useDeleteFolderByIdsMutation
>;
export type DeleteFolderByIdsMutationResult = Apollo.MutationResult<DeleteFolderByIdsMutation>;
export type DeleteFolderByIdsMutationOptions = Apollo.BaseMutationOptions<
  DeleteFolderByIdsMutation,
  DeleteFolderByIdsMutationVariables
>;
export const FileByIdDocument = gql`
  query fileById($id: UUID!) {
    fileById(id: $id) {
      ...File
      castedScreenByFileId {
        nodes {
          id
          name
          device
        }
        totalCount
      }
      fileOutputsByFileId {
        nodes {
          ...FileOutput
        }
        totalCount
      }
      userByCreatedBy {
        ...User
      }
    }
  }
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${UserFragmentDoc}
`;
export type FileByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FileByIdQuery,
    FileByIdQueryVariables
  >;
} &
  TChildProps;
export function withFileById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FileByIdQuery,
    FileByIdQueryVariables,
    FileByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FileByIdQuery,
    FileByIdQueryVariables,
    FileByIdProps<TChildProps, TDataName>
  >(FileByIdDocument, {
    alias: "fileById",
    ...operationOptions,
  });
}

/**
 * __useFileByIdQuery__
 *
 * To run a query within a React component, call `useFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FileByIdQuery, FileByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileByIdQuery, FileByIdQueryVariables>(
    FileByIdDocument,
    options
  );
}
export function useFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileByIdQuery,
    FileByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileByIdQuery, FileByIdQueryVariables>(
    FileByIdDocument,
    options
  );
}
export type FileByIdQueryHookResult = ReturnType<typeof useFileByIdQuery>;
export type FileByIdLazyQueryHookResult = ReturnType<
  typeof useFileByIdLazyQuery
>;
export type FileByIdQueryResult = Apollo.QueryResult<
  FileByIdQuery,
  FileByIdQueryVariables
>;
export function refetchFileByIdQuery(variables?: FileByIdQueryVariables) {
  return { query: FileByIdDocument, variables: variables };
}
export const FileByIdAssociationsDocument = gql`
  query fileByIdAssociations($id: UUID!) {
    fileById(id: $id) {
      associationsByToFileAndOrgId {
        nodes {
          ...FileAssociations
        }
        totalCount
      }
    }
  }
  ${FileAssociationsFragmentDoc}
`;
export type FileByIdAssociationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FileByIdAssociationsQuery,
    FileByIdAssociationsQueryVariables
  >;
} &
  TChildProps;
export function withFileByIdAssociations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FileByIdAssociationsQuery,
    FileByIdAssociationsQueryVariables,
    FileByIdAssociationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FileByIdAssociationsQuery,
    FileByIdAssociationsQueryVariables,
    FileByIdAssociationsProps<TChildProps, TDataName>
  >(FileByIdAssociationsDocument, {
    alias: "fileByIdAssociations",
    ...operationOptions,
  });
}

/**
 * __useFileByIdAssociationsQuery__
 *
 * To run a query within a React component, call `useFileByIdAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileByIdAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileByIdAssociationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileByIdAssociationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FileByIdAssociationsQuery,
    FileByIdAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FileByIdAssociationsQuery,
    FileByIdAssociationsQueryVariables
  >(FileByIdAssociationsDocument, options);
}
export function useFileByIdAssociationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileByIdAssociationsQuery,
    FileByIdAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FileByIdAssociationsQuery,
    FileByIdAssociationsQueryVariables
  >(FileByIdAssociationsDocument, options);
}
export type FileByIdAssociationsQueryHookResult = ReturnType<
  typeof useFileByIdAssociationsQuery
>;
export type FileByIdAssociationsLazyQueryHookResult = ReturnType<
  typeof useFileByIdAssociationsLazyQuery
>;
export type FileByIdAssociationsQueryResult = Apollo.QueryResult<
  FileByIdAssociationsQuery,
  FileByIdAssociationsQueryVariables
>;
export function refetchFileByIdAssociationsQuery(
  variables?: FileByIdAssociationsQueryVariables
) {
  return { query: FileByIdAssociationsDocument, variables: variables };
}
export const FileByIdExtendedAssociationsDocument = gql`
  query fileByIdExtendedAssociations($id: UUID!, $spaceId: UUID!) {
    fileById(id: $id) {
      ...File
      castedScreenByFileId {
        nodes {
          id
          name
          device
        }
        totalCount
      }
      fileOutputsByFileId {
        nodes {
          ...FileOutput
        }
        totalCount
      }
      userByCreatedBy {
        ...User
      }
      associationsByFileAndSpaceId(spaceId: $spaceId) {
        nodes {
          ...PlaylistAndChannelAssociations
        }
      }
    }
  }
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${UserFragmentDoc}
  ${PlaylistAndChannelAssociationsFragmentDoc}
`;
export type FileByIdExtendedAssociationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FileByIdExtendedAssociationsQuery,
    FileByIdExtendedAssociationsQueryVariables
  >;
} &
  TChildProps;
export function withFileByIdExtendedAssociations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FileByIdExtendedAssociationsQuery,
    FileByIdExtendedAssociationsQueryVariables,
    FileByIdExtendedAssociationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FileByIdExtendedAssociationsQuery,
    FileByIdExtendedAssociationsQueryVariables,
    FileByIdExtendedAssociationsProps<TChildProps, TDataName>
  >(FileByIdExtendedAssociationsDocument, {
    alias: "fileByIdExtendedAssociations",
    ...operationOptions,
  });
}

/**
 * __useFileByIdExtendedAssociationsQuery__
 *
 * To run a query within a React component, call `useFileByIdExtendedAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileByIdExtendedAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileByIdExtendedAssociationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useFileByIdExtendedAssociationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FileByIdExtendedAssociationsQuery,
    FileByIdExtendedAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FileByIdExtendedAssociationsQuery,
    FileByIdExtendedAssociationsQueryVariables
  >(FileByIdExtendedAssociationsDocument, options);
}
export function useFileByIdExtendedAssociationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileByIdExtendedAssociationsQuery,
    FileByIdExtendedAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FileByIdExtendedAssociationsQuery,
    FileByIdExtendedAssociationsQueryVariables
  >(FileByIdExtendedAssociationsDocument, options);
}
export type FileByIdExtendedAssociationsQueryHookResult = ReturnType<
  typeof useFileByIdExtendedAssociationsQuery
>;
export type FileByIdExtendedAssociationsLazyQueryHookResult = ReturnType<
  typeof useFileByIdExtendedAssociationsLazyQuery
>;
export type FileByIdExtendedAssociationsQueryResult = Apollo.QueryResult<
  FileByIdExtendedAssociationsQuery,
  FileByIdExtendedAssociationsQueryVariables
>;
export function refetchFileByIdExtendedAssociationsQuery(
  variables?: FileByIdExtendedAssociationsQueryVariables
) {
  return { query: FileByIdExtendedAssociationsDocument, variables: variables };
}
export const FileByIdExtendedQueryStringDocument = gql`
  query fileByIdExtendedQueryString($fileId: UUID!) {
    fileById(id: $fileId) {
      ...File
      fileOutputsByFileId {
        nodes {
          ...FileOutput
        }
        totalCount
      }
    }
  }
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
`;
export type FileByIdExtendedQueryStringProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FileByIdExtendedQueryStringQuery,
    FileByIdExtendedQueryStringQueryVariables
  >;
} &
  TChildProps;
export function withFileByIdExtendedQueryString<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FileByIdExtendedQueryStringQuery,
    FileByIdExtendedQueryStringQueryVariables,
    FileByIdExtendedQueryStringProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FileByIdExtendedQueryStringQuery,
    FileByIdExtendedQueryStringQueryVariables,
    FileByIdExtendedQueryStringProps<TChildProps, TDataName>
  >(FileByIdExtendedQueryStringDocument, {
    alias: "fileByIdExtendedQueryString",
    ...operationOptions,
  });
}

/**
 * __useFileByIdExtendedQueryStringQuery__
 *
 * To run a query within a React component, call `useFileByIdExtendedQueryStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileByIdExtendedQueryStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileByIdExtendedQueryStringQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileByIdExtendedQueryStringQuery(
  baseOptions: Apollo.QueryHookOptions<
    FileByIdExtendedQueryStringQuery,
    FileByIdExtendedQueryStringQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FileByIdExtendedQueryStringQuery,
    FileByIdExtendedQueryStringQueryVariables
  >(FileByIdExtendedQueryStringDocument, options);
}
export function useFileByIdExtendedQueryStringLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileByIdExtendedQueryStringQuery,
    FileByIdExtendedQueryStringQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FileByIdExtendedQueryStringQuery,
    FileByIdExtendedQueryStringQueryVariables
  >(FileByIdExtendedQueryStringDocument, options);
}
export type FileByIdExtendedQueryStringQueryHookResult = ReturnType<
  typeof useFileByIdExtendedQueryStringQuery
>;
export type FileByIdExtendedQueryStringLazyQueryHookResult = ReturnType<
  typeof useFileByIdExtendedQueryStringLazyQuery
>;
export type FileByIdExtendedQueryStringQueryResult = Apollo.QueryResult<
  FileByIdExtendedQueryStringQuery,
  FileByIdExtendedQueryStringQueryVariables
>;
export function refetchFileByIdExtendedQueryStringQuery(
  variables?: FileByIdExtendedQueryStringQueryVariables
) {
  return { query: FileByIdExtendedQueryStringDocument, variables: variables };
}
export const FileByIdForContentDocument = gql`
  query fileByIdForContent($id: UUID!) {
    fileById(id: $id) {
      ...FileListItem
    }
  }
  ${FileListItemFragmentDoc}
`;
export type FileByIdForContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FileByIdForContentQuery,
    FileByIdForContentQueryVariables
  >;
} &
  TChildProps;
export function withFileByIdForContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FileByIdForContentQuery,
    FileByIdForContentQueryVariables,
    FileByIdForContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FileByIdForContentQuery,
    FileByIdForContentQueryVariables,
    FileByIdForContentProps<TChildProps, TDataName>
  >(FileByIdForContentDocument, {
    alias: "fileByIdForContent",
    ...operationOptions,
  });
}

/**
 * __useFileByIdForContentQuery__
 *
 * To run a query within a React component, call `useFileByIdForContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileByIdForContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileByIdForContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileByIdForContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    FileByIdForContentQuery,
    FileByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FileByIdForContentQuery,
    FileByIdForContentQueryVariables
  >(FileByIdForContentDocument, options);
}
export function useFileByIdForContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileByIdForContentQuery,
    FileByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FileByIdForContentQuery,
    FileByIdForContentQueryVariables
  >(FileByIdForContentDocument, options);
}
export type FileByIdForContentQueryHookResult = ReturnType<
  typeof useFileByIdForContentQuery
>;
export type FileByIdForContentLazyQueryHookResult = ReturnType<
  typeof useFileByIdForContentLazyQuery
>;
export type FileByIdForContentQueryResult = Apollo.QueryResult<
  FileByIdForContentQuery,
  FileByIdForContentQueryVariables
>;
export function refetchFileByIdForContentQuery(
  variables?: FileByIdForContentQueryVariables
) {
  return { query: FileByIdForContentDocument, variables: variables };
}
export const FolderByIdDocument = gql`
  query folderById(
    $folderId: UUID!
    $fileOrderBy: [FilesOrderBy!]
    $folderOrderBy: [FoldersOrderBy!]
    $endCursor: Cursor
  ) {
    folderById(id: $folderId) {
      ...Folder
      foldersByParentId(orderBy: $folderOrderBy) {
        nodes {
          ...Folder
          foldersByParentId {
            __typename
            totalCount
          }
          filesByFolderId {
            __typename
            totalCount
          }
          sharedSpacesByFolderId {
            nodes {
              __typename
              id
            }
          }
        }
        totalCount
      }
      filesByFolderId(orderBy: $fileOrderBy, first: 30, after: $endCursor) {
        nodes {
          ...FileListItem
          associationsByToFileAndOrgId {
            nodes {
              ...PlaylistAndChannelAssociations
            }
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${FolderFragmentDoc}
  ${FileListItemFragmentDoc}
  ${PlaylistAndChannelAssociationsFragmentDoc}
`;
export type FolderByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FolderByIdQuery,
    FolderByIdQueryVariables
  >;
} &
  TChildProps;
export function withFolderById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FolderByIdQuery,
    FolderByIdQueryVariables,
    FolderByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FolderByIdQuery,
    FolderByIdQueryVariables,
    FolderByIdProps<TChildProps, TDataName>
  >(FolderByIdDocument, {
    alias: "folderById",
    ...operationOptions,
  });
}

/**
 * __useFolderByIdQuery__
 *
 * To run a query within a React component, call `useFolderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderByIdQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      fileOrderBy: // value for 'fileOrderBy'
 *      folderOrderBy: // value for 'folderOrderBy'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useFolderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FolderByIdQuery,
    FolderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FolderByIdQuery, FolderByIdQueryVariables>(
    FolderByIdDocument,
    options
  );
}
export function useFolderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FolderByIdQuery,
    FolderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FolderByIdQuery, FolderByIdQueryVariables>(
    FolderByIdDocument,
    options
  );
}
export type FolderByIdQueryHookResult = ReturnType<typeof useFolderByIdQuery>;
export type FolderByIdLazyQueryHookResult = ReturnType<
  typeof useFolderByIdLazyQuery
>;
export type FolderByIdQueryResult = Apollo.QueryResult<
  FolderByIdQuery,
  FolderByIdQueryVariables
>;
export function refetchFolderByIdQuery(variables?: FolderByIdQueryVariables) {
  return { query: FolderByIdDocument, variables: variables };
}
export const FolderByIdExtendedAssociationsDocument = gql`
  query folderByIdExtendedAssociations(
    $folderId: UUID!
    $spaceId: UUID!
    $fileOrderBy: [FilesOrderBy!]
    $folderOrderBy: [FoldersOrderBy!]
    $endCursor: Cursor
  ) {
    folderById(id: $folderId) {
      ...Folder
      foldersByParentId(orderBy: $folderOrderBy) {
        nodes {
          ...Folder
          foldersByParentId {
            __typename
            totalCount
          }
          filesByFolderId {
            __typename
            totalCount
          }
          sharedSpacesByFolderId {
            nodes {
              __typename
              id
            }
          }
        }
        totalCount
      }
      filesByFolderId(orderBy: $fileOrderBy, first: 30, after: $endCursor) {
        nodes {
          ...FileListItem
          associationsByFileAndSpaceId(spaceId: $spaceId) {
            nodes {
              ...PlaylistAndChannelAssociations
            }
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${FolderFragmentDoc}
  ${FileListItemFragmentDoc}
  ${PlaylistAndChannelAssociationsFragmentDoc}
`;
export type FolderByIdExtendedAssociationsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FolderByIdExtendedAssociationsQuery,
    FolderByIdExtendedAssociationsQueryVariables
  >;
} &
  TChildProps;
export function withFolderByIdExtendedAssociations<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FolderByIdExtendedAssociationsQuery,
    FolderByIdExtendedAssociationsQueryVariables,
    FolderByIdExtendedAssociationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FolderByIdExtendedAssociationsQuery,
    FolderByIdExtendedAssociationsQueryVariables,
    FolderByIdExtendedAssociationsProps<TChildProps, TDataName>
  >(FolderByIdExtendedAssociationsDocument, {
    alias: "folderByIdExtendedAssociations",
    ...operationOptions,
  });
}

/**
 * __useFolderByIdExtendedAssociationsQuery__
 *
 * To run a query within a React component, call `useFolderByIdExtendedAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderByIdExtendedAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderByIdExtendedAssociationsQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      spaceId: // value for 'spaceId'
 *      fileOrderBy: // value for 'fileOrderBy'
 *      folderOrderBy: // value for 'folderOrderBy'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useFolderByIdExtendedAssociationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FolderByIdExtendedAssociationsQuery,
    FolderByIdExtendedAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FolderByIdExtendedAssociationsQuery,
    FolderByIdExtendedAssociationsQueryVariables
  >(FolderByIdExtendedAssociationsDocument, options);
}
export function useFolderByIdExtendedAssociationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FolderByIdExtendedAssociationsQuery,
    FolderByIdExtendedAssociationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FolderByIdExtendedAssociationsQuery,
    FolderByIdExtendedAssociationsQueryVariables
  >(FolderByIdExtendedAssociationsDocument, options);
}
export type FolderByIdExtendedAssociationsQueryHookResult = ReturnType<
  typeof useFolderByIdExtendedAssociationsQuery
>;
export type FolderByIdExtendedAssociationsLazyQueryHookResult = ReturnType<
  typeof useFolderByIdExtendedAssociationsLazyQuery
>;
export type FolderByIdExtendedAssociationsQueryResult = Apollo.QueryResult<
  FolderByIdExtendedAssociationsQuery,
  FolderByIdExtendedAssociationsQueryVariables
>;
export function refetchFolderByIdExtendedAssociationsQuery(
  variables?: FolderByIdExtendedAssociationsQueryVariables
) {
  return {
    query: FolderByIdExtendedAssociationsDocument,
    variables: variables,
  };
}
export const FolderByIdFilterByMimeTypeDocument = gql`
  query folderByIdFilterByMimeType(
    $folderId: UUID!
    $fileOrderBy: [FilesOrderBy!]
    $folderOrderBy: [FoldersOrderBy!]
    $endCursor: Cursor
    $allowMimeType: [String!]
  ) {
    folderById(id: $folderId) {
      ...Folder
      foldersByParentId(orderBy: $folderOrderBy, first: 100) {
        nodes {
          ...Folder
          foldersByParentId {
            __typename
            totalCount
          }
          filesByFolderId(filter: { mimetype: { in: $allowMimeType } }) {
            __typename
            totalCount
          }
          sharedSpacesByFolderId {
            nodes {
              __typename
              id
            }
          }
        }
        totalCount
      }
      filesByFolderId(
        orderBy: $fileOrderBy
        first: 30
        after: $endCursor
        filter: { mimetype: { in: $allowMimeType } }
      ) {
        nodes {
          ...File
          castedScreenByFileId {
            nodes {
              __typename
              id
              name
              device
              spaceId
            }
            totalCount
          }
          fileOutputsByFileId {
            nodes {
              ...FileOutput
            }
            totalCount
          }
          userByCreatedBy {
            ...User
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${FolderFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${UserFragmentDoc}
`;
export type FolderByIdFilterByMimeTypeProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FolderByIdFilterByMimeTypeQuery,
    FolderByIdFilterByMimeTypeQueryVariables
  >;
} &
  TChildProps;
export function withFolderByIdFilterByMimeType<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FolderByIdFilterByMimeTypeQuery,
    FolderByIdFilterByMimeTypeQueryVariables,
    FolderByIdFilterByMimeTypeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FolderByIdFilterByMimeTypeQuery,
    FolderByIdFilterByMimeTypeQueryVariables,
    FolderByIdFilterByMimeTypeProps<TChildProps, TDataName>
  >(FolderByIdFilterByMimeTypeDocument, {
    alias: "folderByIdFilterByMimeType",
    ...operationOptions,
  });
}

/**
 * __useFolderByIdFilterByMimeTypeQuery__
 *
 * To run a query within a React component, call `useFolderByIdFilterByMimeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderByIdFilterByMimeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderByIdFilterByMimeTypeQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      fileOrderBy: // value for 'fileOrderBy'
 *      folderOrderBy: // value for 'folderOrderBy'
 *      endCursor: // value for 'endCursor'
 *      allowMimeType: // value for 'allowMimeType'
 *   },
 * });
 */
export function useFolderByIdFilterByMimeTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FolderByIdFilterByMimeTypeQuery,
    FolderByIdFilterByMimeTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FolderByIdFilterByMimeTypeQuery,
    FolderByIdFilterByMimeTypeQueryVariables
  >(FolderByIdFilterByMimeTypeDocument, options);
}
export function useFolderByIdFilterByMimeTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FolderByIdFilterByMimeTypeQuery,
    FolderByIdFilterByMimeTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FolderByIdFilterByMimeTypeQuery,
    FolderByIdFilterByMimeTypeQueryVariables
  >(FolderByIdFilterByMimeTypeDocument, options);
}
export type FolderByIdFilterByMimeTypeQueryHookResult = ReturnType<
  typeof useFolderByIdFilterByMimeTypeQuery
>;
export type FolderByIdFilterByMimeTypeLazyQueryHookResult = ReturnType<
  typeof useFolderByIdFilterByMimeTypeLazyQuery
>;
export type FolderByIdFilterByMimeTypeQueryResult = Apollo.QueryResult<
  FolderByIdFilterByMimeTypeQuery,
  FolderByIdFilterByMimeTypeQueryVariables
>;
export function refetchFolderByIdFilterByMimeTypeQuery(
  variables?: FolderByIdFilterByMimeTypeQueryVariables
) {
  return { query: FolderByIdFilterByMimeTypeDocument, variables: variables };
}
export const FolderByIdRefetchAfterShareDocument = gql`
  query folderByIdRefetchAfterShare($folderId: UUID!) {
    folderById(id: $folderId) {
      ...Folder
      foldersByParentId {
        totalCount
      }
      filesByFolderId {
        totalCount
      }
      sharedSpacesByFolderId {
        nodes {
          __typename
          id
        }
      }
    }
  }
  ${FolderFragmentDoc}
`;
export type FolderByIdRefetchAfterShareProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FolderByIdRefetchAfterShareQuery,
    FolderByIdRefetchAfterShareQueryVariables
  >;
} &
  TChildProps;
export function withFolderByIdRefetchAfterShare<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FolderByIdRefetchAfterShareQuery,
    FolderByIdRefetchAfterShareQueryVariables,
    FolderByIdRefetchAfterShareProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FolderByIdRefetchAfterShareQuery,
    FolderByIdRefetchAfterShareQueryVariables,
    FolderByIdRefetchAfterShareProps<TChildProps, TDataName>
  >(FolderByIdRefetchAfterShareDocument, {
    alias: "folderByIdRefetchAfterShare",
    ...operationOptions,
  });
}

/**
 * __useFolderByIdRefetchAfterShareQuery__
 *
 * To run a query within a React component, call `useFolderByIdRefetchAfterShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderByIdRefetchAfterShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderByIdRefetchAfterShareQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useFolderByIdRefetchAfterShareQuery(
  baseOptions: Apollo.QueryHookOptions<
    FolderByIdRefetchAfterShareQuery,
    FolderByIdRefetchAfterShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FolderByIdRefetchAfterShareQuery,
    FolderByIdRefetchAfterShareQueryVariables
  >(FolderByIdRefetchAfterShareDocument, options);
}
export function useFolderByIdRefetchAfterShareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FolderByIdRefetchAfterShareQuery,
    FolderByIdRefetchAfterShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FolderByIdRefetchAfterShareQuery,
    FolderByIdRefetchAfterShareQueryVariables
  >(FolderByIdRefetchAfterShareDocument, options);
}
export type FolderByIdRefetchAfterShareQueryHookResult = ReturnType<
  typeof useFolderByIdRefetchAfterShareQuery
>;
export type FolderByIdRefetchAfterShareLazyQueryHookResult = ReturnType<
  typeof useFolderByIdRefetchAfterShareLazyQuery
>;
export type FolderByIdRefetchAfterShareQueryResult = Apollo.QueryResult<
  FolderByIdRefetchAfterShareQuery,
  FolderByIdRefetchAfterShareQueryVariables
>;
export function refetchFolderByIdRefetchAfterShareQuery(
  variables?: FolderByIdRefetchAfterShareQueryVariables
) {
  return { query: FolderByIdRefetchAfterShareDocument, variables: variables };
}
export const FolderHierarchyDocument = gql`
  query folderHierarchy($folderId: UUID!) {
    folderHierarchy(folderId: $folderId) {
      nodes {
        id
        level
        name
        namePath
        idPath
      }
    }
  }
`;
export type FolderHierarchyProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FolderHierarchyQuery,
    FolderHierarchyQueryVariables
  >;
} &
  TChildProps;
export function withFolderHierarchy<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FolderHierarchyQuery,
    FolderHierarchyQueryVariables,
    FolderHierarchyProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FolderHierarchyQuery,
    FolderHierarchyQueryVariables,
    FolderHierarchyProps<TChildProps, TDataName>
  >(FolderHierarchyDocument, {
    alias: "folderHierarchy",
    ...operationOptions,
  });
}

/**
 * __useFolderHierarchyQuery__
 *
 * To run a query within a React component, call `useFolderHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderHierarchyQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useFolderHierarchyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FolderHierarchyQuery,
    FolderHierarchyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FolderHierarchyQuery, FolderHierarchyQueryVariables>(
    FolderHierarchyDocument,
    options
  );
}
export function useFolderHierarchyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FolderHierarchyQuery,
    FolderHierarchyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FolderHierarchyQuery,
    FolderHierarchyQueryVariables
  >(FolderHierarchyDocument, options);
}
export type FolderHierarchyQueryHookResult = ReturnType<
  typeof useFolderHierarchyQuery
>;
export type FolderHierarchyLazyQueryHookResult = ReturnType<
  typeof useFolderHierarchyLazyQuery
>;
export type FolderHierarchyQueryResult = Apollo.QueryResult<
  FolderHierarchyQuery,
  FolderHierarchyQueryVariables
>;
export function refetchFolderHierarchyQuery(
  variables?: FolderHierarchyQueryVariables
) {
  return { query: FolderHierarchyDocument, variables: variables };
}
export const FolderParentsDocument = gql`
  query folderParents($folderid: UUID!) {
    folderParents(folderId: $folderid) {
      nodes {
        id
        orgId
        name
        parentId
        spaceId
      }
    }
  }
`;
export type FolderParentsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    FolderParentsQuery,
    FolderParentsQueryVariables
  >;
} &
  TChildProps;
export function withFolderParents<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FolderParentsQuery,
    FolderParentsQueryVariables,
    FolderParentsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FolderParentsQuery,
    FolderParentsQueryVariables,
    FolderParentsProps<TChildProps, TDataName>
  >(FolderParentsDocument, {
    alias: "folderParents",
    ...operationOptions,
  });
}

/**
 * __useFolderParentsQuery__
 *
 * To run a query within a React component, call `useFolderParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderParentsQuery({
 *   variables: {
 *      folderid: // value for 'folderid'
 *   },
 * });
 */
export function useFolderParentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FolderParentsQuery,
    FolderParentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FolderParentsQuery, FolderParentsQueryVariables>(
    FolderParentsDocument,
    options
  );
}
export function useFolderParentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FolderParentsQuery,
    FolderParentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FolderParentsQuery, FolderParentsQueryVariables>(
    FolderParentsDocument,
    options
  );
}
export type FolderParentsQueryHookResult = ReturnType<
  typeof useFolderParentsQuery
>;
export type FolderParentsLazyQueryHookResult = ReturnType<
  typeof useFolderParentsLazyQuery
>;
export type FolderParentsQueryResult = Apollo.QueryResult<
  FolderParentsQuery,
  FolderParentsQueryVariables
>;
export function refetchFolderParentsQuery(
  variables?: FolderParentsQueryVariables
) {
  return { query: FolderParentsDocument, variables: variables };
}
export const GetAllMediasDocument = gql`
  query getAllMedias($folderId: UUID!) {
    folderById(id: $folderId) {
      __typename
      id
      name
      foldersByParentId {
        nodes {
          __typename
          id
          name
          spaceId
          tags
        }
        totalCount
      }
      filesByFolderId {
        nodes {
          __typename
          id
          name
          spaceId
          tags
          folderId
          fileOutputsByFileId {
            nodes {
              __typename
              id
              mimetype
            }
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
          castedScreenByFileId {
            nodes {
              __typename
              id
            }
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
export type GetAllMediasProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAllMediasQuery,
    GetAllMediasQueryVariables
  >;
} &
  TChildProps;
export function withGetAllMedias<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllMediasQuery,
    GetAllMediasQueryVariables,
    GetAllMediasProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllMediasQuery,
    GetAllMediasQueryVariables,
    GetAllMediasProps<TChildProps, TDataName>
  >(GetAllMediasDocument, {
    alias: "getAllMedias",
    ...operationOptions,
  });
}

/**
 * __useGetAllMediasQuery__
 *
 * To run a query within a React component, call `useGetAllMediasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMediasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMediasQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useGetAllMediasQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllMediasQuery,
    GetAllMediasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllMediasQuery, GetAllMediasQueryVariables>(
    GetAllMediasDocument,
    options
  );
}
export function useGetAllMediasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllMediasQuery,
    GetAllMediasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllMediasQuery, GetAllMediasQueryVariables>(
    GetAllMediasDocument,
    options
  );
}
export type GetAllMediasQueryHookResult = ReturnType<
  typeof useGetAllMediasQuery
>;
export type GetAllMediasLazyQueryHookResult = ReturnType<
  typeof useGetAllMediasLazyQuery
>;
export type GetAllMediasQueryResult = Apollo.QueryResult<
  GetAllMediasQuery,
  GetAllMediasQueryVariables
>;
export function refetchGetAllMediasQuery(
  variables?: GetAllMediasQueryVariables
) {
  return { query: GetAllMediasDocument, variables: variables };
}
export const NestedFilesByFolderIdDocument = gql`
  query nestedFilesByFolderId($folderId: UUID!) {
    nestedFilesByFolderId(folderId: $folderId) {
      nodes {
        ...File
        fileOutputsByFileId {
          nodes {
            ...FileOutput
          }
        }
      }
    }
  }
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
`;
export type NestedFilesByFolderIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    NestedFilesByFolderIdQuery,
    NestedFilesByFolderIdQueryVariables
  >;
} &
  TChildProps;
export function withNestedFilesByFolderId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NestedFilesByFolderIdQuery,
    NestedFilesByFolderIdQueryVariables,
    NestedFilesByFolderIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    NestedFilesByFolderIdQuery,
    NestedFilesByFolderIdQueryVariables,
    NestedFilesByFolderIdProps<TChildProps, TDataName>
  >(NestedFilesByFolderIdDocument, {
    alias: "nestedFilesByFolderId",
    ...operationOptions,
  });
}

/**
 * __useNestedFilesByFolderIdQuery__
 *
 * To run a query within a React component, call `useNestedFilesByFolderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNestedFilesByFolderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNestedFilesByFolderIdQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useNestedFilesByFolderIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    NestedFilesByFolderIdQuery,
    NestedFilesByFolderIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NestedFilesByFolderIdQuery,
    NestedFilesByFolderIdQueryVariables
  >(NestedFilesByFolderIdDocument, options);
}
export function useNestedFilesByFolderIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NestedFilesByFolderIdQuery,
    NestedFilesByFolderIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NestedFilesByFolderIdQuery,
    NestedFilesByFolderIdQueryVariables
  >(NestedFilesByFolderIdDocument, options);
}
export type NestedFilesByFolderIdQueryHookResult = ReturnType<
  typeof useNestedFilesByFolderIdQuery
>;
export type NestedFilesByFolderIdLazyQueryHookResult = ReturnType<
  typeof useNestedFilesByFolderIdLazyQuery
>;
export type NestedFilesByFolderIdQueryResult = Apollo.QueryResult<
  NestedFilesByFolderIdQuery,
  NestedFilesByFolderIdQueryVariables
>;
export function refetchNestedFilesByFolderIdQuery(
  variables?: NestedFilesByFolderIdQueryVariables
) {
  return { query: NestedFilesByFolderIdDocument, variables: variables };
}
export const SearchMediaDocument = gql`
  query searchMedia(
    $query: String
    $spaceId: UUID
    $fileOrderBy: [FilesOrderBy!]
    $folderOrderBy: [FoldersOrderBy!]
  ) {
    searchFile(query: $query, spaceId: $spaceId, orderBy: $fileOrderBy) {
      nodes {
        ...FileListItem
      }
      totalCount
    }
    searchFolder(query: $query, spaceId: $spaceId, orderBy: $folderOrderBy) {
      nodes {
        ...Folder
        foldersByParentId {
          totalCount
        }
        filesByFolderId {
          nodes {
            ...File
          }
          totalCount
        }
      }
      totalCount
    }
  }
  ${FileListItemFragmentDoc}
  ${FolderFragmentDoc}
  ${FileFragmentDoc}
`;
export type SearchMediaProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchMediaQuery,
    SearchMediaQueryVariables
  >;
} &
  TChildProps;
export function withSearchMedia<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchMediaQuery,
    SearchMediaQueryVariables,
    SearchMediaProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchMediaQuery,
    SearchMediaQueryVariables,
    SearchMediaProps<TChildProps, TDataName>
  >(SearchMediaDocument, {
    alias: "searchMedia",
    ...operationOptions,
  });
}

/**
 * __useSearchMediaQuery__
 *
 * To run a query within a React component, call `useSearchMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMediaQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      fileOrderBy: // value for 'fileOrderBy'
 *      folderOrderBy: // value for 'folderOrderBy'
 *   },
 * });
 */
export function useSearchMediaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchMediaQuery,
    SearchMediaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchMediaQuery, SearchMediaQueryVariables>(
    SearchMediaDocument,
    options
  );
}
export function useSearchMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchMediaQuery,
    SearchMediaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchMediaQuery, SearchMediaQueryVariables>(
    SearchMediaDocument,
    options
  );
}
export type SearchMediaQueryHookResult = ReturnType<typeof useSearchMediaQuery>;
export type SearchMediaLazyQueryHookResult = ReturnType<
  typeof useSearchMediaLazyQuery
>;
export type SearchMediaQueryResult = Apollo.QueryResult<
  SearchMediaQuery,
  SearchMediaQueryVariables
>;
export function refetchSearchMediaQuery(variables?: SearchMediaQueryVariables) {
  return { query: SearchMediaDocument, variables: variables };
}
export const UpdateFileAvailableDocument = gql`
  mutation updateFileAvailable($input: UpdateFileAvailableInput!) {
    updateFileAvailable(input: $input) {
      file {
        ...File
      }
    }
  }
  ${FileFragmentDoc}
`;
export type UpdateFileAvailableMutationFn = Apollo.MutationFunction<
  UpdateFileAvailableMutation,
  UpdateFileAvailableMutationVariables
>;
export type UpdateFileAvailableProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateFileAvailableMutation,
    UpdateFileAvailableMutationVariables
  >;
} &
  TChildProps;
export function withUpdateFileAvailable<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateFileAvailableMutation,
    UpdateFileAvailableMutationVariables,
    UpdateFileAvailableProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateFileAvailableMutation,
    UpdateFileAvailableMutationVariables,
    UpdateFileAvailableProps<TChildProps, TDataName>
  >(UpdateFileAvailableDocument, {
    alias: "updateFileAvailable",
    ...operationOptions,
  });
}

/**
 * __useUpdateFileAvailableMutation__
 *
 * To run a mutation, you first call `useUpdateFileAvailableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileAvailableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileAvailableMutation, { data, loading, error }] = useUpdateFileAvailableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileAvailableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFileAvailableMutation,
    UpdateFileAvailableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFileAvailableMutation,
    UpdateFileAvailableMutationVariables
  >(UpdateFileAvailableDocument, options);
}
export type UpdateFileAvailableMutationHookResult = ReturnType<
  typeof useUpdateFileAvailableMutation
>;
export type UpdateFileAvailableMutationResult = Apollo.MutationResult<UpdateFileAvailableMutation>;
export type UpdateFileAvailableMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileAvailableMutation,
  UpdateFileAvailableMutationVariables
>;
export const UpdateFileByIdDocument = gql`
  mutation updateFileById($input: UpdateFileByIdInput!) {
    updateFileById(input: $input) {
      file {
        ...FileListItem
      }
    }
  }
  ${FileListItemFragmentDoc}
`;
export type UpdateFileByIdMutationFn = Apollo.MutationFunction<
  UpdateFileByIdMutation,
  UpdateFileByIdMutationVariables
>;
export type UpdateFileByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateFileByIdMutation,
    UpdateFileByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateFileById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateFileByIdMutation,
    UpdateFileByIdMutationVariables,
    UpdateFileByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateFileByIdMutation,
    UpdateFileByIdMutationVariables,
    UpdateFileByIdProps<TChildProps, TDataName>
  >(UpdateFileByIdDocument, {
    alias: "updateFileById",
    ...operationOptions,
  });
}

/**
 * __useUpdateFileByIdMutation__
 *
 * To run a mutation, you first call `useUpdateFileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileByIdMutation, { data, loading, error }] = useUpdateFileByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFileByIdMutation,
    UpdateFileByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFileByIdMutation,
    UpdateFileByIdMutationVariables
  >(UpdateFileByIdDocument, options);
}
export type UpdateFileByIdMutationHookResult = ReturnType<
  typeof useUpdateFileByIdMutation
>;
export type UpdateFileByIdMutationResult = Apollo.MutationResult<UpdateFileByIdMutation>;
export type UpdateFileByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileByIdMutation,
  UpdateFileByIdMutationVariables
>;
export const UpdateFileExpireDocument = gql`
  mutation updateFileExpire($input: UpdateFileExpireInput!) {
    updateFileExpire(input: $input) {
      file {
        ...File
      }
    }
  }
  ${FileFragmentDoc}
`;
export type UpdateFileExpireMutationFn = Apollo.MutationFunction<
  UpdateFileExpireMutation,
  UpdateFileExpireMutationVariables
>;
export type UpdateFileExpireProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateFileExpireMutation,
    UpdateFileExpireMutationVariables
  >;
} &
  TChildProps;
export function withUpdateFileExpire<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateFileExpireMutation,
    UpdateFileExpireMutationVariables,
    UpdateFileExpireProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateFileExpireMutation,
    UpdateFileExpireMutationVariables,
    UpdateFileExpireProps<TChildProps, TDataName>
  >(UpdateFileExpireDocument, {
    alias: "updateFileExpire",
    ...operationOptions,
  });
}

/**
 * __useUpdateFileExpireMutation__
 *
 * To run a mutation, you first call `useUpdateFileExpireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileExpireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileExpireMutation, { data, loading, error }] = useUpdateFileExpireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileExpireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFileExpireMutation,
    UpdateFileExpireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFileExpireMutation,
    UpdateFileExpireMutationVariables
  >(UpdateFileExpireDocument, options);
}
export type UpdateFileExpireMutationHookResult = ReturnType<
  typeof useUpdateFileExpireMutation
>;
export type UpdateFileExpireMutationResult = Apollo.MutationResult<UpdateFileExpireMutation>;
export type UpdateFileExpireMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileExpireMutation,
  UpdateFileExpireMutationVariables
>;
export const UpdateFileIsFavoriteDocument = gql`
  mutation updateFileIsFavorite($input: UpdateFileIsFavoriteInput!) {
    updateFileIsFavorite(input: $input) {
      favorites {
        fileId
        fileByFileId {
          __typename
          id
          isFavorite
        }
      }
    }
  }
`;
export type UpdateFileIsFavoriteMutationFn = Apollo.MutationFunction<
  UpdateFileIsFavoriteMutation,
  UpdateFileIsFavoriteMutationVariables
>;
export type UpdateFileIsFavoriteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateFileIsFavoriteMutation,
    UpdateFileIsFavoriteMutationVariables
  >;
} &
  TChildProps;
export function withUpdateFileIsFavorite<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateFileIsFavoriteMutation,
    UpdateFileIsFavoriteMutationVariables,
    UpdateFileIsFavoriteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateFileIsFavoriteMutation,
    UpdateFileIsFavoriteMutationVariables,
    UpdateFileIsFavoriteProps<TChildProps, TDataName>
  >(UpdateFileIsFavoriteDocument, {
    alias: "updateFileIsFavorite",
    ...operationOptions,
  });
}

/**
 * __useUpdateFileIsFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateFileIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileIsFavoriteMutation, { data, loading, error }] = useUpdateFileIsFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileIsFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFileIsFavoriteMutation,
    UpdateFileIsFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFileIsFavoriteMutation,
    UpdateFileIsFavoriteMutationVariables
  >(UpdateFileIsFavoriteDocument, options);
}
export type UpdateFileIsFavoriteMutationHookResult = ReturnType<
  typeof useUpdateFileIsFavoriteMutation
>;
export type UpdateFileIsFavoriteMutationResult = Apollo.MutationResult<UpdateFileIsFavoriteMutation>;
export type UpdateFileIsFavoriteMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileIsFavoriteMutation,
  UpdateFileIsFavoriteMutationVariables
>;
export const UpdateFolderByIdDocument = gql`
  mutation updateFolderById($input: UpdateFolderByIdInput!) {
    updateFolderById(input: $input) {
      folder {
        ...Folder
      }
    }
  }
  ${FolderFragmentDoc}
`;
export type UpdateFolderByIdMutationFn = Apollo.MutationFunction<
  UpdateFolderByIdMutation,
  UpdateFolderByIdMutationVariables
>;
export type UpdateFolderByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateFolderByIdMutation,
    UpdateFolderByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateFolderById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateFolderByIdMutation,
    UpdateFolderByIdMutationVariables,
    UpdateFolderByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateFolderByIdMutation,
    UpdateFolderByIdMutationVariables,
    UpdateFolderByIdProps<TChildProps, TDataName>
  >(UpdateFolderByIdDocument, {
    alias: "updateFolderById",
    ...operationOptions,
  });
}

/**
 * __useUpdateFolderByIdMutation__
 *
 * To run a mutation, you first call `useUpdateFolderByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderByIdMutation, { data, loading, error }] = useUpdateFolderByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFolderByIdMutation,
    UpdateFolderByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFolderByIdMutation,
    UpdateFolderByIdMutationVariables
  >(UpdateFolderByIdDocument, options);
}
export type UpdateFolderByIdMutationHookResult = ReturnType<
  typeof useUpdateFolderByIdMutation
>;
export type UpdateFolderByIdMutationResult = Apollo.MutationResult<UpdateFolderByIdMutation>;
export type UpdateFolderByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateFolderByIdMutation,
  UpdateFolderByIdMutationVariables
>;
export const UpdateFolderIsFavoriteDocument = gql`
  mutation updateFolderIsFavorite($input: UpdateFolderIsFavoriteInput!) {
    updateFolderIsFavorite(input: $input) {
      favorites {
        folderId
        folderByFolderId {
          __typename
          id
          isFavorite
        }
      }
    }
  }
`;
export type UpdateFolderIsFavoriteMutationFn = Apollo.MutationFunction<
  UpdateFolderIsFavoriteMutation,
  UpdateFolderIsFavoriteMutationVariables
>;
export type UpdateFolderIsFavoriteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateFolderIsFavoriteMutation,
    UpdateFolderIsFavoriteMutationVariables
  >;
} &
  TChildProps;
export function withUpdateFolderIsFavorite<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateFolderIsFavoriteMutation,
    UpdateFolderIsFavoriteMutationVariables,
    UpdateFolderIsFavoriteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateFolderIsFavoriteMutation,
    UpdateFolderIsFavoriteMutationVariables,
    UpdateFolderIsFavoriteProps<TChildProps, TDataName>
  >(UpdateFolderIsFavoriteDocument, {
    alias: "updateFolderIsFavorite",
    ...operationOptions,
  });
}

/**
 * __useUpdateFolderIsFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateFolderIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderIsFavoriteMutation, { data, loading, error }] = useUpdateFolderIsFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderIsFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFolderIsFavoriteMutation,
    UpdateFolderIsFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFolderIsFavoriteMutation,
    UpdateFolderIsFavoriteMutationVariables
  >(UpdateFolderIsFavoriteDocument, options);
}
export type UpdateFolderIsFavoriteMutationHookResult = ReturnType<
  typeof useUpdateFolderIsFavoriteMutation
>;
export type UpdateFolderIsFavoriteMutationResult = Apollo.MutationResult<UpdateFolderIsFavoriteMutation>;
export type UpdateFolderIsFavoriteMutationOptions = Apollo.BaseMutationOptions<
  UpdateFolderIsFavoriteMutation,
  UpdateFolderIsFavoriteMutationVariables
>;
export const MigrationScreenDocument = gql`
  mutation migrationScreen($input: MigrationScreenInput!) {
    migrationScreen(input: $input) {
      json
    }
  }
`;
export type MigrationScreenMutationFn = Apollo.MutationFunction<
  MigrationScreenMutation,
  MigrationScreenMutationVariables
>;
export type MigrationScreenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    MigrationScreenMutation,
    MigrationScreenMutationVariables
  >;
} &
  TChildProps;
export function withMigrationScreen<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MigrationScreenMutation,
    MigrationScreenMutationVariables,
    MigrationScreenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MigrationScreenMutation,
    MigrationScreenMutationVariables,
    MigrationScreenProps<TChildProps, TDataName>
  >(MigrationScreenDocument, {
    alias: "migrationScreen",
    ...operationOptions,
  });
}

/**
 * __useMigrationScreenMutation__
 *
 * To run a mutation, you first call `useMigrationScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrationScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrationScreenMutation, { data, loading, error }] = useMigrationScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMigrationScreenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MigrationScreenMutation,
    MigrationScreenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MigrationScreenMutation,
    MigrationScreenMutationVariables
  >(MigrationScreenDocument, options);
}
export type MigrationScreenMutationHookResult = ReturnType<
  typeof useMigrationScreenMutation
>;
export type MigrationScreenMutationResult = Apollo.MutationResult<MigrationScreenMutation>;
export type MigrationScreenMutationOptions = Apollo.BaseMutationOptions<
  MigrationScreenMutation,
  MigrationScreenMutationVariables
>;
export const MigrationStatusDocument = gql`
  query migrationStatus($orgId: UUID) {
    migrationStatus(orgId: $orgId)
  }
`;
export type MigrationStatusProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    MigrationStatusQuery,
    MigrationStatusQueryVariables
  >;
} &
  TChildProps;
export function withMigrationStatus<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MigrationStatusQuery,
    MigrationStatusQueryVariables,
    MigrationStatusProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MigrationStatusQuery,
    MigrationStatusQueryVariables,
    MigrationStatusProps<TChildProps, TDataName>
  >(MigrationStatusDocument, {
    alias: "migrationStatus",
    ...operationOptions,
  });
}

/**
 * __useMigrationStatusQuery__
 *
 * To run a query within a React component, call `useMigrationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMigrationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMigrationStatusQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useMigrationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MigrationStatusQuery,
    MigrationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MigrationStatusQuery, MigrationStatusQueryVariables>(
    MigrationStatusDocument,
    options
  );
}
export function useMigrationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MigrationStatusQuery,
    MigrationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MigrationStatusQuery,
    MigrationStatusQueryVariables
  >(MigrationStatusDocument, options);
}
export type MigrationStatusQueryHookResult = ReturnType<
  typeof useMigrationStatusQuery
>;
export type MigrationStatusLazyQueryHookResult = ReturnType<
  typeof useMigrationStatusLazyQuery
>;
export type MigrationStatusQueryResult = Apollo.QueryResult<
  MigrationStatusQuery,
  MigrationStatusQueryVariables
>;
export function refetchMigrationStatusQuery(
  variables?: MigrationStatusQueryVariables
) {
  return { query: MigrationStatusDocument, variables: variables };
}
export const PlaylistByIdDocument = gql`
  query playlistById($id: UUID!) {
    playlistById(id: $id) {
      ...PlaylistByIdData
    }
  }
  ${PlaylistByIdDataFragmentDoc}
`;
export type PlaylistByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PlaylistByIdQuery,
    PlaylistByIdQueryVariables
  >;
} &
  TChildProps;
export function withPlaylistById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PlaylistByIdQuery,
    PlaylistByIdQueryVariables,
    PlaylistByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PlaylistByIdQuery,
    PlaylistByIdQueryVariables,
    PlaylistByIdProps<TChildProps, TDataName>
  >(PlaylistByIdDocument, {
    alias: "playlistById",
    ...operationOptions,
  });
}

/**
 * __usePlaylistByIdQuery__
 *
 * To run a query within a React component, call `usePlaylistByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistByIdQuery,
    PlaylistByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlaylistByIdQuery, PlaylistByIdQueryVariables>(
    PlaylistByIdDocument,
    options
  );
}
export function usePlaylistByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistByIdQuery,
    PlaylistByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlaylistByIdQuery, PlaylistByIdQueryVariables>(
    PlaylistByIdDocument,
    options
  );
}
export type PlaylistByIdQueryHookResult = ReturnType<
  typeof usePlaylistByIdQuery
>;
export type PlaylistByIdLazyQueryHookResult = ReturnType<
  typeof usePlaylistByIdLazyQuery
>;
export type PlaylistByIdQueryResult = Apollo.QueryResult<
  PlaylistByIdQuery,
  PlaylistByIdQueryVariables
>;
export function refetchPlaylistByIdQuery(
  variables?: PlaylistByIdQueryVariables
) {
  return { query: PlaylistByIdDocument, variables: variables };
}
export const PlaylistByIdForListCachingDocument = gql`
  query playlistByIdForListCaching($id: UUID!) {
    playlistById(id: $id) {
      ...PlaylistListItem
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type PlaylistByIdForListCachingProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PlaylistByIdForListCachingQuery,
    PlaylistByIdForListCachingQueryVariables
  >;
} &
  TChildProps;
export function withPlaylistByIdForListCaching<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PlaylistByIdForListCachingQuery,
    PlaylistByIdForListCachingQueryVariables,
    PlaylistByIdForListCachingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PlaylistByIdForListCachingQuery,
    PlaylistByIdForListCachingQueryVariables,
    PlaylistByIdForListCachingProps<TChildProps, TDataName>
  >(PlaylistByIdForListCachingDocument, {
    alias: "playlistByIdForListCaching",
    ...operationOptions,
  });
}

/**
 * __usePlaylistByIdForListCachingQuery__
 *
 * To run a query within a React component, call `usePlaylistByIdForListCachingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistByIdForListCachingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistByIdForListCachingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistByIdForListCachingQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistByIdForListCachingQuery,
    PlaylistByIdForListCachingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlaylistByIdForListCachingQuery,
    PlaylistByIdForListCachingQueryVariables
  >(PlaylistByIdForListCachingDocument, options);
}
export function usePlaylistByIdForListCachingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistByIdForListCachingQuery,
    PlaylistByIdForListCachingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlaylistByIdForListCachingQuery,
    PlaylistByIdForListCachingQueryVariables
  >(PlaylistByIdForListCachingDocument, options);
}
export type PlaylistByIdForListCachingQueryHookResult = ReturnType<
  typeof usePlaylistByIdForListCachingQuery
>;
export type PlaylistByIdForListCachingLazyQueryHookResult = ReturnType<
  typeof usePlaylistByIdForListCachingLazyQuery
>;
export type PlaylistByIdForListCachingQueryResult = Apollo.QueryResult<
  PlaylistByIdForListCachingQuery,
  PlaylistByIdForListCachingQueryVariables
>;
export function refetchPlaylistByIdForListCachingQuery(
  variables?: PlaylistByIdForListCachingQueryVariables
) {
  return { query: PlaylistByIdForListCachingDocument, variables: variables };
}
export const PublishDraftPlaylistDocument = gql`
  mutation publishDraftPlaylist($input: PublishDraftPlaylistInput!) {
    publishDraftPlaylist(input: $input) {
      playlist {
        __typename
        id
        name
        content
        draft {
          __typename
          id
          name
          content
          isPublished
        }
        userByPublishedBy {
          __typename
          id
          givenName
          familyName
        }
      }
    }
  }
`;
export type PublishDraftPlaylistMutationFn = Apollo.MutationFunction<
  PublishDraftPlaylistMutation,
  PublishDraftPlaylistMutationVariables
>;
export type PublishDraftPlaylistProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    PublishDraftPlaylistMutation,
    PublishDraftPlaylistMutationVariables
  >;
} &
  TChildProps;
export function withPublishDraftPlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PublishDraftPlaylistMutation,
    PublishDraftPlaylistMutationVariables,
    PublishDraftPlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PublishDraftPlaylistMutation,
    PublishDraftPlaylistMutationVariables,
    PublishDraftPlaylistProps<TChildProps, TDataName>
  >(PublishDraftPlaylistDocument, {
    alias: "publishDraftPlaylist",
    ...operationOptions,
  });
}

/**
 * __usePublishDraftPlaylistMutation__
 *
 * To run a mutation, you first call `usePublishDraftPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftPlaylistMutation, { data, loading, error }] = usePublishDraftPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishDraftPlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishDraftPlaylistMutation,
    PublishDraftPlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishDraftPlaylistMutation,
    PublishDraftPlaylistMutationVariables
  >(PublishDraftPlaylistDocument, options);
}
export type PublishDraftPlaylistMutationHookResult = ReturnType<
  typeof usePublishDraftPlaylistMutation
>;
export type PublishDraftPlaylistMutationResult = Apollo.MutationResult<PublishDraftPlaylistMutation>;
export type PublishDraftPlaylistMutationOptions = Apollo.BaseMutationOptions<
  PublishDraftPlaylistMutation,
  PublishDraftPlaylistMutationVariables
>;
export const RevertDraftPlaylistDocument = gql`
  mutation revertDraftPlaylist($input: RevertDraftPlaylistInput!) {
    revertDraftPlaylist(input: $input) {
      playlist {
        __typename
        id
        name
        content
        draft {
          __typename
          id
          name
          content
          isPublished
        }
        userByPublishedBy {
          __typename
          id
          givenName
          familyName
        }
      }
    }
  }
`;
export type RevertDraftPlaylistMutationFn = Apollo.MutationFunction<
  RevertDraftPlaylistMutation,
  RevertDraftPlaylistMutationVariables
>;
export type RevertDraftPlaylistProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RevertDraftPlaylistMutation,
    RevertDraftPlaylistMutationVariables
  >;
} &
  TChildProps;
export function withRevertDraftPlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RevertDraftPlaylistMutation,
    RevertDraftPlaylistMutationVariables,
    RevertDraftPlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RevertDraftPlaylistMutation,
    RevertDraftPlaylistMutationVariables,
    RevertDraftPlaylistProps<TChildProps, TDataName>
  >(RevertDraftPlaylistDocument, {
    alias: "revertDraftPlaylist",
    ...operationOptions,
  });
}

/**
 * __useRevertDraftPlaylistMutation__
 *
 * To run a mutation, you first call `useRevertDraftPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertDraftPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertDraftPlaylistMutation, { data, loading, error }] = useRevertDraftPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertDraftPlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevertDraftPlaylistMutation,
    RevertDraftPlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevertDraftPlaylistMutation,
    RevertDraftPlaylistMutationVariables
  >(RevertDraftPlaylistDocument, options);
}
export type RevertDraftPlaylistMutationHookResult = ReturnType<
  typeof useRevertDraftPlaylistMutation
>;
export type RevertDraftPlaylistMutationResult = Apollo.MutationResult<RevertDraftPlaylistMutation>;
export type RevertDraftPlaylistMutationOptions = Apollo.BaseMutationOptions<
  RevertDraftPlaylistMutation,
  RevertDraftPlaylistMutationVariables
>;
export const UpdatePlaylistDocument = gql`
  mutation updatePlaylist($input: UpdatePlaylistInput!) {
    updatePlaylist(input: $input) {
      playlist {
        __typename
        id
        name
        draft {
          ...PlaylistByIdForPlaylistListing
        }
      }
    }
  }
  ${PlaylistByIdForPlaylistListingFragmentDoc}
`;
export type UpdatePlaylistMutationFn = Apollo.MutationFunction<
  UpdatePlaylistMutation,
  UpdatePlaylistMutationVariables
>;
export type UpdatePlaylistProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdatePlaylistMutation,
    UpdatePlaylistMutationVariables
  >;
} &
  TChildProps;
export function withUpdatePlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlaylistMutation,
    UpdatePlaylistMutationVariables,
    UpdatePlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlaylistMutation,
    UpdatePlaylistMutationVariables,
    UpdatePlaylistProps<TChildProps, TDataName>
  >(UpdatePlaylistDocument, {
    alias: "updatePlaylist",
    ...operationOptions,
  });
}

/**
 * __useUpdatePlaylistMutation__
 *
 * To run a mutation, you first call `useUpdatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaylistMutation, { data, loading, error }] = useUpdatePlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlaylistMutation,
    UpdatePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlaylistMutation,
    UpdatePlaylistMutationVariables
  >(UpdatePlaylistDocument, options);
}
export type UpdatePlaylistMutationHookResult = ReturnType<
  typeof useUpdatePlaylistMutation
>;
export type UpdatePlaylistMutationResult = Apollo.MutationResult<UpdatePlaylistMutation>;
export type UpdatePlaylistMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlaylistMutation,
  UpdatePlaylistMutationVariables
>;
export const UpdatePlaylistColorDocument = gql`
  mutation UpdatePlaylistColor($input: UpdatePlaylistColorInput!) {
    updatePlaylistColor(input: $input) {
      playlist {
        __typename
        id
        name
        color
      }
    }
  }
`;
export type UpdatePlaylistColorMutationFn = Apollo.MutationFunction<
  UpdatePlaylistColorMutation,
  UpdatePlaylistColorMutationVariables
>;
export type UpdatePlaylistColorProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdatePlaylistColorMutation,
    UpdatePlaylistColorMutationVariables
  >;
} &
  TChildProps;
export function withUpdatePlaylistColor<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlaylistColorMutation,
    UpdatePlaylistColorMutationVariables,
    UpdatePlaylistColorProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlaylistColorMutation,
    UpdatePlaylistColorMutationVariables,
    UpdatePlaylistColorProps<TChildProps, TDataName>
  >(UpdatePlaylistColorDocument, {
    alias: "updatePlaylistColor",
    ...operationOptions,
  });
}

/**
 * __useUpdatePlaylistColorMutation__
 *
 * To run a mutation, you first call `useUpdatePlaylistColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaylistColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaylistColorMutation, { data, loading, error }] = useUpdatePlaylistColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaylistColorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlaylistColorMutation,
    UpdatePlaylistColorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlaylistColorMutation,
    UpdatePlaylistColorMutationVariables
  >(UpdatePlaylistColorDocument, options);
}
export type UpdatePlaylistColorMutationHookResult = ReturnType<
  typeof useUpdatePlaylistColorMutation
>;
export type UpdatePlaylistColorMutationResult = Apollo.MutationResult<UpdatePlaylistColorMutation>;
export type UpdatePlaylistColorMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlaylistColorMutation,
  UpdatePlaylistColorMutationVariables
>;
export const UpdatePlaylistTagsDocument = gql`
  mutation updatePlaylistTags($input: UpdatePlaylistTagsInput!) {
    updatePlaylistTags(input: $input) {
      playlist {
        __typename
        id
        name
        tags
      }
    }
  }
`;
export type UpdatePlaylistTagsMutationFn = Apollo.MutationFunction<
  UpdatePlaylistTagsMutation,
  UpdatePlaylistTagsMutationVariables
>;
export type UpdatePlaylistTagsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdatePlaylistTagsMutation,
    UpdatePlaylistTagsMutationVariables
  >;
} &
  TChildProps;
export function withUpdatePlaylistTags<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlaylistTagsMutation,
    UpdatePlaylistTagsMutationVariables,
    UpdatePlaylistTagsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlaylistTagsMutation,
    UpdatePlaylistTagsMutationVariables,
    UpdatePlaylistTagsProps<TChildProps, TDataName>
  >(UpdatePlaylistTagsDocument, {
    alias: "updatePlaylistTags",
    ...operationOptions,
  });
}

/**
 * __useUpdatePlaylistTagsMutation__
 *
 * To run a mutation, you first call `useUpdatePlaylistTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaylistTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaylistTagsMutation, { data, loading, error }] = useUpdatePlaylistTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaylistTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlaylistTagsMutation,
    UpdatePlaylistTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlaylistTagsMutation,
    UpdatePlaylistTagsMutationVariables
  >(UpdatePlaylistTagsDocument, options);
}
export type UpdatePlaylistTagsMutationHookResult = ReturnType<
  typeof useUpdatePlaylistTagsMutation
>;
export type UpdatePlaylistTagsMutationResult = Apollo.MutationResult<UpdatePlaylistTagsMutation>;
export type UpdatePlaylistTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlaylistTagsMutation,
  UpdatePlaylistTagsMutationVariables
>;
export const AllPlaylistListDocument = gql`
  query allPlaylistList($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      id
      name
      publishedPlaylistsBySpaceId(
        filter: { spaceId: { equalTo: $spaceId }, isEnabled: { equalTo: true } }
      ) {
        nodes {
          __typename
          id
          spaceId
        }
      }
    }
  }
`;
export type AllPlaylistListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllPlaylistListQuery,
    AllPlaylistListQueryVariables
  >;
} &
  TChildProps;
export function withAllPlaylistList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllPlaylistListQuery,
    AllPlaylistListQueryVariables,
    AllPlaylistListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllPlaylistListQuery,
    AllPlaylistListQueryVariables,
    AllPlaylistListProps<TChildProps, TDataName>
  >(AllPlaylistListDocument, {
    alias: "allPlaylistList",
    ...operationOptions,
  });
}

/**
 * __useAllPlaylistListQuery__
 *
 * To run a query within a React component, call `useAllPlaylistListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPlaylistListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPlaylistListQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllPlaylistListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllPlaylistListQuery,
    AllPlaylistListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllPlaylistListQuery, AllPlaylistListQueryVariables>(
    AllPlaylistListDocument,
    options
  );
}
export function useAllPlaylistListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPlaylistListQuery,
    AllPlaylistListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPlaylistListQuery,
    AllPlaylistListQueryVariables
  >(AllPlaylistListDocument, options);
}
export type AllPlaylistListQueryHookResult = ReturnType<
  typeof useAllPlaylistListQuery
>;
export type AllPlaylistListLazyQueryHookResult = ReturnType<
  typeof useAllPlaylistListLazyQuery
>;
export type AllPlaylistListQueryResult = Apollo.QueryResult<
  AllPlaylistListQuery,
  AllPlaylistListQueryVariables
>;
export function refetchAllPlaylistListQuery(
  variables?: AllPlaylistListQueryVariables
) {
  return { query: AllPlaylistListDocument, variables: variables };
}
export const AllSearchPlaylistDocument = gql`
  query allSearchPlaylist($query: String, $spaceId: UUID) {
    searchPlaylist(
      query: $query
      spaceId: $spaceId
      filter: { spaceId: { equalTo: $spaceId }, isEnabled: { equalTo: true } }
    ) {
      nodes {
        __typename
        id
        spaceId
      }
    }
  }
`;
export type AllSearchPlaylistProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllSearchPlaylistQuery,
    AllSearchPlaylistQueryVariables
  >;
} &
  TChildProps;
export function withAllSearchPlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllSearchPlaylistQuery,
    AllSearchPlaylistQueryVariables,
    AllSearchPlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllSearchPlaylistQuery,
    AllSearchPlaylistQueryVariables,
    AllSearchPlaylistProps<TChildProps, TDataName>
  >(AllSearchPlaylistDocument, {
    alias: "allSearchPlaylist",
    ...operationOptions,
  });
}

/**
 * __useAllSearchPlaylistQuery__
 *
 * To run a query within a React component, call `useAllSearchPlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSearchPlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSearchPlaylistQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllSearchPlaylistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllSearchPlaylistQuery,
    AllSearchPlaylistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllSearchPlaylistQuery,
    AllSearchPlaylistQueryVariables
  >(AllSearchPlaylistDocument, options);
}
export function useAllSearchPlaylistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllSearchPlaylistQuery,
    AllSearchPlaylistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllSearchPlaylistQuery,
    AllSearchPlaylistQueryVariables
  >(AllSearchPlaylistDocument, options);
}
export type AllSearchPlaylistQueryHookResult = ReturnType<
  typeof useAllSearchPlaylistQuery
>;
export type AllSearchPlaylistLazyQueryHookResult = ReturnType<
  typeof useAllSearchPlaylistLazyQuery
>;
export type AllSearchPlaylistQueryResult = Apollo.QueryResult<
  AllSearchPlaylistQuery,
  AllSearchPlaylistQueryVariables
>;
export function refetchAllSearchPlaylistQuery(
  variables?: AllSearchPlaylistQueryVariables
) {
  return { query: AllSearchPlaylistDocument, variables: variables };
}
export const CreatePlaylistDocument = gql`
  mutation createPlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      playlist {
        ...PlaylistListItem
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type CreatePlaylistMutationFn = Apollo.MutationFunction<
  CreatePlaylistMutation,
  CreatePlaylistMutationVariables
>;
export type CreatePlaylistProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables
  >;
} &
  TChildProps;
export function withCreatePlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables,
    CreatePlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables,
    CreatePlaylistProps<TChildProps, TDataName>
  >(CreatePlaylistDocument, {
    alias: "createPlaylist",
    ...operationOptions,
  });
}

/**
 * __useCreatePlaylistMutation__
 *
 * To run a mutation, you first call `useCreatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaylistMutation, { data, loading, error }] = useCreatePlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables
  >(CreatePlaylistDocument, options);
}
export type CreatePlaylistMutationHookResult = ReturnType<
  typeof useCreatePlaylistMutation
>;
export type CreatePlaylistMutationResult = Apollo.MutationResult<CreatePlaylistMutation>;
export type CreatePlaylistMutationOptions = Apollo.BaseMutationOptions<
  CreatePlaylistMutation,
  CreatePlaylistMutationVariables
>;
export const DeletePlaylistDocument = gql`
  mutation deletePlaylist($input: DeletePlaylistInput!) {
    deletePlaylist(input: $input) {
      playlist {
        __typename
        id
        name
      }
    }
  }
`;
export type DeletePlaylistMutationFn = Apollo.MutationFunction<
  DeletePlaylistMutation,
  DeletePlaylistMutationVariables
>;
export type DeletePlaylistProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeletePlaylistMutation,
    DeletePlaylistMutationVariables
  >;
} &
  TChildProps;
export function withDeletePlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletePlaylistMutation,
    DeletePlaylistMutationVariables,
    DeletePlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeletePlaylistMutation,
    DeletePlaylistMutationVariables,
    DeletePlaylistProps<TChildProps, TDataName>
  >(DeletePlaylistDocument, {
    alias: "deletePlaylist",
    ...operationOptions,
  });
}

/**
 * __useDeletePlaylistMutation__
 *
 * To run a mutation, you first call `useDeletePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaylistMutation, { data, loading, error }] = useDeletePlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlaylistMutation,
    DeletePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePlaylistMutation,
    DeletePlaylistMutationVariables
  >(DeletePlaylistDocument, options);
}
export type DeletePlaylistMutationHookResult = ReturnType<
  typeof useDeletePlaylistMutation
>;
export type DeletePlaylistMutationResult = Apollo.MutationResult<DeletePlaylistMutation>;
export type DeletePlaylistMutationOptions = Apollo.BaseMutationOptions<
  DeletePlaylistMutation,
  DeletePlaylistMutationVariables
>;
export const DuplicatePlaylistDocument = gql`
  mutation duplicatePlaylist($input: DuplicatePlaylistInput!) {
    duplicatePlaylist(input: $input) {
      playlist {
        ...PlaylistListItem
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type DuplicatePlaylistMutationFn = Apollo.MutationFunction<
  DuplicatePlaylistMutation,
  DuplicatePlaylistMutationVariables
>;
export type DuplicatePlaylistProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DuplicatePlaylistMutation,
    DuplicatePlaylistMutationVariables
  >;
} &
  TChildProps;
export function withDuplicatePlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DuplicatePlaylistMutation,
    DuplicatePlaylistMutationVariables,
    DuplicatePlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DuplicatePlaylistMutation,
    DuplicatePlaylistMutationVariables,
    DuplicatePlaylistProps<TChildProps, TDataName>
  >(DuplicatePlaylistDocument, {
    alias: "duplicatePlaylist",
    ...operationOptions,
  });
}

/**
 * __useDuplicatePlaylistMutation__
 *
 * To run a mutation, you first call `useDuplicatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePlaylistMutation, { data, loading, error }] = useDuplicatePlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicatePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicatePlaylistMutation,
    DuplicatePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicatePlaylistMutation,
    DuplicatePlaylistMutationVariables
  >(DuplicatePlaylistDocument, options);
}
export type DuplicatePlaylistMutationHookResult = ReturnType<
  typeof useDuplicatePlaylistMutation
>;
export type DuplicatePlaylistMutationResult = Apollo.MutationResult<DuplicatePlaylistMutation>;
export type DuplicatePlaylistMutationOptions = Apollo.BaseMutationOptions<
  DuplicatePlaylistMutation,
  DuplicatePlaylistMutationVariables
>;
export const GetTotalPlaylistsDocument = gql`
  query getTotalPlaylists($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      id
      name
      publishedPlaylistsBySpaceId(filter: { isEnabled: { equalTo: true } }) {
        totalCount
      }
    }
  }
`;
export type GetTotalPlaylistsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetTotalPlaylistsQuery,
    GetTotalPlaylistsQueryVariables
  >;
} &
  TChildProps;
export function withGetTotalPlaylists<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetTotalPlaylistsQuery,
    GetTotalPlaylistsQueryVariables,
    GetTotalPlaylistsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetTotalPlaylistsQuery,
    GetTotalPlaylistsQueryVariables,
    GetTotalPlaylistsProps<TChildProps, TDataName>
  >(GetTotalPlaylistsDocument, {
    alias: "getTotalPlaylists",
    ...operationOptions,
  });
}

/**
 * __useGetTotalPlaylistsQuery__
 *
 * To run a query within a React component, call `useGetTotalPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalPlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalPlaylistsQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetTotalPlaylistsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTotalPlaylistsQuery,
    GetTotalPlaylistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTotalPlaylistsQuery,
    GetTotalPlaylistsQueryVariables
  >(GetTotalPlaylistsDocument, options);
}
export function useGetTotalPlaylistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTotalPlaylistsQuery,
    GetTotalPlaylistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTotalPlaylistsQuery,
    GetTotalPlaylistsQueryVariables
  >(GetTotalPlaylistsDocument, options);
}
export type GetTotalPlaylistsQueryHookResult = ReturnType<
  typeof useGetTotalPlaylistsQuery
>;
export type GetTotalPlaylistsLazyQueryHookResult = ReturnType<
  typeof useGetTotalPlaylistsLazyQuery
>;
export type GetTotalPlaylistsQueryResult = Apollo.QueryResult<
  GetTotalPlaylistsQuery,
  GetTotalPlaylistsQueryVariables
>;
export function refetchGetTotalPlaylistsQuery(
  variables?: GetTotalPlaylistsQueryVariables
) {
  return { query: GetTotalPlaylistsDocument, variables: variables };
}
export const PlaylistByIdForContentDocument = gql`
  query playlistByIdForContent($id: UUID!) {
    playlistById(id: $id) {
      __typename
      id
      orgId
      spaceId
      name
      color
      content
      isPublished
      draft {
        __typename
        id
        isPublished
      }
      appInstancesByPlaylistId(first: 10) {
        nodes {
          __typename
          id
          config
          fileByThumbnailFileId {
            __typename
            id
            metadata
            mimetype
            fileProcessingStatus
            source
          }
          appByAppId {
            __typename
            id
            name
            iconUrl
          }
        }
      }
      filesByPlaylistId(first: 10) {
        nodes {
          __typename
          id
          metadata
          mimetype
          fileProcessingStatus
          fileOutputsByFileId(first: 10) {
            nodes {
              ...FileOutput
            }
          }
        }
      }
      linksByPlaylistId(first: 10) {
        nodes {
          __typename
          id
          fileByFileId {
            __typename
            id
            metadata
            mimetype
            fileProcessingStatus
          }
        }
      }
      sitesByPlaylistId(first: 10) {
        nodes {
          __typename
          id
          fileByThumbnailId {
            __typename
            id
            metadata
            mimetype
            fileProcessingStatus
          }
        }
      }
    }
  }
  ${FileOutputFragmentDoc}
`;
export type PlaylistByIdForContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PlaylistByIdForContentQuery,
    PlaylistByIdForContentQueryVariables
  >;
} &
  TChildProps;
export function withPlaylistByIdForContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PlaylistByIdForContentQuery,
    PlaylistByIdForContentQueryVariables,
    PlaylistByIdForContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PlaylistByIdForContentQuery,
    PlaylistByIdForContentQueryVariables,
    PlaylistByIdForContentProps<TChildProps, TDataName>
  >(PlaylistByIdForContentDocument, {
    alias: "playlistByIdForContent",
    ...operationOptions,
  });
}

/**
 * __usePlaylistByIdForContentQuery__
 *
 * To run a query within a React component, call `usePlaylistByIdForContentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistByIdForContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistByIdForContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistByIdForContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistByIdForContentQuery,
    PlaylistByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlaylistByIdForContentQuery,
    PlaylistByIdForContentQueryVariables
  >(PlaylistByIdForContentDocument, options);
}
export function usePlaylistByIdForContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistByIdForContentQuery,
    PlaylistByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlaylistByIdForContentQuery,
    PlaylistByIdForContentQueryVariables
  >(PlaylistByIdForContentDocument, options);
}
export type PlaylistByIdForContentQueryHookResult = ReturnType<
  typeof usePlaylistByIdForContentQuery
>;
export type PlaylistByIdForContentLazyQueryHookResult = ReturnType<
  typeof usePlaylistByIdForContentLazyQuery
>;
export type PlaylistByIdForContentQueryResult = Apollo.QueryResult<
  PlaylistByIdForContentQuery,
  PlaylistByIdForContentQueryVariables
>;
export function refetchPlaylistByIdForContentQuery(
  variables?: PlaylistByIdForContentQueryVariables
) {
  return { query: PlaylistByIdForContentDocument, variables: variables };
}
export const PlaylistByIdForListingDocument = gql`
  query playlistByIdForListing($id: UUID!) {
    playlistById(id: $id) {
      __typename
      id
      tags
      color
      content
      spaceId
      isFavorite
      isSharedExplicit
      isSharedAll
      isEmbedEnabled
      isEmbedPublic
      embedsByPlaylistId {
        nodes {
          __typename
          id
          shortId
        }
      }
      sharedSpacesByPlaylistId {
        nodes {
          __typename
          id
        }
      }
      castedScreenByPlaylistId {
        nodes {
          __typename
          id
          name
          spaceId
          device
        }
        totalCount
      }
      draft {
        ...PlaylistByIdForListing
      }
      published {
        ...PlaylistByIdForListing
      }
    }
  }
  ${PlaylistByIdForListingFragmentDoc}
`;
export type PlaylistByIdForListingProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PlaylistByIdForListingQuery,
    PlaylistByIdForListingQueryVariables
  >;
} &
  TChildProps;
export function withPlaylistByIdForListing<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PlaylistByIdForListingQuery,
    PlaylistByIdForListingQueryVariables,
    PlaylistByIdForListingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PlaylistByIdForListingQuery,
    PlaylistByIdForListingQueryVariables,
    PlaylistByIdForListingProps<TChildProps, TDataName>
  >(PlaylistByIdForListingDocument, {
    alias: "playlistByIdForListing",
    ...operationOptions,
  });
}

/**
 * __usePlaylistByIdForListingQuery__
 *
 * To run a query within a React component, call `usePlaylistByIdForListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistByIdForListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistByIdForListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistByIdForListingQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistByIdForListingQuery,
    PlaylistByIdForListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlaylistByIdForListingQuery,
    PlaylistByIdForListingQueryVariables
  >(PlaylistByIdForListingDocument, options);
}
export function usePlaylistByIdForListingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistByIdForListingQuery,
    PlaylistByIdForListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlaylistByIdForListingQuery,
    PlaylistByIdForListingQueryVariables
  >(PlaylistByIdForListingDocument, options);
}
export type PlaylistByIdForListingQueryHookResult = ReturnType<
  typeof usePlaylistByIdForListingQuery
>;
export type PlaylistByIdForListingLazyQueryHookResult = ReturnType<
  typeof usePlaylistByIdForListingLazyQuery
>;
export type PlaylistByIdForListingQueryResult = Apollo.QueryResult<
  PlaylistByIdForListingQuery,
  PlaylistByIdForListingQueryVariables
>;
export function refetchPlaylistByIdForListingQuery(
  variables?: PlaylistByIdForListingQueryVariables
) {
  return { query: PlaylistByIdForListingDocument, variables: variables };
}
export const PlaylistListDocument = gql`
  query playlistList($spaceId: UUID!, $first: Int, $endCursor: Cursor) {
    spaceById(id: $spaceId) {
      id
      name
      publishedPlaylistsBySpaceId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        filter: { spaceId: { equalTo: $spaceId }, isEnabled: { equalTo: true } }
      ) {
        nodes {
          ...PlaylistListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type PlaylistListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PlaylistListQuery,
    PlaylistListQueryVariables
  >;
} &
  TChildProps;
export function withPlaylistList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PlaylistListQuery,
    PlaylistListQueryVariables,
    PlaylistListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PlaylistListQuery,
    PlaylistListQueryVariables,
    PlaylistListProps<TChildProps, TDataName>
  >(PlaylistListDocument, {
    alias: "playlistList",
    ...operationOptions,
  });
}

/**
 * __usePlaylistListQuery__
 *
 * To run a query within a React component, call `usePlaylistListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistListQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function usePlaylistListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistListQuery,
    PlaylistListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlaylistListQuery, PlaylistListQueryVariables>(
    PlaylistListDocument,
    options
  );
}
export function usePlaylistListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistListQuery,
    PlaylistListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlaylistListQuery, PlaylistListQueryVariables>(
    PlaylistListDocument,
    options
  );
}
export type PlaylistListQueryHookResult = ReturnType<
  typeof usePlaylistListQuery
>;
export type PlaylistListLazyQueryHookResult = ReturnType<
  typeof usePlaylistListLazyQuery
>;
export type PlaylistListQueryResult = Apollo.QueryResult<
  PlaylistListQuery,
  PlaylistListQueryVariables
>;
export function refetchPlaylistListQuery(
  variables?: PlaylistListQueryVariables
) {
  return { query: PlaylistListDocument, variables: variables };
}
export const PlaylistSharedListDocument = gql`
  query playlistSharedList($spaceId: UUID!, $first: Int, $endCursor: Cursor) {
    spaceById(id: $spaceId) {
      id
      name
      publishedPlaylistsBySpaceId(
        first: $first
        after: $endCursor
        orderBy: [NAME_ASC, ID_DESC]
        filter: {
          spaceId: { notEqualTo: $spaceId }
          isEnabled: { equalTo: true }
        }
      ) {
        nodes {
          ...PlaylistListItem
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type PlaylistSharedListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    PlaylistSharedListQuery,
    PlaylistSharedListQueryVariables
  >;
} &
  TChildProps;
export function withPlaylistSharedList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PlaylistSharedListQuery,
    PlaylistSharedListQueryVariables,
    PlaylistSharedListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PlaylistSharedListQuery,
    PlaylistSharedListQueryVariables,
    PlaylistSharedListProps<TChildProps, TDataName>
  >(PlaylistSharedListDocument, {
    alias: "playlistSharedList",
    ...operationOptions,
  });
}

/**
 * __usePlaylistSharedListQuery__
 *
 * To run a query within a React component, call `usePlaylistSharedListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistSharedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistSharedListQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function usePlaylistSharedListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlaylistSharedListQuery,
    PlaylistSharedListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlaylistSharedListQuery,
    PlaylistSharedListQueryVariables
  >(PlaylistSharedListDocument, options);
}
export function usePlaylistSharedListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistSharedListQuery,
    PlaylistSharedListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlaylistSharedListQuery,
    PlaylistSharedListQueryVariables
  >(PlaylistSharedListDocument, options);
}
export type PlaylistSharedListQueryHookResult = ReturnType<
  typeof usePlaylistSharedListQuery
>;
export type PlaylistSharedListLazyQueryHookResult = ReturnType<
  typeof usePlaylistSharedListLazyQuery
>;
export type PlaylistSharedListQueryResult = Apollo.QueryResult<
  PlaylistSharedListQuery,
  PlaylistSharedListQueryVariables
>;
export function refetchPlaylistSharedListQuery(
  variables?: PlaylistSharedListQueryVariables
) {
  return { query: PlaylistSharedListDocument, variables: variables };
}
export const SearchPlaylistDocument = gql`
  query searchPlaylist(
    $query: String
    $spaceId: UUID
    $first: Int
    $endCursor: Cursor
    $playlistFilter: PlaylistFilter
  ) {
    searchPlaylist(
      query: $query
      spaceId: $spaceId
      first: $first
      after: $endCursor
      orderBy: [NAME_ASC, ID_ASC]
      filter: $playlistFilter
    ) {
      nodes {
        ...PlaylistListItem
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type SearchPlaylistProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchPlaylistQuery,
    SearchPlaylistQueryVariables
  >;
} &
  TChildProps;
export function withSearchPlaylist<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchPlaylistQuery,
    SearchPlaylistQueryVariables,
    SearchPlaylistProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchPlaylistQuery,
    SearchPlaylistQueryVariables,
    SearchPlaylistProps<TChildProps, TDataName>
  >(SearchPlaylistDocument, {
    alias: "searchPlaylist",
    ...operationOptions,
  });
}

/**
 * __useSearchPlaylistQuery__
 *
 * To run a query within a React component, call `useSearchPlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlaylistQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      endCursor: // value for 'endCursor'
 *      playlistFilter: // value for 'playlistFilter'
 *   },
 * });
 */
export function useSearchPlaylistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchPlaylistQuery,
    SearchPlaylistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPlaylistQuery, SearchPlaylistQueryVariables>(
    SearchPlaylistDocument,
    options
  );
}
export function useSearchPlaylistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPlaylistQuery,
    SearchPlaylistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPlaylistQuery, SearchPlaylistQueryVariables>(
    SearchPlaylistDocument,
    options
  );
}
export type SearchPlaylistQueryHookResult = ReturnType<
  typeof useSearchPlaylistQuery
>;
export type SearchPlaylistLazyQueryHookResult = ReturnType<
  typeof useSearchPlaylistLazyQuery
>;
export type SearchPlaylistQueryResult = Apollo.QueryResult<
  SearchPlaylistQuery,
  SearchPlaylistQueryVariables
>;
export function refetchSearchPlaylistQuery(
  variables?: SearchPlaylistQueryVariables
) {
  return { query: SearchPlaylistDocument, variables: variables };
}
export const UpdatePlaylistEmbedDocument = gql`
  mutation UpdatePlaylistEmbed($input: UpdatePlaylistEmbedInput!) {
    updatePlaylistEmbed(input: $input) {
      playlist {
        __typename
        id
        isEmbedEnabled
        isEmbedPublic
      }
    }
  }
`;
export type UpdatePlaylistEmbedMutationFn = Apollo.MutationFunction<
  UpdatePlaylistEmbedMutation,
  UpdatePlaylistEmbedMutationVariables
>;
export type UpdatePlaylistEmbedProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdatePlaylistEmbedMutation,
    UpdatePlaylistEmbedMutationVariables
  >;
} &
  TChildProps;
export function withUpdatePlaylistEmbed<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlaylistEmbedMutation,
    UpdatePlaylistEmbedMutationVariables,
    UpdatePlaylistEmbedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlaylistEmbedMutation,
    UpdatePlaylistEmbedMutationVariables,
    UpdatePlaylistEmbedProps<TChildProps, TDataName>
  >(UpdatePlaylistEmbedDocument, {
    alias: "updatePlaylistEmbed",
    ...operationOptions,
  });
}

/**
 * __useUpdatePlaylistEmbedMutation__
 *
 * To run a mutation, you first call `useUpdatePlaylistEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaylistEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaylistEmbedMutation, { data, loading, error }] = useUpdatePlaylistEmbedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaylistEmbedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlaylistEmbedMutation,
    UpdatePlaylistEmbedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlaylistEmbedMutation,
    UpdatePlaylistEmbedMutationVariables
  >(UpdatePlaylistEmbedDocument, options);
}
export type UpdatePlaylistEmbedMutationHookResult = ReturnType<
  typeof useUpdatePlaylistEmbedMutation
>;
export type UpdatePlaylistEmbedMutationResult = Apollo.MutationResult<UpdatePlaylistEmbedMutation>;
export type UpdatePlaylistEmbedMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlaylistEmbedMutation,
  UpdatePlaylistEmbedMutationVariables
>;
export const UpdatePlaylistIsFavoriteDocument = gql`
  mutation updatePlaylistIsFavorite($input: UpdatePlaylistIsFavoriteInput!) {
    updatePlaylistIsFavorite(input: $input) {
      favorites {
        playlistId
        playlistByPlaylistId {
          __typename
          id
          isFavorite
        }
      }
    }
  }
`;
export type UpdatePlaylistIsFavoriteMutationFn = Apollo.MutationFunction<
  UpdatePlaylistIsFavoriteMutation,
  UpdatePlaylistIsFavoriteMutationVariables
>;
export type UpdatePlaylistIsFavoriteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdatePlaylistIsFavoriteMutation,
    UpdatePlaylistIsFavoriteMutationVariables
  >;
} &
  TChildProps;
export function withUpdatePlaylistIsFavorite<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlaylistIsFavoriteMutation,
    UpdatePlaylistIsFavoriteMutationVariables,
    UpdatePlaylistIsFavoriteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlaylistIsFavoriteMutation,
    UpdatePlaylistIsFavoriteMutationVariables,
    UpdatePlaylistIsFavoriteProps<TChildProps, TDataName>
  >(UpdatePlaylistIsFavoriteDocument, {
    alias: "updatePlaylistIsFavorite",
    ...operationOptions,
  });
}

/**
 * __useUpdatePlaylistIsFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdatePlaylistIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaylistIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaylistIsFavoriteMutation, { data, loading, error }] = useUpdatePlaylistIsFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaylistIsFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlaylistIsFavoriteMutation,
    UpdatePlaylistIsFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlaylistIsFavoriteMutation,
    UpdatePlaylistIsFavoriteMutationVariables
  >(UpdatePlaylistIsFavoriteDocument, options);
}
export type UpdatePlaylistIsFavoriteMutationHookResult = ReturnType<
  typeof useUpdatePlaylistIsFavoriteMutation
>;
export type UpdatePlaylistIsFavoriteMutationResult = Apollo.MutationResult<UpdatePlaylistIsFavoriteMutation>;
export type UpdatePlaylistIsFavoriteMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlaylistIsFavoriteMutation,
  UpdatePlaylistIsFavoriteMutationVariables
>;
export const UpdatePlaylistNameDocument = gql`
  mutation UpdatePlaylistName($input: UpdatePlaylistNameInput!) {
    updatePlaylistName(input: $input) {
      playlist {
        ...PlaylistListItem
      }
    }
  }
  ${PlaylistListItemFragmentDoc}
`;
export type UpdatePlaylistNameMutationFn = Apollo.MutationFunction<
  UpdatePlaylistNameMutation,
  UpdatePlaylistNameMutationVariables
>;
export type UpdatePlaylistNameProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdatePlaylistNameMutation,
    UpdatePlaylistNameMutationVariables
  >;
} &
  TChildProps;
export function withUpdatePlaylistName<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlaylistNameMutation,
    UpdatePlaylistNameMutationVariables,
    UpdatePlaylistNameProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlaylistNameMutation,
    UpdatePlaylistNameMutationVariables,
    UpdatePlaylistNameProps<TChildProps, TDataName>
  >(UpdatePlaylistNameDocument, {
    alias: "updatePlaylistName",
    ...operationOptions,
  });
}

/**
 * __useUpdatePlaylistNameMutation__
 *
 * To run a mutation, you first call `useUpdatePlaylistNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaylistNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaylistNameMutation, { data, loading, error }] = useUpdatePlaylistNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaylistNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlaylistNameMutation,
    UpdatePlaylistNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlaylistNameMutation,
    UpdatePlaylistNameMutationVariables
  >(UpdatePlaylistNameDocument, options);
}
export type UpdatePlaylistNameMutationHookResult = ReturnType<
  typeof useUpdatePlaylistNameMutation
>;
export type UpdatePlaylistNameMutationResult = Apollo.MutationResult<UpdatePlaylistNameMutation>;
export type UpdatePlaylistNameMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlaylistNameMutation,
  UpdatePlaylistNameMutationVariables
>;
export const UpdateSharePlaylistToAllSpacesDocument = gql`
  mutation UpdateSharePlaylistToAllSpaces(
    $input: UpdateSharePlaylistToAllSpacesInput!
  ) {
    updateSharePlaylistToAllSpaces(input: $input) {
      playlist {
        __typename
        id
        isSharedAll
        isSharedExplicit
        sharedSpacesByPlaylistId {
          nodes {
            __typename
            id
          }
        }
      }
    }
  }
`;
export type UpdateSharePlaylistToAllSpacesMutationFn = Apollo.MutationFunction<
  UpdateSharePlaylistToAllSpacesMutation,
  UpdateSharePlaylistToAllSpacesMutationVariables
>;
export type UpdateSharePlaylistToAllSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateSharePlaylistToAllSpacesMutation,
    UpdateSharePlaylistToAllSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateSharePlaylistToAllSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSharePlaylistToAllSpacesMutation,
    UpdateSharePlaylistToAllSpacesMutationVariables,
    UpdateSharePlaylistToAllSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSharePlaylistToAllSpacesMutation,
    UpdateSharePlaylistToAllSpacesMutationVariables,
    UpdateSharePlaylistToAllSpacesProps<TChildProps, TDataName>
  >(UpdateSharePlaylistToAllSpacesDocument, {
    alias: "updateSharePlaylistToAllSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateSharePlaylistToAllSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateSharePlaylistToAllSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSharePlaylistToAllSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSharePlaylistToAllSpacesMutation, { data, loading, error }] = useUpdateSharePlaylistToAllSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSharePlaylistToAllSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSharePlaylistToAllSpacesMutation,
    UpdateSharePlaylistToAllSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSharePlaylistToAllSpacesMutation,
    UpdateSharePlaylistToAllSpacesMutationVariables
  >(UpdateSharePlaylistToAllSpacesDocument, options);
}
export type UpdateSharePlaylistToAllSpacesMutationHookResult = ReturnType<
  typeof useUpdateSharePlaylistToAllSpacesMutation
>;
export type UpdateSharePlaylistToAllSpacesMutationResult = Apollo.MutationResult<UpdateSharePlaylistToAllSpacesMutation>;
export type UpdateSharePlaylistToAllSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateSharePlaylistToAllSpacesMutation,
  UpdateSharePlaylistToAllSpacesMutationVariables
>;
export const UpdateSharePlaylistToSpacesDocument = gql`
  mutation UpdateSharePlaylistToSpaces(
    $input: UpdateSharePlaylistToSpacesInput!
  ) {
    updateSharePlaylistToSpaces(input: $input) {
      playlist {
        __typename
        id
        isSharedExplicit
        isSharedAll
        sharedSpacesByPlaylistId {
          nodes {
            __typename
            id
          }
        }
      }
    }
  }
`;
export type UpdateSharePlaylistToSpacesMutationFn = Apollo.MutationFunction<
  UpdateSharePlaylistToSpacesMutation,
  UpdateSharePlaylistToSpacesMutationVariables
>;
export type UpdateSharePlaylistToSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateSharePlaylistToSpacesMutation,
    UpdateSharePlaylistToSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateSharePlaylistToSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSharePlaylistToSpacesMutation,
    UpdateSharePlaylistToSpacesMutationVariables,
    UpdateSharePlaylistToSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSharePlaylistToSpacesMutation,
    UpdateSharePlaylistToSpacesMutationVariables,
    UpdateSharePlaylistToSpacesProps<TChildProps, TDataName>
  >(UpdateSharePlaylistToSpacesDocument, {
    alias: "updateSharePlaylistToSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateSharePlaylistToSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateSharePlaylistToSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSharePlaylistToSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSharePlaylistToSpacesMutation, { data, loading, error }] = useUpdateSharePlaylistToSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSharePlaylistToSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSharePlaylistToSpacesMutation,
    UpdateSharePlaylistToSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSharePlaylistToSpacesMutation,
    UpdateSharePlaylistToSpacesMutationVariables
  >(UpdateSharePlaylistToSpacesDocument, options);
}
export type UpdateSharePlaylistToSpacesMutationHookResult = ReturnType<
  typeof useUpdateSharePlaylistToSpacesMutation
>;
export type UpdateSharePlaylistToSpacesMutationResult = Apollo.MutationResult<UpdateSharePlaylistToSpacesMutation>;
export type UpdateSharePlaylistToSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateSharePlaylistToSpacesMutation,
  UpdateSharePlaylistToSpacesMutationVariables
>;
export const CreateScreenGroupDocument = gql`
  mutation createScreenGroup($input: CreateScreenGroupInput!) {
    createScreenGroup(input: $input) {
      screenGroup {
        __typename
        id
        name
        isPrimary
        screensByScreenGroupIdAndOrgId(orderBy: NAME_ASC) {
          nodes {
            __typename
            id
            orgId
            screenGroupId
            castId
            name
            content
            status
            device
            preview
            tags
            preferences
            screenGroupByScreenGroupId {
              __typename
              id
              name
              isPrimary
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
`;
export type CreateScreenGroupMutationFn = Apollo.MutationFunction<
  CreateScreenGroupMutation,
  CreateScreenGroupMutationVariables
>;
export type CreateScreenGroupProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateScreenGroupMutation,
    CreateScreenGroupMutationVariables
  >;
} &
  TChildProps;
export function withCreateScreenGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateScreenGroupMutation,
    CreateScreenGroupMutationVariables,
    CreateScreenGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateScreenGroupMutation,
    CreateScreenGroupMutationVariables,
    CreateScreenGroupProps<TChildProps, TDataName>
  >(CreateScreenGroupDocument, {
    alias: "createScreenGroup",
    ...operationOptions,
  });
}

/**
 * __useCreateScreenGroupMutation__
 *
 * To run a mutation, you first call `useCreateScreenGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScreenGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScreenGroupMutation, { data, loading, error }] = useCreateScreenGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScreenGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScreenGroupMutation,
    CreateScreenGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScreenGroupMutation,
    CreateScreenGroupMutationVariables
  >(CreateScreenGroupDocument, options);
}
export type CreateScreenGroupMutationHookResult = ReturnType<
  typeof useCreateScreenGroupMutation
>;
export type CreateScreenGroupMutationResult = Apollo.MutationResult<CreateScreenGroupMutation>;
export type CreateScreenGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateScreenGroupMutation,
  CreateScreenGroupMutationVariables
>;
export const ExchangePreviewTokenDocument = gql`
  mutation exchangePreviewToken($screenId: UUID!) {
    exchangePreviewToken(screenId: $screenId) {
      previewToken
    }
  }
`;
export type ExchangePreviewTokenMutationFn = Apollo.MutationFunction<
  ExchangePreviewTokenMutation,
  ExchangePreviewTokenMutationVariables
>;
export type ExchangePreviewTokenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ExchangePreviewTokenMutation,
    ExchangePreviewTokenMutationVariables
  >;
} &
  TChildProps;
export function withExchangePreviewToken<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ExchangePreviewTokenMutation,
    ExchangePreviewTokenMutationVariables,
    ExchangePreviewTokenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ExchangePreviewTokenMutation,
    ExchangePreviewTokenMutationVariables,
    ExchangePreviewTokenProps<TChildProps, TDataName>
  >(ExchangePreviewTokenDocument, {
    alias: "exchangePreviewToken",
    ...operationOptions,
  });
}

/**
 * __useExchangePreviewTokenMutation__
 *
 * To run a mutation, you first call `useExchangePreviewTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangePreviewTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangePreviewTokenMutation, { data, loading, error }] = useExchangePreviewTokenMutation({
 *   variables: {
 *      screenId: // value for 'screenId'
 *   },
 * });
 */
export function useExchangePreviewTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExchangePreviewTokenMutation,
    ExchangePreviewTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExchangePreviewTokenMutation,
    ExchangePreviewTokenMutationVariables
  >(ExchangePreviewTokenDocument, options);
}
export type ExchangePreviewTokenMutationHookResult = ReturnType<
  typeof useExchangePreviewTokenMutation
>;
export type ExchangePreviewTokenMutationResult = Apollo.MutationResult<ExchangePreviewTokenMutation>;
export type ExchangePreviewTokenMutationOptions = Apollo.BaseMutationOptions<
  ExchangePreviewTokenMutation,
  ExchangePreviewTokenMutationVariables
>;
export const RefreshScreenDocument = gql`
  mutation refreshScreen($input: RefreshScreenInput!) {
    refreshScreen(input: $input) {
      screen {
        ...Screen
        castByCastId {
          ...Cast
          fileByCastId {
            ...File
          }
          linkByCastId {
            ...Link
          }
          siteByCastId {
            ...Site
          }
          playlistByCastId {
            ...Playlist
          }
        }
      }
    }
  }
  ${ScreenFragmentDoc}
  ${CastFragmentDoc}
  ${FileFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
  ${PlaylistFragmentDoc}
`;
export type RefreshScreenMutationFn = Apollo.MutationFunction<
  RefreshScreenMutation,
  RefreshScreenMutationVariables
>;
export type RefreshScreenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RefreshScreenMutation,
    RefreshScreenMutationVariables
  >;
} &
  TChildProps;
export function withRefreshScreen<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RefreshScreenMutation,
    RefreshScreenMutationVariables,
    RefreshScreenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RefreshScreenMutation,
    RefreshScreenMutationVariables,
    RefreshScreenProps<TChildProps, TDataName>
  >(RefreshScreenDocument, {
    alias: "refreshScreen",
    ...operationOptions,
  });
}

/**
 * __useRefreshScreenMutation__
 *
 * To run a mutation, you first call `useRefreshScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshScreenMutation, { data, loading, error }] = useRefreshScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshScreenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshScreenMutation,
    RefreshScreenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshScreenMutation,
    RefreshScreenMutationVariables
  >(RefreshScreenDocument, options);
}
export type RefreshScreenMutationHookResult = ReturnType<
  typeof useRefreshScreenMutation
>;
export type RefreshScreenMutationResult = Apollo.MutationResult<RefreshScreenMutation>;
export type RefreshScreenMutationOptions = Apollo.BaseMutationOptions<
  RefreshScreenMutation,
  RefreshScreenMutationVariables
>;
export const ScreenByIdDocument = gql`
  query screenById($id: UUID!) {
    screenById(id: $id) {
      ...Screen
      orgByOrgId {
        id
        __typename
        brandChannelId
      }
      castByCastId {
        ...CastByCastId
      }
      spaceBySpaceId {
        __typename
        id
        preferences
      }
    }
  }
  ${ScreenFragmentDoc}
  ${CastByCastIdFragmentDoc}
`;
export type ScreenByIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenByIdQuery,
    ScreenByIdQueryVariables
  >;
} &
  TChildProps;
export function withScreenById<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenByIdQuery,
    ScreenByIdQueryVariables,
    ScreenByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenByIdQuery,
    ScreenByIdQueryVariables,
    ScreenByIdProps<TChildProps, TDataName>
  >(ScreenByIdDocument, {
    alias: "screenById",
    ...operationOptions,
  });
}

/**
 * __useScreenByIdQuery__
 *
 * To run a query within a React component, call `useScreenByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScreenByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenByIdQuery,
    ScreenByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScreenByIdQuery, ScreenByIdQueryVariables>(
    ScreenByIdDocument,
    options
  );
}
export function useScreenByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenByIdQuery,
    ScreenByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScreenByIdQuery, ScreenByIdQueryVariables>(
    ScreenByIdDocument,
    options
  );
}
export type ScreenByIdQueryHookResult = ReturnType<typeof useScreenByIdQuery>;
export type ScreenByIdLazyQueryHookResult = ReturnType<
  typeof useScreenByIdLazyQuery
>;
export type ScreenByIdQueryResult = Apollo.QueryResult<
  ScreenByIdQuery,
  ScreenByIdQueryVariables
>;
export function refetchScreenByIdQuery(variables?: ScreenByIdQueryVariables) {
  return { query: ScreenByIdDocument, variables: variables };
}
export const ScreenDetailDocument = gql`
  query screenDetail($id: UUID!) {
    screenById(id: $id) {
      ...Screen
      screenGroupByScreenGroupId {
        __typename
        id
        name
      }
      castByCastId {
        ...CastByCastIdForScreenDetail
      }
      linksByScreenId(first: 1) {
        nodes {
          __typename
          id
          name
          url
          linkType
          cloudConfig
        }
      }
      sitesByScreenId(first: 1) {
        nodes {
          __typename
          id
          name
          type
          url
          config
          compute
          actions
        }
      }
      filesByScreenId(first: 1) {
        nodes {
          __typename
          id
          orgId
          name
          mimetype
          source
          metadata
          fileProcessingStatus
          availableAt
          expireAt
          fileOutputsByFileId(first: 10) {
            nodes {
              ...FileOutput
            }
          }
        }
      }
      playlistsByScreenId(first: 1) {
        nodes {
          ...PlaylistDetail
        }
      }
      appInstancesByScreenId(first: 1) {
        nodes {
          __typename
          id
          config
          state
          version
          name
          filesByAppInstanceId {
            nodes {
              __typename
              id
              orgId
              name
              mimetype
              source
              metadata
              fileProcessingStatus
              availableAt
              expireAt
            }
          }
          appInstallByAppInstallId {
            __typename
            appId
            id
            orgId
          }
          appVersionByAppInstanceId {
            __typename
            id
            version
            manifestJson
            viewerUrl
            appByAppId {
              __typename
              id
              name
              iconUrl
            }
          }
        }
      }
      channelsByScreenId(first: 1) {
        nodes {
          ...ChannelDetailForScreen
        }
      }
      notificationRulesByScreenIdAndOrgId {
        nodes {
          id
          notificationSubscriptionsByNotificationRuleId {
            nodes {
              id
              userByUserIdAndOrgId {
                id
              }
            }
          }
        }
      }
    }
  }
  ${ScreenFragmentDoc}
  ${CastByCastIdForScreenDetailFragmentDoc}
  ${FileOutputFragmentDoc}
  ${PlaylistDetailFragmentDoc}
  ${ChannelDetailForScreenFragmentDoc}
`;
export type ScreenDetailProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenDetailQuery,
    ScreenDetailQueryVariables
  >;
} &
  TChildProps;
export function withScreenDetail<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenDetailQuery,
    ScreenDetailQueryVariables,
    ScreenDetailProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenDetailQuery,
    ScreenDetailQueryVariables,
    ScreenDetailProps<TChildProps, TDataName>
  >(ScreenDetailDocument, {
    alias: "screenDetail",
    ...operationOptions,
  });
}

/**
 * __useScreenDetailQuery__
 *
 * To run a query within a React component, call `useScreenDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScreenDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenDetailQuery,
    ScreenDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScreenDetailQuery, ScreenDetailQueryVariables>(
    ScreenDetailDocument,
    options
  );
}
export function useScreenDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenDetailQuery,
    ScreenDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScreenDetailQuery, ScreenDetailQueryVariables>(
    ScreenDetailDocument,
    options
  );
}
export type ScreenDetailQueryHookResult = ReturnType<
  typeof useScreenDetailQuery
>;
export type ScreenDetailLazyQueryHookResult = ReturnType<
  typeof useScreenDetailLazyQuery
>;
export type ScreenDetailQueryResult = Apollo.QueryResult<
  ScreenDetailQuery,
  ScreenDetailQueryVariables
>;
export function refetchScreenDetailQuery(
  variables?: ScreenDetailQueryVariables
) {
  return { query: ScreenDetailDocument, variables: variables };
}
export const ScreenGroupsBySpaceIdDocument = gql`
  query screenGroupsBySpaceId($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      screenGroupsBySpaceId(orderBy: [NAME_ASC, CREATED_AT_ASC]) {
        nodes {
          __typename
          id
          orgId
          spaceId
          name
          isPrimary
        }
        totalCount
      }
    }
  }
`;
export type ScreenGroupsBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenGroupsBySpaceIdQuery,
    ScreenGroupsBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withScreenGroupsBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenGroupsBySpaceIdQuery,
    ScreenGroupsBySpaceIdQueryVariables,
    ScreenGroupsBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenGroupsBySpaceIdQuery,
    ScreenGroupsBySpaceIdQueryVariables,
    ScreenGroupsBySpaceIdProps<TChildProps, TDataName>
  >(ScreenGroupsBySpaceIdDocument, {
    alias: "screenGroupsBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useScreenGroupsBySpaceIdQuery__
 *
 * To run a query within a React component, call `useScreenGroupsBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenGroupsBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenGroupsBySpaceIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useScreenGroupsBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenGroupsBySpaceIdQuery,
    ScreenGroupsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScreenGroupsBySpaceIdQuery,
    ScreenGroupsBySpaceIdQueryVariables
  >(ScreenGroupsBySpaceIdDocument, options);
}
export function useScreenGroupsBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenGroupsBySpaceIdQuery,
    ScreenGroupsBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScreenGroupsBySpaceIdQuery,
    ScreenGroupsBySpaceIdQueryVariables
  >(ScreenGroupsBySpaceIdDocument, options);
}
export type ScreenGroupsBySpaceIdQueryHookResult = ReturnType<
  typeof useScreenGroupsBySpaceIdQuery
>;
export type ScreenGroupsBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useScreenGroupsBySpaceIdLazyQuery
>;
export type ScreenGroupsBySpaceIdQueryResult = Apollo.QueryResult<
  ScreenGroupsBySpaceIdQuery,
  ScreenGroupsBySpaceIdQueryVariables
>;
export function refetchScreenGroupsBySpaceIdQuery(
  variables?: ScreenGroupsBySpaceIdQueryVariables
) {
  return { query: ScreenGroupsBySpaceIdDocument, variables: variables };
}
export const TakeScreenshotByScreenIdDocument = gql`
  mutation takeScreenshotByScreenId($input: TakeScreenshotByScreenIdInput!) {
    takeScreenshotByScreenId(input: $input) {
      screenshot {
        __typename
        id
        url
        status
        expires_at
        created_at
        updated_at
        app_id
        player_id
        action_log_id
      }
    }
  }
`;
export type TakeScreenshotByScreenIdMutationFn = Apollo.MutationFunction<
  TakeScreenshotByScreenIdMutation,
  TakeScreenshotByScreenIdMutationVariables
>;
export type TakeScreenshotByScreenIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    TakeScreenshotByScreenIdMutation,
    TakeScreenshotByScreenIdMutationVariables
  >;
} &
  TChildProps;
export function withTakeScreenshotByScreenId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TakeScreenshotByScreenIdMutation,
    TakeScreenshotByScreenIdMutationVariables,
    TakeScreenshotByScreenIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    TakeScreenshotByScreenIdMutation,
    TakeScreenshotByScreenIdMutationVariables,
    TakeScreenshotByScreenIdProps<TChildProps, TDataName>
  >(TakeScreenshotByScreenIdDocument, {
    alias: "takeScreenshotByScreenId",
    ...operationOptions,
  });
}

/**
 * __useTakeScreenshotByScreenIdMutation__
 *
 * To run a mutation, you first call `useTakeScreenshotByScreenIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeScreenshotByScreenIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeScreenshotByScreenIdMutation, { data, loading, error }] = useTakeScreenshotByScreenIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTakeScreenshotByScreenIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TakeScreenshotByScreenIdMutation,
    TakeScreenshotByScreenIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TakeScreenshotByScreenIdMutation,
    TakeScreenshotByScreenIdMutationVariables
  >(TakeScreenshotByScreenIdDocument, options);
}
export type TakeScreenshotByScreenIdMutationHookResult = ReturnType<
  typeof useTakeScreenshotByScreenIdMutation
>;
export type TakeScreenshotByScreenIdMutationResult = Apollo.MutationResult<TakeScreenshotByScreenIdMutation>;
export type TakeScreenshotByScreenIdMutationOptions = Apollo.BaseMutationOptions<
  TakeScreenshotByScreenIdMutation,
  TakeScreenshotByScreenIdMutationVariables
>;
export const ScreenshotsByScreenIdDocument = gql`
  query screenshotsByScreenId($input: ScreenshotsByScreenIdInput!) {
    screenshotsByScreenId(input: $input) {
      screenshots {
        __typename
        id
        url
        status
        expires_at
        created_at
        updated_at
        app_id
        player_id
        action_log_id
        given_name
        family_name
      }
      totalCount
    }
  }
`;
export type ScreenshotsByScreenIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenshotsByScreenIdQuery,
    ScreenshotsByScreenIdQueryVariables
  >;
} &
  TChildProps;
export function withScreenshotsByScreenId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenshotsByScreenIdQuery,
    ScreenshotsByScreenIdQueryVariables,
    ScreenshotsByScreenIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenshotsByScreenIdQuery,
    ScreenshotsByScreenIdQueryVariables,
    ScreenshotsByScreenIdProps<TChildProps, TDataName>
  >(ScreenshotsByScreenIdDocument, {
    alias: "screenshotsByScreenId",
    ...operationOptions,
  });
}

/**
 * __useScreenshotsByScreenIdQuery__
 *
 * To run a query within a React component, call `useScreenshotsByScreenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenshotsByScreenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenshotsByScreenIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScreenshotsByScreenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenshotsByScreenIdQuery,
    ScreenshotsByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScreenshotsByScreenIdQuery,
    ScreenshotsByScreenIdQueryVariables
  >(ScreenshotsByScreenIdDocument, options);
}
export function useScreenshotsByScreenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenshotsByScreenIdQuery,
    ScreenshotsByScreenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScreenshotsByScreenIdQuery,
    ScreenshotsByScreenIdQueryVariables
  >(ScreenshotsByScreenIdDocument, options);
}
export type ScreenshotsByScreenIdQueryHookResult = ReturnType<
  typeof useScreenshotsByScreenIdQuery
>;
export type ScreenshotsByScreenIdLazyQueryHookResult = ReturnType<
  typeof useScreenshotsByScreenIdLazyQuery
>;
export type ScreenshotsByScreenIdQueryResult = Apollo.QueryResult<
  ScreenshotsByScreenIdQuery,
  ScreenshotsByScreenIdQueryVariables
>;
export function refetchScreenshotsByScreenIdQuery(
  variables?: ScreenshotsByScreenIdQueryVariables
) {
  return { query: ScreenshotsByScreenIdDocument, variables: variables };
}
export const UpdateScreenByIdDocument = gql`
  mutation updateScreenById($input: UpdateScreenByIdInput!) {
    updateScreenById(input: $input) {
      screen {
        ...Screen
        screenGroupByScreenGroupId {
          __typename
          id
          name
          isPrimary
        }
        castByCastId {
          ...Cast
          fileByCastId {
            ...File
            fileOutputsByFileId {
              nodes {
                ...FileOutput
              }
              totalCount
            }
          }
          playlistByCastId {
            ...Playlist
          }
          linkByCastId {
            ...Link
          }
          siteByCastId {
            ...Site
          }
          appInstanceByCastId {
            ...AppInstance
            appVersionByAppInstanceId {
              ...AppVersion
              appByAppId {
                __typename
                id
                name
                iconUrl
              }
            }
            appInstallByAppInstallId {
              ...AppInstall
            }
          }
        }
        linksByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        sitesByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        channelsByScreenId {
          nodes {
            __typename
            id
            name
            spaceId
            content
            isSharedExplicit
            isSharedAll
            draft {
              __typename
              id
              isPublished
              content
            }
            published {
              __typename
              id
              isPublished
              content
            }
            childOf
            parentChannelByChildOf {
              ...ParentChannel
            }
          }
        }
        filesByScreenId {
          nodes {
            __typename
            id
            name
            mimetype
          }
        }
        playlistsByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        appInstancesByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
      }
    }
  }
  ${ScreenFragmentDoc}
  ${CastFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${PlaylistFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
  ${AppInstanceFragmentDoc}
  ${AppVersionFragmentDoc}
  ${AppInstallFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export type UpdateScreenByIdMutationFn = Apollo.MutationFunction<
  UpdateScreenByIdMutation,
  UpdateScreenByIdMutationVariables
>;
export type UpdateScreenByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateScreenByIdMutation,
    UpdateScreenByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateScreenById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateScreenByIdMutation,
    UpdateScreenByIdMutationVariables,
    UpdateScreenByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateScreenByIdMutation,
    UpdateScreenByIdMutationVariables,
    UpdateScreenByIdProps<TChildProps, TDataName>
  >(UpdateScreenByIdDocument, {
    alias: "updateScreenById",
    ...operationOptions,
  });
}

/**
 * __useUpdateScreenByIdMutation__
 *
 * To run a mutation, you first call `useUpdateScreenByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScreenByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScreenByIdMutation, { data, loading, error }] = useUpdateScreenByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScreenByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScreenByIdMutation,
    UpdateScreenByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScreenByIdMutation,
    UpdateScreenByIdMutationVariables
  >(UpdateScreenByIdDocument, options);
}
export type UpdateScreenByIdMutationHookResult = ReturnType<
  typeof useUpdateScreenByIdMutation
>;
export type UpdateScreenByIdMutationResult = Apollo.MutationResult<UpdateScreenByIdMutation>;
export type UpdateScreenByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateScreenByIdMutation,
  UpdateScreenByIdMutationVariables
>;
export const AllScreensInCurrentSpaceDocument = gql`
  query allScreensInCurrentSpace($spaceId: UUID!) {
    spaceById(id: $spaceId) {
      __typename
      id
      name
      screensBySpaceId {
        nodes {
          __typename
          id
          name
          env
          device
          deviceProvider
          screenGroupId
          screenGroupByScreenGroupId {
            id
            name
          }
          notificationRulesByScreenIdAndOrgId {
            nodes {
              id
            }
          }
        }
        totalCount
      }
    }
  }
`;
export type AllScreensInCurrentSpaceProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllScreensInCurrentSpaceQuery,
    AllScreensInCurrentSpaceQueryVariables
  >;
} &
  TChildProps;
export function withAllScreensInCurrentSpace<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllScreensInCurrentSpaceQuery,
    AllScreensInCurrentSpaceQueryVariables,
    AllScreensInCurrentSpaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllScreensInCurrentSpaceQuery,
    AllScreensInCurrentSpaceQueryVariables,
    AllScreensInCurrentSpaceProps<TChildProps, TDataName>
  >(AllScreensInCurrentSpaceDocument, {
    alias: "allScreensInCurrentSpace",
    ...operationOptions,
  });
}

/**
 * __useAllScreensInCurrentSpaceQuery__
 *
 * To run a query within a React component, call `useAllScreensInCurrentSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllScreensInCurrentSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllScreensInCurrentSpaceQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAllScreensInCurrentSpaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllScreensInCurrentSpaceQuery,
    AllScreensInCurrentSpaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllScreensInCurrentSpaceQuery,
    AllScreensInCurrentSpaceQueryVariables
  >(AllScreensInCurrentSpaceDocument, options);
}
export function useAllScreensInCurrentSpaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllScreensInCurrentSpaceQuery,
    AllScreensInCurrentSpaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllScreensInCurrentSpaceQuery,
    AllScreensInCurrentSpaceQueryVariables
  >(AllScreensInCurrentSpaceDocument, options);
}
export type AllScreensInCurrentSpaceQueryHookResult = ReturnType<
  typeof useAllScreensInCurrentSpaceQuery
>;
export type AllScreensInCurrentSpaceLazyQueryHookResult = ReturnType<
  typeof useAllScreensInCurrentSpaceLazyQuery
>;
export type AllScreensInCurrentSpaceQueryResult = Apollo.QueryResult<
  AllScreensInCurrentSpaceQuery,
  AllScreensInCurrentSpaceQueryVariables
>;
export function refetchAllScreensInCurrentSpaceQuery(
  variables?: AllScreensInCurrentSpaceQueryVariables
) {
  return { query: AllScreensInCurrentSpaceDocument, variables: variables };
}
export const ClearCacheScreenDocument = gql`
  mutation clearCacheScreen($input: ClearCacheScreenInput!) {
    clearCacheScreen(input: $input) {
      screen {
        __typename
        id
        name
        device
      }
    }
  }
`;
export type ClearCacheScreenMutationFn = Apollo.MutationFunction<
  ClearCacheScreenMutation,
  ClearCacheScreenMutationVariables
>;
export type ClearCacheScreenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ClearCacheScreenMutation,
    ClearCacheScreenMutationVariables
  >;
} &
  TChildProps;
export function withClearCacheScreen<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClearCacheScreenMutation,
    ClearCacheScreenMutationVariables,
    ClearCacheScreenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ClearCacheScreenMutation,
    ClearCacheScreenMutationVariables,
    ClearCacheScreenProps<TChildProps, TDataName>
  >(ClearCacheScreenDocument, {
    alias: "clearCacheScreen",
    ...operationOptions,
  });
}

/**
 * __useClearCacheScreenMutation__
 *
 * To run a mutation, you first call `useClearCacheScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCacheScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCacheScreenMutation, { data, loading, error }] = useClearCacheScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearCacheScreenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearCacheScreenMutation,
    ClearCacheScreenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearCacheScreenMutation,
    ClearCacheScreenMutationVariables
  >(ClearCacheScreenDocument, options);
}
export type ClearCacheScreenMutationHookResult = ReturnType<
  typeof useClearCacheScreenMutation
>;
export type ClearCacheScreenMutationResult = Apollo.MutationResult<ClearCacheScreenMutation>;
export type ClearCacheScreenMutationOptions = Apollo.BaseMutationOptions<
  ClearCacheScreenMutation,
  ClearCacheScreenMutationVariables
>;
export const DeleteScreenGroupDocument = gql`
  mutation deleteScreenGroup($input: DeleteScreenGroupByIdInput!) {
    deleteScreenGroupById(input: $input) {
      screenGroup {
        __typename
        id
        name
        isPrimary
        screensByScreenGroupIdAndOrgId(orderBy: NAME_ASC) {
          nodes {
            ...screensByScreenGroupIdAndOrgId
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
  ${ScreensByScreenGroupIdAndOrgIdFragmentDoc}
`;
export type DeleteScreenGroupMutationFn = Apollo.MutationFunction<
  DeleteScreenGroupMutation,
  DeleteScreenGroupMutationVariables
>;
export type DeleteScreenGroupProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteScreenGroupMutation,
    DeleteScreenGroupMutationVariables
  >;
} &
  TChildProps;
export function withDeleteScreenGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteScreenGroupMutation,
    DeleteScreenGroupMutationVariables,
    DeleteScreenGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteScreenGroupMutation,
    DeleteScreenGroupMutationVariables,
    DeleteScreenGroupProps<TChildProps, TDataName>
  >(DeleteScreenGroupDocument, {
    alias: "deleteScreenGroup",
    ...operationOptions,
  });
}

/**
 * __useDeleteScreenGroupMutation__
 *
 * To run a mutation, you first call `useDeleteScreenGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScreenGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScreenGroupMutation, { data, loading, error }] = useDeleteScreenGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScreenGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScreenGroupMutation,
    DeleteScreenGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScreenGroupMutation,
    DeleteScreenGroupMutationVariables
  >(DeleteScreenGroupDocument, options);
}
export type DeleteScreenGroupMutationHookResult = ReturnType<
  typeof useDeleteScreenGroupMutation
>;
export type DeleteScreenGroupMutationResult = Apollo.MutationResult<DeleteScreenGroupMutation>;
export type DeleteScreenGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteScreenGroupMutation,
  DeleteScreenGroupMutationVariables
>;
export const DepairScreenDocument = gql`
  mutation depairScreen($input: DeleteScreenInput!) {
    deleteScreen(input: $input) {
      screen {
        ...Screen
        castByCastId {
          ...Cast
          fileByCastId {
            ...File
          }
          linkByCastId {
            ...Link
          }
          siteByCastId {
            ...Site
          }
          playlistByCastId {
            ...Playlist
          }
        }
      }
    }
  }
  ${ScreenFragmentDoc}
  ${CastFragmentDoc}
  ${FileFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
  ${PlaylistFragmentDoc}
`;
export type DepairScreenMutationFn = Apollo.MutationFunction<
  DepairScreenMutation,
  DepairScreenMutationVariables
>;
export type DepairScreenProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DepairScreenMutation,
    DepairScreenMutationVariables
  >;
} &
  TChildProps;
export function withDepairScreen<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DepairScreenMutation,
    DepairScreenMutationVariables,
    DepairScreenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DepairScreenMutation,
    DepairScreenMutationVariables,
    DepairScreenProps<TChildProps, TDataName>
  >(DepairScreenDocument, {
    alias: "depairScreen",
    ...operationOptions,
  });
}

/**
 * __useDepairScreenMutation__
 *
 * To run a mutation, you first call `useDepairScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepairScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depairScreenMutation, { data, loading, error }] = useDepairScreenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepairScreenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DepairScreenMutation,
    DepairScreenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DepairScreenMutation,
    DepairScreenMutationVariables
  >(DepairScreenDocument, options);
}
export type DepairScreenMutationHookResult = ReturnType<
  typeof useDepairScreenMutation
>;
export type DepairScreenMutationResult = Apollo.MutationResult<DepairScreenMutation>;
export type DepairScreenMutationOptions = Apollo.BaseMutationOptions<
  DepairScreenMutation,
  DepairScreenMutationVariables
>;
export const GetBillingSubscriptionDocument = gql`
  query getBillingSubscription($spaceId: UUID!) {
    getBillingSubscription(spaceId: $spaceId) {
      ...Subscription
    }
  }
  ${SubscriptionFragmentDoc}
`;
export type GetBillingSubscriptionProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetBillingSubscriptionQuery,
    GetBillingSubscriptionQueryVariables
  >;
} &
  TChildProps;
export function withGetBillingSubscription<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBillingSubscriptionQuery,
    GetBillingSubscriptionQueryVariables,
    GetBillingSubscriptionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBillingSubscriptionQuery,
    GetBillingSubscriptionQueryVariables,
    GetBillingSubscriptionProps<TChildProps, TDataName>
  >(GetBillingSubscriptionDocument, {
    alias: "getBillingSubscription",
    ...operationOptions,
  });
}

/**
 * __useGetBillingSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetBillingSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingSubscriptionQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetBillingSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillingSubscriptionQuery,
    GetBillingSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBillingSubscriptionQuery,
    GetBillingSubscriptionQueryVariables
  >(GetBillingSubscriptionDocument, options);
}
export function useGetBillingSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillingSubscriptionQuery,
    GetBillingSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBillingSubscriptionQuery,
    GetBillingSubscriptionQueryVariables
  >(GetBillingSubscriptionDocument, options);
}
export type GetBillingSubscriptionQueryHookResult = ReturnType<
  typeof useGetBillingSubscriptionQuery
>;
export type GetBillingSubscriptionLazyQueryHookResult = ReturnType<
  typeof useGetBillingSubscriptionLazyQuery
>;
export type GetBillingSubscriptionQueryResult = Apollo.QueryResult<
  GetBillingSubscriptionQuery,
  GetBillingSubscriptionQueryVariables
>;
export function refetchGetBillingSubscriptionQuery(
  variables?: GetBillingSubscriptionQueryVariables
) {
  return { query: GetBillingSubscriptionDocument, variables: variables };
}
export const GetScreenLicencesDocument = gql`
  query getScreenLicences($spaceId: UUID!) {
    getScreenLicences(spaceId: $spaceId) {
      usedLicences
      totalLicences
    }
  }
`;
export type GetScreenLicencesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetScreenLicencesQuery,
    GetScreenLicencesQueryVariables
  >;
} &
  TChildProps;
export function withGetScreenLicences<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetScreenLicencesQuery,
    GetScreenLicencesQueryVariables,
    GetScreenLicencesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetScreenLicencesQuery,
    GetScreenLicencesQueryVariables,
    GetScreenLicencesProps<TChildProps, TDataName>
  >(GetScreenLicencesDocument, {
    alias: "getScreenLicences",
    ...operationOptions,
  });
}

/**
 * __useGetScreenLicencesQuery__
 *
 * To run a query within a React component, call `useGetScreenLicencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScreenLicencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScreenLicencesQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useGetScreenLicencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScreenLicencesQuery,
    GetScreenLicencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScreenLicencesQuery,
    GetScreenLicencesQueryVariables
  >(GetScreenLicencesDocument, options);
}
export function useGetScreenLicencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScreenLicencesQuery,
    GetScreenLicencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScreenLicencesQuery,
    GetScreenLicencesQueryVariables
  >(GetScreenLicencesDocument, options);
}
export type GetScreenLicencesQueryHookResult = ReturnType<
  typeof useGetScreenLicencesQuery
>;
export type GetScreenLicencesLazyQueryHookResult = ReturnType<
  typeof useGetScreenLicencesLazyQuery
>;
export type GetScreenLicencesQueryResult = Apollo.QueryResult<
  GetScreenLicencesQuery,
  GetScreenLicencesQueryVariables
>;
export function refetchGetScreenLicencesQuery(
  variables?: GetScreenLicencesQueryVariables
) {
  return { query: GetScreenLicencesDocument, variables: variables };
}
export const ResetScreenLocationByIdsDocument = gql`
  mutation resetScreenLocationByIds($input: ResetScreenLocationByIdsInput!) {
    resetScreenLocationByIds(input: $input) {
      screens {
        ...screensByScreenGroupIdAndOrgId
      }
    }
  }
  ${ScreensByScreenGroupIdAndOrgIdFragmentDoc}
`;
export type ResetScreenLocationByIdsMutationFn = Apollo.MutationFunction<
  ResetScreenLocationByIdsMutation,
  ResetScreenLocationByIdsMutationVariables
>;
export type ResetScreenLocationByIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ResetScreenLocationByIdsMutation,
    ResetScreenLocationByIdsMutationVariables
  >;
} &
  TChildProps;
export function withResetScreenLocationByIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResetScreenLocationByIdsMutation,
    ResetScreenLocationByIdsMutationVariables,
    ResetScreenLocationByIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResetScreenLocationByIdsMutation,
    ResetScreenLocationByIdsMutationVariables,
    ResetScreenLocationByIdsProps<TChildProps, TDataName>
  >(ResetScreenLocationByIdsDocument, {
    alias: "resetScreenLocationByIds",
    ...operationOptions,
  });
}

/**
 * __useResetScreenLocationByIdsMutation__
 *
 * To run a mutation, you first call `useResetScreenLocationByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetScreenLocationByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetScreenLocationByIdsMutation, { data, loading, error }] = useResetScreenLocationByIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetScreenLocationByIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetScreenLocationByIdsMutation,
    ResetScreenLocationByIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetScreenLocationByIdsMutation,
    ResetScreenLocationByIdsMutationVariables
  >(ResetScreenLocationByIdsDocument, options);
}
export type ResetScreenLocationByIdsMutationHookResult = ReturnType<
  typeof useResetScreenLocationByIdsMutation
>;
export type ResetScreenLocationByIdsMutationResult = Apollo.MutationResult<ResetScreenLocationByIdsMutation>;
export type ResetScreenLocationByIdsMutationOptions = Apollo.BaseMutationOptions<
  ResetScreenLocationByIdsMutation,
  ResetScreenLocationByIdsMutationVariables
>;
export const ScreenListDocument = gql`
  query screenList($spaceId: UUID!, $filter: ScreenFilter, $endCursor: Cursor) {
    defaultScreenGroupBySpaceId(spaceId: $spaceId) {
      id
      name
      isPrimary
      screensByScreenGroupIdAndOrgId(orderBy: NAME_ASC, filter: $filter) {
        nodes {
          ...screensByScreenGroupIdAndOrgId
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
    spaceById(id: $spaceId) {
      __typename
      id
      name
      castsBySpaceId {
        nodes {
          screensByCastId {
            totalCount
          }
        }
        totalCount
      }
      screensBySpaceId(filter: $filter) {
        nodes {
          ...screensByScreenGroupIdAndOrgId
        }
        totalCount
      }
      screenGroupsBySpaceId(
        first: 20
        after: $endCursor
        condition: { isPrimary: false }
        orderBy: [NAME_ASC, CREATED_AT_ASC]
      ) {
        nodes {
          __typename
          id
          name
          isPrimary
          screensByScreenGroupIdAndOrgId(orderBy: NAME_ASC, filter: $filter) {
            nodes {
              ...screensByScreenGroupIdAndOrgId
            }
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${ScreensByScreenGroupIdAndOrgIdFragmentDoc}
`;
export type ScreenListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreenListQuery,
    ScreenListQueryVariables
  >;
} &
  TChildProps;
export function withScreenList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreenListQuery,
    ScreenListQueryVariables,
    ScreenListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreenListQuery,
    ScreenListQueryVariables,
    ScreenListProps<TChildProps, TDataName>
  >(ScreenListDocument, {
    alias: "screenList",
    ...operationOptions,
  });
}

/**
 * __useScreenListQuery__
 *
 * To run a query within a React component, call `useScreenListQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenListQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      filter: // value for 'filter'
 *      endCursor: // value for 'endCursor'
 *   },
 * });
 */
export function useScreenListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScreenListQuery,
    ScreenListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScreenListQuery, ScreenListQueryVariables>(
    ScreenListDocument,
    options
  );
}
export function useScreenListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreenListQuery,
    ScreenListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScreenListQuery, ScreenListQueryVariables>(
    ScreenListDocument,
    options
  );
}
export type ScreenListQueryHookResult = ReturnType<typeof useScreenListQuery>;
export type ScreenListLazyQueryHookResult = ReturnType<
  typeof useScreenListLazyQuery
>;
export type ScreenListQueryResult = Apollo.QueryResult<
  ScreenListQuery,
  ScreenListQueryVariables
>;
export function refetchScreenListQuery(variables?: ScreenListQueryVariables) {
  return { query: ScreenListDocument, variables: variables };
}
export const SearchScreenDocument = gql`
  query searchScreen($query: String, $spaceId: UUID) {
    searchScreen(query: $query, spaceId: $spaceId) {
      nodes {
        ...screensByScreenGroupIdAndOrgId
      }
      totalCount
    }
  }
  ${ScreensByScreenGroupIdAndOrgIdFragmentDoc}
`;
export type SearchScreenProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchScreenQuery,
    SearchScreenQueryVariables
  >;
} &
  TChildProps;
export function withSearchScreen<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchScreenQuery,
    SearchScreenQueryVariables,
    SearchScreenProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchScreenQuery,
    SearchScreenQueryVariables,
    SearchScreenProps<TChildProps, TDataName>
  >(SearchScreenDocument, {
    alias: "searchScreen",
    ...operationOptions,
  });
}

/**
 * __useSearchScreenQuery__
 *
 * To run a query within a React component, call `useSearchScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchScreenQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useSearchScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchScreenQuery,
    SearchScreenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchScreenQuery, SearchScreenQueryVariables>(
    SearchScreenDocument,
    options
  );
}
export function useSearchScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchScreenQuery,
    SearchScreenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchScreenQuery, SearchScreenQueryVariables>(
    SearchScreenDocument,
    options
  );
}
export type SearchScreenQueryHookResult = ReturnType<
  typeof useSearchScreenQuery
>;
export type SearchScreenLazyQueryHookResult = ReturnType<
  typeof useSearchScreenLazyQuery
>;
export type SearchScreenQueryResult = Apollo.QueryResult<
  SearchScreenQuery,
  SearchScreenQueryVariables
>;
export function refetchSearchScreenQuery(
  variables?: SearchScreenQueryVariables
) {
  return { query: SearchScreenDocument, variables: variables };
}
export const SetScreenContentByAppInstanceIdDocument = gql`
  mutation setScreenContentByAppInstanceId(
    $input: SetScreenContentByAppInstanceIdInput!
  ) {
    setScreenContentByAppInstanceId(input: $input) {
      screen {
        ...Screen
        screenGroupByScreenGroupId {
          __typename
          id
          name
        }
        appInstancesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        castByCastId {
          __typename
          id
          content
        }
      }
    }
  }
  ${ScreenFragmentDoc}
`;
export type SetScreenContentByAppInstanceIdMutationFn = Apollo.MutationFunction<
  SetScreenContentByAppInstanceIdMutation,
  SetScreenContentByAppInstanceIdMutationVariables
>;
export type SetScreenContentByAppInstanceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetScreenContentByAppInstanceIdMutation,
    SetScreenContentByAppInstanceIdMutationVariables
  >;
} &
  TChildProps;
export function withSetScreenContentByAppInstanceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetScreenContentByAppInstanceIdMutation,
    SetScreenContentByAppInstanceIdMutationVariables,
    SetScreenContentByAppInstanceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetScreenContentByAppInstanceIdMutation,
    SetScreenContentByAppInstanceIdMutationVariables,
    SetScreenContentByAppInstanceIdProps<TChildProps, TDataName>
  >(SetScreenContentByAppInstanceIdDocument, {
    alias: "setScreenContentByAppInstanceId",
    ...operationOptions,
  });
}

/**
 * __useSetScreenContentByAppInstanceIdMutation__
 *
 * To run a mutation, you first call `useSetScreenContentByAppInstanceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScreenContentByAppInstanceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScreenContentByAppInstanceIdMutation, { data, loading, error }] = useSetScreenContentByAppInstanceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetScreenContentByAppInstanceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetScreenContentByAppInstanceIdMutation,
    SetScreenContentByAppInstanceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetScreenContentByAppInstanceIdMutation,
    SetScreenContentByAppInstanceIdMutationVariables
  >(SetScreenContentByAppInstanceIdDocument, options);
}
export type SetScreenContentByAppInstanceIdMutationHookResult = ReturnType<
  typeof useSetScreenContentByAppInstanceIdMutation
>;
export type SetScreenContentByAppInstanceIdMutationResult = Apollo.MutationResult<SetScreenContentByAppInstanceIdMutation>;
export type SetScreenContentByAppInstanceIdMutationOptions = Apollo.BaseMutationOptions<
  SetScreenContentByAppInstanceIdMutation,
  SetScreenContentByAppInstanceIdMutationVariables
>;
export const SetScreenContentByChannelIdDocument = gql`
  mutation setScreenContentByChannelId(
    $input: SetScreenContentByChannelIdInput!
  ) {
    setScreenContentByChannelId(input: $input) {
      screen {
        ...Screen
        screenGroupByScreenGroupId {
          ...ScreenGroup
        }
        channelsByScreenId(first: 1) {
          nodes {
            __typename
            id
            spaceId
            name
          }
        }
        castByCastId {
          __typename
          id
          content
        }
      }
    }
  }
  ${ScreenFragmentDoc}
  ${ScreenGroupFragmentDoc}
`;
export type SetScreenContentByChannelIdMutationFn = Apollo.MutationFunction<
  SetScreenContentByChannelIdMutation,
  SetScreenContentByChannelIdMutationVariables
>;
export type SetScreenContentByChannelIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetScreenContentByChannelIdMutation,
    SetScreenContentByChannelIdMutationVariables
  >;
} &
  TChildProps;
export function withSetScreenContentByChannelId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetScreenContentByChannelIdMutation,
    SetScreenContentByChannelIdMutationVariables,
    SetScreenContentByChannelIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetScreenContentByChannelIdMutation,
    SetScreenContentByChannelIdMutationVariables,
    SetScreenContentByChannelIdProps<TChildProps, TDataName>
  >(SetScreenContentByChannelIdDocument, {
    alias: "setScreenContentByChannelId",
    ...operationOptions,
  });
}

/**
 * __useSetScreenContentByChannelIdMutation__
 *
 * To run a mutation, you first call `useSetScreenContentByChannelIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScreenContentByChannelIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScreenContentByChannelIdMutation, { data, loading, error }] = useSetScreenContentByChannelIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetScreenContentByChannelIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetScreenContentByChannelIdMutation,
    SetScreenContentByChannelIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetScreenContentByChannelIdMutation,
    SetScreenContentByChannelIdMutationVariables
  >(SetScreenContentByChannelIdDocument, options);
}
export type SetScreenContentByChannelIdMutationHookResult = ReturnType<
  typeof useSetScreenContentByChannelIdMutation
>;
export type SetScreenContentByChannelIdMutationResult = Apollo.MutationResult<SetScreenContentByChannelIdMutation>;
export type SetScreenContentByChannelIdMutationOptions = Apollo.BaseMutationOptions<
  SetScreenContentByChannelIdMutation,
  SetScreenContentByChannelIdMutationVariables
>;
export const SetScreenContentByFileIdDocument = gql`
  mutation setScreenContentByFileId($input: SetScreenContentByFileIdInput!) {
    setScreenContentByFileId(input: $input) {
      screen {
        ...Screen
        screenGroupByScreenGroupId {
          __typename
          id
          name
        }
        filesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
            mimetype
          }
        }
        castByCastId {
          __typename
          id
          content
        }
      }
    }
  }
  ${ScreenFragmentDoc}
`;
export type SetScreenContentByFileIdMutationFn = Apollo.MutationFunction<
  SetScreenContentByFileIdMutation,
  SetScreenContentByFileIdMutationVariables
>;
export type SetScreenContentByFileIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetScreenContentByFileIdMutation,
    SetScreenContentByFileIdMutationVariables
  >;
} &
  TChildProps;
export function withSetScreenContentByFileId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetScreenContentByFileIdMutation,
    SetScreenContentByFileIdMutationVariables,
    SetScreenContentByFileIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetScreenContentByFileIdMutation,
    SetScreenContentByFileIdMutationVariables,
    SetScreenContentByFileIdProps<TChildProps, TDataName>
  >(SetScreenContentByFileIdDocument, {
    alias: "setScreenContentByFileId",
    ...operationOptions,
  });
}

/**
 * __useSetScreenContentByFileIdMutation__
 *
 * To run a mutation, you first call `useSetScreenContentByFileIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScreenContentByFileIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScreenContentByFileIdMutation, { data, loading, error }] = useSetScreenContentByFileIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetScreenContentByFileIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetScreenContentByFileIdMutation,
    SetScreenContentByFileIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetScreenContentByFileIdMutation,
    SetScreenContentByFileIdMutationVariables
  >(SetScreenContentByFileIdDocument, options);
}
export type SetScreenContentByFileIdMutationHookResult = ReturnType<
  typeof useSetScreenContentByFileIdMutation
>;
export type SetScreenContentByFileIdMutationResult = Apollo.MutationResult<SetScreenContentByFileIdMutation>;
export type SetScreenContentByFileIdMutationOptions = Apollo.BaseMutationOptions<
  SetScreenContentByFileIdMutation,
  SetScreenContentByFileIdMutationVariables
>;
export const SetScreenContentByLinkIdDocument = gql`
  mutation setScreenContentByLinkId($input: SetScreenContentByLinkIdInput!) {
    setScreenContentByLinkId(input: $input) {
      screen {
        ...Screen
        screenGroupByScreenGroupId {
          __typename
          id
          name
        }
        linksByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        castByCastId {
          __typename
          id
          content
        }
      }
    }
  }
  ${ScreenFragmentDoc}
`;
export type SetScreenContentByLinkIdMutationFn = Apollo.MutationFunction<
  SetScreenContentByLinkIdMutation,
  SetScreenContentByLinkIdMutationVariables
>;
export type SetScreenContentByLinkIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetScreenContentByLinkIdMutation,
    SetScreenContentByLinkIdMutationVariables
  >;
} &
  TChildProps;
export function withSetScreenContentByLinkId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetScreenContentByLinkIdMutation,
    SetScreenContentByLinkIdMutationVariables,
    SetScreenContentByLinkIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetScreenContentByLinkIdMutation,
    SetScreenContentByLinkIdMutationVariables,
    SetScreenContentByLinkIdProps<TChildProps, TDataName>
  >(SetScreenContentByLinkIdDocument, {
    alias: "setScreenContentByLinkId",
    ...operationOptions,
  });
}

/**
 * __useSetScreenContentByLinkIdMutation__
 *
 * To run a mutation, you first call `useSetScreenContentByLinkIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScreenContentByLinkIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScreenContentByLinkIdMutation, { data, loading, error }] = useSetScreenContentByLinkIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetScreenContentByLinkIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetScreenContentByLinkIdMutation,
    SetScreenContentByLinkIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetScreenContentByLinkIdMutation,
    SetScreenContentByLinkIdMutationVariables
  >(SetScreenContentByLinkIdDocument, options);
}
export type SetScreenContentByLinkIdMutationHookResult = ReturnType<
  typeof useSetScreenContentByLinkIdMutation
>;
export type SetScreenContentByLinkIdMutationResult = Apollo.MutationResult<SetScreenContentByLinkIdMutation>;
export type SetScreenContentByLinkIdMutationOptions = Apollo.BaseMutationOptions<
  SetScreenContentByLinkIdMutation,
  SetScreenContentByLinkIdMutationVariables
>;
export const SetScreenContentByPlaylistIdDocument = gql`
  mutation setScreenContentByPlaylistId(
    $input: SetScreenContentByPlaylistIdInput!
  ) {
    setScreenContentByPlaylistId(input: $input) {
      screen {
        ...Screen
        screenGroupByScreenGroupId {
          __typename
          id
          name
        }
        playlistsByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        castByCastId {
          __typename
          id
          content
        }
      }
    }
  }
  ${ScreenFragmentDoc}
`;
export type SetScreenContentByPlaylistIdMutationFn = Apollo.MutationFunction<
  SetScreenContentByPlaylistIdMutation,
  SetScreenContentByPlaylistIdMutationVariables
>;
export type SetScreenContentByPlaylistIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetScreenContentByPlaylistIdMutation,
    SetScreenContentByPlaylistIdMutationVariables
  >;
} &
  TChildProps;
export function withSetScreenContentByPlaylistId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetScreenContentByPlaylistIdMutation,
    SetScreenContentByPlaylistIdMutationVariables,
    SetScreenContentByPlaylistIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetScreenContentByPlaylistIdMutation,
    SetScreenContentByPlaylistIdMutationVariables,
    SetScreenContentByPlaylistIdProps<TChildProps, TDataName>
  >(SetScreenContentByPlaylistIdDocument, {
    alias: "setScreenContentByPlaylistId",
    ...operationOptions,
  });
}

/**
 * __useSetScreenContentByPlaylistIdMutation__
 *
 * To run a mutation, you first call `useSetScreenContentByPlaylistIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScreenContentByPlaylistIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScreenContentByPlaylistIdMutation, { data, loading, error }] = useSetScreenContentByPlaylistIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetScreenContentByPlaylistIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetScreenContentByPlaylistIdMutation,
    SetScreenContentByPlaylistIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetScreenContentByPlaylistIdMutation,
    SetScreenContentByPlaylistIdMutationVariables
  >(SetScreenContentByPlaylistIdDocument, options);
}
export type SetScreenContentByPlaylistIdMutationHookResult = ReturnType<
  typeof useSetScreenContentByPlaylistIdMutation
>;
export type SetScreenContentByPlaylistIdMutationResult = Apollo.MutationResult<SetScreenContentByPlaylistIdMutation>;
export type SetScreenContentByPlaylistIdMutationOptions = Apollo.BaseMutationOptions<
  SetScreenContentByPlaylistIdMutation,
  SetScreenContentByPlaylistIdMutationVariables
>;
export const SetScreenContentBySiteIdDocument = gql`
  mutation setScreenContentBySiteId($input: SetScreenContentBySiteIdInput!) {
    setScreenContentBySiteId(input: $input) {
      screen {
        ...Screen
        screenGroupByScreenGroupId {
          __typename
          id
          name
        }
        sitesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        castByCastId {
          __typename
          id
          content
        }
      }
    }
  }
  ${ScreenFragmentDoc}
`;
export type SetScreenContentBySiteIdMutationFn = Apollo.MutationFunction<
  SetScreenContentBySiteIdMutation,
  SetScreenContentBySiteIdMutationVariables
>;
export type SetScreenContentBySiteIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SetScreenContentBySiteIdMutation,
    SetScreenContentBySiteIdMutationVariables
  >;
} &
  TChildProps;
export function withSetScreenContentBySiteId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetScreenContentBySiteIdMutation,
    SetScreenContentBySiteIdMutationVariables,
    SetScreenContentBySiteIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetScreenContentBySiteIdMutation,
    SetScreenContentBySiteIdMutationVariables,
    SetScreenContentBySiteIdProps<TChildProps, TDataName>
  >(SetScreenContentBySiteIdDocument, {
    alias: "setScreenContentBySiteId",
    ...operationOptions,
  });
}

/**
 * __useSetScreenContentBySiteIdMutation__
 *
 * To run a mutation, you first call `useSetScreenContentBySiteIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScreenContentBySiteIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScreenContentBySiteIdMutation, { data, loading, error }] = useSetScreenContentBySiteIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetScreenContentBySiteIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetScreenContentBySiteIdMutation,
    SetScreenContentBySiteIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetScreenContentBySiteIdMutation,
    SetScreenContentBySiteIdMutationVariables
  >(SetScreenContentBySiteIdDocument, options);
}
export type SetScreenContentBySiteIdMutationHookResult = ReturnType<
  typeof useSetScreenContentBySiteIdMutation
>;
export type SetScreenContentBySiteIdMutationResult = Apollo.MutationResult<SetScreenContentBySiteIdMutation>;
export type SetScreenContentBySiteIdMutationOptions = Apollo.BaseMutationOptions<
  SetScreenContentBySiteIdMutation,
  SetScreenContentBySiteIdMutationVariables
>;
export const TransferScreensDocument = gql`
  mutation transferScreens($input: TransferScreensInput!) {
    transferScreens(input: $input) {
      screens {
        ...Screen
      }
    }
  }
  ${ScreenFragmentDoc}
`;
export type TransferScreensMutationFn = Apollo.MutationFunction<
  TransferScreensMutation,
  TransferScreensMutationVariables
>;
export type TransferScreensProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    TransferScreensMutation,
    TransferScreensMutationVariables
  >;
} &
  TChildProps;
export function withTransferScreens<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TransferScreensMutation,
    TransferScreensMutationVariables,
    TransferScreensProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    TransferScreensMutation,
    TransferScreensMutationVariables,
    TransferScreensProps<TChildProps, TDataName>
  >(TransferScreensDocument, {
    alias: "transferScreens",
    ...operationOptions,
  });
}

/**
 * __useTransferScreensMutation__
 *
 * To run a mutation, you first call `useTransferScreensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferScreensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferScreensMutation, { data, loading, error }] = useTransferScreensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferScreensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferScreensMutation,
    TransferScreensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransferScreensMutation,
    TransferScreensMutationVariables
  >(TransferScreensDocument, options);
}
export type TransferScreensMutationHookResult = ReturnType<
  typeof useTransferScreensMutation
>;
export type TransferScreensMutationResult = Apollo.MutationResult<TransferScreensMutation>;
export type TransferScreensMutationOptions = Apollo.BaseMutationOptions<
  TransferScreensMutation,
  TransferScreensMutationVariables
>;
export const UpdateScreenByIdsDocument = gql`
  mutation updateScreenByIds($input: UpdateScreenByIdsInput!) {
    updateScreenByIds(input: $input) {
      screens {
        ...Screen
        screenGroupByScreenGroupId {
          ...ScreenGroup
        }
        castByCastId {
          ...Cast
          fileByCastId {
            ...File
          }
          linkByCastId {
            ...Link
          }
          siteByCastId {
            ...Site
          }
          playlistByCastId {
            ...Playlist
          }
        }
        linksByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        sitesByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        channelsByScreenId {
          nodes {
            __typename
            id
            name
            spaceId
            content
            isSharedExplicit
            isSharedAll
            draft {
              __typename
              id
              isPublished
              content
            }
            published {
              __typename
              id
              isPublished
              content
            }
            childOf
            parentChannelByChildOf {
              ...ParentChannel
            }
          }
        }
        filesByScreenId {
          nodes {
            __typename
            id
            name
            mimetype
          }
        }
        playlistsByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        appInstancesByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
      }
    }
  }
  ${ScreenFragmentDoc}
  ${ScreenGroupFragmentDoc}
  ${CastFragmentDoc}
  ${FileFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
  ${PlaylistFragmentDoc}
  ${ParentChannelFragmentDoc}
`;
export type UpdateScreenByIdsMutationFn = Apollo.MutationFunction<
  UpdateScreenByIdsMutation,
  UpdateScreenByIdsMutationVariables
>;
export type UpdateScreenByIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateScreenByIdsMutation,
    UpdateScreenByIdsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateScreenByIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateScreenByIdsMutation,
    UpdateScreenByIdsMutationVariables,
    UpdateScreenByIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateScreenByIdsMutation,
    UpdateScreenByIdsMutationVariables,
    UpdateScreenByIdsProps<TChildProps, TDataName>
  >(UpdateScreenByIdsDocument, {
    alias: "updateScreenByIds",
    ...operationOptions,
  });
}

/**
 * __useUpdateScreenByIdsMutation__
 *
 * To run a mutation, you first call `useUpdateScreenByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScreenByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScreenByIdsMutation, { data, loading, error }] = useUpdateScreenByIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScreenByIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScreenByIdsMutation,
    UpdateScreenByIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScreenByIdsMutation,
    UpdateScreenByIdsMutationVariables
  >(UpdateScreenByIdsDocument, options);
}
export type UpdateScreenByIdsMutationHookResult = ReturnType<
  typeof useUpdateScreenByIdsMutation
>;
export type UpdateScreenByIdsMutationResult = Apollo.MutationResult<UpdateScreenByIdsMutation>;
export type UpdateScreenByIdsMutationOptions = Apollo.BaseMutationOptions<
  UpdateScreenByIdsMutation,
  UpdateScreenByIdsMutationVariables
>;
export const UpdateScreenGroupDocument = gql`
  mutation updateScreenGroup($input: UpdateScreenGroupByIdInput!) {
    updateScreenGroupById(input: $input) {
      screenGroup {
        ...ScreenGroup
      }
    }
  }
  ${ScreenGroupFragmentDoc}
`;
export type UpdateScreenGroupMutationFn = Apollo.MutationFunction<
  UpdateScreenGroupMutation,
  UpdateScreenGroupMutationVariables
>;
export type UpdateScreenGroupProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateScreenGroupMutation,
    UpdateScreenGroupMutationVariables
  >;
} &
  TChildProps;
export function withUpdateScreenGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateScreenGroupMutation,
    UpdateScreenGroupMutationVariables,
    UpdateScreenGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateScreenGroupMutation,
    UpdateScreenGroupMutationVariables,
    UpdateScreenGroupProps<TChildProps, TDataName>
  >(UpdateScreenGroupDocument, {
    alias: "updateScreenGroup",
    ...operationOptions,
  });
}

/**
 * __useUpdateScreenGroupMutation__
 *
 * To run a mutation, you first call `useUpdateScreenGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScreenGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScreenGroupMutation, { data, loading, error }] = useUpdateScreenGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScreenGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScreenGroupMutation,
    UpdateScreenGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScreenGroupMutation,
    UpdateScreenGroupMutationVariables
  >(UpdateScreenGroupDocument, options);
}
export type UpdateScreenGroupMutationHookResult = ReturnType<
  typeof useUpdateScreenGroupMutation
>;
export type UpdateScreenGroupMutationResult = Apollo.MutationResult<UpdateScreenGroupMutation>;
export type UpdateScreenGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateScreenGroupMutation,
  UpdateScreenGroupMutationVariables
>;
export const UpdateScreenListByScreenIdDocument = gql`
  mutation updateScreenListByScreenId($input: UpdateScreenByIdInput!) {
    updateScreenById(input: $input) {
      screen {
        __typename
        id
        orgId
        screenGroupId
        castId
        name
        content
        status
        device
        preview
        tags
        screenGroupByScreenGroupId {
          __typename
          id
          name
          isPrimary
        }
        castByCastId {
          ...Cast
          channelByCastId {
            ...Channel
            childOf
            parentChannelByChildOf {
              ...ParentChannel
            }
          }
          fileByCastId {
            ...File
            fileOutputsByFileId {
              nodes {
                ...FileOutput
              }
              totalCount
            }
          }
          playlistByCastId {
            ...Playlist
          }
          linkByCastId {
            ...Link
          }
          siteByCastId {
            ...Site
          }
          appInstanceByCastId {
            ...AppInstance
            appVersionByAppInstanceId {
              ...AppVersion
              appByAppId {
                __typename
                id
                name
                iconUrl
              }
            }
            appInstallByAppInstallId {
              ...AppInstall
            }
          }
        }
        linksByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        sitesByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        channelsByScreenId {
          nodes {
            __typename
            id
            name
            spaceId
            content
            isSharedExplicit
            isSharedAll
            draft {
              __typename
              id
              isPublished
              content
            }
            published {
              __typename
              id
              isPublished
              content
            }
            childOf
            parentChannelByChildOf {
              ...ParentChannel
            }
          }
        }
        filesByScreenId {
          nodes {
            __typename
            id
            name
            mimetype
          }
        }
        playlistsByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        appInstancesByScreenId {
          nodes {
            __typename
            id
            name
          }
        }
        spaceBySpaceId {
          __typename
          id
          castsBySpaceId {
            nodes {
              screensByCastId {
                totalCount
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${CastFragmentDoc}
  ${ChannelFragmentDoc}
  ${ParentChannelFragmentDoc}
  ${FileFragmentDoc}
  ${FileOutputFragmentDoc}
  ${PlaylistFragmentDoc}
  ${LinkFragmentDoc}
  ${SiteFragmentDoc}
  ${AppInstanceFragmentDoc}
  ${AppVersionFragmentDoc}
  ${AppInstallFragmentDoc}
`;
export type UpdateScreenListByScreenIdMutationFn = Apollo.MutationFunction<
  UpdateScreenListByScreenIdMutation,
  UpdateScreenListByScreenIdMutationVariables
>;
export type UpdateScreenListByScreenIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateScreenListByScreenIdMutation,
    UpdateScreenListByScreenIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateScreenListByScreenId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateScreenListByScreenIdMutation,
    UpdateScreenListByScreenIdMutationVariables,
    UpdateScreenListByScreenIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateScreenListByScreenIdMutation,
    UpdateScreenListByScreenIdMutationVariables,
    UpdateScreenListByScreenIdProps<TChildProps, TDataName>
  >(UpdateScreenListByScreenIdDocument, {
    alias: "updateScreenListByScreenId",
    ...operationOptions,
  });
}

/**
 * __useUpdateScreenListByScreenIdMutation__
 *
 * To run a mutation, you first call `useUpdateScreenListByScreenIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScreenListByScreenIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScreenListByScreenIdMutation, { data, loading, error }] = useUpdateScreenListByScreenIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScreenListByScreenIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScreenListByScreenIdMutation,
    UpdateScreenListByScreenIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScreenListByScreenIdMutation,
    UpdateScreenListByScreenIdMutationVariables
  >(UpdateScreenListByScreenIdDocument, options);
}
export type UpdateScreenListByScreenIdMutationHookResult = ReturnType<
  typeof useUpdateScreenListByScreenIdMutation
>;
export type UpdateScreenListByScreenIdMutationResult = Apollo.MutationResult<UpdateScreenListByScreenIdMutation>;
export type UpdateScreenListByScreenIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateScreenListByScreenIdMutation,
  UpdateScreenListByScreenIdMutationVariables
>;
export const UpdateScreenLocationByIdsDocument = gql`
  mutation updateScreenLocationByIds($input: UpdateScreenLocationByIdsInput!) {
    updateScreenLocationByIds(input: $input) {
      screens {
        ...screensByScreenGroupIdAndOrgId
      }
    }
  }
  ${ScreensByScreenGroupIdAndOrgIdFragmentDoc}
`;
export type UpdateScreenLocationByIdsMutationFn = Apollo.MutationFunction<
  UpdateScreenLocationByIdsMutation,
  UpdateScreenLocationByIdsMutationVariables
>;
export type UpdateScreenLocationByIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateScreenLocationByIdsMutation,
    UpdateScreenLocationByIdsMutationVariables
  >;
} &
  TChildProps;
export function withUpdateScreenLocationByIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateScreenLocationByIdsMutation,
    UpdateScreenLocationByIdsMutationVariables,
    UpdateScreenLocationByIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateScreenLocationByIdsMutation,
    UpdateScreenLocationByIdsMutationVariables,
    UpdateScreenLocationByIdsProps<TChildProps, TDataName>
  >(UpdateScreenLocationByIdsDocument, {
    alias: "updateScreenLocationByIds",
    ...operationOptions,
  });
}

/**
 * __useUpdateScreenLocationByIdsMutation__
 *
 * To run a mutation, you first call `useUpdateScreenLocationByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScreenLocationByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScreenLocationByIdsMutation, { data, loading, error }] = useUpdateScreenLocationByIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScreenLocationByIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScreenLocationByIdsMutation,
    UpdateScreenLocationByIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScreenLocationByIdsMutation,
    UpdateScreenLocationByIdsMutationVariables
  >(UpdateScreenLocationByIdsDocument, options);
}
export type UpdateScreenLocationByIdsMutationHookResult = ReturnType<
  typeof useUpdateScreenLocationByIdsMutation
>;
export type UpdateScreenLocationByIdsMutationResult = Apollo.MutationResult<UpdateScreenLocationByIdsMutation>;
export type UpdateScreenLocationByIdsMutationOptions = Apollo.BaseMutationOptions<
  UpdateScreenLocationByIdsMutation,
  UpdateScreenLocationByIdsMutationVariables
>;
export const AllScreensDocument = gql`
  query allScreens(
    $fetchSize: Int
    $offset: Int
    $orderBy: [ScreensOrderBy!]
    $filter: ScreenFilter
  ) {
    allScreens(
      first: $fetchSize
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      nodes {
        id
        device
        name
        isConnected
        connectedAt
        disconnectedAt
        status
        deviceInfo
        deviceModel
        devicePlatform
        playerWidth
        playerHeight
        content
        env
        messages
        operatingHours
        isNotificationActive
        spaceBySpaceId {
          __typename
          id
          name
          preferences
        }
        screenGroupByScreenGroupId {
          __typename
          id
          name
        }
        channelsByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
            spaceId
            content
            isSharedExplicit
            isSharedAll
            draft {
              __typename
              id
              isPublished
              content
            }
            published {
              __typename
              id
              isPublished
              content
            }
            childOf
            parentChannelByChildOf {
              ...ParentChannel
            }
          }
        }
        appInstancesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        filesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
            mimetype
            metadata
            source
            fileProcessingStatus
          }
        }
        linksByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        playlistsByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
            content
            draft {
              __typename
              id
              isPublished
            }
          }
        }
        sitesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        screenNotesByScreenId {
          nodes {
            ...ScreenNote
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${ParentChannelFragmentDoc}
  ${ScreenNoteFragmentDoc}
`;
export type AllScreensProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllScreensQuery,
    AllScreensQueryVariables
  >;
} &
  TChildProps;
export function withAllScreens<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllScreensQuery,
    AllScreensQueryVariables,
    AllScreensProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllScreensQuery,
    AllScreensQueryVariables,
    AllScreensProps<TChildProps, TDataName>
  >(AllScreensDocument, {
    alias: "allScreens",
    ...operationOptions,
  });
}

/**
 * __useAllScreensQuery__
 *
 * To run a query within a React component, call `useAllScreensQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllScreensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllScreensQuery({
 *   variables: {
 *      fetchSize: // value for 'fetchSize'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllScreensQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllScreensQuery,
    AllScreensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllScreensQuery, AllScreensQueryVariables>(
    AllScreensDocument,
    options
  );
}
export function useAllScreensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllScreensQuery,
    AllScreensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllScreensQuery, AllScreensQueryVariables>(
    AllScreensDocument,
    options
  );
}
export type AllScreensQueryHookResult = ReturnType<typeof useAllScreensQuery>;
export type AllScreensLazyQueryHookResult = ReturnType<
  typeof useAllScreensLazyQuery
>;
export type AllScreensQueryResult = Apollo.QueryResult<
  AllScreensQuery,
  AllScreensQueryVariables
>;
export function refetchAllScreensQuery(variables?: AllScreensQueryVariables) {
  return { query: AllScreensDocument, variables: variables };
}
export const BulkUpdateScreenTimezoneDocument = gql`
  mutation bulkUpdateScreenTimezone($input: BulkUpdateScreenTimezoneInput!) {
    bulkUpdateScreenTimezone(input: $input) {
      screens {
        __typename
        id
        orgId
        screenGroupId
        name
        timezoneOverride
      }
    }
  }
`;
export type BulkUpdateScreenTimezoneMutationFn = Apollo.MutationFunction<
  BulkUpdateScreenTimezoneMutation,
  BulkUpdateScreenTimezoneMutationVariables
>;
export type BulkUpdateScreenTimezoneProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    BulkUpdateScreenTimezoneMutation,
    BulkUpdateScreenTimezoneMutationVariables
  >;
} &
  TChildProps;
export function withBulkUpdateScreenTimezone<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BulkUpdateScreenTimezoneMutation,
    BulkUpdateScreenTimezoneMutationVariables,
    BulkUpdateScreenTimezoneProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BulkUpdateScreenTimezoneMutation,
    BulkUpdateScreenTimezoneMutationVariables,
    BulkUpdateScreenTimezoneProps<TChildProps, TDataName>
  >(BulkUpdateScreenTimezoneDocument, {
    alias: "bulkUpdateScreenTimezone",
    ...operationOptions,
  });
}

/**
 * __useBulkUpdateScreenTimezoneMutation__
 *
 * To run a mutation, you first call `useBulkUpdateScreenTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateScreenTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateScreenTimezoneMutation, { data, loading, error }] = useBulkUpdateScreenTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateScreenTimezoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateScreenTimezoneMutation,
    BulkUpdateScreenTimezoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateScreenTimezoneMutation,
    BulkUpdateScreenTimezoneMutationVariables
  >(BulkUpdateScreenTimezoneDocument, options);
}
export type BulkUpdateScreenTimezoneMutationHookResult = ReturnType<
  typeof useBulkUpdateScreenTimezoneMutation
>;
export type BulkUpdateScreenTimezoneMutationResult = Apollo.MutationResult<BulkUpdateScreenTimezoneMutation>;
export type BulkUpdateScreenTimezoneMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateScreenTimezoneMutation,
  BulkUpdateScreenTimezoneMutationVariables
>;
export const ExportScreensDocument = gql`
  query exportScreens($orderBy: [ScreensOrderBy!], $filter: ScreenFilter) {
    allScreens(orderBy: $orderBy, filter: $filter) {
      nodes {
        id
        name
        devicePlatform
        deviceModel
        status
        screenGroupByScreenGroupId {
          name
        }
        spaceId
        spaceBySpaceId {
          name
        }
        isConnected
        connectedAt
        disconnectedAt
        deviceInfo
        content
        channelsByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
            spaceId
            content
            isSharedExplicit
            isSharedAll
            draft {
              __typename
              id
              isPublished
              content
            }
            published {
              __typename
              id
              isPublished
              content
            }
            childOf
            parentChannelByChildOf {
              ...ParentChannel
            }
          }
        }
        appInstancesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        filesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
            mimetype
            metadata
            source
            fileProcessingStatus
          }
        }
        linksByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
        playlistsByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
            content
            draft {
              __typename
              id
              isPublished
            }
          }
        }
        sitesByScreenId(first: 1) {
          nodes {
            __typename
            id
            name
          }
        }
      }
      totalCount
    }
  }
  ${ParentChannelFragmentDoc}
`;
export type ExportScreensProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ExportScreensQuery,
    ExportScreensQueryVariables
  >;
} &
  TChildProps;
export function withExportScreens<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ExportScreensQuery,
    ExportScreensQueryVariables,
    ExportScreensProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ExportScreensQuery,
    ExportScreensQueryVariables,
    ExportScreensProps<TChildProps, TDataName>
  >(ExportScreensDocument, {
    alias: "exportScreens",
    ...operationOptions,
  });
}

/**
 * __useExportScreensQuery__
 *
 * To run a query within a React component, call `useExportScreensQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportScreensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportScreensQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportScreensQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExportScreensQuery,
    ExportScreensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportScreensQuery, ExportScreensQueryVariables>(
    ExportScreensDocument,
    options
  );
}
export function useExportScreensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportScreensQuery,
    ExportScreensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportScreensQuery, ExportScreensQueryVariables>(
    ExportScreensDocument,
    options
  );
}
export type ExportScreensQueryHookResult = ReturnType<
  typeof useExportScreensQuery
>;
export type ExportScreensLazyQueryHookResult = ReturnType<
  typeof useExportScreensLazyQuery
>;
export type ExportScreensQueryResult = Apollo.QueryResult<
  ExportScreensQuery,
  ExportScreensQueryVariables
>;
export function refetchExportScreensQuery(
  variables?: ExportScreensQueryVariables
) {
  return { query: ExportScreensDocument, variables: variables };
}
export const ScreensByDevicePlatformDocument = gql`
  query screensByDevicePlatform($devicePlatformList: [String!]) {
    allScreens(
      orderBy: NAME_ASC
      filter: { devicePlatform: { in: $devicePlatformList } }
    ) {
      totalCount
    }
  }
`;
export type ScreensByDevicePlatformProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ScreensByDevicePlatformQuery,
    ScreensByDevicePlatformQueryVariables
  >;
} &
  TChildProps;
export function withScreensByDevicePlatform<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ScreensByDevicePlatformQuery,
    ScreensByDevicePlatformQueryVariables,
    ScreensByDevicePlatformProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ScreensByDevicePlatformQuery,
    ScreensByDevicePlatformQueryVariables,
    ScreensByDevicePlatformProps<TChildProps, TDataName>
  >(ScreensByDevicePlatformDocument, {
    alias: "screensByDevicePlatform",
    ...operationOptions,
  });
}

/**
 * __useScreensByDevicePlatformQuery__
 *
 * To run a query within a React component, call `useScreensByDevicePlatformQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreensByDevicePlatformQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreensByDevicePlatformQuery({
 *   variables: {
 *      devicePlatformList: // value for 'devicePlatformList'
 *   },
 * });
 */
export function useScreensByDevicePlatformQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ScreensByDevicePlatformQuery,
    ScreensByDevicePlatformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScreensByDevicePlatformQuery,
    ScreensByDevicePlatformQueryVariables
  >(ScreensByDevicePlatformDocument, options);
}
export function useScreensByDevicePlatformLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScreensByDevicePlatformQuery,
    ScreensByDevicePlatformQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScreensByDevicePlatformQuery,
    ScreensByDevicePlatformQueryVariables
  >(ScreensByDevicePlatformDocument, options);
}
export type ScreensByDevicePlatformQueryHookResult = ReturnType<
  typeof useScreensByDevicePlatformQuery
>;
export type ScreensByDevicePlatformLazyQueryHookResult = ReturnType<
  typeof useScreensByDevicePlatformLazyQuery
>;
export type ScreensByDevicePlatformQueryResult = Apollo.QueryResult<
  ScreensByDevicePlatformQuery,
  ScreensByDevicePlatformQueryVariables
>;
export function refetchScreensByDevicePlatformQuery(
  variables?: ScreensByDevicePlatformQueryVariables
) {
  return { query: ScreensByDevicePlatformDocument, variables: variables };
}
export const SubscribeScreenNotificationByOrgIdDocument = gql`
  mutation subscribeScreenNotificationByOrgId(
    $input: SubscribeScreenNotificationByOrgIdInput!
  ) {
    subscribeScreenNotificationByOrgId(input: $input) {
      notificationRules {
        __typename
        id
        orgId
        spaceId
        event
        active
        waitTime
        createdBy
        screenId
      }
    }
  }
`;
export type SubscribeScreenNotificationByOrgIdMutationFn = Apollo.MutationFunction<
  SubscribeScreenNotificationByOrgIdMutation,
  SubscribeScreenNotificationByOrgIdMutationVariables
>;
export type SubscribeScreenNotificationByOrgIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SubscribeScreenNotificationByOrgIdMutation,
    SubscribeScreenNotificationByOrgIdMutationVariables
  >;
} &
  TChildProps;
export function withSubscribeScreenNotificationByOrgId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubscribeScreenNotificationByOrgIdMutation,
    SubscribeScreenNotificationByOrgIdMutationVariables,
    SubscribeScreenNotificationByOrgIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SubscribeScreenNotificationByOrgIdMutation,
    SubscribeScreenNotificationByOrgIdMutationVariables,
    SubscribeScreenNotificationByOrgIdProps<TChildProps, TDataName>
  >(SubscribeScreenNotificationByOrgIdDocument, {
    alias: "subscribeScreenNotificationByOrgId",
    ...operationOptions,
  });
}

/**
 * __useSubscribeScreenNotificationByOrgIdMutation__
 *
 * To run a mutation, you first call `useSubscribeScreenNotificationByOrgIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeScreenNotificationByOrgIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeScreenNotificationByOrgIdMutation, { data, loading, error }] = useSubscribeScreenNotificationByOrgIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeScreenNotificationByOrgIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeScreenNotificationByOrgIdMutation,
    SubscribeScreenNotificationByOrgIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeScreenNotificationByOrgIdMutation,
    SubscribeScreenNotificationByOrgIdMutationVariables
  >(SubscribeScreenNotificationByOrgIdDocument, options);
}
export type SubscribeScreenNotificationByOrgIdMutationHookResult = ReturnType<
  typeof useSubscribeScreenNotificationByOrgIdMutation
>;
export type SubscribeScreenNotificationByOrgIdMutationResult = Apollo.MutationResult<SubscribeScreenNotificationByOrgIdMutation>;
export type SubscribeScreenNotificationByOrgIdMutationOptions = Apollo.BaseMutationOptions<
  SubscribeScreenNotificationByOrgIdMutation,
  SubscribeScreenNotificationByOrgIdMutationVariables
>;
export const SubscribeScreenNotificationByScreenIdsDocument = gql`
  mutation subscribeScreenNotificationByScreenIds(
    $input: SubscribeScreenNotificationByScreenIdsInput!
  ) {
    subscribeScreenNotificationByScreenIds(input: $input) {
      notificationRules {
        __typename
        id
        orgId
        spaceId
        event
        active
        waitTime
        createdBy
        screenId
      }
    }
  }
`;
export type SubscribeScreenNotificationByScreenIdsMutationFn = Apollo.MutationFunction<
  SubscribeScreenNotificationByScreenIdsMutation,
  SubscribeScreenNotificationByScreenIdsMutationVariables
>;
export type SubscribeScreenNotificationByScreenIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SubscribeScreenNotificationByScreenIdsMutation,
    SubscribeScreenNotificationByScreenIdsMutationVariables
  >;
} &
  TChildProps;
export function withSubscribeScreenNotificationByScreenIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubscribeScreenNotificationByScreenIdsMutation,
    SubscribeScreenNotificationByScreenIdsMutationVariables,
    SubscribeScreenNotificationByScreenIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SubscribeScreenNotificationByScreenIdsMutation,
    SubscribeScreenNotificationByScreenIdsMutationVariables,
    SubscribeScreenNotificationByScreenIdsProps<TChildProps, TDataName>
  >(SubscribeScreenNotificationByScreenIdsDocument, {
    alias: "subscribeScreenNotificationByScreenIds",
    ...operationOptions,
  });
}

/**
 * __useSubscribeScreenNotificationByScreenIdsMutation__
 *
 * To run a mutation, you first call `useSubscribeScreenNotificationByScreenIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeScreenNotificationByScreenIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeScreenNotificationByScreenIdsMutation, { data, loading, error }] = useSubscribeScreenNotificationByScreenIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeScreenNotificationByScreenIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeScreenNotificationByScreenIdsMutation,
    SubscribeScreenNotificationByScreenIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeScreenNotificationByScreenIdsMutation,
    SubscribeScreenNotificationByScreenIdsMutationVariables
  >(SubscribeScreenNotificationByScreenIdsDocument, options);
}
export type SubscribeScreenNotificationByScreenIdsMutationHookResult = ReturnType<
  typeof useSubscribeScreenNotificationByScreenIdsMutation
>;
export type SubscribeScreenNotificationByScreenIdsMutationResult = Apollo.MutationResult<SubscribeScreenNotificationByScreenIdsMutation>;
export type SubscribeScreenNotificationByScreenIdsMutationOptions = Apollo.BaseMutationOptions<
  SubscribeScreenNotificationByScreenIdsMutation,
  SubscribeScreenNotificationByScreenIdsMutationVariables
>;
export const SubscribeScreenNotificationBySpaceIdDocument = gql`
  mutation subscribeScreenNotificationBySpaceId(
    $input: SubscribeScreenNotificationBySpaceIdInput!
  ) {
    subscribeScreenNotificationBySpaceId(input: $input) {
      notificationRules {
        __typename
        id
        orgId
        spaceId
        event
        active
        waitTime
        createdBy
        screenId
      }
    }
  }
`;
export type SubscribeScreenNotificationBySpaceIdMutationFn = Apollo.MutationFunction<
  SubscribeScreenNotificationBySpaceIdMutation,
  SubscribeScreenNotificationBySpaceIdMutationVariables
>;
export type SubscribeScreenNotificationBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    SubscribeScreenNotificationBySpaceIdMutation,
    SubscribeScreenNotificationBySpaceIdMutationVariables
  >;
} &
  TChildProps;
export function withSubscribeScreenNotificationBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubscribeScreenNotificationBySpaceIdMutation,
    SubscribeScreenNotificationBySpaceIdMutationVariables,
    SubscribeScreenNotificationBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SubscribeScreenNotificationBySpaceIdMutation,
    SubscribeScreenNotificationBySpaceIdMutationVariables,
    SubscribeScreenNotificationBySpaceIdProps<TChildProps, TDataName>
  >(SubscribeScreenNotificationBySpaceIdDocument, {
    alias: "subscribeScreenNotificationBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useSubscribeScreenNotificationBySpaceIdMutation__
 *
 * To run a mutation, you first call `useSubscribeScreenNotificationBySpaceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeScreenNotificationBySpaceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeScreenNotificationBySpaceIdMutation, { data, loading, error }] = useSubscribeScreenNotificationBySpaceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeScreenNotificationBySpaceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeScreenNotificationBySpaceIdMutation,
    SubscribeScreenNotificationBySpaceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeScreenNotificationBySpaceIdMutation,
    SubscribeScreenNotificationBySpaceIdMutationVariables
  >(SubscribeScreenNotificationBySpaceIdDocument, options);
}
export type SubscribeScreenNotificationBySpaceIdMutationHookResult = ReturnType<
  typeof useSubscribeScreenNotificationBySpaceIdMutation
>;
export type SubscribeScreenNotificationBySpaceIdMutationResult = Apollo.MutationResult<SubscribeScreenNotificationBySpaceIdMutation>;
export type SubscribeScreenNotificationBySpaceIdMutationOptions = Apollo.BaseMutationOptions<
  SubscribeScreenNotificationBySpaceIdMutation,
  SubscribeScreenNotificationBySpaceIdMutationVariables
>;
export const UnsubscribeAllScreenNotificationsDocument = gql`
  mutation unsubscribeAllScreenNotifications(
    $input: UnsubscribeAllScreenNotificationsInput!
  ) {
    unsubscribeAllScreenNotifications(input: $input) {
      notificationRules {
        __typename
        id
        orgId
        screenId
      }
    }
  }
`;
export type UnsubscribeAllScreenNotificationsMutationFn = Apollo.MutationFunction<
  UnsubscribeAllScreenNotificationsMutation,
  UnsubscribeAllScreenNotificationsMutationVariables
>;
export type UnsubscribeAllScreenNotificationsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UnsubscribeAllScreenNotificationsMutation,
    UnsubscribeAllScreenNotificationsMutationVariables
  >;
} &
  TChildProps;
export function withUnsubscribeAllScreenNotifications<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UnsubscribeAllScreenNotificationsMutation,
    UnsubscribeAllScreenNotificationsMutationVariables,
    UnsubscribeAllScreenNotificationsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UnsubscribeAllScreenNotificationsMutation,
    UnsubscribeAllScreenNotificationsMutationVariables,
    UnsubscribeAllScreenNotificationsProps<TChildProps, TDataName>
  >(UnsubscribeAllScreenNotificationsDocument, {
    alias: "unsubscribeAllScreenNotifications",
    ...operationOptions,
  });
}

/**
 * __useUnsubscribeAllScreenNotificationsMutation__
 *
 * To run a mutation, you first call `useUnsubscribeAllScreenNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeAllScreenNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeAllScreenNotificationsMutation, { data, loading, error }] = useUnsubscribeAllScreenNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeAllScreenNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeAllScreenNotificationsMutation,
    UnsubscribeAllScreenNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeAllScreenNotificationsMutation,
    UnsubscribeAllScreenNotificationsMutationVariables
  >(UnsubscribeAllScreenNotificationsDocument, options);
}
export type UnsubscribeAllScreenNotificationsMutationHookResult = ReturnType<
  typeof useUnsubscribeAllScreenNotificationsMutation
>;
export type UnsubscribeAllScreenNotificationsMutationResult = Apollo.MutationResult<UnsubscribeAllScreenNotificationsMutation>;
export type UnsubscribeAllScreenNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeAllScreenNotificationsMutation,
  UnsubscribeAllScreenNotificationsMutationVariables
>;
export const UnsubscribeScreenNotificationByScreenIdsDocument = gql`
  mutation unsubscribeScreenNotificationByScreenIds(
    $input: UnsubscribeScreenNotificationByScreenIdsInput!
  ) {
    unsubscribeScreenNotificationByScreenIds(input: $input) {
      notificationRules {
        __typename
        id
        orgId
        screenId
      }
    }
  }
`;
export type UnsubscribeScreenNotificationByScreenIdsMutationFn = Apollo.MutationFunction<
  UnsubscribeScreenNotificationByScreenIdsMutation,
  UnsubscribeScreenNotificationByScreenIdsMutationVariables
>;
export type UnsubscribeScreenNotificationByScreenIdsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UnsubscribeScreenNotificationByScreenIdsMutation,
    UnsubscribeScreenNotificationByScreenIdsMutationVariables
  >;
} &
  TChildProps;
export function withUnsubscribeScreenNotificationByScreenIds<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UnsubscribeScreenNotificationByScreenIdsMutation,
    UnsubscribeScreenNotificationByScreenIdsMutationVariables,
    UnsubscribeScreenNotificationByScreenIdsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UnsubscribeScreenNotificationByScreenIdsMutation,
    UnsubscribeScreenNotificationByScreenIdsMutationVariables,
    UnsubscribeScreenNotificationByScreenIdsProps<TChildProps, TDataName>
  >(UnsubscribeScreenNotificationByScreenIdsDocument, {
    alias: "unsubscribeScreenNotificationByScreenIds",
    ...operationOptions,
  });
}

/**
 * __useUnsubscribeScreenNotificationByScreenIdsMutation__
 *
 * To run a mutation, you first call `useUnsubscribeScreenNotificationByScreenIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeScreenNotificationByScreenIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeScreenNotificationByScreenIdsMutation, { data, loading, error }] = useUnsubscribeScreenNotificationByScreenIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeScreenNotificationByScreenIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeScreenNotificationByScreenIdsMutation,
    UnsubscribeScreenNotificationByScreenIdsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeScreenNotificationByScreenIdsMutation,
    UnsubscribeScreenNotificationByScreenIdsMutationVariables
  >(UnsubscribeScreenNotificationByScreenIdsDocument, options);
}
export type UnsubscribeScreenNotificationByScreenIdsMutationHookResult = ReturnType<
  typeof useUnsubscribeScreenNotificationByScreenIdsMutation
>;
export type UnsubscribeScreenNotificationByScreenIdsMutationResult = Apollo.MutationResult<UnsubscribeScreenNotificationByScreenIdsMutation>;
export type UnsubscribeScreenNotificationByScreenIdsMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeScreenNotificationByScreenIdsMutation,
  UnsubscribeScreenNotificationByScreenIdsMutationVariables
>;
export const UnsubscribeScreenNotificationBySpaceIdDocument = gql`
  mutation unsubscribeScreenNotificationBySpaceId(
    $input: UnsubscribeScreenNotificationBySpaceIdInput!
  ) {
    unsubscribeScreenNotificationBySpaceId(input: $input) {
      notificationRules {
        __typename
        id
        orgId
        screenId
      }
    }
  }
`;
export type UnsubscribeScreenNotificationBySpaceIdMutationFn = Apollo.MutationFunction<
  UnsubscribeScreenNotificationBySpaceIdMutation,
  UnsubscribeScreenNotificationBySpaceIdMutationVariables
>;
export type UnsubscribeScreenNotificationBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UnsubscribeScreenNotificationBySpaceIdMutation,
    UnsubscribeScreenNotificationBySpaceIdMutationVariables
  >;
} &
  TChildProps;
export function withUnsubscribeScreenNotificationBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UnsubscribeScreenNotificationBySpaceIdMutation,
    UnsubscribeScreenNotificationBySpaceIdMutationVariables,
    UnsubscribeScreenNotificationBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UnsubscribeScreenNotificationBySpaceIdMutation,
    UnsubscribeScreenNotificationBySpaceIdMutationVariables,
    UnsubscribeScreenNotificationBySpaceIdProps<TChildProps, TDataName>
  >(UnsubscribeScreenNotificationBySpaceIdDocument, {
    alias: "unsubscribeScreenNotificationBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useUnsubscribeScreenNotificationBySpaceIdMutation__
 *
 * To run a mutation, you first call `useUnsubscribeScreenNotificationBySpaceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeScreenNotificationBySpaceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeScreenNotificationBySpaceIdMutation, { data, loading, error }] = useUnsubscribeScreenNotificationBySpaceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeScreenNotificationBySpaceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnsubscribeScreenNotificationBySpaceIdMutation,
    UnsubscribeScreenNotificationBySpaceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnsubscribeScreenNotificationBySpaceIdMutation,
    UnsubscribeScreenNotificationBySpaceIdMutationVariables
  >(UnsubscribeScreenNotificationBySpaceIdDocument, options);
}
export type UnsubscribeScreenNotificationBySpaceIdMutationHookResult = ReturnType<
  typeof useUnsubscribeScreenNotificationBySpaceIdMutation
>;
export type UnsubscribeScreenNotificationBySpaceIdMutationResult = Apollo.MutationResult<UnsubscribeScreenNotificationBySpaceIdMutation>;
export type UnsubscribeScreenNotificationBySpaceIdMutationOptions = Apollo.BaseMutationOptions<
  UnsubscribeScreenNotificationBySpaceIdMutation,
  UnsubscribeScreenNotificationBySpaceIdMutationVariables
>;
export const CreateSiteDocument = gql`
  mutation createSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      __typename
      success
      site {
        ...SiteMutationField
      }
    }
  }
  ${SiteMutationFieldFragmentDoc}
`;
export type CreateSiteMutationFn = Apollo.MutationFunction<
  CreateSiteMutation,
  CreateSiteMutationVariables
>;
export type CreateSiteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateSiteMutation,
    CreateSiteMutationVariables
  >;
} &
  TChildProps;
export function withCreateSite<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSiteMutation,
    CreateSiteMutationVariables,
    CreateSiteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSiteMutation,
    CreateSiteMutationVariables,
    CreateSiteProps<TChildProps, TDataName>
  >(CreateSiteDocument, {
    alias: "createSite",
    ...operationOptions,
  });
}

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSiteMutation,
    CreateSiteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(
    CreateSiteDocument,
    options
  );
}
export type CreateSiteMutationHookResult = ReturnType<
  typeof useCreateSiteMutation
>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<
  CreateSiteMutation,
  CreateSiteMutationVariables
>;
export const DeleteSiteByIdDocument = gql`
  mutation deleteSiteById($input: DeleteSiteByIdInput!) {
    deleteSiteById(input: $input) {
      __typename
      site {
        id
      }
    }
  }
`;
export type DeleteSiteByIdMutationFn = Apollo.MutationFunction<
  DeleteSiteByIdMutation,
  DeleteSiteByIdMutationVariables
>;
export type DeleteSiteByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteSiteByIdMutation,
    DeleteSiteByIdMutationVariables
  >;
} &
  TChildProps;
export function withDeleteSiteById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteSiteByIdMutation,
    DeleteSiteByIdMutationVariables,
    DeleteSiteByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteSiteByIdMutation,
    DeleteSiteByIdMutationVariables,
    DeleteSiteByIdProps<TChildProps, TDataName>
  >(DeleteSiteByIdDocument, {
    alias: "deleteSiteById",
    ...operationOptions,
  });
}

/**
 * __useDeleteSiteByIdMutation__
 *
 * To run a mutation, you first call `useDeleteSiteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteByIdMutation, { data, loading, error }] = useDeleteSiteByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSiteByIdMutation,
    DeleteSiteByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSiteByIdMutation,
    DeleteSiteByIdMutationVariables
  >(DeleteSiteByIdDocument, options);
}
export type DeleteSiteByIdMutationHookResult = ReturnType<
  typeof useDeleteSiteByIdMutation
>;
export type DeleteSiteByIdMutationResult = Apollo.MutationResult<DeleteSiteByIdMutation>;
export type DeleteSiteByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteSiteByIdMutation,
  DeleteSiteByIdMutationVariables
>;
export const SearchSiteDocument = gql`
  query searchSite(
    $query: String
    $spaceId: UUID
    $endCursor: Cursor
    $orderBy: [SitesOrderBy!]
    $first: Int
    $filter: SiteFilter
  ) {
    searchSite(
      query: $query
      spaceId: $spaceId
      first: $first
      filter: $filter
      after: $endCursor
      orderBy: $orderBy
    ) {
      nodes {
        ...Site
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${SiteFragmentDoc}
`;
export type SearchSiteProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SearchSiteQuery,
    SearchSiteQueryVariables
  >;
} &
  TChildProps;
export function withSearchSite<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SearchSiteQuery,
    SearchSiteQueryVariables,
    SearchSiteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SearchSiteQuery,
    SearchSiteQueryVariables,
    SearchSiteProps<TChildProps, TDataName>
  >(SearchSiteDocument, {
    alias: "searchSite",
    ...operationOptions,
  });
}

/**
 * __useSearchSiteQuery__
 *
 * To run a query within a React component, call `useSearchSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSiteQuery({
 *   variables: {
 *      query: // value for 'query'
 *      spaceId: // value for 'spaceId'
 *      endCursor: // value for 'endCursor'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchSiteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchSiteQuery,
    SearchSiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchSiteQuery, SearchSiteQueryVariables>(
    SearchSiteDocument,
    options
  );
}
export function useSearchSiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSiteQuery,
    SearchSiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchSiteQuery, SearchSiteQueryVariables>(
    SearchSiteDocument,
    options
  );
}
export type SearchSiteQueryHookResult = ReturnType<typeof useSearchSiteQuery>;
export type SearchSiteLazyQueryHookResult = ReturnType<
  typeof useSearchSiteLazyQuery
>;
export type SearchSiteQueryResult = Apollo.QueryResult<
  SearchSiteQuery,
  SearchSiteQueryVariables
>;
export function refetchSearchSiteQuery(variables?: SearchSiteQueryVariables) {
  return { query: SearchSiteDocument, variables: variables };
}
export const SiteByIdForContentDocument = gql`
  query siteByIdForContent($id: UUID!) {
    siteById(id: $id) {
      __typename
      id
      orgId
      spaceId
      url
      fileByThumbnailId {
        __typename
        id
        orgId
        metadata
        source
        mimetype
        fileProcessingStatus
      }
      type
      config
      compute
      actions
      name
    }
  }
`;
export type SiteByIdForContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables
  >;
} &
  TChildProps;
export function withSiteByIdForContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables,
    SiteByIdForContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables,
    SiteByIdForContentProps<TChildProps, TDataName>
  >(SiteByIdForContentDocument, {
    alias: "siteByIdForContent",
    ...operationOptions,
  });
}

/**
 * __useSiteByIdForContentQuery__
 *
 * To run a query within a React component, call `useSiteByIdForContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteByIdForContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteByIdForContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteByIdForContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables
  >(SiteByIdForContentDocument, options);
}
export function useSiteByIdForContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SiteByIdForContentQuery,
    SiteByIdForContentQueryVariables
  >(SiteByIdForContentDocument, options);
}
export type SiteByIdForContentQueryHookResult = ReturnType<
  typeof useSiteByIdForContentQuery
>;
export type SiteByIdForContentLazyQueryHookResult = ReturnType<
  typeof useSiteByIdForContentLazyQuery
>;
export type SiteByIdForContentQueryResult = Apollo.QueryResult<
  SiteByIdForContentQuery,
  SiteByIdForContentQueryVariables
>;
export function refetchSiteByIdForContentQuery(
  variables?: SiteByIdForContentQueryVariables
) {
  return { query: SiteByIdForContentDocument, variables: variables };
}
export const SiteListDocument = gql`
  query siteList(
    $spaceId: UUID!
    $endCursor: Cursor
    $orderBy: [SitesOrderBy!]
    $filter: SiteFilter
    $first: Int
  ) {
    spaceById(id: $spaceId) {
      __typename
      id
      availableSitesBySpaceId(
        first: $first
        after: $endCursor
        orderBy: $orderBy
        filter: $filter
      ) {
        nodes {
          ...Site
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${SiteFragmentDoc}
`;
export type SiteListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SiteListQuery,
    SiteListQueryVariables
  >;
} &
  TChildProps;
export function withSiteList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SiteListQuery,
    SiteListQueryVariables,
    SiteListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SiteListQuery,
    SiteListQueryVariables,
    SiteListProps<TChildProps, TDataName>
  >(SiteListDocument, {
    alias: "siteList",
    ...operationOptions,
  });
}

/**
 * __useSiteListQuery__
 *
 * To run a query within a React component, call `useSiteListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteListQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      endCursor: // value for 'endCursor'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSiteListQuery(
  baseOptions: Apollo.QueryHookOptions<SiteListQuery, SiteListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiteListQuery, SiteListQueryVariables>(
    SiteListDocument,
    options
  );
}
export function useSiteListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SiteListQuery,
    SiteListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiteListQuery, SiteListQueryVariables>(
    SiteListDocument,
    options
  );
}
export type SiteListQueryHookResult = ReturnType<typeof useSiteListQuery>;
export type SiteListLazyQueryHookResult = ReturnType<
  typeof useSiteListLazyQuery
>;
export type SiteListQueryResult = Apollo.QueryResult<
  SiteListQuery,
  SiteListQueryVariables
>;
export function refetchSiteListQuery(variables?: SiteListQueryVariables) {
  return { query: SiteListDocument, variables: variables };
}
export const UpdateShareSiteToAllSpacesDocument = gql`
  mutation UpdateShareSiteToAllSpaces(
    $input: UpdateShareSiteToAllSpacesInput!
  ) {
    updateShareSiteToAllSpaces(input: $input) {
      site {
        __typename
        id
        isSharedAll
        isSharedExplicit
        sharedSpacesBySiteId {
          nodes {
            __typename
            id
          }
        }
      }
    }
  }
`;
export type UpdateShareSiteToAllSpacesMutationFn = Apollo.MutationFunction<
  UpdateShareSiteToAllSpacesMutation,
  UpdateShareSiteToAllSpacesMutationVariables
>;
export type UpdateShareSiteToAllSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateShareSiteToAllSpacesMutation,
    UpdateShareSiteToAllSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateShareSiteToAllSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateShareSiteToAllSpacesMutation,
    UpdateShareSiteToAllSpacesMutationVariables,
    UpdateShareSiteToAllSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateShareSiteToAllSpacesMutation,
    UpdateShareSiteToAllSpacesMutationVariables,
    UpdateShareSiteToAllSpacesProps<TChildProps, TDataName>
  >(UpdateShareSiteToAllSpacesDocument, {
    alias: "updateShareSiteToAllSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateShareSiteToAllSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateShareSiteToAllSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareSiteToAllSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareSiteToAllSpacesMutation, { data, loading, error }] = useUpdateShareSiteToAllSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareSiteToAllSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareSiteToAllSpacesMutation,
    UpdateShareSiteToAllSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareSiteToAllSpacesMutation,
    UpdateShareSiteToAllSpacesMutationVariables
  >(UpdateShareSiteToAllSpacesDocument, options);
}
export type UpdateShareSiteToAllSpacesMutationHookResult = ReturnType<
  typeof useUpdateShareSiteToAllSpacesMutation
>;
export type UpdateShareSiteToAllSpacesMutationResult = Apollo.MutationResult<UpdateShareSiteToAllSpacesMutation>;
export type UpdateShareSiteToAllSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareSiteToAllSpacesMutation,
  UpdateShareSiteToAllSpacesMutationVariables
>;
export const UpdateShareSiteToSpacesDocument = gql`
  mutation UpdateShareSiteToSpaces($input: UpdateShareSiteToSpacesInput!) {
    updateShareSiteToSpaces(input: $input) {
      site {
        __typename
        id
        isSharedAll
        isSharedExplicit
        sharedSpacesBySiteId {
          nodes {
            __typename
            id
          }
        }
      }
    }
  }
`;
export type UpdateShareSiteToSpacesMutationFn = Apollo.MutationFunction<
  UpdateShareSiteToSpacesMutation,
  UpdateShareSiteToSpacesMutationVariables
>;
export type UpdateShareSiteToSpacesProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateShareSiteToSpacesMutation,
    UpdateShareSiteToSpacesMutationVariables
  >;
} &
  TChildProps;
export function withUpdateShareSiteToSpaces<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateShareSiteToSpacesMutation,
    UpdateShareSiteToSpacesMutationVariables,
    UpdateShareSiteToSpacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateShareSiteToSpacesMutation,
    UpdateShareSiteToSpacesMutationVariables,
    UpdateShareSiteToSpacesProps<TChildProps, TDataName>
  >(UpdateShareSiteToSpacesDocument, {
    alias: "updateShareSiteToSpaces",
    ...operationOptions,
  });
}

/**
 * __useUpdateShareSiteToSpacesMutation__
 *
 * To run a mutation, you first call `useUpdateShareSiteToSpacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareSiteToSpacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareSiteToSpacesMutation, { data, loading, error }] = useUpdateShareSiteToSpacesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareSiteToSpacesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareSiteToSpacesMutation,
    UpdateShareSiteToSpacesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareSiteToSpacesMutation,
    UpdateShareSiteToSpacesMutationVariables
  >(UpdateShareSiteToSpacesDocument, options);
}
export type UpdateShareSiteToSpacesMutationHookResult = ReturnType<
  typeof useUpdateShareSiteToSpacesMutation
>;
export type UpdateShareSiteToSpacesMutationResult = Apollo.MutationResult<UpdateShareSiteToSpacesMutation>;
export type UpdateShareSiteToSpacesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShareSiteToSpacesMutation,
  UpdateShareSiteToSpacesMutationVariables
>;
export const UpdateSiteByIdDocument = gql`
  mutation updateSiteById($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      __typename
      success
      site {
        ...SiteMutationField
      }
    }
  }
  ${SiteMutationFieldFragmentDoc}
`;
export type UpdateSiteByIdMutationFn = Apollo.MutationFunction<
  UpdateSiteByIdMutation,
  UpdateSiteByIdMutationVariables
>;
export type UpdateSiteByIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateSiteByIdMutation,
    UpdateSiteByIdMutationVariables
  >;
} &
  TChildProps;
export function withUpdateSiteById<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSiteByIdMutation,
    UpdateSiteByIdMutationVariables,
    UpdateSiteByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSiteByIdMutation,
    UpdateSiteByIdMutationVariables,
    UpdateSiteByIdProps<TChildProps, TDataName>
  >(UpdateSiteByIdDocument, {
    alias: "updateSiteById",
    ...operationOptions,
  });
}

/**
 * __useUpdateSiteByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSiteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteByIdMutation, { data, loading, error }] = useUpdateSiteByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSiteByIdMutation,
    UpdateSiteByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSiteByIdMutation,
    UpdateSiteByIdMutationVariables
  >(UpdateSiteByIdDocument, options);
}
export type UpdateSiteByIdMutationHookResult = ReturnType<
  typeof useUpdateSiteByIdMutation
>;
export type UpdateSiteByIdMutationResult = Apollo.MutationResult<UpdateSiteByIdMutation>;
export type UpdateSiteByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateSiteByIdMutation,
  UpdateSiteByIdMutationVariables
>;
export const UpdateSiteIsFavoriteDocument = gql`
  mutation updateSiteIsFavorite($input: UpdateSiteIsFavoriteInput!) {
    updateSiteIsFavorite(input: $input) {
      favorites {
        siteId
        siteBySiteId {
          __typename
          id
          isFavorite
        }
      }
    }
  }
`;
export type UpdateSiteIsFavoriteMutationFn = Apollo.MutationFunction<
  UpdateSiteIsFavoriteMutation,
  UpdateSiteIsFavoriteMutationVariables
>;
export type UpdateSiteIsFavoriteProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateSiteIsFavoriteMutation,
    UpdateSiteIsFavoriteMutationVariables
  >;
} &
  TChildProps;
export function withUpdateSiteIsFavorite<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSiteIsFavoriteMutation,
    UpdateSiteIsFavoriteMutationVariables,
    UpdateSiteIsFavoriteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSiteIsFavoriteMutation,
    UpdateSiteIsFavoriteMutationVariables,
    UpdateSiteIsFavoriteProps<TChildProps, TDataName>
  >(UpdateSiteIsFavoriteDocument, {
    alias: "updateSiteIsFavorite",
    ...operationOptions,
  });
}

/**
 * __useUpdateSiteIsFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteIsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteIsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteIsFavoriteMutation, { data, loading, error }] = useUpdateSiteIsFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteIsFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSiteIsFavoriteMutation,
    UpdateSiteIsFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSiteIsFavoriteMutation,
    UpdateSiteIsFavoriteMutationVariables
  >(UpdateSiteIsFavoriteDocument, options);
}
export type UpdateSiteIsFavoriteMutationHookResult = ReturnType<
  typeof useUpdateSiteIsFavoriteMutation
>;
export type UpdateSiteIsFavoriteMutationResult = Apollo.MutationResult<UpdateSiteIsFavoriteMutation>;
export type UpdateSiteIsFavoriteMutationOptions = Apollo.BaseMutationOptions<
  UpdateSiteIsFavoriteMutation,
  UpdateSiteIsFavoriteMutationVariables
>;
export const PermanentlyDeleteAllItemsDocument = gql`
  mutation permanentlyDeleteAllItems($input: PermanentlyDeleteAllItemsInput!) {
    permanentlyDeleteAllItems(input: $input) {
      recycleBins {
        ...RecycleBin
      }
    }
  }
  ${RecycleBinFragmentDoc}
`;
export type PermanentlyDeleteAllItemsMutationFn = Apollo.MutationFunction<
  PermanentlyDeleteAllItemsMutation,
  PermanentlyDeleteAllItemsMutationVariables
>;
export type PermanentlyDeleteAllItemsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    PermanentlyDeleteAllItemsMutation,
    PermanentlyDeleteAllItemsMutationVariables
  >;
} &
  TChildProps;
export function withPermanentlyDeleteAllItems<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PermanentlyDeleteAllItemsMutation,
    PermanentlyDeleteAllItemsMutationVariables,
    PermanentlyDeleteAllItemsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PermanentlyDeleteAllItemsMutation,
    PermanentlyDeleteAllItemsMutationVariables,
    PermanentlyDeleteAllItemsProps<TChildProps, TDataName>
  >(PermanentlyDeleteAllItemsDocument, {
    alias: "permanentlyDeleteAllItems",
    ...operationOptions,
  });
}

/**
 * __usePermanentlyDeleteAllItemsMutation__
 *
 * To run a mutation, you first call `usePermanentlyDeleteAllItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermanentlyDeleteAllItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permanentlyDeleteAllItemsMutation, { data, loading, error }] = usePermanentlyDeleteAllItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePermanentlyDeleteAllItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PermanentlyDeleteAllItemsMutation,
    PermanentlyDeleteAllItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PermanentlyDeleteAllItemsMutation,
    PermanentlyDeleteAllItemsMutationVariables
  >(PermanentlyDeleteAllItemsDocument, options);
}
export type PermanentlyDeleteAllItemsMutationHookResult = ReturnType<
  typeof usePermanentlyDeleteAllItemsMutation
>;
export type PermanentlyDeleteAllItemsMutationResult = Apollo.MutationResult<PermanentlyDeleteAllItemsMutation>;
export type PermanentlyDeleteAllItemsMutationOptions = Apollo.BaseMutationOptions<
  PermanentlyDeleteAllItemsMutation,
  PermanentlyDeleteAllItemsMutationVariables
>;
export const PermanentlyDeleteByIdsAndSpaceIdDocument = gql`
  mutation permanentlyDeleteByIdsAndSpaceId(
    $input: PermanentlyDeleteByIdsAndSpaceIdInput!
  ) {
    permanentlyDeleteByIdsAndSpaceId(input: $input) {
      recycleBins {
        ...RecycleBin
      }
    }
  }
  ${RecycleBinFragmentDoc}
`;
export type PermanentlyDeleteByIdsAndSpaceIdMutationFn = Apollo.MutationFunction<
  PermanentlyDeleteByIdsAndSpaceIdMutation,
  PermanentlyDeleteByIdsAndSpaceIdMutationVariables
>;
export type PermanentlyDeleteByIdsAndSpaceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    PermanentlyDeleteByIdsAndSpaceIdMutation,
    PermanentlyDeleteByIdsAndSpaceIdMutationVariables
  >;
} &
  TChildProps;
export function withPermanentlyDeleteByIdsAndSpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PermanentlyDeleteByIdsAndSpaceIdMutation,
    PermanentlyDeleteByIdsAndSpaceIdMutationVariables,
    PermanentlyDeleteByIdsAndSpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PermanentlyDeleteByIdsAndSpaceIdMutation,
    PermanentlyDeleteByIdsAndSpaceIdMutationVariables,
    PermanentlyDeleteByIdsAndSpaceIdProps<TChildProps, TDataName>
  >(PermanentlyDeleteByIdsAndSpaceIdDocument, {
    alias: "permanentlyDeleteByIdsAndSpaceId",
    ...operationOptions,
  });
}

/**
 * __usePermanentlyDeleteByIdsAndSpaceIdMutation__
 *
 * To run a mutation, you first call `usePermanentlyDeleteByIdsAndSpaceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermanentlyDeleteByIdsAndSpaceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permanentlyDeleteByIdsAndSpaceIdMutation, { data, loading, error }] = usePermanentlyDeleteByIdsAndSpaceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePermanentlyDeleteByIdsAndSpaceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PermanentlyDeleteByIdsAndSpaceIdMutation,
    PermanentlyDeleteByIdsAndSpaceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PermanentlyDeleteByIdsAndSpaceIdMutation,
    PermanentlyDeleteByIdsAndSpaceIdMutationVariables
  >(PermanentlyDeleteByIdsAndSpaceIdDocument, options);
}
export type PermanentlyDeleteByIdsAndSpaceIdMutationHookResult = ReturnType<
  typeof usePermanentlyDeleteByIdsAndSpaceIdMutation
>;
export type PermanentlyDeleteByIdsAndSpaceIdMutationResult = Apollo.MutationResult<PermanentlyDeleteByIdsAndSpaceIdMutation>;
export type PermanentlyDeleteByIdsAndSpaceIdMutationOptions = Apollo.BaseMutationOptions<
  PermanentlyDeleteByIdsAndSpaceIdMutation,
  PermanentlyDeleteByIdsAndSpaceIdMutationVariables
>;
export const RecycleBinBySpaceIdDocument = gql`
  query recycleBinBySpaceId($spaceId: UUID!, $first: Int, $after: Cursor) {
    recycleBinBySpaceId(spaceId: $spaceId, first: $first, after: $after) {
      nodes {
        ...RecycleBin
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${RecycleBinFragmentDoc}
`;
export type RecycleBinBySpaceIdProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    RecycleBinBySpaceIdQuery,
    RecycleBinBySpaceIdQueryVariables
  >;
} &
  TChildProps;
export function withRecycleBinBySpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RecycleBinBySpaceIdQuery,
    RecycleBinBySpaceIdQueryVariables,
    RecycleBinBySpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    RecycleBinBySpaceIdQuery,
    RecycleBinBySpaceIdQueryVariables,
    RecycleBinBySpaceIdProps<TChildProps, TDataName>
  >(RecycleBinBySpaceIdDocument, {
    alias: "recycleBinBySpaceId",
    ...operationOptions,
  });
}

/**
 * __useRecycleBinBySpaceIdQuery__
 *
 * To run a query within a React component, call `useRecycleBinBySpaceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecycleBinBySpaceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecycleBinBySpaceIdQuery({
 *   variables: {
 *      spaceId: // value for 'spaceId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useRecycleBinBySpaceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecycleBinBySpaceIdQuery,
    RecycleBinBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecycleBinBySpaceIdQuery,
    RecycleBinBySpaceIdQueryVariables
  >(RecycleBinBySpaceIdDocument, options);
}
export function useRecycleBinBySpaceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecycleBinBySpaceIdQuery,
    RecycleBinBySpaceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecycleBinBySpaceIdQuery,
    RecycleBinBySpaceIdQueryVariables
  >(RecycleBinBySpaceIdDocument, options);
}
export type RecycleBinBySpaceIdQueryHookResult = ReturnType<
  typeof useRecycleBinBySpaceIdQuery
>;
export type RecycleBinBySpaceIdLazyQueryHookResult = ReturnType<
  typeof useRecycleBinBySpaceIdLazyQuery
>;
export type RecycleBinBySpaceIdQueryResult = Apollo.QueryResult<
  RecycleBinBySpaceIdQuery,
  RecycleBinBySpaceIdQueryVariables
>;
export function refetchRecycleBinBySpaceIdQuery(
  variables?: RecycleBinBySpaceIdQueryVariables
) {
  return { query: RecycleBinBySpaceIdDocument, variables: variables };
}
export const RestoreAllItemsDocument = gql`
  mutation restoreAllItems($input: RestoreAllItemsInput!) {
    restoreAllItems(input: $input) {
      recycleBins {
        ...RecycleBin
      }
    }
  }
  ${RecycleBinFragmentDoc}
`;
export type RestoreAllItemsMutationFn = Apollo.MutationFunction<
  RestoreAllItemsMutation,
  RestoreAllItemsMutationVariables
>;
export type RestoreAllItemsProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RestoreAllItemsMutation,
    RestoreAllItemsMutationVariables
  >;
} &
  TChildProps;
export function withRestoreAllItems<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestoreAllItemsMutation,
    RestoreAllItemsMutationVariables,
    RestoreAllItemsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestoreAllItemsMutation,
    RestoreAllItemsMutationVariables,
    RestoreAllItemsProps<TChildProps, TDataName>
  >(RestoreAllItemsDocument, {
    alias: "restoreAllItems",
    ...operationOptions,
  });
}

/**
 * __useRestoreAllItemsMutation__
 *
 * To run a mutation, you first call `useRestoreAllItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreAllItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreAllItemsMutation, { data, loading, error }] = useRestoreAllItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreAllItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreAllItemsMutation,
    RestoreAllItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreAllItemsMutation,
    RestoreAllItemsMutationVariables
  >(RestoreAllItemsDocument, options);
}
export type RestoreAllItemsMutationHookResult = ReturnType<
  typeof useRestoreAllItemsMutation
>;
export type RestoreAllItemsMutationResult = Apollo.MutationResult<RestoreAllItemsMutation>;
export type RestoreAllItemsMutationOptions = Apollo.BaseMutationOptions<
  RestoreAllItemsMutation,
  RestoreAllItemsMutationVariables
>;
export const RestoreByIdsAndSpaceIdDocument = gql`
  mutation restoreByIdsAndSpaceId($input: RestoreByIdsAndSpaceIdInput!) {
    restoreByIdsAndSpaceId(input: $input) {
      recycleBins {
        ...RecycleBin
      }
    }
  }
  ${RecycleBinFragmentDoc}
`;
export type RestoreByIdsAndSpaceIdMutationFn = Apollo.MutationFunction<
  RestoreByIdsAndSpaceIdMutation,
  RestoreByIdsAndSpaceIdMutationVariables
>;
export type RestoreByIdsAndSpaceIdProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RestoreByIdsAndSpaceIdMutation,
    RestoreByIdsAndSpaceIdMutationVariables
  >;
} &
  TChildProps;
export function withRestoreByIdsAndSpaceId<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestoreByIdsAndSpaceIdMutation,
    RestoreByIdsAndSpaceIdMutationVariables,
    RestoreByIdsAndSpaceIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestoreByIdsAndSpaceIdMutation,
    RestoreByIdsAndSpaceIdMutationVariables,
    RestoreByIdsAndSpaceIdProps<TChildProps, TDataName>
  >(RestoreByIdsAndSpaceIdDocument, {
    alias: "restoreByIdsAndSpaceId",
    ...operationOptions,
  });
}

/**
 * __useRestoreByIdsAndSpaceIdMutation__
 *
 * To run a mutation, you first call `useRestoreByIdsAndSpaceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreByIdsAndSpaceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreByIdsAndSpaceIdMutation, { data, loading, error }] = useRestoreByIdsAndSpaceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreByIdsAndSpaceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreByIdsAndSpaceIdMutation,
    RestoreByIdsAndSpaceIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreByIdsAndSpaceIdMutation,
    RestoreByIdsAndSpaceIdMutationVariables
  >(RestoreByIdsAndSpaceIdDocument, options);
}
export type RestoreByIdsAndSpaceIdMutationHookResult = ReturnType<
  typeof useRestoreByIdsAndSpaceIdMutation
>;
export type RestoreByIdsAndSpaceIdMutationResult = Apollo.MutationResult<RestoreByIdsAndSpaceIdMutation>;
export type RestoreByIdsAndSpaceIdMutationOptions = Apollo.BaseMutationOptions<
  RestoreByIdsAndSpaceIdMutation,
  RestoreByIdsAndSpaceIdMutationVariables
>;
