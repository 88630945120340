import Axios, { Method, AxiosError } from "axios";
import { appConfig } from "../../appConfig";
import { ssm } from "../../state/session/ssm";
import { Backend } from "@screencloud/billing.types";
import { setupCache, CacheRequestConfig } from "axios-cache-interceptor";

/**
 * Enumerate request IDs for billing operations.
 *
 * @remarks
 * An enum is employed to facilitate the secure invalidation of request cache via external sources. It's crucial to
 * understand that we exclusively cache requests made throughout the app, excluding the billing tab. This intentional
 * exclusion ensures that API requests in the billing tab are always 'network-only', guaranteeing up-to-date data.
 */
export enum BILLING_REQUEST_ID {
  GET_PROVIDER = "get-provider",
  GET_CUSTOMER = "get-customer",
  GET_SUBSCRIPTION = "get-subscription",
  GET_PLAN = "get-plan",
  GET_PAYMENT_SOURCE = "get-payment-source",
  GET_SCREENS = "get-screens",
  GET_ADDONS = "get-addons",
  CAN_PAIR_SCREEN = "can-pair-screen",
}

/**
 * Setup a cache interceptor for Axios
 */
export const axios = setupCache(Axios);

/**
 * Client for interacting with the new Billing RESTful API backend.
 *
 * @param {Opts} opts - Options for the client.
 *
 * @remarks
 * This client is specifically designed for making requests to the new Billing Backend, which has been separated from
 * the Studio service. The transition from the old to the new backend will occur in phases, resulting in potential
 * coexistence of the current (Studio GraphQL) and new services at certain times.
 */
interface Opts<I> {
  id?: string;
  url: string;
  method?: Method;
  data?: I;
  headers?: any;
  params?: any;
}
export const client = async <O, I = Record<string, any>>(opts: Opts<I>): Promise<O> => {
  const token = ssm.current.token;
  if (!token) {
    /**
     * If there's no token, return undefined.
     *
     * @remarks
     * The absence of a token typically occurs when a customer enters an invalid organization index in the URL
     * (e.g., /org/99999/). In such cases, our app handles the situation gracefully by logging the user out and
     * performing the necessary actions. Consequently, we opt to completely bypass the billing call and delegate this
     * task to Studio. We reluctantly use a 'ts-ignore' here, as specifying an 'undefined' return type would
     * necessitate existence checks in more than 20 implementations across the app. Given the rarity of this issue and
     * the app's ability to redirect the user to authentication, we choose not to clutter the codebase with those
     * checks.
     */
    // @ts-ignore: see @remarks
    return;
  }
  const authToken = ssm.getAuthToken();
  const config: CacheRequestConfig = {
    method: opts.method || "GET",
    baseURL: appConfig.billingBaseURL,
    url: opts.url,
    headers: {
      "Id-Token": authToken,
      Authorization: `Bearer ${token}`,
      ...opts.headers,
    },
    data: opts.data,
    params: opts.params,
  };
  if (opts.id) {
    // Specifically bind id as inteceptor works with undefined.
    config.id = opts.id;
  } else {
    config.cache = false;
  }
  try {
    const request = await axios.request<O>(config);
    return request.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data?.message || error.message);
    }
    throw error;
  }
};

/**
 * Shortcut methods for retrieving data from the RESTful API endpoint.
 *
 * @remarks
 * The following methods offer a convenient shorthand for interacting with the Billing Service by making requests to
 * the corresponding endpoints. Additionally, shorthand types for the endpoints are exported to enhance brevity in the
 * client's consumers.
 */

export const getProvider = async (cache = false) => {
  return client<Backend.Organizations.Customer.Provider.Response.Body>({
    id: cache ? BILLING_REQUEST_ID.GET_PROVIDER : undefined,
    url: "/customers/me/provider",
  });
};

/**
 * Invalidate cache for the above request.
 */
export const invalidateGetProvider = () => {
  return axios.storage.remove(BILLING_REQUEST_ID.GET_PROVIDER);
};

export type Customer = Backend.Organizations.Customer.Get.Response.Body;
export const getCustomer = async (spaceId?: string, cache = false) => {
  return client<Customer>({
    id: cache ? BILLING_REQUEST_ID.GET_CUSTOMER : undefined,
    url: "/customers/me",
    headers: { "Space-Id": spaceId },
  });
};

/**
 * Invalidate cache for the above request.
 */
export const invalidateGetCustomer = () => {
  return axios.storage.remove(BILLING_REQUEST_ID.GET_CUSTOMER);
};

export const updateCustomerVat = async (data: Backend.Organizations.Customer.Vat.Request.Body, spaceId?: string) => {
  return client<Backend.Organizations.Customer.Vat.Response.Body>({
    method: "PUT",
    url: "/customers/me/vat",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const updateCustomerAddress = async (
  data: Backend.Organizations.Customer.Address.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.Customer.Address.Response.Body>({
    method: "PUT",
    url: "/customers/me/address",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const canPairScreen = async (spaceId?: string, cache = false) => {
  return client<void>({
    method: "POST",
    id: cache ? BILLING_REQUEST_ID.CAN_PAIR_SCREEN : undefined,
    url: "/screens/me/pair/check",
    headers: { "Space-Id": spaceId },
  });
};

/**
 * Invalidate cache for the above request.
 */
export const invalidateCanPairScreen = () => {
  return axios.storage.remove(BILLING_REQUEST_ID.CAN_PAIR_SCREEN);
};

export type Screens = Backend.Organizations.Screens.Get.Response.Body;
export const getScreens = async (spaceId?: string, cache = false) => {
  return client<Screens>({
    id: cache ? BILLING_REQUEST_ID.GET_SCREENS : undefined,
    url: "/screens/me",
    headers: { "Space-Id": spaceId },
  });
};

/**
 * Invalidate cache for the above request.
 */
export const invalidateGetScreens = () => {
  return axios.storage.remove(BILLING_REQUEST_ID.GET_SCREENS);
};

export type Subscription = Backend.Organizations.Subscription.Get.Response.Body;
export const getSubscription = async (spaceId?: string, cache = false) => {
  return client<Subscription>({
    id: cache ? BILLING_REQUEST_ID.GET_SUBSCRIPTION : undefined,
    url: "/subscriptions/me",
    headers: { "Space-Id": spaceId },
  });
};

/**
 * Invalidate cache for the above request.
 */
export const invalidateGetSubscription = () => {
  return axios.storage.remove(BILLING_REQUEST_ID.GET_SUBSCRIPTION);
};

export type SubscriptionRenewal = Backend.Organizations.Subscription.Renewal.Response.Body;
export const getSubscriptionRenewal = async (spaceId?: string) => {
  return client<SubscriptionRenewal>({
    url: "/subscriptions/me/renewal",
    headers: { "Space-Id": spaceId },
  });
};

export const updateSubscription = async (
  data: Backend.Organizations.Subscription.Update.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.Subscription.Update.Response.Body>({
    method: "PUT",
    url: "/subscriptions/me",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const startSubscription = async (spaceId?: string) => {
  return client<Backend.Organizations.Subscription.Start.Response.Body>({
    method: "POST",
    url: "/subscriptions/me/start",
    headers: { "Space-Id": spaceId },
  });
};

export const updateSubscriptionPoNumber = async (
  data: Backend.Organizations.Subscription.PoNumber.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.Subscription.PoNumber.Response.Body>({
    method: "PUT",
    url: "/subscriptions/me/ponumber",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export type Plan = Backend.Organizations.Plan.Get.Response.Body;
export const getPlan = async (spaceId?: string, cache = false) => {
  return client<Plan>({
    id: cache ? BILLING_REQUEST_ID.GET_PLAN : undefined,
    url: "/plans/me",
    headers: { "Space-Id": spaceId },
  });
};

/**
 * Invalidate cache for the above request.
 */
export const invalidateGetPlan = () => {
  return axios.storage.remove(BILLING_REQUEST_ID.GET_PLAN);
};

export type PlanRenewal = Backend.Organizations.Plan.Renewal.Response.Body;
export const getPlanRenewal = async (spaceId?: string) => {
  return client<PlanRenewal>({
    url: "/plans/me/renewal",
    headers: { "Space-Id": spaceId },
  });
};

/**
 *
 * @remarks
 * To retrieve the desired plan(s), you must provide the corresponding version parameter. This parameter is mandatory
 * and determines the specific plan version to be returned. Here are the available plan versions:
 * v1: Signage
 * v2: Legacy Studio
 * v3: 2021 plans (e.g., Starter, Teams)
 * v4: 2022 plans (e.g., Core, Pro)
 * Make sure to include the appropriate version parameter when calling this API endpoint to ensure that the correct
 * plans are returned based on your specific requirements and compatibility needs.
 */
export const searchPlans = async (params: Backend.Plans.List.Request.Body) => {
  return client<Plan[], Backend.Plans.List.Request.Body>({
    url: "/plans",
    params,
  });
};

/**
 *
 * @remarks
 * This endpoint fetches an array of payment sources associated with organizations. Generally, only one payment source
 * is expected, but multiple sources may exist. However, only the top payment source from the array will be returned.
 * The API ensures that the payment sources are ordered appropriately (this includes invoice customers), so we rely on
 * this ordering.
 */
export type PaymentSource = Backend.PaymentSources.PaymentSource;
export const getPaymentSource = async (spaceId?: string, cache = false) => {
  const paymentSources = await client<Backend.Organizations.PaymentSources.Get.Response.Body>({
    id: cache ? BILLING_REQUEST_ID.GET_PAYMENT_SOURCE : undefined,
    url: "/paymentsources/me",
    headers: { "Space-Id": spaceId },
  });
  if (!paymentSources.length) {
    return null;
  }
  return paymentSources[0];
};

/**
 * Invalidate cache for the above request.
 */
export const invalidateGetPaymentSource = () => {
  return axios.storage.remove(BILLING_REQUEST_ID.GET_PAYMENT_SOURCE);
};

export const createPaymentSourceCardSetup = async (
  data: Backend.Organizations.PaymentSources.Card.Setup.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.PaymentSources.Card.Setup.Response.Body>({
    method: "POST",
    url: "/paymentsources/me/card/setup",
    data,
    headers: { "Space-Id": spaceId },
  });
};

/**
 * Create a Payment Intent
 *
 */
export const createPaymentSourceCardPaymentIntent = async (
  data: Backend.Organizations.PaymentSources.Card.Payment.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.PaymentSources.Card.Payment.Response.Body>({
    method: "POST",
    url: "/paymentsources/me/card/payment",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const createPaymentSourceCard = async (
  data: Backend.Organizations.PaymentSources.Card.Complete.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.PaymentSources.Card.Complete.Response.Body>({
    method: "POST",
    url: "/paymentsources/me/card/complete",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const createPaymentSourcePaypalAuthorize = async (
  data: Backend.Organizations.PaymentSources.Paypal.Authorize.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.PaymentSources.Paypal.Authorize.Response.Body>({
    method: "POST",
    url: "/paymentsources/me/paypal/authorize",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const createPaymentSourcePaypalComplete = async (
  data: Backend.Organizations.PaymentSources.Paypal.Complete.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.PaymentSources.Paypal.Complete.Response.Body>({
    method: "POST",
    url: "/paymentsources/me/paypal/complete",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const createPaymentSourceBankAuthorize = async (
  data: Backend.Organizations.PaymentSources.Bank.Authorize.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.PaymentSources.Bank.Authorize.Response.Body>({
    method: "POST",
    url: "/paymentsources/me/bank/authorize",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const createPaymentSourceBankComplete = async (
  data: Backend.Organizations.PaymentSources.Bank.Complete.Request.Body,
  spaceId?: string
) => {
  return client<Backend.Organizations.PaymentSources.Bank.Complete.Response.Body>({
    method: "POST",
    url: "/paymentsources/me/bank/complete",
    data,
    headers: { "Space-Id": spaceId },
  });
};

export const createPaymentSourceInvoice = async (spaceId?: string) => {
  return client<Backend.Organizations.PaymentSources.Invoice.Complete.Response.Body>({
    method: "POST",
    url: "/paymentsources/me/invoice",
    headers: { "Space-Id": spaceId },
  });
};

export type Coupons = Backend.Organizations.Coupons.Get.Response.Body;
export const getCoupons = async (spaceId?: string) => {
  return client<Coupons>({
    url: "/coupons/me",
    headers: { "Space-Id": spaceId },
  });
};

export type CouponsRenewal = Backend.Organizations.Coupons.Renewal.Response.Body;
export const getCouponsRenewal = async (spaceId?: string) => {
  return client<CouponsRenewal>({
    url: "/coupons/me/renewal",
    headers: { "Space-Id": spaceId },
  });
};

/**
 * @remarks By default, this endpoint is cached
 */
export type Addons = Backend.Organizations.Addons.Get.Response.Body;
export const getAddons = async (spaceId?: string, cache = true) => {
  return client<Addons>({
    id: cache ? BILLING_REQUEST_ID.GET_ADDONS : undefined,
    url: "/addons/me",
    headers: { "Space-Id": spaceId },
  });
};

/**
 * Invalidate cache for the above request.
 */
export const invalidateGetAddons = () => {
  return axios.storage.remove(BILLING_REQUEST_ID.GET_ADDONS);
};

export type AddonsRenewal = Backend.Organizations.Addons.Renewal.Response.Body;
export const getAddonsRenewal = async (spaceId?: string) => {
  return client<AddonsRenewal>({
    url: "/addons/me/renewal",
    headers: { "Space-Id": spaceId },
  });
};

export type Estimate = Backend.Organizations.Estimate.Get.Response.Body;
export const getEstimate = async (spaceId?: string) => {
  return client<Estimate>({
    url: "/estimates/me",
    headers: { "Space-Id": spaceId },
  });
};

/**
 *
 * @remarks
 * Error responses are expected on this endpoint, indicating no scheduled changes. In such cases, the response type
 * will be inferred from an empty object. The hook is designed to handle this scenario appropriately.
 */
export type EstimateRenewal = Backend.Organizations.Estimate.Renewal.Response.Body;
export const getEstimateRenewal = async (spaceId?: string) => {
  try {
    return await client<EstimateRenewal>({
      url: "/estimates/me/renewal",
      headers: { "Space-Id": spaceId },
    });
  } catch (error) {
    return {} as Backend.Estimates.InvoiceEstimate;
  }
};

export type FeatureFlags = Backend.Organizations.FeatureFlags.Get.Response.Body;
export const getBillingFeatureFlags = async (spaceId?: string) => {
  try {
    return client<FeatureFlags>({
      url: "/featureflags/me",
      headers: { "Space-Id": spaceId },
    });
  } catch (error) {
    return [];
  }
};

export type Invoices = Backend.Organizations.Invoices.Get.Response.Body;
export const getInvoices = async (spaceId?: string) => {
  return client<Invoices>({
    url: "/invoices/me",
    headers: { "Space-Id": spaceId },
  });
};

export type DownloadUrl = Backend.Organizations.Invoices.Download.Response.Body;
export const getInvoiceDownloadUrl = async (invoiceId: string, spaceId?: string) => {
  try {
    return client<DownloadUrl>({
      url: `/invoices/me/${invoiceId}`,
      headers: { "Space-Id": spaceId },
    });
  } catch (error) {
    return undefined;
  }
};
