import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";
import { STUDIO_VARIABLES } from "@screencloud/studio-player-sdk";
import DeleteWarning from "../components/DeleteWarning";
import {
  CreateFileDocument,
  CreateFileMutationVariables,
  CreateLinkDocument,
  CreateLinkMutation,
  CreateLinkMutationVariables,
  Link,
  LinkByIdQueryHookResult,
  LinkListItemFragmentDoc,
  LinkType,
  UpdateLinkByIdDocument,
  UpdateLinkByIdMutationVariables,
} from "../types.g";
import { CastedScreen } from "./castingHelper";
import { getTheUsedPlace } from "./deleteStuffHelper";
import queryHelper from "../state/helper/query";
import { AppContextState } from "../AppContextProvider";
import fileStackClient, { resolveFileKey } from "./filestackHelper";
import { appConfig } from "../appConfig";
import { getImageDimensions, trimToLessThan80Char } from "./mediaHelper";
import apolloClient from "../state/apolloClient";
import { isUuid } from "@screencloud/uuid";
import { CreateLinkPayload } from "../components/LinkPicker/create";
import { orderBy } from "lodash";

type QrCodeConfig = {
  payload: { [key: string]: any } | null;
  prop: { [key: string]: any } | null;
};

export const getDeleteLinkConfirmMsg = async (
  link: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"],
  castedScreens?: number,
  castedScreensData?: CastedScreen[]
) => {
  const linkData = await queryHelper.getLinkAssociation(link?.id);
  let message = (
    <>
      <h2>
        <FormattedMessage
          id="ui_component.confirm.delete_heading_name"
          defaultMessage="Delete {name}?"
          values={{ name: link?.name }}
        />
      </h2>
      <p>
        <FormattedMessage
          id="ui_component.confirm.delete_message_with_cast"
          defaultMessage="{screenCount, select, 0 {{name} will be deleted permanently and this action cannot be undone.} 1 {{name} is currently casting on {screenName}. Are you sure you want to continue? This action cannot be undone.} other {{name} is currently casting on {screenCount} screens, Are you sure you want to continue? This action cannot be undone.}}"
          values={{
            name: <strong>{link?.name}</strong>,
            screenCount: castedScreens ?? 0,
            screenName: (
              <strong>
                {castedScreensData && castedScreensData.length
                  ? castedScreensData[0].name
                  : ""}
              </strong>
            ),
          }}
        />
      </p>
    </>
  );
  if (
    linkData?.data?.linkById?.associationsByToLinkAndOrgId.totalCount ??
    0 > 0
  ) {
    const places = getTheUsedPlace(
      linkData?.data.linkById?.associationsByToLinkAndOrgId.nodes
    );
    message = <DeleteWarning name={link?.name ?? ""} places={places} />;
  }
  return message;
};

export const handleCreateLinkOpen = async (
  context: AppContextState,
  linkById?: NonNullable<LinkByIdQueryHookResult["data"]>["linkById"]
) => {
  const spaceId = context.currentSpace?.id;
  // when there's linkIdById it's will be an edit action
  const isEdit = !!linkById;
  const param = await context.modal.openLinkPicker(
    isEdit ? (
      <FormattedMessage
        id="ui_component.link.edit_link"
        defaultMessage="Edit Link"
      />
    ) : (
      <FormattedMessage
        id="ui_component.link.add_link"
        defaultMessage="Add Link"
      />
    ),
    linkById
  );

  let tagManagerData = {
    dataLayer: {
      event: "studio_link_add",
    },
  };

  if (isEdit) {
    tagManagerData = {
      dataLayer: {
        event: "studio_link_update",
      },
    };
  }

  try {
    const upsertLinkData = await onUpsertLink(context, param, linkById?.id);
    context.modal.closeModals();
    TagManager.dataLayer(tagManagerData);
    const uploadedFile = await fileStackClient.upload(
      param.thumbUrl as string,
      {},
      {
        access: "public",
        container: appConfig.uploadsBucket,
        location: appConfig.uploadsLocation,
        path: "",
        region: appConfig.s3Region,
      }
    );

    let metadata = await resolveFileKey({ ...uploadedFile, name: param.name });

    if (uploadedFile.mimetype!.startsWith("image")) {
      const dimensions = await getImageDimensions(uploadedFile.handle);
      const { width, height } = await dimensions.json();
      metadata = {
        ...metadata,
        height,
        width,
      };
    }

    const fileName = trimToLessThan80Char(param.name!);
    const fileInput: CreateFileMutationVariables = {
      input: {
        metadata,
        mimetype: uploadedFile.mimetype,
        name: fileName,
        size: uploadedFile.size,
        source: `https://${appConfig.uploadsBaseUrl}/${
          appConfig.uploadsBucket
        }/${escape(metadata.key!)}`,
        spaceId,
        tags: [],
      },
    };
    const createdFile = await apolloClient.mutate({
      mutation: CreateFileDocument,
      variables: fileInput,
    });
    const fileId =
      createdFile &&
      createdFile.data.createFile.file &&
      createdFile.data.createFile.file.id;

    if (upsertLinkData && isUuid(upsertLinkData?.id)) {
      const updateLinkInput: UpdateLinkByIdMutationVariables = {
        input: {
          fileId,
          id: upsertLinkData.id,
        },
      };
      await apolloClient.mutate({
        mutation: UpdateLinkByIdDocument,
        variables: updateLinkInput,
      });
    }
  } catch (error) {
    console.error("error while creatiing or updating link", error);
  }
};

const onUpsertLink = async (
  context: AppContextState,
  param: CreateLinkPayload,
  linkId?: string
): Promise<
  NonNullable<CreateLinkMutation["createLink"]>["link"] | undefined
> => {
  const spaceId = context.currentSpace?.id;
  if (!spaceId) {
    return;
  }
  const linkTypeParam = param.isInternal
    ? LinkType.Internal
    : param.isCloudRendering
    ? LinkType.Cloud
    : LinkType.Standard;

  const qrcodeConfig: QrCodeConfig = { payload: null, prop: null };
  if (!!param.qrcodeEnabled && !!param.link) {
    qrcodeConfig.payload = {
      client_id: appConfig.qrServiceClientId,
      target_url: param.link,
      context: {
        tenant_id: STUDIO_VARIABLES._SC_ORG_ID,
        secondary_tenant_id: STUDIO_VARIABLES._SC_SPACE_ID,
        source_id: STUDIO_VARIABLES._SC_LINK_ID,
        source_type: STUDIO_VARIABLES._SC_CONTENT_TYPE,
        screen_id: STUDIO_VARIABLES._SC_SCREEN_ID,
      },
    };
    qrcodeConfig.prop = {
      position: param.qrcodePosition,
    };
  }

  if (param.isEdit && linkId) {
    const updateLinkInput: UpdateLinkByIdMutationVariables = {
      input: {
        id: linkId,
        cloudConfig: param.isCloudRendering
          ? { credential: param.credential }
          : {},
        linkType: linkTypeParam,
        name: param.name || "",
        tags: param.tags,
        url: param.link || "",
        cacheBusting: param.cacheBusting,
        autoReload: param.autoReload,
        autoReloadDurationMs: param.autoReloadDurationMs,
        params: param.params,
        qrcodeEnabled: param.qrcodeEnabled,
        qrcodeConfig,
      },
    };
    try {
      const { data } = await apolloClient.mutate({
        mutation: UpdateLinkByIdDocument,
        variables: updateLinkInput,
      });
      return data?.updateLinkById?.link;
    } catch (error) {
      console.error("error while updating link", error);
      return;
    }
  } else {
    const createLinkInput: CreateLinkMutationVariables = {
      input: {
        cloudConfig: param.isCloudRendering
          ? { credential: param.credential }
          : {},
        linkType: linkTypeParam,
        name: param.name || "",
        spaceId,
        tags: param.tags,
        url: param.link || "",
        cacheBusting: param.cacheBusting,
        autoReload: param.autoReload,
        autoReloadDurationMs: param.autoReloadDurationMs,
        params: param.params,
        qrcodeEnabled: param.qrcodeEnabled,
        qrcodeConfig,
      },
    };
    try {
      const { data } = await apolloClient.mutate({
        mutation: CreateLinkDocument,
        variables: createLinkInput,
        update: (cache, { data }) => {
          cache.modify({
            id: cache.identify(context.currentOrg!),
            fields: {
              linksByOrgId(links, options) {
                const newLinkRef = cache.writeFragment({
                  data: data.createLink?.link,
                  fragment: LinkListItemFragmentDoc,
                  fragmentName: "LinkListItem",
                });
                if (
                  links.nodes.some(
                    (ref) =>
                      options.readField("id", ref) === data.createLink?.link?.id
                  )
                ) {
                  return links;
                }

                const nodes = [...links.nodes, newLinkRef];
                const reOrder = orderBy(
                  nodes.map((node) => {
                    const name = options.readField("name", node) as string;
                    const created = options.readField(
                      "createdAt",
                      node
                    ) as string;
                    return {
                      name,
                      created,
                      node,
                    };
                  }),
                  ["created"],
                  ["desc"]
                );
                const newLinkList = {
                  ...links,
                  nodes: reOrder.map((re) => re.node),
                  totalCount: links.totalCount + 1,
                };
                return newLinkList;
              },
            },
          });
        },
      });
      return data?.createLink?.link;
    } catch (error) {
      console.error("error while creating link", error);
      return;
    }
  }
};

const isLinkImageReady = (src: string): boolean => {
  return src.startsWith("https");
};

export const canGenerateQrCode = (
  linkById: Pick<Link, "qrcodeEnabled" | "url">
) => {
  return linkById?.qrcodeEnabled && linkById.url;
};

export const shouldRenderQrcode = (
  linkById: Pick<Link, "qrcodeEnabled" | "url">,
  src
) => {
  return canGenerateQrCode(linkById) && isLinkImageReady(src);
};
