/**
 * Return a fully formatted currency string
 *
 * @param currency - ISO currency symbol (e.g. USD)
 * @param amount - Amount (in cents)
 * @example - $550,000
 */
export function formatted(currency: string, amount: number) {
  const up = amount / 100;
  return up
    .toLocaleString(undefined, {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
    })
    .replace(".00", "");
}

/**
 * Remove the currency code (in brackets only) from a string
 *
 * @param str - String to remove the code from
 * @description - Removes '(XXX)' and NOT 'XXX' (too risky)
 */
export function stripCode(str: string): string {
  return str.replace(/\([A-Z]+\)/g, "").trim();
}
