import { Loader } from "@screencloud/screencloud-ui-components";
import { useEffect, useState } from "react";
import { ssm } from "src/state/session/ssm";
import { getProvider } from "src/billinglatest/clients/service.client";
import { provider } from "src/billinglatest/util/provider";

/**
 * This is HOC to make sure token is loaded before we can use any graphql
 */
export const withStudioSessionInitialized = (
  Component: React.ComponentType<any>
) => (props: any) => {
  const [
    isStudioSessionManagerInitialized,
    setIsStudioSessionManagerInitalized,
  ] = useState(false);

  useEffect(() => {
    (async () => {
      let start = Date.now();
      await ssm.init({ autoRefresh: true });
      console.log(
        `withStudioSessionInitialized: ssm.init() took`,
        Date.now() - start
      );

      /**
       * Retrieve customer information initially from the Billing Service.
       *
       * @api billing.backend
       * @see {@link https://github.com/screencloud/billing.backend Latest Billing API}
       *
       * @remarks
       * Presently, there exist two billing APIs: the legacy (billing-next-restful) and the latest (billing.backend).
       * Although the legacy API is planned for retirement, both APIs are currently operational due to time constraints
       * with Azure. The latest API has not yet achieved feature parity with the legacy version. As a result, all default
       * Chargebee customers will be directed through the legacy API, while non-Chargebee customers (e.g. Azure) will
       * follow the latest API route. Prior to making any further calls, it is essential to request about the customer's
       * provider via the billing system in order to determine the appropriate processing flow. We make this request here
       * as it is the earliest possible point in the app initialisation where it is possible, as at this point we have
       * access to the Studio token.
       *
       * Currently, our focus at this stage (until the legacy API is retired) is solely on the provider value. Given
       * the extensive range of areas in which this value is required, and in order to avert the need for excessive
       * prop drilling, we will retain it within sessionStorage.
       */
      start = Date.now();
      const fetch = await getProvider(true);
      provider.set(fetch?.provider);
      console.log(
        `withStudioSessionInitialized: getProvider() took`,
        Date.now() - start
      );

      setIsStudioSessionManagerInitalized(true);
    })();
  }, []);

  if (!isStudioSessionManagerInitialized) {
    return <Loader active style={{ top: "50%", left: "50%" }} />;
  }

  return <Component {...props} />;
};
