import { useState } from "react";
import { MaybePromise } from "axios-cache-interceptor";
import { Backend } from "@screencloud/billing.types";
import differenceInDays from "date-fns/differenceInDays";

import { util } from "src/billinglatest/util";
import { Constants } from "src/billinglatest/constants";
import { getSubscription, invalidateGetSubscription } from "src/billinglatest/clients/service.client";
import { useAppContext } from "src/hooks/useAppContext";

import { HookProps } from "src/billinglatest/types";

export interface UseSubscription {
  currencyCode: () => string;
  isInTrial: () => boolean;
  isActive: () => boolean;
  isCancelled: () => boolean;
  allowDirectDebit: () => boolean;
  nextBillingDate: () => Date | undefined;
  trialEndDate: () => Date | null;
  remainingTrialDays: () => number | null;
  price: () => string | undefined;
  priceTotal: () => string | undefined;
  set: (input: Backend.Subscriptions.Subscription) => void;
  get: () => Backend.Subscriptions.Subscription;
  fetch: () => Promise<void>;
  refetch: () => Promise<void>;
  invalidate: () => MaybePromise<void>;
  isFetched: boolean;
}

export function useSubscription(props?: HookProps): UseSubscription {
  const context = useAppContext();
  const cache = props?.cache || false;
  const [_get, _set] = useState({} as Backend.Subscriptions.Subscription);

  /**
   * Retrieves the uppercase version of the currency code.
   *
   * @remarks
   * This function returns the currency code in uppercase. Alternatively, you can obtain this value from the
   * plan hook.
   *
   * @see [usePlan]{@link src/billinglatest/hooks/useplan.ts} for accessing the plan hook.
   */
  const currencyCode = (): string => {
    return get().currencyCode?.toUpperCase() || "USD";
  };

  /**
   * Determines if the subscription is currently in a trial period.
   */
  const isInTrial = (): boolean => {
    return get().status === "in_trial";
  };

  /**
   * Checks whether the subscription is currently active.
   */
  const isActive = (): boolean => {
    return get().status === "active";
  };

  /**
   * Checks whether the subscription is currently cancelled.
   */
  const isCancelled = (): boolean => {
    return get().status === "cancelled";
  };

  /**
   * Is this subscription eligible for Direct Debit?
   */
  const allowDirectDebit = (): boolean => {
    return Constants.DirectDebitCurrencies.includes(currencyCode());
  };

  /**
   * Retrieves the upcoming billing date, which marks the start of the next subscription term.
   *
   * @remarks
   * It is worth noting that in some cases, Chargebee may not provide a value for nextBillingAt if the subscription is
   * in a trial period. Therefore, it is essential to handle this scenario and select the appropriate date accordingly.
   */
  const nextBillingDate = (): Date | undefined => {
    const date = get().nextBillingAt || get().trialEnd;
    if (date) {
      return util.date.fromUnix(date);
    }
    return undefined;
  };

  /**
   * Retrieves the trial end date.
   *
   * @remarks
   * If the trial end date is not available (which is probable for certain plans), the function returns null. It is
   * recommended to handle this scenario in your code and avoid assuming the presence of a date.
   */
  const trialEndDate = (): Date | null => {
    const trialEnd = get().trialEnd;
    if (!isInTrial() || !trialEnd) {
      return null;
    }
    return util.date.fromUnix(trialEnd);
  };

  /**
   * Retrieves the days remaining in trial.
   *
   * @remarks
   * If the trial end date is not available (which is probable for certain plans), the function returns null. It is
   * recommended to handle this scenario in your code and avoid assuming the presence of a date.
   */
  const remainingTrialDays = () => {
    const endDate = trialEndDate();
    if (!endDate) {
      return null;
    }
    return differenceInDays(endDate, new Date(Date.now()));
  };

  /**
   * Cost for each individual license.
   *
   * @remarks
   * It is essential to emphasise that Chargebee enables us to customise the cost per license for each customer.
   * Therefore, the planUnitPrice within the subscription (returned here) should be considered as the accurate value,
   * rather than relying on the price from the plan object. It is also important to mention that changing the unit
   * price for specific customers is an extremely rare occurrence.
   */
  const price = (): string | undefined => {
    const price = get().planUnitPrice;
    if (price) {
      return util.currency.formatted(currencyCode(), price);
    }
    return undefined;
  };

  /**
   * Compute the sub-total cost of the subscription excluding any additions or discounts.
   *
   * @remarks
   * This cost represents the total number of licenses the customer possesses, without factoring in VAT, discounts,
   * addons, and other elements. In essence, it is calculated as the number of licenses multiplied by the cost per
   * license.
   */
  const priceTotal = (): string | undefined => {
    let price = get().planAmount || 0;
    if (!get().planAmount) {
      const unitPrice = get().planUnitPrice;
      if (!unitPrice) {
        return undefined;
      }
      price = unitPrice * get().planQuantity;
    }
    return util.currency.formatted(currencyCode(), price);
  };

  /**
   * Essential Methods.
   *
   * This section includes essential methods that form the core of the hook's functionality. These methods are crucial
   * and are unlikely to require any updates in the future.
   */

  /**
   * Set the subscription.
   */
  const set = (input: Backend.Subscriptions.Subscription): void => {
    return _set(input || {});
  };

  /**
   * Return the subscription.
   */
  const get = (): Backend.Subscriptions.Subscription => {
    return _get;
  };

  /**
   * Fetches the subscription from the billing service.
   *
   * @remarks
   * This function retrieves the dsubscription from the billing service and stores it within the hook.
   */
  const fetch = async (): Promise<void> => {
    set(await getSubscription(context.currentSpace?.id, cache));
    util.log.action(`subscription fetched${cache ? " (using cache)" : ""}.`);
  };

  /**
   * A shortcut for the `fetch` function.
   *
   * @remarks
   * This function acts as a convenient alternative to the fetch function. Calling refetch() produces the same outcome
   * as calling fetch(), with the added benefit of ensuring the invalidation of any cached data.
   */
  const refetch = async (): Promise<void> => {
    await invalidate();
    return fetch();
  };

  /**
   * Clear the cache.
   *
   * @remarks
   * This method is of type `MaybePromise<>`, indicating it can be invoked in a promise-like or synchronous manner,
   * depending on the calling context (e.g., it's preferable to call it synchronously in useEffect).
   */
  const invalidate = () => {
    return invalidateGetSubscription();
  };

  /**
   * Has the subscription been fetched yet?
   *
   * @remarks
   * Simple notification to let us know if the fetch has been made yet.
   */
  const isFetched = Object.values(get()).length > 0;

  /**
   * Return the hook
   */
  return {
    currencyCode,
    isInTrial,
    isActive,
    isCancelled,
    allowDirectDebit,
    nextBillingDate,
    trialEndDate,
    remainingTrialDays,
    price,
    priceTotal,
    set,
    get,
    fetch,
    refetch,
    invalidate,
    isFetched,
  };
}
